import { Injectable } from '@angular/core';
import {
  AddFormField,
  CreateFormField,
  RemoveFormField,
  SetSelectedFormField,
  SetFormFieldUpdatingMode,
  UpdateFormField,
  DeleteFormField,
  GetDataTypes,
  ResetSelectedFormFields,
  SelectFormFields,
  GetFormFields,
  ResetSelectedFormField,
} from '../store/form-fields.actions';
import { Select, Store } from '@ngxs/store';
import {
  CreateFormFieldDto,
  FormFields,
  FormFieldsWithValiation,
} from '../models/form-fields.model';
import { FormFieldsSelector } from '../store/form-fields.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { DataTypeForDisplay } from '../../shared/models/data-types.model';
import { FormFieldsService } from '../services/form-fields.service';

@Injectable({
  providedIn: 'root',
})
export class FormFieldsFacade {
  constructor(private store: Store, private templateFieldService: FormFieldsService) {}

  @Select(FormFieldsSelector.selectedFormFields)
  selectedFormFields$!: Observable<FormFields[]>;

  @Select(FormFieldsSelector.isUpdatingFormField)
  isUpdatingFormField$!: Observable<boolean>;

  @Select(FormFieldsSelector.selectedFormField)
  selectedFormField$!: Observable<FormFields>;

  @Select(FormFieldsSelector.dataTypes)
  dataTypes$!: Observable<DataTypeForDisplay[]>;

  @Select(FormFieldsSelector.templateFields)
  templateFields$!: Observable<PaginatedList<FormFields>>;

  dispatchGetFormFields(
    documentTemplateId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetFormFields(documentTemplateId, pageNumber, pageSize),
    );
  }

  dispatchCreateFormField(templateField: CreateFormFieldDto) {
    return this.store.dispatch(new CreateFormField(templateField));
  }

  dispatchAddFormField(newFormField: FormFieldsWithValiation) {
    return this.store.dispatch(new AddFormField(newFormField));
  }

  dispatchResetSelectedFormFields() {
    this.store.dispatch(new ResetSelectedFormFields());
  }
  
  dispatchResetSelectedFormField() {
    this.store.dispatch(new ResetSelectedFormField());
  }

  dispatchSetFormFieldUpdatingMode(isUpdatingFormField: boolean) {
    this.store.dispatch(
      new SetFormFieldUpdatingMode(isUpdatingFormField),
    );
  }

  dispatchSetSelectedFormField(
    selectedFormField: FormFields | undefined | null,
  ) {
    this.store.dispatch(new SetSelectedFormField(selectedFormField));
  }

  dispatchUpdateFormField(updatedFormField: FormFields) {
    return this.store.dispatch(new UpdateFormField(updatedFormField));
  }

  dispatchRemoveFormField(templateField: FormFields) {
    this.store.dispatch(new RemoveFormField(templateField));
  }

  dispatchSearchFormByName(name: string, ) {
    return this.templateFieldService.searchFormByName(name);
  }

  dispatchSelectFormFields(selectedFormFields: FormFields[]) {
    this.store.dispatch(new SelectFormFields(selectedFormFields));
  }

  dispatchDeleteFormField(templateFieldId: string) {
    this.store.dispatch(new DeleteFormField(templateFieldId));
  }

  dispatchGetDataTypes() {
    this.store.dispatch(new GetDataTypes());
  }
}
