import { TorConfigurationService } from './../services/tor-settings.service';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { ActivityType } from '../models/activityType.model';
import { QuantityType } from '../models/quantityType.model';
import { Injectable } from '@angular/core';
import {
  CreateActivityType,
  ActivityUpdate,
  CreateQuantityType,
  GetActivityTypes,
  GetQuantityTypes,
  RemoveQuantityType,
  RemoveActivityType,
  RemoveActivityUpdate,
  UpdateActivityType,
  UpdateQuantityType,
  RemoveQuantityUpdate,
  QuantityUpdate,
} from './tor-setting.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import {
  patch,
  insertItem,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

export interface TorConfigurationStateModel {
  quantityTypes: QuantityType[];
  activityTypes: ActivityType[];
  activityUpdate: ActivityType | undefined;
  quantityUpdate: QuantityType | undefined;
}

const TorConfiguration_STATE_TOKEN = new StateToken<TorConfigurationStateModel>(
  'torConfigurationState',
);

const defaults: TorConfigurationStateModel = {
  quantityTypes: [],
  activityTypes: [],
  activityUpdate: undefined,
  quantityUpdate: undefined,
};

@State<TorConfigurationStateModel>({
  name: TorConfiguration_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class TorConfigurationState {
  constructor(
    public TorConfigurationService: TorConfigurationService,
    private store: Store,
    private operationStatus: OperationStatusService,
  ) {}

  @Action(GetQuantityTypes)
  getQuantityTypes(
    { patchState }: StateContext<TorConfigurationStateModel>,
    {}: GetQuantityTypes,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.TorConfigurationService.GetQuantityTypes().pipe(
      tap((result) => {
        patchState({ quantityTypes: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetActivityTypes)
  getActivityTypes(
    { patchState }: StateContext<TorConfigurationStateModel>,
    {}: GetActivityTypes,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.TorConfigurationService.GetActivityTypes().pipe(
      tap((result) => {
        patchState({ activityTypes: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateQuantityType)
  createQuantityType(
    { setState }: StateContext<TorConfigurationStateModel>,
    { quantityType }: CreateQuantityType,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.TorConfigurationService.PostQuantityType(quantityType).pipe(
      tap((result: QuantityType) => {
        setState(
          patch({
            quantityTypes: insertItem(result),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.quantity-type-created-successfully: Quantity type created successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(RemoveQuantityType)
  removeQuantityType(
    { patchState, setState }: StateContext<TorConfigurationStateModel>,
    { id }: RemoveQuantityType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.TorConfigurationService.DeleteQuantityType(id).pipe(
      tap(() => {
        setState(
          patch({
            quantityTypes: removeItem((a) => a.id == id),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.quantity-type-deleted-successfully: Quantity type deleted successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(RemoveActivityType)
  removeActivityType(
    { setState }: StateContext<TorConfigurationStateModel>,
    { id }: RemoveActivityType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.TorConfigurationService.DeleteActivityType(id).pipe(
      tap(() => {
        setState(
          patch({
            activityTypes: removeItem((a) => a.id == id),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.activity-type-deleted-successfully: Activity type deleted successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(CreateActivityType)
  createActivityType(
    { setState, patchState }: StateContext<TorConfigurationStateModel>,
    { activityType }: CreateActivityType,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.TorConfigurationService.PostActivityType(activityType).pipe(
      tap((result: ActivityType) => {
        setState(
          patch({
            activityTypes: insertItem(result),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.activity-type-created-successfully: Activity type created successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(ActivityUpdate)
  activityUpdate(
    { patchState }: StateContext<TorConfigurationStateModel>,
    { activityType }: ActivityUpdate,
  ) {
    patchState({ activityUpdate: activityType });
  }

  @Action(RemoveActivityUpdate)
  removeActivityUpdate(
    { patchState }: StateContext<TorConfigurationStateModel>,
    {}: RemoveActivityUpdate,
  ) {
    patchState({ activityUpdate: undefined });
  }

  @Action(UpdateActivityType)
  updateActivityType(
    { setState }: StateContext<TorConfigurationStateModel>,
    { activityType }: UpdateActivityType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.TorConfigurationService.UpdateActivity(activityType).pipe(
    tap((result) => {
        setState(
          patch({
            activityTypes: updateItem((at) => at.id == activityType.id, result),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.activity-type-updated-successfully: Activity type updated successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(QuantityUpdate)
  quantityUpdate(
    { patchState }: StateContext<TorConfigurationStateModel>,
    { quantityType }: QuantityUpdate,
  ) {
    patchState({ quantityUpdate: quantityType });
  }

  @Action(RemoveQuantityUpdate)
  removeQUantityUpdate(
    { patchState }: StateContext<TorConfigurationStateModel>,
    {}: RemoveQuantityUpdate,
  ) {
    patchState({ quantityUpdate: undefined });
  }

  @Action(UpdateQuantityType)
  updateQuantityType(
    { setState }: StateContext<TorConfigurationStateModel>,
    { quantityType }: UpdateQuantityType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.TorConfigurationService.UpdateQuantity(quantityType).pipe(
      tap((result) => {
        setState(
          patch({
            quantityTypes: updateItem((qt) => qt.id == quantityType.id, result),
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-setting.quantity-type-updated-successfully: Quantity type updated successfully`,
          successStyle,
        );
      }),
    );
  }
}
