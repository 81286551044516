<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.published-process-vs-category.publication-category-vs-research-count">Publication Category Vs Research Count</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-row">
      <div class="flex-row-item">
        <div class="flex-row">
          <div class="flex-row-item flex-row-item-title">
            <h3 i18n="@@researches.published-process-vs-category.publication-category">Publication Category</h3>
          </div>
          <div class="flex-row-item flex-row-item-title">
            <h3 i18n="@@researches.published-process-vs-category.research-count">Research Count</h3>
          </div>
        </div>

        <div
          *ngFor="let item of publicationCategoriesVsProcessCount"
          class="flex-row inner-flex-row"
        >
          <div class="flex-row-item flex-row-item-value">
            <p>{{ item.categoryName }}</p>
          </div>
          <div class="flex-row-item flex-row-item-value">
            <p>{{ item.processCount }}</p>
          </div>
        </div>
      </div>

      <div echarts [options]="chartOption" class="chart flex-row-item"></div>
    </div>
  </mat-card-content>
</mat-card>
