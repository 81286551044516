import { Component } from '@angular/core';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

@Component({
  selector: 'app-tor-settings',
  templateUrl: './tor-settings.component.html',
  styleUrls: ['./tor-settings.component.scss'],
})
export class TorSettingsComponent {
  constructor() {}

  hasGetAllQuantityTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.QuantityType.Feature,
      PERMISSION_NAMES.Researches.QuantityType.GetAllQuantityTypes,
    );
  }

  hasGetAllActivityTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ActivityType.Feature,
      PERMISSION_NAMES.Researches.ActivityType.GetAllActivityTypes,
    );
  }
}
