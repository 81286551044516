import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowCommentState,
  WorkflowCommentStateModel,
} from './workflow-comment.state';

export class WorkflowCommentSelector {
  static slices =
    createPropertySelectors<WorkflowCommentStateModel>(WorkflowCommentState);

  @Selector([WorkflowCommentState])
  static workflowComments(workflowCommentModel: WorkflowCommentStateModel) {
    return workflowCommentModel.workflowComments;
  }

  @Selector([WorkflowCommentState])
  static update(workflowCommentModel: WorkflowCommentStateModel) {
    return workflowCommentModel.update;
  }

  @Selector([WorkflowCommentState])
  static selectedWorkflowComment(
    workflowCommentModel: WorkflowCommentStateModel,
  ) {
    return workflowCommentModel.selectedWorkflowComment;
  }

  @Selector([WorkflowCommentState])
  static reply(workflowCommentModel: WorkflowCommentStateModel) {
    return workflowCommentModel.reply;
  }

  @Selector([WorkflowCommentState])
  static workflowCommentPolicy(
    workflowCommentModel: WorkflowCommentStateModel,
  ) {
    return workflowCommentModel.workflowCommentPolicy;
  }

  @Selector([WorkflowCommentState])
  static reportedWorkflowComments(
    workflowCommentModel: WorkflowCommentStateModel,
  ) {
    return workflowCommentModel.reportedWorkflowComments;
  }

  @Selector([WorkflowCommentState])
  static selectedReportedWorkflowComment(
    workflowCommentModel: WorkflowCommentStateModel,
  ) {
    return workflowCommentModel.selectedReportedWorkflowComment;
  }

  @Selector([WorkflowCommentState])
  static selectedReply(
    workflowCommentModel: WorkflowCommentStateModel
    ) {
      return workflowCommentModel.selectedReply;
    }
  @Selector([WorkflowCommentState])
  static isUpdatingReply(
    workflowCommentModel: WorkflowCommentStateModel
  ) {
    return workflowCommentModel.isUpdatingReply;
  }
}
