import { FormFields } from '../../template-fields/models/form-fields.model';
import {
  OrderWorkflowTypeSteps,
  UpdateWorkflowTypeStepDto,
} from '../models/workflow-type-steps.model';

export class GetWorkFlowTypeSteps {
  static readonly type = `[WorkFlowSteps] ${GetWorkFlowTypeSteps.name}`;
  constructor(
    public readonly id: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class CreateWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${CreateWorkflowTypeStep.name}`;
  constructor(public workflowTypeStep: any) {}
}

export class UpdateWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${UpdateWorkflowTypeStep.name}`;
  constructor(public workflowTypeStep: UpdateWorkflowTypeStepDto) {}
}
export class SetSelectedWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${SetSelectedWorkflowTypeStep.name}`;
  constructor(public workflowTypeStep: any) {}
}
export class SetUpdateStatus {
  static readonly type = `[WorkflowTypeSteps] ${SetUpdateStatus.name}`;
  constructor(public update: boolean) {}
}
export class DeleteWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${DeleteWorkflowTypeStep.name}`;
  constructor(public workflowTypeStepId: string) {}
}

export class SetSelectedWorkflowTypeStepDocumentTemplate {
  static readonly type = `[WorkflowTypeSteps] ${SetSelectedWorkflowTypeStepDocumentTemplate.name}`;
  constructor(public workflowTypeStepDocumentTemplate: any) {}
}

export class SetSelectedWorkflowTypeStepDocumentTemplateFields {
  static readonly type = `[WorkflowTypeSteps] ${SetSelectedWorkflowTypeStepDocumentTemplateFields.name}`;
  constructor(public templateFields: FormFields[]) {}
}
export class AddNotifyOfficesToWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${AddNotifyOfficesToWorkflowTypeStep.name}`;
  constructor(public cc: any) {}
}

export class OrderWorkflowTypeStep {
  static readonly type = `[WorkflowTypeSteps] ${OrderWorkflowTypeStep.name}`;
  constructor(public orderSteps: OrderWorkflowTypeSteps) {}
}

export class GetWorkflowTypeStepDetail {
  static readonly type = `[WorkflowTypeSteps] ${GetWorkflowTypeStepDetail.name}`;
  constructor(public id: string){}
}
