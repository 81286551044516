import { NgModule } from '@angular/core';

import { FormFieldsRoutingModule } from './form-fields-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { FormFieldsState } from './store/form-fields.state';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FieldValidationRulesModule } from '../field-validation-rules/field-validation-rules.module';
import { FormFieldFormComponent } from './components/form-field-form/form-field-form.component';
import { DocumentFormState } from '../document-templates/store/document-form.state';
import { FormFieldStepFormComponent } from './components/form-field-step-form/form-field-step-form.component';
import { WorkflowStepsModule } from '../workflow-steps/workflow-steps.module';

@NgModule({
  declarations: [FormFieldComponent, FormFieldFormComponent, FormFieldStepFormComponent],
  exports: [FormFieldComponent,FormFieldFormComponent, FormFieldStepFormComponent],
  imports: [
    SharedModule,
    FormFieldsRoutingModule,
    FieldValidationRulesModule,
    WorkflowStepsModule,
    NgxsModule.forFeature([FormFieldsState, DocumentFormState]),
  ],
})
export class FormFieldsModule {}
