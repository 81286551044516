import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowAverageTimeSpentState,
  WorkflowAverageTimeSpentStateModel,
} from './workflow-average-time-spent.state';

export class WorkflowAverageTimeSpentSelector {
  static slices = createPropertySelectors<WorkflowAverageTimeSpentStateModel>(
    WorkflowAverageTimeSpentState,
  );

  @Selector([WorkflowAverageTimeSpentState])
  static workflowAverageTimeSpents(
    stateModel: WorkflowAverageTimeSpentStateModel,
  ) {
    return stateModel.workflowAverageTimeSpents;
  }
}
