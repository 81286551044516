<div>
  <mat-card class="profile-card">
    <mat-card-content>
      <div class="edit-icon"  *appHasPermission="hasUpdateUserProfilePermission()">
        <button mat-icon-button (click)="openUpdateProfileDialog()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div class="profile-picture-container">
        <mat-icon class="profile-picture">account_circle</mat-icon>
      </div>

      <div class="profile-details">
        <h1 class="profile-name">{{ userProfile?.fullName }}</h1>

        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@users.user-profile.personal-information">
                Personal information
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="info-icon">account_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            
            <div class="profile-info">
              <div class="info-item">
                <p i18n="@@users.user-profile.email">Email:</p>
                <p>{{ userProfile?.email }}</p>
                <p i18n="@@users.user-profile.user-name">User name:</p>
                <p>{{ userProfile?.userName }}</p>
                <p i18n="@@users.user-profile.phone-number">Phone number:</p>
                <p>{{ "+251" + userProfile?.phoneNumber }}</p>
                <p i18n="@@users.user-profile.office">Office:</p>
                <p>{{ userProfile?.officeName }}</p>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@users.user-profile.roles">
                My roles
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="info-icon">account_box</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="userProfile?.roles?.length ?? 0 > 0; else emptyRoles">
              <div class="profile-info" *ngFor="let role of userProfile?.roles">
                <div class="role-name">
                  <fa-icon
                  [icon]="faCircle"
                ></fa-icon>
                <span>{{role?.name}}</span>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@users.user-profile.permissions">
                My permissions
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="info-icon">key</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="userProfile?.modules?.length ?? 0 > 0 ; else emptyPermissions">
              <div *ngFor="let module of userProfile?.modules">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{module?.name}}
                    </mat-panel-title>
                    <mat-panel-description>
                      module
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div *ngFor="let feature of module?.features">

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{feature?.name}}
                        </mat-panel-title>
                        <mat-panel-description>
                          feature
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div *ngFor="let permission of feature?.permissions">
                        <div matListItemTitle class="permission-name">
                          <fa-icon
                          [icon]="faCircle"
                          ></fa-icon>
                        <span>{{permission?.permissionName}}</span>
                      </div>
                      </div>
                    </mat-expansion-panel>
                  </div>
                </mat-expansion-panel>                     
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <h2 class="mat-h2" style="margin-top: 1.5em; margin-bottom: 0.25em">
        Notification settings
      </h2>
      <mat-selection-list (selectionChange)="handleSelectionChange($event)">
        <mat-list-option
          value="emailNotificationEnabled"
          [selected]="(emailSettings$ | async)?.emailNotificationEnabled"
        >
          Email notifications
        </mat-list-option>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>
</div>


<ng-template #emptyPermissions>
  <div>
    <span class="empty-text" i18n="@@user.user-profile.no-permissions-found">No permissions given</span>
  </div>
</ng-template>

<ng-template #emptyRoles>
  <div>
    <span class="empty-text" i18n="@@user.user-profile.no-roles-found">No roles assigned</span>
  </div>
</ng-template>
