<mat-dialog-content class="dialog-content" *appHasPermission="hasMoveFolderFromArchivedWorkspacePermission()">
  <form [formGroup]="moveForm">
    <div class="share-header">
      <ng-container>
        <p>
         <span i18n="@@offices.archived-folder-move.move"> Move </span> {{ !isSharingFile ? selectedFlatFolderNode?.name : "Files" }}
        </p>
      </ng-container>
    </div>

    <div class="main-continier">
      <div class="first-row">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label i18n="@@offices.archived-folder-move.destination-workspace">Destination Workspace</mat-label>

          <mat-icon matPrefix>{{ "search" }}</mat-icon>
          <mat-chip-grid #workspaceChipGrid aria-label="Workspaces">
            <mat-chip-row
              (removed)="removeWorkspace()"
              *ngIf="moveForm.value.workspace"
            >
              {{ moveForm.value.workspace.name }}
              <button
                matChipRemove
                [attr.aria-label]="'remove ' + moveForm.value.workspace.name"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            #workspaceInput
            [placeholder]="placeholderToggleLabel.selectDestinationWorkspace"
            formControlName="workspaceSearchQuery"
            [matChipInputFor]="workspaceChipGrid"
            [matAutocomplete]="workspaceAuto"
          />
          <mat-autocomplete
            #workspaceAuto="matAutocomplete"
            (optionSelected)="selectWorkspace($event)"
          >
            <mat-option
              *ngFor="let workspace of filteredWorkspaces"
              [value]="workspace"
            >
              {{ workspace.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </form>

  <button
    *ngIf="isSharingFile"
    mat-raised-button
    [disabled]="isSelectFolderDisabled"
    color="primary"
    (click)="selectDestinationFolder()"
   i18n="@@offices.archived-folder-move.select-destination-folder">
    Select Destination Folder
  </button>
  <div
    *ngIf="selectedDestinationFolder && isSharingFile"
    class="selected-folder"
  >
    <p i18n="@@offices.archived-folder-move.selected-destination-folder">Selected Destination Folder :</p>
    <mat-chip (removed)="removeSelectedFolder()">
      {{ selectedDestinationFolder.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="action-buttons">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="onMoveClicked()"
    mat-dialog-close="save"
    [disabled]="isMoveButtonDisabled"
   i18n="@@offices.archived-folder-move.move">
    Move
  </button>
  <button class="submit" mat-flat-button color="accent" mat-dialog-close i18n="@@offices.archived-folder-move.cancel">
    Cancel
  </button>
</mat-dialog-actions>
