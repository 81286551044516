import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentHomeComponent } from './components/document-home/document-home.component';
import {
  APPROVE_DOCUMENT_TEMPLATES_ROUTE,
  ARCHIVED_WORKFLOWS_ROUTE,
  DOCUMENTS_DASHBOARD_ROUTE,
  DOCUMENT_REPORTED_COMMENTS_REPORT_ROUTE,
  DOCUMENT_SETTINGS_ROUTE,
  DOCUMENT_TEMPLATES_ROUTE,
  DOCUMENT_TEMPLATE_DETAIL_ROUTE,
  DOCUMENT_TEMPLATE_LAYOUT_ROUTE,
  DOCUMENT_WORKFLOWSTEP_AVG_TIME,
  EXTERNAL_DOCUMENT_RESPONSE_ROUTE,
  EXTERNAL_DOCUMENT_ROUTE,
  FIELD_VALIDATION_RULES_ROUTE,
  INITIATED_WORKFLOWS,
  INVOLVED_WORKFLOWS,
  MEMOS_ROUTE,
  MEMO_DETAIL_ROUTE,
  MEMO_FORM_ROUTE,
  MY_WORKFLOWS,
  MY_WORKFLOWS_ROUTE,
  NOTICES_FORM_ROUTE,
  NOTICES_ROUTE,
  NOTICES_UPDATE_ROUTE,
  Owned_WORKFLOWS_ROUTE,
  REPORTED_COMMENT_ROUTE,
  TEMPLATE_FIELDS_ROUTE,
  WORKFLOWS_ROUTE,
  WORKFLOW_STEPS_ROUTE,
  WORKFLOW_STEP_DETAIL_ROUTE,
  WORKFLOW_TYPES_ROUTE,
  WORKFLOW_TYPE_STEPS_ROUTE,
  WORKFLOW_TYPE_VALIDATIONS,
} from '../core/constants/routes';

import { WORKFLOW_TYPES_URL } from '../core/constants/api-endpoints';
import { FieldValidationRuleComponent } from './field-validation-rules/components/field-validation-rule/field-validation-rule.component';
import { FormFieldComponent } from './template-fields/components/form-field/form-field.component';
import { WorkflowTypeStepComponent } from './workflow-type-steps/components/workflow-type-step/workflow-type-step.component';
import { WorkflowTypeComponent } from './workflow-types/components/workflow-type/workflow-type.component';
import { DocumentFormListComponent } from './document-templates/components/document-form-list/document-form-list.component';
import { SubmittedDocumentFormsComponent } from './document-templates/components/submitted-document-forms/submitted-document-forms.component';
import { DocumentFormDetailComponent } from './document-templates/components/document-form-detail/document-form-detail.component';
import { WorkflowTypeStepListComponent } from './workflow-type-steps/components/workflow-type-step-list/workflow-type-step-list.component';
import { WorkflowComponent } from './workflows/components/workflow/workflow.component';
import { MyWorkflowsComponent } from './workflows/components/my-workflows/my-workflows.component';
import { DocumentFormLayoutComponent } from './document-templates/components/document-form-layout/document-form-layout.component';
import { OwnedWorkflowsComponent } from './workflows/components/owned-workflows/owned-workflows.component';
import { WorkflowStepComponent } from './workflow-steps/components/workflow-step/workflow-step.component';
import { WorkflowValidationsComponent } from './workflow-validation/components/workflow-validations/workflow-validations.component';
import { WorkflowCommentPolicyComponent } from './workflow-comments/components/workflow-comment-policy/workflow-comment-policy.component';
import { MemoListComponent } from './memos/components/memo-list/memo-list.component';
import { NoticesListComponent } from './notices/components/notices-list/notices-list.component';
import { NoticesFormComponent } from './notices/components/notices-form/notices-form.component';
import { ReportedWorkflowCommentComponent } from './workflow-comments/components/reported-workflow-comment/reported-workflow-comment/reported-workflow-comment.component';
import { NoticeDetailComponent } from './notices/components/notices-detail/notices-detail.component';
import { MemoFormComponent } from './memos/components/memo-form/memo-form.component';
import { ExternalDocumentListComponent } from './external-document/components/external-document-list/external-document-list.component';
import { ExternalDocumentDetailComponent } from './external-document/components/external-document-detail/external-document-detail.component';
import { MemoDetailComponent } from './memos/components/memo-detail/memo-detail.component';
import { ExternalDocumentResponseFormComponent } from './external-document/external-document-response-form/external-document-response-form.component';
import { ArchivedWorkflows } from './workflows/components/archived-workflows/archived-workflows.component';
import { CcdWorkflowStepDetailComponent } from './workflow-steps/components/ccd-workflow-step-detail/ccd-workflow-step-detail.component';
import { ExternalDocumentResponseDetailComponent } from './external-document/components/external-document-response-detail/external-document-response-detail.component';
import { DocumentsAnalyticsHomeComponent } from './dashboard/components/documents-home/documents-analytics-home.component';
import { ReportedCommentsReportComponent } from './dashboard/components/reported-comments-report/reported-comments-report.component';
import { ExternalDocumentRecordsComponent } from './dashboard/components/external-document-records/external-document-records.component';
import { WorkflowStepAveragerTimeComponent } from './dashboard/components/workflow-step-averager-time/workflow-step-averager-time.component';
import { InitiatedWorkflowsReportComponent } from './dashboard/components/initiated-workflows-report/initiated-workflows-report.component';
import { MyInitiatedWorkflowsReportComponent } from './dashboard/components/my-initiated-workflows-report/my-initiated-workflows-report.component';
import { WorkflowsInvolvedInReportComponent } from './dashboard/components/workflows-involved-in-report/workflows-involved-in-report.component';
import { MODULES, PERMISSION_NAMES } from '../core/constants/permissions';
import { HasFeaturePermissionGuard, HasPermissionGuard } from '../auth/services/has.permission.guard';
import { WorkflowTypeStepDetailComponent } from './workflow-type-steps/components/workflow-type-step-detail/workflow-type-step-detail.component';

const routes: Routes = [
  {
    path: '',
    component: DocumentHomeComponent,
    children: [
      {
        path: WORKFLOWS_ROUTE,
        component: WorkflowComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByFilter}` }
      },
      {
        path: WORKFLOW_TYPES_ROUTE,
        component: WorkflowTypeComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowType.Feature}:${PERMISSION_NAMES.Documents.WorkflowType.GetWorkflowTypes}` }
      },
      {
        path: WORKFLOW_STEPS_ROUTE,
        component: WorkflowTypeStepComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowStep.Feature}:${PERMISSION_NAMES.Documents.WorkflowStep.GetWorkflowSteps}` }
      },
      {
        path: `${WORKFLOW_TYPE_STEPS_ROUTE}/:id`,
        component: WorkflowTypeStepListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.GetWorkflowTypeSteps}` }
      },
      {
        path: `${WORKFLOW_TYPE_STEPS_ROUTE}/:id/${WORKFLOW_TYPE_VALIDATIONS}`,
        component: WorkflowValidationsComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.GetWorkflowTypeStep}` }
      },
      {
        path: `${WORKFLOW_TYPE_STEPS_ROUTE}/:id/:id`,
        component: WorkflowTypeStepDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature}:${PERMISSION_NAMES.Documents.WorkflowTypeStep.GetWorkflowTypeSteps}` }
      },
      {
        path: DOCUMENT_TEMPLATES_ROUTE,
        component: DocumentFormListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.DocumentForm.Feature}:${PERMISSION_NAMES.Documents.DocumentForm.GetDocumentForms}` }
      },
      {
        path: TEMPLATE_FIELDS_ROUTE,
        component: FormFieldComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.FormField.Feature}:${PERMISSION_NAMES.Documents.FormField.GetFormFields}` }
      },
      {
        path: FIELD_VALIDATION_RULES_ROUTE,
        component: FieldValidationRuleComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.FieldValidationRule.Feature}:${PERMISSION_NAMES.Documents.FieldValidationRule.GetFieldValidationRules}` }
      },
      {
        path: APPROVE_DOCUMENT_TEMPLATES_ROUTE,
        component: SubmittedDocumentFormsComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.DocumentForm.Feature}:${PERMISSION_NAMES.Documents.DocumentForm.ChangeFormStatusToApproved}` }
      },
      {
        path: `${DOCUMENT_TEMPLATE_DETAIL_ROUTE}`,
        component: DocumentFormDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.DocumentForm.Feature}:${PERMISSION_NAMES.Documents.DocumentForm.GetDocumentForm}` }
      },
      {
        path: MY_WORKFLOWS_ROUTE,
        component: MyWorkflowsComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByUser}` }
      },
      {
        path: `${DOCUMENT_TEMPLATE_LAYOUT_ROUTE}`,
        component: DocumentFormLayoutComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.DocumentForm.Feature}:${PERMISSION_NAMES.Documents.DocumentForm.GetDocumentForm}` }
      },
      {
        path: `${Owned_WORKFLOWS_ROUTE}`,
        component: OwnedWorkflowsComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetOwnedWorkflows}` }
      },
      {
        path: ARCHIVED_WORKFLOWS_ROUTE,
        component: ArchivedWorkflows,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetArchivedWorkflows}` }
      },
      {
        path: `${WORKFLOWS_ROUTE}/:id`,
        component: WorkflowStepComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowStep.Feature}:${PERMISSION_NAMES.Documents.WorkflowStep.GetWorkflowStep}` }
      },
      {
        path: `${DOCUMENT_SETTINGS_ROUTE}`,
        component: WorkflowCommentPolicyComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowCommentPolicy.Feature}:${PERMISSION_NAMES.Documents.WorkflowCommentPolicy.GetWorkflowCommentPolicy}` }
      },
      {
        path: `${EXTERNAL_DOCUMENT_ROUTE}`,
        component: ExternalDocumentListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.ExternalDocument.Feature}:${PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocuments}`}
      },
      {
        path: `${EXTERNAL_DOCUMENT_ROUTE}/:id`,
        component: ExternalDocumentDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.ExternalDocument.Feature}:${PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocumentDetail}`}
      },
      {
        path: `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}/:id`,
        component: ExternalDocumentResponseFormComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.ExternalDocument.Feature}:${PERMISSION_NAMES.Documents.ExternalDocument.GetRespondedExternalDocuments}`}
      },
      {
        path: `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}/detail/:id`,
        component: ExternalDocumentResponseDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.ExternalDocument.Feature}:${PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocumentResponseDetail}`}
      },
      {
        path: `${MEMOS_ROUTE}`,
        component: MemoListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Memo.Feature}:${PERMISSION_NAMES.Documents.Memo.GetMemosReceived}`}
      },
      {
        path: `${NOTICES_ROUTE}`,
        component: NoticesListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Notice.Feature}:${PERMISSION_NAMES.Documents.Notice.GetNotices}`}
      },
      {
        path: `${NOTICES_FORM_ROUTE}`,
        component: NoticesFormComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Notice.Feature}:${PERMISSION_NAMES.Documents.Notice.CreateNotice}`}
      },
      {
        path: `${REPORTED_COMMENT_ROUTE}`,
        component: ReportedWorkflowCommentComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowComment.Feature}:${PERMISSION_NAMES.Documents.WorkflowComment.GetReportedWorkflowComments}`}
      },
      {
        path: `${NOTICES_ROUTE}/:id`,
        component: NoticeDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Notice.Feature}:${PERMISSION_NAMES.Documents.Notice.GetNoticeDetail}`}
      },
      {
        path: MEMO_FORM_ROUTE,
        component: MemoFormComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Memo.Feature}:${PERMISSION_NAMES.Documents.Memo.CreateMemo}`}
      },
      {
        path: `${NOTICES_UPDATE_ROUTE}`,
        component: NoticesFormComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Notice.Feature}:${PERMISSION_NAMES.Documents.Notice.UpdateNotice}`}
      },
      {
        path: `${MEMO_DETAIL_ROUTE}/:id`,
        component: MemoDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Memo.Feature}:${PERMISSION_NAMES.Documents.Memo.GetMemo}`}
      },
      {
        path: '',
        component: DocumentsAnalyticsHomeComponent,
      },
      {
        path: `${DOCUMENTS_DASHBOARD_ROUTE}/external-document/:id`,
        component: ExternalDocumentRecordsComponent,
        canActivate: [HasFeaturePermissionGuard],
        data: { feature: PERMISSION_NAMES.Analytics.AnalyticsDocument}
      },
      {
        path: `${MY_WORKFLOWS_ROUTE}/step/:id`,
        component: CcdWorkflowStepDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetNotifiedWorkflows}`}
      },
      {
        path: DOCUMENT_REPORTED_COMMENTS_REPORT_ROUTE,
        component: ReportedCommentsReportComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.WorkflowComment.Feature}:${PERMISSION_NAMES.Documents.WorkflowComment.GetReportedWorkflowComments}`}
      },
      {
        path: DOCUMENT_WORKFLOWSTEP_AVG_TIME,
        component: WorkflowStepAveragerTimeComponent,
      },
      {
        path: INITIATED_WORKFLOWS,
        component: InitiatedWorkflowsReportComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByUser}`}
      },
      {
        path: MY_WORKFLOWS,
        component: MyInitiatedWorkflowsReportComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByUser}`}
      },

      {
        path: INVOLVED_WORKFLOWS,
        component: WorkflowsInvolvedInReportComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.DOCUMENTS}:${PERMISSION_NAMES.Documents.Workflows.Feature}:${PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByUser}`}
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule {}
