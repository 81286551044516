import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { RoleFacade } from '../../facade/role.facade';
import { Observable } from 'rxjs';
import { Role } from '../../models/role.model';
import { User } from '../../models/user.model';
import { UserFacade } from '../../facade/user.facade';

interface AssignRolesDialogComponentState {
  roles: Role[];
  selectedRoles: Set<string>;
}

const initAssignRolesDialogComponentState: AssignRolesDialogComponentState = {
  roles: [],
  selectedRoles: new Set<string>(),
};

@Component({
  selector: 'app-assign-roles-dialog',
  templateUrl: './assign-roles-dialog.component.html',
  styleUrl: './assign-roles-dialog.component.scss',
  providers: [RxState],
})
export class AssignRolesDialogComponent implements OnInit {
  selectedUsersId: Set<string> = new Set<string>();
  roles: Role[] = [];
  roles$: Observable<Role[]> = this.state.select('roles');
  selectedRoles: Set<string> = new Set<string>();
  selectedRoles$: Observable<Set<string>> = this.state.select('selectedRoles');

  constructor(
    private matDialog: MatDialog,
    private state: RxState<AssignRolesDialogComponentState>,
    private roleFacade: RoleFacade,
    private userFacade: UserFacade,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.state.set(initAssignRolesDialogComponentState);
    this.state.connect('roles', this.roleFacade.roles$);

    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });
  }

  ngOnInit(): void {
    this.selectedUsersId = this.data.selectedUsersId;
    this.roleFacade.dispatchGetRoles();
  }

  updateRoleState(checked: boolean, role: Role): void {
    if (checked) {
      this.selectedRoles.add(role.id);
    } else {
      this.selectedRoles.delete(role.id);
    }
  }

  save(): void {
    this.userFacade.dispatchAssignRevokeUserRoles(
      Array.from(this.selectedRoles.values()),
      undefined,
      Array.from(this.selectedUsersId.values()),
    );
    this.matDialog.closeAll();
  }
}
