import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ProcessInstance,
  ProcessInstanceStatus,
} from '../models/process-instance.model';
import { PROCESS_INSTANCES_URL } from 'src/app/core/constants/api-endpoints';
import { PUBLICATIONS_URL } from './../../core/constants/api-endpoints';
import {
  CreatePublicationInfo,
  Publication,
  PublicationStatus,
  PublicationComment,
} from './../models/publication.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicationService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private readonly http: HttpClient) {}

  createPublication(publication: CreatePublicationInfo): Observable<any> {
    return this.http.post<any>(`${PUBLICATIONS_URL}`, publication);
  }

  getCompletedProcessInstances(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    let url = `${PROCESS_INSTANCES_URL}/by-status`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${ProcessInstanceStatus.Completed}`;
    }
    return this.http.get<PaginatedList<ProcessInstance>>(url);
  }

  getPublications(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Publication>> {
    let url = `${PUBLICATIONS_URL}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${ProcessInstanceStatus.Completed}`;
    }
    return this.http.get<PaginatedList<Publication>>(url);
  }

  postPublicationFiles(files: FormData, id: string): Observable<any> {
    return this.http.put(`${PUBLICATIONS_URL}/${id}/upload-document`, files);
  }

  removePublicationProcessDocument(
    processId: string,
    documentId: string,
  ): Observable<any> {
    var url = `${PUBLICATIONS_URL}/publication/${processId}/remove-document/${documentId}`;
    return this.http.delete(url);
  }

  submitPublicationDocuments(id: string): Observable<any> {
    const url = `${PUBLICATIONS_URL}/${id}/submit`;
    return this.http.put(url, null);
  }

  changePublicationStatus(
    id: string,
    status: PublicationStatus,
  ): Observable<any> {
    let url = `${PUBLICATIONS_URL}/`;
    if (id !== null && status !== null) {
      switch(status) {
        case PublicationStatus.Initialized:
          url = url + 'change-status-to-initiazed'
          break;
        case PublicationStatus.ReadyForPublication:
          url = url + 'change-status-to-ready-for-publication'
          break;
        case PublicationStatus.NeedsRevision:
          url = url + 'change-status-to-needs-revision'
          break;
        case PublicationStatus.Cancelled:
          url = url + 'change-status-to-cancelled'
          break;
        case PublicationStatus.Published:
          url = url + 'change-status-to-published'
          break;
      }
    }
    return this.http.put<any>(`${url}?Id=${id}`, this.httpOptions);
  }

  getPaginatedPublicationComments(
    publicationProcessId: string,
    page?: number,
    size?: number,
  ): Observable<any> {
    if (page && size) {
      return this.http.get<PublicationComment>(
        `${PUBLICATIONS_URL}/comment/${publicationProcessId}?pageNumber=${page}&pageSize=${size}`,
      );
    }
    return this.http.get<PublicationComment>(
      `${PUBLICATIONS_URL}/comment/${publicationProcessId}`,
    );
  }

  addPublicationComment(publicationProcessId: string, comment: string) {
    return this.http.post<PublicationComment>(`${PUBLICATIONS_URL}/comment`, {
      publicationProcessId,
      content: comment,
    });
  }

  updatePublicationComment(publicationCommentId: string, content: string) {
    return this.http.put<PublicationComment>(`${PUBLICATIONS_URL}/comment`, {
      publicationCommentId,
      content,
    });
  }

  replyPublicationComment(commentId: string, content: string) {
    return this.http.post<PublicationComment>(`${PUBLICATIONS_URL}/reply`, {
      commentId,
      content,
    });
  }

  deletePublicationComment(commentId: string) {
    return this.http.delete<any>(`${PUBLICATIONS_URL}/comments/${commentId}`);
  }

  updateReplyPublicationCommentReply(
    publicationCommentId: string,
    commentId: string,
    content: string,
  ) {
    return this.http.put<PublicationComment>(
      `${PUBLICATIONS_URL}/comment/reply`,
      {
        publicationCommentId,
        commentId,
        content,
      },
    );
  }

  deletePublicationCommentReply(commentId: string, replyId: string) {
    return this.http.delete<any>(`${PUBLICATIONS_URL}/comments/${commentId}/${replyId}`);
  }

  downloadPublicationDocument(id: string) {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Blob>(
      `${PUBLICATIONS_URL}/documents/download/${id}`,
      options,
    );
  }

  getPublicationFile(publicationProcessId: string, fileId: string): Observable<any> {
    return this.http.get(`${PUBLICATIONS_URL}/${publicationProcessId}/documents/${fileId}`,{
      responseType: 'blob',
    });
  }
}
