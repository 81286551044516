import { Component, HostListener, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { DataType, DataTypeForDisplay } from 'src/app/documents/shared/models/data-types.model';
import { FormFieldsFacade } from '../../facade/form-fields.facades';
import { FormFields } from '../../models/form-fields.model';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { FieldValidationRuleFacade } from 'src/app/documents/field-validation-rules/facades/field-validation-rules.facade';
import { FieldValidationRule } from 'src/app/documents/field-validation-rules/models/field-validation-rule.model';
import { MatSelectChange } from '@angular/material/select';

interface FormFieldFormComponentState {
  selectedDocumentForm?: DocumentForm;
  isCreatingForm?: boolean;
  isUpdatingFormField?: boolean;
  selectedFormField?: FormFields;
  isUpdatingDocumentForm?: boolean;
  dataTypes: DataTypeForDisplay[];
  currentFieldValidationRules: FieldValidationRule[];
}

const initialFormFieldFormComponentState: FormFieldFormComponentState =
  {
    currentFieldValidationRules: [],
    dataTypes:[]
  };

@Component({
  selector: 'app-form-field-form',
  templateUrl: './form-field-form.component.html',
  styleUrls: ['./form-field-form.component.scss'],
})
export class FormFieldFormComponent implements OnInit {
  createEditToggle = {
    value: {
      title: $localize`:@@documents.form-field-form.add-field:ADD Field`,
      submitButton: $localize`:@@documents.form-field-form.add-field:Add Field`,
    },
    create: {
      title: $localize`:@@documents.form-field-form.add-field:ADD Field`,
      submitButton: $localize`:@@documents.form-field-form.add-field:Add Field`,
    },
    update: {
      title: $localize`:@@documents.form-field-form.update-field:Update Field`,
      submitButton: $localize`:@@documents.form-field-form.update-field:Update Field`,
    },
  };

  placeholderFieldLabel = {
    templateFieldName: $localize`:@@documents.form-field-form.form-field-name: Form Field Name`,
    templateFieldDescription: $localize`:@@documents.form-field-form.this-is-description-for-the-form-feild: This is description for the form field`,
    templateFieldPlaceholder: $localize`:@@documents.form-field-form.this-is-placeholder-for-the-form-feild: This is placeholder for the form field`,
}

  selectedDocumentForm$ = this.state.select('selectedDocumentForm');
  selectedDocumentForm?: DocumentForm;
  focusedInput: string = '';
  dataTypes: DataTypeForDisplay[] = [];
  dataTypes$ = this.state.select('dataTypes');
  isCreatingForm?: boolean;
  isCreatingForm$ = this.state.select('isCreatingForm');
  isUpdatingFormField?: boolean;
  isUpdatingFormField$ = this.state.select('isUpdatingFormField');
  selectedFormField?: FormFields;
  selectedFormField$ = this.state.select('selectedFormField');
  isUpdatingDocumentForm?: boolean;
  isUpdatingDocumentForm$ = this.state.select('isUpdatingDocumentForm');

  templateFieldForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    placeholder: [''],
    dataType: ['' as DataType | undefined, Validators.required],
  });
  
  

  @HostListener('window:keydown.space', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const val = this.templateFieldForm.get(this.focusedInput)?.value;
    if (val === '' || val.trimEnd() !== val) event.preventDefault();
  }

  currentFieldValidationRules: FieldValidationRule[] = [];

  constructor(
    private fb: NonNullableFormBuilder,
    public state: RxState<FormFieldFormComponentState>,
    private templateFieldFacade: FormFieldsFacade,
    private documentFormFacade: DocumentFormFacade,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private dialogRef: MatDialogRef<FormFieldFormComponent>,
  ) {
    this.state.set(initialFormFieldFormComponentState);
    this.state.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
    this.state.connect(
      'isCreatingForm',
      this.documentFormFacade.isCreatingForm$,
    );
    this.state.connect(
      'isUpdatingFormField',
      this.templateFieldFacade.isUpdatingFormField$,
    );
    this.state.connect(
      'selectedFormField',
      this.templateFieldFacade.selectedFormField$,
    );
    this.state.connect(
      'isUpdatingDocumentForm',
      this.documentFormFacade.updateStatus$,
    );
    this.state.connect('dataTypes', this.templateFieldFacade.dataTypes$);

    this.selectedFormField$.subscribe((selectedFormField) => {
      this.selectedFormField = selectedFormField;
    });

    this.isUpdatingFormField$.subscribe((isUpdatingFormField) => {
      this.isUpdatingFormField = isUpdatingFormField;
    });
    if (this.isUpdatingFormField && this.selectedFormField) {
      this.createEditToggle.value = this.createEditToggle.update;

      this.templateFieldForm = this.fb.group({
        name: [this.selectedFormField.name, Validators.required],
        description: [this.selectedFormField.description],
        placeholder: [this.selectedFormField.placeholder],
        dataType: [
          this.selectedFormField.dataType as DataType | undefined,
          Validators.required,
        ],
      });
    }

    this.state.connect(
      'currentFieldValidationRules',
      this.fieldValidationRuleFacade.currentFieldValidationRules$,
    );
  }

  ngOnInit(): void {
    this.selectedDocumentForm$.subscribe(
      (selectedForm) => (this.selectedDocumentForm = selectedForm),
    );
    this.isCreatingForm$.subscribe(
      (isCreatingForm) => (this.isCreatingForm = isCreatingForm),
    );
    this.isUpdatingDocumentForm$.subscribe(
      (isUpdatingDocumentForm) =>
        (this.isUpdatingDocumentForm = isUpdatingDocumentForm),
    );
    this.templateFieldFacade.dispatchGetDataTypes();
    this.dataTypes$.subscribe((dataTypes) => (this.dataTypes = dataTypes));
    this.state.select('currentFieldValidationRules').subscribe((val) => {
      this.currentFieldValidationRules = val;
    });
  }

  handleSelectedDataType(){
    const test = this.dataTypes.find(dt=>{
     return dt.dataTypeEnum === this.templateFieldForm.controls.dataType.value?.toString();
    })?.dataTypeForDisplay;
    return test;
  }

  submit(event: Event) {
    const fieldDto: any = {
      id: this.selectedFormField?.id || undefined,
      name: this.templateFieldForm.getRawValue().name,
      description: this.templateFieldForm.getRawValue().description,
      placeholder: this.templateFieldForm.getRawValue().placeholder,
      dataType: this.templateFieldForm.getRawValue().dataType,
      documentTemplateId: this.selectedDocumentForm?.id,
      fieldValidationRules: this.currentFieldValidationRules.map((val) => {
        return {
          ValidationRuleValue: val.validationRuleValues,
          validationRuleId: val.validationRule.id,
        };
      }),
      order: this.selectedFormField?.order,
    };

    let dispatched;

    if (this.isCreatingForm || this.isUpdatingDocumentForm) {
      if (this.isUpdatingFormField) {
        dispatched =
          this.templateFieldFacade.dispatchUpdateFormField(fieldDto);
        this.dialogRef.close();
        if (fieldDto.id)
          dispatched.pipe(() =>
            this.fieldValidationRuleFacade.dispatchCreateFieldValidationRules(
              fieldDto.id,
            ),
          );
      } else {
        this.templateFieldFacade.dispatchAddFormField(fieldDto);
        this.templateFieldForm.reset();
      }
    } else {
      if (this.isUpdatingFormField) {
        dispatched =
          this.documentFormFacade.dispatchUpdateFieldOnFormDetail(
            fieldDto,
          );
        dispatched.subscribe(() => this.dialogRef.close());
      } else {
        dispatched =
          this.templateFieldFacade.dispatchCreateFormField(fieldDto);
        dispatched.subscribe(() => this.templateFieldForm.reset());
      }
    }
    event.preventDefault();
  }
}
