import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ResearchesHomeState,
  ResearchesHomeStateModel,
} from './researches-home.state';

export class ResearchesHomeSelector {
  static slices =
    createPropertySelectors<ResearchesHomeStateModel>(ResearchesHomeState);

  @Selector([ResearchesHomeState])
  static breadcrumbs(stateModel: ResearchesHomeStateModel) {
    return stateModel.breadcrumbs;
  }
}
