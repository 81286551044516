<mat-card class="main" appearance="outlined">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.quantity-types-list.quantity-types"
        >Quantity Types</mat-card-title
      >

      <button
        class="add-btn"
        mat-flat-button
        color="primary"
        (click)="addQuantityType()"
        *appHasPermission="hasCreateQuantityTypePermission()"
      >
        <mat-icon>add</mat-icon>
        <span i18n="@@researches.quantity-types-list.add" [attr.data-test-id]="'researches-create-quantity-type-quantity-types-list-addbutton'">Add</span>
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let item of quantityTypes">
        <div class="flex-container">
          <span>{{ item.name }}</span>
          <button *appHasPermission="hasCrudPermission()" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editQuantityType(item)" *appHasPermission="hasUpdateQuantityTypePermission()">
              <mat-icon>edit</mat-icon>
              <span i18n="@@researches.quantity-types-list.edit">Edit</span>
            </button>
            <button mat-menu-item (click)="deleteQuantityType(item)" *appHasPermission="hasRemoveQuantityTypePermission()">
              <mat-icon>delete</mat-icon>
              <span i18n="@@researches.quantity-types-list.delete">Delete</span>
            </button>
          </mat-menu>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
