import { Process } from './../../../models/process.model';
import {
  Criterion,
  UpdateCriterionDto,
} from './../../../models/criterion.model';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { Observable, filter, of, take } from 'rxjs';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import { Stage } from 'src/app/researches/models/stage.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { UpdateCriterion } from 'src/app/researches/store/stage.actions';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';

interface CriteriaFormComponentState {
  criteria: Criterion[];
  selectedCriterion: Criterion;
  selectedStage: Stage;
}

const initCriteriaFormComponentState: Partial<CriteriaFormComponentState> = {
  criteria: [],
  selectedCriterion: undefined,
};

@Component({
  selector: 'app-criteria-form',
  templateUrl: './criteria-form.component.html',
  styleUrls: ['./criteria-form.component.scss'],
})
export class CriteriaFormComponent implements OnInit {
  addedCriteria$: Observable<Criterion[]> = this.state.select('criteria');
  addedCriteria: Criterion[] | undefined;
  selectedStage$: Observable<Stage> = this.state.select('selectedStage');
  selectedStage: Stage | null = null;
  selectedCriterion$: Observable<Criterion> =
    this.state.select('selectedCriterion');
  selectedCriterion: Criterion | undefined;

  criteriaForm: FormGroup;
  isUpdate: boolean = false;
  usedNames = new Map();

  placeholderToggleLabel = {
      enterCriteronName: $localize`:@@researches.criteria-form.enter-criteron-name: Enter Criteron Name`,
      enterWeight: $localize`:@@researches.criteria-form.enter-weight: Enter Weight`,
  }

  constructor(
    private formBuilder: FormBuilder,
    private state: RxState<CriteriaFormComponentState>,
    private stageFacade: StageFacade,
    private dialog: MatDialog,
    public sanitizerService: SanitizerService,
  ) {
    this.state.set(initCriteriaFormComponentState);

    this.state.connect('criteria', this.stageFacade.criteria$);
    this.state.connect('selectedStage', this.stageFacade.selectedStage$);
    this.state.connect(
      'selectedCriterion',
      this.stageFacade.selectedCriterion$,
    );
    this.selectedCriterion$.subscribe((criterion) => {
      this.selectedCriterion = criterion;
    });

    this.criteriaForm = this.formBuilder.group({
      name: ['', [Validators.required], this.validName()],
      weight: [null, [Validators.required, this.nonZeroValidator]],
    });
  }

  nonZeroValidator(control: AbstractControl): ValidationErrors | null {
    if (control.touched || control.dirty) {
      return control.value > 0 ? null : { zeroWeight: true };
    }
    return null;
  }

  ngOnInit(): void {
    this.stageFacade.dispatchResetCriteria();
    this.addedCriteria$.subscribe((criteria) => {
      if (criteria !== null) {
        this.addedCriteria = criteria;
        this.usedNames = new Map();
        for (let criterion of this.addedCriteria) {
          this.usedNames.set(criterion.name, true);
        }
      }
    });
    this.selectedStage$
      .pipe(filter((stage) => stage !== null))
      .subscribe((stage) => {
        this.selectedStage = stage;
        if (stage.id && !stage.criteria)
          this.stageFacade.dispatchGetStageCriteria(stage.id);
        if (stage.criteria) {
          this.stageFacade.dispatchLoadCritera(stage.criteria);
        }
      });
  }

  validName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowedName = !this.usedNames.has(control.value);

      if (!allowedName) {
        return of({ forbidden: { value: control.value } });
      }
      return of(null);
    };
  }

  removeCriterion(item: Criterion) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.criteria-form.delete-criteria-message: Are you sure you want to delete criteria:` ,
        boldText: ` "${item.name}?" `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.stageFacade.dispatchRemoveCriterion(item);
      }
    });
    this.selectedCriterion$.subscribe((criterion) => {
      this.selectedCriterion = criterion;
    });
  }

  saveCriterion() {
    if (this.isUpdate == true) {
      const { name, weight } = this.criteriaForm.value;

      let criterion: UpdateCriterionDto = {
        id: this.selectedCriterion?.id as string,
        name: name === null ? this.selectedCriterion?.name! : (name as string),
        weight:
          weight === null
            ? this.selectedCriterion?.weight!
            : (weight as number),
      };
      this.criteriaForm.reset();
      this.isUpdate = false;
      if (this.selectedCriterion)
        return this.stageFacade.dispatchUpdateCriterion(criterion);
    }
  }

  getCriteriaSum(): number {
    let criteriaSum = 0;
    let stageCriteria = this.addedCriteria ?? [];

    for (const criterion of stageCriteria) {
      criteriaSum += criterion.weight;
    }

    return criteriaSum;
  }

  addCriterion() {
    const { valid, touched, dirty } = this.criteriaForm;
    if (this.criteriaForm.valid && (this.criteriaForm.touched || this.criteriaForm.dirty)) {
      const { name, weight } = this.criteriaForm.value;
      let criterion: Criterion = {
        name,
        weight,
        stage: this.selectedStage ?? null,
      };
      this.stageFacade.dispatchAddCriterion(criterion);
      this.stageFacade.dispatchResetSelectedCriterion();
      this.criteriaForm.reset();
    }else{
      this.criteriaForm.markAllAsTouched();
    }
  }

  editCriterion(criterion: Criterion) {
    this.stageFacade.dispatchSetSelectedCriterion(criterion);
    this.isUpdate = true;
    if (this.selectedCriterion) {
      this.criteriaForm.setValue({
        name: this.selectedCriterion.name,
        weight: this.selectedCriterion.weight,
      });
    }
  }
}
