<mat-drawer-container autosize [hasBackdrop]="false" *appHasPermission="hasGetTorDetailPermission()">
  <div class="row">
    <button
      class="ml-btn"
      mat-raised-button
      color="primary"
      (click)="downloadPdf()"
      *appHasPermission="hasDownloadTorPdfPermission()"
      i18n="@@researches.tor-detail.download"
    >
      Download
    </button>
    <ng-container *appHasPermission="hasSubmitTorPermission()">
    <button
      class="ml-btn"
      mat-raised-button
      color="primary"
      (click)="submitTor()"
      [disabled]="!isOnFirstStep"
      i18n="@@researches.tor-detail.submit"
      *ngIf="isOnFirstStep && selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id"
    >
      Submit
    </button>
  </ng-container>
  </div>
  <mat-stepper class="tor_stepper">
    <mat-step
      [stepControl]="firstFormGroup"
      [completed]="!isOnFirstStep"
      *ngIf="isOnFirstStep"
    >
      <ng-template i18n="@@researches.tor-detail.initiate-tor" matStepLabel
        >Initiate Tor</ng-template
      >
      <div class="text tor-status-color">
        {{ selectedTorDetail?.statusForDisplay?.status }}
      </div>
      <mat-card class="tor-mat-card">
        <mat-card-header />
        <mat-card-content class="tor-info">
          <mat-icon id="info-icon-tor">info</mat-icon>

          <div class="tor_info">
            <p>
              {{ selectedTorDetail?.stageInstanceName }}
            </p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInStage }}
              <span i18n="@@researches.tor-detail.tors">Tors</span>
            </p>
            <p>
              {{ selectedTorDetail?.numberOfApprovedTorsInStage }}
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>
            <p>{{ selectedTorDetail?.processInstanceName }}</p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInProcess }}
              <span i18n="@@researches.tor-detail.tors">Tors</span>
            </p>
            <p>
              {{ selectedTorDetail?.numberOfApprovedTorsInProcess }}
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>
          </div>
        </mat-card-content>
      </mat-card>

      <h1 class="mat-h1">{{ selectedTorDetail?.title }}</h1>
      <p class="mat-body">{{ torDescription?.description }}</p>
      <div flex class="full-width">
        <form [formGroup]="torForm" class="full-width">
          <div class="tor-description-fields full-width">
            <div class="full-width-form">
              <mat-form-field appearance="outline" class="form-field">
                <mat-label i18n="@@researches.tor-detail.description"
                [attr.data-test-id]="'researches.option-cost-breakdown.cost-breakdown-list.update-cost-breakdown-description'"
                  >Description</mat-label
                >
                <textarea
                  matInput
                  formControlName="description"
                  name="description"
                  [readonly]="show"
                ></textarea>
                <mat-error
                  *ngIf="torForm.get('description')?.hasError('noNull')"
                  i18n="@@researches.tor-detail.please-provide-a-description"
                  >Please provide a description</mat-error
                >
              </mat-form-field>
            </div>
            <ng-container *appHasPermission="hasUpdateTorPermission()">
              <button
                mat-icon-button
                (click)="toggleShowButton()"
                *ngIf="
                  show &&
                  selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id
                "
                class="tor-description-icons"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  fontIcon="edit"
                  class="edit tor-description-icons"
                  *ngIf="isOnFirstStep"
                ></mat-icon>
              </button>
            </ng-container>
            <div
              *ngIf="
                !show &&
                selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id
              "
              class="save"
            >
              <button mat-icon-button (click)="editDescription()">
                <mat-icon
                  class="tor-description-icons"
                  id="tor-done"
                  *appHasPermission="hasUpdateTorPermission()"
                  >done</mat-icon
                >
              </button>
              <button
                mat-icon-button
                (click)="cancelDescription()"
                *appHasPermission="hasUpdateTorPermission()"
              >
                <mat-icon class="tor-description-icons" id="tor-close"
                  >close</mat-icon
                >
              </button>
            </div>
          </div>
          <br />
        </form>
        <ng-container class="buttons">
          <div id="torAddNewBtn">
            <button
              mat-raised-button
              (click)="drawer.toggle()"
              class="comment-btn"
              *appHasPermission="hasGetTorCommentsPermission()"
              [attr.data-test-id]="'researches.comment-tor.comment-tor-button.comment-tor'"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="chat"
              ></mat-icon>
              <span i18n="@@researches.tor-detail.comments">Comments</span>
            </button>

            <div
              *ngIf="
                selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id
              "
            >
              <button
                mat-raised-button
                color="primary"
                [disabled]="isAddNewButtonDisabled()"
                (click)="addNewCostBreakdownItem()"
                *appHasPermission="hasCreateCostBreakdownItemPermission()"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  fontIcon="add"
                ></mat-icon>
                <span i18n="@@researches.tor-detail.add-new">Add New</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="full-width">
        <div class="flex space-between">
          <h2 class="mat-h2" i18n="@@researches.tor-detail.cost-breakdowns">
            Cost Breakdowns
          </h2>
          <mat-paginator
            [length]="costBreakdownItems?.totalCount"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [showFirstLastButtons]="true"
            (page)="loadPaginatedCostBreakdownItems($event)"
          >
          </mat-paginator>
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <mat-table [dataSource]="dataSource" class="full-width">
              <ng-container matColumnDef="No">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.row-number"
                >
                  No
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-type"
                >
                  Activity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-name"
                >
                  Activity Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity"
                >
                  Quantity
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity type">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity-type"
                >
                  Quantity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unit cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.unit-cost"
                >
                  Unit Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.unitCost !== null && element?.unitCost !== undefined ? element.unitCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="total cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.total-cost"
                >
                  Total Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.totalCost !== null && element?.totalCost !== undefined ? element.totalCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="remark">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.remark"
                >
                  Remark
                </th>
                <td mat-cell *matCellDef="let element">{{ element.remark }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.actions"
                >
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <button
                   *appHasPermission="hasCrudPermission()"
                    mat-icon-button
                    [matMenuTriggerFor]="tormenu"
                    (click)="setSelectedCostBreakdownItem($event, element)"
                    [attr.data-test-id]="'researches.option-cost-breakdown.cost-breakdown-list.update-cost-breakdown'"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns;
                  let odd = odd;
                  let even = even
                "
                class="mat-row"
                [ngClass]="{ odd: odd, even: even }"
              ></tr>
            </mat-table>
          </mat-card-content>
        </mat-card>
        <mat-menu #tormenu="matMenu">
          <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
          <button
            mat-menu-item
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="updateCostBreakdownItem()"
            *appHasPermission="hasUpdateCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.edit-cost-breakdown"
          >
            Edit Cost Breakdown
          </button>
          <button
            mat-menu-item
            color="warn"
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="deleteCostBreakdownItem()"
            *appHasPermission="hasDeleteCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.delete-cost-breakdown"
          >
            Delete Cost Breakdown
          </button>
        </ng-container>
        </mat-menu>
      </div>
      <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
      <button
        mat-stroked-button
        color="warn"
        (click)="openConfirmationDialog()"
        *appHasPermission="hasDeleteTorPermission()"
      >
        <span i18n="@@researches.tor-detail.delete-tor">Delete TOR</span>
        <mat-icon>delete</mat-icon>
      </button>
      </ng-container>
    </mat-step>
    <mat-step
      [stepControl]="secondFormGroup"
      class="approved-one"
      [completed]="isOnThirdStep"
      *ngIf="isOnSecondStep"
    >
      <ng-template matStepLabel i18n="@@researches.tor-detail.approved-by"
        >Approved By</ng-template
      >
      <div>{{ selectedTorDetail?.firstApproverUserName }}</div>
      <div class="text tor-status-color">{{ selectedTorDetail?.status }}</div>
      <mat-card class="tor-mat-card">
        <mat-card-header />
        <mat-card-content class="tor-info">
          <mat-icon id="info-icon-tor">info</mat-icon>

          <div class="tor_info">
            <p>{{ selectedTorDetail?.stageInstanceName }}</p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInStage }}
              <span i18n="@@researches.tor-detail.tors">Tors : </span>
              {{ selectedTorDetail?.numberOfApprovedTorsInStage }}
            </p>
            <p>
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>

            <p>{{ selectedTorDetail?.processInstanceName }}</p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInProcess }}
              <span i18n="@@researches.tor-detail.tors">Tors : </span>
            </p>
            <p>
              {{ selectedTorDetail?.numberOfApprovedTorsInProcess }}
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>
          </div>
        </mat-card-content>
      </mat-card>

      <h1 class="mat-h1">{{ selectedTorDetail?.title }}</h1>
      <p class="mat-body">{{ torDescription?.description }}</p>
      <div>
        <mat-form-field appearance="outline" class="form-field">
          <mat-label i18n="@@researches.tor-detail.description"
            >Description</mat-label
          >
          <textarea
            matInput
            formControlName="description"
            name="description"
            [readonly]="show"
          >{{ selectedTorDetail?.description }}</textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <button
          class="ml-btn"
          mat-raised-button
          color="primary"
          (click)="approveStepTwo()"
          [disabled]="!isOnSecondStep"
          i18n="@@researches.tor-detail.approve"
          *appHasPermission="
            hasChangeTorStatusToSubmittedToSecondApproverPermission()
          "
        >
          Approve
        </button>
        <button
          class="ml-btn"
          mat-raised-button
          color="warn"
          (click)="rejectStepTwo()"
          [disabled]="!isOnSecondStep"
          i18n="@@researches.tor-detail.reject"
          *appHasPermission="
            hasChangeTorStatusToDeclinedByFirstApproverPermission()
          "
        >
          Decline
        </button>
      </div>
      <div flex>
        <ng-container class="buttons">
          <button
            mat-raised-button
            (click)="drawer.toggle()"
            class="comment-btn"
            *appHasPermission="hasGetTorCommentsPermission()"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              fontIcon="chat"
            ></mat-icon>
            <span i18n="@@researches.tor-detail.comments">Comments</span>
          </button>
        </ng-container>
      </div>
      <div
        class="full-width"
        *appHasPermission="hasGetCostBreakdownItemsPermission()"
      >
        <div class="flex space-between">
          <h2 class="mat-h2" i18n="@@researches.tor-detail.cost-breakdowns">
            Cost Breakdowns
          </h2>
          <mat-paginator
            [length]="costBreakdownItems?.totalCount"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [showFirstLastButtons]="true"
            (page)="loadPaginatedCostBreakdownItems($event)"
          >
          </mat-paginator>
          <div
            *ngIf="
              selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id
            "
          >
            <button
              class="add"
              mat-raised-button
              color="primary"
              [disabled]="isAddNewButtonDisabled()"
              (click)="addNewCostBreakdownItem()"
              *appHasPermission="hasCreateCostBreakdownItemPermission()"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="add"
              ></mat-icon>
              <span i18n="@@researches.tor-detail.add-new">Add New</span>
            </button>
          </div>
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <mat-table [dataSource]="dataSource" class="full-width">
              <ng-container matColumnDef="No">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.row-number"
                >
                  No
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-type"
                >
                  Activity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-name"
                >
                  Activity Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity"
                >
                  Quantity
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity type">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity-type"
                >
                  Quantity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unit cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.unit-cost"
                >
                  Unit Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.unitCost !== null && element?.unitCost !== undefined ? element.unitCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="total cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.total-cost"
                >
                  Total Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.totalCost !== null && element?.totalCost !== undefined ? element.totalCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="remark">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.remark"
                >
                  Remark
                </th>
                <td mat-cell *matCellDef="let element">{{ element.remark }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.actions"
                >
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <button
                    *appHasPermission="hasCrudPermission()"
                    mat-icon-button
                    [matMenuTriggerFor]="tormenu"
                    (click)="setSelectedCostBreakdownItem($event, element)"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns;
                  let odd = odd;
                  let even = even
                "
                class="mat-row"
                [ngClass]="{ odd: odd, even: even }"
              ></tr>
            </mat-table>
          </mat-card-content>
        </mat-card>
        <mat-menu #tormenu="matMenu">
          <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
          <button
            mat-menu-item
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="updateCostBreakdownItem()"
            *appHasPermission="hasUpdateCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.edit-cost-breakdown"
          >
            Edit Cost Breakdown
          </button>
          <button
            mat-menu-item
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="deleteCostBreakdownItem()"
            *appHasPermission="hasDeleteCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.delete-cost-breakdown"
          >
            Delete Cost Breakdown
          </button>
        </ng-container>
        </mat-menu>
      </div>
      <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
      <button
        mat-stroked-button
        color="warn"
        (click)="openConfirmationDialog()"
        *appHasPermission="hasDeleteTorPermission()"
      >
        <span i18n="@@researches.tor-detail.delete-tor">Delete TOR</span>
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>
    </mat-step>
    <mat-step
      [stepControl]="thirdFormGroup"
      class="approved-two"
      [completed]="isOnThirdStep"
      *ngIf="!isOnFirstStep && !isOnSecondStep"
    >
      <ng-template matStepLabel i18n="@@researches.tor-detail.approved-by"
        >Approved By</ng-template
      >
      <div>{{ selectedTorDetail?.lastApproverUserName }}</div>
      <div class="text tor-status-color">{{ selectedTorDetail?.status }}</div>

      <mat-card class="tor-mat-card">
        <mat-card-header />
        <mat-card-content class="tor-info">
          <mat-icon id="info-icon-tor">info</mat-icon>
          <div class="tor_info">
            <p>{{ selectedTorDetail?.stageInstanceName }}</p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInStage }}
              <span i18n="@@researches.tor-detail.tors">Tors</span>
            </p>
            <p>
              {{ selectedTorDetail?.numberOfApprovedTorsInStage }}
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>
            <p>{{ selectedTorDetail?.processInstanceName }}</p>
            <p>
              {{ selectedTorDetail?.numberOfTorsInProcess }}
              <span i18n="@@researches.tor-detail.tors">Tors</span>
            </p>
            <p>
              {{ selectedTorDetail?.numberOfApprovedTorsInProcess }}
              <span i18n="@@researches.tor-detail.approved">Approved</span>
            </p>
          </div>
        </mat-card-content>
      </mat-card>

      <h1 class="mat-h1">{{ selectedTorDetail?.title }}</h1>
      <p class="mat-body">{{ torDescription?.description }}</p>
      <div>
        <mat-form-field appearance="outline" class="form-field">
          <mat-label i18n="@@researches.tor-detail.description"
            >Description</mat-label
          >
          <textarea
            matInput
            formControlName="description"
            name="description"
            [readonly]="show"
          >{{ selectedTorDetail?.description }}</textarea>
        </mat-form-field>
      </div>
      <div class="row">
        <button
          class="ml-btn"
          mat-raised-button
          color="primary"
          (click)="approveStepThree()"
          [disabled]="!isOnThirdStep"
          *appHasPermission="hasChangeTorStatusToApprovedPermission()"
          i18n="@@researches.tor-detail.approve"
        >
          Approve
        </button>
        <button
          class="ml-btn"
          mat-raised-button
          color="warn"
          (click)="rejectStepThree()"
          [disabled]="!isOnThirdStep"
          *appHasPermission="
            hasChangeTorStatusToDeclinedBySecondApproverPermission()
          "
          i18n="@@researches.tor-detail.reject"
        >
          Reject
        </button>
      </div>
      <div flex>
        <ng-container class="buttons">
          <button
            mat-raised-button
            (click)="drawer.toggle()"
            class="comment-btn"
            *appHasPermission="hasGetTorCommentsPermission()"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              fontIcon="chat"
            ></mat-icon>
            <span i18n="@@researches.tor-detail.comments">Comments</span>
          </button>
        </ng-container>
      </div>
      <div
        class="full-width"
        *appHasPermission="hasGetCostBreakdownItemsPermission()"
      >
        <div class="flex space-between">
          <h1 class="mat-h2" i18n="@@researches.tor-detail.cost-breakdowns">
            Cost Breakdowns
          </h1>
          <mat-paginator
            [length]="costBreakdownItems?.totalCount"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [showFirstLastButtons]="true"
            (page)="loadPaginatedCostBreakdownItems($event)"
          >
          </mat-paginator>
          <div
            *ngIf="
              selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id
            "
          >
            <button
              class="add"
              mat-raised-button
              color="primary"
              [disabled]="isAddNewButtonDisabled()"
              (click)="addNewCostBreakdownItem()"
              *appHasPermission="hasCreateCostBreakdownItemPermission()"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                fontIcon="add"
              ></mat-icon>
              <span i18n="@@researches.tor-detail.add-new">Add New</span>
            </button>
          </div>
        </div>
        <mat-card appearance="outlined">
          <mat-card-content>
            <mat-table [dataSource]="dataSource" class="full-width">
              <ng-container matColumnDef="No">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.row-number"
                >
                  No
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-type"
                >
                  Activity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="activity name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.activity-name"
                >
                  Activity Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.activity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity"
                >
                  Quantity
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity type">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.quantity-type"
                >
                  Quantity Type
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantityType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unit cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.unit-cost"
                >
                  Unit Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.unitCost !== null && element?.unitCost !== undefined ? element.unitCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="total cost">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.total-cost"
                >
                  Total Cost
                </th>
                <td mat-cell *matCellDef="let element">
                  {{
                    (element?.currency ? element.currency + ' ' : '') +
                    (element?.totalCost !== null && element?.totalCost !== undefined ? element.totalCost.toString() : '')
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="remark">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.remark"
                >
                  Remark
                </th>
                <td mat-cell *matCellDef="let element">{{ element.remark }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.tor-detail.actions"
                >
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <button
                  *appHasPermission="hasCrudPermission()"
                    mat-icon-button
                    [matMenuTriggerFor]="tormenu"
                    (click)="setSelectedCostBreakdownItem($event, element)"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumns;
                  let odd = odd;
                  let even = even
                "
                class="mat-row"
                [ngClass]="{ odd: odd, even: even }"
              ></tr>
            </mat-table>
          </mat-card-content>
        </mat-card>
        <mat-menu #tormenu="matMenu">
          <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
          <button
            mat-menu-item
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="updateCostBreakdownItem()"
            *appHasPermission="hasUpdateCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.edit-cost-breakdown"
          >
            Edit Cost Breakdown
          </button>
          <button
            mat-menu-item
            color="warn"
            [disabled]="isEditAndDeleteButtonDisabled()"
            (click)="deleteCostBreakdownItem()"
            *appHasPermission="hasDeleteCostBreakdownItemPermission()"
            i18n="@@researches.tor-detail.delete-cost-breakdown"
          >
            Delete Cost Breakdown
          </button>
        </ng-container>
        </mat-menu>
      </div>
      <ng-container *ngIf="selectedTorprocessInstanceOwnerId === currentLoggedInUser?.id">
      <button
        mat-stroked-button
        color="warn"
        (click)="openConfirmationDialog()"
        *appHasPermission="hasDeleteTorPermission()"
      >
        <span i18n="@@researches.tor-detail.delete-tor">Delete TOR</span>
        <mat-icon>delete</mat-icon>
      </button>
      </ng-container>
    </mat-step>
  </mat-stepper>
  <mat-drawer #drawer class="comment-sidenav" mode="over" position="end">
    <app-tor-comments class="tor-comments" (closeDrawer)="closeDrawer()">
    </app-tor-comments>
  </mat-drawer>
</mat-drawer-container>
