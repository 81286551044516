import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { ASSIGNED_REVIEWS_ROUTE } from 'src/app/core/constants/routes';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { StageInstanceFacade } from 'src/app/researches/facades/stage-instance.facade';
import { StageInstanceByReviewers } from 'src/app/researches/models/stage-instance-detail.model';

interface RequestedReviewsComponentState {
  assignedStageInstances: PaginatedList<StageInstanceByReviewers> | undefined;
}
const initRequestedReviewsComponentState: RequestedReviewsComponentState = {
  assignedStageInstances: undefined,
};
@Component({
  selector: 'app-requested-reviews',
  templateUrl: './requested-reviews.component.html',
  styleUrls: ['./requested-reviews.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: RequestedReviewsComponent}],
})
export class RequestedReviewsComponent implements MatPaginatorIntl {
  assignedStageInstances$ = this.state.select('assignedStageInstances');
  assignedStageInstances: PaginatedList<StageInstanceByReviewers> | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  assignedreviews: any;
  recievedreviews: any;

  firstPageLabel = $localize`:@@researches.requested-reviews.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.requested-reviews.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.requested-reviews.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.requested-reviews.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.requested-reviews.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.requested-reviews.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.requested-reviews.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.requested-reviews.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<RequestedReviewsComponentState>,
    private stageInstanceFacade: StageInstanceFacade,
    private router: Router,
  ) {
    this.state.set(initRequestedReviewsComponentState);
    this.state.connect(
      'assignedStageInstances',
      this.stageInstanceFacade.assignedStageInstances$,
    );
  }

  changes = new Subject<void>();

  ngOnInit() {
    this.stageInstanceFacade.dispatchGetAssignedStageInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.assignedStageInstances$.subscribe((stageInstances) => {
      if (stageInstances) {
        this.assignedStageInstances = stageInstances;
      }
    });
    this.assignedreviews= $localize`:@@researches.requested-reviews.assigned-reviews:Assigned Reviews`;
    this.recievedreviews= $localize`:@@researches.requested-reviews.recieved-reviews:Recieved Reviews`;
  }
  loadPaginatedStageInstances(event: PageEvent) {
    this.stageInstanceFacade.dispatchGetAssignedStageInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
  }

  onRowClick(event: MouseEvent, StageInstance: StageInstanceByReviewers): void {
    this.stageInstanceFacade.dispatchSetSelectedAssignedStageInstance(
      StageInstance,
    );
    this.stageInstanceFacade.dispatchSetMyStageInstanceReviewNull();
    this.stageInstanceFacade.dispatchGetMyStageInstanceReview(StageInstance.id);
    this.router.navigate([
      `${ASSIGNED_REVIEWS_ROUTE}/stage-instances`,
      StageInstance.id,
    ]);
  }

  hasGetStageInstancesByReviewersPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetStageInstancesByReviewers,
    );
  }
}
