import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { ActivatedRoute, Router } from '@angular/router';
import Quill from 'quill';
import { Notice } from '../../models/notices.model';
import { NoticesFacade } from '../../facade/notices.facade';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import {
  NOTICES_ROUTE,
  NOTICES_UPDATE_ROUTE,
} from 'src/app/core/constants/routes';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface NoticeDetailState {
  selectedNotice: Notice | undefined;
}

const initNoticeDetailState: NoticeDetailState = {
  selectedNotice: undefined,
};

@Component({
  selector: 'app-notices-list',
  templateUrl: './notices-detail.component.html',
  styleUrls: ['./notices-detail.component.scss'],
  providers: [RxState],
})
export class NoticeDetailComponent implements OnInit {
  selectedNotice$ = this.state.select('selectedNotice');
  selectedNotice: Notice | undefined;
  editor?: Quill;
  initialEditorContent: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private state: RxState<NoticeDetailState>,
    private noticesFacade: NoticesFacade,
    private officeFacade: OfficeFacade,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.state.set(initNoticeDetailState);
    this.state.connect('selectedNotice', this.noticesFacade.selectedNotice$);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.noticesFacade.dispatchGetNoticeDetial(params['id']);
    });

    this.editor = new Quill('#quill-container', {
      theme: 'bubble',
      readOnly: true,
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });

    this.selectedNotice$.subscribe((notice) => {
      if (notice) {
        this.selectedNotice = notice;
        this.initialEditorContent = notice.body;
        if (this.editor && this.initialEditorContent) {
          this.editor.setContents(JSON.parse(this.initialEditorContent), 'api');
          this.editor.disable();
        }
      }
    });
  }
  deleteNotice() {
    if (!this.selectedNotice?.id) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.notices-detail.delete-notice-part-1:Are you sure you want to delete the` ,
        boldText: ` "${this.selectedNotice?.title}" ` ,
        regularTextTwo: $localize`:@@documents.notices-detail.delete-notice-part-2: Notice?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedNotice?.id)
          this.noticesFacade.dispatchDeleteNotice(this.selectedNotice?.id);
        this.router.navigate([NOTICES_ROUTE]);
      }
    });
  }
  deleteNoticeAttachment(attachmentId: string | undefined) {
    if (!this.selectedNotice?.id) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.notices-detail.delete-attachment:Are you sure you want to delete the attachment?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedNotice?.id && attachmentId)
          this.noticesFacade.dispatchDeleteNoticeAttachment(
            this.selectedNotice?.id,
            attachmentId,
          );
      }
    });
  }
  updateNotice() {
    if (!this.selectedNotice?.id) return;
    this.officeFacade.dispatchResetSelectedOffices();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.noticesFacade.dispatchGetNoticeDetial(this.selectedNotice.id);
    this.noticesFacade.dispatchGetNoticeAccessData(this.selectedNotice.id);
    this.noticesFacade.dispatchSetUpdateStatus(true);
    this.router.navigate([NOTICES_UPDATE_ROUTE]);
  }

  downloadAttachment(attachment: any) {
    this.noticesFacade.dispatchDownloadAttachment(
      this.selectedNotice?.id as string,
      attachment.id,
      attachment.name,
    );
  }
  previewAttachment(attachment: any) {
    const fileExtension = attachment.name.split('.').pop()?.toLowerCase();

    if (fileExtension !== 'pdf') {
      this.snackBar.open('File type not supported to preview.', 'Close', {
        duration: 3000, 
      });
      return;
    }

    this.noticesFacade.dispatchPreviewAttachment(
      this.selectedNotice?.id as string,
      attachment.id,
      attachment.name,
    );
  }
  hasUpdateNoticePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.UpdateNotice,
    );
  }
  hasDeleteNoticePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.DeleteNotice,
    );
  }

  hasGetNoticesAttachmentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.GetNoticeDetail,
    );
  }
}
