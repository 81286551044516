import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { StageInstanceDetailSelector } from '../store/stage-instance-detail.selector';
import {
  ApproveStageInstance,
  ChangeStageStatus,
  GetStageEvaluation,
  CheckEveryTasksApproval,
  GetStageInstanceDetail,
  GetStageInstanceTasks,
  RejectStageInstance,
  GetStageInstanceEvaluationStatus,
  GetTasksByTaskType,
  DeleteTaskFromStageInstanceDetail,
  UpdateTask,
  SetIsEveryTaskApprovedStatus,
  GetUserStageEvaluation,
  UpdateStageEvaluation,
  ResetStageInstanceDetail,
  SetEvaluationUpdateMode,
  ToggleIsMajorTask,
  CheckIfMajorTaskFound,
  DownloadStage,
} from '../store/stage-instance-detail.actions';
import { Observable } from 'rxjs';
import {
  StageInstanceDetail,
  StageInstanceEvaluation,
  StageInstanceEvaluationStatus,
  StageInstanceStatus,
  StageInstanceTask,
  UpdateStageStatusDto,
  UserStageInstanceEvaluation,
} from '../models/stage-instance-detail.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Task } from '../models/task.model';
import {
  CriterionEvaluation,
  UpdateCriterionEvaluation,
} from '../models/criterion.model';
import { StageStatus } from '../models/stage.model';

@Injectable({
  providedIn: 'root',
})
export class StageInstanceDetailFacade {
  constructor(private store: Store) {}

  @Select(StageInstanceDetailSelector.stageInstanceDetail)
  selectedStageInstanceDetail$!: Observable<StageInstanceDetail>;

  @Select(StageInstanceDetailSelector.isStageEvaluauted)
  isStageEvaluated$!: Observable<Boolean>;

  @Select(StageInstanceDetailSelector.stageInstanceTasks)
  stageInstanceTasks$!: Observable<PaginatedList<StageInstanceTask>>;

  @Select(StageInstanceDetailSelector.slices.stageInstanceEvaluations)
  StageInstanceEvaluations$!: Observable<StageInstanceEvaluation>;

  @Select(StageInstanceDetailSelector.slices.userStageEvalutions)
  userStageEvalutions$!: Observable<UserStageInstanceEvaluation>;

  @Select(StageInstanceDetailSelector.slices.evaluationStatus)
  StageInstanceEvaluationStatus$!: Observable<StageInstanceEvaluationStatus>;

  @Select(StageInstanceDetailSelector.slices.isUpdatingEvaluation)
  isUpdatingEvaluation$!: Observable<boolean>;

  @Select(StageInstanceDetailSelector.slices.isMajorTaskFound)
  isMajorTaskFound$!: Observable<boolean>;

  dispatchGetStageEvaluation(stageId: string) {
    return this.store.dispatch(new GetStageEvaluation(stageId));
  }

  dispatchUpdateStageEvaluation(
    stageInstanceId: string,
    evalutions: UpdateCriterionEvaluation[],
  ) {
    return this.store.dispatch(
      new UpdateStageEvaluation(stageInstanceId, evalutions),
    );
  }
  @Select(StageInstanceDetailSelector.isEveryTaskApproved)
  isEveryTaskApproved$!: Observable<boolean>;

  dispatchGetStageInstanceDetail(id: string) {
    this.store.dispatch(new GetStageInstanceDetail(id));
  }

  dispatchDeleteTaskFromStageInstanceDetail(taskId: string) {
    this.store.dispatch(new DeleteTaskFromStageInstanceDetail(taskId));
  }

  dispatchGetUserStageEvalutions(stageId: string) {
    this.store.dispatch(new GetUserStageEvaluation(stageId));
  }
  dispatchUpdateTask(task: Task) {
    this.store.dispatch(new UpdateTask(task));
  }

  dispatchGetStageInstanceTasks(
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetStageInstanceTasks(stageInstanceId, pageNumber, pageSize),
    );
  }

  dispatchApproveStageInstance(stageInstanceId: string) {
    this.store.dispatch(new ApproveStageInstance(stageInstanceId));
  }

  dispatchChangeStageStatus(updatedStage: UpdateStageStatusDto) {
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToSubmittedForApproval(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.WaitingForApproval
    }

    this.store.dispatch(new ChangeStageStatus(updatedStage ));
  }

  dispatchChangeStageStatusToInprogress(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.Inprogress
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToSubmittedForEvaluation(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.Approved
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToWaitingForApproval(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.WaitingForApproval
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToApproved(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.Approved
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToNeedsRevision(id: string, remark: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.NeedsRevision,
      remark: remark
    }
    return this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToRejected(id: string, remark: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.Rejected,
      remark: remark
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchChangeStageStatusToTerminated(id: string) {
    const updatedStage = {
      id: id,
      stageStatus: StageInstanceStatus.Terminated
    }
    this.store.dispatch(new ChangeStageStatus(updatedStage));
  }

  dispatchRejectStageInstance(stageInstanceId: string) {
    this.store.dispatch(new RejectStageInstance(stageInstanceId));
  }

  dispatchCheckEveryTasksApproval(stageInstanceId: string) {
    this.store.dispatch(new CheckEveryTasksApproval(stageInstanceId));
  }

  dispatchGetStageInstanceEvaluationStatus(stageInstanceId: string) {
    this.store.dispatch(new GetStageInstanceEvaluationStatus(stageInstanceId));
  }

  dispatchGetTasksByTaskType(
    taskTypeId: string,
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetTasksByTaskType(taskTypeId, stageInstanceId, pageNumber, pageSize),
    );
  }

  dispatchDownloadStage(stageInstanceId: string) {
    this.store.dispatch(new DownloadStage(stageInstanceId));
  }

  dispatchSetIsEveryTaskApprovedStatus(isEveryTaskApproved: boolean) {
    this.store.dispatch(new SetIsEveryTaskApprovedStatus(isEveryTaskApproved));
  }

  dispatchResetStageInstanceDetail() {
    this.store.dispatch(new ResetStageInstanceDetail());
  }

  dispatchSetEvaluationUpdateMode(isUpdatingEvaluation: boolean) {
    this.store.dispatch(new SetEvaluationUpdateMode(isUpdatingEvaluation));
  }

  dispatchToggleIsMajorTask(id: string, stageInstanceId: string){
    this.store.dispatch(new ToggleIsMajorTask(id, stageInstanceId));
  }
  dispatchCheckIfMajorTaskFound(stageInstanceId: string){
    this.store.dispatch(new CheckIfMajorTaskFound(stageInstanceId));
  }
}
