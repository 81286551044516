<mat-dialog-content mat-dialog-content class="form-container">
  <form class="quantity-form" [formGroup]="quantityForm" (ngSubmit)="save()">
    <h3>{{ formTitle }}</h3>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.configure-quantity-type.quantity-type-name">Quantity Type Name: </mat-label>
      <input
        type="text"
        id="name"
        formControlName="name"
        matInput
        (keydown)="sanitizerService.spaceSanitizer($event, quantityForm.get('name')?.value ?? '')"
        class="full-width-input"
      />
      <mat-error *ngIf="quantityForm.get('name')!.hasError('required')"
      i18n="@@researches.configure-quantity-type.please-enter-quantity-type-name">
        Please enter quantity type name
      </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="action-btn">
      <button mat-button mat-dialog-close i18n="@@researches.configure-quantity-type.cancel">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="!quantityForm.valid" [attr.data-test-id]="'researches-create-quantity-type-configure-quantity-type-createbutton'">
        {{ saveButtonText }}
      </button>
    </div>
  </form>
</mat-dialog-content>
