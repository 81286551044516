import { User } from 'src/app/users/models/user.model';
import { Criterion } from './criterion.model';
import { Process } from './process.model';
import { Stage } from './stage.model';
import { ATaskStatusForDisplay, Task, TaskStatus, TaskType, TaskVisibility } from './task.model';
import { ProcessInstanceDetail, StageInstance } from './process-instance.model';
import { Schedule } from '../research-council/models/schedule.model';
import { Reviewer } from '../research-council/models/reviewer.model';

export interface StageInstanceDetail {
  id: string;
  name: string;
  description: String;
  evaluations: Evaluation[];
  processInstance: ProcessInstance;
  stage: Stage;
  stageStatus: StageInstanceStatus;
  needsRevisionComment?: string;
  criterionInstances: Criterion[]
}

export interface Evaluation {
  criterion: Criterion[];
}

export interface ProcessInstance {
  process: Process;
  id: string;
  description: string;
}

export interface StageInstanceTask {
  id: string;
  name: string;
  description: string;
  assigneeName: string;
  assignee?: User;
  assignedTo: string;
  startDate: Date;
  endDate: Date;
  taskStatus: TaskStatus;
  taskStatusForDisplay?: ATaskStatusForDisplay;
  taskType?: TaskType;
  stageInstanceId: string;
  hasDocument?: boolean;
  hasFile?: boolean;
  createdBy?: string;
  isMajorTask: boolean;
}

export enum StageInstanceStatus {
  ReadyToStart = 'ReadyToStart',
  Inprogress = 'Inprogress',
  WaitingForApproval = 'WaitingForApproval',
  NeedsRevision = 'NeedsRevision',
  Approved = 'Approved',
  SubmittedToEvaluation = 'SubmittedToEvaluation',
  Rejected = 'Rejected',
  Terminated = 'Terminated',
}

export interface UpdateStageStatusDto {
  id?: string | null;
  stageStatus: StageInstanceStatus;
  remark?: string;
}

export interface StageInstanceEvaluation {
  totalResult: number;
  evaluations: { name: string; weight: number; value: number }[];
}
export interface UserStageInstanceEvaluation {
  evaluations: { id: string; name: string; weight: number; value: number }[];
}

export enum StageInstanceEvaluationStatus {
  Invalid = 'Invalid',
  Passed = 'Passed',
  Failed = 'Failed',
}

export interface StageInstanceByReviewers {
  id: string;
  name: string;
  processInstance: ProcessInstanceDetail;
  evaluationSchedule: Schedule;
  isReviewed: boolean;
}

export interface ReviewAttachment{
  id:string,
  name:string,
  stageInstanceId:string,
  createdAt:string,
  createdBy:string,    
}

export interface StageInstanceReview {
  id?: string;
  documentContent?: any;
  reviewStatus: ReviewStatus;
  attachments?: ReviewAttachment[];
  reviewedAt?: Date;
}

export enum ReviewStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
}

export interface MyStageInstanceReview {
  stageInstanceReview?: StageInstanceReview;
}

export interface StagesWithoutEvaluationSchedule {
  id?: string;
  title: string;
  stageInstances: StageInstance[];
}

export interface Review{
    id: string;
    documentContent: any;
    reviewer: Reviewer
    attachments?: ReviewAttachment[];
}
