import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { WorkflowTypes } from '../models/workflow-types.model';
import { WorkflowTypesService } from '../services/workflow-types.service';
import {
  CreateWorkflowType,
  GetWorkflowTypes,
  GetWorkflowTypesWithSteps,
  SetSelectedWorkflowType,
  SetUpdateStatus,
  UpdateWorkflowType,
  DeleteWorkflowType,
  GetOwnedWorkflowTypes,
  SearchWorkflowTypes,
  SetWorkflowTypeSearchingMode,
} from './workflow-types.actions';
import { filter, take, tap } from 'rxjs';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';

import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';

export interface WorkflowTypesStateModel {
  update: boolean;
  workflowTypes: PaginatedList<WorkflowTypes>;
  workflowTypesWithSteps : PaginatedList<WorkflowTypes>;
  selectedWorkflowType: WorkflowTypes | null;
  totalCount: number;
  ownedWorkflowTypes: PaginatedList<WorkflowTypes>;
  isSearchingWorkflowType?: boolean;
}

const WORKFLOW_TYPES_TOKEN = new StateToken<WorkflowTypesStateModel>(
  'workflowTypesStateModel',
);

const defaults: WorkflowTypesStateModel = {
  workflowTypes: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  workflowTypesWithSteps: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  totalCount: 0,
  update: false,
  selectedWorkflowType: null,
  ownedWorkflowTypes: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowTypesStateModel>({
  name: WORKFLOW_TYPES_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowTypesState {
  constructor(
    public workflowTypesService: WorkflowTypesService,
    public operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetWorkflowTypes)
  getWorkflowTypes(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { pageNumber, pageSize }: GetWorkflowTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypesService
      .getWorkflowTypes(pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflowTypes) => {
          patchState({
            workflowTypes: paginatedWorkflowTypes,
            totalCount: paginatedWorkflowTypes.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowTypesWithSteps)
  getWorkflowTypesWithSteps(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { pageNumber, pageSize }: GetWorkflowTypesWithSteps,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypesService
      .getWorkflowTypesWithSteps(pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflowTypesWithSteps) => {
          patchState({
            workflowTypesWithSteps: paginatedWorkflowTypesWithSteps,
            totalCount: paginatedWorkflowTypesWithSteps.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetOwnedWorkflowTypes)
  getOwnedWorkflowTypes(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { pageNumber, pageSize }: GetWorkflowTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypesService
      .getOwnedWorkflowTypes(pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflowTypes) => {
          patchState({
            ownedWorkflowTypes: paginatedWorkflowTypes,
            totalCount: paginatedWorkflowTypes.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(CreateWorkflowType)
  createWorkflowType(
    { setState, getState }: StateContext<WorkflowTypesStateModel>,
    { workflowType }: CreateWorkflowType,
  ) {
    this.store.dispatch(new SetProgressOn());

    let prevLength = getState().workflowTypes.totalCount;
    let pageSize =
      getState().workflowTypes.totalCount / getState().workflowTypes.totalPages;
    return this.workflowTypesService.addWorkflowType(workflowType).pipe(
      tap((workflowType) => {
        setState(
          patch({
            workflowTypes: patch({
              items: insertItem(workflowType),
              totalCount: (state) => prevLength + 1,
              totalPages: (state) => Math.ceil(prevLength + 1 / pageSize),
            }),
            totalCount: (state) => prevLength + 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-types.created-workflow-type:Workflow Type Created Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateWorkflowType)
  updateWorkflowType(
    { patchState, getState }: StateContext<WorkflowTypesStateModel>,
    { workflowType }: UpdateWorkflowType,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.workflowTypesService.updateWorkflowType(workflowType).pipe(
      filter((workflowType) => workflowType !== null),
      take(1),
      tap((updateWorkflowType) => {
        let oldWorkflowTypes = getState().workflowTypes;
        let filteredWorkflowTypes = [
          updateWorkflowType,
          ...oldWorkflowTypes.items.filter(
            (workflowType) => workflowType.id !== updateWorkflowType.id,
          ),
        ];
        let newPaginatedWorkflowTypes = getState().workflowTypes;
        newPaginatedWorkflowTypes.items = filteredWorkflowTypes;
        patchState({
          update: false,
          workflowTypes: newPaginatedWorkflowTypes,
          selectedWorkflowType: null,
        });
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-types.updated-workflow-type:Workflow Type Updated Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteWorkflowType)
  deleteProcess(
    { setState }: StateContext<WorkflowTypesStateModel>,
    { workflowTypeId }: DeleteWorkflowType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypesService.deleteWorkflowType(workflowTypeId).pipe(
      tap(() => {
        setState(
          patch({
            workflowTypes: patch({
              items: removeItem((workflowType) => {
                return workflowType.id === workflowTypeId;
              }),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-types.deleted-workflow-type:Workflow Type Deleted Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedWorkflowType)
  setSelectedProcessInstance(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { workflowType }: SetSelectedWorkflowType,
  ) {
    patchState({
      selectedWorkflowType: workflowType,
    });
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { updateStatus }: SetUpdateStatus,
  ) {
    patchState({ update: updateStatus });
  }

  @Action(SearchWorkflowTypes)
  searchWorkflowTypes(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { workflowTypeName,isOwned, pageNumber, pageSize }: SearchWorkflowTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypesService
      .searchWorkflowTypes(workflowTypeName, isOwned,pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflowTypes) => {
          patchState({
            workflowTypes: paginatedWorkflowTypes,
            totalCount: paginatedWorkflowTypes.totalCount,
          });
          if(isOwned){
            patchState({
              ownedWorkflowTypes: paginatedWorkflowTypes,
            });
          }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetWorkflowTypeSearchingMode)
  setWorkflowTypeSearchingMode(
    { patchState }: StateContext<WorkflowTypesStateModel>,
    { isSearchingWorkflowType }: SetWorkflowTypeSearchingMode,
  ) {
    patchState({
      isSearchingWorkflowType: isSearchingWorkflowType,
    });
  }
}
