import {
  CriterionEvaluation,
  UpdateCriterionEvaluation,
} from '../models/criterion.model';
import {
  StageInstanceTask,
  UpdateStageStatusDto,
} from '../models/stage-instance-detail.model';
import { Task } from '../models/task.model';

export class GetStageEvaluation {
  static readonly type = `[StageInstanceDetail] ${GetStageEvaluation.name}`;
  constructor(public readonly stageId: string) {}
}
export class GetUserStageEvaluation {
  static readonly type = `[StageInstanceDetail] ${GetUserStageEvaluation.name}`;
  constructor(public readonly stageId: string) {}
}
export class UpdateStageEvaluation {
  static readonly type = `[StageInstanceDetail] ${UpdateStageEvaluation.name}`;
  constructor(
    public stageInstanceId: string,
    public evaluations: UpdateCriterionEvaluation[],
  ) {}
}
export class GetStageInstanceDetail {
  static readonly type = `[StageInstanceDetail] ${GetStageInstanceDetail.name}`;
  constructor(public readonly id: string) {}
}

export class GetStageInstanceTasks {
  static readonly type = `[StageInstanceDetail] ${GetStageInstanceTasks.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class UpdateTaskFromStageInstanceDetail {
  static readonly type = `[Task] ${UpdateTaskFromStageInstanceDetail.name}`;
  constructor(public task: Task) {}
}

export class UpdateTask {
  static readonly type = `[Task] ${UpdateTask.name}`;
  constructor(public task: Task) {}
}

export class AddSageInstanceTask {
  static readonly type = `[StageInstanceDetail] ${AddSageInstanceTask.name}`;
  constructor(public readonly task: StageInstanceTask) {}
}

export class ApproveStageInstance {
  static readonly type = `[StageInstanceDetail] ${ApproveStageInstance.name}`;
  constructor(public stageInstanceId: string) {}
}

export class ChangeStageStatus {
  static readonly type = `[StageInstanceDetail] ${ChangeStageStatus.name}`;
  constructor(public readonly updatedStage: UpdateStageStatusDto) {}
}

export class DeleteTaskFromStageInstanceDetail {
  static readonly type = `[Task] ${DeleteTaskFromStageInstanceDetail.name}`;
  constructor(public readonly taskId: string) {}
}

export class RejectStageInstance {
  static readonly type = `[StageInstanceDetail] ${RejectStageInstance.name}`;
  constructor(public stageInstanceId: string) {}
}

export class CheckEveryTasksApproval {
  static readonly type = `[StageInstanceDetail] ${CheckEveryTasksApproval.name}`;
  constructor(public stageInstanceId: string) {}
}
export class DownloadStage{
  static readonly type = `[StageInstanceDetail] ${DownloadStage.name}`;
  constructor(public stageInstanceId: string){}
}

export class GetStageInstanceEvaluationStatus {
  static readonly type = `[StageInstanceDetail] ${GetStageInstanceEvaluationStatus.name}`;
  constructor(public stageInstanceId: string) {}
}

export class GetTasksByTaskType {
  static readonly type = `[task] ${GetTasksByTaskType.name}`;
  constructor(
    public readonly taskTypeId: string,
    public readonly stageInstanceId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SetIsEveryTaskApprovedStatus {
  static readonly type = `[StageInstanceDetail] ${SetIsEveryTaskApprovedStatus.name}`;
  constructor(public isEveryTaskApproved: boolean) {}
}
export class StageStatusChangeSuccess {
  static readonly type = `[StageInstanceDetail] ${SetIsEveryTaskApprovedStatus.name}`;
}

export class ResetStageInstanceDetail {
  static readonly type = `[StageInstanceDetail] ${ResetStageInstanceDetail.name}`;
}

export class SetEvaluationUpdateMode {
  static readonly type = `[StageInstanceDetail] ${SetEvaluationUpdateMode.name}`;
  constructor(public isUpdatingEvaluation: boolean) {}
}

export class ToggleIsMajorTask {
  static readonly type = `[StageInstanceDetail] ${ToggleIsMajorTask.name}`;
  constructor(public id: string, public stageInstanceId: string){}
}
export class CheckIfMajorTaskFound {
  static readonly type = `[StageInstanceDetail] ${CheckIfMajorTaskFound.name}`;
  constructor(public stageInstanceId: string){}
}
