import {
  AddCcUserToMemo,
  DownloadAttachment,
  GetDraftMemo,
  GetMemoAccessDetail,
  GetMemoById,
  GetMemoFilters,
  GetMemosByDate,
  GetMemosCcedToMe,
  PreviewAttachment,
  RemoveCcUserToMemo,
  SearchMemos,
  SetMemoSearchingMode,
  UpdateMemoStatus,
} from './../store/memo.actions';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MemoSelector } from '../store/memo.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Memo, MemoAccessDetail, MemoFilter, MemoStatus } from '../models/memo.model';
import {
  CreateMemo,
  DeleteMemo,
  DeleteMemoAttachment,
  GetMemosReceived,
  GetMemosSent,
  GetParentMemo,
  SelectMemo,
  SetMemoPageNumberAndSize,
  UpdateMemo,
} from '../store/memo.actions';

@Injectable({
  providedIn: 'root',
})
export class MemoFacade {
  @Select(MemoSelector.memosSent)
  memosSent$!: Observable<PaginatedList<Memo>>;
  @Select(MemoSelector.memosCcedToMe)
  memosCcedToMe$!: Observable<PaginatedList<Memo>>;
  @Select(MemoSelector.memosReceived)
  memosReceived$!: Observable<PaginatedList<Memo>>;
  @Select(MemoSelector.pageNumber)
  pageNumber$!: Observable<number>;
  @Select(MemoSelector.pageSize)
  pageSize$!: Observable<number>;
  @Select(MemoSelector.selectedMemo)
  selectedMemo$!: Observable<Memo>;
  @Select(MemoSelector.parentMemo)
  parentMemo$!: Observable<Memo>;
  @Select(MemoSelector.selectedMemoAccessDetail)
  selectedMemoAccessDetail$!: Observable<MemoAccessDetail>;
  @Select(MemoSelector.isSearchingMemo)
  isSearchingMemo$!: Observable<boolean>;
  @Select(MemoSelector.draftMemo)
  draftMemo$!: Observable<PaginatedList<Memo>>

  @Select(MemoSelector.memoFilters)
  memoFilters$!: Observable<MemoFilter[]>

  constructor(private store: Store) {}

  dispatchGetMemosSent(pageNumber?: number, pageSize?: number) {
    return this.store.dispatch(new GetMemosSent(pageNumber, pageSize));
  }
  dispatchGetDraftMemo(pageNumber?: number, pageSize?: number) {
    return this.store.dispatch(new GetDraftMemo(pageNumber, pageSize));
  }

  dispatchGetMemosReceived(pageNumber?: number, pageSize?: number) {
    return this.store.dispatch(new GetMemosReceived(pageNumber, pageSize));
  }

  dispatchGetMemosCcedToMe(pageNumber: number, pageSize: number) {
    return this.store.dispatch(new GetMemosCcedToMe(pageNumber, pageSize));
  }

  dispatchCreateMemo(memo: FormData) {
    return this.store.dispatch(new CreateMemo(memo));
  }

  dispatchGetMemoById(memoId: string) {
    return this.store.dispatch(new GetMemoById(memoId));
  }

  dispatchSelectMemo(memo: Memo) {
    return this.store.dispatch(new SelectMemo(memo));
  }

  dispatchSetPageNumberAndSize(pageNumber: number, pageSize: number) {
    return this.store.dispatch(
      new SetMemoPageNumberAndSize(pageNumber, pageSize),
    );
  }

  dispatchUpdateMemoStatus(status: MemoStatus, memo: string) {
    return this.store.dispatch(new UpdateMemoStatus(status, memo));
  }

  dispatchDeleteMemo(id: string) {
    return this.store.dispatch(new DeleteMemo(id));
  }

  dispatchUpdateMemo(formData: FormData, memoId: string) {
    return this.store.dispatch(new UpdateMemo(formData, memoId));
  }

  dispatchDeleteAttachment(memoId: string, attachmentId: string) {
    return this.store.dispatch(new DeleteMemoAttachment(memoId, attachmentId));
  }

  dispatchGetMemoAccessDetail(memoId: string) {
    return this.store.dispatch(new GetMemoAccessDetail(memoId));
  }

  dispatchAddCcUserToMemo(memoId: string, userId: string) {
    return this.store.dispatch(new AddCcUserToMemo(memoId, userId));
  }

  dispatchRemoveCcUserToMemo(memoId: string, userId: string) {
    return this.store.dispatch(new RemoveCcUserToMemo(memoId, userId));
  }
  dispatchGetParentMemo(parentId: string) {
    return this.store.dispatch(new GetParentMemo(parentId));
  }
  dispatchSearchMemos(
    searchTerm?: string,
    sentFromId?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new SearchMemos(searchTerm, sentFromId, pageNumber, pageSize),
    );
  }

  dispatchSetMemoSearchingMode(isSearchingMemo: boolean) {
    this.store.dispatch(new SetMemoSearchingMode(isSearchingMemo));
  }

  dispatchDownloadAttachment(
    memoId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new DownloadAttachment(memoId, attachmentId, name));
  }

  dispatchPreviewAttachment(
    memoId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new PreviewAttachment(memoId, attachmentId, name));
  }

  dispatchGetMemoFilters() {
    this.store.dispatch(new GetMemoFilters());
  }

  dispatchGetMemosByDate(email: string, startDate: string, endDate: string, status: string, pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetMemosByDate(email, startDate, endDate, status, pageNumber, pageSize));
  }
}
