<div class="template-detail">
  <div class="row mb-5">
    <ng-container *appHasPermission="hasUpdateDocumentFormPermission()">
      <button
        mat-flat-button
        class="round-btn"
        color="primary"
        *ngIf="isDraftForm"
        (click)="continueWorkingOnDraft()"
        i18n="@@documents.document-form-detail.continue-working-on-draft-form"
      >
        Continue Working on Draft Form
      </button>
    </ng-container>
    <ng-container *appHasPermission="hasUpdateDocumentFormPermission()">
      <button
        mat-flat-button
        class="round-btn"
        color="primary"
        *ngIf="isRollbackedForm"
        (click)="continueWorkingOnDraft()"
      >
        <mat-icon>edit</mat-icon>
        <span i18n="@@documents.document-form-detail.rework"> Rework </span>
      </button>
    </ng-container>
    <button
      mat-flat-button
      class="round-btn"
      color="primary"
      *ngIf="isRollbackedForm"
      (click)="seeComment()"
    >
      <span i18n="@@documents.document-form-detail.see-comment">
        See Comment
      </span>
    </button>
    <ng-container
      *appHasPermission="hasChangeFormStatusToSubmittedPermission()"
    >
      <button
        mat-flat-button
        class="round-btn"
        color="primary"
        *ngIf="isRollbackedForm"
        (click)="submitForm()"
        i18n="@@documents.document-form-detail.submit"
      >
        Submit
      </button>
    </ng-container>
    <ng-container
      *appHasPermission="hasChangeFormStatusToSubmittedPermission()"
    >
      <button
        mat-flat-button
        class="round-btn"
        color="primary"
        *ngIf="isDraftForm"
        (click)="submitDraftForm()"
        i18n="@@documents.document-form-list.submit-form"
        [attr.data-test-id]="'documents.submit-draft.document-form-detail.submit-form-button'"
      >
        Submit Form
      </button>
    </ng-container>
  </div>
  <div class="row">
    <h1 class="mat-h1">{{ selectedDocumentFormDetail?.name }}</h1>
    <button mat-stroked-button class="round-btn null-btn" color="primary">
      {{ selectedDocumentFormDetail?.status?.status }}
    </button>
  </div>

  <mat-card class="comment-card" *ngIf="isRollbackedForm && displayComment">
    <mat-card-title i18n="@@documents.document-form-detail.comment">
      Comment
    </mat-card-title>
    <mat-card-content>
      <p class="text-gray">
        {{ selectedDocumentFormDetail?.status?.comment }}
      </p>
    </mat-card-content>
  </mat-card>
  <p>
    {{ selectedDocumentFormDetail?.description }}
  </p>

  <div class="approve-reject-btn">
    <button
      *ngIf="selectedDocumentForm?.status?.status === 'Draft'"
      class="add-btn"
      mat-flat-button
      color="primary"
      (click)="onAddFieldClicked()"
    >
      <mat-icon>add</mat-icon>
      <span i18n="@@documents.document-form-detail.add-field"> Add Field </span>
    </button>
    <div
      *ngIf="!isDraftForm && !isRollbackedForm && !isApproved && !isRejected"
    >
      <button
        class="template-btn"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="menu"
        i18n="@@documents.document-form-detail.actions"
        *appHasPermission="hasChangeFormStatusToApprovedPermission()"
      >
        Actions
      </button>
      <mat-menu #menu="matMenu">
        <button
          *appHasPermission="hasChangeFormStatusToApprovedPermission()"
          mat-menu-item
          (click)="approveForm()"
          [disabled]="isApproved || !this.selectedDocumentFormDetail"
          i18n="@@documents.document-form-detail.approve-template"
        >
          Approve Form
        </button>
        <button
          mat-menu-item
          *appHasPermission="hasChangeFormStatusToRejectedPermission()"
          (click)="rejectForm()"
          [disabled]="isRejected || !this.selectedDocumentFormDetail"
          i18n="@@documents.document-form-detail.reject-template"
        >
          Reject Form
        </button>
        <button
          mat-menu-item
          (click)="rollback()"
          [disabled]="isRollbacked"
          *appHasPermission="hasChangeFormStatusToChangeRequestedPermission()"
          i18n="@@documents.document-form-detail.request-changes"
        >
          Request changes
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<ng-container *ngIf="hasFields">
  <div class="template-detail-wrapper">
    <div class="fields">
      <mat-card class="ungrouped-fields-card">
        <mat-card-header>
          <mat-card-title
            i18n="@@documents.document-form-detail.fields"
            *ngIf="unGroupedFields.length > 0"
            >Ungrouped Fields</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let template of unGroupedFields" class="field-card">
            <app-custom-input
              [label]="template?.name || ''"
              [placeholder]="template?.placeholder"
              [isReadOnly]="true"
              [dataType]="template?.dataType"
              [options]="template.optionValues"
              (click)="onFieldClick(template)"
              class="field"
            />
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card
        class="ungrouped-fields-card"
        *ngIf="groupedFieldsColumns.length > 0"
      >
        <mat-card-header>
          <mat-card-title
            *ngIf="groupedFieldsColumns"
            class="grouped-fields-h2"
            i18n="@@documents.document-form-detail.grouped-fields"
          >
            Grouped Fields
            <mat-chip>{{
              groupedFields[groupedFieldsColumns.at(0) || ""]?.groupName
            }}</mat-chip>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="table-container">
          <table mat-table [dataSource]="[groupedFields]" class="field-table">
            <ng-container
              [matColumnDef]="column"
              *ngFor="let column of groupedFieldsColumns"
            >
              <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
              <td mat-cell *matCellDef="let element">
                <app-custom-input
                  [label]="element[column]?.name || ''"
                  [placeholder]="element[column]?.placeholder"
                  [isReadOnly]="true"
                  [dataType]="element[column]?.dataType"
                  [options]="element[column].optionValues"
                  (click)="onFieldClick(element[column])"
                  class="field"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="groupedFieldsColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: groupedFieldsColumns"
            ></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <ng-container *ngIf="!!selectedFormField">
      <div
        class="validations"
        *appHasPermission="hasGetFormFieldValidationRulesPermission()"
      >
        <div class="field-title">
          <h2 class="mat-h2">
            {{ selectedFormField?.name }}
          </h2>
          <div>
            <button
              mat-icon-button
              (click)="onEditFieldClicked(selectedFormField!)"
              *ngIf="selectedDocumentForm?.status?.status === 'Draft'"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="edit icon"
                fontIcon="edit"
              ></mat-icon>
            </button>

            <button
              mat-icon-button
              (click)="onDeleteFieldClicked(selectedFormField!)"
              *ngIf="selectedDocumentForm?.status?.status === 'Draft'"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="delete icon"
                fontIcon="delete"
              ></mat-icon>
            </button>
          </div>
        </div>

        <p>
          <span
            class="mat-subtitle"
            i18n="@@documents.document-form-detail.field-type"
            >Filed Type:</span
          >
          <span class="chip mat-caption">{{
            selectedFormField?.dataType
          }}</span>
        </p>
        <app-field-validation-list
          [templateFieldId]="selectedFormField?.id"
        ></app-field-validation-list>
      </div>
    </ng-container>
  </div>
</ng-container>
