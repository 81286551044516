import { Component, Input } from '@angular/core';
import { Letter } from '../../models/letter.model';

@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss'],
})
export class LetterComponent {
  @Input()
  letter!: Letter;
}
