import { Q } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, filter, of } from 'rxjs';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import {
  CreateProcessInstanceModel,
  ProcessInstance,
  UpdateProcessInstanceModel,
} from 'src/app/researches/models/process-instance.model';
import { Process } from 'src/app/researches/models/process.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User } from 'src/app/users/models/user.model';

interface ProcessInstanceFormComponentState {
  selectedProcessInstance: ProcessInstance | null;
  update: boolean;
  users: User[];
}

interface ProcessListComponentState {
  processes: Process[];
  length: number;
}

const initProcessInstanceFormComponentState: ProcessInstanceFormComponentState =
  {
    selectedProcessInstance: null,
    update: false,
    users: [],
  };

const initProcessListComponentState: ProcessListComponentState = {
  processes: [],
  length: 0,
};

@Component({
  selector: 'app-process-instance-form',
  templateUrl: './process-instance-form.component.html',
  styleUrls: ['./process-instance-form.component.scss'],
})
export class ProcessInstanceFormComponent implements OnInit {
  processInstanceForm: FormGroup;
  update: boolean = false;
  update$: Observable<boolean> = this.state.select('update');
  selectedProcessInstance: ProcessInstance | undefined;
  selectedProcessInstance$: Observable<ProcessInstance | null> =
    this.state.select('selectedProcessInstance');
  saveButtonText: string = 'Create Process';
  formTitle: string = 'Create Process';
  processes: Process[] = [];
  processes$ = this.processFacade.processes$;
  length: number = 0;
  length$ = this.processState.select('length');
  zeroStageProcessChosen: boolean = false;
  users: User[] = [];
  users$ = this.state.select('users');
  filteredTeamMembers: User[] = [];

  placeholderToggleLabel = {
      processDescription: $localize`:@@researches.process-instance-form.this-is-description-for-process: This is description for the process.`,
      teamMembers: $localize`:@@researches.process-instance-form.team-members: Team Members`,
  }

  @ViewChild('teamMembersInput')
  teamMembersInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private state: RxState<ProcessInstanceFormComponentState>,
    private processState: RxState<ProcessListComponentState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private processFacade: ProcessFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initProcessInstanceFormComponentState);
    this.processState.set(initProcessListComponentState);
    this.processState.connect('processes', this.processFacade.processes$);
    this.processState.connect('length', this.processFacade.totalCount$);
    this.state.connect(
      'selectedProcessInstance',
      this.processInstanceFacade.selectedProcessInstance$,
    );
    this.state.connect('update', this.processInstanceFacade.update$);
    this.state.connect('users', this.userFacade.users$);
    this.processInstanceForm = this.fb.group({
      processId: ['', [Validators.required], this.processValidator()],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      teamMembersSearchQuery: [''],
      teamMembers: [new Set<User>()],
    });
  }

  ngOnInit(): void {
    this.userFacade.dispatchGetUsers();
    this.processFacade.dispatchGetPaginatedProcesses();
    this.processes$.subscribe((processes) => {
      if (processes) {
        this.processes = processes;
      }
    });
    this.length$.subscribe((length) => {
      this.length = length;
    });
    this.selectedProcessInstance$.subscribe((processInstance) => {
      if (processInstance) this.selectedProcessInstance = processInstance;
      this.updateForm();
    });
    this.update$.subscribe((updateStatus) => {
      this.update = updateStatus;
      this.updateForm();
    });
    this.users$.subscribe((users) => {
      this.users = users;
      this.filteredTeamMembers = users;
    });
    this.processInstanceForm.controls['teamMembersSearchQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredTeamMembers = this.filterTeamMembers(query);
      });
  }

  save() {
    const { valid, touched, dirty } = this.processInstanceForm;
    if (valid && (touched || dirty)) {
      if (this.update) {
        const updatedProcessInstance: UpdateProcessInstanceModel = {
          id: this.selectedProcessInstance?.id,
          title: this.processInstanceForm.value.title,
          description: this.processInstanceForm.value.description,
        };
        this.processInstanceFacade.dispatchUpdateProcessInstance(
          updatedProcessInstance,
        );
      } else {
        const processInstance: CreateProcessInstanceModel = {
          processId: this.processInstanceForm.value.processId,
          title: this.processInstanceForm.value.title,
          description: this.processInstanceForm.value.description,
          teamMembersIds: Array.from(
            this.processInstanceForm.controls['teamMembers'].value,
          ).map((user: any) => user.id),
        };

        this.processInstanceFacade.dispatchCreateProcessInstance(
          processInstance,
        );
      }
      this.dialog.closeAll();
    }
  }

  processValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.zeroStageProcessChosen) {
        return of({ forbidden: { value: control.value } });
      }
      return of(null);
    };
  }

  checkZeroStagesProcess(selectedProcessId: string) {
    let process =
      this.processes.find((pr) => pr.id == selectedProcessId) ?? null;
    if (
      process !== null &&
      process.stagesCount != undefined &&
      process.stagesCount == 0
    ) {
      this.zeroStageProcessChosen = true;
    } else {
      this.zeroStageProcessChosen = false;
    }
    this.disableEnableFormFields();
  }

  disableEnableFormFields() {
    if (this.zeroStageProcessChosen) {
      this.processInstanceForm.controls['title'].disable();
      this.processInstanceForm.controls['description'].disable();
    } else {
      this.processInstanceForm.controls['title'].enable();
      this.processInstanceForm.controls['description'].enable();
    }
  }

  updateForm() {
    if (this.update && this.selectedProcessInstance) {
      this.saveButtonText = $localize`:@@researches.process-instance-form.update-process:Update Process`;
      this.formTitle = $localize`:@@researches.process-instance-form.update-process:Update Process`;
      this.processInstanceForm.patchValue({
        processId: this.selectedProcessInstance?.process.id,
        title: this.selectedProcessInstance?.title,
        description: this.selectedProcessInstance?.description,
      });
    } else {
      this.saveButtonText = $localize`:@@researches.process-instance-form.create-process:Create Process`;
      this.formTitle =  $localize`:@@researches.process-instance-form.create-process:Create Process`;
    }
  }

  selectTeamMember(event: MatAutocompleteSelectedEvent) {
    this.processInstanceForm.controls['teamMembers'].value.add(
      event.option.value,
    );
    this.teamMembersInput.nativeElement.value = '';
  }

  removeTeamMember(user: User) {
    this.processInstanceForm.controls['teamMembers'].value.delete(user);
  }

  private filterTeamMembers(query: string | null) {
    return this.users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(query?.toLowerCase() ?? '') ||
        user.middleName?.toLowerCase().includes(query?.toLocaleLowerCase() ?? '') ||
        user.lastName!.toLowerCase().includes(query?.toLowerCase() ?? ''),
    );
  }
}
