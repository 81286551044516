import { Component, OnInit } from '@angular/core';
import { Role } from '../../models/role.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RoleFacade } from '../../facade/role.facade';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';

interface RoleFormComponentState {
  selectedRole: Role | undefined;
  update: boolean;
}

const initRoleFormComponentState: RoleFormComponentState = {
  selectedRole: undefined,
  update: false,
};

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrl: './role-form.component.scss',
  providers: [RxState],
})
export class RoleFormComponent implements OnInit {
  roleForm: FormGroup;
  update: boolean = false;
  update$: Observable<boolean> = this.state.select('update');
  selectedRole$: Observable<Role | undefined> =
    this.state.select('selectedRole');
  selectedRole: Role | undefined;
  saveButtonText: string =  $localize`:@@users.role-form.create-role: Create Role`;
  formTitle: string = $localize`:@@users.role-form.create-role: Create Role`;

  placeholderLabel = {
    roleName: $localize`:@@users.role-form.role-name: Role Name`,
  }

  constructor(
    private fb: FormBuilder,
    private state: RxState<RoleFormComponentState>,
    private roleFacade: RoleFacade,
    private dialog: MatDialog,
  ) {
    this.state.set(initRoleFormComponentState);
    this.state.connect('selectedRole', this.roleFacade.selectedRole$);
    this.state.connect('update', this.roleFacade.update$);
    this.roleForm = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.update$.subscribe((update) => {
      this.update = update;
      this.updateForm();
    });
    this.selectedRole$.subscribe((role) => {
      if (role) this.selectedRole = role;
      this.updateForm();
    });
  }

  save() {
    const { valid, touched, dirty } = this.roleForm;

    if (valid && (touched || dirty)) {
      if (this.update && this.selectedRole) {
        this.roleFacade.dispatchUpdateRole({
          id: this.selectedRole.id,
          name: this.roleForm.value.name,
        });
      } else {
        this.roleFacade.dispatchCreateRole(this.roleForm.value.name);
      }
    }
    this.dialog.closeAll();
  }

  updateForm() {
    if (this.update && this.selectedRole) {
      this.saveButtonText = $localize`:@@users.role-form.update-role: Update Role`;
      this.formTitle = $localize`:@@users.role-form.update-role: Update Role`;
      this.roleForm.patchValue({
        name: this.selectedRole.name,
      });
    } else {
      this.saveButtonText = $localize`:@@users.role-form.create-role: Create Role`;
      this.formTitle = $localize`:@@users.role-form.create-role: Create Role`;
      this.roleForm.patchValue({
        name: '',
      });
    }
  }
}
