<mat-dialog-content>
  <div class="intro">
    <p
      i18n="
        @@offices.update-excel.select-the-parent-office-and-reports-to-the-first-office-in-the-excel-file"
    >
      Select the parent office and reports to the first office in the excel
      file.
    </p>
  </div>
  <div class="office-part">
    <div class="chip-alignment">
      <button
        mat-raised-button
        (click)="openSingleOffice()"
        i18n="@@offices.office-form.parent-office"
        [attr.data-test-id]="'offices.import-excel.upload-excel-dialog.parent-office-button'"
      >
        Parent office
      </button>
      <div class="chip-w">
        <mat-chip-row
          class="chip"
          *ngIf="selectedFlatOfficeNode"
          (removed)="removeSelectedFlatOfficeNode()"
          [editable]="false"
        >
          {{ selectedFlatOfficeNode.name }}
          <button
            matChipRemove
            [attr.aria-label]="'remove ' + selectedFlatOfficeNode.name"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </div>
    </div>
    <div class="chip-alignment">
      <button
        mat-raised-button
        *appHasPermission="hasGetReportsToNodesPermission()"
        (click)="openMultipleOffice()"
        i18n="@@offices.office-form.reports-to"
        [attr.data-test-id]="'offices.import-excel.upload-excel-dialog.reports-to-button'"
      >
        Reports to
      </button>
      <div class="chip-w">
        <mat-chip-row
          class="chip"
          *ngFor="let item of selectedFlatOfficeNodes"
          (removed)="removeSelectedFlatOfficeNodeFromNodes(item)"
          [editable]="false"
        >
          {{ item.name }}
          <button matChipRemove [attr.aria-label]="'remove ' + item.name">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </div>
    </div>
  </div>

  <div class="intro">
    <h2 i18n="@@offices.update-excel.import-offices-excel">
      Import Offices Excel
    </h2>
  </div>

  <div class="file-part">
    <button
      type="button"
      mat-button
      color="accent"
      (click)="chooseFiles.click()"
      class="custom-btn-space"
      *appHasPermission="hasUploadExcelPermission()"
    >
      <mat-icon>file_upload</mat-icon>
      <p class="nowrap-text" i18n="@@offices.update-excel.choose-file">
        Choose File
      </p>
    </button>
    <input
      accept=".xlsx,.csv"
      type="file"
      hidden="true"
      #chooseFiles
      class="input"
      (change)="selectFile($event)"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      [attr.data-test-id]="'offices.import-excel.upload-excel-dialog.choose-files-input'"
    />
    <label class="file-name" [title]="selectedFile?.name">{{
      selectedFile?.name
    }}</label>
  </div>

  <mat-card-actions align="end" class="action-buttons">
    <button
      class="submit"
      mat-flat-button
      color="accent"
      mat-dialog-close
      i18n="@@offices.office-form.cancel"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      [disabled]="isImportButtonDisabled()"
      color="primary"
      (click)="importOfficesFromExcel()"
      class="custom-btn-space"
      *appHasPermission="hasUploadExcelPermission()"
      i18n="@@offices.update-excel.import-offices-excel"
      [attr.data-test-id]="'offices.import-excel.upload-excel-dialog.import-button'"
    >
      Import
    </button>
  </mat-card-actions>
</mat-dialog-content>
