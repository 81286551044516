import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { TorComment, TorCommentReply } from '../../models/tor.model';
import { TorFacade } from '../../facades/tor.facades';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { RxState } from '@rx-angular/state';
import { UserFacade } from 'src/app/users/facade/user.facade';


interface TorCommentReplyCardComponentState {
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initTorCommentReplyCardComponentState: TorCommentReplyCardComponentState = {
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-tor-comment-reply-card',
  templateUrl: './tor-comment-reply-card.component.html',
  styleUrls: ['./tor-comment-reply-card.component.scss'],
})

export class TorCommentReplyCardComponent implements OnInit {
  @Input() torComment: TorComment | undefined;
  @Input() torCommentReply: TorComment | undefined;
  @Output() editCommentReplyClicked: EventEmitter<void> = new EventEmitter<void>();

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  constructor(
    private torFacade: TorFacade,
    private dialog: MatDialog,
    private state: RxState<TorCommentReplyCardComponentState>,
    private userFacade: UserFacade,
  ) {
    this.state.connect('currentLoggedInUser',this.userFacade.currentLoggedInUser$);
  }

  ngOnInit(): void {
    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
  }

  editReply(torComment: TorComment, reply: TorComment) {
    this.torFacade.dispatchSetSelectedTorComment(torComment);
    this.torFacade.dispatchSetSelectedReplyComment(reply);
    this.torFacade.dispatchSetTorReplyUpdatingMode(true);
    this.torFacade.dispatchSetTorCommentReplyingMode(false);
    this.editCommentReplyClicked.emit();
  }

  deleteComment(event: MouseEvent, torComment: TorComment, torCommentReply: TorCommentReply) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.tor-comment-card.delete-comment-part-1:Are you sure you want to remove ` ,
        boldText:  ` "${torCommentReply?.content}" `  ,
        regularTextTwo: $localize`:@@researches.tor-comment-card.delete-comment-part-2: tor comment?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.torFacade.dispatchDeleteTorCommentReply(torComment?.id as string, torCommentReply?.id as string);
      }
    });
  }

  hasUpdateTorCommentReplyPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TorComment.Feature,
      PERMISSION_NAMES.Researches.TorComment.UpdateReplyTorComment,
    );
  }

  hasDeleteTorCommentReplyPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TorComment.Feature,
      PERMISSION_NAMES.Researches.TorComment.DeleteReplyTorComment,
    );
  }
}
