import { Component } from '@angular/core';
import {
  DocumentForm,
  DocumentFormDetailDto,
} from '../../models/document-forms.model';
import { RxState } from '@rx-angular/state';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { ActivatedRoute, Router } from '@angular/router';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT_TEMPLATES_ROUTE } from 'src/app/core/constants/routes';

interface DocumentFormLayoutComponentState {
  selectedFormDetail: DocumentFormDetailDto | undefined;
  selectedDocumentForm: DocumentForm | undefined;
}

const initDocumentFormLayoutComponentState: DocumentFormLayoutComponentState =
  {
    selectedFormDetail: undefined,
    selectedDocumentForm: undefined,
  };

@Component({
  selector: 'app-document-form-layout',
  templateUrl: './document-form-layout.component.html',
  styleUrls: ['./document-form-layout.component.scss'],
})
export class DocumentFormLayoutComponent {
  selectedFormDetail$ = this.documentFormDetailState.select(
    'selectedFormDetail',
  );
  selectedFormDetail: DocumentFormDetailDto | undefined;
  selectedDocumentForm$ = this.documentFormDetailState.select(
    'selectedDocumentForm',
  );
  selectedDocumentForm: DocumentForm | undefined;
  selectedFormFieldId?: string;

  constructor(
    private documentFormDetailState: RxState<DocumentFormLayoutComponentState>,
    private documentFormFacade: DocumentFormFacade,
    private route: ActivatedRoute,
    private router: Router,
    private templateFieldsFacade: FormFieldsFacade,
  ) {
    this.documentFormDetailState.set(
      initDocumentFormLayoutComponentState,
    );
    this.documentFormDetailState.connect(
      'selectedFormDetail',
      this.documentFormFacade.selectedDocumentFormDetail$,
    );
    this.documentFormDetailState.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
  }

  ngOnInit(): void {
    this.selectedFormDetail$.subscribe((selectedForm) => {
      this.selectedFormDetail = selectedForm;
    });

    this.selectedDocumentForm$.subscribe((selectedDocumentForm) => {
      this.selectedDocumentForm = selectedDocumentForm;
    });
  }

  get isDraftForm(): boolean {
    return (
      !!this.selectedFormDetail &&
      this.selectedFormDetail.status.status === FormStatusOption.DRAFT
    );
  }

  get hasFields(): boolean {
    return (
      !!this.selectedFormDetail &&
      this.selectedFormDetail.templateFields.length > 0
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.selectedFormDetail?.templateFields) return;
    var previousOrder =
      this.selectedFormDetail?.templateFields[event.previousIndex].order;
    var currOrder =
      this.selectedFormDetail?.templateFields[event.currentIndex].order;

    this.selectedFormDetail.templateFields[event.previousIndex].order =
      currOrder;
    this.selectedFormDetail.templateFields[event.currentIndex].order =
      previousOrder;
    this.selectedFormDetail.templateFields.sort(
      (f1, f2) => f1.order - f2.order,
    );
  }
  save() {
    this.documentFormFacade.dispatchOrderFormFields(
      this.selectedFormDetail?.templateFields!,
    );
    this.router.navigate([DOCUMENT_TEMPLATES_ROUTE]);
  }
}
