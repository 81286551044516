<mat-card
  class="communications-count-card"
  (click)="navigateToReseachCommunicationsList()"
>
  <mat-card-header>
    <mat-card-title i18n="@@researches.communications-count.briefings">Briefings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="h3">{{ totalCommunicationsCount }}</p>
  </mat-card-content>
</mat-card>
