<mat-dialog-content mat-dialog-content class="form-container">
  <h3 i18n="@@researches.tor-initiate-form.tor-initiate">Tor Initiate</h3>
  <mat-form-field
      class="full-width"
      appearance="fill"
    >
      <mat-label
      i18n="@@researches.tor-initiate-form.choose-currency">Choose currency</mat-label>
      <mat-select [value]="selectedCurrency" (valueChange)="handleCurrencyChange($event)">
        <mat-option
          *ngFor="let currency of currencies"
          [value]="currency?.currencyName"
          >
          {{currency?.currencyName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
     <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.tor-initiate-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled] ="!selectedCurrency"

      (click)="initiateTor()"
      i18n="@@researches.tor-initiate-form.initiate-tor"
    >
      Initiate  Tor
    </button>
  </div>
  </mat-dialog-content>
