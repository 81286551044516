import { RxState } from '@rx-angular/state';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { DateTimeService } from 'src/app/core/services/datetime/datetime.service';
import { DocumentsAnalyticsFacade } from '../../facades/documents-analytics.facade';
import { ReceivedExternalDocument } from '../../models/received-external-document.model';
import { SentExternalDocument } from '../../models/sent-external-document.model';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';
import { RecievedReviewsComponent } from 'src/app/researches/components/reviews/recieved-reviews/recieved-reviews.component';

interface ExternalDocumentRecordsState {
  receivedExternalDocuments: PaginatedList<ReceivedExternalDocument>;
  sentExternalDocuments: PaginatedList<SentExternalDocument>;
}

const initExternalDocumentRecordsState: Partial<ExternalDocumentRecordsState> =
  {
    receivedExternalDocuments: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    sentExternalDocuments: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
  };

@Component({
  selector: 'app-external-document-records',
  templateUrl: './external-document-records.component.html',
  styleUrls: ['./external-document-records.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ExternalDocumentRecordsComponent}],
})
export class ExternalDocumentRecordsComponent implements OnInit {
  officeId: string = '';
  receivedPageSize: number = 10;
  receivedPageIndex: number = 0;
  receivedDataSource!: MatTableDataSource<ReceivedExternalDocument>;
  sentDataSource!: MatTableDataSource<SentExternalDocument>;


  firstPageLabel = $localize`:@@documents.external-document-records.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.external-document-records.per-page: per page:`;
  lastPageLabel = $localize`Last page`;
 

  nextPageLabel = $localize`:@@documents.external-document-records.next-page: Next page`;
  previousPageLabel = $localize`:@@documents.external-document-records.previous-page: Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.external-document-records.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.external-document-records.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.external-document-records.page-part-two:of` + `${amountPages}`;
  }

  receivedExternalDocuments:
    | PaginatedList<ReceivedExternalDocument>
    | undefined;
  receivedExternalDocuments$ = this.state.select('receivedExternalDocuments');

  sentExternalDocuments: PaginatedList<SentExternalDocument> | undefined;
  sentExternalDocuments$ = this.state.select('sentExternalDocuments');
  sentPageSize: number = 10;
  sentPageIndex: number = 0;

  constructor(
    private documentsAnalyticsFacade: DocumentsAnalyticsFacade,
    private state: RxState<ExternalDocumentRecordsState>,
    private activatedRoute: ActivatedRoute,
    private dateTimeService: DateTimeService,
  ) {
    this.state.set(initExternalDocumentRecordsState);
    this.state.connect(
      'receivedExternalDocuments',
      this.documentsAnalyticsFacade.receivedExternalDocuments$,
    );
    this.state.connect(
      'sentExternalDocuments',
      this.documentsAnalyticsFacade.sentExternalDocuments$,
    );
  }
  changes = new RxState();
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.officeId = params['id'];
      this.documentsAnalyticsFacade.dispatchGetReceivedExternalDocuments(
        params['id'],
      );
      this.documentsAnalyticsFacade.dispatchGetSentExternalDocuments(
        params['id'],
      );

      this.receivedExternalDocuments$.subscribe((results) => {
        this.receivedExternalDocuments = results;
        this.receivedDataSource =
          new MatTableDataSource<ReceivedExternalDocument>(
            results.items.map((item) => ({
              ...item,
              date: this.dateTimeService.getDateOnly(new Date(item.date)),
            })),
          );
      });

      this.sentExternalDocuments$.subscribe((results) => {
        this.sentExternalDocuments = results;
        this.sentDataSource = new MatTableDataSource<SentExternalDocument>(
          results.items.map((item) => ({
            ...item,
            date: this.dateTimeService.getDateOnly(new Date(item.date)),
          })),
        );
      });
    });
  }

  loadPaginatedReceivedDocuments(event: PageEvent) {
    if (this.officeId) {
      this.documentsAnalyticsFacade.dispatchGetReceivedExternalDocuments(
        this.officeId,
        event.pageIndex + 1,
        event.pageSize,
      );
    }
  }

  loadPaginatedSentDocuments(event: PageEvent) {
    if (this.officeId) {
      this.documentsAnalyticsFacade.dispatchGetSentExternalDocuments(
        this.officeId,
        event.pageIndex + 1,
        event.pageSize,
      );
    }
  }

  hasGetSentExternalDocumentsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetSentExternalDocuments
    )
  }
}
