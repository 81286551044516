import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UUID } from 'crypto';
import { of } from 'rxjs';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import {
  CreateCriterionDto,
  Criterion,
  UpdateCriterionDto,
} from 'src/app/researches/models/criterion.model';
import { CreateStageDto, Stage } from 'src/app/researches/models/stage.model';
@Component({
  selector: 'app-criteria-update-form',
  templateUrl: './criteria-update-form.component.html',
  styleUrls: ['./criteria-update-form.component.scss'],
})
export class CriteriaUpdateFormComponent {
  selectedCriterion$ = this.stageFacade.selectedCriterion$;
  criterion$ = this.stageFacade.criteria$;
  criterion: Criterion[] | undefined;
  selectedStage: Stage | undefined;
  selectedStage$ = this.stageFacade.selectedStage$;
  selectedCriterion: Criterion | undefined;

  criteriaUpdateForm: FormGroup;
  isUpdate: boolean = false;
  usedNames = new Map();

  placeholderToggleLabel = {
    enterCriteronName: $localize`:@@researches.criteria-form.enter-criteron-name: Enter Criteron Name`,
    enterWeight: $localize`:@@researches.criteria-form.enter-weight: Enter Weight`,
  };

  constructor(
    private formBuilder: FormBuilder,
    private stageFacade: StageFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      status: string;
      isUpdate: boolean;
      sumValue: number;
    },
    private dialogRef: MatDialogRef<CriteriaUpdateFormComponent>,
  ) {
    this.selectedCriterion$.subscribe((criteria) => {
      this.selectedCriterion = criteria;
    });

    this.selectedStage$.subscribe((stage) => {
      this.selectedStage = stage;
    });
    if (this.selectedCriterion) {
      this.criteriaUpdateForm = this.formBuilder.group({
        name: [this.selectedCriterion?.name || '', Validators.required],
        weight: [
          this.selectedCriterion?.weight || null,
          [Validators.required, this.isAllowed.bind(this)],
        ],
      });
    } else {
      this.criteriaUpdateForm = this.formBuilder.group({
        name: ['', Validators.required],
        weight: [null, [Validators.required, this.isAllowed.bind(this)]],
      });
    }
  }

  isAllowed(control: AbstractControl): ValidationErrors | null {
    const currentNumber = control.value;
    let subNumber = this.selectedCriterion?.weight ?? 0;

    if (currentNumber === 0) {
      return { zeroWeight: true };
    }

    let currentSum = this.data.sumValue - subNumber + currentNumber;

    if (100 - currentSum < 0) {
      return { exceededCriteriaSum: true };
    }

    return null;
  }
  saveCriteria() {
    const { valid, touched, dirty } = this.criteriaUpdateForm;

    if (valid && (touched || dirty)) {
      if (this.selectedCriterion && this.data.isUpdate === true) {
        const { name, weight } = this.criteriaUpdateForm.value;
        const updatedCriterion: UpdateCriterionDto = {
          ...this.selectedCriterion!,
          name: name,
          weight: weight,
        };
        this.stageFacade.dispatchUpdateCriterion(updatedCriterion);
        this.dialogRef.close();
        return;
      }
      if (this.selectedStage && this.data.isUpdate === false) {
        const { name, weight } = this.criteriaUpdateForm.value;
        const criterion: CreateCriterionDto = {
          stageId: this.selectedStage.id!,
          name: name,
          weight: weight,
        };
        this.stageFacade.dispatchCreateCriterion(criterion);
        this.dialogRef.close();
        return;
      }
    }
  }
  cancel() {
    this.stageFacade.dispatchResetSelectedCriterion();
    this.dialogRef.close();
  }

  hasUpdateCriteriaPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.UpdateCriteria,
    );
  }
}
