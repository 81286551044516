import { User } from 'src/app/users/models/user.model';
import { Process } from './process.model';
import { Stage, StageStatus } from './stage.model';
import { CURRENCY } from 'src/app/core/constants/requirement_constants';
import { StageInstanceTask, StageInstanceStatus, Review, StageInstanceReview } from './stage-instance-detail.model';
import { DateTime } from 'luxon';

export enum ProcessInstanceStatus {
  ReadyToStart = 'ReadyToStart',
  Inprogress = 'Inprogress',
  Terminated = 'Terminated',
  Completed = 'Completed',
  Rejected = 'Rejected',
  WaitingForApproval = 'WaitingForApproval',
  SubmittedToEvaluation = 'SubmittedToEvaluation',
}

export interface ProcessInstance {
  id?: string | null;
  title: string;
  description: string;
  ownerId: string;
  processStatus: ProcessInstanceStatus;
  process?: any;
  ownerName: string;
  startDate?: string;
  endDate?: string;
  deleteScheduled: boolean;
  isArchived?: boolean;
  teamMembersIds?: string[];
  ownerEmail: string;
}

export interface CreateProcessInstanceModel {
  processId: string;
  title: string;
  description: string;
  teamMembersIds?: string[];
}

export interface UpdateProcessInstanceModel {
  id?: string | null;
  title: string;
  description: string;
}

export interface StageInstance {
  id?: string | null;
  name: string;
  description: string;
  stageStatus: StageInstanceStatus;
  quorum: number;
  allocatedBudget: number;
  allocatedBudgetCurrency: CURRENCY;
  consumedBudget: number;
  consumedBudgetCurrency: CURRENCY;
  stage: Stage;
  processInstance?: ProcessInstance;
  startDate?: Date;
  endDate?: Date;
  actualStartDate?: Date;
  actualEndDate?: Date;
  tasks?: StageInstanceTask[];
  reviews?: StageInstanceReview[];
}

export interface UpdateStageInstanceStatusDto {
  id?: string | null;
  status: StageInstanceStatus;
  remark?: string | null;
}

export interface ProcessInstanceDetail {
  id?: string | null;
  title: string;
  description: string;
  stageInstances: StageInstance[];
  ownerName: string;
  ownerId?: string;
  process: Process;
  startDate?: Date;
  deleteScheduled: boolean;
  endDate?: Date;
  isArchived?: boolean;
  teamMembersIds?: string[];
  ownerEmail: string;
  processStatus: ProcessInstanceStatus;
  teamMembers?: User[];
}

export interface AddTeamMembersDto {
  processInstanceId: string;
  teamMembersIds: string[];
}
export interface DeleteProcessInstanceDto {
  processInstanceId: string;
  notifiedUserIds: string[];
}

export interface ProcessInstanceFilter {
  id?: string;
  processStatus: string;
  translations: {
    processStatus: string,
    id: string,
    cultureName: string,
  }[];
}

export interface ProcessInstanceMultipleFilter {
  searchTerm?: string,
  processesIds?: string[],
  status?: ProcessInstanceStatus,
  startDate?: string,
  endDate?: string,
  pageNumber?: number,
  pageSize?: number,
}