export class GetWorkflowTypesCount {
  static readonly type = `[DocumentsAnalytics] ${GetWorkflowTypesCount.name}`;
  constructor(public readonly officeId?: string) {}
}

export class GetWorkflowsCount {
  static readonly type = `[DocumentsAnalytics] ${GetWorkflowTypesCount.name}`;
  constructor(
    public readonly officeId?: string,
    public readonly userId?: string,
    public readonly startDateTime?: Date,
    public readonly endDateTime?: Date,
  ) {}
}

export class GetReceivedExternalDocumentsCount {
  static readonly type = `[DocumentsAnalytics] ${GetReceivedExternalDocumentsCount.name}`;
  constructor(public readonly officeId?: string) {}
}

export class GetWorkflowsVsTimeData {
  static readonly type = `[DocumentsAnalytics] ${GetWorkflowsVsTimeData.name}`;
  constructor(
    public readonly officeId?: string,
    public readonly userId?: string,
    public readonly startDateTime?: Date,
    public readonly endDateTime?: Date,
    public readonly workflowTypeId?: string,
  ) {}
}

export class GetReceivedExternalDocuments {
  static readonly type = `[DocumentsAnalytics] ${GetReceivedExternalDocuments.name}`;
  constructor(
    public readonly officeId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetSentExternalDocuments {
  static readonly type = `[DocumentsAnalytics] ${GetSentExternalDocuments.name}`;
  constructor(
    public readonly officeId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetReportedCommentsPercentage {
  static readonly type = `[DocumentsAnalytics] ${GetReportedCommentsPercentage.name}`;
  constructor(
    public readonly officeId: string,
    public readonly workflowTypeId?: string,
  ) {}
}

export class GetMyWorkflowsCount {
  static readonly type = `[DocumentsAnalytics] ${GetMyWorkflowsCount.name}`;
  constructor(
    public readonly userId: string,
    public readonly officeId?: string,
    public readonly startDateTime?: Date,
    public readonly endDateTime?: Date,
  ) {}
}
export class GetInvolvedWorkflowsCount {
  static readonly type = `[DocumentsAnalytics] ${GetInvolvedWorkflowsCount.name}`;
  constructor(
    public readonly userId: string,
    public readonly officeId?: string,
    public readonly startDateTime?: Date,
    public readonly endDateTime?: Date,
  ) {}
}