import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, from, map } from 'rxjs';

import {ContactForm, UpdateContactUsPriorityDto, UpdateContactUsStatusDto } from '../models/contact-form.model';
import { ChangeContactUsPriority, ChangeContactUsStatus, CreateContactUs, DownloadAttachment, GetContactUsDetail, GetMyPaginatedContacts, GetPaginatedContactUs, GetSelectedContactUs, PreviewAttachment, SetSelectedContactForm } from '../store/contact.action';
import { ContactUsSelector } from '../store/contact.selector';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
    providedIn: 'root',
  })
  export class ContactUsFacade {
    contacts$: Observable<ContactForm[]> = this.store.select(ContactUsSelector.slices.contactSent);
    paginatedContactUs$: Observable<PaginatedList<ContactForm>> = this.store.select(ContactUsSelector.slices.paginatedContactUs);
    totalCount$!: Observable<number>;
    selectedContactForm$!: Observable<ContactForm | undefined>;

    constructor(private store: Store) {
      this.selectedContactForm$ = this.store.select(ContactUsSelector.slices.selectedContactUs);
    }

    dispatchCreateContactUS(contactForm: ContactForm) {
        this.store.dispatch(new CreateContactUs(contactForm));
      }

    dispatchGetPaginatedContactUs(pageNumber: number, pageSize: number) {
      this.store.dispatch(new GetPaginatedContactUs(pageNumber, pageSize));
    }
    
    dispatchGetMyPaginatedContacts(pageNumber: number, pageSize: number) {
      this.store.dispatch(new GetMyPaginatedContacts(pageNumber, pageSize));
    }

    dispatchGetContactUsDetail(id: String) {
      this.store.dispatch(new GetSelectedContactUs(id));
    }

    dispatchSetSelectedContactUs(
      selectedContactForm: ContactForm | undefined,
    ) {
      this.store.dispatch(new SetSelectedContactForm(selectedContactForm));
    }

    dispatchChangeContactUsStatus(updatedContactUs: UpdateContactUsStatusDto) {
      return this.store.dispatch(new ChangeContactUsStatus(updatedContactUs));
    }

    dispatchChangeContactUsPriority(updatedContactUs: UpdateContactUsPriorityDto) {
      return this.store.dispatch(new ChangeContactUsPriority(updatedContactUs));
    }

    dispatchDownloadAttachment(
      contactId: string,
      attachmentId: string,
      name: string,
    ) {
      this.store.dispatch(new DownloadAttachment(contactId, attachmentId, name));
    }

    dispatchPreviewAttachment(
      contactId: string,
      attachmentId: string,
      name: string,
    ) {
      this.store.dispatch(new PreviewAttachment(contactId, attachmentId, name));
    }
  }