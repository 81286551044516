import { StageCriteria } from './../components/process/view-stage-criteria/view-stage-criteria.component';
import {
  CreateCriterionDto,
  Criterion,
  CriterionDto,
  CriterionEvaluation,
  UpdateCriterionDto,
} from './../models/criterion.model';
import { Select, Store } from '@ngxs/store';
import { StageSelector } from '../store/stage.selector';
import { Observable } from 'rxjs';
import {
  CreateStageDto,
  Stage,
  UpdateStageOrderDto,
} from '../models/stage.model';
import {
  AddCriterion,
  CreateStage,
  ResetCriteria,
  DeleteStage,
  SaveCriteria,
  SelectEditableStage,
  UpdateStage,
  GetStageCriteria,
  UpdateCriterion,
  SetSelectedCriterion,
  RemoveCriterion,
  ResetSelectedCriterion,
  LoadCriteria,
  OrderStage,
  EvaluateStage,
  GetPaginatedStageCriteria,
  DeleteCriterion,
  CreateCriterion,
} from '../store/stage.actions';
import { Injectable } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class StageFacade {
  constructor(private store: Store) {}

  @Select(StageSelector.stage)
  selectedStage$!: Observable<Stage>;

  @Select(StageSelector.paginatedStageCriteria)
  paginatedStageCriteria$!: Observable<PaginatedList<CriterionDto>>;

  dispatchGetStageCriteria(selectedStageId: string) {
    this.store.dispatch(new GetStageCriteria(selectedStageId));
  }

  dispatchDeleteStage(selectedStageId: string) {
    this.store.dispatch(new DeleteStage(selectedStageId));
  }
  @Select(StageSelector.criteria)
  criteria$!: Observable<Criterion[]>;

  @Select(StageSelector.criteriaSum)
  criteriaSum$!: Observable<number>;

  @Select(StageSelector.selectedCriterion)
  selectedCriterion$!: Observable<Criterion>;

  dispatchSaveCriteria() {
    return this.store.dispatch(new SaveCriteria());
  }

  dispatchAddCriterion(criterion: Criterion) {
    return this.store.dispatch(new AddCriterion(criterion));
  }

  dispatchRemoveCriterion(criterion: Criterion) {
    return this.store.dispatch(new RemoveCriterion(criterion));
  }

  dispatchCreateCriterion(criterion: CreateCriterionDto) {
    return this.store.dispatch(new CreateCriterion(criterion));
  }

  dispatchLoadCritera(criteria: Criterion[]) {
    return this.store.dispatch(new LoadCriteria(criteria));
  }

  dispatchResetCriteria() {
    return this.store.dispatch(new ResetCriteria());
  }
  dispatchDeleteCriteria(id: string) {
    return this.store.dispatch(new DeleteCriterion(id));
  }

  dispatchCreateStage(stage: CreateStageDto) {
    this.store.dispatch(new CreateStage(stage));
  }

  dispatchUpdateStage(stage: CreateStageDto) {
    return this.store.dispatch(new UpdateStage(stage));
  }

  dispatchEditableStage(stage: Stage | null) {
    this.store.dispatch(new SelectEditableStage(stage));
  }
  dispatchUpdateCriterion(criterion: UpdateCriterionDto) {
    this.store.dispatch(new UpdateCriterion(criterion));
  }
  dispatchSetSelectedCriterion(criterion: Criterion) {
    return this.store.dispatch(new SetSelectedCriterion(criterion));
  }
  dispatchResetSelectedCriterion() {
    return this.store.dispatch(new ResetSelectedCriterion());
  }

  dispatchEvaluateStage(stageId: string, evaluations: CriterionEvaluation[]) {
    return this.store.dispatch(new EvaluateStage(stageId, evaluations));
  }
  dispatchOrderStage(updateStageOrder: UpdateStageOrderDto) {
    this.store.dispatch(new OrderStage(updateStageOrder));
  }
  dispatchGetPaginatedStageCriteria(
    stageId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetPaginatedStageCriteria(stageId, pageNumber, pageSize),
    );
  }
}
