export class UploadCurrencyExcel {
  static readonly type = `[ResearchesSettings] ${UploadCurrencyExcel.name}`;
  constructor(public excelFile: File) {}
}

export class DownloadCurrencyExcelTemplate {
  static readonly type = `[ResearchesSettings] ${DownloadCurrencyExcelTemplate.name}`;
}

export class GetCurrencies {
  static readonly type = `[ResearchesSettings] ${GetCurrencies.name}`;
}
export class SetDefaultCurrency {
  static readonly type = `[ResearchesSettings] ${SetDefaultCurrency.name}`;
  constructor(public id: string){}
}
