import { Component, OnInit, ViewChild } from '@angular/core';
import { Memo, MemoStatus } from '../../models/memo.model';
import { MemoFacade } from '../../facade/memo.facades';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import Quill from 'quill';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { 
  MEMOS_ROUTE,
  MEMO_FORM_ROUTE,
  MEMO_DETAIL_ROUTE,
 } from 'src/app/core/constants/routes';
import { MatSidenav } from '@angular/material/sidenav';

interface MemoDetailComponentState {
  selectedMemo: Memo | null;
  parentMemo: Memo | null;
}

const initMemoDetailComponentState: MemoDetailComponentState = {
  selectedMemo: null,
  parentMemo: null,
};

@Component({
  selector: 'app-memo-detail',
  templateUrl: './memo-detail.component.html',
  styleUrls: ['./memo-detail.component.scss'],
})
export class MemoDetailComponent implements OnInit {
  selectedMemo$: Observable<Memo | null> = this.state.select('selectedMemo');
  parentMemo$: Observable<Memo | null> = this.state.select('parentMemo');
  selectedMemo!: Memo | null;
  parentMemo!: Memo | null;
  memoStatusEnum = MemoStatus;
  memoFilter;
  editor?: Quill;
  initialEditorContent: any;
  opened: string = 'chevron_left';

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(
    private memoFacade: MemoFacade,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private state: RxState<MemoDetailComponentState>,
    private route: ActivatedRoute,
  ) {
    this.state.set(initMemoDetailComponentState);
    this.state.connect('selectedMemo', this.memoFacade.selectedMemo$);
    this.state.connect('parentMemo', this.memoFacade.parentMemo$);
    this.memoFilter = this.route.snapshot.paramMap.get('memoFilter');
  }

  ngOnInit(): void {
    this.editor = new Quill('#quill-container', {
      theme: 'bubble',
      readOnly: true,
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });
    this.selectedMemo$.subscribe((memo) => {
      this.selectedMemo = memo;
      if (this.selectedMemo) this.initialEditorContent = memo?.body;
      if (this.editor && this.initialEditorContent) {
        this.editor.setContents(this.initialEditorContent, 'api');
        this.editor.disable();
      }
      if (this.selectedMemo && this.selectedMemo.parentMemoId) {
        this.getParentMemo(this.selectedMemo.parentMemoId);
      }
    });
    this.parentMemo$.subscribe((parentMemo) => {
      this.parentMemo = parentMemo;
    });

    this.memoFacade.dispatchGetMemoById(this.route.snapshot.params['id'])
  }
  getParentMemo(parentId: string) {
    this.memoFacade.dispatchGetParentMemo(parentId);
  }
  updateMemo() {
    this.router.navigate([MEMO_FORM_ROUTE, { updateStatus: 'true' }]);
  }

  async handleToggle() {
    const opened = await this.sidenav.toggle();
    if (opened === 'open') this.opened = 'chevron_right';
    else this.opened = 'chevron_left';
  }

  deleteMemo() {
    if (this.selectedMemo !== null) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@documents.memo-detail.delete-title-memo-part-1:Are you sure you want to delete the` ,
            boldText: ` "${this.selectedMemo.title}" ` ,
            regularTextTwo: $localize`:@@documents.memo-detail.delete-title-memo-part-2:memo?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm') {
            this.memoFacade.dispatchDeleteMemo(this.selectedMemo!.id!);
            this.router.navigate([MEMOS_ROUTE]);
          }
        });
    }
  }

  deleteAttachment(attchmentId: string) {
    if (this.selectedMemo) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne:  $localize`:@@documents.memo-detail.delete-attachment:Are you sure you want to delete this attachment?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm' && this.selectedMemo) {
            this.memoFacade.dispatchDeleteAttachment(
              this.selectedMemo.id,
              attchmentId,
            );
          }
        });
    }
  }

  downloadAttachment(attachment: any) {
    this.memoFacade.dispatchDownloadAttachment(
      this.selectedMemo?.id as string,
      attachment.id,
      attachment.name,
    );
  }
  previewAttachment(attachment: any) {
    const fileExtension = attachment.name.split('.').pop()?.toLowerCase();

    if (fileExtension !== 'pdf') {
      this.snackBar.open('File type not supported to preview.', 'Close', {
        duration: 3000, 
      });
      return;
    }

    this.memoFacade.dispatchPreviewAttachment(
      this.selectedMemo?.id as string,
      attachment.id,
      attachment.name,
    );
  }

  replyToMemo() {
    if (!this.selectedMemo) return;
    this.router.navigate([MEMO_FORM_ROUTE, { parentId: this.selectedMemo.id, updateStatus: 'false' ,isReplay: 'true'}]);
  }
  showParentMemoDetail(){
    if (this.parentMemo) {
      this.memoFacade.dispatchSelectMemo(this.parentMemo);
      this.router.navigate([MEMO_DETAIL_ROUTE, { memoFilter: 'Sent' }]);
    }
  }
  
  sendMemo() {
    if (!this.selectedMemo) return;

    this.memoFacade.dispatchUpdateMemoStatus(
      MemoStatus.Sent,
      this.selectedMemo.id,
    );
    this.router.navigate([MEMOS_ROUTE]);
  }

  updateMemoStatus(status: MemoStatus) {
    if (!this.selectedMemo) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne:  $localize`:@@documents.memo-detail.are-you-sure:Are you sure?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.memoFacade.dispatchUpdateMemoStatus(status, this.selectedMemo!.id);
        this.router.navigate([MEMOS_ROUTE]);
      }
    });
  }
}
