import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  DocumentFormState,
  DocumentFormStateModel,
} from './document-form.state';

export class DocumentFormSelector {
  static slices = createPropertySelectors<DocumentFormStateModel>(
    DocumentFormState,
  );

  @Selector([DocumentFormState])
  static documentForms(stateModel: DocumentFormStateModel) {
    return stateModel.documentForms;
  }

  @Selector([DocumentFormState])
  static totalCount(stateModel: DocumentFormStateModel) {
    return stateModel.totalCount;
  }
  @Selector([DocumentFormState])
  static totalPages(stateModel: DocumentFormStateModel) {
    return stateModel.totalPages;
  }
  @Selector([DocumentFormState])
  static selectedDocumentForms(stateModel: DocumentFormStateModel) {
    return stateModel.selectedDocumentForm;
  }

  @Selector([DocumentFormState])
  static updateStatus(stateModel: DocumentFormStateModel) {
    return stateModel.updateStatus;
  }

  @Selector([DocumentFormState])
  static templates(stateModel: DocumentFormStateModel) {
    return stateModel.templates;
  }

  @Selector([DocumentFormState])
  static selectedDocumentFormDetail(
    stateModel: DocumentFormStateModel,
  ) {
    return stateModel.selectedDocumentFormDetail;
  }

  @Selector([DocumentFormState])
  static draftForms(documentStateModel: DocumentFormStateModel) {
    return documentStateModel.draftForms;
  }

  @Selector([DocumentFormState])
  static isCreatingForm(stateModel: DocumentFormStateModel) {
    return stateModel.isCreatingForm;
  }

  @Selector([DocumentFormState])
  static isSearchingForm(stateModel: DocumentFormStateModel) {
    return stateModel.isSearchingForm;
  }

  @Selector([DocumentFormState])
  static selectedFormType(stateModel: DocumentFormStateModel) {
    return stateModel.selectedFormType;
  }

  @Selector([DocumentFormState])
  static isAddFieldStepSelected(stateModel: DocumentFormStateModel) {
    return stateModel.isAddFieldStepSelected;
  }
}
