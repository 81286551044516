<div class="main" *appHasPermission="hasGetTorCommentsPermission()">
  <div class="header">
    <h3 i18n="@@researches.tor-comments.comments">Comments</h3>
    <button mat-icon-button  (click)="onCloseIconClick()">
      <mat-icon> close </mat-icon>
    </button>
  </div>
  <div class="overflow">
    <app-tor-comment-card
      class="comment-card"
      *ngFor="let torComment of torComments?.items"
      [torComment]="torComment"
      (editCommentClicked)="updateForm()"
      (replyCommentClicked)="replyCommentForm()"
    ></app-tor-comment-card>
  </div>
  <div *ngIf="!torComments?.items?.length" class="no-comment"
  i18n="@@researches.tor-comments.no-comments-yet">
    No comments yet
  </div>
  <div class="comment-form">
    <mat-card
      class="card-edit-color flex-row"
      *ngIf="selectedTorComment && isUpdatingComment"
    >
      <mat-card-content class="mat-card-content">
        <mat-icon class="mat-edit-icon">edit</mat-icon>
        <div class="flex-column text-sm">
          <span class="text-comment edit-color"
          i18n="@@researches.tor-comments.edit-message">Edit message</span>
          <span class="text-comment ellipsis">{{
            selectedTorComment.content
          }}</span>
        </div>
        <button
          class="hover-effect close-btn"
          mat-icon-button
          color="primary"
          (click)="cancelComment()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
    <mat-card
      class="card-edit-color flex-row"
      *ngIf="selectedTorComment && isReplyingToComment"
    >
      <mat-card-content class="mat-card-content">
        <mat-icon class="mat-edit-icon">reply</mat-icon>
        <div class="flex-column text-sm">
          <span class="text-comment edit-color"
            > <span i18n="@@researches.tor-comments.replying-to">Replying to </span>{{ selectedTorComment.fullName }}
          </span>
          <span class="text-comment ellipsis">{{
            selectedTorComment.content
          }}</span>
        </div>
        <button
          class="hover-effect close-btn"
          mat-icon-button
          color="primary"
          (click)="cancelComment()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>

    <div class="flex-row">
      <form [formGroup]="commentForm" id="comment-form" class="w-full">
        <mat-form-field appearance="outline" class="padding-vert w-full">
          <input
            id="name"
            formControlName="comment"
            type="text"
            #input
            maxlength="1000"
            matInput
            [placeholder]="placeholderToggleLabel.addComment"
            (keyup.enter)="addComment()"
          />
          <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
        </mat-form-field>
      </form>
      <button
        class="hover-effect h-full ml-2"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!commentForm.valid"
        (click)="addComment()"
        *appHasPermission="hasCreateTorCommentPermission()"
      >
        <mat-icon class="text-center send-btn">send</mat-icon>
      </button>
    </div>
  </div>
</div>
