import { COMMA, ENTER, F } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { EChartsOption, SeriesOption } from 'echarts';
import { Observable, combineLatest, filter, of, startWith, take } from 'rxjs';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Office } from 'src/app/offices/models/office.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User } from 'src/app/users/models/user.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { CommunicationVsTime } from '../../models/communication-vs-time.mode';
import { Filter } from '../../models/filter.model';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';

interface State {
  filter?: Filter;
  communicationVsTime: CommunicationVsTime[];
  datetime: string;
}

const initialState: State = {
  communicationVsTime: [],
  datetime: '',
};

@Component({
  selector: 'app-communication-time-graph',
  templateUrl: './communication-time-graph.component.html',
  styleUrls: ['./communication-time-graph.component.scss'],
})
export class CommunicationTimeGraphComponent implements AfterViewInit {
  datetime: string = '';
  datetime$ = this.state.select('datetime');

  @ViewChild('officeInput') officeInput!: ElementRef<HTMLInputElement>;

  eChartInstance: any;
  chartOption: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: Array(12)
          .fill(0)
          .map((_, index) => {
            const date = new Date();
            date.setMonth(index);

            return date.toLocaleString('en-US', {
              month: 'short',
            });
          }),
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [],
  };

  constructor(
    public state: RxState<State>,
    private researchDashboardFacade: ResearchDashboardFacade,
    private datetimeFacade: DateTimeFacade,
  ) {
    researchDashboardFacade.dispatchGetCommunicationVsTime();
    this.state.connect('datetime', this.datetimeFacade.datetime$);
    this.state.connect('filter', this.researchDashboardFacade.filter$);
    this.state.connect(
      'communicationVsTime',
      this.researchDashboardFacade.communicationVsTime$,
    );

    this.state.select('communicationVsTime').subscribe((val) => {
      const types = val.map((data) => data.name);
      this.chartOption = {
        ...this.chartOption,
        series: this.mapCommunicationDataToSeries(val),
        legend: { data: types },
      };
    });
  }

  ngOnInit(): void {
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
    });
  }

  ngAfterViewInit(): void {
    this.state.select('filter').subscribe((filters) => {
      this.researchDashboardFacade.dispatchGetCommunicationVsTime(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  onChartInit(ec: any) {
    this.eChartInstance = ec;
    this.setChartOption();
  }

  setChartOption() {
    this.eChartInstance.setOption(this.chartOption);
  }

  mapCommunicationDataToSeries(data: any[]): any[] {
    const counts: { [pname: string]: number[] } = {};
    data.forEach((item) => {
      const pname = item.name;
      const month = item.month - 1;
      counts[pname] = counts[pname] || Array(12).fill(0);
      counts[pname][month] += item.total;
    });

    return Object.keys(counts).map((pname) => {
      return {
        name: pname,
        type: 'bar',
        data: counts[pname],
      };
    });
  }
}
