<div style="margin-left: 20px" [attr.data-test-id]="'offices.import-excel.excel-upload-report-dialog.import-report-div'">
  <h2 class="dialog-title" i18n="@@offices.excel-upload-report-dialog.office-import-report">Office Import Report</h2>
  <angular-slickgrid
    gridId="upload-report-list-grid"
    [columnDefinitions]="columnDefinitions"
    (onAngularGridCreated)="angularGridReady($event)"
    [gridOptions]="gridOptions"
    [dataset]="reports"
  >
  </angular-slickgrid>
  <button
    mat-button
    color="accent"
    class="close-button"
    (click)="dialogRef.close()"
    i18n="@@offices.excel-upload-report-dialog.close">
    Close
  </button>
</div>
