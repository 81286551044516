import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  Input,
} from '@angular/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessDetailFacade } from 'src/app/researches/facades/process.detail.facades';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import {
  Criterion,
  CriterionDto,
} from 'src/app/researches/models/criterion.model';
import { ProcessDetail } from 'src/app/researches/models/process-detail.model';
import { Stage } from 'src/app/researches/models/stage.model';
import { CriteriaUpdateFormComponent } from '../../stage/criteria-update-form/criteria-update-form.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';

export interface StageCriteria {
  name: string;
  weight: number;
}

interface StageCriteriaState {
  selectedStage: Stage | undefined;
  processDetail: ProcessDetail | undefined;
  paginatedStageCriteria: PaginatedList<CriterionDto> | undefined;
  length: number;
}

const initProcessDetailState: StageCriteriaState = {
  selectedStage: undefined,
  processDetail: undefined,
  paginatedStageCriteria: undefined,
  length: 0,
};

@Component({
  selector: 'app-view-stage-criteria',
  templateUrl: './view-stage-criteria.component.html',
  styleUrls: ['./view-stage-criteria.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: ViewStageCriteriaComponent },
  ],
})
export class ViewStageCriteriaComponent implements OnInit, MatPaginatorIntl {
  displayedColumns: string[] = ['name', 'weight', 'actions'];
  dataSource: CriterionDto[] = [];
  selectedStage$ = this.state.select('selectedStage');
  processDetail$ = this.processDetailFacade.selectedProcessDetail$;
  selectedStage: Stage | undefined;
  processDetail: ProcessDetail | undefined;
  paginatedStageCriteria$ = this.state.select('paginatedStageCriteria');
  paginatedStageCriteria: PaginatedList<CriterionDto> | undefined = undefined;

  firstPageLabel = $localize`:@@researches.view-stage-criteria.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.view-stage-criteria.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.view-stage-criteria.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.view-stage-criteria.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.view-stage-criteria.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.view-stage-criteria.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return (
      $localize`:@@researches.view-stage-criteria.page-part-one: Page` +
      `${page + 1}` +
      $localize`:@@researches.view-stage-criteria.page-part-two: of` +
      `${amountPages}`
    );
  }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  pageSize: number = 10;
  pageIndex: number = 0;

  constructor(
    private state: RxState<StageCriteriaState>,
    private stageFacade: StageFacade,
    private processDetailFacade: ProcessDetailFacade,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.paginatedStageCriteria$.subscribe((criteria) => {
      this.dataSource = criteria?.items!;
    });
    this.state.set(initProcessDetailState);
    this.state.connect('selectedStage', this.stageFacade.selectedStage$);
    this.state.connect(
      'paginatedStageCriteria',
      this.stageFacade.paginatedStageCriteria$,
    );
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.stageFacade.dispatchGetStageCriteria(params['id']);
      this.stageFacade.dispatchGetPaginatedStageCriteria(
        params['id'],
        this.paginator?.pageIndex + 1 || 1,
        this.paginator?.pageSize || 10,
      );
    });

    this.selectedStage$.subscribe((selectedStage) => {
      this.selectedStage = selectedStage;
    });

    this.paginatedStageCriteria$.subscribe((paginatedStageCriteria) => {
      if (paginatedStageCriteria) {
        this.paginatedStageCriteria = paginatedStageCriteria;
        this.pageIndex = paginatedStageCriteria.pageNumber - 1;
        this.dataSource = paginatedStageCriteria.items;
      } else {
        this.dataSource = [];
      }
    });

    this.processDetail$.subscribe((processDetail) => {
      this.processDetail = processDetail;
    });
  }

  getCriteriaSum() {
    let criteriaSum = 0;
    let stageCriteria = this.dataSource ?? [];

    for (const i of stageCriteria) {
      criteriaSum += i.weight;
    }

    return criteriaSum;
  }

  updateCriteria(criteria: Criterion) {
    let criteriaSum = this.getCriteriaSum();

    this.stageFacade.dispatchSetSelectedCriterion(criteria);
    this.dialog.open(CriteriaUpdateFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        status: 'Update',
        isUpdate: true,
        sumValue: criteriaSum,
      },
    });
  }
  deleteCriteria(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.view-stage-criteria.delete-stage-criteria-confirmation:Are you sure you want to delete the criteria?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.stageFacade.dispatchDeleteCriteria(id);
      }
    });
  }

  addCriteria() {
    let criteriaSum = this.getCriteriaSum();
    this.dialog.open(CriteriaUpdateFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        status: 'Create',
        isUpdate: false,
        sumValue: criteriaSum,
      },
    });
  }

  loadPaginatedStageCriteria(event: PageEvent) {
    this.stageFacade.dispatchGetPaginatedStageCriteria(
      this.selectedStage?.id as string,
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  hasGetCriterionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.GetCriterion,
    );
  }

  hasCreateCriteriaPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.CreateCriteria,
    );
  }

  hasUpdateCriteriaPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.UpdateCriteria,
    );
  }

  hasDeleteCriteriaPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.DeleteCriteria,
    );
  }
}
