<mat-dialog-content mat-dialog-content class="container">
  <div>
    <h3 class="add-fields-title" i18n="@@documents.workflow-type-step-add-fields.select-fields-to-include">Select fields to include</h3>
    <ng-container>
      <ng-container
        *ngFor="let templateField of templateFields?.items; let i = index"
      >
        <ng-container>
          <div class="border-gray-round mb-2">
            <div class="flex-justifyb-itemsc">
              <span>{{ templateField.name }}</span>
              <span><span i18n="@@documents.workflow-type-step-add-fields.type"> Type: </span> {{ templateField.dataType }} </span>
              <mat-checkbox
                (change)="onFieldChange(templateField)"
                [checked]="handleChecked(templateField)"
              ></mat-checkbox>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@documents.workflow-type-step-add-fields.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="selectedTemplateFields.length === 0"
      i18n="@@documents.workflow-type-step-add-fields.save"
    >
      Save
    </button>
  </div>
</mat-dialog-content>
