import { removeItem } from '@ngxs/store/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, filter } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  AddTeamMembersDto,
  DeleteProcessInstanceDto,
  ProcessInstance,
  ProcessInstanceDetail,
} from 'src/app/researches/models/process-instance.model';
import { ConfirmGenericDialogComponent } from 'src/app/shared/shared-components/confirm-generic-dialog/confirm-generic-dialog.component';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User, CurrentLoggedInUser } from 'src/app/users/models/user.model';

interface AddTeamMemberFormComponentState {
  processInstanceDetail: ProcessInstanceDetail | null;
  users: User[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initAddTeamMemberFormComponentState: AddTeamMemberFormComponentState = {
  processInstanceDetail: null,
  users: [],
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-delete-process-form',
  templateUrl: './delete-process-instance-form.component.html',
  styleUrls: ['./delete-process-instance-form.component.scss'],
})
export class DeleteProcessInstanceFormComponent implements OnInit {
  deleteProcessForm: FormGroup;
  users: User[] = [];
  users$ = this.state.select('users');
  filteredNotifiedUsers: User[] = [];
  notifiedUsers: User[] = [];

  processInstanceDetail: ProcessInstanceDetail | null = null;
  processInstanceDetail$: Observable<ProcessInstanceDetail | null> =
    this.state.select('processInstanceDetail');

  @ViewChild('notifiedUsersInput')
  notifiedUsersInput!: ElementRef<HTMLInputElement>;

  placeholderToggleLabel = {
      searchNotifiedUsersAdd: $localize`:@@researches.delete-process-instance-form.search-users-to-notify: Search Team Members to Add`,
      allAvailableUsersAdded: $localize`:@@researches.delete-process-instance-form.all-available-users-are-added: all available users are added`,
  }
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private state: RxState<AddTeamMemberFormComponentState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initAddTeamMemberFormComponentState);
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.state.connect('users', this.userFacade.users$);

    this.deleteProcessForm = this.fb.group({
      notifiedUsersSearchQuery: [''],
      notifiedUsers: [new Set<User>(), [Validators.required]],
    });
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }
  ngOnInit(): void {
    this.userFacade.dispatchGetUsers();
    this.users$.subscribe((users) => {
      this.users = users;
      this.filterExistingNotifiedUser(this.processInstanceDetail?.teamMembersIds);
    });
    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
      this.notifiedUsers = processInstance?.teamMembersIds
        ?.map((memberId: string) => {
          const user = this.users.find((user: User) => user.id === memberId);
          return user as User;
        })
        .filter((user) => user) as User[];
    });

    this.deleteProcessForm.controls['notifiedUsersSearchQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredNotifiedUsers = this.filterNotifiedUsers(query);
      });
    this.filterExistingNotifiedUser(this.processInstanceDetail?.teamMembersIds);
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  filterNotifiedUsers(query: string | null) {
    const existingNotifiedUserIds =
      this.processInstanceDetail?.teamMembersIds || [];
    return this.users.filter(
      (user) =>
        (user.firstName.toLowerCase().includes(query?.toLowerCase() ?? '') ||
          user.lastName!.toLowerCase().includes(query?.toLowerCase() ?? '')) &&
        !existingNotifiedUserIds.includes(user?.id as string),
    );
  }

  filterExistingNotifiedUser(membersToExclude: any) {
    const existingNotifiedUserIds = membersToExclude || [];
    this.filteredNotifiedUsers = this.users?.filter(
      (user) => !existingNotifiedUserIds.includes(user?.id as string),
    );
  }

  selectNotifiedUser(event: MatAutocompleteSelectedEvent) {
    const user = event.option.value;
    if (!this.notifiedUsers.includes(user)) {
      this.filteredNotifiedUsers =this.filteredNotifiedUsers.filter(filterdUser => filterdUser.id !== user.id)
      this.notifiedUsers.push(user);
      this.notifiedUsersInput.nativeElement.value = '';
    }
  }

  removeNotifiedUser(user: User) {
    this.deleteProcessForm.controls['notifiedUsers'].value.delete(user);
  }

  deleteProcess() {
    if(!this.processInstanceDetail?.id)
      return

    const userIds = this.notifiedUsers.map(user => user.id).filter((id): id is string => id !== null && id !== undefined);
    
    const deleteDto: DeleteProcessInstanceDto = {
      processInstanceId: this.processInstanceDetail.id,
      notifiedUserIds: userIds, 
    };
    this.processInstanceFacade.dispatchDeleteProcessInstance(deleteDto);
    this.dialog.closeAll();
  }

  onDeleteNotifiedUserClicked(member: User) {
    const index = this.notifiedUsers.findIndex(user => user.id === member.id);
    if (index !== -1) {
      this.notifiedUsers.splice(index, 1);
    }
  }

  hasDeleteProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.DeleteProcessInstance,
    );
  }
}
