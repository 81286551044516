import { AfterViewInit, Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { EvaluatedProcessInstanceStatusCount } from '../../models/research-dashboard.model';
import { RxState } from '@rx-angular/state';
import { Filter } from '../../models/filter.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { combineLatest } from 'rxjs';

interface ProcessesVsEvaluationComponentState {
  evaluatedProcessInstanceStatusCount: EvaluatedProcessInstanceStatusCount[];
  filter?: Filter;
  totalCount?: number;
}

const initProcessesVsEvaluationComponentState: ProcessesVsEvaluationComponentState =
  {
    evaluatedProcessInstanceStatusCount: [],
    totalCount: 0,
  };

@Component({
  selector: 'app-processes-vs-evaluation',
  templateUrl: './processes-vs-evaluation.component.html',
  styleUrls: ['./processes-vs-evaluation.component.scss'],
  providers: [RxState],
})
export class ProcessesVsEvaluationComponent implements AfterViewInit {
  evaluatedProcessInstanceStatusCount$ = this.state.select(
    'evaluatedProcessInstanceStatusCount',
  );
  evaluatedProcessInstanceStatusCount: EvaluatedProcessInstanceStatusCount[] =
    [];

  totalCount$ = this.state.select('totalCount');
  totalCount = 0;

  echartInstance: any;

  chartOption: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      bottom: '80%',
    },
    grid: {
      left: '0%',
      bottom: '0%',
      right: '0%',
      top: '0%',
      height: '10%',
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      type: 'category',
      show: false,
    },
  };

  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    public state: RxState<ProcessesVsEvaluationComponentState>,
  ) {
    this.state.set(initProcessesVsEvaluationComponentState);

    this.state.connect(
      'evaluatedProcessInstanceStatusCount',
      this.researchDashboardFacade.evaluatedProcessInstanceStatusCount$,
    );

    this.state.connect('filter', this.researchDashboardFacade.filter$);
  }

  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceStatusCount();
    this.evaluatedProcessInstanceStatusCount$.subscribe((count)=>{
      this.evaluatedProcessInstanceStatusCount = count
      this.setChartOption();
    })

    combineLatest([this.state.select('filter')]).subscribe(([filters]) => {
      this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceStatusCount(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  onChartInit(ec: any) {
    this.echartInstance = ec;
    this.setChartOption();
  }

  setChartOption() {
    this.chartOption.series = this.evaluatedProcessInstanceStatusCount.map(
      (item) => {
        return {
          name: item.evaluatedProcessInstanceStatus,
          type: 'bar',
          stack: 'total',
          color: this.getColor(item.evaluatedProcessInstanceStatus),
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: [item.count],
        };
      },
    );
    this.echartInstance.setOption(this.chartOption, true);
    this.getTotalCount();
  }

  getColor(status: string) {
    if (status == 'Passed') return 'green';
    if (status == 'Failed') return 'red';
    return 'orange';
  }

  getTotalCount() {
    return this.evaluatedProcessInstanceStatusCount.reduce(
      (total, item) => total + item.count,
      0,
    );
  }
}
