import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { debounceTime, tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import { Process } from 'src/app/researches/models/process.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface SearchProcessComponentState {
  processes: PaginatedList<Process> | undefined;
  length: number;
  processListPageIndex: number;
  processListPageSize: number;
  processListSearchTerm: string;

}

const initialSearchProcessComponentState: SearchProcessComponentState = {
  processes: undefined,
  length: 0,
  processListPageIndex: 1,
  processListPageSize: 10,
  processListSearchTerm: '',
};

@Component({
  selector: 'app-search-process',
  templateUrl: './search-process.component.html',
  styleUrls: ['./search-process.component.scss'],
  providers: [RxState],
})
export class SearchProcessComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  processes$ = this.state.select('processes');
  processes: PaginatedList<Process> | undefined = undefined;
  searchForm: FormGroup;

  length$ = this.state.select('length');
  length: number = 0;

  processListPageIndex$ = this.state.select('processListPageIndex');
  processListPageIndex: number = 1;

  processListPageSize$ = this.state.select('processListPageSize');
  processListPageSize: number = 10;

  processListSearchTerm$ = this.state.select('processListSearchTerm');
  processListSearchTerm: string = '';

  placeholderToggleLabel = {
      searchProcessType: $localize`:@@researches.search-process.search-process-type: Search Process Type`,
  }

  

  constructor(
    private state: RxState<SearchProcessComponentState>,
    private processFacade: ProcessFacade,
    private formBuilder: FormBuilder,
  ) {
    this.state.set(initialSearchProcessComponentState);
    this.state.connect('processes', this.processFacade.pageniatedProcesses$);
    this.searchForm = this.formBuilder.group({
      searchTerm: [this.processListSearchTerm],
    });
    this.state.connect('length', this.processFacade.totalCount$);
    this.state.connect('processListPageIndex', this.processFacade.processListPageIndex$);
    this.state.connect('processListPageSize', this.processFacade.processListPageSize$);
    this.state.connect('processListSearchTerm', this.processFacade.processListSearchTerm$);
  }

  ngOnInit(): void {
    this.processListPageIndex$.subscribe((processListPageIndex) => {
      this.processListPageIndex = processListPageIndex;
      if(this.processListSearchTerm) this.getProcesses();
    });

    this.processListPageSize$.subscribe((processListPageSize) => {
      this.processListPageSize = processListPageSize;
      if(this.processListSearchTerm) this.getProcesses();
    });

    this.processListSearchTerm$.subscribe((processListSearchTerm) => {
      this.processListSearchTerm = processListSearchTerm;
      this.getProcesses();
    });
  }

  getProcesses() {
    const searchTerm = this.searchForm.get('searchTerm')?.value;

      if(!searchTerm){
        this.processFacade.dispatchGetPaginatedProcesses(
          this.processListPageIndex,
          this.processListPageSize,
        );
      }
      this.processFacade.dispatchSearchProcess(
        searchTerm,
        this.processListPageIndex,
        this.processListPageSize,
      );
  }

  onSearchTermChange() {
    this.searchForm
      .get('searchTerm')
      ?.valueChanges.pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.processFacade.dispatchSetProcessListSearchTerm(
          this.searchForm.get('searchTerm')?.value,
        );
      });
  }
}
