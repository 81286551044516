export const ENGLISH_LANGUAGE: Language = {
  name: 'English',
  code: 'en-US',
  locale: 'en',
};

export const AMHARIC_LANGUAGE: Language = {
  name: 'አማርኛ',
  code: 'am-ET',
  locale: 'am',
};

export const AFAAN_OROMOO_LANGUAGE: Language = {
  name: 'Afaan Oromoo',
  code: 'om-ET',
  locale: 'om',
};

export const TIGRI_LANGUAGE: Language = {
  name: 'ትግርኛ',
  code: 'ti-ET',
  locale: 'ti',
};

export const SOOMAALI_LANGUAGE: Language = {
  name: 'Soomaali',
  code: 'so-ET',
  locale: 'so',
};

export const AFAR_LANGUAGE: Language = {
  name: 'Afar',
  code: 'aa-ET',
  locale: 'aa',
};

export interface Language {
  name: string;
  code: string;
  locale: string;
}
