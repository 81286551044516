import { SelectionChange } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { InitiateWorkflow } from '../../store/workflow.actions';
import { InitiateWorkflowDto } from '../../models/workflow.model';
import { WORKFLOW_STEP_DETAIL_ROUTE } from "../../../../core/constants/routes";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

interface WorkflowInitiateFormState {
  workflowTypesWithSteps: PaginatedList<WorkflowTypes> | undefined;
  selectedWorkflowType: WorkflowTypes | undefined;
}
const initWorkflowInitiateFormState: WorkflowInitiateFormState = {
  workflowTypesWithSteps: undefined,
  selectedWorkflowType: undefined,
};

@Component({
  selector: 'app-workflow-initiate-form',
  templateUrl: './workflow-initiate-form.component.html',
  styleUrls: ['./workflow-initiate-form.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowInitiateFormComponent}],
})
export class WorkflowInitiateFormComponent implements OnInit, MatPaginatorIntl {
  pageIndex: number = 0;

  workflowTypesWithSteps$ = this.state.select('workflowTypesWithSteps');
  workflowTypesWithSteps: PaginatedList<WorkflowTypes> | undefined;

  selectedWorkflowType$ = this.state.select('selectedWorkflowType');
  selectedWorkflowType: WorkflowTypes | undefined;

  firstPageLabel = $localize`:@@documents.workflow-initiate-form.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.workflow-initiate-form.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.workflow-initiate-form.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.workflow-initiate-form.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.workflow-initiate-form.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.workflow-initiate-form.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.workflow-initiate-form.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow-initiate-form.page-part-two: of` + `${amountPages}`;
    }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    private state: RxState<WorkflowInitiateFormState>,
    private workflowTypesFacade: WorkflowTypesFacade,
    private workflowFacade: WorkflowFacade,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.state.set(initWorkflowInitiateFormState);
    this.state.connect(
      'workflowTypesWithSteps',
      this.workflowTypesFacade.workflowTypesWithSteps$,
    );
    this.state.connect(
      'workflowTypesWithSteps',
      this.workflowTypesFacade.workflowTypesWithSteps$,
    );
    this.state.connect(
      'selectedWorkflowType',
      this.workflowTypesFacade.selectedWorkflowType$,
    );
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.workflowTypesFacade.dispatchGetWorkflowTypesWithSteps(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
    this.workflowTypesWithSteps$.subscribe((workflowTypes) => {
      if (workflowTypes) {
        this.workflowTypesWithSteps = workflowTypes;
        this.pageIndex = workflowTypes.pageNumber - 1;
      }
    });
  }

  loadPaginatedWorkflowTypesWithSteps(event: PageEvent) {
    this.workflowTypesFacade.dispatchGetWorkflowTypesWithSteps(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  setSelectedWorkflowType(
    event: MatCheckboxChange,
    workflowType: WorkflowTypes,
  ) {
    if (event.checked) this.selectedWorkflowType = workflowType;
    else this.selectedWorkflowType = undefined;
  }

  save() {
    if (!this.selectedWorkflowType?.id) {
      this.dialog.closeAll();
      return;
    }
    const initWorkflow: InitiateWorkflowDto = {
      workflowTypeId: this.selectedWorkflowType.id,
    };
    const dispatched = this.workflowFacade.dispatchInitiateWorkflow(initWorkflow);
    this.dialog.closeAll();
    dispatched.subscribe(()=>{
      this.router.navigate([WORKFLOW_STEP_DETAIL_ROUTE]);
    })
  }
}
