import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { HomeComponent } from './components/home/home.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { RolesComponent } from './components/roles/roles.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './store/user.state';
import { RoleListComponent } from './components/role-list/role-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RoleState } from './store/role.state';
import { ChangeRoleComponent } from './components/change-role/change-role.component';
import { FormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { LoginHistoryComponent } from './components/login-history/login-history.component';
import { AssignRevokePermissionsComponent } from './components/assign-revoke-permissions/assign-revoke-permissions.component';
import { PermissionState } from './store/permission.state';
import { AssignRevokePermissionsUsersComponent } from './components/assign-revoke-permissions-users/assign-revoke-permissions-users.component';
import { AssignRevokeRolesUsersComponent } from './components/assign-revoke-roles-users/assign-revoke-roles-users.component';
import { AssignRolesDialogComponent } from './components/assign-roles-dialog/assign-roles-dialog.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ManagePasswordsComponent } from './components/manage-passwords/manage-passwords.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContactState } from './store/contact.state';
import { ReportedIssuesComponent } from './components/reported-issues/reported-issues.component';
import { ReportedIssueDetailComponent } from './components/reported-issue-detail/reported-issue-detail.component';
import { ReportedIssueBoardComponent } from './components/reported-issue-board/reported-issue-board.component';
import { UpdateUserProfileFormComponent } from './components/update-user-profile-form/update-user-profile-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    HomeComponent,
    UserFormComponent,
    UserListComponent,
    RolesComponent,
    RoleFormComponent,
    PermissionsComponent,
    RoleListComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ChangeRoleComponent,
    ChangePasswordComponent,
    AssignRevokePermissionsComponent,
    LoginHistoryComponent,
    AssignRevokePermissionsUsersComponent,
    AssignRevokeRolesUsersComponent,
    AssignRolesDialogComponent,
    UserProfileComponent,
    ManagePasswordsComponent,
    ContactUsComponent,
    ReportedIssuesComponent,
    ReportedIssueDetailComponent,
    ReportedIssueBoardComponent,
    UpdateUserProfileFormComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    UsersRoutingModule,
    FontAwesomeModule,
    NgxsModule.forFeature([UserState, RoleState, PermissionState, ContactState]),
  ],
  providers: [RxState],
})
export class UsersModule {}
