import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable, map, tap } from 'rxjs';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import {
  CreateDocumentForm,
  DocumentForm,
  UpdateDocumentFormDto,
} from '../../models/document-forms.model';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { FormFieldStepFormComponent } from 'src/app/documents/template-fields/components/form-field-step-form/form-field-step-form.component';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

interface State {
  updateStatus: boolean;
  selectedDocumentForm: DocumentForm | undefined;
  selectedFormFields: FormFields[];
  selectedFormField?: FormFields;
}

const initState: Partial<State> = {
  updateStatus: false,
  selectedFormFields: [],
};

@Component({
  selector: 'app-create-document-form',
  templateUrl: './create-document-form.component.html',
  styleUrl: './create-document-form.component.scss',
})
export class CreateDocumentFormComponent implements OnInit, AfterViewInit {
  selectedFormFields: FormFields[] = [];
  selectedFormFields$: Observable<FormFields[]> =
    this.state.select('selectedFormFields');

  createForm: any;
  addFields: any;
  groupFields: any;
  reviewFields: any;

  selectedFormField$ = this.state.select('selectedFormField');

  templateForm = this.fb.group({
    name: ['', [Validators.required], [this.uniqueFormNameValidator()]],
    description: ['', [Validators.required]],
  });

  @ViewChild(FormFieldStepFormComponent)
  child!: FormFieldStepFormComponent;

  selectedDocumentForm$: Observable<DocumentForm | undefined> =
    this.state.select('selectedDocumentForm');
  selectedDocumentForm: DocumentForm | undefined;

  @ViewChild('stepper') private formStepper!: MatStepper;
  @ViewChild(FormFieldStepFormComponent) fieldForm!: FormFieldStepFormComponent;

  constructor(
    public state: RxState<State>,
    private fb: FormBuilder,
    private documentFormFacade: DocumentFormFacade,
    private templateFieldsFacade: FormFieldsFacade,
    private dialog: MatDialog,
    private location: Location,
  ) {
    this.state.set(initState);
    this.state.connect(
      'selectedFormFields',
      this.templateFieldsFacade.selectedFormFields$,
    );

    this.state.connect(
      'selectedFormField',
      this.templateFieldsFacade.selectedFormField$,
    );

    this.state.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
  }

  ngAfterViewInit() {
    this.formStepper.selectionChange.subscribe((stepper) => {
      if (stepper.selectedIndex == 1) {
        this.documentFormFacade.dispatchToggleAddFieldStep(true);
      } else {
        this.documentFormFacade.dispatchToggleAddFieldStep(false);
      }
    });
  }

  ngOnInit(): void {
    this.templateFieldsFacade.dispatchResetSelectedFormFields();
    this.templateFieldsFacade.dispatchSetSelectedFormField(undefined);
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(false);
    this.unselectField();

    this.selectedFormFields$.subscribe((selectedFormFields) => {
      this.selectedFormFields = selectedFormFields;
    });

    this.selectedDocumentForm$.subscribe((documentForm) => {
      this.selectedDocumentForm = documentForm;
    });

    this.createForm = $localize`:@@documents.create-document-form.create-form: Create Form`;
    this.addFields = $localize`:@@documents.create-document-form.add-fields: Add Fields`;
    this.groupFields = $localize`:@@documents.create-document-form.group-fields: Group Fields`;
    this.reviewFields = $localize`:@@documents.create-document-form.review-fields: Review Fields`;
  }

  editFormField(field: FormFields) {
    this.templateFieldsFacade.dispatchSetSelectedFormField(field);
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(true);
  }

  removeFormField(field: FormFields) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.create-document-form.remove-form-field-part-1: Are you sure you want to delete` ,
        boldText: ` "${field.name}" ` ,
        regularTextTwo: $localize`:@@documents.create-document-form.remove-form-field-part-2:form field ?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        this.templateFieldsFacade.dispatchRemoveFormField(field);
        this.templateFieldsFacade.dispatchSetSelectedFormField(null);
        this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(false);
      }
    });
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-form-list.discard-form:Are you sure you want discard the form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.dialog.closeAll();
      }
    });
  }

  unselectField() {
    this.templateFieldsFacade.dispatchSetSelectedFormField(null);
  }

  handleSubmit(saveDraft: boolean) {
    const { valid } = this.templateForm;

    if (valid) {
      const template: CreateDocumentForm = {
        id: this.selectedDocumentForm?.id,
        name: this.templateForm.value.name as string,
        description: this.templateForm.value.description as string,
        status: { status: FormStatusOption.DRAFT, comment: undefined },
        templateFields: this.selectedFormFields,
      };

      if (!saveDraft)
        template.status = {
          status: FormStatusOption.SUBMITTED,
          comment: undefined,
        };

      this.isUpdateForm
        ? this.update(template as UpdateDocumentFormDto)
        : this.create(template, saveDraft);

      this.dialog.closeAll();
    }
  }

  create(template: CreateDocumentForm, saveDraft: boolean) {
    if (saveDraft) {
      this.documentFormFacade.dispatchSaveForm(template);
    } else {
      this.documentFormFacade.dispatchCreateForm(template);
    }
  }

  update(template: UpdateDocumentFormDto) {
    this.documentFormFacade.dispatchUpdateDocumentForm(template);
  }

  get isUpdateForm(): boolean {
    return (
      !!this.state.get('selectedDocumentForm') &&
      this.state.get('updateStatus') == true
    );
  }

  get IsFormADraft() {
    return (
      this.state.get('selectedDocumentForm')?.status.status ==
      FormStatusOption.DRAFT
    );
  }

  uniqueFormNameValidator(): AsyncValidatorFn {
    return (c: AbstractControl) => {
      const templateName = c?.value;
      const currentForm = this.state.get('selectedDocumentForm');
      return this.templateFieldsFacade
        .dispatchSearchFormByName(templateName)
        .pipe(
          map((val) => {
            if (!val || (val.id == currentForm?.id && this.isUpdateForm))
              return null;
            return { duplicateFormName: true };
          }),
        );
    };
  }

  handleSelectionChange(e: StepperSelectionEvent) {
    if (
      this.selectedFormField$ == undefined ||
      this.selectedFormField$ == null
    ) {
      this.fieldForm.resetForm();
    }
  }
}
