import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  WORKFLOW_STEP_VALIDATION_RULES_URL,
  WORKFLOW_STEP_VALIDATION_URL,
  WORKFLOW_TEMPLATES_URL,
  WORKFLOW_VALIDATION_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { DocumentForm } from '../../document-templates/models/document-forms.model';
import { WorkflowStepValidationRule } from '../models/workflow-step-validation-rule.model';
import { WorkflowValidation } from '../models/workflow-validation.model';
import {
  CreateWorflowStepValidationDto,
  UpdateWorkflowStepValidationDto,
  WorkflowStepValidation,
} from '../models/worlfow-step-validation.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowValidationService {
  constructor(private http: HttpClient) {}

  getFormFields(workflowId: string) {
    return this.http.get<DocumentForm[]>(`${WORKFLOW_TEMPLATES_URL}`, {
      params: new HttpParams().set('workflowId', workflowId),
    });
  }

  createWorkflowValidation({
    workflowTypeId,
    validationRuleId,
    leftFormFieldId,
    rightFormFieldId,
  }: {
    workflowTypeId: string;
    leftFormFieldId: string;
    rightFormFieldId: string;
    validationRuleId: string;
  }) {
    return this.http.post<WorkflowValidation>(WORKFLOW_VALIDATION_URL, {
      workflowTypeId,
      validationRuleId,
      leftTemplateFieldId: leftFormFieldId,
      rightTemplateFieldId: rightFormFieldId,
    });
  }

  getWorkflowValidations(
    workflowTypeId: string,
    pageSize: number,
    pageNumber: number,
  ) {
    return this.http.get<PaginatedList<WorkflowValidation>>(
      WORKFLOW_VALIDATION_URL,
      {
        params: new HttpParams({
          fromObject: { workflowTypeId, pageSize, pageNumber },
        }),
      },
    );
  }

  deleteWorkflowValidation(id: string) {
    return this.http.delete(WORKFLOW_VALIDATION_URL, {
      params: new HttpParams({ fromObject: { workflowValidationId: id } }),
    });
  }

  updateWorkflowValidation(data: {
    id: string;
    leftFormFieldId: string;
    rightFormFieldId: string;
    validationRuleId: string;
  }) {
    return this.http.put<WorkflowValidation>(WORKFLOW_VALIDATION_URL, {
      id: data.id,
      leftTemplateFieldId: data.leftFormFieldId,
      rightTemplateFieldId: data.rightFormFieldId,
      validationRuleId: data.validationRuleId,
    });
  }

  createWorkflowStepValidation(data: CreateWorflowStepValidationDto) {
    return this.http.post<WorkflowStepValidation>(
      WORKFLOW_STEP_VALIDATION_URL,
      data,
    );
  }

  updateWorkflowStepValidation(data: UpdateWorkflowStepValidationDto) {
    return this.http.put<WorkflowStepValidation>(
      `${WORKFLOW_STEP_VALIDATION_URL}`,
      data,
    );
  }

  deleteWorkflowStepValidation(id: string) {
    return this.http.delete<WorkflowStepValidation>(
      `${WORKFLOW_STEP_VALIDATION_URL}/${id}`,
    );
  }

  getWorkflowStepValidationRule(dataType: string) {
    return this.http.get<WorkflowStepValidationRule[]>(
      WORKFLOW_STEP_VALIDATION_RULES_URL,
      { params: new HttpParams({ fromObject: { dataType } }) },
    );
  }

  getWorkflowStepValidations(workflowStepId: string) {
    return this.http.get<any[]>(
      `${WORKFLOW_STEP_VALIDATION_URL}/${workflowStepId}`,
    );
  }
}
