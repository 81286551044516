import { User } from 'src/app/users/models/user.model';
import { WorkflowStep } from '../../workflow-steps/models/workflow-step.model';
import { WorkflowTypes } from '../../workflow-types/models/workflow-types.model';

export interface Workflow {
  id: string;
  name: string;
  description: string;
  workflowTypeId: string;
  status: WorkflowStatus;
  initiator?:User;
  currentStep?:WorkflowStep;
  created?: Date;
}

export interface WorkflowWithStep{
  id: string;
  name: string;
  description: string;
  workflowTypeId: string;
  workflowSteps?: WorkflowStep[];
  status: WorkflowStatus;
  initiator?:User;
  currentStep?:WorkflowStep;
}

export interface InitiateWorkflowDto {
  workflowTypeId: string;
  initiator?:User;
  currentStep?:WorkflowStep;
}

export interface FilterWorkflowDto {
  filterParams?: string;
  onlyAssigned?: boolean;
  pageNumber: number;
  pageSize: number;
}

export enum WorkflowStatus {
  Inprogress = 'InProgress',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Completed = 'Completed',
}

export interface WorkflowFilter {
  id?: string;
  workflowStatusForDisplay: string;
  translations: {
    workflowStatusForDisplay: string,
    id: string,
    cultureName: string,
  }[];
}
