<form [formGroup]="searchForm">
  <mat-form-field appearance="outline" class="search-term-input">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [placeholder]="placeholderToggleLabel.searchProcessType"
      formControlName="searchTerm"
      (keyup.enter)="getProcesses()"
      (input)="onSearchTermChange()"
      [attr.data-test-id]="'researches.search-process.search-process.process-search-input'"
    />
  </mat-form-field>
</form>
