import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CreateWorkflowTypes,
  WorkflowTypes,
} from '../../models/workflow-types.model';
import { RxState } from '@rx-angular/state';
import { WorkflowTypesFacade } from '../../facade/workflow-types.facades';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';

interface WorkflowConfigurationFormComponentState {
  update: boolean;
  selectedWorkflowType: WorkflowTypes | null;
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
}

const initWorkflowConfigurationFormComponentState: WorkflowConfigurationFormComponentState =
  {
    update: false,
    selectedWorkflowType: null,
    selectedFlatOfficeNode: undefined,
  };

@Component({
  selector: 'app-workflow-configuration-form',
  templateUrl: './workflow-configuration-form.component.html',
  styleUrls: ['./workflow-configuration-form.component.scss'],
})
export class WorkflowConfigurationFormComponent implements OnInit {
  workflowConfigurationForm: FormGroup;
  focusedInput: string = '';

  formTitle: string = $localize`:@@documents.workflow-configuration-form.configure-workflow:Configure Workflow`;
  saveButtonText: string =  $localize`:@@documents.workflow-configuration-form.create-workflow:Create Workflow`;

  update: boolean = false;
  update$ = this.state.select('update');

  selectedWorkflowType: WorkflowTypes | undefined;
  selectedWorkflowType$: Observable<WorkflowTypes | null> = this.state.select(
    'selectedWorkflowType',
  );
  
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  placeholderFieldLabel = {
    processDescriptionField: $localize`:@@documents.workflow-configuration-form.this-is-description-for-the-process: This is description for the process.`,
}

  @ViewChild('officeInput') officeInput!: ElementRef<HTMLInputElement>;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public sanitizerService: SanitizerService,
    private state: RxState<WorkflowConfigurationFormComponentState>,
    private workflowTypesFacade: WorkflowTypesFacade,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
  ) {
    this.state.set(initWorkflowConfigurationFormComponentState);
    this.state.connect('update', this.workflowTypesFacade.update$);
    this.state.connect(
      'selectedWorkflowType',
      this.workflowTypesFacade.selectedWorkflowType$,
    );
    this.workflowConfigurationForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
  }

  ngOnInit(): void {
    this.selectedWorkflowType$.subscribe((selectedWorkflowType) => {
      if (selectedWorkflowType) {
        this.selectedWorkflowType = selectedWorkflowType;
        this.updateForm();
      }
    });

    this.officeFacade.dispatchResetSelectedOffice();

    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });

    this.update$.subscribe((update) => {
      this.update = update;
      if (update) {
        this.updateForm();
      }
    });
  }

  save() {
    const { valid, touched, dirty } = this.workflowConfigurationForm;
    if (valid && (touched || dirty)) {
      if (this.update) {
        const workflowType: CreateWorkflowTypes = {
          id: this.state.get('selectedWorkflowType')?.id,
          name: this.workflowConfigurationForm.value.name,
          description: this.workflowConfigurationForm.value.description,
          officeId: this.selectedFlatOfficeNode?.id as string,
        };
        this.workflowTypesFacade.dispatchUpdateWorkflowType(workflowType);
      } else {
        const workflowType: CreateWorkflowTypes = {
          name: this.workflowConfigurationForm.value.name,
          description: this.workflowConfigurationForm.value.description,
          officeId: this.selectedFlatOfficeNode?.id as string,
        };
        this.workflowTypesFacade.dispatchCreateWorkflowType(workflowType);
      }
      this.dialog.closeAll();
    }
  }

  updateForm() {
    if (this.update && this.selectedWorkflowType) {
      this.saveButtonText =  $localize`:@@documents.workflow-configuration-form.update-workflow:Update Workflow`;
      this.formTitle =  $localize`:@@documents.workflow-configuration-form.update-workflow:Update Workflow`;
      this.workflowConfigurationForm.patchValue({
        name: this.state.get('selectedWorkflowType')?.name,
        description: this.state.get('selectedWorkflowType')?.description,
      });
      this.officeFacade.dispatchSetSelectedFlatOfficeNode(this.state.get('selectedWorkflowType')?.office?.id as string)
    }
  }

  openSingleOffice() {
    this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: false },
    });
  }

  removeSelectedOffice(){
    this.officeFacade.dispatchResetSelectedOffice()
  }
}
