import { Selector, createPropertySelectors } from '@ngxs/store';
import { PublicationState, PublicationStateModel } from './publication.state';

export class PublicationSelector {
  static slices =
    createPropertySelectors<PublicationStateModel>(PublicationState);

  @Selector([PublicationState])
  static completedProcessInstances(stateModel: PublicationStateModel) {
    return stateModel.completedProcessInstances;
  }

  @Selector([PublicationState])
  static publications(stateModel: PublicationStateModel) {
    return stateModel.publications;
  }

  @Selector([PublicationState])
  static selectedTabIndex(stateModel: PublicationStateModel) {
    return stateModel.selectedTabIndex;
  }

  @Selector([PublicationState])
  static selectedPublication(stateModel: PublicationStateModel) {
    return stateModel.selectedPublication;
  }

  @Selector([PublicationState])
  static selectedPublicationOption(stateModel: PublicationStateModel) {
    return stateModel.selectedPublicationOption;
  }

  @Selector([PublicationState])
  static publicationComments(state: PublicationStateModel) {
    return state.publicationComments;
  }

  @Selector([PublicationState])
  static isPublicationCommentVisible(state: PublicationStateModel) {
    return state.isPublicationCommentVisible;
  }
}
