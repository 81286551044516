import { Select, Store } from '@ngxs/store';
import { QuantityType } from '../models/quantityType.model';
import { TorConfigurationSelector } from '../store/tor-setting.selectors';
import { Observable } from 'rxjs';
import { ActivityType } from '../models/activityType.model';
import {
  CreateActivityType,
  ActivityUpdate,
  CreateQuantityType,
  GetActivityTypes,
  GetQuantityTypes,
  RemoveQuantityType,
  RemoveActivityType,
  RemoveActivityUpdate,
  UpdateActivityType,
  UpdateQuantityType,
  QuantityUpdate,
  RemoveQuantityUpdate,
} from '../store/tor-setting.actions';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TorConfigurationFacade {
  constructor(private store: Store) {}

  @Select(TorConfigurationSelector.quantityTypes)
  quantityTypes$!: Observable<QuantityType[]>;

  @Select(TorConfigurationSelector.activityTypes)
  activityTypes$!: Observable<ActivityType[]>;

  @Select(TorConfigurationSelector.activityUpdate)
  activityUpdate$!: Observable<ActivityType>;

  @Select(TorConfigurationSelector.quantityUpdate)
  quantityUpdate$!: Observable<QuantityType>;

  dispatchGetQuantityTypes() {
    this.store.dispatch(new GetQuantityTypes());
  }

  dispatchGetActivityTypes() {
    this.store.dispatch(new GetActivityTypes());
  }

  dispatchRemoveQuantityType(id: string) {
    this.store.dispatch(new RemoveQuantityType(id));
  }

  dispatchRemoveActivityType(id: string) {
    this.store.dispatch(new RemoveActivityType(id));
  }

  dispatchCreateActivityType(activity: any) {
    this.store.dispatch(new CreateActivityType(activity));
  }

  dispatchCreateQuantityType(activity: any) {
    this.store.dispatch(new CreateQuantityType(activity));
  }

  dispatchSetActivityUpdate(activityType: ActivityType) {
    this.store.dispatch(new ActivityUpdate(activityType));
  }

  dispatchRemoveActivityUpdate() {
    this.store.dispatch(new RemoveActivityUpdate());
  }

  dispatchUpdateActivityType(activity: ActivityType) {
    this.store.dispatch(new UpdateActivityType(activity));
  }

  dispatchSetQuantityUpdate(quantityType: QuantityType) {
    this.store.dispatch(new QuantityUpdate(quantityType));
  }

  dispatchRemoveQuantityUpdate() {
    this.store.dispatch(new RemoveQuantityUpdate());
  }

  dispatchUpdateQuantityType(quantity: QuantityType) {
    this.store.dispatch(new UpdateQuantityType(quantity));
  }
}
