import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { ENGLISH_LANGUAGE } from '../constants/language';

@Injectable({
  providedIn: 'root',
})
export class Localization {
  locale: string = ENGLISH_LANGUAGE.locale;

  async setLocale() {
    const userLocale = localStorage.getItem('locale');

    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.locale = userLocale;
    }

    // Use web pack magic string to only include required locale data
    const localeModule = await import(
      /* webpackInclude: /(en|am|om|ti|so|aa)\.mjs$/ */
      `/node_modules/@angular/common/locales/${this.locale}.mjs`
    );

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    const localeTranslationsModule = await import(
      `src/assets/locales/${this.locale}.json`
    );

    // Load translations for the current locale at run-time
    loadTranslations(localeTranslationsModule.default);
  }
}
