import { AfterViewInit, Component, DestroyRef } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState, rxState } from '@rx-angular/state';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { CommunicationCount } from '../../models/communication-count';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject, combineLatest } from 'rxjs';
import { Filter } from '../../models/filter.model';

interface State {
  paginationState: { pageNumber: number; pageSize: number };
  filter?: Filter;
  communicationCount?: PaginatedList<any>;
}

const initialState: State = {
  paginationState: { pageNumber: 1, pageSize: 5 },
};

@Component({
  selector: 'app-department-communications',
  templateUrl: './department-communications.component.html',
  styleUrls: ['./department-communications.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: DepartmentCommunicationsComponent}],
})
export class DepartmentCommunicationsComponent implements AfterViewInit {
  displayedColumns: string[] = [];

  communicationCount$ = this.state.select('communicationCount');

  firstPageLabel = $localize`:@@researches.department-communications.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.department-communications.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.department-communications.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.department-communications.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.department-communications.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.department-communications.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.department-communications.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.department-communications.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    public state: RxState<State>,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initialState);

    this.state.connect(
      'communicationCount',
      this.researchDashboardFacade.communicationCount$,
    );

    this.state.connect('filter', this.researchDashboardFacade.filter$);

    this.state.select('communicationCount').subscribe((val) => {
      if (val?.items?.[0]) {
        this.displayedColumns = Object.keys(val?.items[0]);
      }
    });
  }
  changes = new RxState();
  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetCommunicationCount();

    combineLatest([
      this.state.select('filter'),
      this.state.select('paginationState'),
    ]).subscribe(([filters, pagination]) => {
      this.researchDashboardFacade.dispatchGetCommunicationCount(
        pagination.pageNumber,
        pagination.pageSize,
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  paginateCommunicationCounts(event: PageEvent) {
    this.state.set({
      paginationState: {
        pageSize: event.pageSize,
        pageNumber: event.pageIndex + 1,
      },
    });
  }
}
