import { Component, ElementRef, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { WorkflowTypeStepDto } from '../../models/workflow-type-steps.model';
import { WorkflowTypeStepsFacade } from '../../facade/workflow-type-steps.facades';
import { MatDialog } from '@angular/material/dialog';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';

interface CcFormState {
  
  selectedWorkflowTypeStep: WorkflowTypeStepDto | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[];
}

const initialccFormState: CcFormState = {
  selectedFlatOfficeNodes: [],
  selectedWorkflowTypeStep: undefined,
};

@Component({
  selector: 'app-add-cc-offices-form',
  templateUrl: './add-cc-offices-form.component.html',
  styleUrls: ['./add-cc-offices-form.component.scss'],
})
export class AddCcOfficesFormComponent {
  formTitle = 'CC';
  selectedWorkflowTypeStep$ = this.state.select('selectedWorkflowTypeStep');
  selectedWorkflowTypeStep: WorkflowTypeStepDto | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[] = [];
  selectedFlatOfficeNodes$ = this.state.select('selectedFlatOfficeNodes');
  @ViewChild('officeInput') officeInput!: ElementRef<HTMLInputElement>;

  placeholderFieldLabel = {
    officesField: $localize`:@@documents.add-cc-offices-form.offices: Offices`,
}

  constructor(
    private officeFacade: OfficeFacade,
    private workflowTypeStepsFacade: WorkflowTypeStepsFacade,
    public state: RxState<CcFormState>,
    private dialog: MatDialog,
  ) {
    this.state.set(initialccFormState);
    
    this.state.connect(
      'selectedWorkflowTypeStep',
      this.workflowTypeStepsFacade.selectedWorkflowTypeStep$,
    );
    this.state.connect(
      'selectedFlatOfficeNodes',
      officeFacade.selectedFlatOfficeNodes$,
    );
  }

  ngOnInit() {
    this.selectedWorkflowTypeStep$.subscribe((step) => {
      this.selectedWorkflowTypeStep = step;
    });
    this.officeFacade.dispatchResetSelectedOffices();

    this.selectedFlatOfficeNodes$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNodes = selectedFlatOfficeNode;
    });
  }
  save() {
    if (this.selectedFlatOfficeNodes.length) {
      this.workflowTypeStepsFacade.dispatchAddNotifyOfficesToWorkflowTypeStep({
        WorkflowTypeStepId: this.selectedWorkflowTypeStep?.id,
        OfficeIds: this.selectedFlatOfficeNodes.map(
          (office: FlatOfficeNode) => office.id,
        ),  
      });
    }
    this.dialog.closeAll();
  }



  removeSelectedOffice(officeNode: FlatOfficeNode){
    if (this.selectedFlatOfficeNodes === undefined) return;
    const index = this.selectedFlatOfficeNodes.indexOf(officeNode);
    if (index >= 0) {
      this.selectedFlatOfficeNodes.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(
        this.selectedFlatOfficeNodes
      );
    }
  }

  openMultipleOffice() {
    this.dialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
    });
  }
}

