export class GetEvaluatedProcessInstanceStatusCount {
  static readonly type = `[ResearchDashboard] ${GetEvaluatedProcessInstanceStatusCount.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetEvaluatedProcessInstanceCountByDepartment {
  static readonly type = `[ResearchDashboard] ${GetEvaluatedProcessInstanceCountByDepartment.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly pageNumber: number,
    public readonly pageSize: number,
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetResearchesCount {
  static readonly type = `[ResearchDashboard] ${GetResearchesCount.name}`;
  constructor(
    public startDate?: Date,
    public endDate?: Date,
    public officeIds?: string[],
    public userIds?: string[],
  ) {}
}

export class SetDashboardFilter {
  static readonly type = `[ResearchDashboard] ${SetDashboardFilter.name}`;
  constructor(
    public readonly filter?: {
      users: string[];
      offices: string[];
      startDate?: Date;
      endDate?: Date;
    },
  ) {}
}

export class GetTotalCommunicationsCount {
  static readonly type = `[ResearchDashboard] ${GetTotalCommunicationsCount.name}`;
  constructor(
    public startDate?: Date,
    public endDate?: Date,
    public officeIds?: string[],
    public userIds?: string[],
  ) {}
}
export class GetTaskAccomplishmentVsTimeCount {
  static readonly type = `[ResearchDashboard] ${GetTaskAccomplishmentVsTimeCount.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly year?: number,
  ) {}
}

export class GetPublishedResearchesVsCategory {
  static readonly type = `[ResearchDashboard] ${GetPublishedResearchesVsCategory.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetPublicationCategoriesCount {
  static readonly type = `[ResearchDashboard] ${GetPublicationCategoriesCount.name}`;
  constructor() {}
}
