import { Component } from '@angular/core';
import {
  PublicationOption,
  PublicationTemplate,
} from '../../models/publication-option.model';
import { RxState } from '@rx-angular/state';
import { PublicationOptionFacade } from '../../facades/publication-option.facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurePublicationTemplateComponent } from '../configure-publication-template/configure-publication-template.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface PublicationOptionState {
  publicationOptions: PublicationOption[];
  used: string[];
}

const initPublicationOptionState: PublicationOptionState = {
  publicationOptions: [],
  used: [],
};

@Component({
  selector: 'app-publication-options',
  templateUrl: './publication-options.component.html',
  styleUrls: ['./publication-options.component.scss'],
})
export class PublicationOptionsComponent {
  publicationOptions$ =
    this.publicationOptionState.select('publicationOptions');
  used$ = this.publicationOptionState.select('used');
  used: string[] = [];
  publicationOptions: PublicationOption[] = [];
  constructor(
    private publicationOptionState: RxState<PublicationOptionState>,
    private publicationOptionFacade: PublicationOptionFacade,
    private dialog: MatDialog,
  ) {
    this.publicationOptionState.connect;
    this.publicationOptionState.set(initPublicationOptionState);
    this.publicationOptionState.connect(
      'publicationOptions',
      this.publicationOptionFacade.publicationOptions$,
    );

    this.publicationOptionState.connect(
      'used',
      this.publicationOptionFacade.isBeingUsed$,
    );
  }

  ngOnInit(): void {
    this.publicationOptionFacade.dispatchGetPublicationOptions();
    this.publicationOptions$.subscribe((options) => {
      this.publicationOptions = options;
    });
    this.used$.subscribe((ids) => {
      this.used = ids;
    });
    this.publicationOptionFacade.dispatchGetIsPublicationBeingUsed();
  }

  addPublicationOption() {
    this.dialog.open(ConfigurePublicationTemplateComponent, SIDE_DIALOG_CONFIG);
  }

  editPublicationOption(item: any) {
    const customonfig = {
      ...SIDE_DIALOG_CONFIG,
      data: { publicationOption: item },
    };
    this.dialog.open(ConfigurePublicationTemplateComponent, customonfig);
  }
  downloadTemplate(item: PublicationTemplate) {
    this.publicationOptionFacade.dispatchDownloadPublicationTemplate(item);
  }

  deletePublicationOption(item: PublicationOption) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.publication-options.delete-publication-option-message-part-1: Are you sure you want to delete the` ,
        boldText: ` "${item.title}" ` ,
        regularTextTwo: $localize`:@@researches.publication-options.delete-publication-option-message-part-2: Publication Option?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.publicationOptionFacade.dispatchhDeletePublicationOption(item);
      }
    });
  }
  isBeingUsed(item: PublicationOption): boolean {
    return this.used.includes(item.id);
  }

  banPublicationOption(item: PublicationOption) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: $localize`:@@researches.publication-options.ban-publication-option: Are you sure you want to ban this option?`
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.publicationOptionFacade.dispatchhBanPublicationOption(item);
      }
    });
  }

  permitPublicationOption(item: PublicationOption) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:$localize`:@@researches.publication-options.permit-publication-option: Are you sure you want to permit this option?`
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.publicationOptionFacade.dispatchPermitPublicationOption(item);
      }
    });
  }

  hasAddPublicationOptionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.AddPublicationOption,
    );
  }

  hasDownloadPublicationTemplatePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.DownloadPublicationTemplate,
    );
  }

  hasUpdatePublicationOptionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.UpdatePublicationOption,
    );
  }

  hasBanPublicationOptionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.BanPublicationOption,
    );
  }

  hasDeletePublicationOptionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.DeletePublicationOption,
    );
  }

  hasPermitPublicationOptionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.PermitPublicationOption,
    );
  }
}
