import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ProcessDetailState,
  ProcessDetailStateModel,
} from './process-detail.state';

export class ProcessDetailSelector {
  static slices =
    createPropertySelectors<ProcessDetailStateModel>(ProcessDetailState);

  @Selector([ProcessDetailState])
  static selectedProcessDetail(state: ProcessDetailStateModel) {
    return state.selectedProcessDetail;
  }
}
