<mat-card class="card">
  <mat-card-header>
    <mat-card-title i18n="@@researches.processes-vs-evaluation.researches-in-evaluation"> Researches in Evaluation </mat-card-title>
    <mat-card-title class="flex-row"
      >{{ getTotalCount() }}
      <mat-card-subtitle class="total-text"
      i18n="@@researches.processes-vs-evaluation.total">
        Total</mat-card-subtitle
      ></mat-card-title
    >
  </mat-card-header>
  <mat-card-content>
    <div
      echarts
      [options]="chartOption"
      class="chart"
      (chartInit)="onChartInit($event)"
    ></div>
  </mat-card-content>
</mat-card>
