<div class="main">
  <div class="template-detail">
    <div class="flex">
      <div>
        <div class="row">
          <h1>{{ workflowStep?.name }}</h1>
        </div>
        <p>
          {{ workflowStep?.description }}
        </p>
      </div>
      <div class="flex" align="end">
        <div class="flex">
          {{ workflowStep?.assigneeName }}
          <div class="avator">
            <span>{{ workflowStep?.assigneeName?.substring(0, 1) }}</span>
          </div>
          <mat-card-title class="text">
            {{ workflowStep?.assigneeName }}
          </mat-card-title>
        </div>
      </div>
    </div>
  </div>
  <div class="mid-content">
    <div class="mid-main">
      <mat-card
        class="card"
        *ngFor="let documentField of workflowStep?.document?.documentFields"
        class="document-card"
      >
        <mat-card-title>
          {{ documentField.templateField.name }}
        </mat-card-title>
        <mat-card-content>
          <p>{{ documentField.templateField.description }}</p>
          <div *ngIf="documentField.fieldValue">
            <span i18n="@@documents.ccd-workflow-step-detail.value"> Value: </span> {{ documentField.fieldValue }}
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
