<div class="process-instance-card">
  <ng-container>
    <div>
      <mat-card
        class="stage-card"
        [attr.data-test-id]="
            'researches.update-process-instance.process-instance-card.process-instance-card'
          "
      >
        <mat-card-header class="card-header">
          <div class="stage-header">
            <div class="flex">
              <mat-card-title class="card-title" [attr.data-test-id]="'researches.filter-process-instance.process-instance-card.process-instance-and-process-type-name'">
                {{ processInstance?.title }} :
                {{ processInstance?.process.name }}
              </mat-card-title>
              <div
              *ngIf="!processInstance?.deleteScheduled"
                class="chip text-bold"
                [ngClass]="
                  getProcessInstanceStatusColor(
                    processInstance?.processStatus!.toString()
                  )
                "
              >
                <span>
                  {{
                    checkProcessInstanceStatus(
                      processInstance?.processStatus!.toString()
                    )
                  }}
                </span>
              </div>
              <div
              *ngIf="processInstance?.deleteScheduled"
                class="chip text-bold red"
              >
                <span
                i18n="@@researches.process-instance-card.delete-scheduled-status"
                  >
                  Scheduled for Deletion
                </span>
              </div>
            </div>
            <div class="maticon">
              <ng-container *appHasPermission="hasArchiveProcessInstancePermission()">
                <button
                mat-icon-button
                (click)="openArchiveConfirmationDialog($event, processInstance)"
                *ngIf="!processInstance?.deleteScheduled && processInstance?.processStatus!=='ReadyToStart' && !isArchived && processInstance?.ownerEmail === currentLoggedInUser?.email"
                [attr.data-test-id]="
            'researches.archive-process-instance.process-instance-card.process-instance-arhive-button'"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example archive icon"
                  fontIcon="archive"
                ></mat-icon>
              </button>
              </ng-container>

              <ng-container *appHasPermission="hasUpdateProcessInstancePermission()">
                <button
                mat-icon-button
                (click)="openRestoreConfirmationDialogue($event, processInstance
                )"
                *ngIf="!processInstance?.deleteScheduled && isArchived&& processInstance?.ownerEmail === currentLoggedInUser?.email"
                [attr.data-test-id]="
            'researches.restore-process-instance.process-instance-card.process-instance-restore-button'
          "
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example recover icon"
                  fontIcon="restore"
                ></mat-icon>
              </button>
                <button
                mat-icon-button
                (click)="updateProcessInstance($event, processInstance)"
                *ngIf="!processInstance?.deleteScheduled && !isArchived && processInstance?.ownerEmail === currentLoggedInUser?.email"
                [attr.data-test-id]="
            'researches.update-process-instance.process-instance-card.process-instance-edit-button'
          "
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example edit icon"
                  fontIcon="edit"
                ></mat-icon>
              </button>
              </ng-container>
              <ng-container *appHasPermission="hasDownloadProcessInstancePermission()">
                <button
                  mat-icon-button
                  (click)="downloadProcess(processInstance)"
                  aria-label="Download process instance"
                  *ngIf="isArchived"
                  [attr.data-test-id]="
            'researches.download-process-instance.process-instance-card.process-instance-download-button'
          "
                >
                  <mat-icon aria-hidden="false" fontIcon="download">download</mat-icon>
                </button>
              </ng-container>


              <ng-container *appHasPermission="hasDeleteProcessInstancePermission()">
                <button
                mat-icon-button
                *ngIf="!processInstance?.deleteScheduled && (isArchived || processInstance?.processStatus ==='ReadyToStart') && processInstance?.ownerEmail === currentLoggedInUser?.email"
                (click)="
                openDeletionForm(
                    $event,
                    processInstance
                  )
                "
                [attr.data-test-id]="
            'researches.delete-process-instance.process-instance-card.process-instance-delete-button'
          "
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="delete archived process icon"
                  fontIcon="delete"
                ></mat-icon>
              </button>
              </ng-container>
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <div class="rounded p-10 mr-4">
                <span>{{
                  processInstance?.ownerName &&
                    processInstance?.ownerName!.substring(0, 1)
                }}</span>
              </div>
              <mat-card-title class="text">{{
                processInstance?.ownerName || "No Owner"
              }}</mat-card-title>
              <div class="chip">
                <span i18n="@@researches.process-instance-card.owner"
                  >Owner</span
                >
              </div>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <p class="mat-body">
            {{ processInstance?.description }}
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button
          (click)="navigateToProcessInstanceDetail(processInstance)"
            mat-stroked-button
            color="primary"
            i18n="@@researches.process-instance-card.process-detail"
          >
            Process Detail
          </button>
          <ng-container *appHasPermission="hasCancelProcessInstanceDeletionPermission()">
          <button
            class="ml"
            (click)="$event.stopPropagation();cancelDeletion()"
            *ngIf="processInstance?.deleteScheduled"
            mat-stroked-button
            color="primary"
            i18n="@@researches.process-instance-card.cancel-deletion"
            [attr.data-test-id]="
            'researches.cancel-delete-process-instance.process-instance-card.process-instance-cancel-delete-button'
          "
          >
            Cancel Deletion
          </button>
        </ng-container>
        <ng-container *appHasPermission="hasCreatePublicationPermission()">
          <button
            class="ml"
            (click)="$event.stopPropagation();startPublication()"
            *ngIf="isCompletedProcess && !isArchived"
            mat-stroked-button
            color="primary"
            i18n="@@researches.process-instance-card.start-publication"
          >
            Start Publication
          </button>
        </ng-container>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
</div>
