import { Component, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Workflow, WorkflowFilter, WorkflowStatus } from '../../models/workflow.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

interface OwnedWorkflowsComponentState {
  ownedWorkflows?: PaginatedList<Workflow>;
  workflowFilters: WorkflowFilter[];
}
const initialState: OwnedWorkflowsComponentState = {
  workflowFilters:[]
};

@Component({
  selector: 'app-owned-workflows',
  templateUrl: './owned-workflows.component.html',
  styleUrls: ['./owned-workflows.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: OwnedWorkflowsComponent}],
})
export class OwnedWorkflowsComponent implements MatPaginatorIntl {
  ownedWorkflows$ = this.state.select('ownedWorkflows');
  ownedWorkflows: PaginatedList<Workflow> | undefined;

  selectedStatus:string = "InProgress";
  workflowFilters: WorkflowFilter[] = []
  workflowFilters$ = this.state.select('workflowFilters');

  filterForm: FormGroup;
  pageSize: number = 10;
  pageIndex: number = 0;

  firstPageLabel = $localize`:@@documents.owned-workflows.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.owned-workflows.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.owned-workflows.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.owned-workflows.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.owned-workflows.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.owned-workflows.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.owned-workflows.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.owned-workflows.page-part-two: of` + `${amountPages}`;
    }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    private state: RxState<OwnedWorkflowsComponentState>,
    private workFlowFacade: WorkflowFacade,
    private fb: FormBuilder,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'ownedWorkflows',
      this.workFlowFacade.ownedWorkflows$,
    );
    this.state.connect('workflowFilters', this.workFlowFacade.workflowFilters$);

    this.filterForm = this.fb.group({
      filter: [],
    });
  }
  changes = new Subject<void>();
  ngOnInit() {
    this.workFlowFacade.dispatchGetWorkflowFilter();

    this.workflowFilters$.subscribe((filters) => {
      this.workflowFilters = filters.filter(status => {
        const translations = status.translations.find(tr => tr.cultureName == 'en-US');
        return translations && (translations.workflowStatusForDisplay == "InProgress" || translations.workflowStatusForDisplay == "Rejected");
      });
    this.filterForm.patchValue({ filter:this.workflowFilters.filter(status => {
      const translations = status.translations.find(tr => tr.cultureName == 'en-US');
      return translations && translations.workflowStatusForDisplay == "InProgress";
    })[0].id});

    this.workFlowFacade.dispatchGetOwnedWorkflows(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
    });

    this.ownedWorkflows$.subscribe((ownedWorkflows) => {
      if (ownedWorkflows) {
        this.ownedWorkflows = ownedWorkflows;
        this.pageIndex = ownedWorkflows.pageNumber - 1;
      }
    });
  }
  loadPaginatedWorkflows(event: PageEvent) {
    switch(this.selectedStatus){
      case "Rejected":
        this.workFlowFacade.dispatchGetRejectedWorkflows(
          event?.pageIndex + 1 || 1,
          event?.pageSize || 10,
        );
        break;
      default:
        this.workFlowFacade.dispatchGetOwnedWorkflows(
          event?.pageIndex + 1 || 1,
          event?.pageSize || 10,
        );
    }
  }

  onFilterSelect(event: MatSelectChange) {
    this.selectedStatus = this.workflowFilters.find(status => status.id == event.value)?.translations.find(tr => tr.cultureName == 'en-US')?.workflowStatusForDisplay as string;
    switch(this.selectedStatus){
      case "Rejected":
        this.workFlowFacade.dispatchGetRejectedWorkflows(
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 10,
        );
        break;
      default:
        this.workFlowFacade.dispatchGetOwnedWorkflows(
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 10,
        );
    }

    this.ownedWorkflows$.subscribe((ownedWorkflows) => {
      if (ownedWorkflows) {
        this.ownedWorkflows = ownedWorkflows;
        this.pageIndex = ownedWorkflows.pageNumber - 1;
      }
    });
  }
}
