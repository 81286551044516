import { NgModule } from '@angular/core';

import { OfficesRoutingModule } from './offices-routing.module';
import { OfficeListComponent } from './components/office-list/office-list.component';
import { OfficeFormComponent } from './components/office-form/office-form.component';
import { SharedModule } from '../shared/shared.module';
import { OfficesHomeComponent } from './components/offices-home/offices-home.component';
import { OfficeService } from './services/office.service';
import { NgxsModule } from '@ngxs/store';
import { OfficeState } from './store/office.state';
import { OfficeTreeComponent } from './components/office-tree/office-tree.component';
import { OfficeMultipleSelectComponent } from './components/office-multiple-select/office-multiple-select.component';
import { UploadExcelComponent } from './components/upload-excel/upload-excel.component';
import { DownloadExcelTemplateComponent } from './components/download-excel-template/download-excel-template.component';
import { ExcelUploadReportDialogComponent } from './components/excel-upload-report-dialog/excel-upload-report-dialog.component';
import { OfficeOrganogramComponent } from './components/office-organogram/office-organogram.component';
import { ArchivedWorkspaceCardComponent } from './components/archived-workspaces/archived-workspace-card/archived-workspace-card.component';
import { ArchivedWorkspaceDetailComponent } from './components/archived-workspaces/archived-workspace-detail/archived-workspace-detail.component';
import { ArchivedWorkspaceFilesComponent } from './components/archived-workspaces/archived-workspace-files/archived-workspace-files.component';
import { ArchivedWorkspacesListComponent } from './components/archived-workspaces/archived-workspaces-list/archived-workspaces-list.component';
import { FolderState } from '../files/store/folder.state';
import { FileState } from '../files/store/file.state';
import { WorkspaceState } from '../files/store/workspace.state';
import { ArchivedFolderPropertyComponent } from './components/archived-workspaces/archived-folder-property/archived-folder-property.component';
import { ArchivedFolderMoveComponent } from './components/archived-workspaces/archived-folder-move/archived-folder-move.component';
import { ArchivedFileMoveComponent } from './components/archived-workspaces/archived-file-move/archived-file-move.component';
import { UpdateStructureComponent } from './components/update-structure/update-structure.component';
import { UpdateOfficeStructureFormComponent } from './components/update-office-structure-form/update-office-structure-form.component';
import { OfficeSelectorcomponent } from './components/office-selector/office-selector.component';
import { UploadExcelDialogComponent } from './components/upload-excel-dialog/upload-excel-dialog.component';

@NgModule({
  declarations: [
    OfficeListComponent,
    OfficeFormComponent,
    OfficesHomeComponent,
    OfficeTreeComponent,
    OfficeMultipleSelectComponent,
    UploadExcelComponent,
    DownloadExcelTemplateComponent,
    ExcelUploadReportDialogComponent,
    OfficeOrganogramComponent,
    ArchivedWorkspaceCardComponent,
    ArchivedWorkspaceDetailComponent,
    ArchivedWorkspaceFilesComponent,
    ArchivedWorkspacesListComponent,
    ArchivedFolderPropertyComponent,
    ArchivedFolderMoveComponent,
    ArchivedFileMoveComponent,
    UpdateStructureComponent,
    UpdateOfficeStructureFormComponent,
    OfficeSelectorcomponent,
    UploadExcelDialogComponent,
  ],
  imports: [
    SharedModule,
    OfficesRoutingModule,
    NgxsModule.forFeature([
      OfficeState,
      FolderState,
      FileState,
      WorkspaceState,
    ]),
  ],
  exports: [OfficeTreeComponent, OfficeSelectorcomponent],
  providers: [OfficeService],
})
export class OfficesModule {}
