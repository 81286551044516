import { RESEARCH_COUNCIL_ROUTE } from 'src/app/core/constants/routes';
import { CURRENCY } from 'src/app/core/constants/requirement_constants';
import { environment } from 'src/environments/environment';

export const IS_DEVELOPMENT = false;
export const API_BASE_URL = environment.apiUrl;
export const BASE_URL = environment.baseUrl;

export const USERS_URL = `${API_BASE_URL}/users`;
export const CONTACT_US_URL = `${API_BASE_URL}/contact`;

export const ROLES_URL = `${API_BASE_URL}/roles`;
export const PERMISSIONS_URL = `${API_BASE_URL}/permissions`;

export const LOGIN_HISTORY_URL = `${USERS_URL}/login-history`;
export const LOGIN_URL = `${USERS_URL}/login`;
export const REFRESH_TOKEN_URL = `${USERS_URL}/refresh`;
export const REGISTRATION_URL = `${USERS_URL}/register`;
export const LOGOUT_URL = `${API_BASE_URL}/logout`;
export const CHANGE_PASSWORD_URL = `${USERS_URL}/change-password`;
export const FORGET_PASSWORD_URL = `${USERS_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${USERS_URL}/reset-password`;

export const TOR_URL = `${API_BASE_URL}/tors`;
export const TOR_COMMENTS_URL = `${API_BASE_URL}/tor-comments`;

export const OFFICES_URL = `${API_BASE_URL}/offices`;
export const OFFICES_TREE_URL = `${OFFICES_URL}/tree`;
export const FLAT_OFFICE_NODES_URL = `${OFFICES_URL}/flat-nodes`;
export const UPLOAD_OFFICES_EXCEL = `${OFFICES_URL}/upload-excel`;
export const DOWNLOAD_EXCEL_TEMPLATE = `${OFFICES_URL}/template-excel`;
export const OFFICE_TREE_NODES_URL = `${OFFICES_URL}/office-tree`;
export const REPORTS_TO_FLAT_NODES = `${OFFICES_URL}/ancestor-nodes`;
export const USER_OFFICE_TREE_URL = `${OFFICES_URL}/user-office-tree`;

export const FILES_URL = `${API_BASE_URL}/files`;
export const FOLDER_FILES = `${API_BASE_URL}/files/folder`;

export const WORKSPACES_URL = `${API_BASE_URL}/workspaces`;
export const MY_WORKSPACE_URL = `${API_BASE_URL}/workspaces/my-workspace`;
export const STORAGE_INFO_URL = `${API_BASE_URL}/workspaces/storageInfo`;
export const FOLDERS_URL = `${API_BASE_URL}/folders`;

export const FILE_BOOKMARKS_URL = `${API_BASE_URL}/file-bookmarks`;
export const FOLDER_BOOKMARKS_URL = `${API_BASE_URL}/folder-bookmarks`;
export const WORKSPACE_HUB_URL = `${API_BASE_URL}/workspaceHub`;
export const NOTIFICATION_HUB_URL = `${API_BASE_URL}/notificationHub`;

export const STAGE_URL = `${API_BASE_URL}/stages`;
export const PROCESSES_URL = `${API_BASE_URL}/processes`;
export const CRITERION_URL = `${API_BASE_URL}/criteria`;
export const STAGES_URL = `${API_BASE_URL}/stages`;
export const SCHEDULE_URL = `${API_BASE_URL}/evaluation-schedules`;
export const TASK_URL = `${API_BASE_URL}/tasks`;
export const STAGE_INSTANCE_TASK_URL = `${API_BASE_URL}/tasks`;
export const PROCESS_INSTANCES_URL = `${API_BASE_URL}/process-instances`;
export const TASKS_URL = `${API_BASE_URL}/tasks`;
export const TASK_COMMENTS_URL = `${API_BASE_URL}/task-comments/tasks`;
export const TASK_COMMENTS_ROOT_URL = `${API_BASE_URL}/task-comments`;
export const TASK_COMMENTS_REPLY_URL = `${API_BASE_URL}/task-comments/reply`;

export const PROCESS_TASK_URL = `${API_BASE_URL}/tasks`;
export const TASK_FILES_URL = `${API_BASE_URL}/file-tasks`;

export const TASK_DOCUMENT_URL = `${API_BASE_URL}/document-tasks`;
export const STAGE_INSTANCES_URL = `${API_BASE_URL}/stage-instances`;
export const STAGE_INSTANCES_EVALUATION_URL = `${API_BASE_URL}/evaluation`;
export const CHANGE_STAGE_INSTANCES_STATUS_URL = `${API_BASE_URL}/stage-instances/change-status`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_WAITING_FOR_APPROVAL_URL = `${API_BASE_URL}/stage-instances/change-status/waiting-for-approval`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_IN_PROGRESS_URL = `${API_BASE_URL}/stage-instances/change-status/in-progress`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_APPROVED_URL = `${API_BASE_URL}/stage-instances/change-status/approved`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_REJECTED_URL = `${API_BASE_URL}/stage-instances/change-status/rejected`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_TERMINATED_URL = `${API_BASE_URL}/stage-instances/change-status/terminated`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_NEEDS_REVISION_URL = `${API_BASE_URL}/stage-instances/change-status/needs-revision`;
export const CHANGE_STAGE_INSTANCES_STATUS_TO_SUBMITTED_TO_EVALUATION_URL = `${API_BASE_URL}/stage-instances/change-status/submitted-to-evaluation`;

export const EVALUATION_URL = `${API_BASE_URL}/evaluation`;
export const MEETING_SCHEDULE_URL = `${API_BASE_URL}/meeting-schedules`;

export const NOTIFICATION_URL = `${API_BASE_URL}/notifications`;
export const NOTIFICATION_STATUS_URL = `${API_BASE_URL}/notifications/status`;
export const MARK_NOTIFICATION_URL = `${API_BASE_URL}/notifications/mark-as-read`;

export const VALIDATION_RULES_URL = `${API_BASE_URL}/field-validation-rules/validation-rules`;
export const DATE_TIME_URL = `${API_BASE_URL}/date-time`;

export const WORKFLOW_TYPES_URL = `${API_BASE_URL}/workflow-types`;

export const WORKFLOW_STEP_URL = `${API_BASE_URL}/workflow-steps`;

export const DOCUMENT_TEMPLATE_URL = `${API_BASE_URL}/document-templates`;
export const ARCHIVED_DOCUMENT_TEMPLATE_URL = `${DOCUMENT_TEMPLATE_URL}/archived`;

export const TEMPLATE_DRAFT_URL = `${DOCUMENT_TEMPLATE_URL}/drafts`;

export const TEMPLATE_FIELDS_URL = `${API_BASE_URL}/template-fields`;

export const FIELD_VALIDATION_RULES_URL = `${API_BASE_URL}/field-validation-rules`;

export const WORKFLOW_TYPE_STEP_URL = `${API_BASE_URL}/workflow-type-steps`;

export const WORKFLOW_URL = `${API_BASE_URL}/workflows`;
export const ARCHIVED_WORKFLOWS_URL = `${WORKFLOW_URL}/archived`;
export const DELEGATION_URL = `${API_BASE_URL}/delegations`;

export const WORKFLOW_COMMENT_URL = `${API_BASE_URL}/workflow-comments`;

export const WORKFLOW_TEMPLATES_URL = `${WORKFLOW_URL}/workflow-templates`;
export const WORKFLOW_VALIDATION_URL = `${WORKFLOW_URL}/workflow-validations`;
export const WORKFLOW_COMMENT_POLICY_URL = `${API_BASE_URL}/workflow-policy`;
export const EXTERNAL_DOCUMENT_URL = `${API_BASE_URL}/external-documents`;
export const NOTICES_URL = `${API_BASE_URL}/notices`;

// memos
export const MEMOS_URL = `${API_BASE_URL}/memos`;
export const MEMOS_RECEIVED_URL = `${API_BASE_URL}/memos/received`;

export const RESEARCH_COUNCIL_URL = `${API_BASE_URL}/research-council`;
export const ACTIVITY_TYPES_URL = `${API_BASE_URL}/activity-type`;
export const QUANTITY_TYPES_URL = `${API_BASE_URL}/quantity-type`;
export const EVALUATION_SCHEDULE_URL = `${API_BASE_URL}/evaluation-schedules`;

export const WORKFLOW_STEP_VALIDATION_URL = `${API_BASE_URL}/workflow-type-step-validations`;
export const WORKFLOW_STEP_VALIDATION_RULES_URL = `${WORKFLOW_STEP_VALIDATION_URL}/rules`;

export const TEMPLATE_APPROVED_URL = `${DOCUMENT_TEMPLATE_URL}/approved`;

export const PUBLICATION_OPTION_URL = `${API_BASE_URL}/publication-option`;
export const EDITOR_TYPE_URL = `${API_BASE_URL}/editor-type`;
export const PUBLICATIONS_URL = `${API_BASE_URL}/publications`;
export const ANALYTICS_URL = `${API_BASE_URL}/analytics`;
export const OFFICES_ANALYTICS_URL = `${ANALYTICS_URL}/offices`;
export const DOCUMENTS_ANALYTICS_URL = `${ANALYTICS_URL}/documents`;

export const RESEARCHES_ANALYTICS_URL = `${ANALYTICS_URL}/researches`;
export const BI_COMMUNICATION_VS_TIME_URL = `${RESEARCHES_ANALYTICS_URL}/communications-time-graph`;
export const BI_COMMUNICATION_COUNT = `${RESEARCHES_ANALYTICS_URL}/communications-count`;
export const BI_RESEARCH_COUNT = `${RESEARCHES_ANALYTICS_URL}/research-count`;
export const BI_COMMUNICATION_TYPE_INSTANCES = `${RESEARCHES_ANALYTICS_URL}/communication-types-vs-instances`;
export const TOR_ANALYTICS_URL = `${ANALYTICS_URL}/tors`;
export const BI_BUDGET = `${RESEARCHES_ANALYTICS_URL}/budget-time-graph`;
export const FILES_ANALYTICS_URL = `${ANALYTICS_URL}/files`;

export const CURRENCY_URL = `${API_BASE_URL}/currency`;
