import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Quill from 'quill';
import { Observable, take } from 'rxjs';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { ExternalDocumentFacade } from '../facades/external-document-workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import {
  AFileDto,
  ExternalDocumentDetailDto, ExternalDocumentResponseDetailDto,
  ResponseStatus
} from '../models/external-document.model';

import { EXTERNAL_DOCUMENT_RESPONSE_ROUTE, EXTERNAL_DOCUMENT_ROUTE } from 'src/app/core/constants/routes';

interface ExternalDocumentResponseComponentState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  externalDocumentDetail: ExternalDocumentDetailDto | undefined;
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;
  update: boolean;
}

const init: ExternalDocumentResponseComponentState = {
  selectedFlatOfficeNode: undefined,
  externalDocumentResponseDetail: undefined,
  externalDocumentDetail: undefined,
  update: false,
};

@Component({
  selector: 'app-external-document-response-form',
  templateUrl: './external-document-response-form.component.html',
  styleUrls: ['./external-document-response-form.component.scss'],
})
export class ExternalDocumentResponseFormComponent {
  responseForm: FormGroup;
  update$: Observable<boolean> = this.state.select('update');
  update: boolean = false;
  files?: File[] = [];
  isDragOver = false;
  externalDocumentId: string = '';
  uploadedFiles?: AFileDto[] = [];
  externalDocumentDetail: ExternalDocumentDetailDto | undefined;
  externalDocumentDetail$ = this.state.select('externalDocumentDetail');

  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  externalDocumentResponseDetail$: Observable<
    ExternalDocumentResponseDetailDto | undefined
  > = this.state.select('externalDocumentResponseDetail');
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;

  editor?: Quill;
  composeIsValid: boolean = false;
  initialEditorContent: any;
  selectedResponse: ExternalDocumentResponseDetailDto | undefined;

  placeholderFieldLabel = {
    responseSubjectField: $localize`:@@documents.external-document-response-form.response-subject: Response Subject`,
    destinationField: $localize`:@@documents.external-document-response-form.to: To`,
  };

  constructor(
    private responseFacade: ExternalDocumentFacade,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private router: Router,
    private officeFacade: OfficeFacade,
    private externalDocumentFacade: ExternalDocumentFacade,
    private state: RxState<ExternalDocumentResponseComponentState>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
    this.responseForm = this.fb.group({
      destinationOffice: [
        this.selectedResponse?.destinationOffice,
        [Validators.required],
      ],
      subject: [this.selectedResponse?.subject, [Validators.required]],
    });

    this.state.set(init);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect(
      'externalDocumentDetail',
      this.externalDocumentFacade.selectedExternalDocument$,
    );
    this.state.connect(
      'externalDocumentResponseDetail',
      this.externalDocumentFacade.externalDocumentResponseDetail$,
    );
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const responseId = params.get('id');
      if (responseId)
        this.externalDocumentFacade.dispatchGetExternalDocumentResponseDetail(
          responseId,
        );
    });
    this.externalDocumentDetail$.subscribe((item) => {
      this.externalDocumentDetail = item;
    });
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
    this.update$.subscribe((updateStatus) => {
      this.update = updateStatus;
    });
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });
    this.externalDocumentResponseDetail$.subscribe((respDetail) => {
      this.externalDocumentResponseDetail = respDetail;
      this.selectedResponse = respDetail;
      if (respDetail?.files) this.uploadedFiles = [...respDetail?.files];
    });
    this.editor = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });
    if (this.selectedResponse) {
      if (this.selectedResponse.status !== 'Draft')
        this.officeFacade.dispatchSetSelectedFlatOfficeNode(
          this.selectedResponse.remarkActions[0].nextOfficeId,
        );

      this.files = (this.externalDocumentResponseDetail?.files as any)?.map(
        (aFileDto: AFileDto) => {
          const blob = new Blob([], { type: 'application/octet-stream' });
          const file: File = new File([blob], aFileDto.name, {
            lastModified: new Date(aFileDto.lastModified).getTime(),
          });

          return file;
        },
      );

      this.handleEditorInitContent(this.selectedResponse);
      this.responseForm.setValue({
        subject: this.selectedResponse.subject,
        destinationOffice: this.selectedResponse.destinationOffice,
      });
    }
    this.editor.on('text-change', this.handleTextChange);
  }
  handleEditorInitContent(response: ExternalDocumentResponseDetailDto) {
    if (response.body && this.editor)
      this.editor.setContents(JSON.parse(response.body), 'api');
  }

  ngOnDestroy(): void {
    this.editor?.off('text-change', this.handleTextChange);
    this.uploadedFiles = [];
  }

  handleTextChange = () => {
    this.composeIsValid = !!this.editor?.getText().trim();
  };
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      this.files = Array.from(files);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter(
          (file) =>
            !this.files?.some(
              (existingFile) => existingFile.name === file.name,
            ),
        );
        this.files = this.files?.concat(newFiles);
      }
    };
    fileInput.click();
  }

  removeFile(index: number) {
    this.files?.splice(index, 1);
    if (this.externalDocumentResponseDetail?.status === ResponseStatus.Draft) {
      this.uploadedFiles?.splice(index, 1);
    }
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.files = this.files?.concat(Array.from(event.target.files));
    }
  }
  buttonDisabled() {
    if (!this.responseForm.valid) return true;
    else if (this.isEditorEmpty() && this.files?.length === 0) return true;
    return false;
  }

  isEditorEmpty() {
    if (this.editor) {
      if ((this.editor.getContents()['ops'] || []).length !== 1) {
        return false;
      }
      return this.editor.getText().trim().length === 0;
    }
    return false;
  }

  composeResponse() {
    this.externalDocumentId =
      this.router
        .parseUrl(this.router.url)
        .root.children[PRIMARY_OUTLET].segments.at(-1)
        ?.toString() ?? '';
    this.externalDocumentId = this.externalDocumentDetail?.id ?? '';
    const { valid } = this.responseForm;
    if (this.editor && valid) {
      const { destinationOffice, subject } = this.responseForm.value;
      const officeId = this.selectedFlatOfficeNode?.id;
      const body = JSON.stringify(this.editor.getContents());
      const formData = this.organaizeResponse(
        destinationOffice,
        body,
        subject,
        officeId,
      );
      return formData;
    }
    return;
  }

  handleResponseAction(send: boolean) {
    let dispatch;
    if (!this.selectedResponse) {
      const formData = this.composeResponse();
      if (!formData) return;
      dispatch = this.responseFacade.dispatchCreateResponse(formData, send);
    } else {
      const formData = this.composeResponse();
      if (!formData) return;
      dispatch = this.responseFacade.dispatchUpdateResponse(formData, send);
    }

    dispatch.pipe(take(1)).subscribe(() => {
      const externalDocument = this.state.get('externalDocumentDetail');
      if (send && externalDocument)
        this.router.navigate([
          `${EXTERNAL_DOCUMENT_ROUTE}/detail`,
          externalDocument.id,
        ]);
    });
  }
  organaizeResponse(
    destinationOffice: string,
    body: string,
    subject: string,
    nextOfficeId?: string,
  ): FormData {
    const formData = new FormData();
    if (this.externalDocumentResponseDetail?.status === ResponseStatus.Draft)
      for (const file of this.files ?? []) {
        if (
          this.externalDocumentResponseDetail?.files?.find(
            (f) => f.name === file.name,
          ) === undefined
        ) {
          formData.append('Files', file);
        }
      }
    else
      this.files?.forEach((file) => {
        formData.append('Files', file);
      });
    formData.append('ComposedDocument', body);
    formData.append('Subject', subject);
    formData.append('DestinationOffice', destinationOffice);
    formData.append('ExternalDocumentId', this.externalDocumentId);
    if (nextOfficeId) formData.append('NextOfficeId', nextOfficeId);
    if (this.uploadedFiles?.length ?? 0 > 0) {
      formData.append(
        'UploadedFilesIds',
        this.uploadedFiles!.map((file) => file.id).join(','),
      );
    }
    return formData;
  }
  openOfficeSelector() {
    const dialogRef = this.dialog.open(OfficeTreeComponent, {
      data: {
        update: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.handleResponseAction(true);
      }
    });
  }
}
