import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { StageInstanceFacade } from 'src/app/researches/facades/stage-instance.facade';
import {
  ProcessInstanceDetail,
  StageInstance,
} from 'src/app/researches/models/process-instance.model';
import { Review } from 'src/app/researches/models/stage-instance-detail.model';

interface RecievedReviewsComponentState {
  ownedProcessInstances: PaginatedList<ProcessInstanceDetail> | undefined;
  reviews: Review[];
}
const initRecievedReviewsComponentState: RecievedReviewsComponentState = {
  ownedProcessInstances: undefined,
  reviews: [],
};
@Component({
  selector: 'app-recieved-reviews',
  templateUrl: './recieved-reviews.component.html',
  styleUrl: './recieved-reviews.component.scss',
  providers: [{provide: MatPaginatorIntl, useClass: RecievedReviewsComponent}],
})
export class RecievedReviewsComponent implements OnInit, MatPaginatorIntl {
  ownedProcessInstances$ = this.state.select('ownedProcessInstances');
  ownedProcessInstances: PaginatedList<ProcessInstanceDetail> | undefined;
  reviews$ = this.state.select('reviews');
  reviews: Review[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChildren(MatExpansionPanel) stagePanels!: QueryList<MatExpansionPanel>;

  firstPageLabel = $localize`:@@researches.recieved-reviews.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.recieved-reviews.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.recieved-reviews.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.recieved-reviews.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.recieved-reviews.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.recieved-reviews.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.recieved-reviews.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.recieved-reviews.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<RecievedReviewsComponentState>,
    private stageInstanceFacade: StageInstanceFacade,
    private processInstanceFacade: ProcessInstanceFacade,
    private router: Router,
  ) {
    this.state.set(initRecievedReviewsComponentState);
    this.state.connect('reviews', this.stageInstanceFacade.reviews$);
    this.state.connect(
      'ownedProcessInstances',
      this.processInstanceFacade.ownedProcessInstances$,
    );
  }

  changes = new Subject<void>();

  ngOnInit() {
    this.processInstanceFacade.dispatchGetOwnedProcessInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.ownedProcessInstances$.subscribe((processInstances) => {
      if (processInstances) {
        this.ownedProcessInstances = processInstances;
      }
    });
  }
  loadPaginatedProcessInstances(event: PageEvent) {
    this.processInstanceFacade.dispatchGetOwnedProcessInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
  }

  currentlyOpenPanel: MatExpansionPanel | null = null;

  getReviews(
    event: MouseEvent,
    stageInstance: StageInstance,
    panel: MatExpansionPanel,
  ): void {
    if (this.currentlyOpenPanel && this.currentlyOpenPanel !== panel) {
      this.currentlyOpenPanel.close();
    }
    this.currentlyOpenPanel = panel;
    this.reviews = [];
    this.stageInstanceFacade.dispatchGetStageInstanceReviews(
      stageInstance.id as string,
    );
    this.reviews$.subscribe((review) => {
      if (review) {
        this.reviews = review;
      }
    });
  }

  hasReviewedStage(process: any): boolean {
    return process.stageInstances.some((stage: any) => 
      stage.reviews && stage.reviews.some((review: any) => review.reviewStatus === 'Submitted')
    );
  }
  
  hasGetStageInstanceReviewsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetStageInstanceReviews,
    );
  }
}
