import {
  DOWNLOAD_EXCEL_TEMPLATE,
  FLAT_OFFICE_NODES_URL,
  OFFICES_TREE_URL,
  OFFICES_URL,
  OFFICE_TREE_NODES_URL,
  REPORTS_TO_FLAT_NODES,
  UPLOAD_OFFICES_EXCEL,
  USER_OFFICE_TREE_URL,
} from '../../core/constants/api-endpoints';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateOfficeDto,
  IUpdateOfficeStructure,
  Office,
  UpdateOfficeDto,
} from '../models/office.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OfficeTree, OfficeTreeNode } from '../models/office-tree.model';
import { FlatOfficeNode } from '../models/flat-office-node.model';
import { ExcelUploadReport } from '../models/excel-upload-report.model';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getOfficesTree(): Observable<OfficeTree[]> {
    return this.http.get<OfficeTree[]>(`${OFFICES_TREE_URL}`);
  }

  registerOffice(office: CreateOfficeDto): Observable<any> {
    return this.http.post<any>(`${OFFICES_URL}`, office, this.httpOptions);
  }

  getOffices(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Office>> {
    const url = `${OFFICES_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedList<Office>>(url);
  }

  getFlatOfficeNodes() {
    return this.http.get<FlatOfficeNode[]>(`${FLAT_OFFICE_NODES_URL}`);
  }

  getReportsToFlatNodes(officeId: string) {
    return this.http.get<FlatOfficeNode[]>(
      `${REPORTS_TO_FLAT_NODES}/${officeId}`,
    );
  }

  getOfficeTreeNodes() {
    return this.http.get<OfficeTreeNode>(`${OFFICE_TREE_NODES_URL}`);
  }

  updateOfficeTreeStructure(updateStructure: IUpdateOfficeStructure) {
    return this.http.put<any>(
      `${OFFICE_TREE_NODES_URL}`,
      updateStructure,
      this.httpOptions,
    );
  }

  updateOffice(office: UpdateOfficeDto): Observable<Office> {
    const url = `${OFFICES_URL}`;
    return this.http.put<Office>(url, office, this.httpOptions);
  }

  uploadExcelFile(formData: FormData): Observable<HttpEvent<ExcelUploadReport[]>> {
    return this.http.post<ExcelUploadReport[]>(
      `${UPLOAD_OFFICES_EXCEL}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  downloadExcelTemplate(): Observable<any> {
    const url = DOWNLOAD_EXCEL_TEMPLATE;
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(url, options);
  }

  deleteOffice(id: string): Observable<void> {
    const url = `${OFFICES_URL}/${id}`;
    return this.http.delete<void>(url);
  }

  getOffice(id: string): Observable<Office> {
    const url = `${OFFICES_URL}/${id}`;
    return this.http.get<Office>(url);
  }

  getUserOfficeTree(): Observable<FlatOfficeNode[]> {
    return this.http.get<FlatOfficeNode[]>(`${USER_OFFICE_TREE_URL}`);
  }
}
