<div>
  <div class="flex-row space-between">
    <h2 i18n="@@users.assign-revoke-roles-users.assign-revoke-roles" class="mat-h1">Assign/Revoke Roles</h2>
    <button mat-flat-button color="primary" (click)="submit()" class="" i18n="@@users.assign-revoke-roles-users.save">
      Save
    </button>
  </div>

  <div class="flex-row space-between">
    <div class="flex-row gap-10">
      <h4 class="padding-right" i18n="@@users.assign-revoke-roles-users.user-name" class="mat-subtitle-1">User name:</h4>
      <h3 class="mat-subtitle-1">{{ selectedUser?.firstName }} {{ selectedUser?.lastName }}</h3>
    </div>
  </div>

  <div class="role-list">
    <div class="role-list-item" *ngFor="let r of roles">
      <mat-checkbox
        [(ngModel)]="r.checked"
        (click)="$event.stopPropagation()"
        (change)="updateRoleState($event.checked, r.id)"
      >
        <div class="feature-name">
          {{ r.name }}
        </div>
      </mat-checkbox>
    </div>
  </div>
</div>
