import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, take } from 'rxjs';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { FieldValidationRuleFacade } from 'src/app/documents/field-validation-rules/facades/field-validation-rules.facade';
import { ValidationRule } from 'src/app/documents/field-validation-rules/models/validation-rule.model';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { WorkflowTypeStepDto } from 'src/app/documents/workflow-type-steps/models/workflow-type-steps.model';
import { OfficeSelectorcomponent } from 'src/app/offices/components/office-selector/office-selector.component';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { WorkflowValidationFacade } from '../../facade/workflow-validation.facades';
import { WorkflowStepValidationRule } from '../../models/workflow-step-validation-rule.model';
import { WorkflowValidation } from '../../models/workflow-validation.model';
import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import { DataType } from 'src/app/documents/shared/models/data-types.model';
import { UpdateWorkflowStepValidationDto, WorkflowStepValidation } from '../../models/worlfow-step-validation.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface WorkflowStepValidationState {
  flatOfficeNodes: FlatOfficeNode[];
  documentTemplates: DocumentForm[];
  validationRules: WorkflowStepValidationRule[];
  selectedValidation?: WorkflowValidation;
  documentForm?: DocumentForm;
}

const initialState: WorkflowStepValidationState = {
  flatOfficeNodes: [],
  documentTemplates: [],
  validationRules: [],
};

type TemplateFormType = FormGroup<{
  id: FormControl<string>;
  dataType: FormControl<DataType>;
  selected: FormControl<boolean>;
}>;

@Component({
  selector: 'app-workflow-step-validation',
  templateUrl: './workflow-step-validation.component.html',
  styleUrls: ['./workflow-step-validation.component.scss'],
})
export class WorkflowStepValidationComponent implements OnInit, AfterViewInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  isDialogOpen=false;
  documentTemplates$ = this.state.select('documentTemplates');
  operators$ = this.state.select('validationRules');
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
  this.state.select('flatOfficeNodes');

  officeNodes: Array<FlatOfficeNode> = [];

  workflowTypeStepId?: string = this.data.workflowStep.id;

  placeholderFieldLabel = {
    valueField: $localize`:@@documents.workflow-step-validation.value: Value`,
    officeField: $localize`:@@documents.workflow-step-validation.office: Office`,
  };

  documentForm$ = this.state.select('documentForm');

  constructor(
    private fb: NonNullableFormBuilder,
    private destoryRef: DestroyRef,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private state: RxState<WorkflowStepValidationState>,
    private officeFacade: OfficeFacade,
    private documentFormFacade: DocumentFormFacade,
    private workflowValidationFacade: WorkflowValidationFacade,
    private dialogRef: MatDialogRef<WorkflowStepValidationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workflowStep: WorkflowTypeStepDto;
      selectedValidation?: WorkflowStepValidation;
    },
  ) {
    this.state.set(initialState);
    this.state.connect(
      'documentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
    this.state.connect('flatOfficeNodes', officeFacade.flatOfficeNodes$);
  }

  workflowStepValidationForm = this.fb.group(
    {
      successOffice: [
        this.data.selectedValidation?.onSuccessOfficeId
          ? this.getFlatOfficeNodeById(this.data.selectedValidation.onSuccessOfficeId) as null | FlatOfficeNode
          : null,
        Validators.required,
      ],
      failureOffice: [
        this.data.selectedValidation?.onFailureOfficeId
          ? this.getFlatOfficeNodeById(this.data.selectedValidation.onFailureOfficeId) as null | FlatOfficeNode
          : null,
        Validators.required,
      ],
      leftField: [
        this.data.selectedValidation?.leftField as null | FormFields,
        Validators.required,
      ],
      rightField: [this.data.selectedValidation?.rightField as null | FormFields, Validators.required],
      validationRule: [
        this.data.selectedValidation?.workflowTypeStepValidationRule as Required<ValidationRule> | null,
        Validators.required,
      ],
    },
    { validators: this.validateFields },
  );

  ngAfterViewInit(): void {
    this.workflowStepValidationForm.controls.leftField.valueChanges
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((val) => {
        if (val)
          this.workflowValidationFacade.dispatchGetWorkflowStepValidationRules(
            val.dataType,
          );
      });
  }

  ngOnInit(): void {
    this.state.connect(
      'documentTemplates',
      this.workflowValidationFacade.documentTemplates$,
    );

    this.state.connect(
      'validationRules',
      this.workflowValidationFacade.workflowValidationRules$,
    );
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.flatOfficeNodes$.subscribe((flatOfficeNodes) => {
      this.officeNodes = flatOfficeNodes;
      let successOfficeValue=this.workflowStepValidationForm.get('successOffice')?.value;
      let failureOfficeValue=this.workflowStepValidationForm.get('failureOffice')?.value;
      this.workflowStepValidationForm.patchValue({
        successOffice: successOfficeValue 
        ? successOfficeValue
        : this.data.selectedValidation?.onSuccessOfficeId 
        ? this.getFlatOfficeNodeById(
          this.data.selectedValidation?.onSuccessOfficeId,
        ) 
        : null,
        failureOffice: failureOfficeValue ?
         failureOfficeValue
        : this.data.selectedValidation?.onFailureOfficeId ? 
          this.getFlatOfficeNodeById(
            this.data.selectedValidation?.onFailureOfficeId,
          )
          : null,
      });
    });
    this.data.workflowStep.documentTemplateId &&
      this.documentFormFacade.dispatchGetDocumentForm(
        this.data.workflowStep.documentTemplateId,
      );
  }

  submit() {
      if (!this.workflowStepValidationForm.valid || !this.workflowTypeStepId)
        return;
      
    const { valid, touched, dirty } = this.workflowStepValidationForm;
    if (valid && (touched || dirty)) {
      const formValues = this.workflowStepValidationForm.getRawValue();
      const data = {
        id: this.data?.selectedValidation?.id,
        successOfficeId: formValues.successOffice!.id,
        failureOfficeId: formValues.successOffice!.id,
        leftFieldId: formValues.leftField!.id!,
        rightFieldId: formValues.rightField!.id!,
        validationRuleId: formValues.validationRule!.id,
        workflowTypeStepId: this.workflowTypeStepId,
      };
    

    let dispatch;
    if (this.data.selectedValidation) {
      dispatch =
        this.workflowValidationFacade.dispatchUpdateWorkflowStepValidation(
          data as UpdateWorkflowStepValidationDto,
        );
    } else {
      dispatch =
        this.workflowValidationFacade.dispatchCreateWorflowStepValidation(data);
    }

    dispatch.pipe(take(1)).subscribe(() => this.dialogRef.close());
  }
}

  openFailureOfficeTree(event: MouseEvent) {
    event.stopPropagation();
    if(this.isDialogOpen) return;
    this.isDialogOpen=true;
    this.dialog
      .open(OfficeSelectorcomponent, {
        disableClose: true,
        data: { update: true,
           selectedOffice:this.workflowStepValidationForm.get('failureOffice')?.value },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((val) => {
        this.isDialogOpen=false;
        if (val)
          this.workflowStepValidationForm.patchValue({
            failureOffice: val as FlatOfficeNode,
          });
      });
  }

  openSuccessOfficeTree(event: MouseEvent) {
    event.stopPropagation()
    if(this.isDialogOpen) return;
    this.isDialogOpen=true;
    this.dialog
      .open(OfficeSelectorcomponent, {
        disableClose: true,
        data: { update: true,
           selectedOffice: this.workflowStepValidationForm.get('successOffice')?.value },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((val) => {
        this.isDialogOpen=false;
        if (val)
          this.workflowStepValidationForm.patchValue({
            successOffice: val as FlatOfficeNode,
          });
      });
  }

  compareWith(obj1: any, obj2: any): boolean {
    return obj1?.id == obj2?.id;
  }

  getFlatOfficeNodeById(id: string): FlatOfficeNode | undefined {
    return this.officeNodes.find((node) => node.id === id);
  }

  validateFields(control: AbstractControl) {
    const leftField = control.get('leftField');
    const rightField = control.get('rightField');

    if (!leftField?.value || !rightField?.value) return null;

    if (leftField.value?.dataType != rightField.value?.dataType)
      rightField.setErrors({ dataTypeMismatch: true });

    return null;
  }

  hasCreateWorflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.CreateWorkflowStepValidation,
    );
  }

  hasUpdateWorflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.UpdateWorkflowStepValidation,
    );
  }

  hasGetWorflowStepValidationsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.GetWorkflowStepValidations,
    );
  }

  hasGetWorflowStepValidationRulesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.GetWorkflowStepValidationRules,
    );
  }
}
