import { Component, OnInit } from '@angular/core';
import { CurrencyFacade } from '../../facades/currency.facade';
import { RxState } from '@rx-angular/state';
import { filter, take } from 'rxjs';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrencyData } from '../../tor-settings/models/currency.model';

interface ResearchesSettingsComponentState {
  templateExcelFileUrl: string | null;
  currencies: CurrencyData[];
}

const initResearchesSettingsComponentState: ResearchesSettingsComponentState = {
  templateExcelFileUrl: null,
  currencies: []
};

@Component({
  selector: 'app-researches-settings',
  templateUrl: './researches-settings.component.html',
  styleUrl: './researches-settings.component.scss',
})
export class ResearchesSettingsComponent implements OnInit {
  templateExcelFileUrl$ = this.state.select('templateExcelFileUrl');
  selectedFile: File | null = null;
  currencies$ = this.state.select('currencies');
  currencies: CurrencyData[] = [];
  selectedDefaultCurrency: string | undefined;
  defaultCurrency: any;
  showDefaultForm: boolean = false;
  currencySetting:any;
  torSetting:any;
  constructor(
    private currencyFacade: CurrencyFacade,
    private state: RxState<ResearchesSettingsComponentState>,
  ) {
    this.state.set(initResearchesSettingsComponentState);
    this.state.connect(
      'templateExcelFileUrl',
      currencyFacade.templateExcelFileUrl$,
    );
    this.state.connect('currencies',currencyFacade.currencies$);
    this.currencySetting= $localize`:@@researches.researches-settings.currency-setting:Currency Settings`;
    this.torSetting= $localize`:@@researches.researches-settings.tor-settings:Tor Settings`;
  }
  ngOnInit(): void {
    this.currencyFacade.dispatchGetCurrencies()
    this.currencies$.subscribe((currencies)=>{
      this.currencies = currencies;
      this.defaultCurrency = this.currencies.find((currency) => currency.isDefault === true)?.currencyName as string;
    })
  }

  selectFile(event: any) {
    this.selectedFile = event.target.files[0];
    event.target.files[0] = null;
  }

  uploadExcel() {
    if (this.selectedFile) {
      let dispatched = this.currencyFacade.dispatchUploadCurrencyExcel(this.selectedFile);

      dispatched.subscribe(()=>{
        this.selectedFile = null
      })
    }
  }

  DownloadTemplate() {
    this.currencyFacade.dispatchDownloadCurrencyExcelTemplate();
    this.templateExcelFileUrl$
      .pipe(
        filter((fileUrl) => fileUrl !== null),
        take(1),
      )
      .subscribe((fileUrl) => {
        if (fileUrl) {
          const downloadLink = document.createElement('a');
          downloadLink.href = fileUrl;
          downloadLink.download = 'currencyTemplate.xlsx';
          downloadLink.click();
        }
      });
  }

  getIconForFile(): any {
    return faFileExcel;
  }

  extractFileNameAndExtension(): any {
    const lastIndex = this.selectedFile?.name.lastIndexOf('.');
    if (lastIndex !== -1) {
      const name = this.selectedFile?.name.substring(0, lastIndex);
      const extension = this.selectedFile?.name.substring(lastIndex! + 1);
      return { name, extension };
    } else {
      return { name: this.selectedFile?.name, extension: '' };
    }
  }

  removeSelectedFile() {
    this.selectedFile = null;
  }

  setDefaultCurrency(){
    this.currencyFacade.dispatchSetDefaultCurrency(this.selectedDefaultCurrency as string);
    this.toggleSetDefaultForm()
  }

  toggleSetDefaultForm(){
    this.showDefaultForm = !this.showDefaultForm
  }

  hasDownloadCurrencyExcelTemplatePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Currency.Feature,
      PERMISSION_NAMES.Researches.Currency.DownloadCurrencyExcelTemplate,
    );
  }

  hasUploadCurrencyExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Currency.Feature,
      PERMISSION_NAMES.Researches.Currency.UploadCurrencyExcel,
    );
  }

  hasUploadSetDefaultCurrencyPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Currency.Feature,
      PERMISSION_NAMES.Researches.Currency.SetDefaultCurrency,
    );
  }
}
