<mat-dialog-content>
  <div class="alignment">
    <button mat-raised-button (click)="openSingleOfficeSelector()"
    i18n="@@documents.memo-cc-form.select-office" [attr.data-test-id]="'documents.cc-user-in-memo.memo-cc-form.office-selector'">
      Select office
    </button>
    <mat-chip *ngIf="selectedFlatOfficeNode" (removed)="removeSelectedOffice()">
      {{ selectedFlatOfficeNode.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </div>
  <form>
    <div class="alignment">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@documents.memo-cc-form.role">Role</mat-label>
        <mat-select (selectionChange)="onRoleSelect($event)" [attr.data-test-id]="'documents.cc-user-in-memo.memo-cc-form.role-selector'">
          <mat-option *ngFor="let role of roles" [value]="role" [attr.data-test-id]="'role-option' + role.id">{{
            role.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-chip *ngIf="selectedRole" (removed)="removeSelectedRole()">
        {{ selectedRole.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </div>
    <div class="alignment" *ngIf="filteredUsers.length > 0; else emptyUserList">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@documents.memo-cc-form.user">User</mat-label>
        <mat-select (selectionChange)="onUserSelect($event)" [attr.data-test-id]="'documents.cc-user-in-memo.memo-cc-form.user-selector'">
          <mat-option *ngFor="let user of filteredUsers" [value]="user" [attr.data-test-id]="'user-option' + user.id">{{
            user.firstName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-chip *ngIf="selectedUser" (removed)="removeSelectedUser()">
        {{ selectedUser.firstName }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action-buttons">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]=""
    mat-dialog-close="save"
   i18n="@@documents.memo-cc-form.save">
    Save
  </button>
  <button class="submit" mat-flat-button color="accent" mat-dialog-close i18n="@@documents.memo-cc-form.cancel">
    Cancel
  </button>
</mat-dialog-actions>

<ng-template #emptyUserList>
  <div class="empty-users-list">
    <span i18n="@@documents.memo-cc-form.no-users-found-with-this-filter-parameter">No users found with this filter parameter.</span>
  </div>
</ng-template>
