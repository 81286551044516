import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  StageInstanceDetail,
  StageInstanceEvaluation,
  StageInstanceEvaluationStatus,
  StageInstanceStatus,
  StageInstanceTask,
  UserStageInstanceEvaluation,
} from '../models/stage-instance-detail.model';
import { Injectable } from '@angular/core';
import { StageInstanceDetailService } from '../services/stage-instance-detail.service';
import {
  AddSageInstanceTask,
  ApproveStageInstance,
  GetStageEvaluation,
  ChangeStageStatus,
  CheckEveryTasksApproval,
  GetStageInstanceDetail,
  GetStageInstanceTasks,
  RejectStageInstance,
  GetStageInstanceEvaluationStatus,
  GetTasksByTaskType,
  UpdateTaskFromStageInstanceDetail,
  DeleteTaskFromStageInstanceDetail,
  SetIsEveryTaskApprovedStatus,
  GetUserStageEvaluation,
  UpdateStageEvaluation,
  StageStatusChangeSuccess,
  ResetStageInstanceDetail,
  SetEvaluationUpdateMode,
  ToggleIsMajorTask,
  CheckIfMajorTaskFound,
  DownloadStage,
} from './stage-instance-detail.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { EvaluateStage } from './stage.actions';
import { EvaluationService } from '../services/evaluation.service';
import { TaskService } from '../services/task.service';
import { Task } from '../models/task.model';
import { TaskState, TaskStateModel } from './task.state';
import { GetListOfTasksWithInProcessType } from './task.actions';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { GetArchivedProcessInstances } from './process-instance.actions';

export interface StageInstanceDetailStateModel {
  StageInstanceDetail: StageInstanceDetail | undefined;
  stageInstanceTasks: PaginatedList<StageInstanceTask>;
  stageInstanceEvaluations?: StageInstanceEvaluation;
  isEveryTaskApproved: Boolean | undefined;
  evaluationStatus: StageInstanceEvaluationStatus;
  isStageEvaluated: Boolean | undefined;
  userStageEvalutions?: UserStageInstanceEvaluation;
  isUpdatingEvaluation?: boolean;
  isMajorTaskFound: boolean;
}

const STAGE_INSTANCE_DETAIL_STATE_TOKEN =
  new StateToken<StageInstanceDetailStateModel>('stageInstanceDetailState');

const defaults = {
  StageInstanceDetail: undefined,
  stageInstanceTasks: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  isEveryTaskApproved: undefined,
  isStageEvaluated: undefined,
  isStageRejected: undefined,
  evaluationStatus: StageInstanceEvaluationStatus.Invalid,
  isMajorTaskFound: false,
};

@State<StageInstanceDetailStateModel>({
  name: STAGE_INSTANCE_DETAIL_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class StageInstanceDetailState {
  constructor(
    private stageInstanceDetailService: StageInstanceDetailService,
    private statusService: OperationStatusService,
    private evaluationService: EvaluationService,
    private store: Store,
    private taskService: TaskService,
  ) {}

  @Action(GetStageEvaluation)
  getStageEvaluation(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageId }: GetStageEvaluation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService.getStageEvaluation(stageId).pipe(
      tap((val) => {
        setState(patch({ stageInstanceEvaluations: val }));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetUserStageEvaluation)
  getUserStageEvaluation(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageId }: GetUserStageEvaluation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService.getUserStageEvaluation(stageId).pipe(
      tap((val) => {
        setState(patch({ userStageEvalutions: val }));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(EvaluateStage)
  evaluateStage(
    { patchState }: StateContext<StageInstanceDetailStateModel>,
    { stageId, evaluations }: EvaluateStage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationService.evaluateStage(stageId, evaluations).pipe(
      tap((val) => {
        this.store.dispatch(new SetProgressOff());
        this.statusService.displayStatus(
          $localize`:@@researches.stage-instance-detail.stage-evaluated-successfully: Stage Evaluated successfully.`,
        );

        patchState({
          isStageEvaluated: true,
        });
      }),
    );
  }
  @Action(UpdateStageEvaluation)
  updateStageEvaluation(
    { patchState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId, evaluations }: UpdateStageEvaluation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationService
      .updateStageEvalution(stageInstanceId, evaluations)
      .pipe(
        tap((val) => {
          this.store.dispatch(new SetProgressOff());
          this.store.dispatch(new GetStageEvaluation(stageInstanceId));
          this.statusService.displayStatus(
            $localize`:@@researches.stage-instance-detail.stage-evaluated-updated-successfully: Stage Evaluated updated successfully.`,
          );
          patchState({
            isStageEvaluated: true,
          });
        }),
      );
  }

  @Action(GetStageInstanceDetail)
  getStageInstanceDetail(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { id }: GetStageInstanceDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService.getStageInstanceDetail(id).pipe(
      tap((stage) => {
        setState(
          patch({
            StageInstanceDetail: stage,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetStageInstanceTasks)
  getPaginatedProcesses(
    { patchState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId, pageNumber, pageSize }: GetStageInstanceTasks,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService
      .getStageInstanceTasks(stageInstanceId, pageNumber, pageSize)
      .pipe(
        tap((paginatedStageInstanceTasks) => {
          patchState({
            stageInstanceTasks: paginatedStageInstanceTasks,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AddSageInstanceTask)
  addStageInstanceTask(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { task }: AddSageInstanceTask,
  ) {
    setState(
      patch({ stageInstanceTasks: patch({ items: insertItem(task, 0) }) }),
    );
  }

  @Action(ApproveStageInstance)
  approveStageInstance(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: ApproveStageInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService
      .approveStageInstance(stageInstanceId)
      .pipe(
        tap(() => {
          this.store.dispatch(new SetProgressOff());
          this.statusService.displayStatus(
            $localize`:@@researches.stage-instance-detail.stage-approved-successfully: Stage Approved successfully.`,
          );
          setState(
            patch({
              StageInstanceDetail: patch({
                stageStatus: StageInstanceStatus.Approved,
              }),
            }),
          );
        }),
      );
  }
  @Action(DownloadStage)
  downloadStage(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: DownloadStage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService.downloadStage(stageInstanceId).pipe(
      tap((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'stage.zip';
        a.click();
        window.URL.revokeObjectURL(url);
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetStageInstanceEvaluationStatus)
  getStageInstanceEvaluationStatus(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: GetStageInstanceEvaluationStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService
      .getStageInstanceEvaluationStatus(stageInstanceId)
      .pipe(
        tap((val) => {
          setState(
            patch({
              evaluationStatus: val,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ChangeStageStatus)
  changeStageStatus(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { updatedStage }: ChangeStageStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService.changeStageStatus(updatedStage).pipe(
      tap((stageInstance) => {
        setState(
          patch({
            StageInstanceDetail: patch({
              stageStatus: stageInstance.stageStatus,
            }),
          }),
        );
        this.store.dispatch(new StageStatusChangeSuccess());
        this.statusService.displayStatus(
          $localize`:@@researches.stage-instance-detail.stage-status-updated-successfully: Stage status updated successfully.`,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteTaskFromStageInstanceDetail)
  deleteTaskFromStageDetail(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { taskId }: DeleteTaskFromStageInstanceDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.deleteTask(taskId).pipe(
      tap((deletedTask: Task) => {
        setState(
          patch({
            stageInstanceTasks: patch({
              items: removeItem<StageInstanceTask>(
                (task) => task.id === deletedTask.id,
              ),
            }),
          }),
        );
        const taskState: TaskStateModel = this.store.selectSnapshot(TaskState);
        const myTasks = taskState.myTasks;
        myTasks.forEach((pTask) => {
          if (pTask.tasks.includes(deletedTask))
            this.store.dispatch(new GetListOfTasksWithInProcessType(pTask.id!));
        });
        this.statusService.displayStatus(
          $localize`:@@researches.stage-instance-detail.task-deleted-successfully: Task Deleted successfully.`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(RejectStageInstance)
  rejectStageInstance(
    { setState, patchState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: RejectStageInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService
      .rejectStageInstance(stageInstanceId)
      .pipe(
        tap(() => {
          setState(
            patch({
              StageInstanceDetail: patch({
                stageStatus: StageInstanceStatus.Rejected,
              }),
            }),
          );
          this.store.dispatch(new StageStatusChangeSuccess());
          this.statusService.displayStatus(
            $localize`:@@researches.stage-instance-detail.stage-rejected-successfully: Stage Rejected successfully.`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CheckEveryTasksApproval)
  checkEveryTasksApproval(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: CheckEveryTasksApproval,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceDetailService
      .checkEveryTasksApproval(stageInstanceId)
      .pipe(
        tap((val) => {
          setState(patch({ isEveryTaskApproved: val }));
        }),
      );
  }

  @Action(UpdateTaskFromStageInstanceDetail)
  updateTaskFromStageInstanceDetail(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { task }: UpdateTaskFromStageInstanceDetail,
  ) {
    setState(
      patch({
        stageInstanceTasks: patch({
          items: updateItem(
            (t: StageInstanceTask) => t.id === task.id,
            task as StageInstanceTask,
          ),
        }),
      }),
    );
  }

  @Action(GetTasksByTaskType)
  getTasksByTaskType(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { taskTypeId, stageInstanceId, pageNumber, pageSize }: GetTasksByTaskType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getTasksByTaskType(taskTypeId, stageInstanceId, pageNumber, pageSize)
      .pipe(
        tap((tasks) => {
          const stageInstanceTasks: PaginatedList<StageInstanceTask> = {
            ...tasks,
            items: tasks.items.map((task: Task) => {
              return {
                id: task.id || '',
                name: task.name,
                description: task.description,
                assigneeName: task.assignedTo,
                taskStatus: task.taskStatus,
                assignedTo: task.assignedTo,
                assignee: task.assignee,
                startDate: task.startDate,
                endDate: task.endDate,
                stageInstanceId: task.stageInstanceId,
                isMajorTask: task.isMajorTask,
              };
            }),
          };
          setState(
            patch({
              stageInstanceTasks: stageInstanceTasks,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetIsEveryTaskApprovedStatus)
  setIsEveryTaskApprovedStatus(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { isEveryTaskApproved }: SetIsEveryTaskApprovedStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    setState(
      patch({
        isEveryTaskApproved: isEveryTaskApproved,
      }),
    );
  }

  @Action(ResetStageInstanceDetail)
  resetStageInstanceDetail(
    { patchState }: StateContext<StageInstanceDetailStateModel>,
    {}: ResetStageInstanceDetail,
  ) {
    patchState(defaults);
  }

  @Action(SetEvaluationUpdateMode)
  setEvaluationUpdateMode(
    { patchState }: StateContext<StageInstanceDetailStateModel>,
    { isUpdatingEvaluation }: SetEvaluationUpdateMode,
  ) {
    this.store.dispatch(new SetProgressOn());
    patchState({
      isUpdatingEvaluation: isUpdatingEvaluation,
    });
    this.store.dispatch(new SetProgressOff());
  }

  @Action(ToggleIsMajorTask)
  toggleIsMajorTask(
    { getState, setState }: StateContext<StageInstanceDetailStateModel>,
    { id, stageInstanceId }: ToggleIsMajorTask,
  ) {
    return this.stageInstanceDetailService.updateIsMajotTask(id).pipe(
      tap((updatedTask: StageInstanceTask) => {
        const state = getState();
        const updatedTasks = state.stageInstanceTasks.items.map((task) => {
          if (task.id === id) {
            return { ...task, isMajorTask: !task.isMajorTask };
          }
          return task;
        });

        setState({
          ...state,
          stageInstanceTasks: {
            ...state.stageInstanceTasks,
            items: updatedTasks,
          },
        });
        this.store.dispatch(new CheckIfMajorTaskFound(stageInstanceId));
      }),
    );
  }

  @Action(CheckIfMajorTaskFound)
  checkIfMajorTaskFound(
    { setState }: StateContext<StageInstanceDetailStateModel>,
    { stageInstanceId }: CheckIfMajorTaskFound,
  ) {
    return this.stageInstanceDetailService
      .checkIfMajorTaskFound(stageInstanceId)
      .pipe(
        tap((val) => {
          setState(
            patch({
              isMajorTaskFound: val,
            }),
          );
        }),
      );
  }
}
