<form [formGroup]="searchForm" class="search-form-container">
  <mat-checkbox
    class="checkbox-margin"
    formControlName="onlyAssigned"
    [checked]="onlyAssigned"
    (change)="onlyAssignedCheckbox($event)"
    i18n="@@documents.workflow-search.assigned-to-me">
    Assigned to me
  </mat-checkbox>
  <mat-form-field appearance="outline" class="workflow-selector">
    <mat-label i18n="@@documents.workflow-search.choose-filter">Choose Filter</mat-label>
    <mat-select
      formControlName="filter"
      (selectionChange)="onFilterSelect($event)"
    >
      <mat-option i18n="@@documents.workflow-search.none">None</mat-option>
      <mat-option *ngFor="let filter of workflowFilters" [value]="filter.id">{{
        filter.workflowStatusForDisplay
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="search-term-input">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [placeholder]="placeholderToggleLabel.searchByName"
      formControlName="workflowName"
      (input)="onSearchTermChange()"
    />
  </mat-form-field>
</form>
