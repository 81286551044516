<p class="mat-h3" i18n="@@documents.field-validation-list.rules">Rules</p>

<div class="column">
  <mat-card *ngFor="let rule of fieldValidationRules$ | async; let i = index">
    <mat-card-header>
      <mat-card-title
        ><span i18n="@@documents.field-validation-list.rule"> Rule </span>
        {{ i + 1 }}</mat-card-title
      >
      <mat-card-subtitle>
        <span i18n="@@documents.field-validation-list.type"> Type: </span>
        <mat-chip color="primary">
          {{ rule.validationRule.name }}
        </mat-chip>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p *ngIf="rule.validationRuleValues.length != 0">
        <span i18n="@@documents.field-validation-list.parameter">
          Parameter:
        </span>
        ({{ formatRuleValues(rule.validationRuleValues) }})
      </p>
    </mat-card-content>
  </mat-card>
  <mat-card
    appearance="outlined"
    *ngIf="
      templateFieldId && ((fieldValidationRules$ | async)?.length ?? 0) == 0
    "
  >
    <mat-card-header />
    <mat-card-content>
      <p class="mat-subtitle-1" style="text-align: center" i18n="@@documents.field-validation-list.no-validations-available-for-the-selected-field">
        No validations available for the selected field
      </p>
    </mat-card-content>
  </mat-card>
</div>
