<div class="scrollable">
  <h1 mat-dialog-title i18n="@@documents.external-document-form.external-document-registration-form">External Document Registration Form</h1>
  <form
    [formGroup]="externalDocumentForm"
    mat-dialog-content
    class="external-document-form"
  >
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n="@@documents.external-document-form.source">Source</mat-label>
      <input
        id="source"
        formControlName="source"
        matInput
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            externalDocumentForm.get('source')?.value ?? ''
          )
        "
      />
      <mat-error
        *ngIf="externalDocumentForm?.get('source')?.hasError('required')"
       i18n="@@documents.external-document-form.document-source-is-required">
        Letter source is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n="@@documents.external-document-form.document-number">Document Number</mat-label>
      <input
        id="documentNumber"
        formControlName="documentNumber"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            externalDocumentForm.get('documentNumber')?.value ?? ''
          )
        "
        matInput
      />
      <mat-error
        *ngIf="externalDocumentForm?.get('documentNumber')?.hasError('required')"
       i18n="@@documents.external-document-form.document-number-is-required">
       letter number is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n="@@documents.external-document-form.subject">Subject</mat-label>
      <input id="subject" formControlName="subject" matInput />
      <mat-error
        *ngIf="externalDocumentForm.get('subject')?.hasError('required')"
       i18n="@@documents.external-document-form.document-subject-is-required">
        Letter subject is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n="@@documents.external-document-form.descripiton">Description</mat-label>
      <textarea
        id="description"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            externalDocumentForm.get('description')?.value ?? ''
          )
        "
        formControlName="description"
        matInput
        [placeholder]="placeholderFieldLabel.externalDocumentDescriptionField"
      ></textarea>
      <mat-error
        *ngIf="externalDocumentForm?.get('description')?.hasError('required')"
       i18n="@@documents.external-document-form.please-provide-a-valid-description-for-the-documents">
        Please provide a valid description for the letters.
      </mat-error>
    </mat-form-field>
  </form>

  <div class="chip-alignment">
    <button mat-raised-button (click)="openOfficeSelector()" i18n="@@documents.external-document-form.select-office">
      select office
    </button>
    <mat-chip-row
      class="chip"
      *ngIf="selectedFlatOfficeNode"
      (removed)="removeSelectedFlatOfficeNode(selectedFlatOfficeNode)"
      [editable]="false"
    >
      {{ selectedFlatOfficeNode.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <mat-error class="office-error"
        *ngIf="externalDocumentForm?.get('selectedOffice')?.hasError('required') && showOfficeError"
       i18n="@@documents.external-document-form.office-is-required">
       office is required
      </mat-error>
  </div>

  <div class="overlay">
    <div
      class="drop-zone"
      [class.dragover]="isDragOver"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
      <input
        id="file-upload"
        type="file"
        (change)="onFileSelect($event)"
        multiple
      />
      <ng-container>
        <p>
          <label for="file-upload" class="custom-file-upload">
            <span class="file-upload-label">
              <mat-icon class="upload-icon">cloud_upload</mat-icon>
              <span i18n="@@documents.external-document-form.upload-document"> Upload Document </span>
            </span>
          </label>
        </p>
      </ng-container>
      <div>
        <ng-container
          class="overflow height-m"
          *ngFor="let file of files; let i = index"
        >
          <div class="file-preview">
            <mat-icon>insert_drive_file</mat-icon>
            <p>{{ file.name }}</p>
            <button class="delete-button" (click)="removeFile(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      *ngIf="!data.isUpdate"
      mat-flat-button
      color="primary"
      [disabled]="!this.selectedFlatOfficeNode || externalDocumentForm.invalid || this.files.length === 0"
      (click)="createExternalDocument()"
     i18n="@@documents.external-document-form.create">
      Create
    </button>
    <button
      *ngIf="data.isUpdate"
      mat-flat-button
      color="primary"
      (click)="updateExternalDocument()"
      [disabled]="!this.selectedFlatOfficeNode || externalDocumentForm.invalid"
     i18n="@@documents.external-document-form.update"
     [attr.data-test-id]="'documents.update-document.external-document-form.update-button'">
      Update
    </button>
    <button mat-button (click)="cancel()" i18n="@@documents.external-document-form.cancel">Cancel</button>
  </div>
</div>
