import { Component, OnInit } from '@angular/core';
import { CommunicationsWithStatus } from '../../models/communications';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { RxState } from '@rx-angular/state';
import { MatTableDataSource } from '@angular/material/table';

interface CommunicationsWithStatusComponentState {
  communicationsWithStatus: CommunicationsWithStatus[];
}

const initCommunicationsWithStatusComponentState: Partial<CommunicationsWithStatusComponentState> =
  {
    communicationsWithStatus: [],
  };

@Component({
  selector: 'app-communications-with-status',
  templateUrl: './communications-with-status.component.html',
  styleUrls: ['./communications-with-status.component.scss'],
})
export class CommunicationsWithStatusComponent implements OnInit {
  communicationsWithStatus$ = this.state.select('communicationsWithStatus');
  communicationsWithStatus: CommunicationsWithStatus[] = [];
  dataSource = new MatTableDataSource<CommunicationsWithStatus>(
    this.communicationsWithStatus,
  );

  displayedColumns: string[] = [
    'Communication Type',
    'Total Instances',
    'Ready To Start',
    'In Progress',
    'Terminated',
    'Completed',
    'Rejected',
    'Waiting For Approval',
    'Submitted To Evaluation',
  ];
  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    private state: RxState<CommunicationsWithStatusComponentState>,
  ) {
    this.state.set(initCommunicationsWithStatusComponentState);
    this.state.connect(
      'communicationsWithStatus',
      this.researchDashboardFacade.communicationsWithStatus$,
    );
  }

  ngOnInit(): void {
    this.researchDashboardFacade.dispatchGetCommunicationsWithStatus();
    this.communicationsWithStatus$.subscribe((data) => {
      this.communicationsWithStatus = data;
      this.dataSource.data = this.communicationsWithStatus;
      this.dataSource.data = this.processData(data);
    });
  }

  private processData(data: any[]): any[] {
    const communicationTypes = [
      ...new Set(data.map((item) => item.communicationTypeName)),
    ];
    const processedData = communicationTypes.map((type) => {
      const rowData: any = { 'Communication Type': type };
      let totalInstances = 0;
      this.displayedColumns.slice(1).forEach((status) => {
        const normalizedStatus = status.replaceAll(' ', '').toLowerCase();
        const typeStatusData = data.find(
          (item) =>
            item.communicationTypeName === type &&
            item.status.replaceAll(' ', '').toLowerCase() ===
            normalizedStatus,
        );
        const count = typeStatusData ? typeStatusData.count : 0;
        rowData[status] = count;
        totalInstances += count;
      });
      rowData['Total Instances'] = totalInstances;
      return rowData;
    });
    return processedData;
  }
}
