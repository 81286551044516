import { NgModule } from '@angular/core';
import { MemoFormComponent } from './components/memo-form/memo-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MemoListComponent } from './components/memo-list/memo-list.component';
import { MemoState } from './store/memo.state';
import { NgxsModule } from '@ngxs/store';
import { MemosRoutingModule } from './memos-routing.module';
import { MemoDetailComponent } from './components/memo-detail/memo-detail.component';
import { MemoCcListComponent } from './components/memo-cc-list/memo-cc-list.component';
import { MemoCcFormComponent } from './components/memo-cc-form/memo-cc-form.component';

@NgModule({
  declarations: [
    MemoFormComponent,
    MemoListComponent,
    MemoDetailComponent,
    MemoCcListComponent,
    MemoCcFormComponent,
  ],
  imports: [
    SharedModule,
    MemosRoutingModule,
    NgxsModule.forFeature([MemoState]),
  ],
})
export class MemosModule {}
