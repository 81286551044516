import { SizeUnit } from 'src/app/offices/models/office.model';

export const SizeUnits = {
  TeraByte: 0,
  GigaByte: 1,
  MegaByte: 2,
  KiloByte: 3,
} as const;

export type SizeUnitType = keyof typeof SizeUnit;

export interface Workspace {
  id: string;
  name: string;
  officeId: string;
  quotaLimit: number;
  quotaLimitUnit: SizeUnitType;
  contentSize: number;
  contentSizeUnit: SizeUnitType;
}
