import { Component, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { PublicationOptionFacade } from 'src/app/researches/facades/publication-option.facade';
import { PublicationFacade } from 'src/app/researches/facades/publication.facade';
import {
  PublicationOption,
  PublicationTemplate,
} from 'src/app/researches/models/publication-option.model';

interface PublicationTemplateDownloadComponentState {
  selectedPublicationOption?: PublicationOption;
}

const initialPublicationTemplateDownloadComponentState: PublicationTemplateDownloadComponentState =
  {};

@Component({
  selector: 'app-publication-template-download',
  templateUrl: './publication-template-download.component.html',
  styleUrl: './publication-template-download.component.scss',
  providers: [{provide: MatPaginatorIntl, useClass: PublicationTemplateDownloadComponent}, RxState],
})
export class PublicationTemplateDownloadComponent implements OnInit, MatPaginatorIntl {
  selectedPublicationOption$ = this.state.select('selectedPublicationOption');
  selectedPublicationOption: PublicationOption | undefined;

  firstPageLabel = $localize`:@@researches.publication-template-download.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.publication-template-download.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.publication-template-download.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.publication-template-download.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.publication-template-download.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.publication-template-download.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.publication-template-download.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.publication-template-download.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private publicationOptionFacade: PublicationOptionFacade,
    private publicationFacade: PublicationFacade,
    private state: RxState<PublicationTemplateDownloadComponentState>,
  ) {
    this.state.connect(
      'selectedPublicationOption',
      this.publicationFacade.selectedPublicationOption$,
    );
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.selectedPublicationOption$.subscribe((option) => {
      this.selectedPublicationOption = option;
    });
  }

  downloadTemplate(item: PublicationTemplate) {
    this.publicationOptionFacade.dispatchDownloadPublicationTemplate(item);
  }

  hasDownloadPublicationTemplatePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.DownloadPublicationTemplate,
    );
  }
}
