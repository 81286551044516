import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { WorkflowValidation } from '../models/workflow-validation.model';
import { CreateWorflowStepValidationDto, UpdateWorkflowStepValidationDto } from '../models/worlfow-step-validation.model';
import {
  CreateWorkflowValidation,
  CreateWorkflowStepValidation,
  DeleteWorkflowValidation,
  GetWorkflowTemplateFields,
  GetWorkflowValidations,
  SelectWorkflowValidation,
  UpdateWorkflowValidation,
  GetWorkflowStepValidationRule,
  GetWorkflowStepValidations,
  UpdateWorkflowStepValidation,
  DeleteWorkflowStepValidation,
} from '../store/workflow-validations.actions';
import { WorkflowValidationSelector } from '../store/workflow-validations.selector';

@Injectable({
  providedIn: 'root',
})
export class WorkflowValidationFacade {
  constructor(private store: Store) {}

  dispatchGetWorkflowTemplateFields(workflowId: string) {
    this.store.dispatch(new GetWorkflowTemplateFields(workflowId));
  }

  dispatchCreateWorkflowValidation(
    workflowTypeId: string,
    leftTemplateFieldId: string,
    rightTemplateFieldId: string,
    validationRuleId: string,
  ) {
    return this.store.dispatch(
      new CreateWorkflowValidation(
        workflowTypeId,
        leftTemplateFieldId,
        rightTemplateFieldId,
        validationRuleId,
      ),
    );
  }

  dispatchGetWorkflowValidations(
    workflowTypeId: string,
    pageSize: number = 10,
    pageNumber: number = 1,
  ) {
    return this.store.dispatch(
      new GetWorkflowValidations(workflowTypeId, pageSize, pageNumber),
    );
  }

  dispatchDeleteWorkflowValidation(id: string) {
    return this.store.dispatch(new DeleteWorkflowValidation(id));
  }

  dispatchUpdateWorkflowValidation(
    id: string,
    leftTemplateFieldId: string,
    rightTemplateFieldId: string,
    validationRuleId: string,
  ) {
    return this.store.dispatch(
      new UpdateWorkflowValidation(
        id,
        leftTemplateFieldId,
        rightTemplateFieldId,
        validationRuleId,
      ),
    );
  }

  dispatchSelectValidation(workflowValidation: WorkflowValidation) {
    this.store.dispatch(new SelectWorkflowValidation(workflowValidation));
  }

  dispatchUnSelectValidation() {
    this.store.dispatch(new SelectWorkflowValidation(undefined));
  }

  dispatchCreateWorflowStepValidation(data: CreateWorflowStepValidationDto) {
    return this.store.dispatch(new CreateWorkflowStepValidation(data));
  }

  dispatchUpdateWorkflowStepValidation(data: UpdateWorkflowStepValidationDto) {
    return this.store.dispatch(new UpdateWorkflowStepValidation(data));
  }

  dispatchDeleteWorkflowStepValidation(id: string) {
    return this.store.dispatch(new DeleteWorkflowStepValidation(id));
  }

  dispatchGetWorkflowStepValidationRules(dataType: string) {
    this.store.dispatch(new GetWorkflowStepValidationRule(dataType));
  }

  dispatchGetWorkflowStepValidations(workflowStepId: string) {
    return this.store.dispatch(new GetWorkflowStepValidations(workflowStepId));
  }

  workflowStepValidations$ = this.store.select(
    WorkflowValidationSelector.slices.workflowStepValidations
  )

  workflowValidationRules$ = this.store.select(
    WorkflowValidationSelector.slices.workflowValidationRules,
  );


  documentTemplates$ = this.store.select(
    WorkflowValidationSelector.slices.documentTemplates,
  );

  workflowValidations$ = this.store.select(
    WorkflowValidationSelector.slices.workflowValidations,
  );

  selectedValidation$ = this.store.select(
    WorkflowValidationSelector.slices.selectedValidation,
  );
}
