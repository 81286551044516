import { NgModule } from '@angular/core';

import { WorkflowTypesRoutingModule } from './workflow-types-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { WorkflowTypeComponent } from './components/workflow-type/workflow-type.component';
import { WorkflowTypeStepsModule } from '../workflow-type-steps/workflow-type-steps.module';
import { NgxsModule } from '@ngxs/store';
import { WorkflowTypeStepState } from '../workflow-type-steps/store/workflow-type-steps.state';
import { WorkflowConfigurationFormComponent } from './components/workflow-configuration-form/workflow-configuration-form.component';
import { WorkflowTypesState } from './store/workflow-types.state';

@NgModule({
  declarations: [WorkflowTypeComponent, WorkflowConfigurationFormComponent],
  imports: [
    SharedModule,
    WorkflowTypesRoutingModule,
    WorkflowTypeStepsModule,
    NgxsModule.forFeature([WorkflowTypeStepState, WorkflowTypesState]),
  ],
})
export class WorkflowTypesModule {}
