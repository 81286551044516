import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, map, tap, forkJoin, from, combineLatest, filter } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  DOCUMENTS_ROUTE,
  LOGIN_ROUTE,
  RESEARCH_ROUTE,
  USERS_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FILES_ROUTE,
  OFFICES_ROUTE,
  DELEGATIONS_ROUTE,
  LOGIN_HISTORY_ROUTE,
  PROFILE_ROUTE,
  CONTACT_US_ROUTE,
  REPORTED_ISSUES_ROUTE,
  RESET_PASSWORD_ROUTE,
  FORGET_PASSWORD_ROUTE,
} from 'src/app/core/constants/routes';
import { AuthFacade } from '../auth/facade/auth.facade';
import {
  RegistrationType,
  UserFormComponent,
} from '../users/components/user-form/user-form.component';
import { UserFacade } from '../users/facade/user.facade';
import { IS_DEVELOPMENT } from '../core/constants/api-endpoints';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from '../core/constants/permissions';

interface HomeComponentState {
  isAuthenticated: boolean;
  mainMenu: any;
}

const initHomeComponentState: Partial<HomeComponentState> = {
  isAuthenticated: true,
  mainMenu: [],
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [RxState],
})
export class HomeComponent implements OnInit {
  isFirstTimeLogin: boolean | undefined;
  
  accountManagement = {
    link: USERS_ROUTE,
    label: $localize`:@@account-management.users:Users`,
    icon: `account_circle`,
    hidden: true,
  };
  accountManagement$ = new Observable<{
    link: string;
    label: string;
    icon: string;
    hidden: boolean;
  }>();

  delegationManagement = {
    link: DELEGATIONS_ROUTE,
    label: $localize`:@@delegation-management.delegations:Delegations`,
    icon: `account_circle`,
    hidden: true,
  };
  delegationManagement$ = new Observable<{
    link: string;
    label: string;
    icon: string;
    hidden: boolean;
  }>();

  mainMenu = [
    {
      link: RESEARCH_ROUTE,
      label: $localize`:@@main-menu.research:Research`,
      icon: ``,
      hidden: true,
      module: MODULES.RESEARCHES,
    },
    {
      link: DOCUMENTS_ROUTE,
      label: $localize`:@@main-menu.documents:Documents`,
      icon: ``,
      hidden: true,
      module: MODULES.DOCUMENTS,
    },
    {
      link: FILES_ROUTE,
      label: $localize`:@@main-menu.files:Files`,
      icon: ``,
      hidden: true,
      module: MODULES.FILES,
    },
    {
      link: OFFICES_ROUTE,
      label: $localize`:@@main-menu.offices:Offices`,
      icon: ``,
      hidden: true,
      module: MODULES.OFFICES,
    },
  ];

  contactMenu = [
    {
      link: CONTACT_US_ROUTE,
      label: $localize`:@@main-menu.contact-us:Contact Us`,
      icon: ``,
      hidden: true,
      module: MODULES.IDENTITIES,
      feature: this.hasCreateContactUsPermission(),
    },
    {
      link: REPORTED_ISSUES_ROUTE,
      label: $localize`:@@main-menu.reported-issues:Reported Issues`,
      icon: ``,
      hidden: true,
      module: MODULES.IDENTITIES,
      feature: this.hasGetContactUsPermission(),
    },
  ];

  mainMenu$ = new Observable<any>();
  contactMenu$!: Observable<any[]>;


  loginRoute = {
    link: LOGIN_ROUTE,
    label: $localize`:@@auth.login:Login`,
    icon: '',
  };

  logoutRoute = {
    link: '',
    label: $localize`:@@auth.logout:Logout`,
    icon: '',
  };

  changePasswordRoute = {
    link: CHANGE_PASSWORD_ROUTE,
    label: $localize`:@@account-management.change-passwrod:Change Password`,
    icon: '',
  };

  loginHistory = {
    link: LOGIN_HISTORY_ROUTE,
    label: $localize`:@@account-management.login-history: Login History`,
    icon: '',
  };

  profile = {
    link: PROFILE_ROUTE,
    label: $localize`:@@account-management.profile: Profile`,
    icon: '',
  };

  isAuthenticated$: Observable<boolean> = this.state.select('isAuthenticated');
  isAuthenticated: boolean | undefined = undefined;

  constructor(
    private authFacade: AuthFacade,
    private router: Router,
    private state: RxState<HomeComponentState>,
    private matDialog: MatDialog,
    private userFacade: UserFacade,
  ) {
    this.state.set(initHomeComponentState);

    this.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const currentRoute = event.url.split('?')[0].split('/').pop()
      if(!this.isAuthenticated && !(currentRoute === RESET_PASSWORD_ROUTE || currentRoute === FORGET_PASSWORD_ROUTE)){
        this.router.navigate([LOGIN_ROUTE]);
      }
      if(this.isAuthenticated && (currentRoute==='')){
        this.router.navigate([PROFILE_ROUTE]);
      }
    });

    this.state.connect('isAuthenticated', authFacade.isAuthenticated$);
  }

  ngOnInit(): void {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.isFirstTimeLogin = user.isFirstTimeLogin!;
        if(!this.isFirstTimeLogin){
          this.updateMenuAuthorization();
        }
      }
    });
  }

  logout() {
    this.authFacade.dispatchLogout();
    this.router.navigate([LOGIN_ROUTE]);
  }

  manageAccounts() {}

  addUser() {
    this.matDialog.open(UserFormComponent, {
      data: { update: false, registrationType: RegistrationType.USER },
      disableClose: true,
    });
  }

  updateMenuAuthorization() {
    this.mainMenu$ = combineLatest([
      ...this.mainMenu.map((item) => this.isAuthorized(item.module)),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.mainMenu.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );

    this.contactMenu$ = combineLatest([
      ...this.contactMenu.map((item) => this.hasAuthorizedPermission(item.feature)),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.contactMenu.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );

    this.accountManagement$ = this.isAuthorized(MODULES.IDENTITIES).pipe(
      map((authorized) => ({
        ...this.accountManagement,
        hidden: !authorized,
      })),
    );
    this.delegationManagement$ = this.hasAuthorizedFeature(
      PERMISSION_NAMES.Offices.Delegation.Feature,
    ).pipe(
      map((authorized) => ({
        ...this.delegationManagement,
        hidden: !authorized,
      })),
    );
  }

  isAuthorized(module: any): Observable<boolean> {
    return this.userFacade
      .hasModulePermission(module)
      .pipe(map((module) => !!module));
  }

  hasAuthorizedFeature(feature: string): Observable<boolean> {
    return this.userFacade.hasFeaturePermission(feature).pipe(
      map((permission) => !!permission),
    );
  }
  hasAuthorizedPermission(permission: string): Observable<boolean> {
    return this.userFacade
      .hasPermission(permission)
      .pipe(map((permission) => !!permission));
  }

  hasCreateContactUsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.CreateContactUs,
    );
  }  
  hasGetContactUsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetContactUs,
    );
  }
}
