import { NgModule } from '@angular/core';

import { DocumentsRoutingModule } from './documents-routing.module';
import { SharedModule } from '../shared/shared.module';
import { WorkflowTypesModule } from './workflow-types/workflow-types.module';
import { DocumentHomeComponent } from './components/document-home/document-home.component';
import { DocumentFormsModule } from './document-templates/document-forms.module';
import { FormFieldsModule } from './template-fields/form-fields.module';
import { WorkflowTypeStepsModule } from './workflow-type-steps/workflow-type-steps.module';
import { FieldValidationRulesModule } from './field-validation-rules/field-validation-rules.module';
import { NgxsModule } from '@ngxs/store';
import { WorkflowTypeStepState } from './workflow-type-steps/store/workflow-type-steps.state';
import { WorkflowTypesState } from './workflow-types/store/workflow-types.state';
import { FormFieldsState } from './template-fields/store/form-fields.state';
import { DocumentFormState } from './document-templates/store/document-form.state';
import { WorkflowsModule } from './workflows/workflows.module';
import { WorkflowState } from './workflows/store/workflow.state';
import { WorkflowStepsModule } from './workflow-steps/workflow-steps.module';
import { WorkflowCommentState } from './workflow-comments/store/workflow-comment.state';
import { WorkflowCommentsModule } from './workflow-comments/workflow-comments.module';
import { WorkflowValidationModule } from './workflow-validation/workflow-validation.module';
import { WorkflowCommentPolicyComponent } from './workflow-comments/components/workflow-comment-policy/workflow-comment-policy.component';

import { MemosModule } from './memos/memos.module';
import { NoticesModule } from './notices/notices.module';
import { NoticesState } from './notices/store/notices.state';
import { ExternalDocumentModule } from './external-document/external-document.module';
import { ExternalDocumentState } from './external-document/store/external-document.state';
import { DashboardModule } from './dashboard/dashboard.module';
import { WorkflowStepState } from "./workflow-steps/store/workflow-step.state";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [DocumentHomeComponent],
  imports: [
    SharedModule,
    DocumentsRoutingModule,
    FormFieldsModule,
    FieldValidationRulesModule,
    ExternalDocumentModule,
    WorkflowTypesModule,
    WorkflowsModule,
    WorkflowTypeStepsModule,
    WorkflowStepsModule,
    WorkflowCommentsModule,
    WorkflowValidationModule,
    MemosModule,
    NoticesModule,
    DashboardModule,
    FontAwesomeModule,
    NgxsModule.forFeature([
      DocumentFormState,
      FormFieldsState,
      WorkflowTypesState,
      ExternalDocumentState,
      WorkflowStepState,
      WorkflowTypeStepState,
      WorkflowState,
      WorkflowCommentState,
      NoticesState,
    ]),
  ],
})
export class DocumentsModule {}
