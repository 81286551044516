import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { EvaluationScheduleStatus, Schedule } from '../models/schedule.model';
import {
  EVALUATION_SCHEDULE_URL,
  EVALUATION_URL,
  MEETING_SCHEDULE_URL,
  STAGE_INSTANCES_URL,
} from 'src/app/core/constants/api-endpoints';
import { Observable } from 'rxjs';
import { AssignReviewerType, Reviewer } from '../models/reviewer.model';
import { MeetingSchedule } from '../models/meeting-schedule.model';
import { EvaluationDetail } from '../models/evaluation.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluationScheduleService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getEvaluationSchedules(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Schedule>> {
    return this.http.get<PaginatedList<Schedule>>(
      `${EVALUATION_SCHEDULE_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
  getEvaluationScheduleDetail(id: string): Observable<Schedule> {
    return this.http.get<Schedule>(`${EVALUATION_SCHEDULE_URL}/${id}`);
  }

  createEvuationSchedule(schedule: Schedule): Observable<Schedule> {
    return this.http.post<Schedule>(`${EVALUATION_SCHEDULE_URL}`, schedule);
  }

  updateEvaluationSchedule(schedule: Schedule): Observable<Schedule> {
    return this.http.put<Schedule>(`${EVALUATION_SCHEDULE_URL}`, schedule);
  }

  deleteEvaluationSchedule(id: string) {
    return this.http.delete(`${EVALUATION_SCHEDULE_URL}/${id}`);
  }

  getStageInstanceReviewers(id: string): Observable<Reviewer[]> {
    return this.http.get<Reviewer[]>(`${STAGE_INSTANCES_URL}/${id}/reviewers`);
  }

  assignReviewers(reviewerIds: string[], id: string): Observable<any> {
    return this.http.put<any>(
      `${STAGE_INSTANCES_URL}/${id}/assign-reviewers`,
      reviewerIds,
    );
  }

  getEvaluationDetail(stageInstanceId: string): Observable<EvaluationDetail> {
    return this.http.get<EvaluationDetail>(
      `${EVALUATION_URL}/evaluation-detail?stageInstanceId=${stageInstanceId}`,
      this.httpOptions,
    );
  }

  createMeetingSchedule(
    meetingSchedule: MeetingSchedule,
  ): Observable<MeetingSchedule> {
    return this.http.post<MeetingSchedule>(
      MEETING_SCHEDULE_URL,
      meetingSchedule,
    );
  }
  updateMeetingSchedule(
    meetingSchedule: MeetingSchedule,
  ): Observable<MeetingSchedule> {
    return this.http.put<MeetingSchedule>(
      MEETING_SCHEDULE_URL,
      meetingSchedule,
    );
  }

  deleteMeetingSchedule(meetingScheduleId: string) {
    return this.http.delete(`${MEETING_SCHEDULE_URL}/${meetingScheduleId}`);
  }

  deleteStageInstanceReviewer(stageInstanceId: string, reviewerId: string) {
    return this.http.delete(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/reviewers/${reviewerId}`,
    );
  }

  finishEvaluationSchedule(evaluationScheduleId: string) {
    return this.http.post(
      `${EVALUATION_SCHEDULE_URL}/finish?evaluationScheduleId=${evaluationScheduleId}`,
      {},
    );
  }

  getEvaluationScheduleStatus(
    evaluationScheduleId: string,
  ): Observable<EvaluationScheduleStatus> {
    return this.http.get<EvaluationScheduleStatus>(
      `${EVALUATION_SCHEDULE_URL}/status?evaluationScheduleId=${evaluationScheduleId}`,
    );
  }

  assignReviewerType(
    stageInstanceId: string,
    assignReviewerType: AssignReviewerType,
  ): Observable<any> {
    return this.http.put<any>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/assign-reviewer-type`,
      assignReviewerType,
    );
  }

  FinishStageInstanceEvaluation(
    evaluationScheduleId: string,
    stageInstanceId: string,
  ) {
    return this.http.post(
      `${EVALUATION_SCHEDULE_URL}/finish-single-stage-instance-evaluation?evaluationScheduleId=${evaluationScheduleId}&StageInstanceId=${stageInstanceId}`,
      {},
    );
  }
}
