import { GetWorkflowTypeStepDetail, OrderWorkflowTypeStep } from './../store/workflow-type-steps.actions';
import { OrderWorkflowTypeSteps, WorkflowTypeStepDetail } from './../models/workflow-type-steps.model';
import {
  AddNotifyOfficesToWorkflowTypeStep,
  DeleteWorkflowTypeStep,
  GetWorkFlowTypeSteps,
  SetSelectedWorkflowTypeStep,
  SetSelectedWorkflowTypeStepDocumentTemplate,
  SetSelectedWorkflowTypeStepDocumentTemplateFields,
  SetUpdateStatus,
  UpdateWorkflowTypeStep,
} from '../store/workflow-type-steps.actions';

import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { select } from '@rx-angular/state/selections';
import { WorkflowTypeStepSelector } from '../store/workflow-type-steps.selector';
import { Observable, Observer } from 'rxjs';
import {
  UpdateWorkflowTypeStepDto,
  WorkflowTypeStepDto,
  WorkflowTypeSteps,
} from '../models/workflow-type-steps.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { CreateWorkflowTypeStep } from '../store/workflow-type-steps.actions';
import { WorkflowTypeStepStateModel } from '../store/workflow-type-steps.state';
import { DocumentForm } from '../../document-templates/models/document-forms.model';
import { FormFields } from '../../template-fields/models/form-fields.model';
import { WorkflowTypeStepsService } from '../services/workflow-type-steps.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTypeStepsFacade {
  constructor(private store: Store, private workflowTypeStepService: WorkflowTypeStepsService, ) {}

  @Select(WorkflowTypeStepSelector.workFlowSteps)
  workFlowSteps$!: Observable<PaginatedList<WorkflowTypeStepDto>>;

  @Select(WorkflowTypeStepSelector.update)
  update$!: Observable<boolean>;

  @Select(WorkflowTypeStepSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(WorkflowTypeStepSelector.selectedWorkflowTypeStep)
  selectedWorkflowTypeStep$!: Observable<WorkflowTypeStepDto>;

  @Select(WorkflowTypeStepSelector.selectedWorkflowTypeStepDocumentTemplate)
  selectedWorkflowTypeStepDocumentTemplate$!: Observable<DocumentForm>;

  @Select(
    WorkflowTypeStepSelector.selectedWorkflowTypeStepDocumentTemplateFields,
  )
  selectedWorkflowTypeStepDocumentTemplateFields$!: Observable<
    FormFields[]
  >;

  @Select(WorkflowTypeStepSelector.workflowTypeStepDetail)
  workflowTypeStepDetail$!: Observable<WorkflowTypeStepDetail>;


  dispatchGetWorkFlowTypeSteps(
    id: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    return this.store.dispatch(
      new GetWorkFlowTypeSteps(id, pageNumber, pageSize),
    );
  }
  dispatchCreateWorkflowTypeStep(workflowTypeStep: any) {
    return this.store.dispatch(new CreateWorkflowTypeStep(workflowTypeStep));
  }
  dispatchUpdateWorkflowTypeStep(workflowTypeStep: UpdateWorkflowTypeStepDto) {
    return this.store.dispatch(new UpdateWorkflowTypeStep(workflowTypeStep));
  }
  dispatchSetUpdateStatus(update: boolean) {
    this.store.dispatch(new SetUpdateStatus(update));
  }
  dispatchSetSelectedWorkflowTypeStep(workflowTypeStep: WorkflowTypeStepDto) {
    this.store.dispatch(new SetSelectedWorkflowTypeStep(workflowTypeStep));
  }

  dispatchGetStepByName(workflowStepId: string, stepName: string) {
    return this.workflowTypeStepService.getStepByName(workflowStepId, stepName);
  }

  dispatchSetSelectedWorkflowTypeStepDocumentTemplate(
    workflowTypeStepDocumentTemplate: DocumentForm,
  ) {
    this.store.dispatch(
      new SetSelectedWorkflowTypeStepDocumentTemplate(
        workflowTypeStepDocumentTemplate,
      ),
    );
  }
  dispatchSetSelectedWorkflowTypeStepDocumentTemplateFields(
    templateFields: FormFields[],
  ) {
    this.store.dispatch(
      new SetSelectedWorkflowTypeStepDocumentTemplateFields(templateFields),
    );
  }

  dispatchDeleteWorkflowTypeStep(workflowTypeStepId: string) {
    this.store.dispatch(new DeleteWorkflowTypeStep(workflowTypeStepId));
  }
  dispatchAddNotifyOfficesToWorkflowTypeStep(cc: any) {
    this.store.dispatch(new AddNotifyOfficesToWorkflowTypeStep(cc));
  }

  dispatchOrderWorkflowTypeSteps(orderSteps: OrderWorkflowTypeSteps) {
    this.store.dispatch(new OrderWorkflowTypeStep(orderSteps));
  }
  dispatchGetWorkflowTypeStepDetail(id: string){
    this.store.dispatch(new GetWorkflowTypeStepDetail(id))
  }
}
