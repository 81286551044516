<h1 class="mat-h1" i18n="@@documents.owned-workflows.submitted-workflows">Submitted Workflows</h1>
<form [formGroup]="filterForm" class="form-container">
  <mat-form-field appearance="outline" class="workflow-selector">
    <mat-label i18n="@@documents.owned-workflows.choose-filter" [attr.data-test-id]="'documents.filter-submitted-workflows.owned-workflows.filter-submitted-workflows-input'">Choose Filter</mat-label>
    <mat-select
      formControlName="filter"
      (selectionChange)="onFilterSelect($event)"
    >
      <mat-option *ngFor="let filter of workflowFilters" [value]="filter?.id">{{
        filter.workflowStatusForDisplay
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>

<div class="paginator-container">
  <mat-paginator
    [length]="ownedWorkflows?.totalCount ?? 0"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedWorkflows($event)"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>
</div>

<div class="col">
  <app-workflow-card
    [workflow]="workflow"
    *ngFor="let workflow of ownedWorkflows?.items"
  />
</div>

<ng-container *ngIf="!ownedWorkflows || ownedWorkflows?.totalCount === 0">
  <div class="empty-workflow-list" *ngIf="selectedStatus == 'InProgress'">
    <span class="" i18n="@@documents.owned-workflows.no-owned-workflows-found">No submitted workflows found</span>
  </div>
  <div class="empty-workflow-list" *ngIf="selectedStatus == 'Rejected'">
    <span class="" i18n="@@documents.owned-workflows.no-owned-workflows-found">No submitted workflows found</span>
  </div>
</ng-container>
