import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import {
  GetDocumentForm,
  GetDocumentFormsByStatus,
  SetFormCreatingMode,
  DeleteDraftDocumentForm,
  SaveForm,
  GetSelectedDocumentForm,
  AddFieldOnFormDetail,
  UpdateFieldOnFormDetail,
  ChangeFormStatus,
  DeleteFieldOnFormDetail,
  GetFormDetailByStatus,
  SearchDocumentForms,
  SetFormSearchingMode,
  GetArchivedDocumentForms,
  SelectedFormType,
  ToggleAddFieldStep,
} from './document-form.actions';
import { count, tap } from 'rxjs';
import { PaginatedList } from '../../../core/models/paginated-list.interface';
import { Injectable } from '@angular/core';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  DocumentForm,
  DocumentFormDetailDto,
} from '../models/document-forms.model';
import {
  DeleteDocumentForm,
  GetDocumentForms,
  GetDraftForms,
  ResetSelectedDocumentForm,
  SetSelectedDocumentForm,
  SetUpdateStatus,
  UpdateDocumentForm,
  GetApprovedForms,
} from './document-form.actions';
import {
  CreateForm,
  OrderFormFields,
} from './document-form.actions';
import { DocumentFormService } from '../services/document-form.service';
import {
  patch,
  insertItem,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import {
  errorStyle,
  successStyle,
} from 'src/app/core/services/operation-status/status-style-names';
import {
  SetProgressOn,
  SetProgressOff,
} from 'src/app/core/store/progress-status.actions';
import { StateToken, State, Store, Action, StateContext } from '@ngxs/store';
import { List } from 'echarts';
import { FormStatusOption } from '../../shared/models/document-form-status';
import { FormFields } from '../../template-fields/models/form-fields.model';
import { FieldValidationRuleFacade } from '../../field-validation-rules/facades/field-validation-rules.facade';
import { OrderedBehaviorSubject } from '@ngxs/store/src/internal/custom-rxjs-subjects';

export interface DocumentFormStateModel {
  updateStatus: boolean;
  templates: PaginatedList<DocumentForm>;
  documentForms: DocumentForm[];
  draftForms: PaginatedList<DocumentForm> | undefined;
  totalCount: number;
  totalPages: number;
  selectedDocumentForm: DocumentForm | undefined;
  selectedDocumentFormDetail: DocumentFormDetailDto | undefined;
  isCreatingForm?: boolean;
  isSearchingForm?: boolean;
  selectedFormType: string;
  isAddFieldStepSelected?:boolean;
}

const DOCUMENT_VALIDATION_TOKEN = new StateToken<DocumentFormStateModel>(
  'documentFormStateModel',
);

const defaults: DocumentFormStateModel = {
  updateStatus: false,
  documentForms: [],
  draftForms: undefined,
  totalCount: 0,
  totalPages:1,
  selectedDocumentForm: undefined,
  templates: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  selectedDocumentFormDetail: undefined,
  selectedFormType: 'Approved',
};

@State<DocumentFormStateModel>({
  name: DOCUMENT_VALIDATION_TOKEN,
  defaults: defaults,
})
@Injectable()
export class DocumentFormState {
  constructor(
    public operationStatus: OperationStatusService,
    private documentFormService: DocumentFormService,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private documentFormFacade: DocumentFormFacade,
    private store: Store,
  ) {}

  @Action(GetDocumentForms)
  getPaginatedProcesses(
    { patchState }: StateContext<DocumentFormStateModel>,
    { pageNumber, pageSize }: GetDocumentForms,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getDocumentForms(pageNumber, pageSize)
      .pipe(
        tap((documentForms) => {
          patchState({
            documentForms: documentForms.items,
            templates: documentForms,
            totalCount: documentForms.totalCount,
            totalPages: documentForms.totalPages,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateDocumentForm)
  updateDocumentForm(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { template }: UpdateDocumentForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService.updateDocumentForm(template).pipe(
      tap((updatedForm) => {
        this.store.dispatch(new SetProgressOff());
        let selectedForm = getState().selectedDocumentForm;
        if (
          updatedForm &&
          selectedForm &&
          selectedForm.status !== updatedForm.status
        ) {
          this.operationStatus.displayStatus(
            $localize`:@@documents.document-template.create-template:Formd created Successfully`,
            successStyle,
          );
        } else {
          this.operationStatus.displayStatus(
            $localize`:@@documents.document-template.update-template:Form updated successfully`,
            successStyle,
          );
        }
        setState(
          patch({
            draftForms: patch({
              items: updateItem(
                (ut) => ut.id == updatedForm.id,
                updatedForm,
              ),
            }),
            templates: patch({
              items: updateItem(
                (ut) => ut.id == updatedForm.id,
                updatedForm,
              ),
            }),
            selectedDocumentForm: updatedForm,
            selectedFormType:updatedForm.status.status

          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.document-template.update-template: Form updated successfully`,
          successStyle,
        );
        this.store.dispatch(
          new GetFormDetailByStatus(
            updatedForm.id!,
            updatedForm.status.status,
          ),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<DocumentFormStateModel>,
    { status }: SetUpdateStatus,
  ) {
    patchState({
      updateStatus: status,
    });
  }

  @Action(SetSelectedDocumentForm)
  setSelectedProcess(
    { patchState }: StateContext<DocumentFormStateModel>,
    { selectedDocumentForm }: SetSelectedDocumentForm,
  ) {
    patchState({
      selectedDocumentForm,
    });
  }
  @Action(GetDocumentFormsByStatus)
  getDocumentFormsByStatus(
    { setState, patchState }: StateContext<DocumentFormStateModel>,
    { status, pageIndex, pageSize }: GetDocumentFormsByStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getDocumentFormsByStatus(status, pageIndex, pageSize)
      .pipe(
        tap((templates: PaginatedList<DocumentForm>) => {
          patchState({ templates, totalCount: templates.totalCount });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateForm)
  createForm(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { template }: CreateForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength: number = getState().totalCount;
    return this.documentFormService.createForm(template).pipe(
      tap((createdForm: DocumentForm) => {
        setState(
          patch({
            documentForms: insertItem(createdForm),
            templates: patch({
              items: insertItem(createdForm),
            }),
            totalCount: prevLength + 1,
            selectedFormType:FormStatusOption.All
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.document-template.create-template:Form created successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SaveForm)
  saveForm(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { template }: SaveForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength: number = getState().totalCount;
    return this.documentFormService.createForm(template).pipe(
      tap((savedForm: DocumentForm) => {
        setState(
          patch({
            draftForms: patch({
              items: insertItem(savedForm),
            }),
            templates: patch({
              items: insertItem(savedForm),
            }),
            totalCount: prevLength + 1,
            selectedFormType:FormStatusOption.All
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.document-template.save-template:Form Saved successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ResetSelectedDocumentForm)
  resetSelectedProcess({
    patchState,
  }: StateContext<DocumentFormStateModel>) {
    patchState({
      selectedDocumentForm: undefined,
    });
  }

  @Action(DeleteDocumentForm)
  deleteDocumentForm(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { id }: DeleteDocumentForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService.deleteDocumentForm(id).pipe(
      tap(() => {
        setState(
          patch({
            documentForms: removeItem((d) => d.id === id),
            templates: patch({
              items: removeItem((d) => d.id === id),
            }),
            totalCount: getState().totalCount - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.document-template.delete-template:Document template deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new ResetSelectedDocumentForm());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetDocumentForm)
  getProcessDetail(
    { setState }: StateContext<DocumentFormStateModel>,
    { id }: GetDocumentForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService.getDocumentForm(id).pipe(
      tap((template) => {
        setState(
          patch({
            selectedDocumentFormDetail: template,
            selectedDocumentForm:template
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(DeleteDraftDocumentForm)
  deleteDraftDocumentForm(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { id }: DeleteDraftDocumentForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService.deleteDraftDocumentForm(id).pipe(
      tap(() => {
        let count: any = getState().draftForms?.totalCount;
        setState(
          patch({
            templates: patch({
              items: removeItem<DocumentForm>((item) => item.id === id),
              totalCount: count - 1,
            }),
            totalCount: getState().totalCount - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.document-template.draft-delete-template:Drafted Document template deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new ResetSelectedDocumentForm());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetFormCreatingMode)
  setFormCreatingMode(
    { patchState }: StateContext<DocumentFormStateModel>,
    { isCreatingForm }: SetFormCreatingMode,
  ) {
    patchState({
      isCreatingForm: isCreatingForm,
    });
  }

  @Action(GetDraftForms)
  getDraftForms(
    { patchState }: StateContext<DocumentFormStateModel>,
    { pageNumber, pageSize }: GetDraftForms,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getDraftForms(pageNumber, pageSize)
      .pipe(
        tap((templates) => {
          if (templates) {
            patchState({
              draftForms: templates,
            });
          }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetFormDetailByStatus)
  getFormDetailByStatus(
    { patchState }: StateContext<DocumentFormStateModel>,
    { id, status }: GetFormDetailByStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getFormDetailByStatus(id, status)
      .pipe(
        tap((templateDetail) => {
          patchState({
            selectedDocumentFormDetail: templateDetail,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetSelectedDocumentForm)
  getDocumentForm(
    { patchState }: StateContext<DocumentFormStateModel>,
    { id }: GetSelectedDocumentForm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService.getDocumentForm(id).pipe(
      tap((template: DocumentForm) => {
        if (template) {
          patchState({
            selectedDocumentForm: template,
          });
        }
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AddFieldOnFormDetail)
  addFieldOnFormDetail(
    { getState, patchState }: StateContext<DocumentFormStateModel>,
    { newFormField }: AddFieldOnFormDetail,
  ) {
    var existingFormDetail = getState().selectedDocumentFormDetail;
    var previousFields = existingFormDetail?.templateFields || [];

    const existingField = previousFields.find(
      (field) => field.name === newFormField?.name,
    );
    if (!existingField) {
      existingFormDetail!.templateFields = [
        newFormField,
        ...previousFields,
      ];
      patchState({
        selectedDocumentFormDetail: existingFormDetail,
      });
    }
  }

  @Action(ChangeFormStatus)
  changeTaskStatus(
    { setState, getState }: StateContext<DocumentFormStateModel>,
    { updatedForm }: ChangeFormStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .changeFormStatus(updatedForm)
      .pipe(
        tap(() => {
          setState(
            patch({
              selectedDocumentForm: patch({
                status: updatedForm.status,
              }),
            }),
          );
          this.documentFormFacade.dispatchGetDocumentFormDetail(
            getState().selectedDocumentForm?.id ?? '',
          );
          if (updatedForm.status.status == FormStatusOption.APPROVED) {
            this.operationStatus.displayStatus(
              $localize`:@@documents.document-template.approve-template:Document template approved successfully`,
              successStyle,
            );
          } else if (
            updatedForm.status.status == FormStatusOption.REJECTED
          ) {
            this.operationStatus.displayStatus(
              $localize`:@@documents.document-template.reject-template:Document template rejected successfully`,
              successStyle,
            );
          } else if (
            updatedForm.status.status ==
            FormStatusOption.CHANGE_REQUESTED
          ) {
            this.operationStatus.displayStatus(
              $localize`:@@documents.document-template.change-template:Document template change requested successfully`,
              successStyle,
            );
          } else if (
            updatedForm.status.status === FormStatusOption.SUBMITTED
          ) {
            this.operationStatus.displayStatus(
              $localize`:@@documents.document-template.submit-template:Document Form submitted sucessfully`,
              successStyle,
            );
          }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateFieldOnFormDetail)
  updateFieldOnFormDetail(
    { setState }: StateContext<DocumentFormStateModel>,
    { updatedFormField }: UpdateFieldOnFormDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .updateFieldOnFormDetail(updatedFormField)
      .pipe(
        tap((template) => {
          if (template) {
            setState(
              patch({
                selectedDocumentFormDetail: patch({
                  templateFields: updateItem<FormFields>(
                    (field) => field.id === updatedFormField?.id,
                    updatedFormField as FormFields,
                  ),
                }),
              }),
            );
          }
          if (updatedFormField?.id)
            this.fieldValidationRuleFacade.dispatchCreateFieldValidationRules(
              updatedFormField.id,
            );
          this.operationStatus.displayStatus(
            $localize`:@@documents.document-template.failed-update-template:Field updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteFieldOnFormDetail)
  deleteFieldOnFormDetail(
    { setState }: StateContext<DocumentFormStateModel>,
    { fieldId }: DeleteFieldOnFormDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .deleteFieldOnFormDetail(fieldId)
      .pipe(
        tap((template) => {
          setState(
            patch({
              selectedDocumentFormDetail: patch({
                templateFields: removeItem<any>(
                  (field) => field.id === fieldId,
                ),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.document-template.field-delete-template:Field deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SearchDocumentForms)
  searchDocumentForms(
    { patchState }: StateContext<DocumentFormStateModel>,
    {status,templateName, pageNumber, pageSize }: SearchDocumentForms,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .searchDocumentForms(status,templateName, pageNumber, pageSize)
      .pipe(
        tap((paginatedForms) => {
          patchState({
            documentForms: paginatedForms.items,
            templates: paginatedForms,
            totalCount: paginatedForms.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetFormSearchingMode)
  setFormSearchingMode(
    { patchState }: StateContext<DocumentFormStateModel>,
    { isSearchingForm }: SetFormSearchingMode,
  ) {
    patchState({
      isSearchingForm: isSearchingForm,
    });
  }

  @Action(OrderFormFields)
  orderFormFields(
    { getState }: StateContext<DocumentFormStateModel>,
    { orderedFields }: OrderFormFields,
  ) {
    var state = getState();
    if (state.selectedDocumentForm!.id! != null) {
      this.store.dispatch(new SetProgressOn());

      this.documentFormService
        .orderFormFields(orderedFields, state.selectedDocumentForm!.id!)
        .subscribe();

      this.store.dispatch(new SetProgressOff());

      this.documentFormFacade.dispatchGetDocumentFormDetail(
        state.selectedDocumentForm!.id!,
      );
    } else {
      this.operationStatus.displayStatus(
        $localize`:@@documents.document-template.no-selected-template:No selected Document template`,
        errorStyle,
      );
    }
  }

  @Action(GetApprovedForms)
  getApprovedForms(
    { patchState }: StateContext<DocumentFormStateModel>,
    { pageNumber, pageSize }: GetApprovedForms,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getApprovedForms(pageNumber, pageSize)
      .pipe(
        tap((documentForms) => {
          patchState({
            documentForms: documentForms.items,
            templates: documentForms,
            totalCount: documentForms.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetArchivedDocumentForms)
  getArchivedDocumentForms(
    { patchState }: StateContext<DocumentFormStateModel>,
    { pageNumber, pageSize,templateName }: GetArchivedDocumentForms,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentFormService
      .getArchivedDocumentForms(pageNumber, pageSize,templateName)
      .pipe(
        tap((documentForms) => {
          patchState({
            documentForms: documentForms.items,
            templates: documentForms,
            totalCount: documentForms.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectedFormType)
  selectedFormType(
    { patchState }: StateContext<DocumentFormStateModel>,
    { templateType }: SelectedFormType,
  ) {
    patchState({
      selectedFormType: templateType,
    });
  }

  @Action(ToggleAddFieldStep)
  selectAddFieldStep(
    { patchState }: StateContext<DocumentFormStateModel>,
    { isAddfieldStepSelected }: ToggleAddFieldStep,
  ) {
    patchState({
      isAddFieldStepSelected: isAddfieldStepSelected,
    });
  }
}
