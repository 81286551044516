import {
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Notice, NoticeFilter } from '../../models/notices.model';
import { RxState } from '@rx-angular/state';
import { NoticesFacade } from '../../facade/notices.facade';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Router } from '@angular/router';
import {
  NOTICES_FORM_ROUTE,
  NOTICES_ROUTE,
  NOTICES_UPDATE_ROUTE,
} from 'src/app/core/constants/routes';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { NoticeStatusOption } from 'src/app/documents/shared/models/notice-status';

interface NoticesListComponentState {
  notices: PaginatedList<Notice> | undefined;
  selectedNotices: Notice | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  noticeFilters:NoticeFilter[],
  selectedNoticeStatus:string;
}
const initialNoticesListComponentState: NoticesListComponentState = {
  notices: undefined,
  selectedNotices: undefined,
  currentLoggedInUser: undefined,
  noticeFilters:[],
  selectedNoticeStatus:'Draft'
};
@Component({
  selector: 'app-notices-list',
  templateUrl: './notices-list.component.html',
  styleUrls: ['./notices-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: NoticesListComponent}, RxState],
})
export class NoticesListComponent implements OnInit, MatPaginatorIntl {
  notices$ = this.state.select('notices');
  notices: PaginatedList<Notice> | undefined = undefined;
  selectedNotices: Notice | undefined = undefined;
  selectedNotices$ = this.state.select('selectedNotices');
  pageSize: number = 10;
  pageIndex: number = 0;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  searchForm: FormGroup;
  destroyRef = inject(DestroyRef);
  noticeStatusForDisplay = [$localize`:@@documents.notices-list.draft:Draft`, $localize`:@@documents.notices-list.completed:Completed`];
  noticeStatus = ['Draft', 'Completed'];
  selectedNoticeStatus$ = this.state.select('selectedNoticeStatus');
  selectedNoticeStatus = 'Draft';
  draft = $localize`:@@documents.notices-list.draft:Draft`;
  sent = $localize`:@@documents.notices-list.sent:Sent`;
  received = $localize`:@@documents.notices-list.received:Received`;
  
  noticeFilters: string[] = [this.draft, this.sent, this.received];

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  placeholderFieldLabel = {
    searchTitleField: $localize`:@@documents.notices-list.title: title`,
}

    firstPageLabel = $localize`:@@documents.notices-list.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.notices-list.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.notices-list.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.notices-list.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.notices-list.previous-page:  Previous page`;
    email: any;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.notices-list.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.notices-list.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.notices-list.page-part-two: of` + `${amountPages}`;
    }

  constructor(
    private state: RxState<NoticesListComponentState>,
    private noticesFacade: NoticesFacade,
    private officeFacade: OfficeFacade,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private userFacade: UserFacade,
  ) {
    this.state.set(initialNoticesListComponentState);
    this.state.connect('notices', this.noticesFacade.notices$);
    this.state.connect('selectedNotices', this.noticesFacade.selectedNotice$);
    this.searchForm = this.fb.group({
      searchTerm: '',
      noticeFilter: [''],
      startDate: [''],
      endDate: [new Date()],
    });
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect('noticeFilters', this.noticesFacade.noticeFilters$);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.noticesFacade.dispatchGetNotices(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.notices$.subscribe((notices) => {
      if (notices) {
        this.notices = notices;
      }
    });
    this.selectedNotices$.subscribe((notices) => {
      if (notices) {
        this.selectedNotices = notices;
      }
    });
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
    this.onDateRangeChange();
  }
  handleDataSource(){
    if(this.notices){
      return this.notices.items;
    }
    return [];
  }
  addNotices() {
    this.officeFacade.dispatchResetSelectedOffices();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.noticesFacade.dispatchSetUpdateStatus(false);
    this.router.navigate([NOTICES_FORM_ROUTE]);
  }
  loadPaginatedProcessInstances(event: PageEvent) {
    this.noticesFacade.dispatchGetNotices(event.pageIndex + 1, event.pageSize);
  }
  onSelect(notice: Notice) {
    this.noticesFacade.dispatchSetSelectedNotice(notice);
    this.router.navigate([`${NOTICES_ROUTE}`, notice.id]);
  }
  deleteNotice(event: MouseEvent, notice: Notice) {
    event.stopPropagation();
    if (!notice.id) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.notices-list.delete-notice-part-1:Are you sure you want to delete the` ,
        boldText: ` "${notice!.title}" ` ,
        regularTextTwo: $localize`:@@documents.notices-form.delete-notice-part-2:Notice?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (notice.id) this.noticesFacade.dispatchDeleteNotice(notice.id);
      }
    });
  }

  onDateRangeChange() {
    this.email = this.currentLoggedInUser?.email;
    this.searchForm.get('startDate')?.valueChanges.subscribe(() => {
      this.filterNoticesByDateRange();
    });

    this.searchForm.get('endDate')?.valueChanges.subscribe(() => {
      this.filterNoticesByDateRange();
    });
  } 

  onNoticeFilterSelect() {
    this.getNotices();
  }

  onSerchTermChange() {
    this.searchForm
      .get('searchTerm')
      ?.valueChanges.pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.getNotices();
      });
  }

  getNotices() {
    let isDraft = false;
    let isSent = false;
    let isReceived = false;
    let searchTerm = this.searchForm.get('searchTerm')?.value;
    let noticeFilter = this.searchForm.get('noticeFilter')?.value;
    if(noticeFilter.length) {
    noticeFilter.forEach((element: string) => {
      if (element.trim() === this.draft) isDraft = true;
      if (element.trim() === this.sent) isSent = true;
      if (element.trim() === this.received) isReceived = true;
    });
  }
    if (!isDraft && !isSent && !isReceived && !searchTerm) {
      this.noticesFacade.dispatchGetNotices(1, 10);
      return;
    }
    this.noticesFacade.dispatchSearchNotice(
      isDraft,
      isSent,
      isReceived,
      searchTerm,
    );
  }

    onRowClick(notice: Notice) {
      this.noticesFacade.dispatchSetSelectedNotice(notice);
      this.router.navigate([`${NOTICES_ROUTE}`, notice.id]);
    }
  updateNotice(event: MouseEvent, notice: Notice) {
    event.stopPropagation();
    if (!notice.id) return;
    this.officeFacade.dispatchResetSelectedOffices();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.noticesFacade.dispatchGetNoticeDetial(notice.id);
    this.noticesFacade.dispatchGetNoticeAccessData(notice.id);
    this.noticesFacade.dispatchSetUpdateStatus(true);
    this.router.navigate([NOTICES_UPDATE_ROUTE]);
  }

  getNoticeStatus(notice: Notice): string {
    if (notice.noticeStatus?.status === 'Draft') {
      return "Draft";
    }
    if (notice.noticeStatus?.status ==='Completed') {

      if(notice.sentByUserId === this.currentLoggedInUser?.id)
        return "Sent";
      else
        return  "Received";
    }
    return '';
  }

  filterNoticesByDateRange() {
    const startDate = this.searchForm.get('startDate')?.value;
    const endDate = this.searchForm.get('endDate')?.value;
    let isDraft = false;
    let isSent = false;
    let isReceived = false;

    const noticeFilter = this.searchForm.get('noticeFilter')?.value;
    if (noticeFilter?.length) {
        noticeFilter.forEach((element: string) => {
            if (element.trim() === this.draft) isDraft = true;
            if (element.trim() === this.sent) isSent = true;
            if (element.trim() === this.received) isReceived = true;
        });
    }

    if (startDate && endDate) {
        this.noticesFacade.dispatchSearchNoticeWithDate(
            this.email,
            startDate.toISOString(),
            endDate.toISOString(),
            isDraft,
            isSent,
            isReceived,
            1,
            10,
        );
    }
  }

  hasCreateNoticeRecievedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.CreateNotice,
    );
  }
  hasUpdateNoticePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.UpdateNotice,
    );
  }
  hasDeleteNoticePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.DeleteNotice,
    );
  }

  hasSearchNoticePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.SearchNotices,
    );
  }

  hasNoticeFilterPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.GetNoticeFilter,
    );
  }
}
