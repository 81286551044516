import { DeleteProcessInstanceFormComponent } from './components/process-instance/delete-process-instance-form/delete-process-instance-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResearchesRoutingModule } from './researches-routing.module';
import { StageFormComponent } from './components/stage/stage-form/stage-form.component';
import { ProcessFormComponent } from './components/process/process-form/process-form.component';
import { ProcessListComponent } from './components/process/process-list/process-list.component';
import { StageListComponent } from './components/stage/stage-list/stage-list.component';
import { ResearchesHomeComponent } from './components/researches-home/researches-home.component';
import { SharedModule } from '../shared/shared.module';
import { ProcessState } from './store/process.state';
import { CriteriaFormComponent } from './components/stage/criteria-form/criteria-form.component';
import { NgxsModule } from '@ngxs/store';
import { ViewStageCriteriaComponent } from './components/process/view-stage-criteria/view-stage-criteria.component';
import { StageState } from './store/stage.state';
import { RxState } from '@rx-angular/state';
import { ProcessDetailComponent } from './components/process/process-detail/process-detail.component';
import { ProcessDetailState } from './store/process-detail.state';
import { CriterionService } from './services/criterion.service';
import { SearchFormComponent } from './components/task/search-form/search-form.component';
import { StageInstanceDetailComponent } from './components/stage/stage-instance-detail/stage-instance-detail.component';

import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskState } from './store/task.state';
import { ProcessInstanceListComponent } from './components/process-instance/process-instance-list/process-instance-list.component';
import { ProcessInstanceCardComponent } from './components/process-instance/process-instance-card/process-instance-card.component';
import { ProcessInstanceState } from './store/process-instance.state';
import { ProcessInstanceFormComponent } from './components/process-instance/process-instance-form/process-instance-form.component';
import { ProcessInstanceSearchComponent } from './components/process-instance/process-instance-search/process-instance-search.component';
import { TaskFileUploadComponent } from './components/task/task-file-upload/task-file-upload.component';
import { TaskComposeComponent } from './components/task/task-compose/task-compose.component';
import { ProcessInstanceDetailComponent } from './components/process-instance/process-instance-detail/process-instance-detail.component';
import { TaskFormcomponent } from './components/task/task-form/task-form.component';
import { TaskDetailComponent } from './components/task/task-detail/task-detail.component';
import { FormsModule } from '@angular/forms';
import { StageInstanceDetailState } from './store/stage-instance-detail.state';
import { TaskSearchResultComponent } from './components/task/task-search-result/task-search-result.component';
import { EvaluationFormComponent } from './components/stage/evaluation-form/evaluation-form.component';
import { ResearchDeadlineComponent } from './components/research-deadline/research-deadline.component';
import { SearchProcessComponent } from './components/process/search-process/search-process.component';
import { StageInstanceState } from './store/stage-instance.state';
import { TaskCommentComponent } from './components/task/task-comment/task-comment.component';
import { TaskDocumentListComponent } from './components/task/task-document-list/task-document-list.component';
import { TaskFileUploadOptionDialogComponent } from './components/task/task-file-upload-option-dialog/task-file-upload-option-dialog.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ArchivedProcessInstanceListComponent } from './components/archived-process-instance-list/archived-process-instance-list.component';
import { CriteriaUpdateFormComponent } from './components/stage/criteria-update-form/criteria-update-form.component';
import { TaskReplyCommentComponent } from './components/task/task-reply-comment/task-reply-comment.component';
import { TaskBoardComponent } from './components/task/task-board/task-board.component';
import { BreadcrumbNavigationComponent } from './components/breadcrumb-navigation/breadcrumb-navigation.component';
import { ResearchesHomeState } from './store/researches-home.state';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddTeamMemberFormComponent } from './components/process-instance/add-team-member-form/add-team-member-form.component';
import { AssignedTasksComponent } from './components/task/assigned-tasks/assigned-tasks.component';
import { AssignedTaskDetailComponent } from './components/task/assigned-task-detail/assigned-task-detail.component';
import { TorConfigurationModule } from './tor-settings/tor-settings.module';
import { TorConfigurationState } from './tor-settings/store/tor-setting.state';
import { RequestedReviewsComponent } from './components/reviews/requested-reviews/requested-reviews.component';
import { ReviewDetailComponent } from './components/reviews/review-detail/review-detail.component';
import { TorState } from './tor/store/tor.state';
import { TorModule } from './tor/tor.module';
import { PublicationOptionsComponent } from './components/publication-options/publication-options.component';
import { PublicationOptionState } from './store/publication-option.state';
import { PublicationListComponent } from './components/publication/publication-list/publication-list.component';

import { ConfigurePublicationTemplateComponent } from './components/configure-publication-template/configure-publication-template.component';
import { PublicationState } from './store/publication.state';
import { ChoosePublicationOptionFormComponent } from './components/publication-options/choose-publication-option-form/choose-publication-option-form.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ResearchDashboardState } from './dashboard/store/research-dashboard.state';
import { DashboardModule } from './dashboard/dashboard.module';
import { PublicationDetailComponent } from './components/publication/publication-detail/publication-detail.component';
import { PublicationTemplateDownloadComponent } from './components/publication/publication-template-download/publication-template-download.component';
import { UploadFileComponent } from './components/publication/upload-file/upload-file.component';
import { RecievedReviewsComponent } from './components/reviews/recieved-reviews/recieved-reviews.component';
import { PublicationCommentComponent } from './components/publication/publication-comment/publication-comment.component';
import { ResearchesSettingsComponent } from './components/researches-settings/researches-settings.component';
import { CurrencyState } from './store/currency.state';
// Remove the duplicate import statement

@NgModule({
  declarations: [
    StageFormComponent,
    ProcessFormComponent,
    ProcessListComponent,
    ProcessDetailComponent,
    TaskListComponent,
    StageListComponent,
    ViewStageCriteriaComponent,
    ResearchesHomeComponent,
    CriteriaFormComponent,
    SearchFormComponent,
    StageInstanceDetailComponent,

    ProcessInstanceListComponent,
    ProcessInstanceCardComponent,
    ProcessInstanceFormComponent,
    ProcessInstanceSearchComponent,
    TaskFileUploadComponent,
    TaskComposeComponent,
    ProcessInstanceDetailComponent,
    TaskSearchResultComponent,
    TaskFormcomponent,
    TaskDetailComponent,
    EvaluationFormComponent,
    ResearchDeadlineComponent,
    SearchProcessComponent,

    TaskCommentComponent,
    TaskDocumentListComponent,
    TaskFileUploadOptionDialogComponent,
    ArchivedProcessInstanceListComponent,
    CriteriaUpdateFormComponent,
    TaskReplyCommentComponent,
    TaskBoardComponent,
    BreadcrumbNavigationComponent,
    AddTeamMemberFormComponent,
    AssignedTasksComponent,
    AssignedTaskDetailComponent,
    RequestedReviewsComponent,
    ReviewDetailComponent,
    PublicationOptionsComponent,
    PublicationListComponent,
    ConfigurePublicationTemplateComponent,
    ChoosePublicationOptionFormComponent,
    PublicationDetailComponent,
    PublicationTemplateDownloadComponent,
    UploadFileComponent,
    RecievedReviewsComponent,
    PublicationCommentComponent,
    ResearchesSettingsComponent,
    DeleteProcessInstanceFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ResearchesRoutingModule,
    FormsModule,
    CdkAccordionModule,
    NgxMaterialTimepickerModule,
    FontAwesomeModule,
    TorConfigurationModule,
    TorModule,
    NgxEchartsModule,
    DashboardModule,
    NgxsModule.forFeature([
      StageState,
      ProcessState,
      ProcessDetailState,
      ProcessInstanceState,
      TaskState,
      StageInstanceDetailState,
      StageInstanceState,
      ResearchesHomeState,
      TorState,
      TorConfigurationState,
      PublicationOptionState,
      PublicationState,
      ResearchDashboardState,
      CurrencyState
    ]),
  ],
  providers: [CriterionService, RxState],
})
export class ResearchesModule {}
