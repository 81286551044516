import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DownloadCurrencyExcelTemplate, GetCurrencies, SetDefaultCurrency, UploadCurrencyExcel } from '../store/currency.actions';
import { CurrencySelector } from '../store/currency.selector';
import { Observable } from 'rxjs';
import { CurrencyData } from '../models/currency.model';

@Injectable({
  providedIn: 'root',
})
export class CurrencyFacade {
  constructor(private store: Store) {}

  @Select(CurrencySelector.templateExcelFileUrl)
  templateExcelFileUrl$!: Observable<string>;

  @Select(CurrencySelector.currencies)
  currencies$!: Observable<CurrencyData[]>;

  dispatchUploadCurrencyExcel(excelFile: File) {
    return this.store.dispatch(new UploadCurrencyExcel(excelFile));
  }

  dispatchDownloadCurrencyExcelTemplate() {
    return this.store.dispatch(new DownloadCurrencyExcelTemplate());
  }

  dispatchGetCurrencies() {
    this.store.dispatch(new GetCurrencies());
  }

  dispatchSetDefaultCurrency(id: string) {
    this.store.dispatch(new SetDefaultCurrency(id));
  }
}
