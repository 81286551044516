import { Component, Input } from '@angular/core';
import { Workflow } from '../../models/workflow.model';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { WORKFLOWS_ROUTE} from 'src/app/core/constants/routes';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { WorkflowCommentFacade } from 'src/app/documents/workflow-comments/facade/workflow-comment.facade';

@Component({
  selector: 'app-workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss'],
})
export class WorkflowCardComponent {
  @Input() workflow: Workflow | undefined;
  @Input() isArchived?: boolean;

  constructor(
    private router: Router,
    private workflowFacade: WorkflowFacade,
    private workflowCommentFacade: WorkflowCommentFacade,
    private dialog: MatDialog,
  ) {}

  navigateToWorkflowSteps(workflow?: Workflow) {}
  OpenCancelConfirmationDialog(event: MouseEvent) {
    event.stopPropagation();
    if (!this.workflow?.id) {
      this.dialog.closeAll();
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: `Are you sure you want to cancel this workflow?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowFacade.dispatchCancelWorkflow(this.workflow?.id as string);
        this.dialog.closeAll();
      }
    });
  }

  downloadWorkflow(event: MouseEvent) {
    if (this.isArchived) {
      this.workflowFacade.dispatchDownloadArchivedWorkflow(this.workflow!.id);
      return;
    }
    this.workflowFacade.dispatchDownloadWorkflow(this.workflow!.id);
  }

  detailPage() {
    if (!this.workflow)return;
    this.workflowFacade.dispatchSetSelectedWorkflow(this.workflow);
    this.workflowCommentFacade.dispatchResetWorkflowComments();
    this.router.navigate([WORKFLOWS_ROUTE, this.workflow.id], {
      queryParams: { isArchived: this.isArchived },
    });
  }

  hasDownloadWorkflowPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.DownloadWorkflow,
    );
  }

  handleMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
