import { Component, OnInit, ViewChild } from '@angular/core';
import { ProcessInstance } from '../../models/process-instance.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstanceFacade } from '../../facades/process-instance.facades';
import { RxState } from '@rx-angular/state';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface ProcessInstanceListComponentState {
  archivedProcessInstances: PaginatedList<ProcessInstance> | undefined;
}

const initialProcessInstanceListComponentState: ProcessInstanceListComponentState =
  {
    archivedProcessInstances: undefined,
  };

@Component({
  selector: 'app-archived-process-instance-list',
  templateUrl: './archived-process-instance-list.component.html',
  styleUrls: ['./archived-process-instance-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ArchivedProcessInstanceListComponent}],
})
export class ArchivedProcessInstanceListComponent implements OnInit, MatPaginatorIntl {
  archivedProcessInstances$ = this.state.select('archivedProcessInstances');
  archivedProcessInstances: PaginatedList<ProcessInstance> | undefined =
    undefined;
  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;

  firstPageLabel = $localize`:@@researches.archived-process-instance-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.archived-process-instance-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.archived-process-instance-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.archived-process-instance-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.archived-process-instance-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.archived-process-instance-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.archived-process-instance-list.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.archived-process-instance-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private processInstanceFacade: ProcessInstanceFacade,
    private state: RxState<ProcessInstanceListComponentState>,
  ) {
    this.state.set(initialProcessInstanceListComponentState);
    this.state.connect(
      'archivedProcessInstances',
      this.processInstanceFacade.archivedProcessInstances$,
    );
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.processInstanceFacade.dispatchGetArchivedProcessInstances(
      (this.paginator?.pageIndex ?? 0) + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.archivedProcessInstances$.subscribe((processInstances) => {
      if (processInstances) {
        this.archivedProcessInstances = processInstances;
        this.pageIndex = processInstances.pageNumber - 1;
      }
    });
  }

  loadPaginatedProcessInstances(event: PageEvent) {
    this.processInstanceFacade.dispatchGetArchivedProcessInstances(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  hasGetArchivedProcessInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.GetArchivedProcessInstances,
    );
  }
}
