import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowComment } from '../../models/workflow-comment.model';
import { RxState } from '@rx-angular/state';
import { WorkflowCommentFacade } from '../../facade/workflow-comment.facade';
import { Workflow } from 'src/app/documents/workflows/models/workflow.model';
import { WorkflowFacade } from 'src/app/documents/workflows/facade/workflow.facade';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface WorkflowCommentComponentState {
  workflowComments: PaginatedList<WorkflowComment> | undefined;
  selectedWorkflowComment: WorkflowComment | undefined;
  selectedWorkflow: Workflow | undefined;
  update: boolean;
  reply: boolean;
  isUpdatingReply?: boolean;
  selectedReply?: WorkflowComment;
}

const initWorkflowCommentComponentState: WorkflowCommentComponentState = {
  workflowComments: undefined,
  selectedWorkflowComment: undefined,
  selectedWorkflow: undefined,
  update: false,
  reply: false,
};

@Component({
  selector: 'app-workflow-comment',
  templateUrl: './workflow-comment.component.html',
  styleUrls: ['./workflow-comment.component.scss'],
})
export class WorkflowCommentComponent implements OnInit {
  @Input() isArchived?: boolean;

  workflowComments: PaginatedList<WorkflowComment> | undefined;
  workflowComments$ = this.state.select('workflowComments');

  selectedWorkflow: Workflow | undefined;
  selectedWorkflow$ = this.state.select('selectedWorkflow');

  selectedWorkflowComment: WorkflowComment | undefined;
  selectedWorkflowComment$ = this.state.select('selectedWorkflowComment');

  selectedReply?: WorkflowComment;
  selectedReply$ = this.state.select('selectedReply');

  update: boolean = false;
  update$ = this.state.select('update');

  reply: boolean = false;
  reply$ = this.state.select('reply');

  isUpdatingReply?: boolean = false;
  isUpdatingReply$ = this.state.select('isUpdatingReply');

  commentForm: FormGroup;

  placeholderToggleLabel = {
    addCommentField: $localize`:@@documents.workflow-comment.add-comment: Add Comment`,
}

  constructor(
    private fb: FormBuilder,
    private state: RxState<WorkflowCommentComponentState>,
    private workflowCommentFacade: WorkflowCommentFacade,
    private workflowFacade: WorkflowFacade,
    private elementRef: ElementRef,
  ) {
    this.state.set(initWorkflowCommentComponentState);
    this.state.connect(
      'workflowComments',
      this.workflowCommentFacade.workflowComments$,
    );
    this.state.connect(
      'selectedWorkflow',
      this.workflowFacade.selectedWorkflow$,
    );
    this.state.connect(
      'selectedWorkflowComment',
      this.workflowCommentFacade.selectedWorkflowComment$,
    );
    this.state.connect('update', this.workflowCommentFacade.update$);

    this.state.connect('reply', this.workflowCommentFacade.reply$);

    this.state.connect(
      'selectedReply',
      this.workflowCommentFacade.selectedReply$);

      this.state.connect('isUpdatingReply', this.workflowCommentFacade.isUpdatingReply$);

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.selectedWorkflow$.subscribe((selectedWorkflow) => {
      this.selectedWorkflow = selectedWorkflow;
    });

    this.workflowCommentFacade.dispatchGetWorkflowComments(
      this.selectedWorkflow?.id!,
      Boolean(this.isArchived),
    );

    this.workflowComments$.subscribe((workflowComments) => {
      this.workflowComments = workflowComments;
    });

    this.selectedWorkflowComment$.subscribe((selectedWorkflowComment) => {
      this.selectedWorkflowComment = selectedWorkflowComment;
    });

    this.update$.subscribe((update) => {
      this.update = update;
    });

    this.reply$.subscribe((reply) => {
      this.reply = reply;
    });

    this.selectedReply$.subscribe((selectedReply) => {
      this.selectedReply = selectedReply;
    });

    this.isUpdatingReply$.subscribe((isUpdatingReply) => {
      this.isUpdatingReply = isUpdatingReply;
    });
  }

  addComment() {
    const { valid, touched, dirty } = this.commentForm;
    if (valid && (touched || dirty)) {
      if (!this.update && !this.reply && !this.isUpdatingReply) {
        this.workflowCommentFacade.dispatchCreateWorkflowComment(
          this.selectedWorkflow?.id!,
          this.commentForm.value.comment,
        );
      }
      if (this.reply) {
        this.workflowCommentFacade.dispatchReplyWorkflowComment(
          this.selectedWorkflowComment!.id!,
          this.commentForm.value.comment,
        );
      }
      if (this.update && !this.reply) {
        this.workflowCommentFacade.dispatchEditWorkflowComment(
          this.selectedWorkflow?.id!,
          this.selectedWorkflowComment!.id!,
          this.commentForm.value.comment,
        );
      }

      if (this.isUpdatingReply) {
        this.workflowCommentFacade.dispatchUpdateWorkflowCommentReply(
          this.selectedWorkflowComment?.id as string,
          this.selectedReply?.id as string,
          this.commentForm.value.comment,
        );
      }
      this.commentForm.reset();
    }
  }

  cancelComment() {
    if (this.update) {
      this.workflowCommentFacade.dispatchSetUpdateWorkflowComment(false);
    }
    if (this.reply) {
      this.workflowCommentFacade.dispatchSetReplyWorkflowComment(false);
    }
    this.commentForm.reset();
  }

  updateForm() {
    if (this.selectedWorkflowComment && this.update) {
      this.commentForm.setValue({
        comment: this.selectedWorkflowComment.comment,
      });
    }
    else if(this.selectedReply && this.isUpdatingReply) {
      this.commentForm.setValue({
        comment: this.selectedReply.comment,
      });
    }
    this.scrollToForm();
  }

  replyCommentForm() {
    if (this.selectedWorkflowComment && this.reply) {
      this.commentForm.setValue({
        comment: '',
      });
    }
    this.scrollToForm();
  }

  scrollToForm() {
    const formElement =
      this.elementRef.nativeElement.querySelector('#comment-form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
