import { StageStateModel } from './../../researches/store/stage.state';
import { Selector, createPropertySelectors } from '@ngxs/store';
import { FileState, FileStateModel } from './file.state';

export class FileSelector {
  static slices = createPropertySelectors<FileStateModel>(FileState);

  @Selector([FileState])
  static selectedFilesIds(stateModel: FileStateModel) {
    return stateModel.selectedFilesIds;
  }

  @Selector([FileState])
  static selectedFile(stateModel: FileStateModel) {
    return stateModel.selectedFile;
  }

  @Selector([FileState])
  static selectedFiles(stateModel: FileStateModel) {
    return stateModel.selectedFiles;
  }

  @Selector([FileState])
  static audioBlob(stateModel: FileStateModel) {
    return stateModel.audioBlob;
  }

  @Selector([FileState])
  static trash(stateModel: FileStateModel) {
    return stateModel.trash;
  }

  @Selector([FileState])
  static archivedFiles(stateModel: FileStateModel) {
    return stateModel.archivedFiles;
  }

  @Selector([FileState])
  static isSharingFile(state: FileStateModel) {
    return state.isSharingFile;
  }
}
