import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-length-research-count',
  templateUrl: './full-length-research-count.component.html',
  styleUrls: ['./full-length-research-count.component.scss'],
})
export class FullLengthResearchCountComponent {
  @Input()
  researchesCount: number = 0;

  navigateToReseachList() {
    const fullLengthReseachComponent = document.getElementById(
      'fullLengthResearchComponent',
    );
    if (fullLengthReseachComponent) {
      fullLengthReseachComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
