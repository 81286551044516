import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, from, map } from 'rxjs';
import {
  CurrentLoggedInUser,
  User,
  UserProfile,
  UserUpdate,
  UserWithPassword,
} from '../models/user.model';
import { ForgetPasswordRequest } from '../models/forget-password-request.model';
import { ResetPasswordRequest } from '../models/reset-password-request.model';
import {
  ChangePassword,
  DeleteUser,
  GetUsers,
  GetUsersByRoleId,
  GetUsersBySearch,
  RegisterUser,
  ResetChangePasswordStatus,
  SelectUser,
  UpdateUser,
  ForgetPassword,
  ResetForgetPasswordStatus,
  ResetPassword,
  ResetResetPasswordStatus,
  GetAdmins,
  ToggleStatus,
  UpdateUserRole,
  GetUsersByRolesAndOffices,
  SetFilterdUsersEmpty,
  GetUsersByMultipleRoleIds,
  GetApprovers,
  GetEvaluators,
  SetApproversAndEvaluatorsEmpty,
  GetUsersByOfficeId,
  GetOfficeUsersByRoleIds,
  GetLoginHistory,
  AssignRevokePermissions,
  GetPermissionsInheritedFromRoles,
  GetUserRoles,
  AssignRevokeRoles,
  GetUserPermissionModules,
  GetPermissionsOnlyFromDependency,
  GetUsersDefaultPasswords,
  GetUsersDefaultPasswordsBySearch,
  ResetDefaultPassword,
  GetPasswordPolicy,
  GetEmailSettings,
  UpdateEmailSettings,
  GetCurrentLoggedInUser,
  UpdateUserProfile,
} from '../store/user.actions';
import { UserSelector } from '../store/user.selector';
import { ChangePasswordRequest } from '../models/change-password-request.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { PasswordPolicy } from '../models/password-poilcy.model';
import { EmailSettings } from '../models/email-settings';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  users$: Observable<User[]> = this.store.select(UserSelector.slices.users);
  usersWithoutLoggedInUser$: Observable<User[]> = this.store.select(
    UserSelector.selectUsersWithoutLoggedInUser,
  );

  totalCount$: Observable<number> = this.store.select(
    UserSelector.slices.totalCount,
  );

  loginHistories$ = this.store.select(UserSelector.slices.loginHistories);

  selectedUser$: Observable<User | undefined> = this.store.select(
    UserSelector.slices.selectedUser,
  );

  isForgetPasswordSuccessful$: Observable<boolean> = this.store.select(
    UserSelector.slices.isForgetPasswordSuccessful,
  );

  isResetPasswordSuccessful$: Observable<boolean> = this.store.select(
    UserSelector.slices.isResetPasswordSuccessful,
  );
  isChangePasswordSuccessful$: Observable<boolean> = this.store.select(
    UserSelector.slices.isChangePasswordSuccessful,
  );

  filterdUsers$: Observable<User[]> = this.store.select(
    UserSelector.slices.filterdUsers,
  );

  filteredUsersByMultipleRoles$: Observable<User[]> = this.store.select(
    UserSelector.slices.filteredUsersByMultipleRoles,
  );

  currentLoggedInUser$: Observable<CurrentLoggedInUser | undefined> =
    this.store.select(UserSelector.slices.currentLoggedInUser);

  emailSettings$ = this.store.select(UserSelector.slices.emailSettings);
  constructor(private store: Store) {}

  @Select(UserSelector.approvers)
  approvers$!: Observable<User[]>;

  @Select(UserSelector.evaluators)
  evaluators$!: Observable<User[]>;

  @Select(UserSelector.officeUsers)
  officeUsers$!: Observable<User[]>;

  @Select(UserSelector.usersWithPassword)
  usersWithPassword$!: Observable<PaginatedList<UserWithPassword>>;

  @Select(UserSelector.closeRegistrationModal)
  closeRegistrationModal$!: Observable<boolean>;

  @Select(UserSelector.passwordPolicy)
  passwordPolicy$!: Observable<PasswordPolicy>;

  dispatchRegisterUser(user: User) {
    this.store.dispatch(new RegisterUser(user));
  }

  dispatchSelectUser(user: User) {
    this.store.dispatch(new SelectUser(user));
  }

  dispatchGetUsers(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetUsers(pageNumber, pageSize));
  }

  dispatchGetAdmins(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetAdmins(pageNumber, pageSize));
  }
  dispatchGetUsersBySearch(
    search: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(new GetUsersBySearch(search, pageNumber, pageSize));
  }

  dispatchGetUsersByRoleId(
    roleId: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(new GetUsersByRoleId(roleId, pageNumber, pageSize));
  }

  dispatchGetUsersByMultipleRoleIds(
    roleIds: string[],
    pageNumber?: number,
    pageSize?: number,
  ) {
    return this.store.dispatch(
      new GetUsersByMultipleRoleIds(roleIds, pageNumber, pageSize),
    );
  }

  dispatchUpdateUser(user: UserUpdate) {
    this.store.dispatch(new UpdateUser(user));
  }

  dispatchUpdateUserRole(id: string, roleId: string) {
    this.store.dispatch(new UpdateUserRole(id, roleId));
  }

  dispatchDeleteUser(id: string) {
    this.store.dispatch(new DeleteUser(id));
  }

  dispatchForgetPassword(forgetPassword: ForgetPasswordRequest) {
    return this.store.dispatch(new ForgetPassword(forgetPassword));
  }

  dispatchResetForgetPasswordStatus() {
    this.store.dispatch(new ResetForgetPasswordStatus());
  }

  dispatchResetPassword(resetPasswoerd: ResetPasswordRequest) {
    return this.store.dispatch(new ResetPassword(resetPasswoerd));
  }
  dispatchResetResetPasswordStatus() {
    this.store.dispatch(new ResetResetPasswordStatus());
  }
  dispatchToggleStatus(id?: string) {
    this.store.dispatch(new ToggleStatus(id));
  }

  dispatchChangePassword(changePassword: ChangePasswordRequest) {
    return this.store.dispatch(new ChangePassword(changePassword));
  }

  dispatchResetChangePasswordStatus() {
    this.store.dispatch(new ResetChangePasswordStatus());
  }

  dispachGetUsersByRolesAndOffices(roleIds: string[], officeIds: string[]) {
    this.store.dispatch(new GetUsersByRolesAndOffices(roleIds, officeIds));
  }

  dispachSetFilterdUsersEmpty() {
    this.store.dispatch(new SetFilterdUsersEmpty());
  }

  dispatchGetApprovers(
    roleIds: string[],
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(new GetApprovers(roleIds, pageNumber, pageSize));
  }

  dispatchGetEvaluators(
    roleIds: string[],
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(new GetEvaluators(roleIds, pageNumber, pageSize));
  }

  dispatchSetApproversAndEvaluatorsEmpty() {
    this.store.dispatch(new SetApproversAndEvaluatorsEmpty());
  }

  dispatchGetUsersByOfficeId(
    officeId?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(new GetUsersByOfficeId(officeId, pageNumber, pageSize));
  }

  dispatchGetOfficeUsersByRoleIds(roleIds: string[]) {
    this.store.dispatch(new GetOfficeUsersByRoleIds(roleIds));
  }

  dispatchLoginHistory(pageNumber: number = 1, pageSize: number = 12) {
    this.store.dispatch(new GetLoginHistory(pageNumber, pageSize));
  }

  dispatchAssignRevokePermissions(userId: string, permissionsId: string[]) {
    this.store.dispatch(new AssignRevokePermissions(userId, permissionsId));
  }

  dispatchGetPermissionsFromRoles(userId: string) {
    this.store.dispatch(new GetPermissionsInheritedFromRoles(userId));
  }

  dispatchGetUserRoles(userId: string) {
    this.store.dispatch(new GetUserRoles(userId));
  }

  dispatchAssignRevokeUserRoles(
    roleIds: string[],
    userId?: string,
    userIds?: string[],
  ) {
    this.store.dispatch(new AssignRevokeRoles(roleIds, userId, userIds));
  }

  hasPermission(permission: string): Observable<boolean> {
    if (IS_DEVELOPMENT) {
      return from([true]);
    }
    return this.currentLoggedInUser$.pipe(
      map((user) => user?.permissions.includes(permission) as boolean),
    );
  }
  hasFeaturePermission(feature: string): Observable<boolean> {
    if (IS_DEVELOPMENT) {
      return from([true]);
    }
    return this.currentLoggedInUser$.pipe(
      map((user) => user?.featureNames.includes(feature) as boolean),
    );
  }
  hasModulePermission(module: any): Observable<boolean> {
    if (IS_DEVELOPMENT) {
      return from([true]);
    }
    return this.currentLoggedInUser$.pipe(
      map((user) => user?.moduleNames.includes(module) as boolean),
    );
  }

  dispatchGetPermissionsOnlyFromDependency(userId: string) {
    this.store.dispatch(new GetPermissionsOnlyFromDependency(userId));
  }

  dispatchGetUserPermissionModules(userId: string) {
    this.store.dispatch(new GetUserPermissionModules(userId));
  }

  dispatchGetUsersDefaultPasswords(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetUsersDefaultPasswords(pageNumber, pageSize));
  }

  dispatchGetUsersDefaultPasswordsBySearch(search: string, pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetUsersDefaultPasswordsBySearch(search, pageNumber, pageSize));
  }

  dispatchResetDefaultPassword(userId: string) {
    this.store.dispatch(new ResetDefaultPassword(userId));
  }

  dispatchGetPasswordPolicy() {
    this.store.dispatch(new GetPasswordPolicy());
  }

  dispatchGetEmailSettings() {
    this.store.dispatch(new GetEmailSettings());
  }

  dispatchUpdateEmailSettings(settings: EmailSettings) {
    this.store.dispatch(new UpdateEmailSettings(settings));
  }

  dispatchGetCurrentLoggedInUser() {
    this.store.dispatch(new GetCurrentLoggedInUser());
  }

  dispatchUpdateUserProfile(userProfile: UserProfile) {
    this.store.dispatch(new UpdateUserProfile(userProfile));
  }
}
