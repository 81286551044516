import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { Injectable } from '@angular/core';
import { ContactUsService } from "./../services/contact-us.service"
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  ContactForm,
} from '../models/contact-form.model';
import{ChangeContactUsPriority, ChangeContactUsStatus, CreateContactUs, DownloadAttachment, GetContactUsDetail, GetMyPaginatedContacts, GetPaginatedContactUs, GetSelectedContactUs, PreviewAttachment} from './contact.action'
import {
    SetProgressOff,
    SetProgressOn,
  } from 'src/app/core/store/progress-status.actions';
  import { tap } from 'rxjs';
  import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
  import {
    insertItem,
    patch,
    removeItem,
    updateItem,
  } from '@ngxs/store/operators';
import { Update } from 'src/app/researches/store/task.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

  export interface ContactStateModel {
    contactSent: ContactForm[];
    paginatedContactUs: PaginatedList<ContactForm>;
    selectedContactUs: ContactForm | undefined;
  }
  const CONTACT_STATE_TOKEN = new StateToken<ContactStateModel>('contactStateModel');

  const defaults: ContactStateModel = {
    contactSent: [],
    paginatedContactUs: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0},
    selectedContactUs: undefined,
 };
 @State<ContactStateModel>({
    name: CONTACT_STATE_TOKEN,
    defaults: defaults,
  })
  @Injectable()
  export class ContactState {
    constructor(
      private operationStatus: OperationStatusService,
      private contactService: ContactUsService,
      private store: Store,
      private snackBar: MatSnackBar,
    ) {}

    @Action(CreateContactUs)
    createContactUs(
      { setState, getState }: StateContext<ContactStateModel>,
      {contactUs  }: CreateContactUs,
    ) {
      this.store.dispatch(new SetProgressOn());
    
      let prevLength = getState().contactSent.length;

      return this.contactService.createContactUs(contactUs).pipe(
        tap((createdContactUs: ContactForm) => {
          setState(
            patch({
              contactSent: insertItem(createdContactUs, prevLength),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@user.contact.contact-us-created-successfully: Message Sent successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
    }

  @Action(GetPaginatedContactUs)
  getPaginatedContactUs(
    { patchState }: StateContext<ContactStateModel>,
    { pageNumber, pageSize }: GetPaginatedContactUs,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getPaginatedContactUs(pageNumber, pageSize).pipe(
      tap((contactList) => {
        patchState({
          contactSent: contactList.items,
          paginatedContactUs: contactList,
        });
        this.store.dispatch(new SetProgressOff());
      })
    );
  }
  
  @Action(GetMyPaginatedContacts)
  getMyPaginatedContacts(
    { patchState }: StateContext<ContactStateModel>,
    { pageNumber, pageSize }: GetMyPaginatedContacts,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getMyPaginatedContacts(pageNumber, pageSize).pipe(
      tap((contactList) => {
        patchState({
          contactSent: contactList.items,
          paginatedContactUs: contactList,
        });
        this.store.dispatch(new SetProgressOff());
      })
    );
  }

  @Action(GetContactUsDetail)
  getContactUsDetail(
    { patchState }: StateContext<ContactStateModel>,
    { id }: GetContactUsDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getContactUsDetail(id)
      .pipe(
        tap((contactDetail) => {
          patchState({
            selectedContactUs: contactDetail,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetSelectedContactUs)
  getSelectedContactUs(
    { patchState }: StateContext<ContactStateModel>,
    { id }: GetSelectedContactUs,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getContactUsDetail(id)
      .pipe(
        tap((contactDetail) => {
          if (contactDetail) {
          patchState({
            selectedContactUs: contactDetail,
          });
        }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ChangeContactUsStatus)
  changeContactUsStatus(
    { patchState }: StateContext<ContactStateModel>,
    { updatedContactUs }: ChangeContactUsStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.updateContactUsStatus(updatedContactUs)
      .pipe(
        tap((updatedContactUs) => {
          patchState({
            selectedContactUs: updatedContactUs,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ChangeContactUsPriority)
  changeContactUsPriority(
    { patchState }: StateContext<ContactStateModel>,
    { updatedContactUs }: ChangeContactUsPriority,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.updateContactUsPriority(updatedContactUs)
      .pipe(
        tap((updatedContactUs) => {
          patchState({
            selectedContactUs: updatedContactUs,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DownloadAttachment)
  downloadTaskDocument(
    { patchState }: StateContext<ContactStateModel>,
    { contactId, attachmentId, name }: DownloadAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getAttachment(contactId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        let filename = name;
        link.download = filename;
        link.click();
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(PreviewAttachment)
  previewTaskDocument(
    { setState }: StateContext<ContactStateModel>,
    { contactId, attachmentId, name }: PreviewAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.contactService.getAttachment(contactId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = screenWidth * 0.8;
        const popupHeight = screenHeight * 0.6;
        const leftPosition = (screenWidth - popupWidth) / 2;
        const topPosition = (screenHeight - popupHeight) / 2;

        const popupWindow = window.open('', '_blank', `width=${popupWidth},height=${popupHeight},left=${leftPosition},top=${topPosition},toolbar=no,location=no,status=no,menubar=no,resizable=no,scrollbars=no`);

        if (popupWindow) {
          popupWindow.document.write(`<img src="${fileUrl}" style="width: 100%; height: 100%;">`);
        } else {
          this.snackBar.open('Preview not available', 'OK', {
            duration: 10000,
            panelClass: ['warning-snackbar'],
          });
        }

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}