import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ARCHIVED_WORKFLOWS_URL,
  WORKFLOW_URL,
} from 'src/app/core/constants/api-endpoints';
import {
  FilterWorkflowDto,
  InitiateWorkflowDto,
  Workflow,
  WorkflowWithStep,
  WorkflowFilter,
  WorkflowStatus,
} from '../models/workflow.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  initiateWorkflow(initWorkflow: InitiateWorkflowDto): Observable<Workflow> {
    return this.http.post<Workflow>(
      `${WORKFLOW_URL}`,
      initWorkflow,
      this.httpOptions,
    );
  }

  getWorkflows(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<WorkflowWithStep>> {
    return this.http.get<PaginatedList<WorkflowWithStep>>(
      `${WORKFLOW_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
  getMyWorkFlows(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workflow>> {
    return this.http.get<PaginatedList<Workflow>>(
      `${WORKFLOW_URL}/notified?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  filterWorkFlows(
    filterParams: FilterWorkflowDto,
  ): Observable<PaginatedList<Workflow>> {
    const options = new HttpParams({
      fromObject: { ...filterParams },
    });
    return this.http.get<PaginatedList<Workflow>>(
      `${WORKFLOW_URL}/filter?pageNumber=${filterParams.pageNumber}&pageSize=${filterParams.pageSize}`,
      {
        params: options,
      },
    );
  }
  getOwnedWorkFlows(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workflow>> {
    return this.http.get<PaginatedList<Workflow>>(
      `${WORKFLOW_URL}/owned?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
  cancelWorkflow(workflowId: string): Observable<Workflow> {
    return this.http.put<Workflow>(
      `${WORKFLOW_URL}/change-status/${workflowId}`,
      this.httpOptions,
      {
        params: {
          status: 'Cancelled',
        },
      },
    );
  }
  archiveWorkflow(workflowId: string): Observable<Workflow> {
    return this.http.put<Workflow>(
      `${WORKFLOW_URL}/archive/${workflowId}`,
      this.httpOptions
    );
  }
  searchWorkflows(
    workflowName?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workflow>> {
    let url = `${WORKFLOW_URL}/search?workflowName=${workflowName}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<Workflow>>(url);
  }

  downloadWorkflow(workflowId: string) {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<Observable<Blob>>(
      `${WORKFLOW_URL}/download/${workflowId}`,
      options,
    );
  }

  downloadArchivedWorkflow(workflowId: string) {
    return this.http.get(`${WORKFLOW_URL}/archived/download/${workflowId}`, {
      responseType: 'blob',
    });
  }

  removeCCdUser(id: string): Observable<any> {
    return this.http.delete<any>(`${WORKFLOW_URL}/${id}`, this.httpOptions);
  }

  getArchivedWorkFlows(pageNumber: number, pageSize: number) {
    return this.http.get<PaginatedList<Workflow>>(ARCHIVED_WORKFLOWS_URL, {
      params: new HttpParams({ fromObject: { pageNumber, pageSize } }),
    });
  }

  getWorkflowFilters(): Observable<WorkflowFilter[]> {
    return this.http.get<WorkflowFilter[]>(`${WORKFLOW_URL}/workflow-status`);
  }

  getArchivedWorkFlowsByStatus(
    status:string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Workflow>> {
    return this.http.get<PaginatedList<Workflow>>(
      `${WORKFLOW_URL}/archived-by-status?status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getRejectedWorkFlows(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workflow>> {
    return this.http.get<PaginatedList<Workflow>>(
      `${WORKFLOW_URL}/rejected?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
}
