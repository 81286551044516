import { PublicationOption } from '../models/publication-option.model';
import {
  CreatePublicationInfo,
  Publication,
  PublicationDocument,
  PublicationStatus,
  PublicationStatusForDisplay
} from '../models/publication.model';

export class CreatePublication {
  static readonly type = `[Publication] ${CreatePublication.name}`;
  constructor(public publication: CreatePublicationInfo) {}
}

export class GetCompletedProcessInstances {
  static readonly type = `[Publication] ${GetCompletedProcessInstances.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetPublications {
  static readonly type = `[Publication] ${GetPublications.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SetSelectedPublicationTab {
  static readonly type = `[Publication] ${SetSelectedPublicationTab.name}`;
  constructor(public readonly selectedTabIndex: number) {}
}

export class SetSelectedPublication {
  static readonly type = `[Publication] ${SetSelectedPublication.name}`;
  constructor(public readonly publication: Publication) {}
}

export class SetSelectedPublicationOption {
  static readonly type = `[Publication] ${SetSelectedPublicationOption.name}`;
  constructor(public readonly publicationOption: PublicationOption) {}
}

export class UploadPublicationFiles {
  static readonly type = `[Publication] ${UploadPublicationFiles.name}`;
  constructor(
    public readonly files: FormData,
    public readonly id: string,
  ) {}
}

export class DeletePublicationProcessDocument {
  static readonly type = `[Publication] ${DeletePublicationProcessDocument.name}`;
  constructor(public readonly processId: string, public readonly documentId: string){}
}

export class SubmitPublicationProcess {
  static readonly type = `[Publication] ${SubmitPublicationProcess.name}`;
  constructor(public readonly id: string) {}
}

export class ChangePublicationStatus{
  static readonly type = `[Publication] ${ChangePublicationStatus.name}`;
  constructor(public readonly id: string , public readonly status: PublicationStatus, public readonly statusForDisplay: PublicationStatusForDisplay) {}
}

export class GetPaginatedPublicationComments {
  static readonly type = `[Publication] ${GetPaginatedPublicationComments.name}`;
  constructor(
    public publicationProcessId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class AddPublicationComment {
  static readonly type = `[Publication] ${AddPublicationComment.name}`;
  constructor(
    public publicationProcessId: string,
    public comment: string,
  ) {}
}

export class UpdatePublicationComment {
  static readonly type = `[Publication] ${UpdatePublicationComment.name}`;
  constructor(
    public publicationCommentId: string,
    public content: string,
  ) {}
}

export class DeletePublicationComment {
  static readonly type = `[Publication] ${DeletePublicationComment.name}`;
  constructor(public commentId: string) {}
}

export class UpdateReplyPublicationComment {
  static readonly type = `[Publication] ${UpdateReplyPublicationComment.name}`;
  constructor(
    public publicstionCommentId: string,
    public commentId: string,
    public content: string,
  ) {}
}

export class ReplyPublicationComment {
  static readonly type = `[Publication] ${ReplyPublicationComment.name}`;
  constructor(
    public commentId: string,
    public content: string,
  ) {}
}

export class UpdatePublicationCommentReply {
  static readonly type = `[Publication] ${UpdatePublicationCommentReply.name}`;
  constructor(
    public replyId: string,
    public content: string,
    public parentCommentId: string,
  ) {}
}

export class DeletePublicationCommentReply {
  static readonly type = `[Publication] ${DeletePublicationCommentReply.name}`;
  constructor(
    public replyId: string,
    public parentCommentId: string,
  ) {}
}

export class DownloadPublicationDocument {
  static readonly type = `Publication ${DownloadPublicationDocument.name}`;
  constructor(public publicationDocument: PublicationDocument) {}
}

export class PreviewPublicationDocument {
  static readonly type = `[Publication] ${PreviewPublicationDocument.name}`;
  constructor(
    public readonly publicationProcessId: string,
    public readonly fileId: string,
    public readonly fileName:string,
  ) {}
}

export class TogglePublicationComments {
  static readonly type = `[Publication] ${TogglePublicationComments.name}`;
  constructor(public readonly isPublicationCommentVisible: boolean) {}
}
