import { NgModule } from '@angular/core';
import { TorRoutingModule } from './tor-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TorListComponent } from './components/tor-list/tor-list.component';
import { TorDetailComponent } from './components/tor-detail/tor-detail.component';
import { NgxsModule } from '@ngxs/store';
import { TorState } from './store/tor.state';
import { CostBreakdownFormComponent } from './components/cost-breakdown-form/cost-breakdown-form.component';
import { TorCommentsComponent } from './components/tor-comments/tor-comments.component';
import { TorCommentCardComponent } from './components/tor-comment-card/tor-comment-card.component';
import { TorCommentReplyCardComponent } from './components/tor-comment-reply-card/tor-comment-reply-card.component';
import { TorInitiateFormComponent } from './components/tor-initiate-form/tor-initiate-form.component'; 
@NgModule({
  declarations: [
    TorListComponent,
    CostBreakdownFormComponent,
    TorDetailComponent,
    TorCommentsComponent,
    TorCommentCardComponent,
    TorCommentReplyCardComponent,
    TorInitiateFormComponent
  ],
  imports: [
    SharedModule,
    TorRoutingModule,
    NgxsModule.forFeature([TorState]), 
  ],
})
export class TorModule {}
