import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { filter, take, tap } from 'rxjs';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  AddCriterion,
  CreateCriterion,
  CreateStage,
  DeleteCriterion,
  DeleteStage,
  GetPaginatedStageCriteria,
  GetStageCriteria,
  GetStageInstanceEvaluationSchedule,
  LoadCriteria,
  OrderStage,
  RemoveCriterion,
  ResetCriteria,
  ResetSelectedCriterion,
  SaveCriteria,
  SelectEditableStage,
  SetSelectedCriterion,
  UpdateCriterion,
  UpdateProcessStageOnDelete,
  UpdateStage,
} from './stage.actions';
import {
  errorStyle,
  successStyle,
} from 'src/app/core/services/operation-status/status-style-names';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  Criterion,
  CriterionDto,
  UpdateCriterionDto,
} from '../models/criterion.model';
import { Stage } from '../models/stage.model';
import { CriterionService } from '../services/criterion.service';
import { StageService } from '../services/stage.service';
import { AddProcessStage, UpdateStageList } from './process-detail.actions';
import { ProcessDetailState } from './process-detail.state';
import { ChangeDateFormatService } from 'src/app/core/services/change-date-format/change-date-format.service';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

export interface StageStateModel {
  criteria: Criterion[];
  criteriaSum: number;
  selectedStage: Stage | null;
  selectedCriterion: Criterion | undefined;
  paginatedCriteria: PaginatedList<CriterionDto>;
}

const STAGE_STATE_TOKEN = new StateToken<StageStateModel>('stageState');

const defaults: StageStateModel = {
  criteria: [],
  criteriaSum: 0,
  selectedStage: null,
  selectedCriterion: undefined,
  paginatedCriteria: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<StageStateModel>({
  name: STAGE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class StageState {
  constructor(
    private criterionService: CriterionService,
    private stageService: StageService,
    private operationStatus: OperationStatusService,
    private store: Store,
    private changeDateFormatService: ChangeDateFormatService,
  ) {}

  private evaluateCriteriaSum(criteria: Criterion[]) {
    let sum = 0;
    criteria.forEach((c) => {
      sum += c.weight;
    });
    return sum;
  }
  @Action(AddCriterion)
  addCriterion(
    { getState, patchState }: StateContext<StageStateModel>,
    { criteria }: AddCriterion,
  ) {
    let previousCriteria = getState().criteria;
    patchState({
      criteria: [criteria, ...previousCriteria],
      criteriaSum: this.evaluateCriteriaSum([criteria, ...previousCriteria]),
    });
  }

  @Action(RemoveCriterion)
  removeCriterion(
    { getState, setState }: StateContext<StageStateModel>,
    { criterion }: RemoveCriterion,
  ) {
    const criteriaSum = getState().criteriaSum;
    if (!criterion.id) {
      this.operationStatus.displayStatus(
        $localize`:@@researches.stage.criterion-removed-part-1:Criterion:` +  `${criterion.name}` +  $localize`:@@researches.stage-state.criterion-removed-part-2: removed`,
      );
      return setState(
        patch({
          criteria: removeItem((crit) => crit.name == criterion.name),
          criteriaSum: criteriaSum - criterion.weight,
        }),
      );
    }
    return this.criterionService.removeCriteria(criterion).pipe(
      tap((removedCriterion) => {
        this.operationStatus.displayStatus(
          $localize`:@@researches.stage.criterion-removed-part-1: Criterion:` + `${criterion.name}` +  $localize`:@@researches.stage-state.criterion-removed-part-1: removed`,
        );
        setState(
          patch({
            criteria: removeItem((crit) => crit.id == criterion.id),
            criteriaSum: criteriaSum - removedCriterion.weight,
          }),
        );
      }),
    );
  }

  @Action(ResetCriteria)
  resetSavedCriteria({ patchState }: StateContext<StageStateModel>) {
    patchState({
      criteria: [],
    });
  }

  @Action(LoadCriteria)
  loadCriteria(
    { patchState }: StateContext<StageStateModel>,
    { criteria }: LoadCriteria,
  ) {
    patchState({
      criteria: criteria,
    });
  }

  @Action(DeleteStage)
  deleteStage(
    { setState }: StateContext<StageStateModel>,
    { selectedStageId }: DeleteStage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageService.deleteStage(selectedStageId).pipe(
      tap(() => {
        this.operationStatus.displayStatus(
          $localize`:@@researches.stage.stage-deleted-successfully: Stage deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new UpdateProcessStageOnDelete(selectedStageId));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateCriterion)
  createCriterion(
    { setState, getState }: StateContext<StageStateModel>,
    { criterion }: CreateCriterion,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.criterionService.addCriteria(criterion).pipe(
      tap((createdCriterion) => {
        setState(
          patch({
            paginatedCriteria: patch({
              items: insertItem(createdCriterion),
              totalCount: (getState().paginatedCriteria.totalCount += 1),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.stage.criterion-added-successfully: Criterion added successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateStage)
  createStage(
    { patchState }: StateContext<StageStateModel>,
    { stage }: CreateStage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageService.createStage(stage).pipe(
      tap((val) => {
        this.store.dispatch(new AddProcessStage(val));
        this.store.dispatch(new SetProgressOff());
        patchState({
          criteria: [],
          criteriaSum: val.criteriaSum,
        });
        this.operationStatus.displayStatus($localize`:@@researches.stage.stage-created-successfully: Stage Created Successfully!`);
      }),
    );
  }

  @Action(GetStageCriteria)
  getStageCriteria(
    { setState }: StateContext<StageStateModel>,
    { seletedStageId }: GetStageCriteria,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageService.getStageCriteria(seletedStageId).pipe(
      tap((stage) => {
        setState(
          patch({
            selectedStage: stage,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateStage)
  updateStage(
    { getState, patchState }: StateContext<StageStateModel>,
    { stage }: UpdateStage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageService.updateStage(stage).pipe(
      tap((updatedStage) => {
        const state = this.store.selectSnapshot(ProcessDetailState);
        const stageList = [...state.selectedProcessDetail.stages];
        const stageIndex = stageList.findIndex(
          (item) => item.id === updatedStage.id,
        );
        stageList[stageIndex] = updatedStage;

        patchState({
          selectedStage: updatedStage,
          criteriaSum: updatedStage.criteriaSum,
        });

        this.store.dispatch(new UpdateStageList(stageList));
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus($localize`:@@researches.stage.stage-updated-successfully: Stage updated sucessfully`, successStyle)
      }),
    );
  }

  @Action(SetSelectedCriterion)
  SetSelectedCriterion(
    { patchState }: StateContext<StageStateModel>,
    { criterion }: SetSelectedCriterion,
  ) {
    patchState({
      selectedCriterion: criterion,
    });
  }

  @Action(ResetSelectedCriterion)
  resetSelectedCriterion({ patchState }: StateContext<StageStateModel>) {
    patchState({
      selectedCriterion: undefined,
    });
  }
  @Action(UpdateCriterion)
  updateCriterion(
    { setState, getState, patchState }: StateContext<StageStateModel>,
    { criterion }: UpdateCriterion,
  ) {
    this.store.dispatch(new SetProgressOn());
    if (criterion.id !== undefined) {
      return this.criterionService.updateCriterion(criterion).pipe(
        tap((updatedCriterion) => {
          setState(
            patch({
              paginatedCriteria: patch({
                items: updateItem(
                  (fs) => fs.id === updatedCriterion.id,
                  updatedCriterion,
                ),
              }),
            }),
          );

          this.operationStatus.displayStatus(
            $localize`:@@researches.stage.criterion-updated-successfully: criterion updated successfully`,
            errorStyle,
          );

          this.store.dispatch(new ResetSelectedCriterion());
          this.store.dispatch(new SetProgressOff());
        }),
      );
    }
    const state = this.store.selectSnapshot(StageState);
    const criterionIndex: number = state.criteria.findIndex(
      (item: Criterion) => item === state.selectedCriterion,
    );
    if (criterionIndex === -1) {
      this.operationStatus.displayStatus(
        $localize`:@@researches.stage.selected-criterion-not-found: selected criterion not found`,
        errorStyle,
      );
      this.store.dispatch(new SetProgressOff());
      return;
    }

    state.criteria[criterionIndex] = criterion;
    patchState({
      criteria: state.criteria,
      criteriaSum: this.evaluateCriteriaSum(state.criteria),
    });
    this.store.dispatch(new ResetSelectedCriterion());
    this.store.dispatch(new SetProgressOff());
    return;
  }

  @Action(DeleteCriterion)
  deleteCriterion(
    { patchState, setState }: StateContext<StageStateModel>,
    { id }: DeleteCriterion,
  ) {
    return this.criterionService.deleteCriterion(id).pipe(
      tap(() => {
        this.operationStatus.displayStatus(
          $localize`:@@researches.stage.criterion-deleted-successfully: criterion deleted successfully`,
          successStyle,
        );
        setState(
          patch({
            paginatedCriteria: patch({
              items: removeItem((f) => f.id == id),
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(SelectEditableStage)
  selectEditableStage(
    { patchState }: StateContext<StageStateModel>,
    { stage }: SelectEditableStage,
  ) {
    patchState({
      selectedStage: stage,
      criteriaSum: stage?.criteriaSum,
    });
  }
  @Action(OrderStage)
  orderStage(
    { setState }: StateContext<StageStateModel>,
    { updateStageOrder }: OrderStage,
  ) {
    return this.stageService.orderStage(updateStageOrder).pipe();
  }

  @Action(GetPaginatedStageCriteria)
  getPaginatedStageCriteria(
    { patchState }: StateContext<StageStateModel>,
    { stageId, pageNumber, pageSize }: GetPaginatedStageCriteria,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageService
      .getPaginatedStageCriteria(stageId, pageNumber, pageSize)
      .pipe(
        tap((paginatedCriteria) => {
          patchState({
            paginatedCriteria: paginatedCriteria,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
