<mat-dialog-content *appHasPermission="hasGetFilePropertiesPermission()">
  <div class="header">
    <fa-icon
      [icon]="fileIcons[fileProperty!.fileType.toLowerCase()][0]"
      class="file-icon"
    ></fa-icon>
    <h3>
      {{ fileProperty?.name }} <span i18n="@@files.file-property.properties">Properties</span>
    </h3>
  </div>
  <mat-divider></mat-divider>
  <mat-list role="list">
    <mat-list-item role="listitem"
      >
      <span i18n="@@files.file-property.type-of-file">Type of File :</span>
      <span class="float-right">{{
        fileIcons[fileProperty!.fileType.toLowerCase()][1]
      }}</span></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@files.file-property.size">Size : </span>
      <span
      class="float-right"
        >{{ fileProperty!.size }} {{ fileProperty!.sizeUnit }}</span
      ></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@files.file-property.download-count">Download Count : </span>
      <span class="float-right">{{ fileProperty!.downloadCount }} <span i18n="@@files.file-property.times"> Times </span> </span></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@files.file-property.workspace">Workspace : </span><span class="float-right">{{ fileProperty?.workspace}}</span></mat-list-item
    >
    <mat-divider></mat-divider>
    <mat-list-item role="listitem"
      ><span i18n="@@files.file-property.created">Created : </span><span class="float-right">{{ fileProperty?.created }}</span></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@files.file-property.created-by">Created by : </span><span class="float-right">{{ fileProperty?.createdBy }}</span></mat-list-item
    >
  </mat-list>
  <button
    class="close-btn"
    mat-flat-button
    color="accent"
    mat-dialog-close
    i18n="@@files.file-property.close-btn"
  >
    Close
  </button>
</mat-dialog-content>
