import { Stage } from '../models/stage.model';
import { ProcessDetail } from '../models/process-detail.model';

import { Process } from '../models/process.model';

export class GetProcessDetail {
  static readonly type = `[Process] ${GetProcessDetail.name}`;
  constructor(public readonly processDetailId: string) {}
}

export class AddProcessStage {
  static readonly type = `[Process] ${AddProcessStage.name}`;
  constructor(public readonly stage: Stage) {}
}

export class DeleteProcessDetail {
  static readonly type = `[Process] ${DeleteProcessDetail.name}`;
  constructor(public readonly processId: string) {}
}

export class ResetSelectedProcessDetail {
  static readonly type = `[Process] ${GetProcessDetail.name}`;
  constructor() {}
}
export class SetSelectedProcessDetail {
  static readonly type = `[Process] ${SetSelectedProcessDetail.name}`;
  constructor(public process: Process) {}
}

export class UpdateStageList {
  static readonly type = `[Process] ${UpdateStageList.name}`;
  constructor(public readonly selectedProcessStages: any) {}
}
