import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class IntegerValidator {
  static greaterThan(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) > other) return null;

      return { greaterThan: `Field must be greater than ${other}` };
    };
  }

  static greaterThanOrEqualTo(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) >= other) return null;

      return { greaterThanOrEqualTo: `Field must be greater than or equal to ${other}`};
    };
  }

  static lessThan(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) < other) return null;

      return { LessThan: `Field must be less than ${other}` };
    };
  }

  static lessThanOrEqualTo(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) <= other) return null;

      return { lessThanOrEqualTo: `Field must be less than or equal to ${other}` };
    };
  }

  static equalTo(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) == other) return null;

      return { equalTo: `Field must be equal to ${other}` };
    };
  }

  static notEqualTo(other: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (Number(value) != other) return null;

      return { notEqualTo: `Field must not be equal to ${other}` };
    };
  }
}
