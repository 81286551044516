<mat-paginator
  [length]="(loginHistories$ | async)?.totalCount"
  [pageIndex]="((loginHistories$ | async)?.pageNumber ?? 1) - 1"
  [pageSizeOptions]="[10, 25, 100]"
  [showFirstLastButtons]="true"
  (page)="paginateLoginHistory($event)"
/>

<table
  mat-table
  [dataSource]="(loginHistories$ | async)?.items ?? []"
  class="login-card mat-elevation-z8"
>
  <ng-container matColumnDef="loggedInAt">
    <th mat-header-cell *matHeaderCellDef i18n="@@users.login-history.logged-in-at">Logged In At</th>
    <td mat-cell *matCellDef="let element">{{ element.loggedInAt | date:'medium'}}</td>
  </ng-container>

  <ng-container matColumnDef="browser">
    <th mat-header-cell *matHeaderCellDef i18n="@@users.login-history.browser">Browser</th>
    <td mat-cell *matCellDef="let element">{{ element.browser }}</td>
  </ng-container>

  <ng-container matColumnDef="platform">
    <th mat-header-cell *matHeaderCellDef i18n="@@users.login-history.platform">Platform</th>
    <td mat-cell *matCellDef="let element">{{ element.platform }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
