import { FlatOfficeNode } from './../../models/flat-office-node.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { OfficeFacade } from '../../facades/office.facades';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface OfficesListComponentState {
  flatOfficeNodes: FlatOfficeNode[];
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initOfficesListComponentState: Partial<OfficesListComponentState> = {
  flatOfficeNodes: [],
  currentLoggedInUser: undefined
};

@Component({
  selector: 'app-office-multiple-select',
  templateUrl: './office-multiple-select.component.html',
  styleUrls: ['./office-multiple-select.component.scss'],
  providers: [RxState],
})
export class OfficeMultipleSelectComponent implements OnInit {
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.state.select('flatOfficeNodes');
  selectedFlatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.state.select('selectedFlatOfficeNodes');

  checklistSelection = new SelectionModel<FlatOfficeNode>(true);
  previousSelectedFlatOfficeNodes: FlatOfficeNode[] | undefined;

  officeNodes: Array<FlatOfficeNode> = [];

  dataSource = new ArrayDataSource(this.officeNodes);
  isAllOfficeSelected?: boolean;

  currentLoggedInUser: CurrentLoggedInUser | undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  isSharedFrom=false;

  constructor(
    private officeFacade: OfficeFacade,
    private userFacade: UserFacade,
    private state: RxState<OfficesListComponentState>,
    @Inject(MAT_DIALOG_DATA) private data: { update: boolean, isForShare?: boolean },
  ) {
    this.state.set(initOfficesListComponentState);
    this.state.connect('flatOfficeNodes', officeFacade.flatOfficeNodes$);
    this.state.connect(
      'selectedFlatOfficeNodes',
      officeFacade.selectedFlatOfficeNodes$,
    );
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  ngOnInit(): void {
    this.flatOfficeNodes$.subscribe((flatOfficeNodes) => {
      this.officeNodes = flatOfficeNodes;
      this.dataSource = new ArrayDataSource(this.officeNodes);
    });
    this.selectedFlatOfficeNodes$.subscribe(
      (selectedFlatOfficeNodes) =>
        (this.selectedFlatOfficeNodes = selectedFlatOfficeNodes),
    );
    this.currentLoggedInUser$.subscribe((currUser) => {
      this.currentLoggedInUser = currUser;
    });
    this.checklistSelection = new SelectionModel(
      true,
      this.selectedFlatOfficeNodes,
    );
    this.previousSelectedFlatOfficeNodes = this.selectedFlatOfficeNodes;

    this.checkIsAllOfficeSelected()

    if(this.data && this.data.isForShare){
      this.isSharedFrom=this.data.isForShare
      if(this.isSharedFrom===true)
      this.forShare(this.currentLoggedInUser!.officeId)
    }
  }

  treeControl = new FlatTreeControl<FlatOfficeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatOfficeNode) => node.expandable;

  getParentNode(node: FlatOfficeNode) {
    const nodeIndex = this.officeNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.officeNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.officeNodes[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatOfficeNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  officeTreeNodeSelectionToggle(node: FlatOfficeNode): void {
    this.checklistSelection.toggle(node);
    this.officeFacade.dispatchSelectFlatOfficeNodes(
      this.checklistSelection.selected,
    );
    this.checkIsAllOfficeSelected()
  }

  onCancel() {
    if (this.previousSelectedFlatOfficeNodes) {
      this.officeFacade.dispatchSelectFlatOfficeNodes(
        this.previousSelectedFlatOfficeNodes,
      );
      this.checklistSelection = new SelectionModel<FlatOfficeNode>(
        true,
        this.previousSelectedFlatOfficeNodes,
      );
    } else {
      this.officeFacade.dispatchResetSelectedOffices();
    }
  }

  hasGetFlatOfficeNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetFlatOfficeNodes,
    );
  }

  selectAllNodes(checked: boolean) {
    const userOfficeId= this.currentLoggedInUser?.officeId
    if (checked) {
      this.officeNodes.forEach(node => {
        if(this.isSharedFrom===true){
          if(!this.checklistSelection.isSelected(node) && !(userOfficeId === node.id)){
          this.officeTreeNodeSelectionToggle(node);}
        }
        else if(!this.checklistSelection.isSelected(node)) {
            this.officeTreeNodeSelectionToggle(node);
          }
        
        node.isExpanded = true;
      });
    } else {
      this.officeFacade.dispatchResetSelectedOffices();
      this.checklistSelection.clear();
    }
  }
  
  

  checkIsAllOfficeSelected() {
    this.isAllOfficeSelected = this.officeNodes.every(node =>
      this.checklistSelection.isSelected(node)
    );
  }

  forShare(officeId: string): boolean {
   if(this.isSharedFrom===true){
     if( officeId === this.currentLoggedInUser?.officeId){
      return true;
     }else{
      return false;
     }
   }else{
       this.isSharedFrom=false;
       return this.isSharedFrom;
    }
  }
  
}
