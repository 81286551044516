import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrencyData, CurrencyExcelUploadReport } from '../tor-settings/models/currency.model';
import { Observable } from 'rxjs';
import { CURRENCY_URL } from 'src/app/core/constants/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  uploadCurrencyExcelFile(
    file: File,
  ): Observable<HttpEvent<CurrencyExcelUploadReport[]>> {
    const formData = new FormData();
    formData.set('file', file, file.name);

    return this.http.post<CurrencyExcelUploadReport[]>(
      `${CURRENCY_URL}/upload-excel`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  downloadCurrencyExcelTemplate(): Observable<any> {
    const url = `${CURRENCY_URL}/excel-template`;
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(url, options);
  }

  getCurrencies(): Observable<CurrencyData[]> {
    return this.http.get<CurrencyData[]>(
      `${CURRENCY_URL}/currencyData`,
    );
  }

  setDefaultCurrency(id: string): Observable<any> {
    return this.http.put<any>(
      `${CURRENCY_URL}/default/${id}`, this.httpOptions
    );
  }
}
