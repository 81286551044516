import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DOCUMENTS_ANALYTICS_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowAverageTimeSpent } from '../models/workflow-average-time-spent.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowAverageTimeSpentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getWorkflowAverageTimeSpent(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
  ): Observable<PaginatedList<WorkflowAverageTimeSpent>> {
    let queryParams = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (workflowTypeId) {
      queryParams = queryParams.set('workflowTypeId', workflowTypeId);
    }

    return this.http.get<PaginatedList<WorkflowAverageTimeSpent>>(
      `${DOCUMENTS_ANALYTICS_URL}/workflows-average-time-spent`,
      { params: queryParams },
    );
  }
}
