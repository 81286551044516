import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileProperty } from '../../models/file-property.model';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import {
  faFile,
  faFileWord,
  faFilePdf,
  faFileLines,
  faImage,
  faFileVideo,
  faFileExcel,
  faFileAudio,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ChangeDateFormatService } from 'src/app/core/services/change-date-format/change-date-format.service';
import { FileFacade } from '../../facade/file.facade';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface FilePropertyComponentState {
  fileProperty: FileProperty | undefined;
  fileIcons: any | null;
}
const initFilePropertyComponentState: Partial<FilePropertyComponentState> = {
  fileIcons: null,
};

@Component({
  selector: 'app-file-property',
  templateUrl: './file-property.component.html',
  styleUrls: ['./file-property.component.scss'],
  providers: [RxState],
})
export class FilePropertyComponent implements OnInit {
  fileProperty$: Observable<FileProperty | undefined> =
    this.state.select('fileProperty');
  fileProperty: FileProperty | undefined;

  fileIcons: any = {
    word: [faFileWord, 'DOCX File (.docx)'],
    pdf: [faFilePdf, 'PDF Document (.pdf)'],
    image: [faImage, 'Image File'],
    file: [faFile, 'File'],
    spreadsheet: [faFileExcel, 'XLS File (.xls)'],
    txt: [faFileLines, 'Text Document (.txt)'],
    video: [faFileVideo, 'Video File'],
    audio: [faFileAudio, 'Audio File'],
    generic: [faFileAlt, 'Generic File']
  };
  selectedFileId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedFileId: string;
    },
    public dialog: MatDialog,
    private fileFacade: FileFacade,
    private state: RxState<FilePropertyComponentState>,
    private changeDateFormatService: ChangeDateFormatService,
  ) {
    this.selectedFileId = this.data.selectedFileId;
    this.state.set(initFilePropertyComponentState);
    this.state.connect('fileProperty', fileFacade.fileProperty$);
  }

  ngOnInit(): void {
    this.fileFacade.dispatchGetFileProperty(this.selectedFileId);

    this.fileProperty$.subscribe((fileProperty) => {
      fileProperty!.created = this.changeDateFormatService.changeDateFormat(
        new Date(fileProperty!.created),
      );
      fileProperty!.lastAccessed =
        this.changeDateFormatService.changeDateFormat(
          new Date(fileProperty!.lastAccessed),
        );
      this.fileProperty = fileProperty;
    });
  }

  hasGetFilePropertiesPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.File.Feature,
      PERMISSION_NAMES.Files.File.GetFileProperty,
    );
  }
}
