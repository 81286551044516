<h1 mat-dialog-title class="dialog-title">
  {{ createEditToggle.value.title }}
</h1>

<mat-dialog-content mat-dialog-content class="form-container">
  <form [formGroup]="templateFieldForm" class="template-field-form">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.form-field-form.name">Name</mat-label>
      <input
        id="name"
        (focus)="focusedInput = 'name'"
        matInput
        [placeholder]="placeholderFieldLabel.templateFieldName"
        formControlName="name"
      />
      <mat-error *ngIf="templateFieldForm.controls.name.errors?.['required']" i18n="@@documents.form-field-form.please-provide-a-valid-name-for-the-form-field">
        Please provide a valid name for the form field.
      </mat-error>
      <mat-error
        *ngIf="
          templateFieldForm.controls.name.errors?.[
            'templateFieldNameAlreadyTaken'
          ]
        "
       i18n="@@documents.form-field-form.form-field-name-already-taken">
        Form Field name already taken.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.form-field-form.description">Description</mat-label>
      <textarea
        id="description"
        (focus)="focusedInput = 'description'"
        matInput
        formControlName="description"
        [placeholder]="placeholderFieldLabel.templateFieldDescription"
      ></textarea>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.form-field-form.placeholder">Placeholder</mat-label>
      <textarea
        id="placeholder"
        (focus)="focusedInput = 'placeholder'"
        matInput
        formControlName="placeholder"
        [placeholder]="placeholderFieldLabel.templateFieldPlaceholder"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label i18n="@@documents.form-field-form.choose-data-type">Choose Data Type</mat-label>
      <mat-select formControlName="dataType">
      <mat-select-trigger>{{handleSelectedDataType()}}</mat-select-trigger>        <mat-option *ngFor="let type of dataTypes" [value]="type.dataTypeEnum">
          {{ type.dataTypeForDisplay }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="templateFieldForm.controls.dataType.errors?.['required']"
       i18n="@@documents.form-field-form.please-select-a-data-type">
        Please select a data type
      </mat-error>
    </mat-form-field>
  </form>
  <app-add-field-validation-rules
    [templateFieldId]="selectedFormField?.id"
    [dataType]="templateFieldForm.controls.dataType.value"
  ></app-add-field-validation-rules>
</mat-dialog-content>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close i18n="@@documents.form-field-form.cancel">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="submit($event)"
    [disabled]="templateFieldForm.invalid"
  >
    {{ createEditToggle.value.submitButton }}
  </button>
</div>
