<div class="main-continier">
  <h1 *ngIf="!update" i18n="@@documents.notices-form.create-notice" class="mat-h1">Create Notice</h1>
  <h1 *ngIf="update"i18n="@@documents.notices-form.update-notice" class="mat-h1">Update Notice</h1>
  <div>
    <button mat-raised-button (click)="openMultipleOfficeChoice()" color="primary" i18n="@@documents.notices-form.select-offices">
      Select offices
    </button>
  </div>
  <form class="notice-form" [formGroup]="noticeForm">
    <div class="first-row">
      <div>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@documents.notices-form.role">Role</mat-label>
          <mat-select
            formControlName="selectedRoles"
            multiple
            (selectionChange)="onRoleSelect()"
            data-test-id="role-select"
          >
            <mat-option *ngFor="let role of roles" [value]="role" [attr.data-test-id]="'role-option' + role.id">{{
              role.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@documents.notices-form.users">Users</mat-label>
          <mat-select formControlName="selectedUsers" multiple>
            <mat-option *ngFor="let user of users" [value]="user">{{
              user.firstName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="sec-row">
      <div>
        <p i18n="@@documents.notices-form.selected-offices">Selected offices</p>
        <mat-chip
          *ngFor="let office of selectedFlatOfficeNodes"
          (removed)="removeSelectedOffice(office)"
        >
          {{ office.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>

      <div>
        <p i18n="@@documents.notices-form.selected-roles">Selected roles</p>
        <mat-chip
          *ngFor="let role of noticeForm.value.selectedRoles"
          (removed)="removeSelectedRole(role)"
        >
          {{ role.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>

      <div>
        <p i18n="@@documents.notices-form.selected-users">Selected users</p>
        <mat-chip
          *ngFor="let user of noticeForm.value.selectedUsers"
          (removed)="removeSelectedUser(user)"
        >
          {{ user.firstName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>
    </div>

    <div>
      <mat-form-field class="title" appearance="outline">
        <mat-label i18n="@@documents.notices-form.title">Title</mat-label>
        <input
          id="title"
          formControlName="title"
          type="text"
          matInput
          class="full-width-input"
          [placeholder]="placeholderFieldLabel.noticeTitleField"
        />
        <mat-error *ngIf="noticeForm.get('title')?.hasError('required')" i18n="@@documents.notices-form.please-provide-a-valid-name-for-the-process-type">
          Please provide a valid name for the process type
        </mat-error>
      </mat-form-field>
    </div>
    <div class="editor--wrapper">
      <div id="editor"></div>
    </div>

    <div class="w-100">
      <div
        class="drop-zone flex-center"
        [class.dragover]="isDragOver"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <ng-container *ngIf="files.length === 0">
          <p i18n="@@documents.notices-form.drag-and-drop-files-here">Drag and drop files here</p>
        </ng-container>
        <div *ngFor="let file of files">
          <div class="flex-center">
            <p>{{ file.name }}</p>
            <mat-icon class="pointer" color="warn" (click)="removeFile(file)"
              >close</mat-icon
            >
          </div>
        </div>
        <button mat-flat-button color="primary" (click)="selectFile()" i18n="@@documents.notices-form.select-files">
          Select Files
        </button>
      </div>
      <div *ngIf="update && selectedNotice" class="">
        <p i18n="@@documents.notices-form.selected-files">Selected Files</p>
        <mat-chip
          *ngFor="let attachment of selectedNotice?.attachments"
          (removed)="deleteNoticeAttachment(attachment?.id)"
        >
          {{ attachment.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>

      <button
        *ngIf="!update"
        mat-flat-button
        color="primary"
        [disabled]="isSaveButtonDisabled()"
        (click)="createNotices()"
        class="mt-15 ml-15"
      i18n="@@documents.notices-form.save">
        Save
      </button>
      <button
        *ngIf="update"
        mat-flat-button
        color="primary"
        [disabled]="isSaveButtonDisabled()"
        (click)="createNotices()"
        class="mt-15 ml-15"
      i18n="@@documents.notices-form.update-and-save">
        Update And Save
      </button>
      <button
        *ngIf="!update"
        mat-flat-button
        color="primary"
        [disabled]="isSendButtonDisabled()"
        (click)="sendNotices()"
        class="mt-15 ml-15"
      i18n="@@documents.notices-form.send">
        Send
      </button>
      <button
        *ngIf="update"
        mat-flat-button
        color="primary"
        [disabled]="isSendButtonDisabled()"
        (click)="sendNotices()"
        class="mt-15 ml-15"
      i18n="@@documents.notices-form.update-and-send">
        Update And Send
      </button>
    </div>
  </form>
</div>
