import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RxState } from '@rx-angular/state';
import { User } from 'src/app/users/models/user.model';
import { WorkflowStepFacade } from '../../facade/workflow-step.facade';
import { NotifiedUser } from '../../models/notified-user.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowStep } from '../../models/workflow-step.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { AttachUserFormComponent } from '../attach-user-form/attach-user-form.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface AttachedUserState {
  notifiedUsers: PaginatedList<NotifiedUser> | undefined;
  workflowStep: WorkflowStep | undefined;
}

const initAttachedUserState: AttachedUserState = {
  notifiedUsers: undefined,
  workflowStep: undefined,
};

@Component({
  selector: 'app-attached-user-list',
  templateUrl: './attached-user-list.component.html',
  styleUrls: ['./attached-user-list.component.scss'],
})
export class AttachedUserListComponent {
  notifiedUsers$ = this.state.select('notifiedUsers');
  workflowStep$ = this.state.select('workflowStep');
  workflowStep: WorkflowStep | undefined;
  notifiedUsers: NotifiedUser[] | undefined;

  @Input()
  isArchived!: boolean;

  constructor(
    private state: RxState<AttachedUserState>,
    private workflowStepFacade: WorkflowStepFacade,
    private dialog: MatDialog,
  ) {
    this.state.set(initAttachedUserState);
    this.state.connect('notifiedUsers', workflowStepFacade.notifiedUsers$);
    this.state.connect('workflowStep', workflowStepFacade.workflowStep$);
  }
  ngOnInit() {
    this.workflowStep$.subscribe((step) => {
      if(step){
        this.workflowStep = step;
        this.workflowStepFacade.dispatchGetNotifiedUsers(this.workflowStep.id);
      }
    });
    this.notifiedUsers$.subscribe((items) => {
      this.notifiedUsers = items?.items;
    });
  }

  attachUserToWorkflowStep() {
    this.dialog.open(AttachUserFormComponent);
  }
  removeNotifiedUsers(user: NotifiedUser) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`:@@documents.attached-user-list.removed-workflow-user:Remove user from workflow step`,
        regularTextOne:  $localize`:@@documents.attached-user-list.removed-workflow-step-part-1:Are you sure you want to remove` ,
        boldText: ` "${user.userFullName}" ` ,
        regularTextTwo: $localize`:@@documents.attached-user-list.removed-workflow-step-part-2:from the the step?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowStepFacade.dispatchRemoveNotifiedUser(
          this.workflowStep?.id!,
          user.id!,
        );
      }
    });
  }

  hasAddNotifyUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowStep.AddNotifyUsers,
    );
  }
  hasRemoveNotifyUserPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowStep.RemoveNotifyUser,
    );
  }
}
