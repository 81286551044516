import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  TorProcessInstance,
  TorStageInstance,
  Tor,
  TorDetail,
  TorStatus,
} from '../../models/tor.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { TorFacade } from '../../facades/tor.facades';
import { RxState } from '@rx-angular/state';
import { MatCard } from '@angular/material/card';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Router } from '@angular/router';
import {
  TOR_DETAIL_ROUTE,
  TOR_ROUTE,
  TOR_SETTINGS_ROUTE,
} from 'src/app/core/constants/routes';
import { StageInstance } from 'src/app/researches/models/process-instance.model';
import { ENGLISH_LANGUAGE } from 'src/app/core/constants/language';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface ProcessTorTuple {
  process: TorProcessInstance;
  toR: Tor;
}

interface TorListComponentState {
  paginatedTor: PaginatedList<Tor>;
  totalCount?: number;
  selectedTorDetail?: TorDetail;
}

const initTorListComponentState: TorListComponentState = {
  paginatedTor: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@Component({
  selector: 'app-tor-list',
  templateUrl: './tor-list.component.html',
  styleUrls: ['./tor-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: TorListComponent}],
})
export class TorListComponent implements OnInit, MatPaginatorIntl {
  selectedTorDetail$ = this.state.select('selectedTorDetail');
  selectedTorDetail: TorDetail | undefined;
  paginatedTor: PaginatedList<Tor> | undefined;
  paginatedTor$ = this.state.select('paginatedTor');
  totalCount?: number;
  totalCount$ = this.state.select('totalCount');
  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  groupChecked: boolean = true;
  processToStage: Map<string, TorStageInstance[]> = new Map();
  stageToTor: Map<string, Tor[]> = new Map();
  stageIdToName: Map<string, string> = new Map();

  locale = localStorage.getItem('locale')?? ENGLISH_LANGUAGE.locale;

  firstPageLabel = $localize`:@@researches.tor-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.tor-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.tor-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.tor-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.tor-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.tor-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.tor-list.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.tor-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private torFacade: TorFacade,
    private state: RxState<TorListComponent>,
    private router: Router,
  ) {}
  changes = new Subject<void>();

  @NgModule({
    declarations: [TorListComponent, Component],
    imports: [MatPaginator, PageEvent, MatCard],
  })
  ngOnInit() {
    const tor = this.state.connect(
      'paginatedTor',
      this.torFacade.paginatedTor$,
    );
    this.state.connect('totalCount', this.torFacade.totalCount$);

    this.torFacade.dispatchGetTor(
      this.pageIndex+1,
      this.pageSize,
      this.isGrouped,
    );
    this.totalCount$.subscribe((totalCount: any) => {
      if (totalCount) this.totalCount = totalCount;
    });
    this.paginatedTor$.subscribe((tors: PaginatedList<Tor> | undefined) => {
      if (tors) {
        this.paginatedTor = tors;
        this.processToStage.clear();
        this.stageToTor.clear();
        for (let tor of tors.items) {
          if (tor.stageInstance && tor.stageInstance.processInstance) {
            let processTitle = tor.stageInstance.processInstance.title;

            let stageId = tor.stageInstance.id;
            let oldStageList = this.processToStage.get(processTitle) ?? [];
            let existingStage = oldStageList.filter(
              (stage) => stage.id === stageId,
            );
            if (existingStage.length === 0) {
              this.processToStage.set(processTitle, [
                tor.stageInstance,
                ...oldStageList,
              ]);
            }

            this.stageIdToName.set(stageId, tor.stageInstance.name);
            let oldTorList = this.stageToTor.get(stageId) ?? [];
            this.stageToTor.set(stageId, [tor, ...oldTorList]);
          }
        }
      }
    });
  }

  getStatusColor(statusType: TorStatus) {
    if (statusType == TorStatus.Approved) {
      return 'green';
    } else if (statusType == TorStatus.Rejected1) {
      return 'red';
    } else {
      return 'blue';
    }
  }

  loadPaginatedTor(event: PageEvent) {
    this.torFacade.dispatchGetTor(
      event.pageIndex + 1,
      event.pageSize,
      this.isGrouped,
    );
  }

  navigatetoTorSetting() {
    this.router.navigate([TOR_ROUTE, TOR_SETTINGS_ROUTE]);
  }

  onRowClick(event: MouseEvent, tor: Tor): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    this.torFacade.dispatchSetSelectedTor(tor);
    if (!isMenuButtonClick) {
      this.router.navigate([`${TOR_DETAIL_ROUTE}`, tor.id]);
      this.torFacade.dispatchSetSelectedTor(tor);
    }
  }

  get isGrouped(): boolean {
    return this.groupChecked === true;
  }

  onGroupedChange(checked: boolean): void {
    this.groupChecked = checked;
    this.torFacade.dispatchGetTor(this.pageIndex + 1, this.pageSize, checked);
  }

  get processes(): string[] {
    return Array.from(this.processToStage.keys());
  }

  hasGetTorsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.GetTors,
    );
  }

  hasGetAllActivityTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ActivityType.Feature,
      PERMISSION_NAMES.Researches.ActivityType.GetAllActivityTypes,
    );
  }

  hasGetAllQuantityTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.QuantityType.Feature,
      PERMISSION_NAMES.Researches.QuantityType.GetAllQuantityTypes,
    );
  }
}
