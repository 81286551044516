<div class="flex">
  <div class="gap-x">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label i18n="@@users.user-list.search-for-user"
        >Search for user</mat-label
      >
      <input
        matInput
        [placeholder]= placeholderLabel.nameField
        [(ngModel)]="searchText"
        (input)="getUsersBySearch()"
        matInput
        *appHasPermission="hasGetUsersByAnyTypePermission()"
        [attr.data-test-id]="'users.search-user.user-list.input-for-user-search'"
      />

      <button
        matSuffix
        *ngIf="searchText"
        mat-icon-button
        aria-label="Clear"
        (click)="resetSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="flex" *appHasPermission="hasGetUsersByRoleAndOfficePermission()">
    <mat-form-field appearance="outline">
      <mat-label
        i18n="@@users.user-list.choose-role"
        >Choose Role</mat-label
      >
      <mat-select (selectionChange)="onRoleChange($event)">
        <mat-option value="all" i18n="@@users.user-list.all-roles"> All Roles </mat-option>
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      class="add-btn"
      mat-flat-button
      color="primary"
      (click)="assignUserToRoles()"
      [disabled]="clickedUserRows.size === 0"
      *appHasPermission="hasAssignRevokeRolesToUserPermission()"
      i18n="@@users.user-list.assign-roles"
    >
      Assign Roles
    </button>

    <button
      class="add-btn"
      mat-flat-button
      color="primary"
      (click)="addUser()"
      *appHasPermission="hasCreateUserPermission()"
      i18n="@@users.user-list.add-user"
    >
      Add User
    </button>
  </div>
</div>

<div class="mat-elevation-z1">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedUsers($event)"
  >
  </mat-paginator>

  <mat-table
    [dataSource]="dataset"
    class="table"
    *appHasPermission="hasGetUsersPermission()"
  >
    <ng-container matColumnDef="checkbox">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let user"
        ><mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="selectUser($event.checked, user.id)"
        >
        </mat-checkbox
      ></mat-cell>
    </ng-container>

    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef i18n="@@users.user-list.user-name"
        >Username</mat-header-cell
      >
      <mat-cell
        *matCellDef="let user"
        [class.row-is-clicked]="clickedUserRows.has(user)"
      >
        <span>{{ user.userName }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef i18n="@@users.user-list.email"
        >Email</mat-header-cell
      >
      <mat-cell
        *matCellDef="let user"
        [class.row-is-clicked]="clickedUserRows.has(user)"
      >
        <span>{{ user.email }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <mat-header-cell *matHeaderCellDef i18n="@@users.user-list.phone-number"
        >Phone number</mat-header-cell
      >
      <mat-cell
        *matCellDef="let user"
        [class.row-is-clicked]="clickedUserRows.has(user)"
      >
        <span>{{ user.phoneNumber }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="officeName">
      <mat-header-cell *matHeaderCellDef
      i18n="@@users.user-list.office"
        >Office</mat-header-cell
      >
      <mat-cell
        *matCellDef="let user"
        [class.row-is-clicked]="clickedUserRows.has(user)"
      >
        <span>{{ user?.officeName}}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <mat-header-cell *matHeaderCellDef i18n="@@users.user-list.account-status"
        >Account Status</mat-header-cell
      >
      <mat-cell
        *matCellDef="let user"
        [class.row-is-clicked]="clickedUserRows.has(user)"
      >
        <span
          class="chip"
          [ngClass]="{ green: user.isActive, red: !user.isActive }"
          >{{ user.isActive ? activeInactiveLabel.activeLabel : activeInactiveLabel.inActiveLabel }}</span
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        class="icon-cell"
        i18n="@@users.user-list.actions"
        >Actions</mat-header-cell
      >
      <mat-cell *matCellDef="let user" class="icon-cell">
        <button
          *appHasPermission="hasCrudPermission()"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="setSelectedUser($event, user)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="[
        'checkbox',
        'userName',
        'email',
        'phoneNumber',
        'officeName',
        'isActive',
        'actions'
      ]"
    ></mat-header-row>
    <mat-row
      class="table-row"
      *matRowDef="
        let user;
        columns: [
          'checkbox',
          'userName',
          'email',
          'phoneNumber',
          'officeName',
          'isActive',
          'actions'
        ]
      "
      (click)="onRowClick($event, user)"
    ></mat-row>
  </mat-table>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="assignOrRevokeRoles()"
      *appHasPermission="hasAssignRevokeRolesToUserPermission()"
      i18n="@@users.user-list.assign-revoke-roles"
    >
      Assign / Revoke Roles
    </button>
    <button
      mat-menu-item
      (click)="assignOrRevokePermissions()"
      *appHasPermission="hasAssignRevokePermissionsToUserPermission()"
      i18n="@@users.user-list.assign-revoke-permissions"
    >
      Assign / Revoke Permissions
    </button>
    <button
      mat-menu-item
      (click)="activateOrDeactivateUser()"
      *appHasPermission="hasUserActivationPermission()"
      i18n="@@users.user-list.activate-deactivate-user"
    >
      Activate / Deactivate
    </button>
    <button
      mat-menu-item
      (click)="deleteUser()"
      *appHasPermission="hasDeleteUserPermission()"
      i18n="@@users.user-list.delete"
    >
      Delete
    </button>
    <button mat-menu-item (click)="editUser()" *appHasPermission="hasUpdateUserPermission()"
    i18n="@@users.user-list.edit">
      Edit
    </button>
    <button mat-menu-item (click)="resetDefaultPassword()" *appHasPermission="hasResetDefaultPasswordPermission()"
    i18n="@@users.user-list.reset-default-password">
      Reset Default Password
    </button>
  </mat-menu>
</div>
