<mat-dialog-content mat-dialog-content class="form-container">
  <form class="process-form" [formGroup]="costBreakdownForm">
    <h3 *ngIf="!updateCostBreakdownItem"  i18n="@@researches.cost-breakdown-form.add-cost-breakdown-item">Add Cost Breakdown Item</h3>
    <h3 *ngIf="updateCostBreakdownItem" i18n="@@researches.cost-breakdown-form.update-cost-breakdown-item">Update Cost Breakdown Item</h3>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.activity-type">Activity Type</mat-label>
      <mat-select
        formControlName="activityType"
        [compareWith]="objectComparisonFunction"
      >
        <mat-option
          *ngFor="let activityType of activityTypes"
          [value]="activityType"
        >
          {{ activityType.name }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="costBreakdownForm.get('activityType')?.hasError('required')"
        i18n="@@researches.cost-breakdown-form.please-select-activity-type"
      >
        Please select activity Type
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.activity-name">Activity Name</mat-label>
      <input
        id="activity"
        formControlName="activity"
        type="text"
        matInput
        class="full-width-input"
        [placeholder]="placeholderToggleLabel.activityName"
      />
      <mat-error
        *ngIf="costBreakdownForm.get('activity')?.hasError('required')"
        i18n="@@researches.cost-breakdown-form.please-provide-a-valid-activity-name"
      >
        Please provide a valid activity Name
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.quantity">quantity</mat-label>
      <input
        id="quantity"
        formControlName="quantity"
        type="number"
        matInput
        class="full-width-input"
        [placeholder]="placeholderToggleLabel.numberFive"
        min="0"
      />
      <mat-error
        *ngIf="costBreakdownForm.get('quantity')?.hasError('required')"
        i18n="@@researches.cost-breakdown-form.please-provide-a-valid-quantity"
      >
        Please provide a valid quantity
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.quantity-type">Quantity Type</mat-label>
      <mat-select
        formControlName="quantityType"
        [compareWith]="objectComparisonFunction"
      >
        <mat-option
          *ngFor="let quantityType of quantityTypes"
          [value]="quantityType"
        >
          {{ quantityType.name }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="costBreakdownForm.get('quantityType')?.hasError('required')"
        i18n="@@researches.cost-breakdown-form.please-select-quantity-type"
      >
        Please select quantity Type
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.unit-cost">Unit Cost</mat-label>
      <input
        id="unitCost"
        formControlName="unitCost"
        type="number"
        matInput
        class="full-width-input"
        [placeholder]="placeholderToggleLabel.numberFive"
        min="0"
      />
      <mat-error
        *ngIf="costBreakdownForm.get('unitCost')?.hasError('required')"
        i18n="@@researches.cost-breakdown-form.please-provide-a-unit-cost"
      >
        Please provide a unitCost
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.total-cost">Total Cost</mat-label>
      <input
        id="totalCost"
        formControlName="totalCost"
        type="number"
        matInput
        class="full-width-input"
        readonly="true"
      />
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.currency">Currency</mat-label>
      <input
        type="text"
        matInput
        class="full-width-input"
        readonly
        [value]="data?.currency"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@researches.cost-breakdown-form.remark">Remark</mat-label>
      <textarea
        id="remark"
        formControlName="remark"
        matInput
        [placeholder]="placeholderToggleLabel.processRemark"
        class="full-width"
      ></textarea>
      <mat-error *ngIf="costBreakdownForm.get('remark')?.hasError('required')"
      i18n="@@researches.cost-breakdown-form.please-provide-a-valid-remark">
        Please provide a valid remark
      </mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.cost-breakdown-form.cancel">Cancel</button>
    <button
      *ngIf="!updateCostBreakdownItem"
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!costBreakdownForm.valid"
      i18n="@@researches.cost-breakdown-form.add"
    >
      Add
    </button>
    <button
      *ngIf="updateCostBreakdownItem"
      mat-flat-button
      color="primary"
      (click)="update()"
      [disabled]="!costBreakdownForm.valid"
      i18n="@@researches.cost-breakdown-form.update"
    >
      Update
    </button>
  </div>
</mat-dialog-content>
