import { Selector, createPropertySelectors } from '@ngxs/store';
import { NoticesState, NoticeStateModel } from './notices.state';

export class NoticesSelector {
  static slices = createPropertySelectors<NoticesState>(NoticesState);

  @Selector([NoticesState])
  static notices(stateModel: NoticeStateModel) {
    return stateModel.notices;
  }

  @Selector([NoticesState])
  static selectedNotices(stateModel: NoticeStateModel) {
    return stateModel.selectedNotice;
  }

  @Selector([NoticesState])
  static selectedNotice(stateModel: NoticeStateModel) {
    return stateModel.selectedNotice;
  }

  @Selector([NoticesState])
  static update(stateModel: NoticeStateModel) {
    return stateModel.update;
  }

  @Selector([NoticesState])
  static noticeAccessData(stateModel: NoticeStateModel) {
    return stateModel.noticeAccessData;
  }

  @Selector([NoticesState])
  static noticeFilters(stateModel: NoticeStateModel) {
    return stateModel.noticeFilters;
  }

  @Selector([NoticesState])
  static totalCount(stateModel: NoticeStateModel) {
    return stateModel.totalCount;
  }

  @Selector([NoticesState])
  static totalPages(stateModel: NoticeStateModel) {
    return stateModel.totalPages;
  }
}
