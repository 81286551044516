import { Component } from '@angular/core';
import { WorkflowStep } from '../../models/workflow-step.model';
import { RxState } from '@rx-angular/state';
import { WorkflowStepFacade } from '../../facade/workflow-step.facade';
import { ActivatedRoute } from '@angular/router';

interface ccdWorkflowState {
  workflowStep: WorkflowStep | undefined;
}

const initccdWorkflowState: ccdWorkflowState = {
  workflowStep: undefined,
};
@Component({
  selector: 'app-ccd-workflow-step-detail',
  templateUrl: './ccd-workflow-step-detail.component.html',
  styleUrls: ['./ccd-workflow-step-detail.component.scss'],
})
export class CcdWorkflowStepDetailComponent {
  workflowStep$ = this.state.select('workflowStep');
  workflowStep: WorkflowStep | undefined;

  constructor(
    private state: RxState<ccdWorkflowState>,
    private worflowStepFacade: WorkflowStepFacade,
    private route: ActivatedRoute,
  ) {
    state.set(initccdWorkflowState);
    state.connect('workflowStep', this.worflowStepFacade.ccWorkflowStep$);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.worflowStepFacade.dispatchGetCcdWorkflowStepDetail(params['id']);
    });

    this.worflowStepFacade.ccWorkflowStep$.subscribe((step) => {
      this.workflowStep = step;
    });
  }
}
