import { MeetingSchedule } from '../models/meeting-schedule.model';
import { AssignReviewerType } from '../models/reviewer.model';
import { Schedule } from '../models/schedule.model';

export class GetEvaluationSchedules {
  static readonly type = `[EvalutionSchedule] ${GetEvaluationSchedules.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetEvaluationScheduleDetail {
  static readonly type = `[EvalutionSchedule] ${GetEvaluationScheduleDetail.name}`;
  constructor(public id: string) {}
}
export class CreateEvaluationSchedule {
  static readonly type = `[EvalutionSchedule] ${CreateEvaluationSchedule.name}`;
  constructor(public schedule: Schedule) {}
}
export class UpdateEvalutionSchedule {
  static readonly type = `[EvalutionSchedule] ${UpdateEvalutionSchedule.name}`;
  constructor(public schedule: Schedule) {}
}
export class SetEditMode {
  static readonly type = `[EvalutionSchedule] ${SetEditMode.name}`;
  constructor(public update: boolean) {}
}
export class SetSelectedEvaluationSchedule {
  static readonly type = `[EvalutionSchedule] ${SetSelectedEvaluationSchedule.name}`;
  constructor(public schedule: Schedule) {}
}
export class DeleteEvaluationSchedule {
  static readonly type = `[EvalutionSchedule] ${DeleteEvaluationSchedule.name}`;
  constructor(public id: string) {}
}

export class GetStageInstanceReviewers {
  static readonly type = `[EvalutionSchedule] ${GetStageInstanceReviewers.name}`;
  constructor(public id: string) {}
}
export class AssignStageInstanceReviewers {
  static readonly type = `[EvalutionSchedule] ${AssignStageInstanceReviewers.name}`;
  constructor(
    public id: string,
    public reviewerIds: string[],
  ) {}
}

export class GetEvaluationDetail {
  static readonly type = `[EvalutionSchedule] ${GetEvaluationDetail.name}`;
  constructor(public readonly stageInstanceId: string) {}
}

export class CreateMeetingSchedule {
  static readonly type = `[meetingSchedule] ${CreateMeetingSchedule.name}`;
  constructor(public meetingSchedule: MeetingSchedule) {}
}
export class DeleteMeetingSchedule {
  static readonly type = `[meetingSchedule] ${DeleteMeetingSchedule.name}`;
  constructor(public meetingScheduleId: string) {}
}

export class UpdateMeetingSchedule {
  static readonly type = `[meetingSchedule] ${UpdateMeetingSchedule.name}`;
  constructor(public meetingSchedule: MeetingSchedule) {}
}

export class SetUpateStatus {
  static readonly type = `[meetingSchedule] ${SetUpateStatus.name}`;
  constructor(public update: boolean) {}
}

export class DeleteStageInstanceReviewer {
  static readonly type = `[EvalutionSchedule] ${DeleteStageInstanceReviewer.name}`;
  constructor(
    public stageInstanceId: string,
    public reviewerId: string,
  ) {}
}

export class FinishEvaluationSchedule {
  static readonly type = `[EvalutionSchedule] ${FinishEvaluationSchedule.name}`;
  constructor(public evaluationScheduleId: string) {}
}

export class GetEvaluationScheduleStatus {
  static readonly type = `[EvalutionSchedule] ${GetEvaluationScheduleStatus.name}`;
  constructor(public evaluationScheduleId: string) {}
}

export class AssignStageInstanceReviewerTypes {
  static readonly type = `[EvalutionSchedule] ${AssignStageInstanceReviewerTypes.name}`;
  constructor(
    public stageInstanceId: string,
    public assignReviewerType: AssignReviewerType,
  ) {}
}

export class FinishStageInstanceEvaluation {
  static readonly type = `[EvalutionSchedule] ${FinishStageInstanceEvaluation.name}`;
constructor(
  public evaluationScheduleId: string,
  public stageInstanceId: string,
){}
}


export class CreateScheduleSuccess {
  static readonly type = `[EvalutionSchedule] ${CreateScheduleSuccess.name}`;
  constructor(){}
}
export class CreateMeetingSuccess {
  static readonly type = `[EvalutionSchedule] ${CreateMeetingSuccess.name}`;
  constructor(){}
}