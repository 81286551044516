<mat-sidenav-container class="container">
  <mat-sidenav mode="side" opened class="left-menu">
    <mat-nav-list>
      <ng-container *ngFor="let link of navLinks$ | async">
        <a
          mat-list-item
          [routerLink]="link.link"
          [routerLinkActive]="['is-active']"
          *ngIf="!link?.hidden"
          [attr.data-test-id]="link.id"
        >
          <mat-icon color="accent" *ngIf="link.icon" matListItemIcon>{{ link.icon }}</mat-icon>
          <span matListItemTitle>{{ link.label }}</span>
        </a>
      </ng-container>
      <a
        mat-list-item
        href="assets/manuals/user/user-module.html"
        target="_blank"
      >
        <mat-icon color="accent" matListItemIcon>help_outline</mat-icon>
        <span matListItemTitle i18n="@@documents.document-home.user-manual">User Manual</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
