import { Selector, createPropertySelectors, createSelector } from '@ngxs/store';
import { UserState, UserStateModel } from './user.state';
export class UserSelector {
  static slices = createPropertySelectors<UserStateModel>(UserState);

  static selectUsersWithoutLoggedInUser = createSelector(
    [UserState],
    (state: UserStateModel) => {
      return state.users.filter(
        (user) => user.id !== state.currentLoggedInUser?.id,
      );
    },
  );

  @Selector([UserState])
  static approvers(stateModel: UserStateModel) {
    return stateModel.approvers;
  }
  @Selector([UserState])
  static evaluators(stateModel: UserStateModel) {
    return stateModel.evaluators;
  }
  @Selector([UserState])
  static officeUsers(stateModel: UserStateModel) {
    return stateModel.officeUsers;
  }
  @Selector([UserState])
  static currentLoggedInUser(stateModel: UserStateModel) {
    return stateModel.currentLoggedInUser;
  }
  @Selector([UserState])
  static totalCount(stateModel: UserStateModel) {
    return stateModel.totalCount;
  }

  @Selector([UserState])
  static selectedUser(stateModel: UserStateModel) {
    return stateModel.selectedUser;
  }

  @Selector([UserState])
  static usersWithPassword(stateModel: UserStateModel) {
    return stateModel.usersWithPassword;
  }

  @Selector([UserState])
  static closeRegistrationModal(stateModel: UserStateModel) {
    return stateModel.closeRegistrationModal;
  }

  @Selector([UserState])
  static passwordPolicy(stateModel: UserStateModel) {
    return stateModel.passwordPolicy;
  }
}
