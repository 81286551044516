import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ImisFile } from '../models/imis-file';
import { ShareFileRequest } from '../models/share-file-request';
import { MoveArchivedFileDto, UnsharedFile } from '../models/file.model';
import { FileBookmark } from '../models/bookmark.model';

export class GetSortedFiles {
  static readonly type = `[File] ${GetSortedFiles.name}`;
  constructor(
    public folderId: string,
    public orderBy: number[],
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class SelectFilesIds {
  static readonly type = `[File] ${SelectFilesIds}`;
  constructor(public selectedFilesIds: string[]) {}
}

export class SelectFile {
  static readonly type = `[File] ${SelectFile}`;
  constructor(public selectedFile: ImisFile) {}
}

export class MoveFiles {
  static readonly type = `[File] ${MoveFiles}`;
  constructor(
    public destinationFolderId: string,
    public selectedFilesIds: string[],
  ) {}
}

export class GetFilesBySearchTerm {
  static readonly type = `[File] ${GetFilesBySearchTerm.name}`;
  constructor(public readonly searchTerm: string) {}
}
export class CopyFile {
  static readonly type = `[File] ${CopyFile.name}`;
  constructor(
    public fileId: string,
    public folderId: string,
  ) {}
}

export class RenameFile {
  static readonly type = `[File] ${RenameFile}`;

  constructor(
    public readonly fileId: string,
    public readonly fileName: string,
  ) {}
}

export class DeleteFiles {
  static readonly type = `[File] ${DeleteFiles.name}`;
  constructor(public readonly fileIds: string[]) {}
}

export class SelectFiles {
  static readonly type = `[File] ${SelectFiles}`;
  constructor(public selectedFiles: ImisFile[]) {}
}

export class ShareFilesToOffices {
  static readonly type = `[File] ${ShareFilesToOffices.name}`;
  constructor(public shareFileToOfficeRequests: ShareFileRequest[]) {}
}

export class ShareFilesToRoles {
  static readonly type = `[File] ${ShareFilesToRoles.name}`;
  constructor(public shareFileToRolesRequests: ShareFileRequest[]) {}
}

export class ShareFilesToUsers {
  static readonly type = `[File] ${ShareFilesToUsers.name}`;
  constructor(public shareFileToUsersRequests: ShareFileRequest[]) {}
}

export class GetSharedFromFiles {
  static readonly type = `[File] ${GetSharedFromFiles.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class UnshareFile {
  static readonly type = `[File] ${UnshareFile.name}`;
  constructor(public unsharedFile: UnsharedFile) {}
}

export class GetSharedToFiles {
  static readonly type = `[File] ${GetSharedToFiles.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class DownloadFile {
  static readonly type = `[File] ${DownloadFile}`;
  constructor(
    public readonly fileId: string,
    public readonly fileName: string,
  ) {}
}

export class CreateFiles {
  static readonly type = `[File] ${CreateFiles.name}`;
  constructor(
    public readonly newFiles: FormData,
    public readonly folderId: string,
  ) {}
}

export class PreviewFile {
  static readonly type = `[File] ${PreviewFile.name}`;
  constructor(
    public readonly fileId: string,
    public readonly fileName: string,
  ) {}
}

export class FetchAudio {
  static readonly type = `[File] ${FetchAudio.name}`;
  constructor(public readonly fileId: string) {}
}

export class TrashFiles {
  static readonly type = `[File] ${TrashFiles.name}`;
  constructor(public readonly fileIds: string[]) {}
}

export class GetTrashedFiles {
  static readonly type = `[File] ${GetTrashedFiles.name}`;
  constructor(
    public readonly pageIndex: number,
    public readonly pageSize: number,
  ) {}
}

export class RestoreFile {
  static readonly type = `[File] ${RestoreFile.name}`;
  constructor(public readonly fileId: string) {}
}

export class GetFileProperty {
  static readonly type = `[Files] ${GetFileProperty.name}`;
  constructor(public fileId: string) {}
}

export class GetArchivedFiles {
  static readonly type = `[File] ${GetArchivedFiles.name}`;
  constructor(
    public folderId: string,
    public orderBy: number[],
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class SetArchivedFiles {
  static readonly type = `[File] ${SetArchivedFiles.name}`;
  constructor(public archivedFiles: PaginatedList<ImisFile>) {}
}

export class MoveArchivedFiles {
  static readonly type = `[File] ${MoveArchivedFiles.name}`;
  constructor(public filesToMove: MoveArchivedFileDto[]) {}
}

export class SetFileSharingMode {
  static readonly type = `[File] ${SetFileSharingMode.name}`;
  constructor(public isSharingFile: boolean) {}
}

export class GetFileDetial {
  static readonly type = `[File] ${GetFileDetial.name}`;
  constructor(public fileId: string) {}
}

export class SetFilesNull{
  static readonly type = `[File] ${SetFilesNull.name}`;
  constructor(){}
}

export class FilesAddedToBookmark {
  static readonly type = `[File] ${FilesAddedToBookmark.name}`;
  constructor(public readonly addedBookmarks: FileBookmark[] ) {}
}

export class FilesRemovedFromBookmark {
  static readonly type = `[File] ${FilesRemovedFromBookmark.name}`;
  constructor(public readonly removedBookmarks: FileBookmark[] ) {}
}

