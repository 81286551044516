<div class="flex">
  <form [formGroup]="searchRoleForm">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label i18n="@@roles.role-list.search-for-role"
        >Search roles...</mat-label
      >
      <input
        formControlName="searchTerm"
        matInput
        placeholder= "Search roles..."
        (input)="searchRole()"
        *appHasPermission="hasGetUsersBySearchPermission()"
        [attr.data-test-id]="'users.search-role.role-list.input-for-role-search'"
      />
  
      <button
      *ngIf="searchRoleForm.get('searchTerm')?.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="resetSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <button class="add-btn" mat-flat-button color="primary" (click)="addRole()" *appHasPermission="hasCreateRolePermission()">
    <mat-icon>add</mat-icon> <span i18n="@@users.role-list.create-role"> Create Role </span>
  </button>
</div>

<div class="mat-elevation-z1">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedRoles($event)"
  ></mat-paginator>

  <mat-table [dataSource]="dataset" class="table" *appHasPermission="hasGetRolesPermission()">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="@@users.role-list.name">Name</mat-header-cell>
      <mat-cell *matCellDef="let role">
        <span class="chip">{{ role.name }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="icon-cell"
         i18n="@@users.role-list.actions">Actions</mat-header-cell
      >
      <mat-cell *matCellDef="let role" class="icon-cell">
        <button
          *appHasPermission="hasCrudPermission()"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="setSelectedRole($event, role)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['name', 'actions']"></mat-header-row>
    <mat-row
      class="table-row"
      *matRowDef="let role; columns: ['name', 'actions']"
      (click)="onRowClick($event, role)"
    ></mat-row>
  </mat-table>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="assignOrRevokePermissions()" *appHasPermission="hasAssignRevokePermissionsToRolePermission()" i18n="@@users.role-list.assign-revoke-permissions">
      Assign / Revoke Permissions
    </button>
    <button mat-menu-item (click)="updateRole()" *appHasPermission="hasUpdateRolePermission()" i18n="@@users.role-list.edit-role">Edit Role</button>
    <button mat-menu-item (click)="deleteRole()" *appHasPermission="hasDeleteRolePermission()" i18n="@@users.role-list.delete-role">Delete Role</button>
  </mat-menu>
</div>
