<mat-sidenav-container class="container">
  <div *ngIf="processTasks.length > 0">
    <mat-card *ngFor="let item of processTasks; let i = index">
      <mat-card-header>
        <div class="mat-card-header-container">
          <p>{{ item.name }}</p>
          <button mat-icon-button (click)="toggleSearchCardContent(i)">
            <mat-icon [ngClass]="{ expanded: showCardContent[i] }"
              >expand_more</mat-icon
            >
          </button>
        </div>
      </mat-card-header>
      <ng-container *ngIf="showCardContent[i]" class="table-container">
        <div class="table">
          <mat-table [dataSource]="item.tasks">
            <ng-container matColumnDef="name">
              <mat-cell *matCellDef="let tasks">{{ tasks.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-cell *matCellDef="let tasks">{{
                tasks.description
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-cell *matCellDef="let task">{{ task.status }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-cell *matCellDef="let tasks" class="icon-cell">
                <button mat-icon-button>
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-row
              class="table-row"
              *matRowDef="
                let tasks;
                columns: ['name', 'description', 'actions']
              "
            ></mat-row>
          </mat-table>
        </div>
      </ng-container>
    </mat-card>
  </div>
  <div *ngIf="processTasks.length == 0">
    <p i18n="@@researches.task-search-result.no-tasks-found">no tasks found</p>
  </div>
</mat-sidenav-container>
