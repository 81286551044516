import { Component, OnInit } from '@angular/core';
import { DocumentsAnalyticsFacade } from '../../facades/documents-analytics.facade';
import { RxState } from '@rx-angular/state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { MatDialog } from '@angular/material/dialog';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';

interface WorkflowsTimeGraphComponentState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  workflowTypes: PaginatedList<WorkflowTypes> | undefined;
  reportedCommentPercentage: number;
  currentLoggedInUser?: CurrentLoggedInUser;
}

const initWorkflowsTimeGraphComponentState: Partial<WorkflowsTimeGraphComponentState> =
  {
    selectedFlatOfficeNode: undefined,
    reportedCommentPercentage: 0,
  };
@Component({
  selector: 'app-reported-comments-percentage',
  templateUrl: './reported-comments-percentage.component.html',
  styleUrls: ['./reported-comments-percentage.component.scss'],
})
export class ReportedCommentsPercentageComponent implements OnInit {
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  workflowTypes$ = this.state.select('workflowTypes');
  workflowTypes: WorkflowTypes[] = [];

  reportedCommentPercentage$ = this.state.select('reportedCommentPercentage');
  reportedCommentPercentage: number = 0;

  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  filterForm: FormGroup;

  constructor(
    private documentsAnalyticsFacade: DocumentsAnalyticsFacade,
    private state: RxState<WorkflowsTimeGraphComponentState>,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
    private workflowTypesFacade: WorkflowTypesFacade,
    private fb: FormBuilder,
    private userFacade: UserFacade,
  ) {
    this.state.set(initWorkflowsTimeGraphComponentState);
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );

    this.state.connect(
      'workflowTypes',
      this.workflowTypesFacade.workflowTypes$,
    );

    this.state.connect(
      'reportedCommentPercentage',
      this.documentsAnalyticsFacade.reportedCommentsPercentage$,
    );

    this.filterForm = this.fb.group({
      workflowType: [null],
    });

    this.filterForm.get('workflowType')?.valueChanges.subscribe(() => {
      this.onWorkflowTypeChange();
    });

    this.state.connect('currentLoggedInUser', userFacade.currentLoggedInUser$);
  }

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
      this.documentsAnalyticsFacade.dispatchGetReportedCommentsPercentage(currentLoggedInUser?.officeId as string);
    });

    this.reportedCommentPercentage$.subscribe((percentage) => {
      this.reportedCommentPercentage = percentage;
    });

    this.workflowTypesFacade.dispatchGetWorkflowTypes();

    this.workflowTypes$.subscribe((workflowTypes) => {
      if (workflowTypes) {
        this.workflowTypes = workflowTypes.items;
      }
    });
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.handleSelectedFlatOfficeNodeChange();
    });
  }

  handleSelectedFlatOfficeNodeChange() {
    this.handleReportedCommentPercentage();
  }

  resetFilter() {
    this.officeFacade.dispatchResetSelectedOffice();
    this.filterForm.controls['workflowType'].setValue('');
  }

  onWorkflowTypeChange() {
    this.handleReportedCommentPercentage();
  }

  handleReportedCommentPercentage() {
    if (this.selectedFlatOfficeNode?.id) {
      if (this.filterForm.get('workflowType')?.value?.id != undefined) {
        this.documentsAnalyticsFacade.dispatchGetReportedCommentsPercentage(
          this.selectedFlatOfficeNode?.id,
          this.filterForm.get('workflowType')?.value?.id,
        );
      } else {
        this.documentsAnalyticsFacade.dispatchGetReportedCommentsPercentage(
          this.selectedFlatOfficeNode?.id,
        );
      }
    }
  }

  removeSelectedOffice() {
    this.selectedFlatOfficeNode = undefined;
  }
}
