<div>
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>
        <span i18n="@@documents.reported-workflow-comment-card.reported-by">
          Reported by:
        </span>
        {{ reportedWorkflowComment.issuedByFullName }}</mat-card-title
      >
      <mat-card-subtitle
        ><span i18n="@@documents.reported-workflow-comment-card.reported-on">
          Reported on:
        </span>
        {{ reportedWorkflowComment.reportedOn }}
      </mat-card-subtitle>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        [disabled]="
        reportedWorkflowComment?.commentReportStatus === commentReportStatus.REJECTED 
        || reportedWorkflowComment?.commentReportStatus === commentReportStatus.RESOLVED
        "
        [attr.data-test-id]="'documents.accept-reported-comment.reported-workflow-comment-card.'+reportedWorkflowComment.issueDescription+'-more_vert'"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="resolveComment()" 
        [attr.data-test-id]="'documents.accept-reported-comment.reported-workflow-comment-card.accept-button'">
          <mat-icon>done</mat-icon>
          <span i18n="@@documents.reported-workflow-comment-card.accept"
            >Accept</span
          >
        </button>
        <button mat-menu-item (click)="rejectReport()">
          <mat-icon>close</mat-icon>
          <span i18n="@@documents.reported-workflow-comment-card.reject"
            >Reject</span
          >
        </button>
      </mat-menu>
    </mat-card-header>
    <mat-card-content>
      <div class="flex-row">
        <div class="flex">
          <p
            class="mr-2 mat-body"
            i18n="@@documents.reported-workflow-comment-card.status"
          >
            Status
          </p>
          <mat-chip>
            {{ reportedWorkflowComment.commentReportStatus }}
          </mat-chip>
          <div *ngIf="reportedWorkflowComment?.commentReportStatus === commentReportStatus.REJECTED || reportedWorkflowComment?.commentReportStatus === commentReportStatus.RESOLVED">
            <button mat-icon-button (click)="showReportedWorkflowCommentRespondingRemark()">
              <mat-icon
                aria-hidden="false"
                aria-label="ReportedWorkflowComment Responding icon"
                fontIcon="comment"
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <span
          class="mat-body-2"
          i18n="@@documents.reported-workflow-comment-card.issue-description"
        >
          Issue Description: {{ reportedWorkflowComment.issueDescription }}
        </span>
        <span
          class="mat-body-2"
          i18n="@@documents.reported-workflow-comment-card.issue-date"
        >
          Issue Date: {{ reportedWorkflowComment.issuedDate | date: "medium" }}
        </span>
        <button mat-button (click)="toggleComment()" class="comment-button" 
        [attr.data-test-id]="'documents.show-reported-comment.reported-workflow-comment-card.'+ reportedWorkflowComment.issueDescription+'-show-or-hide-comment-button'">
          {{ collapsed ? "Show Comment" : "Hide Comment" }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <app-show-workflow-comment-card
    [workflowComment]="reportedWorkflowComment.workflowComment"
    *ngIf="!collapsed"
    class="workflow-comment-card"
  />
</div>
