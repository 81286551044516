import {
  ReportedWorkflowComment,
  RespondReportedWorkflowComment,
  WorkflowComment,
} from '../models/workflow-comment.model';

export class GetWorkflowComments {
  static readonly type = `[WorkflowComment] ${GetWorkflowComments.name}`;
  constructor(
    public readonly workflowId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetArchivedWorkflowComments {
  static readonly type = `[WorkflowComment] ${GetArchivedWorkflowComments.name}`;
  constructor(
    public readonly workflowId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class CreateWorkflowComment {
  static readonly type = `[WorkflowComment] ${CreateWorkflowComment.name}`;
  constructor(
    public readonly workflowId: string,
    public readonly comment: string,
  ) {}
}

export class DeleteWorkflowComment {
  static readonly type = `[WorkflowComment] ${DeleteWorkflowComment.name}`;
  constructor(
    public readonly workflowId: string,
    public readonly workflowCommentId: string,
  ) {}
}

export class EditWorkflowComment {
  static readonly type = `[WorkflowComment] ${EditWorkflowComment.name}`;
  constructor(
    public readonly workflowId: string,
    public readonly workflowCommentId: string,
    public readonly content: string,
  ) {}
}

export class SetUpdateWorkflowComment {
  static readonly type = `[WorkflowComment] ${SetUpdateWorkflowComment.name}`;
  constructor(public readonly status: boolean) {}
}

export class SetSelectedWorkflowComment {
  static readonly type = `[WorkflowComment] ${SetSelectedWorkflowComment.name}`;
  constructor(public readonly workflowComment: WorkflowComment) {}
}

export class SetReplyWorkflowComment {
  static readonly type = `[WorkflowComment] ${SetReplyWorkflowComment.name}`;
  constructor(public readonly status: boolean) {}
}

export class ReplyWorkflowComment {
  static readonly type = `[WorkflowComment] ${ReplyWorkflowComment.name}`;
  constructor(
    public readonly workflowCommentId: string,
    public readonly content: string,
  ) {}
}

export class ReportWorkflowComment {
  static readonly type = `[WorkflowComment] ${ReportWorkflowComment.name}`;
  constructor(
    public readonly workflowCommentId: string,
    public readonly issueDescription: string,
  ) {}
}
export class GetWorkflowCommentPolicy {
  static readonly type = `[WorkflowComment] ${GetWorkflowCommentPolicy.name}`;
}

export class CreateWorkflowCommentPolicy {
  static readonly type = `[WorkflowComment] ${CreateWorkflowCommentPolicy.name}`;
  constructor(public readonly description: string) {}
}

export class UpdateWorkflowCommentPolicy {
  static readonly type = `[WorkflowComment] ${UpdateWorkflowCommentPolicy.name}`;
  constructor(
    public readonly id: string,
    public readonly description: string,
  ) {}
}

export class DeleteWorkflowCommentPolicy {
  static readonly type = `[WorkflowComment] ${DeleteWorkflowCommentPolicy.name}`;
  constructor(public readonly id: string) {}
}

export class GetReportedWorkflowComments {
  static readonly type = `[WorkflowComment] ${GetReportedWorkflowComments.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class SetSelectedReportedWorkflowComment {
  static readonly type = `[WorkflowComment] ${SetSelectedReportedWorkflowComment.name}`;
  constructor(
    public readonly reportedWorkflowComment: ReportedWorkflowComment,
  ) {}
}

export class GetWorkflowCommentsByReported {
  static readonly type = `[WorkflowComment] ${GetWorkflowCommentsByReported.name}`;
  constructor(
    public readonly workflowCommentId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class ResolveReportedWorkflowComment {
  static readonly type = `[WorkflowComment] ${ResolveReportedWorkflowComment.name}`;
  constructor(
    public readonly respondReportedWorkflowComment: RespondReportedWorkflowComment,
  ) {}
}

export class UpdateWorkflowCommentReply {
  static readonly type = `[WorkflowComment] ${UpdateWorkflowCommentReply.name}`;
  constructor(
    public workflowCommentId: string,
    public commentId: string,
    public content: string,
  ){}
}

export class SetSelectedReplyComment {
  static readonly type = `[WorkflowComment] ${SetSelectedReplyComment.name}`;
  constructor(
    public comment: WorkflowComment,
  ) {}
}

export class SetWorkflowReplyUpdatingMode {
  static readonly type = `[WorkflowComment] ${SetWorkflowReplyUpdatingMode.name}`;
  constructor(public readonly isUpdatingReply: boolean) {}
}


export class ResetSelectedReplyComment {
  static readonly type = `[WorkflowComment] ${ResetSelectedReplyComment.name}`;
  constructor() {}
}

export class DeleteWorkflowCommentReply {
  static readonly type = `[WorkflowComment] ${DeleteWorkflowCommentReply.name}`;
  constructor(
    public readonly workflowCommentId: string,
    public commentId: string,
    ) {}
}
export class ResetWorkflowComments {
  static readonly type = `[WorkflowComment] ${ResetWorkflowComments.name}`;
  constructor(
    ) {}
}
