import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  Module,
  Permission,
  PermissionWithDependenciesId,
} from '../models/permission.model';
import { Injectable } from '@angular/core';
import { PermissionsService } from '../services/permissons.service';
import {
  GetPermissions,
  GetPermissionsWithDependenciesId,
} from './permission.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';

export interface PermissionStateModel {
  modules: Module[];
  permissionsWithDependenciesId: PermissionWithDependenciesId[];
}

const PERMISSION_STATE_TOKEN = new StateToken<PermissionStateModel>(
  'permissionsState',
);

const defaults = {
  modules: [],
  permissionsWithDependenciesId: [],
};

@State<PermissionStateModel>({
  name: PERMISSION_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class PermissionState {
  constructor(
    private store: Store,
    private permissionsService: PermissionsService,
  ) {}

  @Action(GetPermissions)
  getPermissions({ setState }: StateContext<PermissionStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.permissionsService.getPermissions().pipe(
      tap((modules) => {
        setState(
          patch({
            modules: modules,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetPermissionsWithDependenciesId)
  getPermissionsWithDependenciesId({
    setState,
  }: StateContext<PermissionStateModel>) {
    this.store.dispatch(new SetProgressOn());

    return this.permissionsService.getPermissionsWithDependenciesId().pipe(
      tap((permissions) => {
        setState(
          patch({
            permissionsWithDependenciesId: permissions,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
