import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { StageInstance } from '../models/process-instance.model';
import { Injectable } from '@angular/core';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  GetStageInstanceWithoutSchedule,
  GetAssignedStageInstances,
  SetSelectedAssignedStageInstance,
  SetSelectedStageInstance,
  UpdateStageInstanceStatus,
  ReivewStageInstance,
  UpdateStageInstanceReview,
  GetMyStageInstanceReview,
  SetMyStageInstanceReviewNull,
  GetStageInstanceReview,
  GetStageInstanceReviews,
  GetStageInstanceReviewerType,
  DeleteReviewAttachment,
} from './stage-instance.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { StageInstanceService } from '../services/stage-instance.service';
import { ProcessInstanceState } from './process-instance.state';
import { tap } from 'rxjs';
import { UpdateStageInstanceList } from './process-instance.actions';
import { patch, removeItem } from '@ngxs/store/operators';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  Review,
  StageInstanceByReviewers,
  StageInstanceReview,
  StagesWithoutEvaluationSchedule,
} from '../models/stage-instance-detail.model';
import { StageInstanceReviewerType } from '../models/stage.model';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

export interface StageInstanceStateModel {
  selectedStageInstance: StageInstance | undefined;
  stageInstancesWithoutSchedule: StagesWithoutEvaluationSchedule[];
}

export interface StageInstanceStateModel {
  selectedStageInstance: StageInstance | undefined;
  assignedStageInstances: PaginatedList<StageInstanceByReviewers>;
  selectedAssignedStageInstance: StageInstanceByReviewers | undefined;
  myStageInstancesReview: StageInstanceReview | undefined;
  stageInstancesReviews: PaginatedList<StageInstanceReview>;
  reviews: Review[];
  stageInstanceReviewerType: StageInstanceReviewerType | undefined
}

const STAGE_INSTANCE_STATE_TOKEN = new StateToken<StageInstanceStateModel>(
  'stageInstanceState',
);

const defaults: StageInstanceStateModel = {
  selectedStageInstance: undefined,
  stageInstancesWithoutSchedule: [],
  assignedStageInstances: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedAssignedStageInstance: undefined,
  myStageInstancesReview: undefined,
  stageInstancesReviews: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  reviews: [],
  stageInstanceReviewerType: undefined
};

@State<StageInstanceStateModel>({
  name: STAGE_INSTANCE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class StageInstanceState {
  constructor(
    private stageInstanceService: StageInstanceService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(SetSelectedStageInstance)
  setSelectedProcess(
    { patchState }: StateContext<StageInstanceStateModel>,
    { stageInstance }: SetSelectedStageInstance,
  ) {
    patchState({
      selectedStageInstance: stageInstance,
    });
  }

  @Action(UpdateStageInstanceStatus)
  updateStageInstance(
    { setState }: StateContext<StageInstanceStateModel>,
    { updateStageInstance }: UpdateStageInstanceStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .updateStageInstanceStatusService(updateStageInstance)
      .pipe(
        tap((updatedStageInstance) => {
          setState(
            patch({
              selectedStageInstance: patch({
                stageStatus: updateStageInstance.status,
              }),
            }),
          );
          this.operationStatus.displayStatus($localize`:@@researches.stage-instance.stage-updated-successfully: Stage Updated Successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetStageInstanceWithoutSchedule)
  getStageInstanceWithoutSchedule({
    setState,
  }: StateContext<StageInstanceStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService.getStageInstanceWithoutSchedule().pipe(
      tap((stageInstances) => {
        setState(
          patch({
            stageInstancesWithoutSchedule: stageInstances,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetAssignedStageInstances)
  getStageInstances(
    { patchState }: StateContext<StageInstanceStateModel>,
    { pageNumber, pageSize }: GetAssignedStageInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .getStageInstancesByReviewers(pageNumber, pageSize)
      .pipe(
        tap((paginatedStageInstances) => {
          patchState({
            assignedStageInstances: paginatedStageInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetSelectedAssignedStageInstance)
  setSelectedAssignedStageInstance(
    { patchState }: StateContext<StageInstanceStateModel>,
    { assignedStageInstance }: SetSelectedAssignedStageInstance,
  ) {
    patchState({
      selectedAssignedStageInstance: assignedStageInstance,
    });
  }

  @Action(ReivewStageInstance)
  reviewStageInstance(
    { setState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId, review }: ReivewStageInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .reviewStageInstance(stageInstanceId, review)
      .pipe(
        tap((stageInstanceReview) => {
          setState(
            patch({
              myStageInstancesReview: stageInstanceReview,
            }),
          );
          this.operationStatus.displayStatus('stage instance review saved successfully');
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateStageInstanceReview)
  submitStageInstanceReview(
    { setState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId, reviewId, review }: UpdateStageInstanceReview,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .submitStageInstanceReview(stageInstanceId, reviewId, review)
      .pipe(
        tap((stageInstanceReview) => {
          setState(
            patch({
              myStageInstancesReview: stageInstanceReview,
            }),
          );
          this.operationStatus.displayStatus('stage instance review submitted successfully');
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetMyStageInstanceReview)
  getMyStageInstanceReview(
    { setState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId }: GetMyStageInstanceReview,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .getMyStageInstanceReview(stageInstanceId)
      .pipe(
        tap((myStageInstanceReview) => {
          setState(
            patch({
              myStageInstancesReview: myStageInstanceReview.stageInstanceReview,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetMyStageInstanceReviewNull)
  setMyStageInstanceReviewNull({
    setState,
  }: StateContext<StageInstanceStateModel>) {
    setState(
      patch({
        myStageInstancesReview: undefined,
      }),
    );
  }

  @Action(GetStageInstanceReview)
  getStageInstanceReview(
    { patchState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId, pageNumber, pageSize }: GetStageInstanceReview,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .getStageInstanceReview(stageInstanceId, pageNumber, pageSize)
      .pipe(
        tap((paginatedStageInstanceReview) => {
          patchState({
            stageInstancesReviews: paginatedStageInstanceReview,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetStageInstanceReviews)
  getStageInstanceReviews(
    { patchState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId}: GetStageInstanceReviews,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .getStageInstanceReviews(stageInstanceId)
      .pipe(
        tap((reviews) => {
          patchState({
            reviews: reviews,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetStageInstanceReviewerType)
  getStageInstanceReviewerType(
    { patchState }: StateContext<StageInstanceStateModel>,
    { stageInstanceId}: GetStageInstanceReviewerType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
      .getStageInstanceReviewerType(stageInstanceId)
      .pipe(
        tap((reviewerType) => {
          patchState({
            stageInstanceReviewerType: reviewerType,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(DeleteReviewAttachment)
  deleteReviewAttachment(
    {setState}: StateContext<StageInstanceStateModel>,
    {reviewId,attachmentId}: DeleteReviewAttachment,
  ){
    this.store.dispatch(new SetProgressOn());
    return this.stageInstanceService
    .deleteReviewAttachment(reviewId,attachmentId)
    .pipe(
      tap(() => {
        setState(
          patch({
            myStageInstancesReview: patch({
              attachments: removeItem((item) => item.id === attachmentId)
            })
          })
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.review.deleted-attachemet: Attachment deleted successflly`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff())
      })
    );
  }
}
