import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PermissionSelector } from '../store/permission.selector';
import {
  GetPermissions,
  GetPermissionsWithDependenciesId,
} from '../store/permission.actions';
import {
  Module,
  Permission,
  PermissionWithDependenciesId,
} from '../models/permission.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionFacade {
  modules$: Observable<Module[]> = this.store.select(
    PermissionSelector.slices.modules,
  );
  permissionsWithDependenciesId$: Observable<PermissionWithDependenciesId[]> =
    this.store.select(PermissionSelector.slices.permissionsWithDependenciesId);

  constructor(private store: Store) {}

  dispatchGetPermissions() {
    this.store.dispatch(new GetPermissions());
  }

  dispatchGetPermissionsWithDependenciesId() {
    this.store.dispatch(new GetPermissionsWithDependenciesId());
  }
}
