import { Role } from '../models/role.model';

export class GetRoles {
  static readonly type = `[Roles] ${GetRoles.name}`;
  constructor() {}
}

export class GetPaginatedRoles {
  static readonly type = `[Roles] ${GetPaginatedRoles.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class SetUpdateStatus {
  static readonly type = `[Roles] ${SetUpdateStatus}`;
  constructor(public updateStatus: boolean) {}
}

export class UpdateRole {
  static readonly type = `[Roles] ${UpdateRole}`;
  constructor(public role: Role) {}
}

export class CreateRole {
  static readonly type = `[Roles] ${CreateRole.name}`;
  constructor(public name: string) {}
}

export class SetSelectedRole {
  static readonly type = `[Roles] ${SetSelectedRole.name}`;
  constructor(public role: Role) {}
}

export class GetPermissionsByRoleId {
  static readonly type = `[Roles] ${GetPermissionsByRoleId.name}`;
  constructor(public roleId: string) {}
}

export class AssignRevokePermissions {
  static readonly type = `[Roles] ${AssignRevokePermissions.name}`;
  constructor(
    public roleId: string,
    public permissionsId: string[],
  ) {}
}

export class DeleteRole {
  static readonly type = `[Roles] ${DeleteRole.name}`;
  constructor(public roleId: string) {}
}

export class SearchRole {
  static readonly type = `[Roles] ${SearchRole.name}`;
  constructor(
    public readonly searchTerm: string,
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}