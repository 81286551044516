<div class="main-container" layout="column">
  <div class="inner-container" layout="row">
    <h1 class="mat-h1">{{ selectedReportedIssueDetail?.subject }}</h1>
    <div
    *appHasPermission="hasGetMyContactsPermission()"
    >
      <button
        class="template-btn"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="menu"
        
        i18n="@@users.reported-issue-detail.actions"
      >
        Actions
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          *ngFor="let status of resolvedStatuses"
          (click)="selectStatus(status)"
        >
        <span i18n="@@users.reported-issue-detail.mark-as"> Mark as </span> 
        {{ statusForDisplay[status] }}
        </button>
      </mat-menu>
    </div>
  </div>

  <p>{{ selectedReportedIssueDetail?.description }}</p>

  <div class="tags-container" layout="row">
    <div class="row">
      <p i18n="@@users.reported-issue-detail.status">Status :</p>
      <button
        mat-flat-button
        color="primary"
        class="custom-button"
        (click)="toggleStatusDropdown()"
        [matMenuTriggerFor]="statusMenu"
        *appHasPermission="hasGetAllContactsPermission()"
      >
        {{ checkIssueStatus(selectedReportedIssueDetail?.status) }}
        <mat-icon >{{
          isStatusDropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
        }}</mat-icon>
      </button>
      <button
        mat-flat-button
        color="primary"
        *appHasPermission="hasGetMyContactsPermission()"
      >
        {{ checkIssueStatus(selectedReportedIssueDetail?.status) }}
      </button>
      <mat-menu #statusMenu="matMenu" (closed)="toggleStatusDropdown()">
        <button
          mat-menu-item
          *ngFor="let status of statuses"
          (click)="selectStatus(status)"
        >
          {{ statusForDisplay[status] }}
        </button>
      </mat-menu>    
    </div>
    <div class="row" *appHasPermission="hasUpdateContactUsPriorityPermission()">
      <p i18n="@@users.reported-issue-detail.priority">Priority :</p>
      <button
        mat-flat-button
        color="primary"
        class="custom-button"
        (click)="togglePriorityDropdown()"
        [matMenuTriggerFor]="priorityMenu"
      >
        {{ checkIssuePriority(selectedReportedIssueDetail?.priority) }}
        <mat-icon>{{
          isPriorityDropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
        }}</mat-icon>
      </button>
      <mat-menu #priorityMenu="matMenu" (closed)="togglePriorityDropdown()">
        <button
          mat-menu-item
          *ngFor="let priority of priorities"
          (click)="selectPriority(priority)"
        >
        {{ priorityForDisplay[priority] }}
        </button>
      </mat-menu>
    </div>
  </div>
  <ng-container *ngIf="selectedReportedIssueDetail && selectedReportedIssueDetail?.attachments?.length; else emptyFilesList">
    <div class="mx-5" *appHasPermission="hasGetContactUsAttachmentPermission()">
      <h2 i18n="@@users.contact-us-detail.files" class="mat-h2">Files</h2>
      <mat-card
        *ngFor="let attachment of selectedReportedIssueDetail?.attachments"
        class="custom-mat-card"
        appearance="outlined"
      >
        <mat-card-header>
          <mat-card-title-group>
            <mat-card-title>{{ attachment.name }}</mat-card-title>
  
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="previewAttachment(attachment)" i18n="@@users.reported-issue-detail.preview">
                Preview
              </button>
              <button mat-menu-item (click)="downloadAttachment(attachment)" i18n="@@users.reported-issue-detail.download">
                Download
              </button>
            </mat-menu>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content></mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <ng-template #emptyFilesList>
    <div class="mx-5">
      <h2 i18n="@@users.reported-issue-detail.files">Files</h2>
    </div>
    <div class="empty-files-list">
      <span class="text-2xl" i18n="@@users.reported-issue-detail.no-files-attached">No files attached</span>
    </div>
  </ng-template>
</div>
