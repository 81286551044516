import { append } from '@ngxs/store/operators';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Quill from 'quill';
import { Observable } from 'rxjs';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Office } from 'src/app/offices/models/office.model';
import { Role } from 'src/app/users/models/role.model';
import { User } from 'src/app/users/models/user.model';
import { MemoFacade } from '../../facade/memo.facades';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { Store } from '@ngxs/store';
import { RxState } from '@rx-angular/state';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';
import { MEMOS_ROUTE } from 'src/app/core/constants/routes';
import { Memo, MemoAccessDetail, MemoStatus } from '../../models/memo.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';

interface MemoFormComponentState {
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
  roles: Role[];
  filteredUsers: User[];
  selectedMemo: Memo | null;
  selectedAccessDetail: MemoAccessDetail | null;
}

const initMemoFormComponentState: MemoFormComponentState = {
  selectedFlatOfficeNodes: [],
  roles: [],
  filteredUsers: [],
  selectedMemo: null,
  selectedAccessDetail: null,
};

@Component({
  selector: 'app-memo-form',
  templateUrl: './memo-form.component.html',
  styleUrls: ['./memo-form.component.scss'],
})
export class MemoFormComponent {
  parentId: string | null = null;
  selectedMemo$: Observable<Memo | null> = this.state.select('selectedMemo');
  selectedMemo: Memo | null = null;
  memoForm: FormGroup;
  files: File[] = [];
  isDragOver = false;
  roles: Role[] = [];
  users: User[] = [];
  offices: Office[] = [];
  selectedFlatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.state.select('selectedFlatOfficeNodes');
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined = [];
  selctedFlatFolderNode: FlatFolderNode | null = null;
  roles$: Observable<Role[]> = this.state.select('roles');
  filterdUsers$: Observable<User[]> = this.state.select('filteredUsers');
  update: string = 'false';
  isReplay:string = 'false';
  editor?: Quill;
  composeIsValid: boolean = false;
  initialEditorContent: any;
  selectedAccessDetail$: Observable<MemoAccessDetail | null> =
    this.state.select('selectedAccessDetail');
  selectedAccessDetail: MemoAccessDetail | null = null;

  placeholderToggleLabel = {
    memoTitle: $localize`:@@documents.memo-form.memo-title: Memo Title`,
  };

  constructor(
    private memoFacade: MemoFacade,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private officeFacade: OfficeFacade,
    private state: RxState<MemoFormComponentState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.state.set(initMemoFormComponentState);
    this.state.connect(
      'selectedFlatOfficeNodes',
      officeFacade.selectedFlatOfficeNodes$,
    );
    this.state.connect('roles', roleFacade.roles$);
    this.state.connect('filteredUsers', userFacade.filterdUsers$);
    this.state.connect('selectedMemo', this.memoFacade.selectedMemo$);
    this.state.connect(
      'selectedAccessDetail',
      this.memoFacade.selectedMemoAccessDetail$,
    );

    this.update = this.route.snapshot.paramMap.get('updateStatus')!;
    this.parentId = this.route.snapshot.paramMap.get('parentId')!;
    this.isReplay = this.route.snapshot.paramMap.get('isReplay')!

    this.memoForm = this.fb.group({
      title: ['', !this.parentId ? [Validators.required] : []],
      selectedRoles: [[]],
      selectedUsers: [[]],
    });
  }

  ngOnInit(): void {
    this.editor = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });
    this.editor.on('text-change', this.handleTextChange);

    this.selectedFlatOfficeNodes$.subscribe((selectedFlatOfficeNodes) => {
      this.selectedFlatOfficeNodes = selectedFlatOfficeNodes;
     if(this.selctedFlatFolderNode){
       this.onRoleSelect();
     }
    });
    this.selectedAccessDetail$.subscribe((accessDetail) => {
      if (accessDetail) {
        this.selectedAccessDetail = accessDetail;
        if (this.isReplay === 'true') {
          this.memoForm.controls['selectedRoles'].setValue(accessDetail.senderRole || []);
          this.memoForm.controls['selectedUsers'].setValue(accessDetail.senderUser || []);
          this.selectedFlatOfficeNodes = accessDetail.senderOffice || [];
          this.state.set({ selectedFlatOfficeNodes: this.selectedFlatOfficeNodes });
        } else if (this.update === 'true'){
          this.memoForm.controls['selectedRoles'].setValue(this.selectedAccessDetail?.roles);
          this.memoForm.controls['selectedUsers'].setValue(this.selectedAccessDetail?.users);
          this.selectedFlatOfficeNodes = this.selectedAccessDetail?.offices;
          this.state.set({ selectedFlatOfficeNodes: this.selectedFlatOfficeNodes });
        }else{
          this.selectedFlatOfficeNodes = [];
          this.state.set({ selectedFlatOfficeNodes: this.selectedFlatOfficeNodes });
        }
      }
    });
    this.selectedMemo$.subscribe((memo) => {
      this.selectedMemo = memo;
      this.selectedMemo?.id &&
        this.memoFacade.dispatchGetMemoAccessDetail(this.selectedMemo.id);
      if (this.update === 'true' && this.selectedMemo) {
        this.editor?.setContents(this.selectedMemo.body);
        this.memoForm.controls['title'].setValue(this.selectedMemo.title);
      }

      if (this.parentId && this.selectedMemo)
        this.memoForm.controls['title'].setValue(this.selectedMemo.title);
    });
    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.filterdUsers$.subscribe((filterdUsers) => (this.users = filterdUsers));
  }

  ngOnDestroy(): void {
    this.editor?.off('text-change', this.handleTextChange);
  }

  handleTextChange = () => {
    this.composeIsValid = !!this.editor?.getText().trim();
  };

  openMultipleOfficeChoice() {
    this.matDialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
    });
  }
  onRoleSelect() {
    const selectedRoles = this.memoForm.get('selectedRoles');
    const selectedUsers = this.memoForm.get('selectedUsers');
    const roleIds = selectedRoles?.value.map((r: Role) => r.id);
    const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);

    if (roleIds && officeIds && roleIds.length > 0 && officeIds.length > 0) {
      this.userFacade.dispachGetUsersByRolesAndOffices(roleIds, officeIds);
      this.filterdUsers$.subscribe((filterdUsers) => {
        this.users = filterdUsers;
        const userselectedByUser = selectedUsers?.value as User[];
        const userselectedByUserCopy = selectedUsers?.value as User[];
        userselectedByUserCopy.forEach((user, index) => {
          const potion = this.users.findIndex((u) => u.id === user.id);
          if (potion < 0) {
            userselectedByUser.splice(index, 1);
            selectedUsers?.setValue(userselectedByUser);
          }
        });
      });
    }
    if (
      roleIds &&
      officeIds &&
      (roleIds.length === 0 || officeIds.length === 0)
    ) {
      this.userFacade.dispachSetFilterdUsersEmpty();
      selectedUsers?.setValue([]);
    }
  }
  removeSelectedUser(user: User) {
    const selectedUsers = this.memoForm.get('selectedUsers');
    const users = selectedUsers?.value as User[];
    const index = users.findIndex((u) => u.id === user.id);
    if (index >= 0) {
      users.splice(index, 1);
      selectedUsers?.setValue(users);
    }
  }

  removeSelectedRole(role: Role) {
    const selectedRoles = this.memoForm.get('selectedRoles');
    const roles = selectedRoles?.value as Role[];
    const index = roles.findIndex((r) => r.id === role.id);
    if (index >= 0) {
      roles.splice(index, 1);
      selectedRoles?.setValue(roles);
      this.onRoleSelect();
    }
  }

  removeSelectedOffice(office: FlatOfficeNode) {
    const selectedOffices = this.selectedFlatOfficeNodes;
    const index = selectedOffices?.findIndex((o) => o.id === office.id);
    if (index != undefined && index >= 0) {
      selectedOffices?.splice(index, 1);
      this.state.set({ selectedFlatOfficeNodes: selectedOffices });
      this.onRoleSelect();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      const selectedFiles = Array.from(files);

      const newFiles = selectedFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }
        return !this.files.some(
          (existingFile) => existingFile.name === file.name,
        );
      });
      this.files = this.files.concat(newFiles);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter((file) => {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          if (fileExtension === 'exe') {
            this.snackBar.open('Invalid file type.', 'Close', {
              duration: 3000,
            });
            return false;
          }

          return !this.files.some(
            (existingFile) => existingFile.name === file.name,
          );
        });
        this.files = this.files.concat(newFiles);
      }
    };
    fileInput.click();
    this.memoForm.markAsTouched();
  }

  removeFile(file: File) {
    this.files = this.files.filter((f) => f !== file);
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files) as File[];

      const newFiles = selectedFiles.filter((file: File) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }
        return !this.files.some(
          (existingFile) => existingFile.name === file.name,
        );
      });

      this.files = this.files.concat(newFiles);
    }
  }

  isSaveAndSendButtonDisabled() {
    if (
      this.memoForm.valid &&
      this.selectedFlatOfficeNodes?.length !== 0 &&
      (this.composeIsValid || this.files.length > 0)
    )
      return false;
    return true;
  }

  createMemo() {
    if (this.editor && !this.isSaveAndSendButtonDisabled()) {
      if (this.update === 'false') {
        const { title, selectedRoles, selectedUsers } = this.memoForm.value;
        const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
        const body = JSON.stringify(this.editor.getContents());
        if (!officeIds) return;
        const formData = this.organizeMemo(
          title,
          this.parentId,
          body,
          officeIds,
          selectedRoles,
          selectedUsers,
          MemoStatus.Draft,
        );
        this.memoFacade.dispatchCreateMemo(formData);
        this.router.navigate([MEMOS_ROUTE]);
      } else if (this.selectedMemo) {
        const { title, selectedRoles, selectedUsers } = this.memoForm.value;
        const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
        const body = JSON.stringify(this.editor.getContents());
        if (!officeIds) return;
        const formData = this.organizeMemo(
          title,
          this.parentId,
          body,
          officeIds,
          selectedRoles,
          selectedUsers,
          this.selectedMemo.status === 'Sent'
            ? MemoStatus.Sent
            : MemoStatus.Draft,
        );
        formData.append('id', this.selectedMemo.id);

        this.memoFacade.dispatchUpdateMemo(formData, this.selectedMemo.id);
        this.router.navigate([MEMOS_ROUTE]);
      }
    }
  }

  organizeMemo(
    title: string,
    parentMemoId: string | null,
    body: string,
    officeIds: string[],
    selectedRoles: Role[],
    selectedUsers: User[],
    status: MemoStatus,
  ): FormData {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('status', status);
    officeIds?.forEach((id) => {
      formData.append('SentToOfficeIds', id);
    });
    selectedRoles.forEach((role: Role) => {
      formData.append('sentToRoleIds', role.id);
    });
    selectedUsers.forEach((user: User) => {
      if (user.id != (null || undefined)) {
        formData.append('sentToUserIds', user.id);
      }
    });
    this.files.forEach((file) => {
      formData.append('attachments', file);
    });
    if (parentMemoId) {
      formData.append('parentMemoId', parentMemoId);
    }

    return formData;
  }

  sendMemo() {
    let request: Observable<any> | undefined;
    if (this.editor && !this.isSaveAndSendButtonDisabled()) {
      if (this.update === 'false') {
        const { title, selectedRoles, selectedUsers } = this.memoForm.value;
        const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
        const body = JSON.stringify(this.editor.getContents());
        if (!officeIds) return;
        const formData = this.organizeMemo(
          title,
          this.parentId,
          body,
          officeIds,
          selectedRoles,
          selectedUsers,
          MemoStatus.Sent,
        );

        request = this.memoFacade.dispatchCreateMemo(formData);
      } else if (this.selectedMemo) {
        const { title, selectedRoles, selectedUsers } = this.memoForm.value;
        const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
        const body = JSON.stringify(this.editor.getContents());
        if (!officeIds) return;
        const formData = this.organizeMemo(
          title,
          this.parentId,
          body,
          officeIds,
          selectedRoles,
          selectedUsers,
          MemoStatus.Sent,
        );
        formData.append('id', this.selectedMemo.id);

        request = this.memoFacade.dispatchUpdateMemo(
          formData,
          this.selectedMemo.id,
        );
      }

      request?.subscribe({
        complete: () => {
          this.router.navigate([MEMOS_ROUTE]);
        },
      });
    }
  }

  deleteAttachment(attachmentId: string) {
    if (this.selectedMemo) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@documents.memo-form.delete-attachment:Are you sure you want to delete this attachment?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm' && this.selectedMemo) {
            this.memoFacade.dispatchDeleteAttachment(
              this.selectedMemo.id,
              attachmentId,
            );
          }
        });
    }
  }

  compareFn(o1: any, o2: any): boolean {
    return o1?.id === o2?.id;
  }
}
