import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Notice, NoticeAccessData, NoticeFilter } from '../models/notices.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NoticesService } from '../services/notices.service';
import {
  CreateNotice,
  GetNotices,
  SetSelectedNotice,
  GetNoticeDetial,
  DeleteNotice,
  DeleteNoticeAttachment,
  SearchNotice,
  UpdateNotice,
  SetUpdateStatus,
  GetNoticeAccessData,
  DownloadAttachment,
  PreviewAttachment,
  GetNoticeFilters,
  GetNoticeByStatus,
  SearchNoticeWithDate,
} from './notices.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { filter, take, tap } from 'rxjs';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
export interface NoticeStateModel {
  notices: PaginatedList<Notice>;
  selectedNotice: Notice | undefined;
  update: boolean;
  noticeAccessData: NoticeAccessData | undefined;
  noticeFilters:NoticeFilter[];
  totalCount: number;
  totalPages: number;
}
const NOTICES_STATE_TOKEN = new StateToken<NoticeStateModel>(
  'NoticesStateModel',
);
const defaults: NoticeStateModel = {
  notices: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  selectedNotice: undefined,
  update: false,
  noticeAccessData: undefined,
  noticeFilters:[],
  totalCount: 0,
  totalPages:1,
};
@State<NoticeStateModel>({
  name: NOTICES_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class NoticesState {
  constructor(
    private store: Store,
    private noticesService: NoticesService,
    public operationStatus: OperationStatusService,
  ) {}
  @Action(CreateNotice)
  createNotice(
    { setState, getState }: StateContext<NoticeStateModel>,
    { notice, send }: CreateNotice,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().notices.totalCount;
    let pageSize =
      getState().notices.totalCount / getState().notices.totalPages;
    return this.noticesService.createNotice(notice, send).pipe(
      tap((result) => {
        setState(
          patch({
            notices: patch({
              items: insertItem(result),
              totalCount: (state) => prevLength + 1,
              totalPages: (state) => Math.ceil((prevLength + 1) / pageSize),
            }),
            selectedNotice: result,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.notices.created-notice:Notice created successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetNotices)
  getNotices(
    { patchState }: StateContext<NoticeStateModel>,
    { pageNumber, pageSize }: GetNotices,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService.getNotices(pageNumber, pageSize).pipe(
      tap((result) => {
        patchState({
          notices: result,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedNotice)
  setSelectedNotice(
    { patchState }: StateContext<NoticeStateModel>,
    { notice }: SetSelectedNotice,
  ) {
    patchState({
      selectedNotice: notice,
    });
  }
  @Action(GetNoticeDetial)
  getNoticeDetial(
    { patchState }: StateContext<NoticeStateModel>,
    { noticeId }: GetNoticeDetial,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService.getNoticeDetail(noticeId).pipe(
      tap((result) => {
        patchState({
          selectedNotice: result,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteNotice)
  deleteNotice(
    { setState, getState }: StateContext<NoticeStateModel>,
    { noticeId }: DeleteNotice,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().notices.totalCount;
    return this.noticesService.deleteNotice(noticeId).pipe(
      tap(() => {
        setState(
          patch({
            notices: patch({
              items: removeItem((item) => item.id === noticeId),
              totalCount: () => prevLength - 1,
            }),
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@documents.notices.deleted-notice:Notice deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(DeleteNoticeAttachment)
  deleteNoticeAttachment(
    { setState }: StateContext<NoticeStateModel>,
    { noticeId, attachmentId }: DeleteNoticeAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService
      .deleteNoticeAttachment(noticeId, attachmentId)
      .pipe(
        tap(() => {
          setState(
            patch({
              selectedNotice: patch({
                attachments: removeItem((item) => item.id === attachmentId),
              }),
            }),
          );

          this.operationStatus.displayStatus(
            $localize`:@@documents.notices.deleted-attachemet:Attachment deleted successflly`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SearchNotice)
  searchNotice(
    { patchState }: StateContext<NoticeStateModel>,
    { isDraft, isSent, isReceived, searchTerm }: SearchNotice,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService
      .searchNotice(isDraft, isSent, isReceived, searchTerm)
      .pipe(
        tap((result) => {
          patchState({
            notices: result,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<NoticeStateModel>,
    { updateStatus }: SetUpdateStatus,
  ) {
    patchState({
      update: updateStatus,
    });
  }

  @Action(UpdateNotice)
  updateNotice(
    { patchState, getState }: StateContext<NoticeStateModel>,
    { noticeId, noticeFormData, send }: UpdateNotice,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService
      .updateNotice(noticeId, noticeFormData, send)
      .pipe(
        filter((updatedNotice) => updatedNotice !== null),
        take(1),
        tap((updatedNotice: Notice) => {
          let notices = getState().notices.items;
          let filteredNotices = [
            updatedNotice,
            ...notices.filter((notice) => notice.id !== updatedNotice.id),
          ];
          let newPaginatedNotices = getState().notices;
          newPaginatedNotices.items = filteredNotices;
          patchState({
            notices: newPaginatedNotices,
            selectedNotice: updatedNotice,
            update: false,
          });
          this.operationStatus.displayStatus(
            $localize`:@@documents.notices.updated-notice:Notice updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetNoticeAccessData)
  getNoticeAccessData(
    { patchState }: StateContext<NoticeStateModel>,
    { noticeId }: GetNoticeAccessData,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService.getNoticeAccessData(noticeId).pipe(
      tap((result) => {
        patchState({
          noticeAccessData: result,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DownloadAttachment)
  downloadTaskDocument(
    { patchState }: StateContext<NoticeStateModel>,
    { noticeId, attachmentId, name }: DownloadAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService.getAttachment(noticeId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        let filename = name;
        link.download = filename;
        link.click();
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(PreviewAttachment)
  previewTaskDocument(
    { setState }: StateContext<NoticeStateModel>,
    { noticeId, attachmentId, name }: PreviewAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService.getAttachment(noticeId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetNoticeFilters)
  getNoticeFilters(
    { setState }: StateContext<NoticeStateModel>,
    { }: GetNoticeFilters,
  ) {
    return this.noticesService
      .getNoticeFilters()
      .pipe(
        tap((filters: any) => {
          setState(
            patch({
              noticeFilters: filters
            }),
          );
        }),
      );
  }

  @Action(GetNoticeByStatus)
  getNoticesByStatus(
    { setState, patchState }: StateContext<NoticeStateModel>,
    { status, pageIndex, pageSize }: GetNoticeByStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.noticesService
      .getNoticeByStatus(status, pageIndex, pageSize)
      .pipe(
        tap((notices: PaginatedList<Notice>) => {
          patchState({ notices, totalCount: notices.totalCount });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SearchNoticeWithDate)
  searchNoticeWithDate(
    { patchState }: StateContext<NoticeStateModel>,
    {
      email,
      startDate,
      endDate,
      isDraft,
      isSent,
      isReceived,
      pageNumber,
      pageSize
    }: SearchNoticeWithDate
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.noticesService
      .searchNoticesByDate(email, startDate, endDate, isDraft, isSent, isReceived, pageNumber, pageSize)
      .pipe(
        tap((paginatedNotices) => {
          patchState({ notices: paginatedNotices });
          this.store.dispatch(new SetProgressOff());
        })
      );
  }
}
