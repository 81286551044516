import { Injectable } from '@angular/core';
import { Selector, createPropertySelectors } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContactForm } from '../models/contact-form.model';
import { ContactState, ContactStateModel } from '../store/contact.state';


export class ContactUsSelector {
    static slices = createPropertySelectors<ContactStateModel>(ContactState);


  @Selector([ContactState])
  static contacts(stateModel: ContactStateModel) {
    return stateModel.contactSent;
  }

  @Selector([ContactState])
  static paginatedContactUs(stateModel: ContactStateModel) {
    return stateModel.contactSent;
  }

  @Selector([ContactState])
  static selectedContactUs(stateModel: ContactStateModel) {
    return stateModel.selectedContactUs;
  }

  @Selector([ContactState])
  static totalCount(stateModel: ContactStateModel) {
    return stateModel.contactSent.length;
  }
}