import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RxState } from '@rx-angular/state';
import { DataType } from 'src/app/documents/field-validation-rules/models/validation-rule.model';
import { CurrencyFacade } from 'src/app/researches/facades/currency.facade';
import { CurrencyData } from 'src/app/researches/models/currency.model';

interface CustomInputComponentState {
  currencies: CurrencyData[];
}

const initCustomInputComponentState: CustomInputComponentState = {
  currencies: [],
};

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
})
export class CustomInputComponent implements OnInit {
  @Input()
  control!: FormControl;
  @Input()
  placeholder?: string;
  @Input()
  isReadOnly?: boolean;
  @Input()
  error?: string;
  @Input()
  dataType?: DataType;
  @Input()
  hasStartAndEndDateValidator?: boolean = false;

  @Input()
  options?: string[] | null;

  @Input()
  label?: string;

  currencies$ = this.state.select('currencies');
  currencies: CurrencyData[] = [];
  defaultCurrency: any;
  selectedDefaultCurrency: string | undefined;

  constructor(
    private currencyFacade: CurrencyFacade,
    private state: RxState<CustomInputComponentState>,
  ) {
    this.state.set(initCustomInputComponentState);
    this.state.connect('currencies', this.currencyFacade.currencies$);
  }

  ngOnInit(): void {
    this.currencies$.subscribe((currencies) => {
      this.currencies = currencies;
      const selectedCurrency = this.currencies.find(
        (currency) => currency.isDefault === true,
      )?.currencyName as string;

      selectedCurrency && this.control.setValue(selectedCurrency);
    });
  }

  onCurrencySelect(event: MatSelectChange) {}
}
