import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  Router, 
  RouterStateSnapshot, 
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { UNAUTHORIZED_ROUTE } from 'src/app/core/constants/routes';
import { UserFacade } from 'src/app/users/facade/user.facade';

export enum GuardLevel {
  Module = 'Module',
  Feature = 'Feature',
  Permission = 'Permission'
}

@Injectable({ providedIn: 'root' })
export default class PermissionGuardService {
  constructor(
    private router: Router,
    private userFacade: UserFacade,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    level: GuardLevel,
  ): Observable<boolean> {
    return this.userFacade.currentLoggedInUser$.pipe(
      map(user => {
        let hasPermission = IS_DEVELOPMENT;

        if (level === GuardLevel.Module) {
          hasPermission = user?.moduleNames.some(m => m === route.data['module']) ?? false;
        } 
        
        if (level === GuardLevel.Feature) {
          hasPermission = user?.featureNames.some(f => f === route.data['feature']) ?? false;
        } 
        
        if (level === GuardLevel.Permission) {
          const requiredPermission = route.data['permission'] as string;
          hasPermission = user?.permissions.includes(requiredPermission) ?? false;
        }

        if (!IS_DEVELOPMENT && !hasPermission) {
          this.router.navigate([UNAUTHORIZED_ROUTE]);
        }

        return IS_DEVELOPMENT || hasPermission;
      })
    );
  }
}