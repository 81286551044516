import { Component, Input, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { Task, TaskStatus } from 'src/app/researches/models/task.model';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  StageInstanceDetail,
  StageInstanceTask,
} from 'src/app/researches/models/stage-instance-detail.model';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  TASK_DOCUMENTS_ROUTE,
  TASK_LIST_ROUTE,
} from 'src/app/core/constants/routes';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TaskFormcomponent } from '../task-form/task-form.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { TaskDetail } from 'src/app/researches/models/taskDetail.model';
import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

export interface TaskBoardState {
  taskList: PaginatedList<StageInstanceTask> | undefined;
  selectedTask: Task | undefined;
  selectedStageInstanceDetail: StageInstanceDetail | undefined;
  currentLoggedInUser?: CurrentLoggedInUser;
  processInstanceDetail: ProcessInstanceDetail | undefined;
}

const initTaskBoardState: TaskBoardState = {
  taskList: undefined,
  selectedTask: undefined,
  selectedStageInstanceDetail: undefined,
  processInstanceDetail: undefined,
};

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss'],
})
export class TaskBoardComponent implements OnInit {
  statusForDisplay: { [key in TaskStatus]: string } = {
    [TaskStatus.Todo]: $localize`:@@researches.task-detail.task-status-to-do:Todo`,
    [TaskStatus.InProgress]: $localize`:@@researches.task-detail.task-status-in-progress:InProgress`,
    [TaskStatus.Submitted]: $localize`:@@researches.task-detail.task-status-submitted:Submitted`,
    [TaskStatus.Done]: $localize`:@@researches.task-detail.task-status-done:Done`,
    [TaskStatus.Approved]: $localize`:@@researches.task-detail.task-status-approved:Approved`,
    [TaskStatus.Rejected]: $localize`:@@researches.task-detail.task-status-rejected:Rejected`,
  };
  @Input() processInstance: ProcessInstanceDetail | undefined;
  taskList: PaginatedList<StageInstanceTask> | undefined;
  taskList$ = this.state.select('taskList');
  filteredTasks: StageInstanceTask[] = [];
  selectedStageInstanceDetail$ = this.state.select(
    'selectedStageInstanceDetail',
  );
  selectedStageInstaneDetail: StageInstanceDetail | undefined = undefined;

  processInstanceDetail$ = this.state.select('processInstanceDetail');
  processInstanceDetail: ProcessInstanceDetail | undefined;

  selectedTask: Task | undefined;
  selectedTask$ = this.state.select('selectedTask');
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  taskCategories = Object.values(TaskStatus).length;
  get gridHeaders(): Array<string> {
    return Object.values(this.statusForDisplay);
  }

  constructor(
    private state: RxState<TaskBoardState>,
    private dialog: MatDialog,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private taskFacade: TaskFacade,
    private router: Router,
    private userFacade: UserFacade,
    private processInstanceFacade: ProcessInstanceFacade,
  ) {
    this.state.set(initTaskBoardState);
    this.state.connect(
      'selectedStageInstanceDetail',
      this.stageInstanceDetailFacade.selectedStageInstanceDetail$,
    );
    this.state.connect('selectedTask', this.taskFacade.selectedTask$);
    this.state.connect(
      'taskList',
      this.stageInstanceDetailFacade.stageInstanceTasks$,
    );
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
  }

  ngOnInit(): void {
    this.taskList$.subscribe((taskList) => {
      this.taskList = taskList;
    });

    this.selectedTask$.subscribe((task) => {
      this.selectedTask = task;
    });
    this.selectedStageInstanceDetail$.subscribe(
      (selectedStageInstanceDetail) => {
        this.selectedStageInstaneDetail = selectedStageInstanceDetail;
      },
    );
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });

    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
    });
  }

  setSelectedTask(event: MouseEvent, task: StageInstanceTask) {
    event.stopPropagation();
    this.taskFacade.dispatchSetSelectedTask(task);
    this.canEditAndDelete();
  }

  filterTasks(status: string) {
    if (this.taskList === undefined) return [];
    this.filteredTasks = this.taskList.items.filter((task) => {
      return task.taskStatusForDisplay?.status === status;
    });
    console.log(this.filteredTasks, status, 'filterTasks');
    return this.filteredTasks;
  }

  viewDetail(task: StageInstanceTask): void {
    this.router.navigate([
      `${TASK_LIST_ROUTE}`,
      task.id,
      {
        stageId: this.selectedStageInstaneDetail?.id ?? '',
        processId: undefined,
      },
    ]);
  }

  viewDocument() {
    if (!this.selectedTask) return;
    this.router.navigate([
      TASK_DOCUMENTS_ROUTE,
      this.selectedTask.id,
      { taskId: this.selectedTask.id },
    ]);
  }

  drop(event: CdkDragDrop<StageInstanceTask[]>) {
    const taskId = event.item.element.nativeElement.getAttribute('id');
    this.taskFacade.dispatchChangeTaskStatus({
      id: taskId!,
      taskStatus: event.container.id as TaskStatus,
    });
    this.taskList!.items.find((task) => task.id === taskId!)!.taskStatus = event
      .container.id as TaskStatus;

    transferArrayItem(
      event.previousContainer.data,
      this.filterTasks(event.container.id),
      event.previousIndex,
      event.currentIndex,
    );
  }

  canDrop(status: any) {
    return function (item: CdkDrag<StageInstanceTask>, list: CdkDropList) {
      if (
        (status as TaskStatus) == TaskStatus.Approved ||
        (status as TaskStatus) == TaskStatus.Rejected
      ) {
        return false;
      } else if (
        (status as TaskStatus) == TaskStatus.Submitted ||
        (status as TaskStatus) == TaskStatus.Done
      ) {
        if (!item.data.hasDocument && !item.data.hasFile) return false;
      }
      return true;
    };
  }

  disabled(status: any) {
    if (
      (status as TaskStatus) == TaskStatus.Approved ||
      (status as TaskStatus) == TaskStatus.Rejected ||
      (status as TaskStatus) == TaskStatus.Done
    )
      return true;
    return false;
  }

  updateTask() {
    if (!this.selectedStageInstaneDetail) return;
    if (!this.selectedTask) return;
    this.taskFacade.dispatchUpdate(true);
    const dialogRef = this.dialog.open(TaskFormcomponent, {
      data: {
        stageInstanceDetailId: this.selectedStageInstaneDetail.id,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.dialog.closeAll();
      } else {
        this.taskFacade.dispatchUpdate(false);
        this.taskFacade.dispatchResetSelectedTask();
      }
    });
  }
  openConfirmationDialog() {
    if (!this.selectedTask) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.task-board.delete-task-message-part-1: Are you sure you want to delete`,
        boldText: ` "${this.selectedTask.name}"? `,
        regularTextTwo: $localize`:@@researches.task-board.delete-task-message-part-2: All related documents and comments are also removed.`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        const taskId = this.selectedTask?.id;
        if (!taskId) return;
        this.stageInstanceDetailFacade.dispatchDeleteTaskFromStageInstanceDetail(
          taskId,
        );
        this.dialog.closeAll();
      }
    });
  }
  canEditAndDelete() {
    const id = this.currentLoggedInUser?.id;
    const userName = this.currentLoggedInUser?.userName;
    const email = this.currentLoggedInUser?.email;

    if (
      (this.selectedTask?.assignedTo == id &&
        this.selectedTask?.createdBy == userName) ||
      this.processInstance?.ownerEmail === email ||
      this.selectedTask?.createdBy == userName
    ) {
      return true;
    }
    return false;
  }

  hasCreateTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.CreateTask,
    );
  }

  hasGetFileTasksByTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTasksByTask,
    );
  }
  hasUpdateTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.UpdateTask,
    );
  }

  hasDeleteTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.DeleteTask,
    );
  }

  hasCrudPermission(): string[] {
    return [
      this.hasUpdateTaskPermission(),
      this.hasGetFileTasksByTaskPermission(),
      this.hasDeleteTaskPermission(),
      this.hasGetFileTasksByTaskPermission(),
    ];
  }
}
