import { Component, Input, OnInit } from '@angular/core';
import { WorkflowCommentFacade } from '../../../facade/workflow-comment.facade';
import { WorkflowComment } from '../../../models/workflow-comment.model';

@Component({
  selector: 'app-show-workflow-comment-card',
  templateUrl: './show-workflow-comment-card.component.html',
  styleUrls: ['./show-workflow-comment-card.component.scss'],
})
export class ShowWorkflowCommentCardComponent implements OnInit {
  @Input() workflowComment: WorkflowComment | undefined;

  constructor(private workflowCommentFacade: WorkflowCommentFacade) {}

  ngOnInit() {}
}
