<h1 class="mat-headline-5" i18n="@@documents.external-document-records.dashboard-records">Document Records</h1>
<h2 class="mat-headline-6" id="sentAndReceivedHeaders"
i18n="@@documents.external-document-records.reveived-external-document">
  Received External Documents
</h2>

<div class="mat-elevation-z8">
  <mat-paginator
    [length]="receivedExternalDocuments?.totalCount"
    [pageSize]="receivedPageSize"
    [pageIndex]="receivedPageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedReceivedDocuments($event)"
  >
  </mat-paginator>
  <mat-table [dataSource]="receivedDataSource">
    <ng-container matColumnDef="source">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.source">Source</mat-header-cell>
      <mat-cell *matCellDef="let data">{{ data.source }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="officeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.office">Office</mat-header-cell>
      <mat-cell *matCellDef="let data">{{ data.officeName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="receivedUserName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.receiver-name">Receiver Name</mat-header-cell>
      <mat-cell *matCellDef="let data">{{ data.receivedUserName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.date">Date</mat-header-cell>
      <mat-cell *matCellDef="let data">{{ data.date }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['source', 'officeName', 'receivedUserName', 'date']"
    ></mat-header-row>
    <mat-row
      *matRowDef="
        let data;
        columns: ['source', 'officeName', 'receivedUserName', 'date']
      "
    ></mat-row>
  </mat-table>
</div>

<div *appHasPermission="hasGetSentExternalDocumentsPermission()">
  <h2 class="mat-headline-6" id="sentAndReceivedHeaders"
  i18n="@@documents.external-document-records.sent-external-documents">
    Sent External Documents
  </h2>
  <div class="mat-elevation-z8">
    <mat-paginator
      [length]="sentExternalDocuments?.totalCount"
      [pageSize]="sentPageSize"
      [pageIndex]="sentPageIndex"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedSentDocuments($event)"
    >
    </mat-paginator>
  
    <mat-table [dataSource]="sentDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="destinationOffice">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.destination-office">Destination Office</mat-header-cell>
        <mat-cell *matCellDef="let data">{{
          data.destinationOfficeName
        }}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="sourceOfficename">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.source-office">Source Office</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.sourceOfficename }}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="senderUserName">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.sender-name">Sender Name</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.senderUserName }}</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.external-document-records.creation-date">Creation Date</mat-header-cell>
        <mat-cell *matCellDef="let data">{{ data.date }}</mat-cell>
      </ng-container>
  
      <mat-header-row
        *matHeaderRowDef="[
          'destinationOffice',
          'sourceOfficename',
          'senderUserName',
          'date'
        ]"
      ></mat-header-row>
      <mat-row
        *matRowDef="
          let data;
          columns: [
            'destinationOffice',
            'sourceOfficename',
            'senderUserName',
            'date'
          ]
        "
      ></mat-row>
    </mat-table>
  </div>  
</div>