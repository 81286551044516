import { Memo, MemoStatus } from '../models/memo.model';

export class GetMemosSent {
  static readonly type = `[Memo] ${GetMemosSent.name}`;
  constructor(
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class GetDraftMemo {
  static readonly type = `[Memo] ${GetDraftMemo.name}`;
  constructor(
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class CreateMemo {
  static readonly type = `[Memo] ${CreateMemo.name}`;
  constructor(public memo: FormData) {}
}

export class SelectMemo {
  static readonly type = `[Memo] ${SelectMemo.name}`;
  constructor(public memo: Memo | null) {}
}
export class GetParentMemo {
  static readonly type = '[Memo] Set Parent Memo';
  constructor(public parentId: string) {}
}
export class DeleteMemo {
  static readonly type = `[Memo] ${DeleteMemo.name}`;
  constructor(public id: string) {}
}

export class SetMemoPageNumberAndSize {
  static readonly type = `[Memo] ${SetMemoPageNumberAndSize.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class GetMemosReceived {
  static readonly type = `[Memo] ${GetMemosReceived.name}`;
  constructor(
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class GetMemosCcedToMe {
  static readonly type = `[Memo] ${GetMemosCcedToMe.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class UpdateMemo {
  static readonly type = `[Memo] ${UpdateMemo.name}`;
  constructor(
    public formData: FormData,
    public memoId: string,
  ) {}
}

export class UpdateMemoStatus {
  static readonly type = `[Memo] ${UpdateMemoStatus.name}`;
  constructor(
    public status: MemoStatus,
    public memoId: string,
  ) {}
}

export class GetMemoById {
  static readonly type = `[Memo] ${GetMemoById.name}`;
  constructor(
    public memoId: string,
  ) {}
}

export class GetMemoAccessDetail {
  static readonly type = `[Memo] ${GetMemoAccessDetail.name}`;
  constructor(public memoId: string) {}
}

export class AddCcUserToMemo {
  static readonly type = `[Memo] ${AddCcUserToMemo.name}`;
  constructor(
    public memoId: string,
    public userId: string,
  ) {}
}

export class DeleteMemoAttachment {
  static readonly type = `[Memo] ${DeleteMemoAttachment.name}`;
  constructor(
    public memoId: string,
    public attachmentId: string,
  ) {}
}

export class UpdateSelectedMemo {
  static readonly type = `[Memo] ${UpdateSelectedMemo.name}`;
  constructor(public memoId: string) {}
}

export class RemoveCcUserToMemo {
  static readonly type = `[Memo] ${RemoveCcUserToMemo.name}`;
  constructor(
    public memoId: string,
    public userId: string,
  ) {}
}

export class SearchMemos {
  static readonly type = `[Memo] ${SearchMemos.name}`;
  constructor(
    public searchTerm?: string,
    public sentFromId?: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class SetMemoSearchingMode {
  static readonly type = `[Memo] ${SearchMemos.name}`;
  constructor(public isSearchingMemo: boolean) {}
}

export class PreviewAttachment {
  static readonly type = `[Memo] ${PreviewAttachment.name}`;
  constructor(
    public readonly memoId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}
export class DownloadAttachment {
  static readonly type = `[Memo] ${DownloadAttachment.name}`;
  constructor(
    public readonly memoId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}

export class GetMemoFilters {
  static readonly type = `[Memo] ${GetMemoFilters.name}`;
  constructor() {}
}

export class GetMemosByDate {
  static readonly type = `[Memo] ${GetMemosByDate.name}`;
  constructor(
    public readonly email: string,
    public readonly startDate: string,
    public readonly endDate: string,
    public readonly status: string,
    public readonly pageNumber: number,
    public readonly pageSize: number
  ) {}
}