import { Injectable } from '@angular/core';
import { StageInstanceSelector } from '../store/stage-instance.selector';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  StageInstance,
  UpdateStageInstanceStatusDto,
} from '../models/process-instance.model';
import {
  GetStageInstanceWithoutSchedule,
  GetAssignedStageInstances,
  SetSelectedAssignedStageInstance,
  SetSelectedStageInstance,
  UpdateStageInstanceStatus,
  ReivewStageInstance,
  GetMyStageInstanceReview,
  UpdateStageInstanceReview,
  SetMyStageInstanceReviewNull,
  GetStageInstanceReview,
  GetStageInstanceReviews,
  GetStageInstanceReviewerType,
  DeleteReviewAttachment,
} from '../store/stage-instance.actions';
import {
  Review,
  StageInstanceByReviewers,
  StageInstanceReview,
  StagesWithoutEvaluationSchedule,
} from '../models/stage-instance-detail.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { StageInstanceReviewerType } from '../models/stage.model';

@Injectable({
  providedIn: 'root',
})
export class StageInstanceFacade {
  constructor(private store: Store) {}

  @Select(StageInstanceSelector.selectedStageInstance)
  selectedStageInstance$!: Observable<StageInstance>;

  @Select(StageInstanceSelector.selectedAssignedStageInstance)
  selectedAssignedStageInstance$!: Observable<StageInstanceByReviewers>;

  @Select(StageInstanceSelector.selectStageInstancesWithoutSchedule)
  StageInstancesWithoutSchedule$!: Observable<
    StagesWithoutEvaluationSchedule[]
  >;

  @Select(StageInstanceSelector.assignedStageInstances)
  assignedStageInstances$!: Observable<PaginatedList<StageInstanceByReviewers>>;

  @Select(StageInstanceSelector.myStageInstancesReview)
  myStageInstancesReview$!: Observable<StageInstanceReview>;

  @Select(StageInstanceSelector.stageInstancesReviews)
  stageInstancesReviews$!: Observable<PaginatedList<StageInstanceReview>>;

  @Select(StageInstanceSelector.reviews)
  reviews$!: Observable<Review[]>;

  @Select(StageInstanceSelector.stageInstanceReviewerType)
  stageInstanceReviewerType$!: Observable<StageInstanceReviewerType>;

  dispatchSetSelectedStageInstance(stageInstance: StageInstance) {
    this.store.dispatch(new SetSelectedStageInstance(stageInstance));
  }
  dispatchSetSelectedAssignedStageInstance(
    stageInstance: StageInstanceByReviewers,
  ) {
    this.store.dispatch(new SetSelectedAssignedStageInstance(stageInstance));
  }

  dispatchGetStageInstanceWithoutSchedule() {
    this.store.dispatch(new GetStageInstanceWithoutSchedule());
  }
  dispatchGetAssignedStageInstances(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetAssignedStageInstances(pageNumber, pageSize));
  }
  dispatchReviewStageInstance(
    stageInstanceId: string,
    review: FormData,
  ) {
    this.store.dispatch(new ReivewStageInstance(stageInstanceId, review));
  }
  dispatchGetMyStageInstanceReview(stageInstanceId: string) {
    this.store.dispatch(new GetMyStageInstanceReview(stageInstanceId));
  }

  dispatchUpdateStageInstanceReview(
    stageInstanceId: string,
    reviewId: string,
    review: FormData,
  ) {
    this.store.dispatch(
      new UpdateStageInstanceReview(stageInstanceId, reviewId, review),
    );
  }

  dispatchSetMyStageInstanceReviewNull() {
    this.store.dispatch(new SetMyStageInstanceReviewNull());
  }
  dispatchGetStageInstanceReview(
    stageInstanceId: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetStageInstanceReview(stageInstanceId, pageNumber, pageSize),
    );
  }
  dispatchGetStageInstanceReviews(
    stageInstanceId: string
  ) {
    this.store.dispatch(
      new GetStageInstanceReviews(stageInstanceId)
    );
  }
  dispatchGetStageInstanceReviewerType(
    stageInstanceId: string
  ) {
    this.store.dispatch(
      new GetStageInstanceReviewerType(stageInstanceId)
    );
  }
  dispatchDeleteReviewAttachment(reviewId: string, attachmentId: string) {
    this.store.dispatch(new DeleteReviewAttachment(reviewId,attachmentId));
  }
}
