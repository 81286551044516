import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  AssignStageInstanceReviewers,
  CreateEvaluationSchedule,
  CreateMeetingSchedule,
  DeleteEvaluationSchedule,
  GetEvaluationDetail,
  DeleteMeetingSchedule,
  GetEvaluationScheduleDetail,
  GetEvaluationSchedules,
  GetStageInstanceReviewers,
  SetEditMode,
  SetSelectedEvaluationSchedule,
  UpdateEvalutionSchedule,
  UpdateMeetingSchedule,
  SetUpateStatus,
  DeleteStageInstanceReviewer,
  FinishEvaluationSchedule,
  GetEvaluationScheduleStatus,
  AssignStageInstanceReviewerTypes,
  FinishStageInstanceEvaluation,
} from '../store/evaluation-schedule.actions';

import { EvaluationScheduleSelector } from '../store/evaluation-schedule.selector';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { EvaluationScheduleStatus, Schedule } from '../models/schedule.model';
import { Observable } from 'rxjs';
import { AssignReviewerType, Reviewer } from '../models/reviewer.model';
import { EvaluationDetail } from '../models/evaluation.model';
import { MeetingSchedule } from '../models/meeting-schedule.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluationScheduleFacade {
  @Select(EvaluationScheduleSelector.evaluationSchedules)
  schedules$!: Observable<PaginatedList<Schedule>>;

  @Select(EvaluationScheduleSelector.evaluationScheduledetail)
  selectedSchedules$!: Observable<Schedule>;

  @Select(EvaluationScheduleSelector.stageInstanceReviewers)
  stageInstanceReviewers$!: Observable<Reviewer[]>;

  @Select(EvaluationScheduleSelector.editMode)
  editMode$!: Observable<boolean>;

  @Select(EvaluationScheduleSelector.evaluationDetail)
  evaluationDetail$!: Observable<EvaluationDetail>;

  @Select(EvaluationScheduleSelector.update)
  update$!: Observable<boolean>;

  @Select(EvaluationScheduleSelector.evaluationScheduleStatus)
  evaluationScheduleStatus$!: Observable<EvaluationScheduleStatus>;

  @Select(EvaluationScheduleSelector.EvaluationScheduleLength)
  length$!: Observable<number>;

  constructor(private store: Store) {}

  dispatchGetEvaluationSchedule(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetEvaluationSchedules(pageNumber, pageSize));
  }

  dispatchCreateEvaluationSchedule(schedule: Schedule) {
    this.store.dispatch(new CreateEvaluationSchedule(schedule));
  }

  dispatchUpdateEvaluationSchedule(schedule: Schedule) {
    this.store.dispatch(new UpdateEvalutionSchedule(schedule));
  }

  dispatchDeleteEvaluationSchedule(id: string) {
    this.store.dispatch(new DeleteEvaluationSchedule(id));
  }
  dispatchSetEditMode(update: boolean) {
    this.store.dispatch(new SetEditMode(update));
  }
  dispatchSetSelectedEvaluationSchedule(schedule: Schedule) {
    this.store.dispatch(new SetSelectedEvaluationSchedule(schedule));
  }
  dispatchGetEvaluationScheduleDetail(id: string) {
    this.store.dispatch(new GetEvaluationScheduleDetail(id));
  }
  dispatchGetAssignedReviewers(id: string) {
    this.store.dispatch(new GetStageInstanceReviewers(id));
  }
  dispatchAssignStageInstanceReviewers(id: string, reviewerIds: string[]) {
    this.store.dispatch(new AssignStageInstanceReviewers(id, reviewerIds));
  }

  dispatchGetEvaluationDetail(stageInstanceId: string) {
    return this.store.dispatch(new GetEvaluationDetail(stageInstanceId));
  }

  dispatchCreateMeetingSchedule(meetingSchedule: MeetingSchedule) {
    this.store.dispatch(new CreateMeetingSchedule(meetingSchedule));
  }

  dispatchUpdateMeetingSchedule(meetingSchedule: MeetingSchedule) {
    this.store.dispatch(new UpdateMeetingSchedule(meetingSchedule));
  }

  dispatchDeleteMeetingSchedule(meetingScheduleId: string) {
    this.store.dispatch(new DeleteMeetingSchedule(meetingScheduleId));
  }

  dispatchSetUpateStatus(update: boolean) {
    this.store.dispatch(new SetUpateStatus(update));
  }
  dispatchDeleteStageInstanceReviewer(
    stageInstanceId: string,
    reviewerId: string,
  ) {
    this.store.dispatch(
      new DeleteStageInstanceReviewer(stageInstanceId, reviewerId),
    );
  }

  dispatchFinishEvaluationSchedule(evaluationScheduleId: string) {
    this.store.dispatch(new FinishEvaluationSchedule(evaluationScheduleId));
  }

  dispatchGetEvaluationScheduleStatus(evaluationScheduleId: string) {
    this.store.dispatch(new GetEvaluationScheduleStatus(evaluationScheduleId));
  }

  dispatchAssignReviewerType(stageInstanceId: string, assingReviewerType: AssignReviewerType) {
    this.store.dispatch(new AssignStageInstanceReviewerTypes(stageInstanceId, assingReviewerType));
  }
  dispatchFinishStageInstanceEvaluation(evaluationScheduleId: string,stageInstanceId: string){
    this.store.dispatch(new FinishStageInstanceEvaluation(evaluationScheduleId,stageInstanceId));
  }
}
