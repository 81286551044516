export enum MODULES {
  ANALYTICS = 'Analytics',
  DOCUMENTS = 'Documents',
  FILES = 'Files',
  IDENTITIES = 'Identities',
  NOTIFICATIONS = 'Notifications',
  OFFICES = 'Offices',
  RESEARCHES = 'Researches',
}

export const PERMISSION_NAMES = {
  Researches: {
    ActivityType: {
      Feature: 'Activity Type',
      CreateActivityType: 'Create Activity Type',
      UpdateActivityType: 'Update Activity Type',
      RemoveActivityType: 'Remove Activity Type',
      GetAllActivityTypes: 'Get All Activity Types',
    },

    Criterion: {
      Feature: 'Criterion',
      GetCriterion: 'Get Criterion',
      CreateCriteria: 'Create Criteria',
      UpdateCriteria: 'Update Criteria',
      DeleteCriteria: 'Delete Criteria',
    },

    DocumentTask: {
      Feature: 'Document Task',
      GetDocumentTask: 'Get Document Task',
      GetDocumentTaskByTask: 'Get Document Task ByTask',
      GetDocumentTaskByProcessInstance: 'Get Document Task By Process Instance',
      CreateDocumentTask: 'Create Document Task',
      UpdateDocumentTask: 'Update Document Task',
      DeleteDocumentTask: 'Delete Document Task',
      GetDocumentTaskByStageInstance: 'Get Document Task By Stage Instance',
    },

    EditorType: {
      Feature: 'Editor Type',
      GetAllEditorTypes: 'Get All Editor Types',
    },

    Evaluation: {
      Feature: 'Evaluation',
      CreateEvaluation: 'Create Evaluation',
      UpdateEvaluation: 'Update Evaluation',
      ViewEvaluation: 'View Evaluation',
      GetUserStageEvaluation: 'Get User Stage Evaluation',
      GetEvaluationDetail: 'Get Evaluation Detail',
    },

    EvaluationSchedule: {
      Feature: 'Evaluation Schedule',
      GetEvaluationSchedule: 'Get Evaluation Schedule',
      GetEvaluationScheduleForAStage: 'Get Evaluation Schedule For A Stage',
      GetEvaluationSchedules: 'Get Evaluation Schedules',
      CreateEvaluationSchedule: 'Create Evaluation Schedule',
      UpdateEvaluationSchedule: 'Update Evaluation Schedule',
      DeleteEvaluationSchedule: 'Delete Evaluation Schedule',
      FinishEvaluationSchedule: 'Finish Evaluation Schedule',
      GetEvaluationScheduleStatus: 'Get Evaluation Schedule Status',
      FinishSingleStageInstanceEvaluation:
        'Finish Single Stage Instance Evaluation',
      GetEvaluationScheduleStatusForDisplay:
        'Get Evaluation Schedule Status For Display',
    },

    FileTask: {
      Feature: 'File Task',
      GetFileTask: 'Get File Task',
      GetFileTasksByTask: 'Get File Tasks By Task',
      CreateFileTask: 'Create File Task',
      UpdateFileTask: 'Update File Task',
      DeleteFileTask: 'Delete File Task',
      GetVideo: 'Get Video',
      GetFileTypes: 'Get File Type',
    },

    MeetingSchedule: {
      Feature: 'Meeting Schedule',
      GetMeetingSchedules: 'Get Meeting Schedules',
      CreateMeetingSchedule: 'Create Meeting Schedule',
      DeleteMeetingSchedule: 'Delete Meeting Schedule',
      UpdateMeetingSchedule: 'Update Meeting Schedule',
    },


    Process: {
      Feature: 'Process',
      GetProcess: 'Get Process',
      GetProcesses: 'Get Processes',
      CreateProcess: 'Create Process',
      UpdateProcess: 'Update Process',
      DeleteProcess: 'Delete Process',
      SearchProcesses: 'Search Processes',
      GetProcessCategories: 'Get Process Categories',
    },

    ProcessInstance: {
      Feature: 'Process Instance',
      GetProcessInstances: 'Get Process Instances',
      CreateProcessInstance: 'Create Process Instance',
      UpdateProcessInstance: 'Update Process Instance',
      AssignOwnerToProcessInstance: 'Assign Owner To Process Instance',
      DeleteProcessInstance: 'Delete Process Instance',
      GetProcessInstance: 'Get Process Instance',
      SearchProcessInstances: 'Search Process Instances',
      UpdateProcessInstanceSchedule: 'Update Process Instance Schedule',
      GetArchivedProcessInstances: 'Get Archived Process Instances',
      ArchiveProcessInstance: 'Archive Process Instance',
      AddTeamMembers: 'Add Team Members',
      DeleteTeamMembers: 'Delete Team Members',
      GetTeamMembers: 'Get Team Members',
      CancelProcessInstanceDeletion: 'Cancel Process Instance Deletion',
      GetProcessInstancesByStatus: 'Get Process Instances By Status',
      SubmitProcessInstanceSchedule: 'Submit Process Instance Schedule',
      GetOwnedProcessInstances: 'Get Owned Process Instances',
      GetProcessStatus: 'Get Process Status',
      GetProcessInstancesByCollaboratorsTasks:
        'Get Process Instances By Collaborators Tasks',
      DownloadProcessInstance: 'Download Archived Process Instance',
    },

    Publication: {
      Feature: 'Publication',
      CreatePublication: 'Create Publication',
      GetPublicationProcessDetail: 'Get Publication Process Detail',
      GetPublications: 'Get Publications',
      ChangePublicationStatus: 'Change Publication Status',
      UploadPublicationDocument: 'Upload Publication Document',
      RemovePublicationDocument: 'Remove Publication Document',
      SubmitPublicationDocuments: 'Submit Publication Documents',
      CreatePublicationComment: 'Create Publication Comment',
      GetPublicationComments: 'Get Publication Comments',
      ReplyPublicationComment: 'Reply Publication Comment',
      DeletePublicationComment: 'Delete Publication Comment',
      UpdatePublicationComment: 'Update Publication Comment',
      GetPublicationStatus: 'Get Publication Status',
      UpdatePublicationCommentReply: 'Update Publication Comment Reply',
      DeletePublicationReplyComment: 'Delete Publication Reply Comment',
      ChangePublicationStatusToInitialized: 'Change Publication To Initialized',
      ChangePublicationStatusToReadyForPublication:
        'Change Publication To Ready For Publication',
      ChangePublicationStatusToCancelled: 'Change Publication To Cancelled',
      ChangePublicationStatusToNeedsRevision:
        'Change Publication To Needs Revision',
      ChangePublicationStatusToPublished: 'Change Publication To Published',
      DownloadPublicationDocument: 'Download Publication Document',
      GetPublicationDocument: 'Get Publication Document',
    },

    PublicationOption: {
      Feature: 'Publication Option',
      GetPublicationOptions: 'Get Publication Options',
      AddPublicationOption: 'Add Publication Option',
      UpdatePublicationOption: 'Update Publication Option',
      DeletePublicationOption: 'Delete Publication Option',
      DeletePublicationOptionTemplate: 'Delete Publication Option Template',
      BanPublicationOption: 'Ban Publication Option',
      DownloadPublicationTemplate: 'Download Publication Template',
      PermitPublicationOption: 'Permit Publication Option',
    },

    QuantityType: {
      Feature: 'Quantity Type',
      CreateQuantityType: 'Create Quantity Type',
      UpdateQuantityType: 'Update Quantity Type',
      RemoveQuantityType: 'Remove Quantity Type',
      GetAllQuantityTypes: 'Get All Quantity Types',
    },

    ResearchCouncil: {
      Feature: 'Research Council',
      AddResearchCouncilRoles: 'Add Research Council Roles',
      AddResearchCouncilUsers: 'Add Research Council Users',
      GetResearchCouncilRoles: 'Get Research Council Roles',
      DeleteResearchCouncilRole: 'Delete Research Council Role',
      GetResearchCouncilUsers: 'Get Research Council Users',
      DeleteResearchCouncilUser: 'Delete Research Council User',
      GetResearchCouncilGroup: 'Get Research Council Group',
    },


    Stage: {
      Feature: 'Stage',
      GetStage: 'Get Stage',
      GetStages: 'Get Stages',
      CreateStage: 'Create Stage',
      UpdateStage: 'Update Stage',
      DeleteStage: 'Delete Stage',
      OrderStage: 'Order Stage',
      GetStageCriteria: 'Get Stage Criteria',
    },

    StageInstance: {
      Feature: 'Stage Instance',
      UpdateStatusToInprogress: 'Update Status To Inprogress',
      UpdateStatusToApproved: 'Update Status To Approved',
      UpdateStatusToRejected: 'Update Status To Rejected',
      UpdateStatusToTerminated: 'Update Status To Terminated',
      UpdateStatusToNeedsRevision: 'Update Status To Needs Revision',
      UpdateStatusToSubmittedToEvaluation:
        'Update Status To Submitted To Evaluation',
      UpdateStatusToWaitingForApproval: 'Update Status To Waiting For Approval',
      GetStageInstances: 'Get Stage Instances',
      GetStageInstanceDetail: 'Get Stage Instance Detail',
      GetStageInstanceTask: 'Get Stage Instance Task',
      UpdateStageInstance: 'Update Stage Instance',
      ApproveOrRejectStageInstance: 'Approve Or Reject Stage Instance',
      CheckTasksApprovalStatus: 'Check Tasks Approval Status',
      GetEvaluationStatus: 'Get Evaluation Status',
      AssignReviewers: 'Assign Reviewers',
      GetStageInstancesByReviewers: 'Get Stage Instances By Reviewers',
      GetNonScheduledStageInstances: 'Get Non-Scheduled Stage Instances',
      GetStageInstanceReviewers: 'Get Stage Instance Reviewers',
      RemoveReviewer: 'Remove Reviewer',
      GetStageInstanceMajorTasksDocuments:
        'Get Stage Instance Major Tasks Documents',
      ReviewStageInstance: 'Review Stage Instance',
      UpdateStageInstanceReview: 'Update Stage Instance Review',
      GetMyReview: 'Get My Review',
      GetStageInstanceReviews: 'Get Stage Instance Reviews',
      AssignReviewerType: 'Assign Reviewer Type',
      GetReviewsByStageInstance: 'Get Reviews By Stage Instance',
      DownloadStageInstance: 'Download Stage Instance',
    },

    TaskComment: {
      Feature: 'Task Comment',
      GetTaskComment: 'Get Task Comment',
      GetTaskComments: 'Get Task Comments',
      CreateTaskComment: 'Create Task Comment',
      ReplyTaskComment: 'Reply Task Comment',
      UpdateTaskComment: 'Update Task Comment',
      DeleteTaskComment: 'Delete Task Comment',
      UpdateReplyTaskComment: 'Update Reply Task Comment',
    },

    Task: {
      Feature: 'Task',
      GetTaskDetail: 'Get Task Detail',
      GetTasksByName: 'Get Tasks By Name',
      GetProcessTask: 'Get Process Task',
      CreateTask: 'Create Task',
      UpdateTask: 'Update Task',
      DeleteTask: 'Delete Task',
      UpdateStatus: 'Update Status',
      SearchTask: 'Search Task',
      AssignTask: 'Assign Task',
      AddTaskType: 'Add TaskType',
      GetTasksByType: 'Get Tasks By Type',
      GetTaskTypes: 'Get Task Types',
      GetTaskTypesByStageInstanceId: 'Get Task Types By Stage Instance Id',
      GetCollaboratorsTasks: 'Get Collaborators Tasks',
      GetTaskStatus: 'Get Task Status',
      ToggleIsMajor: 'Toggle Is Major',
      ChangeStatusToTodo: 'Change Status To Todo',
      ChangeStatusToInProgress: 'Change Status To InProgress',
      ChangeStatusToDone: 'Change Status To Done',
      ChangeStatusToSubmitted: 'Change Status To Submitted',
      ChangeStatusToTerminated: 'Change Status To Terminated',
      ChangeStatusToApproved: 'Change Status To Approved',
      ChangeStatusToNeedsRevision: 'Change Status To Needs Revision',
    },

    TorComment: {
      Feature: 'Tor Comment',
      CreateTorComment: 'Create Tor Comment',
      UpdateTorComment: 'Update Tor Comment',
      DeleteTorComment: 'Delete Tor Comment',
      ReplyTorComment: 'Reply Tor Comment',
      GetTorComments: 'Get Tor Comments',
      UpdateReplyTorComment: 'Update Reply Tor Comment',
      DeleteReplyTorComment: 'Delete Reply Tor Comment',
    },

    Tor: {
      Feature: 'TOR',
      GetTors: 'Get Tors',
      GetTorDetail: 'Get Tor Detail',
      CreateTor: 'Create Tor',
      UpdateTor: 'Update Tor',
      DeleteTor: 'Delete Tor',
      DownloadTorPdf: 'Download Tor Pdf',
      CreateCostBreakdownItem: 'Create Cost Breakdown Item',
      GetCostBreakdownItems: 'Get Cost Breakdown Items',
      UpdateCostBreakdownItem: 'Update Cost Breakdown Item',
      ChangeTorStatus: 'Change Tor Status',
      DeleteCostBreakdownItem: 'Delete Cost Breakdown Item',
      GetCurrency: 'Get Currency',
      GetTorStatus: 'Get Tor Status',
      ChangeTorStatusToDraft: 'Change Tor Status Draft',
      ChangeTorStatusToApproved: 'Change Tor Status Approved',
      ChangeTorStatusToNeedsRevision: 'Change Tor Status NeedsRevision',
      ChangeTorStatusToDeclinedByFirstApprover:
        'Change Tor Status Declined By First Approver',
      ChangeTorStatusToDeclinedBySecondApprover:
        'Change Tor Status Declined By Second Approver',
      ChangeTorStatusToSubmittedToFirstApprover:
        'Change Tor Status Submitted To First Approver',
      ChangeTorStatusToSubmittedToSecondApprover:
        'Change Tor Status Submitted To Second Approver',
    },

    Currency: {
      Feature: 'Currency',
      GetCurrencyData: 'Get Currency Data',
      UploadCurrencyExcel: 'Upload Currency Excel',
      DownloadCurrencyExcelTemplate: 'Download Currency Excel Template',
      SetDefaultCurrency: 'Set Default Currency',
    },
  },

  Analytics: {
    AnalyticsResearch: {
      Feature: 'Analytics Research',
      GetResearches: 'Get Researches',
      GetResearchPublications: 'Get Researches Publications',
      GetCountOfEvaluatedProcessWithTheirStatus:
        'Get Count Of Evaluated Process With Their Status',
      GetCommunicationsTimeGraph: 'Get Communications Time Graph',
      GetCommunicationsCount: 'Get Communications Count',
      GetResearchCount: 'Get Research Count',
      GetBudgetTimeGraph: 'Get Budget Time Graph',
      GetCountOfEvaluatedProcessByDepartment:
        'Get Count Of Evaluated Process By Department',
      GetResearchesCount: 'Get Researches Count',
      GetFullLengthResearches: 'Get Full Length Researches',
      GetCommunications: 'Get Communications',
      GetCommunicationTypesWithInstances:
        'Get Communication Types With Instances',
      GetCommunicationsWithStatus: 'Get Communications With Status',
      GetResearchEvaluations: 'Get Research Evaluations',
      GetTaskAccomplishmentCount: 'Get Task Accomplishment Count',
      GetTotalCommunicationsCount: 'Get Total Communications Count',
      GetTotalPublicateioncategoriesCount:
        'Get Total Publicateion Categories Count',
      GetPublicationCategoriesVsResearchCount:
        'Get Publication Categories Vs Research Count',
      GetDepartmentVsPublications: "Get Department Vs Publication",
    },

    AnalyticsDocument: {
      Feature: 'Analytics Document',
      GetReceivedExternalDocuments: 'Get Received External Documents',
      GetSentExternalDocuments: 'Get Sent External Documents',
      GetReceivedExternalDocumentCount: 'Get Received External Document Count',
      GetWorkflowTypesCount: 'Get Workflow Types Count',
      GetWorkflowsCount: 'Get Workflows Count',
      GetWorkflowsByTimeline: 'Get Workflows By Timeline',
      GetReportedCommentsReport: 'Get Reported Comments Report',
      GetAllWorkflowTypes: 'Get All Workflow Types',
      GetAverageTimeSpentPerWorkflowTypeStep:
        'Get Average Time Spent Per Workflow Type Step',
      GetReportedCommentPercentage: 'Get Reported Comment Percentage',
      GetInitiatedWorkflows: 'Get Initiated Workflows',
      GetWorkflowsAverageTimeSpent: 'Get Workflows Average Time Spent',
      GetMyInitiatedWorkflows: 'Get My Initiated Workflows',
      GetWorkflowsIAmInvovedIn: 'Get Workflows I Am Involved In',
    },

    AnalyticsFile: {
      Feature: 'Analytics File',
      GetWorkspaceQuotaData: 'Get Workspace Quota Data',
      GetWorkspaceQuotaDataWithFileType:
        'Get Workspace Quota Data With File Type',
      GetFiles: 'Get Files',
      GetAllWorkspacesFiles: 'Get All Workspaces Files',
      GetStorageGivenToOffices: 'Get Storage Given To Offices',
      GetTotalStorageInfo: 'Get Total Storage Info',
    },

    AnalyticsOffice: {
      Feature: 'Analytics Office',
      GetOffices: 'Get Offices',
      GetOfficesCount: 'Get Offices Count',
      GetOfficesUserOfficeReportsTo: 'Get Offices User Office Reports To',
      GetOfficesReportingToUserOffice: 'Get Offices Reporting To User Office',
      GetUsersWithinOffice: 'Get Users Within Office',
      GetDelegationCount: 'Get Delegation Count',
      GetAllDelegationsAnalytics: 'Get All Delegations Analytics',
      GetUserByRoleName: 'Get User By Role Name',
    },
  },

  Files: {
    FileBookmark: {
      Feature: 'File Bookmark',
      GetBookmarkFiles: 'Get Bookmark Files',
      CreateBookmarkFile: 'Create Bookmark File',
      DeleteBookmarkFile: 'Delete Bookmark File',
    },

    File: {
      Feature: 'Files',
      CreateFile: 'Create File',
      CopyFile: 'Copy File',
      RenameFile: 'Rename File',
      MoveFile: 'Move File',
      DeleteFile: 'Delete File',
      DeleteFiles: 'Delete Multiple Files',
      GetFileProperty: 'Get File Property',
      SearchFiles: 'Search Files',
      GetFilesInFolder: 'Get Files in Folder',
      DownloadFile: 'Download File',
      ShareFilesToOffices: 'Share Files To Offices',
      ShareFilesToRoles: 'Share Files To Roles',
      ShareFilesToUsers: 'Share Files To Users',
      UnshareFromUser: 'Unshare From User',
      GetSharedToFiles: 'Get Shared To Files',
      GetSharedFromFiles: 'Get Shared From Files',
      PreviewFile: 'Preview File',
      PlayAudio: 'Play Audio',
      MoveFileFromArchivedWorkspace: 'Move File From Archived Workspace',
      TrashFiles: 'Trash Multiple Files',
      GetTrashFiles: 'Get Trash Files',
      RestoreFile: 'Restore File',
    },

    FolderBookmark: {
      Feature: 'Folder Bookmark',
      GetBookmarkFolders: 'Get Bookmark Folders',
      CreateBookmarkFolder: 'Create Bookmark Folder',
      DeleteBookmarkFolder: 'Delete Bookmark Folder',
    },

    Folder: {
      Feature: 'Folder',
      GetFolders: 'Get Folders',
      GetFlatFolderNodes: 'Get Flat Folder Nodes',
      CreateFolder: 'Create Folder',
      MoveFolder: 'Move Folder',
      GetFoldersByName: 'Get Folders By Name',
      GetFolderProperty: 'Get Folder Property',
      DeleteFolder: 'Delete Folder',
      RenameFolder: 'Rename Folder',
      ShareFoldersToOffices: 'Share Folders To Offices',
      ShareFoldersToRoles: 'Share Folders To Roles',
      ShareFoldersToUsers: 'Share Folders To Users',
      SharedTo: 'Shared To',
      SharedFrom: 'Shared From',
      GetFoldersByWorkspaceId: 'Get Folders By Workspace Id',
      GetArchivedFolderProperty: 'Get Archived Folder Property',
      MoveFolderFromArchivedWorkspace: 'Move Folder From Archived Workspace',
      TrashFolder: 'Trash Folder',
      GetTrashFolder: 'Get Trash Folder',
      RestoreFolder: 'Restore Folder',
    },

    Workspace: {
      Feature: 'Workspace',
      GetWorkspace: 'Get Workspace',
      GetWorkspaces: 'Get Workspaces',
      UpdateWorkspace: 'Update Workspace',
      GetStorageInfo: 'Get Storage Info',
      GetFlatFolderNodesByWorkspaceId: 'Get Flat Folder Nodes By Workspace Id',
      ArchiveWorkspace: 'Archive Workspace',
      GetArchivedWorkspaces: 'Get Archived Workspaces',
      SearchWorkspaceByName: 'Search Workspace By Name',
    },
  },

  Identities: {
    Permission: {
      Feature: 'Permission',
      GetPermissions: 'Get Permissions',
    },

    Role: {
      Feature: 'Role',
      GetRoles: 'Get Roles',
      GetUsersByRole: 'Get Users By Role',
      CreateRole: 'Create Role',
      UpdateRole: 'Update Role',
      AssignRevokePermissionsToRole: 'Assign Revoke Permissions To Role',
      GetRolePermissions: 'Get Role Permissions',
      DeleteRole: 'Delete Role',
      SearchRoles: 'Search Roles',
    },

    User: {
      Feature: 'User',
      GetUser: 'Get user',
      GetLogInHistory: 'Get Login History',
      GetUsers: 'Get users',
      UpdateUserRole: 'Update User Role',
      GetUsersByAnyType: 'Get Users By Any Type',
      GetAdminUsers: 'Get Admin Users',
      UserActivation: 'User Activation',
      GetUsersByRoleAndOffice: 'Get Users By Role And Office',
      GetUsersByMultipleRoles: 'Get Users By Multiple Roles',
      GetOfficeUsers: 'Get Office Users',
      GetOfficeUsersByRoleIds: 'Get Office Users By Role Ids',
      AssignRevokePermissionsToUser: 'Assign Revoke Permissions To User',
      GetUserPermissions: 'Get User Permissions',
      GetMyPermissions: 'Get My Permissions',
      GetPermissionsFromRoles: 'Get Permissions From Roles',
      GetMyPermissionsFromMyRoles: 'Get My Permissions From My Roles',
      AssignRevokeRolesToUser: 'Assign Revoke Roles To User',
      GetUserRoles: 'Get User Roles',
      GetMyUserRoles: 'Get My User Roles',
      CreateUser: 'Create User',
      DeleteUser: 'Delete User',
      UpdateUser: 'Update User',
      GetUsersDefaultPasswords: 'Get Users Default Passwords',
      ResetDefaultPassword: 'Reset Default Password',
      UpdateUserProfile : "Update User Profile",
    },

    ContactUs: {
      Feature: 'Contact Us',
      GetContactUs: 'Get Contact Us',
      GetAllContactUs: 'Get All Contact Us',
      GetMyContact: 'Get My Contact',
      CreateContactUs: 'Create Contact Us',
      UpdateContactUs: 'Update Contact Us',
      UpdateContactUsStatus: 'Update Contact Us Status',
      UpdateContactUsPrioriry: 'Update Contact Us Priority',
      GetContactUsAttachment: 'Get Contact Us Attachment',
    },
  },

  Offices: {
    Delegation: {
      Feature: 'Delegation',
      GetDelegations: 'Get Delegations',
      GetDelegation: 'Get Delegation',
      GetOnbehalfDelegation: 'Get Onbehalf Delegation',
      AssignDelegation: 'Assign Delegation',
      OnbehalfAssignDelegation: 'Onbehalf Assign Delegation',
      ChangeStatusDelegation: 'Change Status Delegation',
      GetAllDelegations: 'Get All Delegations',
    },

    Office: {
      Feature: 'Office',
      GetOffice: 'Get Office',
      GetOffices: 'Get Offices',
      GetFlatOfficeNodes: 'Get Flat Office Nodes',
      GetReportsToNodes: 'Get Reports To Nodes',
      GetUserOfficeTree: 'Get User Office Tree',
      GetOfficeTree: 'Get Office Tree',
      UpdateOfficeTree: 'Update Office Tree',
      CreateOffice: 'Create Office',
      UpdateOffice: 'Update Office',
      DeleteOffice: 'Delete Office',
      UploadExcelFile: 'Upload Excel File',
      ExportTemplateExcel: 'Export Template Excel',
    },
  },

  Documents: {
    WorkflowType: {
      Feature: 'Workflow Type',
      GetWorkflowStepActionStatus: 'Get Workflow Step Action Status',
      GetWorkflowType: 'Get Workflow Type',
      GetWorkflowTypes: 'Get Workflow Types',
      CreateWorkflowType: 'Create Workflow Type',
      UpdateWorkflowType: 'Update Workflow Type',
      DeleteWorkflowType: 'Delete Workflow Type',
      SearchWorkflowTypes: 'Search Workflow Types',
      GetOwnedWorkflowTypes: 'Get Owned Workflow Types',
    },
    WorkflowTypeStep: {
      Feature: 'Workflow Type Step',
      GetWorkflowTypeStep: 'Get Workflow Type Step',
      GetWorkflowTypeSteps: 'Get Workflow Type Steps',
      CreateWorkflowTypeStep: 'Create Workflow Type Step',
      UpdateWorkflowTypeStep: 'Update Workflow Type Step',
      DeleteWorkflowTypeStep: 'Delete Workflow Type Step',
      AttachNotifyOfficesToWorkflowTypeStep:
        'Attach Notify Offices To Workflow Type Step',
      OrderSteps: 'Order Steps',
    },
    WorkflowTypeStepValidation: {
      Feature: 'Workflow Type Step Validation',
      GetWorkflowStepValidationRules: 'Get Workflow Step Validation Rules',
      CreateWorkflowStepValidation: 'Create Workflow Step Validation',
      DeleteWorkflowStepValidation: 'Delete Workflow Step Validation',
      UpdateWorkflowStepValidation: 'Update Workflow Step Validation',
      GetWorkflowStepValidations: 'Get Workflow Step Validations',
    },
    Workflows: {
      Feature: 'Workflow',
      GetWorkflowStatus: 'Get Workflow Status',
      InitiateWorkflow: 'Initiate Workflow',
      GetWorkflowsByUser: 'Get Workflows By User',
      SearchWorkflows: 'Search Workflows',
      GetNotifiedWorkflows: 'Get Notified Workflows',
      ChangeWorkflowStatus: 'Change Workflow Status',
      GetOwnedWorkflows: 'Get Owned Workflows',
      GetWorkflowsByFilter: 'Get Workflows By Filter',
      DownloadWorkflow: 'Download Workflow',
      DownloadArchivedWorkflow: 'Download Archived Workflow',
      CreateWorkflowValidations: 'Create Workflow Validations',
      GetWorkflowValidations: 'Get Workflow Validations',
      DeleteWorkflowValidations: 'Delete Workflow Validations',
      EditWorkflowValidations: 'Edit Workflow Validations',
      GetWorkflowTemplateFields: 'Get Workflow Template Fields',
      GetArchivedWorkflows: 'Get Archived Workflows',
      GetArchivedWorkflow: 'Get Archived Workflow',
    },
    WorkflowStep: {
      Feature: 'Workflow Step',
      DownloadWorkflowStep: 'Download Workflow Step',
      GetNotifiedUsers: 'Get Notified Users',
      GetWorkflowStep: 'Get Workflow Step',
      GetArchivedWorkflowStep: 'Get Archived Workflow Step',
      GetWorkflowSteps: 'Get Workflow Steps',
      GetArchivedWorkflowSteps: 'Get Archived Workflow Steps',
      UpdateWorkflowStep: 'Update Workflow Step',
      AddNotifyUsers: 'Add Notify Users',
      RemoveNotifyUser: 'Remove Notify User',
      AssignUserToWorkflowStep: 'Assign User To Workflow Step',
      GetNotifiedWorkflowStepDetail: 'Get Notified Workflow Step Detail',
    },
    DocumentForm: {
      Feature: 'Document Form',
      GetDocumentForm: 'Get Document Form',
      GetDocumentForms: 'Get Document Forms',
      GetDocumentFormDetailByStatus: 'Get Document Form Detail By Status',
      GetSubmittedForms: 'Get Submitted Document Form',
      GetArchivedDocumentForms: 'Get Archived Document Forms',
      GetFormStatusOption: 'Get Form Status Option',
      GetDraftForms: 'Get Draft Forms',
      CreateDocumentForm: 'Create Document Form',
      UpdateDocumentForms: 'Update Document Forms',
      SubmitDraft: 'Submit Draft',
      DeleteDocumentForm: 'Delete Document Form',
      GetFormsByStatus: 'Get Forms By Status',
      DeleteDraftDocumentForms: 'Delete Draft Document Forms',
      SearchDocumentFormsByNameAndOffice:
        'Search Document Forms By Name And Office',
      SearchDocumentFormsByName: 'Search Document Forms By Name',
      GetApprovedForms: 'Get Approved Forms',
      SubmitDocumentForm: 'Submit Document Form',
      ChangeFormStatusToDraft: 'Change Form Status To Draft',
      ChangeFormStatusToSubmitted: 'Change Form Status To Submitted',
      ChangeFormStatusToApproved: 'Change Form Status To Approved',
      ChangeFormStatusToRejected: 'Change Form Status To Rejected',
      ChangeFormStatusToChangeRequested:
        'Change Form Status To Change Requested',
      GetDocumentFormByName: 'Get Document form By Name',
    },
    ExternalDocument: {
      Feature: 'External Document',
      GetExternalDocumentResponseStatus:
        'Get External Document Response Status',
      GetRemarkActionStatus: 'Get Remark Action Status',
      UploadExternalDocument: 'Upload External Document',
      RespondToExternalDocument: 'Respond To External Document',
      UpdateExternalDocumentResponse: 'Update External Document Response',
      GetExternalDocumentDetail: 'Get External Document Detail',
      UpdateUploadExternalDocument: 'Update Upload External Document',
      DeleteExternalDocument: 'Delete External Document',
      DeleteExternalDocumentResponse: 'Delete External Document Response',
      GetExternalDocuments: 'Get External Documents',
      GetRespondedExternalDocuments: 'Get Responded External Documents',
      SubmitRemarkForExternalDocument: 'Submit Remark For External Document',
      SubmitRemarkForExternalDocumentResponse:
        'Submit Remark For External Document Response',
      GetExternalDocumentResponseDetail:
        'Get External Document Response Detail',
      GetExternalDocumentAttachment: 'Get External Document Attachment',
      GetExternalDocumentResponseAttachment:
        'Get External Document Response Attachment',
    },
    FieldValidationRule: {
      Feature: 'Field Validation Rule',
      GetDataType: 'Get Data Type',
      GetFieldValidationRule: 'Get Field Validation Rule',
      GetFieldValidationRules: 'Get Field Validation Rules',
      GetValidationRulesByDataType: 'Get Validation Rules By Data Type',
      CreateFieldValidationRule: 'Create Field Validation Rule',
      UpdateFieldValidationRule: 'Update Field Validation Rule',
      DeleteFieldValidationRule: 'Delete Field Validation Rule',
    },

    Memo: {
      Feature: 'Memo',
      GetMemoStatus: 'Get Memo Status',
      GetMemosUserSent: 'Get Memos User Sent',
      CreateMemo: 'Create Memo',
      DeleteMemo: 'Delete Memo',
      GetDraftMemos: 'Get Draft Memos',
      GetMemosReceived: 'Get Memos Received',
      GetMemo: 'Get Memo',
      UpdateMemo: 'Update Memo',
      UpdateMemoStatus: 'Update Memo Status',
      GetSentToUsers: 'Get Sent To Users',
      GetUsersCCedToMemo: 'Get Users CCed To Memo',
      GetMemosCCedToMeQuery: 'Get Memos CCed To Me Query',
      AddUsersCCedToMemo: 'Add Users CCed To Memo',
      RemoveUsersCCedToMemo: 'Remove Users CCed To Memo',
      DeleteAttachment: 'Delete Attachment',
      SearchMemosByNameAndSentFrom: 'Search Memos By Name And Sent From',
      GetAttachment: 'Get Attachment',
      GetMemosByDate: 'Get Memos By Date',
    },
    Notice: {
      Feature: 'Notice',
      GetNoticeStatus: 'Get Notice Status',
      CreateNotice: 'Create Notice',
      GetNotices: 'Get Notices',
      GetNoticeDetail: 'Get Notice Detail',
      DeleteNotice: 'Delete Notice',
      DeleteNoticeAttachment: 'Delete Notice Attachment',
      GetNoticeAccessData: 'Get Notice Access Data',
      GetNoticeAttachment: 'Get Notice Attachment',
      UpdateNotice: 'Update Notice',
      SearchNotices: 'Search Notices',
      GetAttachment: 'Get Attachment',
      GetNoticeFilter: 'Get Notice Filter',
      GetNoticesByDate: 'Get Notices By Date',
    },
    FormField: {
      Feature: 'Form Field',
      GetFormField: 'Get Form Field',
      GetFormFields: 'Get Form Fields',
      CreateFormField: 'Create Form Field',
      UpdateFormField: 'Update Form Field',
      DeleteFormField: 'Delete Form Field',
      GetDataTypes: 'Get Data Types',
      OrderFormField: 'Order Form Field',
    },
    WorkflowComment: {
      Feature: 'Workflow Comment',
      GetCommentReportStatus: 'Get Comment Report Status',
      GetWorkflowComments: 'Get Workflow Comments',
      GetArchivedWorkflowComments: 'Get Archived Workflow Comments',
      CreateWorkflowComment: 'Create Workflow Comment',
      EditWorkflowComment: 'Edit Workflow Comment',
      DeleteWorkflowComment: 'Delete Workflow Comment',
      ReplyWorkflowComment: 'Reply Workflow Comment',
      GetReportedWorkflowComments: 'Get Reported Workflow Comments',
      GetWorkflowComment: 'Get Workflow Comment',
      ReportWorkflowComment: 'Report Workflow Comment',
      RespondReportedWorkflowComment: 'Respond Reported Workflow Comment',
      GetWorkflowCommentsByReported: 'Get Workflow Comments By Reported',
    },
    WorkflowCommentPolicy: {
      Feature: 'Workflow Comment Policy',
      GetWorkflowCommentPolicy: 'Get Workflow Comment Policy',
      CreateWorkflowCommentPolicy: 'Create Workflow Comment Policy',
      UpdateWorkflowCommentPolicy: 'Update Workflow Comment Policy',
      DeleteWorkflowCommentPolicy: 'Delete Workflow Comment Policy',
    },
  },
};

export const GetFullPermissionName = (
  moduleName: MODULES,
  featureName: string,
  permissionName: string,
) => {
  return `${moduleName}:${featureName}:${permissionName}`;
};
