<mat-card
  [ngClass]="
    workflowComment?.isReported
      ? 'example-card-reported'
      : 'example-card'
  "
>
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      {{
        workflowComment?.fullName && workflowComment?.fullName?.substring(0, 1)
      }}
    </div>
    <mat-card-title>
      <p class="mat-body-strong">
        {{ workflowComment!.fullName }}
      </p>
    </mat-card-title>
    <mat-card-subtitle>
      <p class="mat-body-strong">
        {{ workflowComment!.dateTime | date: "medium" }}
      </p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p class="text-comment">
      {{ workflowComment!.comment }}
    </p>
  </mat-card-content>
</mat-card>
<div *ngIf="workflowComment?.replies?.length! > 0">
  <app-show-workflow-comment-reply-card
    *ngFor="let reply of workflowComment?.replies"
    [workflowCommentReply]="reply"
  ></app-show-workflow-comment-reply-card>
</div>
