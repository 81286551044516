<div class="grid-container">
    <div
      class="column-container"
      *ngFor="let contact of gridHeaders"
      cdkDropList
      id="{{ contact }}"
      #{{contact}}="cdkDropList"
      [cdkDropListData]="filterContacts(contact)"
      [cdkDropListConnectedTo]="gridHeaders"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListEnterPredicate]="canDrop(contact)"
    >
      <p class="tile-title bold-5">
        {{ contact }}
        <small class="mat-caption"> ({{ filteredContacts.length }}) <span i18n="@@users.reported-issues.reported-issues">Reported Issues</span></small>
      </p>
      <div class="list-container">
        <mat-list class="card-list">
          <mat-card
            class="card"
            *ngFor="let item of filteredContacts"
            cdkDrag
            [cdkDragData]="item"
            [attr.id]="item.id"
            (click) = "viewDetail(item)"
          >
            <mat-card-header>
              <mat-card-title> {{ item.subject }} </mat-card-title>
            </mat-card-header>
            
            <mat-card-content> {{ item.description }} </mat-card-content>
            <mat-card-actions align="end">
              <div matCardAvatar class="avatar">
              </div>
            </mat-card-actions>
          </mat-card>
        </mat-list>
      </div>
    </div>
  </div>
  