import { Select, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ReportedCommentSelector } from '../store/reported-comments.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  GetReportedCommentsReport,
  GetWorkflowTypes,
} from '../store/reported-comments.actions';
import { ReportedComment } from '../models/reported-comment.model';
import { WorkflowType } from '../models/workfflow-type.model';

@Injectable({
  providedIn: 'root',
})
export class ReportedCommentFacade {
  constructor(private store: Store) {}

  @Select(ReportedCommentSelector.reportedCommentsReport)
  reportedComments$!: Observable<PaginatedList<ReportedComment>>;

  @Select(ReportedCommentSelector.workflowTypes)
  workflowTypes$!: Observable<WorkflowType[]>;

  dispatchGetReportedCommentsReport(
    pageIdx: number,
    pageSize: number,
    workflowType?: string,
    searchTerm?: string,
  ) {
    this.store.dispatch(
      new GetReportedCommentsReport(
        pageIdx,
        pageSize,
        workflowType,
        searchTerm,
      ),
    );
  }

  dispatchGetWorkflowTypes() {
    this.store.dispatch(new GetWorkflowTypes());
  }
}
