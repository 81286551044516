<h2 mat-dialog-title i18n="@@researches.confirm-dialog.confirm-action" i18n="@@shared.confirm-dialog.confirm-action">Confirm Action</h2>
<div mat-dialog-content class="mat-dialog">
  <span>{{ regularTextOne }}</span>
  <span class="font-bold">{{ boldText }}</span>
  <span>{{ regularTextTwo }}</span>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="onConfirm()" color="warn" i18n="@@shared.confirm-dialog.yes" data-test-id="shared.conform-dialog.yes-button">Yes</button>
  <button mat-flat-button mat-dialog-close color="primary" (click)="onCancel()" i18n="@@shared.confirm-dialog.cancel">
    Cancel
  </button>
</div>
