import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Workflow } from '../../models/workflow.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { WorkflowInitiateFormComponent } from '../workflow-initiate-form/workflow-initiate-form.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface WorkflowListComponentState {
  workflows: PaginatedList<Workflow> | undefined;
  filteredWorkflows: PaginatedList<Workflow> | undefined;
  search: boolean;
  length: number;
  isSearchingWorkflow?: boolean;
  workflowSearchTerm?: string;
}

const initialWorkflowListComponentState: WorkflowListComponentState = {
  workflows: undefined,
  search: false,
  length: 0,
  filteredWorkflows: undefined,
};

interface FilterParameters {
  filters: string;
  onlyAssigned: boolean;
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowComponent}],
})
export class WorkflowComponent implements OnInit, MatPaginatorIntl {
  workflows$ = this.state.select('workflows');
  workflows: PaginatedList<Workflow> | undefined;

  filteredWorkflows$ = this.state.select('filteredWorkflows');
  filteredWorkflows: PaginatedList<Workflow> | undefined;

  filterParams: FilterParameters = {
    filters: '',
    onlyAssigned: false,
  };

  search$ = this.state.select('search');
  search: boolean = false;

  length: number = 10;
  length$ = this.state.select('length');

  showSearchTermAndFiltersData: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 0;
  isSearchingWorkflow$ = this.state.select('isSearchingWorkflow');
  isSearchingWorkflow?: boolean = false;
  workflowSearchTerm$ = this.state.select('workflowSearchTerm');
  workflowSearchTerm?: string;

  firstPageLabel = $localize`:@@documents.workflow.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.workflow.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.workflow.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.workflow.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.workflow.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.workflow.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.workflow.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow.page-part-two: of` + `${amountPages}`;
    }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    private state: RxState<WorkflowListComponentState>,
    private workflowFacade: WorkflowFacade,
    private dialog: MatDialog,
  ) {
    this.state.set(initialWorkflowListComponentState);
    this.state.connect('workflows', this.workflowFacade.workflows$);

    this.state.connect(
      'filteredWorkflows',
      this.workflowFacade.filteredWorkflows$,
    );
    this.state.connect('search', this.workflowFacade.search$);

    this.state.connect(
      'isSearchingWorkflow',
      this.workflowFacade.isSearchingWorkflow$,
    );
    this.state.connect(
      'workflowSearchTerm',
      this.workflowFacade.workflowSearchTerm$,
    );
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.workflowFacade.dispatchGetWorkflows(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
    this.workflows$.subscribe((workflows) => {
      if (workflows) {
        this.workflows = workflows;
        this.pageIndex = workflows.pageNumber;
        this.length = workflows.totalCount;
      }
    });
    this.filteredWorkflows$.subscribe((filteredWorkflows) => {
      if (this.search && filteredWorkflows) {
        this.filteredWorkflows = filteredWorkflows;
      }
    });
    this.search$.subscribe((search) => {
      this.search = search;
      if (this.search && this.filteredWorkflows) {
        this.pageIndex = this.filteredWorkflows.pageNumber;
        this.length = this.filteredWorkflows.totalCount;
      } else {
        this.pageIndex = this.workflows?.pageNumber ?? 0;
        this.length = this.workflows?.totalCount ?? 10;
      }
    });
    this.isSearchingWorkflow$.subscribe((isSearchingWorkflow) => {
      this.isSearchingWorkflow = isSearchingWorkflow;
    });

    this.workflowSearchTerm$.subscribe((workflowSearchTerm) => {
      this.workflowSearchTerm = workflowSearchTerm;
    });
  }

  handlePageIndex() {
    if (this.search && this.filteredWorkflows?.pageNumber) {
      return this.filteredWorkflows?.pageNumber - 1;
    } else if (this.workflows?.pageNumber) {
      return this.workflows?.pageNumber - 1;
    } else return 0;
  }
  saveWorkflowParameters(event: FilterParameters) {
    this.filterParams = event;
  }
  loadPaginatedWorkflows(event: PageEvent) {
    if (this.search) {
      this.workflowFacade.dispatchFilterWorkflows({
        filterParams: this.filterParams.filters,
        onlyAssigned: this.filterParams.onlyAssigned,
        pageNumber: event.pageIndex + 1,
        pageSize: event.pageSize,
      });
    } else if (this.isSearchingWorkflow) {
      this.workflowFacade.dispatchSearchWorkflows(
        this.workflowSearchTerm,
        event.pageIndex + 1,
        event.pageSize,
      );
    } else {
      this.workflowFacade.dispatchGetWorkflows(
        event.pageIndex + 1,
        event.pageSize,
      );
    }
  }

  initiateWorkflow() {
    this.dialog.open(WorkflowInitiateFormComponent, SIDE_DIALOG_CONFIG);
  }
  
  hasInitiateWorkflowPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.InitiateWorkflow,
    );
  }
  hasSearchWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.SearchWorkflows,
    );
  }
}
