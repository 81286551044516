<div class="container" *appHasPermission="hasGetAssignedTasksPermission()">
  <div class="flex justify-between">
    <h1 class="mat-h1" i18n="@@researches.assigned-tasks.collaborators-tasks">
      Collaborators Tasks
    </h1>
    <mat-paginator
      [length]="assignedTasks?.totalCount"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedTasks($event)"
    >
    </mat-paginator>
  </div>
</div>
<ng-container *appHasPermission="hasGetAssignedTasksPermission()">
  <div *ngFor="let assignedTask of assignedTasks?.items" class="container">
    <mat-card
      class="task-card"
      (click)="onRowClick($event, assignedTask, assignedTask.id!)"
    >
      <mat-card-header class="card-header">
        <div class="stage-header">
          <div class="flex">
            <mat-card-title class="card-title">
              {{ assignedTask.name }}
            </mat-card-title>
            <div class="chip text-bold">
              {{ assignedTask.taskStatusForDisplay?.status }}
            </div>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content class="text-gray">
        {{ assignedTask.description }}
      </mat-card-content>

      <mat-card-actions>
        <button
          class="detail-button"
          mat-stroked-button
          color="primary"
          i18n="@@researches.assigned-tasks.task-detail"
        >
          Task Detail
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-container>
