<div class="main-continier" layout="column">
  <form class="contact-form" [formGroup]="contactUsForm">
    <h1 class="mat-h1" i18n="@@users.contact-us.contact-us">Contact US</h1>

    <div>
      <mat-form-field class="" appearance="outline">
        <mat-label i18n="@@users.contact-us.subject">Subject</mat-label>
        <input
          id="subject"
          formControlName="subject"
          type="text"
          matInput
          class="full-width-input"
          [placeholder]="placeHolderLabel.enterSubject"
        />
        <mat-error *ngIf="contactUsForm.get('subject')?.hasError('required')"
        i18n="@@users.contact-us.provide-valid-name-for-subject">
          Please provide a valid name for the Subject
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="" appearance="outline">
        <mat-label i18n="@@users.contact-us.description">Description</mat-label>
        <textarea
          id="description"
          formControlName="description"
          rows="5"
          matInput
          class="full-width-input"
          [placeholder]="placeHolderLabel.enterDescription"
        ></textarea>
        <mat-error *ngIf="contactUsForm.get('description')?.hasError('required')"
        i18n="@@users.contact-us.provide-description">
          Please provide a description
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="w-100">
    <div
      class="drop-zone flex-center"
      [class.dragover]="isDragOver"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
      <ng-container *ngIf="attachments.length === 0">
        <p i18n="@@users.contact-us.drag-and-drop-immages">Drag and drop images here</p>
      </ng-container>
      <div *ngFor="let file of attachments">
        <div class="flex-center">
          <p>{{ file.name }}</p>
          <mat-icon class="pointer" color="warn" (click)="removeFile(file)"
            >close</mat-icon
          >
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="selectFile()" 
      i18n="@@users.contact-us.select-images">
        Select images
      </button>
    </div>
   

    <button
      mat-flat-button
      color="primary"
      [disabled]="isSaveButtonDisabled()"
      (click)="createContactUs()"
      class="mt-15 ml-15"
      i18n="@@users.contact-us.submit"
    >
      Submit
    </button>
  </div>
</div>
