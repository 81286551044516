import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AverageTimeSpentWorkflowTypeStep } from '../models/workflow-step-average-time.model';
import { DOCUMENTS_ANALYTICS_URL } from 'src/app/core/constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepAverageTimeService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getWorkflowTypeAverageTimeReport(
    pageNumber: number,
    pageSize: number,
    searchTerm?: string,
    officeId?: string
  ) {
    let queryParams = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (searchTerm) {
      queryParams = queryParams.set('searchTerm', searchTerm);
    }

    if(officeId){
      queryParams = queryParams.set('officeId', officeId);
    }

    return this.http.get<AverageTimeSpentWorkflowTypeStep[]>(
      `${DOCUMENTS_ANALYTICS_URL}/workflow-type-step/avg-time-spent`,
      { params: queryParams },
    );
  }
}
