import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityTypesListComponent } from './components/activity-types-list/activity-types-list.component';
import { QuantityTypesListComponent } from './components/quantity-types-list/quantity-types-list.component';
import { TorSettingsComponent } from './components/tor-settings/tor-settings.component';
import { CreateActivityTypeComponent } from './components/create-activity-type/create-activity-type.component';
import { ConfigureQuantityTypeComponent } from './components/configure-quantity-type/configure-quantity-type.component';

@NgModule({
  declarations: [
    ActivityTypesListComponent,
    QuantityTypesListComponent,
    TorSettingsComponent,
    CreateActivityTypeComponent,
    ConfigureQuantityTypeComponent,
  ],
  imports: [SharedModule],
  exports: [TorSettingsComponent]
})
export class TorConfigurationModule {}
