import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreatePublicationInfo } from '../models/publication.model';
import { SetProgressOff } from 'src/app/core/store/progress-status.actions';
import { CreatePublication } from '../store/publication.actions';

@Injectable({
  providedIn: 'root',
})
export class PublicationFacade {
  constructor(private readonly store: Store) {}

  dispatchCreatePublication(publication: CreatePublicationInfo) {
    return this.store.dispatch(new CreatePublication(publication));
  }
}
