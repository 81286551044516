import { AfterViewInit, Component, DestroyRef, OnInit } from '@angular/core';
import { User } from 'src/app/users/models/user.model';
import { Observable, combineLatest, filter, of, startWith } from 'rxjs';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EChartsOption } from 'echarts';
import { TaskAccomplishmentVsTimeCount } from '../../models/research-dashboard.model';
import { Filter } from '../../models/filter.model';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';

interface TaskAccomplishmentVsTimeComponentState {
  taskAccomplishmentVsTimeCount: TaskAccomplishmentVsTimeCount[];
  filter?: Filter;
  datetime: string;
}

const initTaskAccomplishmentVsTimeComponentState: TaskAccomplishmentVsTimeComponentState =
  {
    taskAccomplishmentVsTimeCount: [],
    datetime:''
  };

@Component({
  selector: 'app-task-accomplishment-vs-time',
  templateUrl: './task-accomplishment-vs-time.component.html',
  styleUrls: ['./task-accomplishment-vs-time.component.scss'],
})
export class TaskAccomplishmentVsTimeComponent implements AfterViewInit {
  datetime: string = '';
  datetime$ = this.state.select('datetime');

  taskAccomplishmentVsTimeCount$ = this.state.select(
    'taskAccomplishmentVsTimeCount',
  );

  eChartInstance: any;
  chartOption!: EChartsOption;

  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    public state: RxState<TaskAccomplishmentVsTimeComponentState>,
    private datetimeFacade: DateTimeFacade,
  ) {
    this.state.set(initTaskAccomplishmentVsTimeComponentState);
    this.state.connect('datetime', this.datetimeFacade.datetime$);
    this.state.connect(
      'taskAccomplishmentVsTimeCount',
      this.researchDashboardFacade.taskAccomplishmentVsTimeCount$,
    );
    this.state.connect('filter', this.researchDashboardFacade.filter$);
    this.state.select('taskAccomplishmentVsTimeCount').subscribe((data) => {
      this.chartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: [$localize`:@@researches.task-accomplishment-vs-time.ahead:Ahead`, $localize`:@@researches.task-accomplishment-vs-time.on-time:On time`, $localize`:@@researches.task-accomplishment-vs-time.over-due:Over due`],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '10%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: Array(12)
              .fill(0)
              .map((_, index) => {
                const date = new Date(this.datetime);
                date.setMonth(index);

                return date.toLocaleString('en-US', {
                  month: 'short',
                });
              }),
          },
        ],
        yAxis: [
          {
            type: 'value',
            interval: 1,
          },
        ],
        series: this.mapTaskAccomplishmentDataToSeries(data),
      };
    });
  }

  ngOnInit(): void {
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
    });
  }
  
  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetTaskAccomplishmentVsTimeCount();

    combineLatest([this.state.select('filter')]).subscribe(([filters]) => {
      this.researchDashboardFacade.dispatchGetTaskAccomplishmentVsTimeCount(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
        filters?.year ?? undefined,
      );
    });
  }

  mapTaskAccomplishmentDataToSeries(
    data: TaskAccomplishmentVsTimeCount[],
  ): any[] {
    const counts: { [category: string]: number[] } = {
      'Over due': [],
      'On time': [],
      Ahead: [],
    };
    data.forEach((item: TaskAccomplishmentVsTimeCount) => {
      const month = item.scheduledMonth - 1;
      counts['Over due'][month] = item.overdueCount;
      counts['On time'][month] = item.onTimeCount;
      counts['Ahead'][month] = item.aheadCount;
    });
    return Object.keys(counts).map((category) => {
      return {
        name: category,
        type: 'bar',
        data: counts[category],
        itemStyle: {
          color:
            category == 'Over due'
              ? '#f44336'
              : category == 'On time'
                ? '#4caf50'
                : '#2196f3',
        },
      };
    });
  }
}
