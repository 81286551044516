<mat-dialog-content mat-dialog-content class="dialog-container">
  <form [formGroup]="remarkFormGroup">
    <ng-template i18n="@@documents.external-document-response-remark-form.fill-out-a-remark-for-this-document">Fill out a remark for this document</ng-template>
    <mat-form-field appearance="outline" class="remark-field">
      <mat-label i18n="@@documents.external-document-response-remark-form.remark">Remark</mat-label>
      <textarea
        id="remark"
        formControlName="remark"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            remarkFormGroup.get('remark')?.value ?? ''
          )
        "
        matInput
      ></textarea>
      <mat-error *ngIf="remarkFormGroup.get('remark')?.hasError('required')" i18n="@@documents.external-document-response-remark-form.remark-is-required">
        remark is required.
      </mat-error>
    </mat-form-field>
  </form>
  <div *ngIf="this.viewOfficeSelector" class="chip-alignment">
    <button mat-raised-button (click)="openSingleOffice()" i18n="@@documents.external-document-response-remark-form.select-recipient-office">
      select recipient office
    </button>
    <mat-chip-row
      class="chip"
      *ngIf="selectedFlatOfficeNode"
      (removed)="removeSelectedFlatOfficeNode()"
      [editable]="false"
    >
      {{ selectedFlatOfficeNode.name }}
      <button
        matChipRemove
        [attr.aria-label]="'remove ' + selectedFlatOfficeNode.name"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="primary"
      (click)="sendRemark()"
      [disabled]="!remarkFormGroup.valid"
     i18n="@@documents.external-document-response-remark-form.send-remark">
      send remark
    </button>
    <button mat-flat-button color="accent" (click)="cancel()" i18n="@@documents.external-document-response-remark-form.cancel">cancel</button>
  </mat-dialog-actions>
</mat-dialog-content>
