import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

import {
  CurrentLoggedInUser,
  User,
  UserWithPassword,
} from '../models/user.model';
import { UsersService } from '../services/users.service';
import {
  DeleteUser,
  GetUsers,
  GetUsersByRoleId,
  GetUsersBySearch,
  RegisterUser,
  SelectUser,
  UpdateUser,
  ForgetPassword,
  ResetForgetPasswordStatus,
  ResetPassword,
  ResetResetPasswordStatus,
  ToggleStatus,
  GetAdmins,
  UpdateUserRole,
  ChangePassword,
  ResetChangePasswordStatus,
  GetUsersByRolesAndOffices,
  SetFilterdUsersEmpty,
  GetUsersByMultipleRoleIds,
  GetApprovers,
  GetEvaluators,
  SetApproversAndEvaluatorsEmpty,
  GetUsersByOfficeId,
  GetOfficeUsersByRoleIds,
  GetLoginHistory,
  GetCurrentLoggedInUser,
  AssignRevokePermissions,
  GetPermissionsInheritedFromRoles,
  GetUserRoles,
  AssignRevokeRoles,
  GetPermissionsOnlyFromDependency,
  GetUserPermissionModules,
  GetUsersDefaultPasswords,
  GetUsersDefaultPasswordsBySearch,
  ResetDefaultPassword,
  SetCloseRegistrationModalFalse,
  GetPasswordPolicy,
  GetEmailSettings,
  UpdateEmailSettings,
  UpdateUserProfile,
} from './user.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { LoginHistory } from '../models/login-history.model';
import { PasswordPolicy } from '../models/password-poilcy.model';
import { EmailSettings } from '../models/email-settings';

export interface UserStateModel {
  users: User[];
  selectedUser: User | undefined;
  isForgetPasswordSuccessful: boolean;
  isResetPasswordSuccessful: boolean;
  isChangePasswordSuccessful: boolean;
  filterdUsers: User[];
  filteredUsersByMultipleRoles: User[];
  approvers: User[];
  evaluators: User[];
  officeUsers: User[];
  loginHistories?: PaginatedList<LoginHistory>;
  currentLoggedInUser?: CurrentLoggedInUser;
  totalCount: number;
  usersWithPassword: PaginatedList<UserWithPassword> | undefined;
  closeRegistrationModal: boolean;
  passwordPolicy: PasswordPolicy | null;
  emailSettings?: EmailSettings
}

const USER_STATE_TOKEN = new StateToken<UserStateModel>('usersState');

const defaults = {
  users: [],
  selectedUser: undefined,
  isForgetPasswordSuccessful: false,
  isResetPasswordSuccessful: false,
  isChangePasswordSuccessful: false,
  filterdUsers: [],
  filteredUsersByMultipleRoles: [],
  approvers: [],
  evaluators: [],
  officeUsers: [],
  totalCount: 0,
  usersWithPassword: undefined,
  closeRegistrationModal: false,
  passwordPolicy: null,
};

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class UserState {
  constructor(
    private usersService: UsersService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetUsers)
  getUsers(
    { setState }: StateContext<UserStateModel>,
    { pageNumber, pageSize }: GetUsers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getUsers(pageNumber, pageSize).pipe(
      tap((users) => {
        setState(
          patch({
            users: users.items,
            totalCount: users.totalCount,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetUsersByRoleId)
  getUsersByRoleId(
    { setState }: StateContext<UserStateModel>,
    { roleId, pageNumber, pageSize }: GetUsersByRoleId,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService
      .getUsersByRoleId(roleId, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              users: users.items,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetUsersBySearch)
  getUsersBySearch(
    { setState }: StateContext<UserStateModel>,
    { search, pageNumber, pageSize }: GetUsersBySearch,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService
      .getUsersBySearch(search, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              users: users.items,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(RegisterUser)
  registerUser(
    { setState, getState }: StateContext<UserStateModel>,
    { user }: RegisterUser,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().totalCount;
    return this.usersService.registerUser(user).pipe(
      tap((u) => {
        setState(
          patch({
            users: insertItem<User>(u),
            totalCount: prevLength + 1,
            closeRegistrationModal: true,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.register-user: User has been registered`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new SetCloseRegistrationModalFalse());
      }),
    );
  }

  @Action(SetCloseRegistrationModalFalse)
  setCloseRegistrationModalFalse(
    { patchState }: StateContext<UserStateModel>,
    {}: SetCloseRegistrationModalFalse,
  ) {
    patchState({ closeRegistrationModal: false });
  }

  @Action(SelectUser)
  selectUser(
    { patchState }: StateContext<UserStateModel>,
    { user }: SelectUser,
  ) {
    patchState({ selectedUser: user });
  }

  @Action(UpdateUser)
  updateUser({ setState }: StateContext<UserStateModel>, { user }: UpdateUser) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.updateUser(user).pipe(
      tap((updatedUser) => {
        setState(
          patch({
            users: updateItem<User>((u) => u?.id === user.id, {
              ...updatedUser,
            }),
            closeRegistrationModal: true,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.update-user: User has been updated`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new SetCloseRegistrationModalFalse());
      }),
    );
  }

  @Action(UpdateUserRole)
  updateUserRole(
    { setState }: StateContext<UserStateModel>,
    { id, roleId }: UpdateUserRole,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.updateUserRole(id, roleId).pipe(
      tap((updatedUser) => {
        setState(
          patch({
            users: removeItem<User>((u) => u?.id === id),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.update-user-role: User role has been updated`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteUser)
  deleteUser({ setState }: StateContext<UserStateModel>, { id }: DeleteUser) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.deleteUser(id).pipe(
      tap((_) => {
        setState(
          patch({
            users: removeItem<User>((u) => u?.id === id),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.delete-user: User has been deleted`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ForgetPassword)
  forgotPassword(
    { setState }: StateContext<UserStateModel>,
    { forgetPassword }: ForgetPassword,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.forgetPassword(forgetPassword.email).pipe(
      tap((result) => {
        setState(
          patch({
            isForgetPasswordSuccessful: result,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.password-reset: We've sent a password reset link to your email.`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ResetForgetPasswordStatus)
  resetForgetPasswordStatus({ setState }: StateContext<UserStateModel>) {
    setState(
      patch({
        isForgetPasswordSuccessful: false,
      }),
    );
  }

  @Action(ResetPassword)
  resetPassword(
    { setState }: StateContext<UserStateModel>,
    { resetPassword }: ResetPassword,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.resetPassword(resetPassword).pipe(
      tap((_) => {
        setState(
          patch({
            isResetPasswordSuccessful: true,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.password-reset-success: your password is successfully reset`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ResetResetPasswordStatus)
  resetResetPasswordStatus({ setState }: StateContext<UserStateModel>) {
    setState(
      patch({
        isResetPasswordSuccessful: false,
      }),
    );
  }
  @Action(GetAdmins)
  getAdmins(
    { setState }: StateContext<UserStateModel>,
    { pageNumber, pageSize }: GetAdmins,
  ) {
    return this.usersService.getAdmins(pageNumber, pageSize).pipe(
      tap((users) => {
        setState(
          patch({
            users: users.items,
          }),
        );
      }),
    );
  }
  @Action(ToggleStatus)
  toggleStatus(
    { setState }: StateContext<UserStateModel>,
    { id }: ToggleStatus,
  ) {
    return this.usersService.toggleStatus(id).pipe(
      tap((user) => {
        setState(
          patch({
            users: updateItem<User>((u) => u?.id === id, { ...user }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.update-user-status: User status has been updated`,
          successStyle,
        );
      }),
    );
  }

  @Action(ChangePassword)
  changePassword(
    { setState }: StateContext<UserStateModel>,
    { changePassword }: ChangePassword,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.changePassword(changePassword).pipe(
      tap((_) => {
        setState(
          patch({
            isChangePasswordSuccessful: true,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.password-change-success: Password changed successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ResetChangePasswordStatus)
  resetChangePasswordStatus({ setState }: StateContext<UserStateModel>) {
    setState(
      patch({
        isChangePasswordSuccessful: false,
      }),
    );
  }

  @Action(GetUsersByRolesAndOffices)
  getUsersByRolesAndOffices(
    { setState }: StateContext<UserStateModel>,
    { roleIds, officeIds }: GetUsersByRolesAndOffices,
  ) {
    return this.usersService.getUsersByRolesAndOffices(roleIds, officeIds).pipe(
      tap((users) => {
        setState(
          patch({
            filterdUsers: users,
            users: users,
          }),
        );
      }),
    );
  }
  @Action(GetUsersByMultipleRoleIds)
  getUsersByMultipleRoleIds(
    { setState }: StateContext<UserStateModel>,
    { roleIds, pageNumber, pageSize }: GetUsersByMultipleRoleIds,
  ) {
    return this.usersService
      .getUsersByMultipleRoleIds(roleIds, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              filteredUsersByMultipleRoles: users,
              users: users,
            }),
          );
        }),
      );
  }
  @Action(SetFilterdUsersEmpty)
  setFilterdUsersEmpty(
    { patchState }: StateContext<UserStateModel>,
    {}: SetFilterdUsersEmpty,
  ) {
    patchState({ filterdUsers: [] });
  }

  @Action(GetApprovers)
  getApprovers(
    { setState }: StateContext<UserStateModel>,
    { roleIds, pageNumber, pageSize }: GetApprovers,
  ) {
    return this.usersService
      .getUsersByMultipleRoleIds(roleIds, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              approvers: users,
            }),
          );
        }),
      );
  }

  @Action(GetEvaluators)
  getEvaluators(
    { setState }: StateContext<UserStateModel>,
    { roleIds, pageNumber, pageSize }: GetEvaluators,
  ) {
    return this.usersService
      .getUsersByMultipleRoleIds(roleIds, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              evaluators: users,
            }),
          );
        }),
      );
  }

  @Action(SetApproversAndEvaluatorsEmpty)
  setApproversAndEvaluatorsEmpty(
    { patchState }: StateContext<UserStateModel>,
    {}: SetApproversAndEvaluatorsEmpty,
  ) {
    patchState({
      approvers: [],
      evaluators: [],
    });
  }
  @Action(GetUsersByOfficeId)
  getUsersByOfficeId(
    { setState }: StateContext<UserStateModel>,
    { officeId, pageNumber, pageSize }: GetUsersByOfficeId,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService
      .getUsersByOfficeId(officeId, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              officeUsers: users,
              users: users,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetOfficeUsersByRoleIds)
  getOfficeUsersByRoleIds(
    { setState }: StateContext<UserStateModel>,
    { roleIds }: GetOfficeUsersByRoleIds,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getOfficeUsersByRoleIds(roleIds).pipe(
      tap((users) => {
        setState(
          patch({
            officeUsers: users,
          }),
        ),
          this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetLoginHistory)
  getLoginHistory(
    { setState }: StateContext<UserStateModel>,
    { pageNumber, pageSize }: GetLoginHistory,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getLoginHistory(pageNumber, pageSize).pipe(
      tap((val) => {
        setState(
          patch({
            loginHistories: val,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetCurrentLoggedInUser)
  getCurrentLoggedInUser(
    { setState }: StateContext<UserStateModel>,
    {}: GetCurrentLoggedInUser,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getCurrentLoggedInUser().pipe(
      tap((user) => {
        setState(
          patch({
            currentLoggedInUser: {
              ...user,
              permissions: user.modules?.flatMap(
                (m) =>
                  m.features?.flatMap(
                    (f) =>
                      f.permissions?.map(
                        (p) => `${m.name}:${f.name}:${p.permissionName}`,
                      ),
                  ),
              ),
              moduleNames: user.modules?.flatMap((m) => m.name),
              featureNames: user.modules?.flatMap(
                (m) => m.features?.map((f) => f.name),
              ),
            },
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AssignRevokePermissions)
  assignRevokePermissions(
    { setState, getState }: StateContext<UserStateModel>,
    { userId, permissionsId }: AssignRevokePermissions,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService
      .assignRevokePermissions(userId, permissionsId)
      .pipe(
        tap((user) => {
          setState(
            patch({
              selectedUser: patch({
                ...user,
                permissionsFromRoles:
                  getState().selectedUser?.permissionsFromRoles,
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@users.user.update-user-permission: User permissions updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetPermissionsInheritedFromRoles)
  getPermissionsInheritedFromRoles(
    { setState, getState }: StateContext<UserStateModel>,
    { userId }: GetPermissionsInheritedFromRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    const user = getState().selectedUser;
    return this.usersService.getPermissionsInheritedFromRoles(userId).pipe(
      tap((permissions) => {
        setState(
          patch({
            selectedUser: patch({
              permissionsFromRoles: permissions,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetUserRoles)
  getUserRoles(
    { setState, getState }: StateContext<UserStateModel>,
    { userId }: GetUserRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    const user = getState().selectedUser;
    return this.usersService.getUserRoles(userId).pipe(
      tap((roles) => {
        setState(
          patch({
            selectedUser: patch({
              roles: roles,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AssignRevokeRoles)
  assignRevokeRoles(
    { setState, getState }: StateContext<UserStateModel>,
    { userId, userIds, roleIds }: AssignRevokeRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    const user = getState().selectedUser;
    return this.usersService.assignRevokeRoles(roleIds, userId, userIds).pipe(
      tap((roles) => {
        setState(
          patch({
            selectedUser: patch({
              roles: roles,
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.user.update-user-role: User roles updated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetPermissionsOnlyFromDependency)
  getPermissionsOnlyFromDependency(
    { setState, getState }: StateContext<UserStateModel>,
    { userId }: GetPermissionsOnlyFromDependency,
  ) {
    this.store.dispatch(new SetProgressOn());
    const user = getState().selectedUser;
    return this.usersService.getPermissionsOnlyFromDependency(userId).pipe(
      tap((permissions) => {
        setState(
          patch({
            selectedUser: patch({
              permissionsFromDependency: permissions,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetUserPermissionModules)
  getUserPermissionModules(
    { setState, getState }: StateContext<UserStateModel>,
    { userId }: GetUserPermissionModules,
  ) {
    this.store.dispatch(new SetProgressOn());
    const user = getState().selectedUser;
    return this.usersService.getUserPermissionModules(userId).pipe(
      tap((modules) => {
        setState(
          patch({
            selectedUser: patch({
              modules: modules,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetUsersDefaultPasswords)
  getUsersDefaultPasswords(
    { setState }: StateContext<UserStateModel>,
    { pageNumber, pageSize }: GetUsersDefaultPasswords,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService
      .getUsersDefaultPasswords(pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              usersWithPassword: users,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetUsersDefaultPasswordsBySearch)
  getUsersDefaultPasswordsBySearch(
    { setState }: StateContext<UserStateModel>,
    { search, pageNumber, pageSize }: GetUsersDefaultPasswordsBySearch
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getUsersDefaultPasswordsBySearch(search, pageNumber, pageSize).pipe(
      tap((users) => {
        setState(
          patch({
            usersWithPassword: users,
          })
        );
        this.store.dispatch(new SetProgressOff());
      })
    );
  }
  @Action(ResetDefaultPassword)
  resetDefaultPassword(
    { setState }: StateContext<UserStateModel>,
    { userId }: ResetDefaultPassword,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.resetDefaultPassword(userId).pipe(
      tap((_) => {
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@users.user.password-reset-to-default-successfully:Password reset to default sucessfully.`,
          successStyle,
        );
      }),
    );
  }

  @Action(GetPasswordPolicy)
  getPasswordPolicy(
    { setState }: StateContext<UserStateModel>,
    {}: GetPasswordPolicy,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.getPasswordPolicy().pipe(
      tap((val) => {
        setState(
          patch({
            passwordPolicy: val,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetEmailSettings)
  getEmailSettings(
    { patchState }: StateContext<UserStateModel>,
    {}: GetEmailSettings,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.GetEmailSettings().pipe(
      tap((emailSettings) => {
        patchState({ emailSettings });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateEmailSettings)
  updateEmailSettings(
    { patchState }: StateContext<UserStateModel>,
    { settings }: UpdateEmailSettings,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.UpdateEmailSettings(settings).pipe(
      tap((emailSettings) => {
        patchState({ emailSettings });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateUserProfile)
  updateUserProfile(
    { setState }: StateContext<UserStateModel>,
    { userProfile }: UpdateUserProfile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.usersService.UpdateUserProfile(userProfile).pipe(
      tap((user) => {
        setState(
          patch({
            currentLoggedInUser: patch({
              userName:user.userName,
              phoneNumber:user.phoneNumber
            }),
            selectedUser:user
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@users.user.profile-updated-successfully:Profile updated sucessfully.`,
          successStyle,
        );
      }),
    );
  }
}
