import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ContactForm } from '../../models/contact-form.model';
import { ContactUsFacade } from '../../facade/contact.facade';
import { RxState } from '@rx-angular/state';
import { Observable, Subject, map } from 'rxjs';
import { ContactUsSelector } from '../../store/contact.selector';
import { Store } from '@ngxs/store';
import { DatePipe } from '@angular/common';
import { REPORTED_ISSUES_DETAIL_ROUTE } from 'src/app/core/constants/routes';
import { Router } from '@angular/router';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { UserFacade } from '../../facade/user.facade';
import { Priority, Status } from 'src/app/core/constants/reported-issues-constants';

export interface ReportedIssuesComponentState {
  paginatedIssues: ContactForm[];
  length: number;
  selectedContactForm: ContactForm | undefined;
}

const initReportedIssuesComponentState: Partial<ReportedIssuesComponentState> = {
  paginatedIssues: [],
  length: 0,
  selectedContactForm: undefined,
};

@Component({
  selector: 'app-reported-issues',
  templateUrl: './reported-issues.component.html',
  styleUrls: ['./reported-issues.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: ReportedIssuesComponent }, DatePipe],
})
export class ReportedIssuesComponent implements OnInit, MatPaginatorIntl {
  dataset!: ContactForm[];
  displayedColumns: string[] = ['reporterName', 'subject', 'status', 'reportedDate'];
  adminDisplayedColumns: string[] = ['reporterName', 'subject', 'priority', 'status', 'reportedDate'];
  issues$: Observable<ContactForm[]> = this.store.select(ContactUsSelector.slices.contactSent);
  paginatedIssues$: Observable<ContactForm[]> = this.state.select('paginatedIssues');
  paginatedIssues: ContactForm[] = [];
  reportedIssues: any;
  reportedIssuesBoard: any;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length$ = this.state.select('length');
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;

  selectedContactForm$ = this.state.select('selectedContactForm');
  selectedContactForm: ContactForm | undefined = undefined;

  firstPageLabel = $localize`:@@users.reported-issues.first-page: First page`;
  itemsPerPageLabel = $localize`:@@users.reported-issues.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@users.reported-issues.last-page: Last page`;

  nextPageLabel = $localize`:@@users.reported-issues.next-page:  Next page`;
  previousPageLabel = $localize`:@@users.reported-issues.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@users.reported-issues.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@users.reported-issues.page-part-one: Page` + `${page + 1}` + $localize`:@@users.reported-issues.page-part-two: of` + `${amountPages}`;
  }

  statusForDisplay: { [key in Status]: string } = {
    [Status.Reported]: $localize`:@@users.reported-issue-detail.reported: Reported`,
    [Status.InProgress]: $localize`:@@users.reported-issue-detail.in-progress: InProgress`,
    [Status.Fixed]: $localize`:@@users.reported-issue-detail.fixed: Fixed`,
    [Status.Resolved]: $localize`:@@users.reported-issue-detail.resolved: Resolved`,
    [Status.Unresolved]: $localize`:@@users.reported-issue-detail.unresolved: Unresolved`,
  };  

  priorityForDisplay: { [key in Priority]: string } = {
    [Priority.High]: $localize`:@@users.reported-issue-detail.high: High`,
    [Priority.Medium]: $localize`:@@users.reported-issue-detail.medium: Medium`,
    [Priority.Low]: $localize`:@@users.reported-issue-detail.low: Low`,
  };  

  constructor(
    private contactUsFacade: ContactUsFacade,
    private state: RxState<ReportedIssuesComponentState>,
    private router: Router,
    private store: Store,
    private userFacade: UserFacade,
  ) {
    this.state.set(initReportedIssuesComponentState);
    this.state.connect('paginatedIssues', this.contactUsFacade.contacts$);
  }

  changes = new Subject<void>();

  ngOnInit(): void {
      this.contactUsFacade.dispatchGetPaginatedContactUs(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10
      );

    this.userFacade.hasPermission(this.hasGetAllContactUsPermission())
    .pipe(
      map((hasPermission) => {
        if (hasPermission) {
          this.displayedColumns = this.adminDisplayedColumns;
          this.contactUsFacade.dispatchGetPaginatedContactUs(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize || 10
          );
        } else {
          this.contactUsFacade.dispatchGetMyPaginatedContacts(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize || 10
          );
        }
      })
    )
    .subscribe();

    this.paginatedIssues$.subscribe((paginatedIssues) => {
      this.dataset = paginatedIssues;
    });
    this.length$.subscribe((length) => (this.length = length));

    this.selectedContactForm$.subscribe((selectedContactForm) => {
      this.selectedContactForm = selectedContactForm;
    });
    this.reportedIssues= $localize`:@@users.reported-issues.reported-issues:Reported Issues`;
    this.reportedIssuesBoard= $localize`:@@users.reported-issues.reported-issues-board:Reported Issues Board`;

  }

  initializeDisplayedColumns() {
    if (this.hasGetAllContactUsPermission()) {
      this.displayedColumns.splice(2, 0, 'priority');
    }
  }

  loadPaginatedIssues(event: PageEvent) {
    this.contactUsFacade.dispatchGetPaginatedContactUs(event.pageIndex + 1, event.pageSize);
  }

  onRowClick(event: any, issue: ContactForm) {
    this.contactUsFacade.dispatchSetSelectedContactUs(issue);
    if (issue.id) {
      this.contactUsFacade.dispatchGetContactUsDetail(
        issue.id as string,
      );
      this.router.navigate([REPORTED_ISSUES_DETAIL_ROUTE]);
    }
  }

  checkIssueStatus(status: any) {
    if (status && status.statusName) {
      return this.statusForDisplay[status.statusName as Status] || $localize`:@@users.reported-issue-detail.reported: Reported`;
    } else {
      return $localize`:@@users.reported-issue-detail.reported: Reported`;
    }
  }
  checkIssuePriority(priority: any) {
    if (priority && priority.priorityName) {
      return this.priorityForDisplay[priority.priorityName as Priority] || $localize`:@@users.reported-issue-detail.not-set: Not Set`;
    } else {
      return $localize`:@@users.reported-issue-detail.not-set: Not Set`;
    }
  }
  hasGetAllContactUsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetAllContactUs,
    );
  }
  hasGetContactUsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetContactUs,
    );
  }

  isAuthorized(module: any): Observable<boolean> {
    return this.userFacade.hasPermission(module).pipe(
      map((module) => !!module),
    );
  }
}
