import { Selector, createPropertySelectors } from '@ngxs/store';
import { WorkspaceState, WorkspaceStateModel } from './workspace.state';

export class WorkspaceSelector {
  static slices = createPropertySelectors<WorkspaceStateModel>(WorkspaceState);

  @Selector([WorkspaceState])
  static archivedWorkspaces(state: WorkspaceStateModel) {
    return state.archivedWorkspaces;
  }

  @Selector([WorkspaceState])
  static selectedArchivedWorkspace(state: WorkspaceStateModel) {
    return state.selectedArchivedWorkspace;
  }

  @Selector([WorkspaceState])
  static filteredWorkspaces(state: WorkspaceStateModel) {
    return state.filteredWorkspaces;
  }
}
