import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  PublicationCategoriesVsProcessCount,
  PublicationData,
  ResearchVsTime,
} from '../models/research-vs-time.model';
import { Injectable } from '@angular/core';
import { ResearchDashboardService } from '../services/research-dashboard.service';
import {
  GetBudgetPerCommunication,
  GetBudgetTimeGraph,
  GetCommunicationCount,
  GetCommunicationTimeGraph,
  GetCommunicationTypeInstances,
  GetCommunicationsWithStatus,
  GetDepartmentVsPublication,
  GetFullLengthResearches,
  GetResearchCommunications,
  GetResearchCount,
  GetResearchEvaluations,
  GetResearchPublicationData,
  GetResearchVsTime,
  GetTotalCost,
  GetTotalCostCommunication,
  GetTotalCostResearch
} from './research-dashboard.action';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import {
  GetEvaluatedProcessInstanceCountByDepartment,
  GetEvaluatedProcessInstanceStatusCount,
  GetPublishedResearchesVsCategory,
  GetResearchesCount,
  GetTotalCommunicationsCount,
  GetTaskAccomplishmentVsTimeCount,
  SetDashboardFilter,
  GetPublicationCategoriesCount,
} from './research-dashboard.actions';
import {
  EvaluatedProcessInstanceCountByDepartment,
  EvaluatedProcessInstanceStatusCount,
  TaskAccomplishmentVsTimeCount,
} from '../models/research-dashboard.model';
import {
  BudgetPerCommunication,
  CommunicationVsTime,
} from '../models/communication-vs-time.mode';
import { CommunicationCount } from '../models/communication-count';
import { ResearchCount } from '../models/research-count';
import { patch } from '@ngxs/store/operators';
import { Research } from '../models/research.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  Communications,
  CommunicationsWithStatus,
} from '../models/communications';
import { BudgetVsTime } from '../models/budget-vs-time.model';
import { CommunicationTypeInstance } from '../models/communication-type-Instances.model';
import { ResearchEvaluation } from '../models/research-evaluation.model';
import { DepartmentVsPublication } from '../models/department-vs-publicaiton.model'
export interface ResearchDashboardStateModel {
  communicationCount?: PaginatedList<any>;
  communicationVsTime: CommunicationVsTime[];
  researchCount?: PaginatedList<ResearchCount>;
  researchVsTime: ResearchVsTime[];
  budgetPerCommunication: BudgetPerCommunication[];
  evaluatedProcessInstanceStatusCount: EvaluatedProcessInstanceStatusCount[];
  evaluatedProcessInstanceCountByDepartment: PaginatedList<EvaluatedProcessInstanceCountByDepartment>;
  publicationData: PublicationData[];
  departmentVspublication: PaginatedList<DepartmentVsPublication>;
  budgetVsTimeData: BudgetVsTime[];
  communicationsWithStatus: CommunicationsWithStatus[];
  totalCost: number;
  totalResearchCost: number;
  totalCommunicationCost: number;

  researches: PaginatedList<Research>;

  communications: PaginatedList<Communications>;
  communicationTypeInstances: CommunicationTypeInstance[];

  researchesCount: number;
  totalCommunicationsCount: number;
  filter?: {
    users: string[];
    offices: string[];
    startDate?: Date;
    endDate?: Date;
  };

  researchEvaluations: PaginatedList<ResearchEvaluation>;
  taskAccomplishmentVsTimeCount: TaskAccomplishmentVsTimeCount[];
  publishedResearchesVsCategory: PublicationCategoriesVsProcessCount[];
  totalPublicationCategoriesCount: number;
  length: number;
}

const RESEARCH_DASHBOARD_STATE_TOKEN =
  new StateToken<ResearchDashboardStateModel>('ResearchDashboardState');

const defaults: ResearchDashboardStateModel = {
  communicationVsTime: [],
  researchVsTime: [],
  budgetPerCommunication: [],
  evaluatedProcessInstanceStatusCount: [],
  evaluatedProcessInstanceCountByDepartment: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  publicationData: [],
  budgetVsTimeData: [],
  totalCost: 0,
  totalResearchCost: 0,
  totalCommunicationCost: 0,
  communicationTypeInstances: [],

  researches: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  communications: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  researchesCount: 0,
  communicationsWithStatus: [],
  totalCommunicationsCount: 0,
  taskAccomplishmentVsTimeCount: [],
  publishedResearchesVsCategory: [],
  researchEvaluations: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  departmentVspublication: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  totalPublicationCategoriesCount: 0,
  length: 0,
};

@State<ResearchDashboardStateModel>({
  name: RESEARCH_DASHBOARD_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ResearchDashboardState {
  constructor(
    public researchDashboardService: ResearchDashboardService,
    private store: Store,
  ) {}

  @Action(GetResearchVsTime)
  getResearchVsTime(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetResearchVsTime,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getResearchData(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((researchData) => {
          patchState({
            researchVsTime: researchData,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetEvaluatedProcessInstanceStatusCount)
  getEvaluatedProcessInstanceStatusCount(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    {
      offices,
      users,
      startDate,
      endDate,
    }: GetEvaluatedProcessInstanceStatusCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getEvaluatedProcessInstanceStatusCount(
        offices,
        users,
        startDate,
        endDate,
      )
      .pipe(
        tap((data) => {
          patchState({
            evaluatedProcessInstanceStatusCount: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetEvaluatedProcessInstanceCountByDepartment)
  getEvaluatedProcessInstanceCountByDepartment(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    {
      startDate,
      endDate,
      offices,
      users,
      pageNumber,
      pageSize,
    }: GetEvaluatedProcessInstanceCountByDepartment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getEvaluatedProcessInstanceCountByDepartment(
        offices,
        users,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((data) => {
          patchState({
            evaluatedProcessInstanceCountByDepartment: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetCommunicationTimeGraph)
  getCommunicationTimeGraph(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, offices, users }: GetCommunicationTimeGraph,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getCommunicationTimeGraph(users, offices, startDate, endDate)
      .pipe(
        tap((data) => {
          patchState({
            communicationVsTime: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetCommunicationCount)
  getCommunicationCount(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    {
      startDate,
      endDate,
      offices,
      users,
      pageNumber,
      pageSize,
    }: GetCommunicationCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getCommunicationCounts(
        offices,
        users,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((data) => {
          patchState({
            communicationCount: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetResearchCount)
  getResearchCount(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    {
      offices,
      users,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    }: GetCommunicationCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getResearchCounts(
        offices,
        users,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((data) => {
          patchState({
            researchCount: data,
          });

          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetResearchPublicationData)
  getResearchPublicationData(
    { setState }: StateContext<ResearchDashboardStateModel>,
    { start, end, officeIds, userIds }: GetResearchPublicationData,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getPublicationData(start, end, officeIds, userIds)
      .pipe(
        tap((data) => {
          setState(
            patch({
              publicationData: data,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetFullLengthResearches)
  getFullLengthResearches(
    { setState }: StateContext<ResearchDashboardStateModel>,
    {
      officeIds,
      userIds,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    }: GetFullLengthResearches,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getFullLengthResearches(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((researches) => {
          setState(
            patch({
              researches: researches,
              length: researches.totalCount,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetResearchCommunications)
  getResearchCommunications(
    { setState }: StateContext<ResearchDashboardStateModel>,
    {
      officeIds,
      userIds,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    }: GetResearchCommunications,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getResearchCommications(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((communications) => {
          setState(
            patch({
              communications: communications,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetBudgetPerCommunication)
  getBudgetPerCommunication(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetBudgetPerCommunication,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getBudgetPerCommunication(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((data) => {
          patchState({
            budgetPerCommunication: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetBudgetTimeGraph)
  getBudgetTimeGraph(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, offices, users }: GetCommunicationCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .GetBudgetTimeGraph(offices, users, startDate, endDate)
      .pipe(
        tap((data) => {
          patchState({
            budgetVsTimeData: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetTotalCost)
  getTotalCost(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetTotalCost,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getTotalCost(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((data) => {
          patchState({
            totalCost: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetTotalCostResearch)
  getTotalCostResearch(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetTotalCostResearch,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getTotalResearchCost(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((data) => {
          patchState({
            totalResearchCost: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetTotalCostCommunication)
  getTotalCostCommunication(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetTotalCostCommunication,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getTotalCommunincationCost(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((data) => {
          patchState({
            totalCommunicationCost: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetResearchesCount)
  getResearchesCount(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetResearchesCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getResearchesCount(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((count) => {
          patchState({
            researchesCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetCommunicationsWithStatus)
  getCommunicationsWithStatus(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetCommunicationsWithStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getCommunicationsWithStatus(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((data) => {
          patchState({
            communicationsWithStatus: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetTotalCommunicationsCount)
  getTotalCommunicationsCount(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetTotalCommunicationsCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getTotalCommunicationsCount(startDate, endDate, officeIds, userIds)
      .pipe(
        tap((count) => {
          patchState({
            totalCommunicationsCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetCommunicationTypeInstances)
  getCommunicationTypeInstances(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, officeIds, userIds }: GetCommunicationTypeInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getCommunicationTypeInstances(officeIds, userIds, startDate, endDate)
      .pipe(
        tap((data) => {
          patchState({
            communicationTypeInstances: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetResearchEvaluations)
  getResearchEvaluations(
    { setState }: StateContext<ResearchDashboardStateModel>,
    {
      officeIds,
      userIds,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    }: GetResearchEvaluations,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getResearchEvaluations(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((evaluations) => {
          setState(
            patch({
              researchEvaluations: evaluations,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetDashboardFilter)
  setDashboardFilter(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    { filter }: SetDashboardFilter,
  ) {
    patchState({ filter });
  }

  @Action(GetTaskAccomplishmentVsTimeCount)
  getTaskAccomplishmentVsTimeCount(
    { setState }: StateContext<ResearchDashboardStateModel>,
    {
      offices,
      users,
      startDate,
      endDate,
      year,
    }: GetTaskAccomplishmentVsTimeCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getTaskAccomplishmentVsTimeData(offices, users, startDate, endDate, year)
      .pipe(
        tap((data) => {
          setState(
            patch({
              taskAccomplishmentVsTimeCount: data,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetPublishedResearchesVsCategory)
  getPublishedResearchesVsCategory(
    { setState }: StateContext<ResearchDashboardStateModel>,
    { startDate, endDate, offices, users }: GetPublishedResearchesVsCategory,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService
      .getPublishedResearchesVsCategory(offices, users, startDate, endDate)
      .pipe(
        tap((data) => {
          setState(
            patch({
              publishedResearchesVsCategory: data,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetPublicationCategoriesCount)
  getPublicationcategoriesCount({
    patchState,
  }: StateContext<ResearchDashboardStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService.getPublicationCategoriesCount().pipe(
      tap((count) => {
        patchState({
          totalPublicationCategoriesCount: count,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetDepartmentVsPublication)
  get(
    { patchState }: StateContext<ResearchDashboardStateModel>,
    {
      officeIds,
      userIds,
      startDate,
      endDate,
      pageNumber,
      pageSize,
    }: GetDepartmentVsPublication,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.researchDashboardService.getDepartmentVsPublication(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize
      )
      .pipe(
        tap((dvsp) => {
          patchState({
            departmentVspublication: dvsp,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
