<mat-sidenav-container class="container">
  <mat-sidenav-content class="processdetail">
    <div>
      <h1 class="mat-h1">{{ selectedProcessDetail?.name }}</h1>
      <p class="mat-body-2">
        {{ selectedProcessDetail?.description }}
      </p>
      <div class="example-button-row row">
        <button mat-stroked-button color="primary" *appHasPermission="hasUpdateProcessPermission()" (click)="updateProcess()">
          <span i18n="@@researches.process-detail.update-process-type">Update Process Type</span> <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-stroked-button
          color="warn"
          (click)="openConfirmationDialog()"
          *appHasPermission="hasDeleteProcessPermission()"
        >
         <span i18n="@@researches.process-detail.delete-process-type">Delete Process Type</span>  <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="openCreateStageForm()"
          *appHasPermission="hasCreateStagePermission()"
        >
          <span i18n="@@researches.process-detail.create-stage">Create Stage</span> <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div class="stages" *appHasPermission="hasGetStagesPermission()">
      <h2 class="mat-h2" i18n="@@researches.process-detail.stages">Stages</h2>
      <ng-container *ngIf="selectedProcessDetail">
        <div
          *ngIf="
            selectedProcessDetail!.stages &&
            selectedProcessDetail!.stages.length > 0
          "
          cdkDropList
          class="example-list"
          (cdkDropListDropped)="drop($event)"
        >
          <mat-card
            *ngFor="let stage of selectedProcessDetail!.stages"
            class="stage-card"
            cdkDrag
            [attr.id]="stage.id"
            [cdkDragDisabled]="selectedProcessDetail.hasProcessInstances"
          >
            <mat-card-header class="card-header">
              <mat-card-title-group class="stage-header">
                <mat-card-title class="card-title"
                  > <span i18n="@@researches.process-detail.stage">Stage</span>: {{ stage.name }}</mat-card-title
                >
                <div class="maticon">
                  <button
                    mat-icon-button
                    (click)="openDeleteConfirmationDialog(stage)"
                    *appHasPermission="hasDeleteStagePermission()"
                    [attr.data-test-id]="'researches.delete-stage.process-detail.delete-stage-icon'"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example delete icon"
                      fontIcon="delete"
                    ></mat-icon>
                  </button>

                  <button mat-icon-button (click)="openUpdateStageForm(stage)" *appHasPermission=" hasUpdateStagePermission()"
                  [attr.data-test-id]="'researches.edit-stage.process-detail.edit-stage-icon'"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="Example edit icon"
                      fontIcon="edit"
                    ></mat-icon>
                  </button>
                </div>
                <mat-card-subtitle>
                  <span i18n="@@researches.process-detail.budget">Budget:</span>
                  <span class="stage-approver-title">
                    {{
                      stage.hasBudget
                        ? placeholderLabel.canRequestBudget
                        : placeholderLabel.canNotRequestBudget
                    }}
                  </span>
                  <div class="">
                    <span i18n="@@researches.process-detail.approximate-duration-in-weeks">Approximate Duration (in weeks):</span>
                    <span class="stage-approver-title">
                      {{ stage.approximateDurationInWeeks }}
                    </span>
                  </div>
                </mat-card-subtitle>
              </mat-card-title-group>

            </mat-card-header>
            <mat-card-content>
              <p class="mat-body">
                {{ stage.description }}
              </p>
            </mat-card-content>
            <mat-card-actions *ngIf="stage.hasEvaluation">
              <button
                mat-stroked-button
                color="primary"
                (click)="viewCriteria(stage)"
                *appHasPermission="hasGetCriterionPermission()"
                i18n="@@researches.process-detail.view-criteria"
                [attr.data-test-id]="'researches.view-criteria.process-detail.view-criteria-button'"

              >
                View Criteria
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
        <div *ngIf="selectedProcessDetail!.stages?.length === 0" i18n="@@researches.process-detail.no-stages-found">
          No stages found.
        </div>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
