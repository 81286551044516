import { Office } from 'src/app/offices/models/office.model';
import { User } from 'src/app/users/models/user.model';

export interface ExternalDocument {
  source: string;
  documentNumber: string;
  subject: string;
  description: string;
}

export interface ExternalDocumentDto {
  id: string;
  source: string;
  referenceNumber: string;
  subject: string;
  description: string;
  creatorId: string;
  creatorOfficeId: string;
  remarkActions: RemarkActionDto[];
  files: AFileDto[];
  officesNotified: string[];
  usersNotified: string[];
  responses?: ResponseDto[];
}
export interface ExternalDocumentResponseDto {
  id: string;
  subject: string;
  creatorId: string;
  creatorOfficeId: string;
  status: string;
  destinationOffice: string;
  notifiedOfficesIds: string[];
  notifiedUsersIds: string[];
  remarkActions: RemarkActionDto[];
  files?: AFileDto[];
  body?: string;
}
export interface ExternalDocumentDetailDto {
  id: string;
  source: string;
  referenceNumber: string;
  subject: string;
  description: string;
  created: string;
  creatorId: string;
  creatorOfficeId: string;
  remarkActions: RemarkActionDetailDto[];
  files: AFileDto[];
  officesNotified: string[];
  usersNotified: string[];
  creatorUser: User;
  creatorOffice: Office;
  responses?: ResponseDto[];
}

export interface ExternalDocumentResponseDetailDto {
  id: string;
  subject: string;
  created: string;
  creatorId: string;
  creatorOfficeId: string;
  status: string;
  destinationOffice: string;
  notifiedOfficesIds: string[];
  notifiedUsersIds: string[];
  remarkActions: RemarkActionDetailDto[];
  files?: AFileDto[];
  body?: string;
  creatorOffice: Office;
  user: User;
}

export interface RemarkActionDetailDto {
  userId: string;
  officeId: string;
  message: string;
  remarkStatus: string;
  submissionDate: string; //date
  nextOfficeId: string;
  submittedByUser?: User;
  userOffice?: Office;
  toOffice?: Office;
  toOfficeId?: string;
}

export interface RemarkActionDto {
  userId: string;
  officeId: string;
  message: string;
  submissionDate: string; //date
  toOfficeId: string;
}
export interface AFileDto {
  id: string;
  name: string;
  uploadedById: string;
  fileSize: string;
  created: string;
  lastModified: string; //date
  lastModifiedById: string; //date
}

export interface ResponseDto {
  id: string;
  subject: string;
  creatorId: string;
  creatorOfficeId: string;
  status: string;
  destinationOffice: string;
  notifiedOfficesIds: string[];
  notifiedUsersIds: string[];
  remarkActions: RemarkActionDto[];
  files?: AFileDto[];
  body?: string;
  creatorOffice?: Office;
  user?: User;
}

export interface SendRemarkDto {
  remark: string;
  toOfficeId: string;
  status: RemarkStatus;
}

export enum RemarkStatus {
  Approved = 'Approved',
  RollBacked = 'RollBacked',
  Rejected = 'Rejected',
  Received = 'Received',
}

export interface RemarkActionStatusForDisplay {
  id: string;
  remarkActionStatusForDisplay: string;
  remarkActionStatusEnum: string;
}

export enum ResponseStatus {
  Complete = 'Completed',
  Draft = 'Draft',
}
