<mat-list role="list">
  <mat-list-item
    role="listitem"
    *ngFor="let validation of validations$ | async; let i = index"
  >
    <mat-icon matListItemIcon>check_box_outline_blank</mat-icon>
    <div matListItemTitle>Validation rule {{ i + 1 }}</div>
    <div matListItemLine>
      {{ validation.leftField.name }}
      <strong>{{ validation.workflowTypeStepValidationRule.name }}</strong>
      {{ validation.rightField.name }}
    </div>
    <div matListItemMeta>
      <button mat-icon-button (click)="updateValidation(validation)" *appHasPermission="hasUpdateWorflowStepValidationPermission()"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button color="warn" (click)="deleteValidation(validation)" *appHasPermission="hasDeleteWorflowStepValidationPermission()"><mat-icon>delete</mat-icon></button>
    </div>
  </mat-list-item>
</mat-list>
