import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UserFacade } from '../../facade/user.facade';
import { RxState } from '@rx-angular/state';
import { Observable, debounceTime, take } from 'rxjs';
import { Router } from '@angular/router';
import {
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  USERS_ROUTE,
} from 'src/app/core/constants/routes';
import { ChangePasswordRequest } from '../../models/change-password-request.model';
import { AuthFacade } from 'src/app/auth/facade/auth.facade';

interface ChangePasswordComponentState {
  changePasswordSuccess: boolean;
}

const initChangePasswordComponentState: Partial<ChangePasswordComponentState> =
  {
    changePasswordSuccess: false,
  };

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [RxState],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  changePasswordForm: FormGroup;

  changePasswordSuccess$: Observable<boolean> = this.state.select(
    'changePasswordSuccess',
  );
  hideCurrentPassword = true;
  hideNewPassword = true;
  isFirstTimeLogin = false;

  confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newPassword =
        this.changePasswordForm?.controls['newPassword'].value;
      if (control.value && control.value != newPassword) {
        return { confirmPasswordDoNotMatch: true };
      }
      return null;
    };
  }

  constructor(
    private fb: NonNullableFormBuilder,
    private userFacade: UserFacade,
    private state: RxState<ChangePasswordComponentState>,
    private router: Router,
    private authFacade: AuthFacade
  ) {
    this.state.set(initChangePasswordComponentState);
    this.state.connect(
      'changePasswordSuccess',
      userFacade.isChangePasswordSuccessful$,
    );

    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.passwordPattern),
        ],
      ],
      confirmPassword: [
        '',
        [Validators.required, this.confirmPasswordValidator()],
      ],
    });
  }

  ngOnInit(): void {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      this.isFirstTimeLogin = user!.isFirstTimeLogin;
    })
    this.changePasswordSuccess$
      .subscribe((result) => {
        if (result) {
          if (this.isFirstTimeLogin) {
            this.authFacade.dispatchLogout();
          }
          this.router.navigate([LOGIN_ROUTE]);
        }
      });
  }
  ngOnDestroy(): void {
    this.userFacade.dispatchResetChangePasswordStatus();
  }

  toggleVisibility(field: string) {
    if (field === 'currentPassword') {
      this.hideCurrentPassword = !this.hideCurrentPassword;
    } else if (field === 'newPassword') {
      this.hideNewPassword = !this.hideNewPassword;
    }
  }
  currentAndNewPasswordEqualityValidator(control: AbstractControl) {
    if (!control.parent) {
      return null;
    }
    const currentPassword = control.parent.get('currentPassword')?.value;
    const newPassword = control.value;

    if (currentPassword === newPassword) {
      return { samePassword: true };
    }

    return null;
  }

  changePassword(event: Event) {
    event.preventDefault();
    if (this.changePasswordForm.valid) {
      const changePasswordRequest: ChangePasswordRequest = {
        currentPassword:
          this.changePasswordForm?.controls['currentPassword'].value,
        newPassword: this.changePasswordForm?.controls['newPassword'].value,
      };

      this.userFacade
        .dispatchChangePassword(changePasswordRequest)
        .pipe(debounceTime(500))
        .subscribe();
    }
  }

  changeOldPasswordVisibility() {
    this.hideCurrentPassword = !this.hideCurrentPassword;
  }

  changeNewPasswordVisibility() {
    this.hideNewPassword = !this.hideNewPassword;
  }
}
