import { createPropertySelectors } from '@ngxs/store';
import {
  FieldValidationRuleState,
  FieldValidationRuleStateModel,
} from './field-validation-rules.state';

export class FieldValidationRuleSelector {
  static slices = createPropertySelectors<FieldValidationRuleStateModel>(
    FieldValidationRuleState,
  );
}
