<div >
  <h1 class="mat-h1" i18n="@@documents.submitted-document-forms.submitted-forms">Submitted Forms</h1>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedDocumentForms($event)"
  >
  </mat-paginator>
  
  <ng-container
*ngIf="documentForms.length > 0; else emptyDocumentTemplateList"
>
  <mat-table
  [dataSource]="dataSource"
  class="table"
>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n="@@documents.submitted-document-forms.name">Name</mat-header-cell>
    <mat-cell *matCellDef="let template">{{ template.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef i18n="@@documents.submitted-document-forms.status">Status</mat-header-cell>
    <mat-cell *matCellDef="let template">{{
      template.isArchived ? "Archived" : template.status.status
    }}
  </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let template">
      <button mat-button class="button" (click)="onRowClick($event, template)" color="primary"
      i18n="@@documents.submitted-document-forms.view-detail">
        View Detail
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let template; columns: displayedColumns"></mat-row>
</mat-table>
</ng-container>
 
<ng-template #emptyDocumentTemplateList>
  <div class="empty-document-template-list">
  <span class="text-2xl" i18n="@@documents.submitted-document-forms.no-forms-to-approve">No forms to Approve</span>
  </div>
  </ng-template>
</div>