import {
  InitiateTor,
  GetPaginatedTor,
  SetPageIndexAndSize,
  DeleteTor,
  DetailTor,
  SelectedTor,
  UpdateTor,
  SetSelectedTorDescription,
  AddCostBreakdownItem,
  GetCostBreakdownItems,
  SetSelectedCostBreakdownItem,
  SetUpdateCostBreakdownItemStatus,
  UpdateCostBreakdownItem,
  DownloadTorPdf,
  DeleteCostBreakdownItem,
  ChangeTorStatus,
  GetTorComments,
  SetSelectedTorComment,
  AddTorComment,
  SetTorCommentUpdatingMode,
  EditTorComment,
  DeleteTorComment,
  SetTorCommentReplyingMode,
  ReplyToTorComment,
  GetCurrencyList,
  UpdateTorCommentReply,
  ResetSelectedReplyComment,
  SetSelectedReplyComment,
  SetTorReplyUpdatingMode,
  DeleteTorCommentReply
} from '../store/tor.action';
import { TorService } from '../services/tor.services';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TorPdfFile, Tor, TorComment, TorDetail, TorCommentReply } from '../models/tor.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { filter, take, tap } from 'rxjs';
import {
  append,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { CostBreakdownItem } from '../models/cost-breakdown';

export interface TorStateModel {
  tors: Tor[];
  currency: any[];
  paginatedTors: PaginatedList<Tor> | null;
  totalCount: number;
  pageIndex: number;
  pageSize: number;
  initiatedTor: Tor | undefined;
  selectedTor: TorDetail | undefined;
  selectTor: Tor | undefined;
  selectedTorDescription: TorDetail | undefined;
  isEditDescription: boolean;
  torDescription: TorDetail | undefined;
  updateCostBreakdownItem: boolean;
  costBreakdownItems: PaginatedList<CostBreakdownItem>;
  selectedCostBreakdownItem: CostBreakdownItem | undefined;
  torComments?: PaginatedList<TorComment>;
  selectedTorComment?: TorComment;
  isUpdatingComment?: boolean;
  isReplyingToComment?: boolean;
  selectedTorprocessInstanceOwnerId?:string;
  isReplyTorComment?: boolean,
  isUpdatingReply?: boolean;
  selectedReply?: TorComment | null,
}

const TOR_STATE_TOKEN = new StateToken<TorStateModel>('torState');

const defaults: TorStateModel = {
  tors: [],
  currency: [] ,
  paginatedTors: null,
  totalCount: 0,
  pageIndex: 10,
  pageSize: 10,
  initiatedTor: undefined,
  selectedTor: undefined,
  selectTor: undefined,
  selectedTorDescription: undefined,
  isEditDescription: false,
  torDescription: undefined,
  updateCostBreakdownItem: false,
  costBreakdownItems: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedCostBreakdownItem: undefined,
};

@State<TorStateModel>({
  name: TOR_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class TorState {
  constructor(
    public torService: TorService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(SetPageIndexAndSize)
  setPageSizeandIndex(
    { patchState }: StateContext<TorStateModel>,
    { index, size }: SetPageIndexAndSize,
  ) {
    patchState({
      pageIndex: index,
      pageSize: size,
    });
  }

  @Action(GetCurrencyList)
  getCurrencyList(
    { setState }: StateContext<TorStateModel>,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .getCurrencyList()
      .pipe(
        tap((currency) => {
          setState(
            patch({
              currency: currency,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetPaginatedTor)
  getPaginatedTor(
    { patchState }: StateContext<TorStateModel>,
    { pageNumber, pageSize, grouped }: GetPaginatedTor,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.getPaginatedTor(pageNumber, pageSize, grouped).pipe(
      tap((tors) => {
        patchState({
          tors: tors.items,
          totalCount: tors.totalCount,
          paginatedTors: tors,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(InitiateTor)
  createTor(
    { setState }: StateContext<TorStateModel>,
    { stageInstanceId , currency }: InitiateTor,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.intiateTor(stageInstanceId , currency).pipe(
      tap((initiatedTor: Tor) => {
        setState(
          patch({
            tors: insertItem(initiatedTor),
            initiatedTor: initiatedTor,
          }),
        );
        this.operationStatus.displayStatus($localize`:@@researches.tor-state.tor-created-successfully:Tor Created Successfully!`);
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteTor)
  deleteTor(
    { setState, getState }: StateContext<TorStateModel>,
    { torId }: DeleteTor,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.deleteTor(torId).pipe(
      tap(() => {
        setState(
          patch({
            tors: removeItem((t) => t.id === torId),
            totalCount: getState().totalCount - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.tor-deleted-successfully:Tor deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DetailTor)
  detailTor(
    { setState }: StateContext<TorStateModel>,
    { torDetailId }: DetailTor,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.detailTor(torDetailId).pipe(
      tap((tor) => {
        setState(
          patch({
            selectedTor: tor,
            selectedTorprocessInstanceOwnerId:tor.processInstanceOwnerId
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(SelectedTor)
  selectedTor(
    { patchState }: StateContext<TorStateModel>,
    { selectedTor }: SelectedTor,
  ) {
    patchState({
      selectTor: selectedTor,
    });
  }

  @Action(UpdateTor)
  updateTor(
    { setState }: StateContext<TorStateModel>,
    { id, description }: UpdateTor,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.updateTorDescription(id, description).pipe(
      tap((updateTor) => {
        setState(
          patch({
            selectedTorDescription: updateTor,
            isEditDescription: false,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.description-updated-successfully:Description Updated Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedTorDescription)
  setSelectedTorDescription(
    { patchState }: StateContext<TorStateModel>,
    { selectedTorDescription, typeOfAction }: SetSelectedTorDescription,
  ) {
    patchState({
      selectedTorDescription: selectedTorDescription,
      isEditDescription: typeOfAction === 'edit',
    });
  }

  @Action(AddCostBreakdownItem)
  addCostBreakdownItem(
    { setState, getState }: StateContext<TorStateModel>,
    { torId, costBreakdownItem }: AddCostBreakdownItem,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().costBreakdownItems.totalCount;
    let pageSize =
      getState().costBreakdownItems.totalCount /
      getState().costBreakdownItems.totalPages;
    return this.torService.addCostBreakdownItem(torId, costBreakdownItem).pipe(
      tap((costBreakdownItem: CostBreakdownItem) => {
        setState(
          patch({
            costBreakdownItems: patch({
              items: insertItem(costBreakdownItem),
              totalCount: (state) => prevLength + 1,
              totalPages: (state) => Math.ceil((prevLength + 1) / pageSize),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.cost-breakdown-item-added-successfully:Cost Breakdown Item added successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetCostBreakdownItems)
  getCostBreakdownItems(
    { setState }: StateContext<TorStateModel>,
    { torId, pageNumber, pageSize }: GetCostBreakdownItems,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .getCostBreakdownItems(torId, pageNumber, pageSize)
      .pipe(
        tap((costBreakdownItems) => {
          setState(
            patch({
              costBreakdownItems: costBreakdownItems,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DownloadTorPdf)
  downloadPdf(
    { patchState }: StateContext<TorStateModel>,
    { torId }: DownloadTorPdf,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .downloadTorPdf(torId)
      .subscribe(async (pdfFile: TorPdfFile) => {
        const base64Content = pdfFile.fileContent;
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: pdfFile.fileType });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = pdfFile.fileName;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(SetSelectedCostBreakdownItem)
  setSelectedCostBreakdownItem(
    { patchState }: StateContext<TorStateModel>,
    { selectedCostBreakdownItem }: SetSelectedCostBreakdownItem,
  ) {
    patchState({
      selectedCostBreakdownItem: selectedCostBreakdownItem,
    });
  }

  @Action(SetUpdateCostBreakdownItemStatus)
  setUpdateCostBreakdownItemStatus(
    { patchState }: StateContext<TorStateModel>,
    { updateCostBreakdownItemStatus }: SetUpdateCostBreakdownItemStatus,
  ) {
    patchState({
      updateCostBreakdownItem: updateCostBreakdownItemStatus,
    });
  }

  @Action(UpdateCostBreakdownItem)
  updateCostBreakdownItem(
    { patchState, getState }: StateContext<TorStateModel>,
    { torId, costBreakdownItemId, costBreakdownItem ,  }: UpdateCostBreakdownItem,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .updateCostBreakdownItem(torId, costBreakdownItemId, costBreakdownItem)
      .pipe(
        filter((costBreakdownItem) => costBreakdownItem !== null),
        take(1),
        tap((costBreakdownItem) => {
          let costBreakdownItems = getState().costBreakdownItems.items;
          let filteredCostBreakdownItems = [
            costBreakdownItem,
            ...costBreakdownItems.filter(
              (item) => item.id !== costBreakdownItemId,
            ),
          ];
          const costBreakdownItemsCpy = {
            ...getState().costBreakdownItems , 
            items:filteredCostBreakdownItems
          }
          patchState({
              costBreakdownItems: costBreakdownItemsCpy,
              selectedCostBreakdownItem: costBreakdownItem,
              updateCostBreakdownItem: false,
          });     
          this.operationStatus.displayStatus(
            $localize`:@@researches.tor-state.cost-breakdown-item-updated-successfully:Cost Breakdown Item updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteCostBreakdownItem)
  deleteCostBreakdownItem(
    { setState, getState }: StateContext<TorStateModel>,
    { torId, costBreakdownItemId }: DeleteCostBreakdownItem,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .deleteCostBreakdownItem(torId, costBreakdownItemId)
      .pipe(
        tap(() => {
          let totalCount = getState().costBreakdownItems.totalCount - 1;
          setState(
            patch({
              costBreakdownItems: patch({
                items: removeItem((item) => item.id === costBreakdownItemId),
                totalCount: totalCount,
              }),
              selectedCostBreakdownItem: undefined,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.tor-state.cost-breakdown-item-deleted-successfully:Cost Breakdown Item deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ChangeTorStatus)
  changeStatus(
    { patchState, getState }: StateContext<TorStateModel>,
    { statusChange }: ChangeTorStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.changeStatus(statusChange).pipe(
      tap(() => {
        this.store.dispatch(new SetProgressOff());
        let { id, status } = statusChange;
        let updatedTor = getState().selectedTor;
        if (updatedTor && updatedTor.status) {
          updatedTor.status = status;
        }
        patchState({
          selectedTor: updatedTor,
        });
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.tor-status-updated-successfully:TOR Status Updated Successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(GetTorComments)
  getTorComments(
    { patchState }: StateContext<TorStateModel>,
    { pageNumber, pageSize, torId }: GetTorComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.getTorComments(torId, pageNumber, pageSize).pipe(
      tap((comments) => {
        patchState({
          torComments: comments,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedTorComment)
  setSelectedWorkflowComment(
    { setState }: StateContext<TorStateModel>,
    { selectedTorComment }: SetSelectedTorComment,
  ) {
    setState(
      patch({
        selectedTorComment: selectedTorComment,
      }),
    );
  }

  @Action(AddTorComment)
  addTorComment(
    { setState }: StateContext<TorStateModel>,
    { torId, content }: AddTorComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.addTorComment(torId, content).pipe(
      tap((comment) => {
        setState(
          patch({
            torComments: patch({
              items: append([comment]),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.comment-added-successfully: Comment Added Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetTorCommentUpdatingMode)
  setTorCommentUpdatingMode(
    { patchState }: StateContext<TorStateModel>,
    { isUpdatingComment }: SetTorCommentUpdatingMode,
  ) {
    patchState({
      isUpdatingComment: isUpdatingComment,
    });
  }

  @Action(EditTorComment)
  editTorComment(
    { setState }: StateContext<TorStateModel>,
    { torCommentId, content }: EditTorComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.editTorComment(torCommentId, content).pipe(
      tap((comment) => {
        setState(
          patch({
            torComments: patch({
              items: updateItem<TorComment>(
                (item) => item.id === torCommentId,
                comment,
              ),
            }),
            isUpdatingComment: false,
            selectedTorComment: undefined,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.comment-updated-successfully: Comment Updated Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteTorComment)
  deleteTorComment(
    { setState }: StateContext<TorStateModel>,
    { torCommentId }: DeleteTorComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.deleteTorComment(torCommentId).pipe(
      tap(() => {
        setState(
          patch({
            torComments: patch({
              items: (torComments) =>
                torComments.filter(
                  (torComment) => torComment.id !== torCommentId,
                ),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.comment-removed-successfully: Comment Removed Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetTorCommentReplyingMode)
  setTorCommentReplyingMode(
    { patchState }: StateContext<TorStateModel>,
    { isReplyingToComment }: SetTorCommentReplyingMode,
  ) {
    patchState({
      isReplyingToComment: isReplyingToComment,
    });
  }

  @Action(ReplyToTorComment)
  replyToTorComment(
    { setState, getState }: StateContext<TorStateModel>,
    { content, commentId }: ReplyToTorComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.replyToTorComment(content, commentId).pipe(
      tap((reply) => {
        const selectedTorComment = getState().selectedTorComment;
        selectedTorComment?.replies.push(reply);
        setState(
          patch({
            selectedTorComment: selectedTorComment,
            isReplyingToComment: false,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.reply-added-successfully: Reply Added Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(UpdateTorCommentReply)
  updateTorCommentReply(
    { setState, getState }: StateContext<TorStateModel>,
    { torCommentId, commentId, content }: UpdateTorCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService
      .updateTorCommentReply(torCommentId, commentId, content)
      .pipe(
        tap((torComment) => {
          const selectedTorComment = getState().selectedTorComment;
          const selectedReplyCommentt = getState().selectedReply;
          selectedReplyCommentt!.content = torComment.content;
          setState(
            patch({
              selectedTorComment: selectedTorComment,
              isUpdatingReply: false,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.tor.reply-updated-successfully: Reply updated successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedReplyComment());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResetSelectedReplyComment)
  resetSelectedReplyComment(
    { setState }: StateContext<TorStateModel>,
    {}: ResetSelectedReplyComment,
  ) {
    setState(
      patch({
        selectedReply: null,
      }),
    );
  }

  @Action(SetSelectedReplyComment)
  setSelectedReplyComment(
    { patchState }: StateContext<TorStateModel>,
    { comment }: SetSelectedReplyComment,
  ) {
    patchState({
      selectedReply: comment,
    });
  }

  @Action(SetTorReplyUpdatingMode)
  setTorReplyUpdatingMode(
    { patchState }: StateContext<TorStateModel>,
    { isUpdatingReply }: SetTorReplyUpdatingMode,
  ) {
    patchState({
      isUpdatingReply: isUpdatingReply,
    })

  }

  @Action(DeleteTorCommentReply)
  deleteTorCommentReply(
    { setState, getState }: StateContext<TorStateModel>,
    { torCommentId, commentId }: DeleteTorCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.torService.deleteTorCommentReply(torCommentId, commentId).pipe(
      tap(() => {
        const state = getState();
        const parentCommentIndex = state.torComments?.items.findIndex(
          (comment) => comment.id === torCommentId,
        );
        if(parentCommentIndex !== -1) {
          const updatedReplies = state.torComments?.items[parentCommentIndex!]
          .replies.filter((reply) => reply.id !== commentId);
          const updatedComments = [...state.torComments?.items!];
          updatedComments[parentCommentIndex!] = {
            ...updatedComments[parentCommentIndex!],
            replies: updatedReplies!,
          };
          setState(
            patch( {
              torComments: patch({
                items: updatedComments,
              }),
            }),
          );
        }
        this.operationStatus.displayStatus(
          $localize`:@@researches.tor-state.comment-removed-successfully: Comment Removed Successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
