import {
  CreateWorkflowTypes,
  WorkflowTypes,
} from '../models/workflow-types.model';
export class GetWorkflowTypes {
  static readonly type = `[WorkflowTypes] ${GetWorkflowTypes.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetWorkflowTypesWithSteps {
  static readonly type = `[WorkflowTypesWithSteps] ${GetWorkflowTypesWithSteps.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetOwnedWorkflowTypes {
  static readonly type = `[WorkflowTypes] ${GetOwnedWorkflowTypes.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}
export class CreateWorkflowType {
  static readonly type = `[WorkflowTypes] ${CreateWorkflowType.name}`;
  constructor(public workflowType: CreateWorkflowTypes) {}
}

export class UpdateWorkflowType {
  static readonly type = `[WorkflowTypes] ${UpdateWorkflowType.name}`;
  constructor(public workflowType: CreateWorkflowTypes) {}
}

export class SetSelectedWorkflowType {
  static readonly type = `[WorkflowTypes] ${SetSelectedWorkflowType.name}`;
  constructor(public workflowType: WorkflowTypes) {}
}

export class SetUpdateStatus {
  static readonly type = `[WorkflowTypes] ${SetUpdateStatus.name}`;
  constructor(public updateStatus: boolean) {}
}

export class DeleteWorkflowType {
  static readonly type = `[WorkflowTypes] ${DeleteWorkflowType.name}`;
  constructor(public workflowTypeId: string) {}
}

export class SearchWorkflowTypes {
  static readonly type = `[DocumentTemplate] ${SearchWorkflowTypes.name}`;
  constructor(
    public workflowTypeName: string,
    public isOwned: boolean,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class SetWorkflowTypeSearchingMode {
  static readonly type = `[DocumentTemplate] ${SetWorkflowTypeSearchingMode.name}`;
  constructor(public isSearchingWorkflowType: boolean) {}
}
