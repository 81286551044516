
<form class="flex-row" [formGroup]="tableForm">

<mat-form-field class="full-width" appearance="outline">
  <mat-label i18n="@@documents.list-table-builder.table-name">Table name</mat-label>
  <input
    formControlName="tableName"
    matInput
    placeholder="Table Name"
    (focus)="focusedInput = 'tableName'"

  />
  <mat-error *ngIf="tableForm.get('tableName')?.errors && tableForm.get('tableName')?.errors?.['required']">
    Table name is required.
  </mat-error>
  <mat-error *ngIf="tableForm.get('tableName')?.errors && tableForm.get('tableName')?.errors?.['invalid']">
    Table name is invalid.
  </mat-error>
</mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@documents.list-table-builder.columns">columns</mat-label>
    <mat-select formControlName="columns" multiple #select >
      @for (item of selectedFormFields; track item.id) {
        <mat-option  [value]="item">{{item.name}}</mat-option>
      }
    </mat-select>
    <mat-error *ngIf="tableForm.get('columns')?.errors && tableForm.get('columns')?.errors?.['atLeastOneRequired']">
      At least one column is required.
    </mat-error>
  </mat-form-field>


</form>
<button
  mat-stroked-button
  color="primary"
  (click)="createTableCluster()"
>
  <mat-icon>add</mat-icon>
  <span i18n="@@documents.list-table-builder.create-table"> Create Table </span>
</button>
<div *ngIf="createdColumnsTitle.size > 0; else noData" class="table-container">
  <p> <span i18n="@@documents.list-table-builder.table"> Table </span> {{groupName}} <span i18n="@@documents.list-table-builder.preview"> preview </span></p>
  <table  mat-table class="mat-elevation-z2">
    <ng-container *ngFor="let column of createdColumnsTitle" matColumnDef="{{column}}">
      <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="createdColumnsTitle"></tr>
  </table>
</div>
<ng-template #noData>
  <p i18n="@@documents.list-table-builder.create-table-to-preview">
    create table to preview
  </p>
</ng-template>

<button mat-button matStepperPrevious (click)="previousClicked()" i18n="@@documents.list-table-builder.back">Back</button>
<button mat-button matStepperNext i18n="@@documents.list-table-builder.next" data-test-id="document.list-table-builder.next-button">Next</button>
