import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, take } from 'rxjs';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import {
  Criterion,
  UpdateCriterionEvaluation,
} from 'src/app/researches/models/criterion.model';
import {
  Evaluation,
  StageInstanceDetail,
  StageInstanceEvaluation,
  UserStageInstanceEvaluation,
} from 'src/app/researches/models/stage-instance-detail.model';
import { EvaluationScheduleFacade } from 'src/app/researches/research-council/facades/schedule-evalution.facades';
import {
  EvaluationDetail,
  EvaluationResult,
} from 'src/app/researches/research-council/models/evaluation.model';

interface EvaluationFormState {
  selectedStageInstanceDetail?: StageInstanceDetail;
  evaluation?: StageInstanceEvaluation;
  isUpdatingEvaluation?: boolean;
  evaluationDetail?: EvaluationDetail;
}

const initaialEvaluationFormState: EvaluationFormState = {};

@Component({
  selector: 'app-evaluation-form',
  templateUrl: './evaluation-form.component.html',
  styleUrls: ['./evaluation-form.component.scss'],
  providers: [RxState],
})
export class EvaluationFormComponent implements OnInit {
  userStageEvaluations: UserStageInstanceEvaluation | undefined;
  evaluationsForm = this.fb.group({
    rows: this.fb.array<FormControl<number | null>>([]),
  });

  displayedColumns: string[] = ['criterion', 'weight', 'value'];
  dataSource: Criterion[] = [];
  evaluation$: Observable<StageInstanceEvaluation | undefined> =
    this.state.select('evaluation');
  isStageEvaluated$ = this.stageInstanceDetailFacade.isStageEvaluated$;

  isUpdatingEvaluation$: Observable<boolean | undefined> = this.state.select(
    'isUpdatingEvaluation',
  );
  isUpdatingEvaluation?: boolean;

  evaluationDetail$ = this.state.select('evaluationDetail');
  myEvaluation: EvaluationResult[] = [];

  createEditToggle = {
    value: {
      title: $localize`:@@researches.evaluation-form.create-evaluation: Create Evaluation`,
      submitButton: $localize`:@@researches.evaluation-form.evaluate: Evaluate`,
    },
    create: {
      title: $localize`:@@researches.evaluation-form.create-evaluation: Create Evaluation`,
      submitButton: $localize`:@@researches.evaluation-form.evaluate: Evaluate`,
    },
    update: {
      title: $localize`:@@researches.evaluation-form.update-evaluation: Update Evaluation`,
      submitButton: $localize`:@@researches.evaluation-form.update: Update`,
    },
  };
  constructor(
    private fb: FormBuilder,
    private stageFacade: StageFacade,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private state: RxState<EvaluationFormState>,
    private dialog: MatDialog,
    private router: Router,
    private evaluationScheduleFacade: EvaluationScheduleFacade,
    @Inject(MAT_DIALOG_DATA) public data: { stageInstanceId: string },
  ) {
    this.state.set(initaialEvaluationFormState);
    this.state.connect(
      'selectedStageInstanceDetail',
      this.stageInstanceDetailFacade.selectedStageInstanceDetail$,
    );
    this.state.connect(
      'evaluation',
      this.stageInstanceDetailFacade.StageInstanceEvaluations$,
    );
    this.state.connect(
      'isUpdatingEvaluation',
      this.stageInstanceDetailFacade.isUpdatingEvaluation$,
    );
    this.state.connect(
      'evaluationDetail',
      this.evaluationScheduleFacade.evaluationDetail$,
    );
  }

  ngOnInit(): void {
    this.stageInstanceDetailFacade.dispatchGetStageInstanceDetail(
      this.data.stageInstanceId,
    );

    this.stageInstanceDetailFacade.userStageEvalutions$.subscribe(
      (evalutions) => {
        this.userStageEvaluations = evalutions;
      },
    );
    this.state.select('selectedStageInstanceDetail').subscribe((val) => {
      this.dataSource = (val as any).criterionInstances;
      this.evaluationScheduleFacade.dispatchGetEvaluationDetail(
        val?.id as string,
      );
    });
    this.evaluationDetail$.subscribe((evaluationDetail) => {
      this.myEvaluation =
        evaluationDetail?.myEvaluationResult as EvaluationResult[];
    });

    this.stageInstanceDetailFacade.isUpdatingEvaluation$.subscribe(
      (isUpdatingEvaluation) => {
        this.isUpdatingEvaluation = isUpdatingEvaluation;
      },
    );

    this.dataSource.forEach((item) => {
      this.evaluationsForm.controls.rows.push(
        this.fb.control(null, [
          Validators.required,
          Validators.min(0),
          Validators.max(item.weight),
        ]),
      );
    });

    if (this.isUpdatingEvaluation) {
      this.createEditToggle.value = this.createEditToggle.update;
      let pairs = this.myEvaluation.map((evaluation) => evaluation.value);
      this.evaluationsForm.controls.rows.setValue(pairs);
    }
  }

  submitEvaluation() {
    if (this.evaluationsForm.valid) {
      const evaluations = this.evaluationsForm.controls.rows.value.map(
        (item, index) => {
          const existingEvaluation =
            this.userStageEvaluations?.evaluations[index];
          return {
            id: existingEvaluation?.id,
            criterionId: this.dataSource[index]!.id!,
            value: item!,
          };
        },
      );
      const updatedEvaluation: UpdateCriterionEvaluation[] =
        this.evaluationsForm.controls.rows.value.map((item, index) => {
          const existingEvaluation = this.myEvaluation[index];
          return {
            id: existingEvaluation?.id,
            value: item,
          } as UpdateCriterionEvaluation;
        });
      let submission;
      if (this.isUpdatingEvaluation) {
        submission =
          this.stageInstanceDetailFacade.dispatchUpdateStageEvaluation(
            this.data.stageInstanceId,
            updatedEvaluation,
          );
      } else {
        submission = this.stageFacade.dispatchEvaluateStage(
          this.data.stageInstanceId,
          evaluations,
        );
      }
      this.state.select('selectedStageInstanceDetail').subscribe((val) => {
        this.dataSource = (val as any).criterionInstances;
        this.evaluationScheduleFacade.dispatchGetEvaluationDetail(
          val?.id as string,
        );
      });
      this.isStageEvaluated$.subscribe((isEvaluated) => {
        if (isEvaluated) {
          this.router.navigate([
            `/stage-instances/${this.data.stageInstanceId}`,
          ]);
        }
      });

      submission.pipe(take(1)).subscribe(() => {
        this.evaluationScheduleFacade.dispatchGetEvaluationDetail(
          this.data.stageInstanceId,
        );
      });
      this.dialog.closeAll();
    }
  }

  hasCreateEvaluationPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Evaluation.Feature,
      PERMISSION_NAMES.Researches.Evaluation.CreateEvaluation,
    );
  }
}
