import { Injectable } from '@angular/core';
import { QuantityType } from '../models/quantityType.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ACTIVITY_TYPES_URL,
  QUANTITY_TYPES_URL,
} from 'src/app/core/constants/api-endpoints';
import { ActivityType } from '../models/activityType.model';

@Injectable({
  providedIn: 'root',
})
export class TorConfigurationService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  GetQuantityTypes() {
    return this.http.get<QuantityType[]>(`${QUANTITY_TYPES_URL}`);
  }

  GetActivityTypes() {
    return this.http.get<ActivityType[]>(`${ACTIVITY_TYPES_URL}`);
  }

  DeleteQuantityType(id: string) {
    return this.http.delete<any>(`${QUANTITY_TYPES_URL}/${id}` );
  }

  DeleteActivityType(id: string) {
    return this.http.delete<any>(`${ACTIVITY_TYPES_URL}/${id}`);
  }

  PostActivityType(formGroup: any) {
    return this.http.post<ActivityType>(
      `${ACTIVITY_TYPES_URL}`,
      formGroup,
      this.httpOptions,
    );
  }

  PostQuantityType(formGroup: any) {
    return this.http.post<QuantityType>(
      `${QUANTITY_TYPES_URL}`,
      formGroup,
      this.httpOptions,
    );
  }

  UpdateActivity(activityType: ActivityType) {
    return this.http.put<any>(
      `${ACTIVITY_TYPES_URL}`,
      activityType,
      this.httpOptions,
    );
  }

  UpdateQuantity(quantityType: QuantityType) {
    return this.http.put<any>(
      `${QUANTITY_TYPES_URL}`,
      quantityType,
      this.httpOptions,
    );
  }
}
