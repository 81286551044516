import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowTypes } from '../models/workflow-types.model';
import { WORKFLOW_TYPES_URL } from 'src/app/core/constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTypesService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getWorkflowTypes(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<WorkflowTypes>> {
    let url = WORKFLOW_TYPES_URL;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<WorkflowTypes>>(url);
  }

  getWorkflowTypesWithSteps(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<WorkflowTypes>> {
    let url = WORKFLOW_TYPES_URL + '/with-steps';
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<WorkflowTypes>>(url);
  }
  getOwnedWorkflowTypes(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<WorkflowTypes>> {
    return this.http.get<PaginatedList<WorkflowTypes>>(
      `${WORKFLOW_TYPES_URL}/owned?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  addWorkflowType(workflowType: WorkflowTypes) {
    return this.http.post<WorkflowTypes>(
      `${WORKFLOW_TYPES_URL}`,
      workflowType,
      this.httpOptions,
    );
  }

  updateWorkflowType(workflowType: WorkflowTypes) {
    return this.http.put<WorkflowTypes>(
      `${WORKFLOW_TYPES_URL}`,
      workflowType,
      this.httpOptions,
    );
  }

  deleteWorkflowType(workflowTypeId: string) {
    return this.http.delete(`${WORKFLOW_TYPES_URL}/${workflowTypeId}`);
  }

  searchWorkflowTypes(
    workflowTypeName: string,
    isOwned: boolean,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<WorkflowTypes>> {
    let url = `${WORKFLOW_TYPES_URL}/search?workflowTypeName=${workflowTypeName}&isOwned=${isOwned}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<WorkflowTypes>>(url);
  }
}
