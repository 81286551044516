import { Component, OnInit } from '@angular/core';
import { ResearchVsTime } from '../../models/research-vs-time.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { RxState } from '@rx-angular/state';
import { EChartsOption } from 'echarts';
import { MONTHS } from 'src/app/core/constants/months';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';

interface ResearchVsTimeGraphComponentState {
  researchVsTime: ResearchVsTime[];
  datetime: string;
}

const initResearchVsTimeGraphComponentState: Partial<ResearchVsTimeGraphComponentState> =
  {
    researchVsTime: [],
    datetime: ''
  };

@Component({
  selector: 'app-research-vs-time-graph',
  templateUrl: './research-vs-time-graph.component.html',
  styleUrls: ['./research-vs-time-graph.component.scss'],
})
export class ResearchVsTimeGraphComponent implements OnInit {
  datetime: string = '';
  datetime$ = this.state.select('datetime');

  chartOption!: EChartsOption;
  researchVsTime$ = this.state.select('researchVsTime');
  researchVsTime: ResearchVsTime[] = [];

  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    private state: RxState<ResearchVsTimeGraphComponentState>,
    private datetimeFacade: DateTimeFacade,
  ) {
    this.state.set(initResearchVsTimeGraphComponentState);
    this.state.connect(
      'researchVsTime',
      this.researchDashboardFacade.researchVsTime$,
    );
    this.state.connect('datetime', this.datetimeFacade.datetime$);
  }

  ngOnInit(): void {
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
    });

    this.researchDashboardFacade.dispatchGetResearchVsTime();
    this.researchVsTime$.subscribe((data) => {
      this.researchVsTime = data;
      const years = [...new Set(this.researchVsTime.map((item) => item.year))];
      const months = [
        MONTHS.Jan,
        MONTHS.Feb,
        MONTHS.Mar,
        MONTHS.Apr,
        MONTHS.May,
        MONTHS.Jun,
        MONTHS.Jul,
        MONTHS.Aug,
        MONTHS.Sep,
        MONTHS.Oct,
        MONTHS.Nov,
        MONTHS.Dec,
      ];
      this.chartOption = {
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: Array(12)
              .fill(0)
              .map((_, index) => {
                const date = new Date(this.datetime);
                date.setMonth(index);

                return date.toLocaleString('en-US', {
                  month: 'short',
                });
              }),
          },
        ],
        yAxis: {
          type: 'value',
          interval: 1,
        },
        series: years.map((year) => ({
          name: year.toString(),
          type: 'line',
          data: months.map((month) => {
            const dataForMonth = this.researchVsTime.find(
              (item) =>
                item.year === year && item.month === months.indexOf(month) + 1,
            );
            return dataForMonth ? dataForMonth.numberOfResearches : 0;
          }),
          itemStyle: {
            color: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
              Math.random() * 256,
            )}, ${Math.floor(Math.random() * 256)})`, // Assign random color to each series
          },
        })),
        legend: {
          data: years.map((year) => year.toString()),
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
      };
    });
  }
}
