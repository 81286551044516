import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  CancelWorkflow,
  DownloadWorkflow,
  FilterWorkflows,
  GetMyWorkFlows,
  GetOwnedWorkFlows,
  GetWorkflows,
  InitiateWorkflow,
  SearchWorkflows,
  SetSearch,
  SetSelectedWorkflow,
  SetWorkflowSearchTerm,
  SetWorkflowSearchingMode,
  GetArchivedWorkflows,
  DownloadArchivedWorkflow,
  GetWorkflowFilter,
  GetArchivedWorkflowsByStatus,
  ArchiveWorkflow,
  GetRejectedWorkFlows,
} from '../store/workflow.actions';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  FilterWorkflowDto,
  InitiateWorkflowDto,
  Workflow,
  WorkflowWithStep,
  WorkflowFilter,
} from '../models/workflow.model';
import { WorkflowSelector } from '../store/workflow.selectors';

@Injectable({
  providedIn: 'root',
})
export class WorkflowFacade {
  @Select(WorkflowSelector.workflows)
  workflows$!: Observable<PaginatedList<Workflow>>;

  @Select(WorkflowSelector.myworkflows)
  myworkflows$!: Observable<PaginatedList<WorkflowWithStep>>;

  @Select(WorkflowSelector.ownedWorkflows)
  ownedWorkflows$!: Observable<PaginatedList<Workflow>>;

  @Select(WorkflowSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(WorkflowSelector.search)
  search$!: Observable<boolean>;

  @Select(WorkflowSelector.filteredWorkflows)
  filteredWorkflows$!: Observable<PaginatedList<Workflow>>;
  @Select(WorkflowSelector.isSearchingWorkflow)
  isSearchingWorkflow$!: Observable<boolean>;

  @Select(WorkflowSelector.workflowSearchTerm)
  workflowSearchTerm$!: Observable<string>;

  @Select(WorkflowSelector.selectedWorkflow)
  selectedWorkflow$!: Observable<Workflow>;

  @Select(WorkflowSelector.archivedWorkflows)
  archivedWorkflows$!: Observable<PaginatedList<Workflow>>;

  @Select(WorkflowSelector.workflowFilters)
  workflowFilters$!: Observable<WorkflowFilter[]>;

  constructor(private store: Store) {}

  dispatchInitiateWorkflow(initWorkflow: InitiateWorkflowDto) {
    return this.store.dispatch(new InitiateWorkflow(initWorkflow));
  }

  dispatchFilterWorkflows(filterParams: FilterWorkflowDto) {
    this.store.dispatch(new FilterWorkflows(filterParams));
  }

  dispatchGetWorkflows(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetWorkflows(pageNumber, pageSize));
  }

  dispatchGetMyWorkflows(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetMyWorkFlows(pageNumber, pageSize));
  }
  dispatchGetOwnedWorkflows(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetOwnedWorkFlows(pageNumber, pageSize));
  }

  dispatchSetSearch(isSearch: boolean) {
    this.store.dispatch(new SetSearch(isSearch));
  }
  dispatchSetWorkflowSearchingMode(isSearchingWorkflow: boolean) {
    this.store.dispatch(new SetWorkflowSearchingMode(isSearchingWorkflow));
  }
  dispatchSearchWorkflows(
    workflowName?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new SearchWorkflows(workflowName, pageNumber, pageSize),
    );
  }
  dispatchSetWorkflowSearchTerm(workflowSearchTerm: string) {
    this.store.dispatch(new SetWorkflowSearchTerm(workflowSearchTerm));
  }
  dispatchCancelWorkflow(workflowId: string) {
    this.store.dispatch(new CancelWorkflow(workflowId));
  }
  dispatchDownloadWorkflow(workflowId: string) {
    this.store.dispatch(new DownloadWorkflow(workflowId));
  }
  dispatchDownloadArchivedWorkflow(workflowId: string) {
    this.store.dispatch(new DownloadArchivedWorkflow(workflowId));
  }
  dispatchSetSelectedWorkflow(workflow: Workflow) {
    this.store.dispatch(new SetSelectedWorkflow(workflow));
  }

  dispatchGetArchivedWorkflow(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetArchivedWorkflows(pageNumber, pageSize));
  }

  dispatchGetWorkflowFilter() {
    this.store.dispatch(new GetWorkflowFilter());
  }

  dispatchGetArchivedWorkflowsByStatus(
    status: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetArchivedWorkflowsByStatus(status, pageNumber, pageSize),
    );
  }
  dispatchArchiveWorkflow(workflowId: string) {
    this.store.dispatch(new ArchiveWorkflow(workflowId));
  }
  dispatchGetRejectedWorkflows(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetRejectedWorkFlows(pageNumber, pageSize));
  }
}
