import { PaginatedList } from './../../../../core/models/paginated-list.interface';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MemoFacade } from '../../facade/memo.facades';
import { Memo, MemoStatus } from "../../models/memo.model";
import { RxState } from '@rx-angular/state';
import { Observable, Subject, combineLatest, filter } from 'rxjs';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {
  MEMO_DETAIL_ROUTE,
  MEMO_FORM_ROUTE,
} from 'src/app/core/constants/routes';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { CurrentLoggedInUser, User } from 'src/app/users/models/user.model';
import { Role } from 'src/app/users/models/role.model';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Office } from 'src/app/offices/models/office.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';
import { MatSelectChange } from '@angular/material/select';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { MatCheckboxChange } from '@angular/material/checkbox';

interface MemoListComponentState {
  draftMemo: PaginatedList<Memo>;
  memosSent: PaginatedList<Memo>;
  memosRecieved: PaginatedList<Memo>;
  memosCcedToMe: PaginatedList<Memo>;
  selectedMemo: Memo | null;
  pageNumber: number;
  pageSize: number;
  isSearchingMemo?: boolean;
  users: User[];
  currentUser?:CurrentLoggedInUser
}

const initMemoListComponentState: MemoListComponentState = {
  draftMemo: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosSent: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosRecieved: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosCcedToMe: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  selectedMemo: null,
  pageNumber: 1,
  pageSize: 10,
  users: [],
};

@Component({
  selector: 'app-memo-list',
  templateUrl: './memo-list.component.html',
  styleUrls: ['./memo-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: MemoListComponent}],
})
export class MemoListComponent implements OnInit, MatPaginatorIntl {
  MemoFilter:{ [key: string]: string } = {
  Draft: $localize`:@@documents.memo-list.draft:Draft `,
  Sent: $localize`:@@documents.memo-list.sent:Sent`,
  Received: $localize`:@@documents.memo-list.received:Received`,
  CC: $localize`:@@documents.memo-list.cc:CC`
} as const;
  currentUser!: CurrentLoggedInUser | undefined;
  sentMemos$: Observable<PaginatedList<Memo>> = this.state.select('memosSent');
  sentMemos!: PaginatedList<Memo>;
  draftMemos!: PaginatedList<Memo>;
  draftMemos$: Observable<PaginatedList<Memo>> = this.state.select('draftMemo');
  MemosCcedTome$: Observable<PaginatedList<Memo>> =
    this.state.select('memosCcedToMe');
  MemosCcedToMe!: PaginatedList<Memo>;
  receivedMemos$: Observable<PaginatedList<Memo>> =
    this.state.select('memosRecieved');
  receivedMemos!: PaginatedList<Memo>;
  selectedMemo$: Observable<Memo | null> = this.state.select('selectedMemo');
  selectedMemo: Memo | null = null;
  selectedFilter= "Sent";
  memoFilterControl = new FormControl(this.selectedFilter);
  memoSearchForm: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  pageNumber$: Observable<number> = this.state.select('pageNumber');
  pageNumber: number = 1;
  pageSize$: Observable<number> = this.state.select('pageSize');
  pageSize: number = 10;
  allMemos!: Map<string, PaginatedList<Memo>>;
  isSearchingMemo$ = this.state.select('isSearchingMemo');
  isSearchingMemo?: boolean = false;
  replyPrefix = $localize`:@@documents.memo-list.reply:\<Reply:\> `;
  placeholderToggleLabel = {
      searchMemo: $localize`:@@documents.memo-list.search-memos: Search Memos`,
  }

  dropDownButtonToogleLabel = {
    filterMemoStatus: $localize`:@@documents.memo-list.sent: Sent`,
    searchMemoSender: $localize`:@@documents.memo-list.sender: Sender`,
  };

  users: User[] = [];
  filteredUsers: User[] = [];
  users$ = this.state.select('users');
  roles: Role[] = [];
  roles$ = this.roleFacade.roles$;

  selectedOffices: FlatOfficeNode[] = [];
  selectedOffices$ = this.officeFacade.selectedFlatOfficeNodes$;

  memoDataSource: Memo[] = [];
  combinedPaginatedMemos?: PaginatedList<Memo>;
  areAllMemosShown: boolean = true;
  offices: Office[] = [];
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('officeInput') officeInput!: ElementRef<HTMLInputElement>;

  firstPageLabel = $localize`:@@documents.memo-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.memo-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.memo-list.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.memo-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.memo-list.previous-page:  Previous page`;
  email: any;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.memo-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.memo-list.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.memo-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private memoFacade: MemoFacade,
    private state: RxState<MemoListComponentState>,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private officeFacade: OfficeFacade,
    private roleFacade: RoleFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initMemoListComponentState);
    this.state.connect('draftMemo', this.memoFacade.draftMemo$);
    this.state.connect('memosSent', this.memoFacade.memosSent$);
    this.state.connect('memosCcedToMe', this.memoFacade.memosCcedToMe$);
    this.state.connect('memosRecieved', this.memoFacade.memosReceived$);
    this.state.connect('pageNumber', this.memoFacade.pageNumber$);
    this.state.connect('pageSize', this.memoFacade.pageSize$);
    this.state.connect('selectedMemo', this.memoFacade.selectedMemo$);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect('currentUser', this.userFacade.currentLoggedInUser$);
    this.memoSearchForm = this.fb.group({
      searchTerm: [''],
      roles: [[]],
      user: [null],
      startDate: [''],
      endDate: [new Date()],
    });
    this.state.connect('isSearchingMemo', this.memoFacade.isSearchingMemo$);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.state.select('currentUser').subscribe((user) => {
      this.currentUser = user;
      this.userFacade.dispatchGetUsersByOfficeId(
        this.currentUser?.officeId,
        1,
        10,
      );
    });
    this.pageNumber$.subscribe((pageNum) => {
      this.pageNumber = pageNum;
    });
    this.pageSize$.subscribe((pageSize) => {
      this.pageSize = pageSize;
    });
    this.memoFacade.dispatchGetMemosSent(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetMemosReceived(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetMemosCcedToMe(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetDraftMemo(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetMemoFilters();

    combineLatest([
      this.sentMemos$,
      this.MemosCcedTome$,
      this.receivedMemos$,
      this.draftMemos$,
    ]).subscribe(([sentMemos, MemosCcedTome, receivedMemos, draftMemos]) => {
      this.sentMemos = sentMemos;
      this.MemosCcedToMe = MemosCcedTome;
      this.receivedMemos = receivedMemos;
      this.draftMemos = draftMemos;

      if (this.selectedFilter === 'Draft' && !this.areAllMemosShown) {
        this.memoDataSource = draftMemos.items;
      } else if (this.selectedFilter === 'Sent' && !this.areAllMemosShown) {
        this.memoDataSource = sentMemos.items;
      } else if (this.selectedFilter === 'Received' && !this.areAllMemosShown) {
        this.memoDataSource = receivedMemos.items;
      } else if (this.selectedFilter === 'CC' && !this.areAllMemosShown) {
        this.memoDataSource = this.MemosCcedToMe.items;
      } else {
        this.memoDataSource = sentMemos.items;
      }
    });

    this.selectedMemo$.subscribe((memo) => {
      this.selectedMemo = memo;
    });

    this.allMemos = new Map([
      ['Draft', this.draftMemos],
      ['Sent', this.sentMemos],
      ['Received', this.receivedMemos],
      ['CC', this.MemosCcedToMe],
      ['All', this.getCombinedPaginatedMemos()],
    ]);

    this.memoFacade.isSearchingMemo$.subscribe((isSearchingMemo) => {
      this.isSearchingMemo = isSearchingMemo;
    });
    this.officeFacade.dispatchResetSelectedOffices();

    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.users$.subscribe(
      (val) => ((this.users = val), (this.filteredUsers = val)),
    );
    this.selectedOffices$.subscribe((val) => (this.selectedOffices = val));

    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });

    this.email = this.currentUser?.email;

    this.memoSearchForm.get('startDate')?.valueChanges.subscribe(() => {
      this.handleDateRangeChange();
    });
    this.memoSearchForm.get('endDate')?.valueChanges.subscribe(() => {
      this.handleDateRangeChange();
    });
  }

  get memoFilters() {
    return Object.keys(this.MemoFilter);
  }

  createMemo(): void {
    this.router.navigate([MEMO_FORM_ROUTE, { updateStatus: 'false' }]);
  }

  handlePaginationLength() {
    switch (this.selectedFilter) {
      case 'Draft':
        return this.draftMemos.totalCount;

      case 'Sent':
        return this.sentMemos.totalCount;

      case 'Received':
        return this.receivedMemos.totalCount;

      case 'CC':
        return this.MemosCcedToMe.totalCount;

      case 'All':
        return (
          this.draftMemos.totalCount +
          this.sentMemos.totalCount +
          this.receivedMemos.totalCount +
          this.MemosCcedToMe.totalCount
        );

      default:
        return 0;
    }
  }

  editMemo(): void {
    if (this.selectedMemo)
      this.memoFacade.dispatchGetMemoAccessDetail(this.selectedMemo.id);
    this.router.navigate([MEMO_FORM_ROUTE, { updateStatus: 'true' }]);
  }

  sendMemo():void {
    if(this.selectedMemo)
    this.memoFacade.dispatchUpdateMemoStatus(
      MemoStatus.Sent,
      this.selectedMemo.id,
    );
  }

  deleteMemo(): void {
    if (this.selectedMemo !== null) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@documents.memo-list.delete-title-memo-part-1:Are you sure you want to delete the` ,
            boldText: ` "${this.selectedMemo.title}" ` ,
            regularTextTwo: $localize`:@@documents.memo-list.delete-title-memo-part-2:memo?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm') {
            this.memoFacade.dispatchDeleteMemo(this.selectedMemo!.id!);
          }
        });
    }
  }

  onRowClick(event: any, memo: Memo) {
    this.memoFacade.dispatchSelectMemo(memo);
    this.router.navigate([
      MEMO_DETAIL_ROUTE,
      memo.id,
      { memoFilter: this.selectedFilter },
    ]);
  }

  loadPaginatedMemos(event: PageEvent): void {
    this.isSearchingMemo
      ? this.memoFacade.dispatchSearchMemos(
          this.memoSearchForm.get('searchTerm')?.value,
          this.memoSearchForm.get('user')?.value.id,
          event.pageIndex + 1,
          event.pageSize,
        )
      : this.memoFacade.dispatchGetMemosSent(
          event.pageIndex + 1,
          event.pageSize,
        );
    this.memoFacade.dispatchSetPageNumberAndSize(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  setSelectedMemo(event: MouseEvent, memo: Memo) {
    event.stopPropagation();
    this.memoFacade.dispatchSelectMemo(memo);
  }

  get dataSource(): PaginatedList<Memo> {
    return (
      this.allMemos.get(this.selectedFilter) ?? {
        items: [],
        pageNumber: 0,
        totalCount: 0,
        totalPages: 0,
      }
    );
  }

  tableHeader(): string[] {
    switch (this.selectedFilter) {
      case 'Draft':
        return ['Title', 'Status', 'Created At', 'Actions'];

      case 'Sent':
        return ['Title', 'Status', 'Created At', 'Actions'];

      case 'Received':
        return ['Title', 'Sent From', 'Status', 'Created At'];

      case 'CC':
        return ['Title', 'Sent From', 'Created At'];

      case 'All':
        return ['Title', 'Status', 'Created At', 'Actions'];

      default:
        return ['Title', 'Status', 'Created At', 'Actions'];
    }
  }

  onFilterChange(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.areAllMemosShown = false;
    this.memoFilterControl.setValue(this.selectedFilter);
    this.memoFacade.dispatchSetPageNumberAndSize(1, 10);
    this.memoFacade.dispatchGetMemosSent(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetMemosReceived(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetMemosCcedToMe(this.pageNumber, this.pageSize);
    this.memoFacade.dispatchGetDraftMemo(this.pageNumber, this.pageSize);

    switch (this.selectedFilter) {
      case 'Draft':
        this.memoDataSource = this.draftMemos.items;
        return;

      case 'Sent':
        this.memoDataSource = this.sentMemos.items;
        return;

      case 'Received':
        this.memoDataSource = this.receivedMemos.items;
        return;

      case 'CC':
        this.memoDataSource = this.MemosCcedToMe.items;
        return;

      default:
        this.memoDataSource = this.sentMemos.items;
        break;
    }
  }

  onSenderChange() {
    this.memoFacade.dispatchSetMemoSearchingMode(true);
    this.memoFacade.dispatchSearchMemos(
      undefined,
      this.memoSearchForm.get('user')?.value.id,
      this.pageNumber,
      this.pageSize,
    );
  }

  onSearchTermChange() {
    if (
      this.memoSearchForm.get('searchTerm')?.value === '' &&
      this.memoSearchForm.get('user')?.value === null
    ) {
      this.memoFacade.dispatchSetMemoSearchingMode(false);
      this.memoFacade.dispatchGetMemosSent(this.pageNumber, this.pageSize);
    } else {
      this.memoFacade.dispatchSetMemoSearchingMode(true);
      this.memoFacade.dispatchSearchMemos(
        this.memoSearchForm.get('searchTerm')?.value,
        undefined,
        this.pageNumber,
        this.pageSize,
      );
    }
  }

  openMultipleOffice() {
    this.dialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
    });
  }

  onRoleSelect() {
    const selectedRoles = this.memoSearchForm.get('roles') as FormControl;
    if (selectedRoles && selectedRoles.value) {
      const roleIds = selectedRoles.value.map((p: Role) => p.id);
      const officeIds = this.selectedOffices.map((o: FlatOfficeNode) => o.id);
      if (!officeIds || officeIds.length < 1) {
        this.userFacade.dispatchGetUsersByMultipleRoleIds(roleIds);
      } else {
        if (roleIds && roleIds.length > 0) {
          this.userFacade.dispachGetUsersByRolesAndOffices(roleIds, officeIds);
        }
      }
    }
  }

  handleDateRangeChange() {
    const startDate = this.memoSearchForm.get('startDate')?.value;
    const endDate = this.memoSearchForm.get('endDate')?.value;

    if (startDate && endDate) {
      this.memoFacade.dispatchGetMemosByDate(
        this.email,
        startDate.toISOString(),
        endDate.toISOString(),
        this.selectedFilter,
        1,
        10,
      );
    }
  }

  removeSelectedFlatOfficeNodeFromNodes(officeNode: FlatOfficeNode) {
    if (this.selectedOffices === undefined) return;
    const index = this.selectedOffices.indexOf(officeNode);

    if (index >= 0) {
      this.selectedOffices.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(this.selectedOffices);
    }
  }

  hasGetMemosPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.GetMemo,
    );
  }
  hasCreateMemosPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.CreateMemo,
    );
  }
  hasUpdateMemosPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.UpdateMemo,
    );
  }
  hasDeleteMemosPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.DeleteMemo,
    );
  }

  hasGetMemosRecievedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.GetMemosReceived,
    );
  }
  hasGetMemosCcdToMePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.GetMemosCCedToMeQuery,
    );
  }

  hasSearchMemosByNameAndSentFromPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.SearchMemosByNameAndSentFrom,
    );
  }
  hasCrudPermission() {
    return [this.hasUpdateMemosPermission(), this.hasDeleteMemosPermission()];
  }

  getCombinedPaginatedMemos() {
    const combinedItems: Memo[] = [
      ...this.draftMemos.items,
      ...this.sentMemos.items,
      ...this.receivedMemos.items,
      ...this.MemosCcedToMe.items,
    ];

    const totalCount: number = combinedItems.length;

    const combinedMemos: PaginatedList<Memo> = {
      items: combinedItems,
      pageNumber: 1,
      totalPages: 1,
      totalCount: totalCount,
    };

    return combinedMemos;
  }

  isFilterChecked(type: string) {
    return this.selectedFilter === type;
  }

  onCheckboxChange(event: MatCheckboxChange, type: string) {
    if (event.checked) {
      this.selectedFilter === type;
    } else {
      this.selectedFilter = 'Sent';
      this.memoDataSource = this.sentMemos.items;
    }
  }
}
