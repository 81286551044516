import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import { TaskComment } from 'src/app/researches/models/task.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';import { UserFacade } from 'src/app/users/facade/user.facade';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';

interface TaskReplyCommentComponentState {
  selectedReplyComment: TaskComment | undefined;
  selectedTaskComment: TaskComment | undefined;
  processInstanceDetail: ProcessInstanceDetail | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initTaskReplyCommentComponentState: TaskReplyCommentComponentState = {
  selectedReplyComment: undefined,
  selectedTaskComment: undefined,
  processInstanceDetail: undefined,
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-task-reply-comment',
  templateUrl: './task-reply-comment.component.html',
  styleUrls: ['./task-reply-comment.component.scss'],
})
export class TaskReplyCommentComponent implements OnInit {
  @Input() taskComment: TaskComment | undefined;
  @Input() comment: TaskComment | undefined;
  @Output() isEditReplyTaskCommentClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isEditReplyTaskComment: boolean = false;

  selectedReplyComment: TaskComment | undefined;
  selectedReplyComment$ = this.state.select('selectedReplyComment');

  selectedTaskComment: TaskComment | undefined;
  selectedTaskComment$ = this.state.select('selectedTaskComment');

  replyForm: FormGroup;

  processInstanceDetail$ = this.state.select('processInstanceDetail');
  processInstanceDetail: ProcessInstanceDetail | undefined;

  placeholderToggleLabel = {
      replyingTo: $localize`:@@researches.task-reply-comment.replying-to: Replying to` ,
  }
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;


  constructor(
    private fb: FormBuilder,
    private state: RxState<TaskReplyCommentComponentState>,
    private taskFacade: TaskFacade,
    private dialog: MatDialog,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initTaskReplyCommentComponentState);

    this.state.connect(
      'selectedReplyComment',
      this.taskFacade.selectedReplyComment$,
    );

    this.state.connect(
      'selectedTaskComment',
      this.taskFacade.selectedTaskComment$,
    );

    this.replyForm = this.fb.group({
      reply: ['', [Validators.required]],
    });

    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );

    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  ngOnInit(): void {
    this.selectedReplyComment$.subscribe((selectedReplyComment) => {
      this.selectedReplyComment = selectedReplyComment;
    });

    this.selectedTaskComment$.subscribe((selectedTaskComment) => {
      this.selectedTaskComment = selectedTaskComment;
    });

    this.processInstanceDetail$.subscribe((processIntance) => {
      this.processInstanceDetail = processIntance;
    });

    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
  }

  editReplyComment() {
    this.isEditReplyTaskCommentClicked.emit(true);
    this.taskFacade.dispatchSetSelectedReplyComment(this.comment!);
    this.isEditReplyTaskComment = true;
    this.updateReplyForm();
  }

  cancelReplyComment() {
    this.isEditReplyTaskComment = false;
  }

  updateReplyComment() {
    const { valid, touched, dirty } = this.replyForm;
    if (valid && (touched || dirty)) {
      this.taskFacade.dispatchUpdateReplyTaskComment(
        this.selectedTaskComment?.id!,
        this.selectedReplyComment?.id!,
        this.replyForm.value.reply,
      );

      this.replyForm.reset();
      this.cancelReplyComment();
    }
  }

  updateReplyForm() {
    if (this.isEditReplyTaskComment) {
      this.replyForm.patchValue({
        reply: this.selectedReplyComment?.content,
      });
    }
  }

  hasUpdateReplyTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.UpdateReplyTaskComment,
    );
  }

  openDeleteConfirmationDialog(
    event: MouseEvent,
    taskComment: TaskComment | undefined,
    comment: TaskComment | undefined,

  ) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize `:@@researches.task-reply-comment.task-reply-comment-confirmationAre you sure you want to delete "`,
        boldText: ` "${comment!.content}" `,
        regularTextTwo: $localize `:@@researches.task-reply-comment.comment-label-text:comment?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.taskFacade.dispatchDeleteTaskCommentReply(taskComment?.id!, comment?.id!);
      }
    });
  }
}
