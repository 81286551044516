import { SanitizerService } from './../../../../core/services/sanitizers-and-validators/sanitizer.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TorConfigurationFacade } from '../../facade/tor-setttings.facade';
import { MatDialog } from '@angular/material/dialog';
import { ActivityType } from '../../models/activityType.model';
import { RxState } from '@rx-angular/state';

interface ConfigureActivityTypeState {
  activityUpdate: ActivityType | undefined;
}

const initConfigureActivityTypeState: ConfigureActivityTypeState = {
  activityUpdate: undefined,
};

@Component({
  selector: 'app-create-activity-type',
  templateUrl: './create-activity-type.component.html',
  styleUrls: ['./create-activity-type.component.scss'],
})
export class CreateActivityTypeComponent {
  activityForm: FormGroup;
  saveButtonText: string = $localize`:@@researches.create-activity-type.create: Create`;
  formTitle = $localize`:@@researches.create-activity-type.create-activity-type: Create Activity Type`;
  activityUpdate$ = this.configureActivityTypeState.select('activityUpdate');
  activityUpdate: undefined | ActivityType = undefined;

  constructor(
    private configureActivityTypeState: RxState<ConfigureActivityTypeState>,
    private formBuilder: FormBuilder,
    public sanitizerService: SanitizerService,
    private torConfigurationFacade: TorConfigurationFacade,
    private dialog: MatDialog,
  ) {
    this.configureActivityTypeState.connect;
    this.configureActivityTypeState.set(initConfigureActivityTypeState);
    this.configureActivityTypeState.connect(
      'activityUpdate',
      this.torConfigurationFacade.activityUpdate$,
    );
    this.activityForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.activityUpdate$.subscribe((result) => {
      if (result != undefined) {
        this.formTitle = $localize`:@@researches.create-activity-type.update-activity-type: Update Activity Type`;
        this.saveButtonText = $localize`:@@researches.create-activity-type.update: Update`;
        this.activityUpdate = result;
        const control = this.activityForm.get('name');
        if (control) {
          control.patchValue(result.name);
        }
      }
    });
  }

  save() {
    if (!this.activityForm.valid) return;
    const activityData = this.activityForm.value;
    if (this.activityUpdate == undefined) {
      this.torConfigurationFacade.dispatchCreateActivityType(activityData);
      this.dialog.closeAll();
    } else {
      if (this.activityUpdate.name != activityData.name) {
        const data = {...this.activityUpdate , name : activityData.name }
        this.torConfigurationFacade.dispatchUpdateActivityType(data);
      }
    }
    this.torConfigurationFacade.dispatchRemoveActivityUpdate();
    this.dialog.closeAll();
  }
}
