<mat-dialog-content class="pb-0">
  <div class="mb-2" i18n="@@users.change-role.select-roles">Select Role</div>
  <form class="user-form" [formGroup]="roleForm">
    <mat-form-field appearance="fill">
      <mat-label i18n="@@users.change-role.role">Role</mat-label>
      <mat-select formControlName="newRole">
        <mat-option *ngFor="let role of filteredRoles" [value]="role.id">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!roleForm.valid"
    mat-dialog-close="save"
   i18n="@@users.change-role.save">
    Save
  </button>
  <button class="submit" mat-flat-button color="accent" mat-dialog-close i18n="@@users.change-role.cancel">
    Cancel
  </button>
</mat-dialog-actions>
