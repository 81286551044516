import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { DocumentsAnalyticsService } from '../services/documents-analytics.service';
import {
  GetWorkflowTypesCount,
  GetWorkflowsCount,
  GetReceivedExternalDocumentsCount,
  GetWorkflowsVsTimeData,
  GetReceivedExternalDocuments,
  GetSentExternalDocuments,
  GetReportedCommentsPercentage,
  GetMyWorkflowsCount,
  GetInvolvedWorkflowsCount,
} from './documents-analytics.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { WorkflowsVsTime } from '../models/workflows-vs-time.model';
import { ReceivedExternalDocument } from '../models/received-external-document.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { SentExternalDocument } from '../models/sent-external-document.model';

export interface DocumentsAnalyticsStateModel {
  workflowTypesCount: number;
  workflowsCount: any;
  receivedExternalDocumentsCount: number;
  workflowsVsTime: WorkflowsVsTime[];
  receivedExternalDocuments: PaginatedList<ReceivedExternalDocument>;
  sentExternalDocuments: PaginatedList<SentExternalDocument>;
  reportedCommentsPercentage: number;
  myWorkflowsCount:number;
  involvedWorkflowsCount:number;
}

const DOCUMENTS_ANALYTICS_STATE_TOKEN =
  new StateToken<DocumentsAnalyticsStateModel>('DocumentsAnalyticsState');

const defaults: DocumentsAnalyticsStateModel = {
  workflowTypesCount: 0,
  workflowsCount: 0,
  receivedExternalDocumentsCount: 0,
  workflowsVsTime: [],
  receivedExternalDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  sentExternalDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  reportedCommentsPercentage: 0,
  myWorkflowsCount:0,
  involvedWorkflowsCount:0
};
@State<DocumentsAnalyticsStateModel>({
  name: DOCUMENTS_ANALYTICS_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class DocumentsAnalyticsState {
  constructor(
    public documentsAnalyticsService: DocumentsAnalyticsService,
    private store: Store,
  ) {}

  @Action(GetWorkflowTypesCount)
  getWorkflowTypesCount(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId }: GetWorkflowTypesCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService.getWorkflowTypesCount(officeId).pipe(
      tap((count) => {
        patchState({
          workflowTypesCount: count,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetWorkflowsCount)
  getWorkflowsCount(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId, userId, startDateTime, endDateTime }: GetWorkflowsCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getWorkflowsCount(officeId, userId, startDateTime, endDateTime)
      .pipe(
        tap((count) => {
          patchState({
            workflowsCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetReceivedExternalDocumentsCount)
  getReceivedExternalDocumentsCount(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId }: GetReceivedExternalDocumentsCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getReceivedExternalDocumentCount(officeId)
      .pipe(
        tap((count) => {
          patchState({
            receivedExternalDocumentsCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowsVsTimeData)
  getWorkflowsVsTime(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    {
      startDateTime,
      endDateTime,
      officeId,
      userId,
      workflowTypeId,
    }: GetWorkflowsVsTimeData,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getWorkflowsVsTimeGraphData(
        startDateTime,
        endDateTime,
        officeId,
        userId,
        workflowTypeId,
      )
      .pipe(
        tap((data) => {
          patchState({
            workflowsVsTime: data,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetReceivedExternalDocuments)
  getReceivedExternalDocuments(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId, pageNumber, pageSize }: GetReceivedExternalDocuments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getReceivedExternalDocuments(officeId, pageNumber, pageSize)
      .pipe(
        tap((result) => {
          patchState({
            receivedExternalDocuments: result,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetSentExternalDocuments)
  getSentExternalDocuments(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId, pageNumber, pageSize }: GetSentExternalDocuments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getSentExternalDocuments(officeId, pageNumber, pageSize)
      .pipe(
        tap((result) => {
          patchState({
            sentExternalDocuments: result,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetReportedCommentsPercentage)
  getReportedCommentsPercentage(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    { officeId, workflowTypeId }: GetReportedCommentsPercentage,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getReportedCommentsPercentage(officeId, workflowTypeId)
      .pipe(
        tap((percentage) => {
          patchState({
            reportedCommentsPercentage: percentage,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetMyWorkflowsCount)
  getMyWorkflowsCount(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    {userId, officeId, startDateTime, endDateTime }: GetMyWorkflowsCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getMyWorkflowsCount(userId,officeId,startDateTime, endDateTime)
      .pipe(
        tap((count) => {
          patchState({
            myWorkflowsCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetInvolvedWorkflowsCount)
  getInvolvedWorkflowsCount(
    { patchState }: StateContext<DocumentsAnalyticsStateModel>,
    {userId, officeId, startDateTime, endDateTime }: GetInvolvedWorkflowsCount,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.documentsAnalyticsService
      .getInvolvedWorkflowsCount(userId,officeId,startDateTime, endDateTime)
      .pipe(
        tap((count) => {
          patchState({
            involvedWorkflowsCount: count,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
