import { Component, DestroyRef, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User } from 'src/app/users/models/user.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { combineLatest, filter, startWith } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { WorkflowStepFacade } from '../../facade/workflow-step.facade';
import { WorkflowStep } from '../../models/workflow-step.model';
import { Office } from 'src/app/offices/models/office.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Role } from 'src/app/users/models/role.model';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface attachUserToWorkflowStepState {
  users: User[];
  WorkflowStep: WorkflowStep | undefined;
}

const initialattachUserToWorkflowStepState: attachUserToWorkflowStepState = {
  users: [],
  WorkflowStep: undefined,
};

@Component({
  selector: 'app-attach-user-form',
  templateUrl: './attach-user-form.component.html',
  styleUrls: ['./attach-user-form.component.scss'],
})
export class AttachUserFormComponent {
  formTitle = 'Attach User';
  attachUserForm!: FormGroup;

  users: User[] = [];
  filteredUsers: User[] = [];
  users$ = this.state.select('users');
  workflowStep: WorkflowStep | undefined;
  workflowStep$ = this.state.select('WorkflowStep');
  roles: Role[] = [];
  roles$ = this.roleFacade.roles$;

  selectedOffices$ = this.officeFacade.selectedFlatOfficeNodes$;

  offices: Office[] = [];
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('officeInput') officeInput!: ElementRef<HTMLInputElement>;

  placeholderToggleLabel = {
    usersField: $localize`:@@documents.attach-user-form.users: Users`,
  };

  constructor(
    private fb: FormBuilder,
    private userFacade: UserFacade,
    public state: RxState<attachUserToWorkflowStepState>,
    private workflowStepFacade: WorkflowStepFacade,
    private officeFacade: OfficeFacade,
    private roleFacade: RoleFacade,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {
    this.state.set(initialattachUserToWorkflowStepState);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect('WorkflowStep', this.workflowStepFacade.workflowStep$);

    this.attachUserForm = this.fb.group({
      selectedOffices: [[] as FlatOfficeNode[]],
      roles: [[]],
      usersQuery: [''],
      users: [new Set<User>(), this.usersValidator],
    });
  }

  ngOnInit() {
    this.officeFacade.dispatchResetSelectedOffices();
    this.officeFacade.dispatchResetSelectedOffice();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.userFacade.dispatchGetUsers(1, 10);
    this.users$.subscribe(
      (val) => ((this.users = val), (this.filteredUsers = val)),
    );
    this.selectedOffices$.subscribe((val) => this.attachUserForm.controls['selectedOffices'].setValue(val));

    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });
    this.attachUserForm.controls['usersQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredUsers = this.filterUser(query);
      });

    this.workflowStep$.subscribe((step) => {
      this.workflowStep = step;
    });

    combineLatest([
      this.attachUserForm.controls['selectedOffices'].valueChanges.pipe(startWith([])),
      this.attachUserForm.controls['roles'].valueChanges.pipe(startWith([])),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([offices, roles]) => {
        this.handleChange(offices, roles);
        this.attachUserForm.controls['users'].value.clear();
      });
  }

  selectUsers(event: MatAutocompleteSelectedEvent) {
    const selectUser = event.option.value;
    const selectUsers = this.attachUserForm.controls['users'].value;
    const updatedUsers = new Set([...selectUsers, selectUser]);
    this.attachUserForm.controls['users'].patchValue(updatedUsers);
    this.userInput.nativeElement.value = '';
  }

  removeUser(user: User) {
    const currentUsers = this.attachUserForm.controls['users'].value;
    currentUsers.delete(user);
    this.attachUserForm.controls['users'].patchValue(currentUsers);
  }

  save() {
    const { valid, touched, dirty } = this.attachUserForm;
    if (valid && (touched || dirty)) {
      const userIds = [...this.attachUserForm.value.users].map(
        (user: User) => user.id,
      ) as any;
      if (userIds.length > 0) {
        this.workflowStepFacade.dispatchAttachUserToStep(
          this.workflowStep?.id!,
          userIds,
        );
      }
    }
    this.dialog.closeAll();
  }

  openMultipleOffice() {
    this.dialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
    });
  }

  handleChange(offices: FlatOfficeNode[], roles: Role[]) {
    const roleIds = roles.map((p: Role) => p.id);
    const officeIds = offices.map((o: FlatOfficeNode) => o.id);

    this.userFacade.dispachGetUsersByRolesAndOffices(roleIds, officeIds);
  }

  removeSelectedFlatOfficeNodeFromNodes(officeNode: FlatOfficeNode) {
    const selectedOffices = this.attachUserForm.controls['selectedOffices'].value;
    this.attachUserForm.controls['selectedOffices'].setValue(selectedOffices.filter((o: FlatOfficeNode) => o.id !== officeNode.id))
  }

  private filterUser(query: string | null) {
    return this.users.filter((user) =>
      user.firstName.toLowerCase().includes(query?.toLocaleLowerCase() || ''),
    );
  }

  usersValidator: ValidatorFn = (control) => {
    const user: Set<User> = control.value;
    if (user.size === 0) {
      return { userRequired: true };
    }
    return null;
  };
}
