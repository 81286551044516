import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DocumentsAnalyticsHomeComponent } from './components/documents-home/documents-analytics-home.component';
import { DocumentsAnalyticsState } from './store/documents-analytics.state';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { ReportedCommentsReportComponent } from './components/reported-comments-report/reported-comments-report.component';
import { ReportedCommentState } from './store/reported-comments.state';
import { WorkflowsTimeGraphComponent } from './components/workflows-time-graph/workflows-time-graph.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ExternalDocumentRecordsComponent } from './components/external-document-records/external-document-records.component';
import { ReportedCommentsPercentageComponent } from './components/reported-comments-percentage/reported-comments-percentage.component';
import { WorkflowStepAveragerTimeComponent } from './components/workflow-step-averager-time/workflow-step-averager-time.component';
import { WorkflowTypeAverageTimeState } from './store/workflow-step-average-time.state';
import { WorkflowAverageTimeSpentComponent } from './components/workflow-average-time-spent/workflow-average-time-spent.component';
import { WorkflowAverageTimeSpentState } from './store/workflow-average-time-spent.state';
import { InitiatedWorkflowsReportComponent } from './components/initiated-workflows-report/initiated-workflows-report.component';
import { IntitatedWorkflowState } from './store/workflow-analytics.state';
import { MyInitiatedWorkflowsReportComponent } from './components/my-initiated-workflows-report/my-initiated-workflows-report.component';
import { WorkflowsInvolvedInReportComponent } from './components/workflows-involved-in-report/workflows-involved-in-report.component';

@NgModule({
  declarations: [
    DocumentsAnalyticsHomeComponent,
    ReportedCommentsReportComponent,
    WorkflowsTimeGraphComponent,
    ExternalDocumentRecordsComponent,
    ReportedCommentsPercentageComponent,
    WorkflowStepAveragerTimeComponent,
    WorkflowAverageTimeSpentComponent,
    InitiatedWorkflowsReportComponent,
    MyInitiatedWorkflowsReportComponent,
    WorkflowsInvolvedInReportComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    NgxsModule.forFeature([
      DocumentsAnalyticsState,
      ReportedCommentState,
      WorkflowTypeAverageTimeState,
      WorkflowAverageTimeSpentState,
      IntitatedWorkflowState,
    ]),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
})
export class DashboardModule {}
