<div class="container">
  <button
    class="add-user"
    mat-flat-button
    color="primary"
    (click)="openCcForm()"
    *appHasPermission="hasAddUsersCCedToMemoPermission()"
  >
    <mat-icon> add </mat-icon> <span i18n="@@documents.memo-cc-list.add-user"> Add User </span>
  </button>

  <mat-table class="background" [dataSource]="CCUsersList">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.memo-cc-list.name">Name</mat-header-cell>
      <mat-cell *matCellDef="let ccedUser">{{
        ccedUser.user.firstName
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="office">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.memo-cc-list.office">Office</mat-header-cell>
      <mat-cell *matCellDef="let ccedUser">{{
        ccedUser.usersOffice.name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" *appHasPermission="hasRemoveUsersCCedToMemoPermission()">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.memo-cc-list.actions">Actions</mat-header-cell>
      <mat-cell *matCellDef="let user" class="icon-cell">
        <button mat-icon-button (click)="removeUser($event, user)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-row
      class="table-row"
      *matRowDef="let task; columns: ['name', 'office', 'actions']"
    ></mat-row>
  </mat-table>
</div>
