<div class="flex-row">
  <h4
    i18n="@@researches.assign-roles-dialog.select-roles"
    i18n="@@users.assign-roles-dialog.select-roles"
  >
    Select Roles
  </h4>
</div>
<div class="flex-row">
  <p>
    {{ selectedUsersId.size }}
    <span i18n="@@researches.assign-roles-dialog.users-selected">
      users selected</span
    >
  </p>
</div>
<mat-dialog-content>
  <div class="role-list">
    <div class="role-list-item" *ngFor="let r of roles">
      <mat-checkbox
        [(ngModel)]="r.checked"
        (click)="$event.stopPropagation()"
        (change)="updateRoleState($event.checked, r)"
      >
        <div class="feature-name">
          {{ r.name }}
        </div>
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div>
    <button
      mat-flat-button
      color="accent"
      mat-dialog-close
      i18n="@@researches.assign-roles-dialog.cancel"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      mat-dialog-close="save"
      (click)="save()"
      i18n="@@researches.assign-roles-dialog.save"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
