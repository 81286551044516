import { AfterViewInit, Component, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { EvaluatedProcessInstanceCountByDepartment } from '../../models/research-dashboard.model';
import { Filter } from '../../models/filter.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { Subject, combineLatest } from 'rxjs';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

interface OfficeVsEvaluationComponentState {
  paginationState: { pageNumber: number; pageSize: number };
  filter?: Filter;
  evaluatedProcessInstanceCountByDepartment?: PaginatedList<EvaluatedProcessInstanceCountByDepartment>;
}

const initOfficeVsEvaluationComponentState: OfficeVsEvaluationComponentState = {
  paginationState: { pageNumber: 1, pageSize: 5 },
};

@Component({
  selector: 'app-office-vs-evaluation',
  templateUrl: './office-vs-evaluation.component.html',
  styleUrls: ['./office-vs-evaluation.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: OfficeVsEvaluationComponent},RxState],
})
export class OfficeVsEvaluationComponent implements AfterViewInit {
  evaluatedProcessInstanceCountByDepartment$ = this.state.select(
    'evaluatedProcessInstanceCountByDepartment',
  );
  evaluatedProcessInstanceCountByDepartment: EvaluatedProcessInstanceCountByDepartment[] =
    [];

  displayedColumns: string[] = [
    'officeName',
    'totalCount',
    'ongoingCount',
    'passedCount',
    'failedCount',
  ];

  firstPageLabel = $localize`:@@researches.office-vs-evaluation.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.office-vs-evaluation.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.office-vs-evaluation.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.office-vs-evaluation.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.office-vs-evaluation.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.office-vs-evaluation.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.office-vs-evaluation.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.office-vs-evaluation.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    public state: RxState<OfficeVsEvaluationComponentState>,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initOfficeVsEvaluationComponentState);
    this.state.connect('filter', this.researchDashboardFacade.filter$);
    this.state.connect(
      'evaluatedProcessInstanceCountByDepartment',
      this.researchDashboardFacade.evaluatedProcessInstanceCountByDepartment$,
    );
  }
  changes = new RxState();
  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceCountByDepartment();

    combineLatest([
      this.state.select('filter'),
      this.state.select('paginationState'),
    ]).subscribe(([filters, pagination]) => {
      this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceCountByDepartment(
        pagination.pageNumber,
        pagination.pageSize,
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  paginateEvaluatedProcessInstanceCountByDepartment(event: PageEvent) {
    this.state.set({
      paginationState: {
        pageSize: event.pageSize,
        pageNumber: event.pageIndex + 1,
      },
    });
  }
}
