import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { AverageTimeSpentWorkflowTypeStep } from '../models/workflow-step-average-time.model';
import { WorkflowStepAverageTimeService } from '../services/workflow-step-average-time.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { Injectable } from '@angular/core';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { GetWorkflowTypeAverageTimeReport } from './workflow-step-average-time.action';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

export interface WorkflowTypeAverageTimeStateModel {
  workflowTypeStepTimes: PaginatedList<AverageTimeSpentWorkflowTypeStep>;
}

const WorkflowTypeStepAvgTime_STATE_TOKEN =
  new StateToken<WorkflowTypeAverageTimeStateModel>(
    'workflowStepTypeAvgTimeState',
  );

const defaults: WorkflowTypeAverageTimeStateModel = {
  workflowTypeStepTimes: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowTypeAverageTimeStateModel>({
  name: WorkflowTypeStepAvgTime_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowTypeAverageTimeState {
  constructor(
    private store: Store,
    private operationStatus: OperationStatusService,
    private workflowStepAverageTimeService: WorkflowStepAverageTimeService,
  ) {}

  @Action(GetWorkflowTypeAverageTimeReport)
  getReportedComments(
    { patchState }: StateContext<WorkflowTypeAverageTimeStateModel>,
    { pageIdx, pageSize, searchTerm, officeId}: GetWorkflowTypeAverageTimeReport,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepAverageTimeService
      .getWorkflowTypeAverageTimeReport(pageIdx, pageSize, searchTerm, officeId)
      .pipe(
        tap((report: any) => {
          patchState({ workflowTypeStepTimes: report });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
