<div class="search-bar">
  <div class="selector">
    <mat-form-field class="workflow-select">
      <mat-label i18n="@@documents.workflow-type.workflow-type">Workflow type</mat-label>
      <mat-select
        [placeholder]= "placeholderFieldsLabel.workflowTypeAll"
        [formControl]="workflowTypeControl"
        hideSingleSelectionIndicator="true"
      >
        <mat-select-trigger>
          {{ workflowTypeControl.value || "" }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let option of optionsForDisplay; let i = index"
          [value]="options[i]"
        >
          <mat-checkbox
            [checked]="workflowTypeControl.value === options[i]"
          ></mat-checkbox>
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search-form" *appHasPermission="hasSearchWorkflowTypesPermission()">
    <form [formGroup]="searchForm">
      <mat-form-field appearance="outline" class="search-term-input">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [placeholder]= "placeholderFieldsLabel.searchWorkflowType"
          formControlName="workflowTypeName"
          data-test-id="documents.workflow-type.search-workflow-type"
          (input)="onSearchTermChange()"
        />
      </mat-form-field>
    </form>
  </div>
</div>
<div class="flex paginator">
  <mat-paginator
    [length]="isOwnedWorkflowTypePage ? ownedWorkflowTypes?.totalCount : length"
    [pageSize]="
      isOwnedWorkflowTypePage ? ownedWorkflowTypes?.items?.length : pageSize
    "
    [pageIndex]="
      isOwnedWorkflowTypePage ? ownedWorkflowTypes?.pageNumber! - 1 : pageIndex
    "
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="
      isOwnedWorkflowTypePage
        ? loadPaginatedOwnedWorkflowTypes($event)
        : loadPaginatedWorkflowTypes($event)
    "
  >
  </mat-paginator>
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="addWorkflowConfiguration()"
    *appHasPermission="hascreateWorkflowTypesPermission()"
  >
    <span i18n="@@documents.workflow-type.create-workflow-type"> Create Workflow Type </span> <mat-icon>add</mat-icon>
  </button>
</div>

<ng-container *ngIf="workflowTypes">
  <mat-card
    *ngFor="
      let workflowType of isOwnedWorkflowTypePage
        ? ownedWorkflowTypes?.items
        : workflowTypes.items
    "
    class="workflowType-card"
  >
    <mat-card-header class="card-header">
      <div class="title-office">
        <mat-card-title class="card-title">
          {{ workflowType.name }}
        </mat-card-title>
        <div class="office-description">
          <span class="avator">{{ (workflowType.office?.name)![0] }}</span>
          <mat-card-title>{{ workflowType.office?.name }}</mat-card-title>
          <span class="chip mat-caption" i18n="@@documents.workflow-type.owner-office">Owner Office</span>
        </div>
      </div>
      <div class="maticon">
        <button
          mat-icon-button
          (click)="openDeleteConfirmationDialog($event, workflowType)"
          *appHasPermission="hasUpdateWorkflowTypesPermission()"
          [attr.data-test-id]="'documents.workflow.delete-'+workflowType.name+'-button'"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example delete icon"
            fontIcon="delete"
          ></mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="updateWorkflowConfiguration($event, workflowType)"
          [attr.data-test-id]="'documents.workflow.edit-'+workflowType.name+'-button'"
          *appHasPermission="hasUpdateWorkflowTypesPermission()"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Example edit icon"
            fontIcon="edit"
          ></mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <p class="mat-body-2">
        {{ workflowType.description }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button
        class="detail-button"
        mat-stroked-button
        color="primary"
        (click)="workflowTypeDetail(workflowType)"
        *appHasPermission="hasGetWorkflowTypesDetailPermission()"
        [attr.data-test-id]="'documents.workflow-type.'+workflowType.name+'-details-button'"
       i18n="@@documents.workflow-type.workflow-type-detail">
        Workflow type Detail
      </button>
    </mat-card-actions>
  </mat-card>
  <div *ngIf="workflowTypes.items.length === 0" i18n="@@documents.workflow-type.no-workflow-type-found">No work flow types found.</div>
</ng-container>
