<mat-card class="table-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.budget-time-graph.budget-vs-time">Budget vs Time</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div
      echarts
      [options]="chartOption"
      class="chart"
      (chartInit)="onChartInit($event)"
    ></div>
  </mat-card-content>
</mat-card>
