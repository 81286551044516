import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  Publication,
  PublicationStatus,
  PublicationProcess,
  PublicationDocument,
  PublicationStatusForDisplay,
} from 'src/app/researches/models/publication.model';
import { MatDialog } from '@angular/material/dialog';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { PublicationFacade } from 'src/app/researches/facades/publication.facade';
import {
  PublicationOption,
  PublicationTemplate,
} from 'src/app/researches/models/publication-option.model';
import { PublicationOptionFacade } from 'src/app/researches/facades/publication-option.facade';
import { PublicationTemplateDownloadComponent } from '../publication-template-download/publication-template-download.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component'
import {
  faFileWord,
  faFilePdf,
  faImage,
  faFile,
  faFileExcel,
  faFileLines,
  faFileVideo,
  faFileAudio,
} from '@fortawesome/free-solid-svg-icons';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

interface PublicationDetailComponentState {
  publication?: Publication;
  showComments: boolean;
}

const initialPublicationDetailComponentState: PublicationDetailComponentState =
  {
    publication: undefined,
    showComments: false,
  };

@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.component.html',
  styleUrl: './publication-detail.component.scss',
  providers: [RxState],
})
export class PublicationDetailComponent implements OnInit {
  publication$ = this.state.select('publication');
  publication?: Publication;
  showComments = false;
  showComments$ = this.state.select('showComments');
  currentProcessId?: string;

  fileIcons: any = {
    doc: faFileWord,
    docx: faFileWord,
    pdf: faFilePdf,
    png: faImage,
    jpg: faImage,
    file: faFile,
    xlsx: faFileExcel,
    xls: faFileExcel,
    txt: faFileLines,
    mp4: faFileVideo,
    mkv: faFileVideo,
    ogg: faFileVideo,
    webm: faFileVideo,
    mp3: faFileAudio,
    m4a: faFileAudio,
    wav: faFileAudio,
  };

  constructor(
    private publicationFacade: PublicationFacade,
    private publicationOptionFacade: PublicationOptionFacade,
    private dialog: MatDialog,
    private state: RxState<PublicationDetailComponentState>,
    private matDialog: MatDialog,
  ) {
    this.state.connect(
      'publication',
      this.publicationFacade.selectedPublication$,
    );

    this.state.connect(
      'showComments',
      this.publicationFacade.isPublicationCommentVisible$,
    );
  }

  ngOnInit(): void {
    this.publication$?.subscribe((publication) => {
      this.publication = publication;
    });

    this.showComments$?.subscribe((showComments) => {
      this.showComments = showComments;
    });
  }

  getPublicationStatusColor(status?: string) {
    if (status == PublicationStatus.Submitted) {
      return 'submitted-color';
    } else if (status == PublicationStatus.NeedsRevision) {
      return 'revision-color';
    } else if (status == PublicationStatus.Published) {
      return 'published-color';
    } else if (status == PublicationStatus.ReadyForPublication) {
      return 'ready-color';
    } else if (status == PublicationStatus.Cancelled) {
      return 'cancelled-color';
    } else {
      return 'initialized-color';
    }
  }

  downloadTemplates(option: PublicationOption) {
    this.publicationFacade.dispatchSetSelectedPublicationOption(option);
    this.dialog.open(PublicationTemplateDownloadComponent);
  }
  openUploadDialog(id?: string) {
    if (!id) return;
    this.matDialog.open(UploadFileComponent, {
      disableClose: true,
      width: '50%',
      data: { id: id },
    });
  }

  deleteDocument(processId?: string, documentId?: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.publication-detail.delete-document-confirmation:Are you sure you want to remove this document?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (processId && documentId && result === 'confirm') {
        this.publicationFacade.dispatchDeletePublicationProcesDocument(
          processId,
          documentId,
        );
      }
    });
  }

  submitProcessDocuments(processId?: string) {
    if (processId) {
      this.publicationFacade.dispatchSubmitPublicationDocuments(processId);
    }
  }

  changeStatus(id?: string, status?: string) {
    if (id != undefined && status != undefined) {
      if (status == 'ReadyForPublication') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.publication-detail.ready-publication-confirmation: Are you sure you want to change the publication status to ready?`,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'confirm') {
            const statusForDisplay: PublicationStatusForDisplay = {
              publicationStatus:
                PublicationStatus.ReadyForPublication as string,
            };
            this.publicationFacade.dispatchChangePublicationStatus(
              id,
              PublicationStatus.ReadyForPublication,
              statusForDisplay,
            );
          }
        });
      } else if(status=='NeedsRevision'){
        const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.publication-detail.needs-revision-publication-confirmation: Are you sure you want to change the publication status to needs revision?`,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {

          if (result.comment && result.confirmText === 'confirm') {

            this.publicationFacade.dispatchAddPublicationComment(id,result.comment)
            
            const statusForDisplay: PublicationStatusForDisplay = {
              publicationStatus:
                PublicationStatus.NeedsRevision as string,
            };
            this.publicationFacade.dispatchChangePublicationStatus(
              id,
              PublicationStatus.NeedsRevision,
              statusForDisplay,
            );
          }
        });
    }else if (status == 'Cancelled') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.publication-detail.cancel-publication-confirmation:Are you sure you want to cancel this publication?`,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'confirm') {
            const statusForDisplay: PublicationStatusForDisplay = {
              publicationStatus: PublicationStatus.Cancelled as string,
            };
            this.publicationFacade.dispatchChangePublicationStatus(
              id,
              PublicationStatus.Cancelled,
              statusForDisplay,
            );
          }
        });
      }
    }
  }

  toggleComments(processId?: string) {
    if (!processId) return;
    this.currentProcessId = processId;
    this.publicationFacade.dispatchTogglePublicationComments(true);
  }

  extractFileNameAndExtension(fileName: string): any {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex !== -1) {
      const name = fileName.substring(0, lastIndex);
      const extension = fileName.substring(lastIndex + 1);
      return { name, extension };
    } else {
      return { name: fileName, extension: '' };
    }
  }

  getIconForFile(fileName: string): any {
    const extension = fileName.split('.').slice(-1)[0].toLowerCase();
    const iconInfo = this.fileIcons[extension];
    return iconInfo ? iconInfo : this.fileIcons.file;
  }

  downloadPublicationDocument(
    event: MouseEvent,
    document: PublicationDocument,
  ) {
    this.publicationFacade.dispatchDownloadPublicationDocument(document);
    event.stopPropagation();
  }

  previewPublicationDocument(
    publicationProcessId: string | undefined,
    fileId: string,
    fileName:string
  ) {
    this.publicationFacade.dispatchPreviewPublicationDocument(
      publicationProcessId as string,
      fileId,
      fileName
    );
  }

  checkPublicationStatus(publicationProcesses: PublicationProcess): boolean {
    return (
      !publicationProcesses?.publicationDocument?.length ||
      publicationProcesses.publicationStatus === 'Submitted' ||
      publicationProcesses.publicationStatus === 'ReadyForPublication' ||
      publicationProcesses.publicationStatus === 'Cancelled'
    );
  }

  hasSubmitPublicationDocumentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.SubmitPublicationDocuments,
    );
  }

  hasGetPublicationCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.GetPublicationComments,
    );
  }

  hasChangePublicationStatusToReadyForPublicationPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication
        .ChangePublicationStatusToReadyForPublication,
    );
  }
  hasChangePublicationStatusToNeedsRevisionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication
        .ChangePublicationStatusToNeedsRevision,
    );
  }
  hasChangePublicationStatusToCancelledPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication
        .ChangePublicationStatusToCancelled,
    );
  }

  hasDownloadPublicationDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.DownloadPublicationDocument,
    );
  }

  hasGetPublicationDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.GetPublicationDocument,
    );
  }

  hasRemovePublicationDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.RemovePublicationDocument,
    );
  }

  hasUploadPublicationDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.UploadPublicationDocument,
    );
  }
  hasDownloadPublicationTemplatePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.DownloadPublicationTemplate,
    );
  }

  hasCrudPermission(): string[] {
    return [
      this.hasDownloadPublicationDocumentPermission(),
      this.hasRemovePublicationDocumentPermission(),
      this.hasGetPublicationDocumentPermission()
    ]
  }
}
