<div class="container">
  <div>
    <div class="header">
      <h1 class="mat-h1">
        {{ selectedWorkflowType?.name }}
      </h1>
      <div class="office-description">
        <span class="avator" mat-card-avatar>{{
          (selectedWorkflowType?.office?.name)![0]
        }}</span>
        <span>{{ selectedWorkflowType?.office?.name }}</span>
        <span
          class="chip"
          i18n="@@documents.workflow-type-step-list.owner-office"
          >Owner Office</span
        >
      </div>
    </div>
    <p class="mat-body">
      {{ selectedWorkflowType?.description }}
    </p>
  </div>
  <div class="btns" align="end">
    <a routerLink="validations">
      <button
        mat-raised-button
        color="primary"
        *appHasPermission="hasGetWorkflowStepValidationRulesPermission()"
        i18n="@@documents.workflow-type-step-list.view-validation-rules"
      >
        View Validation Rules
      </button>
    </a>
    <button
      mat-raised-button
      color="primary"
      (click)="addWorkflowTypeStep()"
      *appHasPermission="hasCreateWorkflowTypeStepPermission()"
      i18n="@@documents.workflow-type-step-list.add-step"
    >
      Add Step
    </button>
  </div>
  <div class="flex justify-between">
    <h2 class="mat-h2" i18n="@@documents.workflow-type-step-list.steps">Steps</h2>
    <div class="gap-x flex" align="end">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 20, 100]"
        [showFirstLastButtons]="true"
        (page)="loadPaginatedWorkflowTypeSteps($event)"
      >
      </mat-paginator>
    </div>
  </div>
  <ng-container
    *ngIf="(workFlowTypeSteps?.items)!.length > 0; else emptyworkFlowSteps"
  >
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div
        class="steps"
        *ngFor="let workFlowTypeStep of workFlowTypeSteps?.items"
        cdkDrag
      >
        <div class="mb-5">
          <mat-accordion class="step-headers-align">
            <mat-expansion-panel hideToggle class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ workFlowTypeStep.name }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ workFlowTypeStep?.documentTemplateName }}
                </mat-panel-description>
                <button
                 *appHasPermission="hasCrudPermission()"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  (click)="moreActions($event)"
                  [attr.data-test-id]="'documents.workflow-type-step.'+workFlowTypeStep.name+'-menu-button'"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="getWorkflowTypeStepDetail(workFlowTypeStep)"
                    i18n="@@documents.workflow-type-step-list.detail"
                  >
                    Detail
                  </button>
                  <button
                    mat-menu-item
                    (click)="updateWorkflowTypeStep(workFlowTypeStep)"
                    *appHasPermission="hasUpdateWorkflowTypeStepPermission()"
                    i18n="@@documents.workflow-type-step-list.edit"
                  >
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      AddNotifyOfficesToWorkflowTypeStep(workFlowTypeStep)
                    "
                    *appHasPermission="
                      hasAttachNotifyOfficesToWorkflowTypeStepPermission()
                    "
                    i18n="@@documents.workflow-type-step-list.add-cc-office"
                  >
                    Add cc offices
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      openWorkflowStepValidationForm($event, workFlowTypeStep)
                    "
                    *appHasPermission="
                      hasCreateWorkflowStepValidationPermission()
                    "
                    i18n="@@documents.workflow-type-step-list.add-validation"
                  >
                    Add Validation
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      openDeleteConfirmationDialog($event, workFlowTypeStep)
                    "
                    *appHasPermission="hasDeleteWorkflowStepValidationPermission()"
                    i18n="@@documents.workflow-type-step-list.delete"
                  >
                    Delete
                  </button>
                </mat-menu>
              </mat-expansion-panel-header>
              <p class="ml-7">
                <strong i18n="@@documents.workflow-type-step-list.description"
                  >Description:</strong
                >
                {{ workFlowTypeStep.description }}
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyworkFlowSteps>
    <div class="empty-steps-list">
      <span
        class="text-2xl"
        i18n="@@documents.workflow-type-step-list.no-workflow-type-steps-found"
        >No Workflow type steps found</span
      >
    </div>
  </ng-template>
</div>
