import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DocumentsAnalyticsSelector } from '../store/documents-analytics.selector';
import { Observable } from 'rxjs';
import {
  GetWorkflowTypesCount,
  GetWorkflowsCount,
  GetReceivedExternalDocumentsCount,
  GetWorkflowsVsTimeData,
  GetReceivedExternalDocuments,
  GetSentExternalDocuments,
  GetReportedCommentsPercentage,
  GetMyWorkflowsCount,
  GetInvolvedWorkflowsCount,
} from '../store/documents-analytics.actions';
import { WorkflowsVsTime } from '../models/workflows-vs-time.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ReceivedExternalDocument } from '../models/received-external-document.model';
import { SentExternalDocument } from '../models/sent-external-document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAnalyticsFacade {
  @Select(DocumentsAnalyticsSelector.workflowTypesCount)
  workflowTypesCount$!: Observable<number>;

  @Select(DocumentsAnalyticsSelector.workflowsCount)
  workflowsCount$!: Observable<any>;

  @Select(DocumentsAnalyticsSelector.receivedExternalDocumentsCount)
  receivedExternalDocumentsCount$!: Observable<number>;

  @Select(DocumentsAnalyticsSelector.workflowsVsTime)
  workflowsVsTime$!: Observable<WorkflowsVsTime[]>;

  @Select(DocumentsAnalyticsSelector.receivedExternalDocuments)
  receivedExternalDocuments$!: Observable<
    PaginatedList<ReceivedExternalDocument>
  >;

  @Select(DocumentsAnalyticsSelector.sentExternalDocuments)
  sentExternalDocuments$!: Observable<PaginatedList<SentExternalDocument>>;

  @Select(DocumentsAnalyticsSelector.reportedCommentsPercentage)
  reportedCommentsPercentage$!: Observable<number>;

  @Select(DocumentsAnalyticsSelector.myWorkflowsCount)
  myWorkflowsCount$!: Observable<number>;
  
  @Select(DocumentsAnalyticsSelector.involvedWorkflowsCount)
  involvedWorkflowsCount$!: Observable<number>;

  constructor(private store: Store) {}

  dispatchGetWorkflowTypesCount(officeId?: string) {
    this.store.dispatch(new GetWorkflowTypesCount(officeId));
  }

  dispatchGetWorkflowsCount(
    officeId?: string,
    userId?: string,
    startDateTime?: Date,
    endDateTime?: Date,
  ) {
    this.store.dispatch(
      new GetWorkflowsCount(officeId, userId, startDateTime, endDateTime),
    );
  }

  dispatchGetReceivedExternalDocumentsCount(officeId?: string) {
    this.store.dispatch(new GetReceivedExternalDocumentsCount(officeId));
  }

  dispatchGetWorkflowsVsTimeData(
    officeId?: string,
    userId?: string,
    startDateTime?: Date,
    endDateTime?: Date,
    workflowTypeId?: string,
  ) {
    this.store.dispatch(
      new GetWorkflowsVsTimeData(
        officeId,
        userId,
        startDateTime,
        endDateTime,
        workflowTypeId,
      ),
    );
  }

  dispatchGetReceivedExternalDocuments(
    officeId: string,
    pageSize?: number,
    pageNumber?: number,
  ) {
    this.store.dispatch(
      new GetReceivedExternalDocuments(officeId, pageSize, pageNumber),
    );
  }

  dispatchGetSentExternalDocuments(
    officeId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetSentExternalDocuments(officeId, pageNumber, pageSize),
    );
  }

  dispatchGetReportedCommentsPercentage(
    officeId: string,
    workflowTypeId?: string,
  ) {
    this.store.dispatch(
      new GetReportedCommentsPercentage(officeId, workflowTypeId),
    );
  }

  dispatchGetMyWorkflowsCount(
    userId: string,
    officeId?: string,
    startDateTime?: Date,
    endDateTime?: Date,
  ) {
    this.store.dispatch(
      new GetMyWorkflowsCount(userId, officeId, startDateTime, endDateTime),
    );
  }

  dispatchGetInvolvedWorkflowsCount(
    userId: string,
    officeId?: string,
    startDateTime?: Date,
    endDateTime?: Date,
  ) {
    this.store.dispatch(
      new GetInvolvedWorkflowsCount(userId, officeId, startDateTime, endDateTime),
    );
  }
}
