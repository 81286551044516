import { User } from 'src/app/users/models/user.model';

export interface Task {
  id?: string;
  name: string;
  description: string;
  assignedTo: string;
  taskStatus: TaskStatus;
  taskStatusForDisplay?: ATaskStatusForDisplay;
  assignee?: User;
  startDate: Date;
  endDate: Date;
  taskType?: TaskType;
  stageInstanceId: string;
  createdBy?: string;
  isMajorTask: boolean;
}

export interface ATaskStatusForDisplay{
  id: string,
  status: string
}

export interface TaskDocumnet {
  id?: string;
  documentContent: any;
  taskId?: string;
}

export interface UpdateTaskStatusDto {
  id?: string;
  taskStatus: TaskStatus;
  isOwner?: boolean;
}

export interface CreateTaskDto {
  stageInstanceId: string;
  assignedTo: string;
  endDate: string;
  startDate: string;
  name: string;
  description: string;
  taskType?: TaskType;
}

export enum TaskStatus {
  Todo = 'Todo',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Done = 'Done',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum TaskVisibility {
  Visible = 'Visible',
  Private = 'Private',
  Major = 'Major',
}

export interface AssignTaskDto {
  id?: string;
  assignedTo: string;
}

export interface Comment {
  id?: string;
  content: string;
}

export interface TaskComment {
  id?: string;
  content: string;
  created: string;
  createdBy: string;
  fullName: string;
  replies: TaskComment[];
}

export interface TaskType {
  id?: string;
  name: string;
}

export interface FileTaskUploadReport {
  name: string;
  message: string;
  success: boolean;
  fileTask?: TaskDocumnet;
}
