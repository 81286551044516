import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Quill from 'quill';
import { ExternalDocumentFacade } from '../../facades/external-document-workflow.facade';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { Office } from 'src/app/offices/models/office.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { RxState } from '@rx-angular/state';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import {
  AFileDto,
  ExternalDocumentDetailDto,
  ExternalDocumentDto,
} from '../../models/external-document.model';

interface ExternalDocumentFormState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedExternalDocument: ExternalDocumentDetailDto | undefined;
  isFormSuccessFul: boolean
}

const initExternalFlatOfficeFormState: ExternalDocumentFormState = {
  isFormSuccessFul: false,
  selectedFlatOfficeNode: undefined,
  selectedExternalDocument: undefined,
};
@Component({
  selector: 'app-external-document-form',
  templateUrl: './external-document-form.component.html',
  styleUrls: ['./external-document-form.component.scss'],
})
export class ExternalDocumentFormComponent implements OnInit, OnDestroy {
  externalDocumentForm: FormGroup = new FormGroup({});

  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');

  isFormSuccessFul$ = this.state.select('isFormSuccessFul');

  uploadedFiles?: AFileDto[] = [];
  removedFilesIds: string[] = [];
  selectedExternalDocument: ExternalDocumentDetailDto | undefined;
  selectedExternalDocument$ = this.state.select('selectedExternalDocument');

  files: File[] = [];
  showOfficeError: boolean = false;

  isDragOver = false;

  placeholderFieldLabel = {
    externalDocumentDescriptionField: $localize`:@@documents.external-document-form.this-is-the-short-description-for-the-external-document: This is the short description for the external letter`,
}

  constructor(
    private externalDocumentFacade: ExternalDocumentFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: { isUpdate: boolean },
    private state: RxState<ExternalDocumentFormState>,
    private officeFacade: OfficeFacade,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public sanitizerService: SanitizerService,
  ) {
    this.externalDocumentForm = this.fb.group({
      source: ['', Validators.required],
      description: ['', Validators.required],
      subject: ['', Validators.required],
      documentNumber: ['', Validators.required],
      selectedOffice: ['', Validators.required]
    });
    this.state.set(initExternalFlatOfficeFormState);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect(
      'isFormSuccessFul',
      this.externalDocumentFacade.isFormSuccessFul$,
    );
    this.state.connect(
      'selectedExternalDocument',
      this.externalDocumentFacade.selectedExternalDocument$,
    );
  }

  ngOnInit(): void {
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
    this.selectedFlatOfficeNode$.subscribe((office) => {
      this.selectedFlatOfficeNode = office;
      this.externalDocumentForm.controls['selectedOffice'].setValue(office);
      this.showOfficeError = true;
    });

    this.isFormSuccessFul$.subscribe((isFormSuccessFul) => {
      if (isFormSuccessFul) this.dialog.closeAll();
    });

    this.selectedExternalDocument$.subscribe((selectedExDoc) => {
      this.selectedExternalDocument = selectedExDoc;
      if (selectedExDoc) this.uploadedFiles = [...selectedExDoc?.files];
    });
    if (this.selectedExternalDocument) {
      if (this.selectedExternalDocument.creatorOfficeId) {
        this.officeFacade.dispatchSetSelectedFlatOfficeNode(
          this.selectedExternalDocument.creatorOfficeId,
        );
      }
      this.externalDocumentForm.patchValue({
        source: this.selectedExternalDocument?.source,
        description: this.selectedExternalDocument?.description,
        subject: this.selectedExternalDocument?.subject,
        documentNumber: this.selectedExternalDocument?.referenceNumber,
        selectedOffice:this.selectedFlatOfficeNode
      });
      this.files = (this.selectedExternalDocument?.files as any)?.map(
        (aFileDto: AFileDto) => {
          const blob = new Blob([], { type: 'application/octet-stream' });
          const file: File = new File([blob], aFileDto.name, {
            lastModified: new Date(aFileDto.lastModified).getTime(),
          });

          return file;
        },
      );
    }
  }

  ngOnDestroy() {
    this.uploadedFiles = [];
  }

  ngOnChanges(changes: SimpleChanges): void {}
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = await event.dataTransfer?.files;
    if (files) {
      this.files = this.files.concat(Array.from(files));
    }
    this.isDragOver = false;
  }

  get fileControls(): FormArray {
    return this.externalDocumentForm?.get('files') as FormArray;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    if (this.data.isUpdate) {
      this.uploadedFiles?.splice(index, 1);
    }
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.files = this.files.concat(Array.from(event.target.files));
    }
  }

  async uploadFiles() {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('files', file);
    }
  }

  createExternalDocument() {
    if (!this.externalDocumentForm) return;
    const { valid, touched, dirty } = this.externalDocumentForm;
    if (this.selectedFlatOfficeNode === undefined) return;
    if (valid && (touched || dirty)) {
      const { source, documentNumber, subject, description } =
        this.externalDocumentForm.value;
      const formData = new FormData();
      for (const file of this.files) {
        formData.append('Files', file);
      }
      formData.append('Source', source);
      formData.append('DocumentNumber', documentNumber);
      formData.append('Subject', subject);
      formData.append('Description', description);
      formData.append('NextOfficeId', this.selectedFlatOfficeNode.id);
      this.externalDocumentFacade.dispatchCreateExternalDocument(formData);
    }
  }

  openOfficeSelector() {
    this.showOfficeError = true;
    this.dialog.open(OfficeTreeComponent, {
      data: {
        update: false,
      },
    });
  }

  removeSelectedFlatOfficeNode(officeNode: FlatOfficeNode) {
    if (this.selectedFlatOfficeNode === undefined) return;
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(this.selectedFlatOfficeNode);
    this.externalDocumentForm.controls['selectedOffice'].setValue(undefined);
    this.showOfficeError = true;
  }

  cancel() {
    this.dialog.closeAll();
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
    this.externalDocumentFacade.dispatchResetSelectedExternalDocuments();
  }

  updateExternalDocument() {
    if (!this.externalDocumentForm || !this.selectedExternalDocument) return;
    const { valid, touched, dirty } = this.externalDocumentForm;
    if (this.selectedFlatOfficeNode === undefined) return;
    if (valid && (touched || dirty)) {
      const { source, documentNumber, subject, description } =
        this.externalDocumentForm.value;

      const formData = new FormData();
      for (const file of this.files) {
        if (
          this.selectedExternalDocument.files.find(
            (f) => f.name === file.name,
          ) === undefined
        ) {
          formData.append('Files', file);
        }
      }
      formData.append('Source', source);
      formData.append('Id', this.selectedExternalDocument.id);
      formData.append('DocumentNumber', documentNumber);
      formData.append('Subject', subject);
      formData.append('Description', description);
      formData.append('NextOfficeId', this.selectedFlatOfficeNode.id);
      if (this.uploadedFiles?.length ?? 0 > 0) {
        formData.append(
          'UploadedFilesIds',
          this.uploadedFiles!.map((file) => file.id).join(','),
        );
      }
      this.externalDocumentFacade.dispatchUpdateExternalDocument(formData);
    }
  }
}
