import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, of, retry, throwError, timer } from 'rxjs';
import { OperationStatusService } from '../services/operation-status/operation-status.service';
import { NGXLogger } from 'ngx-logger';
import { errorStyle } from '../services/operation-status/status-style-names';
import { ProgressStatusFacade } from '../facades/progress-status.facade';
import { Store } from '@ngxs/store';
import { AuthSelector } from 'src/app/auth/store/auth.selector';
import {
  RefreshToken,
  Logout,
  SetLockedOut,
} from 'src/app/auth/store/auth.actions';
import { REFRESH_TOKEN_URL } from 'src/app/core/constants/api-endpoints';
import { ErrorDetail } from '../constants/requirement_constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private operationStatusService: OperationStatusService,
    private logger: NGXLogger,
    private progressStatusFacade: ProgressStatusFacade,
  ) {}

  shouldRetry(error: any, retryCount: number) {
    if (error.status >= 500) {
      return timer(retryCount * 500);
    }
    throw error;
  }

  extractLockoutDuration(detail: string): number {
    const match = detail.match(/(\d+(\.\d+)?)\s*minutes?/);
    return match ? parseFloat(match[1]) : 0;
  }

  isLockoutError(detail: string): boolean {
    return /Account is locked out\. Try again after \d+(\.\d+)? minutes/.test(
      detail,
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      retry({
        count: 2,
        delay: this.shouldRetry,
      }),
      catchError((error) => {
        this.logger.error(error);

        this.progressStatusFacade.dispatchSetProgessOff();

        let tokens = this.store.selectSnapshot(AuthSelector.tokens);

        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            if (tokens.refreshToken) {
              this.store.dispatch(
                new RefreshToken({ refreshToken: tokens.refreshToken }),
              );
              tokens = this.store.selectSnapshot(AuthSelector.tokens);
              return next.handle(
                request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                  },
                }),
              );
            } else if (error.url?.includes(REFRESH_TOKEN_URL)) {
              this.store.dispatch(new Logout());
            } else {
              this.operationStatusService.displayStatus(
                $localize`:@@core.error.something-went-wrong: Something went wrong,` +
                  `${error.error.title}`,
                errorStyle,
              );
            }
          } else if (error.status == 401 || error.status == 403) {
            if (error.error && error.error.title === ErrorDetail.UnAuthorized) {
              console.log(error.error.detail);
              if (error.error.detail == ErrorDetail.InvalidRefreshToken) {
                this.store.dispatch(new Logout());
              }
              if (error.error.detail == ErrorDetail.LockedOut) {
                this.operationStatusService.displayStatus(
                  $localize`:@@core.error.suspended-from-system-message: You have beed suspended from the system. Please contact your admin.`,
                  errorStyle,
                );
              } else if (this.isLockoutError(error.error.detail)) {
                const lockoutDuration = this.extractLockoutDuration(
                  error.error.detail,
                );
                this.store.dispatch(new SetLockedOut(lockoutDuration));
                this.operationStatusService.displayStatus(
                  'You have been locked out due to multiple failed trials. Try again in one minute.',
                  errorStyle,
                );
              } else {
                this.operationStatusService.displayStatus(
                  `${error.error.detail}`,
                  errorStyle,
                );
              }
            } else if (error.url?.includes(REFRESH_TOKEN_URL)) {
              this.store.dispatch(new Logout());
            } else if (error.statusText == ErrorDetail.UnAuthorized) {
              if (tokens.refreshToken) {
                this.store.dispatch(
                  new RefreshToken({ refreshToken: tokens.refreshToken }),
                );
                tokens = this.store.selectSnapshot(AuthSelector.tokens);
                return next.handle(
                  request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${tokens.accessToken}`,
                    },
                  }),
                );
              } else {
                this.store.dispatch(new Logout());
              }
            } else {
              this.operationStatusService.displayStatus(
                error.error.title,
                errorStyle,
                0,
              );
            }
          } else if (error.status == 400) {
            // handling "One or more validation errors" error,

            if (
              error.error.title == 'You have reached the maximum attempts' ||
              error.error.title == 'Yaalii guddaa irra geesseetta' ||
              error.error.title == 'ከፍተኛ ሙከራዎች ላይ ደርሰዋል' ||
              error.error.title == 'ኣብ ዝለዓለ ፈተነታት በጺሕካ ኣለኻ' ||
              error.error.title == 'Waxaad gaartay isku dayadii ugu badnaa'
            ) {
              this.store.dispatch(new Logout());
            }
            if (error.error.errors) {
              for (var key in error.error.errors) {
                this.operationStatusService.displayStatus(
                  error.error.errors[key][0],
                  errorStyle,
                  0,
                );
                break;
              }
            } else {
              this.operationStatusService.displayStatus(
                error.error.title,
                errorStyle,
                0,
              );
            }
          } else if (error.status == 429) {
            this.operationStatusService.displayStatus(
              `${error.error}`,
              errorStyle,
            );
          } else {
            if (
              error.error instanceof Blob &&
              error.headers
                .get('content-type')
                ?.includes('application/problem+json')
            ) {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const jsonError = JSON.parse(reader.result as string);
                  this.operationStatusService.displayStatus(
                    jsonError.title,
                    errorStyle,
                    0,
                  );
                } catch (parseError) {
                  this.operationStatusService.displayStatus(
                    error.error.title,
                    errorStyle,
                    0,
                  );
                }
              };
              reader.readAsText(error.error);
            } else {
              this.operationStatusService.displayStatus(
                error.error.title,
                errorStyle,
                0,
              );
            }
          }
          return of();
        }
        return throwError(() => {
          this.operationStatusService.displayStatus(
            error.error.title,
            errorStyle,
            0,
          );
        });
      }),
    );
  }
}
