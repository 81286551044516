import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { OfficeFacade } from '../../facades/office.facades';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { ArrayDataSource } from '@angular/cdk/collections';
import { MatRadioChange } from '@angular/material/radio';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

interface OfficesListComponentState {
  flatOfficeNodes: FlatOfficeNode[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
}

const initOfficesListComponentState: Partial<OfficesListComponentState> = {
  flatOfficeNodes: [],
};

@Component({
  selector: 'app-office-tree',
  templateUrl: './office-tree.component.html',
  styleUrls: ['./office-tree.component.scss'],
  providers: [RxState],
})
export class OfficeTreeComponent implements OnInit {
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.state.select('flatOfficeNodes');
  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');

  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  previousSelectedOfficeNode: FlatOfficeNode | null | undefined;
  parentOffice: FlatOfficeNode | undefined;

  officeNodes: Array<FlatOfficeNode> = [];
  smallestLevel: number = 0;

  dataSource = new ArrayDataSource(this.officeNodes);

  constructor(
    private officeFacade: OfficeFacade,
    private dialogRef: MatDialogRef<OfficeTreeComponent>,
    private state: RxState<OfficesListComponentState>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      update?: boolean;
      parentOffice?: FlatOfficeNode;
      userOfficeTree?: boolean;
    },
  ) {
    this.state.set(initOfficesListComponentState);
    this.state.connect('flatOfficeNodes', officeFacade.flatOfficeNodes$);
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    if (!!this.data.update && this.data.parentOffice) {
      this.parentOffice = data.parentOffice;
    }
  }

  ngOnInit(): void {
    if (this.data.userOfficeTree) {
      this.officeFacade.dispatchGetUserOfficeTree();
    } else {
      this.officeFacade.dispatchGetFlatOfficeNodes();
    }
    this.flatOfficeNodes$.subscribe((flatOfficeNodes) => {
      this.officeNodes = flatOfficeNodes;
      this.dataSource = new ArrayDataSource(this.officeNodes);
      this.smallestLevel =
        Math.min(...this.officeNodes.map((node) => node.level)) === Infinity
          ? 0
          : Math.min(...this.officeNodes.map((node) => node.level));
    });

    this.selectedFlatOfficeNode$.subscribe(
      (selectedFlatOfficeNode) =>
        (this.selectedFlatOfficeNode = selectedFlatOfficeNode),
    );
    this.previousSelectedOfficeNode = this.selectedFlatOfficeNode;
  }

  treeControl = new FlatTreeControl<FlatOfficeNode>(
    (node) => node.level - this.smallestLevel,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatOfficeNode) => node.expandable;

  getParentNode(node: FlatOfficeNode) {
    const nodeIndex = this.officeNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.officeNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.officeNodes[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatOfficeNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  selectFlatOfficeNode(event: MatRadioChange, flatOfficeNode: FlatOfficeNode) {
    this.officeFacade.dispatchSelectFlatOfficeNode(flatOfficeNode);
  }
  cancel() {
    if (this.previousSelectedOfficeNode)
      this.officeFacade.dispatchSelectFlatOfficeNode(
        this.previousSelectedOfficeNode,
      );
    else this.officeFacade.dispatchResetSelectedOffice();
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.selectedFlatOfficeNode);
  }

  hasGetFlatOfficeNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetFlatOfficeNodes,
    );
  }
}
