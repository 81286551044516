<div class="container">
  <div class="flex justify-between bg-white">
    <div class="gap-x flex">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 20, 100]"
        [showFirstLastButtons]="true"
        (page)="loadPaginatedWorkflows($event)"
      >
      </mat-paginator>
    </div>
  </div>
  <ng-container *ngIf="(myWorkFlows?.items)!.length > 0; else emptymyWorkflows">
    <div class="my-workflows mb-5" *ngFor="let workFlow of myWorkFlows?.items">
      <mat-accordion class="step-headers-align">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ workFlow.name }}
            </mat-panel-title>
            <mat-panel-description>
              {{ workFlow.description }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list>
            <mat-list-item
              (click)="stepDetail(step)"
              *ngFor="let step of workFlow.workflowSteps"
              >{{ step.name }}</mat-list-item
            >
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
  <ng-template #emptymyWorkflows>
    <div class="empty-workflow-list">
      <span class="" i18n="@@documents.my-workflows.you-are-not-acquainted-or-ccd-to-any-workflow">You are not acquainted or cc'd to any workflow</span>
    </div>
  </ng-template>
</div>
