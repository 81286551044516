<mat-sidenav-container class="container" *ngIf="taskDetail">
  <mat-sidenav-content class="taskDetail">
    <div class="row justify-between padding-hor">
      <h3>
        {{ taskDetail.processInstance.title }}
      </h3>
    </div>
    <div class="btns">
      <button mat-stroked-button color="primary">
        {{ taskDetail.processInstance.process.name }}
      </button>
      <button mat-stroked-button color="primary" (click)="viewDocument()"
      i18n="@@researches.task-detail.view-documents">
        View Documents
      </button>
    </div>
    <div class="padding-hor">
      <h4>
        {{ taskDetail.stageInstance.name }}
      </h4>
    </div>
    <div class="padding-hor">
      <p>
        {{ taskDetail.stageInstance.description }}
      </p>
    </div>
    <div class="close-btn row flex-end">
      <button mat-icon-button (click)="toggleTaskInfo()">
        <mat-icon>{{
          isTaskInfoHidden ? "keyboard_arrow_left" : "close"
        }}</mat-icon>
      </button>
    </div>
    <div class="detail-content row padding-hor">
      <div
        class="left-content column scrollable"
        [ngClass]="{ expanded: !isTaskInfoHidden }"
      >
        <h2 class="mat-h2">{{ taskDetail.name }}</h2>
        <ng-container>
          <mat-radio-group
            color="primary"
            [(ngModel)]="composingOption"
            *ngIf="
              !processInstanceDetail?.isArchived &&
              (isCurrentUserAssignee | async)
            "
          >
            <mat-radio-button
              *appHasPermission="hasCreateDocumentTaskPermission()"
              value="compose"
              i18n="@@researches.task-detail.compose"
              [attr.data-test-id]="'researches.compose-document.task-detail.compose-radio-button'"
              >Compose</mat-radio-button
            >
            <mat-radio-button
              *appHasPermission="hasCreateFileTaskPermission()"
              value="upload"
              i18n="@@researches.task-detail.upload-file"
              [attr.data-test-id]="'researches.upload-file.task-detail.upload-file-button'"
              >Upload File</mat-radio-button
            >
          </mat-radio-group>
          <ng-container *appHasPermission="hasCreateDocumentTaskPermission()">
            <app-task-compose
              [taskId]="taskDetail.id ?? ''"
              [taskDetail]="taskDetail"
              [assigneeId]="taskDetail.assignee?.id ?? ''"
              *ngIf="
                composingOption === 'compose'
              "
            ></app-task-compose>
          </ng-container>
          <ng-container *appHasPermission="hasCreateFileTaskPermission()">
            <div
              class="upload-file padding-vert"
              *ngIf="
                composingOption === 'upload' && (isCurrentUserAssignee | async)
              "
            >
              <app-task-file-upload />
            </div>
          </ng-container>
          <h2 class="mat-h2" *ngIf="taskFiles?.length">
            <span
              i18n="@@researches.task-detail.files-uploaded"
              *appHasPermission="hasGetFileTasksByTaskPermission()"
              >Files Uploaded</span
            >
            ({{ taskFiles?.length }})
          </h2>
          <mat-chip-listbox
            class="mat-mdc-chip-set-stacked"
            *appHasPermission="hasGetFileTasksByTaskPermission()"
          >
            <mat-chip
              class="truncated-chip"
              *ngFor="let file of taskFiles"
              (removed)="removeTaskFile(file)"
              [attr.data-test-id]="'researches.get-uploaded-file.task-detail.mat-chip'"
            >
              <fa-icon
                matChipAvatar
                [icon]="getIconForFile(file.name)"
                class="file-icon"
              ></fa-icon>
              <span>
                {{
                  extractFileNameAndExtension(file.name).name.length > 25
                    ? extractFileNameAndExtension(file.name).name.slice(0, 25) +
                      "..."
                    : extractFileNameAndExtension(file.name).name
                }}.{{ extractFileNameAndExtension(file.name).extension }}
              </span>
              <button matChipRemove [attr.data-test-id]="'researches.delete-uploaded-file.task-detail.cancle-button'">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>

          <div
            class="submit-btn"
            *ngIf="
              !processInstanceDetail?.isArchived &&
              taskDetail.taskStatus.toString() != TaskStatus.Approved
            "
          >
            <ng-container
              *appHasPermission="hasChangeStatusToSubmittedPermission()"
            >
              <button
                *ngIf="
                  !isOwnersTask() &&
                  taskDetail.assignee?.id === currentLoggedInUser?.id
                "
                mat-flat-button
                color="primary"
                class="custom-button"
                (click)="submitTask(false)"
                [disabled]="
                  taskDetail === null ||
                  taskDetail === undefined ||
                  (!taskDetail.taskFile && !taskDetail.taskDocument) ||
                  taskDetail.taskStatus.toString() === TaskStatus.Submitted ||
                  taskDetail.taskStatus.toString() === TaskStatus.Approved
                "
                i18n="@@researches.task-detail.submit-task"
                [attr.data-test-id]="'researches.submitting-task.task-detail.submit-task-button'"
              >
                Submit Task
              </button>
            </ng-container>
          </div>
        </ng-container>
        <h4>
          <span i18n="@@researches.task-detail.comments">Comments</span> ({{
            taskComments?.totalCount
          }})
        </h4>
        <div *ngIf="taskComments?.items?.length! > 0; else emptyTaskComments">
          <ng-container *appHasPermission="hasGetTaskCommentsPermission()">
            <app-task-comment
              *ngFor="let taskComment of taskComments?.items"
              [taskComment]="taskComment"
              [toggleReplyBoxVisibility]="toggleReplyBoxVisibility"
              [toggleReplyBox]="toggleReplyBox"
              [cancelReplyBox]="cancelReplyBox"
              [attr.data-test-id]="'researches.view-comment.task-detail.app-task-comment'"
            ></app-task-comment>
          </ng-container>
        </div>
        <ng-template #emptyTaskComments>
          <div class="empty-task-comments">
            <span class="text-2xl" i18n="@@researches.task-detail.no-comments"
              >No comments</span
            >
          </div>
        </ng-template>

        <div *ngIf="taskComments?.totalCount! > 5">
          <button
            mat-stroked-button
            color="primary"
            class="see-comments-btn custom-button"
            (click)="getPaginatedComments()"
            *appHasPermission="hasGetTaskCommentsPermission()"
          >
            <span
              *ngIf="
                taskComments?.totalCount !== taskComments?.items?.length &&
                taskComments?.totalCount !== 0
              "
              i18n="@@researches.task-detail.view-more-comments"
              >View More Comments</span
            >
            <span
              *ngIf="
                taskComments?.totalCount === taskComments?.items?.length ||
                taskComments?.totalCount === 0
              "
              i18n="@@researches.task-detail.view-less-comments"
              >View Less Comments</span
            >
          </button>
        </div>

        <div class="comment-form" *ngIf="!processInstanceDetail?.isArchived">
          <form
            [formGroup]="commentForm"
            *appHasPermission="hasCreateTaskCommentPermission()"
          >
            <mat-form-field appearance="outline" class="padding-vert">
              <input
                id="name"
                formControlName="comment"
                type="text"
                #input
                maxlength="1000"
                matInput
                [placeholder]="placeholderToggleLabel.addComment"
                [attr.data-test-id]="'researches.add-comment.task-detail.comment-input'"
              />
              <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div
          class="comment-btns row"
          *ngIf="!processInstanceDetail?.isArchived"
        >
          <button
            mat-flat-button
            class="custom-button"
            (click)="commentForm.reset()"
            [disabled]="commentForm.controls['comment'].value === ''"
            i18n="@@researches.task-detail.cancel"
            *appHasPermission="hasCreateTaskCommentPermission()"
          >
            Cancel
          </button>
          <button
            [disabled]="!commentForm.valid"
            mat-flat-button
            color="primary"
            class="custom-button"
            (click)="addComment()"
            *appHasPermission="hasCreateTaskCommentPermission()"
            i18n="@@researches.task-detail.comment"
            [attr.data-test-id]="'researches.add-comment.task-detail.comment-button'"
          >
            Comment
          </button>
        </div>
        <div class="row justify-center mt-30">
          <button
            mat-flat-button
            (click)="goToPreviousTask()"
            class="custom-button"
            [disabled]="currentTaskIndex === 0"
          >
            <mat-icon>chevron_left</mat-icon>
            <span i18n="@@researches.task-detail.previous-task"
              >Previous Task</span
            >
          </button>
          <button
            mat-flat-button
            (click)="goToNextTask()"
            color="primary"
            class="custom-button"
            [disabled]="currentTaskIndex === taskList.length - 1"
          >
            <span i18n="@@researches.task-detail.next-task">Next Task </span
            ><mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div
        class="right-content column"
        [ngClass]="{ hidden: isTaskInfoHidden }"
      >
        <h2 class="mat-h2" i18n="@@researches.task-detail.task-overview">
          Task Overview
        </h2>
        <div class="task-info column">
          <div class="row">
            <form [formGroup]="assigneeForm" class="row">
              <p i18n="@@researches.task-detail.assignee">Assignee :</p>
              <div class="assignee-container">
                <button
                  mat-mini-fab
                  color="primary"
                  class="assignee-avatar"
                  [matTooltip]="selectedName"
                >
                  {{ selectedName.substring(0, 1) }}
                </button>
                <div
                  class="assignee-name"
                  *ngIf="!isAssigningTask"
                  (click)="toggleIsAssigningTask()"
                >
                  {{ selectedName }}
                </div>
                <mat-form-field
                  appearance="outline"
                  class="assignee-form"
                  *ngIf="isAssigningTask"
                >
                  <input
                    #assigneeInput
                    matInput
                    [formControl]="nameControl"
                    formControlName="assignee"
                    [matAutocomplete]="auto"
                    (input)="filterNames()"
                    [readonly]="processInstanceDetail?.isArchived"
                    (keyup.enter)="handleAssigneeBlurAndKeyup()"
                    (blur)="handleAssigneeBlurAndKeyup()"
                    autofocus
                    *appHasPermission="hasAssignTaskPermission()"
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayAssigneeName"
                    (optionSelected)="onNameSelected($event)"
                  >
                    <mat-option
                      *ngFor="let name of filteredNames"
                      [value]="name"
                    >
                      {{ name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </form>
          </div>
          <div class="row">
            <p i18n="@@researches.task-detail.status">Status :</p>
            <button
              *appHasPermission="
                hasChangeStatusToInProgressPermission() ||
                hasChangeStatusToTodoPermission()
              "
              mat-flat-button
              color="primary"
              class="custom-button"
              [ngClass]="getTaskStatusColor(taskDetail.taskStatus)"
              (click)="toggleStatusDropdown()"
              [matMenuTriggerFor]="menu"
              [disabled]="processInstanceDetail?.isArchived || !(taskDetail.assignee?.id === currentLoggedInUser?.id)"
            >
              {{ checkTaskStatus(taskDetail.taskStatus) }}
              <mat-icon>{{
                isStatusDropdownOpen
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu" (closed)="toggleStatusDropdown()">
              <button
                mat-menu-item
                *ngFor="let status of statuses"
                (click)="selectStatus(status)"
              >
                {{ checkTaskStatus(status) }}
              </button>
            </mat-menu>
          </div>
          <div class="row">
            <p>
              <span i18n="@@researches.task-detail.start-date">Start Date</span>
              : {{ taskDetail.startDate }}
            </p>
          </div>
          <div class="row">
            <p>
              <span i18n="@@researches.task-detail.end-date">End Date</span> :
              {{ taskDetail.endDate }}
            </p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="description padding-vert">
          <h4 i18n="@@researches.task-detail.task-description">
            Task Description
          </h4>
          <p>{{ taskDetail.description }}</p>
        </div>
        <mat-divider></mat-divider>

        <div *ngIf="taskDetail.createdBy" class="creator">
          <span i18n="@@researches.task-detail.created-by"> Created By: </span> {{ taskDetail.createdBy.fullName }}
        </div>

        <mat-divider></mat-divider>

        <div *ngIf="taskDetail.taskStatus.toString() != TaskStatus.Approved">
          <div
            class="approve-btns row padding-vert"
            *ngIf="
              !processInstanceDetail?.isArchived && isOwnersActionApprove()
            "
          >
            <button
              mat-flat-button
              color="primary"
              class="custom-button"
              (click)="approveTask()"
              [disabled]="
                taskDetail.taskStatus.toString() != TaskStatus.Submitted
              "
              *appHasPermission="hasChangeStatusToApprovedPermission()"
              i18n="@@researches.task-detail.approve-task"
              [attr.data-test-id]="'researches.approve-task.task-detail.approve-task-button'"
            >
              Approve Task
            </button>
            <button
              mat-flat-button
              class="custom-button"
              color="accent"
              (click)="RejectTask()"
              [disabled]="
                taskDetail.taskStatus.toString() != TaskStatus.Submitted ||
                taskDetail.taskStatus.toString() == TaskStatus.Rejected
              "
              *appHasPermission="hasChangeStatusToRejectedPermission()"
              i18n="@@researches.task-detail.needs-revision-task"
              [attr.data-test-id]="'researches.rejection-of-a-task.task-detail.needs-revision-button'"
            >
              Needs Revision
            </button>
          </div>
        </div>
        <button
          *ngIf="
            !processInstanceDetail?.isArchived &&
            taskDetail.assignee?.id === currentLoggedInUser?.id &&
            isOwnersActionDone()
          "
          mat-flat-button
          color="primary"
          class="custom-button"
          (click)="submitTask(true)"
          [disabled]="
            taskDetail === null ||
            taskDetail === undefined ||
            (!taskDetail.taskFile && !taskDetail.taskDocument) ||
            taskDetail.taskStatus.toString() === TaskStatus.Submitted ||
            taskDetail.taskStatus.toString() === TaskStatus.Approved ||
            taskDetail.taskStatus.toString() === TaskStatus.Done
          "
          i18n="@@researches.task-detail.done"
          [attr.data-test-id]="'researches.task-overview.task-detail.done-button'"
        >
          Done
        </button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
