import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import PermissionGuardService, { GuardLevel } from './permission.guard.service';

export const HasModulePermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(PermissionGuardService).canActivate(
    route,
    state,
    GuardLevel.Module,
  );
};

export const HasFeaturePermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(PermissionGuardService).canActivate(
    route,
    state,
    GuardLevel.Feature,
  );
};

export const HasPermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(PermissionGuardService).canActivate(
    route,
    state,
    GuardLevel.Permission);
};
