import { Component, OnInit } from '@angular/core';
import {
  AFileDto,
  ExternalDocumentDetailDto,
  ExternalDocumentResponseDetailDto,
  ExternalDocumentResponseDto,
  RemarkActionStatusForDisplay,
  RemarkStatus,
  ResponseStatus,
} from '../../models/external-document.model';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { ExternalDocumentFacade } from '../../facades/external-document-workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { ExternalDocumentRemarkFormComponent } from '../external-document-remark-form/external-document-remark-form.component';
import { ExternalDocumentResponseFormComponent } from '../../external-document-response-form/external-document-response-form.component';
import {
  EXTERNAL_DOCUMENT_RESPONSE_ROUTE,
  EXTERNAL_DOCUMENT_ROUTE,
} from 'src/app/core/constants/routes';
import Quill from 'quill';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { ExternalDocumentResponseRemarkFormComponent } from '../external-document-response-remark-form/external-document-response-remark-form.component';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from "../../../../users/models/user.model";
import { UserFacade } from "../../../../users/facade/user.facade";
import { Observable } from 'rxjs';

interface ExternalDocumentResponseDetailComponentState {
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;
  remarkActionStatusForDisplay : RemarkActionStatusForDisplay[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  selectedExternalDocumentResponse: ExternalDocumentResponseDto | undefined;
}

const initExternalDocumentResponseDetailComponentState: ExternalDocumentResponseDetailComponentState =
  {
    remarkActionStatusForDisplay:[],
    externalDocumentResponseDetail: undefined,
    currentLoggedInUser: undefined,
    selectedExternalDocumentResponse: undefined,
  };

@Component({
  selector: 'app-external-document-response-detail',
  templateUrl: './external-document-response-detail.component.html',
  styleUrls: ['./external-document-response-detail.component.scss'],
})
export class ExternalDocumentResponseDetailComponent {
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;
  externalDocumentResponseDetail$ = this.state.select(
    'externalDocumentResponseDetail',
  );


  remarkActionStatusForDisplay: RemarkActionStatusForDisplay[] = [];
  remarkActionStatusForDisplay$ = this.state.select(
    'remarkActionStatusForDisplay',
  );
   selectedExternalDocumentResponse$: Observable<ExternalDocumentResponseDto | undefined> | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  editor?: Quill;
  initialEditorContent: any;

    parseAndConvertBodyToString(body: string | undefined | null): string {
    if (body) {
      try {
        const delta = JSON.parse(body);
        return this.convertQuillDeltaToString(delta);
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    }
    return '';
  }
  

  convertQuillDeltaToString(delta: any): string {
    if (delta && delta.ops) {
      return delta.ops.map((op: any) => op.insert).join('');
    }
    return '';
  }

  constructor(
    private state: RxState<ExternalDocumentResponseDetailComponentState>,
    private externalDocumentFacade: ExternalDocumentFacade,
    private userFacade: UserFacade,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.state.set(initExternalDocumentResponseDetailComponentState);
    this.state.connect(
      'externalDocumentResponseDetail',
      this.externalDocumentFacade.externalDocumentResponseDetail$,
    );
    this.selectedExternalDocumentResponse$ = this.state.select('selectedExternalDocumentResponse');
    this.state.connect('currentLoggedInUser', this.userFacade.currentLoggedInUser$);

    this.state.connect(
      'remarkActionStatusForDisplay',
      this.externalDocumentFacade.remarkActionForDisplay$,
    );

    this.selectedExternalDocumentResponse$.subscribe(externalDocumentResponse => {
      if(externalDocumentResponse){
      this.externalDocumentFacade.dispatchGetExternalDocumentResponseDetail(externalDocumentResponse.id)
      }
    });
    this.externalDocumentResponseDetail$.subscribe(responseDetail=>{
      if(responseDetail){
        this.externalDocumentResponseDetail=responseDetail;
      }
    }
    )


    this.currentLoggedInUser$.subscribe((currUser)=>{
      this.currentLoggedInUser = currUser;
    })

    this.externalDocumentFacade.dispatchGetRemarkActionStatus();
    this.remarkActionStatusForDisplay$.subscribe((remarkActions) => {
      this.remarkActionStatusForDisplay = remarkActions;
    });

    this.editor = new Quill('#quill-container', {
      theme: 'bubble',
      readOnly: true,
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });
  }

  openResponseForm() {
    if (!this.externalDocumentResponseDetail) return;
    this.externalDocumentResponseDetail$.subscribe((responseDetail) => {
      if (responseDetail) {
        this.router.navigate([
          `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}`,
          responseDetail.id,
        ]);
      }
    });
  }


   handleStatus(status: string) {
    if (!this.externalDocumentResponseDetail || !status) return;
    const st = RemarkStatus[status as keyof typeof RemarkStatus];
    this.dialog.open(ExternalDocumentResponseRemarkFormComponent, {
      data: {
        status: st,
        externalDocumentId: this.externalDocumentResponseDetail?.id,
      },
    });
  }

  getDateFromString(strDate?: string) {
    if (!strDate) return;
    return new Date(strDate).toUTCString();
  }
  showFilesSection() {
    return (
      this.externalDocumentResponseDetail?.files !== undefined &&
      (this.externalDocumentResponseDetail?.files?.length ?? 0) > 0
    );
  }
  openDeleteConfirmationDialog() {
    if (!this.externalDocumentResponseDetail) return;
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: this.externalDocumentResponseDetail?.subject,
          message: $localize`:@@documents.external-document-response-detail.delete-response:Delete response with subject` + ` ${this.externalDocumentResponseDetail?.subject} `,
          regularTextOne: $localize`:@@documents.external-document-response-detail.delete-response-description:This action is irreversible. Are you sure you want to delete this response?`,
          extraTextOne: $localize`:@@documents.external-document-response-detail.delete-response-extra-part-1:To confirm, type` ,
          extraTextBold: ` ${this.externalDocumentResponseDetail?.subject} ` ,
          extraTextTwo: $localize`:@@documents.external-document-response-detail.delete-response-part-2:below:`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (!this.externalDocumentResponseDetail?.id) return;
        this.externalDocumentFacade.dispatchDeleteExternalDocumentResponse(
          this.externalDocumentResponseDetail.id,
        );
        this.router.navigate([
          EXTERNAL_DOCUMENT_ROUTE,
          this.externalDocumentResponseDetail.id,
        ]);
      }
    });
  }
  downloadResponseAttachment(file: AFileDto) {
    this.externalDocumentResponseDetail$.subscribe((responseDetail) => {
      if (responseDetail) {
        this.externalDocumentFacade.dispatchDownloadResponseAttachment(
          responseDetail.id,
          file.id,
          file.name,
        );
      }
    });
  }

  showActionButton(){
    const lastRemarkAction = this.externalDocumentResponseDetail?.remarkActions[this.externalDocumentResponseDetail?.remarkActions.length - 1];
    return lastRemarkAction?.toOffice?.id === this.currentLoggedInUser?.officeId
  }

  viewRemarkStatus(remarkStatus:string){
    switch (remarkStatus){
      case "Approved" :
        return "Approve";
      case "Rejected":
        return "Reject";
      case "RollBacked":
        return "RollBack";
      case "Received":
        return "Receive";
      default:
        return remarkStatus;
    }
  }
  previewResponseAttachment(file: AFileDto) {
    this.externalDocumentResponseDetail$.subscribe((responseDetail) => {
      if (responseDetail) {
        this.externalDocumentFacade.dispatchPreviewResponseAttachment(
          responseDetail.id,
          file.id,
          file.name,
        );
      }
    });
  }

  hasDeleteExternalDocumentResponsePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.DeleteExternalDocumentResponse,
    );
  }

  protected readonly responseStatus = ResponseStatus;
  protected readonly remarkStatusEnum = RemarkStatus;
}
