import { UpdateStructureComponent } from './components/update-structure/update-structure.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfficeListComponent } from './components/office-list/office-list.component';
import {
  ARCHIVED_WORKSPACES_ROUTE,
  CREATE_OFFICES_ROUTE,
  OFFICES_DASHBOARD_ROUTE,
  OFFICES_LIST_ROUTE,
  OFFICES_MULTIPLE_SELECT_ROUTE,
  OFFICES_TREE_ROUTE,
  OFFICE_ORGANOGRAM_ROUTE,
  UPDATE_OFFICE_STRUCTURE_ROUTE,
} from '../core/constants/routes';
import { OfficesHomeComponent } from './components/offices-home/offices-home.component';
import { OfficeTreeComponent } from './components/office-tree/office-tree.component';
import { OfficeFormComponent } from './components/office-form/office-form.component';
import { OfficeMultipleSelectComponent } from './components/office-multiple-select/office-multiple-select.component';
import { OfficeOrganogramComponent } from './components/office-organogram/office-organogram.component';
import { ArchivedWorkspacesListComponent } from './components/archived-workspaces/archived-workspaces-list/archived-workspaces-list.component';
import { ArchivedWorkspaceDetailComponent } from './components/archived-workspaces/archived-workspace-detail/archived-workspace-detail.component';
import { ArchivedWorkspaceFilesComponent } from './components/archived-workspaces/archived-workspace-files/archived-workspace-files.component';
import { MODULES, PERMISSION_NAMES } from '../core/constants/permissions';
import { HasPermissionGuard } from '../auth/services/has.permission.guard';

const routes: Routes = [
  {
    path: '',
    component: OfficesHomeComponent,
    children: [
      {
        path: OFFICES_LIST_ROUTE,
        component: OfficeListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.GetOffices}`},
      },
      {
        path: OFFICES_TREE_ROUTE,
        component: OfficeTreeComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.GetOfficeTree}`},
      },
      {
        path: CREATE_OFFICES_ROUTE,
        component: OfficeFormComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.CreateOffice}`},
      },
      {
        path: OFFICES_MULTIPLE_SELECT_ROUTE,
        component: OfficeMultipleSelectComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.GetOffices}`},
      },
      {
        path: OFFICE_ORGANOGRAM_ROUTE,
        component: OfficeOrganogramComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.GetOfficeTree}`},
      },
      {
        path: ARCHIVED_WORKSPACES_ROUTE,
        component: ArchivedWorkspacesListComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.FILES}:${PERMISSION_NAMES.Files.Workspace.Feature}:${PERMISSION_NAMES.Files.Workspace.GetArchivedWorkspaces}`},
      },
      {
        path: UPDATE_OFFICE_STRUCTURE_ROUTE,
        component: UpdateStructureComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.OFFICES}:${PERMISSION_NAMES.Offices.Office.Feature}:${PERMISSION_NAMES.Offices.Office.UpdateOfficeTree}`},
      },
      {
        path: `${ARCHIVED_WORKSPACES_ROUTE}/:id`,
        component: ArchivedWorkspaceDetailComponent,
        canActivate: [HasPermissionGuard],
        data: { permission: `${MODULES.FILES}:${PERMISSION_NAMES.Files.Workspace.Feature}:${PERMISSION_NAMES.Files.Workspace.GetArchivedWorkspaces}`},
        children: [
          {
            path: ':folderId',
            component: ArchivedWorkspaceFilesComponent,
          },
        ],
      },
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: OFFICES_DASHBOARD_ROUTE,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfficesRoutingModule {}
