<div class="container">
  <div>
    <div class="header">
      <h1>
        {{ workflowtypeStep?.name }}
      </h1>
    </div>
    <p>
      {{ workflowtypeStep?.description }}
    </p>
      <div class="flex">
        <h4>Owner Office : </h4> 
        <p>{{ workflowtypeStep?.ownerOfficeDto?.name }}</p>
      </div>
      <div class="flex">
        <h4>Owner Role : </h4>
        <p>{{ workflowtypeStep?.ownerRole?.name }}</p>
      </div>
      <h4 class="cc-title">Cc'd offices :</h4>
      <mat-list *ngIf="workflowtypeStep?.ccdOffices?.length ?? 0 > 0;else emptyCcOffices" class="cc-list">
        <mat-list-item *ngFor="let office of workflowtypeStep?.ccdOffices">
          <span matListItemTitle>{{office?.name}}</span>
          <span matListItemLine>{{office.shortName}}</span>
        </mat-list-item>
      </mat-list>
      <ng-template #emptyCcOffices>
        <p matListItemLine class="empty-offices">No cc'd offices</p>
      </ng-template>
      
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="workflowtypeStep">
    <app-workflow-step-validations [workflowTypeStep]="workflowtypeStep"/>
  </ng-container>
  <ng-container>
    <div class="template-detail-wrapper">
      <div class="fields">
        <div class="template-head">
          <h2
            *ngIf="(workflowtypeStep?.templateFields)!.length > 0"
            class="mat-h1"
          >
            Fields
          </h2>
        </div>
        <mat-card
          *ngFor="let field  of workflowtypeStep?.templateFields"
          class="field-card"
        >
          <mat-card-header class="card-header">
            <mat-card-title-group>
              <mat-card-title>
                {{ field.name }}
              </mat-card-title>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content>
            <p>
              <span class="mat-subtitle" i18n="@@documents.document-form-detail.field-type">Filed Type:</span>
              <span class="chip mat-caption">{{ field.dataType }}</span>
            </p>
            <p>
              <span class="mat-subtitle" i18n="@@documents.document-form-detail.place-holder">Place holder:</span>
              <span> {{ field.placeholder }}</span>
            </p>
            <p>
              <span class="mat-subtitle" i18n="@@documents.document-form-detail.description">Description:</span>
              <span class="mat-body-2">{{ field.description }}</span>
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
