import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  STAGE_INSTANCE_TASK_URL,
  TASK_COMMENTS_REPLY_URL,
  TASK_COMMENTS_ROOT_URL,
  TASK_COMMENTS_URL,
  TASK_URL,
  TASK_FILES_URL,
  API_BASE_URL,
  STAGE_INSTANCES_URL,
} from 'src/app/core/constants/api-endpoints';
import { Observable } from 'rxjs';
import {
  PROCESS_TASK_URL,
  TASK_DOCUMENT_URL,
} from 'src/app/core/constants/api-endpoints';
import { ProcessTasks } from '../models/process-tasks.model';
import { TASKS_URL } from 'src/app/core/constants/api-endpoints';
import {
  TaskDocumnet,
  UpdateTaskStatusDto,
  CreateTaskDto,
  Task,
  TaskComment,
  AssignTaskDto,
  TaskType,
  FileTaskUploadReport,
  TaskStatus,
} from '../models/task.model';
import { TaskDetail } from '../models/taskDetail.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TaskDocument } from '../models/task-document.model';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  deleteTask(id: string): Observable<any> {
    return this.http.delete<any>(`${TASK_URL}/${id}`, this.httpOptions);
  }

  getTasksByName(name: string, stageInstanceId: string): Observable<Task[]> {
    const query = new URLSearchParams({
      stageInstanceId,
      name,
    });

    return this.http.get<Task[]>(
      `${STAGE_INSTANCE_TASK_URL}?${query.toString()}`,
    );
  }

  getTasksBySearchTerm(
    searchTerm: string,
    processIds: string[],
  ): Observable<ProcessTasks[]> {
    const processIdsParam = processIds.join(',');
    const url = `${TASK_URL}/search-task?processInstanceIds=${processIdsParam}&keyword=${searchTerm}`;
    return this.http.get<ProcessTasks[]>(url);
  }
  getProcessTasks(processId: string): Observable<ProcessTasks> {
    return this.http.get<ProcessTasks>(`${TASK_URL}/process/${processId}`);
  }

  getAssignedTasks(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(
      `${TASK_URL}/assigned?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  uploadTaskFile(
    file: FormData,
    taskId: string,
  ): Observable<FileTaskUploadReport[]> {
    return this.http.post<FileTaskUploadReport[]>(
      `${TASK_FILES_URL}/?taskId=${taskId}`,
      file,
    );
  }
  DeleteFileTask(id: string): Observable<any> {
    return this.http.delete<any>(`${TASK_FILES_URL}/${id}`, this.httpOptions);
  }
  createTaskDocument(taskDoc: TaskDocumnet): Observable<TaskDocumnet> {
    return this.http.post<TaskDocumnet>(
      `${TASK_DOCUMENT_URL}/?taskId=${taskDoc.taskId}`,
      {
        documentContent: JSON.stringify(taskDoc.documentContent),
      },
    );
  }

  updateTaskDocuemnt(document: TaskDocumnet): Observable<TaskDocumnet> {
    return this.http.put<TaskDocumnet>(
      `${TASK_DOCUMENT_URL}/${document.taskId}`,
      {
        documentContent: JSON.stringify(document.documentContent),
      },
    );
  }

  updateTask(task: Task): Observable<Task> {
    return this.http.put<Task>(`${TASK_URL}`, task);
  }
  DeleteDocumentTask(id: string): Observable<any> {
    return this.http.delete<any>(
      `${TASK_DOCUMENT_URL}/${id}`,
      this.httpOptions,
    );
  }

  createTask(task: CreateTaskDto): Observable<Task> {
    return this.http.post<Task>(
      `${TASK_URL}?stageInstanceId=${task.stageInstanceId}`,
      task,
    );
  }

  getTaskDetail(taskId: string): Observable<TaskDetail> {
    return this.http.get<TaskDetail>(`${PROCESS_TASK_URL}/${taskId}`);
  }

  getPaginatedTaskComments(
    taskId: string,
    page: number,
    size: number,
  ): Observable<any> {
    return this.http.get<TaskComment>(
      `${TASK_COMMENTS_URL}/${taskId}?pageNumber=${page}&pageSize=${size}`,
    );
  }

  addTaskComment(taskId: string, comment: string) {
    return this.http.post<TaskComment>(`${TASK_COMMENTS_ROOT_URL}`, {
      taskId,
      content: comment,
    });
  }

  updateTaskComment(taskCommentId: string, content: string) {
    return this.http.put<TaskComment>(`${TASK_COMMENTS_ROOT_URL}`, {
      taskCommentId,
      content,
    });
  }

  assignTask(assignTask: AssignTaskDto): Observable<any> {
    return this.http.put<any>(`${PROCESS_TASK_URL}/Assign-Task`, assignTask);
  }

  replyTaskComment(commentId: string, content: string) {
    return this.http.post<TaskComment>(`${TASK_COMMENTS_REPLY_URL}`, {
      commentId,
      content,
    });
  }

  changeTaskStatus(updatedTask: UpdateTaskStatusDto): Observable<any> {
    let url = `${PROCESS_TASK_URL}/`
    if (updatedTask) {
      switch(updatedTask.taskStatus) {
        case TaskStatus.Todo:
          url = url + 'change-status-todo'
          break;
        case TaskStatus.InProgress:
          url = url + 'change-status-to-inprogress'
          break;
        case TaskStatus.Submitted:
          url = url + 'change-status-submitted'
          break;
        case TaskStatus.Rejected:
          url = url + 'change-status-needs-revision'
          break;
        case TaskStatus.Approved:
          url = url + 'change-status-approved'
          break;
        case TaskStatus.Done:
          url = url + 'change-status-done'
          break;
      }
    }
    return this.http.put<any>(`${url}?Id=${updatedTask.id}`, this.httpOptions);
  }

  deleteTaskComment(commentId: string) {
    return this.http.delete<any>(`${TASK_COMMENTS_ROOT_URL}/${commentId}`);
  }
  getTaskDocument(
    fileId: string,
    table: string,
    documentDownloadType: string,
    documentOperationType?: string,
  ): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url = `${API_BASE_URL}/file-tasks/${fileId}?table=${table}&documentDownloadType=${documentDownloadType}`;
    if (documentOperationType) {
      url += `&documentOperationType=${documentOperationType}`;
    }
    return this.http.get<any>(url, options);
  }
  getPaginatedTaskDocument(
    taskId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TaskDocument>> {
    let url = `${TASK_FILES_URL}?taskId=${taskId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<TaskDocument>>(url);
  }

  getPaginatedTaskDocumentsByStageInstanceId(
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TaskDocument>> {
    let url = `${STAGE_INSTANCES_URL}/${stageInstanceId}/major-tasks-documents`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<TaskDocument>>(url);
  }

  searchTaskTypes(
    name?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TaskType>> {
    let url = `${PROCESS_TASK_URL}/task-types`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined &&
      name !== null &&
      name !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}`;
    } else if (name !== null && name !== undefined) {
      url += `?name=${name}`;
    }
    return this.http.get<PaginatedList<TaskType>>(url);
  }

  getTasksByTaskType(
    taskTypeId: string,
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Task>> {
    let url = `${PROCESS_TASK_URL}/task-types/${taskTypeId}/tasks?stageInstanceId=${stageInstanceId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<Task>>(url);
  }

  updateReplyTaskComment(
    taskCommentId: string,
    commentId: string,
    content: string,
  ) {
    return this.http.put<TaskComment>(`${TASK_COMMENTS_REPLY_URL}`, {
      taskCommentId,
      commentId,
      content,
    });
  }

  deleteTaskCommentReply(
    taskCommentId: string,
    commentId: string,
  ) {
    return this.http.delete<any>(`${TASK_COMMENTS_REPLY_URL}`, {
      params: {
        taskCommentId,
        commentId,
      },
    });
  }

  getFile(fileId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(`${TASK_FILES_URL}/video/${fileId}`, options);
  }

  getTaskFiles(
    taskId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<any>> {
    let url = `${TASK_FILES_URL}?taskId=${taskId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<any>>(url);
  }

  getTaskTypeByStageInstanceId(
    stageInstanceId: string,
  ): Observable<TaskType[]> {
    return this.http.get<TaskType[]>(
      `${PROCESS_TASK_URL}/task-types/${stageInstanceId}`,
    );
  }
  getTaskDocumentsByStageInstance(
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TaskDocument>> {
    return this.http.get<PaginatedList<TaskDocument>>(
      `${TASK_DOCUMENT_URL}/stage-instance/${stageInstanceId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getAssignedTaskDocuments(
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TaskDocument>> {
    return this.http.get<PaginatedList<TaskDocument>>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/major-tasks-documents/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  downloadTaskDocumentCompose(id: string): Observable<TaskDocumnet> {
    return this.http.get<TaskDocumnet>(`${TASK_DOCUMENT_URL}/${id}`);
  }
}
