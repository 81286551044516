<h1 i18n="@@users.manage-passwords.manage-passwords" class="mat-h1">Manage Passwords</h1>
<div class="flex">
  <div class="gap-x">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label i18n="@@users.manage-passwords.search-for-user"
        >Search</mat-label
      >
      <input
        matInput
        [placeholder]= placeholderLabel.nameField
        [(ngModel)]="searchText"
        (input)="getUsersBySearch()"
        matInput
        *appHasPermission="hasGetUsersPasswordPermission()"
        [attr.data-test-id]="'users.search-user.manage-passwords.input-for-user-search'"
      />

      <button
        matSuffix
        *ngIf="searchText"
        mat-icon-button
        aria-label="Clear"
        (click)="resetSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<div class="table-container">
  <mat-paginator
    [length]="usersWithPassword?.totalCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    (page)="loadPaginatedUsers($event)"
    [showFirstLastButtons]="true"
  ></mat-paginator>
  <mat-table [dataSource]="usersWithPassword?.items!" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@users.manage-passwords.name">Name</mat-header-cell>
      <mat-cell *matCellDef="let user">{{ user.fullName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@users.manage-passwords.user-name">User name</mat-header-cell>
      <mat-cell *matCellDef="let user">{{ user.userName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="password">
      <mat-header-cell *matHeaderCellDef i18n="@@users.manage-passwords.password">Password</mat-header-cell>
      <mat-cell *matCellDef="let user; let i = index">
        <span *ngIf="!visiblePasswords[i]">************</span>
        <span *ngIf="visiblePasswords[i]">{{ user.defaultPassword }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef i18n="@@users.manage-passwords.actions"></mat-header-cell>
      <mat-cell *matCellDef="let user; let i = index">
        <button mat-icon-button (click)="togglePasswordVisibility(i)">
          <mat-icon>{{ visiblePasswords[i] ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <button mat-icon-button (click)="copyPassword(user)"
        [attr.data-test-id]="'users.copy-password.manage-passwords.copy-password-icon'">
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
