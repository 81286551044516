import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  AddTeamMembersDto,
  CreateProcessInstanceModel,
  DeleteProcessInstanceDto,
  ProcessInstance,
  ProcessInstanceDetail,
  ProcessInstanceFilter,
  ProcessInstanceMultipleFilter,
  ProcessInstanceStatus,
  UpdateProcessInstanceModel,
} from '../models/process-instance.model';
import { PROCESS_INSTANCES_URL } from 'src/app/core/constants/api-endpoints';
import { Deadline } from '../models/deadline.model';
import { User } from 'src/app/users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ProcessInstanceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getProcessInstances(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}`,
      );
    }
  }

  createProcessInstance(
    processInstance: CreateProcessInstanceModel,
  ): Observable<ProcessInstance> {
    return this.http.post<ProcessInstance>(
      `${PROCESS_INSTANCES_URL}`,
      processInstance,
      this.httpOptions,
    );
  }

  updateProcessInstance(
    processInstance: UpdateProcessInstanceModel,
  ): Observable<ProcessInstance> {
    return this.http.put<ProcessInstance>(
      `${PROCESS_INSTANCES_URL}`,
      processInstance,
      this.httpOptions,
    );
  }

  assignOwnerToResearchInstance(
    processInstanceId: string,
    ownerId: string,
  ): Observable<ProcessInstance> {
    return this.http.put<ProcessInstance>(
      `${PROCESS_INSTANCES_URL}/assign`,
      { processInstanceId, ownerId },
      this.httpOptions,
    );
  }
  searchProcessInstances(
    searchTerm: string,
    processesIds: string[],
  ): Observable<PaginatedList<ProcessInstance>> {
    let url = `${PROCESS_INSTANCES_URL}/search?searchTerm=${searchTerm}`;
    processesIds.forEach((id) => {
      url += `&ProcessesIds=${id}`;
    });
    return this.http.get<PaginatedList<ProcessInstance>>(url);
  }

  deleteProcessInstance(deleteProcessDTO: DeleteProcessInstanceDto): Observable<any> {
    return this.http.request<any>('delete', `${PROCESS_INSTANCES_URL}`, {
      body: deleteProcessDTO,
      ...this.httpOptions,
    });
  }

  getProcessInstanceDetail(
    processInstanceDetailId: string,
  ): Observable<ProcessInstanceDetail> {
    return this.http.get<ProcessInstanceDetail>(
      `${PROCESS_INSTANCES_URL}/${processInstanceDetailId}`,
      this.httpOptions,
    );
  }
  submitProcessInstanceSchedule(
    deadline: Deadline,
  ): Observable<ProcessInstanceDetail> {
    return this.http.post<ProcessInstanceDetail>(
      `${PROCESS_INSTANCES_URL}/submit-schedule`,
      deadline,
      this.httpOptions,
    );
  }
  updateProcessInstanceSchedule(
    deadline: Deadline,
  ): Observable<ProcessInstanceDetail> {
    return this.http.put<ProcessInstanceDetail>(
      `${PROCESS_INSTANCES_URL}/update-schedule`,
      deadline,
      this.httpOptions,
    );
  }
  deleteProcessInstanceDeadline(deadline: Deadline): Observable<Deadline> {
    return this.http.put<Deadline>(
      `${PROCESS_INSTANCES_URL}/update-deadline`,
      deadline,
      this.httpOptions,
    );
  }

  getArchivedProcessInstances(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    return this.http.get<PaginatedList<ProcessInstance>>(
      `${PROCESS_INSTANCES_URL}/archived?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  archiveProcessInstance(processInstanceId: string): Observable<any> {
    return this.http.put<any>(
      `${PROCESS_INSTANCES_URL}/archive?processInstanceId=${processInstanceId}`,
      {},
    );
  }
  cancelProcessDeletion(processInstanceId: string): Observable<any> {
    return this.http.put<any>(
      `${PROCESS_INSTANCES_URL}/cancel-deletion?processInstanceId=${processInstanceId}`,
      {},
    );
  }

  restoreArchivedProcessInstance(processInstanceId: string): Observable<any> {
    return this.http.put<any>(
      `${PROCESS_INSTANCES_URL}/unarchive?processInstanceId=${processInstanceId}`,
      {},
    );
  }

  addTeamMembers(
    addTeamMemberDto: AddTeamMembersDto,
  ): Observable<ProcessInstance> {
    return this.http.put<any>(
      `${PROCESS_INSTANCES_URL}/team-members`,
      addTeamMemberDto,
    );
  }

  downloadProcess(
    processInstanceId: string,
  ): Observable<Blob> {
    return this.http.get(
      `${PROCESS_INSTANCES_URL}/download-archive?processInstanceId=${processInstanceId}`,
      {
        responseType: 'blob',
      },
    );
  }


  deleteTeamMember(
    processInstanceId: string | undefined | null,
    teamMemberId: string,
  ): Observable<ProcessInstance> {
    return this.http.delete<ProcessInstance>(
      `${PROCESS_INSTANCES_URL}/team-members?processInstanceId=${processInstanceId}&teamMemberId=${teamMemberId}`,
      this.httpOptions,
    );
  }

  getProcessInstanceTeamMembers(
    processInstanceId: string,
  ): Observable<User[]> {
    return this.http.get<User[]>(
      `${PROCESS_INSTANCES_URL}/team-members?processInstanceId=${processInstanceId}`,
    );
  }

  getOwnedProcessInstances(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<ProcessInstanceDetail>> {
      return this.http.get<PaginatedList<ProcessInstanceDetail>>(
        `${PROCESS_INSTANCES_URL}/owned?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
  }

  getProcessInstancesByAssignedTasks(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}/by-assigned-tasks?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}/by-assigned-tasks`,
      );
    }
  }

  getProcessInstanceFilters(): Observable<ProcessInstanceFilter[]> {
    return this.http.get<ProcessInstanceFilter[]>(`${PROCESS_INSTANCES_URL}/process-instance-status`);
  }

  getProcessInstancesByStatus(
    status:ProcessInstanceStatus,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}/by-status?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}`,
      );
    } else {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}`,
      );
    }
  }

  getProcessInstancesByDate(
    startDate:string,
    endDate:string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ProcessInstance>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}/by-date?pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
      );
    } else {
      return this.http.get<PaginatedList<ProcessInstance>>(
        `${PROCESS_INSTANCES_URL}`,
      );
    }
  }

  getProcessInstancesByFilter(
    filterData:ProcessInstanceMultipleFilter

  ): Observable<PaginatedList<ProcessInstance>> {
    const {searchTerm,processesIds,status,startDate,endDate,pageNumber,pageSize} = filterData
    let params = new HttpParams();
    if (searchTerm) {params = params.set('searchTerm', searchTerm)}
    if (processesIds) {params = params.appendAll({processesIds})}
    if (status) {params = params.set('status', status)}
    if (startDate) {params = params.set('startDate', startDate)}
    if (endDate) {params = params.set('endDate', endDate)}
    if (pageNumber) {params = params.set('pageNumber', pageNumber)}
    if (pageSize) {params = params.set('pageSize', pageSize)}
    return this.http.get<PaginatedList<ProcessInstance>>(
      `${PROCESS_INSTANCES_URL}/by-multiple-filter`,
      { params: params },
    );
  }
}
