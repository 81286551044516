import { MEMOS_RECEIVED_URL } from './../../../core/constants/api-endpoints';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CcUserWithOfficeDto,
  Memo,
  MemoAccessDetail,
  MemoFilter,
  MemoStatus,
} from '../models/memo.model';
import { MEMOS_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getDraftMemo(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Memo>> {
    let url = `${MEMOS_URL}/draft/?pageNumber=${pageNumber ?? null}&pageSize=${
      pageSize ?? null
    }`;
    return this.http.get<PaginatedList<Memo>>(url, this.httpOptions);
  }

  getMemosSent(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Memo>> {
    let url = `${MEMOS_URL}?pageNumber=${pageNumber ?? null}&pageSize=${
      pageSize ?? null
    }`;
    return this.http.get<PaginatedList<Memo>>(url, this.httpOptions);
  }

  getMemosCcedToMe(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Memo>> {
    let url = `${MEMOS_URL}/cc/?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedList<Memo>>(url, this.httpOptions);
  }

  getMemosReceived(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Memo>> {
    let url = `${MEMOS_RECEIVED_URL}?pageNumber=${
      pageNumber ?? null
    }&pageSize=${pageSize ?? null}`;

    return this.http.get<PaginatedList<Memo>>(url, this.httpOptions);
  }

  createMemo(memo: FormData): Observable<Memo> {
    return this.http.post<Memo>(`${MEMOS_URL}`, memo);
  }

  addCcUserToMemo(
    userId: string,
    memoId: string,
  ): Observable<CcUserWithOfficeDto> {
    return this.http.put<CcUserWithOfficeDto>(
      `${MEMOS_URL}/cc-user/${memoId}`,
      {
        id: userId,
      },
      this.httpOptions,
    );
  }

  removeCcUserToMemo(userId: string, memoId: string): Observable<any> {
    return this.http.delete(`${MEMOS_URL}/cc-user/${memoId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: userId,
      },
    });
  }

  updateMemo(memo: FormData, id: string): Observable<Memo> {
    return this.http.put<Memo>(`${MEMOS_URL}/${id}`, memo);
  }

  updateMemoStatus(status: MemoStatus, id: string): Observable<Memo> {
    return this.http.put<Memo>(`${MEMOS_URL}/update/status`, {
      id,
      status,
    });
  }

getMemo(id: string): Observable<Memo> {
    return this.http.get<Memo>(`${MEMOS_URL}/${id}`);
  }

  deleteMemo(id: string): Observable<any> {
    return this.http.delete<any>(`${MEMOS_URL}/${id}`);
  }

  deleteAttachment(memoId: string, attachmentId: string): Observable<Memo> {
    return this.http.delete<Memo>(
      `${MEMOS_URL}/${memoId}/attachments/${attachmentId}`,
    );
  }

  getMemoAccessDetail(memoId: string): Observable<MemoAccessDetail> {
    return this.http.get<MemoAccessDetail>(
      `${MEMOS_URL}/access-detail/${memoId}`,
    );
  }

  searchMemos(
    searchTerm?: string,
    sentFromId?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Memo>> {
    let url = `${MEMOS_URL}/search?searchTerm=${searchTerm}`;
    if (sentFromId !== null && sentFromId !== undefined) {
      url += `&sentFromId=${sentFromId}`;
    }
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<Memo>>(url);
  }

  getAttachment(memoId: string, attachmentId: string): Observable<any> {
    return this.http.get(`${MEMOS_URL}/${memoId}/attachments/${attachmentId}`, {
      responseType: 'blob',
    });
  }

  getMemoFilters(): Observable<MemoFilter[]> {
    return this.http.get<MemoFilter[]>(`${MEMOS_URL}/memo-filters`);
  }

  getMemoById(memoId: string): Observable<MemoFilter[]> {
    return this.http.get<MemoFilter[]>(`${MEMOS_URL}/${memoId}`);
  }

  getMemosByDate(
    email: string,
    startDate: string,
    endDate: string,
    status: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Memo>> {
    const url = `${MEMOS_URL}/by-date?email=${email}&startDate=${startDate}&endDate=${endDate}&status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedList<Memo>>(url);
  }
}
