<h1
  mat-dialog-title
  *ngIf="!isUpdateForm"
  i18n="@@documents.create-document-form.create-form"
>
  Create Form
</h1>
<h1
  *ngIf="isUpdateForm"
  mat-dialog-title
  i18n="@@documents.create-document-form.update-form"
>
  Update Form
</h1>

<mat-dialog-content>
  <mat-stepper [linear]="true" #stepper (selectionChange)="handleSelectionChange($event)">
    <mat-step [label]="createForm" [stepControl]="templateForm">
      <div style="margin: 2em 0">
        <app-document-form-step-form [templateForm]="templateForm" />
      </div>
    </mat-step>

    <mat-step [label]="addFields" [optional]="true">
      <ng-template matStepContent>
        <div style="margin: 2em 0">
          <app-form-field-step-form />
        </div>
      </ng-template>
    </mat-step>

    <mat-step [label]="groupFields" [optional]="true">
      <ng-template matStepContent>
        <div style="margin: 2em 0">
          <app-list-table-builder></app-list-table-builder>
        </div>
      </ng-template>
    </mat-step>
    <mat-step [label]="reviewFields">
      <mat-card style="margin: 2em 0" appearance="outlined">
        <mat-card-content class="card-content">
          <mat-list>
            <div *ngFor="let item of selectedFormFields" class="field-item">
              <div>
                <div matListItemTitle>{{ item.name }}</div>
                <div matListItemLine>
                  <span i18n="@@documents.create-document-form.data-type"
                    >Data type :</span
                  >
                  {{ item.dataType }}
                </div>
              </div>

              <div>
                <button
                  mat-icon-button
                  (click)="editFormField(item); stepper.selectedIndex = 1"
                >
                  <mat-icon class="fields-icon">edit</mat-icon>
                </button>
                <button mat-icon-button (click)="removeFormField(item)">
                  <mat-icon class="fields-icon">delete</mat-icon>
                </button>
              </div>
            </div>
          </mat-list>
        </mat-card-content>

        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            (click)="stepper.selectedIndex = 1; child.resetForm()"
            i18n="@@documents.create-document-form.add-another-field"
          >
            Add another field
          </button>
        </mat-card-actions>
      </mat-card>

      <div style="display: flex; gap: 1em">
        <button
          (click)="stepper.previous(); child.resetForm()"
          mat-stroked-button
          i18n="@@documents.create-document-form.back"
        >
          back
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="handleSubmit(true)"
          i18n="@@documents.create-document-form.save"
        >
          save
        </button>
        <button mat-flat-button color="primary" (click)="handleSubmit(false)">
          <ng-container
            *ngIf="!isUpdateForm || IsFormADraft; else update"
            i18n="@@documents.create-document-form.create-form"
          >
            Create Form
          </ng-container>
          <ng-template
            #update
            i18n="@@documents.create-document-form.update-form"
            >Update Form</ng-template
          >
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: flex-end;">
    <button
            (click)="openConfirmationDialog()"
            mat-button
            i18n="@@documents.create-document-form.cancel"
            color="warn"
            style="margin-left: auto"
          >
            Cancel
          </button>
</mat-dialog-actions>

