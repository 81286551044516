import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalDocumentListComponent } from './components/external-document-list/external-document-list.component';
import { ExternalDocumentRoutingModule } from './external-document-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { ExternalDocumentFormComponent } from './components/external-document-form/external-document-form.component';
import { ExternalDocumentState } from './store/external-document.state';
import { ExternalDocumentDetailComponent } from './components/external-document-detail/external-document-detail.component';
import { ExternalDocumentRemarkFormComponent } from './components/external-document-remark-form/external-document-remark-form.component';
import { OfficeSelectorcomponent } from 'src/app/offices/components/office-selector/office-selector.component';
import { OfficesModule } from 'src/app/offices/offices.module';
import { ExternalDocumentResponseFormComponent } from './external-document-response-form/external-document-response-form.component';
import { ExternalDocumentResponseDetailComponent } from './components/external-document-response-detail/external-document-response-detail.component';
import { ExternalDocumentResponseRemarkFormComponent } from './components/external-document-response-remark-form/external-document-response-remark-form.component';

@NgModule({
  declarations: [
    ExternalDocumentFormComponent,
    ExternalDocumentListComponent,
    ExternalDocumentDetailComponent,
    ExternalDocumentRemarkFormComponent,
    ExternalDocumentResponseFormComponent,
    ExternalDocumentResponseDetailComponent,
    ExternalDocumentResponseRemarkFormComponent,
  ],
  imports: [
    SharedModule,
    ExternalDocumentRoutingModule,
    OfficesModule,
    NgxsModule.forFeature([ExternalDocumentState]),
  ],
})
export class ExternalDocumentModule {}
