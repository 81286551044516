import { WorkspaceFacade } from './../../../../files/facade/workspace.facade';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import {
  ARCHIVED_WORKSPACES_ROUTE,
  FILES_ROUTE,
} from 'src/app/core/constants/routes';
import { Workspace } from 'src/app/files/models/workspace';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';

interface ArchivedWorkspaceCardComponentState {
  selectedWorkspace: Workspace | undefined;
  update: boolean;
}

const initArchivedWorkspaceCardComponentState: ArchivedWorkspaceCardComponentState =
  {
    selectedWorkspace: undefined,
    update: false,
  };

@Component({
  selector: 'app-archived-workspace-card',
  templateUrl: './archived-workspace-card.component.html',
  styleUrls: ['./archived-workspace-card.component.scss'],
})
export class ArchivedWorkspaceCardComponent implements OnInit {
  @Input() archivedWorkspace: Workspace | undefined;
  selectedWorkspace: Workspace | undefined;
  selectedWorkspace$ = this.state.select('selectedWorkspace');
  archivedWorkspaceName = '';
  archivedWorkspaceDate = '';

  constructor(
    private workspaceFacade: WorkspaceFacade,
    private state: RxState<ArchivedWorkspaceCardComponentState>,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.state.set(initArchivedWorkspaceCardComponentState);
    this.state.connect(
      'selectedWorkspace',
      this.workspaceFacade.selectedArchivedWorkspace$,
    );
  }

  ngOnInit(): void {
    this.selectedWorkspace$.subscribe((selectedWorkspace) => {
      this.selectedWorkspace = selectedWorkspace;
    });

    this.archivedWorkspaceName = this.extractWorkspaceName(
      this.archivedWorkspace?.name,
    );
    this.archivedWorkspaceDate = this.extractWorkspaceDate(
      this.archivedWorkspace?.name,
    );
  }

  navigateToWorkspaceDetail(archivedWorkspace: Workspace | undefined) {
    this.workspaceFacade.dispatchSelectArchivedWorkspace(archivedWorkspace);
    this.router.navigate([ARCHIVED_WORKSPACES_ROUTE, archivedWorkspace?.id]);
  }

  extractWorkspaceName(data: any) {
    const archivedIndex = data.indexOf('-ARCHIVED');

    if (archivedIndex === -1) {
      return '';
    }

    const workspaceName = data.substring(0, archivedIndex).trim();
    return workspaceName;
  }

  extractWorkspaceDate(data: any) {
    const lastHyphenIndex = data.lastIndexOf('-');

    if (lastHyphenIndex === -1) {
      return null;
    }

    var datePart = data.substring(lastHyphenIndex + 1).trim();

    if (datePart === -1) {
      return null;
    }

    return datePart;
  }
  
  deleteWorkspace(event:MouseEvent,workspaceId:string|undefined){
    event.stopPropagation()
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@offices.archived-workspace-card.delete-workspace-confimation-part-1: Are you sure you want to delete` ,
        boldText: ` "${this.archivedWorkspace?.name}]" ` ,
        regularTextTwo:  $localize`:@@offices.archived-workspace-card.delete-workspace-confimation-part-2:workspace?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workspaceFacade.dispatchDeleteArchivedWorkspace(workspaceId as string);
      }
    });
  }
}
