import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENTS_ANALYTICS_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ReportedComment } from '../models/reported-comment.model';
import { WorkflowType } from '../models/workfflow-type.model';

@Injectable({
  providedIn: 'root',
})
export class ReportedCommentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getReportedCommentsReport(
    pageNumber: number,
    pageSize: number,
    workflowType?: string,
    searchTerm?: string,
  ) {
    let queryParams = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (workflowType) {
      queryParams = queryParams.set('workflowType', workflowType);
    }

    if (searchTerm) {
      queryParams = queryParams.set('searchTerm', searchTerm);
    }

    return this.http.get<PaginatedList<ReportedComment>>(
      `${DOCUMENTS_ANALYTICS_URL}/reported-comments`,
      { params: queryParams },
    );
  }

  getWorkflowTypes() {
    return this.http.get<WorkflowType[]>(
      `${DOCUMENTS_ANALYTICS_URL}/workflow-types`,
    );
  }
}
