import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { TaskDetail } from 'src/app/researches/models/taskDetail.model';
import { Router } from '@angular/router';
import {
  FileTaskUploadReport,
  TaskDocumnet,
  TaskStatus,
} from 'src/app/researches/models/task.model';
import { TASK_DOCUMENTS_ROUTE } from 'src/app/core/constants/routes';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { StageInstanceDetail, StageInstanceStatus } from 'src/app/researches/models/stage-instance-detail.model';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';

interface TaskFileUploadComponentState {
  taskDetail?: TaskDetail;
  fileTaskUploadReports: FileTaskUploadReport[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  stageInstance?: StageInstanceDetail
}

const initTaskFileUploadComponentState: Partial<TaskFileUploadComponentState> =
  {
    currentLoggedInUser: undefined,
  };

@Component({
  selector: 'app-task-file-upload',
  templateUrl: './task-file-upload.component.html',
  styleUrls: ['./task-file-upload.component.scss'],
  providers: [RxState],
})
export class TaskFileUploadComponent {
  taskFileUploadForm: FormGroup;
  taskDetail$ = this.state.select('taskDetail');
  stageInstance$ =  this.state.select('stageInstance');
  stageInstance?: StageInstanceDetail
  taskDetail?: TaskDetail;
  fileTaskUploadReports$ = this.state.select('fileTaskUploadReports');
  fileTaskUploadReports: FileTaskUploadReport[] = [];
  failedReports: FileTaskUploadReport[] = [];
  files: File[] = [];
  isDragOver = false;

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private taskFacade: TaskFacade,
    private router: Router,
    private operationStatus: OperationStatusService,
    private state: RxState<TaskFileUploadComponentState>,
    private userFacade: UserFacade,
    private stageInstanceDetailFacade: StageInstanceDetailFacade
  ) {
    this.taskFileUploadForm = this.fb.group({
      files: null,
    });
    this.state.set(initTaskFileUploadComponentState);
    this.state.connect('taskDetail', taskFacade.taskDetail$);
    this.state.connect(
      'fileTaskUploadReports',
      this.taskFacade.fileTaskUploadReports$,
    );
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect('stageInstance', this.stageInstanceDetailFacade.selectedStageInstanceDetail$);
  }

  ngOnInit() {
    this.taskFacade.dispatchSetFileTaskUploadReportsNull();
    this.taskDetail$.subscribe((taskDetail) => {
      this.taskDetail = taskDetail;
    });
    if (this.taskDetail?.id)
      this.taskFacade.dispatchGetPaginatedTaskDocument(this.taskDetail.id);

    this.fileTaskUploadReports$.subscribe((fileTaskUploadReports) => {
      if (fileTaskUploadReports) {
        this.fileTaskUploadReports = fileTaskUploadReports;
        this.decideOntheReport();
      }
    });
    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
    this.stageInstance$.subscribe(stage => {
      this.stageInstance = stage;
    })
  }

  ngOnDestroy() {
    this.taskFacade.dispatchSetFileTaskUploadReportsNull();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      const selectedFiles = Array.from(files);

      const newFiles = selectedFiles.filter(
        (file) =>
          !this.files.some((existingFile) => existingFile.name === file.name),
      );
      this.files = this.files.concat(newFiles);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter(
          (file) =>
            !this.files.some((existingFile) => existingFile.name === file.name),
        );
        this.files = this.files.concat(newFiles);
      }
    };
    fileInput.click();
  }

  removeFile(file: File) {
    this.files = this.files.filter((f) => f !== file);
    this.failedReports = this.failedReports.filter(
      (report) => report.name !== file.name,
    );
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.files = this.files.concat(Array.from(event.target.files));
    }
  }
  isFileInFailedReports(file: File) {
    return this.failedReports.some((report) => report.name === file.name);
  }
  errorForForTheFailedReport(file: File) {
    const report = this.failedReports.find(
      (report) => report.name === file.name,
    );
    return report?.message;
  }
  isUploadButtonDisabled() {
    if (this.failedReports.length > 0){
      return true;
    }

    if (this.files.length > 0 &&
      (this.taskDetail?.taskStatus === TaskStatus.Todo || this.taskDetail?.taskStatus === TaskStatus.InProgress)) {
      return false;
    }
    return true;
  }
  uploadFiles() {
    if (this.files.length > 0 && this.taskDetail?.id) {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append('files', file);
      });
      this.taskFacade.dispachUploadFiles(formData, this.taskDetail?.id);
    }
    if (this.stageInstance?.stageStatus === StageInstanceStatus.ReadyToStart) {
      this.stageInstanceDetailFacade.dispatchChangeStageStatus({
        id: this.stageInstance.id,
        stageStatus: StageInstanceStatus.Inprogress,
      });
    }
  }

  decideOntheReport() {
    const successReports = this.fileTaskUploadReports.filter(
      (report) => report.fileTask && report.success,
    );
    const failedReports = this.fileTaskUploadReports.filter(
      (report) => !report.success,
    );
    this.failedReports = failedReports;
    if (failedReports.length < 1) {
      this.operationStatus.displayStatus(
        $localize`:@@researches.task-file-upload.task-upload: Task Files uploaded successfully`,
        successStyle,
      );
      this.taskFacade.dispatchGetTaskFiles(this.taskDetail?.id as string);
    }
    const fileTaskList: any[] = successReports.map(
      (report) => report.fileTask!,
    );
    const newFiles = this.files.filter(
      (file) =>
        !fileTaskList.some((existingFile) => existingFile.name === file.name),
    );
    this.files = newFiles;
  }
}
