import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Office } from '../models/office.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OfficeService } from '../services/office.service';
import {
  DeleteOffice,
  DownloadExcelTemplate,
  FlushExcelTemplate,
  GetFlatOfficeNodes,
  GetOffice,
  GetOfficeTree,
  GetOffices,
  GetReportsToOfficeNodes,
  ResetSelectedOffice,
  ResetSelectedOffices,
  SelectFlatOfficeNode,
  SelectFlatOfficeNodes,
  SelectOffice,
  SetPageIndexAndSize,
  UpdateOffice,
  UpdateOfficeTreeStructure,
  UploadExcel,
  GetUserOfficeTree,
  SetSelectedFlatOfficeNode,
} from './office.actions';
import { tap } from 'rxjs';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';

import { insertItem, patch } from '@ngxs/store/operators';

import { OfficeTree, OfficeTreeNode } from '../models/office-tree.model';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { CreateOffice } from './office.actions';

import { FlatOfficeNode } from '../models/flat-office-node.model';
import { ExcelUploadReport } from '../models/excel-upload-report.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';

export interface OfficeStateModel {
  offices: Office[];
  flatOfficeNodes: FlatOfficeNode[];
  officeTreeNodes: OfficeTreeNode | null;
  totalCount: number;
  templateExcelFileUrl: string | null;
  selectedFlatOfficeNode: FlatOfficeNode | null;
  selectedFlatOfficeNodes: FlatOfficeNode[];
  paginatedOffices: PaginatedList<Office> | null;
  selectedOffice: Office | null;
  excelUploadReports: ExcelUploadReport[] | null;
  pageIndex: number;
  pageSize: number;
  office: Office | undefined;
}

const OFFICE_STATE_TOKEN = new StateToken<OfficeStateModel>('officeState');

const defaults: OfficeStateModel = {
  offices: [],
  flatOfficeNodes: [],
  officeTreeNodes: null,
  totalCount: 0,
  templateExcelFileUrl: null,
  selectedFlatOfficeNode: null,
  selectedFlatOfficeNodes: [],
  paginatedOffices: null,
  selectedOffice: null,
  excelUploadReports: null,
  pageIndex: 0,
  pageSize: 10,
  office: undefined,
};

@State<OfficeStateModel>({
  name: OFFICE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class OfficeState {
  constructor(
    public officeService: OfficeService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(SetPageIndexAndSize)
  setPageSizeandIndex(
    { patchState }: StateContext<OfficeStateModel>,
    { index, size }: SetPageIndexAndSize,
  ) {
    patchState({
      pageIndex: index,
      pageSize: size,
    });
  }

  @Action(UploadExcel)
  uploadExcel(
    { patchState }: StateContext<OfficeStateModel>,
    { excelFileUplad }: UploadExcel,
  ) {
    this.store.dispatch(new SetProgressOn());
    patchState({
      excelUploadReports: null,
    });
    this.officeService
      .uploadExcelFile(excelFileUplad)
      .subscribe((event: HttpEvent<ExcelUploadReport[]>) => {
        if (event.type === HttpEventType.Response) {
          const uploadExcelReports: ExcelUploadReport[] | null = event.body;
          patchState({ excelUploadReports: uploadExcelReports });
        }
        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(FlushExcelTemplate)
  flushExcelTemplate({ patchState, getState }: StateContext<OfficeStateModel>) {
    let fileUrl = getState().templateExcelFileUrl;
    if (fileUrl) URL.revokeObjectURL(fileUrl);
    patchState({ templateExcelFileUrl: null });
  }

  @Action(DownloadExcelTemplate)
  downloadExcelTemplate({ patchState }: StateContext<OfficeStateModel>) {
    this.store.dispatch(new SetProgressOn());
    this.officeService
      .downloadExcelTemplate()
      .subscribe(async (response: any) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileUrl = URL.createObjectURL(blob);
        patchState({
          templateExcelFileUrl: fileUrl,
        });
        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(CreateOffice)
  createOffice(
    { getState, setState }: StateContext<OfficeStateModel>,
    { office }: CreateOffice,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.officeService.registerOffice(office).pipe(
      tap((u) => {
        let state = getState();
        let parentOffice = state.flatOfficeNodes.find(
          (off) => off.pathFromRoot == office.parentPathFromRoot,
        );
        if (parentOffice) u.parentOfficeName = parentOffice.name;
        const totalCount = getState().totalCount + 1;
        setState(
          patch({
            offices: insertItem<Office>(u),
            totalCount,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@offices.offices.register-office:Office has been registered `,
          successStyle,
        );
        this.store.dispatch(
          new GetOffices(state.pageIndex ?? 1, state.pageSize ?? 10),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetOffices)
  getOffices(
    { patchState }: StateContext<OfficeStateModel>,
    { pageNumber, pageSize }: GetOffices,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.getOffices(pageNumber, pageSize).pipe(
      tap((offices) => {
        patchState({
          offices: offices.items,
          totalCount: offices.totalCount,
          selectedOffice: null,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetFlatOfficeNodes)
  getFlatOfficeNodes({ patchState }: StateContext<OfficeStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.getFlatOfficeNodes().pipe(
      tap((nodes) => {
        patchState({
          flatOfficeNodes: nodes,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedFlatOfficeNode)
  setSelectedFlatOfficeNode(
    { patchState, getState }: StateContext<OfficeStateModel>,
    { id }: SetSelectedFlatOfficeNode,
  ) {
    patchState({
      selectedFlatOfficeNode: getState().flatOfficeNodes.find(
        (item) => item.id === id,
      ),
    });
  }

  @Action(UpdateOfficeTreeStructure)
  updateTreeStructure(
    _: StateContext<OfficeStateModel>,
    { updateStructure }: UpdateOfficeTreeStructure,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.updateOfficeTreeStructure(updateStructure).pipe(
      tap(() => {
        this.store.dispatch(new GetFlatOfficeNodes());
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@offices.offices.updated-office-tree:Office Tree updated Sucessfuly.`,
          successStyle,
        );
      }),
    );
  }

  @Action(GetReportsToOfficeNodes)
  getReportsToNodes(
    { patchState }: StateContext<OfficeStateModel>,
    { officeId }: GetReportsToOfficeNodes,
  ) {
    return this.officeService.getReportsToFlatNodes(officeId).pipe(
      tap((nodes) => {
        patchState({
          flatOfficeNodes: nodes,
        });
      }),
    );
  }

  @Action(GetOfficeTree)
  getOfficeTree({ patchState }: StateContext<OfficeStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.getOfficeTreeNodes().pipe(
      tap((treeNodes: OfficeTreeNode) => {
        patchState({
          officeTreeNodes: treeNodes,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateOffice)
  updateOffice(
    { getState, patchState }: StateContext<OfficeStateModel>,
    { office }: UpdateOffice,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.updateOffice(office).pipe(
      tap((updatedOffice) => {
        const state = getState();
        const officesList = [...state.offices];
        const officeIndex = officesList.findIndex(
          (item) => item.id === updatedOffice.id,
        );
        officesList[officeIndex] = updatedOffice;

        patchState({
          offices: officesList,
          selectedOffice: updatedOffice,
        });
        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new GetFlatOfficeNodes());
        this.store.dispatch(new GetOffices(state.pageIndex || 1, state.pageSize || 10));
        this.operationStatus.displayStatus(
          $localize`:@@offices.offices.updated-office:Office has been updated.`,
          successStyle,
        );
      }),
    );
  }

  @Action(ResetSelectedOffice)
  resetSelectedOffice({ patchState }: StateContext<OfficeStateModel>) {
    return patchState({
      selectedFlatOfficeNode: defaults.selectedFlatOfficeNode,
    });
  }

  @Action(ResetSelectedOffices)
  resetSelectedOffices({ patchState }: StateContext<OfficeStateModel>) {
    return patchState({
      selectedFlatOfficeNodes: defaults.selectedFlatOfficeNodes,
    });
  }

  @Action(DeleteOffice)
  deleteOffice(
    { getState, patchState }: StateContext<OfficeStateModel>,
    { id }: DeleteOffice,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.officeService.deleteOffice(id).pipe(
      tap(() => {
        let currOffices = getState().offices;
        let deletedParentOffice = currOffices.find((o) => o.id === id) ?? null;

        if (deletedParentOffice) {
          let deletedOffices = currOffices.filter((o) =>
            o.pathFromRoot.startsWith(deletedParentOffice!.pathFromRoot),
          );

          let deletedOfficesMap = new Map();
          for (let deletedOffice of deletedOffices) {
            deletedOfficesMap.set(deletedOffice.id, true);
          }
          const filteredOffices = getState().offices.filter(
            (office) => !deletedOfficesMap.has(office.id),
          );

          const new_count = getState().totalCount - deletedOffices.length;

          patchState({
            offices: filteredOffices,
            selectedOffice: null,
            totalCount: new_count,
          });
          this.operationStatus.displayStatus(
            $localize`:@@offices.offices.deleted-office:Office has been deleted along with descendants successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(SelectOffice)
  selectOffice(
    { patchState,getState }: StateContext<OfficeStateModel>,
    { selectedOffice }: SelectOffice,
  ) {

    const state = getState();
    const flatOfficeNodes = state.flatOfficeNodes;
    const selectedFlatOfficeNode = flatOfficeNodes.find((node) => node.id == selectedOffice.id)
    patchState({ selectedOffice: selectedOffice, selectedFlatOfficeNode});
  }

  @Action(SelectFlatOfficeNode)
  selectFlatOfficeNode(
    { patchState }: StateContext<OfficeStateModel>,
    { selectedFlatOfficeNode }: SelectFlatOfficeNode,
  ) {
    patchState({ selectedFlatOfficeNode: selectedFlatOfficeNode });
  }

  @Action(SelectFlatOfficeNodes)
  selectFlatOfficeNodes(
    { patchState }: StateContext<OfficeStateModel>,
    { selectedFlatOfficeNodes }: SelectFlatOfficeNodes,
  ) {
    patchState({ selectedFlatOfficeNodes: selectedFlatOfficeNodes });
  }

  @Action(GetOffice)
  getOffice({ patchState }: StateContext<OfficeStateModel>, { id }: GetOffice) {
    return this.officeService.getOffice(id).pipe(
      tap((office) => {
        patchState({
          office: office,
        });
      }),
    );
  }

  @Action(GetUserOfficeTree)
  getUserOfficeTree({ patchState }: StateContext<OfficeStateModel>) {
    return this.officeService.getUserOfficeTree().pipe(
      tap((tree) => {
        patchState({
          flatOfficeNodes: tree,
        });
      }),
    );
  }
}
