<mat-dialog-content class="pb-0" *appHasPermission="hasMoveFileFromArchivedWorkspacePermission()">
  <p *ngIf="folderNodes.length > 0" i18n="@@offices.archived-file-move.select-destination-folder">Select destination folder</p>
  <ng-container *ngIf="folderNodes.length === 0">
    <p i18n="@@offices.archived-file-move.there-are-no-destination-folders">There are no destination folders.</p>
  </ng-container>
  <div class="bg-white w-half px-2 py-2">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        [style.display]="shouldRender(node) ? 'flex' : 'none'"
        class="tree-node"
      >
        <div
          (click)="handleClick(node)"
          [ngClass]="node.id === destinationFolder?.id ? 'underline' : ''"
          class="none cursor-pointer flex justify-center items-center gap-x-4"
        >
          <mat-icon
            [ngClass]="
              node.id === destinationFolder?.id ? 'color-blue' : 'color-black'
            "
            >folder</mat-icon
          >
          {{ node.name }}
        </div>
        <button mat-icon-button disabled></button>
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [style.display]="shouldRender(node) ? 'flex' : 'none'"
        class="tree-node"
      >
        <div
          (click)="handleClick(node)"
          [ngClass]="node.id === destinationFolder?.id ? 'underline' : ''"
          class="none cursor-pointer flex justify-center items-center gap-x-4"
        >
          <mat-icon
            [ngClass]="
              node.id === destinationFolder?.id ? 'color-blue' : 'color-black'
            "
            >folder</mat-icon
          >
          {{ node.name }}
        </div>

        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
          (click)="node.isExpanded = !node.isExpanded"
          [style.visibility]="node.expandable ? 'visible' : 'hidden'"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </mat-tree-node>
    </mat-tree>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    mat-dialog-close="save"
    (click)="selectDestinationFolder()"
   i18n="@@offices.archived-file-move.select">
    Select
  </button>
  <button class="submit" mat-flat-button color="accent" mat-dialog-close i18n="@@offices.archived-file-move.cancel">
    Cancel
  </button>
</mat-dialog-actions>
