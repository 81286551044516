<mat-sidenav-container class="container" *appHasPermission="hasGetCriterionPermission()">
  <div>
    <h1>{{ processDetail?.name }}</h1>
  </div>
  <mat-sidenav-content class="stagedetail">
    <h1>{{ processDetail?.name }}</h1>
    <div>
      <h2>{{ selectedStage?.name }}</h2>
      <p>{{ selectedStage?.description }}</p>
    </div>
    <div class="stages">
      <div class="flex">
        <h2 i18n="@@researches.view-stage-criteria.criteria-list" >Criteria List</h2>
        <mat-paginator
          [length]="this.selectedStage?.criteria?.length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 20, 100]"
          [showFirstLastButtons]="true"
          (page)="loadPaginatedStageCriteria($event)"
        >
        </mat-paginator>
        <button mat-flat-button color="primary" 
          *appHasPermission="hasCreateCriteriaPermission()" 
          (click)="addCriteria()" 
          [disabled]="getCriteriaSum() === 100">
          + <span i18n="@@researches.view-stage-criteria.add-criteria"
          [attr.data-test-id]="'researches.add-criteria.view-stage-criteria.add-criteria-button'"
          >Add Criteria</span>
        </button>
      </div>
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          class="table"
          style="border-radius: 10px"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef i18n="@@researches.view-stage-criteria.name">Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef i18n="@@researches.view-stage-criteria.weight">Weight</th>
            <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th class="mat-header" mat-header-cell *matHeaderCellDef
            i18n="@@researches.view-stage-criteria.actions">
              Actions
            </th>
            <td class="mat-table-actions" mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="updateCriteria(element)" *appHasPermission="hasUpdateCriteriaPermission()"   
               [attr.data-test-id]="'researches.edit-criteria.view-stage-criteria.edit-criteria-button'"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="edit criteria"
                  fontIcon="edit_icon"
                ></mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="deleteCriteria(element.id)" *appHasPermission="hasDeleteCriteriaPermission()"
              [attr.data-test-id]="'researches.delete-criteria.view-stage-criteria.delete-criteria-button'"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="delete criteria"
                  fontIcon="delete"
                ></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-menu #menu="matMenu">
          <button mat-menu-item *appHasPermission="hasUpdateCriteriaPermission()" i18n="@@researches.view-stage-criteria.edit-criteria">Edit criteria</button>
          <button mat-menu-item *appHasPermission="hasDeleteCriteriaPermission()" i18n="@@researches.view-stage-criteria.delete">Delete</button>
        </mat-menu>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
