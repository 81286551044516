import { FlatFolderNode } from '../models/flat-folder-node.model';
import { Folder } from '../models/folder.model';
import { ShareFolderRequest } from '../models/share-folder-request';

export class GetFoldersByWorkspaceId {
  static readonly type = `[Folder] ${GetFoldersByWorkspaceId.name}`;
  constructor(public readonly workspaceId: string) {}
}

export class GetFoldersByWorkspaceIdTree {
  static readonly type = `[Folder] ${GetFoldersByWorkspaceIdTree.name}`;
  constructor(public readonly workspaceId: string) {}
}

export class GetFlatFolderNodes {
  static readonly type = `[Folder] ${GetFlatFolderNodes.name}`;
  constructor() {}
}

export class SelectFlatFolderNode {
  static readonly type = `[Folder] ${SelectFlatFolderNode.name}`;
  constructor(public readonly flatFolderNode: FlatFolderNode) {}
}

export class MoveFolder {
  static readonly type = `[Folder] ${MoveFolder.name}`;
  constructor(
    public pathFromRoot: string,
    public folderId: string,
  ) {}
}

export class DeleteFolder {
  static readonly type = `[Folder] ${DeleteFolder.name}`;
  constructor(
    public folderId: string,
    public workspaceId: string,
  ) {}
}

export class CreateFolder {
  static readonly type = `[Folder] ${CreateFolder.name}`;
  constructor(public folder: Folder) {}
}
export class RenameFolder {
  static readonly type = `[Folder] ${RenameFolder.name}`;
  constructor(
    public folderId: string,
    public folderName: string,
  ) {}
}

export class GetFoldersByName {
  static readonly type = `[Folder] ${GetFoldersByName.name}`;
  constructor(public readonly name: string) {}
}

export class ShareFoldersToOffices {
  static readonly type = `[Folder] ${ShareFoldersToOffices.name}`;
  constructor(public shareFolderToOfficeRequests: ShareFolderRequest[]) {}
}

export class ShareFoldersToRoles {
  static readonly type = `[Folder] ${ShareFoldersToRoles.name}`;
  constructor(public shareFolderToRolesRequests: ShareFolderRequest[]) {}
}

export class ShareFoldersToUsers {
  static readonly type = `[Folder] ${ShareFoldersToUsers.name}`;
  constructor(public shareFolderToUsersRequests: ShareFolderRequest[]) {}
}
export class SelectFlatFolderNodes {
  static readonly type = `[Folder] ${SelectFlatFolderNodes.name}`;
  constructor(public selectedFlatFolderNodes: FlatFolderNode[]) {}
}

export class SetFlatFolderNodesEmpty {
  static readonly type = `[Folder] ${SetFlatFolderNodesEmpty.name}`;
  constructor() {}
}

export class GetSharedToFolders {
  static readonly type = `[Folder] ${GetSharedToFolders.name}`;
  constructor() {}
}

export class GetSharedFromFolders {
  static readonly type = `[Folder] ${GetSharedFromFolders.name}`;
  constructor() {}
}

export class SetSelectedFolderPathFromRoot {
  static readonly type = `[Folder] ${SetSelectedFolderPathFromRoot.name}`;
  constructor(public selectedFolderPathFromRoot: string) {}
}

export class SetSharingModeFolder {
  static readonly type = `[Folder] ${SetSharingModeFolder.name}`;
  constructor() {}
}
export class SetSharingModeFile {
  static readonly type = `[Folder] ${SetSharingModeFile.name}`;
  constructor() {}
}

export class ClearSelectedFlatFolderNode {
  static readonly type = `[Folder] ${ClearSelectedFlatFolderNode.name}`;
  constructor() {}
}

export class GetFolderProperty {
  static readonly type = `[Folders] ${GetFolderProperty.name}`;
  constructor(public folderId: string) {}
}

export class GetArchivedWorkspaceFolders {
  static readonly type = `[Folder] ${GetArchivedWorkspaceFolders.name}`;
  constructor(public readonly workspaceId: string) {}
}

export class SelectArchivedFlatFolderNode {
  static readonly type = `[Folder] ${SelectArchivedFlatFolderNode.name}`;
  constructor(public readonly flatFolderNode: FlatFolderNode) {}
}

export class GetArchivedFolderProperty {
  static readonly type = `[Folders] ${GetArchivedFolderProperty.name}`;
  constructor(public folderId: string) {}
}

export class MoveFolderFromArchivedWorkspace {
  static readonly type = `[Folder] ${MoveFolderFromArchivedWorkspace.name}`;
  constructor(
    public folderId: string,
    public destinationWorkspaceId: string,
    public currentWorkspaceId: string,
  ) {}
}

export class GetWorkspaceFlatFolderNodes {
  static readonly type = `[Folder] ${GetWorkspaceFlatFolderNodes.name}`;
  constructor(public workspaceId: string) {}
}

export class SelectDestinationFlatFolderNode {
  static readonly type = `[Folder] ${SelectDestinationFlatFolderNode.name}`;
  constructor(public readonly flatFolderNode: FlatFolderNode | undefined) {}
}

export class SearchFolders {
  static readonly type = `[Folder] ${SearchFolders.name}`;
  constructor(public readonly searchTerm: string) {}
}

export class TrashFolder {
  static readonly type = `[Folder] ${TrashFolder.name}`;
  constructor(public readonly folderId: string) {}
}

export class GetTrashedFolders {
  static readonly type = `[Folder] ${GetTrashedFolders.name}`;
  constructor(
    public readonly pageIndex: number,
    public readonly pageSize: number,
  ) {}
}

export class RestoreFolder {
  static readonly type = `[Folder] ${RestoreFolder.name}`;
  constructor(public readonly folderId: string) {}
}
