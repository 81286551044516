<mat-card class="card" appearance="outlined"  [attr.data-test-id]="'researches.get-comment.task-comment.mat-card'">
  <mat-card-header>
    <div class="rounded" mat-card-avatar>
      <span>{{
        taskComment?.fullName && taskComment?.fullName?.substring(0, 1)
      }}</span>
    </div>

    <mat-card-title>
      <span>{{ taskComment?.fullName }}</span>
    </mat-card-title>
    <mat-card-subtitle
      ><span class="mat-caption">
        {{ taskComment?.created | date: "medium" }}
      </span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content >
    <div class="mat-body" [attr.data-test-id]="'researches.get-comment.task-comment.mat-card-content'">
      {{ taskComment?.content }}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="mt-2" *ngIf="!processInstanceDetail?.isArchived">
      <span *ngIf="currentLoggedInUser?.id === taskComment?.createdBy">
      <button
        class="hover-effect mr-4"
        mat-button
        color="primary"
        (click)="editComment(taskComment!)"
        *appHasPermission="hasUpdateTaskCommentPermission()"
        [attr.data-test-id]="'researches.edit-comment.task-comment.edit-button'"
      >
        <mat-icon>edit</mat-icon>
        <span i18n="@@researches.task-comment.edit">Edit</span>
      </button>
    </span>
    <span *ngIf="currentLoggedInUser?.id === taskComment?.createdBy">
      <button
        class="hover-effect mr-4"
        mat-button
        color="warn"
        *appHasPermission="hasDeleteTaskCommentPermission()"
        (click)="openDeleteConfirmationDialog($event, taskComment)"
        [attr.data-test-id]="'researches.delete-comment.task-comment.delete-button'"
      >
        <mat-icon>delete</mat-icon>
        <span i18n="@@researches.task-comment.delete">Delete</span>
      </button>
    </span>
      <button
        class="hover-effect"
        mat-button
        color="primary"
        (click)="replyComment(taskComment!)"
        *appHasPermission="hasReplyTaskCommentPermission()"
        [attr.data-test-id]="'researches.reply-to-a-comment.task-comment.reply-button'"
      >
        <mat-icon>reply</mat-icon>
        <span i18n="@@researches.task-comment.reply">Reply</span>
      </button>
    </div>
  </mat-card-actions>
  <mat-card-footer>
    <div
      [ngClass]="
        toggleReplyBoxVisibility || isEditTaskComment
          ? 'visible p-8 w-100'
          : 'hidden'
      "
    >
      <form [formGroup]="commentForm" class="w-100">
        <div *ngIf="!isEditTaskComment; else editToComment">
          <mat-form-field appearance="outline" class="padding-vert w-full">
            <textarea
              id="name"
              type="text"
              formControlName="comment"
              #input
              maxlength="1000"
              matInput
              [placeholder]="placeholderToggleLabel.replyingTo"
              [attr.data-test-id]="'researches.reply-to-a-comment.task-comment.reply-textarea'"
            ></textarea>
            <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
          </mat-form-field>
        </div>

        <ng-template #editToComment>
          <div>
            <mat-form-field appearance="outline" class="padding-vert w-full">
              <textarea
                id="name"
                type="text"
                formControlName="comment"
                #input
                maxlength="1000"
                matInput
                [placeholder]="placeholderToggleLabel.editComment"
                [attr.data-test-id]="'researches.edit-comment.task-comment.edit-textarea'"
              ></textarea>
              <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
            </mat-form-field>
          </div>
        </ng-template>

        <div class="flex justify-end mt-10">
          <button
            class="hover-effect"
            mat-button
            color="warn"
            (click)="cancelComment()"
            i18n="@@researches.task-comment.cancel"
          >
            Cancel
          </button>
          <button
            class="hover-effect"
            mat-button
            color="primary"
            [disabled]="!commentForm.valid"
            (click)="addComment()"
            *appHasPermission="hasCreateTaskCommentPermission()"
            [attr.data-test-id]="'researches.edit-and-reply.task-comment.edit-or-reply-button'"
          >
            {{ isEditTaskComment ? placeholderToggleLabel.editComment : placeholderToggleLabel.replyComment }}
          </button>
        </div>
      </form>
    </div>
  </mat-card-footer>
</mat-card>
<div *ngIf="taskComment?.replies?.length! > 0">
  <app-task-reply-comment
    *ngFor="let comment of taskComment?.replies"
    [comment]="comment"
    [taskComment]="taskComment"
    (isEditReplyTaskCommentClicked)="
      handleEditReplyTaskComment($event, taskComment!)
    "
  >
  </app-task-reply-comment>
</div>
