<mat-card class="main" appearance="outlined">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.activity-types-list.activity-types">
        Activity Types
      </mat-card-title>
      <button
        class="add-btn"
        mat-flat-button
        color="primary"
        (click)="addActivityType()"
        *appHasPermission="hasCreateActivityTypePermission()"
      >
        <span i18n="@@researches.activity-types-list.add" [attr.data-test-id]="'researches-create-activity-type-activity-types-list-addbutton'">Add</span>
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let item of activityTypes">
        <div class="flex-container">
          <span class="item-text">{{ item.name }}</span>
          <button *appHasPermission="hasCrudPermission()" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editActivityType(item)" *appHasPermission="hasUpdateActivityTypePermission()">
              <mat-icon>edit</mat-icon>
              <span i18n="@@researches.activity-types-list.edit">Edit</span>
            </button>
            <button mat-menu-item (click)="deleteActivityType(item)" *appHasPermission="hasRemoveActivityTypePermission()">
              <mat-icon>delete</mat-icon>
              <span i18n="@@researches.activity-types-list.delete">Delete</span>
            </button>
          </mat-menu>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
