import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';

@Directive({
  selector: '[appHasFeature]',
})
export class HasFeatureDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userFacade: UserFacade,
  ) {}

  @Input() appHasFeature: string | string[] = '';
  private hasFeature: boolean = false;
  private isDevelopment: boolean = IS_DEVELOPMENT;
  private user: CurrentLoggedInUser | undefined;

  ngOnInit() {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      this.user = user;
    });
    this.updateFeature();
  }

  private updateFeature() {
    if (this.isDevelopment || (this.checkFeatures())) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasFeature = true;
    } else {
      this.viewContainer.clear();
      this.hasFeature = false;
    }
  }

  private checkFeatures(): boolean {
    if(this.user){
      if (Array.isArray(this.appHasFeature)) {
        return this.appHasFeature.some(feature =>
          this.user && this.user.featureNames.includes(feature)
        );
      } else {
        return this.user && this.user.featureNames.includes(this.appHasFeature as string);
      }
    }
    return false;
  }
}