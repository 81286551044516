import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { FieldValidationRuleFacade } from '../../facades/field-validation-rules.facade';
import { FieldValidationRule } from '../../models/field-validation-rule.model';
import { ValidationRuleValue } from '../../models/validation-rule-value.model';
import { DataType, ValidationRule } from '../../models/validation-rule.model';
import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import { map, take } from 'rxjs';
import { ValidationRules } from 'src/app/core/services/Document-field-control/document-field-control-service';

interface AddFieldValidationRulesComponentState {
  fieldValidationRules: Required<ValidationRule>[];
  currentFieldValidationRules: FieldValidationRule[];
  editFieldValidationRule?: { rule: FieldValidationRule; index: number };
  isAddFieldStepSelected: boolean;
}

const initalState: AddFieldValidationRulesComponentState = {
  fieldValidationRules: [],
  currentFieldValidationRules: [],
  isAddFieldStepSelected: false,
};

@Component({
  selector: 'app-add-field-validation-rules',
  templateUrl: './add-field-validation-rules.component.html',
  styleUrls: ['./add-field-validation-rules.component.scss'],
})
export class AddFieldValidationRulesComponent
  implements OnInit, OnChanges, OnDestroy
{
  validationRules: Required<ValidationRule>[] = [];
  currentFieldValidationRules$ = this.state.select(
    'currentFieldValidationRules',
  );
  currentFieldValidationRules: FieldValidationRule[] = [];

  fieldValidationRuleForm = this.fb.group({
    validationRule: [
      null as Required<ValidationRule> | null,
      this.uniqueValidator(),
    ],
    values: this.fb.array<
      FormGroup<{
        value: FormControl<string | null>;
        order: FormControl<number | null>;
        id: FormControl<number | undefined | null>;
      }>
    >([]),
  });

  @Input() templateFieldId?: string;
  @Input() dataType?: DataType;

  @Input()
  previousRules: FieldValidationRule[] | undefined;

  isAddFieldStepSelected?: boolean;
  isAddFieldStepSelected$ = this.state.select('isAddFieldStepSelected');
  constructor(
    private fb: FormBuilder,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private state: RxState<AddFieldValidationRulesComponentState>,
    private distroyRef: DestroyRef,
    private dialog: MatDialog,
    private documentFormFacade: DocumentFormFacade,
  ) {
    this.state.connect(
      'fieldValidationRules',
      this.fieldValidationRuleFacade.fieldValidationRules$,
    );
    this.state.connect(
      'currentFieldValidationRules',
      this.fieldValidationRuleFacade.currentFieldValidationRules$,
    );
    this.state.connect(
      'isAddFieldStepSelected',
      this.documentFormFacade.isAddFieldStepSelected$,
    );
    this.state.set(initalState);
  }

  ngOnDestroy(): void {
    this.fieldValidationRuleFacade.dispatchClearVaidationRule();
  }

  ngAfterViewInit() {
    this.isAddFieldStepSelected$.subscribe((isAddFieldStepSelected) => {
      if (isAddFieldStepSelected) {
        this.fieldValidationRuleForm?.get('validationRule')?.setErrors(null);
      }
    });
  }

  uniqueValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        control.value &&
        this.currentFieldValidationRules.find(
          (rule) => rule?.validationRule?.id == control.value.id,
        )
      )
        return { ruleMustBeUnique: true };
      return null;
    };
  }

  isRuleMandatory(ruleId: string) {
    return (
      (this.dataType == 'Email' && ruleId == ValidationRules.EmailIsValid) ||
      (this.dataType == 'Phone' && ruleId == ValidationRules.PhoneIsValid) ||
      (this.dataType == 'Currency' && ruleId == ValidationRules.CurrencyIsValid)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataType) {
      this.fieldValidationRuleFacade
        .dispatchGetFieldValidationRulesByDataType(this.dataType)
        .pipe(take(2))
        .subscribe((val) => {
          let validator: Required<ValidationRule> | undefined;

          if (this.dataType == 'Email') {
            validator = this.validationRules.find(
              (rule) => rule.id == ValidationRules.EmailIsValid,
            );
          } else if (this.dataType == 'Phone') {
            validator = this.validationRules.find(
              (rule) => rule.id == ValidationRules.PhoneIsValid,
            );
          } else if (this.dataType == 'Currency') {
            validator = this.validationRules.find(
              (rule) => rule.id == ValidationRules.CurrencyIsValid,
            );
          }

          validator &&
            this.fieldValidationRuleFacade.dispatchAddVaidationRule({
              validationRule: validator,
              validationRuleValues: [],
            });
        });
    }

    if (this.templateFieldId) {
      this.fieldValidationRuleFacade.dispatchGetTemplateFieldValidationRules(
        this.templateFieldId,
      );
    }

    if (changes['dataType']) {
      this.fieldValidationRuleFacade.dispatchClearVaidationRule();
      this.validationRulesValues.clear();
      this.fieldValidationRuleForm.reset();
    }

    if (changes['previousRules']) {
      var updated = changes['previousRules'].currentValue;
      updated &&
        this.fieldValidationRuleFacade.dispatchAddValidationRules(updated);
    }
  }

  ngOnInit(): void {
    if (this.templateFieldId) {
      this.fieldValidationRuleFacade.dispatchGetTemplateFieldValidationRules(
        this.templateFieldId,
      );
    }
    this.fieldValidationRuleForm.controls.validationRule.valueChanges
      .pipe(takeUntilDestroyed(this.distroyRef))
      .subscribe((rule) => {
        if (rule) {
          this.fieldValidationRuleForm.controls.values.clear();
          for (let index = 0; index < rule.parametersCount; index++) {
            this.fieldValidationRuleForm.controls.values.push(
              this.fb.group({
                id: [undefined as number | undefined],
                value: ['', Validators.required],
                order: [index, Validators.required],
              }),
            );
            this.fieldValidationRuleForm.controls.values.setErrors(null);
          }
        }
      });

    this.state
      .select('fieldValidationRules')
      .subscribe((val) => (this.validationRules = val));

    this.state.select('editFieldValidationRule').subscribe((val) => {
      if (val) {
        this.fieldValidationRuleForm.patchValue({
          values: val.rule.validationRuleValues,
        });
      }
    });

    this.currentFieldValidationRules$.subscribe(
      (currentRules) => (this.currentFieldValidationRules = currentRules),
    );
  }

  get validationRulesValues(): FormArray {
    return this.fieldValidationRuleForm.get('values') as FormArray;
  }

  get isInUpdateMode(): boolean {
    return !!this.state.get('editFieldValidationRule');
  }

  addValidationRule() {
    if (this.fieldValidationRuleForm.valid) {
      const { validationRule, values } = this.fieldValidationRuleForm.value;

      const validationRuleValues = values!.map(({ value, order }, index) => {
        return { value: value!, order: order! };
      });

      if (validationRule?.name === 'IsEmpty') {
        this.fieldValidationRuleFacade.dispatchClearVaidationRule();
      }

      this.fieldValidationRuleFacade.dispatchAddVaidationRule({
        validationRule: validationRule!,
        validationRuleValues,
      });

      this.validationRulesValues.clear();
      this.fieldValidationRuleForm.reset();
      this.fieldValidationRuleForm.controls['validationRule'].setErrors(null);
    }
  }

  get formDisabled() {
    return this.currentFieldValidationRules$.pipe(
      map((rules) =>
        rules.some((rule) => rule.validationRule.name == 'IsEmpty'),
      ),
    );
  }

  selectValidationRuleForEdition(rule: FieldValidationRule, index: number) {
    this.fieldValidationRuleForm.patchValue({
      validationRule: rule.validationRule,
    });
    this.state.set({ editFieldValidationRule: { rule, index } });
  }

  updateValidationRuleForEdition() {
    if (
      this.fieldValidationRuleForm.valid &&
      this.state.get('editFieldValidationRule')
    ) {
      const { index, rule } = this.state.get('editFieldValidationRule')!;
      const { validationRule, values } = this.fieldValidationRuleForm.value;

      const updateFieldRule: FieldValidationRule = {
        id: rule.id,
        validationRule: validationRule!,
        validationRuleValues: values as any,
      };

      this.fieldValidationRuleFacade.dispatchUpdateFieldValidationRule(
        index,
        updateFieldRule,
      );

      this.validationRulesValues.clear();
      this.fieldValidationRuleForm.reset();
      this.fieldValidationRuleForm.controls['validationRule'].setErrors(null);
    }
  }

  cancelEdition() {
    this.validationRulesValues.clear();
    this.fieldValidationRuleForm.reset();
    this.fieldValidationRuleForm.controls['validationRule'].setErrors(null);
    this.state.set({ editFieldValidationRule: undefined });
  }

  compareFunction(obj1: any, obj2: any) {
    return obj1?.id === obj2?.id;
  }

  formatRuleValues(values: ValidationRuleValue[]) {
    return values.map((val) => val.value).join(',');
  }

  deleteFieldValidationRule(rule: FieldValidationRule) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.add-field-validation-rules.delete-field-validation:Are you sure you want to delete field validation rule:`,
        boldText: ` "${rule.validationRule.name}" `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.fieldValidationRuleFacade.dispatchDeleteFieldValidationRules(rule);
        this.getValidationRules();
      }
    });
  }
  getValidationRules() {
    this.currentFieldValidationRules$.subscribe((rules) => {
      if (rules.some((rule) => rule.validationRule.name === 'IsEmpty')) {
        this.validationRules = [];
      } else {
        if (this.dataType) {
          this.fieldValidationRuleFacade.dispatchGetFieldValidationRulesByDataType(
            this.dataType,
          );
        }

        if (rules.some((rule) => rule.validationRule.name === 'IsNotEmpty')) {
          this.validationRules = this.validationRules.filter(
            (rule) => rule.name !== 'IsEmpty',
          );
        }
      }
    });
  }
  resetForm() {
    this.fieldValidationRuleForm.reset();
    Object.keys(this.fieldValidationRuleForm.controls).forEach((key) => {
      this.fieldValidationRuleForm.get(key)?.setErrors(null);
    });
  }
}
