import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { StorageInfo } from '../models/storage-info';
import { Workspace } from '../models/workspace';
import { WorkspaceForm } from '../models/workspace-form';
import {
  GetStorageInfo,
  GetMyWorkspace,
  UpdateWorkspace,
  GetWorkspaces,
  SelectEditableWorkspace,
  SyncWorkspaceInfo,
  StopSyncingWorkspaceInfo,
  GetArchivedWorkspaces,
  SelectArchivedWorkspace,
  SearchWorkspaces,
  DeleteArchivedWorkspace,
} from '../store/workspace.actions';
import { WorkspaceSelector } from '../store/workspace.selector';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceFacade {
  constructor(private store: Store) {}

  currentWorkspace$: Observable<Workspace | undefined> = this.store.select(
    WorkspaceSelector.slices.currentWorkspace,
  );

  selectedWorkspace$: Observable<Workspace | undefined> = this.store.select(
    WorkspaceSelector.slices.selectedWorkspace,
  );

  editableWorkspace$: Observable<Workspace | undefined> = this.store.select(
    WorkspaceSelector.slices.editableWorkspace,
  );

  storageInfo$: Observable<StorageInfo | undefined> = this.store.select(
    WorkspaceSelector.slices.storageInfo,
  );

  workspaces$: Observable<PaginatedList<Workspace>> = this.store.select(
    WorkspaceSelector.slices.workspaces,
  );

  @Select(WorkspaceSelector.archivedWorkspaces)
  archivedWorkspaces$!: Observable<PaginatedList<Workspace>>;

  @Select(WorkspaceSelector.selectedArchivedWorkspace)
  selectedArchivedWorkspace$!: Observable<Workspace | undefined>;

  @Select(WorkspaceSelector.filteredWorkspaces)
  filteredWorkspaces$!: Observable<Workspace[]>;

  dispatchGetWorkspace() {
    this.store.dispatch(new GetMyWorkspace());
  }

  dispatchGetWorkspaces(pageSize: number, pageNumber: number) {
    this.store.dispatch(new GetWorkspaces(pageSize, pageNumber));
  }

  dispatchGetMyWorkspace() {
    this.store.dispatch(new GetMyWorkspace());
  }

  dispatchGetStorageInfo(workspaceId?: string) {
    this.store.dispatch(new GetStorageInfo(workspaceId));
  }

  dispatchUpdateWorkspace(workspaceForm: WorkspaceForm) {
    this.store.dispatch(new UpdateWorkspace(workspaceForm));
  }

  dispatchEditableWorkspace(workspace: Workspace) {
    this.store.dispatch(new SelectEditableWorkspace(workspace));
  }

  dispatchSyncWorkspaceInfo(workspace: Workspace) {
    this.store.dispatch(new SyncWorkspaceInfo(workspace));
  }

  dispatchStopSyncingWorkspaceInfo() {
    this.store.dispatch(new StopSyncingWorkspaceInfo());
  }

  dispatchGetArchivedWorkspaces(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetArchivedWorkspaces(pageNumber, pageSize));
  }

  dispatchSelectArchivedWorkspace(workspace: Workspace | undefined) {
    this.store.dispatch(new SelectArchivedWorkspace(workspace));
  }

  dispatchSearchWorkspaces(
    name?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(new SearchWorkspaces(name, pageNumber, pageSize));
  }

  dispatchDeleteArchivedWorkspace(workspaceId:string) {
    this.store.dispatch(new DeleteArchivedWorkspace(workspaceId));
  }
}
