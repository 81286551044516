import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ResearchesHomeSelector } from '../store/researches-home.selector';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../components/breadcrumb-navigation/breadcrumb-navigation.component';
import { UpdateBreadcrumbs } from '../store/researches-home.actions';

@Injectable({
  providedIn: 'root',
})
export class ResearchesHomeFacade {
  @Select(ResearchesHomeSelector.breadcrumbs)
  breadcrumbs$!: Observable<Breadcrumb[]>;

  constructor(private store: Store) {}

  dispatchUpdateBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.store.dispatch(new UpdateBreadcrumbs(breadcrumbs));
  }
}
