import { Component, OnInit } from '@angular/core';
import { BudgetPerCommunication } from '../../models/communication-vs-time.mode';
import { EChartsOption } from 'echarts';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { RxState } from '@rx-angular/state';
import { ENGLISH_LANGUAGE } from 'src/app/core/constants/language';

interface BudgetPerCommunicationComponentState {
  totalCost: number;
  totalCostResearch: number;
  totalCostCommunication: number;
  budgetPerCommunication: BudgetPerCommunication[];
}

const initBudgetPerCommunicationComponentState: Partial<BudgetPerCommunicationComponentState> =
  {
    totalCost: 0,
    totalCostResearch: 0,
    totalCostCommunication: 0,
    budgetPerCommunication: [],
  };

@Component({
  selector: 'app-budget-per-communication',
  templateUrl: './budget-per-communication.component.html',
  styleUrls: ['./budget-per-communication.component.scss'],
})
export class BudgetPerCommunicationComponent implements OnInit {
  chartOption!: EChartsOption;
  budgetPerCommunication$ = this.state.select('budgetPerCommunication');
  budgetPerCommunication: BudgetPerCommunication[] = [];
  totalCost$ = this.state.select('totalCost');
  totalCost: number = 0;
  totalCostResearch$ = this.state.select('totalCostResearch');
  totalCostResearch: number = 0;
  totalCostCommunication$ = this.state.select('totalCostCommunication');
  totalCostCommunication: number = 0;
  value = (this.totalCostResearch / this.totalCost) * 100;
  locale = localStorage.getItem('locale')?? ENGLISH_LANGUAGE.locale;

  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    private state: RxState<BudgetPerCommunicationComponentState>,
  ) {
    this.state.set(initBudgetPerCommunicationComponentState);
    this.state.connect(
      'budgetPerCommunication',
      this.researchDashboardFacade.budgetPerCommunication$,
    );
    this.state.connect('totalCost', researchDashboardFacade.totalCost$);
    this.state.connect(
      'totalCostResearch',
      researchDashboardFacade.totalResearchCost$,
    );
    this.state.connect(
      'totalCostCommunication',
      researchDashboardFacade.totalCommunicationCost$,
    );
  }

  ngOnInit(): void {
    this.researchDashboardFacade.dispatchGetBudgetPerCommunication();
    this.budgetPerCommunication$.subscribe((data) => {
      this.budgetPerCommunication = data;
      this.updateChart();
    });

    this.researchDashboardFacade.dispatchGetTotalCount();

    this.totalCost$.subscribe((data) => {
      this.totalCost = data;
      this.value = (this.totalCostResearch / this.totalCost) * 100;
    });

    this.researchDashboardFacade.dispatchGetTotalResearchCount();

    this.totalCostResearch$.subscribe((data) => {
      this.totalCostResearch = data;
      this.value = (this.totalCostResearch / this.totalCost) * 100;
    });

    this.researchDashboardFacade.dispatchGetTotalCommunicationCount();

    this.totalCostCommunication$.subscribe((data) => {
      this.totalCostCommunication = data;
    });
  }

  updateChart(): void {
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: '{a0}: {c0} Birr',
      },
      yAxis: {
        type: 'category',
        data: this.budgetPerCommunication.map((item) => item.processTypeName),
      },
      xAxis: [
        {
          type: 'value',
          name: 'Total Cost',
          axisLabel: {
            formatter: '{value} Birr',
          },
        },
      ],
      series: [
        {
          name: 'Total Cost',
          data: this.budgetPerCommunication.map((item) => ({
            value: item.totalCost,
            percentage: item.percentage,
          })),
          type: 'bar',
          itemStyle: {
            color: () => getRandomColor(),
          },
          label: {
            show: true,
            position: 'right',
            formatter: (params: any) => {
              return `${params.value} Birr, ${params.data.percentage.toFixed(
                2,
              )}%`;
            },
          },
        },
      ],
    };
  }
}
