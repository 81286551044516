<form class="workspace-form" [formGroup]="workspaceForm" *appHasPermission="hasUpdateWorkspacePermission()">
  <h2 mat-dialog-title i18n="@@files.workspace-form.update-workspace-quota">
    Update Workspace Quota
  </h2>

  <mat-dialog-content class="mat-typography content">
    <div>
      <span i18n="@@files.workspace-form.quota-limit">Quota Limit </span>
      <span class="body-2 bold"
        >{{ workspaceForm.controls.quotaLimit.value.toFixed(2) }}
        {{ sizeUnits[workspaceForm.controls.quotaLimitUnit.value] }}</span
      >
    </div>
    <div class="row">
      <div class="grow">
        <mat-slider
          [min]="range.min"
          [max]="range.max"
          class="slider"
          step="0.1"
        >
          <input matSliderThumb formControlName="quotaLimit" />
        </mat-slider>
      </div>
      <div class="">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@files.workspace-form.size-unit"
            >Size unit</mat-label
          >
          <mat-select formControlName="quotaLimitUnit">
            <mat-option
              *ngFor="let unit of sizeUnits; let val = index"
              [value]="val"
              >{{ unit }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close i18n="@@files.workspace-form.cancel">
      Cancel
    </button>
    <button
      mat-button
      cdkFocusInitial
      color="primary"
      type="submit"
      (click)="submit()"
      i18n="@@files.workspace-form.save"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
