<div class="editor--wrapper">
  <div id="editor" [attr.data-test-id]="'researches.get-task-detail.task-compose.div'"></div>
  <div class="editor--actions" *ngIf="!processInstanceDetail?.isArchived && currentLoggedInUser?.id === assigneeId">
    <button mat-button (click)="reset()"  i18n="@@researches.task-compose.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="
      !composeIsValid ||         
      this.taskDetail.taskStatus.toString() === TaskStatus.Submitted ||
      this.taskDetail.taskStatus.toString() === TaskStatus.Done || 
      this.taskDetail.taskStatus.toString() === TaskStatus.Approved"
      i18n="@@researches.task-compose.save"
      [attr.data-test-id]="'researches.get-task-detail.task-compose.save-button'"
    >
      Save
    </button>
    <button 
    mat-flat-button
    color="primary"
    (click)="exportPdf()" [attr.data-test-id]="'researches.get-task-detail.task-compose.download-pdf-button'"
    >Download PDF</button>
  </div>
</div>
