import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { WorkspaceFacade } from '../../facade/workspace.facade';
import { SizeUnits, Workspace } from '../../models/workspace';
import { WorkspaceListComponent } from '../workspace-list/workspace-list.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface WorkspaceComponentState {
  currentWorkspace?: Workspace;
}

const initWorkspaceComponentState = {};

export const SIZE_UNITS = ['TB', 'GB', 'MB', 'KB'];

@Component({
  selector: 'app-workspace-quota',
  templateUrl: './workspace-quota.component.html',
  styleUrls: ['./workspace-quota.component.scss'],
  providers: [RxState],
})
export class WorkspaceQuotaComponent implements OnInit, OnDestroy {
  currentWorkspace: Workspace | undefined;

  constructor(
    public dialog: MatDialog,
    public state: RxState<WorkspaceComponentState>,
    private workspaceFacade: WorkspaceFacade,
  ) {
    this.state.set(initWorkspaceComponentState);
    this.state.connect('currentWorkspace', workspaceFacade.currentWorkspace$);
  }

  ngOnInit(): void {
    this.workspaceFacade.dispatchGetWorkspace();

    this.state.select('currentWorkspace').subscribe((ws) => {
      this.currentWorkspace = ws;
      this.workspaceFacade.dispatchSyncWorkspaceInfo(this.currentWorkspace!);
    });
  }

  ngOnDestroy(): void {
    this.workspaceFacade.dispatchStopSyncingWorkspaceInfo();
  }

  get usage(): number {
    if (!this.currentWorkspace) {
      return 100;
    }

    const { contentSize, contentSizeUnit, quotaLimit, quotaLimitUnit } =
      this.currentWorkspace;

    const unitDif = SizeUnits[quotaLimitUnit] - SizeUnits[contentSizeUnit];

    return (contentSize * Math.pow(1024, unitDif) * 100) / (quotaLimit || 1);
  }

  get workspaceSize(): string {
    if (!this.currentWorkspace) {
      return '0 KB';
    }

    const { quotaLimitUnit, contentSizeUnit, contentSize } =
      this.currentWorkspace;

    return `${(
      contentSize *
      Math.pow(1024, SizeUnits[quotaLimitUnit] - SizeUnits[contentSizeUnit])
    ).toFixed(2)} ${SIZE_UNITS[SizeUnits[quotaLimitUnit]]}`;
  }

  get workspaceLimit(): string {
    if (!this.currentWorkspace) {
      return '0 KB';
    }

    return `${this.currentWorkspace.quotaLimit.toFixed(2)} ${
      SIZE_UNITS[SizeUnits[this.currentWorkspace.quotaLimitUnit]]
    }`;
  }

  openDialog() {
    this.dialog.open(WorkspaceListComponent, {
      width: '100%',
      maxWidth: '720px',
    });
  }

  get isAdmin(): boolean {
    return true;
  }

  hasGetWorkspacesPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.GetWorkspaces,
    );
  }

  hasGetWorkspacePermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.GetWorkspace,
    );
  }
}
