<div class="wrapper">
  <h1 class="mat-h1">
    {{ selectedWorkflowType?.name }}
  </h1>
  <mat-divider></mat-divider>
  <div class="row" style="margin-top: 1em">
    <h2 class="mat-h2" i18n="@@documents.workflow-validations.steps">Steps</h2>
    <button
      mat-flat-button
      color="primary"
      (click)="isValidationFormOpend = true"
      *appHasPermission="hasCreateWorkflowValidationsPermission()"
    >
      <mat-icon>add</mat-icon>
      <span i18n="@@documents.workflow-validations.add"> Add </span>
    </button>
  </div>

  <ng-container *ngIf="isValidationFormOpend">
    <app-workflow-validation-form (close)="close()" />
  </ng-container>

  <div class="column">
    <mat-card
      class="form-card mat-elevation-z0"
      appearance="outlined"
      style="overflow: hidden"
    >
      <mat-paginator
        [length]="(workflowValidations$ | async)?.totalCount"
        [pageIndex]="((workflowValidations$ | async)?.pageNumber || 0) - 1"
        [pageSizeOptions]="[10, 25, 50, 100]"
        (page)="paginateWorkflowValidations($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </mat-card>

    <mat-card
      class="form-card mat-elevation-z0"
      appearance="outlined"
      *ngFor="
        let validation of (workflowValidations$ | async)?.items;
        index as i
      "
    >
      <mat-card-header>
        <div class="row" style="padding: 0; width: 100%">
          <p class="mat-subheading-1" style="margin: 0"><span i18n="@@documents.workflow-validations.logic"> Logic </span> {{ i + 1 }}</p>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="selecteWorkflowValidation(validation)"
              *appHasPermission="hasEditWorkflowValidationsPermission()"
            >
              <mat-icon>edit</mat-icon> <span i18n="@@documents.workflow-validations.edit"> Edit </span>
            </button>
            <button
              mat-menu-item
              color="warn"
              (click)="deleteWorkflowValidation(validation.id)"
              *appHasPermission="hasDeleteWorkflowValidationsPermission()"
            >
              <mat-icon>delete</mat-icon> <span i18n="@@documents.workflow-validations.delete"> Delete </span>
            </button>
          </mat-menu>
        </div>
      </mat-card-header>

      <mat-card-content>
        <p class="mat-body-2">
          {{ validation.leftTemplateField.name }}
          <strong>{{ validation.validationRule.name }}</strong>
          {{ validation.rightTemplateField.name }}
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
