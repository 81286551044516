import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PROCESSES_URL,
  SCHEDULE_URL,
  STAGES_URL,
} from 'src/app/core/constants/api-endpoints';
import { STAGE_URL } from 'src/app/core/constants/api-endpoints';
import {
  CreateStageDto,
  Stage,
  UpdateStageOrderDto,
} from '../models/stage.model';
import { CriterionDto } from '../models/criterion.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class StageService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  deleteStage(id: string): Observable<any> {
    return this.http.delete<any>(`${STAGES_URL}/${id}`, this.httpOptions);
  }
  createStage(stage: CreateStageDto): Observable<Stage> {
    return this.http.post<Stage>(STAGE_URL, stage);
  }

  getStageCriteria(id: string): Observable<Stage> {
    return this.http.get<Stage>(`${STAGE_URL}/${id}`, this.httpOptions);
  }

  updateStage(stage: CreateStageDto): Observable<Stage> {
    return this.http.put<Stage>(STAGE_URL, stage);
  }
  orderStage(updateStageOrder: UpdateStageOrderDto): Observable<any> {
    return this.http.put<any>(`${STAGE_URL}/order`, updateStageOrder);
  }
  getPaginatedStageCriteria(
    stageId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<CriterionDto>> {
    let url = `${STAGE_URL}/stage-criteria?stageId=${stageId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<CriterionDto>>(url);
  }
}
