<mat-dialog-content>
  <h2
    mat-dialog-title
    i18n="@@researches.publication-options.publication-options"
  >
    Publication Options
  </h2>
  <div class="button-container">
    <span class="spacer"></span>
    <button
      mat-button
      class="custom-mat-button"
      (click)="addPublicationOption()"
      *appHasPermission="hasAddPublicationOptionPermission()"
      [attr.data-test-id]="'researches.create-publication-option.publication-option.add-option-button'"
    >
      <span i18n="@@researches.publication-options.add-option">Add Option</span>
    </button>
  </div>
  <mat-card
    *ngFor="let item of publicationOptions"
    class="row-container"
    appearance="outlined"
  >
    <mat-card-header>
      <mat-card-title-group [attr.data-test-id]="'researches.get-publication-option.publication-options.mat-card-title-group'">
        <mat-card-title class="title">
          {{ item.title }}
        </mat-card-title>
        <button
          mat-stroked-button
          class="download"
          aria-label="Download"
          [matMenuTriggerFor]="menu"
          *appHasPermission="hasDownloadPublicationTemplatePermission()"
          [attr.data-test-id]="'researches.download-publication-template.publication-options.download-template-button'"
        >
          <mat-icon>download</mat-icon>
          <span i18n="@@researches.publication-options.download-template"
            >Download Template</span
          >
        </button>

        <mat-menu #menu="matMenu">
          <ng-container *appHasPermission="hasDownloadPublicationTemplatePermission()">
          <button
            *ngFor="let option of item.publicationTemplate"
            mat-menu-item
            class="mat-item-download"
            (click)="downloadTemplate(option)"
            [attr.data-test-id]="'researches.download-publication-template.publication-options.select-file-button'"
          >
            {{ option.editorType.name }}
          </button>
        </ng-container>
        </mat-menu>

        <div style="display: flex; align-items: center;">
          <button
            mat-icon-button
            aria-label="Edit"
            (click)="editPublicationOption(item)"
            *appHasPermission="hasUpdatePublicationOptionPermission()"
            [attr.data-test-id]="'researches.update-publication-option.publication-options.edit-button'"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <ng-container *appHasPermission="hasBanPublicationOptionPermission()">
          <button
            *ngIf="isBeingUsed(item) && !item.isBanned"
            mat-stroked-button
            aria-label="Delete"
            color="warn"
            (click)="banPublicationOption(item)"
            i18n="@@researches.publication-options.ban"
            [attr.data-test-id]="'researches.banning-publication-option.publication-options.ban-button'"
          >
            Ban
          </button>
        </ng-container>
          <ng-container
          *appHasPermission="hasPermitPublicationOptionPermission()">
          <button
            *ngIf="isBeingUsed(item) && item.isBanned"
            mat-stroked-button
            aria-label="Delete"
            color="warn"
            (click)="permitPublicationOption(item)"
            i18n="@@researches.publication-options.permit"
            [attr.data-test-id]="'researches.permitting-publication-option.publication-options.permint-button'"
          >
            Permit
          </button>
        </ng-container>
          <ng-container *appHasPermission="hasDeletePublicationOptionPermission()">
          <button
            *ngIf="!isBeingUsed(item)"
            mat-icon-button
            aria-label="Delete"
            (click)="deletePublicationOption(item)"
            [attr.data-test-id]="'researches.delete-publication-option.publication-options.delete-button'"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content/>
  </mat-card>
</mat-dialog-content>
