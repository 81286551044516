import { ArrayDataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { ARCHIVED_WORKSPACES_ROUTE } from 'src/app/core/constants/routes';
import { FolderFacade } from 'src/app/files/facade/folder.facade';
import { WorkspaceFacade } from 'src/app/files/facade/workspace.facade';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';
import { Workspace } from 'src/app/files/models/workspace';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { ArchivedFolderPropertyComponent } from '../archived-folder-property/archived-folder-property.component';
import { ArchivedFolderMoveComponent } from '../archived-folder-move/archived-folder-move.component';
import { FileFacade } from 'src/app/files/facade/file.facade';

interface ArchivedWorkspaceDetailComponentState {
  flatFolderNodes: FlatFolderNode[];
  selectedFlatFolderNode: FlatFolderNode | undefined;
  selectedWorkspace: Workspace | undefined;
}

const initArchivedWorkspaceDetailComponentState: Partial<ArchivedWorkspaceDetailComponentState> =
  {
    flatFolderNodes: [],
  };

@Component({
  selector: 'app-archived-workspace-detail',
  templateUrl: './archived-workspace-detail.component.html',
  styleUrls: ['./archived-workspace-detail.component.scss'],
})
export class ArchivedWorkspaceDetailComponent implements OnInit {
  flatFolderNodes$: Observable<FlatFolderNode[]> =
    this.state.select('flatFolderNodes');
  flatFolderNodes?: FlatFolderNode[];
  selectedFlatFolderNode$: Observable<FlatFolderNode | undefined> =
    this.state.select('selectedFlatFolderNode');
  selectedFlatFolderNode: FlatFolderNode | undefined;
  selectedWorkspace: Workspace | undefined;
  selectedWorkspace$ = this.state.select('selectedWorkspace');

  folderNodes: Array<FlatFolderNode> = [];

  dataSource = new ArrayDataSource(this.folderNodes);

  archivedWorkspaceName: string = '';

  constructor(
    private state: RxState<ArchivedWorkspaceDetailComponentState>,
    private folderFacade: FolderFacade,
    private route: ActivatedRoute,
    private router: Router,
    private workspaceFacade: WorkspaceFacade,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
    private fileFacade: FileFacade,
  ) {
    this.state.connect(
      'flatFolderNodes',
      folderFacade.archivedWorkspaceFolders$,
    );
    this.state.connect(
      'selectedFlatFolderNode',
      folderFacade.selectedArchivedFlatFolderNode$,
    );
    this.state.connect(
      'selectedWorkspace',
      this.workspaceFacade.selectedArchivedWorkspace$,
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.folderFacade.dispatchGetArchivedWorkspaceFolders(params['id']);
    });

    this.selectedWorkspace$.subscribe((selectedWorkspace) => {
      this.selectedWorkspace = selectedWorkspace;
    });

    this.flatFolderNodes$.subscribe((flatFolders) => {
      this.flatFolderNodes = flatFolders;
      this.folderNodes = flatFolders;

      this.dataSource = new ArrayDataSource(this.folderNodes);
    });

    this.selectedFlatFolderNode$.subscribe((selectedFlatFolderNode) => {
      this.selectedFlatFolderNode = selectedFlatFolderNode;
    });

    this.archivedWorkspaceName = this.extractWorkspaceName(
      this.selectedWorkspace?.name,
    );
  }

  treeControl = new FlatTreeControl<FlatFolderNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatFolderNode) => node.expandable;

  getParentNode(node: FlatFolderNode) {
    const nodeIndex = this.folderNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.folderNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.folderNodes[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatFolderNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  handleClick(node: FlatFolderNode) {
    this.folderFacade.dispatchSelectArchivedFlatFolderNode(node);
    this.router.navigate([
      `${ARCHIVED_WORKSPACES_ROUTE}/${this.selectedWorkspace?.id}`,
      node.id,
    ]);
  }

  menuTopLeftPosition = { x: '0', y: '0' };

  @ViewChild(MatMenuTrigger, { static: true })
  matMenuTrigger!: MatMenuTrigger;

  onRightClick(event: MouseEvent, node: FlatFolderNode) {
    this.folderFacade.dispatchSelectArchivedFlatFolderNode(node);
    this.router.navigate([
      `${ARCHIVED_WORKSPACES_ROUTE}/${this.selectedWorkspace?.id}`,
      node.id,
    ]);
    if (this.selectedFlatFolderNode) {
      event.preventDefault();
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.matMenuTrigger.menuData = { item: node };
      this.matMenuTrigger.openMenu();
    }
  }

  extractWorkspaceName(data: any) {
    const archivedIndex = data.indexOf('-ARCHIVED');

    if (archivedIndex === -1) {
      return '';
    }

    const workspaceName = data.substring(0, archivedIndex).trim();
    return workspaceName;
  }

  showFolderProperty() {
    this.matDialog.open(ArchivedFolderPropertyComponent);
  }

  moveFolder() {
    this.folderFacade.dispatchSelectFlatFolderNode(
      this.selectedFlatFolderNode!,
    );
    this.fileFacade.dispatchSetFileSharingMode(false);
    this.matDialog.open(ArchivedFolderMoveComponent, {
      disableClose: true,
      autoFocus: false,
    });
  }
}
