import { PathUpdateType } from './../../models/office.model';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { OfficeFacade } from '../../facades/office.facades';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable, count, filter, of, take } from 'rxjs';
import {
  CreateOfficeDto,
  Office,
  UpdateOfficeDto,
} from '../../models/office.model';
import { RxState } from '@rx-angular/state';
import { Country, PhoneCode } from 'src/app/core/models/PhoneCode.interface';
import { OfficeTreeComponent } from '../office-tree/office-tree.component';
import { OfficeMultipleSelectComponent } from '../office-multiple-select/office-multiple-select.component';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { MatSelectChange } from '@angular/material/select';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface OfficeFormComponentState {
  offices: Office[];
  flatOfficeNodes: FlatOfficeNode[] | null;
  selectedOffice: Office | null;
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
}

const initOfficeFormComponentState = {};

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html',
  styleUrls: ['./office-form.component.scss'],
  providers: [RxState],
})
export class OfficeFormComponent implements OnInit, AfterViewInit {
  update = false;
  toBeUpdatedOffice: Office | undefined = undefined;
  reportsToOfficeIds: string[] = [];
  parentOffice: FlatOfficeNode | null = null;
  officeForm;
  public phoneCode: Country[] = new PhoneCode().items;
  selectedCountry = this.phoneCode.find(
    (country) => country.dialCode === '+251',
  );
  countryCode: string = this.selectedCountry
    ? this.selectedCountry.dialCode
    : '';

  countyCodeName:string = this.selectedCountry
   ? this.selectedCountry.code 
   : ""
  phoneNumberLength: number[] = this.selectedCountry
    ? this.selectedCountry.length
    : [];
  isPrefixVisible = false;
  isLabelVisible = true;

  flatOfficeNodes = this.state.select('flatOfficeNodes');
  selectedOffice$: Observable<Office | null> =
    this.state.select('selectedOffice');
  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.state.select('selectedFlatOfficeNodes');

  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;

  offices$: Observable<any> = this.officeState.select('offices');

  offices: Office[] = [];

  pathUpdateTypes = {
    'Office only': PathUpdateType.OFFICEONLY,
    'With Children': PathUpdateType.WITHCHILDREN,
    'With Decsendants': PathUpdateType.WITHDESCENDANTS,
  };
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowedPhoneNumberLength = this.phoneNumberLength.includes(control.value.length);
      const allowedPhoneNumberFormat = /^\d+$/.test(control.value);
  
      if (!allowedPhoneNumberLength || !allowedPhoneNumberFormat) {
        return of({ forbidden: { value: control.value } });
      }
      return of(null);
    };
  }

  shortNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const maxLength = 50;
      if (control.value && control.value.length > maxLength) {
        return { maxLengthExceeded: true };
      }
      return null;
    };
  }

  officeNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const maxLength = 256;
      if (control.value && control.value.length > maxLength) {
        return { maxLengthExceeded: true };
      }
      return null;
    };
  }

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private state: RxState<OfficeFormComponentState>,
    public officeState: RxState<OfficeFormComponentState>,
    public sanitizerService: SanitizerService,
    private officeFacade: OfficeFacade,
    @Inject(MAT_DIALOG_DATA)
    private data: { update?: boolean; toBeUpdatedOffice?: Office },
  ) {
    this.officeState.set(initOfficeFormComponentState);
    this.officeState.connect('offices', officeFacade.offices$);

    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );

    this.state.connect(
      'selectedFlatOfficeNodes',
      officeFacade.selectedFlatOfficeNodes$,
    );

    this.state.connect('flatOfficeNodes', this.officeFacade.flatOfficeNodes$);

    if (this.data && this.data.update) this.update = this.data.update;

    if (this.data && this.data.toBeUpdatedOffice) {
      this.toBeUpdatedOffice = this.data.toBeUpdatedOffice;
    }

    this.officeForm = this.fb.group({
      name: ['', [Validators.required, this.officeNameValidator()]],
      shortName: ['', [Validators.required, this.shortNameValidator()]],
      countryCode: [this.countryCode as string, [Validators.required]],
      description: ['', [Validators.required]],
      phoneNumber: [
        '',
        [Validators.required],
        this.phoneNumberValidator(),
      ],
    });

    if (this.update && this.toBeUpdatedOffice) {
      this.populateReportsTold();
      this.assignParent();
      this.isPrefixVisible = true;
      this.countryCode = this.toBeUpdatedOffice.phoneNumber.countryCode;
      this.countyCodeName = this.toBeUpdatedOffice.phoneNumber.countryCodeName;
      this.selectedCountry = this.phoneCode.find(
        (country) => country.code === this.countyCodeName && country.dialCode === this.countryCode
      ) || this.phoneCode.find((country) => country.dialCode === this.countryCode);
      this.officeForm.setValue({
        name: this.toBeUpdatedOffice.name,
        shortName: this.toBeUpdatedOffice.shortName,
        countryCode: this.countryCode,
        phoneNumber: this.toBeUpdatedOffice.phoneNumber.number,
        description: this.toBeUpdatedOffice.description,
      });
      this.phoneNumberLength = this.phoneCode.find(
        (c) => c.dialCode === this.countryCode,
      )!.length;
    }
  }

  private PageNumber: number = 1;
  private PageSize: number = 10;

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe(
      (selectedFlatOfficeNode) =>
        (this.selectedFlatOfficeNode = selectedFlatOfficeNode),
    );
    this.selectedFlatOfficeNodes$.subscribe(
      (selectedFlatOfficeNodes) =>
        (this.selectedFlatOfficeNodes = selectedFlatOfficeNodes),
    );
    if (this.data && this.data.update) {
      this.update = this.data.update;
    }
    if (this.data && this.data.toBeUpdatedOffice) {
      this.toBeUpdatedOffice = this.data.toBeUpdatedOffice;
    }
    if (this.update && this.toBeUpdatedOffice) {
      this.populateReportsTold();
      this.assignParent();
    }
  }

  ngAfterViewInit(): void {
    if (this.data && this.data.update) {
      this.update = this.data.update;
    }
  }

  getCountryCode(phoneNumber: string) {
    let codeSize = phoneNumber.length - 9;

    let code = phoneNumber.slice(0, codeSize);
    if (code[0] !== '+') code = '+' + code;
    return code;
  }

  getPhoneNumber(phoneNumber: string) {
    let codeSize = phoneNumber.length - 9;
    return phoneNumber.slice(codeSize);
  }

  populateReportsTold() {
    if (!this.toBeUpdatedOffice) return;
    let toBeSelectedOffices: FlatOfficeNode[] = [];
    let reportsToldsIds = this.toBeUpdatedOffice.reportsToIds;
    for (let id of reportsToldsIds) {
      this.flatOfficeNodes
        .pipe(
          filter((offs) => offs != null),
          take(1),
        )
        .subscribe((flatOffices) => {
          if (flatOffices) {
            toBeSelectedOffices = [
              ...toBeSelectedOffices,
              ...flatOffices.filter((off) => off.id === id),
            ];
          }
        });
    }
    toBeSelectedOffices.filter((off) => {
      if (this.toBeUpdatedOffice) off.id !== this.toBeUpdatedOffice.id;
    });
    this.officeFacade.dispatchSelectFlatOfficeNodes(toBeSelectedOffices);
  }

  assignParent() {
    if (!this.toBeUpdatedOffice) return;

    let pathFromRoot = this.toBeUpdatedOffice.pathFromRoot;
    let pathNodes = pathFromRoot.split('.');
    pathNodes.pop();
    let parentPathFromRoot = pathNodes.join('.');
    this.flatOfficeNodes
      .pipe(
        filter((offices) => offices != null),
        take(1),
      )
      .subscribe((offices) => {
        if (offices) {
          let result = offices.filter(
            (office) => office.pathFromRoot == parentPathFromRoot,
          );
          this.parentOffice = result[0];
          this.officeFacade.dispatchSelectFlatOfficeNode(this.parentOffice);
        }
      });
  }

  onFocus() {
    this.isPrefixVisible = true;
    this.isLabelVisible = true;
  }
  onBlur() {
    if (this.officeForm.value.phoneNumber == '') {
      this.isPrefixVisible = false;
      this.isLabelVisible = true;
    } else {
      this.isLabelVisible = true;
      this.isPrefixVisible = true;
    }
  }
  save() {
    const { valid, touched, dirty } = this.officeForm;
    if (valid && (touched || dirty)) {
      const { name, shortName, description, phoneNumber } =
        this.officeForm.value;
      const phoneNumberPrefix = this.selectedCountry?.dialCode;
      const phoneNumberCountryCode = this.selectedCountry?.code;
      this.selectedFlatOfficeNodes!.forEach((office) =>
        this.reportsToOfficeIds.push(office.id),
      );

      if (!this.update) {
        const office: CreateOfficeDto = {
          name: name?.trim() as string,
          shortName: shortName?.trim() as string,
          description: description?.trim() as string,
          phoneNumber: {
            countryCode: phoneNumberPrefix!,
            number: phoneNumber!,
            countryCodeName: phoneNumberCountryCode!           
          },
          reportsToOfficeIds: this.reportsToOfficeIds,
          parentPathFromRoot:
            this.update && this.toBeUpdatedOffice
              ? this.toBeUpdatedOffice.pathFromRoot
              : this.selectedFlatOfficeNode!.pathFromRoot,
          parentId: this.selectedFlatOfficeNode?.id!,
        };

        this.officeFacade.dispatchCreateOffice(office);
      }
      if (this.update && this.toBeUpdatedOffice) {
        const updatedOffice: UpdateOfficeDto = {
          id: this.toBeUpdatedOffice.id!,
          name: name?.trim() as string,
          shortName: shortName?.trim() as string,
          description: description?.trim() as string,
          phoneNumber: {
            countryCode: phoneNumberPrefix!,
            number: phoneNumber!,
            countryCodeName: phoneNumberCountryCode!   
          },
          reportsToOfficeIds: this.reportsToOfficeIds,
        };

        if (this.toBeUpdatedOffice.id)
          this.officeFacade.dispatchUpdateOffice(
            this.toBeUpdatedOffice.id,
            updatedOffice,
          );
      }
    }
  }

  removeSelectedFlatOfficeNodeFromNodes(officeNode: FlatOfficeNode) {
    if (this.selectedFlatOfficeNodes === undefined) return;
    const index = this.selectedFlatOfficeNodes.indexOf(officeNode);

    if (index >= 0) {
      this.selectedFlatOfficeNodes.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(
        this.selectedFlatOfficeNodes,
      );
    }
  }

  removeSelectedFlatOfficeNode(officeNode: FlatOfficeNode) {
    if (this.selectedFlatOfficeNode === undefined) return;
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(this.selectedFlatOfficeNode);
  }

  openSingleOffice() {
    this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { update: this.update },
    });
  }

  openMultipleOffice() {
    this.populateReportsTold();
    this.matDialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
      data: { update: this.update },
    });
  }

  onCountryCodeSelect(event: MatSelectChange) {
    this.countryCode = event.value.dialCode;
    this.countyCodeName = event.value.code;
    this.phoneNumberLength = this.phoneCode.find(
      (c) => c.dialCode === this.countryCode,
    )!.length;

    this.selectedCountry = this.phoneCode.find(
      (c) => c.code === this.countyCodeName,
    );
    if (!this.selectedCountry) this.selectedCountry = this.phoneCode[0];

    this.officeForm.controls.phoneNumber.updateValueAndValidity();
  }

  onPhoneNumberChange(){
    this.phoneNumberValidator()
  }
  
  hasGetReportsToNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetReportsToNodes,
    );
  }
}
