<mat-horizontal-stepper
  [linear]="true"
  [selectedIndex]="defaultStepIndex"
  (selectionChange)="onStepChange($event)"
>
  <mat-step
    *ngFor="
      let slectedWorkflowWorkflowStep of slectedWorkflowWorkflowSteps;
      let i = index
    "
    [completed]="isNextStepEnabled(i)"
  >
    <ng-template matStepLabel>{{
      slectedWorkflowWorkflowStep?.name
    }}</ng-template>
    <ng-template matStepContent>
      <div class="template-detail">
        <div class="flex">
          <div>
            <div class="row">
              <h1 class="mat-h1">{{ workflowStep?.name }}</h1>
            </div>
            <p class="mat-body">
              {{ workflowStep?.description }}
            </p>
            <div class="flex-row">
              <mat-chip *ngIf="isChangeRequestVisible">
                {{ workflowStep?.workflowStepAction }}
              </mat-chip>
              <div *ngIf="isChangeRequestVisible">
                <button mat-icon-button (click)="toggleRejectionReason()">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="Workflow Step Rejection Reason"
                    fontIcon="comment"
                  ></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex"
            align="end"
            *appHasPermission="hasAssignUserToWorkflowStepPermission()"
          >
          </div>

          <div
            class="flex"
            align="end"
            *appHasPermission="hasAssignUserToWorkflowStepPermission()"
          >
            <button
              mat-stroked-button
              [ngClass]="toggleButton === 'Cancel' ? 'border-red' : ''"
              [disabled]="route.snapshot.queryParams['isArchived'] || !isEditable(workflowStep) || ! isCurrentUserWorkflowAssigner()"
              (click)="assignUser(workflowStep)"
              [attr.data-test-id]="
                'documents.assign-user.workflow-step.assign-user-button'
              "
              color="primary"
            >
              {{ toggleButton }}
            </button>
            <div
              class="flex"
              [ngClass]="
                !assignUserClicked && workflowStep?.assignee?.fullName
                  ? 'visible'
                  : 'hidden'
              "
            >
              <div class="avator">
                <span>{{
                  workflowStep?.assignee?.fullName?.substring(0, 1)
                }}</span>
              </div>
              <mat-card-title class="text">
                {{ workflowStep?.assignee?.fullName }}
              </mat-card-title>
            </div>
            <form
              class="flex"
              [formGroup]="assigneeForm"
              *ngIf="assignUserClicked"
            >
              <mat-form-field class="full-width" appearance="outline">
                <input
                  #officeInput
                  matInput
                  [placeholder]="placeholderFieldLabel.selectUserField"
                  formControlName="assignee"
                  [matAutocomplete]="userauto"
                  [attr.data-test-id]="
                    'documents.change-and-assigne-user.workflow-step.search-user-input'
                  "
                />
                <mat-autocomplete
                  #userauto="matAutocomplete"
                  (optionSelected)="selectUser($event)"
                >
                  <mat-option
                    *ngFor="let user of filteredOfficeUsers"
                    [value]="user"
                    [attr.data-test-id]="
                      'documents.change-and-assigne-user.workflow-step.map-option'
                    "
                  >
                    <div class="flex">
                      <div class="avator">
                        <span>{{ user.firstName.substring(0, 1) }}</span>
                      </div>
                      <mat-card-title class="text">
                        {{ user.firstName }} {{ user.middleName }}
                        {{ user.lastName }}
                      </mat-card-title>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
        </div>
        <div class="archive-button">
          <button mat-raised-button color="primary" *ngIf="!route.snapshot.queryParams['isArchived'] && isStepArchivable(slectedWorkflowWorkflowStep) && isCurrentUserWorkflowAssigner()" (click)="archiveWorkflow($event)">
            Archive workflow
          </button>
        </div>
      </div>
      <div class="mid-content">
        <div class="mid-main">
          <form [formGroup]="form">
            <mat-card appearance="outlined">
              <mat-card-header />
              <mat-card-content formArrayName="documentFields">
                @for (
                  field of form.controls.documentFields.controls;
                  track field.value.id;
                  let i = $index
                ) {
                  <div class="card" [formGroup]="field" appearance="outlined">
                    <div>
                      <h4 class="label-text">
                        {{ field.value.templateField?.name || "" }}
                      </h4>
                      <p>{{ field.value.templateField?.description }}</p>
                      <app-custom-input
                        [label]="field.value.templateField?.name || ''"
                        *ngIf="isEditable(workflowStep)"
                        [placeholder]="field.value.templateField?.placeholder"
                        [isReadOnly]="!isStepAssigned"
                        [dataType]="field.value.templateField?.dataType"
                        [control]="field.controls.fieldValue"
                        [options]="field.value.templateField?.optionValues"
                        [hasStartAndEndDateValidator]="hasStartAndEndDateValidator"
                        [error]="
                          getValidationErrorMessage(
                            field.controls['fieldValue']
                          )
                        "
                      />
                      <div *ngIf="!isEditable(workflowStep)">
                        <span>
                          {{ field.value.templateField?.name || "" }} :
                        </span>
                        {{ field.value.fieldValue }}
                      </div>
                    </div>
                  </div>
                }
              </mat-card-content>
            </mat-card>

            <div
              class="action-container"
              *ngIf="
                this.workflowStep?.workflowStepAction === 'Draft' &&
                stepHasTableFields
              "
            >
              <button mat-raised-button color="primary" (click)="addRow()">
                Add Row
              </button>
              <button mat-stroked-button color="primary" (click)="removeRow()">
                Delete Row
              </button>
            </div>

            <h2 *ngIf="documentFieldTableName" class="mat-h2">
              {{ documentFieldTableName }}
            </h2>
            <div class="table-container" [ngClass]="{'side-content-visible': isSideContentVisible}">
            <table>
              <thead>
                <tr>
                  <th
                    *ngFor="let header of formTableHeaders"
                    class="mat-subtitle-1"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="tableDocumentFields">
                @for (
                  rowControl of form.controls.tableDocumentFields.controls;
                  track i;
                  let i = $index
                ) {
                  <tr class="table-row">
                    @for (
                      field of rowControl.controls;
                      track field.value.groupProperties?.column +
                        "-" +
                        field.value.groupProperties?.column;
                      let i = $index
                    ) {
                      <ng-container [formArrayName]="i">
                        <td [formGroup]="field">
                          <app-custom-input
                            [label]="field.value.templateField?.name || ''"
                            *ngIf="isEditable(workflowStep)"
                            [placeholder]="
                              field.value.templateField?.placeholder
                            "
                            [isReadOnly]="!isStepAssigned"
                            [dataType]="field.value.templateField?.dataType"
                            [control]="field.controls.fieldValue"
                            [options]="field.value.templateField?.optionValues"
                            [error]="
                              getValidationErrorMessage(
                                field.controls['fieldValue']
                              )
                            "
                          />

                          <p
                            *ngIf="
                              this.workflowStep?.workflowStepAction ===
                              'Submitted'
                            "
                          >
                            {{ field.value.fieldValue }}
                          </p>
                        </td>
                      </ng-container>
                    }
                  </tr>
                }
              </tbody>
            </table>
            </div>
            <div class="attach-file">
                <div>
                  <button
                    type="button"
                    mat-button
                    color="accent"
                    (click)="chooseFiles.click()"
                    class="custom-btn-space"
                    *ngIf="!route.snapshot.queryParams['isArchived']"
                  >
                    <mat-icon>file_upload</mat-icon>
                    <p
                      class="nowrap-text"
                      i18n="@@documents.workflow-step.choose-file"
                    >
                      Choose File
                    </p>
                  </button>

                  <mat-chip
                    class="truncated-chip"
                    (removed)="removeSelectedFile()"
                    *ngIf="selectedFile"
                  >
                    <span>
                      {{
                        extractFileNameAndExtension(selectedFile.name).name.length > 25
                          ? extractFileNameAndExtension(selectedFile.name).name.slice(0, 25) + "..."
                          : extractFileNameAndExtension(selectedFile.name).name
                      }}.{{ extractFileNameAndExtension(selectedFile.name).extension }}
                    </span>
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </div>

              <button
                [disabled]="!selectedFile"
                mat-flat-button
                color="primary"
                (click)="uploadFile()"
                i18n="@@documents.workflow-step.upload-file"
                [attr.data-test-id]="'upload-currency-excel'"
              >
                Upload File
              </button>
              <input
                type="file"
                hidden="true"
                #chooseFiles
                class="input"
                (change)="selectFile($event)"
              />
            </div>

            <mat-chip-listbox
            class="mat-mdc-chip-set-stacked"
          >
            <mat-chip
              class="truncated-chip"
              *ngFor="let file of workflowStepFiles?.items"
              (removed)="removeWorkflowStepFile(file)"
              (click)="previewWorkflowStepFile(file.id,file.name)"          >
              <fa-icon
                matChipAvatar
                [icon]="getIconForFile(file.name)"
              ></fa-icon>
              <span class="cursor-pointer">
                {{
                  extractFileNameAndExtension(file.name).name.length > 25
                    ? extractFileNameAndExtension(file.name).name.slice(0, 25) +
                      "..."
                    : extractFileNameAndExtension(file.name).name
                }}.{{ extractFileNameAndExtension(file.name).extension }}
              </span>
              <button matChipRemove *ngIf="!route.snapshot.queryParams['isArchived'] &&
              this.workflowStep?.assignee?.id ==
                this.currentLoggedInUser?.id">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>

            <button
              class="action-btn"
              mat-stroked-button
              color="primary"
              [matMenuTriggerFor]="menu"
              [attr.data-test-id]="
                'documents.get-actions-menus.workflow-step.actions-button'
              "
            >
              Actions
            </button>
            <mat-menu #menu="matMenu">
              <ng-container
                *ngIf="
                  !route.snapshot.queryParams['isArchived'] &&
                  this.workflowStep?.assignee?.id ===
                    this.currentLoggedInUser?.id
                "
              >
                <button
                  *ngIf="isActionVisible()"
                  mat-menu-item
                  (click)="draft()"
                  i18n="@@documents.workflow-step.draft"
                  [attr.data-test-id]="
                    'documents.draft-action.workflow-step.draft-button'
                  "
                >
                  Draft
                </button>
                <button
                  mat-menu-item
                  (click)="submit()"
                  i18n="@@documents.workflow-step.submit"
                  *ngIf="isActionVisible()"
                  [attr.data-test-id]="
                    'documents.submit-action.workflow-step.submit-button'
                  "
                >
                  Submit
                </button>
                <button
                  *ngIf="
                    isActionVisible() &&
                    !(slectedWorkflowWorkflowStep.order.toString() === '1')
                  "
                  mat-menu-item
                  (click)="reject()"
                  i18n="@@documents.workflow-step.reject"
                  [attr.data-test-id]="
                    'documents.reject-action.workflow-step.reject-button'
                  "
                >
                  Reject
                </button>
                <button
                  *ngIf="
                    isActionVisible() &&
                    !(slectedWorkflowWorkflowStep.order.toString() === '1')
                  "
                  mat-menu-item
                  (click)="rollback()"
                  i18n="@@documents.workflow-step.rollback"
                  [attr.data-test-id]="
                    'documents.rollback-action.workflow-step.rollback-button'
                  "
                >
                  Rollback
                </button>
              </ng-container>
              <button
                mat-menu-item
                (click)="downloadWorkflowStep($event)"
                *appHasPermission="hasDownloadWorkflowStepPermission()"
                i18n="@@documents.workflow-step.download"
                [attr.data-test-id]="
                  'documents.download-action.workflow-step.download-button'
                "
              >
                download
              </button>
            </mat-menu>
          </form>
        </div>
        <button
          mat-icon-button
          color="primary"
          (click)="toggleSideContent()"
          [attr.data-test-id]="'documents.expand.workflow-step.mat-icon-button'"
        >
          <mat-icon>{{
            isSideContentVisible
              ? "keyboard_arrow_right"
              : "keyboard_arrow_left"
          }}</mat-icon>
        </button>
        <div class="side-content" [class.collapsed]="!isSideContentVisible">
          <mat-tab-group class="tab-container" *ngIf="isSideContentVisible">
            <mat-tab [label]="comment">
              <app-workflow-comment
                [isArchived]="route.snapshot.queryParams['isArchived']"
              />
            </mat-tab>
            <mat-tab
              [label]="ccManagement"
              *appHasPermission="hasGetNotifiedUsersPermission()"
            >
              <app-attached-user-list
                [isArchived]="route.snapshot.queryParams['isArchived']"
              />
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
