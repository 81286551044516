import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  StageInstance,
  UpdateStageInstanceStatusDto,
} from '../models/process-instance.model';
import { Observable } from 'rxjs';
import { STAGE_INSTANCES_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  StageInstanceByReviewers,
  StageInstanceReview,
  MyStageInstanceReview,
  StagesWithoutEvaluationSchedule,
  Review,
} from '../models/stage-instance-detail.model';
import { StageInstanceReviewerType } from '../models/stage.model';

@Injectable({
  providedIn: 'root',
})
export class StageInstanceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  updateStageInstanceStatusService(
    stageInstance: UpdateStageInstanceStatusDto,
  ): Observable<StageInstance> {
    return this.http.put<StageInstance>(
      `${STAGE_INSTANCES_URL}/change-status`,
      stageInstance,
      this.httpOptions,
    );
  }
  getStageInstanceWithoutSchedule(): Observable<
    StagesWithoutEvaluationSchedule[]
  > {
    return this.http.get<StagesWithoutEvaluationSchedule[]>(
      `${STAGE_INSTANCES_URL}/with-out-evaluation-schedule`,
    );
  }
  getStageInstancesByReviewers(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<StageInstanceByReviewers>> {
    return this.http.get<PaginatedList<StageInstanceByReviewers>>(
      `${STAGE_INSTANCES_URL}/assigned-reviews?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  reviewStageInstance(
    stageInstanceId: string,
    review:FormData,
  ): Observable<StageInstanceReview> {
    return this.http.post<StageInstanceReview>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/review`,
      review,
    );
  }

  submitStageInstanceReview(
    stageInstanceId: string,
    reviewId: string,
    review: FormData,
  ): Observable<StageInstanceReview> {
    return this.http.put<StageInstanceReview>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/review/${reviewId}`,
      review,
    );
  }

  getMyStageInstanceReview(
    stageInstanceId: string,
  ): Observable<MyStageInstanceReview> {
    return this.http.get<MyStageInstanceReview>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/my-review`,
    );
  }

  getStageInstanceReview(
    stageInstanceId: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<StageInstanceReview>> {
    return this.http.get<PaginatedList<StageInstanceReview>>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/reviews?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
  getStageInstanceReviews(
    stageInstanceId: string,
  ): Observable<Review[]> {
    return this.http.get<Review[]>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/reviews-with-reviewers`,
    );
  }
  getStageInstanceReviewerType(
    stageInstanceId: string,
  ): Observable<StageInstanceReviewerType> {
    return this.http.get<StageInstanceReviewerType>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/reviewer-type`,
    );
  }
  deleteReviewAttachment(
    reviewId: string,
    attachmentId: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${STAGE_INSTANCES_URL}/${reviewId}/attachments/${attachmentId}`
    )
  }
}
