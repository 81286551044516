import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ExternalDocumentState,
  ExternalDocumentStateModel,
} from './external-document.state';

export class ExternalDocumentSelector {
  static slices = createPropertySelectors<ExternalDocumentStateModel>(
    ExternalDocumentState,
  );
  @Selector([ExternalDocumentState])
  static externalDocuments(state: ExternalDocumentStateModel) {
    return state.externalDocuments;
  }

  @Selector([ExternalDocumentState])
  static externalDocumentResponses(state: ExternalDocumentStateModel) {
    return state.externalDocumentResponses;
  }

  @Selector([ExternalDocumentState])
  static selectedExternalDocumentResponse(state: ExternalDocumentStateModel) {
    return state.selectedExternalDocumentResponse;
  }

  @Selector([ExternalDocumentState])
  static externalDocumentDetail(state: ExternalDocumentStateModel) {
    return state.externalDocumentDetail;
  }

  @Selector([ExternalDocumentState])
  static externalDocumentResponseDetail(state: ExternalDocumentStateModel) {
    return state.externalDocumentResponseDetail;
  }

  @Selector([ExternalDocumentState])
  static selectedExternalDocument(state: ExternalDocumentStateModel) {
    return state.selectedExternalDocument;
  }

  @Selector([ExternalDocumentState])
  static totalCount(state: ExternalDocumentStateModel) {
    return state.externalDocuments.totalCount;
  }

  
  @Selector([ExternalDocumentState])
  static remarkActionsForDisplay(stateModel: ExternalDocumentStateModel) {
    return stateModel.remarkActionsForDisplay;
  }

  @Selector([ExternalDocumentState])
  static isFormSuccessFul(stateModel: ExternalDocumentStateModel) {
    return stateModel.isFormSuccessful;
  }
}
