import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NoticesRoutingModule } from './notices-routing.module';
import { NoticesListComponent } from './components/notices-list/notices-list.component';
import { NoticesFormComponent } from './components/notices-form/notices-form.component';
import { NoticeDetailComponent } from './components/notices-detail/notices-detail.component';

@NgModule({
  declarations: [
    NoticesListComponent,
    NoticesFormComponent,
    NoticeDetailComponent,
  ],
  imports: [SharedModule, NoticesRoutingModule, NgxsModule.forFeature([])],
})
export class NoticesModule {}
