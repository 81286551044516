import { Injectable } from '@angular/core';
import {
  Action,
  Select,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

import { FoldersService } from '../services/folders.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  DeleteFolder,
  CreateFolder,
  GetFlatFolderNodes,
  GetFoldersByWorkspaceIdTree,
  MoveFolder,
  GetFoldersByName,
  ShareFoldersToOffices,
  ShareFoldersToRoles,
  ShareFoldersToUsers,
  SelectFlatFolderNodes,
  SetFlatFolderNodesEmpty,
  RenameFolder,
  GetSharedToFolders,
  GetSharedFromFolders,
  SelectFlatFolderNode,
  SetSelectedFolderPathFromRoot,
  SetSharingModeFolder,
  SetSharingModeFile,
  ClearSelectedFlatFolderNode,
  GetFolderProperty,
  SelectArchivedFlatFolderNode,
  GetArchivedWorkspaceFolders,
  GetArchivedFolderProperty,
  MoveFolderFromArchivedWorkspace,
  GetWorkspaceFlatFolderNodes,
  SelectDestinationFlatFolderNode,
  SearchFolders,
  GetTrashedFolders,
  TrashFolder,
  RestoreFolder,
} from './folder.actions';
import { FolderTree } from '../models/folder-tree.model';
import { FlatFolderNode } from '../models/flat-folder-node.model';
import { Folder } from '../models/folder.model';
import { FolderProperty } from '../models/folder-property.model';
import { SetArchivedFiles } from './file.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

export interface FolderStateModel {
  foldersTree: FolderTree[];
  flatFolderNodes: FlatFolderNode[];
  selectedFlatFolderNode: FlatFolderNode | null;
  folders: Folder[];
  sharedToflatFolderNodes: FlatFolderNode[];
  sharedFromflatFolderNodes: FlatFolderNode[];
  selectedFolder: Folder | null;
  selectedFlatFolderNodes: FlatFolderNode[];
  selectedFolderPathFromRoot: string;
  isSharingModeFolder: boolean;
  folderProperty?: FolderProperty;
  archivedWorkspaceFolders?: FlatFolderNode[];
  selectedArchivedFlatFolderNode?: FlatFolderNode | null;
  archivedFolderProperty?: FolderProperty;
  workspaceFlatFolderNodes?: FlatFolderNode[];
  selectedDestinationFlatFolderNode?: FlatFolderNode;
  searchResults: FlatFolderNode[];
  trash: PaginatedList<Folder>;
}

const FOLDER_STATE_TOKEN = new StateToken<FolderStateModel>('foldersState');

const defaults = {
  foldersTree: [],
  flatFolderNodes: [],
  selectedFlatFolderNode: null,
  selectedFolder: null,
  folders: [],
  sharedToflatFolderNodes: [],
  sharedFromflatFolderNodes: [],
  selectedFlatFolderNodes: [],
  selectedFolderPathFromRoot: '',
  isSharingModeFolder: true,
  searchResults: [],
  trash: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
};

@State<FolderStateModel>({
  name: FOLDER_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class FolderState {
  constructor(
    private foldersService: FoldersService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetFoldersByWorkspaceIdTree)
  getFolders(
    { setState }: StateContext<FolderStateModel>,
    { workspaceId }: GetFoldersByWorkspaceIdTree,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getFoldersByWorkspaceIdTree(workspaceId).pipe(
      tap((folders) => {
        setState(
          patch({
            foldersTree: folders,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetFlatFolderNodes)
  getFlatFolderNodes({ patchState }: StateContext<FolderStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getFlatFolderNodes().pipe(
      tap((flatFolderNodes) => {
        patchState({
          flatFolderNodes,
        });

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SelectFlatFolderNode)
  selectFlatFolderNode(
    { patchState }: StateContext<FolderStateModel>,
    { flatFolderNode }: SelectFlatFolderNode,
  ) {
    patchState({
      selectedFlatFolderNode: flatFolderNode,
    });
  }

  @Action(MoveFolder)
  moveFolder(
    { setState }: StateContext<FolderStateModel>,
    { pathFromRoot, folderId }: MoveFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.moveFolder(pathFromRoot, folderId).pipe(
      tap((folder) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-moved-part-one:Folder` + `${folder.name}` + $localize`:@@files.folder.file-copied-part-two: moved successfully`,
          successStyle,
        );
        this.store.dispatch(new GetFlatFolderNodes());
        this.store.dispatch(new GetSharedFromFolders());
        this.store.dispatch(new GetSharedToFolders());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteFolder)
  deleteFolder(
    { setState }: StateContext<FolderStateModel>,
    { folderId, workspaceId }: DeleteFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.deleteFolder(folderId).pipe(
      tap(() => {
        setState(
          patch({
            trash: patch({
              items: removeItem((f) => f.id == folderId),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-deleted-successfully: Folder deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new GetFlatFolderNodes());
        this.store.dispatch(new GetSharedFromFolders());
        this.store.dispatch(new GetSharedToFolders());
      }),
    );
  }

  @Action(GetFoldersByName)
  getFoldersByName(
    { setState }: StateContext<FolderStateModel>,
    { name }: GetFoldersByName,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getFoldersByName(name).pipe(
      tap((folders) => {
        setState(
          patch({
            folders: folders,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateFolder)
  createFolder(
    { setState }: StateContext<FolderStateModel>,
    { folder }: CreateFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.createFolder(folder).pipe(
      tap((folder) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-named-part-one: Folder named` + ` "${folder.name}" ` + $localize`:@@files.folder.folder-named-part-two: has been created`,
          successStyle,
        );
        this.store.dispatch(new GetFlatFolderNodes());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ShareFoldersToOffices)
  shareFoldersToOffices(
    { setState }: StateContext<FolderStateModel>,
    { shareFolderToOfficeRequests }: ShareFoldersToOffices,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .shareFoldersToOffices(shareFolderToOfficeRequests)
      .pipe(
        tap((_) => {
          this.operationStatus.displayStatus(
            $localize`:@@files.folder.folder-has-been-shared-to-offices: Folder has been shared to the selcted offices`,
            successStyle,
          );
          this.store.dispatch(new GetSharedFromFolders());
          this.store.dispatch(new GetSharedToFolders());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ShareFoldersToRoles)
  shareFoldersToRoles(
    { setState }: StateContext<FolderStateModel>,
    { shareFolderToRolesRequests }: ShareFoldersToRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .shareFoldersToRoles(shareFolderToRolesRequests)
      .pipe(
        tap((_) => {
          this.operationStatus.displayStatus(
            $localize`:@@files.folder.folder-has-been-moved-to-roles: Folder has been shared to the selcted roles`,
            successStyle,
          );
          this.store.dispatch(new GetSharedFromFolders());
          this.store.dispatch(new GetSharedToFolders());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ShareFoldersToUsers)
  shareFoldersToUsers(
    { setState }: StateContext<FolderStateModel>,
    { shareFolderToUsersRequests }: ShareFoldersToUsers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .shareFoldersToUsers(shareFolderToUsersRequests)
      .pipe(
        tap((_) => {
          this.operationStatus.displayStatus(
            $localize`:@@files.folder.folder-has-been-shared-to-users: Folder has been shared to the selected users`,
            successStyle,
          );
          this.store.dispatch(new GetSharedFromFolders());
          this.store.dispatch(new GetSharedToFolders());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectFlatFolderNodes)
  selectFlatOfficeNodes(
    { patchState }: StateContext<FolderStateModel>,
    { selectedFlatFolderNodes }: SelectFlatFolderNodes,
  ) {
    patchState({ selectedFlatFolderNodes: selectedFlatFolderNodes });
  }

  @Action(SetFlatFolderNodesEmpty)
  setFlatFolderNodesEmpty(
    { patchState }: StateContext<FolderStateModel>,
    {}: SetFlatFolderNodesEmpty,
  ) {
    patchState({ selectedFlatFolderNodes: [] });
  }

  @Action(RenameFolder)
  renameFolder(
    { setState }: StateContext<FolderStateModel>,
    { folderId, folderName }: RenameFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.renameFolder(folderId, folderName).pipe(
      tap((renamedFolder: Folder) => {
        setState(
          patch({
            flatFolderNodes: updateItem(
              (item) => item.id === folderId,
              patch({
                name: renamedFolder.name,
              }),
            ),
            sharedFromflatFolderNodes: updateItem(
              (item) => item.id === folderId,
              patch({
                name: renamedFolder.name,
              }),
            ),
            sharedToflatFolderNodes: updateItem(
              (item) => item.id === folderId,
              patch({
                name: renamedFolder.name,
              }),
            ),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-is-successfully-renamed: The Folder is successfully renamed to` + `${renamedFolder.name}`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetSharedToFolders)
  getSharedToFolders(
    { patchState }: StateContext<FolderStateModel>,
    {}: GetSharedToFolders,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getSharedToFolders().pipe(
      tap((sharedToflatFolderNodes) => {
        patchState({
          sharedToflatFolderNodes,
        });

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetSharedFromFolders)
  getSharedFromFolders(
    { patchState }: StateContext<FolderStateModel>,
    {}: GetSharedFromFolders,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getSharedFromFolders().pipe(
      tap((sharedFromflatFolderNodes) => {
        patchState({
          sharedFromflatFolderNodes,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedFolderPathFromRoot)
  setSelectedFolderPathFromRoot(
    { patchState }: StateContext<FolderStateModel>,
    { selectedFolderPathFromRoot }: SetSelectedFolderPathFromRoot,
  ) {
    patchState({ selectedFolderPathFromRoot: selectedFolderPathFromRoot });
  }

  @Action(SetSharingModeFolder)
  setSharingModeFolder(
    { patchState }: StateContext<FolderStateModel>,
    {}: SetSharingModeFolder,
  ) {
    patchState({ isSharingModeFolder: true });
  }

  @Action(SetSharingModeFile)
  setSharingModeFile(
    { patchState }: StateContext<FolderStateModel>,
    {}: SetSharingModeFile,
  ) {
    patchState({ isSharingModeFolder: false });
  }

  @Action(ClearSelectedFlatFolderNode)
  clearSelectedFlatFolderNode(
    { patchState }: StateContext<FolderStateModel>,
    {}: ClearSelectedFlatFolderNode,
  ) {
    patchState({ selectedFlatFolderNode: null });
  }

  @Action(GetFolderProperty)
  getFolderProperty(
    { setState }: StateContext<FolderStateModel>,
    { folderId }: GetFolderProperty,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getFolderProperty(folderId).pipe(
      tap((folderProperty: FolderProperty) => {
        setState(
          patch({
            folderProperty: folderProperty,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetArchivedWorkspaceFolders)
  getArchivedWorkspaceFolders(
    { patchState }: StateContext<FolderStateModel>,
    { workspaceId }: GetArchivedWorkspaceFolders,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .getArchivedFoldersByWorkspaceId(workspaceId)
      .pipe(
        tap((folders) => {
          patchState({
            archivedWorkspaceFolders: folders,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectArchivedFlatFolderNode)
  selectArchivedFlatFolderNode(
    { patchState }: StateContext<FolderStateModel>,
    { flatFolderNode }: SelectArchivedFlatFolderNode,
  ) {
    patchState({
      selectedArchivedFlatFolderNode: flatFolderNode,
    });
  }

  @Action(GetArchivedFolderProperty)
  getArchivedFolderProperty(
    { setState }: StateContext<FolderStateModel>,
    { folderId }: GetArchivedFolderProperty,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getArchivedFolderProperty(folderId).pipe(
      tap((folderProperty: FolderProperty) => {
        setState(
          patch({
            archivedFolderProperty: folderProperty,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(MoveFolderFromArchivedWorkspace)
  moveFolderFromArchivedWorkspace(
    {}: StateContext<FolderStateModel>,
    {
      folderId,
      destinationWorkspaceId,
      currentWorkspaceId,
    }: MoveFolderFromArchivedWorkspace,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .moveFolderFromArchivedWorkspace(folderId, destinationWorkspaceId)
      .pipe(
        tap(() => {
          this.store.dispatch(
            new GetArchivedWorkspaceFolders(currentWorkspaceId),
          );
          this.store.dispatch(
            new SetArchivedFiles({
              items: [],
              pageNumber: 0,
              totalPages: 0,
              totalCount: 0,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@files.folder.folder-is-moved-successfully: The Folder is successfully moved`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkspaceFlatFolderNodes)
  getWorkspaceFlatFolderNodes(
    { setState }: StateContext<FolderStateModel>,
    { workspaceId }: GetWorkspaceFlatFolderNodes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService
      .getArchivedFoldersByWorkspaceId(workspaceId)
      .pipe(
        tap((folders) => {
          setState(
            patch({
              workspaceFlatFolderNodes: folders,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectDestinationFlatFolderNode)
  selectDestinationFlatFolderNode(
    { patchState }: StateContext<FolderStateModel>,
    { flatFolderNode }: SelectDestinationFlatFolderNode,
  ) {
    patchState({
      selectedDestinationFlatFolderNode: flatFolderNode,
    });
  }

  @Action(SearchFolders)
  searchFolders(
    { patchState, getState }: StateContext<FolderStateModel>,
    { searchTerm }: SearchFolders,
  ) {
    this.store.dispatch(new SetProgressOn());
    var state = getState();
    var folders = state.flatFolderNodes;
    const filteredFolders = folders.filter((folder) =>
      folder.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    patchState({
      searchResults: filteredFolders,
    });
    this.store.dispatch(new SetProgressOff());
  }

  @Action(GetTrashedFolders)
  getTrashFolders(
    { setState, patchState }: StateContext<FolderStateModel>,
    { pageIndex, pageSize }: GetTrashedFolders,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.getTrashedFolders(pageIndex, pageSize).pipe(
      tap((trash: PaginatedList<Folder>) => {
        patchState({ trash });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(TrashFolder)
  trashFiles(
    { setState, patchState }: StateContext<FolderStateModel>,
    { folderId }: TrashFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.trashFolder(folderId).pipe(
      tap(() => {
        setState(
          patch({
            flatFolderNodes: removeItem((f) => f.id == folderId),
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-trashed-successfully: Folder trashed successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new GetFlatFolderNodes());
        this.store.dispatch(new GetSharedFromFolders());
        this.store.dispatch(new GetSharedToFolders());
      }),
    );
  }

  @Action(RestoreFolder)
  restoreFile(
    { setState, patchState }: StateContext<FolderStateModel>,
    { folderId }: RestoreFolder,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.foldersService.restoreFile(folderId).pipe(
      tap(() => {
        setState(
          patch({
            trash: patch({
              items: removeItem((f) => f.id == folderId),
            }),
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@files.folder.folder-restored-successfully: Folder restored successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new GetFlatFolderNodes());
        this.store.dispatch(new GetSharedFromFolders());
        this.store.dispatch(new GetSharedToFolders());
      }),
    );
  }
}
