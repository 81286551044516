import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  StageInstanceDetailState,
  StageInstanceDetailStateModel,
} from './stage-instance-detail.state';

export class StageInstanceDetailSelector {
  static slices = createPropertySelectors<StageInstanceDetailStateModel>(
    StageInstanceDetailState,
  );

  @Selector([StageInstanceDetailState])
  static stageInstanceDetail(state: StageInstanceDetailStateModel) {
    return state.StageInstanceDetail;
  }

  @Selector([StageInstanceDetailState])
  static stageInstanceTasks(state: StageInstanceDetailStateModel) {
    return state.stageInstanceTasks;
  }

  @Selector([StageInstanceDetailState])
  static isEveryTaskApproved(state: StageInstanceDetailStateModel) {
    return state.isEveryTaskApproved;
  }
  @Selector([StageInstanceDetailState])
  static isStageEvaluauted(state: StageInstanceDetailStateModel) {
    return state.isStageEvaluated;
  }
  @Selector([StageInstanceDetailState])
  static userStageEvalutions(state: StageInstanceDetailStateModel) {
    return state.userStageEvalutions;
  }
  @Selector([StageInstanceDetailState])
  static isUpdatingEvaluation(state: StageInstanceDetailStateModel) {
    return state.isUpdatingEvaluation;
  }
  @Selector([StageInstanceDetailState])
  static isMajorTaskFound(state: StageInstanceDetailStateModel) {
    return state.isMajorTaskFound;
  }
}
