import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';

import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { ProcessDetail } from '../models/process-detail.model';
import { ProcessDetailService } from '../services/process.detail.service';
import {
  DeleteProcessDetail,
  AddProcessStage,
  GetProcessDetail,
  ResetSelectedProcessDetail,
  UpdateStageList,
} from './process-detail.actions';
import { ProcessService } from '../services/process.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { ResetSelectedProcess, SetSelectedProcess } from './process.actions';
import { ProcessStateModel } from './process.state';
import { DeleteStage, UpdateProcessStageOnDelete } from './stage.actions';

export interface ProcessDetailStateModel {
  selectedProcessDetail: ProcessDetail | undefined;
}

const PROCESS_DETAIL_STATE_TOKEN = new StateToken<ProcessDetailStateModel>(
  'processDetailState',
);

const defaults = {
  selectedProcessDetail: undefined,
};

@State<ProcessDetailStateModel>({
  name: PROCESS_DETAIL_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ProcessDetailState {
  constructor(
    private ProcessDetailService: ProcessDetailService,
    private processService: ProcessService,
    private operationStatus: OperationStatusService,

    private store: Store,
  ) {}

  @Action(SetSelectedProcess)
  setSelectedProcess(
    { patchState }: StateContext<ProcessStateModel>,
    { process }: SetSelectedProcess,
  ) {
    patchState({
      selectedProcess: process,
    });
  }
  @Action(GetProcessDetail)
  getProcessDetail(
    { setState }: StateContext<ProcessDetailStateModel>,
    { processDetailId }: GetProcessDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.ProcessDetailService.getProcessDetail(processDetailId).pipe(
      tap((process) => {
        setState(
          patch({
            selectedProcessDetail: process,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ResetSelectedProcessDetail)
  resetSelectedProcess({ patchState }: StateContext<ProcessDetailStateModel>) {
    patchState({
      selectedProcessDetail: undefined,
    });
  }
  @Action(DeleteProcessDetail)
  deleteProcess(
    { setState }: StateContext<ProcessDetailStateModel>,
    { processId }: DeleteProcessDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processService.deleteProcess(processId).pipe(
      tap(() => {
        setState(
          patch({
            selectedProcessDetail: undefined,
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@researches.process-detail.process-type-deleted-successfully: Process type deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new ResetSelectedProcess());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateProcessStageOnDelete)
  updateProcessStageOnDelete(
    { setState, getState }: StateContext<ProcessDetailStateModel>,
    { selectedStageId }: UpdateProcessStageOnDelete,
  ) {
    const state = getState();
    if (state.selectedProcessDetail) {
      const updatedStages = state.selectedProcessDetail.stages.filter(
        (stage) => stage.id !== selectedStageId,
      );
      const updatedProcess: ProcessDetail = {
        ...state.selectedProcessDetail,
        stages: updatedStages,
      };
      setState(
        patch({
          selectedProcessDetail: updatedProcess,
        }),
      );
    }
  }
  @Action(AddProcessStage)
  addProcessStage(
    { setState, getState }: StateContext<ProcessDetailStateModel>,
    { stage }: AddProcessStage,
  ) {
    const { selectedProcessDetail } = getState();
    if (selectedProcessDetail) {
      setState(
        patch({
          selectedProcessDetail: patch<ProcessDetail>({
            stages: append([stage]),
          }),
        }),
      );
    }
  }

  @Action(UpdateStageList)
  updateStageList(
    { setState, getState }: StateContext<ProcessDetailStateModel>,
    { selectedProcessStages }: UpdateStageList,
  ) {
    const { selectedProcessDetail } = getState();
    if (selectedProcessDetail) {
      selectedProcessDetail.stages = selectedProcessStages;
      setState(
        patch({
          selectedProcessDetail: selectedProcessDetail,
        }),
      );
    }
  }
}
