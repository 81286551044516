import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { CurrentLoggedInUser } from '../../models/user.model';
import { UserFacade } from '../../facade/user.facade';
import { EmailSettings } from '../../models/email-settings';
import { MatSelectionListChange } from '@angular/material/list';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserProfileFormComponent } from '../update-user-profile-form/update-user-profile-form.component';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface UserProfileComponentState {
  userProfile: CurrentLoggedInUser | undefined;
  emailSettings?: EmailSettings;
}

const initUserProfileComponentState: UserProfileComponentState = {
  userProfile: undefined,
};

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
  providers: [RxState],
})
export class UserProfileComponent implements OnInit {
  userProfile$ = this.state.select('userProfile');
  userProfile: CurrentLoggedInUser | undefined = undefined;

  emailSettings$ = this.state.select('emailSettings');
  faCircle = faCircle

  constructor(
    private userFacade: UserFacade,
    private state: RxState<UserProfileComponentState>,
    private matDialog: MatDialog,
  ) {
    this.state.set(initUserProfileComponentState);
    this.state.connect('userProfile', this.userFacade.currentLoggedInUser$);
    this.state.connect('emailSettings', this.userFacade.emailSettings$);
  }

  ngOnInit(): void {
    this.userFacade.dispatchGetEmailSettings();
    this.userFacade.dispatchGetCurrentLoggedInUser();
    this.userProfile$.subscribe((profile) => {
      this.userProfile = profile;
    });
  }

  handleSelectionChange(event: MatSelectionListChange) {
    const setting = event.options
      .map((op) => ({ value: op.value, select: op.selected }))
      .find((op) => op.value == 'emailNotificationEnabled');
    this.userFacade.dispatchUpdateEmailSettings({
      emailNotificationEnabled: !!setting?.select,
    });
  }

  openUpdateProfileDialog(){
    this.matDialog.open(UpdateUserProfileFormComponent)
  }

  hasUpdateUserProfilePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.UpdateUserProfile,
    );
  }
}
