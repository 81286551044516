import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Role } from '../models/role.model';
import {
  AssignRevokePermissions,
  CreateRole,
  DeleteRole,
  GetPaginatedRoles,
  GetPermissionsByRoleId,
  GetRoles,
  SearchRole,
  SetSelectedRole,
  SetUpdateStatus,
  UpdateRole,
} from '../store/role.actions';
import { RoleSelector } from '../store/role.selector';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class RoleFacade {
  roles$: Observable<Role[]> = this.store.select(RoleSelector.slices.roles);
  paginatedRoles$: Observable<PaginatedList<Role>> = this.store.select(
    RoleSelector.slices.paginatedRoles,
  );
  totalCount$: Observable<number> = this.store.select(
    RoleSelector.slices.totalCount,
  );
  selectedRole$: Observable<Role | undefined> = this.store.select(
    RoleSelector.slices.selectedRole,
  );
  update$: Observable<boolean> = this.store.select(RoleSelector.slices.update);

  constructor(private store: Store) {}

  dispatchGetRoles() {
    this.store.dispatch(new GetRoles());
  }

  dispatchGetPaginatedRoles(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetPaginatedRoles(pageNumber, pageSize));
  }

  dispatchSetUpdateStatus(updateStatus: boolean) {
    this.store.dispatch(new SetUpdateStatus(updateStatus));
  }

  dispatchCreateRole(name: string) {
    this.store.dispatch(new CreateRole(name));
  }

  dispatchUpdateRole(role: Role) {
    this.store.dispatch(new UpdateRole(role));
  }

  dispatchSetSelectedRole(role: Role) {
    this.store.dispatch(new SetSelectedRole(role));
  }

  dispatchGetPermissionsByRoleId(roleId: string) {
    this.store.dispatch(new GetPermissionsByRoleId(roleId));
  }

  dispatchAssignRevokePermissions(roleId: string, permissionsId: string[]) {
    this.store.dispatch(new AssignRevokePermissions(roleId, permissionsId));
  }

  dispatchDeleteRole(roleId: string) {
    this.store.dispatch(new DeleteRole(roleId));
  }

  dispatchSearchRole(
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(new SearchRole(searchTerm, pageNumber, pageSize));
  }
}
