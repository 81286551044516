<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>language</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="changeLocale(englishLanguage.locale)">
    {{ englishLanguage.name }}
  </button>
  <button mat-menu-item (click)="changeLocale(amharicLanguage.locale)">
    {{ amharicLanguage.name }}
  </button>
  <button mat-menu-item (click)="changeLocale(afanOromoLanguage.locale)">
    {{ afanOromoLanguage.name }}
  </button>
  <button mat-menu-item (click)="changeLocale(tigriLanguage.locale)">
    {{ tigriLanguage.name }}
  </button>
  <button mat-menu-item (click)="changeLocale(soomaaliLanguage.locale)">
    {{ soomaaliLanguage.name }}
  </button>
</mat-menu>
