import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WorkflowTypeAverageTimeSelector } from '../store/workflow-step-average-time.selector';
import { Observable } from 'rxjs';
import { AverageTimeSpentWorkflowTypeStep } from '../models/workflow-step-average-time.model';
import { GetWorkflowTypeAverageTimeReport } from '../store/workflow-step-average-time.action';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTypeAverageTimeFacade {
  constructor(private store: Store) {}

  @Select(WorkflowTypeAverageTimeSelector.workflowTypeStepAverageTimeReport)
  workflowTypeSteps$!: Observable<
    PaginatedList<AverageTimeSpentWorkflowTypeStep>
  >;

  dispatchWorkflowTypeAverageTimeReport(
    pageIdx: number,
    pageSize: number,
    searchTerm?: string,
    officeId?: string
  ) {
    this.store.dispatch(
      new GetWorkflowTypeAverageTimeReport(pageIdx, pageSize, searchTerm, officeId),
    );
  }
}
