import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { MatTableDataSource } from '@angular/material/table';
import { Communications } from '../../models/communications';
import { Subject } from 'rxjs';

interface ResearchCommunicationState {
  communications: PaginatedList<Communications> | undefined;
}

const initResearchCommunicationState: ResearchCommunicationState = {
  communications: undefined,
};

@Component({
  selector: 'app-research-communcations',
  templateUrl: './research-communcations.component.html',
  styleUrls: ['./research-communcations.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ResearchCommuncationsComponent}],
})
export class ResearchCommuncationsComponent{
  communications$ = this.state.select('communications');
  communications: PaginatedList<Communications> | undefined;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'communicationTypeName',
    'name',
    'officeName',
    'startDate',
    'endDate',
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.research-communications.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.research-communications.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.research-communications.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.research-communications.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.research-communications.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.research-communications.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.research-communications.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.research-communications.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<ResearchCommunicationState>,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initResearchCommunicationState),
      this.state.connect(
        'communications',
        this.researchDashboardFacade.communications$,
      );
  }
  changes = new RxState();
  ngOnInit() {
    this.researchDashboardFacade.dispatchgetResearchCommunications();
    this.communications$.subscribe((communications) => {
      this.dataSource.data = communications?.items || [];
      this.dataSource.sort = this.sort;
      this.length = communications?.totalCount as number;
    });
  }

  loadPaginatedCommunications(event: PageEvent) {
    this.researchDashboardFacade.dispatchgetResearchCommunications(
      undefined,
      undefined,
      undefined,
      undefined,
      event.pageIndex + 1,
      event.pageSize,
    );
  }
}
