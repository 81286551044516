import { NgModule } from '@angular/core';

import { WorkflowCommentsRoutingModule } from './workflow-comments-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportedWorkflowCommentComponent } from './components/reported-workflow-comment/reported-workflow-comment/reported-workflow-comment.component';
import { ReportedWorkflowCommentCardComponent } from './components/reported-workflow-comment/reported-workflow-comment-card/reported-workflow-comment-card.component';
import { ShowWorkflowCommentCardComponent } from './components/reported-workflow-comment/show-workflow-comment-card/show-workflow-comment-card.component';
import { ShowWorkflowCommentReplyCardComponent } from './components/reported-workflow-comment/show-workflow-comment-reply-card/show-workflow-comment-reply-card.component';
import { WorkflowCommentPolicyComponent } from './components/workflow-comment-policy/workflow-comment-policy.component';

@NgModule({
  declarations: [
    ReportedWorkflowCommentComponent,
    ReportedWorkflowCommentCardComponent,
    ShowWorkflowCommentCardComponent,
    ShowWorkflowCommentReplyCardComponent,
    WorkflowCommentPolicyComponent,
  ],
  imports: [SharedModule, WorkflowCommentsRoutingModule],
})
export class WorkflowCommentsModule {}
