import { Workspace } from '../models/workspace';
import { WorkspaceForm } from '../models/workspace-form';

export class GetMyWorkspace {
  static readonly type = `[Workspaces] ${GetMyWorkspace.name}`;
}

export class GetWorkspaces {
  static readonly type = `[Workspaces] ${GetWorkspaces.name}`;
  constructor(
    public pageSize: number,
    public pageNumber: number,
  ) {}
}

export class GetStorageInfo {
  static readonly type = `[Workspaces] ${GetStorageInfo.name}`;
  constructor(public workspaceId?: string) {}
}

export class UpdateWorkspace {
  static readonly type = `[Workspaces] ${UpdateWorkspace.name}`;
  constructor(public workspaceForm: WorkspaceForm) {}
}

export class SelectEditableWorkspace {
  static readonly type = `[Workspaces] ${SelectEditableWorkspace.name}`;
  constructor(public workspace: Workspace) {}
}

export class SyncWorkspaceInfo {
  static readonly type = `[Workspaces] ${SyncWorkspaceInfo.name}`;
  constructor(public workspace: Workspace) {}
}

export class StopSyncingWorkspaceInfo {
  static readonly type = `[Workspaces] ${StopSyncingWorkspaceInfo.name}`;
}

export class GetArchivedWorkspaces {
  static readonly type = `[Workspaces] ${GetArchivedWorkspaces.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SelectArchivedWorkspace {
  static readonly type = `[Workspaces] ${SelectArchivedWorkspace.name}`;
  constructor(public workspace: Workspace | undefined) {}
}

export class SearchWorkspaces {
  static readonly type = `[Workspaces] ${SearchWorkspaces.name}`;
  constructor(
    public readonly name?: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class DeleteArchivedWorkspace {
  static readonly type = `[Workspaces] ${DeleteArchivedWorkspace.name}`;
  constructor(
    public workspaceId: string
  ) {}
}