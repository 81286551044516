import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import {
  FieldType,
  Formatters,
  OnEventArgs,
  SlickEventData,
} from 'angular-slickgrid';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkspaceFacade } from '../../facade/workspace.facade';
import { StorageInfo } from '../../models/storage-info';
import { SizeUnits, Workspace } from '../../models/workspace';
import { WorkspaceFormComponent } from '../workspace-form/workspace-form.component';
import { SIZE_UNITS } from '../workspace-quota/workspace-quota.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

interface WorkspaceListComponentState {
  workspaces: PaginatedList<Workspace>;
  storageInfo?: StorageInfo;
}

const initialWorkspaceListState: WorkspaceListComponentState = {
  workspaces: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
};

@Component({
  selector: 'app-workspace-list',
  templateUrl: './workspace-list.component.html',
  styleUrls: ['./workspace-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: WorkspaceListComponent },
    RxState,
  ],
})
export class WorkspaceListComponent implements OnInit, MatPaginatorIntl {
  workspaces$ = this.state.select('workspaces');

  firstPageLabel = $localize`:@@files.workspace-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@files.workspace-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@files.workspace-list.last-page: Last page`;

  nextPageLabel = $localize`:@@files.workspace-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@files.workspace-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@files.workspace-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return (
      $localize`:@@files.workspace-list.page-part-one: Page` +
      `${page + 1}` +
      $localize`:@@files.workspace-list.page-part-two: of` +
      `${amountPages}`
    );
  }
  changes = new Subject<void>();
  workspaces: Workspace[] = [];
  totalCount = 0;
  totalPages = 0;
  pageSizes = [5, 10, 25, 100];

  gridOptions = {
    gridWidth: 'auto',
    autoHeight: true,
    enableAutoResize: false,
    enableSorting: false,
    enableFiltering: false,
    autoFitColumnsOnFirstLoad: false,
    enableContextMenu: false,
    enableCellMenu: false,
    enableGridMenu: false,
    enableHeaderMenu: false,
  };

  column = [
    {
      id: 'edit',
      name: $localize`:@@files.workspace-list.edit:edit`,
      field: 'edit',
      formatter: Formatters.editIcon,
      excludeFromHeaderMenu: true,
      onCellClick: (e: SlickEventData, args: OnEventArgs) => {
        this.workspaceFacade.dispatchEditableWorkspace(args.dataContext);
        this.dialog.open(WorkspaceFormComponent, {
          width: '100%',
          maxWidth: '720px',
        });
      },
    },
    {
      id: 'name',
      name: $localize`:@@files.workspace-list.name:Name`,
      field: 'name',
    },
    {
      id: 'content',
      name: $localize`:@@files.workspace-list.used:Used`,
      field: 'content',
    },
    {
      id: 'quota',
      name: $localize`:@@files.workspace-list.quota:Quota`,
      field: 'quota',
    },
    {
      id: 'usage',
      name: $localize`:@@files.workspace-list.usage:Usage`,
      field: 'usage',
      formatter: Formatters.progressBar,
      type: FieldType.number,
      sortable: true,
      minWidth: 100,
    },
  ];

  constructor(
    public state: RxState<Partial<WorkspaceListComponentState>>,
    private workspaceFacade: WorkspaceFacade,
    private dialog: MatDialog,
  ) {
    this.state.set(initialWorkspaceListState);
    this.state.connect('workspaces', this.workspaceFacade.workspaces$);
    this.state.connect('storageInfo', this.workspaceFacade.storageInfo$);
  }

  ngOnInit(): void {
    this.workspaceFacade.dispatchGetWorkspaces(5, 1);
    this.workspaceFacade.dispatchGetStorageInfo();

    this.workspaces$.subscribe((ws) => {
      if (ws?.items) {
        this.workspaces = ws.items.map((item) => {
          return {
            ...item,
            content: this.formatContent(item),
            quota: this.formatQuota(item),
            usage: this.calculateUsage(item),
          };
        });
        this.totalCount = ws.totalCount;
        this.totalPages = ws.totalPages;
      }
    });
  }

  private calculateUsage(ws: Workspace) {
    const quota =
      ws.quotaLimit *
      Math.pow(
        1024,
        SizeUnits[ws.contentSizeUnit] - SizeUnits[ws.quotaLimitUnit],
      );

    if (quota == 0) return 0;
    return Number((ws.contentSize / quota * 100).toFixed(2));
  }

  loadWorkspaces(event: any) {
    this.workspaceFacade.dispatchGetWorkspaces(
      event.pageSize,
      event.pageIndex + 1,
    );
  }

  private formatContent(ws: Workspace): string {
    const unit = SIZE_UNITS[SizeUnits[ws.quotaLimitUnit]];
    const used =
      ws.contentSize *
      Math.pow(
        1024,
        SizeUnits[ws.quotaLimitUnit] - SizeUnits[ws.contentSizeUnit],
      );
    return `${used} ${unit}`;
  }

  private formatQuota(ws: Workspace): string {
    const unit = SIZE_UNITS[SizeUnits[ws.quotaLimitUnit]];
    return `${ws.quotaLimit} ${unit}`;
  }

  hasGetWorkspacesPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.GetWorkspaces,
    );
  }
}
