import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { WorkflowStepService } from '../services/workflow-step.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { Injectable } from '@angular/core';
import {
  AttachUserToStep,
  AssignUserToWorkflowStep,
  DownloadWorkflowStep,
  GetNotifiedUsers,
  GetWorkflowStep,
  GetWorkflowSteps,
  RemoveNotifiedUser,
  WorkflowStepUpdate,
  GetArchivedWorkflowSteps,
  GetArchivedWorkflowStep,
  GetCcdWorkflowStepDetail,
  GetSelectedWorkflowStep,
  WorkflowStepUpdateStatus,
  UploadWorkflowStepFile,
  GetWorkflowStepFiles,
  DeleteWorkflowStepFile,
  PreviewWorkflowStepFile,
} from './workflow-step.actions';
import { WorkflowStep,WorkflowStepFile,WorkflowStepFileUploadReport,WorkflowStepSimple } from '../models/workflow-step.model';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  patch,
  removeItem
} from '@ngxs/store/operators';
import { NotifiedUser } from '../models/notified-user.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { filter, of, take, tap } from 'rxjs';
import { errorStyle, successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { WorkflowStateModel } from '../../workflows/store/workflow.state';
import { GetWorkflows } from '../../workflows/store/workflow.actions';
import { HttpEvent, HttpEventType } from '@angular/common/http';

export interface WorkflowStepStateModel {
  workflowStep: WorkflowStep | undefined;
  workflowStepDetail?: WorkflowStep;
  notifiedUsers: PaginatedList<NotifiedUser> | undefined;
  workflowSteps: WorkflowStep[];
  slectedWorkflowWorkflowSteps: WorkflowStepSimple[];
  ccdworkflowStep: WorkflowStep | undefined;
  workflowStepFiles?: PaginatedList<WorkflowStepFile>;
}

const WORKFLOWSTEP_STATE_TOKEN = new StateToken<WorkflowStepStateModel>(
  'workflowStepState',
);

const defaults: WorkflowStepStateModel = {
  workflowStep: undefined,
  notifiedUsers: undefined,
  workflowSteps: [],
  slectedWorkflowWorkflowSteps: [],
  ccdworkflowStep: undefined,
  workflowStepFiles:undefined
};

@State<WorkflowStepStateModel>({
  name: WORKFLOWSTEP_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowStepState {
  constructor(
    public workflowStepService: WorkflowStepService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetWorkflowStep)
  workflowStepAction(
    { patchState }: StateContext<WorkflowStepStateModel>,
    { workflowId }: GetWorkflowStep,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.workflowStepService.GetWorkflowStep(workflowId).pipe(
      tap((result) => {
        patchState({ workflowStep: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetWorkflowSteps)
  getworkflowSteps(
    { patchState }: StateContext<WorkflowStepStateModel>,
    { workflowId }: GetWorkflowSteps,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.workflowStepService.GetWorkflowSteps(workflowId).pipe(
      tap((result) => {
        patchState({ slectedWorkflowWorkflowSteps: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetNotifiedUsers)
  getAttachedUsers(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId, pageNumber, pageSize }: GetNotifiedUsers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .GetNotifiedUsers(workflowStepId, pageNumber, pageSize)
      .pipe(
        tap((users) => {
          setState(
            patch({
              notifiedUsers: users,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(DownloadWorkflowStep)
  downloadWorkflowStep(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId, workflowStepName }: DownloadWorkflowStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .downloadWorkflowStep(workflowStepId)
      .pipe(
        filter((wf) => wf !== null),
        take(1),
      )
      .subscribe(async (response: any) => {
        if (response) {
          const blob = new Blob([response], {
            type: 'application/json',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'workflow_' + workflowStepName + '.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          this.store.dispatch(new SetProgressOff());
        }
      });
  }
  @Action(RemoveNotifiedUser)
  removeCCdUser(
    { setState }: StateContext<WorkflowStepStateModel>,
    { worflowStepId, userId }: RemoveNotifiedUser,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .removeNotifiedUser(worflowStepId, userId)
      .pipe(
        tap(() => {
          setState(
            patch({
              notifiedUsers: patch({
                items: removeItem((user) => {
                  return user.id === userId;
                }),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-step.removed-user:user is removed successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(WorkflowStepUpdate)
  updateWorkflowStep(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStep }: WorkflowStepUpdate,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService.updateWorkflowStep(workflowStep).pipe(
      tap((workflowStep) => {
        setState(
          patch({
            workflowStep: workflowStep,
          }),
        );
        this.operationStatus.displayStatus(
          `Workflow step updated successfully`,
          successStyle,
        );
        this.store.dispatch(new GetWorkflows(1, 10));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(WorkflowStepUpdateStatus)
  WorkflowStepUpdateStatus(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStepStatus }: WorkflowStepUpdateStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .updateWorkflowStepStatus(workflowStepStatus)
      .pipe(
        tap((workflowStep) => {
          setState(
            patch({
              workflowStep: workflowStep,
            }),
          );
          this.operationStatus.displayStatus(
            `Workflow step updated successfully`,
            successStyle,
          );
          this.store.dispatch(new GetWorkflows(1, 10));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AttachUserToStep)
  attachUserToStep(
    { setState, getState }: StateContext<WorkflowStepStateModel>,
    { id, notifiedUsers }: AttachUserToStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService.attachUserToStep(id, notifiedUsers).pipe(
      tap((step) => {
        const state = getState();
        const existingUsers = state.notifiedUsers!.items;
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-step.notified-user:users is notified successfully`,
          successStyle,
        );
        this.store.dispatch(new GetNotifiedUsers(step.id));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(AssignUserToWorkflowStep)
  assignUserToWorkflowStep(
    { setState, getState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId, userId, workflowId }: AssignUserToWorkflowStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .assignUserToWorkflowStep(workflowStepId, userId)
      .pipe(
        tap((step) => {
          const currentStep = getState().workflowStep;
          if (currentStep) {
            setState(
              patch({
                workflowStep: patch({
                  assignee: step.assignee
                }),
              }),
            );
          }
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-step.assigned-user:users assigned to workflow step successfully`,
            successStyle,
          );
          this.store.dispatch(new GetWorkflowSteps(workflowId));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetArchivedWorkflowSteps)
  getArchivedWorkflowSteps(
    { patchState }: StateContext<WorkflowStepStateModel>,
    { workflowId }: GetArchivedWorkflowSteps,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.workflowStepService.getArchivedWorkflowSteps(workflowId).pipe(
      tap((result) => {
        patchState({ slectedWorkflowWorkflowSteps: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetArchivedWorkflowStep)
  getArchivedWorkflowStep(
    { patchState }: StateContext<WorkflowStepStateModel>,
    { workflowId }: GetArchivedWorkflowStep,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.workflowStepService.getArchivedWorkflowStep(workflowId).pipe(
      tap((result) => {
        patchState({ workflowStep: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetCcdWorkflowStepDetail)
  getCcdWorkflowStepDetail(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId }: GetCcdWorkflowStepDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .getCcdWorkflowStepDetail(workflowStepId)
      .pipe(
        tap((step) => {
          setState(
            patch({
              ccdworkflowStep: step,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetSelectedWorkflowStep)
  getSelectedWorkflowStep(
    { setState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId }: GetSelectedWorkflowStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
      .getWorkflowStepDetail(workflowStepId)
      .pipe(
        tap((step) => {
          setState(
            patch({
              workflowStep: step,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UploadWorkflowStepFile)
  uploadWorkflowStepFile(
    {}: StateContext<WorkflowStepStateModel>,
    { file, workflowStepId }: UploadWorkflowStepFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
    .uploadWorkflowStepFile(file, workflowStepId)
    .pipe(
      tap((event: HttpEvent<WorkflowStepFileUploadReport[]>) => {
        if (event.type === HttpEventType.Response) {
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-step.workflow-step-file-uploaded-successfully: Workflow step file uploaded successfully`,
            successStyle,
          );
        }
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetWorkflowStepFiles)
  getWorkflowStepFiles(
    { patchState }: StateContext<WorkflowStepStateModel>,
    { workflowStepId }: GetWorkflowStepFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService
    .getWorkflowStepFiles(workflowStepId)
    .pipe(
      tap((files) => {
        patchState({ workflowStepFiles: files });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteWorkflowStepFile)
  deleteWorkflowStepFile(
    { setState }: StateContext<WorkflowStepStateModel>,
    { fileId }: DeleteWorkflowStepFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowStepService.DeleteWorkflowStepFile(fileId).pipe(
      tap(() => {
        setState(
          patch({
            workflowStepFiles: patch({
              items: removeItem<WorkflowStepFile>((item) => item.id === fileId),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-step.workflow-step-file-deleted-successfully: File deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(PreviewWorkflowStepFile)
  previewPublicationDocument(
    { setState }: StateContext<WorkflowStepStateModel>,
    { fileId, fileName }: PreviewWorkflowStepFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    var fileType = 'pdf';
    var contentType = 'application/pdf';
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (fileExtension === 'jpg' ||fileExtension === 'jpeg' ||fileExtension === 'png' ||fileExtension === 'gif') {
      fileType = 'image';
    }
    else if(fileExtension !== 'pdf'){
      this.operationStatus.displayStatus(
        $localize`:@@documents.workflow-step.file-type-not-supported: File type not supported`,
        errorStyle,
      );
      this.store.dispatch(new SetProgressOff());
      return of();
    }
    if (fileType === 'image') {
      contentType = `image/${fileExtension}`;
    }


    return this.workflowStepService.getWorkflowStepFile(fileId).pipe(
      tap((response) => {
        const blob = new Blob([response], { type: contentType });
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

}
