import { Component, OnInit, ViewChild } from '@angular/core';
import { OfficeFacade } from '../../facades/office.facades';
import { Office } from '../../models/office.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { RxState } from '@rx-angular/state';
import {
  AngularGridInstance,
  Column,
  Formatter,
  FormatterResultObject,
  Formatters,
  GridOption,
  OnEventArgs,
} from 'angular-slickgrid';
import { OfficeFormComponent } from '../office-form/office-form.component';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { Subject, map } from 'rxjs';
import { UserFacade } from 'src/app/users/facade/user.facade';

interface OfficeListComponentState {
  offices?: Office[];
  totalCount?: number;
}

const initOfficeListComponentState = {};

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: OfficeListComponent}, RxState],
})
export class OfficeListComponent implements OnInit, MatPaginatorIntl {
  columnDefinitions: Column[] = [];
  gridOptions!: GridOption;
  angularGrid!: AngularGridInstance;
  offices: Office[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@offices.office-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@offices.office-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@offices.office-list.last-page: Last page`;

  nextPageLabel = $localize`:@@offices.office-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@offices.office-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@offices.office-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@offices.office-list.page-part-one: Page` + `${page + 1}` + $localize`:@@offices.office-list.page-part-two: of` + `${amountPages}`;
  }

  phoneNumberFormatter: Formatter = (row, cell, value, columnDef, dataContext): FormatterResultObject => (    {
    text: `${dataContext.phoneNumber.countryCode} ${dataContext.phoneNumber.number}`
  })

  constructor(
    private officeFacade: OfficeFacade,
    private dialog: MatDialog,
    private state: RxState<OfficeListComponentState>,
    private operationStatusService: OperationStatusService,
    private userFacade: UserFacade
  ) {
    this.state.set(initOfficeListComponentState);
    this.state.connect('offices', officeFacade.offices$);
    this.state.connect('totalCount', this.officeFacade.totalCount$);
    this.prepareGrid();
  }
  changes = new Subject<void>();
  ngOnInit() {
    this.officeFacade.dispatchGetOffices(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.state.select('offices').subscribe((offices) => {
      if (offices) {
        this.offices = offices;
      }
    });
    this.state.select('totalCount').subscribe((totalCount) => {
      if (totalCount) this.length = totalCount;
    });
  }

  // preparing angular slick grid
  prepareGrid() {
    this.columnDefinitions = [
      {
        id: 'edit',
        field: 'id',
        excludeFromHeaderMenu: true,
        formatter: (row, cell, value, columnDef, dataContext, grid) =>
          this.isRootOffice(dataContext) || !this.isAuthorized(this.hasUpdateOfficePermission())
            ? ''
            : (Formatters.editIcon(
                row,
                cell,
                value,
                columnDef,
                dataContext,
                grid,
              ) as FormatterResultObject),
        minWidth: 30,
        maxWidth: 30,
        onCellClick: (e: Event, args: OnEventArgs) => {
          if (!this.isRootOffice(args.dataContext) && this.isAuthorized(this.hasUpdateOfficePermission()))
            return this.updateOffice(args.dataContext);
          return null;
        },
      },
      {
        id: 'delete',
        field: 'id',
        excludeFromColumnPicker: true,
        excludeFromGridMenu: true,
        excludeFromHeaderMenu: true,
        formatter: (row, cell, value, columnDef, dataContext, grid) =>
          this.isRootOffice(dataContext) || !this.isAuthorized(this.hasDeleteOfficePermission())
            ? ''
            : (Formatters.deleteIcon(
                row,
                cell,
                value,
                columnDef,
                dataContext,
                grid,
              ) as FormatterResultObject),
        minWidth: 30,
        maxWidth: 30,
        onCellClick: (e: Event, args: OnEventArgs) => {
          if (!this.isRootOffice(args.dataContext) && this.isAuthorized(this.hasDeleteOfficePermission()))
            return this.openConfirmationDialog(args.dataContext);
          return null;
        },
      },
      {
        id: 'name',
        name: $localize`:@@offices.office-list.name: Name`,
        field: 'name',
        sortable: true,
      },
      {
        id: 'shortName',
        name: $localize`:@@offices.office-list.short-name: Short Name`,
        field: 'shortName',
        sortable: true,
      },
      {
        id: 'parentOfficeName',
        name: $localize`:@@offices.office-list.parent-office: Parent Office`,
        field: 'parentOfficeName',
        sortable: true,
      },
      {
        id: 'phoneNumber',
        name: $localize`:@@offices.office-list.phone-number: Phone Number`,
        field: 'phoneNumber',
        sortable: false,
        formatter:this.phoneNumberFormatter,
        maxWidth: 200,
      },
      {
        id: 'description',
        name: $localize`:@@offices.office-list.description: Description`,
        field: 'description',
        sortable: true,
        minWidth:150
      },
    ];

    this.gridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableGrouping: true,
      gridHeight: 450,
      gridWidth: '100%',
      enableCellNavigation: true,
      enableRowSelection: true,
      editable: false,
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      enableGridMenu: false,
      enableHeaderMenu: false,
      enableContextMenu: false,
      enableCellMenu: false,

      // disable the default resizing option
      autoFitColumnsOnFirstLoad: false,
      enableAutoSizeColumns: false,

      // enable resize by content
      autosizeColumnsByCellContentOnFirstLoad: true,
      enableAutoResizeColumnsByCellContent: true,

      resizeByContentOptions: {
        cellCharWidthInPx: 11,
      },

      enableFiltering: true,
    };
  }

  angularGridReady(event: Event) {
    const angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.angularGrid = angularGrid;
  }

  openConfirmationDialog(office: Office) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@offices.office-list.delete-office-part-1:Are you sure you want to delete` ,
        boldText: ` "${office.name}" ` ,
        regularTextTwo: $localize`:@@offices.office-list.delete-office-part-2:along with its descendants?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.openDeliberateConfirmationDialogue(office);
      }
    });
  }

  openDeliberateConfirmationDialogue(office: Office) {
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: office.name,
          message: $localize`:@@offices.office-list.delete-office:Delete` + ` ${office.name} `,
          regularTextOne: $localize`:@@offices.office-list.delete-office-description-part-1:This action is irreversible. Are you sure you want to delete` ,
          boldText:  ` "${office.name}" ` ,
          regularTextTwo:  $localize`:@@offices.office-list.delete-office--description-part-2:Office with its descendants?`,
          extraTextOne: $localize`:@@offices.office-list.delete-office-extra-part-1:To confirm, type` ,
          extraTextBold: ` "${office.name}" ` ,
          extraTextTwo: $localize`:@@offices.office-list.delete-office-extra-part-2:below:`,
        },
      },
    );

    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (office.id) this.officeFacade.dispatchDeleteOffice(office.id);
      }
    });
  }

  isRootOffice(office: Office): boolean {
    return !office.pathFromRoot.includes('.');
  }

  updateOffice(office: Office) {
    this.officeFacade.dispatchGetReportsToNodes(office.id!);
    this.dialog.open(OfficeFormComponent, {
      data: { update: true, toBeUpdatedOffice: office },
      disableClose: false,
    });
  }

  loadPaginatedOffices(event: PageEvent) {
    this.officeFacade.dispatchSetPageIndexAndSize(
      event.pageIndex + 1,
      event.pageSize,
    );
    this.officeFacade.dispatchGetOffices(event.pageIndex + 1, event.pageSize);
  }

  createOffice() {
    this.officeFacade.dispatchResetSelectedOffice();
    this.officeFacade.dispatchResetSelectedOffices();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.dialog.open(OfficeFormComponent, {
      data: { update: false },
      disableClose: true,
    });
  }

  isAuthorized(permission: string): boolean {
    let isAuthorized: boolean = false;

    this.userFacade.hasPermission(permission)
      .pipe(
        map(permission => !!permission)
      )
      .subscribe(hasPermission => {
        isAuthorized = hasPermission;
      });

      if(IS_DEVELOPMENT){
        return true;
      }

    return isAuthorized;
  }

  hasAddOfficePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.CreateOffice,
    );
  }
  hasUpdateOfficePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UpdateOffice,
    );
  }
  hasDeleteOfficePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.DeleteOffice,
    );
  }
  hasGetOfficesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetOffices,
    );
  }
  hasUploadExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UploadExcelFile,
    );
  }
}
