import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CoreModule } from './core/core.module';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthModule } from './auth/auth.module';
import { UsersModule } from './users/users.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './auth/services/jwt.interceptor';
import { SharedModule } from './shared/shared.module';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ProgressStatusState } from './core/store/progress-status.state';
import { ProgressStatusComponent } from './shared/shared-components/progress-status/progress-status.component';
import { OfficesModule } from './offices/offices.module';
import { ResearchesModule } from './researches/researches.module';
import { NotificationState } from './core/store/notification.state';
import { DocumentsModule } from './documents/documents.module';
import { HomeComponent } from './home/home.component';
import { AcceptLanguageHeaderInterceptor } from './core/localization/accept-language-header.interceptor';
import { Localization } from './core/localization/localization.class';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (l: Localization) => () => l.setLocale(),
      deps: [Localization],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (l: Localization) => l.locale,
      deps: [Localization],
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([ProgressStatusState, NotificationState]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/v1/logs',
      level: NgxLoggerLevel.DEBUG,
      // serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    AngularSlickgridModule.forRoot({
      // disable the default resizing option
      autoFitColumnsOnFirstLoad: false,
      enableAutoSizeColumns: false,
      // enable resize by content
      autosizeColumnsByCellContentOnFirstLoad: true,
      enableAutoResizeColumnsByCellContent: true,
      resizeByContentOptions: {
        cellCharWidthInPx: 11,
      },
      enableFiltering: true,
    }),
    SharedModule,
    OfficesModule,
    ResearchesModule,
    CoreModule,
    AppRoutingModule,
    AuthModule,
    UsersModule,
    ProgressStatusComponent,
    DocumentsModule,
  ],
})
export class AppModule {}
