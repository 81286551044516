import { S } from '@angular/cdk/keycodes';
import { TorState, TorStateModel } from './tor.state';
import { Selector, createPropertySelectors } from '@ngxs/store';

export class TorSelector {
  static slices = createPropertySelectors<TorStateModel>(TorState);

  @Selector([TorState])
  static tors(stateModel: TorStateModel) {
    return stateModel.tors;
  }

  @Selector([TorState])
  static totalCount(stateModel: TorStateModel) {
    return stateModel.totalCount;
  }

  @Selector([TorState])
  static getPaginatedTor(stateModel: TorStateModel) {
    return stateModel.paginatedTors;
  }

  @Selector([TorState])
  static initiatedTor(stateModel: TorStateModel) {
    return stateModel.initiatedTor;
  }

  @Selector([TorState])
  static selectedTorDetail(state: TorStateModel) {
    return state.selectedTor;
  }

  @Selector([TorState])
  static selectedTorDescription(state: TorState) {
    return state.setSelectedTorDescription;
  }
  @Selector([TorState])
  static costBreakdownItems(state: TorStateModel) {
    return state.costBreakdownItems;
  }

  @Selector([TorState])
  static selectedCostBreakdownItem(state: TorStateModel) {
    return state.selectedCostBreakdownItem;
  }

  @Selector([TorState])
  static updateCostBreakdownItem(state: TorStateModel) {
    return state.updateCostBreakdownItem;
  }

  @Selector([TorState])
  static isEditDescription(stateModel: TorStateModel) {
    return stateModel.isEditDescription;
  }

  @Selector([TorState])
  static torComments(state: TorStateModel) {
    return state.torComments;
  }

  @Selector([TorState])
  static selectedTorComment(state: TorStateModel) {
    return state.selectedTorComment;
  }

  @Selector([TorState])
  static isUpdatingComment(state: TorStateModel) {
    return state.isUpdatingComment;
  }

  @Selector([TorState])
  static isReplyingToComment(state: TorStateModel) {
    return state.isReplyingToComment;
  }

  @Selector([TorState])
  static currency(state: TorStateModel) {
    return state.currency;
  }

  @Selector([TorState])

  static selectedTorprocessInstanceOwnerId(state: TorStateModel) {
    return state.selectedTorprocessInstanceOwnerId;
  }
  @Selector([TorState])
  static isReplyTorComment(state: TorStateModel) {
    return state.isReplyTorComment;
  }

  @Selector([TorState])
  static selectedReply(state: TorStateModel){
    return state.selectedReply;
  }

  @Selector([TorState])
  static isUpdatingReply(state: TorStateModel) {
    return state.isUpdatingReply;
  }
}
