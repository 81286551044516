<mat-dialog-content mat-dialog-content class="form-container">
  <form class="process-instance-form" [formGroup]="workflowConfigurationForm">
    <h3>{{ formTitle }}</h3>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.workflow-configuration-form.name">Name</mat-label>
      <input
        id="name"
        formControlName="name"
        type="text"
        matInput
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            workflowConfigurationForm.get('name')?.value ?? ''
          )
        "
        class="full-width-input"
      />
      <mat-error
        *ngIf="workflowConfigurationForm.get('name')!.hasError('required')"
       i18n="@@documents.workflow-configuration-form.please-enter-a-name">
        Please enter a name
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.workflow-configuration-form.description">Description</mat-label>
      <textarea
        id="description"
        formControlName="description"
        matInput
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            workflowConfigurationForm.get('description')?.value ?? ''
          )
        "
        [placeholder]="placeholderFieldLabel.processDescriptionField"
        class="full-width"
      ></textarea>

      <mat-error
        *ngIf="
          workflowConfigurationForm.get('description')!.hasError('required')
        "
       i18n="@@documents.workflow-configuration-form.please-enter-a-description">
        Please enter a description
      </mat-error>
    </mat-form-field>

    <div class="owner-office">
      <button mat-flat-button color="primary" (click)="openSingleOffice()"
        i18n="@@documents.workflow-types.workflow-configuration-form.select-owner-office">
        Select owner office
      </button>
      <mat-chip
          class="truncated-chip"
          (removed)="removeSelectedOffice()"
          *ngIf="selectedFlatOfficeNode"
        >
          <span>
            {{selectedFlatOfficeNode.name}}
          </span>
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
      </mat-chip>
    </div>
  </form>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@documents.workflow-configuration-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!workflowConfigurationForm.valid || !selectedFlatOfficeNode"
    >
      {{ saveButtonText }}
    </button>
  </div>
</mat-dialog-content>
