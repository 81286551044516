<div class="container">
    <h3 class="h3 mb-1">{{ publication?.processInstance?.title }}</h3>
    <p> <span i18n="@@researches.publication-detail.publication-title"> Publication title: </span> {{ publication?.title }}</p>
    <div class="publication-process-list">
        <mat-card class="process-card" *ngFor="let publicationProcesses of publication?.publicationProcesses"
            appearance="outlined">
            <mat-card-content class="ongoing-header">
                <div class="ongoing-title">
                    <div class="option">
                        <p>{{ publicationProcesses.publicationOption.title }} :</p>
                        <div class="status-chip" [ngClass]="
                getPublicationStatusColor(
                  publicationProcesses?.publicationStatus?.toString()
                )
              "
            >
              <p>
                {{
                  publicationProcesses?.publicationStatus
                }}
              </p>
            </div>
          </div>
          <button
            mat-stroked-button
            color="primary"
            *appHasPermission="hasDownloadPublicationTemplatePermission()"
            (click)="downloadTemplates(publicationProcesses.publicationOption)"
          >
            <mat-icon color="accent">download</mat-icon>
            <span i18n="@@researches.publication-detail.download-template">
              Download Templete</span
            >
          </button>
        </div>
        <div class="document-container document-portion">
          <div class="add-button-container option">
            <p i18n="@@researches.publication-detail.document">Document:</p>
            <button
              mat-raised-button
              color="primary"
              i18n="@@researches.publication-detail.add"
              (click)="openUploadDialog(publicationProcesses.id)"
              *appHasPermission="hasUploadPublicationDocumentPermission()"
              [disabled]=" publicationProcesses?.publicationStatus === 'ReadyForPublication' || publicationProcesses?.publicationStatus === 'Cancelled' || publicationProcesses?.publicationStatus === 'Submitted'"
              [attr.data-test-id]="'researches.add-document.publication-detail.add-button'"
            >
              Add
            </button>
          </div>
          <mat-chip-listbox class="mat-mdc-chip-set-stacked uploaded-files">
            <mat-chip
              class="truncated-chip"
              *ngFor="let file of publicationProcesses.publicationDocument"
              [matTooltip]="file.fileName"
            >
              <fa-icon
                matChipAvatar
                [icon]="getIconForFile(file.fileName)"
                class="file-icon"
              ></fa-icon>
              <span>
                {{
                  extractFileNameAndExtension(file.fileName).name.length > 25
                    ? extractFileNameAndExtension(file.fileName).name.slice(
                        0,
                        25
                      ) + "..."
                    : extractFileNameAndExtension(file.fileName).name
                }}.{{ extractFileNameAndExtension(file.fileName).extension }}
              </span>

              <button
               *appHasPermission="hasCrudPermission()"
                mat-icon-button
                class="menu-btn"
                matChipTrailingIcon
                [matMenuTriggerFor]="menu"
                [attr.data-test-id]="'researches.get-file.publication-detail.kebab-icon-button'"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="
                    previewPublicationDocument(publicationProcesses.id, file.id, file.fileName)
                  "
                  *appHasPermission="hasGetPublicationDocumentPermission()"
                  i18n="@@researches.publication-detail.preview-document"
                  [attr.data-test-id]="'researches.preview-document.publication-detail.preview-document-button'"
                >
                  Preview document
                </button>
                <button
                  mat-menu-item
                  (click)="downloadPublicationDocument($event, file)"
                  *appHasPermission="hasDownloadPublicationDocumentPermission()"
                  i18n="@@researches.publication-detail.download-document"
                  [attr.data-test-id]="'researches.download-document.publication-detail.download-document-button'"
                >
                  Download Document
                </button>
                <div *ngIf="publicationProcesses?.publicationStatus !== 'Submitted' && publicationProcesses?.publicationStatus !=='ReadyForPublication'">
                <button
                  mat-menu-item
                  (click)="deleteDocument(publicationProcesses.id, file.id)"
                  *appHasPermission="hasRemovePublicationDocumentPermission()"
                  i18n="@@researches.publication-detail.delete-document"
                  [attr.data-test-id]="'researches.delete-document.publication-detail.delete-document-button'"
                >
                  Delete Document
                </button>
              </div>
              </mat-menu>
            </mat-chip>
          </mat-chip-listbox>
        </div>
        <div class="ongoing-title">
          <button
            class="custom-button"
            *appHasPermission="hasGetPublicationCommentsPermission()"
            mat-stroked-button
            color="primary"
            (click)="toggleComments(publicationProcesses.id)"
          >
            <mat-icon color="accent">comment</mat-icon>
            <span i18n="@@researches.publication-detail.comments"
            [attr.data-test-id]="'researches.comment.publication-detail.comment-button'"
              >Comments</span
            >
          </button>
          <div class="option">
            <button
              mat-stroked-button
              color="primary"
              i18n="@@researches.publication-detail.submit"
              (click)="submitProcessDocuments(publicationProcesses.id)"
              *appHasPermission="hasSubmitPublicationDocumentsPermission()"
              [disabled]="checkPublicationStatus(publicationProcesses)"
              [attr.data-test-id]="'researches.submit.publication-detail.submit-button'"
            >
              Submit
            </button>
            <button
              mat-stroked-button
              color="primary"
              i18n="@@researches.publication-detail.needs-revision"
              (click)="
                changeStatus(publicationProcesses.id, 'NeedsRevision')
              "
              *appHasPermission="
                hasChangePublicationStatusToNeedsRevisionPermission()
              "
              [disabled]="
                publicationProcesses?.publicationStatus !== 'Submitted'
              "
              [attr.data-test-id]="'researches.needs-revision.publication-detail.needs-revision-button'"
            >
              Needs A Revision
            </button>
            <button
              mat-stroked-button
              color="primary"
              i18n="@@researches.publication-detail.ready-to-publish"
              (click)="
                changeStatus(publicationProcesses.id, 'ReadyForPublication')
              "
              *appHasPermission="
                hasChangePublicationStatusToReadyForPublicationPermission()
              "
              [disabled]="
                publicationProcesses?.publicationStatus !== 'Submitted'
              "
              [attr.data-test-id]="'researches.ready-to-publish.publication-detail.ready-to-publish-button'"
            >
              Ready To Publish
            </button>
            <div
            *ngIf="!(!publicationProcesses ||
      publicationProcesses.publicationStatus === 'Cancelled')"
            >
              <button
                mat-stroked-button
                color="warn"
                (click)="changeStatus(publicationProcesses.id, 'Cancelled')"
                *appHasPermission="
                  hasChangePublicationStatusToCancelledPermission()
                "
                i18n="@@researches.publication-detail.cancel"
                [attr.data-test-id]="'researches.cancel-publish.publication-detail.cancel-button'"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <ng-container *appHasPermission="hasGetPublicationCommentsPermission()">
    <div class="comments" *ngIf="showComments && currentProcessId">
      <app-publication-comment
        [processId]="currentProcessId"
      ></app-publication-comment>
    </div>
  </ng-container>
</div>
