import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfficeFormComponent } from '../office-form/office-form.component';
import {
  RegistrationType,
  UserFormComponent,
} from 'src/app/users/components/user-form/user-form.component';
import { Office } from '../../models/office.model';
import { OfficeFacade } from '../../facades/office.facades';
import { RxState } from '@rx-angular/state';
import { OfficeListComponent } from '../office-list/office-list.component';

import {
  ARCHIVED_WORKSPACES_ROUTE,
  CREATE_OFFICES_ROUTE,
  OFFICES_DASHBOARD_ROUTE,
  OFFICES_LIST_ROUTE,
  OFFICES_MULTIPLE_SELECT_ROUTE,
  OFFICE_ORGANOGRAM_ROUTE,
  UPDATE_OFFICE_STRUCTURE_ROUTE,
} from 'src/app/core/constants/routes';
import { OFFICES_TREE_ROUTE } from 'src/app/core/constants/routes';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { Observable, combineLatest, map } from 'rxjs';
import { UserFacade } from 'src/app/users/facade/user.facade';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

interface OfficeListComponentState {
  offices: Office[];
}

const initOfficeListComponentState: Partial<OfficeListComponentState> = {
  offices: [],
};

@Component({
  selector: 'app-offices-home',
  templateUrl: './offices-home.component.html',
  styleUrls: ['./offices-home.component.scss'],
  providers: [RxState],
})
export class OfficesHomeComponent implements OnInit {
  navLinks: Array<{
    link: string;
    label: string;
    icon: string;
    hidden: boolean;
    feature?: string | undefined;
    permission?: string | undefined;
  }> = [
    {
      link: OFFICES_LIST_ROUTE,
      label: $localize`:@@nav-links.offices:Offices`,
      icon: `domain`,
      hidden: true,
      permission: this.hasGetOfficesPermission(),
    },
    {
      link: OFFICE_ORGANOGRAM_ROUTE,
      label: $localize`:@@nav-links.organogram:Organogram`,
      icon: `device_hub`,
      hidden: true,
      permission: this.hasGetOfficeTreePermission(),
    },
    {
      link: UPDATE_OFFICE_STRUCTURE_ROUTE,
      label: $localize`:@@nav-links.update-tree:Update Office Structure`,
      icon: `layers`,
      hidden: true,
      permission: this.hasUpdateOfficeTreePermission(),
    },
    {
      link: ARCHIVED_WORKSPACES_ROUTE,
      label: $localize`:@@nav-links.archived:Archived`,
      icon: `archive`,
      hidden: true,
      permission: this.hasGetArchivedWorkspacesPermission(),
    },
    {
      link: OFFICES_DASHBOARD_ROUTE,
      label: $localize`:@@nav-links.dashboard:Dashboard`,
      icon: `dashboard`,
      hidden: true,
      feature: PERMISSION_NAMES.Analytics.AnalyticsOffice.Feature,
    },
  ];
  navLinks$ = new Observable<any>();

  private PageNumber: number = 1;
  private PageSize: number = 50;
  constructor(
    private matDialog: MatDialog,
    private officeFacade: OfficeFacade,
    private state: RxState<OfficeListComponentState>,
    private userFacade: UserFacade,
  ) {
    this.state.set(initOfficeListComponentState);
    this.state.connect('offices', officeFacade.offices$);
  }

  ngOnInit(): void {
    this.officeFacade.dispatchGetOffices(this.PageNumber, this.PageSize);
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.updateMenuAuthorization();
      }
    });
  }

  createOffice() {
    this.matDialog.open(OfficeFormComponent, {
      data: {},
      disableClose: true,
    });
  }

  isAuthorized(permission: string): Observable<boolean> {
    return this.userFacade
      .hasPermission(permission)
      .pipe(map((permission) => !!permission));
  }

  updateMenuAuthorization() {
    this.navLinks$ = combineLatest([
      ...this.navLinks.map((item) =>
        item.feature
          ? this.hasAuthorizedFeature(item.feature)
          : item.permission
            ? this.isAuthorized(item.permission)
            : new Observable<boolean>(),
      ),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.navLinks.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );
  }

  hasAuthorizedFeature(feature: string): Observable<boolean> {
    return this.userFacade
      .hasFeaturePermission(feature)
      .pipe(map((permission) => !!permission));
  }

  hasGetOfficesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetOffices,
    );
  }

  hasGetOfficeTreePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetOfficeTree,
    );
  }

  hasUpdateOfficeTreePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UpdateOfficeTree,
    );
  }

  hasGetArchivedWorkspacesPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.GetArchivedWorkspaces,
    );
  }
}
