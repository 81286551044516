import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateWorkflowTypeStep,
  OrderWorkflowTypeSteps,
  UpdateWorkflowTypeStepDto,
  WorkflowTypeStepDetail,
  WorkflowTypeStepDto,
} from '../models/workflow-type-steps.model';
import {
  WORKFLOW_TYPES_URL,
  WORKFLOW_TYPE_STEP_URL,
} from 'src/app/core/constants/api-endpoints';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTypeStepsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getWorkFlowTypeSteps(
    id: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<WorkflowTypeStepDto>> {
    return this.http.get<PaginatedList<WorkflowTypeStepDto>>(
      `${WORKFLOW_TYPE_STEP_URL}?workFlowTypeId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  createWorkflowTypeStep(workflowTypeStep: CreateWorkflowTypeStep) {
    return this.http.post<any>(`${WORKFLOW_TYPE_STEP_URL}`, workflowTypeStep);
  }

  updateWorkflowTypeStep(workflowTypeStep: UpdateWorkflowTypeStepDto) {
    return this.http.put<any>(`${WORKFLOW_TYPE_STEP_URL}`, workflowTypeStep);
  }

  deleteWorkflowTypeStep(workflowTypeStepId: string) {
    return this.http.delete<any>(
      `${WORKFLOW_TYPE_STEP_URL}/${workflowTypeStepId}`,
    );
  }

  addNotifyOfficesToWorkflowTypeStep(cc: any) {
    return this.http.put<any>(`${WORKFLOW_TYPE_STEP_URL}/notify-offices`, cc);
  }

  orderWorkflowTypeSteps(orderSteps: OrderWorkflowTypeSteps): Observable<any> {
    return this.http.put<any>(
      `${WORKFLOW_TYPE_STEP_URL}/order-steps`,
      orderSteps,
    );
  }

  getWorkflowTypeStepDetail(id: string): Observable<WorkflowTypeStepDetail> {
    return this.http.get<WorkflowTypeStepDetail>(
      `${WORKFLOW_TYPE_STEP_URL}/${id}`,
    );
  }

  getStepByName(workflowStepId: string, stepName: string) {
    return this.http.get<{ id: string } | null>(`${WORKFLOW_TYPES_URL}/steps`, {
      params: { workflowStepId, stepName },
    });
  }
}
