<div class="mat-elevation-z0">
  <h1 i18n="@@documents.workflow-average-time-spent.average-time-spent-on-workflow" class="mat-h1">Average time spent on a workflow</h1>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedWorkflowAverageTimeSpents($event)"
  ></mat-paginator>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    <ng-container matColumnDef="workflowName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-average-time-spent.workflow-name"> Workflow Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.workflowName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="workflowTypeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-average-time-spent.workflow-type"> Workflow Type </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.workflowTypeName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averageTimeInDays">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-average-time-spent.average-time"> Average Time </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ convertDaysToDaysAndHours(row.averageTimeInDays) }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
