export class GetReportedCommentsReport {
  static readonly type = `[template] ${GetReportedCommentsReport.name}`;
  constructor(
    public pageIdx: number,
    public pageSize: number,
    public workflowType?: string,
    public searchTerm?: string,
  ) {}
}

export class GetWorkflowTypes {
  static readonly type = `[template] ${GetWorkflowTypes.name}`;
  constructor() {}
}
