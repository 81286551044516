import { Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessInstance } from 'src/app/researches/models/process-instance.model';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessTasks } from 'src/app/researches/models/process-tasks.model';
import { debounceTime } from 'rxjs/operators';

interface TaskSearchComponentState {
  myTasks: ProcessTasks[];
  processInstances: PaginatedList<ProcessInstance> | undefined;
  isSearching: boolean;
}

const initialTaskSearchComponentState: TaskSearchComponentState = {
  myTasks: [],
  processInstances: undefined,
  isSearching: false,
};

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  providers: [RxState],
})
export class SearchFormComponent {
  processTasks$ = this.state.select('myTasks');
  myTasks: ProcessTasks[] = [];
  processInstances$ = this.state.select('processInstances');
  processInstances: ProcessInstance[] = [];
  searchForm: FormGroup;
  isSearching$ = this.state.select('isSearching');
  isSearching: boolean = false;

  placeholderToggleLabel = {
      searchForm: $localize`:@@researches.search-form.search: Search`,
  }

  constructor(
    private formBuilder: FormBuilder,
    private taskFacade: TaskFacade,
    private processInstanceFacade: ProcessInstanceFacade,
    private state: RxState<TaskSearchComponentState>,
  ) {
    this.state.set(initialTaskSearchComponentState);
    this.state.connect('myTasks', this.taskFacade.myTasks$);
    this.state.connect(
      'processInstances',
      this.processInstanceFacade.processInstances$,
    );
    this.searchForm = this.formBuilder.group({
      searchTerm: [''],
      selectedProcesses: [[]],
    });
    this.state.connect('isSearching', this.taskFacade.isSearching$);
  }

  ngOnInit(): void {
    this.processInstanceFacade.dispatchGetProcessInstances();
    this.processInstances$.subscribe((processes) => {
      if (processes) {
        this.processInstances = processes.items;
      }
    });
    this.taskFacade.dispatchShowSearchedTask(false);
    this.isSearching$.subscribe((isSearching) => {
      this.isSearching = isSearching;
    });
  }

  getTasks() {
    const selectedValues: string[] =
      this.searchForm.get('selectedProcesses')?.value;

    const searchTerm = this.searchForm.get('searchTerm')?.value;
    if (selectedValues.length != 0) {
      this.taskFacade.dispatchGetTasksBySearchTerm(searchTerm, selectedValues);
      this.processTasks$.pipe(debounceTime(300)).subscribe((tasks) => {
        this.myTasks = tasks;
      });

      this.taskFacade.dispatchShowSearchedTask(true);
    } else if (selectedValues.length == 0 && searchTerm != '') {
      let allProcessIds: any[] = [];
      for (let index = 0; index < this.processInstances.length; index++) {
        allProcessIds.push(this.processInstances[index].id);
      }
      this.taskFacade.dispatchGetTasksBySearchTerm(searchTerm, allProcessIds);
      this.taskFacade.dispatchShowSearchedTask(true);
    } else if (selectedValues.length == 0 && searchTerm == '') {
      this.taskFacade.dispatchShowSearchedTask(!this.isSearching);
    }
  }

  onSearchTermChange() {
    this.taskFacade.dispatchShowSearchedTask(true);
    this.searchForm
      .get('searchTerm')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.getTasks();
      });
  }
}
