import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import {
  PROCESS_FORM_SIDE_DIALOG_CONFIG,
  SIDE_DIALOG_CONFIG,
} from 'src/app/core/constants/dialog_configs';
import { PROCESS_LIST_ROUTE } from 'src/app/core/constants/routes';
import { ProcessDetailFacade } from 'src/app/researches/facades/process.detail.facades';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import { ProcessDetail } from 'src/app/researches/models/process-detail.model';
import { Process } from 'src/app/researches/models/process.model';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import {
  Stage,
  UpdateStageOrderDto,
} from 'src/app/researches/models/stage.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { StageFormComponent } from '../../stage/stage-form/stage-form.component';
import { ProcessFormComponent } from '../process-form/process-form.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface ProcessState {
  selectedProcessDetail: Process | undefined;
}

interface ProcessDetailState {
  selectedProcessDetail: ProcessDetail | undefined;
}

const initProcessState: ProcessState = {
  selectedProcessDetail: undefined,
};

const initProcessDetailState: ProcessDetailState = {
  selectedProcessDetail: undefined,
};

@Component({
  selector: 'app-process-detail',
  templateUrl: './process-detail.component.html',
  styleUrls: ['./process-detail.component.scss'],
  providers: [RxState],
})
export class ProcessDetailComponent {
  selectedProcessDetail$ = this.processDetailstate.select(
    'selectedProcessDetail',
  );
  selectedProcessDetail: ProcessDetail | undefined;
  selectedProcess$ = this.processState.select('selectedProcessDetail');
  selectedProcess: Process | undefined;
  updateStageOrder: UpdateStageOrderDto | undefined = undefined;

  constructor(
    private matDialog: MatDialog,
    private processDetailstate: RxState<ProcessDetailState>,
    private processState: RxState<ProcessState>,
    private processDetailFacade: ProcessDetailFacade,
    private stageFacade: StageFacade,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,

    private processFacade: ProcessFacade,
  ) {
    this.processDetailstate.set(initProcessDetailState);
    this.processState.set(initProcessState);
    this.processDetailstate.connect(
      'selectedProcessDetail',
      this.processDetailFacade.selectedProcessDetail$,
    );
    this.processState.connect(
      'selectedProcessDetail',
      this.processFacade.selectedProcess$,
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.processDetailFacade.dispatchGetProcessDetail(params['id']);
    });

    this.selectedProcess$.subscribe((selectedProcess) => {
      this.selectedProcess = selectedProcess;
    });
    this.selectedProcessDetail$.subscribe((selectedProcessDetail) => {
      this.selectedProcessDetail = selectedProcessDetail;
    });
  }

  placeholderLabel = {
    canRequestBudget: $localize`:@@researches.process-detail.can-request-budget: Can request Budget`,
    canNotRequestBudget: $localize`:@@researches.process-detail.can-not-request-budget: Cannot request Budget`,
  }

  openDeleteConfirmationDialog(stage: Stage) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.process-detail.delete-stage-confirmation-part-1:Are you sure you want to delete` ,
        boldText: ` "${stage.name}" ` ,
        regularTextTwo: $localize`:@@researches.process-detail.delete-stage-confirmation-part-2:stage?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.stageFacade.dispatchDeleteStage(stage.id!);
      }
    });
  }
  openConfirmationDialog() {
    if (!this.selectedProcess) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.process-detail.delete-process-type-confirmation-part-1:Are you sure you want delete` ,
        boldText: ` "${
          this.selectedProcessDetail!.name
        }" ` ,
        regularTextTwo: $localize`:@@researches.process-detail.delete-process-type-confirmation-part-2: process type?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedProcess!.id)
          this.processFacade.dispatchDeleteProcess(
            this.selectedProcessDetail!.id,
          );
        this.router.navigate([PROCESS_LIST_ROUTE]);
      }
    });
  }

  openCreateStageForm() {
    this.stageFacade.dispatchEditableStage(null);
    this.dialog.open(StageFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        isUpdate: false,
      },
    });
  }

  openUpdateStageForm(stage: Stage) {
    this.stageFacade.dispatchEditableStage(stage);
    this.dialog.open(StageFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        isUpdate: true,
      },
    });
  }

  updateProcess() {
    this.processFacade.dispatchSetUpdateStatus(true);
    this.dialog.open(ProcessFormComponent, PROCESS_FORM_SIDE_DIALOG_CONFIG);
  }

  viewCriteria(stage: Stage) {
    this.router.navigate([`${PROCESS_LIST_ROUTE}/stage/`, stage.id]);
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;
    const elementId = event.item.element.nativeElement.getAttribute('id');
    this.updateStageOrder = {
      stageId: elementId as string,
      order: currentIndex + 1,
    };
    this.stageFacade.dispatchOrderStage(this.updateStageOrder);
    moveItemInArray(
      this.selectedProcessDetail!.stages,
      previousIndex,
      currentIndex,
    );
  }

  hasUpdateProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.UpdateProcess,
    );
  }

  hasDeleteProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.DeleteProcess,
    );
  }

  hasCreateStagePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Stage.Feature,
      PERMISSION_NAMES.Researches.Stage.CreateStage,
    );
  }

  hasGetStagesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Stage.Feature,
      PERMISSION_NAMES.Researches.Stage.GetStages,
    );
  }

  hasDeleteStagePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Stage.Feature,
      PERMISSION_NAMES.Researches.Stage.DeleteStage,
    );
  }

  hasUpdateStagePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Stage.Feature,
      PERMISSION_NAMES.Researches.Stage.UpdateStage,
    );
  }

  hasGetCriterionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.GetCriterion,
    );
  }
}
