import { NgModule } from '@angular/core';

import { WorkflowsRoutingModule } from './workflows-routing.module';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WorkflowSearchComponent } from './components/workflow-search/workflow-search.component';
import { WorkflowCardComponent } from './components/workflow-card/workflow-card.component';
import { WorkflowInitiateFormComponent } from './components/workflow-initiate-form/workflow-initiate-form.component';
import { MyWorkflowsComponent } from './components/my-workflows/my-workflows.component';
import { OwnedWorkflowsComponent } from './components/owned-workflows/owned-workflows.component';
import { ArchivedWorkflows } from './components/archived-workflows/archived-workflows.component';

@NgModule({
  declarations: [
    WorkflowComponent,
    WorkflowSearchComponent,
    WorkflowCardComponent,
    WorkflowInitiateFormComponent,
    ArchivedWorkflows,
    MyWorkflowsComponent,
    OwnedWorkflowsComponent,
  ],
  imports: [SharedModule, WorkflowsRoutingModule],
})
export class WorkflowsModule {}
