import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StageInstanceDetail,
  StageInstanceEvaluation,
  StageInstanceEvaluationStatus,
  StageInstanceStatus,
  StageInstanceTask,
  UpdateStageStatusDto,
  UserStageInstanceEvaluation,
} from '../models/stage-instance-detail.model';
import {
  CHANGE_STAGE_INSTANCES_STATUS_URL,
  PROCESS_TASK_URL,
  STAGE_INSTANCES_EVALUATION_URL,
  STAGE_INSTANCES_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_IN_PROGRESS_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_APPROVED_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_REJECTED_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_TERMINATED_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_NEEDS_REVISION_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_SUBMITTED_TO_EVALUATION_URL,
  CHANGE_STAGE_INSTANCES_STATUS_TO_WAITING_FOR_APPROVAL_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class StageInstanceDetailService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getStageEvaluation(stageId: string): Observable<StageInstanceEvaluation> {
    return this.http.get<StageInstanceEvaluation>(
      `${STAGE_INSTANCES_EVALUATION_URL}/?stageInstanceId=${stageId}`,
    );
  }
  getUserStageEvaluation(
    stageId: string,
  ): Observable<UserStageInstanceEvaluation> {
    return this.http.get<UserStageInstanceEvaluation>(
      `${STAGE_INSTANCES_EVALUATION_URL}/user-stage-evalution/?stageInstanceId=${stageId}`,
    );
  }

  getStageInstanceDetail(id: string): Observable<StageInstanceDetail> {
    return this.http.get<StageInstanceDetail>(
      `${STAGE_INSTANCES_URL}/${id}`,
      this.httpOptions,
    );
  }

  getStageInstanceTasks(
    stageInstanceId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<StageInstanceTask>> {
    if (pageNumber !== undefined && pageSize !== undefined) {
      return this.http.get<PaginatedList<StageInstanceTask>>(
        `${STAGE_INSTANCES_URL}/${stageInstanceId}/tasks?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    }
    return this.http.get<PaginatedList<StageInstanceTask>>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/tasks`,
    );
  }

  approveStageInstance(stageInstanceId: string) {
    return this.http.put(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/approve-or-reject`,
      true,
    );
  }

  changeStageStatus(updatedStage: UpdateStageStatusDto): Observable<any> {
    if(updatedStage.stageStatus == StageInstanceStatus.Inprogress){
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_IN_PROGRESS_URL}`,
        updatedStage
      );
    }
    else if(updatedStage.stageStatus == StageInstanceStatus.WaitingForApproval) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_WAITING_FOR_APPROVAL_URL}`,
        updatedStage
      );

    }
    else if (updatedStage.stageStatus == StageInstanceStatus.SubmittedToEvaluation) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_SUBMITTED_TO_EVALUATION_URL}`,
        updatedStage
      );

    }
    else if(updatedStage.stageStatus == StageInstanceStatus.Approved) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_APPROVED_URL}`,
        updatedStage
      );

    }
    else if(updatedStage.stageStatus == StageInstanceStatus.Rejected) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_REJECTED_URL}`,
        updatedStage
      );
    }
    else if(updatedStage.stageStatus == StageInstanceStatus.Terminated) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_TERMINATED_URL}`,
        updatedStage
      );
    }
    else if(updatedStage.stageStatus == StageInstanceStatus.NeedsRevision) {
      return this.http.put<any>(
        `${CHANGE_STAGE_INSTANCES_STATUS_TO_NEEDS_REVISION_URL}`,
        updatedStage
      );

    }
    else
    {
      return new Observable<any>;
    }

    }


  rejectStageInstance(stageInstanceId: string): Observable<any> {
    return this.http.put(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/approve-or-reject`,
      false,
    );
  }

  checkEveryTasksApproval(stageInstanceId: string): Observable<any> {
    return this.http.get(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/check-tasks-approval`,
    );
  }

  getStageInstanceEvaluationStatus(stageInstanceId: string) {
    return this.http.get<StageInstanceEvaluationStatus>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/evaluation-status`,
    );
  }

  updateIsMajotTask(id: string): Observable<any> {
    return this.http.put<any>(`${PROCESS_TASK_URL}/toggle-is-major/${id}`, null);
  }

  checkIfMajorTaskFound(stageInstanceId: string): Observable<any> {
    return this.http.get<any>(
      `${STAGE_INSTANCES_URL}/${stageInstanceId}/check-major-task`,
    );
  }

  downloadStage(stageInstanceId: string) {
    return this.http.get(
      `${STAGE_INSTANCES_URL}/download-stage?stageInstanceId=${stageInstanceId}`,
      {
        responseType: 'blob',
      },
    );
  }

}
