import { MoveArchivedFileDto } from './../../../../files/models/file.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, filter } from 'rxjs';
import { FolderFacade } from 'src/app/files/facade/folder.facade';
import { WorkspaceFacade } from 'src/app/files/facade/workspace.facade';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';
import { Workspace } from 'src/app/files/models/workspace';
import { ArchivedFileMoveComponent } from '../archived-file-move/archived-file-move.component';
import { FileFacade } from 'src/app/files/facade/file.facade';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface ArchivedFolderMoveComponentState {
  selectedFlatFolderNode: FlatFolderNode | null;
  filteredWorkspaces: Workspace[];
  selectedDestinationFolder?: FlatFolderNode;
  selectedFilesIds: string[];
  isSharingFile?: boolean;
}

const initArchivedFolderMoveComponentState: ArchivedFolderMoveComponentState = {
  selectedFlatFolderNode: null,
  filteredWorkspaces: [],
  selectedFilesIds: [],
};

@Component({
  selector: 'app-archived-folder-move',
  templateUrl: './archived-folder-move.component.html',
  styleUrls: ['./archived-folder-move.component.scss'],
})
export class ArchivedFolderMoveComponent implements OnInit {
  moveForm: FormGroup;
  selectedFlatFolderNode: FlatFolderNode | null = null;
  selectedFlatFolderNode$: Observable<FlatFolderNode | null> =
    this.state.select('selectedFlatFolderNode');
  filteredWorkspaces$ = this.state.select('filteredWorkspaces');
  selectedDestinationFolder?: FlatFolderNode;
  selectedDestinationFolder$ = this.state.select('selectedDestinationFolder');
  isSharingFile?: boolean;
  isSharingFile$ = this.state.select('isSharingFile');
  filteredWorkspaces?: Workspace[];
  selectedDestinationWorkspace?: Workspace;
  selectedFilesIds$ = this.state.select('selectedFilesIds');
  selectedFilesIds?: string[];
  isMoveButtonDisabled: boolean = true;
  isSelectFolderDisabled: boolean = true;

  placeholderToggleLabel = {
    selectDestinationWorkspace: $localize`:@@offices.archived-folder-move.select-destination-workspace: Select Destination Workspace`,
}

  @ViewChild('workspaceInput') workspaceInput!: ElementRef<HTMLInputElement>;
  constructor(
    private state: RxState<ArchivedFolderMoveComponentState>,
    private workspaceFacade: WorkspaceFacade,
    private folderFacade: FolderFacade,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileFacade: FileFacade,
  ) {
    this.state.connect(
      'selectedFlatFolderNode',
      folderFacade.selectedFlatFolderNode$,
    );

    this.state.connect(
      'filteredWorkspaces',
      workspaceFacade.filteredWorkspaces$,
    );
    this.state.connect(
      'selectedDestinationFolder',
      folderFacade.selectedDestinationFlatFolderNode$,
    );
    this.state.connect('selectedFilesIds', this.fileFacade.selectedFilesIds$);
    this.state.connect('isSharingFile', this.fileFacade.isSharingFile$);

    this.moveForm = this.fb.group({
      workspaceSearchQuery: [''],
      workspace: [undefined as Workspace | undefined, Validators.required],
    });
  }
  ngOnInit(): void {
    this.workspaceFacade.dispatchSearchWorkspaces();

    this.selectedFlatFolderNode$.subscribe(
      (selectedFlatFolderNode) =>
        (this.selectedFlatFolderNode = selectedFlatFolderNode),
    );

    this.filteredWorkspaces$.subscribe(
      (workspaces) => (this.filteredWorkspaces = workspaces),
    );
    this.selectedFilesIds$.subscribe(
      (fileIds) => (this.selectedFilesIds = fileIds),
    );
    this.isSharingFile$.subscribe(
      (isSharingFile) => (this.isSharingFile = isSharingFile),
    );

    this.moveForm.controls['workspaceSearchQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((name) => {
        if (name !== '') {
          this.workspaceFacade.dispatchSearchWorkspaces(name);
        } else if (name === '') {
          this.workspaceFacade.dispatchSearchWorkspaces();
        }
      });
  }

  moveFolder() {
    this.folderFacade.dispatchMoveFolderFromArchivedWorkspace(
      this.selectedFlatFolderNode?.id as string,
      this.selectedDestinationWorkspace?.id as string,
      this.selectedFlatFolderNode?.workspaceId as string,
    );
  }

  moveFile() {
    const moveArchivedFileDtos: MoveArchivedFileDto[] | undefined =
      this.selectedFilesIds?.map((fileId) => {
        const moveDto = {
          fileId: fileId,
          destinationFolderId: this.selectedDestinationFolder?.id as string,
        };

        return moveDto;
      });
    this.fileFacade.dispatchMoveArchivedFiles(
      moveArchivedFileDtos as MoveArchivedFileDto[],
    );
  }

  onMoveClicked() {
    this.isSharingFile ? this.moveFile() : this.moveFolder();
    this.fileFacade.dispatchSelectFilesIds([]);
  }

  removeWorkspace() {
    this.moveForm.controls['workspace'].setValue(undefined);
    this.selectedDestinationFolder = undefined;
    this.isMoveButtonDisabled = true;
    this.isSelectFolderDisabled = true;
  }

  selectWorkspace(event: MatAutocompleteSelectedEvent) {
    this.moveForm.controls['workspace'].setValue(event.option.value);
    this.workspaceInput.nativeElement.value = '';
    this.selectedDestinationWorkspace = this.moveForm.value.workspace;
    this.isSelectFolderDisabled = false;
    this.isMoveButtonDisabled = false;
  }

  selectDestinationFolder() {
    const dialogRef = this.dialog.open(ArchivedFileMoveComponent, {
      data: {
        selectedWorkspaceId: this.selectedDestinationWorkspace?.id,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.selectedDestinationFolder$.subscribe(
        (selectedDestinationFolder) =>
          (this.selectedDestinationFolder = selectedDestinationFolder),
      );

      if (this.selectedDestinationFolder) {
        this.isSelectFolderDisabled = true;
      } else {
        this.selectedDestinationFolder = undefined;
      }
    });
  }

  removeSelectedFolder() {
    this.selectedDestinationFolder = undefined;
    this.isSelectFolderDisabled = false;
  }

  hasMoveFolderFromArchivedWorkspacePermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Folder.Feature,
      PERMISSION_NAMES.Files.Folder.MoveFolderFromArchivedWorkspace,
    );
  }
}
