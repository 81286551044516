import { NgModule } from '@angular/core';

import { WorkflowStepsRoutingModule } from './workflow-steps-routing.module';
import { WorkflowStepComponent } from './components/workflow-step/workflow-step.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { WorkflowStepState } from './store/workflow-step.state';
import { AttachedUserListComponent } from './components/attached-user-list/attached-user-list.component';
import { WorkflowCommentComponent } from '../workflow-comments/components/workflow-comment/workflow-comment.component';
import { WorkflowCommentCardComponent } from '../workflow-comments/components/workflow-comment-card/workflow-comment-card.component';
import { WorkflowCommentReplyCardComponent } from '../workflow-comments/components/workflow-comment-reply-card/workflow-comment-reply-card.component';
import { AttachUserFormComponent } from './components/attach-user-form/attach-user-form.component';
import { CcdWorkflowStepDetailComponent } from './components/ccd-workflow-step-detail/ccd-workflow-step-detail.component';
import { DocumentFieldControlService } from "../../core/services/Document-field-control/document-field-control-service";
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
    declarations: [
        WorkflowStepComponent,
        WorkflowCommentComponent,
        WorkflowCommentCardComponent,
        WorkflowCommentReplyCardComponent,
        AttachedUserListComponent,
        AttachUserFormComponent,
        CcdWorkflowStepDetailComponent,
        CustomInputComponent
    ],
    providers: [DocumentFieldControlService],
    imports: [
        SharedModule,
        WorkflowStepsRoutingModule,
        FontAwesomeModule,
        NgxsModule.forFeature([WorkflowStepState]),
    ],
    exports: [CustomInputComponent]
})
export class WorkflowStepsModule {}
