import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Deadline,
  StageInstanceScheduleDto,
} from '../../models/deadline.model';
import { ProcessInstanceFacade } from '../../facades/process-instance.facades';
import {
  ProcessInstanceDetail,
  ProcessInstanceStatus,
  StageInstance,
} from '../../models/process-instance.model';
import { RxState } from '@rx-angular/state';

interface ProcessInstanceDetailState {
  processInstanceDetail: ProcessInstanceDetail | undefined;
}

const initProcessInstanceDetailState: ProcessInstanceDetailState = {
  processInstanceDetail: undefined,
};

@Component({
  selector: 'app-research-deadline',
  templateUrl: './research-deadline.component.html',
  styleUrls: ['./research-deadline.component.scss'],
})
export class ResearchDeadlineComponent {
  processInstanceDetail: ProcessInstanceDetail | undefined;
  processInstanceDetail$ = this.state.select('processInstanceDetail');
  ProcessInstanceStatus = ProcessInstanceStatus;
  update: string;
  view: string;
  title: string = $localize`:@@researches.research-deadline.time-schedule: Time Schedule`;

  placeholderToggleLabel = {
      startDate: $localize`:@@researches.research-deadline.start-date: Start date`,
      endDate: $localize`:@@researches.research-deadline.end-date: End date`,
  }

  deadlineForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      update: string;
      view: string;
    },
    private fb: FormBuilder,
    private processInstanceFacade: ProcessInstanceFacade,
    private dialogRef: MatDialogRef<ResearchDeadlineComponent>,
    private state: RxState<ProcessInstanceDetailState>,
  ) {
    this.state.set(initProcessInstanceDetailState);
    this.update = this.data.update;
    this.view = this.data.view;

    this.deadlineForm = this.fb.group(
      {
        stageInstanceSchedules: this.fb.array([]),
      },
      { validators: this.dateRangeValidator() },
    );
  }

  get stageInstanceSchedules(): FormArray {
    return this.deadlineForm.get('stageInstanceSchedules') as FormArray;
  }

  addStageInstanceSchedule(
    stageInstanceId: string,
    stageInstanceName: string,
    startDate: Date,
    endDate: Date,
  ) {
    this.stageInstanceSchedules.push(
      this.fb.group({
        stageInstanceId: stageInstanceId,
        stageInstanceName: stageInstanceName,
        startDate: startDate,
        endDate: endDate,
      }),
    );
  }

  get getStartDate() {
    return (
      this.processInstanceDetail?.startDate &&
      new Date(this.processInstanceDetail?.startDate).toDateString()
    );
  }

  get getEndDate() {
    return (
      this.processInstanceDetail?.endDate &&
      new Date(this.processInstanceDetail?.endDate).toDateString()
    );
  }

  get isUpdate() {
    if (this.update) return true;
    return false;
  }

  get isView() {
    if (this.view) return true;
    return false;
  }

  getReadableDate(date: Date) {
    return new Date(date).toDateString();
  }

  ngOnInit(): void {
    if (this.update) {
      this.title =  $localize`:@@researches.research-deadline.update-time-schedule-title: Update Time Schedule`;
    }
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
      this.patchFormValues();
    });
  }

  patchFormValues() {
    this.processInstanceDetail?.stageInstances.forEach((stageInstance) => {
      this.addStageInstanceSchedule(
        stageInstance.id!,
        stageInstance.name!,
        stageInstance.startDate!,
        stageInstance.endDate!,
      );
    });
  }
  save() {
    const { valid, touched, dirty } = this.deadlineForm;
    if (valid && (touched || dirty)) {
      if (this.processInstanceDetail?.process.enforceSequential) {
        const stageInstanceSchedules = this.deadlineForm.value
          .stageInstanceSchedules as StageInstanceScheduleDto[];

        for (let i = 0; i < stageInstanceSchedules.length - 1; i++) {
          if (
            stageInstanceSchedules[i].endDate >
            stageInstanceSchedules[i + 1].startDate
          ) {
            this.deadlineForm.setErrors({ invalidDateRange: true });
            return;
          }
        }
      }
      const deadline: Deadline = {
        processInstanceId: this.processInstanceDetail?.id,
        stageInstanceSchedules: this.deadlineForm.value
          .stageInstanceSchedules as StageInstanceScheduleDto[],
      };
      this.processInstanceFacade.dispatchSubmitProcessInstanceSchedule(
        deadline,
      );
      this.dialogRef.close();
    }
  }
  dateRangeValidator(): any {
    return (group: FormGroup): { [key: string]: any } | null => {
      const start = group.get('start')?.value;
      const end = group.get('end')?.value;
      if (start && end && start > end) {
        group.get('end')?.setErrors({ invalidDateRange: true });
        return { invalidDateRange: true };
      } else {
        group.get('end')?.setErrors(null);
        return null;
      }
    };
  }
}
