import { ProcessInstance } from './process-instance.model';
import { PublicationOption } from './publication-option.model';

export interface CreatePublicationInfo {
  title: string;
  processInstanceId: string;
  publicationOptionIds: string[];
}

export interface Publication {
  id?: string;
  title: string;
  processInstance: ProcessInstance;
  publicationProcesses: PublicationProcess[];
}

export interface PublicationProcess {
  id?: string;
  publicationStatus: PublicationStatus;
  publicationStatusForDisplay: PublicationStatusForDisplay;
  publicationOption: PublicationOption;
  publicationDocument?: PublicationDocument[];
}

export interface PublicationStatusForDisplay {
  id?: string;
  publicationStatus: string
}

export interface PublicationDocument{
  id: string;
  fileName: string;
  version: string;
}

export enum PublicationStatus {
  Initialized = 'Initialized',
  Submitted = 'Submitted',
  Published = 'Published',
  NeedsRevision = 'NeedsRevision',
  ReadyForPublication = 'ReadyForPublication',
  Cancelled = 'Cancelled'
}

export interface PublicationComment {
  id?: string;
  content: string;
  created: string;
  createdBy: string;
  fullName: string;
  replies: PublicationComment[];
}
