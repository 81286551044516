<form class="template-form" [formGroup]="templateForm">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label i18n="@@documents.document-template-step-form.name">Name</mat-label>
    <input
      formControlName="name"
      data-test-id="document-template-name"
      type="text"
      (focus)="focusedInput='name'"
      (keydown)="handleSpaceEvent($event)"
      matInput
      class="full-width"
      [placeholder]="placeholderFieldLabel.templateNameField"
    />
    <mat-error *ngIf="templateForm.get('name')?.hasError('required')"
    i18n="@@documents.document-form-step-form.please-provide-a-valid-name-for-the-document-form">
      Please provide a valid name for the document template
    </mat-error>
    <mat-error *ngIf="templateForm.get('name')?.hasError('duplicateFormName')">
      Form name is already taken.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label i18n="@@documents.document-form-step-form.description">Description</mat-label>
    <textarea
      formControlName="description"
      (focus)="focusedInput='description'"
      (keydown)="handleSpaceEvent($event)"
      data-test-id="document-template-description"
      matInput
      [placeholder]="placeholderFieldLabel.templateDescriptionField"
    ></textarea>
    <mat-error *ngIf="templateForm.get('description')?.hasError('required')"
    i18n="@@documents.document-form-step-form.please-provide-a-valid-description-for-the-form">
      Please provide a valid description for the template.
    </mat-error>
  </mat-form-field>
</form>

<button
  mat-button matStepperNext i18n="@@documents.document-form-step-form.next"
  (click) = "focusedInput=''"
  [disabled]="!templateForm.valid"
  data-test-id="document.document-form-step.next-button">
  Next
</button>
