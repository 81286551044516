import { NgModule } from '@angular/core';

import { WorkflowTypeStepsRoutingModule } from './workflow-type-steps-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { WorkflowTypeStepState } from './store/workflow-type-steps.state';
import { WorkflowTypeStepComponent } from './components/workflow-type-step/workflow-type-step.component';
import { WorkflowTypeStepFormComponent } from './components/workflow-type-step-form/workflow-type-step-form.component';
import { WorkflowTypeStepListComponent } from './components/workflow-type-step-list/workflow-type-step-list.component';
import { WorkflowTypeStepAddFieldsComponent } from './components/workflow-type-step-add-fields/workflow-type-step-add-fields.component';
import { AddCcOfficesFormComponent } from './components/add-cc-offices-form/add-cc-offices-form.component';
import { WorkflowTypeStepDetailComponent } from './components/workflow-type-step-detail/workflow-type-step-detail.component';
import { WorkflowStepValidationsComponent } from "../workflow-validation/components/workflow-step-validations/workflow-step-validations.component";
import { WorkflowValidationModule } from '../workflow-validation/workflow-validation.module';

@NgModule({
    declarations: [
        WorkflowTypeStepComponent,
        WorkflowTypeStepFormComponent,
        WorkflowTypeStepListComponent,
        WorkflowTypeStepAddFieldsComponent,
        AddCcOfficesFormComponent,
        WorkflowTypeStepDetailComponent
    ],
    imports: [
        SharedModule,
        WorkflowTypeStepsRoutingModule,
        WorkflowValidationModule,
        NgxsModule.forFeature([WorkflowTypeStepState]),
    ]
})
export class WorkflowTypeStepsModule {}
