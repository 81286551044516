import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { RxState } from '@rx-angular/state';
import { debounceTime } from 'rxjs';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import {
  ProcessInstanceFilter,
  ProcessInstanceMultipleFilter,
  ProcessInstanceStatus,
} from 'src/app/researches/models/process-instance.model';
import { Process } from 'src/app/researches/models/process.model';

interface ProcessInstanceSearchComponentState {
  processes: Process[];
  showSearchTermAndFiltersData: boolean;
  processInstanceFilters: ProcessInstanceFilter[];
}

const initialProcessInstanceSearchComponentState: ProcessInstanceSearchComponentState =
  {
    processes: [],
    showSearchTermAndFiltersData: false,
    processInstanceFilters: [],
  };

@Component({
  selector: 'app-process-instance-search',
  templateUrl: './process-instance-search.component.html',
  styleUrls: ['./process-instance-search.component.scss'],
  providers: [RxState],
})
export class ProcessInstanceSearchComponent implements OnInit {
  processes$ = this.state.select('processes');
  processes: Process[] = [];
  showSearchTermAndFiltersData$ = this.state.select(
    'showSearchTermAndFiltersData',
  );
  showSearchTermAndFiltersData = false;
  searchForm: FormGroup;

  placeholderToggleLabel = {
    Africa: $localize`:@@researches.process-instance-search.africa: Africa`,
  };

  processInstanceFilters: ProcessInstanceFilter[] = [];
  processInstanceFilters$ = this.state.select('processInstanceFilters');

  datePlaceholderToggleLabel = {
    startDate: $localize`:@@researches.process-instance-search.start-date: Start date`,
    endDate: $localize`:@@researches.process-instance-search.end-date: End date`,
  };

  constructor(
    private fb: FormBuilder,
    private processInstanceFacade: ProcessInstanceFacade,
    private processFacade: ProcessFacade,
    private state: RxState<ProcessInstanceSearchComponentState>,
  ) {
    this.state.set(initialProcessInstanceSearchComponentState);
    this.state.connect('processes', this.processFacade.processes$);
    this.state.connect(
      'showSearchTermAndFiltersData',
      this.processInstanceFacade.showSearchTermAndFiltersData$,
    );
    this.state.connect(
      'processInstanceFilters',
      this.processInstanceFacade.processInstanceFilters$,
    );
    this.searchForm = this.fb.group({
      searchTerm: [''],
      processTypes: [[]],
      filter: [''],
      startDate: [''],
      endDate: [''],
    });

    this.searchForm.get('startDate')?.valueChanges.subscribe(() => {
      this.handleDateRangeChange();
    });
    this.searchForm.get('endDate')?.valueChanges.subscribe(() => {
      this.handleDateRangeChange();
    });
  }

  ngOnInit(): void {
    this.processFacade.dispatchGetPaginatedProcesses();
    this.processInstanceFacade.dispatchGetProcessInstanceFilters();
    this.processes$.subscribe((processes) => {
      if (processes) {
        this.processes = processes;
      }
    });

    this.showSearchTermAndFiltersData$.subscribe((show) => {
      this.showSearchTermAndFiltersData = show;
      if (!show) {
        this.searchForm.get('searchTerm')?.setValue('');
        this.searchForm.get('processTypes')?.setValue([]);
      }
    });

    this.processInstanceFilters$.subscribe((filters) => {
      this.processInstanceFilters = filters;
    });
    this.processInstanceFacade.dispatchSetProcessInstanceMultipleFilter(undefined)
    this.processInstanceFacade.dispatchResetProcessInstanceFilter(true)
  }
  onProcessSelect() {
    this.getProcessInstances();
  }
  getProcessInstances() {
    const selectedProcess = this.searchForm.get('processTypes')?.value ?? undefined;
    const searchTerm = this.searchForm.get('searchTerm')?.value ?? undefined;
    
    const processIds = selectedProcess ? selectedProcess.map((p: Process) => p.id) : undefined;
    const status = this.searchForm.get('filter')?.value ?? undefined;
    const startDate = this.searchForm.get('startDate')?.value ? this.searchForm.get('startDate')?.value.toISOString() : undefined;
    const endDate = this.searchForm.get('endDate')?.value ? this.searchForm.get('endDate')?.value.toISOString() : undefined;

    const filterData: ProcessInstanceMultipleFilter = {
      searchTerm: searchTerm,
      processesIds: processIds,
      status: status,
      startDate: startDate,
      endDate: endDate,
      pageNumber: 1,
      pageSize: 10
    };
    
    this.processInstanceFacade.dispatchSetProcessInstanceMultipleFilter(filterData)
    this.processInstanceFacade.dispatchGetProcessInstancesByFilter(
      filterData
    );
    this.processInstanceFacade.dispatchResetProcessInstanceFilter(false)
  }
  onSerchTermChange() {
    if (!this.showSearchTermAndFiltersData) {
      this.processInstanceFacade.dispachToggleSearchTermVisibility(true);
    }
    this.searchForm
      .get('searchTerm')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.getProcessInstances();
      });
  }

  onProcessFilterSelect(event: MatSelectChange) {
    if (event.value === undefined) {
      return;
    }
    this.getProcessInstances()

  }

  handleDateRangeChange() {
    const startDate = this.searchForm.get('startDate')?.value;
    const endDate = this.searchForm.get('endDate')?.value;

    if (startDate && endDate) {
      this.getProcessInstances()
    }
  }

  resetFilter() {
    this.searchForm.reset();

    const filterData: ProcessInstanceMultipleFilter = {
      searchTerm: undefined,
      processesIds: undefined,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      pageNumber: 1,
      pageSize: 10
    };
    this.processInstanceFacade.dispatchSetProcessInstanceMultipleFilter(filterData)
    this.processInstanceFacade.dispatchGetProcessInstances(1,10);
    this.processInstanceFacade.dispatchResetProcessInstanceFilter(true)
  }

  isResetFilterDisabled() {
    return (
      !this.searchForm.get('startDate')?.value &&
      !this.searchForm.get('endDate')?.value &&
      !this.searchForm.get('searchTerm')?.value &&
      (this.searchForm.get('processTypes')?.value?.length == 0 || !this.searchForm.get('processTypes')?.value) &&
      !this.searchForm.get('filter')?.value
    );
  }
}
