<mat-dialog-content *appHasPermission="hasUpdateOfficeTreePermission()">
  <form class="parent-form" [formGroup]="parentForm">
    <div class="chip-alignment">
      <button
        mat-raised-button
        (click)="openSingleOffice()"
        i18n="@@offices.update-office-structure-form.parent-office"
        [attr.data-test-id]="
          'offices.update-office-structure.update-office-structure-form.parent-office-button'
        "
      >
        Parent office
      </button>
      <mat-chip-row
        class="chip"
        *ngIf="selectedFlatOfficeNode"
        (removed)="removeSelectedFlatOfficeNode()"
        [editable]="false"
      >
        {{ selectedFlatOfficeNode.name }}
        <button
          matChipRemove
          [attr.aria-label]="'remove ' + selectedFlatOfficeNode.name"
          [attr.data-test-id]="
            'offices.update-office-structure.update-office-structure-form.remove-button'
          "
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>
    <mat-form-field appearance="outline" class="path-update-select">
      <mat-label
        i18n="@@offices.update-office-structure-form.path-update-tree"
        [attr.data-test-id]="
          'offices.update-office-structure.update-office-structure-form.path-update-type-field'
        "
        >Path Update Type</mat-label
      >
      <mat-select formControlName="pathUpdateType" class="path-update-menu">
        <mat-option
          *ngFor="let type of pathUpdateTypesForDisplay | keyvalue"
          [value]="type.key"
          [attr.data-test-id]="
            'offices.update-office-structure.update-office-structure-form.path-update-type-options'
          "
        >
          {{ type.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action-buttons">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!parentForm.valid || this.selectedFlatOfficeNode === null"
    mat-dialog-close="save"
    i18n="@@offices.update-office-structure-form.save"
    [attr.data-test-id]="'offices.update-office-structure.update-office-structure-form.save-button'"
  >
    Save
  </button>
  <button
    class="submit"
    mat-flat-button
    color="accent"
    mat-dialog-close
    i18n="@@offices.update-office-structure-form.cancel"
  >
    Cancel
  </button>
</mat-dialog-actions>
