import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelUploadReport } from '../../models/excel-upload-report.model';
import { AngularGridInstance, Column, GridOption } from 'angular-slickgrid';

@Component({
  selector: 'app-excel-upload-report-dialog',
  templateUrl: './excel-upload-report-dialog.component.html',
  styleUrls: ['./excel-upload-report-dialog.component.scss'],
})
export class ExcelUploadReportDialogComponent implements OnInit {
  columnDefinitions: Column[] = [];
  gridOptions!: GridOption;
  angularGrid!: AngularGridInstance;

  constructor(
    public dialogRef: MatDialogRef<ExcelUploadReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public reports: ExcelUploadReport[],
  ) {}
  ngOnInit(): void {
    this.prepareGrid();
  }

  angularGridReady(event: Event) {
    const angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.angularGrid = angularGrid;
  }

  prepareGrid() {
    this.columnDefinitions = [
      {
        id: 'excelRow',
        name: 'Row',
        field: 'excelRow',
        sortable: false,
      },
      {
        id: 'success',
        name: 'Success',
        field: 'success',
        sortable: false,
      },
      {
        id: 'message',
        name: 'Message',
        field: 'message',
        sortable: false,
      },
      {
        id: 'reportsToOfficesRegisterLog',
        name: 'Reports To Offices Log',
        field: 'reportsToOfficesRegisterLog',
        sortable: false,
      },
    ];

    this.gridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableGrouping: true,
      gridHeight: 300,
      gridWidth: '96%',
      enableCellNavigation: true,
      enableRowSelection: true,
      editable: false,
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      enableGridMenu: false,
      enableHeaderMenu: false,
      enableContextMenu: false,
      enableCellMenu: false,

      // disable the default resizing option
      autoFitColumnsOnFirstLoad: false,
      enableAutoSizeColumns: false,

      // enable resize by content
      autosizeColumnsByCellContentOnFirstLoad: true,
      enableAutoResizeColumnsByCellContent: true,

      resizeByContentOptions: {
        cellCharWidthInPx: 11,
      },

      enableFiltering: false,
    };

    this.reports = this.reports.map((item, index) => ({
      ...item,
      id: `row-${index}`,
    }));
  }
}
