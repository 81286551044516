import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactForm, UpdateContactUsPriorityDto, UpdateContactUsStatusDto } from '../models/contact-form.model';
import { CONTACT_US_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(private http: HttpClient) {}

  createContactUs(contactForm: ContactForm): Observable<ContactForm> {
    const formData = new FormData();
    formData.append('subject', contactForm.subject);
    formData.append('description', contactForm.description);
    for (const file of contactForm.attachments) {
      formData.append('attachments', file);
    }
    return this.http.post<ContactForm>(`${CONTACT_US_URL}`, formData);
  }

  getPaginatedContactUs(pageNumber: number, pageSize: number): Observable<PaginatedList<ContactForm>> {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);

    return this.http.get<PaginatedList<ContactForm>>(`${CONTACT_US_URL}`, {
      params,
    });
  }

  getMyPaginatedContacts(pageNumber: number, pageSize: number): Observable<PaginatedList<ContactForm>> {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);

    return this.http.get<PaginatedList<ContactForm>>(`${CONTACT_US_URL}/my-contact`, {
      params,
    });
  }

  getContactUsDetail(id: String): Observable<ContactForm> {
    return this.http.get<ContactForm>(`${CONTACT_US_URL}/${id}`);
  }

  updateContactUsStatus(updatedContactUs: UpdateContactUsStatusDto): Observable<ContactForm> {
    return this.http.patch<ContactForm>(`${CONTACT_US_URL}/change-status`, updatedContactUs);
  }

  updateContactUsPriority(updatedContactUs: UpdateContactUsPriorityDto): Observable<ContactForm> {
    return this.http.patch<ContactForm>(`${CONTACT_US_URL}/change-priority`, updatedContactUs);
  }

  getAttachment(contactId: string, attachmentId: string): Observable<any> {
    return this.http.get(
      `${CONTACT_US_URL}/${contactId}/attachments/${attachmentId}`,
      { responseType: 'blob' },
    );
  }
}
