<div class="header">
  <h1 i18n="@@documents.reported-comments-report.reported-comments">Reported Comments</h1>
  <form [formGroup]="filterForm" class="filter-form-container">
    <mat-form-field appearance="outline" class="workflow-type-selector">
      <mat-label i18n="@@documents.reported-comments-report.workflow-type">Workflow Type</mat-label>
      <mat-select
        formControlName="workflowType"
        (selectionChange)="applyFilter()"
      >
        <mat-option [value]="null" i18n="@@documents.reported-comments-report.all">All</mat-option>
        <mat-option *ngFor="let type of workflowTypes" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="search-name-input">
      <mat-label i18n="@@documents.reported-comments-report.search-by-initiator">Search by Initiator</mat-label>
      <input
        matInput
        formControlName="searchName"
        placeholder="Search"
        (input)="applyFilter()"
      />
    </mat-form-field>
  </form>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedReportedCommentsReport($event)"
  >
  </mat-paginator>
  <mat-table [dataSource]="reportedCommentsDataSource" class="table">
    <ng-container matColumnDef="commenter">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.reported-comments-report.commenter">Commenter</mat-header-cell>
      <mat-cell *matCellDef="let report">{{
        report?.commenterFullName
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.reported-comments-report.comment">Comment</mat-header-cell>
      <mat-cell *matCellDef="let report">{{ report?.comment }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="office">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.reported-comments-report.office">Office</mat-header-cell>
      <mat-cell *matCellDef="let report">{{ report?.officeName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="initiator">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.reported-comments-report.initiator">Initiator</mat-header-cell>
      <mat-cell *matCellDef="let report">{{
        report?.workflowInitiatorName
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="workflowType">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.reported-comments-report.workflow-type">Workflow Type</mat-header-cell>
      <mat-cell *matCellDef="let report">{{
        report?.workflowTypeName
      }}</mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="[
        'commenter',
        'comment',
        'office',
        'initiator',
        'workflowType'
      ]"
    ></mat-header-row>

    <mat-row
      class="table-row"
      *matRowDef="
        let file;
        columns: ['commenter', 'comment', 'office', 'initiator', 'workflowType']
      "
    ></mat-row>
  </mat-table>
</div>
