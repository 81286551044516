import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notice, NoticeAccessData, NoticeFilter } from '../models/notices.model';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { NOTICES_URL } from 'src/app/core/constants/api-endpoints';
@Injectable({
  providedIn: 'root',
})
export class NoticesService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}
  createNotice(notice: FormData, send: boolean) {
    return this.http.post<Notice>(`${NOTICES_URL}?Send=${send}`, notice);
  }
  getNotices(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Notice>> {
    return this.http.get<PaginatedList<Notice>>(
      `${NOTICES_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
  getNoticeDetail(noticeId: string): Observable<Notice> {
    return this.http.get<Notice>(`${NOTICES_URL}/${noticeId}`);
  }
  deleteNotice(noticeId: string): Observable<any> {
    return this.http.delete<any>(`${NOTICES_URL}/${noticeId}`);
  }
  deleteNoticeAttachment(
    noticeId: string,
    attachmentId: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${NOTICES_URL}/${noticeId}/attachments/${attachmentId}`,
    );
  }
  searchNotice(
    isDraft: boolean,
    isSent: boolean,
    isReceived: boolean,
    searchTerm?: string,
  ): Observable<PaginatedList<Notice>> {
    let url = `${NOTICES_URL}/search?`;
    if (searchTerm) url += `searchTerm=${searchTerm}&`;
    url += `isDraft=${isDraft}&isSent=${isSent}&isReceived=${isReceived}`;
    return this.http.get<PaginatedList<Notice>>(url, this.httpOptions);
  }
  updateNotice(
    noticeId: string,
    notice: FormData,
    send: boolean,
  ): Observable<Notice> {
    return this.http.put<Notice>(
      `${NOTICES_URL}/${noticeId}?Send=${send}`,
      notice,
    );
  }
  getNoticeAccessData(noticeId: string): Observable<NoticeAccessData> {
    return this.http.get<any>(`${NOTICES_URL}/access-data/${noticeId}`);
  }
  getAttachment(noticeId: string, attachmentId: string): Observable<any> {
    return this.http.get(
      `${NOTICES_URL}/${noticeId}/attachments/${attachmentId}`,
      { responseType: 'blob' },
    );
  }
  getNoticeFilters(
    ): Observable<NoticeFilter[]> {
      return this.http.get<NoticeFilter[]>(
        `${NOTICES_URL}/notice-filters`,
      );
    }

  getNoticeByStatus(
      status: any,
      pageNumber: number,
      pageSize: number,
    ): Observable<PaginatedList<Notice>> {
      const options = new HttpParams({
        fromObject: { status, pageSize, pageNumber },
      });
      return this.http.get<PaginatedList<Notice>>(
        `${NOTICES_URL}/by-status`,
        {
          params: options,
        },
      );
    }

    searchNoticesByDate(
      email: string,
      startDate: string,
      endDate: string,
      isDraft: boolean,
      isSent: boolean,
      isReceived: boolean,
      pageNumber: number,
      pageSize: number
    ): Observable<PaginatedList<Notice>> {
      const url = `${NOTICES_URL}/by-date?email=${email}&startDate=${startDate}&endDate=${endDate}&isDraft=${isDraft}&isSent=${isSent}&isReceived=${isReceived}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      return this.http.get<PaginatedList<Notice>>(url, this.httpOptions);
    }
}
