<div class="main-continier">
  <h1 *ngIf="!parentId" i18n="@@documents.memo-form.create-memo" class="mat-h1">
    Create Memo
  </h1>
  <h1 *ngIf="parentId" class="mat-h1">
    <span i18n="@@documents.memo-form.replying-to">Replying to:</span>
    {{ selectedMemo?.title }}
  </h1>
  <div>
    <button
      mat-raised-button
      (click)="openMultipleOfficeChoice()"
      color="primary"
      i18n="@@documents.memo-form.select-offices"
      data-test-id="select-offices-button"
    >
      Select offices
    </button>
  </div>
  <form class="memo-form" [formGroup]="memoForm">
    <div class="first-row">
      <div>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@documents.memo-form.role">Role</mat-label>
          <mat-select
            formControlName="selectedRoles"
            multiple
            (selectionChange)="onRoleSelect()"
            [compareWith]="compareFn"
            data-test-id="role-select"
          >
            <mat-option
              *ngFor="let role of roles"
              [value]="role"
              [attr.data-test-id]="'role-option' + role.id"
              >{{ role.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label i18n="@@documents.memo-form.users">Users</mat-label>
          <mat-select
            formControlName="selectedUsers"
            multiple
            [compareWith]="compareFn"
            data-test-id="user-select"
          >
            <mat-option
              *ngFor="let user of users"
              [value]="user"
              [attr.data-test-id]="'user-option' + user.id"
              >{{ user.firstName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="sec-row">
      <div>
        <p i18n="@@documents.memo-form.selected-offices">Selected offices</p>
        <mat-chip
          *ngFor="let office of selectedFlatOfficeNodes"
          (removed)="removeSelectedOffice(office)"
        >
          {{ office.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>

      <div>
        <p i18n="@@documents.memo-form.selected-roles">Selected roles</p>
        <mat-chip
          *ngFor="let role of memoForm.value.selectedRoles"
          (removed)="removeSelectedRole(role)"
        >
          {{ role.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>

      <div>
        <p i18n="@@documents.memo-form.selected-users">Selected users</p>
        <mat-chip
          *ngFor="let user of memoForm.value.selectedUsers"
          (removed)="removeSelectedUser(user)"
        >
          {{ user.firstName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </div>
    </div>

    <div>
      @if(!parentId){
        <mat-form-field class="title" appearance="outline">
          <mat-label i18n="@@documents.memo-form.title">Title</mat-label>
          <input
            id="title"
            formControlName="title"
            type="text"
            matInput
            class="full-width-input"
            [placeholder]="placeholderToggleLabel.memoTitle"
          />
          <mat-error
            *ngIf="memoForm.get('title')?.hasError('required')"
            i18n="@@documents.memo-form.please-provide-a-valid-title"
          >
            Please provide a valid title
          </mat-error>
        </mat-form-field>
      }
    </div>
    <div class="editor--wrapper">
      <div id="editor"></div>
    </div>

    <div class="w-100">
      <div
        class="drop-zone flex-center"
        [class.dragover]="isDragOver"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <ng-container *ngIf="files.length === 0">
          <p i18n="@@documents.memo-form.drag-and-drop-files-here">
            Drag and drop files here
          </p>
        </ng-container>
        <div *ngFor="let file of files">
          <div class="flex-center">
            <p>{{ file.name }}</p>
            <mat-icon class="pointer" color="warn" (click)="removeFile(file)"
              >close</mat-icon
            >
          </div>
        </div>
        <button
          mat-flat-button
          color="primary"
          (click)="selectFile()"
          i18n="@@documents.memo-form.select-files"
        >
          Select Files
        </button>
      </div>

      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaveAndSendButtonDisabled()"
        (click)="createMemo()"
        class="mt-15 ml-15"
        i18n="@@documents.memo-form.save"
        data-test-id="save-button"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSaveAndSendButtonDisabled()"
        (click)="sendMemo()"
        class="mt-15 ml-15"
        i18n="@@documents.memo-form.send"
      >
        Send
      </button>
    </div>
  </form>
</div>

<div *ngIf="update === 'true'" class="margin-left">
  <ng-container *ngIf="selectedMemo?.attachments?.length; else emptyFilesList">
    <div class="mx-5">
      <h2 i18n="@@documents.memo-form.files">Files</h2>
      <mat-card
        *ngFor="let attachment of selectedMemo?.attachments"
        class="custom-mat-card"
      >
        <mat-card-content class="flex justify-between">
          <p class="mx-2">{{ attachment.name }}</p>
          <div>
            <mat-icon
              (click)="deleteAttachment(attachment.id)"
              class="cursor-pointer mx-2"
              >delete</mat-icon
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <ng-template #emptyFilesList>
    <div class="mx-5">
      <h2 i18n="@@documents.memo-form.files">Files</h2>
    </div>
    <div class="empty-notices-list">
      <span class="text-2xl" i18n="@@documents.memo-form.no-file-attached"
        >No files attached</span
      >
    </div>
  </ng-template>
</div>
