import { Component, Input, OnInit } from '@angular/core';
import { WorkflowValidationFacade } from '../../facade/workflow-validation.facades';
import { RxState } from '@rx-angular/state';
import { WorkflowStepValidation } from '../../models/worlfow-step-validation.model';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowStepValidationComponent } from '../workflow-step-validation/workflow-step-validation.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { WorkflowTypeStepFormComponent } from 'src/app/documents/workflow-type-steps/components/workflow-type-step-form/workflow-type-step-form.component';
import { WorkflowTypeStepDetail } from 'src/app/documents/workflow-type-steps/models/workflow-type-steps.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface State {
  validations: WorkflowStepValidation[];
}

const initState: State = {
  validations: [],
};

@Component({
  selector: 'app-workflow-step-validations',
  templateUrl: './workflow-step-validations.component.html',
  styleUrl: './workflow-step-validations.component.scss',
})
export class WorkflowStepValidationsComponent implements OnInit {
  @Input() workflowTypeStep!: WorkflowTypeStepDetail;
  validations$ = this.state.select('validations');

  constructor(
    private workflowStepFacade: WorkflowValidationFacade,
    private state: RxState<State>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.state.set(initState);
  }

  ngOnInit(): void {
    this.state.connect(
      'validations',
      this.workflowStepFacade.workflowStepValidations$,
    );
    this.workflowStepFacade.dispatchGetWorkflowStepValidations(
      this.route.snapshot.params['id'],
    );
  }

  updateValidation(validation: WorkflowStepValidation) {
    this.dialog.open(WorkflowStepValidationComponent, {
      ...PROCESS_FORM_SIDE_DIALOG_CONFIG,
      data: {
        workflowStep: this.workflowTypeStep,
        selectedValidation: validation,
      },
    });
  }

  deleteValidation(validation: WorkflowStepValidation) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`:@@documents.workflow-type-step-list.delete-workflow-step-validation-title:Delete Workflow Type Step`,
        regularTextOne: $localize`:@@documents.workflow-type-step-list.delete-workflow-step-validation-description:Are you sure you want to delete workflow step validation?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowStepFacade.dispatchDeleteWorkflowStepValidation(
          validation.id,
        );
      }
    });
  }

  hasDeleteWorflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.DeleteWorkflowStepValidation,
    );
  }

  hasUpdateWorflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.UpdateWorkflowStepValidation,
    );
  }
}
