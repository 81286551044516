<mat-dialog-content mat-dialog-content class="form-container">
  <form class="activity-form" [formGroup]="activityForm" (ngSubmit)="save()">
    <h3>{{ formTitle }}</h3>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.create-activity-type.activity-type-name">Activity Type Name: </mat-label>
      <input
        type="text"
        id="name"
        formControlName="name"
        matInput
        (keydown)="sanitizerService.spaceSanitizer($event, activityForm.get('name')?.value ?? '')"
        class="full-width-input"
      />
      <mat-error *ngIf="activityForm.get('name')!.hasError('required')"
      i18n="@@researches.create-activity-type.please-enter-activity-type-name">
        Please enter activity type name
      </mat-error>
    </mat-form-field>
    <div mat-dialog-actions class="action-btn">
      <button mat-button mat-dialog-close i18n="@@researches.create-activity-type.cancel">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="!activityForm.valid" [attr.data-test-id]="'researches-create-activity-type-create-activity-type-createbutton'">
        {{ saveButtonText }}
      </button>
    </div>
  </form>
</mat-dialog-content>
