import { RxState } from '@rx-angular/state';
import { WorkflowFacade } from './../../../workflows/facade/workflow.facade';
import {
  Component,
  DestroyRef,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Workflow } from 'src/app/documents/workflows/models/workflow.model';
import { WorkflowStepFacade } from '../../facade/workflow-step.facade';
import {
  DocumentField,
  UpdateWorkflowStep,
  WorkflowStep,
  WorkflowStepFile,
  WorkflowStepSimple,
} from '../../models/workflow-step.model';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { WorkflowStepAction } from 'src/app/core/constants/requirement_constants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { CurrentLoggedInUser, User } from 'src/app/users/models/user.model';
import { filter, Observable, combineLatest, take } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Role } from 'src/app/users/models/role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { WORKFLOWS_ROUTE } from 'src/app/core/constants/routes';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { DocumentFieldBase } from '../../models/document-field-base';
import { DocumentFieldControlService } from '../../../../core/services/Document-field-control/document-field-control-service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormFieldsWithValiation } from 'src/app/documents/template-fields/models/form-fields.model';
import { WorkflowStepValidation } from 'src/app/documents/workflow-validation/models/worlfow-step-validation.model';
import { WorkflowValidationFacade } from '../../../workflow-validation/facade/workflow-validation.facades';
import { CurrencyFacade } from 'src/app/researches/facades/currency.facade';
import {
  faFile,
  faFileAudio,
  faFileExcel,
  faFileLines,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialogWithOneOptionComponent } from 'src/app/shared/shared-components/confirm-dialog-with-one-option/confirm-dialog-with-one-option.component';

export type DocumentFieldForm = FormGroup<{
  id: FormControl<string | null>;
  fieldValue: FormControl<string | null>;
  templateField: FormControl<FormFieldsWithValiation>;
  groupProperties: FormControl<{
    groupName: string;
    row: number;
    column: number;
  }>;
}>;

interface WorkflowStepState {
  slectedWorkflowWorkflowSteps: WorkflowStepSimple[];
  officeUsers: PaginatedList<User>;
  roles: Role[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  validations: WorkflowStepValidation[];
  workflowStepDetail?: WorkflowStep;
  workflowStepFiles: PaginatedList<WorkflowStepFile>;
}

const initWorkflowStepState: WorkflowStepState = {
  slectedWorkflowWorkflowSteps: [],
  validations: [],
  officeUsers: {
    items: [],
    totalCount: 0,
    totalPages: 0,
    pageNumber: 0,
  },

  workflowStepFiles: {
    items: [],
    totalCount: 0,
    totalPages: 0,
    pageNumber: 0,
  },

  roles: [],
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-workflow-step',
  templateUrl: './workflow-step.component.html',
  styleUrls: ['./workflow-step.component.scss'],
})
export class WorkflowStepComponent {
  selectedWorkflow$ = this.workflowFacade.selectedWorkflow$;
  selectedWorkflow: Workflow | undefined;
  workflowStep$ = this.workflowStepFacade.workflowStep$;

  workflowStep: WorkflowStep | undefined;

  slectedWorkflowWorkflowSteps$ = this.workflowStepState.select(
    'slectedWorkflowWorkflowSteps',
  );
  officeUsers$ = this.workflowStepState.select('officeUsers');
  officeUsers: PaginatedList<User> = {
    items: [],
    totalCount: 0,
    totalPages: 0,
    pageNumber: 0,
  };
  roles$ = this.workflowStepState.select('roles');
  roles: Role[] = [];
  slectedWorkflowWorkflowSteps: WorkflowStepSimple[] = [];
  defaultStepIndex: number = 0;
  comment: any;
  ccManagement: any;

  assignUserClicked: boolean = false;
  toggleButton: string = $localize`:@@documents.workflow-step.change-user: Change user`;
  filteredOfficeUsers: User[] = [];

  hasStartAndEndDateValidator: boolean = false;

  assigneeForm = this.fb.group({
    roles: [[]],
    assignee: ['', Validators.required],
  });

  documentFieldForm!: FormGroup;
  documentFieldTableForm!: FormGroup;
  documentFields: DocumentFieldBase<string>[] = [];
  documentFieldTable: DocumentFieldBase<string>[] = [];
  documentFieldTableTitle: string[] = [];
  documentFieldTableName: string | null = null;
  duplicateFieldCounter: number = 0;
  isSideContentVisible = false;
  clusteredFields: DocumentFieldBase<string>[][] = [];

  @ViewChild('assigneeInput') assigneeInput!: ElementRef<HTMLInputElement>;

  placeholderFieldLabel = {
    selectUserField: $localize`:@@documents.workflow-step.select-user: Select user`,
  };

  currentLoggedInUser$ = this.workflowStepState.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  form = this.fb.group({
    documentFields: this.fb.array<DocumentFieldForm>([]),
    tableDocumentFields: this.fb.array<FormArray<DocumentFieldForm>>([]),
  });

  formTableRow = this.fb.array<DocumentFieldForm>([]);
  formTableHeaders: string[] = [];

  stepHasTableFields: boolean = false;
  isStepAssigned: boolean = false;
  stepValidations$ = this.workflowStepState.select('validations');
  isWorkflowArchived: boolean = false;
  selectedFile: File | null = null;
  fileIcons: any = {
    doc: faFileWord,
    docx: faFileWord,
    pdf: faFilePdf,
    png: faImage,
    jpg: faImage,
    file: faFile,
    xlsx: faFileExcel,
    xls: faFileExcel,
    txt: faFileLines,
    mp4: faFileVideo,
    ogg: faFileVideo,
    webm: faFileVideo,
    mp3: faFileAudio,
    m4a: faFileAudio,
    wav: faFileAudio,
  };

  workflowStepDetail$ = this.workflowStepState.select('workflowStepDetail');
  workflowStepFiles$ = this.workflowStepState.select('workflowStepFiles');
  workflowStepFiles: PaginatedList<WorkflowStepFile> = {
    items: [],
    totalCount: 0,
    totalPages: 0,
    pageNumber: 0,
  };

  constructor(
    private documentFieldService: DocumentFieldControlService,
    private workflowStepState: RxState<WorkflowStepState>,
    private workflowFacade: WorkflowFacade,
    private workflowStepFacade: WorkflowStepFacade,
    private workflowStepValidationFacade: WorkflowValidationFacade,
    public sanitizerService: SanitizerService,
    private userFacade: UserFacade,
    private dialog: MatDialog,
    private fb: NonNullableFormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private currencyFacade: CurrencyFacade,
    private destroyRef: DestroyRef,
  ) {
    this.workflowStepState.set(initWorkflowStepState);
    this.workflowStepState.connect(
      'slectedWorkflowWorkflowSteps',
      this.workflowStepFacade.slectedWorkflowWorkflowSteps$,
    );
    this.workflowStepState.connect(
      'officeUsers',
      this.userFacade.officeUsers$ as unknown as Observable<
        PaginatedList<User>
      >,
    );

    this.workflowStepState.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.workflowStepState.connect(
      'validations',
      this.workflowStepValidationFacade.workflowStepValidations$,
    );
    this.isWorkflowArchived = this.route.snapshot.queryParams['isArchived'];

    this.workflowStepState.connect(
      'workflowStepDetail',
      this.workflowStepFacade.workflowStepDetails$,
    );

    this.workflowStepState.connect(
      'workflowStepFiles',
      this.workflowStepFacade.workflowStepFiles$,
    );
  }
  @ViewChildren('inputFields') inputFields: QueryList<ElementRef> | undefined;

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.isWorkflowArchived = !!params['isArchived'];
      });

    this.currencyFacade.dispatchGetCurrencies();
    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
    if (!this.currentLoggedInUser?.officeId) {
      this.userFacade.dispatchGetUsersByOfficeId();
    }
    combineLatest([this.selectedWorkflow$, this.route.queryParams])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([workflow, params]) => {
        if (!workflow) return;
        if (!workflow.id) return;
        this.selectedWorkflow = workflow;
        if (params['isArchived']) {
          this.workflowStepFacade.dispatchGetArchivedWorkflowStep(workflow.id);
          this.workflowStepFacade.dispatchGetArchivedWorkflowSteps(workflow.id);
        } else {
          this.workflowStepFacade.dispatchCurrentWorkflowStep(workflow.id);
          this.workflowStepFacade.dispatchGetWorkflowSteps(workflow.id);
        }
      });
    this.workflowStep$.subscribe((result) => {
      this.workflowStep = result;
      this.hasStartAndEndDateValidator = this.checkStartAndEndDateValidator()
      if (!this.workflowStep?.assignee?.fullName) {
        this.toggleButton = $localize`:@@documents.workflow-step.assign-user: Assign user`;
      }

      this.defaultStepIndex = this.slectedWorkflowWorkflowSteps.findIndex(
        (step) => step.id == this.workflowStep?.id,
      );


      if (!this.isWorkflowArchived && result.workflowTypeStep?.id) {
        this.workflowStepValidationFacade.dispatchGetWorkflowStepValidations(
          result.workflowTypeStep.id,
        );
      }

      if (this.workflowStep?.assignee !== null) {
        this.isStepAssigned =
          this.workflowStep?.assignee.id === this.currentLoggedInUser?.id;
      } else {
        this.isStepAssigned = false;
      }
    });

    combineLatest([this.workflowStep$, this.stepValidations$]).subscribe(
      ([_, validations]) => this.buildFormGroup(validations),
    );

    this.slectedWorkflowWorkflowSteps$.subscribe((result) => {
      this.slectedWorkflowWorkflowSteps = result;
      this.defaultStepIndex = result.findIndex(
        (step) => step.id == this.workflowStep?.id,
      );
    });

    this.officeUsers$.subscribe((users) => {
      if (Array.isArray(users)) {
        this.officeUsers = {
          items: users,
          totalCount: users.length,
          totalPages: 1,
          pageNumber: 1,
        };
      } else {
        this.officeUsers = users;
      }
      this.filteredOfficeUsers = users.items?.filter(
        (user) => user.id != this.selectedWorkflow?.initiator?.id,
      );
    });
    this.assigneeForm.controls.assignee.valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredOfficeUsers = this.filterUser(query);
      });

    this.comment = $localize`:@@documents.workflow-step.comment: Comment`;
    this.ccManagement = $localize`:@@documents.workflow-step.cc-management: CC Management`;

    this.workflowStepFacade.dispatchGetWorkflowStepFiles(
      this.selectedWorkflow?.currentStep?.id as string,
    );
    this.workflowStepFiles$.subscribe((files) => {
      console.log('fffffff', files);
      this.workflowStepFiles = files;
    });
  }

  toggleSideContent() {
    this.isSideContentVisible = !this.isSideContentVisible;
  }

  documentFieldToForm(field: DocumentField) {
    const defaultValue =
      field.templateField.dataType === 'Date'
        ? new Date().toISOString().split('T')[0]
        : field.fieldValue;
    return this.fb.group({
      id: this.fb.control(field.id as string | null, []),
      fieldValue: this.fb.control(
        field.fieldValue || defaultValue,
        this.documentFieldService.getValidators(
          field.templateField.fieldValidationRules,
        ),
      ),
      templateField: this.fb.control(field.templateField, []),
      groupProperties: this.fb.control(
        {
          groupName: field.templateField.groupName,
          row: field.groupProperties.row,
          column: field.groupProperties.column,
        },
        [],
      ),
    });
  }

  buildFormGroup(validations: WorkflowStepValidation[]) {
    if (this.workflowStep) {
      this.form.controls.documentFields.clear();
      this.form.controls.tableDocumentFields.clear();
      this.form.clearValidators();

      this.formTableRow = this.fb.array<DocumentFieldForm>([]);

      this.workflowStep.document.documentFields
        .filter((field) => !field.templateField.groupName)
        .forEach((field) => {
          const documentField = this.documentFieldToForm(field);
          this.form.controls.documentFields.push(documentField);
        });

      const tableFields = this.workflowStep.document.documentFields
        .filter((field) => !!field.templateField.groupName)
        .sort((a, b) => a.templateField.order - b.templateField.order);

      if (tableFields.length > 0) {
        this.stepHasTableFields = true;
      } else {
        this.stepHasTableFields = false;
      }

      const table = Array.from(
        this.groupBy(tableFields, (field) => field.groupProperties.row),
        ([key, value]) => {
          return { key, value };
        },
      );

      this.formTableHeaders = table
        .at(0)
        ?.value.getRawValue()
        .map((field) => field.templateField?.name)
        .filter((field) => !!field) as string[];

      table.forEach((element, index) => {
        this.form.controls.tableDocumentFields.push(element.value);
      });

      this.form.addValidators(
        this.documentFieldService.getStepValidators(validations),
      );
    }
  }

  isEditable(workflowStep: WorkflowStep | undefined): boolean {
    if (!workflowStep) return false;
    return (
      workflowStep.workflowStepAction === WorkflowStepAction.Draft ||
      workflowStep.workflowStepAction === WorkflowStepAction.Rollbacked ||
      workflowStep.workflowStepAction === WorkflowStepAction.Rejected
    );
  }

  isStepArchivable(workflowStep: WorkflowStepSimple | undefined): boolean {
    if (!workflowStep) return false;
    return workflowStep.workflowStepAction === WorkflowStepAction.Rejected;
  }

  isCurrentUserWorkflowAssigner(): boolean {
    if (!this.workflowStep) return false;
    return this.currentLoggedInUser?.roles.some(
      (role) => role.id == this.workflowStep?.workflowTypeStep?.ownerRoleId,
    ) as boolean;
  }

  archiveWorkflow(event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectedWorkflow?.id) {
      this.dialog.closeAll();
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: `Are you sure you want to archive this workflow?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowFacade.dispatchArchiveWorkflow(
          this.selectedWorkflow?.id as string,
        );
        this.dialog.closeAll();
        this.router.navigate([WORKFLOWS_ROUTE]);
      }
    });
  }

  removeRow() {
    if (this.form.controls.tableDocumentFields.length > 1)
      this.form.controls.tableDocumentFields.removeAt(-1);
  }
  onStepChange(event: StepperSelectionEvent) {
    const selectedWorkflowStep =
      this.slectedWorkflowWorkflowSteps[event.selectedIndex];
    selectedWorkflowStep &&
      this.workflowStepFacade.dispatchGetSelectedWorkflowStep(
        selectedWorkflowStep.id,
      );
    if (!this.workflowStep?.assignee?.fullName) {
      this.toggleButton = $localize`:@@documents.workflow-step.assign-user: Assign user`;
    }
  }

  addRow() {
    const newRow = this.fb.array<DocumentFieldForm>([]);

    this.form.controls.tableDocumentFields
      .getRawValue()
      .at(0)
      ?.forEach((field) => {
        const group: DocumentFieldForm = this.fb.group({
          id: this.fb.control(null as string | null, []),
          fieldValue: this.fb.control(
            '' as string | null,
            this.documentFieldService.getValidators(
              field.templateField.fieldValidationRules,
            ),
          ),
          templateField: this.fb.control(field.templateField, []),
          groupProperties: this.fb.control(
            {
              groupName: field.templateField.groupName,
              column: field.groupProperties.row,
              row: this.form.controls.tableDocumentFields.length,
            },
            [],
          ),
        });
        newRow.push(group);
      });

    this.form.controls.tableDocumentFields.push(newRow);
  }

  assignUser(workflowStep?: WorkflowStep) {
    if (!workflowStep) return;
    this.assignUserClicked = !this.assignUserClicked;
    if (this.assignUserClicked)
      this.toggleButton = $localize`:@@documents.workflow-step.cancel: Cancel`;
    else
      this.toggleButton = $localize`:@@documents.workflow-step.change-user: Change user`;
    if (this.assignUserClicked) {
      this.toggleButton = 'Cancel';
      if (workflowStep.workflowTypeStep?.ownerOfficeId) {
        this.userFacade.dispatchGetUsersByOfficeId(
          workflowStep.workflowTypeStep?.ownerOfficeId,
        );
        this.filteredOfficeUsers = this.officeUsers.items.filter(
          (user) => user.roleId !== workflowStep.workflowTypeStep?.ownerRoleId,
        );
      } else this.userFacade.dispatchGetUsers(1, 100);
    } else this.toggleButton = 'Change User';
  }

  selectUser(event: MatAutocompleteSelectedEvent) {
    this.workflowStepFacade.dispatchAssignUserToWorkflowStep(
      this.workflowStep?.id!,
      event.option.value.id,
      this.selectedWorkflow?.id as string,
    );
    const newAssignee = this.officeUsers.items.filter((user) => {
      return user.id === event.option.value.id;
    });
    const assigneeName = `${newAssignee[0].firstName} ${newAssignee[0].middleName} ${newAssignee[0].lastName}`;
    this.workflowStep!.assigneeName = assigneeName;
    this.assigneeForm.controls.assignee.setValue(assigneeName);
    this.assignUserClicked = false;
    this.toggleButton = $localize`:@@documents.workflow-step.change-user: Change user`;
  }

  private filterUser(query: string | null) {
    return this.officeUsers.items.filter((user) =>
      `${user.firstName} ${user.middleName} ${user.lastName}`
        .toLowerCase()
        .includes(query?.toLocaleLowerCase() || ''),
    );
  }

  draft() {
    const data = this.getValueFromFormGroup();
    if (!this.form.valid || !this.workflowStep) {
      this.form.markAllAsTouched();
      return;
    }

    data.workflowStepAction = WorkflowStepAction.Draft;
    var updateWorkflowStep = this.createUpdateWorkflowStep(data, null);
    this.workflowStepFacade.dispatchUpdateWorkflowStep(updateWorkflowStep);
  }

  getValueFromFormGroup(): WorkflowStep {
    const data = { ...this.workflowStep! };
    const { documentFields, tableDocumentFields } = this.form.getRawValue();
    data.document.documentFields = [
      ...tableDocumentFields.flat(),
      ...documentFields,
    ];
    return data;
  }

  submit() {
    const data = this.getValueFromFormGroup();
    if (!this.form.valid || !this.workflowStep) {
      this.form.updateValueAndValidity();
      return;
    }

    data.workflowStepAction = WorkflowStepAction.Submitted;
    var updateWorkflowStep = this.createUpdateWorkflowStep(data, null);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-step.approve-workflow-part-1:Are you sure you want to approve`,
        boldText: ` "${this.workflowStep!.name}" `,
        regularTextTwo: $localize`:@@documents.workflow-step.approve-workflow-part-2:workflow step?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'confirm') {
        this.workflowStepFacade.dispatchUpdateWorkflowStep(updateWorkflowStep);
        this.router.navigate([WORKFLOWS_ROUTE]);
      }
    });
  }

  rollback() {
    let fieldArray = this.workflowStep!.document.documentFields;
    this.inputFields!.toArray().forEach((inputElem, index) => {
      fieldArray[index].fieldValue = inputElem.nativeElement.value;
    });
    this.workflowStep!.workflowStepAction = WorkflowStepAction.Rollbacked;

    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        name: '',
        message: 'Change Request',
        regularTextOne: $localize`:@@documents.workflow-step.request-change-workflow-part-1:Are you sure you want to request change`,
        boldText: ` "${this.workflowStep?.name!}" `,
        regularTextTwo: $localize`:@@documents.workflow-step.approve-workflow-part-2:workflow step?`,
        extra: $localize`:@@documents.workflow-step.change-request-extra:Write your change requests.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        this.workflowStepFacade.dispatchUpdateWorkflowStepStatus(
          this.workflowStep?.id!,
          WorkflowStepAction.Rollbacked,
          result.comment,
        );
        this.router.navigate([WORKFLOWS_ROUTE]);
      }
    });
  }

  reject() {
    let fieldArray = this.workflowStep!.document.documentFields;
    this.inputFields!.toArray().forEach((inputElem, index) => {
      fieldArray[index].fieldValue = inputElem.nativeElement.value;
    });

    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        name: '',
        message: 'Change Request',
        regularTextOne: $localize`:@@documents.workflow-step.reject-workflow-part-1:Are you sure you want to reject`,
        boldText: ` "${this.workflowStep?.name!}" `,
        regularTextTwo: $localize`:@@documents.workflow-step.reject-workflow-part-2:workflow step?`,
        extra: $localize`:@@documents.workflow-step.reject-reason-extra:Write your rejection reason.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        this.workflowStep!.rejectionRemark = result.comment;
        const action = WorkflowStepAction.Rejected;
        this.workflowStepFacade.dispatchUpdateWorkflowStepStatus(
          this.workflowStep?.id!,
          action,
          result.comment,
          this.workflowStep!.rejectionRemark,
        );
        this.router.navigate([WORKFLOWS_ROUTE]);
      }
    });
  }

  toggleRejectionReason() {
    const dialogRef = this.dialog.open(ConfirmDialogWithOneOptionComponent, {
      data: {
        name: '',
        title: $localize`:@@documents.workflow-step.rejection-reason-title:Rejection Reason`,
        regularTextOne: this.workflowStep?.rejectionRemark,
      },
    });
  }

  downloadWorkflowStep(event: MouseEvent) {
    this.workflowStepFacade.dispatchDownloadWorkflowStep(
      this.workflowStep?.id!,
      this.workflowStep?.name!,
    );
  }

  createUpdateWorkflowStep(
    workflowStep: WorkflowStep,
    comment: string | null,
  ): UpdateWorkflowStep {
    return {
      id: workflowStep.id,
      name: workflowStep.name,
      description: workflowStep.description,
      order: workflowStep.order,
      document: workflowStep.document,
      workflowId: workflowStep.workflowId,
      workflowStepAction: workflowStep.workflowStepAction,
      comment: comment,
    };
  }

  isActionVisible() {
    const action = this.workflowStep?.workflowStepAction;
    if (
      action == 'Submitted' ||
      action == 'Rejected' ||
      action == 'Rollbacked'
    ) {
      return false;
    }
    return true;
  }

  hasAssignUserToWorkflowStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowStep.AssignUserToWorkflowStep,
    );
  }

  hasDownloadWorkflowStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowStep.DownloadWorkflowStep,
    );
  }

  isNextStepEnabled(index: number): boolean {
    return (
      this.slectedWorkflowWorkflowSteps[index].workflowStepAction ===
      'Submitted'
    );
  }
  hasGetNotifiedUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowStep.GetNotifiedUsers,
    );
  }

  getValidationErrorMessage(control: AbstractControl) {
    for (const key in control.errors) {
      if (control.errors[key]) return control.errors[key];
    }

    return null;
  }

  groupBy<P>(list: DocumentField[], keyGetter: (input: DocumentField) => P) {
    const map = new Map<P, FormArray<DocumentFieldForm>>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, this.fb.array([this.documentFieldToForm(item)]));
      } else {
        collection.push(this.documentFieldToForm(item));
      }
    });
    return map;
  }

  selectFile(event: any) {
    this.selectedFile = event.target.files[0];
    event.target.files[0] = null;
  }

  uploadFile() {
    if (this.selectedFile) {
      let dispatched = this.workflowStepFacade.dispatchUploadWorkflowStepFile(
        this.selectedFile,
        this.selectedWorkflow?.currentStep?.id as string,
      );

      dispatched.subscribe(() => {
        this.selectedFile = null;
        this.workflowStepFacade.dispatchGetWorkflowStepFiles(
          this.selectedWorkflow?.currentStep?.id as string,
        );
      });
    }
  }

  extractFileNameAndExtension(fileName: string): any {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex !== -1) {
      const name = fileName.substring(0, lastIndex);
      const extension = fileName.substring(lastIndex! + 1);
      return { name, extension };
    } else {
      return { name: fileName, extension: '' };
    }
  }

  removeSelectedFile() {
    this.selectedFile = null;
  }

  getIconForFile(fileName: string): any {
    const extension = fileName.split('.').slice(-1)[0].toLowerCase();
    const iconInfo = this.fileIcons[extension];
    return iconInfo ? iconInfo : this.fileIcons.file;
  }

  removeWorkflowStepFile(file: any) {
    this.openConfirmationDialog(file);
    this.workflowStepFacade.dispatchGetWorkflowStepFiles(
      this.selectedWorkflow?.currentStep?.id as string,
    );
  }

  openConfirmationDialog(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-step.delete-file-message: Are you sure you want to delete`,
        boldText: ` "${file?.name}"? `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowStepFacade.dispatchDeleteWorkflowStepFile(file.id);
      }
    });
  }

  previewWorkflowStepFile(fileId: string, fileName: string) {
    this.workflowStepFacade.dispatchPreviewWorkflowStepFile(fileId, fileName);
  }

  get isChangeRequestVisible() {
    return (
      this.workflowStep?.workflowStepAction === 'Rejected' ||
      this.workflowStep?.workflowStepAction === 'Rollbacked'
    );
  }

  checkStartAndEndDateValidator(): boolean {

    const hasRange = this.workflowStep?.document.documentFields.some((field)=>field.templateField.fieldValidationRules.some((rule)=>rule.validationRule.id == "e6b3b510-4d2a-4b5d-a5d6-341e4a1e6de4"))

    return !!hasRange;
  }
}
