<mat-card class="card ml-4" appearance="outlined">
  <mat-card-header>
    <div class="rounded" mat-card-avatar>
      <span>{{ comment?.fullName && comment?.fullName?.substring(0, 1) }}</span>
    </div>
    <mat-card-title>
      <span>{{ comment?.fullName }}</span>
    </mat-card-title>
    <mat-card-subtitle>
      <span class="mat-caption">{{ comment?.created | date: "medium" }}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="my-auto flex-col">
    <div class="mat-body">
      {{ comment?.content }}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="flex justify-end mt-4">
      <ng-container *appHasPermission="hasUpdateReplyTaskCommentPermission()">
    <span *ngIf="currentLoggedInUser?.id === comment?.createdBy">
      <button
        class="hover-effect mr-4"
        mat-button
        color="primary"
        (click)="editReplyComment()"
        *ngIf="!processInstanceDetail?.isArchived"
      >
        <mat-icon>edit</mat-icon>
        <span i18n="@@researches.task-reply-comment.edit">Edit</span>
      </button>
    </span>
    </ng-container>
    <ng-container *appHasPermission="hasUpdateReplyTaskCommentPermission()">
      <button
        class="hover-effect mr-4"
        mat-button
        color="warn"
        (click)="openDeleteConfirmationDialog($event, taskComment, comment)"
        *ngIf="!processInstanceDetail?.isArchived"
      >
        <mat-icon>delete</mat-icon>
        <span i18n="@@researches.task-reply-comment.delete">Delete</span>
      </button>
    </ng-container>


    </div>
  </mat-card-actions>
  <mat-card-footer>
    <div *ngIf="isEditReplyTaskComment">
      <form [formGroup]="replyForm" class="w-100">
        <div>
          <mat-form-field appearance="outline" class="padding-vert w-full">
            <textarea
              id="name"
              type="text"
              formControlName="reply"
              #input
              maxlength="1000"
              matInput
              [placeholder]="placeholderToggleLabel.replyingTo"
            ></textarea>
            <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
          </mat-form-field>
        </div>
        <div class="flex justify-end mt-10">
          <button
            class="hover-effect"
            mat-button

            color="warn"
            (click)="cancelReplyComment()"
            i18n="@@researches.task-reply-comment.cancel"
          >
            Cancel
          </button>
          <button
            class="hover-effect"
            mat-button
            color="primary"
            [disabled]="!replyForm.valid"
            (click)="updateReplyComment()"
            i18n="@@researches.task-reply-comment.update"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </mat-card-footer>
</mat-card>
