import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WorkflowTypesSelector } from '../store/workflow-types.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  CreateWorkflowTypes,
  WorkflowTypes,
} from '../models/workflow-types.model';
import {
  CreateWorkflowType,
  GetWorkflowTypes,
  SetSelectedWorkflowType,
  SetUpdateStatus,
  UpdateWorkflowType,
  DeleteWorkflowType,
  GetOwnedWorkflowTypes,
  SearchWorkflowTypes,
  SetWorkflowTypeSearchingMode,
  GetWorkflowTypesWithSteps,
} from '../store/workflow-types.actions';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTypesFacade {
  @Select(WorkflowTypesSelector.workflowTypes)
  workflowTypes$!: Observable<PaginatedList<WorkflowTypes>>;

  @Select(WorkflowTypesSelector.workflowTypesWithSteps)
  workflowTypesWithSteps$! : Observable<PaginatedList<WorkflowTypes>>

  @Select(WorkflowTypesSelector.ownedWorkflowTypes)
  ownedWorkflowTypes$!: Observable<PaginatedList<WorkflowTypes>>;

  @Select(WorkflowTypesSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(WorkflowTypesSelector.selecteWorkflowType)
  selectedWorkflowType$!: Observable<WorkflowTypes>;

  @Select(WorkflowTypesSelector.update)
  update$!: Observable<boolean>;

  @Select(WorkflowTypesSelector.isSearchingWorkflowType)
  isSearchingWorkflowType$!: Observable<boolean>;

  constructor(private store: Store) {}

  dispatchGetWorkflowTypes(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetWorkflowTypes(pageNumber, pageSize));
  }

  dispatchGetWorkflowTypesWithSteps(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetWorkflowTypesWithSteps(pageNumber, pageSize));
  }

  dispatchGetOwnedWorkflowTypes(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetOwnedWorkflowTypes(pageNumber, pageSize));
  }

  dispatchCreateWorkflowType(workflowType: CreateWorkflowTypes) {
    this.store.dispatch(new CreateWorkflowType(workflowType));
  }

  dispatchUpdateWorkflowType(workflowType: CreateWorkflowTypes) {
    this.store.dispatch(new UpdateWorkflowType(workflowType));
  }

  dispatchSetSelectedWorkflowType(workflowType: WorkflowTypes) {
    this.store.dispatch(new SetSelectedWorkflowType(workflowType));
  }

  dispatchSetUpdateStatus(update: boolean) {
    this.store.dispatch(new SetUpdateStatus(update));
  }

  dispatchDeleteWorkflowType(workflowTypeId: string) {
    this.store.dispatch(new DeleteWorkflowType(workflowTypeId));
  }

  dispatchSearchWorkflowTypes(
    workflowTypeName: string,
    isOwned: boolean,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new SearchWorkflowTypes(workflowTypeName,isOwned, pageNumber, pageSize),
    );
  }

  dispatchSetWorkflowTypeSearchingMode(isSearchingWorkflowType: boolean) {
    this.store.dispatch(
      new SetWorkflowTypeSearchingMode(isSearchingWorkflowType),
    );
  }
}
