export interface Country {
  name: string;
  code: string;
  dialCode: string;
  length: number[];
}
export class PhoneCode {
  items = [
    { code: 'AD', name: 'Andorra', dialCode: '+376', length: [6] },
    { code: 'AE', name: 'United Arab Emirates', dialCode: '+971', length: [9] },
    { code: 'AF', name: 'Afghanistan', dialCode: '+93', length: [9] },
    {
      code: 'AG',
      name: 'Antigua and Barbuda',
      dialCode: '+1-268',
      length: [10],
    },
    { code: 'AI', name: 'Anguilla', dialCode: '+1-264', length: [10] },
    { code: 'AL', name: 'Albania', dialCode: '+355', length: [9] },
    { code: 'AM', name: 'Armenia', dialCode: '+374', length: [6] },
    { code: 'AO', name: 'Angola', dialCode: '+244', length: [9] },
    { code: 'AQ', name: 'Antarctica', dialCode: '+672', length: [6] },
    { code: 'AR', name: 'Argentina', dialCode: '+54', length: [6, 7, 8] },
    { code: 'AS', name: 'American Samoa', dialCode: '+1-684', length: [10] },
    { code: 'AT', name: 'Austria', dialCode: '+43', length: [10, 11] },
    {
      code: 'AU',
      name: 'Australia',
      dialCode: '+61',

      length: [9],
    },
    { code: 'AW', name: 'Aruba', dialCode: '+297', length: [7] },
    {
      code: 'AX',
      name: 'Alland Islands',
      dialCode: '+358',
      length: [7, 8, 9, 10],
    },
    { code: 'AZ', name: 'Azerbaijan', dialCode: '+994', length: [9] },
    {
      code: 'BA',
      name: 'Bosnia and Herzegovina',
      dialCode: '+387',
      length: [8],
    },
    { code: 'BB', name: 'Barbados', dialCode: '+1-246', length: [10] },
    { code: 'BD', name: 'Bangladesh', dialCode: '+880', length: [10] },
    { code: 'BE', name: 'Belgium', dialCode: '+32', length: [9] },
    { code: 'BF', name: 'Burkina Faso', dialCode: '+226', length: [8] },
    { code: 'BG', name: 'Bulgaria', dialCode: '+359', length: [9] },
    { code: 'BH', name: 'Bahrain', dialCode: '+973', length: [8] },
    { code: 'BI', name: 'Burundi', dialCode: '+257', length: [8] },
    { code: 'BJ', name: 'Benin', dialCode: '+229', length: [8] },
    { code: 'BL', name: 'Saint Barthelemy', dialCode: '+590', length: [9] },
    { code: 'BM', name: 'Bermuda', dialCode: '+1-441', length: [10] },
    { code: 'BN', name: 'Brunei Darussalam', dialCode: '+673', length: [7] },
    { code: 'BO', name: 'Bolivia', dialCode: '+591', length: [9] },
    { code: 'BR', name: 'Brazil', dialCode: '+55', length: [11] },
    { code: 'BS', name: 'Bahamas', dialCode: '+1-242', length: [10] },
    { code: 'BT', name: 'Bhutan', dialCode: '+975', length: [7] },
    { code: 'BV', name: 'Bouvet Island', dialCode: '+47', length: [10] },
    { code: 'BW', name: 'Botswana', dialCode: '+267', length: [7] },
    { code: 'BY', name: 'Belarus', dialCode: '+375', length: [9] },
    { code: 'BZ', name: 'Belize', dialCode: '+501', length: [7] },
    {
      code: 'CA',
      name: 'Canada',
      dialCode: '+1',

      length: [10],
    },
    {
      code: 'CC',
      name: 'Cocos (Keeling) Islands',
      dialCode: '+61',
      length: [10],
    },
    {
      code: 'CD',
      name: 'Congo, Democratic Republic of the',
      dialCode: '+243',
      length: [7],
    },
    {
      code: 'CF',
      name: 'Central African Republic',
      dialCode: '+236',
      length: [8],
    },
    {
      code: 'CG',
      name: 'Congo, Republic of the',
      dialCode: '+242',
      length: [9],
    },
    { code: 'CH', name: 'Switzerland', dialCode: '+41', length: [9] },
    { code: 'CI', name: "Cote d'Ivoire", dialCode: '+225', length: [8] },
    { code: 'CK', name: 'Cook Islands', dialCode: '+682', length: [5] },
    { code: 'CL', name: 'Chile', dialCode: '+56', length: [9] },
    { code: 'CM', name: 'Cameroon', dialCode: '+237', length: [9] },
    { code: 'CN', name: 'China', dialCode: '+86', length: [11] },
    { code: 'CO', name: 'Colombia', dialCode: '+57', length: [10] },
    { code: 'CR', name: 'Costa Rica', dialCode: '+506', length: [8] },
    { code: 'CU', name: 'Cuba', dialCode: '+53', length: [8] },
    { code: 'CV', name: 'Cape Verde', dialCode: '+238', length: [7] },
    { code: 'CW', name: 'Curacao', dialCode: '+599', length: [7] },
    { code: 'CX', name: 'Christmas Island', dialCode: '+61', length: [9] },
    { code: 'CY', name: 'Cyprus', dialCode: '+357', length: [8] },
    { code: 'CZ', name: 'Czech Republic', dialCode: '+420', length: [9] },
    {
      code: 'DE',
      name: 'Germany',
      dialCode: '+49',

      length: [10],
    },
    { code: 'DJ', name: 'Djibouti', dialCode: '+253', length: [10] },
    { code: 'DK', name: 'Denmark', dialCode: '+45', length: [8] },
    { code: 'DM', name: 'Dominica', dialCode: '+1-767', length: [10] },
    {
      code: 'DO',
      name: 'Dominican Republic',
      dialCode: '+1-809',
      length: [10],
    },
    { code: 'DZ', name: 'Algeria', dialCode: '+213', length: [9] },
    { code: 'EC', name: 'Ecuador', dialCode: '+593', length: [9] },
    { code: 'EE', name: 'Estonia', dialCode: '+372', length: [8] },
    { code: 'EG', name: 'Egypt', dialCode: '+20', length: [10] },
    { code: 'EH', name: 'Western Sahara', dialCode: '+212', length: [9] },
    { code: 'ER', name: 'Eritrea', dialCode: '+291', length: [7] },
    { code: 'ES', name: 'Spain', dialCode: '+34', length: [9] },
    { code: 'ET', name: 'Ethiopia', dialCode: '+251', length: [9] },
    { code: 'FI', name: 'Finland', dialCode: '+358', length: [9, 10, 11] },
    { code: 'FJ', name: 'Fiji', dialCode: '+679', length: [7] },
    {
      code: 'FK',
      name: 'Falkland Islands (Malvinas)',
      dialCode: '+500',
      length: [5],
    },
    {
      code: 'FM',
      name: 'Micronesia, Federated States of',
      dialCode: '+691',
      length: [7],
    },
    { code: 'FO', name: 'Faroe Islands', dialCode: '+298', length: [5] },
    {
      code: 'FR',
      name: 'France',
      dialCode: '+33',

      length: [9],
    },
    { code: 'GA', name: 'Gabon', dialCode: '+241', length: [7] },
    { code: 'GB', name: 'United Kingdom', dialCode: '+44', length: [10] },
    { code: 'GD', name: 'Grenada', dialCode: '+1-473', length: [10] },
    { code: 'GE', name: 'Georgia', dialCode: '+995', length: [9] },
    { code: 'GF', name: 'French Guiana', dialCode: '+594', length: [9] },
    { code: 'GG', name: 'Guernsey', dialCode: '+44', length: [10] },
    { code: 'GH', name: 'Ghana', dialCode: '+233', length: [9] },
    { code: 'GI', name: 'Gibraltar', dialCode: '+350', length: [8] },
    { code: 'GL', name: 'Greenland', dialCode: '+299', length: [6] },
    { code: 'GM', name: 'Gambia', dialCode: '+220', length: [7] },
    { code: 'GN', name: 'Guinea', dialCode: '+224', length: [9] },
    { code: 'GP', name: 'Guadeloupe', dialCode: '+590', length: [9] },
    { code: 'GQ', name: 'Equatorial Guinea', dialCode: '+240', length: [9] },
    { code: 'GR', name: 'Greece', dialCode: '+30', length: [10] },
    {
      code: 'GS',
      name: 'South Georgia and the South Sandwich Islands',
      dialCode: '+500',
      length: [5],
    },
    { code: 'GT', name: 'Guatemala', dialCode: '+502', length: [8] },
    { code: 'GU', name: 'Guam', dialCode: '+1-671', length: [10] },
    { code: 'GW', name: 'Guinea-Bissau', dialCode: '+245', length: [9] },
    { code: 'GY', name: 'Guyana', dialCode: '+592', length: [7] },
    { code: 'HK', name: 'Hong Kong', dialCode: '+852', length: [8] },
    {
      code: 'HM',
      name: 'Heard Island and McDonald Islands',
      dialCode: '+672',
      length: [10],
    },
    { code: 'HN', name: 'Honduras', dialCode: '+504', length: [8] },
    { code: 'HR', name: 'Croatia', dialCode: '+385', length: [9] },
    { code: 'HT', name: 'Haiti', dialCode: '+509', length: [8] },
    { code: 'HU', name: 'Hungary', dialCode: '+36', length: [9] },
    { code: 'ID', name: 'Indonesia', dialCode: '+62', length: [11] },
    { code: 'IE', name: 'Ireland', dialCode: '+353', length: [9] },
    { code: 'IL', name: 'Israel', dialCode: '+972', length: [9] },
    { code: 'IM', name: 'Isle of Man', dialCode: '+44', length: [10] },
    { code: 'IN', name: 'India', dialCode: '+91', length: [10] },
    {
      code: 'IO',
      name: 'British Indian Ocean Territory',
      dialCode: '+246',
      length: [7],
    },
    { code: 'IQ', name: 'Iraq', dialCode: '+964', length: [10] },
    {
      code: 'IR',
      name: 'Iran, Islamic Republic of',
      dialCode: '+98',
      length: [11],
    },
    { code: 'IS', name: 'Iceland', dialCode: '+354', length: [7] },
    { code: 'IT', name: 'Italy', dialCode: '+39', length: [10] },
    { code: 'JE', name: 'Jersey', dialCode: '+44', length: [10] },
    { code: 'JM', name: 'Jamaica', dialCode: '+1-876', length: [10] },
    { code: 'JO', name: 'Jordan', dialCode: '+962', length: [8, 9] },
    { code: 'JP', name: 'Japan', dialCode: '+81', length: [10] },
    { code: 'KE', name: 'Kenya', dialCode: '+254', length: [10] },
    { code: 'KG', name: 'Kyrgyzstan', dialCode: '+996', length: [9] },
    { code: 'KH', name: 'Cambodia', dialCode: '+855', length: [9] },
    { code: 'KI', name: 'Kiribati', dialCode: '+686', length: [8] },
    { code: 'KM', name: 'Comoros', dialCode: '+269', length: [7] },
    {
      code: 'KN',
      name: 'Saint Kitts and Nevis',
      dialCode: '+1-869',
      length: [10],
    },
    {
      code: 'KP',
      name: "Korea, Democratic People's Republic of",
      dialCode: '+850',
      length: [4, 6, 7, 13],
    },
    {
      code: 'KR',
      name: 'Korea, Republic of',
      dialCode: '+82',
      length: [7, 8],
    },
    { code: 'KW', name: 'Kuwait', dialCode: '+965', length: [8] },
    { code: 'KY', name: 'Cayman Islands', dialCode: '+1-345', length: [7] },
    { code: 'KZ', name: 'Kazakhstan', dialCode: '+7', length: [10] },
    {
      code: 'LA',
      name: "Lao People's Democratic Republic",
      dialCode: '+856',
      length: [8, 9],
    },
    { code: 'LB', name: 'Lebanon', dialCode: '+961', length: [7, 8] },
    { code: 'LC', name: 'Saint Lucia', dialCode: '+1-758', length: [7] },
    { code: 'LI', name: 'Liechtenstein', dialCode: '+423', length: [7] },
    { code: 'LK', name: 'Sri Lanka', dialCode: '+94', length: [7] },
    { code: 'LR', name: 'Liberia', dialCode: '+231', length: [8, 9] },
    { code: 'LS', name: 'Lesotho', dialCode: '+266', length: [8] },
    { code: 'LT', name: 'Lithuania', dialCode: '+370', length: [8] },
    { code: 'LU', name: 'Luxembourg', dialCode: '+352', length: [9] },
    { code: 'LV', name: 'Latvia', dialCode: '+371', length: [8] },
    { code: 'LY', name: 'Libya', dialCode: '+218', length: [10] },
    { code: 'MA', name: 'Morocco', dialCode: '+212', length: [9] },
    { code: 'MC', name: 'Monaco', dialCode: '+377', length: [8] },
    { code: 'MD', name: 'Moldova, Republic of', dialCode: '+373', length: [8] },
    { code: 'ME', name: 'Montenegro', dialCode: '+382', length: [8] },
    {
      code: 'MF',
      name: 'Saint Martin (French part)',
      dialCode: '+590',
      length: [6],
    },
    { code: 'MG', name: 'Madagascar', dialCode: '+261', length: [7] },
    { code: 'MH', name: 'Marshall Islands', dialCode: '+692', length: [7] },
    {
      code: 'MK',
      name: 'Macedonia, the Former Yugoslav Republic of',
      dialCode: '+389',
      length: [8],
    },
    { code: 'ML', name: 'Mali', dialCode: '+223', length: [8] },
    { code: 'MM', name: 'Myanmar', dialCode: '+95', length: [7, 8, 9, 10] },
    { code: 'MN', name: 'Mongolia', dialCode: '+976', length: [8] },
    { code: 'MO', name: 'Macao', dialCode: '+853', length: [8] },
    {
      code: 'MP',
      name: 'Northern Mariana Islands',
      dialCode: '+1-670',
      length: [7],
    },
    { code: 'MQ', name: 'Martinique', dialCode: '+596', length: [9] },
    { code: 'MR', name: 'Mauritania', dialCode: '+222', length: [8] },
    { code: 'MS', name: 'Montserrat', dialCode: '+1-664', length: [10] },
    { code: 'MT', name: 'Malta', dialCode: '+356', length: [8] },
    { code: 'MU', name: 'Mauritius', dialCode: '+230', length: [8] },
    { code: 'MV', name: 'Maldives', dialCode: '+960', length: [7] },
    { code: 'MW', name: 'Malawi', dialCode: '+265', length: [7, 8, 9] },
    { code: 'MX', name: 'Mexico', dialCode: '+52', length: [10] },
    { code: 'MY', name: 'Malaysia', dialCode: '+60', length: [7] },
    { code: 'MZ', name: 'Mozambique', dialCode: '+258', length: [12] },
    { code: 'NA', name: 'Namibia', dialCode: '+264', length: [7] },
    { code: 'NC', name: 'New Caledonia', dialCode: '+687', length: [6] },
    { code: 'NE', name: 'Niger', dialCode: '+227', length: [8] },
    { code: 'NF', name: 'Norfolk Island', dialCode: '+672', length: [6] },
    { code: 'NG', name: 'Nigeria', dialCode: '+234', length: [8] },
    { code: 'NI', name: 'Nicaragua', dialCode: '+505', length: [8] },
    { code: 'NL', name: 'Netherlands', dialCode: '+31', length: [9] },
    { code: 'NO', name: 'Norway', dialCode: '+47', length: [8] },
    { code: 'NP', name: 'Nepal', dialCode: '+977', length: [10] },
    { code: 'NR', name: 'Nauru', dialCode: '+674', length: [7] },
    { code: 'NU', name: 'Niue', dialCode: '+683', length: [4] },
    { code: 'NZ', name: 'New Zealand', dialCode: '+64', length: [8, 9] },
    { code: 'OM', name: 'Oman', dialCode: '+968', length: [8] },
    { code: 'PA', name: 'Panama', dialCode: '+507', length: [8] },
    { code: 'PE', name: 'Peru', dialCode: '+51', length: [9] },
    { code: 'PF', name: 'French Polynesia', dialCode: '+689', length: [8] },
    { code: 'PG', name: 'Papua New Guinea', dialCode: '+675', length: [8] },
    { code: 'PH', name: 'Philippines', dialCode: '+63', length: [10] },
    { code: 'PK', name: 'Pakistan', dialCode: '+92', length: [10] },
    { code: 'PL', name: 'Poland', dialCode: '+48', length: [9] },
    {
      code: 'PM',
      name: 'Saint Pierre and Miquelon',
      dialCode: '+508',
      length: [6],
    },
    { code: 'PN', name: 'Pitcairn', dialCode: '+870', length: [9] },
    { code: 'PR', name: 'Puerto Rico', dialCode: '+1', length: [10] },
    { code: 'PS', name: 'Palestine, State of', dialCode: '+970', length: [9] },
    { code: 'PT', name: 'Portugal', dialCode: '+351', length: [9] },
    { code: 'PW', name: 'Palau', dialCode: '+680', length: [7] },
    { code: 'PY', name: 'Paraguay', dialCode: '+595', length: [9] },
    { code: 'QA', name: 'Qatar', dialCode: '+974', length: [8] },
    { code: 'RE', name: 'Reunion', dialCode: '+262', length: [10] },
    { code: 'RO', name: 'Romania', dialCode: '+40', length: [10] },
    { code: 'RS', name: 'Serbia', dialCode: '+381', length: [9] },
    { code: 'RU', name: 'Russian Federation', dialCode: '+7', length: [10] },
    { code: 'RW', name: 'Rwanda', dialCode: '+250', length: [9] },
    { code: 'SA', name: 'Saudi Arabia', dialCode: '+966', length: [9] },
    { code: 'SB', name: 'Solomon Islands', dialCode: '+677', length: [7] },
    { code: 'SC', name: 'Seychelles', dialCode: '+248', length: [7] },
    { code: 'SD', name: 'Sudan', dialCode: '+249', length: [7] },
    { code: 'SE', name: 'Sweden', dialCode: '+46', length: [7] },
    { code: 'SG', name: 'Singapore', dialCode: '+65', length: [8] },
    { code: 'SH', name: 'Saint Helena', dialCode: '+290', length: [4] },
    { code: 'SI', name: 'Slovenia', dialCode: '+386', length: [9] },
    {
      code: 'SJ',
      name: 'Svalbard and Jan Mayen',
      dialCode: '+47',
      length: [8],
    },
    { code: 'SK', name: 'Slovakia', dialCode: '+421', length: [9] },
    { code: 'SL', name: 'Sierra Leone', dialCode: '+232', length: [8] },
    { code: 'SM', name: 'San Marino', dialCode: '+378', length: [10] },
    { code: 'SN', name: 'Senegal', dialCode: '+221', length: [9] },
    { code: 'SO', name: 'Somalia', dialCode: '+252', length: [8, 9] },
    { code: 'SR', name: 'Suriname', dialCode: '+597', length: [6, 7] },
    { code: 'SS', name: 'South Sudan', dialCode: '+211', length: [7] },
    {
      code: 'ST',
      name: 'Sao Tome and Principe',
      dialCode: '+239',
      length: [7],
    },
    { code: 'SV', name: 'El Salvador', dialCode: '+503', length: [8] },
    {
      code: 'SX',
      name: 'Sint Maarten (Dutch part)',
      dialCode: '+1-721',
      length: [10],
    },
    { code: 'SY', name: 'Syrian Arab Republic', dialCode: '+963', length: [7] },
    { code: 'SZ', name: 'Swaziland', dialCode: '+268', length: [8] },
    {
      code: 'TC',
      name: 'Turks and Caicos Islands',
      dialCode: '+1-649',
      length: [10],
    },
    { code: 'TD', name: 'Chad', dialCode: '+235', length: [6] },
    {
      code: 'TF',
      name: 'French Southern Territories',
      dialCode: '+262',
      length: [10],
    },
    { code: 'TG', name: 'Togo', dialCode: '+228', length: [8] },
    { code: 'TH', name: 'Thailand', dialCode: '+66', length: [9] },
    { code: 'TJ', name: 'Tajikistan', dialCode: '+992', length: [9] },
    { code: 'TK', name: 'Tokelau', dialCode: '+690', length: [5] },
    { code: 'TL', name: 'Timor-Leste', dialCode: '+670', length: [7] },
    { code: 'TM', name: 'Turkmenistan', dialCode: '+993', length: [8] },
    { code: 'TN', name: 'Tunisia', dialCode: '+216', length: [8] },
    { code: 'TO', name: 'Tonga', dialCode: '+676', length: [5] },
    { code: 'TR', name: 'Turkey', dialCode: '+90', length: [11] },
    {
      code: 'TT',
      name: 'Trinidad and Tobago',
      dialCode: '+1-868',
      length: [7],
    },
    { code: 'TV', name: 'Tuvalu', dialCode: '+688', length: [5] },
    {
      code: 'TW',
      name: 'Taiwan, Province of China',
      dialCode: '+886',
      length: [9],
    },
    {
      code: 'TZ',
      name: 'United Republic of Tanzania',
      dialCode: '+255',
      length: [7],
    },
    { code: 'UA', name: 'Ukraine', dialCode: '+380', length: [9] },
    { code: 'UG', name: 'Uganda', dialCode: '+256', length: [7] },
    {
      code: 'US',
      name: 'United States',
      dialCode: '+1',

      length: [10],
    },
    { code: 'UY', name: 'Uruguay', dialCode: '+598', length: [8] },
    { code: 'UZ', name: 'Uzbekistan', dialCode: '+998', length: [9] },
    {
      code: 'VA',
      name: 'Holy See (Vatican City State)',
      dialCode: '+379',
      length: [10],
    },
    {
      code: 'VC',
      name: 'Saint Vincent and the Grenadines',
      dialCode: '+1-784',
      length: [7],
    },
    { code: 'VE', name: 'Venezuela', dialCode: '+58', length: [7] },
    {
      code: 'VG',
      name: 'British Virgin Islands',
      dialCode: '+1-284',
      length: [7],
    },
    { code: 'VI', name: 'US Virgin Islands', dialCode: '+1-340', length: [10] },
    { code: 'VN', name: 'Vietnam', dialCode: '+84', length: [9] },
    { code: 'VU', name: 'Vanuatu', dialCode: '+678', length: [5] },
    { code: 'WF', name: 'Wallis and Futuna', dialCode: '+681', length: [6] },
    { code: 'WS', name: 'Samoa', dialCode: '+685', length: [5, 6, 7] },
    { code: 'XK', name: 'Kosovo', dialCode: '+383', length: [8] },
    { code: 'YE', name: 'Yemen', dialCode: '+967', length: [9] },
    { code: 'YT', name: 'Mayotte', dialCode: '+262', length: [9] },
    { code: 'ZA', name: 'South Africa', dialCode: '+27', length: [9] },
    { code: 'ZM', name: 'Zambia', dialCode: '+260', length: [9] },
    { code: 'ZW', name: 'Zimbabwe', dialCode: '+263', length: [9] },
  ];
}
