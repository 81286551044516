<mat-card class="example-card" appearance="outlined">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      {{
        workflowComment!.fullName && workflowComment!.fullName.substring(0, 1)
      }}
    </div>
    <mat-card-title>
      <p class="mat-body-strong">{{ workflowComment!.fullName }}</p>
    </mat-card-title>
    <mat-card-subtitle>
      <p class="mat-caption">
        {{ workflowComment!.dateTime | date: "medium" }}
      </p>
    </mat-card-subtitle>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
      style="display: inline-block; margin-left: auto"
      [disabled]="isArchived"
      [attr.data-test-id]="'documents.report-comment.workflow-comment-card.more-vert-icon'"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="openReportCommentDialog($event, workflowComment!)"
        [disabled]="workflowComment?.isReported"
      >
        <mat-icon [attr.data-test-id]="'documents.report-comment.workflow-comment-card.report-comment-button'">report_problem</mat-icon>
        <span i18n="@@documents.workflow-comment-card.report-comment"
          >Report comment</span
        >
      </button>
    </mat-menu>
  </mat-card-header>
  <mat-card-content>
    <p class="text-comment">
      {{ workflowComment!.comment }}
    </p>
  </mat-card-content>
  <mat-card-actions class="action-buttons-end">
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Edit comment"
      (click)="editComment(workflowComment!)"
      [disabled]="workflowComment?.isReported || !isCommenter() || isArchived"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="warn"
      matTooltip="Delete comment"
      [disabled]="workflowComment?.isReported || !isCommenter() || isArchived"
      (click)="openDeleteConfirmationDialog($event, workflowComment!)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Reply comment"
      (click)="replyComment(workflowComment!)"
      [disabled]="isArchived"
    >
      <mat-icon>reply</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
<div *ngIf="workflowComment?.replies?.length! > 0">
  <app-workflow-comment-reply-card
    *ngFor="let reply of workflowComment?.replies"
    [workflowCommentReply]="reply"
    [workflowComment]="workflowComment"
    (editCommentReplyClicked)="editReply()"
  ></app-workflow-comment-reply-card>
</div>
