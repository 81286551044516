<mat-card class="margin-top">
  <mat-card-title class="margin-top-title"
  i18n="@@researches.research-evaluations.research-evaluations"
    >Research Evaluations
  </mat-card-title>
  <mat-card-content>
    <div class="">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="researchName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by researchName"
            i18n="@@researches.research-evaluations.research-name"
          >
            Research Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.researchName }}</td>
        </ng-container>
        <ng-container matColumnDef="officeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by officeName"
            i18n="@@researches.research-evaluations.office"
          >
            Office
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.officeName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="stageName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by stageName"
            i18n="@@researches.research-evaluations.stage-name"
          >
            Stage name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.stageName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="result">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by result"
            i18n="@@researches.research-evaluations.evaluation-result"
          >
            Evalution result
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.result }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="paginator">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="loadPaginatedCommunications($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
