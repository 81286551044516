import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowComment } from '../../models/workflow-comment.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowCommentFacade } from '../../facade/workflow-comment.facade';
import { Workflow } from 'src/app/documents/workflows/models/workflow.model';
import { WorkflowFacade } from 'src/app/documents/workflows/facade/workflow.facade';
import { RxState } from '@rx-angular/state';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';

interface WorkflowCommentCardComponentState {
  selectedWorkflow: Workflow | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initWorkflowCommentCardComponentState: WorkflowCommentCardComponentState =
  {
    selectedWorkflow: undefined,
    currentLoggedInUser: undefined
  };

@Component({
  selector: 'app-workflow-comment-card',
  templateUrl: './workflow-comment-card.component.html',
  styleUrls: ['./workflow-comment-card.component.scss'],
})
export class WorkflowCommentCardComponent implements OnInit {
  @Input() isArchived?: boolean;
  @Input() workflowComment: WorkflowComment | undefined;
  @Output() editCommentClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() replyCommentClicked: EventEmitter<void> = new EventEmitter<void>();

  selectedWorkflow: Workflow | undefined;
  selectedWorkflow$ = this.state.select('selectedWorkflow');

  currentLoggedInUser: CurrentLoggedInUser | undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  constructor(
    private dialog: MatDialog,
    private workflowCommentFacade: WorkflowCommentFacade,
    private workflowFacade: WorkflowFacade,  
    private userFacade: UserFacade,  
    private state: RxState<WorkflowCommentCardComponentState>,
  ) {
    this.state.set(initWorkflowCommentCardComponentState);
    this.state.connect(
      'selectedWorkflow',
      this.workflowFacade.selectedWorkflow$,
    );
    this.state.connect('currentLoggedInUser', this.userFacade.currentLoggedInUser$);
  }

  ngOnInit(): void {
    this.selectedWorkflow$.subscribe((selectedWorkflow) => {
      this.selectedWorkflow = selectedWorkflow;
    });
    this.currentLoggedInUser$.subscribe((currUser)=>{
      this.currentLoggedInUser = currUser;
    })
  }

  openDeleteConfirmationDialog(
    event: MouseEvent,
    workflowComment: WorkflowComment,
  ) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-comment-card.deleted-workflow-comment-part-1:Are you sure you want to delete` ,
        boldText: ` "${workflowComment!.comment}" ` ,
        regularTextTwo: $localize`:@@documents.workflow-comment-card.deleted-workflow-comment-part-2:workflow comment?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowCommentFacade.dispatchDeleteWorkflowComment(
          this.selectedWorkflow!.id!,
          workflowComment.id!,
        );
      }
    });
  }

  editComment(workflowComment: WorkflowComment) {
    this.workflowCommentFacade.dispatchSetSelectedWorkflowComment(
      workflowComment,
    );
    this.workflowCommentFacade.dispatchSetUpdateWorkflowComment(true);
    this.workflowCommentFacade.dispatchSetReplyWorkflowComment(false);
    this.editCommentClicked.emit();
  }

  replyComment(workflowComment: WorkflowComment) {
    this.workflowCommentFacade.dispatchSetSelectedWorkflowComment(
      workflowComment,
    );
    this.workflowCommentFacade.dispatchSetUpdateWorkflowComment(false);
    this.workflowCommentFacade.dispatchSetReplyWorkflowComment(true);
    this.replyCommentClicked.emit();
  }

  openReportCommentDialog(event: MouseEvent, workflowComment: WorkflowComment) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        placeholder: 'Issue Description',
        message: $localize`:@@documents.workflow-comment-card.reported-workflow-comment:Are you sure you want to report` + `${
          workflowComment!.comment
        }?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmText === 'confirm') {
        this.workflowCommentFacade.dispatchReportWorkflowComment(
          workflowComment.id!,
          dialogRef.componentInstance.confirmText!,
        );
      }
    });
  }

  editReply(){
    this.editCommentClicked.emit();
  }

  isCommenter(){
    return this.currentLoggedInUser?.id == this.workflowComment?.commentedUserId;
  }
}
