import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  GetFilesBySearchTerm,
  GetSortedFiles,
  MoveFiles,
  RenameFile,
  SelectFilesIds,
  CopyFile,
  SelectFile,
  SelectFiles,
  ShareFilesToOffices,
  ShareFilesToRoles,
  ShareFilesToUsers,
  GetSharedFromFiles,
  GetSharedToFiles,
  DownloadFile,
  CreateFiles,
  DeleteFiles,
  PreviewFile,
  FetchAudio,
  TrashFiles,
  GetTrashedFiles,
  RestoreFile,
  GetFileProperty,
  GetArchivedFiles,
  MoveArchivedFiles,
  SetFileSharingMode,
  UnshareFile,
  SetFilesNull,
} from '../store/file.actions';
import { FileSelector } from '../store/file.selector';
import { ImisFile } from '../models/imis-file';
import { ShareFileRequest } from '../models/share-file-request';
import { FileProperty } from '../models/file-property.model';
import { MoveArchivedFileDto, UnsharedFile } from '../models/file.model';

@Injectable({
  providedIn: 'root',
})
export class FileFacade {
  constructor(private store: Store) {}

  files$: Observable<PaginatedList<ImisFile>> = this.store.select(
    FileSelector.slices.files,
  );

  @Select(FileSelector.selectedFilesIds)
  selectedFilesIds$!: Observable<string[]>;
  dispatchGetFiles(
    folderId: string,
    orderBy: number[],
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetSortedFiles(folderId, orderBy, pageNumber, pageSize),
    );
  }

  @Select(FileSelector.selectedFile)
  selectedFile$!: Observable<ImisFile>;

  @Select(FileSelector.selectedFiles)
  selectedFiles$!: Observable<ImisFile[]>;

  @Select(FileSelector.audioBlob)
  audioBlob$!: Observable<Blob | null>;

  @Select(FileSelector.trash)
  trash$!: Observable<PaginatedList<ImisFile>>;

  fileProperty$: Observable<FileProperty | undefined> = this.store.select(
    FileSelector.slices.fileProperty,
  );

  @Select(FileSelector.archivedFiles)
  archivedFiles$!: Observable<PaginatedList<ImisFile>>;

  @Select(FileSelector.isSharingFile)
  isSharingFile$!: Observable<boolean>;

  dispatchSelectFilesIds(files: string[]) {
    return this.store.dispatch(new SelectFilesIds(files));
  }
  dispatchSelectFiles(files: ImisFile[]) {
    return this.store.dispatch(new SelectFiles(files));
  }
  dispatchSelectFile(file: ImisFile) {
    return this.store.dispatch(new SelectFile(file));
  }

  dispatchMoveFiles(destinationFolderId: string, selectedFilesIds: string[]) {
    return this.store.dispatch(
      new MoveFiles(destinationFolderId, selectedFilesIds),
    );
  }

  dispatchGetFilessBySearchTerm(searchTerm: string) {
    this.store.dispatch(new GetFilesBySearchTerm(searchTerm));
  }

  dispatchCopyFile(fileId: string, folderId: string) {
    this.store.dispatch(new CopyFile(fileId, folderId));
  }

  dispatchRenameFile(fileId: string, fileName: string) {
    this.store.dispatch(new RenameFile(fileId, fileName));
  }

  dispatchDeleteFiles(fileIds: string[]) {
    this.store.dispatch(new DeleteFiles(fileIds));
  }

  dispatchShareFilesToOffices(shareFileRequest: ShareFileRequest[]) {
    this.store.dispatch(new ShareFilesToOffices(shareFileRequest));
  }

  dispatchShareFilesToRoles(shareFileRequest: ShareFileRequest[]) {
    this.store.dispatch(new ShareFilesToRoles(shareFileRequest));
  }

  dispatchShareFilesToUsers(shareFileRequest: ShareFileRequest[]) {
    this.store.dispatch(new ShareFilesToUsers(shareFileRequest));
  }

  dispatchUnshareFile(unsharedFile: UnsharedFile) {
    this.store.dispatch(new UnshareFile(unsharedFile));
  }

  dispatchGetSharedFromFiles(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetSharedFromFiles(pageNumber, pageSize));
  }

  dispatchGetSharedToFiles(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetSharedToFiles(pageNumber, pageSize));
  }

  dispatchDownloadFile(fileId: string, fileName: string) {
    this.store.dispatch(new DownloadFile(fileId, fileName));
  }

  dispachUploadFiles(uploadFile: FormData, folderId: string) {
    this.store.dispatch(new CreateFiles(uploadFile, folderId));
  }

  dispatchPreviewFile(fileId: string, fileName: string) {
    this.store.dispatch(new PreviewFile(fileId, fileName));
  }

  dispatchFetchAudio(fileId: string) {
    this.store.dispatch(new FetchAudio(fileId));
  }

  dispatchTrashFiles(fileIds: string[]) {
    this.store.dispatch(new TrashFiles(fileIds));
  }

  dispatchGetTrashFiles(pageIndex: number, pageSize: number) {
    this.store.dispatch(new GetTrashedFiles(pageIndex, pageSize));
  }

  dispatchRestoreFile(fileId: string) {
    this.store.dispatch(new RestoreFile(fileId));
  }

  dispatchGetFileProperty(fileId: string) {
    this.store.dispatch(new GetFileProperty(fileId));
  }

  dispatchGetArchivedFiles(
    folderId: string,
    orderBy: number[],
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetArchivedFiles(folderId, orderBy, pageNumber, pageSize),
    );
  }

  dispatchMoveArchivedFiles(filesToMove: MoveArchivedFileDto[]) {
    this.store.dispatch(new MoveArchivedFiles(filesToMove));
  }

  dispatchSetFileSharingMode(isSharingFile: boolean) {
    this.store.dispatch(new SetFileSharingMode(isSharingFile));
  }

  dispatchSetFilesNull(){
    this.store.dispatch(new SetFilesNull())
  }
}
