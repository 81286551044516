<div class="wrapper" *ngIf="selectedStageInstanceDetail">
  <div fxLayout="row" fxLayoutAlign="center center" class="row">
    <h1 class="mat-h1">{{ processInstanceDetail?.title }}</h1>
    <div class="chip mat-caption">
      {{ selectedStageInstanceDetail!.processInstance.process.name }}
    </div>
  </div>

  <div class="container">
    <div class="row">
      <h2 class="mat-h2">{{ selectedStageInstanceDetail.name }}</h2>
      <span
        class="chip mat-caption"
        *ngIf="selectedStageInstanceDetail?.stage?.hasEvaluation"
        i18n="@@researches.stage-instance-detail.has-evaluation"
        >Has Evaluation</span
      >
      <span
        class="chip mat-caption"
        *ngIf="!selectedStageInstanceDetail?.stage?.hasEvaluation"
        i18n="@@researches.stage-instance-detail.has-no-evaluation"
        >Has no evaluation</span
      >
      <ng-container *appHasPermission="hasGetUserStageEvaluationPermission()">
        <button
          mat-stroked-button
          disabled
          *ngIf="
            showEvaluationResult &&
            selectedStageInstanceDetail?.stage?.hasEvaluation &&
            (selectedStageInstanceDetail?.stageStatus ===
              StageInstanceStatus.Approved ||
              selectedStageInstanceDetail?.stageStatus ===
                StageInstanceStatus.Rejected ||
              selectedStageInstanceDetail?.stageStatus ===
                StageInstanceStatus.Terminated)
          "
        >
          <span i18n="@@researches.stage-instance-detail.evaluation-result"
            >Evaluation Result:</span
          >
          {{ totalEvaluationResult }}%
        </button>
      </ng-container>
    </div>
    <ng-container *appHasPermission="hasCreateTorPermission()">
      <button
        mat-raised-button
        color="primary"
        (click)="initiateTorForm()"
        *ngIf="
          !processInstanceDetail?.isArchived &&
          selectedStageInstanceDetail?.stage?.hasBudget &&
          processInstanceDetail?.ownerEmail === currentLoggedInUser?.email &&
          (selectedStageInstanceDetail.stageStatus.toString() !==
            StageStatus.Approved &&
            selectedStageInstanceDetail.stageStatus.toString() !==
              StageStatus.Rejected &&
            selectedStageInstanceDetail.stageStatus.toString() !==
              StageStatus.Terminated)
        "
        [disabled]="!selectedStageInstanceDetail.stage.hasBudget"
        i18n="@@researches.stage-instance-detail.initiate-tor"
      >
        Initiate Tor
      </button>
    </ng-container>
  </div>
  <p class="description mat-body-2">
    {{ selectedStageInstanceDetail.description }}
  </p>

  <div class="approve-reject-btn" *ngIf="!processInstanceDetail?.isArchived">
    <button
      class="stage-btn"
      mat-raised-button
      color="accent"
      [disabled]="
        selectedStageInstanceDetail.stageStatus !== 'WaitingForApproval'
      "
      (click)="needsRevision()"
      *appHasPermission="hasUpdateStatusToNeedsRevisionPermission()"
      i18n="@@researches.stage-instance-detail.needs-revision"
    >
      Needs Revision
    </button>
    <button
      class="stage-btn"
      mat-raised-button
      color="primary"
      [disabled]="
        selectedStageInstanceDetail.stageStatus !== 'WaitingForApproval'
      "
      (click)="approveStage()"
      *appHasPermission="hasUpdateStatusToApprovedPermission()"
      [attr.data-test-id]="'researches.approve-stage-instance.stage-instance-detail.approve-stage-button'"
    >
      {{
        selectedStageInstanceDetail.stage.hasEvaluation
          ? placeholderToggleLabel.submitForEvaluation
          : placeholderToggleLabel.approveStage
      }}
    </button>
  </div>
  <div class="container">
    <div class="change-status">
      <p
        class="mat-body"
        i18n="@@researches.stage-instance-detail.stage-instance-status"
      >
        Stage Instance Status:
      </p>
      <button
        mat-flat-button
        color="primary"
        class="custom-button"
        [ngClass]="getStageStatusColor(selectedStageInstanceDetail.stageStatus)"
        [disabled]="processInstanceDetail?.isArchived"
        [attr.data-test-id]="'researches.status.stage-instance-detail.check-stage-status-button'"
      >
        {{ checkStageStatus(selectedStageInstanceDetail.stageStatus) }}
      </button>
      <mat-menu #menu="matMenu" (closed)="toggleStatusDropdown()">
        <button
          mat-menu-item
          *ngFor="let status of statuses"
          (click)="selectStatus(status)"
        >
          {{ checkStageStatus(status) }}
        </button>
      </mat-menu>
      <div *ngIf="selectedStageInstanceDetail.stageStatus == 'NeedsRevision'">
        <button mat-icon-button (click)="showNeedRevisionComments()">
          <mat-icon
            aria-hidden="false"
            aria-label="StageInstance needsrevision icon"
            fontIcon="comment"
          ></mat-icon>
        </button>
      </div>
    </div>

    <ng-container
      *appHasPermission="hasUpdateStatusToWaitingForApprovalPermission()"
    >
      <button
        mat-raised-button
        color="primary"
        [disabled]="!canSubmitForApproval"
        (click)="submitForApproval()"
        *ngIf="!processInstanceDetail?.isArchived"
        i18n="@@researches.stage-instance-detail.submit-for-approval"
        [attr.data-test-id]="'researches.submit-stage-instane.stage-instance-detail.submit-for-approval-button'"
      >
        Submit For Approval
      </button>
    </ng-container>
  </div>

  <mat-card class="tab-content">
    <mat-tab-group
      mat-stretch-tabs="false"
      animationDuration="0ms"
      mat-align-tabs="center"
    >
      <mat-tab
        [label]="tasks"
        *appHasPermission="hasGetStageInstanceTaskPermission()"
      >
        <div class="task-container">
          <div class="task-table-header">
            <div class="task-table-header-title">
              <h3
                class="table-header"
                i18n="@@researches.stage-instance-detail.tasks"
              >
                Tasks
              </h3>
              <button
                mat-icon-button
                [matTooltip]="placeholderToggleLabel.infoStage"
              >
                <mat-icon>info</mat-icon>
              </button>
            </div>
            <div class="flex">
              <form [formGroup]="taskTypeForm" class="task-type-form-container">
                <mat-form-field appearance="outline" class="process-selector">
                  <mat-label
                    i18n="@@researches.stage-instance-detail.tasks-types"
                    >Task Types</mat-label
                  >
                  <mat-select
                    formControlName="selectedTaskType"
                    (selectionChange)="getTasksByType()"
                    [attr.data-test-id]="'researches.filter-tasks.stage-instance-detail.mat-select'"
                  >
                    <mat-option
                      [value]="undefined"
                      i18n="@@researches.stage-instance-detail.all"
                      [attr.data-test-id]="'researches.filter-tasks.stage-instance-detail.all-mat-option'"
                      >All</mat-option
                    >
                    <mat-option
                      *ngFor="let taskType of taskTypes"
                      [value]="taskType"
                      [attr.data-test-id]="'researches.filter-tasks.stage-instance-detail.mat-option'"
                      >{{ taskType.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </form>
              <ng-container *appHasPermission="hasCreateTaskPermission()">
                <button
                  mat-raised-button
                  color="primary"
                  (click)="openTaskForm()"
                  *ngIf="!processInstanceDetail?.isArchived"
                  [disabled]="
                    selectedStageInstanceDetail.stageStatus.toString() ==
                      StageStatus.SubmittedToEvaluation ||
                    selectedStageInstanceDetail.stageStatus.toString() ==
                      StageStatus.WaitingForApproval ||
                    selectedStageInstanceDetail.stageStatus.toString() ==
                      StageStatus.Approved ||
                    selectedStageInstanceDetail.stageStatus.toString() ==
                      StageStatus.Rejected ||
                    selectedStageInstanceDetail.stageStatus.toString() ==
                      StageStatus.Terminated
                  "
                  i18n="@@researches.stage-instance-detail.add-task"
                  [attr.data-test-id]="'researches.create-task.stage-instance-detail.add-task-button'"
                >
                  Add task
                </button>
              </ng-container>
            </div>
          </div>
          <div *ngIf="stageInstanceTasks!.items.length > 0">
            <mat-paginator
              [length]="length"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [pageSizeOptions]="[5, 10, 20, 100]"
              [showFirstLastButtons]="true"
              (page)="loadPaginatedTasks($event)"
            >
            </mat-paginator>

            <ng-container *ngFor="let task of stageInstanceTasks!.items">
              <mat-card class="task-card" appearance="outlined" [attr.data-test-id]="'researchers.get-tasks.stage-instance-detail.mat-card'">
                <mat-card-content (click)="onRowClick($event, task)">
                  <div class="card-content">
                    <div class="task-info">
                      <div class="mat-body-strong"[attr.data-test-id]="'researchers.get-task-by-name.stage-instance-detail.title-div'" >
                        {{ task.name }}
                      </div>
                      <div class="task-description mat-body-2">
                        {{ task.description }}
                      </div>
                      <div *ngIf="task.isMajorTask" class="is-major-chip">
                        <p i18n="@@researches.stage-instance-detail.major">Major</p>
                      </div>
                    </div>

                    <div class="more-info">
                      <div
                        class="status mat-caption"
                        [ngClass]="
                          getTaskStatusColor(task?.taskStatus!.toString())
                        "
                      >
                        {{ checkTaskStatus(task?.taskStatus!.toString()) }}
                      </div>
                      <div class="more-info">
                        <div class="assigned-to">
                          <mat-card class="assignee" *ngIf="task.assignee">{{
                            task.assignee.firstName[0].toUpperCase()
                          }}</mat-card>
                        </div>
                        <div class="more-options">
                          <button
                            *appHasPermission="hasCrudPermission()"
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            (click)="setSelectedTask($event, task)"
                          >
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button
                              mat-menu-item
                              (click)="viewDocument()"
                              *appHasPermission="
                                hasGetFileTasksByTaskPermission()
                              "
                              i18n="
                                @@researches.stage-instance-detail.view-documents"
                                [attr.data-test-id]="'researches.view-task-document.stage-instance-detail.view-documents-button'"
                            >
                              View Documents
                            </button>
                            <ng-container
                              *appHasPermission="hasUpdateTaskPermission()"
                            >
                              <button
                                mat-menu-item
                                (click)="
                                  updateTask(selectedStageInstanceDetail)
                                "
                                [disabled]="checkStageInstanceStatus()"
                                *ngIf="
                                  !processInstanceDetail?.isArchived &&
                                  canEditAndDelete()
                                "
                                i18n="
                                  @@researches.stage-instance-detail.edit-task"
                                  [attr.data-test-id]="'researches.edit-task.stage-instance-detail.edit-task-button'"
                              >
                                Edit Task
                              </button>
                            </ng-container>
                            <ng-container
                              *appHasPermission="hasDeleteTaskPermission()"
                            >
                              <button
                                mat-menu-item
                                (click)="openConfirmationDialog()"
                                [disabled]="checkStageInstanceStatus()"
                                *ngIf="
                                  !processInstanceDetail?.isArchived &&
                                  canEditAndDelete()
                                "
                                i18n="
                                  @@researches.stage-instance-detail.delete-task"
                                  [attr.data-test-id]="'researches.delete-task.stage-instance-detail.delete-task-button'"
                              >
                                Delete Task
                              </button>
                            </ng-container>
                            <ng-container
                              *appHasPermission="hasToggleIsMajorPermission()"
                            >
                              <button
                                mat-menu-item
                                (click)="toggleIsMajor(task.id)"
                                [disabled]="checkStageInstanceStatus()"
                                *ngIf="
                                  task.isMajorTask &&
                                  !processInstanceDetail?.isArchived &&
                                  canSetAsMajorAndSetAsNonMajor()"
                                [attr.data-test-id]="'researches.set-as-non-major.stage-instance-detail.set-as-non-major-button'"
                              >
                                <span
                                  i18n="
                                    @@researches.stage-instance-detail.set-as-non-major"
                                >
                                  Set as Non-Major
                                </span>
                              </button>
                              <button
                                mat-menu-item
                                (click)="toggleIsMajor(task.id)"
                                *ngIf="
                                  !task.isMajorTask &&
                                  !processInstanceDetail?.isArchived &&
                                  !checkStageInstanceStatus()&&
                                  canSetAsMajorAndSetAsNonMajor()
                                "
                                [attr.data-test-id]="'researches.set-as-major.stage-instance-detail.set-as-major-button'"
                              >
                                <span
                                  i18n="
                                    @@researches.stage-instance-detail.set-as-major"
                                >
                                  Set as Major
                                </span>
                              </button>
                            </ng-container>
                          </mat-menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
          <div *ngIf="stageInstanceTasks!.items.length == 0">
            <p i18n="@@researches.stage-instance-detail.no-tasks">
              There are no tasks in this stage
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        [label]="taskBoard"
        *appHasPermission="hasGetStageInstanceTaskPermission()"
      >
        <app-task-board
          [processInstance]="processInstanceDetail"
        ></app-task-board>
      </mat-tab>
      <mat-tab
        [label]="criteria"
        *ngIf="selectedStageInstanceDetail?.stage?.hasEvaluation"
      >
        <div *appHasPermission="hasGetCriterionPermission()">
          <h3
            class="table-header"
            i18n="@@researches.stage-instance-detail.criteria"
          >
            Criteria
          </h3>

          <div class="table-container">
            <table
              mat-table
              [dataSource]="selectedStageInstanceDetail.criterionInstances!"
              class="mat-table"
            >
              <ng-container matColumnDef="criteria">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  i18n="@@researches.stage-instance-detail.evaluation-criteria"
                >
                  Evaluation Criteria
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="weight">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="align-right"
                  i18n="@@researches.stage-instance-detail.weight"
                >
                  Weight
                </th>
                <td mat-cell *matCellDef="let element" class="align-right">
                  {{ element.weight }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="evaluation" *ngIf="showEvaluationResult && selectedStageInstanceDetail?.stage?.hasEvaluation">
        <div *appHasPermission="hasGetUserStageEvaluationPermission()" class="">
          <table mat-table [dataSource]="stageEvaluations" class="mat-table">
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                i18n="@@researches.stage-instance-detail.evaluation-criteria"
              >
                Evaluation Criteria
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="weight">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="align-right"
                i18n="@@researches.stage-instance-detail.weight"
              >
                Weight
              </th>
              <td mat-cell *matCellDef="let element" class="align-right">
                {{ element.weight }}
              </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="align-right"
                i18n="@@researches.stage-instance-detail.value"
              >
                Value
              </th>
              <td mat-cell *matCellDef="let element" class="align-right">
                {{ element.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="evaluationColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: evaluationColumns"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>

  <div
    class="align-right reject-button"
    *ngIf="!processInstanceDetail?.isArchived && selectedStageInstanceDetail.stageStatus !== 'Approved'"
  >
    <button
      class="stage-btn"
      mat-button
      color="warn"
      [disabled]="!isRejectable"
      (click)="rejectStage()"
      *appHasPermission="hasUpdateStatusToRejectedPermission()"
      i18n="@@researches.stage-instance-detail.decline-stage"
    >
      Decline Stage
    </button>
  </div>

  <div
    class="nav-buttons"
    *ngIf="!processInstanceDetail?.process?.enforceSequential"
  >
    <button
      mat-raised-button
      (click)="previousStage()"
      [disabled]="currentStageIndex === 0"
    >
      <mat-icon>chevron_left</mat-icon>
      <span i18n="@@researches.stage-instance-detail.previous-stage"
        >Previous Stage</span
      >
    </button>
    <button
      mat-raised-button
      (click)="nextStage()"
      [disabled]="currentStageIndex === stageIds.length - 1"
      class="next-stage"
      color="primary"
    >
      <span i18n="@@researches.stage-instance-detail.next-stage"
        >Next Stage</span
      >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
