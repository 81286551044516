import { Component, Input, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  REPORTED_ISSUES_DETAIL_ROUTE,
} from 'src/app/core/constants/routes';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Status } from 'src/app/core/constants/reported-issues-constants';
import { ContactForm } from '../../models/contact-form.model';
import { ContactUsFacade } from '../../facade/contact.facade';

export interface ReportedIssueBoardState {
  contactList: PaginatedList<ContactForm> | undefined;
  selectedContact: ContactForm | undefined;
  currentLoggedInUser?: CurrentLoggedInUser;
}

const initReportedIssueBoardState: ReportedIssueBoardState = {
  contactList: undefined,
  selectedContact: undefined,
};

@Component({
  selector: 'app-reported-issue-board',
  templateUrl: './reported-issue-board.component.html',
  styleUrls: ['./reported-issue-board.component.scss'],
})
export class ReportedIssueBoardComponent implements OnInit {
  contactList: PaginatedList<ContactForm> | undefined;
  contactList$ = this.state.select('contactList');
  filteredContacts: ContactForm[] = [];

  selectedContact: ContactForm | undefined;
  selectedContact$ = this.state.select('selectedContact');
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  contactCategories = Object.keys(Status).length;

  statusForDisplay: { [key in Status]: string } = {
    [Status.Reported]: $localize`:@@users.reported-issue-detail.reported: Reported`,
    [Status.InProgress]: $localize`:@@users.reported-issue-detail.in-progress: InProgress`,
    [Status.Fixed]: $localize`:@@users.reported-issue-detail.fixed: Fixed`,
    [Status.Resolved]: $localize`:@@users.reported-issue-detail.resolved: Resolved`,
    [Status.Unresolved]: $localize`:@@users.reported-issue-detail.unresolved: Unresolved`,
  };

  constructor(
    private state: RxState<ReportedIssueBoardState>,
    private contactFacade: ContactUsFacade,
    private router: Router,
    private userFacade: UserFacade,
  ) {
    this.state.set(initReportedIssueBoardState);
    this.state.connect('selectedContact', this.contactFacade.selectedContactForm$);
    this.state.connect(
      'contactList',
      this.contactFacade.paginatedContactUs$,
    );
    this.state.connect('currentLoggedInUser', this.userFacade.currentLoggedInUser$);
  }

  ngOnInit(): void {
    this.contactList$.subscribe((contactList) => {
      this.contactList = contactList;
    });
    this.selectedContact$.subscribe((task) => {
      this.selectedContact = task;
    });
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });

  }

  setSelectedContact(event: MouseEvent, contact: ContactForm) {
    event.stopPropagation();
    this.contactFacade.dispatchSetSelectedContactUs(contact);
  }

  filterContacts(status: string) {
    if (this.contactList === undefined) return [];
    this.filteredContacts = this.contactList.items.filter((contact) => {
      return contact.contactStatusForDisplay?.status === status;
    });
    
    return this.filteredContacts;
  }

  viewDetail(issue: ContactForm): void {
    this.contactFacade.dispatchSetSelectedContactUs(issue);
    if (issue.id) {
      this.contactFacade.dispatchGetContactUsDetail(
        issue.id as string,
      );
    this.router.navigate([REPORTED_ISSUES_DETAIL_ROUTE]);
    }
  }


  drop(event: CdkDragDrop<ContactForm[]>) {
    const contactId = event.item.element.nativeElement.getAttribute('id');
    const newStatus = event.container.id as Status;
    const contactToUpdate = this.contactList?.items.find((contact) => contact.id === contactId);
    if (contactToUpdate) {
      this.contactFacade.dispatchChangeContactUsStatus({
        id: contactId!,
        status: { id: newStatus, statusName: newStatus }
      });
      contactToUpdate.contactStatusForDisplay!.status = newStatus;

      transferArrayItem(
        event.previousContainer.data,
        this.filterContacts(event.container.id),
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  isIssueOwner(issue: ContactForm): boolean {
    return this.currentLoggedInUser?.id === issue.sentByUserId;
  }

  canDrop = (status: any) => {
    return (item: CdkDrag<ContactForm>, list: CdkDropList) => {
      const issue: ContactForm = item.data;
      const isOwner = this.isIssueOwner(issue);

      if ((status === 'Resolved' || status === 'Unresolved') && !isOwner) {
        return false;
      }

      if ((status === 'Reported' || status === 'InProgress' || status === 'Fixed') && isOwner) {
        return false;
      }

      return true;
    };
  };

  get gridHeaders(): Array<string> {
    return Object.values(this.statusForDisplay);
  }
}
