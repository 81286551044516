import { Injectable } from '@angular/core';
import { StateToken, State, Store, Action, StateContext } from '@ngxs/store';
import { patch, insertItem, updateItem, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import {
  SetProgressOn,
  SetProgressOff,
} from 'src/app/core/store/progress-status.actions';
import { Workflow, WorkflowWithStep, WorkflowFilter } from '../models/workflow.model';
import { WorkflowService } from '../services/workflow.service';
import {
  InitiateWorkflow,
  GetWorkflows,
  SetSearch,
  GetMyWorkFlows,
  GetOwnedWorkFlows,
  SetWorkflowSearchingMode,
  SearchWorkflows,
  SetWorkflowSearchTerm,
  DownloadWorkflow,
  SetSelectedWorkflow,
  FilterWorkflows,
  CancelWorkflow,
  GetArchivedWorkflows,
  DownloadArchivedWorkflow,
  GetWorkflowFilter,
  GetArchivedWorkflowsByStatus,
  ArchiveWorkflow,
  GetRejectedWorkFlows
} from './workflow.actions';

export interface WorkflowStateModel {
  workflows: PaginatedList<Workflow>;
  archivedWorkflows?: PaginatedList<Workflow>;
  myworkflows: PaginatedList<Workflow>;
  ownedWorkflows: PaginatedList<Workflow>;
  search: boolean;
  filteredWorkflows: PaginatedList<Workflow>;
  totalCount: number;
  isSearchingWorkflow?: boolean;
  workflowSearchTerm?: string;
  selectedWorkflow: Workflow | undefined;
  workflowWithStep: PaginatedList<WorkflowWithStep>;
  workflowFilters:WorkflowFilter[];
  rejectedWorkflows: PaginatedList<Workflow>;
}

const WORKFLOW_STATE_TOKEN = new StateToken<WorkflowStateModel>(
  'workflowState',
);

const defaults: WorkflowStateModel = {
  workflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  myworkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  ownedWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  filteredWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  search: false,
  totalCount: 0,
  selectedWorkflow: undefined,
  workflowWithStep:  {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  workflowFilters: [],
  rejectedWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowStateModel>({
  name: WORKFLOW_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowState {
  constructor(
    public workflowService: WorkflowService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(InitiateWorkflow)
  initiateWorkflow(
    { setState, getState }: StateContext<WorkflowStateModel>,
    { initWorkflow }: InitiateWorkflow,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().workflows.totalCount;
    let pageSize =
      getState().workflows.totalCount / getState().workflows.totalPages;
    return this.workflowService.initiateWorkflow(initWorkflow).pipe(
      tap((createdWorkflow: Workflow) => {
        setState(
          patch({
            workflows: patch({
              items: insertItem(createdWorkflow),
              totalCount: (state) => prevLength + 1,
              totalPages: (state) => Math.ceil((prevLength + 1) / pageSize),
            }),
          }),
        );
        this.store.dispatch(new SetSelectedWorkflow(createdWorkflow));
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow.initiated-workflow:workflow Initiated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetWorkflows)
  getWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    { pageNumber, pageSize }: GetWorkflows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getWorkflows(pageNumber, pageSize).pipe(
      tap((paginatedWorkflows) => {
        patchState({
          workflowWithStep: paginatedWorkflows,
          workflows: paginatedWorkflows,
          totalCount: paginatedWorkflows.totalCount,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSearch)
  setSearch(
    { patchState }: StateContext<WorkflowStateModel>,
    { isSearch }: SetSearch,
  ) {
    patchState({
      search: isSearch,
    });
  }

  @Action(GetMyWorkFlows)
  getMyWorkFlows(
    { patchState }: StateContext<WorkflowStateModel>,
    { pageNumber, pageSize }: GetMyWorkFlows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getMyWorkFlows(pageNumber, pageSize).pipe(
      tap((workflows) => {
        patchState({
          myworkflows: workflows,
          totalCount: workflows.totalCount,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetOwnedWorkFlows)
  getOwnedWorkFlows(
    { patchState }: StateContext<WorkflowStateModel>,
    { pageNumber, pageSize }: GetOwnedWorkFlows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getOwnedWorkFlows(pageNumber, pageSize).pipe(
      tap((workflows) => {
        patchState({
          ownedWorkflows: workflows,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(CancelWorkflow)
  cancelWorkflow(
    { patchState, setState }: StateContext<WorkflowStateModel>,
    { workflowId }: CancelWorkflow,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.cancelWorkflow(workflowId).pipe(
      tap((cancelledWorkflow) => {
        setState(
          patch({
            workflows: patch({
              items: removeItem(
                (workflow) => workflow.id === cancelledWorkflow.id,
              ),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@user.workflow.workflow-cancelled-successfully: Workflow Cancelled Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ArchiveWorkflow)
  archiveWorkflow(
    { patchState, setState }: StateContext<WorkflowStateModel>,
    { workflowId }: CancelWorkflow,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.archiveWorkflow(workflowId).pipe(
      tap((archivedWorkflow) => {
        setState(
          patch({
            workflows: patch({
              items: removeItem(
                (workflow) => workflow.id === archivedWorkflow.id,
              ),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@user.workflow.workflow-archived-successfully: Workflow archived successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetWorkflowSearchingMode)
  setWorkflowSearchingMode(
    { patchState }: StateContext<WorkflowStateModel>,
    { isSearchingWorkflow }: SetWorkflowSearchingMode,
  ) {
    patchState({
      isSearchingWorkflow: isSearchingWorkflow,
    });
  }

  @Action(SearchWorkflows)
  searchWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    { workflowName, pageNumber, pageSize }: SearchWorkflows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService
      .searchWorkflows(workflowName, pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflows) => {
          patchState({
            workflows: paginatedWorkflows,
            totalCount: paginatedWorkflows.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetWorkflowSearchTerm)
  setWorkflowSearchTerm(
    { patchState }: StateContext<WorkflowStateModel>,
    { workflowSearchTerm }: SetWorkflowSearchTerm,
  ) {
    patchState({
      workflowSearchTerm: workflowSearchTerm,
    });
  }

  @Action(DownloadWorkflow)
  downloadWorkflow(
    { patchState }: StateContext<WorkflowStateModel>,
    { workflowId }: DownloadWorkflow,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService
      .downloadWorkflow(workflowId)
      .subscribe(async (blob: any) => {
        const newblob = new Blob([blob], {
          type: 'application/json',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'workflow_' + workflowId + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);

        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(SetSelectedWorkflow)
  setSelectedWorkflow(
    { patchState }: StateContext<WorkflowStateModel>,
    { workflow }: SetSelectedWorkflow,
  ) {
    patchState({ selectedWorkflow: workflow });
  }

  @Action(FilterWorkflows)
  filterWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    { filterParams }: FilterWorkflows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.filterWorkFlows(filterParams).pipe(
      tap((paginatedWorkflows) => {
        patchState({
          filteredWorkflows: paginatedWorkflows,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetArchivedWorkflows)
  GetArchivedWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    { pageNumber, pageSize }: GetArchivedWorkflows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getArchivedWorkFlows(pageNumber, pageSize).pipe(
      tap((val) => {
        patchState({ archivedWorkflows: val });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DownloadArchivedWorkflow)
  downloadArchivedWorkflow(
    { patchState }: StateContext<WorkflowStateModel>,
    { workflowId }: DownloadWorkflow,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService
      .downloadArchivedWorkflow(workflowId)
      .subscribe(async (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);

        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(GetWorkflowFilter)
  getWorkflowFilter(
    { patchState }: StateContext<WorkflowStateModel>,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getWorkflowFilters().pipe(
      tap((val) => {
        patchState({ workflowFilters: val });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetArchivedWorkflowsByStatus)
  getArchivedWorkflowsByStatus(
    { patchState }: StateContext<WorkflowStateModel>,
    { status, pageNumber, pageSize }: GetArchivedWorkflowsByStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getArchivedWorkFlowsByStatus(status, pageNumber, pageSize).pipe(
      tap((paginatedWorkflows) => {
        patchState({
          archivedWorkflows:paginatedWorkflows,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetRejectedWorkFlows)
  getRejectedWorkFlows(
    { patchState }: StateContext<WorkflowStateModel>,
    { pageNumber, pageSize }: GetRejectedWorkFlows,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowService.getRejectedWorkFlows(pageNumber, pageSize).pipe(
      tap((workflows) => {
        patchState({
          ownedWorkflows: workflows,
          rejectedWorkflows: workflows,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
