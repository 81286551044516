import { Component, OnInit } from '@angular/core';
import {
  AFAAN_OROMOO_LANGUAGE,
  AFAR_LANGUAGE,
  AMHARIC_LANGUAGE,
  ENGLISH_LANGUAGE,
  SOOMAALI_LANGUAGE,
  TIGRI_LANGUAGE,
} from '../constants/language';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent implements OnInit {
  englishLanguage = ENGLISH_LANGUAGE;
  amharicLanguage = AMHARIC_LANGUAGE;
  afanOromoLanguage = AFAAN_OROMOO_LANGUAGE;
  tigriLanguage = TIGRI_LANGUAGE;
  soomaaliLanguage = SOOMAALI_LANGUAGE;
  afarLanguage = AFAR_LANGUAGE;

  currentLanguage: string =
    localStorage.getItem('locale') ?? this.englishLanguage.locale;

  ngOnInit(): void {}

  changeLocale(locale: string) {
    // Store the user's preferred locale in localStorage
    localStorage.setItem('locale', locale);

    // Reload the page to apply the new locale
    location.reload();
  }
}
