import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PUBLICATION_OPTION_URL } from 'src/app/core/constants/api-endpoints';
import { PublicationOption } from '../models/publication-option.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicationOptionService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getPublicationOptions() {
    return this.http.get<PublicationOption[]>(`${PUBLICATION_OPTION_URL}`);
  }

  createPublicationOption(publicationOption: any) {
    return this.http.post<PublicationOption>(
      `${PUBLICATION_OPTION_URL}`,
      publicationOption,
    );
  }
  updatePublicationOption(publicationOption: any) {
    return this.http.put<PublicationOption>(
      `${PUBLICATION_OPTION_URL}`,
      publicationOption,
    );
  }

  deleteTemplates(id: string, templatesIds: string[]) {
    return this.http.delete(`${PUBLICATION_OPTION_URL}/template/${id}`, {
      body: templatesIds,
    });
  }

  deletePublicationOption(id: string) {
    return this.http.delete(`${PUBLICATION_OPTION_URL}/${id}`);
  }

  downloadPublicationOption(id: string) {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Blob>(
      `${PUBLICATION_OPTION_URL}/templates/${id}`,
      options,
    );
  }

  isPublicationOptionBeingUser() {
    return this.http.get<string[]>(`${PUBLICATION_OPTION_URL}/is-used`);
  }

  banPublicationOption(id: string) {
    return this.http.delete(`${PUBLICATION_OPTION_URL}/ban/${id}`);
  }

  permitPublicationOption(id: string) {
    return this.http.put(`${PUBLICATION_OPTION_URL}/permit/${id}`, this.httpOptions);
  }
}
