<mat-card class="card">
  <mat-card-header>
    <mat-card-title i18n="@@researches.office-vs-evaluation.department-with-evaluated-researches">Department with evaluated Researches </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-paginator
      [length]="
        (evaluatedProcessInstanceCountByDepartment$ | async)?.totalCount
      "
      [pageIndex]="
        ((evaluatedProcessInstanceCountByDepartment$ | async)?.pageNumber ??
          1) - 1
      "
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="paginateEvaluatedProcessInstanceCountByDepartment($event)"
    >
    </mat-paginator>
    <table
      mat-table
      [dataSource]="
        (evaluatedProcessInstanceCountByDepartment$ | async)?.items ?? []
      "
      class="table"
    >
      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.office-vs-evaluation.department">Department</th>
        <td mat-cell *matCellDef="let element">
          {{ element.officeName.substring(0, 20) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalCount">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.office-vs-evaluation.total">Total</th>
        <td mat-cell *matCellDef="let element">{{ element.totalCount }}</td>
      </ng-container>

      <ng-container matColumnDef="ongoingCount">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.office-vs-evaluation.ongoing">Ongoing</th>
        <td mat-cell *matCellDef="let element">{{ element.ongoingCount }}</td>
      </ng-container>

      <ng-container matColumnDef="passedCount">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.office-vs-evaluation.passed">Passed</th>
        <td mat-cell *matCellDef="let element">{{ element.passedCount }}</td>
      </ng-container>

      <ng-container matColumnDef="failedCount">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.office-vs-evaluation.failed">Failed</th>
        <td mat-cell *matCellDef="let element">{{ element.failedCount }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>
