import { Component, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Workflow, WorkflowFilter, WorkflowStatus } from '../../models/workflow.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

interface ArchivedComponentState {
  archivedWorkflows?: PaginatedList<Workflow>;
  workflowFilters: WorkflowFilter[];
}
const initialState: ArchivedComponentState = {
  workflowFilters:[]
};

@Component({
  selector: 'app-archived-workflows',
  templateUrl: './archived-workflows.component.html',
  styleUrls: ['./archived-workflows.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ArchivedWorkflows}],
})
export class ArchivedWorkflows implements MatPaginatorIntl {
  archivedWorkflows$ = this.state.select('archivedWorkflows');
  archivedWorkflows: PaginatedList<Workflow> | undefined;

  selectedStatus:string = "Completed";
  workflowFilters: WorkflowFilter[] = []
  workflowFilters$ = this.state.select('workflowFilters');

  filterForm: FormGroup;
  pageSize: number = 10;
  pageIndex: number = 0;

  firstPageLabel = $localize`:@@documents.archived-workflows.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.archived-workflows.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.archived-workflows.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.archived-workflows.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.archived-workflows.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.archived-workflows.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.archived-workflows.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.archived-workflows.page-part-two: of` + `${amountPages}`;
    }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    private state: RxState<ArchivedComponentState>,
    private workFlowFacade: WorkflowFacade,
    private fb: FormBuilder,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'archivedWorkflows',
      this.workFlowFacade.archivedWorkflows$,
    );
    this.state.connect('workflowFilters', this.workFlowFacade.workflowFilters$);

    this.filterForm = this.fb.group({
      filter: [],
    });
  }
  changes = new Subject<void>();
  ngOnInit() {
    this.workFlowFacade.dispatchGetWorkflowFilter();
    this.workFlowFacade.dispatchGetArchivedWorkflowsByStatus(WorkflowStatus.Completed, 1,10)

    this.workflowFilters$.subscribe((filters) => {
      this.workflowFilters = filters.filter(status => {
        const translations = status.translations.find(tr => tr.cultureName == 'en-US');
        return translations && (translations.workflowStatusForDisplay == "Cancelled" || translations.workflowStatusForDisplay == "Completed" || translations.workflowStatusForDisplay == "Rejected");
      });
    });

    this.filterForm.patchValue({ filter:this.workflowFilters.filter(status => {
      const translations = status.translations.find(tr => tr.cultureName == 'en-US');
      return translations && translations.workflowStatusForDisplay == "Completed";
    })[0].id});

    this.archivedWorkflows$.subscribe((archivedWorkFlows) => {
      if (archivedWorkFlows) {
        this.archivedWorkflows = archivedWorkFlows;
        this.pageIndex = archivedWorkFlows.pageNumber - 1;
      }
    });
  }
  loadPaginatedWorkflows(event: PageEvent) {
    this.workFlowFacade.dispatchGetArchivedWorkflowsByStatus(this.selectedStatus,event.pageIndex + 1,
      event.pageSize,)
  }

  onFilterSelect(event: MatSelectChange) {
    this.selectedStatus = this.workflowFilters.find(status => status.id == event.value)?.translations.find(tr => tr.cultureName == 'en-US')?.workflowStatusForDisplay as string;
    this.workFlowFacade.dispatchGetArchivedWorkflowsByStatus(this.selectedStatus,1,10)
  }
}
