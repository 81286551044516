import { DOCUMENT_TEMPLATES_ROUTE } from '../../../../core/constants/routes';
import { UpdateDocumentFormDto } from '../../models/document-forms.model';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import {
  CreateDocumentForm,
  DocumentForm,
} from '../../models/document-forms.model';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { Observable, tap } from 'rxjs';
import { FormFieldFormComponent } from 'src/app/documents/template-fields/components/form-field-form/form-field-form.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { Router } from '@angular/router';

interface DocumentFormFormComponentState {
  updateStatus: boolean;
  selectedDocumentForm: DocumentForm | undefined;
  selectedFormFields: FormFields[];
}

@Component({
  selector: 'app-document-form-step-form',
  templateUrl: './document-form-step-form.component.html',
  styleUrls: ['./document-form-step-form.component.scss'],
})
export class DocumentFormStepFormComponent implements OnInit {
  @Input()
  templateForm!: FormGroup;

  focusedInput: string = '';
  saveDraft = false;
  saveButtonText: string = 'Create Form';
  formTitle: string = 'Create Form';
  updateStatus$: Observable<boolean> = this.state.select('updateStatus');
  updateStatus = false;
  selectedDocumentForm$: Observable<DocumentForm | undefined> =
    this.state.select('selectedDocumentForm');
  selectedDocumentForm: DocumentForm | undefined;
  selectedFormFields: FormFields[] = [];
  selectedFormFields$: Observable<FormFields[]> = this.state.select(
    'selectedFormFields',
  );

  placeholderFieldLabel = {
    templateNameField: $localize`:@@documents.document-form-step-form.form-name: Form Name`,
    templateDescriptionField: $localize`:@@documents.document-form-step-form.this-is-a-description-for-the-form: This is description for the form`,
}

  constructor(
    private dialog: MatDialog,
    public sanitizerService: SanitizerService,
    private router: Router,
    private documentFormFacade: DocumentFormFacade,
    private state: RxState<DocumentFormFormComponentState>,
    private templateFieldsFacade: FormFieldsFacade,
  ) {
    this.state.connect(
      'updateStatus',
      this.documentFormFacade.updateStatus$,
    );
    this.state.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
    this.state.connect(
      'selectedFormFields',
      this.templateFieldsFacade.selectedFormFields$,
    );
  }
  ngOnInit(): void {
    this.templateFieldsFacade.dispatchResetSelectedFormFields();
    this.templateFieldsFacade.dispatchSetSelectedFormField(undefined);
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(false);

    this.updateStatus$.subscribe((status) => {
      this.updateStatus = status;
    });
    this.selectedDocumentForm$.subscribe((documentForm) => {
      this.selectedDocumentForm = documentForm;
    });
    if (this.updateStatus && this.selectedDocumentForm) {
      this.templateFieldsFacade.dispatchSelectFormFields(
        this.selectedDocumentForm.templateFields,
      );
      this.templateForm.setValue({
        name: this.selectedDocumentForm.name,
        description: this.selectedDocumentForm.description,
      });
      this.selectedFormFields =
        this.selectedDocumentForm.templateFields;
      if (!this.isEditingDraftForm) {
        this.saveButtonText = 'Update Form';
        this.formTitle = this.saveButtonText;
      }
    }

    this.selectedFormFields$.subscribe((selectedFormFields) => {
      this.selectedFormFields = selectedFormFields;
    });
  }

  handleSpaceEvent(event: KeyboardEvent) {
    const val = this.templateForm.get(this.focusedInput)?.value;
    this.sanitizerService.spaceSanitizer(event, val);
  }
  get isEditingDraftForm(): boolean {
    return (
      this.updateStatus &&
      !!this.selectedDocumentForm &&
      this.selectedDocumentForm.status.status === FormStatusOption.DRAFT
    );
  }

  save() {
    const { valid, touched, dirty } = this.templateForm;
    if (valid && (touched || dirty)) {
      if (this.updateStatus && this.selectedDocumentForm) {
        let updatedForm: UpdateDocumentFormDto = {
          id: this.selectedDocumentForm.id!,
          name: this.templateForm.value.name,
          description: this.templateForm.value.description,
          status: this.saveDraft
            ? { status: FormStatusOption.DRAFT, comment: undefined }
            : { status: FormStatusOption.SUBMITTED, comment: undefined },
          templateFields: this.selectedFormFields,
        };
        if (!this.saveDraft) {
          this.router.navigate([DOCUMENT_TEMPLATES_ROUTE]);
        }
        this.documentFormFacade.dispatchUpdateDocumentForm(
          updatedForm,
        );
        this.documentFormFacade.dispatchSetUpdateStatus(false);
        this.saveDraft = false;
      } else if (this.saveDraft && !this.updateStatus) {
        const template: CreateDocumentForm = {
          name: this.templateForm.value.name,
          description: this.templateForm.value.description,
          status: { status: FormStatusOption.DRAFT, comment: undefined },
          templateFields: this.selectedFormFields,
        };
        this.documentFormFacade.dispatchSaveForm(template);
        this.saveDraft = false;
      } else {
        const template: CreateDocumentForm = {
          name: this.templateForm.value.name,
          description: this.templateForm.value.description,
          status: {
            status: FormStatusOption.SUBMITTED,
            comment: undefined,
          },
          templateFields: this.selectedFormFields,
        };
        this.documentFormFacade.dispatchCreateForm(template);
      }
    }

    this.documentFormFacade.dispatchSetTempalteCreatingMode(false);
    this.dialog.closeAll();
  }

  editFormField(field: FormFields) {
    this.templateFieldsFacade.dispatchSetSelectedFormField(field);
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(true);
    this.dialog.open(FormFieldFormComponent, SIDE_DIALOG_CONFIG);
  }

  removeFormField(field: FormFields) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize` :@@documents.document-form-step-form.remove-form-field-confirm-part-one:Are you sure you want to delete `,
        boldText: ` "${field.name}" `,
        regularTextTwo: $localize `:@@documents.document-form-step-form.remove-form-field-confirm-part-two:form field?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        this.templateFieldsFacade.dispatchRemoveFormField(field);
      }
    });
  }
}
