import { Selector, createPropertySelectors } from '@ngxs/store';
import { MemoState, MemoStateModel } from './memo.state';

export class MemoSelector {
  static slices = createPropertySelectors<MemoStateModel>(MemoState);

  @Selector([MemoState])
  static memosSent(stateModel: MemoStateModel) {
    return stateModel.memosSent;
  }

  @Selector([MemoState])
  static draftMemo(stateModel: MemoStateModel) {
    return stateModel.draftMemo;
  }
  
  @Selector([MemoState])
  static memosCcedToMe(stateModel: MemoStateModel) {
    return stateModel.memosCcedToMe;
  }
  @Selector([MemoState])
  static parentMemo(stateModel: MemoStateModel) {
    return stateModel.parentMemo;
  }
  @Selector([MemoState])
  static memosReceived(stateModel: MemoStateModel) {
    return stateModel.memosReceived;
  }

  @Selector([MemoState])
  static pageNumber(stateModel: MemoStateModel) {
    return stateModel.pageNumber;
  }

  @Selector([MemoState])
  static pageSize(stateModel: MemoStateModel) {
    return stateModel.pageSize;
  }

  @Selector([MemoState])
  static selectedMemo(stateModel: MemoStateModel) {
    return stateModel.selectedMemo;
  }

  @Selector([MemoState])
  static selectedMemoAccessDetail(stateModel: MemoStateModel) {
    return stateModel.selectedMemoAccessDetail;
  }

  @Selector([MemoState])
  static isSearchingMemo(stateModel: MemoStateModel) {
    return stateModel.isSearchingMemo;
  }

  @Selector([MemoState])
  static memoFilters(stateModel: MemoStateModel) {
    return stateModel.memoFilters;
  }
}
