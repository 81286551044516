<div class="row">
  <button
    class="update-btn"
    mat-flat-button
    color="primary"
    [disabled]="!toBeEdited?.id"
    mat-dialog-close
    (click)="openChangeParentForm()"
    *appHasPermission="hasUpdateOfficeTreePermission()"
    i18n="@@offices.update-structure.update-office-structure"
    [attr.data-test-id]="
      'offices.update-office-structure.update-structure.update-office-structure-button'
    "
  >
    Update Office Structure
  </button>
</div>
<mat-radio-group>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      [style.display]="shouldRender(node) ? 'flex' : 'none'"
      class="tree-node"
    >
      <button mat-icon-button disabled></button>
      <mat-radio-button
        [disabled]="node.level === 0"
        [value]="node"
        [checked]="node?.id === toBeEdited?.id"
        (change)="selectFlatOfficeNode($event, node)"
        >{{ node.name }}</mat-radio-button
      >
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      [style.display]="shouldRender(node) ? 'flex' : 'none'"
      class="tree-node"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
        (click)="node.isExpanded = !node.isExpanded"
        [style.visibility]="node.expandable ? 'visible' : 'hidden'"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-radio-button
        [disabled]="node.level === 0"
        [value]="node"
        [checked]="node?.id === toBeEdited?.id"
        (change)="selectFlatOfficeNode($event, node)"
        >{{ node.name }}</mat-radio-button
      >
    </mat-tree-node>
  </mat-tree>
</mat-radio-group>
