import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  OrderWorkflowTypeSteps,
  WorkflowTypeStepDetail,
  WorkflowTypeStepDto,
} from '../models/workflow-type-steps.model';
import {
  AddNotifyOfficesToWorkflowTypeStep,
  DeleteWorkflowTypeStep,
  GetWorkFlowTypeSteps,
  GetWorkflowTypeStepDetail,
  OrderWorkflowTypeStep,
  SetSelectedWorkflowTypeStep,
  SetSelectedWorkflowTypeStepDocumentTemplate,
  SetSelectedWorkflowTypeStepDocumentTemplateFields,
  SetUpdateStatus,
  UpdateWorkflowTypeStep,
} from './workflow-type-steps.actions';
import { CreateWorkflowTypeStep } from './workflow-type-steps.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { tap } from 'rxjs';
import { WorkflowTypeStepsService } from '../services/workflow-type-steps.service';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { FormFields } from '../../template-fields/models/form-fields.model';

export interface WorkflowTypeStepStateModel {
  WorkFlowTypeSteps: PaginatedList<WorkflowTypeStepDto>;
  selectedWorkflowTypeStep: WorkflowTypeStepDto | undefined;
  selectedWorkflowTypeStepDocumentTemplate: DocumentForm | undefined;
  selectedWorkflowTypeStepDocumentTemplateFields: FormFields[] | undefined;
  workflowTypeStepDetail: WorkflowTypeStepDetail | undefined
  update: boolean;
  totalCount: number;
}

const WORKFLOW_TYPE_STEP_TOKEN = new StateToken<WorkflowTypeStepStateModel>(
  'workflowTypeStepStateModel',
);

const defaults: WorkflowTypeStepStateModel = {
  WorkFlowTypeSteps: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  totalCount: 0,
  selectedWorkflowTypeStep: undefined,
  selectedWorkflowTypeStepDocumentTemplate: undefined,
  selectedWorkflowTypeStepDocumentTemplateFields: undefined,
  update: false,
  workflowTypeStepDetail: undefined
};

@State<WorkflowTypeStepStateModel>({
  name: WORKFLOW_TYPE_STEP_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowTypeStepState {
  constructor(
    private workflowTypeStepService: WorkflowTypeStepsService,
    public operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetWorkFlowTypeSteps)
  getWorkFlowSteps(
    { setState, patchState }: StateContext<WorkflowTypeStepStateModel>,
    { id, pageNumber, pageSize }: GetWorkFlowTypeSteps,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypeStepService
      .getWorkFlowTypeSteps(id, pageNumber, pageSize)
      .pipe(
        tap((paginatedWorkflowTypeSteps) => {
          patchState({
            WorkFlowTypeSteps: paginatedWorkflowTypeSteps,
            totalCount: paginatedWorkflowTypeSteps.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateWorkflowTypeStep)
  createWorkflowTypeStep(
    { setState, getState }: StateContext<WorkflowTypeStepStateModel>,
    { workflowTypeStep }: CreateWorkflowTypeStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().WorkFlowTypeSteps.totalCount;
    let pageSize =
      getState().WorkFlowTypeSteps.totalCount /
      getState().WorkFlowTypeSteps.totalPages;
    return this.workflowTypeStepService
      .createWorkflowTypeStep(workflowTypeStep)
      .pipe(
        tap((workflowTypeStep) => {
          setState(
            patch({
              WorkFlowTypeSteps: patch({
                items: insertItem(workflowTypeStep),
                totalCount: (state) => prevLength + 1,
                totalPages: (state) => Math.ceil((prevLength + 1) / pageSize),
              }),
              totalCount: (state) => getState().totalCount + 1,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-type-steps.created-workflow-type-step:Workflow Type Step Created Successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(UpdateWorkflowTypeStep)
  updateWorkflowTypeStep(
    {
      setState,
      getState,
      patchState,
    }: StateContext<WorkflowTypeStepStateModel>,
    { workflowTypeStep }: UpdateWorkflowTypeStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypeStepService
      .updateWorkflowTypeStep(workflowTypeStep)
      .pipe(
        tap((updatedWorkflowTypeStep) => {
          setState(
            patch({
              WorkFlowTypeSteps: patch({
                items: updateItem(
                  (us) => us.id == updatedWorkflowTypeStep.id,
                  updatedWorkflowTypeStep,
                ),
              }),
            }),
          );
          patchState({
            update: false,
          });
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-type-steps.updated-workflow-type-step:Workflow Type Step Updated Successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    { update }: SetUpdateStatus,
  ) {
    setState(
      patch({
        update: update,
      }),
    );
  }

  @Action(SetSelectedWorkflowTypeStep)
  setSelectedWorkflowTypeStep(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    { workflowTypeStep }: SetSelectedWorkflowTypeStep,
  ) {
    setState(
      patch({
        selectedWorkflowTypeStep: workflowTypeStep,
      }),
    );
  }

  @Action(SetSelectedWorkflowTypeStepDocumentTemplate)
  setSelectedWorkflowTypeStepDocumentTemplate(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    {
      workflowTypeStepDocumentTemplate,
    }: SetSelectedWorkflowTypeStepDocumentTemplate,
  ) {
    setState(
      patch({
        selectedWorkflowTypeStepDocumentTemplate:
          workflowTypeStepDocumentTemplate,
      }),
    );
  }

  @Action(SetSelectedWorkflowTypeStepDocumentTemplateFields)
  setSelectedWorkflowTypeStepDocumentTemplateFields(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    { templateFields }: SetSelectedWorkflowTypeStepDocumentTemplateFields,
  ) {
    setState(
      patch({
        selectedWorkflowTypeStepDocumentTemplateFields: templateFields,
      }),
    );
  }

  @Action(DeleteWorkflowTypeStep)
  deleteProcess(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    { workflowTypeStepId }: DeleteWorkflowTypeStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypeStepService
      .deleteWorkflowTypeStep(workflowTypeStepId)
      .pipe(
        tap(() => {
          setState(
            patch({
              WorkFlowTypeSteps: patch({
                items: removeItem((workflowTypeStep) => {
                  return workflowTypeStep.id === workflowTypeStepId;
                }),
              }),
            }),
          );
          this.operationStatus.displayStatus(
             $localize`:@@documents.workflow-type-steps.deleted-workflow-type-step:Workflow Type Deleted Successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AddNotifyOfficesToWorkflowTypeStep)
  addNotifyOfficesToWorkflowTypeStep(
    { setState }: StateContext<WorkflowTypeStepStateModel>,
    { cc }: AddNotifyOfficesToWorkflowTypeStep,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowTypeStepService
      .addNotifyOfficesToWorkflowTypeStep(cc)
      .pipe(
        tap(() => {
          this.operationStatus.displayStatus(
             $localize`:@@documents.workflow-type-steps.step-cc-office:step cc'd to the offices Successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(OrderWorkflowTypeStep)
  orderSteps(
    { patchState }: StateContext<WorkflowTypeStepStateModel>,
    { orderSteps }: OrderWorkflowTypeStep,
  ) {
    return this.workflowTypeStepService
      .orderWorkflowTypeSteps(orderSteps)
      .pipe(tap(() => {}));
  }

  @Action(GetWorkflowTypeStepDetail)
  getWorkflowTypeStepDetail(
    {setState}: StateContext<WorkflowTypeStepStateModel>,
    {id}: GetWorkflowTypeStepDetail
  ){
    this.store.dispatch(new SetProgressOn())
    return this.workflowTypeStepService.getWorkflowTypeStepDetail(id).pipe(
      tap((workflowTypeStep) => {
        setState(
          patch({
            workflowTypeStepDetail: workflowTypeStep
          })
        )
        this.store.dispatch(new SetProgressOff())
      })
    )
  }
}
