import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { Injectable } from '@angular/core';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowAverageTimeSpent } from '../models/workflow-average-time-spent.model';
import { WorkflowAverageTimeSpentService } from '../services/workflow-average-time-spent.service.service';
import { GetWorkflowAverageTimeSpent } from './workflow-average-time-spent.action';

export interface WorkflowAverageTimeSpentStateModel {
  workflowAverageTimeSpents: PaginatedList<WorkflowAverageTimeSpent>;
}

const WORKFLOW_AVERAGE_TIME_SPENT_STATE_TOKEN =
  new StateToken<WorkflowAverageTimeSpentStateModel>(
    'workflowAverageTimeSpentStateState',
  );

const defaults: WorkflowAverageTimeSpentStateModel = {
  workflowAverageTimeSpents: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowAverageTimeSpentStateModel>({
  name: WORKFLOW_AVERAGE_TIME_SPENT_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowAverageTimeSpentState {
  constructor(
    private store: Store,
    private operationStatus: OperationStatusService,
    private workflowAverageTimeSpentService: WorkflowAverageTimeSpentService,
  ) {}

  @Action(GetWorkflowAverageTimeSpent)
  getReportedComments(
    { patchState }: StateContext<WorkflowAverageTimeSpentStateModel>,
    { pageNumber, pageSize, workflowTypeId }: GetWorkflowAverageTimeSpent,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowAverageTimeSpentService
      .getWorkflowAverageTimeSpent(pageNumber, pageSize, workflowTypeId)
      .pipe(
        tap(
          (
            workflowAverageTimeSpents: PaginatedList<WorkflowAverageTimeSpent>,
          ) => {
            patchState({
              workflowAverageTimeSpents: workflowAverageTimeSpents,
            });
            this.store.dispatch(new SetProgressOff());
          },
        ),
      );
  }
}
