import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InitiatedWorkflowData, WorkflowData } from '../models/workflow.model';
import { DOCUMENTS_ANALYTICS_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getInitiatedWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    userId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
    userName?: string,
  ): Observable<PaginatedList<InitiatedWorkflowData>> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize)
      .set('workflowTypeId', workflowTypeId || '')
      .set('officeId', officeId || '')
      .set('userId', userId || '')
      .set('status', status || '')
      .set('userName', userName || '');

    if (initiatedStart) {
      params = params.set('initiatedStart', initiatedStart.toISOString());
    }
    if (initiatedEnd) {
      params = params.set('initiatedEnd', initiatedEnd.toISOString());
    }
    if (completedStart) {
      params = params.set('completedStart', completedStart.toISOString());
    }
    if (completedEnd) {
      params = params.set('completedEnd', completedEnd.toISOString());
    }

    return this.http.get<PaginatedList<InitiatedWorkflowData>>(
      `${DOCUMENTS_ANALYTICS_URL}/workflows`,

      { params },
    );
  }

  getMyInitiatedWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
  ): Observable<PaginatedList<InitiatedWorkflowData>> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize)
      .set('workflowTypeId', workflowTypeId || '')
      .set('officeId', officeId || '')
      .set('status', status || '');

    if (initiatedStart) {
      params = params.set('initiatedStart', initiatedStart.toISOString());
    }
    if (initiatedEnd) {
      params = params.set('initiatedEnd', initiatedEnd.toISOString());
    }
    if (completedStart) {
      params = params.set('completedStart', completedStart.toISOString());
    }
    if (completedEnd) {
      params = params.set('completedEnd', completedEnd.toISOString());
    }

    return this.http.get<PaginatedList<InitiatedWorkflowData>>(
      `${DOCUMENTS_ANALYTICS_URL}/my-workflows`,
      { params },
    );
  }

  getMyWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
  ): Observable<PaginatedList<WorkflowData>> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize)
      .set('workflowTypeId', workflowTypeId || '')
      .set('officeId', officeId || '')
      .set('status', status || '');

    if (initiatedStart) {
      params = params.set('initiatedStart', initiatedStart.toISOString());
    }
    if (initiatedEnd) {
      params = params.set('initiatedEnd', initiatedEnd.toISOString());
    }
    if (completedStart) {
      params = params.set('completedStart', completedStart.toISOString());
    }
    if (completedEnd) {
      params = params.set('completedEnd', completedEnd.toISOString());
    }

    return this.http.get<PaginatedList<WorkflowData>>(
      `${DOCUMENTS_ANALYTICS_URL}/involved-workflows`,
      { params },
    );
  }
}
