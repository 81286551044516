import { ProcessTasks } from '../models/process-tasks.model';
import {
  TaskDocumnet,
  Task,
  CreateTaskDto,
  UpdateTaskStatusDto,
  AssignTaskDto,
  TaskComment,
} from '../models/task.model';
import { TaskDetailFrom } from './task.state';

export class GetTasksBySearchTerm {
  static readonly type = `[Task] ${GetTasksBySearchTerm.name}`;
  constructor(
    public searchTerm: string,
    public processIds: string[],
  ) {}
}

export class GetTaskByName {
  static readonly type = `[Task] ${GetTaskByName.name}`;
  constructor(
    public name: string,
    public stageInstanceId: string,
  ) {}
}

export class ToggleTaskDetailFrom {
  static readonly type = `[Task] ${ToggleTaskDetailFrom.name}`;
  constructor(public taskDetailFrom: TaskDetailFrom) {}
}
export class GetListOfTasksWithInProcessType {
  static readonly type = `[Task] ${GetListOfTasksWithInProcessType.name}`;
  constructor(public processId: string) {}
}
export class ToggleCardContent {
  static readonly type = `[Task] ${ToggleCardContent.name}`;
  constructor(public showCardContent: boolean[]) {}
}

export class CreateTaskDocument {
  static readonly type = `'[TaskDocument] ${CreateTaskDocument.name}`;
  constructor(public taskDoc: TaskDocumnet) {}
}

export class UpdateTaskDocument {
  static readonly type = `[TaskDocument] ${UpdateTaskDocument.name}`;
  constructor(public document: TaskDocumnet) {}
}
export class DeleteDocumentTask {
  static readonly type = `[TaskDocument] ${DeleteDocumentTask.name}`;
  constructor(public id: string) {}
}

export class CreateTask {
  static readonly type = `[Task] ${CreateTask.name}`;
  constructor(public task: CreateTaskDto) {}
}

export class GetTaskDetail {
  static readonly type = `[Task] ${GetTaskDetail.name}`;
  constructor(public taskId: string) {}
}
export class DeleteTaskFromMyTasks {
  static readonly type = `[Task] ${DeleteTaskFromMyTasks.name}`;
  constructor(
    public processInstanceId: string,
    public selectedTaskId: string,
  ) {}
}

export class SetSelectedTask {
  static readonly type = `[Task] ${SetSelectedTask.name}`;
  constructor(public task: Task) {}
}

export class SetTaskList {
  static readonly type = `[Task] ${SetTaskList.name}`;
  constructor(public taskList: Task[]) {}
}

export class ResetSelectedTask {
  static readonly type = `[Task] ${ResetSelectedTask.name}`;
  constructor() {}
}

export class ResetSelectedProcessInstanceTask {
  static readonly type = `[ProcessTask] ${ResetSelectedProcessInstanceTask.name}`;
  constructor() {}
}

export class SetSelectedProcessInstanceTask {
  static readonly type = `[ProcessTask] ${SetSelectedProcessInstanceTask.name}`;
  constructor(public processTask: ProcessTasks) {}
}

export class DeleteTaskFromStageDetail {
  static readonly type = `[Task] ${DeleteTaskFromStageDetail.name}`;
  constructor(public taskId: string) {}
}

export class UploadTaskFile {
  static readonly type = `[Task] ${UploadTaskFile.name}`;
  constructor(
    public file: FormData,
    public taskId: string,
  ) {}
}

export class DeleteFileTask {
  static readonly type = `[Task] ${DeleteFileTask.name}`;
  constructor(public id: string) {}
}

export class Update {
  static readonly type = `[boolean] ${Update.name}`;
  constructor(public update: boolean) {}
}
export class ShowSearchedTask {
  static readonly type = `[task] ${ShowSearchedTask.name}`;
  constructor(public isSearching: boolean) {}
}

export class AssignTask {
  static readonly type = `[task] ${AssignTask.name}`;
  constructor(public assignTask: AssignTaskDto) {}
}

export class GetPaginatedTaskComments {
  static readonly type = `[task] ${GetPaginatedTaskComments.name}`;
  constructor(
    public taskId: string,
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class AddTaskComment {
  static readonly type = `[task] ${AddTaskComment.name}`;
  constructor(
    public taskId: string,
    public comment: string,
  ) {}
}

export class UpdateTaskComment {
  static readonly type = `[task] ${UpdateTaskComment.name}`;
  constructor(
    public taskCommentId: string,
    public content: string,
  ) {}
}


export class ChangeTaskStatus {
  static readonly type = `[task] ${ChangeTaskStatus.name}`;
  constructor(public updatedTask: UpdateTaskStatusDto) {}
}
export class SetSelectedTaskComment {
  static readonly type = `[task] ${SetSelectedTaskComment.name}`;
  constructor(
    public taskComment: TaskComment,
    public typeOfAction: string,
  ) {}
}

export class ResetSelectedTaskComment {
  static readonly type = `[task] ${ResetSelectedTaskComment.name}`;
  constructor() {}
}

export class ResetSelectedReplyComment {
  static readonly type = `[task] ${ResetSelectedReplyComment.name}`;
  constructor() {}
}

export class ReplyTaskComment {
  static readonly type = `[task] ${ReplyTaskComment.name}`;
  constructor(
    public commentId: string,
    public content: string,
  ) {}
}
export class DeleteTaskComment {
  static readonly type = `[task] ${DeleteTaskComment.name}`;
  constructor(public commentId: string) {}
}

export class PreviewTaskDocument {
  static readonly type = `[task] ${PreviewTaskDocument.name}`;
  constructor(
    public readonly fileId: string,
    public readonly name: string,
    public readonly table: string,
    public readonly documentDownloadType: string,
    public readonly documentOperationType?: string,
  ) {}
}
export class GetPaginatedTaskDocument {
  static readonly type = `[Process] ${GetPaginatedTaskDocument.name}`;
  constructor(
    public readonly taskId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetPaginatedTaskDocumentsByStageInstanceId {
  static readonly type = `[task] ${GetPaginatedTaskDocumentsByStageInstanceId.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SearchTaskTypes {
  static readonly type = `[task] ${SearchTaskTypes.name}`;
  constructor(
    public readonly name?: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SetFileTaskUploadReportsNull {
  static readonly type = `[task] ${SetFileTaskUploadReportsNull.name}`;
  constructor() {}
}

export class SetSelectedReplyComment {
  static readonly type = `[task] ${SetSelectedReplyComment.name}`;
  constructor(public comment: TaskComment) {}
}

export class UpdateReplyTaskComment {
  static readonly type = `[task] ${UpdateReplyTaskComment.name}`;
  constructor(
    public taskCommentId: string,
    public commentId: string,
    public content: string,
  ) {}
}

export class DeleteTaskCommentReply {
  static readonly type = `[task] ${DeleteTaskCommentReply.name}`;
  constructor(
    public taskCommentId: string,
    public commentId: string,
  ) {}
}

export class FetchVideo {
  static readonly type = `[task] ${FetchVideo.name}`;
  constructor(public readonly fileId: string) {}
}

export class FetchAudio {
  static readonly type = `[task] ${FetchAudio.name}`;
  constructor(public readonly fileId: string) {}
}

export class GetTaskTypesByStageInstanceId {
  static readonly type = `[task] ${GetTaskTypesByStageInstanceId.name}`;
  constructor(public stageInstanceId: string) {}
}

export class GetTaskFiles {
  static readonly type = `[task] ${GetTaskFiles.name}`;
  constructor(
    public taskId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class DownloadTaskDocument {
  static readonly type = `[task] ${DownloadTaskDocument.name}`;
  constructor(
    public readonly fileId: string,
    public readonly name: string,
    public readonly table: string,
    public readonly documentDownloadType: string,
    public readonly documentOperationType: string,
  ) {}
}

export class GetAssignedTasks {
  static readonly type = `[task] ${GetAssignedTasks.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class GetAssignedDocumetTasks {
  static readonly type = `[task] ${GetAssignedDocumetTasks.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class ResetTaskDetail {
  static readonly type = `[task] ${ResetTaskDetail.name}`;
}

export class DownloadTaskDocumentCompose {
  static readonly type = `[task] ${DownloadTaskDocumentCompose.name}`;
  constructor(
    public readonly id: string,
    public readonly name: string,
  ) {}
}

export class PreviewTaskDocumentCompose {
  static readonly type = `[task] ${PreviewTaskDocumentCompose.name}`;
  constructor(
    public readonly id: string,
    public readonly name: string,
  ) {}
}
