<mat-accordion [togglePosition]="'before'" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header class="description-accordion">
      <mat-panel-description>
        <h5 class="nowrap-text" i18n="@@offices.update-excel.view-excel-rules">
          View Excel Rules
        </h5>
      </mat-panel-description>
      <mat-card (click)="$event.stopPropagation()">
        <mat-card-actions class="row">
          <download-excel-template
            *appHasPermission="hasExportTemplateExcelPermission()"
          ></download-excel-template>
          <button
            mat-flat-button
            color="primary"
            (click)="openUploadExcelDialog()"
            class="custom-btn-space"
            *appHasPermission="hasUploadExcelPermission()"
            i18n="@@offices.update-excel.import-offices-excel"
            [attr.data-test-id]="'offices.import-excel.upload-excel.import-button'"
          >
            Import Offices Excel
          </button>
        </mat-card-actions>
      </mat-card>
    </mat-expansion-panel-header>
    <ul *ngFor="let rule of rules">
      <li class="rule-list">
        {{ rule }}
      </li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>
