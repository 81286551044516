import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, filter } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  AddTeamMembersDto,
  ProcessInstance,
  ProcessInstanceDetail,
} from 'src/app/researches/models/process-instance.model';
import { ConfirmGenericDialogComponent } from 'src/app/shared/shared-components/confirm-generic-dialog/confirm-generic-dialog.component';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User, CurrentLoggedInUser } from 'src/app/users/models/user.model';

interface AddTeamMemberFormComponentState {
  processInstanceDetail: ProcessInstanceDetail | null;
  users: User[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initAddTeamMemberFormComponentState: AddTeamMemberFormComponentState = {
  processInstanceDetail: null,
  users: [],
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-add-team-member-form',
  templateUrl: './add-team-member-form.component.html',
  styleUrls: ['./add-team-member-form.component.scss'],
})
export class AddTeamMemberFormComponent implements OnInit {
  addTeamMemberForm: FormGroup;
  users: User[] = [];
  users$ = this.state.select('users');
  filteredTeamMembers: User[] = [];
  teamMembers: User[] = [];

  processInstanceDetail: ProcessInstanceDetail | null = null;
  processInstanceDetail$: Observable<ProcessInstanceDetail | null> =
    this.state.select('processInstanceDetail');

  @ViewChild('teamMembersInput')
  teamMembersInput!: ElementRef<HTMLInputElement>;

  placeholderToggleLabel = {
      searchTeamMembersAdd: $localize`:@@researches.add-team-member-form.search-team-members-to-add: Search Team Members to Add`,
      allAvailableUsersAdded: $localize`:@@researches.add-team-member-form.all-available-users-are-added: all available users are added`,
  }
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private state: RxState<AddTeamMemberFormComponentState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initAddTeamMemberFormComponentState);
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.state.connect('users', this.userFacade.users$);

    this.addTeamMemberForm = this.fb.group({
      teamMembersSearchQuery: [''],
      teamMembers: [new Set<User>(), [Validators.required]],
    });
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }
  ngOnInit(): void {
    this.userFacade.dispatchGetUsers();
    this.users$.subscribe((users) => {
      this.users = users;
      this.filterExistingTeamMember(this.processInstanceDetail?.teamMembersIds);
    });
    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
      this.teamMembers = processInstance?.teamMembersIds
        ?.map((memberId: string) => {
          const user = this.users.find((user: User) => user.id === memberId);
          return user as User;
        })
        .filter((user) => user) as User[];
    });

    this.addTeamMemberForm.controls['teamMembersSearchQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredTeamMembers = this.filterTeamMembers(query);
      });
    this.filterExistingTeamMember(this.processInstanceDetail?.teamMembersIds);
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  filterTeamMembers(query: string | null) {
    const existingTeamMemberIds =
      this.processInstanceDetail?.teamMembersIds || [];
    return this.users.filter(
      (user) =>
        (user.firstName.toLowerCase().includes(query?.toLowerCase() ?? '') ||
          user.lastName!.toLowerCase().includes(query?.toLowerCase() ?? '')) &&
        !existingTeamMemberIds.includes(user?.id as string),
    );
  }

  filterExistingTeamMember(membersToExclude: any) {
    const existingTeamMemberIds = membersToExclude || [];
    this.filteredTeamMembers = this.users?.filter(
      (user) => !existingTeamMemberIds.includes(user?.id as string),
    );
  }

  selectTeamMember(event: MatAutocompleteSelectedEvent) {
    const previousValues =
      this.addTeamMemberForm.controls['teamMembers'].value || [];
    previousValues.add(event.option.value);
    this.teamMembersInput.nativeElement.value = '';
  }

  removeTeamMember(user: User) {
    this.addTeamMemberForm.controls['teamMembers'].value.delete(user);
  }

  addTeamMembers() {
    const addTeamMembersDto: AddTeamMembersDto = {
      processInstanceId: this.processInstanceDetail?.id as string,
      teamMembersIds: Array.from(
        this.addTeamMemberForm.controls['teamMembers'].value,
      ).map((user: any) => user.id),
    };
    this.processInstanceFacade.dispatchAddTeamMembers(addTeamMembersDto);
    this.addTeamMemberForm.controls['teamMembers'].setValue(new Set<User>());
    this.addTeamMemberForm.controls['teamMembersSearchQuery'].setValue('');
    this.processInstanceDetail$.subscribe((processInstance) => {
      this.filterExistingTeamMember(processInstance?.teamMembersIds);
    });
    this.dialog.closeAll();
  }

  onDeleteTeamMemberClicked(member: any) {
    const dialogRef = this.dialog.open(ConfirmGenericDialogComponent, {
      data: {
        title: $localize`:@@researches.add-team-member-form.delete-team-member-title:Confirm Team Member Removal`,
        regularTextOne: $localize`:@@researches.add-team-member-form.delete-team-member-confirmation-part-1:Are you sure you want to remove` ,
        boldText:  `${member?.firstName + ' ' + member?.middleName + ' ' + member?.lastName }` ,
        regularTextTwo: $localize`:@@researches.add-team-member-form.delete-team-member-confirmation-part-2:from the team ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'confirm') {
        this.processInstanceFacade.dispatchDeleteTeamMember(
          this.processInstanceDetail?.id as string,
          member?.id,
        );
        this.processInstanceDetail$.subscribe((processInstance) => {
          this.filterExistingTeamMember(processInstance?.teamMembersIds);
        });
        this.dialog.closeAll();
      }
    });
  }

  hasAddTeamMembersPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.AddTeamMembers,
    );
  }
}
