import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  TorConfigurationState,
  TorConfigurationStateModel,
} from './tor-setting.state';

export class TorConfigurationSelector {
  static slices = createPropertySelectors<TorConfigurationStateModel>(
    TorConfigurationState,
  );

  @Selector([TorConfigurationState])
  static quantityTypes(stateModel: TorConfigurationStateModel) {
    return stateModel.quantityTypes;
  }

  @Selector([TorConfigurationState])
  static activityTypes(stateModel: TorConfigurationStateModel) {
    return stateModel.activityTypes;
  }

  @Selector([TorConfigurationState])
  static activityUpdate(stateModel: TorConfigurationStateModel) {
    return stateModel.activityUpdate;
  }

  @Selector([TorConfigurationState])
  static quantityUpdate(stateModel: TorConfigurationStateModel) {
    return stateModel.quantityUpdate;
  }
}
