import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PublicationSelector } from '../store/publication.selector';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstance } from '../models/process-instance.model';
import { Observable } from 'rxjs';
import {
  DeletePublicationProcessDocument,
  GetCompletedProcessInstances,
  GetPublications,
  SetSelectedPublication,
  SetSelectedPublicationTab,
  UploadPublicationFiles,
  SetSelectedPublicationOption,
  SubmitPublicationProcess,
  ChangePublicationStatus,
  GetPaginatedPublicationComments,
  AddPublicationComment,
  DeletePublicationComment,
  ReplyPublicationComment,
  UpdatePublicationComment,
  UpdatePublicationCommentReply,
  DeletePublicationCommentReply,
  PreviewPublicationDocument,
  DownloadPublicationDocument,
  TogglePublicationComments,
} from '../store/publication.actions';
import { Publication, PublicationComment, PublicationDocument, PublicationProcess, PublicationStatus, PublicationStatusForDisplay } from '../models/publication.model';
import {
  PublicationOption,
  PublicationTemplate,
} from '../models/publication-option.model';

@Injectable({
  providedIn: 'root',
})
export class PublicationFacade {
  @Select(PublicationSelector.completedProcessInstances)
  completedProcessInstances$!: Observable<PaginatedList<ProcessInstance>>;

  @Select(PublicationSelector.publications)
  publications$!: Observable<PaginatedList<Publication>>;

  @Select(PublicationSelector.selectedTabIndex)
  selectedTabIndex$!: Observable<number>;

  @Select(PublicationSelector.selectedPublication)
  selectedPublication$!: Observable<Publication>;

  @Select(PublicationSelector.selectedPublicationOption)
  selectedPublicationOption$!: Observable<PublicationOption>;

  @Select(PublicationSelector.publicationComments)
  publicationComments$!: Observable<PaginatedList<PublicationComment>>;

  @Select(PublicationSelector.isPublicationCommentVisible)
  isPublicationCommentVisible$!: Observable<boolean>;

  constructor(private store: Store) {}

  dispatchGetCompletedProcessInstances(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetCompletedProcessInstances(pageNumber, pageSize));
  }

  dispatchGetPublications(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetPublications(pageNumber, pageSize));
  }

  dispatchSetSelectedPublicationTab(selectedTabIndex: number) {
    this.store.dispatch(new SetSelectedPublicationTab(selectedTabIndex));
  }

  dispatchSetSelectedPublication(publication: Publication) {
    this.store.dispatch(new SetSelectedPublication(publication));
  }

  dispatchSetSelectedPublicationOption(publicationOption: PublicationOption) {
    this.store.dispatch(new SetSelectedPublicationOption(publicationOption));
  }

  dispatchUploadPublicationFiles(files: FormData, id: string) {
    this.store.dispatch(new UploadPublicationFiles(files, id));
  }

  dispatchDeletePublicationProcesDocument(
    processId: string,
    documentId: string,
  ) {
    this.store.dispatch(
      new DeletePublicationProcessDocument(processId, documentId),
    );
  }

  dispatchSubmitPublicationDocuments(processId: string) {
    this.store.dispatch(new SubmitPublicationProcess(processId));
  }

  dispatchChangePublicationStatus(id: string, status: PublicationStatus, statusForDisplay: PublicationStatusForDisplay) {
    this.store.dispatch(new ChangePublicationStatus(id, status,statusForDisplay));
  }

  dispatchGetPaginatedPublicationComments(
    publicationProcessId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    return this.store.dispatch(
      new GetPaginatedPublicationComments(
        publicationProcessId,
        pageNumber,
        pageSize,
      ),
    );
  }

  dispatchAddPublicationComment(publicationProcessId: string, comment: string) {
    return this.store.dispatch(
      new AddPublicationComment(publicationProcessId, comment),
    );
  }

  dispatchDeletePublicationComment(commentId: string) {
    return this.store.dispatch(new DeletePublicationComment(commentId));
  }

  dispatchReplyPublicationComment(commentId: string, content: string) {
    return this.store.dispatch(new ReplyPublicationComment(commentId, content));
  }

  dispatchUpdatePublicationComment(
    publicationCommentId: string,
    content: string,
  ) {
    return this.store.dispatch(
      new UpdatePublicationComment(publicationCommentId, content),
    );
  }

  dispatchUpdatePublicationCommentReply(
    replyId: string,
    content: string,
    parentCommentId: string,
  ) {
    return this.store.dispatch(
      new UpdatePublicationCommentReply(replyId, content, parentCommentId),
    );
  }

  dispatchDeletePublicationCommentReply(replyId:string, parentCommentId:string){
    return this.store.dispatch(new DeletePublicationCommentReply(replyId, parentCommentId));
  }

  dispatchDownloadPublicationDocument(publicationDocument: PublicationDocument) {
    this.store.dispatch(new DownloadPublicationDocument(publicationDocument));
  }

  dispatchPreviewPublicationDocument(
    publicationProcessId: string,
    fileId: string,
    fileName: string,
  ) {
    this.store.dispatch(
      new PreviewPublicationDocument(publicationProcessId, fileId, fileName),
    );
  }

  dispatchTogglePublicationComments(isPublicationCommentVisible: boolean) {
    this.store.dispatch(
      new TogglePublicationComments(isPublicationCommentVisible),
    );
  }
}
