<mat-card class="table-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.department-communications.departments-and-briefings-types">Departments and Briefings Types</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <mat-paginator
      [length]="(communicationCount$ | async)?.totalCount"
      [pageIndex]="((communicationCount$ | async)?.pageNumber ?? 1) - 1"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="paginateCommunicationCounts($event)"
    >
    </mat-paginator>
    <table
      mat-table
      [dataSource]="(communicationCount$ | async)?.items ?? []"
      class="mat-elevation-z0"
    >
      <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
        <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
        <td mat-cell *matCellDef="let element">{{ element?.[col] || 0 }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>
