import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { TaskFacade } from '../../facades/task.facades';
import { ProcessTasks } from '../../models/process-tasks.model';
import { Task } from '../../models/task.model';
import {
  TASK_LIST_ROUTE,
  TASK_DOCUMENTS_ROUTE,
  STAGE_INSTANCES_ROUTE,
} from 'src/app/core/constants/routes';
import { Router } from '@angular/router';

import { ProcessInstance } from '../../models/process-instance.model';
import { ProcessInstanceFacade } from '../../facades/process-instance.facades';
import { ProcessDetail } from '../../models/process-detail.model';
import { TaskFormcomponent } from '../task/task-form/task-form.component';
import { TaskDetailFrom } from '../../store/task.state';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { STAGE_INSTANCES_URL } from 'src/app/core/constants/api-endpoints';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

interface TaskListComponentState {
  processInstancesByAssignedTasks: PaginatedList<ProcessInstance> | undefined;
  myTasks: ProcessTasks[];
  selectedTask: Task | undefined;
  isSearching: boolean;
  currentLoggedInUser?: CurrentLoggedInUser;
}

const initialTaskListComponentState: TaskListComponentState = {
  processInstancesByAssignedTasks: undefined,
  selectedTask: undefined,
  myTasks: [],
  isSearching: false,
};

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent {
  panelOpenState = false;
  processInstances: any;

  selectedTask: Task | undefined = undefined;
  selectedTask$ = this.state.select('selectedTask');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  myTasks$ = this.state.select('myTasks');
  myTasks: ProcessTasks[] = [];

  processInstancesByAssignedTasks$ = this.state.select(
    'processInstancesByAssignedTasks',
  );
  selectedProcessInstance: ProcessInstance | undefined;

  isSearching$ = this.state.select('isSearching');
  isSearching: boolean = false;

  taskList: Task[] = [];

  constructor(
    private taskFacade: TaskFacade,
    private state: RxState<TaskListComponentState>,
    private dialog: MatDialog,
    private router: Router,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initialTaskListComponentState);
    this.state.connect('selectedTask', this.taskFacade.selectedTask$);
    this.state.connect('isSearching', this.taskFacade.isSearching$);
    this.state.connect('myTasks', this.taskFacade.myTasks$);
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect(
      'processInstancesByAssignedTasks',
      this.processInstanceFacade.processInstancesByAssignedTasks$,
    );
  }

  ngOnInit(): void {
    this.processInstanceFacade.dispatchGetProcessInstancesByAssignedTasks();

    this.processInstancesByAssignedTasks$.subscribe((processInstances) => {
      this.processInstances = processInstances?.items;
      processInstances?.items.forEach((pInstance: any) => {
        this.taskFacade.dispatchGetListOfTasksWithInProcess(pInstance.id!);
      });
    });

    this.selectedTask$.subscribe((task) => {
      this.selectedTask = task;
    });

    this.myTasks$.subscribe((taskList) => {
      this.myTasks = taskList;
    });

    this.isSearching$.subscribe((isSearching) => {
      this.isSearching = isSearching;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  getProcessInstances() {
    if (this.isSearching) {
      return this.processInstances.filter((pInstances: any) => {
        return this.myTasks.some((pTask) => pTask.id === pInstances.id);
      });
    }
    return this.processInstances;
  }

  getTaskList(processInstanceId: string) {
    const taskList = this.myTasks.filter((pTask) => {
      return pTask.id === processInstanceId;
    });
    if (taskList.length === 0) return [];
    return taskList[0].tasks;
  }

  onToggleClick(panelOpenState: boolean, processInstanceId: string): void {
    if (panelOpenState) {
      this.taskList = this.getTaskList(processInstanceId);
    } else this.taskList = [];
  }
  onRowClick(event: MouseEvent, task: Task, processInstanceId: string): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    if (!isMenuButtonClick) {
      this.taskFacade.dispatchToggleTaskDetailFrom(TaskDetailFrom.MY_TASKS);
      this.taskFacade.dispatchSetSelectedTask(task);
      this.router.navigate([
        `${TASK_LIST_ROUTE}`,
        task.id,
        { stageId: undefined, processId: processInstanceId },
      ]);
    }
  }

  setSelectedTaskAndProcess(
    event: MouseEvent,
    processTask: ProcessTasks,
    task: Task,
  ) {
    event.stopPropagation();
    this.taskFacade.dispatchSetSelectedProcessInstanceTasks(processTask);
    this.taskFacade.dispatchSetSelectedTask(task);
  }
  updateTask() {
    if (!this.selectedTask) return;
    this.taskFacade.dispatchUpdate(true);
    const dialogRef = this.dialog.open(TaskFormcomponent, SIDE_DIALOG_CONFIG);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.router.navigate([
          STAGE_INSTANCES_ROUTE,
          this.selectedTask!.stageInstanceId,
        ]);
      } else {
        this.taskFacade.dispatchUpdate(false);
        this.taskFacade.dispatchResetSelectedTask();
      }
    });
  }

  setSelectedTask(event: MouseEvent, task: Task) {
    event.stopPropagation();
    this.taskFacade.dispatchSetSelectedTask(task);
  }

  openConfirmationDialog(processInstance: ProcessInstance) {
    const taskId = this.selectedTask?.id;
    if (!taskId) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.task-list.delete-task-message-part-1: Are you sure you want to delete`,
        boldText: ` "${this.selectedTask!.name}"? `,
        regularTextTwo: $localize`:@@researches.task-list.delete-task-message-part-2: All related documents and comments are also removed.`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedTask)
          this.taskFacade.dispatchDeleteTaskFromMyTasks(
            processInstance.id!,
            taskId,
          );
        this.dialog.closeAll();
      }
    });
  }

  viewTaskDocuments() {
    if (!this.selectedTask) return;
    this.router.navigate([
      TASK_DOCUMENTS_ROUTE,
      this.selectedTask.id,
      { taskId: this.selectedTask.id },
    ]);
  }

  onMenuClick(event: MouseEvent, task: Task) {
    this.taskFacade.dispatchSetSelectedTask(task);
    event.stopPropagation();
    this.canEditAndDelete();
  }

  canEditAndDelete() {
    const id = this.currentLoggedInUser?.id as string;
    const userName = this.currentLoggedInUser?.userName;
    const email = this.currentLoggedInUser?.email;

    if (
      (this.selectedTask?.assignedTo == id &&
        this.selectedTask?.createdBy == userName) ||
      this.selectedProcessInstance?.ownerEmail === email ||
      this.selectedTask?.createdBy == userName
    ) {
      return true;
    }
    return false;
  }

  hasGetFileTasksByTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTasksByTask,
    );
  }
  hasUpdateTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.UpdateTask,
    );
  }

  hasDeleteTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.DeleteTask,
    );
  }
}
