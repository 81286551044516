import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ReportedWorkflowComment,
  WorkflowComment,
} from '../../../models/workflow-comment.model';
import { RxState } from '@rx-angular/state';
import { WorkflowCommentFacade } from '../../../facade/workflow-comment.facade';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';

interface ReportedWorkflowCommentState {
  reportedWorkflowComments: PaginatedList<ReportedWorkflowComment>;
  workflowComments: PaginatedList<WorkflowComment> | undefined;
}

const initWorkflowCommentState: ReportedWorkflowCommentState = {
  reportedWorkflowComments: {
    items: [],
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
  },
  workflowComments: undefined,
};

@Component({
  selector: 'app-reported-workflow-comment',
  templateUrl: './reported-workflow-comment.component.html',
  styleUrls: ['./reported-workflow-comment.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ReportedWorkflowCommentComponent}],
})
export class ReportedWorkflowCommentComponent implements OnInit, MatPaginatorIntl {
  reportedWorkflowComments: PaginatedList<ReportedWorkflowComment> = {
    items: [],
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
  };
  reportedWorkflowComments$ = this.state.select('reportedWorkflowComments');

  workflowComments: PaginatedList<WorkflowComment> | undefined;
  workflowComments$ = this.state.select('workflowComments');

  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    firstPageLabel = $localize`:@@documents.reported-workflow-comment.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.reported-workflow-comment.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.reported-workflow-comment.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.reported-workflow-comment.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.reported-workflow-comment.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.reported-workflow-comment.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.reported-workflow-comment.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.reported-workflow-comment.page-part-two: of` + `${amountPages}`;
    }

  constructor(
    private state: RxState<ReportedWorkflowCommentState>,
    private workflowCommentFacade: WorkflowCommentFacade,
  ) {
    this.state.set(initWorkflowCommentState);
    this.state.connect(
      'reportedWorkflowComments',
      this.workflowCommentFacade.reportedWorkflowComments$,
    );
    this.state.connect(
      'workflowComments',
      this.workflowCommentFacade.workflowComments$,
    );
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.workflowCommentFacade.dispatchGetReportedWorkflowComments(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.reportedWorkflowComments$.subscribe((reportedWorkflowComments) => {
      this.reportedWorkflowComments = reportedWorkflowComments;
    });

    this.workflowComments$.subscribe((workflowComments) => {
      this.workflowComments = workflowComments;
    });
  }

  loadPaginatedWorkflowComments(event: PageEvent) {
    this.workflowCommentFacade.dispatchGetReportedWorkflowComments(
      event.pageIndex + 1,
      event.pageSize,
    );
  }
}
