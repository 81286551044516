import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import {
  AngularGridInstance,
  Column,
  Formatters,
  GridOption,
  OnEventArgs,
} from 'angular-slickgrid';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { UserFacade } from '../../facade/user.facade';
import { User } from '../../models/user.model';
import {
  RegistrationType,
  UserFormComponent,
} from '../user-form/user-form.component';
import { RoleFacade } from '../../facade/role.facade';
import { ChangeRoleComponent } from '../change-role/change-role.component';
import {
  ASSIGN_REVOKE_PERMISSIONS_USER,
  ASSIGN_REVOKE_ROLES_USER,
} from 'src/app/core/constants/routes';
import { AssignRolesDialogComponent } from '../assign-roles-dialog/assign-roles-dialog.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';

interface UserListComponentState {
  users: User[];
  length: number;
  selectedUser: User | undefined;
}

interface RoleListComponentState {
  roles: Role[];
}

interface Role {
  id: string;
  name: string;
}

const initUserListComponentState: Partial<UserListComponentState> = {
  users: [],
  length: 0,
  selectedUser: undefined,
};

const initRoleListComponentState: Partial<RoleListComponentState> = {
  roles: [],
};

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: UserListComponent}, RxState],
})
export class UserListComponent implements OnInit, MatPaginatorIntl {
  angularGrid!: AngularGridInstance;
  dataset!: User[];
  registrationType?: RegistrationType = RegistrationType.ADMIN;

  users: User[] = [];
  users$: Observable<User[]> = this.state.select('users');
  roles$: Observable<Role[]> = this.roleState.select('roles');
  selectedUser$: Observable<User | undefined> =
    this.state.select('selectedUser');
  selectedUser: User | undefined = undefined;

  roles: Role[] = [];
  selectedRoleId: string = '';

  clickedUserRows = new Set<string>();

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length$ = this.state.select('length');
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;

  activeInactiveLabel = {
    activeLabel: $localize`:@@users.user-list.active: Active`,
    inActiveLabel: $localize`:@@users.user-list.in-active: Inactive`,
}

placeholderLabel = {
  nameField: $localize`:@@users.user-list.john-doe: John Doe`,
}

firstPageLabel = $localize`:@@users.user-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@users.user-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@users.user-list.last-page: Last page`;

  nextPageLabel = $localize`:@@users.user-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@users.user-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@users.user-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@users.user-list.page-part-one: Page` + `${page + 1}` + $localize`:@@users.user-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private matDialog: MatDialog,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private state: RxState<UserListComponentState>,
    private roleState: RxState<RoleListComponentState>,
    private route: ActivatedRoute,
    private router: Router,
    private officeFacade:OfficeFacade
  ) {
    this.state.set(initUserListComponentState);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect('length', this.userFacade.totalCount$);
    this.roleState.set(initRoleListComponentState);
    this.roleState.connect('roles', this.roleFacade.roles$);
    this.state.connect('selectedUser', this.userFacade.selectedUser$);
  }
  changes = new Subject<void>();
  searchText: string = '';

  ngOnInit(): void {
    this.userFacade.dispatchGetUsers(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10,
    );

    this.roleFacade.dispatchGetRoles();

    this.users$.subscribe((users) => (this.dataset = users));
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.length$.subscribe((length) => {
      this.length = length;
    });
    this.route.data.subscribe((data) => {
      this.registrationType = data['registrationType'];
    });
  }

  buttonTitle() {
    return this.registrationType === RegistrationType.ADMIN
      ? 'Add User'
      : this.registrationType === RegistrationType.EMPLOYEE
        ? 'Add Employee'
        : 'Add User';
  }

  onRoleChange(event: any) {
    if (event.value === 'all') {
      this.userFacade.dispatchGetUsers(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10,
      );
      return;
    }
    this.selectedRoleId = event.value;
    this.userFacade.dispatchGetUsersByRoleId(
      this.selectedRoleId,
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10,
    );
  }

  changeRole(user: User) {
    this.userFacade.dispatchSelectUser(user);
    this.matDialog.open(ChangeRoleComponent, {
      data: {
        update: true,
        roleId: this.selectedRoleId,
        roles: this.roles,
      },
      disableClose: true,
    });
  }

  getUsersBySearch() {
    if (this.searchText) {
      this.userFacade.dispatchGetUsersBySearch(
        this.searchText,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10,
      );
    } else {
      this.userFacade.dispatchGetUsers(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10,
      );
    }
  }

  resetSearch() {
    this.searchText = '';
    this.userFacade.dispatchGetUsers(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10,
    );
  }

  addUser() {
    const dialogRef = this.matDialog.open(UserFormComponent, {
      data: { update: false, registrationType: this.registrationType },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(()=>{
      this.officeFacade.dispatchResetSelectedOffice()
    })
  }

  editUser() {
    this.userFacade.dispatchSelectUser(this.selectedUser!);
    this.matDialog.open(UserFormComponent, {
      data: { update: true},
      disableClose: true,
    });
  }

  deleteUser() {
    if (!this.selectUser) return;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne:  $localize`:@@users.user-list.delete-user: Are you sure you want to delete the user`,
        boldText: ` "${this.selectedUser?.fullName}" ?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedUser)
          this.userFacade.dispatchDeleteUser(this.selectedUser?.id!);
      }
    });
  }

  resetDefaultPassword() {
    if (!this.selectUser) return;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to reset the user's password to default?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedUser)
          this.userFacade.dispatchResetDefaultPassword(this.selectedUser?.id!);
      }
    });
  }

  activateOrDeactivateUser() {
    if (!this.selectUser) return;
    const message = this.selectedUser?.isActive ? 'deactivate' : 'activate';
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        message: `Are you sure you want to ${message} this user?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedUser)
          this.userFacade.dispatchToggleStatus(this.selectedUser?.id!);
      }
    });
  }

  angularGridReady(event: Event) {
    const angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.angularGrid = angularGrid;
  }

  loadPaginatedUsers(event: PageEvent) {
    this.userFacade.dispatchGetUsers(event.pageIndex + 1, event.pageSize);
  }

  onRowClick(event: MouseEvent, user: User): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    if (isMenuButtonClick) return;
  }

  setSelectedUser(event: MouseEvent, user: User) {
    event.stopPropagation();
    this.userFacade.dispatchSelectUser(user);
    this.selectedUser$.subscribe((user) => {
      this.selectedUser = user;
    });
  }

  assignOrRevokePermissions() {
    this.router.navigate([ASSIGN_REVOKE_PERMISSIONS_USER]);
  }

  assignOrRevokeRoles() {
    this.router.navigate([ASSIGN_REVOKE_ROLES_USER]);
  }

  selectUser(checked: boolean, userId: string) {
    checked
      ? this.clickedUserRows.add(userId)
      : this.clickedUserRows.delete(userId);
  }

  assignUserToRoles() {
    this.matDialog.open(AssignRolesDialogComponent, {
      disableClose: true,
      data: { selectedUsersId: this.clickedUserRows },
    });
  }

  hasGetUsersByRoleAndOfficePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsersByRoleAndOffice,
    );
  }

  hasGetUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsers,
    );
  }

  hasAssignRevokeRolesToUserPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.AssignRevokeRolesToUser,
    );
  }

  hasAssignRevokePermissionsToUserPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.AssignRevokePermissionsToUser,
    );
  }

  hasGetUsersByAnyTypePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsersByAnyType,
    );
  }

  hasCreateUserPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.CreateUser,
    );
  }

  hasDeleteUserPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.DeleteUser,
    );
  }

  hasUserActivationPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.UserActivation,
    );
  }

  hasUpdateUserPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.UpdateUser,
    );
  }

  hasResetDefaultPasswordPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.ResetDefaultPassword,
    );
  }

  hasCrudPermission(){
    return [
      this.hasAssignRevokeRolesToUserPermission(),
      this.hasAssignRevokePermissionsToUserPermission(),
      this.hasUserActivationPermission(),
      this.hasUpdateUserPermission(),
      this.hasDeleteUserPermission(),
      this.hasResetDefaultPasswordPermission()
    ]
  }
}
