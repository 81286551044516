import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ENGLISH_LANGUAGE } from './core/constants/language';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'imis-angular-client';
  locale = localStorage.getItem('locale') ?? ENGLISH_LANGUAGE.locale;

  ngOnInit(): void {
    localStorage.setItem('locale', this.locale);
  }
}
