import { Selector, createPropertySelectors } from '@ngxs/store';
import { StageState, StageStateModel } from './stage.state';

export class StageSelector {
  static slices = createPropertySelectors<StorageEstimate>(StageState);

  @Selector([StageState])
  static criteria(stateModel: StageStateModel) {
    return stateModel.criteria;
  }

  @Selector([StageState])
  static criteriaSum(stateModel: StageStateModel) {
    return stateModel.criteriaSum;
  }

  @Selector([StageState])
  static stage(state: StageStateModel) {
    return state.selectedStage;
  }

  @Selector([StageState])
  static selectedState(stateModel: StageStateModel) {
    return stateModel.selectedStage;
  }
  @Selector([StageState])
  static selectedCriterion(stateModel: StageStateModel) {
    return stateModel.selectedCriterion;
  }
  @Selector([StageState])
  static paginatedStageCriteria(state: StageStateModel) {
    return state.paginatedCriteria;
  }
}
