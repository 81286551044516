<mat-card class="graph-card">
  <mat-card-title
    i18n="@@researches.budget-per-communication.budget-per-briefing"
    >Budget per briefing</mat-card-title
  >
  <div class="chart-container">
    <div echarts [options]="chartOption" class="chart"></div>
  </div>
</mat-card>

<mat-card class="total-cost">
  <mat-card-header>
    <mat-card-title
      i18n="@@researches.budget-per-communication.budget-for-research"
      >Budget For Research
    </mat-card-title>
  </mat-card-header>
  <mat-card-title class="flex-row"
    >{{ totalCost.toString() | currency: "ETB" : "code" : "2.2-2" : locale }}
    <mat-card-subtitle
      class="total-text"
      i18n="@@researches.budget-per-communication.total-research"
    >
      Total</mat-card-subtitle
    ></mat-card-title
  >
  <div class="progress-bar">
    <mat-progress-bar
      mode="determinate"
      [value]="value"
      class="mat-bar"
    ></mat-progress-bar>
  </div>
  <div class="row-container">
    <div class="item">
      <mat-card-header>
        <mat-card-title>
          <span
            i18n="@@researches.budget-per-communication.full-length-research"
            >Full Length Research</span
          >
          <mat-divider class="mat-divide-research"></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-title class="flex-row"
        >{{
          totalCostResearch.toString()
            | currency: "ETB" : "code" : "2.2-2" : locale
        }}
        <mat-card-subtitle
          class="total-text"
          i18n="
            @@researches.budget-per-communication.total-full-length-research"
        >
          Total</mat-card-subtitle
        ></mat-card-title
      >
    </div>

    <div class="item">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@researches.budget-per-communication.briefing"
            >Briefing</span
          >
          <mat-divider class="mat-divide-communication"></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-title class="flex-row"
        >{{
          totalCostCommunication.toString()
            | currency: "ETB" : "code" : "2.2-2" : locale
        }}
        <mat-card-subtitle
          class="total-text"
          i18n="@@researches.budget-per-communication.total-communication"
        >
          Total</mat-card-subtitle
        ></mat-card-title
      >
    </div>
  </div>
</mat-card>
<br />
