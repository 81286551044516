import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkspaceFacade } from 'src/app/files/facade/workspace.facade';
import { Workspace } from 'src/app/files/models/workspace';

interface ArchivedWorkspacesListState {
  archivedWorkspaces: PaginatedList<Workspace> | undefined;
}

const initialProcessInstanceListComponentState: ArchivedWorkspacesListState = {
  archivedWorkspaces: undefined,
};

@Component({
  selector: 'app-archived-workspaces-list',
  templateUrl: './archived-workspaces-list.component.html',
  styleUrls: ['./archived-workspaces-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ArchivedWorkspacesListComponent}],
})
export class ArchivedWorkspacesListComponent implements OnInit, MatPaginatorIntl {
  archivedWorkspaces$ = this.state.select('archivedWorkspaces');
  archivedWorkspaces: PaginatedList<Workspace> | undefined = undefined;
  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;

  firstPageLabel = $localize`:@@offices.archived-workspaces-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@offices.archived-workspaces-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@offices.archived-workspaces-list.last-page: Last page`;

  nextPageLabel = $localize`:@@offices.archived-workspaces-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@offices.archived-workspaces-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@offices.archived-workspaces-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@offices.archived-workspaces-list.page-part-one: Page` + `${page + 1}` + $localize`:@@offices.archived-workspaces-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private workspaceFacade: WorkspaceFacade,
    private state: RxState<ArchivedWorkspacesListState>,
  ) {
    this.state.set(initialProcessInstanceListComponentState);
    this.state.connect(
      'archivedWorkspaces',
      this.workspaceFacade.archivedWorkspaces$,
    );
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.workspaceFacade.dispatchGetArchivedWorkspaces(
      (this.paginator?.pageIndex ?? 0) + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.archivedWorkspaces$.subscribe((archivedWorkspaces) => {
      if (archivedWorkspaces) {
        this.archivedWorkspaces = archivedWorkspaces;
        this.pageIndex = archivedWorkspaces.pageNumber - 1;
      }
    });
  }

  loadPaginatedWorkspaces(event: PageEvent) {
    this.workspaceFacade.dispatchGetArchivedWorkspaces(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  hasGetArchivedWorkspacesPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.GetArchivedWorkspaces,
    );
  }
}
