import { FieldValidationRule } from '../models/field-validation-rule.model';
import { ValidationRuleValue } from '../models/validation-rule-value.model';
import { DataType } from '../models/validation-rule.model';

export class GetTemplateFieldValidationRules {
  static readonly type = `[FieldValidationRule] ${GetTemplateFieldValidationRules.name}`;
  constructor(public templateFieldId: string) {}
}

export class GetValidationRulesByDataType {
  static readonly type = `[FieldValidationRule] ${GetValidationRulesByDataType.name}`;
  constructor(public dataType: DataType) {}
}

export class AddValidationRulesByDataType {
  static readonly type = `[FieldValidationRule] ${AddValidationRulesByDataType.name}`;
  constructor(public fieldValidationRule: FieldValidationRule) {}
}

export class AddValidationRules{
  static readonly type = `[FieldValidationRule] ${AddValidationRules.name}`;
  constructor(public fieldValidationRules: FieldValidationRule[]) {}
}

export class UpdateFieldValidationRule {
  static readonly type = `[FieldValidationRule] ${UpdateFieldValidationRule.name}`;
  constructor(
    public index: number,
    public fieldValidation: FieldValidationRule,
  ) {}
}

export class ClearValidationRulesByDataType {
  static readonly type = `[FieldValidationRule] ${ClearValidationRulesByDataType.name}`;
  constructor() {}
}

export class CreateFieldValidationRules {
  static readonly type = `[FieldValidationRule] ${CreateFieldValidationRules.name}`;
  constructor(public templateFieldId: string) {}
}

export class DeleteFieldValidationRules {
  static readonly type = `[FieldValidationRule] ${DeleteFieldValidationRules.name}`;
  constructor(public fieldValidationRule: FieldValidationRule) {}
}
