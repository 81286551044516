<div class="container" *ngIf="ownedProcessInstances">
  <mat-paginator
    [length]="ownedProcessInstances.totalCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedProcessInstances($event)"
    *appHasPermission="hasGetStageInstanceReviewsPermission()"
  >
  </mat-paginator>
  <mat-accordion *appHasPermission="hasGetStageInstanceReviewsPermission()">
    <ng-container *ngFor="let process of ownedProcessInstances.items">
      <mat-expansion-panel *ngIf="hasReviewedStage(process)">
        <mat-expansion-panel-header [attr.data-test-id]="'researches.get-review.requested-reviews.mat-expansion-process-title'">
          <mat-panel-title> Process Title: {{ process.title }} </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let stage of process.stageInstances">
          <mat-expansion-panel
            #stagePanel
            (click)="getReviews($event, stage, stagePanel)"
          >
            <mat-expansion-panel-header [attr.data-test-id]="'researches.get-review.requested-reviews.mat-expansion-stage-title'">
              <mat-panel-title> Stage Title: {{ stage.name }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="stage-reviews">
              <div *ngFor="let review of reviews">
                <mat-card class="review-card"  *ngIf="review.documentContent.ops[0].insert !== '\n'">
                  <mat-card-title *ngIf="review.reviewer.stageReviewerType == 'NonBlind'">{{review.reviewer.reviewerDetail.firstName
                      + " " + review.reviewer.reviewerDetail.middleName
                      + " " + review.reviewer.reviewerDetail.lastName
                    }}<span class="chip">Reviewer</span></mat-card-title>
                <p [attr.data-test-id]="'researches.get-review.requested-reviews.review-p'" >
                    {{ review.documentContent.ops[0].insert | slice: 0 : 100 }}
                  </p>
                </mat-card>
                <mat-card
                 *ngFor="let attachment of review.attachments"
                  class="review-card">
                  <mat-card-header>
                    <mat-card-title-group>
                      <mat-card-title>{{ attachment.name }}</mat-card-title>
                    </mat-card-title-group>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
