import { ChangeDateFormatService } from './../../../../core/services/change-date-format/change-date-format.service';
import { Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { FolderFacade } from 'src/app/files/facade/folder.facade';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';
import { FolderProperty } from 'src/app/files/models/folder-property.model';

interface ArchivedFolderPropertyComponentState {
  archivedFolderProperty: FolderProperty | undefined;
  selectedFlatFolderNode: FlatFolderNode | undefined;
}
const initArchivedFolderPropertyComponentState: Partial<ArchivedFolderPropertyComponentState> =
  {};

@Component({
  selector: 'app-archived-folder-property',
  templateUrl: './archived-folder-property.component.html',
  styleUrls: ['./archived-folder-property.component.scss'],
  providers: [RxState],
})
export class ArchivedFolderPropertyComponent {
  archivedFolderProperty$: Observable<FolderProperty | undefined> =
    this.state.select('archivedFolderProperty');
  archivedFolderProperty: FolderProperty | undefined;
  selectedFlatFolderNode$: Observable<FlatFolderNode | undefined> =
    this.state.select('selectedFlatFolderNode');
  selectedFlatFolderNode: FlatFolderNode | undefined;

  constructor(
    private folderFacade: FolderFacade,
    private state: RxState<ArchivedFolderPropertyComponentState>,
    private changeDateFormatService: ChangeDateFormatService,
  ) {
    this.state.set(initArchivedFolderPropertyComponentState);
    this.state.connect(
      'archivedFolderProperty',
      folderFacade.archivedFolderProperty$,
    );
    this.state.connect(
      'selectedFlatFolderNode',
      folderFacade.selectedArchivedFlatFolderNode$,
    );
  }

  ngOnInit(): void {
    this.selectedFlatFolderNode$.subscribe((selectedFlatFolderNode) => {
      this.selectedFlatFolderNode = selectedFlatFolderNode;
    });

    this.folderFacade.dispatchGetArchivedFolderProperty(
      this.selectedFlatFolderNode?.id as string,
    );

    this.archivedFolderProperty$.subscribe((folderProperty) => {
      if (folderProperty) {
        folderProperty.created = this.changeDateFormatService.changeDateFormat(
          new Date(folderProperty.created),
        );
        this.archivedFolderProperty = folderProperty;
      }
    });
  }

  hasGetArchivedFolderPropertyPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Folder.Feature,
      PERMISSION_NAMES.Files.Folder.GetArchivedFolderProperty,
    );
  }
}
