import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  DownloadCurrencyExcelTemplate,
  GetCurrencies,
  SetDefaultCurrency,
  UploadCurrencyExcel,
} from './currency.actions';
import { CurrencyService } from '../services/currency.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { CurrencyExcelUploadReport } from '../tor-settings/models/currency.model';
import { CurrencyData } from '../models/currency.model';
import { tap } from 'rxjs';
import { patch } from '@ngxs/store/operators';

export interface CurrencyStateModel {
  templateExcelFileUrl: string | null;
  currencies:CurrencyData[]
}

const CURRENCY_STATE_TOKEN = new StateToken<CurrencyStateModel>(
  'currencyState',
);

const defaults = {
  templateExcelFileUrl: null,
  currencies:[]
};

@State<CurrencyStateModel>({
  name: CURRENCY_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class CurrencyState {
  constructor(
    private operationStatus: OperationStatusService,
    private store: Store,
    public currencyService: CurrencyService,
  ) {}

  @Action(UploadCurrencyExcel)
  uploadCurrencyExcel(
    { }: StateContext<CurrencyStateModel>,
    { excelFile }: UploadCurrencyExcel,
  ) {
    this.store.dispatch(new SetProgressOn());
    this.currencyService.uploadCurrencyExcelFile(excelFile).subscribe((event: HttpEvent<CurrencyExcelUploadReport[]>) => {
      if (event.type === HttpEventType.Response) {
        this.operationStatus.displayStatus(
          $localize`:@@researches.currency.currency-excel-uploaded-successfully: Currency excel uploaded successfully`,
          successStyle,
        );
      }
      this.store.dispatch(new GetCurrencies())
      this.store.dispatch(new SetProgressOff());
    });
  }
  @Action(DownloadCurrencyExcelTemplate)
  downloadCurrencyExcelTemplate({
    patchState,
  }: StateContext<CurrencyStateModel>) {
    this.currencyService
      .downloadCurrencyExcelTemplate()
      .subscribe(async (response: any) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileUrl = URL.createObjectURL(blob);
        patchState({
          templateExcelFileUrl: fileUrl,
        });
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.currency.currency-excel-downloaded-successfully: Currency excel downloaded successfully`,
          successStyle,
        );
      });
  }

  @Action(GetCurrencies)
  getCurrencies({
    setState
  }: StateContext<CurrencyStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.currencyService.getCurrencies().pipe(
      tap((currencies) => {
        setState(
          patch({
            currencies: currencies,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetDefaultCurrency)
  setDefaultCurrency(
    {setState}: StateContext<CurrencyStateModel>,
    { id }:SetDefaultCurrency)
  {
    this.store.dispatch(new SetProgressOn());
    return this.currencyService.setDefaultCurrency(id).pipe(
      tap((currencies) => {
        this.store.dispatch(new GetCurrencies())
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus($localize`:@@researches.currency.default-currency-set-successfully: Defaut currency set successfully`, successStyle);
      }),
    );
  }
}
