import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PublicationOptionService } from '../services/publication-option.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  CreatePublicationOption,
  DeletePublicationTemplates,
  DeletePublicationOptions,
  GetEditorTypes,
  GetPublicationOptions,
  UpdatePublicationOption,
  DownloadPublicationTemplate,
  IsPublicationOptionBeingUsed,
  BanPublicationOptions,
  PermitPublicationOptions,
} from './publication-option.action';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { PublicationOption } from '../models/publication-option.model';
import { EditorType } from '../models/editor-type.model';
import { EditorTypeService } from '../services/editor-type.service';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

export interface PublicationOptionStateModel {
  publicationOptions: PublicationOption[];
  editorTypes: EditorType[];
  used: string[];
}

const PublicationOption_STATE_TOKEN =
  new StateToken<PublicationOptionStateModel>('publicationOptionState');

const defaults: PublicationOptionStateModel = {
  publicationOptions: [],
  editorTypes: [],
  used: []
};

@State<PublicationOptionStateModel>({
  name: PublicationOption_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class PublicationOptionState {
  constructor(
    public publicationOptionervice: PublicationOptionService,
    private editorTypeService: EditorTypeService,
    private store: Store,
    private operationStatus: OperationStatusService,
  ) {}

  @Action(GetPublicationOptions)
  getPublicationOptions(
    { patchState }: StateContext<PublicationOptionStateModel>,
    {}: GetPublicationOptions,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.publicationOptionervice.getPublicationOptions().pipe(
      tap((result) => {
        patchState({ publicationOptions: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetEditorTypes)
  getEditorTypes(
    { patchState }: StateContext<PublicationOptionStateModel>,
    {}: GetEditorTypes,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.editorTypeService.getEditorTypes().pipe(
      tap((result) => {
        patchState({ editorTypes: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreatePublicationOption)
  createPublicatinOption(
    { setState }: StateContext<PublicationOptionStateModel>,
    { publicationOption }: CreatePublicationOption,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationOptionervice
      .createPublicationOption(publicationOption)
      .pipe(
        tap((result) => {
          setState(
            patch({
              publicationOptions: insertItem(result),
            }),
          );
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication-option.publication-option-created-successfully: Publication Option created successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(UpdatePublicationOption)
  updatePublicatinOption(
    { setState }: StateContext<PublicationOptionStateModel>,
    { publicationOption }: UpdatePublicationOption,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationOptionervice
      .updatePublicationOption(publicationOption)
      .pipe(
        tap((result) => {
          this.store.dispatch(new GetPublicationOptions());
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication-option.publication-option-updated-successfully: Publication Option updated successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(DeletePublicationOptions)
  deletePublicationOptions(
    { setState }: StateContext<PublicationOptionStateModel>,
    { publicationOption }: DeletePublicationOptions,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.publicationOptionervice
      .deletePublicationOption(publicationOption.id)
      .pipe(
        tap(() => {
          setState(
            patch({
              publicationOptions: removeItem(
                (po) => po.id == publicationOption.id,
              ),
            }),
          );
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication-option.publication-option-deleted-successfully: Publication Option deleted successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(DeletePublicationTemplates)
  deletePublicationTemplates(
    { setState }: StateContext<PublicationOptionStateModel>,
    { id, templateIds }: DeletePublicationTemplates,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationOptionervice
      .deleteTemplates(id, templateIds)
      .pipe(tap(() => {}));
  }

  @Action(DownloadPublicationTemplate)
  downloadPublicationTemplate(
    { setState }: StateContext<PublicationOptionStateModel>,
    { template }: DownloadPublicationTemplate,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationOptionervice
      .downloadPublicationOption(template.id)
      .pipe(
        tap((response: any) => {
          const blob = new Blob([response], {
            type: 'application/octate-stream',
          });

          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = fileUrl;
          link.download = template.fileName;
          link.click();
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(IsPublicationOptionBeingUsed)
  isPublicationOptionBeingUser(
    { patchState, getState }: StateContext<PublicationOptionStateModel>,
    {}: IsPublicationOptionBeingUsed,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationOptionervice.isPublicationOptionBeingUser().pipe(
      tap((result) => {
        patchState({ used: result });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(BanPublicationOptions)
  banPublicationOptions(
    { setState }: StateContext<PublicationOptionStateModel>,
    { publicationOption }: BanPublicationOptions,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.publicationOptionervice
      .banPublicationOption(publicationOption.id)
      .pipe(
        tap((option) => {
          this.store.dispatch(new GetPublicationOptions());

          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication-option.publication-option-banned-successfully: Publication Option banned successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(PermitPublicationOptions)
  permitPublicationOptions(
    { setState }: StateContext<PublicationOptionStateModel>,
    { publicationOption }: PermitPublicationOptions,
  ) {
    this.store.dispatch(new SetProgressOn());

    return this.publicationOptionervice
      .permitPublicationOption(publicationOption.id)
      .pipe(
        tap((option) => {
          this.store.dispatch(new GetPublicationOptions());
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            'Publication Option permitted successfully',
            successStyle,
          );
        }),
      );
  }
}
