import { ContactForm, UpdateContactUsPriorityDto, UpdateContactUsStatusDto } from "../models/contact-form.model";

export class CreateContactUs {
    static readonly type = `[ContactUs] ${CreateContactUs.name}`;
    constructor(public contactUs: ContactForm) {}
  }

  export class GetPaginatedContactUs {
    static readonly type = `[ContactUS] ${GetPaginatedContactUs.name}`;
    constructor(
      public readonly pageNumber: number,
      public readonly pageSize: number,
    ) {}
  }

  export class GetMyPaginatedContacts {
    static readonly type = `[ContactUS] ${GetMyPaginatedContacts.name}`;
    constructor(
      public readonly pageNumber: number,
      public readonly pageSize: number,
    ) {}
  }

  export class GetContactUsDetail {
    static readonly type = `[ContactUS] ${GetContactUsDetail.name}`;
    constructor(public id: String) {}
  }
  
export class GetSelectedContactUs {
  static readonly type = `[DocumentForm] ${GetSelectedContactUs.name}`;
  constructor(public id: String) {}
}

export class SetSelectedContactForm {
  static readonly type = `[SetSelectedContactForm] ${SetSelectedContactForm.name}`;
  constructor(public selectedContactForm: ContactForm | undefined) {}
}

export class ChangeContactUsStatus {
  static readonly type = `[task] ${ChangeContactUsStatus.name}`;
  constructor(public updatedContactUs: UpdateContactUsStatusDto) {}
}

export class ChangeContactUsPriority {
  static readonly type = `[task] ${ChangeContactUsPriority.name}`;
  constructor(public updatedContactUs: UpdateContactUsPriorityDto) {}
}

export class DownloadAttachment {
  static readonly type = `[ContactUs] ${DownloadAttachment.name}`;
  constructor(
    public readonly contactId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}

export class PreviewAttachment {
  static readonly type = `[ContactUs] ${PreviewAttachment.name}`;
  constructor(
    public readonly contactId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}