export class GetResearchVsTime {
  static readonly type = `[ResearchDashboard] ${GetResearchVsTime.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetCommunicationTimeGraph {
  static readonly type = `[ResearchDashboard] ${GetCommunicationTimeGraph.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetCommunicationCount {
  static readonly type = `[ResearchDashboard] ${GetCommunicationCount.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly pageNumber: number,
    public readonly pageSize: number,
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetResearchCount {
  static readonly type = `[ResearchDashboard] ${GetResearchCount.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly pageNumber: number,
    public readonly pageSize: number,
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetBudgetTimeGraph {
  static readonly type = `[ResearchDashboard] ${GetBudgetTimeGraph.name}`;
  constructor(
    public offices: string[],
    public users: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetResearchPublicationData {
  static readonly type = `[ResearchDashboard] ${GetResearchPublicationData.name}`;
  constructor(
    public start?: Date,
    public end?: Date,
    public officeIds?: string[],
    public userIds?: string[],
  ) {}
}

export class GetFullLengthResearches {
  static readonly type = `[ResearchDashboard] ${GetFullLengthResearches.name}`;
  constructor(
    public officeIds?: string[],
    public userIds?: string[],
    public startDate?: Date,
    public endDate?: Date,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class GetResearchCommunications {
  static readonly type = `[ResearchDashboard] ${GetResearchCommunications.name}`;
  constructor(
    public officeIds?: string[],
    public userIds?: string[],
    public startDate?: Date,
    public endDate?: Date,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}
export class GetBudgetPerCommunication {
  static readonly type = `[ResearchDashboard] ${GetBudgetPerCommunication.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetTotalCost {
  static readonly type = `[ResearchDashboard] ${GetTotalCost.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetTotalCostResearch {
  static readonly type = `[ResearchDashboard] ${GetTotalCostResearch.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetTotalCostCommunication {
  static readonly type = `[ResearchDashboard] ${GetTotalCostCommunication.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetCommunicationsWithStatus {
  static readonly type = `[ResearchDashboard] ${GetCommunicationsWithStatus.name}`;
  constructor(
    public readonly startDate?: Date,
    public readonly endDate?: Date,
    public readonly officeIds?: string[],
    public readonly userIds?: string[],
  ) {}
}

export class GetCommunicationTypeInstances {
  static readonly type = `[ResearchDashboard] ${GetCommunicationTypeInstances.name}`;
  constructor(
    public readonly officeIds: string[],
    public readonly userIds: string[],
    public readonly startDate?: Date,
    public readonly endDate?: Date,
  ) {}
}

export class GetResearchEvaluations {
  static readonly type = `[ResearchDashboard] ${GetResearchEvaluations.name}`;
  constructor(
    public officeIds?: string[],
    public userIds?: string[],
    public startDate?: Date,
    public endDate?: Date,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class GetDepartmentVsPublication {
  static readonly type = `[ResearchDashboard] ${GetDepartmentVsPublication.name}`;
  constructor(
    public officeIds?: string[] | null,
    public userIds?: string[] | null,
    public startDate?: Date | null,
    public endDate?: Date | null,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}