<mat-radio-group *appHasPermission="hasGetFlatOfficeNodesPermission()">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      [style.display]="shouldRender(node) ? 'flex' : 'none'"
      class="tree-node"
    >
      <button mat-icon-button disabled></button>
      <mat-radio-button
        [value]="node"
        [disabled]="node?.id === parentOffice?.id"
        [checked]="node?.id === selectedFlatOfficeNode?.id"
        (change)="selectFlatOfficeNode($event, node)"
        >{{ node.name }}</mat-radio-button
      >
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      [style.display]="shouldRender(node) ? 'flex' : 'none'"
      class="tree-node"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
        (click)="node.isExpanded = !node.isExpanded"
        [style.visibility]="node.expandable ? 'visible' : 'hidden'"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-radio-button
        [value]="node"
        [disabled]="node?.id === parentOffice?.id"
        [checked]="node?.id === selectedFlatOfficeNode?.id"
        (change)="selectFlatOfficeNode($event, node)"
        >{{ node.name }}</mat-radio-button
      >
    </mat-tree-node>
  </mat-tree>
</mat-radio-group>

<mat-dialog-actions align="end">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    [disabled]="!selectedFlatOfficeNode?.id"
    (click)="done()"
    i18n="@@offices.office-selector.done">
    Done
  </button>
  <button
    class="submit"
    mat-flat-button
    color="accent"
    (click)="cancel()"
    mat-dialog-close
    i18n="@@offices.office-selector.cancel">
    Cancel
  </button>
</mat-dialog-actions>
