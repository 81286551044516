import {
  CreateFormFieldDto,
  FormFields,
} from '../models/form-fields.model';

export class CreateFormField {
  static readonly type = `[FormField] ${CreateFormField.name}`;
  constructor(public templateField: CreateFormFieldDto) {}
}

export class AddFormField {
  static readonly type = `[FormField] ${AddFormField.name}`;
  constructor(public newFormField: FormFields) {}
}

export class ResetSelectedFormFields {
  static readonly type = `[FormField] ${ResetSelectedFormFields.name}`;
  constructor() {}
}
export class ResetSelectedFormField {
  static readonly type = `[FormField] ${ResetSelectedFormField.name}`;
  constructor() {}
}

export class SetFormFieldUpdatingMode {
  static readonly type = `[FormField] ${SetFormFieldUpdatingMode.name}`;
  constructor(public isUpdatingFormField: boolean) {}
}

export class SetSelectedFormField {
  static readonly type = `[FormField] ${SetSelectedFormField.name}`;
  constructor(public selectedFormField: FormFields | undefined | null) {}
}

export class UpdateFormField {
  static readonly type = `[FormField] ${UpdateFormField.name}`;
  constructor(public updatedFormField: FormFields | undefined) {}
}

export class RemoveFormField {
  static readonly type = `[FormField] ${RemoveFormField.name}`;
  constructor(public templateField: FormFields | undefined) {}
}

export class SelectFormFields {
  static readonly type = `[FormField] ${SelectFormFields.name}`;
  constructor(public selectedFormFields: FormFields[]) {}
}

export class DeleteFormField {
  static readonly type = `[FormField] ${DeleteFormField.name}`;
  constructor(public templateFieldId: string) {}
}

export class GetDataTypes {
  static readonly type = `[FormField] ${GetDataTypes.name}`;
  constructor() {}
}
export class GetFormFields {
  static readonly type = `[FormField] ${GetFormFields.name}`;
  constructor(
    public documentTemplateId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}
