<mat-card class="table-card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title i18n="@@researches.department-vs-publication.departments-with-their-total-publication">Departments with their total publication</mat-card-title>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <mat-paginator
        [length]="(deparmentVsPublication$ | async)?.totalCount"
        [pageIndex]="((deparmentVsPublication$ | async)?.pageNumber ?? 1) - 1"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
        (page)="paginatedDepartmentVsPublication($event)"
      >
      </mat-paginator>
      <table
        mat-table
        [dataSource]="(deparmentVsPublication$ | async)?.items ?? []"
        class="mat-elevation-z0"
      >
        <ng-container matColumnDef="office">
          <th mat-header-cell *matHeaderCellDef i18n="@@researches.department-vs-publication.departments">Departments</th>
          <td mat-cell *matCellDef="let element">{{ element.office }}</td>
        </ng-container>
  
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef i18n="@@researches.department-vs-publication.total-publication">Total publication</th>
          <td mat-cell *matCellDef="let element">{{ element.total }}</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
