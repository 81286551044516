import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  PublicationOption,
  PublicationTemplate,
} from '../models/publication-option.model';
import { PublicationOptionSelector } from '../store/publication-option.selector';
import { Observable } from 'rxjs';
import {
  CreatePublicationOption,
  DeletePublicationTemplates,
  DeletePublicationOptions,
  GetEditorTypes,
  GetPublicationOptions,
  UpdatePublicationOption,
  DownloadPublicationTemplate,
  IsPublicationOptionBeingUsed,
  BanPublicationOptions,
  PermitPublicationOptions,
} from '../store/publication-option.action';
import { EditorType } from '../models/editor-type.model';

@Injectable({
  providedIn: 'root',
})
export class PublicationOptionFacade {
  constructor(private store: Store) {}

  @Select(PublicationOptionSelector.publicationOptions)
  publicationOptions$!: Observable<PublicationOption[]>;

  @Select(PublicationOptionSelector.editorTypes)
  editorTypes$!: Observable<EditorType[]>;

  @Select(PublicationOptionSelector.isBeingUsed)
  isBeingUsed$!: Observable<string[]>;

  dispatchGetPublicationOptions() {
    this.store.dispatch(new GetPublicationOptions());
  }

  dispatchGetEditorTypes() {
    this.store.dispatch(new GetEditorTypes());
  }

  dispatchCreatePublicationOption(publicationOption: any) {
    this.store.dispatch(new CreatePublicationOption(publicationOption));
  }
  dispatchUpdatePublicationOption(publicationOption: any) {
    this.store.dispatch(new UpdatePublicationOption(publicationOption));
  }

  dispatchDeletePublicationOptionTemplates(id: string, templateIds: string[]) {
    this.store.dispatch(new DeletePublicationTemplates(id, templateIds));
  }

  dispatchhDeletePublicationOption(publicationOption: PublicationOption) {
    this.store.dispatch(new DeletePublicationOptions(publicationOption));
  }

  dispatchDownloadPublicationTemplate(template: PublicationTemplate) {
    this.store.dispatch(new DownloadPublicationTemplate(template));
  }

  dispatchGetIsPublicationBeingUsed() {
    this.store.dispatch(new IsPublicationOptionBeingUsed());
  }

  dispatchhBanPublicationOption(publicationOption: PublicationOption) {
    this.store.dispatch(new BanPublicationOptions(publicationOption));
  }

  dispatchPermitPublicationOption(publicationOption: PublicationOption) {
    this.store.dispatch(new PermitPublicationOptions(publicationOption));
  }
}
