import { Component } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowType } from '../../models/workfflow-type.model';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { InitiatedWorkflowData } from '../../models/workflow.model';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkflowStatus } from 'src/app/core/constants/requirement_constants';
import { WorkflowFacade } from '../../facades/workflow-analytics.facade';
import { RxState } from '@rx-angular/state';
import { ReportedCommentFacade } from '../../facades/reported-comment.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { MatDialog } from '@angular/material/dialog';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

interface MyInitiatedWorkflowsReportComponentState {
  myWorkflows: PaginatedList<InitiatedWorkflowData>;
  workflowTypes: WorkflowType[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
}

const initialMyInitiatedWorkflowsReportComponentState: MyInitiatedWorkflowsReportComponentState =
  {
    myWorkflows: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    workflowTypes: [],
    selectedFlatOfficeNode: undefined,
  };

@Component({
  selector: 'app-my-initiated-workflows-report',
  templateUrl: './my-initiated-workflows-report.component.html',
  styleUrls: ['./my-initiated-workflows-report.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: MyInitiatedWorkflowsReportComponent}],
})
export class MyInitiatedWorkflowsReportComponent {
  displayedColumns: string[] = [
    'WorkflowTypeName',
    'OfficeName',
    'WorkflowStatus',
    'InitiatedAt',
    'CompletedAt',
  ];

  dataSource!: MatTableDataSource<any>;
  workflowTypes$ = this.state.select('workflowTypes');
  workflowTypes: WorkflowType[] = [];

  myWorkflow$ = this.state.select('myWorkflows');
  workflowStatuses = Object.values(WorkflowStatus);
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  filterForm: FormGroup;

  firstPageLabel = $localize`:@@documents.my-initiated-workflows-report.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.my-initiated-workflows-report.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.my-initiated-workflows-report.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.my-initiated-workflows-report.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.my-initiated-workflows-report.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.my-initiated-workflows-report.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.my-initiated-workflows-report.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.my-initiated-workflows-report.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private workflowFacade: WorkflowFacade,
    private state: RxState<MyInitiatedWorkflowsReportComponentState>,
    private reportedCommentFacade: ReportedCommentFacade,
    private fb: FormBuilder,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
  ) {
    this.state.set(initialMyInitiatedWorkflowsReportComponentState);
    this.state.connect('myWorkflows', this.workflowFacade.myWorkflows$);

    this.state.connect(
      'workflowTypes',
      this.reportedCommentFacade.workflowTypes$,
    );

    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    this.filterForm = this.fb.group({
      status: [''],
      workflowType: [''],
      initiationStartDate: [null],
      initiationEndDate: [null],
      completionStartDate: [null],
      completionEndDate: [null],
    });

    this.filterForm.valueChanges.subscribe((val) => this.applyFilter());
  }
  changes = new RxState();
  ngOnInit(): void {
    this.myWorkflow$.subscribe((report) => {
      this.dataSource = new MatTableDataSource<InitiatedWorkflowData>(
        report.items,
      );
      this.length = report.totalCount;
    });
    this.reportedCommentFacade.dispatchGetWorkflowTypes();

    this.workflowTypes$.subscribe((types) => {
      this.workflowTypes = types;
    });
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilter();
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilter();
    });
  }

  loadPaginatedWorkflows(event: PageEvent) {
    this.workflowFacade.dispatchGetMyWorkflows(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  applyFilter() {
    let {
      status,
      workflowType,
      initiationStartDate,
      initiationEndDate,
      completionStartDate,
      completionEndDate,
    } = this.filterForm.value;
    let officeId = undefined;
    this.selectedFlatOfficeNode$.subscribe((office) => {
      if (office) {
        officeId = office.id;
      }
    });

    this.workflowFacade.dispatchGetMyWorkflows(
      1,
      this.pageSize,
      workflowType?.id,
      officeId,
      status,
      initiationStartDate,
      initiationEndDate,
      completionStartDate,
      completionEndDate,
    );
  }

  resetFilter() {
    this.filterForm.reset();
    this.officeFacade.dispatchResetSelectedOffice();
    this.workflowFacade.dispatchGetMyWorkflows(1, 10);
  }
}
