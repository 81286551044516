import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission-directive/has-permission.directive';
import {HasFeatureDirective} from './has-feature.directive/has-feature.directive';

@NgModule({
  declarations: [HasPermissionDirective, HasFeatureDirective],
  imports: [CommonModule],
  exports: [HasPermissionDirective, HasFeatureDirective],
})
export class SharedDirectivesModule {}
