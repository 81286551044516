import { NotifiedUser } from './../../documents/workflow-steps/models/notified-user.model';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProcessInstanceSelector } from '../store/process-instance.selector';
import { Observable } from 'rxjs';

import {
  AddTeamMembersDto,
  CreateProcessInstanceModel,
  DeleteProcessInstanceDto,
  ProcessInstance,
  ProcessInstanceDetail,
  ProcessInstanceFilter,
  ProcessInstanceMultipleFilter,
  ProcessInstanceStatus,
  UpdateProcessInstanceModel,
} from '../models/process-instance.model';

import {
  CreateProcessInstance,
  DeleteProcessInstance,
  GetProcessInstanceDetail,
  GetProcessInstances,
  SetSelectedProcessInstance,
  SetUpdateStatus,
  UpdateProcessInstance,
  SearchProcessInstances,
  AssignOwnerToResearchInstance,
  SubmitProcessInstanceSchedule,
  ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData,
  UpdateProcessInstanceSchedule,
  GetArchivedProcessInstances,
  DeleteArchivedProcessInstance,
  RestoreArchivedProcessInstance,
  ArchiveProcessInstance,
  AddTeamMembers,
  DeleteTeamMember,
  ResetSelectedProcessInstance,
  GetOwnedProcessInstances,
  GetProcessInstancesByAssignedTasks,
  GetProcessInstanceTeamMembers,
  CancelDeleteProcess,
  DownloadProcess,
  GetProcessInstanceFilters,
  GetProcessInstancesByStatus,
  GetProcessInstancesByDate,
  GetProcessInstancesByFilter,
  SetProcessInstanceMultipleFilter,
  ResetProcessInstanceFilter,
} from '../store/process-instance.actions';

import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Deadline } from '../models/deadline.model';

@Injectable({
  providedIn: 'root',
})
export class ProcessInstanceFacade {
  @Select(ProcessInstanceSelector.processInstances)
  processInstances$!: Observable<PaginatedList<ProcessInstance>>;

  @Select(ProcessInstanceSelector.ownedProcessInstances)
  ownedProcessInstances$!: Observable<PaginatedList<ProcessInstanceDetail>>;

  @Select(ProcessInstanceSelector.selectedProcessInstance)
  selectedProcessInstance$!: Observable<ProcessInstance>;

  @Select(ProcessInstanceSelector.update)
  update$!: Observable<boolean>;

  @Select(ProcessInstanceSelector.processInstanceDetail)
  processInstanceDetail$!: Observable<ProcessInstanceDetail>;

  @Select(ProcessInstanceSelector.showSearchTermAndFiltersData)
  showSearchTermAndFiltersData$!: Observable<boolean>;

  @Select(ProcessInstanceSelector.archivedProcessInstances)
  archivedProcessInstances$!: Observable<PaginatedList<ProcessInstance>>;

  @Select(ProcessInstanceSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(ProcessInstanceSelector.processInstancesByAssignedTasks)
  processInstancesByAssignedTasks$!: Observable<PaginatedList<ProcessInstance>>;

  @Select(ProcessInstanceSelector.processInstanceFilters)
  processInstanceFilters$!: Observable<ProcessInstanceFilter[]>;

  @Select(ProcessInstanceSelector.multipleFilterData)
  multipleFilterData$!: Observable<ProcessInstanceMultipleFilter>;

  @Select(ProcessInstanceSelector.isProcessInstanceFilterReset)
  isProcessInstanceFilterReset$!: Observable<boolean>;

  constructor(private store: Store) {}

  dispatchGetProcessInstances(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetProcessInstances(pageNumber, pageSize));
  }
  dispatchGetOwnedProcessInstances(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetOwnedProcessInstances(pageNumber, pageSize));
  }

  dispatchCreateProcessInstance(
    createProcessInstance: CreateProcessInstanceModel,
  ) {
    this.store.dispatch(new CreateProcessInstance(createProcessInstance));
  }

  dispatchAssignOwnerToResearchInstance(
    processInstanceId: string,
    assignedOwnerId: string,
  ) {
    this.store.dispatch(
      new AssignOwnerToResearchInstance(processInstanceId, assignedOwnerId),
    );
  }

  dispatchSetSelectedProcessInstance(processInstance: ProcessInstance) {
    this.store.dispatch(new SetSelectedProcessInstance(processInstance));
  }

  dispatchSetUpdateStatus(updateStatus: boolean) {
    this.store.dispatch(new SetUpdateStatus(updateStatus));
  }

  dispatchUpdateProcessInstance(processInstance: UpdateProcessInstanceModel) {
    this.store.dispatch(new UpdateProcessInstance(processInstance));
  }
  dispatchSearchProcessInstances(searchTerm: string, processesIds: string[]) {
    this.store.dispatch(new SearchProcessInstances(searchTerm, processesIds));
  }

  dispatchDeleteProcessInstance(deleteProcessInstanceDto: DeleteProcessInstanceDto) {
    this.store.dispatch(new DeleteProcessInstance(deleteProcessInstanceDto));
  }

  dispatchGetProcessInstanceDetail(processInstanceId: string) {
    this.store.dispatch(new GetProcessInstanceDetail(processInstanceId));
  }

  dispatchSubmitProcessInstanceSchedule(deadline: Deadline) {
    this.store.dispatch(new SubmitProcessInstanceSchedule(deadline));
  }

  dispatchUpdateProcessInstanceSchedule(deadline: Deadline) {
    this.store.dispatch(new UpdateProcessInstanceSchedule(deadline));
  }

  dispatchDownloadProcess(processInstanceId: string){
    this.store.dispatch(new DownloadProcess(processInstanceId));
  }

  dispachToggleSearchTermVisibility(visible: boolean) {
    this.store.dispatch(
      new ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData(visible),
    );
  }
  dispatchGetArchivedProcessInstances(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetArchivedProcessInstances(pageNumber, pageSize));
  }
  dispatchDeleteArchivedProcessInstance(deleteProcessInstance: DeleteProcessInstanceDto) {
    this.store.dispatch(new DeleteArchivedProcessInstance(deleteProcessInstance));
  }

  dispatchCancelProcessInstanceDeletion(processInstanceId: string) {
    this.store.dispatch(new CancelDeleteProcess(processInstanceId));
  }

  dispatchRestoreArchivedProcessInstance(processInstanceId: string) {
    this.store.dispatch(new RestoreArchivedProcessInstance(processInstanceId));
  }
  dispatchArchiveProcessInstance(processInstanceId: string) {
    this.store.dispatch(new ArchiveProcessInstance(processInstanceId));
  }
  dispatchAddTeamMembers(addTeamMembersDto: AddTeamMembersDto) {
    this.store.dispatch(new AddTeamMembers(addTeamMembersDto));
  }
  dispatchDeleteTeamMember(
    processInstanceId: string | undefined | null,
    teamMemberId: string,
  ) {
    this.store.dispatch(new DeleteTeamMember(processInstanceId, teamMemberId));
  }
  dispatchResetSelectedProcessInstance() {
    this.store.dispatch(new ResetSelectedProcessInstance());
  }
  dispatchGetProcessInstancesByAssignedTasks(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetProcessInstancesByAssignedTasks(pageNumber, pageSize));
  }
  dispatchGetProcessInstanceTeamMembers(processInstanceId: string) {
    this.store.dispatch(new GetProcessInstanceTeamMembers(processInstanceId));
  }
  dispatchGetProcessInstanceFilters() {
    this.store.dispatch(new GetProcessInstanceFilters());
  }
  dispatchGetProcessInstancesByStatus(status:ProcessInstanceStatus, pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetProcessInstancesByStatus(status, pageNumber, pageSize));
  }
  dispatchGetProcessInstancesByDate(startDate:string, endDate:string, pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetProcessInstancesByDate(startDate, endDate, pageNumber, pageSize));
  }
  dispatchGetProcessInstancesByFilter(filterData:ProcessInstanceMultipleFilter) {
    this.store.dispatch(new GetProcessInstancesByFilter(filterData));
  }
  dispatchSetProcessInstanceMultipleFilter(filterData:ProcessInstanceMultipleFilter | undefined) {
    this.store.dispatch(new SetProcessInstanceMultipleFilter(filterData));
  }
  dispatchResetProcessInstanceFilter(isReset:boolean) {
    this.store.dispatch(new ResetProcessInstanceFilter(isReset));
  }
}
