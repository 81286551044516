import { Observable } from 'rxjs';
import { ExcelUploadReport } from './../models/excel-upload-report.model';
import {
  CreateOffice,
  DeleteOffice,
  GetOffices,
  DownloadExcelTemplate,
  FlushExcelTemplate,
  GetFlatOfficeNodes,
  SelectFlatOfficeNode,
  SelectFlatOfficeNodes,
  SelectOffice,
  UpdateOffice,
  UploadExcel,
  ResetSelectedOffice,
  ResetSelectedOffices,
  GetOfficeTree,
  SetPageIndexAndSize,
  GetOffice,
  UpdateOfficeTreeStructure,
  GetReportsToOfficeNodes,
  GetUserOfficeTree,
  SetSelectedFlatOfficeNode,
} from '../store/office.actions';

import { OfficeSelector } from '../store/office.selector';
import {
  CreateOfficeDto,
  IUpdateOfficeStructure,
  Office,
  UpdateOfficeDto,
} from '../models/office.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OfficeTree, OfficeTreeNode } from '../models/office-tree.model';
import { FlatOfficeNode } from '../models/flat-office-node.model';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class OfficeFacade {
  @Select(OfficeSelector.offices)
  offices$!: Observable<Office[]>;

  @Select(OfficeSelector.selectedOffice)
  selectedOffice$!: Observable<Office>;

  @Select(OfficeSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(OfficeSelector.selectedFlatOfficeNode)
  selectedFlatOfficeNode$!: Observable<FlatOfficeNode>;

  @Select(OfficeSelector.selectedFlatOfficeNodes)
  selectedFlatOfficeNodes$!: Observable<FlatOfficeNode[]>;

  @Select(OfficeSelector.paginatedOffices)
  PaginatedOffices$!: Observable<PaginatedList<Office>>;

  @Select(OfficeSelector.flatOfficeNodes)
  flatOfficeNodes$!: Observable<FlatOfficeNode[]>;

  @Select(OfficeSelector.officeTreeNodes)
  officeTreeNodes$!: Observable<OfficeTreeNode>;

  @Select(OfficeSelector.templateExcelFileUrl)
  tempalateExcelFileUrl$!: Observable<string>;

  @Select(OfficeSelector.excelUploadReports)
  excelUploadReports$!: Observable<ExcelUploadReport[]>;

  @Select(OfficeSelector.office)
  office$!: Observable<Office>;

  constructor(private store: Store) {}

  dispatchSetPageIndexAndSize(pageIndex: number, pageSize: number) {
    this.store.dispatch(new SetPageIndexAndSize(pageIndex, pageSize));
  }

  dispatchUploadExcel(excelFileUpload: FormData) {
    return this.store.dispatch(new UploadExcel(excelFileUpload));
  }

  dispatchFlushExcelTemplate() {
    return this.store.dispatch(new FlushExcelTemplate());
  }

  dispatchDownloadExcelTemplate() {
    return this.store.dispatch(new DownloadExcelTemplate());
  }

  dispatchCreateOffice(office: CreateOfficeDto) {
    this.store.dispatch(new CreateOffice(office));
  }

  dispatchGetOffices(pageNumber: number, pageSize: number) {
    return this.store.dispatch(new GetOffices(pageNumber, pageSize));
  }
  dispatchResetSelectedOffice() {
    return this.store.dispatch(new ResetSelectedOffice());
  }

  dispatchResetSelectedOffices() {
    return this.store.dispatch(new ResetSelectedOffices());
  }
  dispatchGetFlatOfficeNodes() {
    this.store.dispatch(new GetFlatOfficeNodes());
  }

  dispatchSetSelectedFlatOfficeNode(id: string) {
    this.store.dispatch(new SetSelectedFlatOfficeNode(id));
  }

  dispatchGetReportsToNodes(officeId: string) {
    this.store.dispatch(new GetReportsToOfficeNodes(officeId));
  }

  dispatchGetOfficeTree() {
    return this.store.dispatch(new GetOfficeTree());
  }

  dispatchUpdateOfficeStructure(updateStructure: IUpdateOfficeStructure) {
    return this.store.dispatch(new UpdateOfficeTreeStructure(updateStructure));
  }

  dispatchSelectFlatOfficeNode(office: FlatOfficeNode | undefined) {
    return this.store.dispatch(new SelectFlatOfficeNode(office));
  }

  dispatchSelectFlatOfficeNodes(office: FlatOfficeNode[]) {
    return this.store.dispatch(new SelectFlatOfficeNodes(office));
  }

  dispatchSelectOffice(office: Office) {
    return this.store.dispatch(new SelectOffice(office));
  }

  dispatchUpdateOffice(id: string, office: UpdateOfficeDto) {
    return this.store.dispatch(new UpdateOffice(office));
  }

  dispatchDeleteOffice(id: string) {
    return this.store.dispatch(new DeleteOffice(id));
  }

  dispatchGetOffice(id: string) {
    return this.store.dispatch(new GetOffice(id));
  }

  dispatchGetUserOfficeTree() {
    return this.store.dispatch(new GetUserOfficeTree());
  }
  resetSelectedFlatOfficeNode(): void {
    this.dispatchSetSelectedFlatOfficeNode("");
  }
}
