<div #card>
  <mat-card
    class="notification-card"
    appearance="outlined"
    [class.unread]="!notification.isViewed"
  >
    <mat-card-content>
      <p class="mat-body-2">
        {{ notification.message }}
      </p>
      <div class="actions">
        <p class="mat-caption">
          {{ notification.dateCreated | date: "medium" }}
        </p>
        <button
          mat-flat-button
          color="primary"
          (click)="visible.emit()"
          *ngIf="!notification.isViewed"
          i18n="@@core.notification-card.mark-as-read"
        >
          Mark as read
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
