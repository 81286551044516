import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { TorFacade } from '../../facades/tor.facades';
import { TorConfigurationFacade } from 'src/app/researches/tor-settings/facade/tor-setttings.facade';
import { ActivityType } from 'src/app/researches/tor-settings/models/activityType.model';
import { QuantityType } from 'src/app/researches/tor-settings/models/quantityType.model';
import {
  CostBreakdownItem,
  CreateCostBreakdownItemRequest,
} from '../../models/cost-breakdown';
import { TorDetail } from '../../models/tor.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface CostBreakdownFormComponentState {
  selectedTorDetail: TorDetail | undefined;
  selectedCostBreakdownItem: CostBreakdownItem | undefined;
  updateCostBreakdownItem: boolean;
}
const initCostBreakdownFormComponentState: CostBreakdownFormComponentState = {
  selectedTorDetail: undefined,
  selectedCostBreakdownItem: undefined,
  updateCostBreakdownItem: false,
};

interface ActivityTypeListState {
  activityTypes: ActivityType[];
}

const initActivityTypeListState: ActivityTypeListState = {
  activityTypes: [],
};

interface QuantityTypeListState {
  quantityTypes: QuantityType[];
}

const initQuantityTypeListState: QuantityTypeListState = {
  quantityTypes: [],
};

@Component({
  selector: 'app-process-form',
  templateUrl: './cost-breakdown-form.component.html',
  styleUrls: ['./cost-breakdown-form.component.scss'],
  providers: [RxState],
})
export class CostBreakdownFormComponent implements OnInit {
  costBreakdownForm: FormGroup;
  activityTypes$ = this.activityTypeListState.select('activityTypes');
  activityTypes: ActivityType[] = [];
  quantityTypes$ = this.quantityTypeListState.select('quantityTypes');
  quantityTypes: QuantityType[] = [];
  selectedTorDetail$ = this.state.select('selectedTorDetail');
  selectedTorDetail: TorDetail | undefined;
  currencyOptions: string[] = ['ETB', 'USD'];
  selectedCostBreakdownItem$ = this.state.select('selectedCostBreakdownItem');
  selectedCostBreakdownItem: CostBreakdownItem | undefined;
  updateCostBreakdownItemStatus$ = this.state.select('updateCostBreakdownItem');
  updateCostBreakdownItem: boolean = false;

  placeholderToggleLabel = {
      activityName: $localize`:@@researches.cost-breakdown-form.activity-name: activity Name` ,
      numberFive: $localize`:@@researches.cost-breakdown-form.5: 5` ,
      processRemark: $localize`:@@researches.cost-breakdown-form.this-is-remark-for-the-process: This is remark for the process.` ,
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private torFacade: TorFacade,
    private torConfigurationFacade: TorConfigurationFacade,
    private state: RxState<CostBreakdownFormComponentState>,
    private activityTypeListState: RxState<ActivityTypeListState>,
    private quantityTypeListState: RxState<QuantityTypeListState>,
  ) {
    this.state.set(initCostBreakdownFormComponentState);
    this.state.connect('selectedTorDetail', this.torFacade.selectedTorDetail$);
    this.state.connect(
      'selectedCostBreakdownItem',
      this.torFacade.selectedCostBreakdownItem$,
    );
    this.state.connect(
      'updateCostBreakdownItem',
      this.torFacade.updateCostBreakdownItemStatus$,
    );
    this.activityTypeListState.set(initActivityTypeListState);
    this.activityTypeListState.connect(
      'activityTypes',
      this.torConfigurationFacade.activityTypes$,
    );
    this.quantityTypeListState.set(initQuantityTypeListState);
    this.quantityTypeListState.connect(
      'quantityTypes',
      this.torConfigurationFacade.quantityTypes$,
    );
    this.costBreakdownForm = this.fb.group({
      activity: ['', [Validators.required]],
      activityType: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      quantityType: ['', [Validators.required]],
      unitCost: ['', [Validators.required]],
      totalCost: ['', []],
      currency: [[Validators.required]],
      remark: ['', [Validators.required]],
    });
    this.costBreakdownForm.get('unitCost')?.valueChanges.subscribe(() => {
      this.calculateTotalCost();
    });

    this.costBreakdownForm.get('quantity')?.valueChanges.subscribe(() => {
      this.calculateTotalCost();
    });
  }
  ngOnInit(): void {
    this.selectedTorDetail$.subscribe((selectedTorDetail) => {
      this.selectedTorDetail = selectedTorDetail;
    });
    this.selectedCostBreakdownItem$.subscribe((selectedCostBreakdownItem) => {
      this.selectedCostBreakdownItem = selectedCostBreakdownItem;
      this.updateForm();
    });
    this.updateCostBreakdownItemStatus$.subscribe((updateCostBreakdownItem) => {
      this.updateCostBreakdownItem = updateCostBreakdownItem;
      this.updateForm();
    });

    this.torConfigurationFacade.dispatchGetActivityTypes();
    this.activityTypes$.subscribe((types) => {
      this.activityTypes = types;
    });
    this.torConfigurationFacade.dispatchGetQuantityTypes();
    this.quantityTypes$.subscribe((types) => {
      this.quantityTypes = types;
    });
  }
  calculateTotalCost() {
    const unitCost = this.costBreakdownForm.get('unitCost')?.value;
    const quantity = this.costBreakdownForm.get('quantity')?.value;
    const totalCost = unitCost * quantity;
    this.costBreakdownForm.get('totalCost')?.setValue(totalCost);
  }

  save() {
    const { valid, touched, dirty } = this.costBreakdownForm;
    if (valid && (touched || dirty)) {
      const activity = this.costBreakdownForm.value.activity;
      const activityType: ActivityType =
        this.costBreakdownForm.value.activityType;
      const quantity = this.costBreakdownForm.value.quantity;
      const quantityType: QuantityType =
        this.costBreakdownForm.value.quantityType;
      const unitCost = this.costBreakdownForm.value.unitCost;
      const currency = this.selectedTorDetail?.currency;
      const remark = this.costBreakdownForm.value.remark;
      const costBreakdownItem: CreateCostBreakdownItemRequest = {
        activity: activity,
        activityTypeId: activityType.id,
        quantity: quantity,
        quantityTypeId: quantityType.id,
        unitCost: unitCost,
        currency: currency as string,
        remark: remark,
      };
      if (this.selectedTorDetail) {
        this.torFacade.dispatchAddCostBreakdownItem(
          this.selectedTorDetail.id,
          costBreakdownItem,
        );
        this.dialog.closeAll();
      }
    }
  }

  update() {
    const { valid, touched, dirty } = this.costBreakdownForm;
    if (this.updateCostBreakdownItem && valid && (touched || dirty)) {
      const activity = this.costBreakdownForm.value.activity;
      const activityType: ActivityType =
        this.costBreakdownForm.value.activityType;
      const quantity = this.costBreakdownForm.value.quantity;
      const quantityType: QuantityType =
        this.costBreakdownForm.value.quantityType;
      const unitCost = this.costBreakdownForm.value.unitCost;
      const currency = this.costBreakdownForm.value.currency;
      const remark = this.costBreakdownForm.value.remark;
      const costBreakdownItem: CreateCostBreakdownItemRequest = {
        activity: activity,
        activityTypeId: activityType.id,
        quantity: quantity,
        quantityTypeId: quantityType.id,
        unitCost: unitCost,
        currency: currency,
        remark: remark,
      };
      if (this.selectedTorDetail && this.selectedCostBreakdownItem) {
        this.torFacade.dispatchUpdateCostBreakdownItem(
          this.selectedTorDetail.id,
          this.selectedCostBreakdownItem.id,
          costBreakdownItem,
        );
        this.dialog.closeAll();
      }
    }
  }

  updateForm() {
    if (this.updateCostBreakdownItem && this.selectedCostBreakdownItem) {
      this.costBreakdownForm.setValue({
        activity: this.selectedCostBreakdownItem.activity,
        activityType: this.selectedCostBreakdownItem.activityType,
        quantity: this.selectedCostBreakdownItem.quantity,
        quantityType: this.selectedCostBreakdownItem.quantityType,
        unitCost: this.selectedCostBreakdownItem.unitCost,
        totalCost: this.selectedCostBreakdownItem.totalCost,
        currency: this.selectedCostBreakdownItem.currency,
        remark: this.selectedCostBreakdownItem.remark,
      });
    }
  }

  public objectComparisonFunction = function (
    option: any,
    value: any,
  ): boolean {
    return option.id === value.id;
  };
}
