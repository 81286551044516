export interface WorkflowComment {
  id?: string;
  comment: string;
  commentedUserId: string;
  dateTime: string;
  replies: WorkflowComment[];
  fullName: string;
  isReported: boolean;
}

export interface WorkflowCommentReply {
  id?: string;
  comment: string;
  commentedUserId: string;
  dateTime: string;
  fullName: string;
}

export interface WorkflowCommentPolicy {
  id?: string;
  description: string;
}

export interface ReportedWorkflowComment {
  id?: string;
  workflowComment: WorkflowComment;
  issueDescription?: string;
  resolutionDescription?: string;
  issuedById?: string;
  resolvedById?: string;
  issuedDate: string;
  resolvedDate?: string;
  commentReportStatus: string;
  issuedByFullName: string;
  resolvedByFullName?: string;
  reportedOn: string;
}

export enum CommentReportStatus {
  SUBMITTED = 'Submitted',
  RESOLVED = 'Resolved',
  REJECTED = 'Rejected',
  HIDDEN = 'Hidden',
}

export interface RespondReportedWorkflowComment {
  reportedWorkflowCommentId: string;
  resolutionDescription: string;
  status: CommentReportStatus;
}
