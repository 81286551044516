import { Selector, createPropertySelectors } from '@ngxs/store';
import { ProcessState, ProcessStateModel } from './process.state';

export class ProcessSelector {
  static slices = createPropertySelectors<ProcessStateModel>(ProcessState);

  @Selector([ProcessState])
  static processes(stateModel: ProcessStateModel) {
    return stateModel.processes;
  }

  @Selector([ProcessState])
  static totalCount(stateModel: ProcessStateModel) {
    return stateModel.totalCount;
  }

  @Selector([ProcessState])
  static update(stateModel: ProcessStateModel) {
    return stateModel.update;
  }

  @Selector([ProcessState])
  static selectedProcess(stateModel: ProcessStateModel) {
    return stateModel.selectedProcess;
  }

  @Selector([ProcessState])
  static paginatedProcesses(stateModel: ProcessStateModel) {
    return stateModel.paginatedProcesses;
  }

  @Selector([ProcessState])
  static processCategories(stateModel: ProcessStateModel) {
    return stateModel.processCategories;
  }

  @Selector([ProcessState])
  static processListPageIndex(stateModel: ProcessStateModel) {
    return stateModel.processListPageIndex;
  }

  @Selector([ProcessState])
  static processListPageSize(stateModel: ProcessStateModel) {
    return stateModel.processListPageSize;
  }

  @Selector([ProcessState])
  static processListSearchTerm(stateModel: ProcessStateModel) {
    return stateModel.processListSearchTerm;
  }
}
