<form class="forget-password-form" [formGroup]="forgetPasswordForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n="@@users.forgot-password.forget-password-form">Forget Password Form</mat-card-title>
    </mat-card-header>
    <mat-card-content class="forget-password-fields">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@users.forgot-password.email">Email</mat-label>
        <input id="email" formControlName="email" matInput />
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button
        class="submit"
        mat-flat-button
        color="primary"
        (click)="forgetPassword()"
       i18n="@@users.forgot-password.submit">
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</form>
