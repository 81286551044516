<h1 mat-dialog-title>
  <ng-container *ngIf="!!data.selectedValidation">
    <span  *appHasPermission="hasUpdateWorflowStepValidationPermission()">
      Update Workflow Step Validation
    </span>
  </ng-container>
  <ng-container *ngIf="!data.selectedValidation">
    <span
    i18n="@@documents.workflow-step-validation.create-workflow-step-validation"
    *appHasPermission="hasCreateWorflowStepValidationPermission()"
  >
    Create Workflow Step Validation
  </span>
  </ng-container>
</h1>
<mat-dialog-content style="max-height: calc(100vh - 112px)">
  <form
    (ngSubmit)="submit()"
    [formGroup]="workflowStepValidationForm"
    class="col"
  >
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <span class="mat-subtitle-2"> Left field </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group formControlName="leftField">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let field of (documentForm$ | async)?.templateFields"
            >
              <mat-radio-button
                color="primary"
                [value]="field"
                [checked]="
                  workflowStepValidationForm.value.leftField?.id == field.id
                "
              >
                {{ field.name }}
                <mat-chip> {{ field.dataType }} </mat-chip>
              </mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <span class="mat-subtitle-2"> Right field </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group formControlName="rightField">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let field of (documentForm$ | async)?.templateFields"
            >
              <mat-radio-button
                color="primary"
                [value]="field"
                [checked]="
                  workflowStepValidationForm.value.rightField?.id == field.id
                "
              >
                {{ field.name }}
                <mat-chip> {{ field.dataType }} </mat-chip>
              </mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>
        <mat-error
          *ngIf="
            workflowStepValidationForm
              .get('rightField')
              ?.hasError('dataTypeMismatch')
          "
        >
          <p class="mat-body-2">
            The Data type of the left and right field must match.
          </p>
        </mat-error>
      </mat-card-content>
    </mat-card>

    <mat-form-field appearance="outline" class="operator">
      <mat-label
        i18n="@@documents.workflow-step-validation.select-validation-logic"
        >Select validation logic</mat-label
      >
      <mat-select formControlName="validationRule" [compareWith]="compareWith">
        <mat-option
          *ngFor="let operator of operators$ | async"
          [value]="operator"
        >
          {{ operator.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="full-width"
      formControlName="successOffice"
      appearance="outline"
      (click)="openSuccessOfficeTree($event)"
    >
      <mat-label i18n="@@documents.workflow-step-validation.success-office"
        >Success Office</mat-label
      >
      <mat-chip-grid #chipGrid1 aria-label="Enter fruits">
        <mat-chip-row
          (removed)="workflowStepValidationForm.value.successOffice = null"
          *ngIf="workflowStepValidationForm.value.successOffice"
        >
          {{ workflowStepValidationForm.value.successOffice.name || "" }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input
          [placeholder]="placeholderFieldLabel.officeField"
          [matChipInputFor]="chipGrid1"
        />
      </mat-chip-grid>
    </mat-form-field>

    <mat-form-field
      class="full-width"
      formControlName="failureOffice"
      appearance="outline"
      (click)="openFailureOfficeTree($event)"
    >
      <mat-label i18n="@@documents.workflow-step-validation.failure-office"
        >Failure Office</mat-label
      >
      <mat-chip-grid #chipGrid aria-label="Enter fruits">
        <mat-chip-row
          (removed)="workflowStepValidationForm.value.failureOffice = null"
          *ngIf="workflowStepValidationForm.value.failureOffice"
        >
          {{ workflowStepValidationForm.value.failureOffice.name || "" }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input
          [placeholder]="placeholderFieldLabel.officeField"
          [matChipInputFor]="chipGrid"
        />
      </mat-chip-grid>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    (click)="submit()"
    color="primary"
    i18n="@@documents.workflow-step-validation.save"
    [disabled]="!workflowStepValidationForm.valid"
  >
    Save
  </button>
</mat-dialog-actions>
