<mat-card class="margin-top" id="researchCommunicationsComponent">
  <mat-card-title class="margin-top-title"
  i18n="@@researches.research-communications.briefings"
    >Briefings
  </mat-card-title>
  <mat-card-content>
    <div class="">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="communicationTypeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by communicationTypeName"
            i18n="@@researches.research-communications.briefing-type"
          >
          Briefing type
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.communicationTypeName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="officeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by officeName"
            i18n="@@researches.research-communications.office"
          >
            Office
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.officeName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by name"
            i18n="@@researches.research-communications.name"
          >
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by startDate"
            i18n="@@researches.research-communications.start-date"
          >
            Start Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by endDate"
            i18n="@@researches.research-communications.end-date"
          >
            End Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="paginator">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="loadPaginatedCommunications($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
