<form [formGroup]="searchForm" class="search-form-container">
  <mat-form-field appearance="outline" class="process-selector">
    <mat-label i18n="@@researches.search-form.processes">Processes</mat-label>
    <mat-select
      formControlName="selectedProcesses"
      multiple
      (selectionChange)="getTasks()"
      [attr.data-test-id]="'researches.filter-task.search-form.mat-select-processes'"
    >
      <mat-option
        *ngFor="let processInstance of processInstances"
        [value]="processInstance.id"
        [attr.data-test-id]="'researches.filter-task.search-form.mat-option'"
        >{{ processInstance.title }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="search-term-input">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [placeholder]="placeholderToggleLabel.searchForm"
      formControlName="searchTerm"
      (keyup.enter)="getTasks()"
      (input)="onSearchTermChange()"
      [attr.data-test-id]="'researches.search-task.search-form.search-input'"
    />
  </mat-form-field>
</form>
