import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MemoListComponent } from './components/memo-list/memo-list.component';
import { MemoFormComponent } from './components/memo-form/memo-form.component';
import { MEMO_FORM_ROUTE } from 'src/app/core/constants/routes';

const routes: Routes = [
  {
    path: '',
    component: MemoListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemosRoutingModule {}
