import { Component, ViewChild } from '@angular/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { ASSIGNED_TASKS_ROUTE } from 'src/app/core/constants/routes';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { Task } from 'src/app/researches/models/task.model';

interface AssignedTasksComponentState {
  assignedTasks: PaginatedList<Task> | undefined;
}
const initAssignedTasksComponentState: AssignedTasksComponentState = {
  assignedTasks: undefined,
};

@Component({
  selector: 'app-assigned-tasks',
  templateUrl: './assigned-tasks.component.html',
  styleUrls: ['./assigned-tasks.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: AssignedTasksComponent }],
})
export class AssignedTasksComponent implements MatPaginatorIntl {
  assignedTasks$ = this.state.select('assignedTasks');
  assignedTasks: PaginatedList<Task> | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.assigned-tasks.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.assigned-tasks.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.assigned-tasks.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.assigned-tasks.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.assigned-tasks.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.assigned-tasks.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return (
      $localize`:@@researches.assigned-tasks.page-part-one: Page` +
      `${page + 1}` +
      $localize`:@@researches.assigned-tasks.page-part-two: of` +
      `${amountPages}`
    );
  }

  constructor(
    private state: RxState<AssignedTasksComponentState>,
    private taskFacade: TaskFacade,
    private router: Router,
  ) {
    this.state.set(initAssignedTasksComponentState);
    this.state.connect('assignedTasks', this.taskFacade.assingedTasks$);
  }
  changes = new Subject<void>();

  ngOnInit() {
    this.taskFacade.dispatchGetAssignedTasks(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.assignedTasks$.subscribe((tasks) => {
      if (tasks) {
        this.assignedTasks = tasks;
        this.pageIndex = tasks.pageNumber - 1;
      }
    });
  }
  loadPaginatedTasks(event: PageEvent) {
    this.taskFacade.dispatchGetAssignedTasks(
      event.pageIndex + 1,
      event.pageSize,
    );
  }
  onRowClick(event: MouseEvent, task: Task, processInstanceId: string): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    if (!isMenuButtonClick) {
      this.taskFacade.dispatchSetSelectedTask(task);
      this.router.navigate([`${ASSIGNED_TASKS_ROUTE}`, task.id]);
    }
  }

  hasGetAssignedTasksPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.GetCollaboratorsTasks,
    );
  }
}
