import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  CreateWorkflowStepValidation,
  CreateWorkflowValidation,
  DeleteWorkflowStepValidation,
  DeleteWorkflowValidation,
  GetWorkflowStepValidationRule,
  GetWorkflowStepValidations,
  GetWorkflowTemplateFields,
  GetWorkflowValidations,
  SelectWorkflowValidation,
  UpdateWorkflowStepValidation,
  UpdateWorkflowValidation,
} from './workflow-validations.actions';
import { tap } from 'rxjs';

import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { WorkflowValidationService } from '../services/workflow-validations.service';
import { WorkflowValidation } from '../models/workflow-validation.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { WorkflowStepValidationRule } from '../models/workflow-step-validation-rule.model';
import { DocumentForm } from '../../document-templates/models/document-forms.model';
import {
  UpdateWorkflowStepValidationDto,
  WorkflowStepValidation,
} from '../models/worlfow-step-validation.model';

export interface WorkflowValidationsStateModel {
  documentTemplates: DocumentForm[];
  workflowValidations: PaginatedList<WorkflowValidation>;
  selectedValidation?: WorkflowValidation;
  workflowValidationRules: WorkflowStepValidationRule[];
  workflowStepValidations: WorkflowStepValidation[];
}

const WORKFLOW_TYPES_TOKEN = new StateToken<WorkflowValidationsStateModel>(
  'workflowValidationsStateModel',
);

const defaults: WorkflowValidationsStateModel = {
  documentTemplates: [],
  workflowValidationRules: [],
  workflowStepValidations: [],
  workflowValidations: {
    items: [],
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowValidationsStateModel>({
  name: WORKFLOW_TYPES_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowValidationState {
  constructor(
    public operationStatus: OperationStatusService,
    private workflowValidationService: WorkflowValidationService,
    private store: Store,
  ) {}

  @Action(GetWorkflowTemplateFields)
  getWorkflowTypes(
    { patchState }: StateContext<WorkflowValidationsStateModel>,
    { workflowId }: GetWorkflowTemplateFields,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService.getFormFields(workflowId).pipe(
      tap((documentTemplates) => {
        patchState({ documentTemplates });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateWorkflowValidation)
  createWorkflowValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    {
      workflowTypeId,
      leftFormFieldId,
      rightFormFieldId,
      validationRuleId,
    }: CreateWorkflowValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .createWorkflowValidation({
        workflowTypeId,
        leftFormFieldId,
        rightFormFieldId,
        validationRuleId,
      })
      .pipe(
        tap((workflowValidation) => {
          setState(
            patch({
              workflowValidations: patch({
                items: insertItem(workflowValidation, 0),
              }),
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowValidations)
  getWorkflowValidations(
    { patchState }: StateContext<WorkflowValidationsStateModel>,
    { workflowTypeId, pageSize, pageNumber }: GetWorkflowValidations,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .getWorkflowValidations(workflowTypeId, pageSize, pageNumber)
      .pipe(
        tap((vals) => {
          patchState({ workflowValidations: vals });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteWorkflowValidation)
  DeleteWorkflowValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { id }: DeleteWorkflowValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService.deleteWorkflowValidation(id).pipe(
      tap(() => {
        setState(
          patch({
            workflowValidations: patch({
              items: removeItem(
                (workflowValidation) => workflowValidation.id == id,
              ),
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateWorkflowValidation)
  UpdateWorkflowValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    workflow: UpdateWorkflowValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .updateWorkflowValidation(workflow)
      .pipe(
        tap((updated) => {
          setState(
            patch({
              workflowValidations: patch({
                items: updateItem((wv) => wv.id == updated.id, updated),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-validations.updated-workflow-validation:Workflow validation updated successfully.`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateWorkflowStepValidation)
  createWorkflowStepValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { data }: CreateWorkflowStepValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .createWorkflowStepValidation(data)
      .pipe(
        tap((updated) => {
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-validations.created-workflow-validation:Workflow step validation created.`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowStepValidationRule)
  getWorkflowStepValidationRule(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { dataType }: GetWorkflowStepValidationRule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .getWorkflowStepValidationRule(dataType)
      .pipe(
        tap((val) => {
          setState(patch({ workflowValidationRules: val }));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectWorkflowValidation)
  SelectWorkflowValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { workflowValidation }: SelectWorkflowValidation,
  ) {
    setState(patch({ selectedValidation: workflowValidation }));
  }

  @Action(GetWorkflowStepValidations)
  getWorkflowStepValidations(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { workflowStepId }: GetWorkflowStepValidations,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .getWorkflowStepValidations(workflowStepId)
      .pipe(
        tap((val) => {
          setState(patch({ workflowStepValidations: val }));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateWorkflowStepValidation)
  updateWorkflowStepValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { data }: UpdateWorkflowStepValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService
      .updateWorkflowStepValidation(data)
      .pipe(
        tap((val) => {
          setState(
            patch({
              workflowStepValidations: updateItem(
                (wsv) => wsv.id == data.id,
                val,
              ),
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteWorkflowStepValidation)
  deleteWorkflowStepValidation(
    { setState }: StateContext<WorkflowValidationsStateModel>,
    { id }: DeleteWorkflowStepValidation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowValidationService.deleteWorkflowStepValidation(id).pipe(
      tap((val) => {
        setState(
          patch({
            workflowStepValidations: removeItem((wsv) => wsv.id == id),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
