<mat-sidenav-container class="container">
  <mat-sidenav-content class="externalDocumentdetail">
    <div class="flex-column">
      <div class="maticon">
        <button
          *ngIf="externalDocumentResponseDetail?.status !== responseStatus.Complete"
          color="primary"
          mat-flat-button
          (click)="openResponseForm()"
        >
          Edit Draft Response
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="openDeleteConfirmationDialog()"
          *appHasPermission="hasDeleteExternalDocumentResponsePermission()"
          i18n="@@documents.external-document-response-detail.delete-response">
          Delete Response
        </button>
      </div>
      <h1 class="mat-h1">
        <span i18n="@@documents.external-document-response-detail.to"> To: </span>
        {{ externalDocumentResponseDetail?.destinationOffice }}
      </h1>
      <h2 class="mat-h2">
        <span i18n="@@documents.external-document-response-detail.subject"> Subject: </span>
        {{ externalDocumentResponseDetail?.subject }}
      </h2>
      <p>
        <span i18n="@@documents.external-document-response-detail.composed-response" class="mat-h2">Composed Response:</span>
        <br>
        {{ parseAndConvertBodyToString(externalDocumentResponseDetail?.body) }}
      </p>
      <p class="mat-body">{{ getDateFromString(externalDocumentResponseDetail?.created) }}</p>
    </div>

    <div class="editor--wrapper">
      <div id="quill-container"></div>
    </div>

    <div *ngIf="showFilesSection()">
      <h2 i18n="@@documents.external-document-response-detail.files" class="mat-h2">Files</h2>
      <ng-container
        class="overflow height-m"
        *ngFor="
          let file of externalDocumentResponseDetail?.files;
          let i = index
        "
      >
        <div class="file-preview mat-body mat-elevation-z2">
          <mat-icon>insert_drive_file</mat-icon>
          <p>{{ file.name }}</p>
          <p>{{ getDateFromString(file.created) }}</p>
          <p>{{ file.fileSize }} <span i18n="@@documents.external-document-response-detail.kb"> KB </span></p>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="previewResponseAttachment(file)" i18n="@@documents.external-document-response-detail.preview">
            Preview
          </button>
          <button
            mat-menu-item
            (click)="downloadResponseAttachment(file)"
          i18n="@@documents.external-document-response-detail.download">
            Download
          </button>

        </mat-menu>
      </ng-container>
    </div>
    <div class="remarks">
      <h2 i18n="@@documents.external-document-response-detail.remarks" class="mat-h2">Remarks</h2>
      <button *ngIf="showActionButton()" mat-flat-button color="primary" [matMenuTriggerFor]="menu" i18n="@@documents.external-document-response-detail.actions">
        Actions
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <ul
        *ngFor="let status of remarkActionStatusForDisplay"

        mat-menu-item
        (click)="handleStatus(status.remarkActionStatusEnum)"
      >
        {{
          viewRemarkStatus(status.remarkActionStatusForDisplay)
        }}
      </ul>
    </mat-menu>
    <div>
      <ng-container *ngIf="externalDocumentResponseDetail">
        <div
          *ngIf="
            externalDocumentResponseDetail.remarkActions &&
            externalDocumentResponseDetail!.remarkActions.length > 0
          "
        >
          <mat-card
            class="remark-card"
            *ngFor="let remark of externalDocumentResponseDetail?.remarkActions"
          >
            <div>
              <mat-card-header class="card-header">
                <div class="flex">
                  <mat-card-title>
                    {{ remark.submittedByUser?.firstName }}
                    {{ remark.submittedByUser?.middleName }}
                  </mat-card-title>
                  <div class="chip">
                    <span>{{ remark.remarkStatus }}</span>
                  </div>
                </div>
                <div *ngIf="remark.remarkStatus === remarkStatusEnum.Received || remark.remarkStatus === remarkStatusEnum.Rejected; else twoOfficeRemark " class="flex mat-h4">
                  <span>{{remark.remarkStatus}} <span> By: </span> {{ remark.userOffice?.name }}</span>
                </div>
                <ng-template #twoOfficeRemark>
                  <div  class="flex mat-h4">
                    <span> <span i18n="@@documents.external-document-detail.from"> From:  </span> {{ remark.userOffice?.name }}</span>
                    <mat-icon>arrow_right_alt</mat-icon>
                    <span class=""><span i18n="@@documents.external-document-detail.to"> To:  </span> {{ remark.toOffice?.name }}</span>
                  </div>
                </ng-template>
                <div class="flex mat-body-strong">
                  <span i18n="@@documents.external-document-response-detail.date">Date:</span>
                  <span>{{ getDateFromString(remark.submissionDate) }}</span>
                </div>
              </mat-card-header>
              <mat-card-content>
                <p class="mat-body">
                  {{ remark.message }}
                </p>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
        <div
          *ngIf="externalDocumentResponseDetail?.remarkActions?.length === 0"
        i18n="@@documents.external-document-response-detail.no-remarks">
          No Remarks.
        </div>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
