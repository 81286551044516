import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StageFormComponent } from '../stage-form/stage-form.component';

@Component({
  selector: 'app-stage-list',
  templateUrl: './stage-list.component.html',
  styleUrls: ['./stage-list.component.scss'],
})
export class StageListComponent {
  constructor() {}
}
