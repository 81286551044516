import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import Quill from 'quill';
import { combineLatest } from 'rxjs';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import { StageInstanceDetail, StageInstanceStatus } from 'src/app/researches/models/stage-instance-detail.model';
import { TaskDocumnet, TaskStatus } from 'src/app/researches/models/task.model';
import { TaskDetail } from 'src/app/researches/models/taskDetail.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { pdfExporter } from "quill-to-pdf";


interface TaskComposeComponentState {
  processInstanceDetail: ProcessInstanceDetail | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
  taskDetail?: TaskDetail;
  stageInstance?: StageInstanceDetail;
}

const initialTaskComposeComponentState: TaskComposeComponentState = {
  processInstanceDetail: undefined,
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-task-compose',
  templateUrl: './task-compose.component.html',
  styleUrls: ['./task-compose.component.scss'],
})
export class TaskComposeComponent implements OnInit, OnDestroy, OnChanges {
  composeIsValid: boolean = false;
  initialEditorContent: any;
  stageInstance$ = this.taskComposeState.select('stageInstance');
  stageInstance?: StageInstanceDetail;

  @Input() taskDetail: any;
  @Input() taskId!: string | undefined;
  @Input() assigneeId!: string | undefined;
  TaskStatus = TaskStatus;

  handleTextChange = () => {
    this.composeIsValid = !!this.editor?.getText().trim();
  };

  processInstanceDetail$ = this.taskComposeState.select(
    'processInstanceDetail',
  );
  processInstanceDetail: ProcessInstanceDetail | undefined;

  currentLoggedInUser$ = this.taskComposeState.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  editor?: Quill;

  constructor(
    private taskFacade: TaskFacade,
    private taskComposeState: RxState<TaskComposeComponentState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
    private stageInstanceDetailFacade: StageInstanceDetailFacade
  ) {
    this.taskComposeState.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.taskComposeState.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.taskComposeState.connect('taskDetail', taskFacade.taskDetail$);
    this.taskComposeState.connect('stageInstance', stageInstanceDetailFacade.selectedStageInstanceDetail$)
  }

  ngOnInit(): void {
    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;

      if (this.editor) {
        this.editor.enable(!this.processInstanceDetail?.isArchived);
      }
    });

    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });

    this.editor = new Quill('#editor', {
      theme: 'snow',
      readOnly: true,
      modules:{
        toolbar:QuillEditorToolbarOptions
      }
    });

    combineLatest([
      this.taskComposeState.select('currentLoggedInUser'),
      this.taskComposeState.select('processInstanceDetail'),
      this.taskComposeState.select('taskDetail'),
    ]).subscribe(([user, processDetail, task]) => {
      if (
        processDetail?.isArchived ||
        task?.assignee?.id !== user?.id || 
        this.taskDetail.taskStatus.toString() === TaskStatus.Submitted ||
        this.taskDetail.taskStatus.toString() === TaskStatus.Done || 
        this.taskDetail.taskStatus.toString() === TaskStatus.Approved
      ){
        this.editor?.disable();
      }
      else {
        this.editor?.enable()
      };
    });
    this.editor.on('text-change', this.handleTextChange);
    if (!!this.taskDetail && this.taskDetail.taskDocument) {
      this.initialEditorContent = JSON.parse(
        this.taskDetail.taskDocument.documentContent,
      );
      this.editor.setContents(this.initialEditorContent, 'api');
    } else {
      this.initialEditorContent = { ops: [] }; // Reset initial content
      this.editor.setContents(this.initialEditorContent, 'api');
    }
    this.stageInstance$.subscribe(stage => {
      this.stageInstance = stage;
    })
  }

  ngOnDestroy(): void {
    this.editor?.off('text-change', this.handleTextChange);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['taskDetail'] && this.editor) {
      if (this.taskDetail.taskDocument) {
        this.editor.setContents(
          JSON.parse(this.taskDetail.taskDocument.documentContent),
          'api',
        );
      } else {
        this.editor.setText('');
        this.composeIsValid = false;
      }
    }

  }

  async exportPdf() {
    if (!this.editor) {
      return;
    }
    if(!this.taskDetail){
      return;
    }
    const delta = this.editor.getContents();
    const blob = await pdfExporter.generatePdf(delta);
      const fileUrl = URL.createObjectURL(blob as Blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = this.taskDetail.name + '.pdf';
          link.click();
  }

  save() {
    if (this.editor && this.composeIsValid) {
      const document = {
        taskId: this.taskId!,
        documentContent: this.editor.getContents(),
      };

      if (!this.taskDetail.taskDocument && this.composeIsValid) {
        this.taskFacade.dispatchCreateTaskDocument(document);
      } else {
        this.taskFacade.dispatchUpdateTaskDocument(document);
      }

      this.initialEditorContent = document.documentContent;
    }
    if(this.stageInstance?.stageStatus === StageInstanceStatus.ReadyToStart){
      this.stageInstanceDetailFacade.dispatchChangeStageStatus({
        id: this.stageInstance.id,
        stageStatus: StageInstanceStatus.Inprogress,
      });
    }
  }

  reset() {
    if (this.editor && this.initialEditorContent) {
      this.editor.setContents(this.initialEditorContent, 'api');
      this.composeIsValid = false;
    }
  }
}
