<div class="container">
  <h2
    mat-dialog-title
    i18n="
      @@researches.choose-publication-option-form.choose-publication-options"
  >
    Choose Publication Options
  </h2>
  <form>
    <mat-form-field class="title">
      <input
        matInput
        [placeholder]="placeholderToggleLabel.titleOption"
        [(ngModel)]="title"
        name="title"
        required
        [disabled]="publicationOptions.length === 0"
        [attr.data-test-id]="
          'researchers.submit-publication.choose-publication-option-form.title-input'
        "
      />
    </mat-form-field>
    <section class="no-publication" *ngIf="publicationOptions.length === 0">
      No publication options are found.
    </section>
    <section class="ma" *ngFor="let option of publicationOptions">
      <p *ngIf="!option.isBanned">
        <mat-checkbox
          on
          (change)="onChange($event, option)"
          [attr.data-test-id]="
            'researchers.submit-publication.choose-publication-option-form.publication-options-checkbox'
          "
        >
          {{
            option.title.length > 40
              ? option.title.slice(0, 40) + " ..."
              : option.title
          }}
        </mat-checkbox>
      </p>
    </section>

    <div mat-dialog-actions>
      <button
        mat-flat-button
        (click)="onSave()"
        color="accent"
        [disabled]="selectedOptions.size <= 0 || !title"
        i18n="@@researches.choose-publication-option-form.submit"
        [attr.data-test-id]="
          'researchers.submit-publication.choose-publication-option-form.submit-button'
        "
      >
        Submit
      </button>
      <button
        mat-flat-button
        mat-dialog-close
        color="primary"
        (click)="onCancel()"
        i18n="@@researches.choose-publication-option-form.cancel"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
