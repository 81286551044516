import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-dialog-with-one-option',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  templateUrl: './confirm-dialog-with-one-option.component.html',
  styleUrl: './confirm-dialog-with-one-option.component.scss'
})
export class ConfirmDialogWithOneOptionComponent {
  regularTextOne: string | undefined;
  regularTextTwo: string | undefined;
  boldText: string | undefined;
  title: string | 'Confirm Your Action';
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogWithOneOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      title: string,
       regularTextOne: string,
       regularTextTwo: string, 
       boldText: string },
  ) {
    this.regularTextOne = this.data.regularTextOne;
    this.regularTextTwo = this.data.regularTextTwo;
    this.boldText = this.data.boldText;
    this.title = this.data.title;
    this.dialogRef.disableClose = true;
  }

  onConfirm() {
    this.dialogRef.close();
  }
}
