import { PaginatedList } from '../../../../core/models/paginated-list.interface';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DocumentForm } from '../../models/document-forms.model';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { DocumentFormFormComponent } from '../document-form-form/document-form-form.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import {
  DOCUMENT_TEMPLATE_DETAIL_ROUTE,
  DOCUMENT_TEMPLATE_LAYOUT_ROUTE,
} from 'src/app/core/constants/routes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CreateDocumentFormComponent } from '../create-document-form/create-document-form.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { debounceTime } from 'rxjs';

interface DocumentFormListComponentState {
  documentForms: PaginatedList<DocumentForm>;
  selectedDocumentForm: DocumentForm | undefined;
  length: number;
  isSearchingForm?: boolean;
  selectedFormType:string;
}

const initDocumentFormListComponentState: DocumentFormListComponentState =
  {
    documentForms: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    selectedDocumentForm: undefined,
    length: 0,
    selectedFormType: 'All',
  };

@Component({
  selector: 'app-document-form-list',
  templateUrl: './document-form-list.component.html',
  styleUrls: ['./document-form-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: DocumentFormListComponent}],
})
export class DocumentFormListComponent implements OnInit, AfterViewInit {
  dataSource!: MatTableDataSource<DocumentForm>;
  documentForms$ = this.state.select('documentForms');

  length$ = this.state.select('length');
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 1;

  documentForm: PaginatedList<DocumentForm>  = initDocumentFormListComponentState.documentForms;

  selectedDocumentForm: DocumentForm | undefined = undefined;
  selectedDocumentForm$ = this.state.select('selectedDocumentForm');
  templateTypesForDisplay = [$localize`:@@documents.document-form-list.all:All`,$localize`:@@documents.document-form-list.draft:Draft`, $localize`:@@documents.document-form-list.submitted:Submitted`, $localize`:@@documents.document-form-list.approved:Approved`,$localize`:@@documents.document-form-list.chage-requested:ChangeRequested`, $localize`:@@documents.document-form-list.rejected:Rejected`] as const;
  templateTypes = ['All','Draft', 'Submitted', 'Approved','ChangeRequested', 'Rejected'];
  selectedFormType$ = this.state.select('selectedFormType');
  selectedFormType = 'All';
  templateTypeControl = new FormControl('All');
  templateSearchForm: FormGroup;
  isDraftForm = false;

  placeholderFieldLabel = {
    selectFormType: $localize`:@@documents.document-form-list.approved: Approved`,
    searchForms: $localize`:@@documents.document-form-list.search-forms: Search Forms`,
}

firstPageLabel = $localize`:@@documents.document-form-list.first-page: First page`;
itemsPerPageLabel = $localize`:@@documents.document-form-list.items-per-page: Items per page:`;
lastPageLabel = $localize`:@@documents.document-form-list.last-page: Last page`;

nextPageLabel = $localize`:@@documents.document-form-list.next-page:  Next page`;
previousPageLabel = $localize`:@@documents.document-form-list.previous-page:  Previous page`;

getRangeLabel(page: number, pageSize: number, length: number): string {
  if (length === 0) {
    return $localize`:@@documents.document-form-list.page-1-of-1: Page 1 of 1`;
  }
  const amountPages = Math.ceil(length / pageSize);
  return $localize`:@@documents.document-form-list.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.document-form-list.page-part-two: of` + `${amountPages}`;
}

  constructor(
    private documentFormFacade: DocumentFormFacade,
    private router: Router,
    private state: RxState<DocumentFormListComponentState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
  ) {
    this.state.set(initDocumentFormListComponentState);
    this.state.connect(
      'documentForms',
      this.documentFormFacade.templates$,
    );

    this.state.connect('length', this.documentFormFacade.totalCount$);
    this.state.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );
    this.templateSearchForm = this.fb.group({
      templateName: [''],
    });
    this.state.connect(
      'isSearchingForm',
      this.documentFormFacade.isSearchingForm$,
    );
    this.state.connect(
      'selectedFormType',
      this.documentFormFacade.selectedFormType$,
    );
  }
  changes = new RxState();
  ngAfterViewInit(): void {
    this.templateTypeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.selectedFormType = value as FormStatusOption || 'Approved';
        this.documentFormFacade.dispatchSetSelectedFormType(
          this.selectedFormType,
        );
      });
  }

  ngOnInit(): void {
    this.documentFormFacade.dispatchSetSelectedFormType(FormStatusOption.All)
    this.selectedFormType$.subscribe((selectedFormType) => {
      this.selectedFormType = selectedFormType;
      this.templateTypeControl.setValue(selectedFormType);
      this.pageIndex = 1;
      this.pageSize = 10;
      this.loadPaginatedForm();
    });

    this.documentForms$.subscribe((documentForms) => {
      if (documentForms) {
        this.documentForm = documentForms;
        this.dataSource = new MatTableDataSource<DocumentForm>(
          documentForms.items,
        );
      }
    });
    this.length$.subscribe((length) => {
      this.length = length;
    });
    this.selectedDocumentForm$.subscribe((selectedDocumentForm) => {
      this.selectedDocumentForm = selectedDocumentForm;
    });
  }

  addForm() {
    this.documentFormFacade.dispatchSetTempalteCreatingMode(true);
    this.dialog.open(CreateDocumentFormComponent,{disableClose : true} );
  }
  updateForm() {}

  loadPaginatedFormEvent(event: PageEvent) {

    const { pageSize, pageIndex } = event;
    this.pageIndex = pageIndex + 1
    this.pageSize = pageSize;
    this.loadPaginatedForm();
  }


  loadPaginatedForm() {
    const statusIndex = this.templateTypesForDisplay.findIndex(st => st.toLowerCase() === this.selectedFormType.toLowerCase())
    const templateType = this.templateTypes[statusIndex];
    const searchValue = this.templateSearchForm.get('templateName')?.value;
    if(searchValue !== '') {
      this.documentFormFacade.dispatchSearchDocumentForms(
        templateType as FormStatusOption || 'Approved',
        searchValue,
        this.pageIndex,
        this.pageSize,
      );
      return;
    }
    if (templateType === FormStatusOption.All){
      this.documentFormFacade.dispatchGetDocumentForms(this.pageIndex,this.pageSize)
    }
    else{
      this.documentFormFacade.dispatchGetDocumentFormsByStatus(
        templateType as FormStatusOption || 'Approved',
        this.pageIndex,
        this.pageSize,
      );
    }
  }

  setSelectedForm(event: MouseEvent, template: DocumentForm) {
    event.stopPropagation();
    this.documentFormFacade.dispatchSetSelectedDocumentForm(template);
    this.documentFormFacade.dispatchGetDocumentForm(
      template.id as string,
    );
  }
  onRowClick(event: any, template: DocumentForm) {
    this.documentFormFacade.dispatchSetSelectedDocumentForm(template);
    if (template.id) {
      this.documentFormFacade.dispatchGetDocumentForm(
        template.id as string,
      );
      this.router.navigate([DOCUMENT_TEMPLATE_DETAIL_ROUTE]);
    }
  }

  editForm() {
    this.documentFormFacade.dispatchSetUpdateStatus(true);
    this.dialog
      .open(CreateDocumentFormComponent)
      .afterClosed()
      .subscribe((_) => {
        this.documentFormFacade.dispatchSetUpdateStatus(false);
      });
  }

  openConfirmationDialog() {
    if (!this.selectedDocumentForm) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne:  $localize`:@@documents.document-form-list.remove-document-form-part-1:Are you sure you want to delete the` ,
        boldText: ` "${this.selectedDocumentForm!.name}" ` ,
        regularTextTwo:  $localize`:@@documents.document-form-list.remove-document-form-part-2:document form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedDocumentForm)
          if (
            this.selectedDocumentForm.status.status ===
            FormStatusOption.DRAFT
          ) {
            this.documentFormFacade.dispatchDeleteDraftDocumentForm(
              this.selectedDocumentForm!.id!,
            );
          } else {
            this.documentFormFacade.dispatchDeleteDocumentForm(
              this.selectedDocumentForm!.id!,
            );
          }
      }
    });
  }

  onFormTypeChange(templateType: any) {
    this.selectedFormType = templateType;
  }

  onSearchTermChange() {
    this.templateSearchForm.get('templateName')?.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => this.loadPaginatedForm());
  }

  layout() {
    this.documentFormFacade.dispatchGetDocumentFormDetail(
      this.selectedDocumentForm!.id!,
    );
    this.router.navigate([DOCUMENT_TEMPLATE_LAYOUT_ROUTE]);
  }

  hasCreateDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.CreateDocumentForm,
    );
  }
  hasSearchDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.SearchDocumentFormsByName,
    );
  }

  hasSubmitDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.SubmitDocumentForm,
    );
  }
  hasUpdateDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.UpdateDocumentForms,
    );
  }
  hasDeleteDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.DeleteDocumentForm,
    );
  }

  hasOrderFormFieldPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.FormField.Feature,
      PERMISSION_NAMES.Documents.FormField.OrderFormField,
    );
  }
  hasCrudPermission(){
    return [
      this.hasSubmitDocumentFormPermission(),
      this.hasUpdateDocumentFormPermission(),
      this.hasDeleteDocumentFormPermission()
    ]
  }
}
