import { Injectable } from '@angular/core';
import {
  CreateCriterionDto,
  CriteriaSum,
  Criterion,
  UpdateCriterionDto,
} from '../models/criterion.model';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { CRITERION_URL } from 'src/app/core/constants/api-endpoints';
import { UpdateCriterion } from '../store/stage.actions';

@Injectable({
  providedIn: 'root',
})
export class CriterionService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  addCriteria(criterion: CreateCriterionDto): Observable<Criterion> {
    const options = new HttpParams({
      fromObject: { stageId: criterion.stageId },
    });
    return this.http.post<Criterion>(
      `${CRITERION_URL}?stageId=${criterion.stageId}`,
      {
        Name: criterion.name,
        Weight: criterion.weight,
      },
      this.httpOptions,
    );
  }

  updateCriterion(
    criterion: UpdateCriterionDto,
  ): Observable<UpdateCriterionDto> {
    return this.http.put<UpdateCriterionDto>(
      `${CRITERION_URL}`,
      criterion,
      this.httpOptions,
    );
  }
  deleteCriterion(id: string) {
    return this.http.delete<any>(`${CRITERION_URL}/${id}`);
  }
  removeCriteria(criterion: Criterion) {
    return this.http.delete<Criterion>(`${CRITERION_URL}/${criterion.id}`);
  }
}
