import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Workflow, WorkflowWithStep } from '../../models/workflow.model';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { WorkflowStep } from 'src/app/documents/workflow-steps/models/workflow-step.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MY_WORKFLOWS_ROUTE,
  WORKFLOW_STEPS_ROUTE,
} from 'src/app/core/constants/routes';
import { Subject } from 'rxjs';

interface MyWorkflowsComponentState {
  myWorkFlows: PaginatedList<WorkflowWithStep> | undefined;
  length: number;
}
const initMyWorkflowsComponentState: MyWorkflowsComponentState = {
  myWorkFlows: undefined,
  length: 0,
};

@Component({
  selector: 'app-my-workflows',
  templateUrl: './my-workflows.component.html',
  styleUrls: ['./my-workflows.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: MyWorkflowsComponent}],
})
export class MyWorkflowsComponent implements MatPaginatorIntl {
  myWorkFlows$ = this.state.select('myWorkFlows');
  myWorkFlows: PaginatedList<WorkflowWithStep> | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;
  length$ = this.state.select('length');
  length: number = 0;

  firstPageLabel = $localize`:@@documents.my-workflows.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.my-workflows.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.my-workflows.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.my-workflows.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.my-workflows.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.my-workflows.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.my-workflows.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.my-workflows.page-part-two: of` + `${amountPages}`;
    }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    private state: RxState<MyWorkflowsComponentState>,
    private workFLowFacade: WorkflowFacade,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.state.set(initMyWorkflowsComponentState);
    this.state.connect('myWorkFlows', this.workFLowFacade.myworkflows$);
    this.state.connect('length', this.workFLowFacade.totalCount$);
  }
  changes = new Subject<void>();
  ngOnInit() {
    this.workFLowFacade.dispatchGetMyWorkflows(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.length$.subscribe((length) => {
      this.length = length;
    });

    this.myWorkFlows$.subscribe((myWorkFlows) => {
      if (myWorkFlows) {
        this.myWorkFlows = myWorkFlows;
        this.pageIndex = myWorkFlows.pageNumber - 1;
      }
    });
  }

  stepDetail(step: WorkflowStep) {
    this.router.navigate([`./step`, step.id], { relativeTo: this.route });
  }

  loadPaginatedWorkflows(event: PageEvent) {
    this.workFLowFacade.dispatchGetMyWorkflows(
      event.pageIndex + 1,
      event.pageSize,
    );
  }
}
