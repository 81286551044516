import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FILES_URL, FOLDER_FILES } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ImisFile } from '../models/imis-file';
import {
  GetSharedFromFiles,
  GetSharedToFiles,
  GetSortedFiles,
} from '../store/file.actions';
import { ShareFileRequest } from '../models/share-file-request';
import { ImisFileUploadReport } from '../models/file-upload-report.model';
import { FileProperty } from '../models/file-property.model';
import { MoveArchivedFileDto, UnsharedFile } from '../models/file.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getFiles({
    folderId,
    orderBy,
    pageNumber,
    pageSize,
  }: GetSortedFiles): Observable<PaginatedList<ImisFile>> {
    const options = new HttpParams({
      fromObject: { orderBy, pageSize, pageNumber },
    });

    return this.http.get<PaginatedList<any>>(`${FOLDER_FILES}/${folderId}`, {
      params: options,
    });
  }

  moveFiles(
    destinationFolderId: string,
    selectedFilesIds: string[],
  ): Observable<any> {
    return this.http.put<any>(
      `${FILES_URL}/move`,
      {
        DestinationFolderId: destinationFolderId,
        ImisFileIds: selectedFilesIds,
      },
      this.httpOptions,
    );
  }

  getFilesBySearchTerm(searchTerm: string): Observable<ImisFile[]> {
    return this.http.get<ImisFile[]>(
      `${FILES_URL}/search?searchTerm=${searchTerm}`,
    );
  }

  copyFile({
    fileId,
    folderId,
  }: {
    fileId: string;
    folderId: string;
  }): Observable<any> {
    return this.http.put<any>(`${FILES_URL}/copy`, { fileId, folderId });
  }

  renameFile(Id: string, Name: string): Observable<any> {
    return this.http.put<any>(
      `${FILES_URL}/rename`,
      { Id, Name },
      this.httpOptions,
    );
  }

  deleteFiles(fileIds: string[] | string): Observable<any> {
    if (typeof fileIds === 'string') {
      return this.http.delete<any>(`${FILES_URL}/${fileIds}`, this.httpOptions);
    }
    return this.http.put<any>(`${FILES_URL}/delete`, fileIds, this.httpOptions);
  }

  shareFileToUser(
    shareFileToUsersRequests: ShareFileRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FILES_URL}/share-to-users`,
      shareFileToUsersRequests,
      this.httpOptions,
    );
  }

  shareFileToOffice(
    shareFileToOfficesRequests: ShareFileRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FILES_URL}/share-to-office`,
      shareFileToOfficesRequests,
      this.httpOptions,
    );
  }

  shareFileToRole(
    shareFileToRolesRequests: ShareFileRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FILES_URL}/share-to-roles`,
      shareFileToRolesRequests,
      this.httpOptions,
    );
  }

  unshareFile(unsharedFile: UnsharedFile): Observable<any> {
    return this.http.put<any>(
      `${FILES_URL}/unshare-file`,
      unsharedFile,
      this.httpOptions,
    );
  }

  getSharedFromFiles({
    pageNumber,
    pageSize,
  }: GetSharedFromFiles): Observable<PaginatedList<ImisFile>> {
    return this.http.get<PaginatedList<any>>(`${FILES_URL}/shared-from`);
  }

  getSharedToFiles({
    pageNumber,
    pageSize,
  }: GetSharedToFiles): Observable<PaginatedList<ImisFile>> {
    return this.http.get<PaginatedList<any>>(`${FILES_URL}/shared-to`);
  }

  downloadFile(fileId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(`${FILES_URL}/download/${fileId}`, options);
  }

  addFile(files: FormData, folderId: string) {
    return this.http.post<any>(`${FILES_URL}/${folderId}`, files);
  }

  previewFile(fileId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(`${FILES_URL}/preview/${fileId}`, options);
  }

  getAudioFile(fileId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(`${FILES_URL}/audio/${fileId}`, options);
  }

  trashFiles(fileIds: string[]): Observable<any> {
    return this.http.put<any>(
      `${FILES_URL}/trash?fileIds=${fileIds}`,
      this.httpOptions,
    );
  }

  getTrashedFiles(pageNumber: number, pageSize: number): Observable<any> {
    const options = new HttpParams({
      fromObject: { pageNumber, pageSize },
    });
    return this.http.get<any>(`${FILES_URL}/trash`, {
      params: options,
    });
  }

  restoreFile(fileId: string): Observable<any> {
    return this.http.put<any>(
      `${FILES_URL}/restore?fileId=${fileId}`,
      this.httpOptions,
    );
  }

  getFileProperty(fileId: string): Observable<FileProperty> {
    return this.http.get<FileProperty>(`${FILES_URL}/${fileId}`);
  }

  moveArchivedFiles(filesToMove: MoveArchivedFileDto[]) {
    return this.http.post<any>(
      `${FILES_URL}/move-from-archived-workspace`,
      filesToMove,
      this.httpOptions,
    );
  }
  getFileDetial(fileId: string): Observable<ImisFile> {
    return this.http.get<ImisFile>(`${FILES_URL}/detial/${fileId}`);
  } 
}
