import { Select, Store } from '@ngxs/store';
import { ProcessSelector } from '../store/process.selector';
import { Observable } from 'rxjs';
import { Process } from '../models/process.model';
import { Injectable } from '@angular/core';
import {
  CreateProcess,
  DeleteProcess,
  GetPaginatedProcesses,
  GetProcessCategories,
  SearchProcess,
  SetSelectedProcess,
  SetUpdateStatus,
  UpdateProcess,
  SetProcessListPageIndex,
  SetProcessListPageSize,
  SetProcessListSearchTerm,
} from '../store/process.actions';

import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class ProcessFacade {
  @Select(ProcessSelector.update)
  update$!: Observable<boolean>;

  @Select(ProcessSelector.selectedProcess)
  selectedProcess$!: Observable<Process>;

  @Select(ProcessSelector.processes)
  processes$!: Observable<Process[]>;

  @Select(ProcessSelector.paginatedProcesses)
  pageniatedProcesses$!: Observable<PaginatedList<Process>>;

  @Select(ProcessSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(ProcessSelector.processCategories)
  processCategories$!: Observable<any>;

  @Select(ProcessSelector.processListPageIndex)
  processListPageIndex$!: Observable<number>;

  @Select(ProcessSelector.processListPageSize)
  processListPageSize$!: Observable<number>;

  @Select(ProcessSelector.processListSearchTerm)
  processListSearchTerm$!: Observable<string>;

  constructor(private store: Store) {}

  dispatchUpdateProcess(process: Process) {
    this.store.dispatch(new UpdateProcess(process));
  }

  dispatchSetUpdateStatus(updateStatus: boolean) {
    this.store.dispatch(new SetUpdateStatus(updateStatus));
  }

  dispatchCreateProcess(process: Process) {
    this.store.dispatch(new CreateProcess(process));
  }
  dispatchGetPaginatedProcesses(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetPaginatedProcesses(pageNumber, pageSize));
  }
  dispatchSetSelectedProcess(process: Process) {
    this.store.dispatch(new SetSelectedProcess(process));
  }
  dispatchDeleteProcess(process: string) {
    this.store.dispatch(new DeleteProcess(process));
  }

  dispatchSearchProcess(
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(new SearchProcess(searchTerm, pageNumber, pageSize));
  }
  
  dispatchGetProcessCategories() {
    this.store.dispatch(new GetProcessCategories());
  }

  dispatchSetProcessListPageIndex(pageIndex: number) {
    this.store.dispatch(new SetProcessListPageIndex(pageIndex));
  }

  dispatchSetProcessListPageSize(pageSize: number) {
    this.store.dispatch(new SetProcessListPageSize(pageSize));
  }

  dispatchSetProcessListSearchTerm(searchTerm: string) {
    this.store.dispatch(new SetProcessListSearchTerm(searchTerm));
  }
}
