import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { OfficeFacade } from '../../facades/office.facades';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { ArrayDataSource } from '@angular/cdk/collections';
import { MatRadioChange } from '@angular/material/radio';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface OfficesListComponentState {
  flatOfficeNodes: FlatOfficeNode[];
}

const initOfficesListComponentState: Partial<OfficesListComponentState> = {
  flatOfficeNodes: [],
};

@Component({
  selector: 'app-office-selector',
  templateUrl: './office-selector.component.html',
  styleUrls: ['./office-selector.component.scss'],
  providers: [RxState],
})
export class OfficeSelectorcomponent implements OnInit {
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.state.select('flatOfficeNodes');

  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  parentOffice: FlatOfficeNode | undefined;

  officeNodes: Array<FlatOfficeNode> = [];

  dataSource = new ArrayDataSource(this.officeNodes);

  constructor(
    private officeFacade: OfficeFacade,
    private state: RxState<OfficesListComponentState>,
    @Inject(MAT_DIALOG_DATA)
    private data: { selectedOffice?: FlatOfficeNode },
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
    this.state.set(initOfficesListComponentState);
    this.state.connect('flatOfficeNodes', officeFacade.flatOfficeNodes$);

    this.selectedFlatOfficeNode = this.data.selectedOffice;
  }

  ngOnInit(): void {
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.flatOfficeNodes$.subscribe((flatOfficeNodes) => {
      this.officeNodes = flatOfficeNodes;
      this.dataSource = new ArrayDataSource(this.officeNodes);
    });
  }

  treeControl = new FlatTreeControl<FlatOfficeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatOfficeNode) => node.expandable;

  getParentNode(node: FlatOfficeNode) {
    const nodeIndex = this.officeNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.officeNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.officeNodes[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatOfficeNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  selectFlatOfficeNode(event: MatRadioChange, flatOfficeNode: FlatOfficeNode) {
    this.officeFacade.dispatchSelectFlatOfficeNode(flatOfficeNode);
    this.selectedFlatOfficeNode = flatOfficeNode;
  }

  cancel() {
    this.dialogRef.close();
  }

  done() {
    this.dialogRef.close(this.selectedFlatOfficeNode);
  }
  
  hasGetFlatOfficeNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetFlatOfficeNodes,
    );
  }
}
