import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { Injectable } from '@angular/core';
import { MemoService } from '../services/memo.service';
import { OperationStatusService } from './../../../core/services/operation-status/operation-status.service';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  CcUserWithOfficeDto,
  Memo,
  MemoAccessDetail,
  MemoFilter,
} from '../models/memo.model';
import {
  AddCcUserToMemo,
  CreateMemo,
  DeleteMemo,
  DeleteMemoAttachment,
  DownloadAttachment,
  GetDraftMemo,
  GetMemoAccessDetail,
  GetMemoById,
  GetMemoFilters,
  GetMemosByDate,
  GetMemosCcedToMe,
  GetMemosReceived,
  GetMemosSent,
  PreviewAttachment,
  RemoveCcUserToMemo,
  SearchMemos,
  GetParentMemo,
  SelectMemo,
  SetMemoPageNumberAndSize,
  SetMemoSearchingMode,
  UpdateMemo,
  UpdateMemoStatus,
  UpdateSelectedMemo,
} from './memo.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';

export interface MemoStateModel {
  memosSent: PaginatedList<Memo>;
  memosReceived: PaginatedList<Memo>;
  memosCcedToMe: PaginatedList<Memo>;
  selectedMemo: Memo | null;
  selectedMemoAccessDetail: MemoAccessDetail | null;
  pageNumber: number;
  pageSize: number;
  isSearchingMemo: boolean;
  draftMemo: PaginatedList<Memo>;
  memoFilters: MemoFilter[];
  parentMemo: Memo | null;
}

const MEMO_STATE_TOKEN = new StateToken<MemoStateModel>('memoStateModel');

const defaults: MemoStateModel = {
  draftMemo: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosSent: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosReceived: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  memosCcedToMe: {
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  },
  selectedMemo: null,
  parentMemo: null,
  selectedMemoAccessDetail: null,
  pageNumber: 1,
  pageSize: 10,
  isSearchingMemo: false,
  memoFilters: [],
};

@State<MemoStateModel>({
  name: MEMO_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class MemoState {
  constructor(
    private operationStatus: OperationStatusService,
    private memoService: MemoService,
    private store: Store,
  ) {}

  @Action(GetMemosSent)
  getMemosSent(
    { patchState }: StateContext<MemoStateModel>,
    { pageNumber, pageSize }: GetMemosSent,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getMemosSent(pageNumber, pageSize).pipe(
      tap((memos) => {
        patchState({
          memosSent: memos,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetParentMemo)
  getParentMemo(
    { patchState }: StateContext<MemoStateModel>,
    { parentId }: GetParentMemo,
  ) {
    return this.memoService.getMemo(parentId).pipe(
      tap((parentMemo) => {
        patchState({
          parentMemo: parentMemo,
        });
      }),
    );
  }

  @Action(GetMemosCcedToMe)
  getMemosCcedToMe(
    { patchState }: StateContext<MemoStateModel>,
    { pageNumber, pageSize }: GetMemosCcedToMe,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getMemosCcedToMe(pageNumber, pageSize).pipe(
      tap((memos) => {
        patchState({
          memosCcedToMe: memos,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetDraftMemo)
  getDraftMemo(
    { patchState }: StateContext<MemoStateModel>,
    { pageNumber, pageSize }: GetDraftMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getDraftMemo(pageNumber, pageSize).pipe(
      tap((memos) => {
        patchState({
          draftMemo: memos,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetMemosReceived)
  getMemosReceived(
    { patchState }: StateContext<MemoStateModel>,
    { pageNumber, pageSize }: GetMemosReceived,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getMemosReceived(pageNumber, pageSize).pipe(
      tap((memos) => {
        patchState({
          memosReceived: memos,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateMemo)
  createMemo(
    { patchState, getState }: StateContext<MemoStateModel>,
    { memo }: CreateMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.createMemo(memo).pipe(
      tap((createdMemo) => {
        let oldMemos = getState().memosSent.items;
        let newMemos = [createdMemo, ...oldMemos];
        let paginatedMemos = getState().memosSent;
        paginatedMemos.items = newMemos;
        paginatedMemos.totalCount += 1;
        patchState({
          memosSent: paginatedMemos,
        });
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.created-memo:Memo created Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AddCcUserToMemo)
  addCcUsersToMemo(
    { getState, setState }: StateContext<MemoStateModel>,
    { userId, memoId }: AddCcUserToMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.addCcUserToMemo(userId, memoId).pipe(
      tap((ccedUser) => {
        setState(
          patch({
            memosSent: patch({
              items: updateItem(
                (item) => item.id === memoId,
                patch({
                  ccedUsers: insertItem(ccedUser),
                }),
              ),
            }),
            memosReceived: patch({
              items: updateItem(
                (item) => item.id === memoId,
                patch({
                  ccedUsers: insertItem(ccedUser),
                }),
              ),
            }),
          }),
        );
        this.store.dispatch(new UpdateSelectedMemo(memoId));
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.added-memo:user added Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(RemoveCcUserToMemo)
  removeCcUserToMemo(
    { getState, setState }: StateContext<MemoStateModel>,
    { userId, memoId }: RemoveCcUserToMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.removeCcUserToMemo(userId, memoId).pipe(
      tap(() => {
        setState(
          patch({
            memosSent: patch({
              items: updateItem(
                (item) => item.id === memoId,
                patch({
                  ccedUsers: removeItem((item) => item.user.id === userId),
                }),
              ),
            }),
            memosReceived: patch({
              items: updateItem(
                (item) => item.id === memoId,
                patch({
                  ccedUsers: removeItem((item) => item.user.id === userId),
                }),
              ),
            }),
          }),
        );
        this.store.dispatch(new UpdateSelectedMemo(memoId));
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.removed-user:user removed Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SelectMemo)
  selectMemo(
    { patchState }: StateContext<MemoStateModel>,
    { memo }: SelectMemo,
  ) {
    patchState({
      selectedMemo: memo,
    });
  }

  @Action(UpdateSelectedMemo)
  updateSelectedMemo(
    { getState, patchState }: StateContext<MemoStateModel>,
    { memoId }: UpdateSelectedMemo,
  ) {
    patchState({
      selectedMemo: getState()
        .memosReceived.items.concat(getState().memosSent.items)
        .find((item) => item.id === memoId),
    });
  }

  @Action(DeleteMemo)
  deleteMemo(
    { getState, setState }: StateContext<MemoStateModel>,
    { id }: DeleteMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.deleteMemo(id).pipe(
      tap(() => {
        this.store.dispatch(
          new GetDraftMemo(getState().pageNumber, getState().pageSize),
        );
        setState(patch({ selectedMemo: null }));
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.deleted-memo:Memo deleted Successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(SetMemoPageNumberAndSize)
  setPageNumberAndSize(
    { patchState }: StateContext<MemoStateModel>,
    { pageNumber, pageSize }: SetMemoPageNumberAndSize,
  ) {
    patchState({
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  }

  @Action(UpdateMemo)
  updateMemo(
    { patchState, getState }: StateContext<MemoStateModel>,
    { formData, memoId }: UpdateMemo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.updateMemo(formData, memoId).pipe(
      tap((updatedMemo) => {
        let pageNumber = getState().pageNumber;
        let pageSize = getState().pageSize;
        this.store.dispatch(new GetMemosSent(pageNumber, pageSize));
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.updated-memo:Memo updated Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateMemoStatus)
  updateMemoStatus(
    { patchState, getState }: StateContext<MemoStateModel>,
    { status, memoId }: UpdateMemoStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.updateMemoStatus(status, memoId).pipe(
      tap((updatedMemo) => {
        let pageNumber = getState().pageNumber;
        let pageSize = getState().pageSize;
        this.store.dispatch(new GetMemosSent(pageNumber, pageSize));
        this.store.dispatch(new GetDraftMemo(pageNumber, pageSize));
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.updated-memo:Memo updated Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetMemoAccessDetail)
  getMemoAccessDetail(
    { patchState }: StateContext<MemoStateModel>,
    { memoId }: GetMemoAccessDetail,
  ) {
    return this.memoService.getMemoAccessDetail(memoId).pipe(
      tap((accessDetail) => {
        patchState({
          selectedMemoAccessDetail: accessDetail,
        });
      }),
    );
  }

  @Action(DeleteMemoAttachment)
  deleteMemoAttachment(
    { patchState }: StateContext<MemoStateModel>,
    { memoId, attachmentId }: DeleteMemoAttachment,
  ) {
    return this.memoService.deleteAttachment(memoId, attachmentId).pipe(
      tap((memo) => {
        patchState({
          selectedMemo: memo,
        });
        this.operationStatus.displayStatus(
          $localize`:@@documents.memo.deleted-attachment:Attachment deleted successfuly`,
          successStyle,
        );
      }),
    );
  }

  @Action(SearchMemos)
  searchMemos(
    { patchState }: StateContext<MemoStateModel>,
    { searchTerm, sentFromId, pageNumber, pageSize }: SearchMemos,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService
      .searchMemos(searchTerm, sentFromId, pageNumber, pageSize)
      .pipe(
        tap((paginatedMemos) => {
          patchState({
            memosSent: paginatedMemos,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetMemoSearchingMode)
  setMemoSearchingMode(
    { patchState }: StateContext<MemoStateModel>,
    { isSearchingMemo }: SetMemoSearchingMode,
  ) {
    patchState({
      isSearchingMemo: isSearchingMemo,
    });
  }

  @Action(DownloadAttachment)
  downloadTaskDocument(
    { patchState }: StateContext<MemoStateModel>,
    { memoId, attachmentId, name }: DownloadAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getAttachment(memoId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        let filename = name;
        link.download = filename;
        link.click();
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(PreviewAttachment)
  previewTaskDocument(
    { setState }: StateContext<MemoStateModel>,
    { memoId, attachmentId, name }: PreviewAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService.getAttachment(memoId, attachmentId).pipe(
      tap((response) => {
        const blob = response;
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetMemoFilters)
  getMemoFilters(
    { setState }: StateContext<MemoStateModel>,
    {}: GetMemoFilters,
  ) {
    return this.memoService.getMemoFilters().pipe(
      tap((filters) => {
        setState(
          patch({
            memoFilters: filters,
          }),
        );
      }),
    );
  }

  @Action(GetMemoById)
  getMemoById(
    { setState }: StateContext<MemoStateModel>,
    { memoId }: GetMemoById,
  ) {
    return this.memoService.getMemoById(memoId);
  }

  @Action(GetMemosByDate)
  getMemosByDate(
    { patchState }: StateContext<MemoStateModel>,
    { email, startDate, endDate, status, pageNumber, pageSize }: GetMemosByDate,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.memoService
      .getMemosByDate(email, startDate, endDate, status, pageNumber, pageSize)
      .pipe(
        tap((paginatedMemos) => {
          if (status === 'Draft') {
            patchState({ draftMemo: paginatedMemos });
          } else if (status === 'Sent') {
            patchState({ memosSent: paginatedMemos });
          } else if (status === 'Received') {
            patchState({ memosReceived: paginatedMemos });
          } else if (status === 'CC') {
            patchState({ memosCcedToMe: paginatedMemos });
          }

          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
