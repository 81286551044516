import { ArrayDataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { FileFacade } from 'src/app/files/facade/file.facade';
import { FolderFacade } from 'src/app/files/facade/folder.facade';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';

interface ArchivedFileMoveComponentState {
  flatFolderNodes: FlatFolderNode[];
  selectedFlatFolderNode: FlatFolderNode | undefined;
  selectedFilesIds: string[];
}

const initFoldersListComponentState: Partial<ArchivedFileMoveComponentState> = {
  flatFolderNodes: [],
  selectedFilesIds: [],
};

@Component({
  selector: 'app-archived-file-move',
  templateUrl: './archived-file-move.component.html',
  styleUrls: ['./archived-file-move.component.scss'],
})
export class ArchivedFileMoveComponent implements OnInit {
  flatFolderNodes$: Observable<FlatFolderNode[]> =
    this.state.select('flatFolderNodes');
  selectedFlatFolderNode$: Observable<FlatFolderNode | undefined> =
    this.state.select('selectedFlatFolderNode');

  selectedFlatFolderNode: FlatFolderNode | undefined;
  folderNodes: Array<FlatFolderNode> = [];

  dataSource = new ArrayDataSource(this.folderNodes);

  selectedWorkspaceId?: string;
  destinationFolder: FlatFolderNode | undefined;
  workspaceId: string | undefined;
  selectedFilesIds: string[] = [];

  selectedFileIds$ = this.state.select('selectedFilesIds');

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      selectedWorkspaceId: string;
    },
    private folderFacade: FolderFacade,
    private fileFacade: FileFacade,
    private state: RxState<ArchivedFileMoveComponentState>,
  ) {
    this.state.set(initFoldersListComponentState);

    this.state.connect('selectedFilesIds', this.fileFacade.selectedFilesIds$);

    this.state.connect(
      'flatFolderNodes',
      folderFacade.workspaceFlatFolderNodes$,
    );
    this.state.connect(
      'selectedFlatFolderNode',
      this.folderFacade.selectedFlatFolderNode$,
    );
    this.selectedWorkspaceId = this.data.selectedWorkspaceId;
  }

  ngOnInit(): void {
    this.folderFacade.dispatchGetWorkspaceFlatFolderNodes(
      this.selectedWorkspaceId as string,
    );

    this.flatFolderNodes$.subscribe((flatFolders) => {
      this.folderNodes = flatFolders;
      this.folderNodes = this.folderNodes.filter(
        (node) => node.id !== this.selectedFlatFolderNode?.id,
      );

      this.dataSource = new ArrayDataSource(this.folderNodes);
    });

    this.selectedFlatFolderNode$.subscribe(
      (selectedFlatFolderNode) =>
        (this.selectedFlatFolderNode = selectedFlatFolderNode),
    );

    this.selectedFileIds$.subscribe((ids) => (this.selectedFilesIds = ids));
  }

  treeControl = new FlatTreeControl<FlatFolderNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatFolderNode) => node.expandable;

  getParentNode(node: FlatFolderNode) {
    const nodeIndex = this.folderNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.folderNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.folderNodes[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatFolderNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  handleClick(node: FlatFolderNode) {
    this.destinationFolder = node;
  }

  selectDestinationFolder() {
    this.folderFacade.dispatchSelectDestinationFlatFolderNode(
      this.destinationFolder as FlatFolderNode,
    );
  }

  hasMoveFileFromArchivedWorkspacePermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.File.Feature,
      PERMISSION_NAMES.Files.File.MoveFileFromArchivedWorkspace,
    );
  }
}
