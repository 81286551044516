import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  AttachUserToStep,
  AssignUserToWorkflowStep,
  DownloadWorkflowStep,
  GetNotifiedUsers,
  GetWorkflowStep,
  GetWorkflowSteps,
  RemoveNotifiedUser,
  WorkflowStepUpdate,
  GetArchivedWorkflowSteps,
  GetArchivedWorkflowStep,
  GetCcdWorkflowStepDetail,
  GetSelectedWorkflowStep,
  WorkflowStepUpdateStatus,
  UploadWorkflowStepFile,
  GetWorkflowStepFiles,
  DeleteWorkflowStepFile,
  PreviewWorkflowStepFile,
} from '../store/workflow-step.actions';
import { WorkflowStepSelector } from '../store/workflow-step.selectors';
import { Observable } from 'rxjs';
import {
  UpdateWorkflowStep,
  WorkflowStep,
  WorkflowStepFile,
  WorkflowStepSimple,
} from '../models/workflow-step.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { NotifiedUser } from '../models/notified-user.model';
@Injectable({
  providedIn: 'root',
})
export class WorkflowStepFacade {
  constructor(private store: Store) {}

  @Select(WorkflowStepSelector.workflowStepDetail)
  workflowStepDetails$!: Observable<WorkflowStep>;

  @Select(WorkflowStepSelector.workflowStep)
  workflowStep$!: Observable<WorkflowStep>;
  @Select(WorkflowStepSelector.notifiedUsers)
  notifiedUsers$!: Observable<PaginatedList<NotifiedUser>>;
  @Select(WorkflowStepSelector.workflowSteps)
  workflowSteps$!: Observable<WorkflowStep[]>;
  @Select(WorkflowStepSelector.slectedWorkflowWorkflowSteps)
  slectedWorkflowWorkflowSteps$!: Observable<WorkflowStepSimple[]>;
  @Select(WorkflowStepSelector.ccdWorkflowstep)
  ccWorkflowStep$!: Observable<WorkflowStep>;
  @Select(WorkflowStepSelector.workflowStepFiles)
  workflowStepFiles$!: Observable<PaginatedList<WorkflowStepFile>>;

  dispatchCurrentWorkflowStep(workflowId: string) {
    this.store.dispatch(new GetWorkflowStep(workflowId));
  }

  dispatchGetArchivedWorkflowStep(workflowId: string) {
    this.store.dispatch(new GetArchivedWorkflowStep(workflowId));
  }

  dispatchGetNotifiedUsers(
    worflowStepId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetNotifiedUsers(worflowStepId, pageNumber, pageSize),
    );
  }
  dispatchDownloadWorkflowStep(
    workflowStepId: string,
    workflowStepName: string,
  ) {
    this.store.dispatch(
      new DownloadWorkflowStep(workflowStepId, workflowStepName),
    );
  }
  dispatchGetWorkflowSteps(workflowId: string) {
    this.store.dispatch(new GetWorkflowSteps(workflowId));
  }
  dispatchGetArchivedWorkflowSteps(workflowId: string) {
    this.store.dispatch(new GetArchivedWorkflowSteps(workflowId));
  }
  dispatchRemoveNotifiedUser(worflowStepId: string, userId: string) {
    this.store.dispatch(new RemoveNotifiedUser(worflowStepId, userId));
  }
  dispatchUpdateWorkflowStep(updateWorkflowStep: UpdateWorkflowStep) {
    this.store.dispatch(new WorkflowStepUpdate(updateWorkflowStep));
  }
  dispatchUpdateWorkflowStepStatus(stepId: string, action: string, comment?: string, rejectionRemark?: string) {
    this.store.dispatch(new WorkflowStepUpdateStatus({stepId, action, comment, rejectionRemark}));
  }
  dispatchAttachUserToStep(id: string, notifiedUsers: string[]) {
    this.store.dispatch(new AttachUserToStep(id, notifiedUsers));
  }
  dispatchAssignUserToWorkflowStep(workflowStepId: string, assigneeId: string, workflowId:string) {
    this.store.dispatch(
      new AssignUserToWorkflowStep(workflowStepId, assigneeId,workflowId),
    );
  }

  dispatchGetCcdWorkflowStepDetail(stepId: string) {
    this.store.dispatch(new GetCcdWorkflowStepDetail(stepId));
  }
  dispatchGetSelectedWorkflowStep(workflowStepId: string) {
    this.store.dispatch(new GetSelectedWorkflowStep(workflowStepId));
  }

  dispatchUploadWorkflowStepFile(file:File, workflowStepId: string) {
    return this.store.dispatch(new UploadWorkflowStepFile(file,workflowStepId));
  }

  dispatchGetWorkflowStepFiles(workflowStepId: string) {
    return this.store.dispatch(new GetWorkflowStepFiles(workflowStepId));
  }

  dispatchDeleteWorkflowStepFile(fileId: string) {
    return this.store.dispatch(new DeleteWorkflowStepFile(fileId));
  }

  dispatchPreviewWorkflowStepFile(
    fileId: string,
    fileName: string,
  ) {
    this.store.dispatch(
      new PreviewWorkflowStepFile(fileId, fileName),
    );
  }
}
