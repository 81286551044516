import {
  ExternalDocumentDto,
  ExternalDocumentResponseDto,
  SendRemarkDto,
} from '../models/external-document.model';

export class CreateExternalDocument {
  static readonly type = `[ExternalDocument] ${CreateExternalDocument.name}`;
  constructor(public externalDocument: FormData) {}
}

export class UpdateExternalDocument {
  static readonly type = `[ExternalDocument] ${UpdateExternalDocument.name}`;
  constructor(public externalDocument: FormData) {}
}

export class CreateResponse {
  static readonly type = `[ExternalDocument] ${CreateResponse.name}`;
  constructor(
    public response: FormData,
    public send: boolean,
  ) {}
}

export class UpdateResponse {
  static readonly type = `[ExternalDocument] ${UpdateResponse.name}`;
  constructor(
    public response: FormData,
    public send: boolean,
  ) {}
}

export class GetExternalDocument {
  static readonly type = `[ExternalDocument] ${GetExternalDocument.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class GetExternalDocumentResponses {
  static readonly type = `[ExternalDocument] ${GetExternalDocumentResponses.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class GetExternalDocumentDetail {
  static readonly type = `[ExternalDocument] ${GetExternalDocumentDetail.name}`;
  constructor(public externalDocumentId: string) {}
}

export class GetExternalDocumentResponseDetail {
  static readonly type = `[ExternalDocument] ${GetExternalDocumentResponseDetail.name}`;
  constructor(public responseId: string) {}
}

export class SetSelectedExternalDocument {
  static readonly type = `[ExternalDocument] ${SetSelectedExternalDocument.name}`;
  constructor(public externalDocument: ExternalDocumentDto) {}
}

export class SetSelectedExternalDocumentResponse {
  static readonly type = `[ExternalDocument] ${SetSelectedExternalDocumentResponse.name}`;
  constructor(public response: ExternalDocumentResponseDto) {}
}

export class ResetSelectedExternalDocument {
  static readonly type = `[ExternalDocument] ${ResetSelectedExternalDocument.name}`;
  constructor() {}
}


export class ResetExternalDocumentDetail {
  static readonly type = `[ExternalDocument] ${ResetExternalDocumentDetail.name}`;
  constructor() {}
}
export class ResetExternalDocumentResponseDetail {
  static readonly type = `[ExternalDocument] ${ResetExternalDocumentResponseDetail.name}`;
  constructor() {}
}

export class DeleteExternalDocument {
  static readonly type = `[ExternalDocument] ${DeleteExternalDocument.name}`;
  constructor(public externalDocumentId: string) {}
}

export class DeleteExternalDocumentResponse {
  static readonly type = `[ExternalDocument] ${DeleteExternalDocumentResponse.name}`;
  constructor(public externalDocumentResponseId: string) {}
}

export class SendRemark {
  static readonly type = `[ExternalDocument] ${SendRemark.name}`;
  constructor(
    public remark: SendRemarkDto,
    public externalDocumentId: string,
  ) {}
}

export class SendResponseRemark {
  static readonly type = `[ExternalDocument] ${SendResponseRemark.name}`;
  constructor(
    public remark: SendRemarkDto,
    public responseId: string,
  ) {}
}

export class PreviewAttachment {
  static readonly type = `[ExternalDocument] ${PreviewAttachment.name}`;
  constructor(
    public readonly documentId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}
export class DownloadAttachment {
  static readonly type = `[ExternalDocument] ${DownloadAttachment.name}`;
  constructor(
    public readonly documentId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}

export class PreviewResponseAttachment {
  static readonly type = `[ExternalDocument] ${PreviewResponseAttachment.name}`;
  constructor(
    public readonly documentId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}
export class DownloadResponseAttachment {
  static readonly type = `[ExternalDocument] ${DownloadResponseAttachment.name}`;
  constructor(
    public readonly documentId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}

export class GetRemarkActionStatus {
  static readonly type = `[ExternalDocument] ${GetRemarkActionStatus.name}`;
  constructor(
  ) {}

}

export class SetFormSuccessful {
  static readonly type = `[ExternalDocument] ${SetFormSuccessful.name}`;
  constructor() {}
}

export class SetFormNotSuccessful {
  static readonly type = `[ExternalDocument] ${SetFormNotSuccessful.name}`;
  constructor() {}
}

