import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RxState } from '@rx-angular/state';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { PublicationFacade } from 'src/app/researches/facades/publication.facade';
import { PublicationComment } from 'src/app/researches/models/publication.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';

interface PublicationCommentComponentState {
  publicationComments: PaginatedList<PublicationComment>;
  showComments: boolean;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initPublicationCommentComponentState: PublicationCommentComponentState = {
  publicationComments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  showComments: false,
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-publication-comment',
  templateUrl: './publication-comment.component.html',
  styleUrl: './publication-comment.component.scss',
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '300px',
          height: 'auto',
          opacity: 1,
        }),
      ),
      state(
        'out',
        style({
          width: '0px',
          opacity: 0,
        }),
      ),
      transition('in => out', animate('1000ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out')),
    ]),
  ],
})
export class PublicationCommentComponent {
  publicationComments$ = this.state.select('publicationComments');
  publicationComments: PublicationComment[] = [];
  pageNumber: number = 1;
  pageSize?: number;
  length: number = 0;
  currentReplyId?: string;

  editingCommentId?: string;
  editingContent?: string;
  editingReplyId?: string;
  editingReplyContent: string = '';
  parentCommentId?: string;

  showComments = false;
  showComments$ = this.state.select('showComments');

  placeholderToggleLabel = {
      writeReply: $localize`:@@researches.publication-comment.write-a-reply: Write a reply...`,
      writeComment: $localize`:@@researches.publication-comment.write-a-comment: Write a comment...`,
  }

  commentForm: FormGroup;
  @Input() processId?: string;

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private state: RxState<PublicationCommentComponentState>,
    private dialog: MatDialog,
    private publicationFacade: PublicationFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initPublicationCommentComponentState);
    this.state.connect(
      'publicationComments',
      this.publicationFacade.publicationComments$,
    );

    this.state.connect(
      'showComments',
      this.publicationFacade.isPublicationCommentVisible$,
    );

    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );

    this.commentForm = this.fb.group({
      comment: ['',[Validators.required]],
    });
  }

  ngOnInit(): void {
    if (!this.processId) return;
    this.publicationFacade.dispatchGetPaginatedPublicationComments(
      this.processId,
    );
    this.publicationComments$.subscribe((comments) => {
      this.publicationComments = comments.items;
      this.length = comments.totalCount;
    });

    this.showComments$?.subscribe((showComments) => {
      this.showComments = showComments;
    });

    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
  }

  onPageChange(event: any) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if (!this.processId) return;
    if (this.pageSize && this.processId) {
      this.publicationFacade.dispatchGetPaginatedPublicationComments(
        this.processId,
        this.pageNumber,
        this.pageSize,
      );
    } else {
      this.publicationFacade.dispatchGetPaginatedPublicationComments(
        this.processId,
      );
    }
  }

  submitComment() {
    if (this.commentForm.valid && this.processId) {
      const { comment } = this.commentForm.value;
      this.publicationFacade.dispatchAddPublicationComment(
        this.processId,
        comment,
      );
      this.commentForm.reset();
    }
  }

  deleteComment(commentId?: string) {
    if (!commentId) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne:
        $localize`:@@researches.publication-comment.are-you-sure-you-want-to-delete-your-comment: Are you sure you want to delete your comment?`
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.publicationFacade.dispatchDeletePublicationComment(commentId);
      }
    });
  }

  editComment(commentId?: string) {
    if (!commentId) return;
    this.publicationFacade.dispatchDeletePublicationComment(commentId);
  }

  replyToComment(commentId?: string) {
    if (!commentId) return;
    this.publicationFacade.dispatchDeletePublicationComment(commentId);
  }

  submitReply(replyContent: string, parentCommentId?: string): void {
    if (!parentCommentId || !replyContent) return;

    this.publicationFacade.dispatchReplyPublicationComment(
      parentCommentId,
      replyContent,
    );

    this.closeReplyForm();
  }

  openReplyForm(commentId?: string) {
    this.currentReplyId = commentId;
  }

  closeReplyForm() {
    this.currentReplyId = undefined;
  }
  editReplyComment(parentCommentId?: string, commentId?: string) {
    if (!parentCommentId || !commentId) return;
  }

  deleteReplyComment(parentCommentId?: string, commentId?: string) {
    if (!parentCommentId || !commentId) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.publication-comment.are-you-sure-you-want-to-delete-your-reply: Are you sure you want to delete your reply?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.publicationFacade.dispatchDeletePublicationCommentReply(
          commentId,
          parentCommentId,
        );
      }
    });

  }

  openEditForm(comment: PublicationComment) {
    this.editingCommentId = comment.id;
    this.editingContent = comment.content;
  }

  closeEditForm() {
    this.editingCommentId = undefined;
    this.editingContent = undefined;
  }

  submitEdit(commentId?: string) {
    if (this.editingContent && commentId) {
      this.publicationFacade.dispatchUpdatePublicationComment(
        commentId,
        this.editingContent,
      );
      this.closeEditForm();
    }
  }

  openEditReplyForm(reply: PublicationComment, parentCommentId?: string) {
    this.editingReplyId = reply.id;
    this.parentCommentId = parentCommentId;
    this.editingReplyContent = reply.content;
  }

  closeEditReplyForm() {
    this.editingReplyId = undefined;
    this.parentCommentId = undefined;
    this.editingReplyContent = '';
  }

  submitEditReply(replyId?: string, parentCommentId?: string) {
    if (replyId && parentCommentId && this.editingReplyContent) {
      this.publicationFacade.dispatchUpdatePublicationCommentReply(
        replyId,
        this.editingReplyContent,
        parentCommentId,
      );
      this.editingReplyId = undefined;
      this.parentCommentId = undefined;
      this.editingReplyContent = '';
    }
  }

  hasReplyPublicationCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.ReplyPublicationComment,
    );
  }
  hasUpdatePublicationCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.UpdatePublicationComment,
    );
  }
  hasDeletePublicationCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.DeletePublicationComment,
    );
  }
  hasUpdatePublicationCommentReplyPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.UpdatePublicationCommentReply,
    );
  }
  hasDeletePublicationCommentReplyPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.DeletePublicationReplyComment,
    );
  }

  hasCreatePublicationCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.CreatePublicationComment,
    );
  }

  closeComment() {
    this.publicationFacade.dispatchTogglePublicationComments(false);
  }
}
