<h2 mat-dialog-title i18n="@@files.workspace-list.workspaces">Workspaces</h2>

<mat-dialog-content>
  <div class="grid-wrapper">
    <mat-paginator
      [length]="totalCount"
      [pageSize]="workspaces.length"
      [pageSizeOptions]="pageSizes"
      aria-label="Select page"
      (page)="loadWorkspaces($event)"
    >
    </mat-paginator>

    <angular-slickgrid
      gridId="grid1"
      [columnDefinitions]="column"
      [gridOptions]="gridOptions"
      [dataset]="workspaces"
    >
    </angular-slickgrid>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n="@@files.workspace-list.cancel">
    Cancel
  </button>
</mat-dialog-actions>
