import { Component, ElementRef, OnInit, ViewChild  ,Inject} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { TorFacade } from '../../facades/tor.facades';
import { MatDialog , MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyFacade } from 'src/app/researches/facades/currency.facade';
import { CurrencyData } from 'src/app/researches/models/currency.model';
import { distinctUntilChanged, skip } from 'rxjs/operators';

interface TorInitiateFormComponentState {
  selectedCurrency:string;
  currencies: CurrencyData [];

}

const initTorInitiateFormComponentState: TorInitiateFormComponentState = {
  selectedCurrency:"" ,
  currencies: [],
};


@Component({
  selector: 'app-tor-initiate-form',
  templateUrl: './tor-initiate-form.component.html',
  styleUrl: './tor-initiate-form.component.scss'
})
export class TorInitiateFormComponent implements OnInit {
  selectedCurrency:string = "";
  selectedCurrency$ = this.state.select('selectedCurrency');

  currencies: CurrencyData[] = [];
  currencies$ = this.state.select('currencies');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private state: RxState<TorInitiateFormComponentState>,
    private torFacade: TorFacade,
    private router: Router,
    public dialogRef: MatDialogRef<TorInitiateFormComponent>,
    private currencyFacade: CurrencyFacade,

  ) {
    this.state.set(initTorInitiateFormComponentState);
    this.state.connect('currencies', this.currencyFacade.currencies$);
  }
  ngOnInit(): void {
    this.currencyFacade.dispatchGetCurrencies()
    this.currencies$.subscribe(currencies=>{
      this.currencies = currencies;
      this.selectedCurrency = this.currencies.find((currency) => currency.isDefault === true)?.currencyName as string;
    })
  }

initiateTor() {
  this.torFacade.dispatchInitiateTor(this.data.stageInstanceId, this.selectedCurrency);

  this.torFacade.initiatedTor$
    .pipe(
      skip(1),
      distinctUntilChanged((prev, curr) => prev?.id === curr?.id)
    )
    .subscribe(tor => {
      if (tor) {
        this.dialogRef.close();
        const navigationDetails: string[] = [`/tor-detail/${tor.id}`];
        this.router.navigate(navigationDetails);
      }
    });
}

  handleCurrencyChange(currency:string) {
     this.selectedCurrency = currency;
  }
}
