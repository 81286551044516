import { Notice, NoticeStatus } from '../models/notices.model';

export class CreateNotice {
  static readonly type = `[Notices] ${CreateNotice.name}`;
  constructor(
    public notice: FormData,
    public send: boolean,
  ) {}
}
export class GetNotices {
  static readonly type = `[Notices] ${GetNotices.name}`;
  constructor(
    public pageNumber: number,
    public pageSize: number,
  ) {}
}
export class SetSelectedNotice {
  static readonly type = `[Notices] ${SetSelectedNotice.name}`;
  constructor(public notice: Notice) {}
}

export class GetNoticeDetial {
  static readonly type = `[Notices] ${GetNoticeDetial.name}`;
  constructor(public noticeId: string) {}
}

export class DeleteNotice {
  static readonly type = `[Notices] ${DeleteNotice.name}`;
  constructor(public noticeId: string) {}
}

export class DeleteNoticeAttachment {
  static readonly type = `[Notices] ${DeleteNoticeAttachment.name}`;
  constructor(
    public noticeId: string,
    public attachmentId: string,
  ) {}
}

export class SearchNotice {
  static readonly type = `[Notices] ${SearchNotice.name}`;
  constructor(
    public isDraft: boolean,
    public isSent: boolean,
    public isReceived: boolean,
    public searchTerm?: string,
  ) {}
}

export class UpdateNotice {
  static readonly type = `[Notices] ${UpdateNotice.name}`;
  constructor(
    public noticeId: string,
    public noticeFormData: FormData,
    public send: boolean,
  ) {}
}

export class SetUpdateStatus {
  static readonly type = `[Notices] ${SetUpdateStatus.name}`;
  constructor(public updateStatus: boolean) {}
}

export class GetNoticeAccessData {
  static readonly type = `[Notices] ${GetNoticeAccessData.name}`;
  constructor(public noticeId: string) {}
}

export class PreviewAttachment {
  static readonly type = `[Notices] ${PreviewAttachment.name}`;
  constructor(
    public readonly noticeId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}
export class DownloadAttachment {
  static readonly type = `[Notices] ${DownloadAttachment.name}`;
  constructor(
    public readonly noticeId: string,
    public readonly attachmentId: string,
    public readonly name: string,
  ) {}
}
export class GetNoticeFilters {
  static readonly type = `[Notices] ${GetNoticeFilters.name}`;
  constructor() {}
}

export class GetNoticeByStatus {
 static readonly type = `[Notices] ${GetNoticeByStatus.name}`;
  constructor(
    public status: NoticeStatus,
    public pageIndex: number,
    public pageSize: number,
  ) {}
}

export class SearchNoticeWithDate {
  static readonly type = '[Notices] SearchNoticeWithDate';
  constructor(
    public readonly email: string,
    public readonly startDate: string,
    public readonly endDate: string,
    public readonly isDraft: boolean,
    public readonly isSent: boolean,
    public readonly isReceived: boolean,
    public readonly pageNumber: number,
    public readonly pageSize: number
  ) {}
}