<mat-dialog-content *appHasPermission="hasGetArchivedFolderPropertyPermission()">
  <div class="header">
    <mat-icon
      aria-hidden="false"
      class="folder-icon"
      fontIcon="folder"
      color="primary"
    ></mat-icon>
    <h3>{{ archivedFolderProperty?.name }} <span i18n="@@offices.archived-folder-property.properties"> Properties </span> </h3>
  </div>
  <mat-divider></mat-divider>
  <mat-list role="list">
    <mat-list-item role="listitem"
      > <span i18n="@@offices.archived-folder-property.type"> Type : </span> <span>{{ "File Folder" }}</span></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@offices.archived-folder-property.content-size"> Content Size : </span>
      <span
        >{{ archivedFolderProperty?.contentSize }}
        {{ archivedFolderProperty?.contentSizeUnit }}</span
      ></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@offices.archived-folder-property.contains"> Contains : </span>
      <span
        >{{ archivedFolderProperty?.numberOfFilesInUse }} <span i18n="@@offices.archived-folder-property.files-in-use"> Files In Use : </span>
      </span></mat-list-item
    >
    <mat-list-item role="listitem"
    ><span i18n="@@offices.archived-folder-property.contains"> Contains : </span>
    <span
      >{{ archivedFolderProperty?.numberOfFilesInTrash }} <span i18n="@@offices.archived-folder-property.files-in-trash"> Files In Trash : </span>
    </span></mat-list-item
  >
    <mat-list-item role="listitem"
      ><span i18n="@@offices.archived-folder-property.workspace"> Workspace : </span>
      <span>{{ archivedFolderProperty?.workspaceName }}</span></mat-list-item
    >
    <mat-divider></mat-divider>
    <mat-list-item role="listitem"
      ><span i18n="@@offices.archived-folder-property.created"> Created : </span>
      <span>{{ archivedFolderProperty?.created }}</span></mat-list-item
    >
    <mat-list-item role="listitem"
      ><span i18n="@@offices.archived-folder-property.created-by"> Created By : </span>
      <span>{{ archivedFolderProperty?.createdBy }}</span></mat-list-item
    >
  </mat-list>
  <button class="close-btn" mat-flat-button color="accent" mat-dialog-close i18n="@@offices.archived-folder-property.close">
    Close
  </button>
</mat-dialog-content>
