import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { UserWithPassword } from '../../models/user.model';
import { RxState } from '@rx-angular/state';
import { UserFacade } from '../../facade/user.facade';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { Subject } from 'rxjs';

interface ManagePasswordsComponentState {
  usersWithPassword: PaginatedList<UserWithPassword> | undefined;
}

const initManagePasswordsComponentState: ManagePasswordsComponentState = {
  usersWithPassword: undefined,
}

@Component({
  selector: 'app-manage-passwords',
  templateUrl: './manage-passwords.component.html',
  styleUrl: './manage-passwords.component.scss',
  providers: [{provide: MatPaginatorIntl, useClass: ManagePasswordsComponent}, RxState],
})
export class ManagePasswordsComponent implements OnInit, MatPaginatorIntl{
  usersWithPassword$ = this.state.select('usersWithPassword');
  usersWithPassword: PaginatedList<UserWithPassword> | undefined = undefined;
  displayedColumns: string[] = ['name','userName', 'password', 'actions'];
  pageSize: number = 10;
  pageIndex: number = 0;
  visiblePasswords: boolean[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  placeholderLabel = {
    nameField: $localize`:@@users.manage-passwords.search: Search`,
  }

  firstPageLabel = $localize`:@@users.manage-passwords.first-page: First page`;
  itemsPerPageLabel = $localize`:@@users.manage-passwords.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@users.manage-passwords.last-page: Last page`;

  nextPageLabel = $localize`:@@users.manage-passwords.next-page:  Next page`;
  previousPageLabel = $localize`:@@users.manage-passwords.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@users.manage-passwords.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@users.manage-passwords.page-part-one: Page` + `${page + 1}` + $localize`:@@users.manage-passwords.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<ManagePasswordsComponentState>,
    private userFacade: UserFacade,
    private operationStatus: OperationStatusService,
  ) {
    this.state.set(initManagePasswordsComponentState);
    this.state.connect('usersWithPassword', this.userFacade.usersWithPassword$)
  }
  changes = new Subject<void>();
  searchText: string = '';

  ngOnInit(): void {
    this.userFacade.dispatchGetUsersDefaultPasswords(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10
    );
    this.usersWithPassword$.subscribe((users)=>{
      this.usersWithPassword = users;
      this.visiblePasswords = new Array(users?.items.length).fill(false);
    })
  }

  getUsersBySearch() {
    if (this.searchText) {
      this.userFacade.dispatchGetUsersDefaultPasswordsBySearch(
        this.searchText,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10
      );
    } else {
      this.userFacade.dispatchGetUsersDefaultPasswords(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize || 10
      );
    }
  }

  resetSearch() {
    this.searchText = '';
    this.userFacade.dispatchGetUsersDefaultPasswords(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10
    );
  }

  hasGetUsersPasswordPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsersDefaultPasswords,
    );
  }

  loadPaginatedUsers(event: PageEvent) {
    this.userFacade.dispatchGetUsersDefaultPasswords(event.pageIndex + 1, event.pageSize);
  }

  togglePasswordVisibility(index: number): void {
    this.visiblePasswords[index] = !this.visiblePasswords[index];
  }

  copyPassword(user: UserWithPassword): void {
    const passwordToCopy = user.defaultPassword;
    navigator.clipboard.writeText(passwordToCopy)
    this.operationStatus.displayStatus($localize`:@@users.manage-passwords.password-copied-to-clipboard: Password copied to clipboard.`)
  }
}
