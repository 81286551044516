export const QuillEditorToolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, 7, false] }],
  [{ font: [] }],
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  ['image', 'code-block'],
];
