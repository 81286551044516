import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, combineLatest, map } from 'rxjs';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import {
  APPROVE_DOCUMENT_TEMPLATES_ROUTE,
  ARCHIVED_WORKFLOWS_ROUTE,
  DOCUMENTS_DASHBOARD_ROUTE,
  DOCUMENT_SETTINGS_ROUTE,
  DOCUMENT_TEMPLATES_ROUTE,
  EXTERNAL_DOCUMENT_ROUTE,
  MEMOS_ROUTE,
  MY_WORKFLOWS_ROUTE,
  NOTICES_ROUTE,
  Owned_WORKFLOWS_ROUTE,
  REPORTED_COMMENT_ROUTE,
  WORKFLOWS_ROUTE,
  WORKFLOW_TYPES_ROUTE,
} from 'src/app/core/constants/routes';
import { NotificationFacade } from 'src/app/core/facades/notification.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';

interface State {
  memoCount: number;
  noticeCount: number;
}

const initialState: State = {
  memoCount: 0,
  noticeCount: 0,
};

@Component({
  selector: 'app-document-home',
  templateUrl: './document-home.component.html',
  styleUrls: ['./document-home.component.scss'],
})
export class DocumentHomeComponent implements OnInit {
  navLinks: Array<{
    link: string;
    label: string;
    icon: string;
    hidden?: boolean;
    feature: string;
  }> = [
    {
      link: DOCUMENT_TEMPLATES_ROUTE,
      label: $localize`:@@nav-links.document-forms:Document Forms`,
      icon: `insert_chart`,
      hidden: true,
      feature: this.hasGetDocumentTemplatesPermission(),
    },
    {
      link: APPROVE_DOCUMENT_TEMPLATES_ROUTE,
      label: $localize`:@@nav-links.submitted-forms:Submitted Forms`,
      icon: `assignment_turned_in`,
      hidden: true,
      feature: this.hasChangeTemplateStatusToApprovedPermission(),
    },
    {
      link: WORKFLOW_TYPES_ROUTE,
      label: $localize`:@@nav-links.workflow-types:Workflow Types`,
      icon: `work`,
      hidden: true,
      feature: this.hasGetWorkflowTypesPermission(),
    },
    {
      link: WORKFLOWS_ROUTE,
      label: $localize`:@@nav-links.workflows:Workflows`,
      icon: `folder`,
      hidden: true,
      feature: this.hasGetWorkflowsPermission(),
    },
    {
      link: Owned_WORKFLOWS_ROUTE,
      label: $localize`:@@nav-links.submitted-workflows:Submitted Workflows`,
      icon: `folder_shared`,
      hidden: true,
      feature: this.hasGetOwnedWorkflowsPermission(),
    },
    {
      link: MY_WORKFLOWS_ROUTE,
      label: $localize`:@@nav-links.ccs:CC's`,
      icon: `account_box`,
      hidden: true,
      feature: this.hasGetNotifiedWorkflowsPermission(),
    },
    {
      link: EXTERNAL_DOCUMENT_ROUTE,
      label: $localize`:@@nav-links.external-document:External Documents`,
      icon: `email`,
      hidden: true,
      feature: this.hasGetExternalDocumentPermission(),
    },
    {
      link: REPORTED_COMMENT_ROUTE,
      label: $localize`:@@nav-links.reported-comments:Reported Comments`,
      icon: `report_problem`,
      hidden: true,
      feature: this.hasGetReportedCommentsPermission(),
    },
    {
      link: MEMOS_ROUTE,
      label: $localize`:@@nav-links.memos:Memos`,
      icon: `speaker_notes`,
      hidden: true,
      feature: this.hasGetMemosPermission(),
    },

    {
      link: NOTICES_ROUTE,
      label: $localize`:@@nav-links.notices:Notices`,
      icon: `announcement`,
      hidden: true,
      feature: this.hasGetNoticesPermission(),
    },
    {
      link: ARCHIVED_WORKFLOWS_ROUTE,
      label: $localize`:@@nav-links.archived-workflows:Archived Workflows`,
      icon: `archive`,
      hidden: true,
      feature: this.hasGetArchivedWorkflowsPermission(),
    },
  ];
  navLinks$ = new Observable<any>();

  memoCount$ = this.state.select('memoCount');
  noticeCount$ = this.state.select('noticeCount');

  constructor(
    private router: Router,
    private notificationFacade: NotificationFacade,
    public state: RxState<State>,
    private userFacade: UserFacade,
  ) {
    this.state.set(initialState);
    this.state.connect('memoCount', this.notificationFacade.memoCount$);
    this.state.connect('noticeCount', this.notificationFacade.noticeCount$);
  }

  ngOnInit(): void {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.updateMenuAuthorization();
      }
    });
  }

  updateMenuAuthorization() {
    this.navLinks$ = combineLatest([
      ...this.navLinks.map((item) => this.isAuthorized(item.feature)),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.navLinks.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );
  }

  navigateToSettings() {
    this.router.navigate([DOCUMENT_SETTINGS_ROUTE]);
  }

  getBadge$(type: string) {
    if (type == 'Memos') {
      return this.memoCount$;
    } else {
      return this.noticeCount$;
    }
  }
  hasGetWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.GetWorkflowTypes,
    );
  }
  hasGetDocumentTemplatesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.GetDocumentForms,
    );
  }
  hasChangeTemplateStatusToApprovedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm
        .ChangeFormStatusToApproved,
    );
  }

  hasGetWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.GetWorkflowsByUser,
    );
  }

  hasGetOwnedWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.GetOwnedWorkflows,
    );
  }

  hasGetArchivedWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.GetArchivedWorkflows,
    );
  }

  hasGetExternalDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocuments,
    );
  }

  hasGetMemosPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.GetMemo,
    );
  }

  hasGetReportedCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowComment.Feature,
      PERMISSION_NAMES.Documents.WorkflowComment.GetReportedWorkflowComments,
    );
  }

  hasGetNoticesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Notice.Feature,
      PERMISSION_NAMES.Documents.Notice.GetNotices,
    );
  }
  hasGetNotifiedWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.GetNotifiedWorkflows,
    );
  }

  hasGetWorkflowCommentPolicyPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.Feature,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.GetWorkflowCommentPolicy,
    );
  }

  isAuthorized(permission: string): Observable<boolean> {
    return this.userFacade
      .hasPermission(permission)
      .pipe(map((permission) => !!permission));
  }

  isfeatureAuthorized(feature: any): boolean {
    let isAuthorized: boolean = false;

    this.userFacade
      .hasFeaturePermission(feature)
      .pipe(map((feature) => !!feature))
      .subscribe((hasPermission) => {
        isAuthorized = hasPermission;
      });

    if (IS_DEVELOPMENT) {
      return true;
    }
    return isAuthorized;
  }
}
