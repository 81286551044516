<div class="flex justify-between">
  <div
    class="main-table mat-elevation-z4"
    *ngIf="notifiedUsers!.length > 0; noUsers"
  >
    <mat-table [dataSource]="notifiedUsers!">
      <ng-container matColumnDef="users">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.attach-user-list.users">Users</mat-header-cell>
        <mat-cell *matCellDef="let process">{{
          process.userFullName
        }}</mat-cell>
      </ng-container>
      <p i18n="@@documents.attach-user-list.datasource">dataSource</p>
      <ng-container matColumnDef="offices">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.attach-user-list.offices">Offices</mat-header-cell>
        <mat-cell *matCellDef="let process">{{ process.officeName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="icon-cell"
           i18n="@@documents.attach-user-list.actions">Actions</mat-header-cell
        >
        <mat-cell *matCellDef="let user" class="icon-cell">
          <button mat-icon-button (click)="removeNotifiedUsers(user)" *appHasPermission="hasRemoveNotifyUserPermission()">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['users', 'offices', 'actions']"
      ></mat-header-row>
      <mat-row
        class="table-row"
        *matRowDef="let process; columns: ['users', 'offices', 'actions']"
      ></mat-row>
    </mat-table>
  </div>
  <ng-template #noUsers>
    <p class="empty-user" i18n="@@documents.attach-user-list.no-users-notified-yet">no users notified yet</p>
  </ng-template>
  <div align="end">
    <button
      mat-raised-button
      color="primary"
      (click)="attachUserToWorkflowStep()"
      [disabled]="isArchived"
      class="btn"
      *appHasPermission="hasAddNotifyUsersPermission()"
     i18n="@@documents.attach-user-list.add"
     [attr.data-test-id]="'documents.cc-user.attached-user-list.add-button'">
      Add
    </button>
  </div>
</div>
