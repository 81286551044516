import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ReportedWorkflowComment,
  WorkflowComment,
  WorkflowCommentPolicy,
} from '../models/workflow-comment.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  CreateWorkflowComment,
  CreateWorkflowCommentPolicy,
  DeleteWorkflowComment,
  DeleteWorkflowCommentPolicy,
  DeleteWorkflowCommentReply,
  EditWorkflowComment,
  GetArchivedWorkflowComments,
  GetReportedWorkflowComments,
  GetWorkflowCommentPolicy,
  GetWorkflowComments,
  GetWorkflowCommentsByReported,
  ReplyWorkflowComment,
  ReportWorkflowComment,
  ResetSelectedReplyComment,
  ResetWorkflowComments,
  ResolveReportedWorkflowComment,
  SetReplyWorkflowComment,
  SetSelectedReplyComment,
  SetSelectedReportedWorkflowComment,
  SetSelectedWorkflowComment,
  SetUpdateWorkflowComment,
  SetWorkflowReplyUpdatingMode,
  UpdateWorkflowCommentPolicy,
  UpdateWorkflowCommentReply,
} from './workflow-comment.actions';
import { WorkflowCommentService } from '../services/workflow-comment.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

export interface WorkflowCommentStateModel {
  update: boolean;
  workflowComments?: PaginatedList<WorkflowComment>;
  selectedWorkflowComment: WorkflowComment | undefined;
  reply: boolean;
  workflowCommentPolicy: WorkflowCommentPolicy | undefined;
  reportedWorkflowComments: PaginatedList<ReportedWorkflowComment>;
  selectedReportedWorkflowComment: ReportedWorkflowComment | undefined;
  selectedReply?: WorkflowComment | null;
  isUpdatingReply?: boolean;
}

const WORKFLOW_COMMENT_STATE_TOKEN = new StateToken<WorkflowCommentState>(
  'workflowComment',
);

const defaults: WorkflowCommentStateModel = {
  update: false,
  workflowComments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedWorkflowComment: undefined,
  reply: false,
  workflowCommentPolicy: undefined,
  reportedWorkflowComments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedReportedWorkflowComment: undefined,
};

@State<WorkflowCommentStateModel>({
  name: WORKFLOW_COMMENT_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkflowCommentState {
  constructor(
    private workflowCommentService: WorkflowCommentService,
    private store: Store,
    private operationStatus: OperationStatusService,
  ) {}

  @Action(GetWorkflowComments)
  getWorkflowComments(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowId, pageNumber, pageSize }: GetWorkflowComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .getWorkflowComments(workflowId, pageNumber, pageSize)
      .pipe(
        tap((workflowComments) => {
          setState(
            patch({
              workflowComments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateWorkflowComment)
  createWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowId, comment }: CreateWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .createWorkflowComment(workflowId, comment)
      .pipe(
        tap((workflowComment) => {
          this.store.dispatch(new SetProgressOn());
          setState(
            patch({
              workflowComments: patch({
                items: insertItem(workflowComment),
              }),
            }),
          );
          this.operationStatus.displayStatus($localize`:@@documents.workflow-comment.created-comment:Comment created successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteWorkflowComment)
  deleteWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowId, workflowCommentId }: DeleteWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .deleteWorkflowComment(workflowId, workflowCommentId)
      .pipe(
        tap(() => {
          setState(
            patch({
              workflowComments: patch({
                items: (workflowComments) =>
                  workflowComments.filter(
                    (workflowComment) =>
                      workflowComment.id !== workflowCommentId,
                  ),
              }),
            }),
          );
          this.operationStatus.displayStatus($localize`:@@documents.workflow-comment.deleted-comment:Comment deleted successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetUpdateWorkflowComment)
  setUpdateWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { status }: SetUpdateWorkflowComment,
  ) {
    setState(
      patch({
        update: status,
      }),
    );
  }

  @Action(SetSelectedWorkflowComment)
  setSelectedWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowComment }: SetSelectedWorkflowComment,
  ) {
    setState(
      patch({
        selectedWorkflowComment: workflowComment,
      }),
    );
  }

  @Action(EditWorkflowComment)
  editWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowId, workflowCommentId, content }: EditWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .editWorkflowComment(workflowId, workflowCommentId, content)
      .pipe(
        tap((workflowComment) => {
          setState(
            patch({
              workflowComments: patch({
                items: updateItem<WorkflowComment>(
                  (item) => item.id === workflowCommentId,
                  workflowComment,
                ),
              }),
              update: false,
              selectedWorkflowComment: undefined,
            }),
          );
          this.operationStatus.displayStatus($localize`:@@documents.workflow-comment.updated-comment:Comment updated successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetReplyWorkflowComment)
  setReplyWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { status }: SetReplyWorkflowComment,
  ) {
    setState(
      patch({
        reply: status,
      }),
    );
  }

  @Action(ReplyWorkflowComment)
  replyWorkflowComment(
    { setState, getState }: StateContext<WorkflowCommentStateModel>,
    { workflowCommentId, content }: ReplyWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .replyWorkflowComment(workflowCommentId, content)
      .pipe(
        tap((workflowCommentReply) => {
          const selectedWorkflowComment = getState().selectedWorkflowComment;
          selectedWorkflowComment?.replies.push(workflowCommentReply);
          setState(
            patch({
              selectedWorkflowComment: selectedWorkflowComment,
              reply: false,
            }),
          );
          this.operationStatus.displayStatus($localize`:@@documents.workflow-comment.updated-comment:Comment updated successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ReportWorkflowComment)
  reportWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowCommentId, issueDescription }: ReportWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .reportWorkflowComment(workflowCommentId, issueDescription)
      .pipe(
        tap(() => {
          this.operationStatus.displayStatus($localize`:@@documents.workflow-comment.reported-comment:Comment reported successfully`);
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowCommentPolicy)
  getWorkflowCommentPolicy(
    { setState }: StateContext<WorkflowCommentStateModel>,
    {}: GetWorkflowCommentPolicy,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService.getWorkflowCommentPolicy().pipe(
      tap((workflowCommentPolicy) => {
        setState(
          patch({
            workflowCommentPolicy,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateWorkflowCommentPolicy)
  createWorkflowCommentPolicy(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { description }: CreateWorkflowCommentPolicy,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .createWorkflowCommentPolicy(description)
      .pipe(
        tap((workflowCommentPolicy) => {
          setState(
            patch({
              workflowCommentPolicy,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-comment.created-workflow-comment:Workflow comment policy created successfully`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateWorkflowCommentPolicy)
  updateWorkflowCommentPolicy(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { id, description }: UpdateWorkflowCommentPolicy,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .updateWorkflowCommentPolicy(id, description)
      .pipe(
        tap((workflowCommentPolicy) => {
          setState(
            patch({
              workflowCommentPolicy,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-comment.update-workflow-comment:Workflow comment policy updated successfully`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteWorkflowCommentPolicy)
  deleteWorkflowCommentPolicy(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { id }: DeleteWorkflowCommentPolicy,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService.deleteWorkflowCommentPolicy(id).pipe(
      tap(() => {
        setState(
          patch({
            workflowCommentPolicy: {
              id: '',
              description: '',
            },
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-comment.deleted-workflow-comment:Workflow comment policy deleted successfully`,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetReportedWorkflowComments)
  getReportedWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { pageNumber, pageSize }: GetReportedWorkflowComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .getReportedWorkflowComments(pageNumber, pageSize)
      .pipe(
        tap((reportedWorkflowComments) => {
          setState(
            patch({
              reportedWorkflowComments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetSelectedReportedWorkflowComment)
  setSelectedReportedWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { reportedWorkflowComment }: SetSelectedReportedWorkflowComment,
  ) {
    setState(
      patch({
        selectedReportedWorkflowComment: reportedWorkflowComment,
      }),
    );
  }

  @Action(GetWorkflowCommentsByReported)
  getWorkflowCommentsByReported(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { workflowCommentId, pageNumber, pageSize }: GetWorkflowCommentsByReported,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .getWorkflowCommentsByReported(workflowCommentId, pageNumber, pageSize)
      .pipe(
        tap((workflowComments) => {
          setState(
            patch({
              workflowComments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResolveReportedWorkflowComment)
  resolveReportedWorkflowComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    { respondReportedWorkflowComment }: ResolveReportedWorkflowComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .resolveReportedWorkflowComment(respondReportedWorkflowComment)
      .pipe(
        tap((reportedWorkflowComment) => {
          setState(
            patch({
              reportedWorkflowComments: patch({
                items: updateItem<ReportedWorkflowComment>(
                  (item) => item.id === reportedWorkflowComment.id,
                  reportedWorkflowComment,
                ),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-comment.reported-resolved-comment:Comment report resolved successfully`,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetArchivedWorkflowComments)
  getArchivedWorkflowComments(
    { patchState }: StateContext<WorkflowCommentStateModel>,
    { workflowId, pageNumber, pageSize }: GetWorkflowComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .getArchivedWorkflowComments(workflowId, pageNumber, pageSize)
      .pipe(
        tap((workflowComments) => {
          patchState({ workflowComments });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateWorkflowCommentReply)
  updateWorkflowCommentReply(
    { setState, getState }: StateContext<WorkflowCommentStateModel>,
    { workflowCommentId, commentId, content }: UpdateWorkflowCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService
      .updateWorkflowCommentReply(workflowCommentId, commentId, content)
      .pipe(
        tap((workflowComment) => {
          const selectedWorkflowComment = getState().selectedWorkflowComment;
          const selectedReplyCommentt = getState().selectedReply;
          selectedReplyCommentt!.comment = workflowComment.comment;
          setState(
            patch({
              selectedWorkflowComment: selectedWorkflowComment,
              isUpdatingReply: false,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.workflow-comment.updated-comment:Comment updated successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedReplyComment());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResetSelectedReplyComment)
  resetSelectedReplyComment(
    { setState }: StateContext<WorkflowCommentStateModel>,
    {}: ResetSelectedReplyComment,
  ) {
    setState(
      patch({
        selectedReply: null,
      }),
    );
  }

  @Action(SetSelectedReplyComment)
  setSelectedReplyComment(
    { patchState }: StateContext<WorkflowCommentStateModel>,
    { comment }: SetSelectedReplyComment,
  ) {
    patchState({
      selectedReply: comment,
    });
  }

  @Action(SetWorkflowReplyUpdatingMode)
  setTorReplyUpdatingMode(
    { patchState }: StateContext<WorkflowCommentStateModel>,
    { isUpdatingReply }: SetWorkflowReplyUpdatingMode,
  ) {
    patchState({
      isUpdatingReply: isUpdatingReply,
    })

  }

  @Action(DeleteWorkflowCommentReply)
  deleteTorCommentReply(
    { setState, getState }: StateContext<WorkflowCommentStateModel>,
    { workflowCommentId, commentId }: DeleteWorkflowCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workflowCommentService.deleteWorkflowCommentReply(workflowCommentId, commentId).pipe(
      tap(() => {
        const state = getState();
        const parentCommentIndex = state.workflowComments?.items.findIndex(
          (comment) => comment.id === workflowCommentId,
        );
        if(parentCommentIndex !== -1) {
          const updatedReplies = state.workflowComments?.items[parentCommentIndex!]
          .replies.filter((reply) => reply.id !== commentId);
          const updatedComments = [...state.workflowComments?.items!];
          updatedComments[parentCommentIndex!] = {
            ...updatedComments[parentCommentIndex!],
            replies: updatedReplies!,
          };
          setState(
            patch( {
              workflowComments: patch({
                items: updatedComments,
              }),
            }),
          );
        }
        this.operationStatus.displayStatus(
          $localize`:@@documents.workflow-comment.deleted-comment:Comment deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
      );
    }

    @Action(ResetWorkflowComments)
    resetWorkflowComments(
      { setState }: StateContext<WorkflowCommentStateModel>,
      {  }: ResetWorkflowComments,
    ) {
      setState(
        patch({
          workflowComments: undefined,
        }),
      );
    }
  }