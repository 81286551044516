import { WorkflowValidation } from '../models/workflow-validation.model';
import { CreateWorflowStepValidationDto, UpdateWorkflowStepValidationDto } from '../models/worlfow-step-validation.model';

export class GetWorkflowTemplateFields {
  static readonly type = `[WorkflowValidation] ${GetWorkflowTemplateFields.name}`;
  constructor(public workflowId: string) {}
}

export class CreateWorkflowValidation {
  static readonly type = `[WorkflowValidation] ${CreateWorkflowValidation.name}`;
  constructor(
    public workflowTypeId: string,
    public leftFormFieldId: string,
    public rightFormFieldId: string,
    public validationRuleId: string,
  ) {}
}

export class GetWorkflowValidations {
  static readonly type = `[WorkflowValidation] ${GetWorkflowValidations.name}`;
  constructor(
    public workflowTypeId: string,
    public pageSize: number,
    public pageNumber: number,
  ) {}
}

export class DeleteWorkflowValidation {
  static readonly type = `[WorkflowValidation] ${DeleteWorkflowValidation.name}`;
  constructor(public id: string) {}
}

export class UpdateWorkflowValidation {
  static readonly type = `[WorkflowValidation] ${UpdateWorkflowValidation.name}`;
  constructor(
    public id: string,
    public leftFormFieldId: string,
    public rightFormFieldId: string,
    public validationRuleId: string,
  ) {}
}

export class SelectWorkflowValidation {
  static readonly type = `[WorkflowValidation] ${SelectWorkflowValidation.name}`;
  constructor(public workflowValidation: WorkflowValidation | undefined) {}
}

export class CreateWorkflowStepValidation {
  static readonly type = `[WorkflowStepValidation] ${SelectWorkflowValidation.name}`;
  constructor(public data: CreateWorflowStepValidationDto) {}
}

export class UpdateWorkflowStepValidation {
  static readonly type = `[WorkflowStepValidation] ${UpdateWorkflowStepValidation.name}`;
  constructor(public data: UpdateWorkflowStepValidationDto) {}
}

export class DeleteWorkflowStepValidation {
  static readonly type = `[WorkflowStepValidation] ${DeleteWorkflowStepValidation.name}`;
  constructor(public id: string) {}
}

export class GetWorkflowStepValidationRule {
  static readonly type = `[WorkflowStepValidation] ${GetWorkflowStepValidationRule.name}`;
  constructor(public dataType: string) {}
}


export class GetWorkflowStepValidations {
  static readonly type = `[WorkflowStepValidation] ${GetWorkflowStepValidations.name}`;
  constructor(public workflowStepId: string) {}
}
