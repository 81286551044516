import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ExternalDocumentFormComponent } from '../external-document-form/external-document-form.component';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ExternalDocumentFacade } from '../../facades/external-document-workflow.facade';
import {
  ExternalDocumentDto, ExternalDocumentResponseDto, ResponseStatus
} from "../../models/external-document.model";
import { Router } from '@angular/router';
import { EXTERNAL_DOCUMENT_RESPONSE_ROUTE, EXTERNAL_DOCUMENT_ROUTE } from "src/app/core/constants/routes";
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ExternalDocumentResponseFormComponent } from '../../external-document-response-form/external-document-response-form.component';

interface ExternalDocumentListComponentState {
  externalDocumentsList: PaginatedList<ExternalDocumentDto> | undefined;
  externalDocumentResponses: PaginatedList<ExternalDocumentResponseDto> | undefined;
  totalCount: number;
}

const initialExternalDocumentListComponentState: ExternalDocumentListComponentState =
  {
    externalDocumentsList: undefined,
    externalDocumentResponses: undefined,
    totalCount: 0,
  };

@Component({
  selector: 'app-external-document-list',
  templateUrl: './external-document-list.component.html',
  styleUrls: ['./external-document-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ExternalDocumentListComponent}],
})
export class ExternalDocumentListComponent implements MatPaginatorIntl {
  externalDocumentsList$ = this.state.select('externalDocumentsList');
  externalDocumentsList: PaginatedList<ExternalDocumentDto> | undefined =
    undefined;

  showResponse: boolean = false;

  externalDocumentResponses$ = this.state.select('externalDocumentResponses');
  externalDocumentResponses: PaginatedList<ExternalDocumentResponseDto> | undefined =
    undefined;

  externalDocuments: PaginatedList<ExternalDocumentDto> | undefined;

  pageSize: number = 10;
  pageIndex: number = 0;

  totalCount$ = this.state.select('totalCount');
  totalCount: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@documents.external-document-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.external-document-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.external-document-list.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.external-document-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.external-document-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.external-document-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.external-document-list.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.external-document-list.page-part-two: of` + `${amountPages}`;
  }

  parseAndConvertBodyToString(body: string | undefined | null): string {
    if (body) {
      try {
        const delta = JSON.parse(body);
        return this.convertQuillDeltaToString(delta);
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    }
    return '';
  }
  

  convertQuillDeltaToString(delta: any): string {
    if (delta && delta.ops) {
      return delta.ops.map((op: any) => op.insert).join('');
    }
    return '';
  }

  constructor(
    private externalDocumentFacade: ExternalDocumentFacade,
    private state: RxState<ExternalDocumentListComponentState>,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.state.set(initialExternalDocumentListComponentState);
    this.state.connect(
      'externalDocumentsList',
      this.externalDocumentFacade.externalDocumentsList$,
    );

    this.state.connect(
      'externalDocumentResponses',
      this.externalDocumentFacade.externalDocumentResponses$,
    );
    this.state.connect('totalCount', this.externalDocumentFacade.totalCount$);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.externalDocumentFacade.dispatchGetExternalDocuments(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.externalDocumentsList$.subscribe((externalDocuments) => {
      if (externalDocuments) {
        this.externalDocumentsList = externalDocuments;
        this.externalDocuments = this.externalDocumentsList;
        this.totalCount = this.externalDocumentsList.totalCount;
      }
    });
    this.externalDocumentResponses$.subscribe((externalDocuments) => {
      this.externalDocumentResponses = externalDocuments;
    });
  }

  uploadExternalDocument() {
    this.externalDocumentFacade.dispatchSetFormNotSuccessFul();
    this.externalDocumentFacade.dispatchResetSelectedExternalDocuments()
    this.dialog.open(ExternalDocumentFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        isUpdate: false,
      },
    });
  }



  getExternalDocumentResponses(checkboxEvent: MatCheckboxChange) {
    if (checkboxEvent.checked) {
      this.showResponse = true;
      this.externalDocumentFacade.dispatchGetExternalDocumentResponses(
        this.paginator?.pageIndex + 1 || 1,
        this.paginator?.pageSize || 10,
      );
      this.handlePagination(this.externalDocumentResponses);
      
    } else {
      this.showResponse = false;
      this.externalDocumentFacade.dispatchGetExternalDocuments(
        this.paginator?.pageIndex + 1 || 1,
        this.paginator?.pageSize || 10,
      );

      this.externalDocuments = this.externalDocumentsList;
      this.handlePagination(this.externalDocuments);
    }
    
  }
  handlePagination(
    externalDocument:  any,
  ) {
    if (externalDocument) {
      this.pageIndex = externalDocument.pageNumber - 1;
      this.totalCount = externalDocument.totalCount;
    } else {
      this.totalCount = 0;
      this.pageIndex = 0;
    }
  }

  loadPaginatedExternalDocuments(event: PageEvent) {
    if (this.showResponse)
      this.externalDocumentFacade.dispatchGetExternalDocumentResponses(
        event.pageIndex + 1,
        event.pageSize,
      );
    else
      this.externalDocumentFacade.dispatchGetExternalDocuments(
        event.pageIndex + 1,
        event.pageSize,
      );
  }

  navigateToExternalDocumentDetail(
    externalDocument: ExternalDocumentDto | undefined,
  ) {
    if (!externalDocument) return;
    this.externalDocumentFacade.dispatchSetSelectedExternalDocument(externalDocument)
    this.externalDocumentFacade.dispatchGetExternalDocumentDetail(
      externalDocument.id,
    );
    this.router.navigate([EXTERNAL_DOCUMENT_ROUTE, externalDocument.id]);
  }

  navigateToExternalDocumentResponseDetail(externalDocumentResponse:ExternalDocumentResponseDto) {
    if (!externalDocumentResponse?.id) return;
    this.externalDocumentFacade.dispatchGetExternalDocumentResponseDetail(
      externalDocumentResponse.id,
    );
    
    this.router.navigate([
      `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}/detail`,
      externalDocumentResponse.id,
    ]);
  }

  openDeleteConfirmationDialog(
    externalDocument: ExternalDocumentDto | undefined,
    event: MouseEvent,
  ) {
    event.stopPropagation();
    if (!externalDocument) return;
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: externalDocument!.referenceNumber,
          message:
            $localize`:@@documents.external-document-list.delete-external-document:Delete document from` +
            ` "[${externalDocument!.source}]" `,
          regularTextOne: $localize`:@@documents.external-document-list.delete-external-document-description-part-1:This action is irreversible. Are you sure you want to delete the document` ,
          boldText: ` "${externalDocument!.subject}" ` ,
          regularTextTwo: $localize`:@@documents.external-document-list.delete-external-document-description-part-2:process?`,
          extraTextOne: $localize`:@@documents.external-document-list.delete-external-document-extra-part-1:To confirm, type` ,
          extraTextBold: ` "${externalDocument!.referenceNumber}" ` ,
          extraTextTwo: $localize`:@@documents.external-document-list.delete-external-document-extra-part-2:below:`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.externalDocumentFacade.dispatchDeleteExternalDocument(
          externalDocument.id,
        );
      }
    });
  }

  updateExternalDocument(
    event: MouseEvent,
    externalDocument: ExternalDocumentDto | undefined,
  ) {
    if (!externalDocument) return;
    this.externalDocumentFacade.dispatchSetFormNotSuccessFul();
    event.stopPropagation();
    this.externalDocumentFacade.dispatchSetSelectedExternalDocument(
      externalDocument,
    );
    this.dialog.open(ExternalDocumentFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        isUpdate: true,
      },
    });
  }
  updateExternalDocumentResponse(
    event: MouseEvent,
    externalDocumentResponse: ExternalDocumentResponseDto | undefined,
  ) {
    if (!externalDocumentResponse) return;
    this.externalDocumentFacade.dispatchSetFormNotSuccessFul();
    event.stopPropagation();
    this.externalDocumentFacade.dispatchGetExternalDocumentResponseDetail(
      externalDocumentResponse.id,
    );
    this.router.navigate([EXTERNAL_DOCUMENT_RESPONSE_ROUTE, ""]);
  }

  openDeleteResponseConfirmationDialog(
    externalDocumentResponse: ExternalDocumentResponseDto | undefined,
    event: MouseEvent,
  ) {
    event.stopPropagation();
    
    if (!externalDocumentResponse) return;
    const confirmDialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          
          regularTextOne: $localize`:@@documents.external-document-list.delete-external-document-response-description-part-1:This action is irreversible. Are you sure you want to delete the document` ,
          boldText: ` "${externalDocumentResponse!.subject}" ` ,
          regularTextTwo: $localize`:@@documents.external-document-list.delete-external-document-description-part-2:Response?`,
        },
      },
    );
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.externalDocumentFacade.dispatchDeleteExternalDocumentResponse(
          externalDocumentResponse.id,
        );
      }
    });
  }


  hasGetDocumentResponsesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.GetRespondedExternalDocuments,
    );
  }
  hasCreateExternalDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.UploadExternalDocument,
    );
  }
  hasUpdateExternalDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.UpdateUploadExternalDocument,
    );
  }
  hasUpdateExternalDocumentResponsePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.UpdateExternalDocumentResponse,
    );
  }

  hasDeleteExternalDocumentResponsePermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.DeleteExternalDocumentResponse,
    );
  }

  hasGetExternalDocumentResponseDetailPermission(): string { 
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocumentResponseDetail,
    );
  }

  hasDeleteExternalDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.DeleteExternalDocument,
    );
  }
  hasGetExternalDocumentDetailPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.GetExternalDocumentDetail,
    );
  }

  protected readonly responseStatus = ResponseStatus;
}
