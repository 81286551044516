import {
  FilterWorkflowDto,
  InitiateWorkflowDto,
  Workflow,
} from '../models/workflow.model';

export class InitiateWorkflow {
  static readonly type = `[Workflow] ${InitiateWorkflow.name}`;
  constructor(public readonly initWorkflow: InitiateWorkflowDto) {}
}

export class FilterWorkflows {
  static readonly type = `[Workflow] ${FilterWorkflows.name}`;
  constructor(public readonly filterParams: FilterWorkflowDto) {}
}

export class SetSearch {
  static readonly type = `[Workflow] ${SetSearch.name}`;
  constructor(public readonly isSearch: boolean) {}
}

export class GetWorkflows {
  static readonly type = `[Workflow] ${GetWorkflows.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}
export class GetMyWorkFlows {
  static readonly type = `[Workflow] ${GetMyWorkFlows.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetOwnedWorkFlows {
  static readonly type = `[Workflow] ${GetOwnedWorkFlows.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class SetWorkflowSearchingMode {
  static readonly type = `[Workflow] ${SetWorkflowSearchingMode.name}`;
  constructor(public isSearchingWorkflow: boolean) {}
}

export class SearchWorkflows {
  static readonly type = `[Workflow] ${SearchWorkflows.name}`;
  constructor(
    public workflowName?: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class SetWorkflowSearchTerm {
  static readonly type = `[Workflow] ${SetWorkflowSearchTerm.name}`;
  constructor(public workflowSearchTerm: string) {}
}

export class CancelWorkflow {
  static readonly type = `[Workflow] ${CancelWorkflow.name}`;
  constructor(public workflowId: string) {}
}
export class DownloadWorkflow {
  static readonly type = `[Workflow] ${DownloadWorkflow.name}`;
  constructor(public readonly workflowId: string) {}
}

export class DownloadArchivedWorkflow {
  static readonly type = `[Workflow] ${DownloadArchivedWorkflow.name}`;
  constructor(public readonly workflowId: string) {}
}

export class SetSelectedWorkflow {
  static readonly type = `[Workflow] ${SetSelectedWorkflow.name}`;
  constructor(public readonly workflow: Workflow) {}
}

export class GetArchivedWorkflows {
  static readonly type = `[Workflow] ${GetArchivedWorkflows.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class GetWorkflowFilter {
  static readonly type = `[Workflow] ${GetWorkflowFilter.name}`;
}

export class GetArchivedWorkflowsByStatus {
  static readonly type = `[Workflow] ${GetArchivedWorkflowsByStatus.name}`;
  constructor(
    public readonly status: string,
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class ArchiveWorkflow {
  static readonly type = `[Workflow] ${ArchiveWorkflow.name}`;
  constructor(public workflowId: string) {}
}

export class GetRejectedWorkFlows {
  static readonly type = `[Workflow] ${GetRejectedWorkFlows.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}