import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { FieldValidationRule } from '../models/field-validation-rule.model';
import { ValidationRuleValue } from '../models/validation-rule-value.model';
import { DataType } from '../models/validation-rule.model';
import {
  AddValidationRulesByDataType,
  ClearValidationRulesByDataType,
  CreateFieldValidationRules,
  GetTemplateFieldValidationRules,
  DeleteFieldValidationRules,
  GetValidationRulesByDataType,
  UpdateFieldValidationRule,
  AddValidationRules,
} from '../store/field-validation-rules.actions';
import { FieldValidationRuleSelector } from '../store/field-validation-rules.selector';

@Injectable({
  providedIn: 'root',
})
export class FieldValidationRuleFacade {
  constructor(private store: Store) {}

  dispatchGetTemplateFieldValidationRules(templateFieldId: string, cached: boolean = false) {
    this.store.dispatch(new GetTemplateFieldValidationRules(templateFieldId));
  }

  dispatchGetFieldValidationRulesByDataType(dataType: DataType) {
    return this.store.dispatch(new GetValidationRulesByDataType(dataType));
  }

  dispatchAddVaidationRule(fieldValidationRule: FieldValidationRule) {
    this.store.dispatch(new AddValidationRulesByDataType(fieldValidationRule));
  }

  dispatchAddValidationRules(fieldValidationRule: FieldValidationRule[]) {
    this.store.dispatch(new AddValidationRules(fieldValidationRule));
  }

  dispatchClearVaidationRule() {
    return this.store.dispatch(new ClearValidationRulesByDataType());
  }

  dispatchCreateFieldValidationRules(templateFieldId: string) {
    return this.store.dispatch(new CreateFieldValidationRules(templateFieldId));
  }

  dispatchUpdateFieldValidationRule(
    index: number,
    fieldValidationRule: FieldValidationRule,
  ) {
    this.store.dispatch(
      new UpdateFieldValidationRule(index, fieldValidationRule),
    );
  }

  dispatchDeleteFieldValidationRules(fieldValidationRule: FieldValidationRule) {
    this.store.dispatch(new DeleteFieldValidationRules(fieldValidationRule));
  }

  fieldValidationRules$ = this.store.select(
    FieldValidationRuleSelector.slices.ValidationRules,
  );

  currentFieldValidationRules$ = this.store.select(
    FieldValidationRuleSelector.slices.currentFieldValidationRules,
  );
}
