import { Select } from '@ngxs/store';
import { Process } from '../models/process.model';
import { PaginatedList } from '../../core/models/paginated-list.interface';

export class SetUpdateStatus {
  static readonly type = `[Process] ${SetUpdateStatus}`;
  constructor(public updateStatus: boolean) {}
}

export class UpdateProcess {
  static readonly type = `[Process] ${UpdateProcess}`;
  constructor(public process: Process) {}
}

export class GetPaginatedProcesses {
  static readonly type = `[Process] ${GetPaginatedProcesses.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class CreateProcess {
  static readonly type = `[Process] ${CreateProcess.name}`;
  constructor(public process: Process) {}
}

export class SetSelectedProcess {
  static readonly type = `[Process] ${SetSelectedProcess.name}`;
  constructor(public process: Process) {}
}
export class DeleteProcess {
  static readonly type = `[Process] ${DeleteProcess.name}`;
  constructor(public processId: string) {}
}

export class ResetSelectedProcess {
  static readonly type = `[ProgressStatus] ${ResetSelectedProcess.name}`;
}

export class SearchProcess {
  static readonly type = `[Process] ${SearchProcess.name}`;
  constructor(
    public searchTerm: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}
export class GetProcessCategories {
  static readonly type = `[Process] ${GetProcessCategories.name}`;
  constructor() {}
}

export class SetProcessListPageIndex {
  static readonly type = `[Process] ${SetProcessListPageIndex.name}`;
  constructor(public pageIndex: number) {}
}

export class SetProcessListPageSize {
  static readonly type = `[Process] ${SetProcessListPageSize.name}`;
  constructor(public pageSize: number) {}
}

export class SetProcessListSearchTerm {
  static readonly type = `[Process] ${SetProcessListSearchTerm.name}`;
  constructor(public searchTerm: string) {}
}
