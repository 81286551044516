import { Component, OnInit } from '@angular/core';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { Observable } from 'rxjs';
import { OfficeFacade } from '../../facades/office.facades';
import { RxState } from '@rx-angular/state';
import {
  IUpdateOfficeStructure,
  Office,
  PathUpdateType,
} from '../../models/office.model';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OfficeTreeComponent } from '../office-tree/office-tree.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface UpdateOfficeStructureFormComponentState {
  flatOfficeNodes: FlatOfficeNode[];
  selectedFlatOfficeNode: FlatOfficeNode;
  offices: Office[];
}

const initUpdateOfficeStructureFormComponentState = {};

@Component({
  selector: 'app-update-office-structure-form',
  templateUrl: './update-office-structure-form.component.html',
  styleUrls: ['./update-office-structure-form.component.scss'],
})
export class UpdateOfficeStructureFormComponent implements OnInit {
  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  flatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.state.select('flatOfficeNodes');
  flatOfficeNodes: FlatOfficeNode[] = [];

  pathUpdateTypesForDisplay: { [key: number]: string } = {
    [PathUpdateType.OFFICEONLY]: $localize`:@@offices.update-office-structure-form.office-only: Office only`,
    [PathUpdateType.WITHCHILDREN]: $localize`:@@offices.update-office-structure-form.with-children: With Children`,
    [PathUpdateType.WITHDESCENDANTS]: $localize`:@@offices.update-office-structure-form.with-descendants: With Decsendants`,
  };
  

  pathUpdateTypes = {
    'Office only': PathUpdateType.OFFICEONLY,
    'With Children': PathUpdateType.WITHCHILDREN,
    'With Decsendants': PathUpdateType.WITHDESCENDANTS,
  };
  
  defaultUpdateType: PathUpdateType = PathUpdateType.WITHDESCENDANTS;
  parentForm;
  previousSelected: FlatOfficeNode | null = null;
  toBeEdited: FlatOfficeNode | undefined;
  offices$: Observable<Office[]> = this.state.select('offices');
  offices: Office[] = [];

  constructor(
    private state: RxState<UpdateOfficeStructureFormComponentState>,
    private officeFacade: OfficeFacade,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
  ) {
    this.state.set(initUpdateOfficeStructureFormComponentState);
    this.state.connect('flatOfficeNodes', this.officeFacade.flatOfficeNodes$);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect('offices', this.officeFacade.offices$);

    this.parentForm = this.formBuilder.group({
      pathUpdateType: this.pathUpdateTypes['With Decsendants'],
    });
  }

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe((officeNode) => {
      this.selectedFlatOfficeNode = officeNode;
    });

    this.offices$.subscribe((offs) => {
      if (offs) this.offices = offs;
    });

    this.flatOfficeNodes$.subscribe((officeNodes) => {
      if (officeNodes) this.flatOfficeNodes = officeNodes;
    });
    this.toBeEdited = this.selectedFlatOfficeNode;
    if (this.toBeEdited && !!this.getParentNode(this.toBeEdited)) {
      this.officeFacade.dispatchSelectFlatOfficeNode(
        this.getParentNode(this.toBeEdited) ?? undefined,
      );
    }
  }

  getPathLabel(node: FlatOfficeNode) {
    let office = this.offices.find((off) => off.id === node.id);
    if (office) return office.pathLabel;

    return '';
  }
  
  openSingleOffice() {
    this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { update: true, parentOffice: this.toBeEdited },
    });
  }

  removeSelectedFlatOfficeNode() {
    if (this.selectedFlatOfficeNode === undefined) return;
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(this.selectedFlatOfficeNode);
  }

  getParentNode(node: FlatOfficeNode) {
    const nodeIndex = this.flatOfficeNodes.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.flatOfficeNodes[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.flatOfficeNodes[i];
      }
    }

    return null;
  }

  save() {
    const { valid, touched, dirty } = this.parentForm;
    if (
      this.toBeEdited &&
      valid &&
      this.getParentNode(this.toBeEdited) !== this.selectedFlatOfficeNode
    ) {
      let updateStructure: IUpdateOfficeStructure = {
        id: this.toBeEdited.id,
        pathFromRoot:
          this.selectedFlatOfficeNode!.pathFromRoot +
          '.' +
          this.getPathLabel(this.toBeEdited),
        parentPathFromRoot: this.selectedFlatOfficeNode?.pathFromRoot ?? '',
        updateType:
          this.parentForm.value.pathUpdateType ?? this.defaultUpdateType,
      };
      this.officeFacade.dispatchUpdateOfficeStructure(updateStructure);
    }
  }

  hasUpdateOfficeTreePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UpdateOfficeTree,
    );
  }
}
