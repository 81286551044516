import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProcessDetail } from '../models/process-detail.model';
import {
  DeleteProcessDetail,
  GetProcessDetail,
  SetSelectedProcessDetail,
} from '../store/process-detail.actions';
import { ProcessDetailSelector } from '../store/process-detail.selector';
import { Process } from '../models/process.model';

@Injectable({
  providedIn: 'root',
})
export class ProcessDetailFacade {
  constructor(private store: Store) {}

  @Select(ProcessDetailSelector.selectedProcessDetail)
  selectedProcessDetail$!: Observable<ProcessDetail>;

  dispatchGetProcessDetail(processId: string) {
    this.store.dispatch(new GetProcessDetail(processId));
  }
  dispatchDeleteProcess(processId: string) {
    this.store.dispatch(new DeleteProcessDetail(processId));
  }
  dispatchSetSelectedProcess(process: Process) {
    this.store.dispatch(new SetSelectedProcessDetail(process));
  }
}
