import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { combineLatest, map, startWith, take } from 'rxjs';
import { RESEARCH_OWNER_ROLES } from 'src/app/core/constants/requirement_constants';
import {
  ARCHIVED_ROUTE,
  PROCESS_INSTANCE_LIST_ROUTE,
  TASK_DOCUMENTS_ROUTE,
  TASK_LIST_ROUTE,
} from 'src/app/core/constants/routes';
import { AssignTaskDto, Task } from 'src/app/researches/models/task.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { TaskComment, TaskStatus } from 'src/app/researches/models/task.model';
import { TaskDetail } from 'src/app/researches/models/taskDetail.model';
import { ConfirmGenericDialogComponent } from 'src/app/shared/shared-components/confirm-generic-dialog/confirm-generic-dialog.component';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { Role } from 'src/app/users/models/role.model';
import { CurrentLoggedInUser, User } from 'src/app/users/models/user.model';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { TaskDetailFrom } from 'src/app/researches/store/task.state';
import {
  ProcessInstance,
  ProcessInstanceDetail,
} from 'src/app/researches/models/process-instance.model';
import { ProcessTasks } from 'src/app/researches/models/process-tasks.model';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { StageInstanceTask } from 'src/app/researches/models/stage-instance-detail.model';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  faFile,
  faFileWord,
  faFilePdf,
  faFileLines,
  faImage,
  faFileVideo,
  faFileExcel,
  faFileAudio,
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

interface TaskDetailComponentState {
  taskDetailFrom: TaskDetailFrom;
  taskDetail?: TaskDetail;
  myTasks: ProcessTasks[];
  processInstanceDetail: ProcessInstanceDetail | undefined;
  fileIcons: any | undefined;
  taskFiles: any | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

interface RoleState {
  roles: Role[];
}
interface FilteredUsersState {
  users: User[];
}

interface StageInstanceDetailState {
  stageInstanceTasks?: PaginatedList<StageInstanceTask>;
}
interface TaskCommentComponentState {
  taskComments?: PaginatedList<TaskComment> | undefined;
  selectedTaskComment?: TaskComment | null;
  isEditTaskComment: boolean;
  isReplyTaskComment: boolean;
}

const initTaskDetailComponentState: Partial<TaskDetailComponentState> = {
  taskDetailFrom: TaskDetailFrom.NONE,
  myTasks: [],
  processInstanceDetail: undefined,
  fileIcons: undefined,
  taskFiles: undefined,
  currentLoggedInUser: undefined,
};

const initStageInstanceDetailState: StageInstanceDetailState = {
  stageInstanceTasks: undefined,
};

const initTaskCommentComponentState: TaskCommentComponentState = {
  taskComments: undefined,
  selectedTaskComment: null,
  isEditTaskComment: false,
  isReplyTaskComment: false,
};
const initFilteredUsersState: Partial<FilteredUsersState> = {
  users: [],
};

const initRoleState: Partial<RoleState> = {
  roles: [],
};

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
})
export class TaskDetailComponent {
  TaskStatus = TaskStatus;
  composingOption: string = 'compose';
  isTaskInfoHidden: boolean = false;
  statuses: TaskStatus[] = [TaskStatus.Todo, TaskStatus.InProgress];
  isStatusDropdownOpen: boolean = false;
  names: string[] = [];
  selectedName: string = '';
  filteredNames: string[] = [];
  nameControl = new FormControl();

  taskDetail$ = this.state.select('taskDetail');
  taskDetail?: TaskDetail;
  roles$ = this.roleState.select('roles');
  roles: Role[] = [];

  taskDetailFrom$ = this.state.select('taskDetailFrom');
  taskDetailFrom: TaskDetailFrom = TaskDetailFrom.NONE;

  users$ = this.userState.select('users');
  users: User[] = [];

  taskComments$ = this.taskCommentState.select('taskComments');
  taskComments: PaginatedList<TaskComment> | undefined = undefined;

  myTasks$ = this.state.select('myTasks');
  myTasks: ProcessTasks[] = [];

  stageInstanceTasks?: PaginatedList<StageInstanceTask>;
  stageInstanceTasks$ =
    this.stageInstanceDetailState.select('stageInstanceTasks');

  selectedTaskComment?: TaskComment | null;
  selectedTaskComment$ = this.taskCommentState.select('selectedTaskComment');

  isEditTaskComment$ = this.taskCommentState.select('isEditTaskComment');
  isEditTaskComment = false;

  isReplyTaskComment$ = this.taskCommentState.select('isReplyTaskComment');
  isReplyTaskComment = false;

  stageInstanceDetailId: string = '';
  processInstanceId: string = '';
  taskList: Task[] = [];

  selectedProcessInstanceId: string = '';
  selectedStageInstanceDetailId: string = '';

  commentForm: FormGroup;
  assigneeForm: FormGroup;
  currentTaskIndex = 0;
  toggleReplyBoxVisibility: boolean = false;

  processInstanceDetail$ = this.taskDetailstate.select('processInstanceDetail');
  processInstanceDetail: ProcessInstanceDetail | undefined;

  isAssigningTask: boolean = false;

  currentLoggedInUser$ = this.taskDetailstate.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  placeholderToggleLabel = {
    addComment: $localize`:@@researches.task-detail.add-comment: Add Comment`,
  };

  @ViewChild('assigneeInput') assigneeInput!: ElementRef;

  fileIcons: any = {
    doc: faFileWord,
    docx: faFileWord,
    pdf: faFilePdf,
    png: faImage,
    jpg: faImage,
    file: faFile,
    xlsx: faFileExcel,
    xls: faFileExcel,
    txt: faFileLines,
    mp4: faFileVideo,
    ogg: faFileVideo,
    webm: faFileVideo,
    mp3: faFileAudio,
    m4a: faFileAudio,
    wav: faFileAudio,
  };

  taskFiles$ = this.state.select('taskFiles');
  taskFiles: any[] | undefined;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private taskFacade: TaskFacade,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private userState: RxState<FilteredUsersState>,
    private stageInstanceDetailState: RxState<StageInstanceDetailState>,
    private roleState: RxState<RoleState>,
    private state: RxState<TaskDetailComponentState>,
    private matDialog: MatDialog,
    private taskCommentState: RxState<TaskCommentComponentState>,
    private taskDetailstate: RxState<TaskDetailComponentState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private dialog: MatDialog,
  ) {
    this.taskFacade.dispatchResetTaskDetail();
    this.state.set(initTaskDetailComponentState);
    this.taskCommentState.set(initTaskCommentComponentState);
    this.state.connect('taskDetail', taskFacade.taskDetail$);
    this.userState.set(initFilteredUsersState);
    this.userState.connect('users', this.userFacade.users$);
    this.state.connect('myTasks', this.taskFacade.myTasks$);
    this.state.connect('taskDetailFrom', this.taskFacade.taskDetailFrom$);
    this.stageInstanceDetailState.connect(
      'stageInstanceTasks',
      this.stageInstanceDetailFacade.stageInstanceTasks$,
    );
    this.roleState.set(initRoleState);
    this.roleState.connect('roles', this.roleFacade.roles$);
    this.nameControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterNames(value)),
      )
      .subscribe((filteredNames) => {
        this.filteredNames = filteredNames;
      });

    this.stageInstanceTasks$.subscribe((tasks) => {
      this.stageInstanceTasks = tasks;
    });

    this.myTasks$.subscribe((myTasks) => {
      this.myTasks = myTasks;
    });
    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });
    this.assigneeForm = this.fb.group({
      assignee: [''],
    });
    this.taskCommentState.connect('taskComments', taskFacade.taskComments$);
    this.taskCommentState.connect(
      'selectedTaskComment',
      taskFacade.selectedTaskComment$,
    );
    this.taskDetailstate.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );

    this.nameControl.setValue(this.selectedName);
    this.nameControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterNames(value)),
      )
      .subscribe((filteredNames) => {
        this.filteredNames = filteredNames;
      });

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });

    this.state.connect('taskFiles', taskFacade.taskFiles$);
    this.taskDetailstate.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.taskFacade.dispatchGetTaskDetail(params['id']);
      this.stageInstanceDetailId = params['stageId'];
      this.processInstanceId = params['processId'];
      this.taskFacade.dispatchGetPaginatedTaskComments(params['id'], 1, 5);
      this.taskFacade.dispatchGetTaskFiles(params['id']);
    });

    this.taskDetail$.subscribe((taskDetail) => {
      this.selectedName = taskDetail!.assigneeName;
      this.taskDetail = taskDetail;
      this.assigneeForm.patchValue({
        assignee: taskDetail?.assigneeName,
      });
      this.nameControl.setValue(this.selectedName);
    });

    this.taskFiles$.subscribe((files) => {
      this.taskFiles = files;
    });

    this.taskDetailFrom$.subscribe((taskDetailFrom) => {
      this.taskDetailFrom = taskDetailFrom;
      if (this.taskDetailFrom === TaskDetailFrom.STAGE_INSTANCE)
        this.taskList = this.stageInstanceTasks?.items ?? [];
      else if (this.taskDetailFrom === TaskDetailFrom.MY_TASKS)
        this.taskList = this.myTasks.filter((pTask) => {
          return pTask.id === this.processInstanceId;
        })[0].tasks;
    });

    this.userFacade.dispatchGetUsers();

    this.users$.subscribe((users) => {
      this.users = users;
    });

    this.users.map((item) =>
      this.names.push(
        item.firstName + ' ' + item.middleName + ' ' + item.lastName,
      ),
    );

    this.taskComments$.subscribe((taskComments) => {
      this.taskComments = taskComments;
    });
    this.selectedTaskComment$.subscribe((selectedTaskComment) => {
      this.selectedTaskComment = selectedTaskComment;
    });

    this.processInstanceDetail$.subscribe((processIntance) => {
      this.processInstanceDetail = processIntance;
    });

    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
  }

  toggleTaskInfo() {
    this.isTaskInfoHidden = !this.isTaskInfoHidden;
  }
  toggleStatusDropdown() {
    this.isStatusDropdownOpen = !this.isStatusDropdownOpen;
  }
  onDropdownClosed() {
    this.isStatusDropdownOpen = false;
  }

  selectStatus(status: TaskStatus) {
    var updatedTask = { id: this.taskDetail?.id, taskStatus: status };
    this.taskFacade.dispatchChangeTaskStatus(updatedTask);
  }

  onNameSelected(event: any) {
    this.filteredNames = [];
    this.selectedName = event.option.value;
    this.nameControl.setValue(this.selectedName);
    const assignedResearcher = this.users.find(
      (item) => item.fullName === this.selectedName,
    );
    const assignTask: AssignTaskDto = {
      id: this.taskDetail!.id,
      assignedTo: assignedResearcher!.id!,
    };
    this.taskFacade
      .dispatchAssignTask(assignTask)
      .pipe(take(1))
      .subscribe(() => {
        this.taskDetail?.id &&
          this.taskFacade.dispatchGetTaskDetail(this.taskDetail.id);
      });
  }

  displayAssigneeName(name: string): string {
    return name ? name : '';
  }
  private _filterNames(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.names.filter((name) =>
      name.toLowerCase().includes(filterValue),
    );
  }
  filterNames() {
    const filterValue = this.nameControl.value.toLowerCase();
    this.filteredNames = this.names.filter((name) =>
      name.toLowerCase().includes(filterValue),
    );
  }
  selectName(name: string) {
    this.selectedName = name;
  }
  toggleNameDropdown() {
    if (this.nameControl.value) {
      this.filteredNames = this.names;
    }
    this.nameControl.setValue(null);
  }
  next(taskList: Task[], processId?: string, stageId?: string) {
    this.currentTaskIndex++;
    const currentIndex = taskList.findIndex(
      (task) => task.id === this.taskDetail!.id,
    );
    if (currentIndex === -1) {
      return;
    }
    const nextIndex = currentIndex + 1;
    this.currentTaskIndex = nextIndex;
    if (nextIndex < taskList!.length) {
      this.router.navigate([
        `${TASK_LIST_ROUTE}`,
        taskList[nextIndex].id,
        { processId, stageId },
      ]);
    }
  }
  goToNextTask() {
    if (
      this.taskDetailFrom === TaskDetailFrom.MY_TASKS &&
      this.processInstanceId !== undefined
    ) {
      this.taskList = this.myTasks.filter((pTask) => {
        return pTask.id === this.processInstanceId;
      })[0].tasks;
      this.next(this.taskList, this.processInstanceId, '');
    } else if (
      this.taskDetailFrom === TaskDetailFrom.STAGE_INSTANCE &&
      this.stageInstanceDetailId !== undefined
    ) {
      if (!this.stageInstanceTasks?.items) return;
      this.taskList = this.stageInstanceTasks?.items as Task[];
      this.next(this.taskList, '', this.stageInstanceDetailId);
    }
  }

  prev(taskList: Task[], processId?: string, stageId?: string) {
    this.currentTaskIndex--;
    const currentIndex = taskList.findIndex(
      (task) => task.id === this.taskDetail!.id,
    );
    if (currentIndex === -1) {
      return;
    }
    const nextIndex = currentIndex - 1;
    this.currentTaskIndex = nextIndex;
    if (nextIndex >= 0) {
      this.router.navigate([
        `${TASK_LIST_ROUTE}`,
        taskList[nextIndex].id,
        { processId, stageId },
      ]);
    }
  }

  goToPreviousTask() {
    if (
      this.taskDetailFrom === TaskDetailFrom.MY_TASKS &&
      this.processInstanceId !== undefined
    ) {
      this.taskList = this.myTasks.filter((pTask) => {
        return pTask.id === this.processInstanceId;
      })[0].tasks;

      this.prev(this.taskList, this.processInstanceId, '');
    } else if (
      this.taskDetailFrom === TaskDetailFrom.STAGE_INSTANCE &&
      this.stageInstanceDetailId !== undefined
    ) {
      if (!this.stageInstanceTasks?.items) return;
      this.taskList = this.stageInstanceTasks?.items as Task[];
      this.prev(this.taskList, '', this.stageInstanceDetailId);
    }
  }

  submitTask(isOwner: boolean) {
    const dialogRef = this.matDialog.open(ConfirmGenericDialogComponent, {
      data: {
        title: $localize`:@@researches.task-detail.submit-task-title: Confirm Task Submit`,
        regularTextOne: $localize`:@@researches.task-detail.submit-task-message-part-1: Are you sure you want to` ,
        boldText: ` ${isOwner ? 'finish' : 'submit'} ` ,
        regularTextTwo: $localize`:@@researches.task-detail.submit-task-message-part-2:your task ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'confirm') {
        var updatedTask = {
          id: this.taskDetail?.id,
          taskStatus: isOwner ? TaskStatus.Done : TaskStatus.Submitted,
          isOwner,
        };

        this.taskFacade.dispatchChangeTaskStatus(updatedTask);
      }
    });
  }

  approveTask() {
    const dialogRef = this.matDialog.open(ConfirmGenericDialogComponent, {
      data: {
        title: $localize`:@@researches.task-detail.confirm-task-approval: Confirm Task Approval`,
        regularTextOne: $localize`:@@researches.task-detail.confirm-task-message: Are you sure you want to approve this task?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        var updatedTask = {
          id: this.taskDetail?.id,
          taskStatus: TaskStatus.Approved,
        };
        this.taskFacade.dispatchChangeTaskStatus(updatedTask);
      }
    });
  }

  addComment() {
    const { valid, touched, dirty } = this.commentForm;
    if (valid && (touched || dirty)) {
      this.taskFacade.dispatchAddTaskComment(
        this.taskDetail?.id!,
        this.commentForm.value.comment,
      );
      this.commentForm.reset();
    }
  }
  RejectTask() {
    const dialogRef = this.matDialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        name: '',
        message: $localize`:@@researches.task-detail.needs-revision-task-message: Task Needs Revision`,
        regularTextOne: $localize`:@@researches.task-detail.needs-revision-task-description-part-1: Are you sure you want to give a comment` ,
        boldText: ` ${this.taskDetail?.name} ` ,
        regularTextTwo: $localize`:@@researches.task-detail.needs-revision-task-description-part-2: task?`,
        extra: `Write your reason for marking it needs revision`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        var updatedTask = {
          id: this.taskDetail?.id,
          taskStatus: TaskStatus.Rejected,
        };
        this.taskFacade.dispatchAddTaskComment(
          this.taskDetail?.id!,
          result.comment,
        );
        this.taskFacade.dispatchChangeTaskStatus(updatedTask);
      }
    });
  }

  getPaginatedComments() {
    if (
      this.taskComments?.pageNumber === this.taskComments?.totalPages ||
      this.taskComments?.totalPages === 0
    ) {
      this.taskFacade.dispatchGetPaginatedTaskComments(
        this.taskDetail!.id!,
        1,
        5,
      );
    } else {
      this.taskFacade.dispatchGetPaginatedTaskComments(
        this.taskDetail!.id!,
        1,
        this.taskComments!.items.length + 5,
      );
    }
  }

  toggleReplyBox() {
    this.toggleReplyBoxVisibility = true;
  }
  cancelReplyBox() {
    this.toggleReplyBoxVisibility = false;
  }

  checkTaskStatus(status: any) {
    if (status === TaskStatus.Todo) {
      return $localize`:@@researches.task-detail.task-status-to-do: To Do`;
    } else if (status === TaskStatus.InProgress) {
      return $localize`:@@researches.task-detail.task-status-in-progress: In Progress`;
    } else if (status === TaskStatus.Submitted) {
      return $localize`:@@researches.task-detail.task-status-submitted: Submitted`;
    } else if (status === TaskStatus.Rejected) {
      return $localize`:@@researches.task-detail.task-status-needs-revision: Needs Revision`;
    } else if (status === TaskStatus.Done) {
      return $localize`:@@researches.task-detail.task-status-done: Done`;
    } else {
      return $localize`:@@researches.task-detail.task-status-approved: Approved`;
    }
  }

  getTaskStatusColor(status: any) {
    if (status === TaskStatus.Todo) {
      return 'gray';
    } else if (status === TaskStatus.InProgress) {
      return 'yellow';
    } else if (status === TaskStatus.Submitted) {
      return 'cyan';
    } else if (status === TaskStatus.Rejected) {
      return 'red';
    } else {
      return 'green';
    }
  }

  getIconForFile(fileName: string): any {
    const extension = fileName.split('.').slice(-1)[0].toLowerCase();
    const iconInfo = this.fileIcons[extension];
    return iconInfo ? iconInfo : this.fileIcons.file;
  }

  removeTaskFile(file: any) {
    this.openConfirmationDialog(file);
    this.taskFacade.dispatchGetTaskFiles(this.taskDetail?.id as string);
  }

  openConfirmationDialog(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.task-detail.delete-file-message: Are you sure you want to delete` ,
        boldText:  ` "${file?.name}"? `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.taskFacade.dispatchDeleteFileTask(file.id);
      }
    });
  }

  extractFileNameAndExtension(fileName: string): any {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex !== -1) {
      const name = fileName.substring(0, lastIndex);
      const extension = fileName.substring(lastIndex + 1);
      return { name, extension };
    } else {
      return { name: fileName, extension: '' };
    }
  }

  isOwnersTask(): boolean {
    return (
      this.taskDetail?.assignee?.id == this.taskDetail?.processInstance.ownerId
    );
  }

  isOwnersActionApprove(): boolean {
    if (
      this.taskDetail?.assignee?.id !=
        this.taskDetail?.processInstance.ownerId &&
      this.taskDetail?.processInstance.ownerId == this.currentLoggedInUser?.id
    )
      return true;
    return false;
  }

  isOwnersActionDone(): boolean {
    if (
      this.taskDetail?.assignee?.id ==
        this.taskDetail?.processInstance.ownerId &&
      this.taskDetail?.processInstance.ownerId == this.currentLoggedInUser?.id
    ) {
      return true;
    }

    return false;
  }

  toggleIsAssigningTask() {
    if (
      [
        TaskStatus.Approved,
        TaskStatus.Done,
        TaskStatus.Rejected,
        undefined,
      ].includes(this.taskDetail?.taskStatus)
    )
      return;
    if (
      this.taskDetail?.createdById == this.currentLoggedInUser?.id ||
      this.currentLoggedInUser?.id === this.processInstanceDetail?.ownerId
    ) {
      this.isAssigningTask = !this.isAssigningTask;
      setTimeout(() => {
        this.assigneeInput.nativeElement.focus();
      }, 20);
    }
  }

  handleAssigneeBlurAndKeyup() {
    setTimeout(() => {
      this.toggleIsAssigningTask();
    }, 200);
  }

  viewDocument() {
    if (!this.taskDetail) return;
    this.processInstanceDetail?.isArchived
      ? this.router.navigate([
          `${ARCHIVED_ROUTE}/${TASK_DOCUMENTS_ROUTE}`,
          this.taskDetail.id,
          { taskId: this.taskDetail.id },
        ])
      : this.router.navigate([
          `${PROCESS_INSTANCE_LIST_ROUTE}/${TASK_DOCUMENTS_ROUTE}`,
          this.taskDetail.id,
          { taskId: this.taskDetail.id },
        ]);
  }

  hasCreateDocumentTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.DocumentTask.Feature,
      PERMISSION_NAMES.Researches.DocumentTask.CreateDocumentTask,
    );
  }

  hasCreateFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.CreateFileTask,
    );
  }

  hasDeleteFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.DeleteFileTask,
    );
  }

  hasGetFileTasksByTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTasksByTask,
    );
  }

  hasGetTaskCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.GetTaskComments,
    );
  }

  hasCreateTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.CreateTaskComment,
    );
  }

  hasChangeStatusToSubmittedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToSubmitted,
    );
  }
  hasChangeStatusToTodoPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToTodo,
    );
  }

  hasChangeStatusToInProgressPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToInProgress,
    );
  }

  hasAssignTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.AssignTask,
    );
  }

  hasChangeStatusToApprovedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToApproved,
    );
  }
  hasChangeStatusToRejectedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToNeedsRevision,
    );
  }

  get isCurrentUserAssignee() {
    return combineLatest([this.currentLoggedInUser$, this.taskDetail$]).pipe(
      map(([user, task]) => user?.id === task?.assignee?.id),
    );
  }
}
