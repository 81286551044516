import { CriteriaFormComponent } from './components/stage/criteria-form/criteria-form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResearchesHomeComponent } from './components/researches-home/researches-home.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import {
  PROCESS_INSTANCE_LIST_ROUTE,
  TASK_LIST_ROUTE,
  PROCESS_LIST_ROUTE,
  STAGE_INSTANCES_ROUTE,
  TASK_DOCUMENTS_ROUTE,
  ARCHIVED_ROUTE,
  RESEARCH_COUNCIL_ROUTE,
  ASSIGNED_TASKS_ROUTE,
  TOR_SETTINGS_ROUTE,
  TOR_ROUTE,
  ASSIGNED_REVIEWS_ROUTE,
  TOR_INITIATE_ROUTE,
  TOR_DETAIL_ROUTE,
  PUBLICATION_ROUTE,
  PUBLICATION_DETAIL,
  RESEARCHES_SETTINGS_ROUTE,
} from '../core/constants/routes';
import { ProcessListComponent } from './components/process/process-list/process-list.component';
import { ViewStageCriteriaComponent } from './components/process/view-stage-criteria/view-stage-criteria.component';
import { ProcessDetailComponent } from './components/process/process-detail/process-detail.component';
import { StageInstanceDetailComponent } from './components/stage/stage-instance-detail/stage-instance-detail.component';
import { ProcessInstanceListComponent } from './components/process-instance/process-instance-list/process-instance-list.component';
import { ProcessInstanceDetailComponent } from './components/process-instance/process-instance-detail/process-instance-detail.component';
import { TaskDetailComponent } from './components/task/task-detail/task-detail.component';
import { TaskDocumentListComponent } from './components/task/task-document-list/task-document-list.component';
import { ArchivedProcessInstanceListComponent } from './components/archived-process-instance-list/archived-process-instance-list.component';
import { AssignedTasksComponent } from './components/task/assigned-tasks/assigned-tasks.component';
import { AssignedTaskDetailComponent } from './components/task/assigned-task-detail/assigned-task-detail.component';
import { TorSettingsComponent } from './tor-settings/components/tor-settings/tor-settings.component';
import { RequestedReviewsComponent } from './components/reviews/requested-reviews/requested-reviews.component';
import { ReviewDetailComponent } from './components/reviews/review-detail/review-detail.component';
import { TorListComponent } from './tor/components/tor-list/tor-list.component';
import { TorDetailComponent } from './tor/components/tor-detail/tor-detail.component';
import { PublicationOptionsComponent } from './components/publication-options/publication-options.component';
import { ResearchDashboardHomeComponent } from './dashboard/components/research-dashboard-home/research-dashboard-home.component';
import { PublicationDetailComponent } from './components/publication/publication-detail/publication-detail.component';
import { PublicationListComponent } from './components/publication/publication-list/publication-list.component';
import {
  HasFeaturePermissionGuard,
  HasPermissionGuard,
} from '../auth/services/has.permission.guard';
import { MODULES, PERMISSION_NAMES } from '../core/constants/permissions';
import { ResearchesSettingsComponent } from './components/researches-settings/researches-settings.component';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: $localize`:@@researches.researches-routing.research: Research`,
    },
    component: ResearchesHomeComponent,
    children: [
      {
        path: PROCESS_LIST_ROUTE,
        component: ProcessListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.process-types: Process Types`,
          feature: PERMISSION_NAMES.Researches.Process.Feature,
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Process.Feature}:${PERMISSION_NAMES.Researches.Process.GetProcesses}`,
        },
        canActivate: [HasPermissionGuard],
      },
      {
        path: `${PROCESS_LIST_ROUTE}/stage/:id`,
        component: ViewStageCriteriaComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.stage-criteria: Stage Criteria`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Criterion.Feature}:${PERMISSION_NAMES.Researches.Criterion.GetCriterion}`,
        },
      },
      {
        path: `${PROCESS_LIST_ROUTE}/:id`,
        component: ProcessDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.process-type-detail: Process Type Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Process.Feature}:${PERMISSION_NAMES.Researches.Process.GetProcess}`,
        },
      },
      {
        path: 'criterion',
        component: CriteriaFormComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Criterion.Feature}:${PERMISSION_NAMES.Researches.Criterion.GetCriterion}`,
        },
      },
      {
        path: `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}/:id`,
        component: StageInstanceDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.stage-instance-detail: Stage Instance Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.StageInstance.Feature}:${PERMISSION_NAMES.Researches.StageInstance.GetStageInstanceDetail}`,
        },
      },
      {
        path: `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}/task-detail/:id`,
        component: TaskDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-detail: Task Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetTaskDetail}`,
        },
      },
      {
        path: TASK_LIST_ROUTE,
        component: TaskListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.my-tasks: My Tasks`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetProcessTask}`,
        },
      },
      {
        path: `${PROCESS_INSTANCE_LIST_ROUTE}`,
        component: ProcessInstanceListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.process-instances: Process Instances`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.ProcessInstance.Feature}:${PERMISSION_NAMES.Researches.ProcessInstance.GetProcessInstances}`,
        },
      },
      {
        path: `${PROCESS_INSTANCE_LIST_ROUTE}/:id`,
        component: ProcessInstanceDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.process-intasnce-detail: Process Instance Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.ProcessInstance.Feature}:${PERMISSION_NAMES.Researches.ProcessInstance.GetProcessInstance}`,
        },
      },
      {
        path: `${TASK_LIST_ROUTE}/:id`,
        component: TaskDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-detail: Task Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetTaskDetail}`,
        },
      },
      {
        path: `${PROCESS_INSTANCE_LIST_ROUTE}/${TASK_DOCUMENTS_ROUTE}/:id}`,
        component: TaskDocumentListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-documents: Task Documents`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.DocumentTask.Feature}:${PERMISSION_NAMES.Researches.DocumentTask.GetDocumentTaskByTask}`,
        },
      },
      {
        path: `${ARCHIVED_ROUTE}`,
        component: ArchivedProcessInstanceListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.archived: Archived`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.ProcessInstance.Feature}:${PERMISSION_NAMES.Researches.ProcessInstance.GetArchivedProcessInstances}`,
        },
      },
      {
        path: `${ARCHIVED_ROUTE}/:id`,
        component: ProcessInstanceDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.archived-process-instance: Archived Process Instance`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.ProcessInstance.Feature}:${PERMISSION_NAMES.Researches.ProcessInstance.GetProcessInstance}`,
        },
      },
      {
        path: `${ARCHIVED_ROUTE}/${STAGE_INSTANCES_ROUTE}/:id`,
        component: StageInstanceDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.archived-stage-instance-detail: Archived Stage Instance Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.StageInstance.Feature}:${PERMISSION_NAMES.Researches.StageInstance.GetStageInstanceDetail}`,
        },
      },
      {
        path: `${ARCHIVED_ROUTE}/${STAGE_INSTANCES_ROUTE}/task-detail/:id`,
        component: TaskDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-detail: Task Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetTaskDetail}`,
        },
      },
      {
        path: `${ARCHIVED_ROUTE}/${TASK_DOCUMENTS_ROUTE}/:id}`,
        component: TaskDocumentListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-documents: Task Documents`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.DocumentTask.Feature}:${PERMISSION_NAMES.Researches.DocumentTask.GetDocumentTaskByTask}`,
        },
      },
      {
        path: `${ASSIGNED_TASKS_ROUTE}`,
        component: AssignedTasksComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.collaborators-tasks: Collaborators Tasks`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetCollaboratorsTasks}`,
        },
      },
      {
        path: `${ASSIGNED_TASKS_ROUTE}/:id`,
        component: AssignedTaskDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.task-detail: Task Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Task.Feature}:${PERMISSION_NAMES.Researches.Task.GetTaskDetail}`,
        },
      },
      {
        path: `${TOR_ROUTE}`,
        component: TorListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.tor-list: TOR List`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Tor.Feature}:${PERMISSION_NAMES.Researches.Tor.GetTors}`,
        },
      },
      {
        path: `${TOR_DETAIL_ROUTE}/:id`,
        component: TorDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.tor-detail: TOR Detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Tor.Feature}:${PERMISSION_NAMES.Researches.Tor.GetTorDetail}`,
        },
      },
      {
        path: `${TOR_ROUTE}/${TOR_SETTINGS_ROUTE}`,
        component: TorSettingsComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.tor-configurations: Tor-configurations`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.ActivityType.Feature}:${PERMISSION_NAMES.Researches.ActivityType.GetAllActivityTypes}`,
        },
      },
      {
        path: `${ASSIGNED_REVIEWS_ROUTE}`,
        component: RequestedReviewsComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.assigned-reviews: assigned-reviews`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.StageInstance.Feature}:${PERMISSION_NAMES.Researches.StageInstance.GetReviewsByStageInstance}`,
        },
      },
      {
        path: `${ASSIGNED_REVIEWS_ROUTE}/stage-instances/:id`,
        component: ReviewDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.review-detail: review-detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.StageInstance.Feature}:${PERMISSION_NAMES.Researches.StageInstance.GetMyReview}`,
        },
      },
      {
        path: `${PUBLICATION_ROUTE}`,
        component: PublicationListComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.publications: publications`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Publication.Feature}:${PERMISSION_NAMES.Researches.Publication.GetPublications}`,
        },
      },
      {
        path: `${PUBLICATION_ROUTE}/publication-options`,
        component: PublicationOptionsComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.publication-options: publication options`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.PublicationOption.Feature}:${PERMISSION_NAMES.Researches.PublicationOption.GetPublicationOptions}`,
        },
      },
      {
        path: `${PUBLICATION_ROUTE}/detail`,
        component: PublicationDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.detail: detail`,
          canActivate: [HasPermissionGuard],
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Publication.Feature}:${PERMISSION_NAMES.Researches.Publication.GetPublicationProcessDetail}`,
        },
      },
      {
        path: ``,
        component: ResearchDashboardHomeComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.dashboard: dashboard`,
        },
      },
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: `${PUBLICATION_ROUTE}/:id`,
        component: PublicationDetailComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.publications-detail: publications-detial`,
          permission: `${MODULES.RESEARCHES}:${PERMISSION_NAMES.Researches.Publication.Feature}:${PERMISSION_NAMES.Researches.Publication.GetPublicationProcessDetail}`,
        },
        canActivate: [HasPermissionGuard],
      },
      {
        path: `${RESEARCHES_SETTINGS_ROUTE}`,
        component: ResearchesSettingsComponent,
        data: {
          breadcrumb: $localize`:@@researches.researches-routing.settings: Settings`,
        },
      },
    ],
  },
  {
    path: RESEARCH_COUNCIL_ROUTE,
    loadChildren: () =>
      import('./research-council/research-council.module').then(
        (m) => m.ResearchCouncilModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResearchesRoutingModule {}
