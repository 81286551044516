import { Component, OnInit } from '@angular/core';
import {
  MANAGE_PASSWORDS_ROUTE,
  ROLES_LIST,
  ROLES_ROUTE,
  USERS_LIST,
} from 'src/app/core/constants/routes';
import { UserFacade } from '../../facade/user.facade';
import { Observable, combineLatest, map } from 'rxjs';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private userFacade: UserFacade) {}
  navLinks: Array<{
    link: string;
    label: string;
    icon: string;
    hidden: boolean;
    feature: string;
    id: string;
  }> = [
    {
      link: USERS_LIST,
      label: $localize`:@@nav-links.users: Users`,
      icon: 'supervisor_account',
      hidden: true,
      feature: this.hasGetUsersPermission(),
      id: 'users.navigation.home.users-list-link'
    },
    {
      link: ROLES_LIST,
      label: $localize`:@@nav-links.roles: Roles`,
      icon: 'account_box',
      hidden: true,
      feature: this.hasGetRolesPermission(),
      id: 'users.navigation.home.roles-list-link' 
    },
    {
      link: MANAGE_PASSWORDS_ROUTE,
      label: $localize`:@@nav-links.manage-passwords:  Manage Passwords`,
      icon: 'lock',
      hidden: true,
      feature: this.hasGetUsersDefaultPasswordsPermission(),
      id: 'users.navigation.home.manage-passwords-link'
    },
  ];
  navLinks$ = new Observable<any>();

  ngOnInit(): void {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.updateMenuAuthorization();
      }
    });
  }

  updateMenuAuthorization() {
    this.navLinks$ = combineLatest([
      ...this.navLinks.map((item) => this.isAuthorized(item.feature)),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.navLinks.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );
  }

  isAuthorized(permission: string): Observable<boolean> {
    return this.userFacade
      .hasPermission(permission)
      .pipe(map((permission) => !!permission));
  }

  hasGetUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsers,
    );
  }

  hasGetAdminUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetAdminUsers,
    );
  }

  hasGetRolesPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.GetRoles,
    );
  }

  hasGetUsersDefaultPasswordsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.User.Feature,
      PERMISSION_NAMES.Identities.User.GetUsersDefaultPasswords,
    );
  }
}
