<div class="mx-2 mt-4">
  <app-process-instance-search *appHasPermission="hasSearchProcessInstancesPermission()"></app-process-instance-search>
</div>

<div class="flex justify-between">
  <div class="gap-x flex" *appHasPermission="hasGetProcessInstancesPermission()">
    <h1 class="mat-h1" i18n="@@researches.process-instance-list.processes">Processes</h1>
    <mat-paginator
      [length]="
        length
      "
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedProcessInstances($event)"
    >
    </mat-paginator>
  </div>
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="addProcessInstance()"
    *appHasPermission="hasCreateProcessInstancePermission()"
  >
   <span i18n="@@researches.process-instance-list.create-process">Create Process</span> <mat-icon>add</mat-icon>
  </button>
</div>
<ng-container *appHasPermission="hasGetProcessInstancesPermission()">
  <ng-container
  *ngIf="processInstances!.items.length > 0; else emptyProcessInstanceList"
>
  <app-process-instance-card
    *ngFor="let processInstance of processInstances!.items"
    [processInstance]="processInstance"
  ></app-process-instance-card>
</ng-container>
<ng-template #emptyProcessInstanceList>
  <div class="empty-process-instance-list">
    <span class="text-2xl" i18n="@@researches.process-instance-list.no-process-found">No process found</span>
  </div>
</ng-template>
</ng-container>
