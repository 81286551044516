<mat-card
  *ngIf="selectedMemo?.parentMemoId && parentMemo"
  class="reply-to cursor-pointer"
  (click)="showParentMemoDetail()"
>
  <mat-card-header>
    <mat-card-title>
      <span i18n="@@documents.memo-detail.reply-to-part-1">Reply For: </span>
      <span class="reply-title">{{ parentMemo?.title }}</span>
      <span i18n="@@documents.memo-detail.reply-to-part-2"></span>
    </mat-card-title>
  </mat-card-header>
</mat-card>
@if(selectedMemo && !selectedMemo.parentMemoId) {
  <h1 class="mat-h1">
    <span i18n="@@documents.memo-detail.title"> Title: </span>
    {{ selectedMemo.title }}
  </h1>
}
<div class="margin-left flex" *ngIf="memoFilter !== 'Sent'">
  <h4 i18n="@@documents.memo-detail.sent-from">Sent From:</h4>
  <p>{{ selectedMemo?.sentFrom }}</p>
</div>

<div class="editor--wrapper">
  <div id="quill-container"></div>
</div>

<div class="flex-end mx-5 margin-top">
  <div class="flex-end">
    <button
      mat-flat-button
      color="primary"
      *ngIf="selectedMemo?.status === memoStatusEnum.Draft"
      [matMenuTriggerFor]="menu"
      i18n="@@documents.memo-detail.options"
    >
      Options
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngIf="selectedMemo?.status === 'Draft'"
        (click)="updateMemo()"
        i18n="@@documents.memo-detail.edit-memo"
      >
        Edit Memo
      </button>
      <button
        mat-menu-item
        *ngIf="selectedMemo?.status === 'Draft'"
        (click)="sendMemo()"
        i18n="@@documents.memo-detail.send-memo"
      >
        send Memo
      </button>
      <button
        mat-menu-item
        (click)="deleteMemo()"
        i18n="@@documents.memo-detail.delete-memo"
      >
        Delete Memo
      </button>
    </mat-menu>
  </div>
  <div class="flex-end">
    <button
      mat-flat-button
      color="primary"
      *ngIf="selectedMemo?.status === memoStatusEnum.Sent"
      [matMenuTriggerFor]="menu2"
      i18n="@@documents.memo-detail.options"
    >
      Options
    </button>
    <mat-menu #menu2="matMenu">
      <button
        mat-menu-item
        i18n="@@documents.memo-detail.reply"
        (click)="replyToMemo()"
      >
        Reply
      </button>
    </mat-menu>
  </div>
</div>
<div class="my-10 align-collapsible fill-remaining-height">
  <mat-sidenav-container class="sidenav-container">
    <ng-container>
      <div class="fill-remaining-height mx-5">
        <h2 i18n="@@documents.memo-detail.files" class="mat-h2">Files</h2>
        <div *ngIf="selectedMemo?.attachments?.length; else emptyFilesList">
          <mat-card
            *ngFor="let attachment of selectedMemo?.attachments"
            class="custom-mat-card"
          >
            <mat-card-content class="flex justify-between">
              <p class="mx-2">{{ attachment.name }}</p>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="previewAttachment(attachment)"
                    i18n="@@documents.memo-detail.preview"
                  >
                    Preview
                  </button>
                  <button
                    mat-menu-item
                    (click)="downloadAttachment(attachment)"
                    i18n="@@documents.memo-detail.download"
                  >
                    Download
                  </button>
                  <button
                    *ngIf="memoFilter === 'Sent'"
                    mat-menu-item
                    (click)="deleteAttachment(attachment.id)"
                    i18n="@@documents.memo-detail.delete"
                  >
                    Delete
                  </button>
                </mat-menu>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
    <mat-sidenav class="cc-container" #sidenav position="end" mode="side">
      <app-memo-cc-list></app-memo-cc-list>
    </mat-sidenav>
  </mat-sidenav-container>

  <div class="wrapper">
    <button
      class="chevron"
      mat-fab
      color="primary"
      (click)="handleToggle()"
      [attr.data-test-id]="
        'documents.cc-user-in-memo.memo-detail.cc-user-open-arrow'
      "
    >
      <mat-icon>{{ this.opened }}</mat-icon>
    </button>
    <p class="cc" i18n="@@documents.memo-detail.cc-users">CC users</p>
  </div>

  <ng-template class="fill-remaining-height" #emptyFilesList>
    <div class="empty-notices-list">
      <span class="text-2xl" i18n="@@documents.memo-detail.no-files-attached"
        >No files attached</span
      >
    </div>
  </ng-template>
</div>
