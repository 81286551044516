import { Selector, createPropertySelectors } from '@ngxs/store';
import { WorkflowStateModel, WorkflowState } from './workflow.state';

export class WorkflowSelector {
  static slices = createPropertySelectors<WorkflowStateModel>(WorkflowState);

  @Selector([WorkflowState])
  static workflows(stateModel: WorkflowStateModel) {
    return stateModel.workflows;
  }

  @Selector([WorkflowState])
  static ownedWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.ownedWorkflows;
  }

  @Selector([WorkflowState])
  static myworkflows(stateModel: WorkflowStateModel) {
    return stateModel.myworkflows;
  }

  @Selector([WorkflowState])
  static totalCount(stateModel: WorkflowStateModel) {
    return stateModel.totalCount;
  }

  @Selector([WorkflowState])
  static search(stateModel: WorkflowStateModel) {
    return stateModel.search;
  }
  @Selector([WorkflowState])
  static filteredWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.filteredWorkflows;
  }

  @Selector([WorkflowState])
  static isSearchingWorkflow(stateModel: WorkflowStateModel) {
    return stateModel.isSearchingWorkflow;
  }

  @Selector([WorkflowState])
  static workflowSearchTerm(stateModel: WorkflowStateModel) {
    return stateModel.workflowSearchTerm;
  }

  @Selector([WorkflowState])
  static selectedWorkflow(stateModel: WorkflowStateModel) {
    return stateModel.selectedWorkflow;
  }

  @Selector([WorkflowState])
  static archivedWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.archivedWorkflows;
  }

  @Selector([WorkflowState])
  static workflowFilters(stateModel: WorkflowStateModel) {
    return stateModel.workflowFilters;
  }
}
