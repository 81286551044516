import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ReportedCommentState,
  ReportedCommentStateModel,
} from './reported-comments.state';

export class ReportedCommentSelector {
  static slices =
    createPropertySelectors<ReportedCommentStateModel>(ReportedCommentState);

  @Selector([ReportedCommentState])
  static reportedCommentsReport(stateModel: ReportedCommentStateModel) {
    return stateModel.reportedComments;
  }

  @Selector([ReportedCommentState])
  static workflowTypes(stateModel: ReportedCommentStateModel) {
    return stateModel.workflowTypes;
  }
}
