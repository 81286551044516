<mat-sidenav-container class="container">
  <mat-sidenav-content class="externalDocumentdetail">
    <div class="flex-column">
      <div *appHasPermission="hasRespondToExternalDocumentPermission()">
        <button
          class="right-action"
          color="primary"
          mat-flat-button
          (click)="openResponseForm()"
          *ngIf="hasReceivedRemarkStatus"
          [attr.data-test-id]="'documents.external-document-response.external-document-detail.compose-response-button'"
        >
          <span i18n="@@documents.external-document-detail.compose-response"> Compose Response  </span>
        </button>
      </div>
      <h1 class="mat-h1">
        <span i18n="@@documents.external-document-detail.source"> Source:  </span>
        {{ selectedExternalDocumentDetail?.source }}
      </h1>
      <h2 class="mat-h2">
        <span i18n="@@documents.external-document-detail.subject"> Subject:  </span>
        {{ selectedExternalDocumentDetail?.subject }}
      </h2>
      <p class="mat-body">{{ getDateFromString(selectedExternalDocumentDetail?.created) }}</p>
      <p class="mat-body">{{ selectedExternalDocumentDetail?.description }}</p>
    </div>
    <div>
      <h2  class="mat-h2" i18n="@@documents.external-document-detail.files">Files</h2>
      <ng-container
        class="overflow height-m"
        *ngFor="let file of selectedExternalDocumentDetail?.files; let i = index"
      >
        <mat-card class="file-preview">
          <mat-card-content>
            <mat-icon>insert_drive_file</mat-icon>
            <mat-card-title>{{ file.name }}</mat-card-title>
            <p class="mat-body-2">{{ getDateFromString(file.created) }}</p>
            <p class="mat-body-2">{{ file.fileSize }} <span i18n="@@documents.external-document-detail.kb"> KB  </span></p>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="previewAttachment(file)" i18n="@@documents.external-document-detail.preview">
            Preview
          </button>
          <button mat-menu-item (click)="downloadAttachment(file)" i18n="@@documents.external-document-detail.download">
            Download
          </button>
        </mat-menu>
      </ng-container>
    </div>
    <div class="remarks">
      <h2 i18n="@@documents.external-document-detail.remark" class="mat-h2">Remarks</h2>
      <button *ngIf="showActionButton()" mat-flat-button color="primary" [matMenuTriggerFor]="menu" i18n="@@documents.external-document-detail.actions" [disabled]="hasReceivedRemarkStatus"
      [attr.data-test-id]="'documents.external-document-response.external-document-detail.actions-button'">
        Actions
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <ul
        *ngFor="let status of remarkActionStatusForDisplay"
        mat-menu-item
        (click)="handleStatus(status.remarkActionStatusEnum)"
      >
        {{
          viewRemarkStatus(status.remarkActionStatusForDisplay)
        }}
      </ul>
    </mat-menu>
    <div>
      <ng-container *ngIf="selectedExternalDocumentDetail">
        <div
          *ngIf="
            selectedExternalDocumentDetail.remarkActions &&
            selectedExternalDocumentDetail!.remarkActions.length > 0
          "
        >
          <mat-card
            class="remark-card"
            *ngFor="let remark of selectedExternalDocumentDetail?.remarkActions"
          >
            <mat-card-header class="card-header">
              <mat-card-title-group>
                <mat-card-title>
                  {{ remark.submittedByUser?.firstName }}
                  {{ remark.submittedByUser?.middleName }}
                </mat-card-title>

                <span class="chip mat-caption">{{ remark.remarkStatus }}</span>
              </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
              <div *ngIf="remark.remarkStatus === remarkStatusEnum.Received || remark.remarkStatus === remarkStatusEnum.Rejected; else twoOfficeRemark " class="flex mat-h4">
                <span>{{remark.remarkStatus}} <span> By: </span> {{ remark.userOffice?.name }}</span>
              </div>
              <ng-template #twoOfficeRemark>
                <div  class="flex mat-h4">
                  <span> <span i18n="@@documents.external-document-detail.from"> From:  </span> {{ remark.userOffice?.name }}</span>
                  <mat-icon>arrow_right_alt</mat-icon>
                  <span class=""><span i18n="@@documents.external-document-detail.to"> To:  </span> {{ remark.toOffice?.name }}</span>
                </div>
              </ng-template>
              <div class="flex mat-h4">
                <span i18n="@@documents.external-document-detail.date">Date:</span>
                <span>{{ getDateFromString(remark.submissionDate) }}</span>
              </div>
              <p class="mat-body-2">
                {{ remark.message }}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="selectedExternalDocumentDetail?.remarkActions?.length === 0" i18n="@@documents.external-document-detail.no-remark-found">
          No Remarks found.
        </div>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
