import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowValidationsComponent } from './components/workflow-validations/workflow-validations.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WorkflowValidationFormComponent } from './components/workflow-validation-form/workflow-validation-form.component';
import { NgxsModule } from '@ngxs/store';
import { WorkflowValidationState } from './store/workflow-validations.state';
import { ReactiveFormsModule } from '@angular/forms';
import { WorkflowStepValidationComponent } from './components/workflow-step-validation/workflow-step-validation.component';
import { WorkflowStepValidationsComponent } from './components/workflow-step-validations/workflow-step-validations.component';

@NgModule({
  declarations: [
    WorkflowValidationsComponent,
    WorkflowValidationFormComponent,
    WorkflowStepValidationComponent,
    WorkflowStepValidationsComponent
  ],
  exports: [WorkflowValidationsComponent, WorkflowStepValidationComponent, WorkflowStepValidationsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([WorkflowValidationState]),
  ],
})
export class WorkflowValidationModule {}
