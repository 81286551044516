import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { WorkflowCommentPolicy } from '../../models/workflow-comment.model';
import { WorkflowCommentFacade } from '../../facade/workflow-comment.facade';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface WorkflowCommentPolicyState {
  workflowCommentPolicy: WorkflowCommentPolicy | undefined;
}

const initWorkflowCommentPolicyState: WorkflowCommentPolicyState = {
  workflowCommentPolicy: undefined,
};

@Component({
  selector: 'app-workflow-comment-policy',
  templateUrl: './workflow-comment-policy.component.html',
  styleUrls: ['./workflow-comment-policy.component.scss'],
})
export class WorkflowCommentPolicyComponent implements OnInit {
  workflowCommentPolicy: WorkflowCommentPolicy | undefined;
  workflowCommentPolicy$ = this.state.select('workflowCommentPolicy');

  constructor(
    private dialog: MatDialog,
    private state: RxState<WorkflowCommentPolicyState>,
    private workflowCommentFacade: WorkflowCommentFacade,
  ) {
    this.state.set(initWorkflowCommentPolicyState);
    this.state.connect(
      'workflowCommentPolicy',
      this.workflowCommentFacade.workflowCommentPolicy$,
    );
  }

  ngOnInit(): void {
    this.workflowCommentFacade.dispatchGetWorkflowCommentPolicy();

    this.workflowCommentPolicy$.subscribe((policy) => {
      this.workflowCommentPolicy = policy;
    });
  }

  openCommentPolicyDialog() {
    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      width: '400px',
      data: {
        regularTextOne: $localize`:@@documents.workflow-comment-policy.set-comment-policy: Set Comment Policy`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmText == 'confirm') {
        if (this.workflowCommentPolicy?.id) {
          this.workflowCommentFacade.dispatchUpdateWorkflowCommentPolicy(
            this.workflowCommentPolicy.id,
            dialogRef.componentInstance.confirmText,
          );
        } else {
          this.workflowCommentFacade.dispatchCreateWorkflowCommentPolicy(
            dialogRef.componentInstance.confirmText,
          );
        }
      }
    });
  }

  openDeleteCommentPolicyDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-comment-policy.deleted-workflow-policy-part-1:Are you sure you want to delete` ,
        boldText: ` "${this.workflowCommentPolicy?.description}" ` ,
        regularTextTwo: $localize`:@@documents.workflow-comment-policy.deleted-workflow-policy-part-2:comment policy?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        this.workflowCommentFacade.dispatchDeleteWorkflowCommentPolicy(
          this.workflowCommentPolicy?.id!,
        );
      }
    });
  }

  hasCreateWorkflowCommentPolicyPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.Feature,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.CreateWorkflowCommentPolicy
    )
  }
  hasUpdateWorkflowCommentPolicyPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.Feature,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.UpdateWorkflowCommentPolicy
    )
  }

  hasDeleteWorkflowCommentPolicyPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.Feature,
      PERMISSION_NAMES.Documents.WorkflowCommentPolicy.DeleteWorkflowCommentPolicy
    )
  }
}
