import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-communications-count',
  templateUrl: './communications-count.component.html',
  styleUrls: ['./communications-count.component.scss'],
})
export class CommunicationsCountComponent {
  @Input()
  totalCommunicationsCount: number = 0;

  navigateToReseachCommunicationsList() {
    const researchCommunicationsComponent = document.getElementById(
      'researchCommunicationsComponent',
    );
    if (researchCommunicationsComponent) {
      researchCommunicationsComponent.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
