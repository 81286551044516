<mat-dialog-content mat-dialog-content class="form-container">
  <form class="process-form" [formGroup]="processForm">
    <h3>{{ formTitle }}</h3>
    <mat-checkbox class="check-box" formControlName="enforceSequential"
    i18n="@@researches.process-form.enforce-sequence"
      >Enforce sequence
    </mat-checkbox>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.process-form.name" 
      >Name</mat-label>
      <input
        id="name"
        (focus)="focusedInput = 'name'"
        formControlName="name"
        type="text"
        matInput
        class="full-width-input"
        [placeholder]="placeholderToggleLabel.processTypeName"
        [attr.data-test-id]="'researches.create-process.process-form.process-name-input'"
      />
      <mat-error *ngIf="processForm.get('name')?.hasError('required')"
      i18n="@@researches.process-form.please-provide-valid-name-for-process-type">
        Please provide a valid name for the process type
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.process-form.code" 
      >Code</mat-label>
      <input
        id="code"
        (focus)="focusedInput = 'code'"
        formControlName="code"
        type="text"
        matInput
        class="full-width-input"
        [placeholder]="placeholderToggleLabel.processTypeCode"
        [attr.data-test-id]="'researches.create-process.process-form.process-code-input'"
      />
      <mat-error *ngIf="processForm.get('code')?.hasError('required')"
      i18n="@@researches.process-form.please-provide-valid-code-for-process-type">
        Please provide a valid code for the process type
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@researches.process-form.description" 
      >Description</mat-label>
      <textarea
        id="description"
        (focus)="focusedInput = 'description'"
        formControlName="description"
        matInput
        [placeholder]="placeholderToggleLabel.processTypeDescription"
        class="full-width"
        [attr.data-test-id]="'researches.create-process.process-form.process-description-input'"
      ></textarea>
      <mat-error *ngIf="processForm.get('description')?.hasError('required')"
      i18n="@@researches.process-form.please-provide-valid-description-for-process-type">
        Please provide a valid description for the process type
      </mat-error>
      <mat-error *ngIf="processForm.get('description')?.hasError('maxlength')">
        Description should not exceed 1500 characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="full-width"
      appearance="fill"
      *ngIf="!update; else null"
    >
      <mat-label i18n="@@researches.process-form.choose-process-category" 
      >Choose Process Category</mat-label>
      <mat-select formControlName="processCategory">
        <mat-option 
          *ngFor="let category of processCategory" 
          [value]="category.processCategoryEnum"
          >
          {{category.processCategory}}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="processForm.get('processCategory')?.hasError('required')"
        i18n="@@researches.process-form.please-select-process-category">
        Please select a process category
      </mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.process-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!processForm.valid"
      [attr.data-test-id]="'researches.create-and-update-process.process-form.create-or-update-process-type-button'"
    >
      {{ saveButtonText }}
    </button>
  </div>
</mat-dialog-content>
