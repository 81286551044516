import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Breadcrumb } from '../components/breadcrumb-navigation/breadcrumb-navigation.component';
import { Injectable } from '@angular/core';
import { UpdateBreadcrumbs } from './researches-home.actions';

export interface ResearchesHomeStateModel {
  breadcrumbs: Breadcrumb[];
}

const RESEARCHES_HOME_STATE_TOKEN = new StateToken<ResearchesHomeStateModel>(
  'researchesHomeState',
);

const defaults: ResearchesHomeStateModel = {
  breadcrumbs: [],
};

@State<ResearchesHomeStateModel>({
  name: RESEARCHES_HOME_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ResearchesHomeState {
  constructor() {}

  @Action(UpdateBreadcrumbs)
  updateBreadcrumbs(
    { patchState }: StateContext<ResearchesHomeStateModel>,
    { breadcrumbs }: UpdateBreadcrumbs,
  ) {
    patchState({
      breadcrumbs: breadcrumbs,
    });
  }
}
