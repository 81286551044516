import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { FieldType, Formatters } from 'angular-slickgrid';
import { combineLatest } from 'rxjs';
import { WorkspaceFacade } from '../../facade/workspace.facade';
import { StorageInfo } from '../../models/storage-info';
import { SizeUnits, Workspace } from '../../models/workspace';
import { SIZE_UNITS } from '../workspace-quota/workspace-quota.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface WorkspaceFormComponentState {
  storageInfo?: StorageInfo;
  editableWorkspace?: Workspace;
  range: { min: number; max: number };
}

const initWorkspaceFormComponentState = {
  // range for the minimum and maximum values the quotaLimit could be
  range: {
    min: 0,
    max: 0,
  },
};

@Component({
  selector: 'app-workspace-form',
  templateUrl: './workspace-form.component.html',
  styleUrls: ['./workspace-form.component.scss'],
  providers: [RxState],
})
export class WorkspaceFormComponent implements OnInit {
  sizeUnits = SIZE_UNITS;

  storageInfo$ = this.state.select('storageInfo');
  editableWorkspace$ = this.state.select('editableWorkspace');

  workspaces: Workspace[] = [];

  gridOptions = {
    gridWidth: 'auto',
    autoHeight: true,
    enablePagination: true,
    enableAutoResize: false,
    enableSorting: false,
  };

  column = [
    {
      id: 'name',
      name: $localize`:@@files.workspace-form.name:Name`,
      field: 'name',
    },
    {
      id: 'usage',
      name: $localize`:@@files.workspace-form.percent-usage:% usage`,
      field: '',
      formatter: Formatters.progressBar,
      type: FieldType.number,
      minWidth: 100,
    },
    {
      id: 'size',
      name: $localize`:@@files.workspace-form.size:Size`,
      field: 'size',
      formatter: Formatters.progressBar,
      type: FieldType.number,
      minWidth: 100,
    },
  ];

  workspaceForm = this.fb.group({
    quotaLimitUnit: [0, [Validators.required]],
    quotaLimit: [0, [Validators.required]],
  });

  constructor(
    public state: RxState<WorkspaceFormComponentState>,
    private workspaceFacade: WorkspaceFacade,
    private fb: NonNullableFormBuilder,
    private dialogRef: MatDialogRef<WorkspaceFormComponent>,
  ) {
    this.state.set(initWorkspaceFormComponentState);
    this.state.connect('storageInfo', workspaceFacade.storageInfo$);
    this.state.connect('editableWorkspace', workspaceFacade.editableWorkspace$);
  }

  ngOnInit(): void {
    this.editableWorkspace$.subscribe((editableWs) => {
      this.workspaceFacade.dispatchGetStorageInfo(editableWs?.id);
      if (editableWs) {
      this.workspaceForm.controls.quotaLimitUnit.setValue(
        SizeUnits[editableWs.quotaLimitUnit],
      );
      }
    });

    combineLatest([this.editableWorkspace$, this.storageInfo$]).subscribe(
      ([workspace, storageInfo]) => {
        if (workspace) {
          this.workspaceForm.controls.quotaLimitUnit.setValue(
            SizeUnits[workspace.quotaLimitUnit],
          );
          this.calculateRange(
            SizeUnits[workspace.quotaLimitUnit],
            workspace,
            storageInfo,
          );
        }
      },
    );

    this.workspaceForm.controls.quotaLimitUnit.valueChanges.subscribe((val) => {
      const { storageInfo, editableWorkspace: ws } = this.state.get();
      if (ws) {
        this.calculateRange(val, ws, storageInfo);
        this.workspaceForm.controls.quotaLimit.setValue(
          ws.quotaLimit * Math.pow(1000, val - SizeUnits[ws.quotaLimitUnit]),
        );
      }
    });
  }

  get range() {
    return this.state.get().range;
  }

  private calculateRange(
    unit: number,
    ws: Workspace,
    storageInfo?: StorageInfo,
  ) {
    if (storageInfo) {
      const min =
        ws.contentSize * Math.pow(1000,  unit - SizeUnits[ws.contentSizeUnit]);
      const max = storageInfo.maximumUsableSpace * Math.pow(1000, unit);

      this.state.set({ range: { min, max } });
    }
  }

  submit() {
    const { editableWorkspace: ws } = this.state.get();
    if (this.workspaceForm.valid && ws) {
      const form = this.workspaceForm.value;

      this.workspaceFacade.dispatchUpdateWorkspace({
        id: ws.id,
        officeId: ws.officeId,
        name: ws.name,
        quotaLimit: form.quotaLimit!,
        quotaLimitUnit: form.quotaLimitUnit!,
      });

      this.dialogRef.close();
    }
  }

  hasUpdateWorkspacePermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.Workspace.Feature,
      PERMISSION_NAMES.Files.Workspace.UpdateWorkspace,
    );
  }
}
