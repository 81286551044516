import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { StorageInfo } from '../models/storage-info';
import { Workspace } from '../models/workspace';

import { WorkspaceService } from '../services/workspace.service';
import {
  GetMyWorkspace,
  GetStorageInfo,
  UpdateWorkspace,
  GetWorkspaces,
  SelectEditableWorkspace,
  SyncWorkspaceInfo,
  StopSyncingWorkspaceInfo,
  GetArchivedWorkspaces,
  SelectArchivedWorkspace,
  SearchWorkspaces,
  DeleteArchivedWorkspace,
} from './workspace.actions';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';

export interface WorkspaceStateModel {
  currentWorkspace?: Workspace;
  selectedWorkspace?: Workspace;
  storageInfo?: StorageInfo;
  workspaces: PaginatedList<Workspace>;
  editableWorkspace?: Workspace;
  archivedWorkspaces?: PaginatedList<Workspace> | undefined;
  selectedArchivedWorkspace?: Workspace;
  filteredWorkspaces: Workspace[];
}

const WORKSPACE_STATE_TOKEN = new StateToken<WorkspaceStateModel>(
  'workspaceState',
);

const defaults = {
  workspaces: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  filteredWorkspaces: [],
};

@State<WorkspaceStateModel>({
  name: WORKSPACE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class WorkspaceState {
  constructor(
    private workspaceService: WorkspaceService,
    private store: Store,
    public operationStatus: OperationStatusService,
  ) {}

  @Action(GetMyWorkspace)
  getWorkspace({ patchState }: StateContext<WorkspaceStateModel>) {
    return this.workspaceService.getWorkspace().pipe(
      tap((workspace) => {
        patchState({ currentWorkspace: workspace });
      }),
    );
  }

  @Action(GetMyWorkspace)
  getMyWorkspace({ patchState }: StateContext<WorkspaceStateModel>) {
    return this.workspaceService.getMyWorkspace().pipe(
      tap((workspace) => {
        patchState({ selectedWorkspace: workspace });
      }),
    );
  }

  @Action(GetStorageInfo)
  getStorageInfo(
    { patchState }: StateContext<WorkspaceStateModel>,
    { workspaceId }: GetStorageInfo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workspaceService.getStorageInfo(workspaceId).pipe(
      tap((storageInfo) => {
        patchState({ storageInfo });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateWorkspace)
  updateWorkspace(
    { setState, getState }: StateContext<WorkspaceStateModel>,
    { workspaceForm }: UpdateWorkspace,
  ) {
    this.store.dispatch(new SetProgressOn());
    const curWs = getState().currentWorkspace;
    return this.workspaceService.updateWorkspace(workspaceForm).pipe(
      tap((ws) => {
        setState(
          patch({
            workspaces: patch({
              items: updateItem<Workspace>((oldWs) => oldWs.id == ws.id, ws),
            }),
            currentWorkspace: curWs?.id == ws.id ? ws : curWs,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetWorkspaces)
  getWorsspaces(
    { patchState }: StateContext<WorkspaceStateModel>,
    { pageNumber, pageSize }: GetWorkspaces,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workspaceService.getWorspaces(pageNumber, pageSize).pipe(
      tap((ws) => {
        patchState({ workspaces: ws });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SelectEditableWorkspace)
  selectEditableWorksapce(
    { patchState }: StateContext<WorkspaceStateModel>,
    { workspace }: SelectEditableWorkspace,
  ) {
    patchState({
      editableWorkspace: workspace,
    });
  }

  @Action(SyncWorkspaceInfo)
  syncWorkspaceInfo(
    { patchState, getState }: StateContext<WorkspaceStateModel>,
    { workspace }: SyncWorkspaceInfo,
  ) {
    this.workspaceService.startConnection().then(() => {
      this.workspaceService.registerEvent(workspace.id, (ws: Workspace) => {
        if (getState().currentWorkspace?.id == ws.id)
          patchState({ currentWorkspace: ws });
      });
    });
  }

  @Action(StopSyncingWorkspaceInfo)
  stopSyncingWorkspaceInfo() {
    this.workspaceService.stopConnection();
  }

  @Action(GetArchivedWorkspaces)
  getArchivedWorkspaces(
    { patchState }: StateContext<WorkspaceStateModel>,
    { pageNumber, pageSize }: GetArchivedWorkspaces,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workspaceService
      .getArchivedWorkspaces(pageNumber, pageSize)
      .pipe(
        tap((paginatedWorksapces) => {
          patchState({
            archivedWorkspaces: paginatedWorksapces,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SelectArchivedWorkspace)
  selectArchivedWorkspace(
    { patchState }: StateContext<WorkspaceStateModel>,
    { workspace }: SelectArchivedWorkspace,
  ) {
    patchState({
      selectedArchivedWorkspace: workspace,
    });
  }

  @Action(SearchWorkspaces)
  searchWorkspaces(
    { setState }: StateContext<WorkspaceStateModel>,
    { name, pageNumber, pageSize }: SearchWorkspaces,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workspaceService
      .searchWorkspaceByName(name, pageNumber, pageSize)
      .pipe(
        tap((workspaces) => {
          setState(
            patch({
              filteredWorkspaces: workspaces.items,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteArchivedWorkspace)
  deleteArchivedWorkspace(
    { setState }: StateContext<WorkspaceStateModel>,
    { workspaceId }: DeleteArchivedWorkspace,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.workspaceService
      .deleteArchivedWorkspace(workspaceId)
      .pipe(
        tap(() => {
          setState(
            patch({
              archivedWorkspaces: patch({
                items: removeItem((workspace) => {
                  return workspace.id == workspaceId;
                }),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@files.workspace.workspace-deleted: Workspace deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
