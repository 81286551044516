import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { PublicationOptionFacade } from 'src/app/researches/facades/publication-option.facade';
import { PublicationFacade } from 'src/app/researches/facades/publication.facade';
import { ProcessInstance } from 'src/app/researches/models/process-instance.model';
import { PublicationOptionsComponent } from '../../publication-options/publication-options.component';
import { ChoosePublicationOptionFormComponent } from '../../publication-options/choose-publication-option-form/choose-publication-option-form.component';
import { PUBLICATION_DETAIL, PUBLICATION_ROUTE } from 'src/app/core/constants/routes';
import {
  Publication,
  PublicationProcess,
  PublicationStatus,
} from 'src/app/researches/models/publication.model';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface PublicationListComponentState {
  completedProcessInstances?: PaginatedList<ProcessInstance>;
  publications?: PaginatedList<Publication>;
  selectedTabIndex: number;
}

const initialPublicationListComponentState: PublicationListComponentState = {
  selectedTabIndex: 0,
};

@Component({
  selector: 'app-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: ['./publication-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: PublicationListComponent}],
})
export class PublicationListComponent implements OnInit, MatPaginatorIntl {
  completedProcessInstances$ = this.state.select('completedProcessInstances');
  completedProcessInstances?: PaginatedList<ProcessInstance>;

  publications$ = this.state.select('publications');
  publications?: PaginatedList<Publication>;

  selectedTabIndex$ = this.state.select('selectedTabIndex');
  selectedTabIndex: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  onGoingPublication: any;
  notOnPublication:any;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.publication-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.publication-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.publication-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.publication-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.publication-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.publication-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.publication-list.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.publication-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private publicationFacade: PublicationFacade,
    private router: Router,
    private state: RxState<PublicationListComponent>,
    private publicationOptionFacade: PublicationOptionFacade,
    public dialog: MatDialog,
  ) {
    this.state.connect(
      'completedProcessInstances',
      this.publicationFacade.completedProcessInstances$,
    );

    this.state.connect('publications', this.publicationFacade.publications$);

    this.state.connect(
      'selectedTabIndex',
      this.publicationFacade.selectedTabIndex$,
    );
  }
  changes = new Subject<void>();
  
  ngOnInit(): void {
    this.publicationFacade.dispatchGetCompletedProcessInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.publicationFacade.dispatchGetPublications(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.selectedTabIndex$.subscribe((selectedTabIndex) => {
      this.selectedTabIndex = selectedTabIndex;
    });

    this.publications$.subscribe((publications) => {
      if (publications) {
        this.publications = publications;
        this.pageIndex = publications.pageNumber - 1;
      }
    });

    this.completedProcessInstances$.subscribe((completedProcessInstances) => {
      if (completedProcessInstances) {
        this.completedProcessInstances = completedProcessInstances;
        this.pageIndex = completedProcessInstances.pageNumber - 1;
      }
    });

    this.publicationFacade.dispatchSetSelectedPublicationTab(0);

    this.onGoingPublication= $localize`:@@researches.publication-list.on-going-publication:Ongoing Publication`;
    this.notOnPublication= $localize`:@@researches.publication-list.not-on-publication:Not on Publication`;
  }

  loadPaginatedProcessInstances(event: PageEvent) {
    this.publicationFacade.dispatchGetCompletedProcessInstances(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  loadPaginatedPublications(event: PageEvent) {
    this.publicationFacade.dispatchGetPublications(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  viewPublicationOptions() {
    this.dialog.open(PublicationOptionsComponent, {
      width: '50%',
      height: '60%',
    });
  }

  startPublication(processInstance: ProcessInstance) {
    if (processInstance) {
      this.dialog
        .open(ChoosePublicationOptionFormComponent, {
          data: {
            processInstanceId: processInstance.id,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm') {
            this.router.navigate([PUBLICATION_ROUTE]);
            this.publicationFacade.dispatchGetPublications(
              this.paginator?.pageIndex + 1 || 1,
              this.paginator?.pageSize || 10,
            );
            this.publicationFacade.dispatchSetSelectedPublicationTab(0);
          }
        });
    }
  }

  setSelectedPublication(publication: Publication) {
    if (publication) {
      this.publicationFacade.dispatchSetSelectedPublication(publication);
      this.router.navigate([`${PUBLICATION_ROUTE}/${publication.id}`]);
    }
  }

  onTabChange(event: MatTabChangeEvent) {

    if (event.index === 0) {
      this.publicationFacade.dispatchSetSelectedPublicationTab(0);
      this.pageIndex = 0;
      this.pageSize = 10;
    } else if (event.index === 1) {
      this.publicationFacade.dispatchSetSelectedPublicationTab(1);
      this.pageIndex = 0;
      this.pageSize = 10;
    }
  }

  getPublicationStatusColor(status?: string) {
    if (status == PublicationStatus.Submitted) {
      return 'submitted-color';
    } else if (status == PublicationStatus.NeedsRevision) {
      return 'revision-color';
    } else if (status == PublicationStatus.Published) {
      return 'published-color';
    } else if (status == PublicationStatus.ReadyForPublication) {
      return 'ready-color';
    } else {
      return 'initialized-color';
    }
  }

  publicationClicked(publication: Publication) {
    this.publicationFacade.dispatchSetSelectedPublication(publication);
    this.router.navigate([`${PUBLICATION_ROUTE}/detail`])
  }

  hasGetPublicationsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.GetPublications,
    );
  }

  hasGetPublicationOptionsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.GetPublicationOptions,
    );
  }

  hasCreatePublicationPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.CreatePublication,
    );
  }
}
