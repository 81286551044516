import { Observable, filter, take } from 'rxjs';
import { OfficeFacade } from './../../facades/office.facades';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ExcelUploadReport } from '../../models/excel-upload-report.model';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { ExcelUploadReportDialogComponent } from '../excel-upload-report-dialog/excel-upload-report-dialog.component';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { OfficeTreeComponent } from '../office-tree/office-tree.component';
import { OfficeMultipleSelectComponent } from '../office-multiple-select/office-multiple-select.component';


interface UploadExcelDialogComponentState {
  excelUploadReports: ExcelUploadReport[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
}

const initUploadExcelDialogComponentState = {};

@Component({
  selector: 'app-upload-excel-dialog',
  templateUrl: './upload-excel-dialog.component.html',
  styleUrl: './upload-excel-dialog.component.scss',
  providers: [RxState],
})
export class UploadExcelDialogComponent {
  @ViewChild('chooseFiles', { static: false }) chooseFiles!: ElementRef;
  selectedFile: File | null = null;
  uploadReports$ = this.state.select('excelUploadReports');
  @ViewChild('chooseFiles', { static: false }) chooseFilesInput: any;
  rules = [
    $localize`:@@offices.upload-excel.office-rule:The parent of an office should be recorded in the previous entries of the excel or the office will not be saved.`,
  ];
  relativeParentOffice: FlatOfficeNode | null = null;

  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.state.select('selectedFlatOfficeNodes');

  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;


  constructor(
    public officeFacade: OfficeFacade,
    private operationStatus: OperationStatusService,
    private state: RxState<UploadExcelDialogComponentState>,
    public dialog: MatDialog,
  ) {
    this.state.set(initUploadExcelDialogComponentState);
    this.state.connect(
      'excelUploadReports',
      this.officeFacade.excelUploadReports$,
    );
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );

    this.state.connect(
      'selectedFlatOfficeNodes',
      officeFacade.selectedFlatOfficeNodes$,
    );
  }

  ngOnInit() {
    this.officeFacade.dispatchResetSelectedOffice()
    this.officeFacade.dispatchResetSelectedOffices()
    this.selectedFlatOfficeNode$.subscribe(
      (selectedFlatOfficeNode) =>
        (this.selectedFlatOfficeNode = selectedFlatOfficeNode),
    );
    this.selectedFlatOfficeNodes$.subscribe(
      (selectedFlatOfficeNodes) =>
        (this.selectedFlatOfficeNodes = selectedFlatOfficeNodes),
    );
  }

  selectFile(event: any) {
    this.selectedFile = event.target.files[0];
    event.target.files[0] = null;
  }

  clearSelectedFile() {
    if (this.chooseFilesInput) {
      this.chooseFilesInput.nativeElement.value = null;
      this.selectedFile = null;
    }
  }

  importOfficesFromExcel() {
    if (this.selectedFile && this.selectedFlatOfficeNode) {

      const formData = new FormData();
      formData.append('RelativeRootOfficeId', this.selectedFlatOfficeNode?.id);
      this.selectedFlatOfficeNodes?.forEach((node) => {
        formData.append('RelativeReportToOfficeIds', node.id);
      });
      formData.append('ExcelFile', this.selectedFile);
      this.officeFacade.dispatchUploadExcel(formData);
      this.uploadReports$
        .pipe(
          filter((reports) => reports !== null),
          take(1),
        )
        .subscribe((reports) => {
          if (reports) {
            let showTable = this.showTable(reports);

            if (!showTable) {
              this.operationStatus.displayStatus(
                $localize`:@@offices.upload-excel.import-office:Importing Offices finished Successfully.`,
                successStyle,
              );
              this.dialog.closeAll();
            } else {
              this.dialog.open(ExcelUploadReportDialogComponent, {
                width: '600px',
                data: reports,
              });
            }
            this.officeFacade.dispatchGetOffices(1, 10);
            this.officeFacade.dispatchGetFlatOfficeNodes();
          }
        });
      this.clearSelectedFile();
    }
  }

  showTable(reports: ExcelUploadReport[]): boolean {
    let successfulCount = 0;
    for (let report of reports) {
      if (
        report.success === true &&
        (!report.reportsToOfficesRegisterLog ||
          report.reportsToOfficesRegisterLog.length === 0)
      ) {
        successfulCount += 1;
      }
    }
    return successfulCount !== reports.length;
  }

  hasExportTemplateExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.ExportTemplateExcel,
    );
  }
  hasUploadExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UploadExcelFile,
    );
  }

  openSingleOffice() {
    this.dialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { update: false },
    });
  }

  openMultipleOffice() {
    this.dialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
      data: { update: false },
    });
  }

  removeSelectedFlatOfficeNodeFromNodes(officeNode: FlatOfficeNode) {
    if (this.selectedFlatOfficeNodes === undefined) return;
    const index = this.selectedFlatOfficeNodes.indexOf(officeNode);

    if (index >= 0) {
      this.selectedFlatOfficeNodes.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(
        this.selectedFlatOfficeNodes,
      );
    }
  }

  removeSelectedFlatOfficeNode() {
    this.officeFacade.dispatchResetSelectedOffice();
  }

  hasGetReportsToNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetReportsToNodes,
    );
  }

  isImportButtonDisabled(): boolean {
    return this.selectedFile === null || this.selectedFlatOfficeNode === null;
  }
}


