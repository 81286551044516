import { AfterViewInit, Component, DestroyRef } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { User } from 'src/app/users/models/user.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { EChartsOption } from 'echarts';
import { BudgetVsTime } from '../../models/budget-vs-time.model';
import { Filter } from '../../models/filter.model';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';

interface State {
  filteredUsers: Required<User>[];
  selectedOfficeNodes: FlatOfficeNode[];
  budgetVsTimeData: BudgetVsTime[];
  filter?: Filter;
  datetime: string;
}

const initialState: State = {
  filteredUsers: [],
  selectedOfficeNodes: [],
  budgetVsTimeData: [],
  datetime: '',
};

@Component({
  selector: 'app-budget-time-graph',
  templateUrl: './budget-time-graph.component.html',
  styleUrls: ['./budget-time-graph.component.scss'],
})
export class BudgetTimeGraphComponent implements AfterViewInit {
  datetime: string = '';
  datetime$ = this.state.select('datetime');

  eChartInstance: any;
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      data: Array(12)
        .fill(0)
        .map((_, index) => {
          const date = new Date();
          date.setMonth(index);

          return date.toLocaleString('en-US', {
            month: 'short',
          });
        }),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: Array(12).fill(0),
        type: 'line',
      },
    ],
  };

  constructor(
    public state: RxState<State>,
    private researchDashboardFacade: ResearchDashboardFacade,
    private datetimeFacade: DateTimeFacade,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'budgetVsTimeData',
      this.researchDashboardFacade.budgetVsTimeData$,
    );
    this.state.connect('datetime', this.datetimeFacade.datetime$);
  }

  ngOnInit(): void {
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
    });
  }

  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetBudgetTimeGraph();
    this.state.select('budgetVsTimeData').subscribe((val) => {
      this.chartOption = {
        ...this.chartOption,
        series: {
          data: Array(12)
            .fill(0)
            .map(
              (_, index) =>
                val.find((item) => item.month == index + 1)?.totalCost || 0,
            ),
          type: 'line',
        },
      };
    });

    this.state.select('filter').subscribe((filters) => {
      this.researchDashboardFacade.dispatchGetBudgetTimeGraph(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  setChartOption() {
    this.eChartInstance.setOption(this.chartOption);
  }

  onChartInit(ec: any) {
    this.eChartInstance = ec;
    this.setChartOption();
  }
}
