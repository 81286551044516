import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ReportedCommentFacade } from '../../facades/reported-comment.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ReportedComment } from '../../models/reported-comment.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkflowType } from '../../models/workfflow-type.model';

interface ReportedCommentsReportComponentState {
  reportedComments: PaginatedList<ReportedComment>;
  workflowTypes: WorkflowType[];
}

const initialReportedCommentsReportComponentState: ReportedCommentsReportComponentState =
  {
    reportedComments: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    workflowTypes: [],
  };

@Component({
  selector: 'app-reported-comments-report',
  templateUrl: './reported-comments-report.component.html',
  styleUrls: ['./reported-comments-report.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ReportedCommentsReportComponent}, RxState],
})
export class ReportedCommentsReportComponent {
  reportedCommentsDataSource!: MatTableDataSource<any>;
  reportedComments$ = this.state.select('reportedComments');
  reportedComments: ReportedComment[] = [];
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  filterForm: FormGroup;
  workflowTypes$ = this.state.select('workflowTypes');
  workflowTypes: WorkflowType[] = [];

  firstPageLabel = $localize`:@@documents.reported-comments-report.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.reported-comments-report.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.reported-comments-report.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.reported-comments-report.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.reported-comments-report.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.reported-comments-report.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.reported-comments-report.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.reported-comments-report.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private reportedCommentFacade: ReportedCommentFacade,
    private dialog: MatDialog,
    private state: RxState<ReportedCommentsReportComponentState>,
    private formBuilder: FormBuilder,
  ) {
    this.state.set(initialReportedCommentsReportComponentState);
    this.state.connect(
      'reportedComments',
      this.reportedCommentFacade.reportedComments$,
    );

    this.state.connect(
      'workflowTypes',
      this.reportedCommentFacade.workflowTypes$,
    );

    this.filterForm = this.formBuilder.group({
      workflowType: [''],
      searchName: [''],
    });
  }
  changes = new RxState();
  ngOnInit(): void {
    this.reportedCommentFacade.dispatchGetReportedCommentsReport(1, 10);
    this.reportedComments$.subscribe((reports) => {
      this.reportedComments = reports.items;
      this.reportedCommentsDataSource = new MatTableDataSource<ReportedComment>(
        reports.items,
      );
      this.length = reports.totalCount;
    });

    this.reportedCommentFacade.dispatchGetWorkflowTypes();

    this.workflowTypes$.subscribe((types) => {
      this.workflowTypes = types;
    });
  }

  loadPaginatedReportedCommentsReport(event: PageEvent) {
    this.reportedCommentFacade.dispatchGetReportedCommentsReport(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  applyFilter() {
    if (!this.filterForm) return;
    const selectedWorkflowType = this.filterForm.get('workflowType')?.value;
    const searchName = this.filterForm.get('searchName')?.value;
    const workflowTypeId =
      selectedWorkflowType && selectedWorkflowType.id !== null
        ? selectedWorkflowType.id
        : null;

    this.reportedCommentFacade.dispatchGetReportedCommentsReport(
      1,
      10,
      workflowTypeId,
      searchName,
    );
  }
}
