import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as SignalR from '@microsoft/signalr';
import {
  MY_WORKSPACE_URL,
  STORAGE_INFO_URL,
  WORKSPACES_URL,
  WORKSPACE_HUB_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { StorageInfo } from '../models/storage-info';
import { Workspace } from '../models/workspace';
import { WorkspaceForm } from '../models/workspace-form';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private hubConnection?: SignalR.HubConnection;

  constructor(private http: HttpClient) {}

  getWorkspace(): Observable<Workspace> {
    return this.http.get<Workspace>(`${MY_WORKSPACE_URL}`);
  }

  getMyWorkspace(): Observable<Workspace> {
    return this.http.get<Workspace>(`${WORKSPACES_URL}/my-workspace`);
  }

  getWorspaces(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Workspace>> {
    return this.http.get<PaginatedList<Workspace>>(
      `${WORKSPACES_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getStorageInfo(workspaceId?: string): Observable<StorageInfo> {
    return this.http.get<StorageInfo>(
      `${STORAGE_INFO_URL}/${workspaceId ?? 'unknown'}`,
    );
  }

  updateWorkspace(workspace: WorkspaceForm): Observable<Workspace> {
    return this.http.put<Workspace>(WORKSPACES_URL, workspace);
  }

  startConnection() {
    this.hubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(WORKSPACE_HUB_URL, {})
      .withAutomaticReconnect()
      .build();
    return this.hubConnection.start();
  }

  registerEvent(eventName: string, callback: (...args: any[]) => void) {
    this.hubConnection?.on(eventName, callback);
  }

  stopConnection() {
    this.hubConnection?.stop();
    this.hubConnection = undefined;
  }

  getArchivedWorkspaces(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workspace>> {
    return this.http.get<PaginatedList<Workspace>>(
      `${WORKSPACES_URL}/archived?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  searchWorkspaceByName(
    name?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Workspace>> {
    let url = `${WORKSPACES_URL}/search-by-name`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined &&
      name !== null &&
      name !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}`;
    } else if (name !== null && name !== undefined) {
      url += `?name=${name}`;
    }
    return this.http.get<PaginatedList<Workspace>>(url);
  }

  deleteArchivedWorkspace(
    workspaceId: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${WORKSPACES_URL}/archived/${workspaceId}`,
    );
  }
}
