import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  FormFieldsState,
  FormFieldsStateModel,
} from './form-fields.state';

export class FormFieldsSelector {
  static slices =
    createPropertySelectors<FormFieldsStateModel>(FormFieldsState);

  @Selector([FormFieldsState])
  static selectedFormFields(templateFieldsModel: FormFieldsStateModel) {
    return templateFieldsModel.selectedFormFields;
  }

  @Selector([FormFieldsState])
  static isUpdatingFormField(stateModel: FormFieldsStateModel) {
    return stateModel.isUpdatingFormField;
  }

  @Selector([FormFieldsState])
  static selectedFormField(stateModel: FormFieldsStateModel) {
    return stateModel.selectedFormField;
  }

  @Selector([FormFieldsState])
  static dataTypes(stateModel: FormFieldsStateModel) {
    return stateModel.dataTypes;
  }

  @Selector([FormFieldsState])
  static templateFields(stateModel: FormFieldsStateModel) {
    return stateModel.templateFields;
  }
}
