import { Selector, createPropertySelectors } from "@ngxs/store";
import { CurrencyState, CurrencyStateModel } from "./currency.state";

export class CurrencySelector {
    static slices =
      createPropertySelectors<CurrencyStateModel>(CurrencyState);
  
    @Selector([CurrencyState])
    static templateExcelFileUrl(state: CurrencyStateModel) {
      return state.templateExcelFileUrl;
    }

    @Selector([CurrencyState])
    static currencies(state: CurrencyStateModel) {
      return state.currencies;
    }
  }