<mat-dialog-content class="dialog-content">
  <h3 class="form-title">Update profile</h3>
    <form class="user-form" [formGroup]="userForm">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@users.update-user-profile-form.user-name">User Name</mat-label>
        <input id="userName" formControlName="userName" matInput />
        <mat-error
          *ngIf="userForm.get('userName')?.hasError('required')"
          i18n="@@users.update-user-profile-form.user-name-is-required"
          >Username is required</mat-error
        >
        <mat-error
          *ngIf="userForm.get('userName')?.hasError('pattern')"
          i18n="@@users.update-user-profile-form.allowed-character-sets-for-a-user-name"
          >Only English letters,numbers and dash are allowed for user
          name</mat-error
        >
  
        <mat-error
          *ngIf="userForm.get('userName')?.hasError('minlength')"
          i18n="
            @@users.update-user-profile-form.user-name-must-contain-at-least-three-characters"
          >At least three characters for a user name</mat-error
        >
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label i18n="@@users.update-user-profile-form.phone-Number">Phone Number</mat-label>
        <span matPrefix>&nbsp;+251 &nbsp;</span>
        <input
          id="phoneNumber"
          formControlName="phoneNumber"
          matInput
          type="tel"
        />
        <mat-error
          *ngIf="userForm.get('phoneNumber')?.hasError('required')"
          i18n="@@users.update-user-profile-form.phone-number-is-required"
        >
          Phone Number is required
        </mat-error>
        <mat-error
          *ngIf="userForm.get('phoneNumber')?.hasError('pattern')"
          i18n="@@users.update-user-profile-form.phone-number-is-not-valid"
          >Phone Number is not valid</mat-error
        >
      </mat-form-field>
    </form>
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="submit"
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!userForm.valid"
      i18n="@@users.update-user-profile-form.save"
    >
      Save
    </button>
    <button
      class="submit"
      mat-flat-button
      color="accent"
      mat-dialog-close
      i18n="@@users.update-user-profile-form.cancel"
    >
      Cancel
    </button>
  </mat-dialog-actions>
  