


import { Component, OnInit, ViewChild,AfterViewInit,DestroyRef } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowTypes } from '../../models/workflow-types.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { WorkflowTypesFacade } from '../../facade/workflow-types.facades';
import { RxState } from '@rx-angular/state';
import { WorkflowConfigurationFormComponent } from '../workflow-configuration-form/workflow-configuration-form.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WORKFLOW_TYPE_STEPS_ROUTE } from 'src/app/core/constants/routes';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { Subject, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


interface WorkflowTypeComponentState {
  workflowTypes: PaginatedList<WorkflowTypes> | undefined;
  ownedWorkflowTypes: PaginatedList<WorkflowTypes> | undefined;
  length: number;
  isSearchingWorkflowType?: boolean;
}

const initialWorkflowTypeComponentState: WorkflowTypeComponentState = {
  workflowTypes: undefined,
  ownedWorkflowTypes: undefined,
  length: 0,
};

@Component({
  selector: 'app-workflow-type',
  templateUrl: './workflow-type.component.html',
  styleUrls: ['./workflow-type.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowTypeComponent}],
})
export class WorkflowTypeComponent implements OnInit, MatPaginatorIntl, AfterViewInit {
  workflowTypes$ = this.state.select('workflowTypes');
  workflowTypes: PaginatedList<WorkflowTypes> | undefined = undefined;

  ownedWorkflowTypes$ = this.state.select('ownedWorkflowTypes');
  ownedWorkflowTypes: PaginatedList<WorkflowTypes> | undefined = undefined;

  pageSize: number = 10;
  pageIndex: number = 0;

  length$ = this.state.select('length');
  length: number = 0;
  optionsForDisplay = [ $localize`:@@documents.workflow-type.all: All`];
  options = [ $localize`:@@documents.workflow-type.all: All`];
  selectedOption =  'All';
  workflowTypeControl = new FormControl('All');
  searchForm: FormGroup;
  isSearchingWorkflowType$ = this.state.select('isSearchingWorkflowType');
  isSearchingWorkflowType?: boolean = false;

  placeholderFieldsLabel = {
    workflowTypeAll: $localize`:@@documents.workflow-type.all: All`,
    searchWorkflowType: $localize`:@@documents.workflow-type.search-workflow-types: Search Workflow Types`,
  }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    firstPageLabel = $localize`:@@documents.workflow-type.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.workflow-type.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.workflow-type.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.workflow-type.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.workflow-type.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.workflow-type.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.workflow-type.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow-type.page-part-two: of` + `${amountPages}`;
    }

  constructor(
    private dialog: MatDialog,
    private workflowTypesfacade: WorkflowTypesFacade,
    private state: RxState<WorkflowTypeComponentState>,
    private router: Router,
    private fb: FormBuilder,
    private userFacade: UserFacade,
    private destroyRef: DestroyRef,
  ) {
    this.state.set(initialWorkflowTypeComponentState);
    this.state.connect(
      'workflowTypes',
      this.workflowTypesfacade.workflowTypes$,
    );
    this.state.connect(
      'ownedWorkflowTypes',
      this.workflowTypesfacade.ownedWorkflowTypes$,
    );
    this.state.connect('length', this.workflowTypesfacade.totalCount$);
    this.searchForm = this.fb.group({
      workflowTypeName: [''],
    });
    this.state.connect(
      'isSearchingWorkflowType',
      this.workflowTypesfacade.isSearchingWorkflowType$,
    );
  }
  changes = new Subject<void>();

  ngAfterViewInit() {
    this.workflowTypeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.searchForm.reset();
        this.workflowTypesfacade.dispatchSetWorkflowTypeSearchingMode(false);
        if (value === 'All') {
          this.workflowTypesfacade.dispatchGetWorkflowTypes(1,10);
        } else {
          this.workflowTypesfacade.dispatchGetOwnedWorkflowTypes(1,10);
        }
      });
  }

  ngOnInit(): void {
    if (this.isAuthorized(this.hasGetOwnedWorkflowTypesPermission())) {
      this.optionsForDisplay.push($localize`:@@documents.workflow-type.owned: Owned`);
      this.options.push('Owned');
    }
    this.workflowTypesfacade.dispatchGetWorkflowTypes(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.workflowTypesfacade.dispatchGetOwnedWorkflowTypes(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.length$.subscribe((length) => {
      this.length = length;
    });

    this.workflowTypes$.subscribe((workflowTypes) => {
      if (workflowTypes) {
        this.workflowTypes = workflowTypes;
        this.pageIndex = workflowTypes.pageNumber - 1;
      }
    });

    this.ownedWorkflowTypes$.subscribe((workflowTypes) => {
      this.ownedWorkflowTypes = workflowTypes;
    });

    this.isSearchingWorkflowType$.subscribe((isSearchingWorkflowType) => {
      this.isSearchingWorkflowType = isSearchingWorkflowType;
    });
  }

  onOptionChange(option: any) {
    this.selectedOption = option;
  }

  get isOwnedWorkflowTypePage() {
    return (
      this.ownedWorkflowTypes && this.workflowTypeControl.value === 'Owned'
    );
  }

  loadPaginatedWorkflowTypes(event: PageEvent) {
    this.isSearchingWorkflowType
      ? this.workflowTypesfacade.dispatchSearchWorkflowTypes(
          this.searchForm.get('workflowTypeName')?.value,
          this.isOwnedWorkflowTypePage? true: false,
          event.pageIndex + 1,
          event.pageSize,
        )
      : this.workflowTypesfacade.dispatchGetWorkflowTypes(
          event.pageIndex + 1,
          event.pageSize,
        );
  }

  loadPaginatedOwnedWorkflowTypes(event: PageEvent) {
    this.isSearchingWorkflowType
      ? this.workflowTypesfacade.dispatchSearchWorkflowTypes(
          this.searchForm.get('workflowTypeName')?.value,
          true,
          event.pageIndex + 1,
          event.pageSize,
        )
      : this.workflowTypesfacade.dispatchGetOwnedWorkflowTypes(
          event.pageIndex + 1,
          event.pageSize,
        );
  }

  addWorkflowConfiguration() {
    this.workflowTypesfacade.dispatchSetUpdateStatus(false);
    this.dialog.open(
      WorkflowConfigurationFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  updateWorkflowConfiguration(event: Event, workflowType: WorkflowTypes) {
    event.stopPropagation();
    this.workflowTypesfacade.dispatchSetUpdateStatus(true);
    this.workflowTypesfacade.dispatchSetSelectedWorkflowType(workflowType);
    this.dialog.open(
      WorkflowConfigurationFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  workflowTypeDetail(workflowTypes: WorkflowTypes) {
    this.workflowTypesfacade.dispatchSetSelectedWorkflowType(workflowTypes);
    this.router.navigate([WORKFLOW_TYPE_STEPS_ROUTE, workflowTypes.id]);
  }
  openDeleteConfirmationDialog(event: MouseEvent, workflowType: WorkflowTypes) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`:@@documents.workflow-type.delete-workflow-type-title:Delete Workflow Type`,
        regularTextOne: $localize`:@@documents.workflow-type.delete-workflow-type-part-1:Are you sure you want to delete` ,
        boldText: ` "${workflowType.name}" ` ,
        regularTextTwo: $localize`:@@documents.workflow-type.delete-workflow-type-part-2:workflow type?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.openDeliberateConfirmationDialog(workflowType);
      }
    });
  }

  openDeliberateConfirmationDialog(workflowType: WorkflowTypes) {
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: workflowType.name,
          message: $localize`:@@documents.workflow-type.delete-workflow-name:Delete` + ` ["${workflowType.name}"] `,
          regularTextOne: $localize`:@@documents.workflow-type.permanently-delete-workflow-type-part-1:This action is irreversible. Are you sure you want to delete` ,
          boldText: ` "${workflowType.name}" ` ,
          regularTextTwo: $localize`:@@documents.workflow-type.permanently-delete-workflow-type-part-2:workflow type?`,
          extraTextOne: $localize`:@@documents.workflow-type.permanently-delete-workflow-type-extra-part-1:To confirm, type` ,
          extraTextBold: ` "${workflowType.name}" ` ,
          extraTextTwo: $localize`:@@documents.workflow-type.permanently-delete-workflow-type-extra-part-2:below:`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowTypesfacade.dispatchDeleteWorkflowType(workflowType.id!);
      }
    });
  }

  onSearchTermChange() {
    if (this.searchForm.get('workflowTypeName')?.value === '') {
      this.workflowTypesfacade.dispatchSetWorkflowTypeSearchingMode(false);
      this.workflowTypesfacade.dispatchGetWorkflowTypes();
    } else {
      this.workflowTypesfacade.dispatchSetWorkflowTypeSearchingMode(true);
      this.workflowTypesfacade.dispatchSearchWorkflowTypes(
        this.searchForm.get('workflowTypeName')?.value,
        this.isOwnedWorkflowTypePage? true: false,
      );
    }
  }
  hascreateWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.CreateWorkflowType,
    );
  }
  hasUpdateWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.UpdateWorkflowType,
    );
  }
  hasDeleteWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.DeleteWorkflowType,
    );
  }

  hasGetWorkflowTypesDetailPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.GetWorkflowType,
    );
  }
  hasGetOwnedWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.GetOwnedWorkflowTypes,
    );
  }
  hasSearchWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowType.Feature,
      PERMISSION_NAMES.Documents.WorkflowType.SearchWorkflowTypes,
    );
  }
  isAuthorized(permission: string): boolean {
    let isAuthorized: boolean = false;
    this.userFacade.hasPermission(permission).pipe(map((permission) => !!permission)
      )
      .subscribe(hasPermission => {
        isAuthorized = hasPermission;
      });

    return isAuthorized;
  }
}
