<mat-dialog-content mat-dialog-content class="form-container">
  <div class="cc-office-form">
    <div class="owner-office">
      <button
        mat-raised-button
        color="primary"
        align
        (click)="openMultipleOffice()"
        i18n="@@documents.add-cc-office-form.select-office">
        Select Office
      </button>
      <mat-chip-row
        class="chip"
        *ngFor="let item of selectedFlatOfficeNodes"
        (removed)="removeSelectedOffice(item)"
        [editable]="false"
      >
        {{ item.name }}
        <button matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>
  </div>
  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@documents.add-cc-office-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="this.selectedFlatOfficeNodes.length === 0 "
     i18n="@@documents.add-cc-office-form.save">
      save
    </button>
  </div>
</mat-dialog-content>
