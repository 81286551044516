import {
  AddTaskComment,
  AssignTask,
  ChangeTaskStatus,
  DeleteTaskComment,
  GetPaginatedTaskComments,
  GetTaskByName,
  GetTaskDetail,
  GetTasksBySearchTerm,
  ReplyTaskComment,
  ResetSelectedTaskComment,
  SetSelectedTask,
  SetSelectedTaskComment,
  SetTaskList,
  ShowSearchedTask,
  UpdateTaskDocument,
  UploadTaskFile,
  GetPaginatedTaskDocument,
  PreviewTaskDocument,
  SearchTaskTypes,
  DeleteDocumentTask,
  DeleteFileTask,
  UpdateTaskComment,
  Update,
  ToggleTaskDetailFrom,
  SetSelectedReplyComment,
  UpdateReplyTaskComment,
  FetchVideo,
  FetchAudio,
  GetTaskTypesByStageInstanceId,
  GetTaskFiles,
  DownloadTaskDocument,
  GetAssignedTasks,
  GetAssignedDocumetTasks,
  ResetTaskDetail,
  DeleteTaskCommentReply,
  PreviewTaskDocumentCompose,
  GetPaginatedTaskDocumentsByStageInstanceId,
} from '../store/task.actions';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProcessTasks } from '../models/process-tasks.model';
import {
  AssignTaskDto,
  CreateTaskDto,
  FileTaskUploadReport,
  Task,
  TaskComment,
  TaskDocumnet,
  TaskType,
  UpdateTaskStatusDto,
} from '../models/task.model';
import {
  CreateTaskDocument,
  GetListOfTasksWithInProcessType,
  ToggleCardContent,
} from '../store/task.actions';
import { TaskSelector } from '../store/task.selector';
import { TaskDetail } from '../models/taskDetail.model';
import {
  CreateTask,
  DeleteTaskFromMyTasks,
  ResetSelectedProcessInstanceTask,
  ResetSelectedTask,
  SetSelectedProcessInstanceTask,
  SetFileTaskUploadReportsNull,
  DownloadTaskDocumentCompose,
} from '../store/task.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TaskDocument } from '../models/task-document.model';
import { TaskDetailFrom } from '../store/task.state';
import { DeleteTaskFromStageInstanceDetail } from '../store/stage-instance-detail.actions';
@Injectable({
  providedIn: 'root',
})
export class TaskFacade {
  constructor(private store: Store) {}

  @Select(TaskSelector.taskTypes)
  taskTypes$!: Observable<TaskType[]>;

  @Select(TaskSelector.tasks)
  processTasks$!: Observable<ProcessTasks[]>;

  @Select(TaskSelector.update)
  update$!: Observable<boolean>;

  @Select(TaskSelector.selectedTask)
  selectedTask$!: Observable<Task>;

  @Select(TaskSelector.myTasks)
  myTasks$!: Observable<ProcessTasks[]>;

  @Select(TaskSelector.taskDetail)
  taskDetail$!: Observable<TaskDetail>;

  @Select(TaskSelector.isSearching)
  isSearching$!: Observable<boolean>;

  @Select(TaskSelector.taskComments)
  taskComments$!: Observable<PaginatedList<TaskComment>>;

  @Select(TaskSelector.assingedTasks)
  assingedTasks$!: Observable<PaginatedList<Task>>;

  @Select(TaskSelector.selectedTaskComment)
  selectedTaskComment$!: Observable<TaskComment>;

  @Select(TaskSelector.slices.similarTasks)
  similarTasks$!: Observable<Task[]>;

  @Select(TaskSelector.fileTaskUploadReports)
  fileTaskUploadReports$!: Observable<FileTaskUploadReport[]>;

  @Select(TaskSelector.selectedReplyComment)
  selectedReplyComment$!: Observable<TaskComment>;

  @Select(TaskSelector.videoBlob)
  videoBlob$!: Observable<Blob | null>;

  @Select(TaskSelector.audioBlob)
  audioBlob$!: Observable<Blob | null>;

  dispatchGetTaskByName(name: string, stageInstanceId: string) {
    this.store.dispatch(new GetTaskByName(name, stageInstanceId));
  }
  @Select(TaskSelector.Documents)
  documents$!: Observable<TaskDocument[]>;

  @Select(TaskSelector.TotalCount)
  length$!: Observable<number>;

@Select(TaskSelector.stageInstanceTaskDocuments)
  stageInstanceTaskDocuments$!: Observable<any>;

  @Select(TaskSelector.isEditTaskComment)
  isEditTaskComment$!: Observable<boolean>;

  @Select(TaskSelector.isReplyTaskComment)
  isReplyTaskComment$!: Observable<boolean>;

  @Select(TaskSelector.taskDetailFrom)
  taskDetailFrom$!: Observable<TaskDetailFrom>;

  @Select(TaskSelector.taskFiles)
  taskFiles$!: Observable<any>;

  @Select(TaskSelector.assignedTaskDocuments)
  assignedTaskDocuments$!: Observable<any>;

  dispatchShowSearchedTask(isSearching: boolean) {
    this.store.dispatch(new ShowSearchedTask(isSearching));
  }

  dispatchGetTasksBySearchTerm(searchTerm: string, processIds: string[]) {
    this.store.dispatch(new GetTasksBySearchTerm(searchTerm, processIds));
  }

  dispatchGetListOfTasksWithInProcess(processId: string) {
    this.store.dispatch(new GetListOfTasksWithInProcessType(processId));
  }

  dispatchToggleCardContent(showCardContent: boolean[]) {
    this.store.dispatch(new ToggleCardContent(showCardContent));
  }

  dispatchSetSelectedTask(task: Task) {
    this.store.dispatch(new SetSelectedTask(task));
  }

  dispatchDeleteTaskFromStageDetail(taskId: string) {
    this.store.dispatch(new DeleteTaskFromStageInstanceDetail(taskId));
  }

  dispatchGetMajorTaskDocumentsByStageInstanceId(stageInstanceId: string, pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetPaginatedTaskDocumentsByStageInstanceId(stageInstanceId, pageNumber, pageSize));
  }

  dispatchUpdate(update: boolean) {
    this.store.dispatch(new Update(update));
  }

  dispatchToggleTaskDetailFrom(taskDetailFrom: TaskDetailFrom) {
    this.store.dispatch(new ToggleTaskDetailFrom(taskDetailFrom));
  }

  dispatchSetSelectedProcessInstanceTasks(processInstanceTask: ProcessTasks) {
    this.store.dispatch(
      new SetSelectedProcessInstanceTask(processInstanceTask),
    );
  }

  dispatchResetSelectedProcessInstanceTasks() {
    this.store.dispatch(new ResetSelectedProcessInstanceTask());
  }

  dispatchResetSelectedTask() {
    this.store.dispatch(new ResetSelectedTask());
  }
  dispatchDeleteTaskFromMyTasks(processInstanceId: string, taskId: string) {
    this.store.dispatch(new DeleteTaskFromMyTasks(processInstanceId, taskId));
  }

  dispatchCreateTaskDocument(taskDoc: TaskDocumnet) {
    this.store.dispatch(new CreateTaskDocument(taskDoc));
  }

  dispatchUpdateTaskDocument(document: TaskDocumnet) {
    this.store.dispatch(new UpdateTaskDocument(document));
  }
  dispatchDeleteDocumentTask(id: string) {
    this.store.dispatch(new DeleteDocumentTask(id));
  }

  dispatchCreateTask(task: CreateTaskDto) {
    this.store.dispatch(new CreateTask(task));
  }

  dispatchGetTaskDetail(taskId: string) {
    this.store.dispatch(new GetTaskDetail(taskId));
  }

  dispatchSetTaskList(taskList: Task[]) {
    this.store.dispatch(new SetTaskList(taskList));
  }
  dispachUploadFiles(uploadFile: FormData, taskId: string, replace?: boolean) {
    this.store.dispatch(new UploadTaskFile(uploadFile, taskId));
  }
  dispatchDeleteFileTask(id: string) {
    this.store.dispatch(new DeleteFileTask(id));
  }


  dispatchAssignTask(assignTask: AssignTaskDto) {
    return this.store.dispatch(new AssignTask(assignTask));
  }

  dispatchGetPaginatedTaskComments(
    taskId: string,
    pageNumber: number,
    pageSize: number,
  ) {
    return this.store.dispatch(
      new GetPaginatedTaskComments(taskId, pageNumber, pageSize),
    );
  }

  dispatchChangeTaskStatus(updatedTask: UpdateTaskStatusDto) {
    return this.store.dispatch(new ChangeTaskStatus(updatedTask));
  }

  dispatchAddTaskComment(taskId: string, comment: string) {
    return this.store.dispatch(new AddTaskComment(taskId, comment));
  }

  dispatchUpdateTaskComment(taskCommentId: string, content: string) {
    return this.store.dispatch(new UpdateTaskComment(taskCommentId, content));
  }

  dispatchSetSelectedTaskComment(
    taskComment: TaskComment,
    typeOfAction: string,
  ) {
    return this.store.dispatch(
      new SetSelectedTaskComment(taskComment, typeOfAction),
    );
  }

  dispatchResetSelectedTaskComment() {
    return this.store.dispatch(new ResetSelectedTaskComment());
  }

  dispatchReplyTaskComment(commentId: string, content: string) {
    return this.store.dispatch(new ReplyTaskComment(commentId, content));
  }

  dispatchDeleteTaskComment(commentId: string) {
    return this.store.dispatch(new DeleteTaskComment(commentId));
  }

  dispatchGetPaginatedTaskDocument(
    taskId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetPaginatedTaskDocument(taskId, pageNumber, pageSize),
    );
  }
  dispatchPreviewTaskDocument(
    fileId: string,
    name: string,
    table: string,
    documentDownloadType: string,
    documentOperationType?: string,
  ) {
    this.store.dispatch(
      new PreviewTaskDocument(
        fileId,
        name,
        table,
        documentDownloadType,
        documentOperationType,
      ),
    );
  }

  dispatchSearchTaskTypes(
    name?: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(new SearchTaskTypes(name, pageNumber, pageSize));
  }

  dispatchSetFileTaskUploadReportsNull() {
    this.store.dispatch(new SetFileTaskUploadReportsNull());
  }

  dispatchSetSelectedReplyComment(comment: TaskComment) {
    this.store.dispatch(new SetSelectedReplyComment(comment));
  }

  dispatchUpdateReplyTaskComment(
    taskCommentId: string,
    commentId: string,
    content: string,
  ) {
    this.store.dispatch(
      new UpdateReplyTaskComment(taskCommentId, commentId, content),
    );
  }

  dispatchDeleteTaskCommentReply(
    taskCommentId: string,
    commentId: string,
  ) {
    this.store.dispatch(
      new DeleteTaskCommentReply(taskCommentId, commentId)
    );
  }

  dispatchFetchVideo(fileId: string) {
    this.store.dispatch(new FetchVideo(fileId));
  }

  dispatchFetchAudio(fileId: string) {
    this.store.dispatch(new FetchAudio(fileId));
  }

  dispatchGetTaskTypesByStageInstanceId(stageInstanceId: string) {
    this.store.dispatch(new GetTaskTypesByStageInstanceId(stageInstanceId));
  }

  dispatchGetTaskFiles(taskId: string, pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetTaskFiles(taskId));
  }

  dispatchDownloadTaskDocument(
    fileId: string,
    name: string,
    table: string,
    documentDownloadType: string,
    documentOperationType: string,
  ) {
    this.store.dispatch(
      new DownloadTaskDocument(
        fileId,
        name,
        table,
        documentDownloadType,
        documentOperationType,
      ),
    );
  }

  dispatchGetAssignedTasks(pageNumber: number, pageSize: number) {
    return this.store.dispatch(new GetAssignedTasks(pageNumber, pageSize));
  }

  dispatchGetAssignedTaskDocuments(
    stageInstanceId: string,
    pageNumber: number,
    pageSize: number,
  ) {
    return this.store.dispatch(
      new GetAssignedDocumetTasks(stageInstanceId, pageNumber, pageSize),
    );
  }

  dispatchResetTaskDetail() {
    return this.store.dispatch(new ResetTaskDetail());
  }

  dispatchDownloadTaskDocumentCompose(id: string, name:string) {
    this.store.dispatch( new DownloadTaskDocumentCompose(id, name),
    );
  }

  dispatchPreviewTaskDocumentCompose(id: string, name:string) {
    this.store.dispatch( new PreviewTaskDocumentCompose(id, name),
    );
  }
}
