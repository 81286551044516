import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RoleListComponent } from './components/role-list/role-list.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {
  FORGET_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  ROLES_LIST,
  USERS_LIST,
  CHANGE_PASSWORD_ROUTE,
  ASSIGN_REVOKE_PERMISSIONS,
  LOGIN_HISTORY_ROUTE,
  ASSIGN_REVOKE_PERMISSIONS_USER,
  ASSIGN_REVOKE_ROLES_USER,
  PROFILE_ROUTE,
  MANAGE_PASSWORDS_ROUTE,
  CONTACT_US_ROUTE,
  REPORTED_ISSUES_ROUTE,
  REPORTED_ISSUES_DETAIL_ROUTE
} from '../core/constants/routes';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegistrationType } from './components/user-form/user-form.component';
import { AssignRevokePermissionsComponent } from './components/assign-revoke-permissions/assign-revoke-permissions.component';
import { LoginHistoryComponent } from './components/login-history/login-history.component';
import { AssignRevokePermissionsUsersComponent } from './components/assign-revoke-permissions-users/assign-revoke-permissions-users.component';
import { AssignRevokeRolesUsersComponent } from './components/assign-revoke-roles-users/assign-revoke-roles-users.component';
import {
  HasFeaturePermissionGuard,
  HasPermissionGuard,
} from '../auth/services/has.permission.guard';
import { MODULES, PERMISSION_NAMES } from '../core/constants/permissions';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ManagePasswordsComponent } from './components/manage-passwords/manage-passwords.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ReportedIssuesComponent } from './components/reported-issues/reported-issues.component';
import { ReportedIssueDetailComponent } from './components/reported-issue-detail/reported-issue-detail.component';
import { CONTACT_US_URL } from '../core/constants/api-endpoints';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: USERS_LIST,
        component: UserListComponent,
        canActivate: [HasPermissionGuard],
        data: {
          registrationType: RegistrationType.ADMIN,
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.User.Feature}:${PERMISSION_NAMES.Identities.User.GetUsers}`,
        },
      },
      {
        path: ROLES_LIST,
        component: RoleListComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.Role.Feature}:${PERMISSION_NAMES.Identities.Role.GetRoles}`,
        },
      },

      {
        path: ASSIGN_REVOKE_PERMISSIONS,
        component: AssignRevokePermissionsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.Role.Feature}:${PERMISSION_NAMES.Identities.Role.AssignRevokePermissionsToRole}`,
        },
      },
      {
        path: ASSIGN_REVOKE_PERMISSIONS_USER,
        component: AssignRevokePermissionsUsersComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.User.Feature}:${PERMISSION_NAMES.Identities.User.AssignRevokePermissionsToUser}`,
        },
      },
      {
        path: ASSIGN_REVOKE_ROLES_USER,
        component: AssignRevokeRolesUsersComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.User.Feature}:${PERMISSION_NAMES.Identities.User.AssignRevokeRolesToUser}`,
        },
      },
      {
        path: MANAGE_PASSWORDS_ROUTE,
        component: ManagePasswordsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          permission: `${MODULES.IDENTITIES}:${PERMISSION_NAMES.Identities.User.Feature}:${PERMISSION_NAMES.Identities.User.GetUsersDefaultPasswords}`,
        },
      },
    ],
  },
  {
    path: FORGET_PASSWORD_ROUTE,
    component: ForgetPasswordComponent,
  },
  {
    path: LOGIN_HISTORY_ROUTE,
    component: LoginHistoryComponent,
  },
  {
    path: RESET_PASSWORD_ROUTE,
    component: ResetPasswordComponent,
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    component: ChangePasswordComponent,
  },
  {
    path: CONTACT_US_ROUTE,
    component: ContactUsComponent,
  },
  {
    path: REPORTED_ISSUES_ROUTE,
    component: ReportedIssuesComponent,
  },
  { 
    path: `${REPORTED_ISSUES_ROUTE}/:id`, 
    component: ReportedIssueDetailComponent,
  },
  { 
    path: REPORTED_ISSUES_DETAIL_ROUTE, 
    component: ReportedIssueDetailComponent,
  },
  {
    path: PROFILE_ROUTE,
    component: UserProfileComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
