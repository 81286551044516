import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FolderSelector } from '../store/folder.selector';
import { FolderTree } from '../models/folder-tree.model';
import {
  DeleteFolder,
  CreateFolder,
  GetFlatFolderNodes,
  GetFoldersByWorkspaceId,
  GetFoldersByWorkspaceIdTree,
  MoveFolder,
  GetFoldersByName,
  SelectFlatFolderNodes,
  ShareFoldersToOffices,
  ShareFoldersToRoles,
  ShareFoldersToUsers,
  SetFlatFolderNodesEmpty,
  RenameFolder,
  GetSharedFromFolders,
  GetSharedToFolders,
  SelectFlatFolderNode,
  SetSelectedFolderPathFromRoot,
  SetSharingModeFile,
  SetSharingModeFolder,
  GetFolderProperty,
  GetArchivedWorkspaceFolders,
  SelectArchivedFlatFolderNode,
  GetArchivedFolderProperty,
  MoveFolderFromArchivedWorkspace,
  GetWorkspaceFlatFolderNodes,
  SelectDestinationFlatFolderNode,
  SearchFolders,
  GetTrashedFolders,
  TrashFolder,
  RestoreFolder,
  ClearSelectedFlatFolderNode,
} from '../store/folder.actions';
import { FlatFolderNode } from '../models/flat-folder-node.model';
import { Folder } from '../models/folder.model';
import { ShareFolderRequest } from '../models/share-folder-request';
import { FolderProperty } from '../models/folder-property.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class FolderFacade {
  foldersTree$: Observable<FolderTree[]> = this.store.select(
    FolderSelector.slices.foldersTree,
  );

  flatFolderNodes$: Observable<FlatFolderNode[]> = this.store.select(
    FolderSelector.slices.flatFolderNodes,
  );

  folders$: Observable<Folder[]> = this.store.select(
    FolderSelector.slices.folders,
  );

  sharedToflatFolderNodes$: Observable<FlatFolderNode[]> = this.store.select(
    FolderSelector.slices.sharedToflatFolderNodes,
  );

  sharedFromflatFolderNodes$: Observable<FlatFolderNode[]> = this.store.select(
    FolderSelector.slices.sharedFromflatFolderNodes,
  );

  selectedFolderPathFromRoot$: Observable<string> = this.store.select(
    FolderSelector.slices.selectedFolderPathFromRoot,
  );

  @Select(FolderSelector.selectedFlatOfficeNodes)
  selectedFlatFolderNodes$!: Observable<FlatFolderNode[]>;

  @Select(FolderSelector.selectFlatFolderNode)
  selectedFlatFolderNode$!: Observable<FlatFolderNode>;

  @Select(FolderSelector.trash)
  trash$!: Observable<PaginatedList<Folder>>;

  isSharingModeFolder$: Observable<boolean> = this.store.select(
    FolderSelector.slices.isSharingModeFolder,
  );

  selectedFolder$: Observable<Folder | null> = this.store.select(
    FolderSelector.slices.selectedFolder,
  );

  folderProperty$: Observable<FolderProperty | undefined> = this.store.select(
    FolderSelector.slices.folderProperty,
  );

  archivedFolderProperty$: Observable<FolderProperty | undefined> =
    this.store.select(FolderSelector.slices.archivedFolderProperty);

  @Select(FolderSelector.archivedWorkspaceFolders)
  archivedWorkspaceFolders$!: Observable<FlatFolderNode[]>;

  @Select(FolderSelector.selectedArchivedFlatFolderNode)
  selectedArchivedFlatFolderNode$!: Observable<FlatFolderNode>;

  @Select(FolderSelector.workspaceFlatFolderNodes)
  workspaceFlatFolderNodes$!: Observable<FlatFolderNode[]>;

  @Select(FolderSelector.selectedDestinationFlatFolderNode)
  selectedDestinationFlatFolderNode$!: Observable<FlatFolderNode>;

  @Select(FolderSelector.searchResults)
  searchResults$!: Observable<FlatFolderNode[]>;

  constructor(private store: Store) {}

  dispatchGetFoldersByWorkspaceId(workspaceId: string) {
    this.store.dispatch(new GetFoldersByWorkspaceId(workspaceId));
  }

  dispatchGetFlatFolderNodes() {
    this.store.dispatch(new GetFlatFolderNodes());
  }

  dispatchSelectFlatFolderNode(selectedFlatFolderNode: FlatFolderNode) {
    this.store.dispatch(new SelectFlatFolderNode(selectedFlatFolderNode));
  }

  dispatchGetFoldersByWorkspaceIdTree(workspaceId: string) {
    this.store.dispatch(new GetFoldersByWorkspaceIdTree(workspaceId));
  }

  dispatchMoveFolder(pathFromRoot: string, folderId: string) {
    this.store.dispatch(new MoveFolder(pathFromRoot, folderId));
  }

  dispatchDeleteFolder(folderId: string, workspaceId: string) {
    this.store.dispatch(new DeleteFolder(folderId, workspaceId));
  }

  dispatchCreateFolder(folder: Folder) {
    this.store.dispatch(new CreateFolder(folder));
  }

  dispatchGetFolderByName(name: string) {
    this.store.dispatch(new GetFoldersByName(name));
  }

  dispatchSelectFlatFolderNodes(selectedFlatFolderNodes: FlatFolderNode[]) {
    return this.store.dispatch(
      new SelectFlatFolderNodes(selectedFlatFolderNodes),
    );
  }

  dispatchSetFlatFolderNodesEmpty() {
    return this.store.dispatch(new SetFlatFolderNodesEmpty());
  }

  dispatchShareFoldersToOffices(
    shareFolderToOfficeRequests: ShareFolderRequest[],
  ) {
    this.store.dispatch(new ShareFoldersToOffices(shareFolderToOfficeRequests));
  }

  dispatchShareFoldersToRoles(
    shareFolderToRolesRequests: ShareFolderRequest[],
  ) {
    this.store.dispatch(new ShareFoldersToRoles(shareFolderToRolesRequests));
  }

  dispatchShareFoldersToUsers(
    shareFolderToUsersRequests: ShareFolderRequest[],
  ) {
    this.store.dispatch(new ShareFoldersToUsers(shareFolderToUsersRequests));
  }

  dispatchRenameFolder(folderId: string, folderName: string) {
    this.store.dispatch(new RenameFolder(folderId, folderName));
  }

  dispatchGetSharedFromFolders() {
    this.store.dispatch(new GetSharedFromFolders());
  }

  dispatchGetSharedToFolders() {
    this.store.dispatch(new GetSharedToFolders());
  }

  dispatchSetSelectedFolderPathFromRoot(pathFromRoot: string) {
    this.store.dispatch(new SetSelectedFolderPathFromRoot(pathFromRoot));
  }

  dispatchSetSheringModeFile() {
    this.store.dispatch(new SetSharingModeFile());
  }
  dispatchSetSheringModeFolder() {
    this.store.dispatch(new SetSharingModeFolder());
  }

  dispatchGetFolderProperty(folderId: string) {
    this.store.dispatch(new GetFolderProperty(folderId));
  }

  dispatchGetArchivedWorkspaceFolders(workspaceId: string) {
    this.store.dispatch(new GetArchivedWorkspaceFolders(workspaceId));
  }

  dispatchSelectArchivedFlatFolderNode(selectedFlatFolderNode: FlatFolderNode) {
    this.store.dispatch(
      new SelectArchivedFlatFolderNode(selectedFlatFolderNode),
    );
  }

  dispatchGetArchivedFolderProperty(folderId: string) {
    this.store.dispatch(new GetArchivedFolderProperty(folderId));
  }

  dispatchMoveFolderFromArchivedWorkspace(
    folderId: string,
    destinationWorkspaceId: string,
    currentWorkspaceId: string,
  ) {
    this.store.dispatch(
      new MoveFolderFromArchivedWorkspace(
        folderId,
        destinationWorkspaceId,
        currentWorkspaceId,
      ),
    );
  }

  dispatchGetWorkspaceFlatFolderNodes(workspaceId: string) {
    this.store.dispatch(new GetWorkspaceFlatFolderNodes(workspaceId));
  }

  dispatchSelectDestinationFlatFolderNode(
    selectedFlatFolderNode: FlatFolderNode | undefined,
  ) {
    this.store.dispatch(
      new SelectDestinationFlatFolderNode(selectedFlatFolderNode),
    );
  }

  dispatchSearchFolders(searchTerm: string) {
    this.store.dispatch(new SearchFolders(searchTerm));
  }

  dispatchTrashFolder(folderId: string) {
    this.store.dispatch(new TrashFolder(folderId));
  }

  dispatchGetTrashFolders(pageIndex: number, pageSize: number) {
    this.store.dispatch(new GetTrashedFolders(pageIndex, pageSize));
  }

  dispatchRestoreFolder(folderId: string) {
    this.store.dispatch(new RestoreFolder(folderId));
  }

  dispatchClearSelectedFlatFolderNode() {
    this.store.dispatch(new ClearSelectedFlatFolderNode());
  }
}
