import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  ProcessInstance,
  ProcessInstanceMultipleFilter,
} from 'src/app/researches/models/process-instance.model';
import { ProcessInstanceFormComponent } from '../process-instance-form/process-instance-form.component';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface ProcessInstanceListComponentState {
  processInstances: PaginatedList<ProcessInstance> | undefined;
  showSearchTermAndFiltersData: boolean;
  length: number;
  multipleFilterData: ProcessInstanceMultipleFilter | undefined;
  isProcessInstanceFilterReset: boolean;
}

const initialProcessInstanceListComponentState: ProcessInstanceListComponentState =
  {
    processInstances: undefined,
    showSearchTermAndFiltersData: false,
    length: 0,
    multipleFilterData: undefined,
    isProcessInstanceFilterReset: false,
  };

@Component({
  selector: 'app-process-instance-list',
  templateUrl: './process-instance-list.component.html',
  styleUrls: ['./process-instance-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: ProcessInstanceListComponent },
  ],
})
export class ProcessInstanceListComponent implements OnInit, MatPaginatorIntl {
  processInstances$ = this.state.select('processInstances');
  processInstances: PaginatedList<ProcessInstance> | undefined = undefined;

  showSearchTermAndFiltersData$ = this.state.select(
    'showSearchTermAndFiltersData',
  );
  showSearchTermAndFiltersData = false;
  pageSize: number = 10;
  pageIndex: number = 0;

  length$ = this.state.select('length');
  length: number = 0;

  multipleFilterData$ = this.state.select('multipleFilterData');
  multipleFilterData: ProcessInstanceMultipleFilter | undefined;

  isProcessInstanceFilterReset$ = this.state.select(
    'isProcessInstanceFilterReset',
  );

  firstPageLabel = $localize`:@@researches.process-instance-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.process-instance-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.process-instance-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.process-instance-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.process-instance-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.process-instance-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return (
      $localize`:@@researches.process-instance-list.page-part-one: Page` +
      `${page + 1}` +
      $localize`:@@researches.process-instance-list.page-part-two: of` +
      `${amountPages}`
    );
  }

  @ViewChild(MatPaginator, {static:false}) paginator!: MatPaginator;

  constructor(
    private processInstanceFacade: ProcessInstanceFacade,
    private state: RxState<ProcessInstanceListComponentState>,
    private dialog: MatDialog,
  ) {
    this.state.set(initialProcessInstanceListComponentState);
    this.state.connect(
      'processInstances',
      this.processInstanceFacade.processInstances$,
    );
    this.state.connect(
      'showSearchTermAndFiltersData',
      this.processInstanceFacade.showSearchTermAndFiltersData$,
    );
    this.state.connect('length', this.processInstanceFacade.totalCount$);
    this.state.connect(
      'multipleFilterData',
      this.processInstanceFacade.multipleFilterData$,
    );
    this.state.connect(
      'isProcessInstanceFilterReset',
      this.processInstanceFacade.isProcessInstanceFilterReset$,
    );
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.processInstanceFacade.dispachToggleSearchTermVisibility(false);
    this.processInstanceFacade.dispatchGetProcessInstances(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.length$.subscribe((length) => {
      this.length = length;
    });

    this.processInstances$.subscribe((processInstances) => {
      if (processInstances) {
        this.processInstances = processInstances;
        this.pageIndex = processInstances.pageNumber - 1;
      }
    });

    this.showSearchTermAndFiltersData$.subscribe((show) => {
      this.showSearchTermAndFiltersData = show;
    });

    this.multipleFilterData$.subscribe((multipleFilterData) => {
      this.multipleFilterData = multipleFilterData;
    });

    this.isProcessInstanceFilterReset$.subscribe(
      (isProcessInstanceFilterReset) => {
        if (isProcessInstanceFilterReset) {
          // this.paginator.firstPage()
          this.paginator._changePageSize(10)
        }
      },
    );
  }

  addProcessInstance() {
    this.processInstanceFacade.dispatchSetUpdateStatus(false);
    this.dialog.open(
      ProcessInstanceFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  loadPaginatedProcessInstances(event: PageEvent) {
    
    this.multipleFilterData$.subscribe((multipleFilterData) => {
      this.multipleFilterData = multipleFilterData;
    });

    if (this.multipleFilterData) {
      this.multipleFilterData.pageNumber = event.pageIndex + 1;
      this.multipleFilterData.pageSize = event.pageSize;
      this.processInstanceFacade.dispatchGetProcessInstancesByFilter(
        this.multipleFilterData,
      );
    } else {
      this.processInstanceFacade.dispatchGetProcessInstances(
        event.pageIndex + 1,
        event.pageSize,
      );
    }
  }

  hasGetProcessInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.GetProcessInstances,
    );
  }

  hasCreateProcessInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.CreateProcessInstance,
    );
  }

  hasSearchProcessInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.SearchProcessInstances,
    );
  }
}
