import { Component, OnInit } from '@angular/core';
import {
  DocumentForm,
  DocumentFormDetailDto,
} from '../../models/document-forms.model';
import { RxState } from '@rx-angular/state';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import { FormFieldFormComponent } from 'src/app/documents/template-fields/components/form-field-form/form-field-form.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { DocumentFormFormComponent } from '../document-form-form/document-form-form.component';
import {
  APPROVE_DOCUMENT_TEMPLATES_ROUTE,
  DOCUMENT_TEMPLATES_ROUTE,
} from 'src/app/core/constants/routes';
import { CreateDocumentFormComponent } from '../create-document-form/create-document-form.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';

interface DocumentFormDetailState {
  selectedDocumentFormDetail: DocumentFormDetailDto | undefined;
  selectedFormField?: FormFields;
}

const initDocumentFormDetailState: DocumentFormDetailState = {
  selectedDocumentFormDetail: undefined,
};

@Component({
  selector: 'app-document-form-detail',
  templateUrl: './document-form-detail.component.html',
  styleUrls: ['./document-form-detail.component.scss'],
})
export class DocumentFormDetailComponent implements OnInit {
  selectedDocumentFOrmDetail$ = this.DocumentFormDetailState.select(
    'selectedDocumentFormDetail',
  );
  selectedFormField$ = this.DocumentFormDetailState.select(
    'selectedFormField',
  );
  selectedDocumentFormDetail: DocumentFormDetailDto | undefined;
  selectedDocumentForm: DocumentForm | undefined;
  selectedFormField?: FormFields;
  isRejected: boolean = false;
  isApproved: boolean = false;
  isRollbacked: boolean = false;
  displayComment: boolean = false;

  groupedFields: Record<string, FormFields> = {};
  groupedFieldsColumns: string[] = [];
  unGroupedFields: FormFields[] = [];

  constructor(
    private DocumentFormDetailState: RxState<DocumentFormDetailState>,
    private documentFormFacade: DocumentFormFacade,
    private formFieldsFacade: FormFieldsFacade,
    private route: ActivatedRoute,
    private router: Router,
    private templateFieldsFacade: FormFieldsFacade,
    private dialog: MatDialog,
  ) {
    this.DocumentFormDetailState.set(initDocumentFormDetailState);
    this.DocumentFormDetailState.connect(
      'selectedDocumentFormDetail',
      this.documentFormFacade.selectedDocumentFormDetail$,
    );
    this.DocumentFormDetailState.connect(
      'selectedFormField',
      this.formFieldsFacade.selectedFormField$,
    );
  }

  ngOnInit(): void {
    this.selectedDocumentFOrmDetail$.subscribe((selectedDocumentFormDetail) => {
      this.selectedDocumentFormDetail = selectedDocumentFormDetail;
      
      this.formFieldsFacade.dispatchResetSelectedFormField()
      this.selectedFormField$.subscribe((field)=>{
        this.selectedFormField = field
      })

      this.groupedFields = (
        this.selectedDocumentFormDetail?.templateFields.filter(
          (field) => !!field.groupName,
        ) || []
      ).reduce(
        (prev, cur) => {
          prev[cur.name] = cur;
          return prev;
        },
        {} as Record<string, FormFields>,
      );

      this.groupedFieldsColumns = Object.keys(this.groupedFields)
      this.unGroupedFields =
        this.selectedDocumentFormDetail?.templateFields.filter(
          (field) => !field.groupName,
        ) || [];

      this.isApproved =
        this.selectedDocumentFormDetail?.status.status ==
        FormStatusOption.APPROVED;
      this.isRejected =
        this.selectedDocumentFormDetail?.status.status ==
        FormStatusOption.REJECTED;
      this.isRollbacked =
        this.selectedDocumentFormDetail?.status.status ==
        FormStatusOption.CHANGE_REQUESTED;
    });

  }

  get isDraftForm(): boolean {
    return (
      !!this.selectedDocumentFormDetail &&
      this.selectedDocumentFormDetail.status.status === FormStatusOption.DRAFT
    );
  }
  get isRollbackedForm(): boolean {
    return (
      !!this.selectedDocumentFormDetail &&
      this.selectedDocumentFormDetail.status.status ===
        FormStatusOption.CHANGE_REQUESTED
    );
  }

  get hasFields(): boolean {
    return (
      !!this.selectedDocumentFormDetail &&
      this.selectedDocumentFormDetail.templateFields.length > 0
    );
  }

  continueWorkingOnDraft(): void {
    this.documentFormFacade.dispatchSetUpdateStatus(true);
    this.dialog
      .open(CreateDocumentFormComponent)
      .afterClosed()
      .subscribe((_) => {
        this.documentFormFacade.dispatchSetUpdateStatus(false);
      });
  }

  approveForm() {
    if (!this.selectedDocumentFormDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-form-detail.approve-form-part-1:Are you sure you want to approve`,
        boldText: ` "${this.selectedDocumentFormDetail!.name}" `,
        regularTextTwo: $localize`:@@documents.document-template-detail.approve-form-part-2:document form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        const dispatched = this.documentFormFacade.dispatchChangeFormStatus({
          id: this.selectedDocumentFormDetail!.id as string,
          status: { status: FormStatusOption.APPROVED, comment: '' },
        });
        this.isApproved = true;
        this.isRejected = false;
        this.isRollbacked = false;

        dispatched.subscribe(() => {
          this.router.navigate([APPROVE_DOCUMENT_TEMPLATES_ROUTE]);
        });
      }
    });
  }
  rejectForm() {
    if (!this.selectedDocumentFormDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-template-detail.reject-form-part-1:Are you sure you want to reject`,
        boldText: ` "${this.selectedDocumentFormDetail!.name}" `,
        regularTextTwo: $localize`:@@documents.document-template-detail.reject-form-part-2:document form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        const dispatched = this.documentFormFacade.dispatchChangeFormStatus({
          id: this.selectedDocumentFormDetail!.id as string,
          status: { status: FormStatusOption.REJECTED, comment: '' },
        });
        this.isRejected = true;
        this.isApproved = false;
        this.isRollbacked = false;

        dispatched.subscribe(() => {
          this.router.navigate([APPROVE_DOCUMENT_TEMPLATES_ROUTE]);
        });
      }
    });
  }

  onAddFieldClicked() {
    this.documentFormFacade.dispatchSetSelectedDocumentForm(
      this.selectedDocumentFormDetail as DocumentForm,
    );
    this.documentFormFacade.dispatchGetDocumentForm(
      this.selectedDocumentFormDetail?.id as string,
    );
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(false);
    this.dialog.open(FormFieldFormComponent, SIDE_DIALOG_CONFIG);
  }

  onEditFieldClicked(field: FormFields) {
    this.documentFormFacade.dispatchSetTempalteCreatingMode(false);
    this.templateFieldsFacade.dispatchSetSelectedFormField(field);
    this.templateFieldsFacade.dispatchSetFormFieldUpdatingMode(true);
    this.dialog.open(FormFieldFormComponent, SIDE_DIALOG_CONFIG);
  }

  onDeleteFieldClicked(field: FormFields) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-form-detail.delete-field-clicked-part-1:Are you sure you want to delete`,
        boldText: ` "${field.name}" `,
        regularTextTwo: $localize`:@@documents.document-form-detail.delete-field-clicked-part-2:template field ?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        this.documentFormFacade.dispatchDeleteFieldOnFormDetail(
          field.id as string,
        );
      }
    });
  }

  rollback() {
    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        name: '',
        message: 'Change Request',
        regularTextOne: $localize`:@@documents.document-form-detail.change-request-part-1:Are you sure you want to request change`,
        boldText: `${this.selectedDocumentFormDetail?.name}`,
        regularTextTwo: $localize`:@@documents.document-template-detail.change-request-part-2:document template?`,
        extra: $localize`:@@documents.document-form-detail.change-request-extra:Write your change requests.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        const dispatched = this.documentFormFacade.dispatchChangeFormStatus({
          id: this.selectedDocumentFormDetail!.id as string,
          status: {
            status: FormStatusOption.CHANGE_REQUESTED,
            comment: result.comment,
          },
        });

        dispatched.subscribe(() => {
          this.router.navigate([APPROVE_DOCUMENT_TEMPLATES_ROUTE]);
        });
      }
    });
    this.isRejected = false;
    this.isApproved = false;
    this.isRollbacked = true;
  }

  seeComment() {
    this.displayComment = !this.displayComment;
  }

  submitForm() {
    if (!this.selectedDocumentFormDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-form-detail.submit-form-part-1:Are you sure you want to submit`,
        boldText: ` ${this.selectedDocumentFormDetail!.name} `,
        regularTextTwo: $localize`:@@documents.document-form-detail.submit-form-part-2:document form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.documentFormFacade.dispatchChangeFormStatus({
          id: this.selectedDocumentFormDetail!.id as string,
          status: {
            status: FormStatusOption.SUBMITTED,
            comment: this.selectedDocumentFormDetail?.status.comment,
          },
        });
      }
    });
    this.isRollbacked = false;
  }

  submitDraftForm() {
    if (!this.selectedDocumentFormDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.document-form-detail.submit-template-part-1:Are you sure you want to submit`,
        boldText: ` ${this.selectedDocumentFormDetail!.name} `,
        regularTextTwo: $localize`:@@documents.document-form-detail.submit-form-part-2:document form?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.documentFormFacade.dispatchChangeFormStatus({
          id: this.selectedDocumentFormDetail!.id as string,
          status: {
            status: FormStatusOption.SUBMITTED,
            comment: '',
          },
        });
        this.router.navigate([DOCUMENT_TEMPLATES_ROUTE]);
      }
    });
  }

  hasGetFormFieldValidationRulesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.FieldValidationRule.Feature,
      PERMISSION_NAMES.Documents.FieldValidationRule.GetFieldValidationRules,
    );
  }

  hasChangeFormStatusToApprovedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.ChangeFormStatusToApproved,
    );
  }

  hasChangeFormStatusToRejectedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.ChangeFormStatusToRejected,
    );
  }

  hasChangeFormStatusToChangeRequestedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.ChangeFormStatusToChangeRequested,
    );
  }

  hasChangeFormStatusToSubmittedPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.ChangeFormStatusToSubmitted,
    );
  }

  hasUpdateDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.UpdateDocumentForms,
    );
  }

  onFieldClick(field:FormFields){
    this.templateFieldsFacade.dispatchSetSelectedFormField(field);
  }
}
