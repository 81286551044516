<div class="container">
  <h1>{{ selectedAssignedStageInstance?.processInstance?.title }}</h1>
  <h2>{{ selectedAssignedStageInstance?.name }}</h2>
  <div class="header">
    <h3 *ngIf="stageInstanceReviewerType?.reviewerType !== 'TwoWayBlind'">{{ selectedAssignedStageInstance?.processInstance?.ownerName}} <span class="chip">Owner Name</span></h3>
    <h3>{{ stageInstanceReviewerType?.reviewerType}} <span class="chip">Review Type</span></h3>
  </div>
  <div class="main-table mat-elevation-z4">
    <mat-paginator
      [length]="assignedTaskDocuments?.totalCount"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedTaskDocument($event)"
    >
    </mat-paginator>

    <mat-table [dataSource]="dataSource" class="table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef i18n="@@researches.review-detail.name">Name</mat-header-cell>
        <mat-cell *matCellDef="let task">{{ task.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef i18n="@@researches.review-detail.type">Type</mat-header-cell>
        <mat-cell *matCellDef="let task">{{ task.fileType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef i18n="@@researches.review-detail.created-at">Created At</mat-header-cell>
        <mat-cell *matCellDef="let task">{{ task.createdAt }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef i18n="@@researches.review-detail.created-by">Created By</mat-header-cell>
        <mat-cell *matCellDef="let task">{{ task.createdBy }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef i18n="@@researches.review-detail.actions">Actions</mat-header-cell>
        <mat-cell *matCellDef="let task" class="icon-cell">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            *appHasPermission="hasGetFileTaskPermission()"
            (click)="onMenuClick($event, task)"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="['name', 'type', 'createdAt', 'createdBy', 'actions']"
      ></mat-header-row>

      <mat-row
        class="table-row"
        *matRowDef="
          let process;
          columns: ['name', 'type', 'createdAt', 'createdBy', 'actions']
        "
      ></mat-row>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="previewDocument()"
        *appHasPermission="hasGetFileTaskPermission()"
        i18n="@@researches.review-detail.preview-document">
          Preview Document
        </button>
        <ng-container *appHasPermission="hasGetFileTaskPermission()">
        <button
          *ngIf="isTableDocument()"
          mat-menu-item
          (click)="downloadDocumentAsPdf()"
          i18n="@@researches.review-detail.download-as-pdf"
        >
          Download As PDF
        </button>
        <button
          *ngIf="isTableDocument() == false"
          mat-menu-item
          (click)="downloadDocument()"
          i18n="@@researches.review-detail.download-document"
        >
          Download Document
        </button>
      </ng-container>
      </mat-menu>
    </mat-table>
  </div>
  <mat-card class="editor-container">
    <mat-card-title class="editor-title" i18n="@@researches.review-detail.add-comment">Add Comment</mat-card-title>
    <div class="editor--wrapper">
      <div id="editor"></div>
      <div class="editor--actions">
        <div class="w-100">
          <div
            class="drop-zone flex-center"
            [class.dragover]="isDragOver"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
          >
            <ng-container *ngIf="files.length === 0">
              <p i18n="@@documents.notices-form.drag-and-drop-files-here">Drag and drop files here</p>
            </ng-container>
            <div *ngFor="let file of files">
              <div class="flex-center">
                <p>{{ file.name }}</p>
                  <mat-icon class="pointer" color="warn" (click)="removeFile(file)"
                  >close</mat-icon>
              </div>
            </div>
            <button mat-flat-button color="primary"  i18n="@@documents.   notices-form.select-files" (click)="selectFile()" [disabled]="isStatusSubmitted()" [attr.data-test-id]="'researches.upload-review-file.review-detail.select-files-button'">
              Select Files
            </button>
          </div>
          <div class="button-position">
            <div>
            <p i18n="@@documents.notices-form.selected-files">Selected Files</p>
              <mat-chip class="file-chip" *ngFor="let attachment of myStageInstancesReview?.attachments" [disabled]="isStatusSubmitted()" (removed)="deleteReviewAttachment(attachment?.id)">
                {{ attachment.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </div>
            <div class="save-btn">
              <button mat-button (click)="cancle()" i18n="@@researches.review-detail.cancel">Cancel</button>
              <button
                mat-flat-button
                color="primary"
                [disabled]="isSaveButtonDisabled()"
                (click)="createReview()"
                *appHasPermission="hasReviewStageInstancePermission()"
                i18n="@@researches.review-detail.save"
                [attr.data-test-id]="'researches.add-review.requested-reviews.save-button'"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  <div>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isSubmitButtonDisabled()"
      (click)="submitReview()"
      *appHasPermission="hasReviewStageInstancePermission()"
      i18n="@@researches.review-detail.submit"
      [attr.data-test-id]="'researches.add-review.requested-reviews.submit-button'"
    >
      Submit
    </button>
  </div>
</div>
