import {
  GetFormDetailByStatus,
  SearchDocumentForms,
  SetFormSearchingMode,
  OrderFormFields,
  GetArchivedDocumentForms,
  ToggleAddFieldStep,
} from '../store/document-form.actions';
import { Injectable } from '@angular/core';
import { DocumentFormSelector } from '../store/document-form.selector';
import { Select, Store } from '@ngxs/store';
import {
  CreateDocumentForm,
  DocumentForm,
  DocumentFormDetailDto,
  UpdateDocumentFormDto,
  UpdateFormStatusDto,
} from '../models/document-forms.model';
import { Observable } from 'rxjs';
import {
  CreateForm,
  DeleteDocumentForm,
  GetDocumentForm,
  DeleteDraftDocumentForm,
  GetDocumentForms,
  GetDraftForms,
  ResetSelectedDocumentForm,
  SetSelectedDocumentForm,
  SetFormCreatingMode,
  SetUpdateStatus,
  UpdateDocumentForm,
  GetSelectedDocumentForm,
  ChangeFormStatus,
  SaveForm,
  AddFieldOnFormDetail,
  UpdateFieldOnFormDetail,
  DeleteFieldOnFormDetail,
  GetApprovedForms,
  SelectedFormType,
} from '../store/document-form.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { FormStatusOption } from '../../shared/models/document-form-status';
import { GetDocumentFormsByStatus } from '../store/document-form.actions';
import { FormFields } from '../../template-fields/models/form-fields.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentFormFacade {
  @Select(DocumentFormSelector.templates)
  templates$!: Observable<PaginatedList<DocumentForm>>;

  @Select(DocumentFormSelector.documentForms)
  documentForms$!: Observable<DocumentForm[]>;

  @Select(DocumentFormSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(DocumentFormSelector.selectedDocumentForms)
  selectedDocumentForm$!: Observable<DocumentForm | undefined>;

  @Select(DocumentFormSelector.selectedDocumentFormDetail)
  selectedDocumentFormDetail$!: Observable<
    DocumentFormDetailDto | undefined
  >;

  @Select(DocumentFormSelector.updateStatus)
  updateStatus$!: Observable<boolean>;

  @Select(DocumentFormSelector.isCreatingForm)
  isCreatingForm$!: Observable<boolean>;

  @Select(DocumentFormSelector.draftForms)
  draftForms$!: Observable<PaginatedList<DocumentForm>>;

  @Select(DocumentFormSelector.isSearchingForm)
  isSearchingForm$!: Observable<boolean>;

  @Select(DocumentFormSelector.selectedFormType)
  selectedFormType$!: Observable<string>;

  @Select(DocumentFormSelector.isAddFieldStepSelected)
  isAddFieldStepSelected$!: Observable<boolean>;

  constructor(private store: Store) {}

  dispatchGetDocumentForms(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetDocumentForms(pageNumber, pageSize));
  }

  dispatchGetDocumentFormsByStatus(
    status: FormStatusOption,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetDocumentFormsByStatus(status, pageNumber, pageSize),
    );
  }

  dispatchSetSelectedDocumentForm(
    selectedDocumentForm: DocumentForm | undefined,
  ) {
    this.store.dispatch(
      new SetSelectedDocumentForm(selectedDocumentForm),
    );
  }

  dispatchResetSelectedDocumentForm() {
    this.store.dispatch(new ResetSelectedDocumentForm());
  }
  dispatchCreateForm(template: CreateDocumentForm) {
    this.store.dispatch(new CreateForm(template));
  }

  dispatchSaveForm(template: CreateDocumentForm) {
    this.store.dispatch(new SaveForm(template));
  }

  dispatchUpdateDocumentForm(updateForm: UpdateDocumentFormDto) {
    this.store.dispatch(new UpdateDocumentForm(updateForm));
  }

  dispatchSetUpdateStatus(updateStatus: boolean) {
    this.store.dispatch(new SetUpdateStatus(updateStatus));
  }

  dispatchGetDraftForms(
    pageNumber: number | null,
    pageSize: number | null,
  ) {
    this.store.dispatch(new GetDraftForms(pageNumber, pageSize));
  }

  dispatchGetFormDetailByStatus(id: string, status: FormStatusOption) {
    this.store.dispatch(new GetFormDetailByStatus(id, status));
  }

  dispatchDeleteDocumentForm(id: string) {
    this.store.dispatch(new DeleteDocumentForm(id));
  }

  dispatchGetDocumentFormDetail(id: string) {
    this.store.dispatch(new GetDocumentForm(id));
  }

  dispatchGetArchivedDocumentForms(pageNumber?: number, pageSize?: number, templateName?: string) {
    this.store.dispatch(new GetArchivedDocumentForms(pageNumber, pageSize, templateName));
  }

  dispatchDeleteDraftDocumentForm(id: string) {
    this.store.dispatch(new DeleteDraftDocumentForm(id));
  }

  dispatchSetTempalteCreatingMode(isCreatingForm: boolean) {
    this.store.dispatch(new SetFormCreatingMode(isCreatingForm));
  }

  dispatchGetDocumentForm(id: string) {
    this.store.dispatch(new GetSelectedDocumentForm(id));
  }

  dispatchAddFieldOnFormDetail(newFormField: FormFields) {
    this.store.dispatch(new AddFieldOnFormDetail(newFormField));
  }

  dispatchChangeFormStatus(updatedForm: UpdateFormStatusDto) {
    return this.store.dispatch(new ChangeFormStatus(updatedForm));
  }

  dispatchUpdateFieldOnFormDetail(updatedField: FormFields) {
    return this.store.dispatch(new UpdateFieldOnFormDetail(updatedField));
  }

  dispatchDeleteFieldOnFormDetail(fieldId: string) {
    this.store.dispatch(new DeleteFieldOnFormDetail(fieldId));
  }

  dispatchSearchDocumentForms(
    status: string,
    templateName: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new SearchDocumentForms(status,templateName, pageNumber, pageSize),
    );
  }

  dispatchSetFormSearchingMode(isSearchingForm: boolean) {
    this.store.dispatch(new SetFormSearchingMode(isSearchingForm));
  }

  dispatchOrderFormFields(fields: FormFields[]) {
    this.store.dispatch(new OrderFormFields(fields));
  }

  dispatchGetApprovedForms(pageNumber?: number, pageSize?: number) {
    this.store.dispatch(new GetApprovedForms(pageNumber, pageSize));
  }

  dispatchSetSelectedFormType(selectedFormType: string) {
    this.store.dispatch(new SelectedFormType(selectedFormType));
  }

  dispatchToggleAddFieldStep(isAddfieldStepSelected: boolean) {
    this.store.dispatch(new ToggleAddFieldStep(isAddfieldStepSelected));
  }
}
