<form
  class="field-validation-form"
  [formGroup]="fieldValidationRuleForm"
  (ngSubmit)="addValidationRule()"
>
  <p
    class="mat-subheading-1"
    i18n="@@documents.add-field-validation-rules.field-validation-rule"
  >
    Field Validation Rule
  </p>

  <mat-form-field appearance="outline">
    <mat-label
      i18n="@@documents.add-field-validation-rules.choose-validation-rule"
      >Choose Validation Rule</mat-label
    >
    <mat-select
      formControlName="validationRule"
      [compareWith]="compareFunction"
      [disabled]="!!(formDisabled | async) || dataType == 'Boolean'"
    >
      <mat-option *ngFor="let rule of validationRules" [value]="rule">
        {{ rule.name }}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="
        fieldValidationRuleForm.controls.validationRule.errors?.[
          'ruleMustBeUnique'
        ]
      "
      >Validation rule is already added.</mat-error
    >
  </mat-form-field>

  <ng-container formArrayName="values">
    <ng-container
      *ngFor="let value of validationRulesValues.controls; let i = index"
    >
      <div [formGroupName]="i">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label i18n="@@documents.add-field-validation-rules.parameter"
            >Parameter</mat-label
          >
          <input
            matInput
            placeholder="value"
            formControlName="value"
            [disabled]="!!(formDisabled | async)"
          />
        </mat-form-field>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="formDisabled | async" class="caption mat-caption">
    Remove <strong>'isEmpty'</strong> validation in order to add other
    validations.
  </div>

  <div class="row">
    <button
      mat-stroked-button
      color="primary"
      *ngIf="!isInUpdateMode"
      [disabled]="!fieldValidationRuleForm.valid || dataType == 'Boolean' || !fieldValidationRuleForm.controls.validationRule.value"
    >
      <mat-icon>add</mat-icon>
      <span i18n="@@documents.add-field-validation-rules.add-rule">
        Add Rule
      </span>
    </button>

    <button
      mat-stroked-button
      color="primary"
      (click)="updateValidationRuleForEdition()"
      *ngIf="isInUpdateMode"
      type="button"
      i18n="@@documents.add-field-validation-rules.update"
    >
      Update
    </button>
    <button
      mat-stroked-button
      color="warn"
      (click)="cancelEdition()"
      type="button"
      *ngIf="isInUpdateMode"
      i18n="@@documents.add-field-validation-rules.cancel"
    >
      Cancel
    </button>
  </div>
</form>

<mat-card
  class="rules-card"
  *ngIf="((currentFieldValidationRules$ | async)?.length || 0) > 0"
>
  <mat-card-content>
    <mat-list>
      <mat-list-item
        role="listitem"
        *ngFor="let rule of currentFieldValidationRules$ | async; let i = index"
      >
        <div class="list-row">
          <p class="mat-subheading-2">
            {{ rule.validationRule.name }}
            <ng-container *ngIf="rule.validationRuleValues.length != 0">
              ({{ formatRuleValues(rule.validationRuleValues) }})
            </ng-container>
          </p>

          <div>
            <button
              mat-icon-button
              aria-label="Edit"
              (click)="selectValidationRuleForEdition(rule, i)"
              [disabled]="isRuleMandatory(rule.validationRule.id)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="delete"
              (click)="deleteFieldValidationRule(rule)"
              [disabled]="isRuleMandatory(rule.validationRule.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
