export enum ProcessCategory {
  RESEARCH = 'Research',
  BRIEFING = 'Briefing',
}

export interface Process {
  id?: string | null;
  name: string;
  description: string;
  code: string;
  stagesCount?: number;
  enforceSequential?: boolean;
  processCategory: ProcessCategory;
  processCategoryForDisplay?: ProcessCategoryForDisplay
}

export interface ProcessCategoryForDisplay{
  id: string,
  processCategory: string,
  processCategoryEnum?: string;
}
