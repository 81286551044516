<mat-dialog-content>
  <h2 mat-dialog-title i18n="@@researches.publication-template-download.download-templates">Download Templates</h2>
  <mat-toolbar class="row-container">
    <span class="title">
      {{ selectedPublicationOption?.title }}
    </span>
    <button
      mat-button
      class="download"
      aria-label="Download"
      [matMenuTriggerFor]="menu"
      *appHasPermission="hasDownloadPublicationTemplatePermission()"
    >
      <mat-icon>download</mat-icon>
      <span i18n="@@researches.publication-template-download.download-template">Download Template</span>
    </button>

    <mat-menu #menu="matMenu">
      <button
        *ngFor="let option of selectedPublicationOption?.publicationTemplate"
        mat-menu-item
        class="mat-item-download"
        (click)="downloadTemplate(option)"
      >
        {{ option.editorType.name }}
      </button>
    </mat-menu>
    <span class="spacer"></span>
  </mat-toolbar>
</mat-dialog-content>
