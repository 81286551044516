import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Role } from '../../models/role.model';
import { Observable } from 'rxjs';
import { RoleFacade } from '../../facade/role.facade';
import { User } from '../../models/user.model';
import { UserFacade } from '../../facade/user.facade';

interface AssignRevokeRolesUsersComponentState {
  roles: Role[];
  selectedUser: User | undefined;
  selectedRoles: Set<string>;
}

const initAssignRevokeRolesUsersComponentState: AssignRevokeRolesUsersComponentState =
  {
    roles: [],
    selectedUser: undefined,
    selectedRoles: new Set<string>(),
  };

@Component({
  selector: 'app-assign-revoke-roles-users',
  templateUrl: './assign-revoke-roles-users.component.html',
  styleUrl: './assign-revoke-roles-users.component.scss',
  providers: [RxState],
})
export class AssignRevokeRolesUsersComponent implements OnInit {
  roles: Role[] = [];
  roles$: Observable<Role[]> = this.state.select('roles');
  selectedUser: User | undefined = undefined;
  selectedUser$: Observable<User | undefined> =
    this.state.select('selectedUser');
  selectedRoles: Set<string> = new Set<string>();
  selectedRoles$: Observable<Set<string>> = this.state.select('selectedRoles');

  constructor(
    private state: RxState<AssignRevokeRolesUsersComponentState>,
    private roleFacade: RoleFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initAssignRevokeRolesUsersComponentState);
    this.state.connect('roles', this.roleFacade.roles$);
    this.state.connect('selectedUser', this.userFacade.selectedUser$);

    this.selectedUser$.subscribe((selectedUser) => {
      this.selectedUser = selectedUser;
      this.roles$.subscribe((roles) => {
        this.roles = roles;
        this.roles.forEach((role) => {
          const checked = selectedUser?.roles.some
            ? selectedUser?.roles.some((r) => r.id === role.id)
            : false;
          if (checked) this.selectedRoles.add(role.id);
          role.checked = checked;
          return checked;
        });
      });
    });
  }

  ngOnInit(): void {
    this.roleFacade.dispatchGetRoles();
    if (this.selectedUser?.id)
      this.userFacade.dispatchGetUserRoles(this.selectedUser.id);
  }

  updateRoleState(checked: boolean, roleId: string) {
    checked
      ? this.selectedRoles.add(roleId)
      : this.selectedRoles.delete(roleId);
  }

  submit() {
    const roleIds = Array.from(this.selectedRoles.values());
    if (this.selectedUser?.id)
      this.userFacade.dispatchAssignRevokeUserRoles(
        roleIds,
        this.selectedUser?.id,
        undefined,
      );
  }
}
