import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RxState } from '@rx-angular/state';
import {
  Feature,
  Module,
  Permission,
  PermissionWithDependenciesId,
  SelectedPermissionDependency,
} from '../../models/permission.model';
import { PermissionFacade } from '../../facade/permission.facade';
import { Observable } from 'rxjs';
import { RoleFacade } from '../../facade/role.facade';
import { Role } from '../../models/role.model';

interface AssignRevokePermissionsComponentState {
  modules: Module[];
  selectedRole: Role | undefined;
  selectedPermissions: Set<string>;
  selectedPermissionWithoutDependencies: Set<string>;
  modulesList: Module[];
  featuresList: Feature[] | undefined;
  selectedPermissionDependency: SelectedPermissionDependency;
  permissionsWithDependenciesId: PermissionWithDependenciesId[] | undefined;
}

const initAssignRevokePermissionsComponentState: AssignRevokePermissionsComponentState =
  {
    modules: [],
    selectedRole: undefined,
    selectedPermissions: new Set<string>(),
    selectedPermissionWithoutDependencies: new Set<string>(),
    modulesList: [],
    featuresList: [],
    selectedPermissionDependency: {} as SelectedPermissionDependency,
    permissionsWithDependenciesId: [],
  };

@Component({
  selector: 'app-assign-revoke-permissions',
  templateUrl: './assign-revoke-permissions.component.html',
  styleUrl: './assign-revoke-permissions.component.scss',
  providers: [RxState],
})
export class AssignRevokePermissionsComponent implements OnInit {
  modules: Module[] = [];
  modules$: Observable<Module[]> = this.state.select('modules');
  selectedRole$: Observable<Role | undefined> =
    this.state.select('selectedRole');
  selectedRole: Role | undefined;
  selectedPermissions: Set<string> = new Set<string>();
  selectedPermissions$: Observable<Set<string>> = this.state.select(
    'selectedPermissions',
  );
  selectedPermissionWithoutDependencies: Set<string> = new Set<string>();
  selectedPermissionWithoutDependencies$: Observable<Set<string>> =
    this.state.select('selectedPermissionWithoutDependencies');
  modulesList: Module[] = [];
  modulesList$: Observable<Module[]> = this.state.select('modulesList');
  featuresList: Feature[] | undefined = [];
  featuresList$: Observable<Feature[] | undefined> =
    this.state.select('featuresList');
  permissionsWithDependenciesId: PermissionWithDependenciesId[] | undefined =
    [];
  permissionsWithDependenciesId$: Observable<
    PermissionWithDependenciesId[] | undefined
  > = this.state.select('permissionsWithDependenciesId');

  selectedPermissionDependency: SelectedPermissionDependency =
    {} as SelectedPermissionDependency;
  selectedPermissionDependency$: Observable<SelectedPermissionDependency> =
    this.state.select('selectedPermissionDependency');

  selectedModuleId: string = '';
  selectedFeatureId: string = '';

  constructor(
    private state: RxState<AssignRevokePermissionsComponentState>,
    private permissionFacade: PermissionFacade,
    private roleFacade: RoleFacade,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.state.set(initAssignRevokePermissionsComponentState);
    this.state.connect('modules', this.permissionFacade.modules$);
    this.state.connect('selectedRole', this.roleFacade.selectedRole$);
    this.state.connect(
      'permissionsWithDependenciesId',
      this.permissionFacade.permissionsWithDependenciesId$,
    );
  }

  ngOnInit(): void {
    this.permissionFacade.dispatchGetPermissions();
    this.permissionFacade.dispatchGetPermissionsWithDependenciesId();

    this.permissionsWithDependenciesId$.subscribe((p) => {
      this.permissionsWithDependenciesId = p;

      this.permissionsWithDependenciesId?.forEach((p) => {
        p.dependencies?.forEach((d) => {});
      });
    });

    this.selectedRole$.subscribe((selectedRole) => {
      if (selectedRole) {
        this.selectedRole = selectedRole;

        this.modules$.subscribe((modules) => {
          this.modules = modules;
          this.modulesList = modules;

          this.modules.forEach((module) => {
            module.features.forEach((feature) => {
              feature.permissions.forEach((p) => {
                const isChecked =
                  selectedRole.permissions?.some(
                    (p2) =>
                      p2.id === p.id && p2.permissionName === p.permissionName,
                  ) ?? false;

                if (isChecked) {
                  p.checked = true;
                  this.selectedPermissions.add(p.id);
                  this.selectedPermissionWithoutDependencies.add(p.id);
                  const dependencies = this.permissionsWithDependenciesId?.find(
                    (pe) => pe.permissionId == p.id,
                  )?.dependencies;

                  dependencies?.forEach((d) => {
                    this.updateSelectedPermissionDependency(
                      d.permissionId,
                      p.id,
                    );
                  });
                }
              });

              feature.checked = feature.permissions.every((p) => p.checked);
            });

            module.checked = module.features.every((f) => f.checked);
          });
        });
      }
    });
  }

  updateSelectedPermissionDependency(key: string, item: string) {
    if (!this.selectedPermissionDependency[key]) {
      this.selectedPermissionDependency[key] = new Set<string>();
    }
    this.selectedPermissionDependency[key].add(item);
  }

  removeSelectedPermissionDependency(key: string, item: string) {
    if (this.selectedPermissionDependency[key]) {
      this.selectedPermissionDependency[key].delete(item);
    }
    return this.selectedPermissionDependency[key];
  }

  updatePermissionState(checked: boolean, permissionId: string) {
    const dependencies = this.permissionsWithDependenciesId?.find(
      (pe) => pe.permissionId == permissionId,
    )?.dependencies;

    if (checked) {
      this.selectedPermissions.add(permissionId);
      this.selectedPermissionWithoutDependencies.add(permissionId);

      dependencies?.forEach((d) => {
        this.selectedPermissions.add(d.permissionId);
        this.updateSelectedPermissionDependency(d.permissionId, permissionId);
      });
    } else {
      dependencies?.forEach((d) => {
        let res = this.removeSelectedPermissionDependency(
          d.permissionId,
          permissionId,
        );

        if (res.size === 0) {
          this.selectedPermissions.delete(d.permissionId);
          this.selectedPermissionWithoutDependencies.delete(permissionId);
        }
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  submit() {
    const permissionIds = Array.from(
      this.selectedPermissionWithoutDependencies.values(),
    );
    if (this.selectedRole?.id)
      this.roleFacade.dispatchAssignRevokePermissions(
        this.selectedRole?.id,
        permissionIds,
      );
  }

  setModuleLevel(checked: boolean, moduleId: string) {
    const features = this.modules.find((m) => m.id === moduleId)?.features;

    if (checked) {
      features?.forEach((f) => {
        f.checked = true;
        f.permissions.forEach((p) => {
          p.checked = true;
          this.selectedPermissions.add(p.id);
          this.selectedPermissionWithoutDependencies.add(p.id);
        });
      });
    } else
      features?.forEach((f) => {
        f.checked = false;
        f.permissions.forEach((p) => {
          p.checked = false;
          this.selectedPermissions.delete(p.id);
          this.selectedPermissionWithoutDependencies.delete(p.id);
        });
      });
  }

  setFeatureLevel(checked: boolean, featureId: string) {
    const permissions = this.modules
      .map((m) => m.features)
      .flat()
      .find((f) => f.id == featureId)?.permissions;

    if (checked) {
      permissions?.forEach((p) => {
        p.checked = true;
        this.selectedPermissions.add(p.id);
        this.selectedPermissionWithoutDependencies.add(p.id);
      });
    } else
      permissions?.forEach((p) => {
        p.checked = false;
        this.selectedPermissions.delete(p.id);
        this.selectedPermissionWithoutDependencies.delete(p.id);
      });
  }

  filterByModule(moduleId: string) {
    this.selectedModuleId = moduleId;

    this.modules$.subscribe((modules) => {
      this.modules = this.modulesList.filter((m) => m.id === moduleId);
      this.featuresList = this.modulesList.find((m) => m.id === moduleId)
        ?.features;
    });
  }

  filterByFeature(featureId: string) {
    this.selectedFeatureId = featureId;

    this.modules$.subscribe((modules) => {
      this.modules = this.modulesList
        .filter((m) => m.id === this.selectedModuleId)
        .map((m) => ({
          ...m,
          features: m.features.filter((f) => f.id === featureId),
        }));
    });
  }

  clearFilters() {
    this.selectedModuleId = '';
    this.selectedFeatureId = '';
    this.modules = this.modulesList;
    this.featuresList = undefined;
  }

  getDependencyCount(permissionId: string): {
    dependencies: number | null;
    tooltipString: string;
  } {
    const dependencies = this.permissionsWithDependenciesId?.find(
      (p) => p.permissionId == permissionId,
    )?.dependencies;

    return {
      dependencies: dependencies?.length ? dependencies.length - 1 : null,
      tooltipString:
        dependencies?.map((d) => d.permissionName).join(', ') ||
        $localize`:@@users.assign-revoke-permissions.no-permissions-dependencies: No permissions dependencies`,
    };
  }

  isChecked(permissionId: string) {
    return this.selectedPermissions.has(permissionId);
  }

  hasReason(permissionId: string): string | null {
    const reasonPermissions = this.selectedPermissionDependency[permissionId];

    if (reasonPermissions == undefined) return null;

    if (reasonPermissions.size == 0) return null;

    if (reasonPermissions.size == 1 && reasonPermissions.has(permissionId))
      return null;

    let reason =
      $localize`:@@users.assign-revoke-permissions.disabled-permission-part-1: Disabled because of` +
      `${reasonPermissions.size}` +
      $localize`:@@users.assign-revoke-permissions.disabled-permission-part-2: permissions dependency. To remove this, please revoke `;

    return reason;
  }
}
