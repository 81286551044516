import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowStepState,
  WorkflowStepStateModel,
} from './workflow-step.state';

export class WorkflowStepSelector {
  static slices =
    createPropertySelectors<WorkflowStepStateModel>(WorkflowStepState);

  @Selector([WorkflowStepState])
  static workflowStepDetail(stateModel: WorkflowStepStateModel) {
    return stateModel.workflowStepDetail;
  }

  @Selector([WorkflowStepState])
  static workflowStep(stateModel: WorkflowStepStateModel) {
    return stateModel.workflowStep;
  }
  @Selector([WorkflowStepState])
  static notifiedUsers(stateModel: WorkflowStepStateModel) {
    return stateModel.notifiedUsers;
  }
  @Selector([WorkflowStepState])
  static workflowSteps(stateModel: WorkflowStepStateModel) {
    return stateModel.workflowSteps;
  }

  @Selector([WorkflowStepState])
  static ccdWorkflowstep(state: WorkflowStepStateModel) {
    return state.ccdworkflowStep;
  }
  @Selector([WorkflowStepState])
  static slectedWorkflowWorkflowSteps(state: WorkflowStepStateModel) {
    return state.slectedWorkflowWorkflowSteps;
  }

  @Selector([WorkflowStepState])
  static workflowStepFiles(state: WorkflowStepStateModel) {
    return state.workflowStepFiles;
  }
}
