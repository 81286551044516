import { Component } from '@angular/core';
import { AverageTimeSpentWorkflowTypeStep } from '../../models/workflow-step-average-time.model';
import { MatTableDataSource } from '@angular/material/table';
import { RxState } from '@rx-angular/state';
import { WorkflowTypeAverageTimeFacade } from '../../facades/workflow-step-average-time.facade';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { MatDialog } from '@angular/material/dialog';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';

interface WorkflowStepAveragerTimeComponentState {
  workflowTypeSteps: PaginatedList<AverageTimeSpentWorkflowTypeStep>;
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
}

const initialorkflowStepAveragerTimeComponentState: WorkflowStepAveragerTimeComponentState =
  {
    workflowTypeSteps: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    selectedFlatOfficeNode: undefined,
  };

@Component({
  selector: 'app-workflow-step-averager-time',
  templateUrl: './workflow-step-averager-time.component.html',
  styleUrls: ['./workflow-step-averager-time.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowStepAveragerTimeComponent}],
})
export class WorkflowStepAveragerTimeComponent {
  workflowTypeSteps$ = this.state.select('workflowTypeSteps');
  displayedColumns: string[] = [
    'WorkflowTypeStepName',
    'WorkflowTypeName',
    'AverageTimeInDays',
  ];
  dataSource!: MatTableDataSource<any>;
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  filterForm: FormGroup;

  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  firstPageLabel = $localize`:@@documents.workflow-step-averager-time.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.workflow-step-averager-time.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.workflow-step-averager-time.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.workflow-step-averager-time.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.workflow-step-averager-time.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.workflow-step-averager-time.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.workflow-step-averager-time.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow-step-averager-time.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<WorkflowStepAveragerTimeComponentState>,
    private workflowTypeAverageTimeFacade: WorkflowTypeAverageTimeFacade,
    private formBuilder: FormBuilder,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
  ) {
    this.state.set(initialorkflowStepAveragerTimeComponentState);
    this.state.connect(
      'workflowTypeSteps',
      this.workflowTypeAverageTimeFacade.workflowTypeSteps$,
    );
    this.filterForm = this.formBuilder.group({
      searchName: [''],
    });
  }
  changes = new RxState();
  ngOnInit(): void {
    this.workflowTypeAverageTimeFacade.dispatchWorkflowTypeAverageTimeReport(
      1,
      10,
    );
    this.workflowTypeSteps$.subscribe((steps) => {
      this.dataSource =
        new MatTableDataSource<AverageTimeSpentWorkflowTypeStep>(steps.items);
      this.length = steps.totalCount;
    });
  }

  loadPaginatedReport(event: PageEvent) {
    const searchName = this.filterForm.get('searchName')?.value;
    this.workflowTypeAverageTimeFacade.dispatchWorkflowTypeAverageTimeReport(
      event.pageIndex + 1,
      event.pageSize,
      searchName,
    );
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilter();
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilter();
    });
  }

  applyFilter() {
    if (!this.filterForm) return;
    const searchName = this.filterForm.get('searchName')?.value;
    let officeId = undefined;
    this.selectedFlatOfficeNode$.subscribe((office) => {
      if (office) {
        officeId = office.id;
      }
    });
    this.workflowTypeAverageTimeFacade.dispatchWorkflowTypeAverageTimeReport(
      1,
      10,
      searchName,
      officeId
    );
  }

  convertDaysToDaysAndHours(days: number): string {
    const wholeDays = Math.floor(days);
    const hours = Math.round((days - wholeDays) * 24);
    return `${wholeDays} day${wholeDays === 1 ? '' : 's'} and ${hours} hour${
      hours === 1 ? '' : 's'
    }`;
  }

  resetFilter() {
    this.filterForm.reset();
    this.officeFacade.dispatchResetSelectedOffice();
    this.workflowTypeAverageTimeFacade.dispatchWorkflowTypeAverageTimeReport(
      1,
      10,
    );
  }
}
