import { select } from 'd3';
import { ExternalDocumentResponseDto, ResponseDto } from './../models/external-document.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StateToken, State, Store, Action, StateContext } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { ExternalDocumentService } from '../services/external-document.service';
import {
  CreateExternalDocument,
  GetExternalDocument,
  DeleteExternalDocument,
  GetExternalDocumentDetail,
  ResetSelectedExternalDocument,
  SetSelectedExternalDocument,
  UpdateExternalDocument,
  SendRemark,
  CreateResponse,
  UpdateResponse,
  GetExternalDocumentResponseDetail,
  DeleteExternalDocumentResponse,
  SendResponseRemark,
  GetExternalDocumentResponses,
  DownloadAttachment,
  PreviewAttachment,
  DownloadResponseAttachment,
  PreviewResponseAttachment,
  GetRemarkActionStatus,
  SetFormSuccessful,
  SetFormNotSuccessful, ResetExternalDocumentDetail, ResetExternalDocumentResponseDetail,
  SetSelectedExternalDocumentResponse
} from "./external-document.actions";
import { tap } from 'rxjs';
import {
  SetProgressOn,
  SetProgressOff,
} from 'src/app/core/store/progress-status.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ExternalDocumentDetailDto,
  ExternalDocumentDto,
  ExternalDocumentResponseDetailDto,
  RemarkActionStatusForDisplay,
} from '../models/external-document.model';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import {
  insertItem,
  patch,
  updateItem,
  removeItem,
  append,
} from '@ngxs/store/operators';
import { GetListOfTasksWithInProcessType } from 'src/app/researches/store/task.actions';

export interface ExternalDocumentStateModel {
  externalDocuments: PaginatedList<ExternalDocumentDto>;
  externalDocumentDetail: ExternalDocumentDetailDto | undefined;
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;
  selectedExternalDocument: ExternalDocumentDto | undefined;
  selectedExternalDocumentResponse: ExternalDocumentResponseDto | undefined;
  externalDocumentResponses: PaginatedList<ExternalDocumentResponseDto>;
  remarkActionsForDisplay: RemarkActionStatusForDisplay[];
  isFormSuccessful:boolean;
}

const EXTERNAL_DOCUMENT_STATE_TOKEN =
  new StateToken<ExternalDocumentStateModel>('ExternalDocumentState');

const defaults: ExternalDocumentStateModel = {
  isFormSuccessful: false,
  externalDocumentDetail: undefined,
  externalDocumentResponseDetail: undefined,
  selectedExternalDocument: undefined,
  selectedExternalDocumentResponse: undefined,
  externalDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  externalDocumentResponses: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  remarkActionsForDisplay: [],
};

@State<ExternalDocumentStateModel>({
  name: EXTERNAL_DOCUMENT_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ExternalDocumentState {
  constructor(
    public externalDocumentService: ExternalDocumentService,
    private operationStatus: OperationStatusService,
    private store: Store,
    private router: Router,
  ) {}

  @Action(CreateExternalDocument)
  createDocument(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { externalDocument }: CreateExternalDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .registerExternalDocument(externalDocument)
      .pipe(
        tap((createdDocument) => {
          setState(
            patch({
              externalDocuments: patch({
                items: insertItem(createdDocument),
                totalCount: getState().externalDocuments.totalCount + 1,
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.upload-document:Document uploaded successfully`,
            successStyle,
          );
          this.store.dispatch(new SetFormSuccessful());
          this.store.dispatch(new GetExternalDocument(1,10));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetFormSuccessful)
  setFormSuccessful(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    {  }: SetFormSuccessful,
  ) {
          patchState({
            isFormSuccessful: true
          });

  }

  @Action(SetFormNotSuccessful)
  setFormNotSuccessful(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    {  }: SetFormNotSuccessful,
  ) {
          patchState({
            isFormSuccessful: false
          });

  }

  @Action(SendRemark)
  sendRemark(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { remark, externalDocumentId }: SendRemark,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .sendRemark(remark, externalDocumentId)
      .pipe(
        tap((newExternalDocumentDetail) => {
          setState(
            patch({
              externalDocumentDetail: newExternalDocumentDetail,
              selectedExternalDocument:newExternalDocumentDetail
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.added-remark:Remark added successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SendResponseRemark)
  sendResponseRemark(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { remark, responseId }: SendResponseRemark,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .sendResponseRemark(remark, responseId)
      .pipe(
        tap((newExternalDocumentResponse) => {
          setState(
            patch({
              externalDocumentResponseDetail: newExternalDocumentResponse,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.added-remark:Remark added successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetExternalDocumentDetail)
  getExternalDocumentDetail(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { externalDocumentId }: GetExternalDocumentDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getExternalDocumentDetail(externalDocumentId)
      .pipe(
        tap((externalDocumentDetail) => {
          setState(
            patch({
              externalDocumentDetail:externalDocumentDetail,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResetExternalDocumentDetail)
  resetExternalDocumentDetail(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { }: ResetExternalDocumentDetail,
  ) {
          setState(
            patch({
              externalDocumentDetail: undefined,
            }),
          );
  }

  @Action(ResetExternalDocumentResponseDetail)
  resetExternalDocumentResponseDetail(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { }: ResetExternalDocumentResponseDetail,
  ) {
    setState(
      patch({
        externalDocumentResponseDetail: undefined,
      }),
    );
  }

  @Action(GetExternalDocumentResponseDetail)
  getExternalDocumentResponseDetail(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { responseId }: GetExternalDocumentResponseDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getExternalDocumentResponseDetail(responseId)
      .pipe(
        tap((externalDocumentResponseDetail) => {
          setState(
            patch({
              externalDocumentResponseDetail: externalDocumentResponseDetail
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteExternalDocument)
  deleteDocument(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { externalDocumentId }: DeleteExternalDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .deleteExternalDocument(externalDocumentId)
      .pipe(
        tap((deletedDocument) => {
          setState(
            patch({
              externalDocuments: patch({
                items: removeItem((doc) => doc.id === deletedDocument.id),
                totalCount: getState().externalDocuments.totalCount - 1,
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.deleted-document:Document deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new GetExternalDocument(1,10));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(DeleteExternalDocumentResponse)
  deleteDocumentResponse(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { externalDocumentResponseId }: DeleteExternalDocumentResponse,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .deleteExternalDocumentResponse(externalDocumentResponseId)
      .pipe(
        tap(() => {
          setState(
            patch({
              externalDocumentDetail: patch({
                responses: removeItem((res) => res.id === externalDocumentResponseId),
              }),
              externalDocumentResponses: patch({
                items: removeItem((res) => res.id === externalDocumentResponseId),
              }),
            }),
          );
          
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.deleted-response:response deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetExternalDocument)
  getDocuments(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { pageNumber, pageSize }: GetExternalDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getExternalDocuments(pageNumber, pageSize)
      .pipe(
        tap((externalDocuments) => {
          setState(
            patch({
              externalDocuments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetExternalDocumentResponses)
  getExternalDocumentResponses(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { pageNumber, pageSize }: GetExternalDocumentResponses,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getExternalDocumentResponses(pageNumber, pageSize)
      .pipe(
        tap((externalDocumentResponses) => {
          setState(
            patch({
              externalDocumentResponses,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateExternalDocument)
  updateDocument(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { externalDocument }: UpdateExternalDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .updateExternalDocument(externalDocument)
      .pipe(
        tap((updatedDocument) => {
          setState(
            patch({
              externalDocuments: patch({
                items: updateItem(
                  (doc) => doc.id === updatedDocument.id,
                  updatedDocument,
                ),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.updated-document:Document updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetFormSuccessful());
          this.store.dispatch(new GetExternalDocument(1,10));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetSelectedExternalDocument)
  setSelectedExternalDocument(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    { externalDocument }: SetSelectedExternalDocument,
  ) {
    patchState({
      selectedExternalDocument: externalDocument,
    });
  }

  @Action(SetSelectedExternalDocumentResponse)
  setSelectedExternalDocumentResponse(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    { response }: SetSelectedExternalDocumentResponse,
  ) {
    patchState({
      selectedExternalDocumentResponse: response,
    });
  }

  @Action(ResetSelectedExternalDocument)
  resetSelectedExternalDocument({
    patchState,
  }: StateContext<ExternalDocumentStateModel>) {
    patchState({
      selectedExternalDocument: undefined,
    });
  }

  @Action(CreateResponse)
  createResponse(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { response, send }: CreateResponse,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService.createResponse(response, send).pipe(
      tap((externalDocumentResponse) => {
        setState(
          patch({
            externalDocumentDetail: patch({
              responses: append(externalDocumentResponse),
            }),
          }),
        );
        if (send) {
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.sent-response:Response sent successfully`,
            successStyle,
          );
          const isResponse = getState().externalDocumentDetail?.responses?.find(res => res.id === externalDocumentResponse.id)?.id;
          if (isResponse)
            this.store.dispatch(
              new GetExternalDocumentResponseDetail(isResponse),
            );
        } else {
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.saved-draft-response:Draft Response saved successfully`,
            successStyle,
          );
        }
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(UpdateResponse)
  updateResponse(
    { setState, getState }: StateContext<ExternalDocumentStateModel>,
    { response, send }: UpdateResponse,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService.updateResponse(response, send).pipe(
      tap((externalDocumentResponse) => {
          const currentState = getState();
          if (!currentState.externalDocumentDetail) {
            return; 
          }
          const responsesList = currentState.externalDocumentDetail.responses;
          if (responsesList){
          const index = responsesList.findIndex(r => r.id === externalDocumentResponse.id);
          if (index !== -1) {
            responsesList[index] = externalDocumentResponse;
          }
        }
        setState(
          patch({
            externalDocumentDetail: patch({
              responses: responsesList,
            }),
          }),
        );
        if (send) {
          this.store.dispatch(new GetExternalDocumentResponseDetail(externalDocumentResponse.id));
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.sent-response:Response sent successfully`,
            successStyle,
          );
        } else {
          this.operationStatus.displayStatus(
            $localize`:@@documents.external-document.saved-draft-response:Draft Response saved successfully`,
            successStyle,
          );
        }
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DownloadAttachment)
  downloadAttachment(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    { documentId, attachmentId, name }: DownloadAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getAttachment(documentId, attachmentId)
      .pipe(
        tap((response) => {
          const blob = response;
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          let filename = name;
          link.download = filename;
          link.click();
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(PreviewAttachment)
  previewTaskDocument(
    { setState }: StateContext<ExternalDocumentStateModel>,
    { documentId, attachmentId }: PreviewAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getAttachment(documentId, attachmentId)
      .pipe(
        tap((response) => {
          const blob = response;
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, '_blank');
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DownloadResponseAttachment)
  downloadResponseAttachment(
    { patchState }: StateContext<ExternalDocumentStateModel>,
    { documentId, attachmentId, name }: DownloadAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getResponseAttachment(documentId, attachmentId)
      .pipe(
        tap((response) => {
          const blob = response;
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          let filename = name;
          link.download = filename;
          link.click();
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(PreviewResponseAttachment)
  previewResponseAttachment(
    { setState }: StateContext<ExternalDocumentStateModel>,
    { documentId, attachmentId, name }: PreviewAttachment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.externalDocumentService
      .getResponseAttachment(documentId, attachmentId)
      .pipe(
        tap((response) => {
          const blob = response;
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, '_blank');
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetRemarkActionStatus)
  getProcessCategories(
    { setState }: StateContext<ExternalDocumentStateModel>,
    {}: GetRemarkActionStatus,
  ) {
    return this.externalDocumentService.getRemarkStatusEnum().pipe(
      tap((remarkActions) => {
        setState(
          patch({
            remarkActionsForDisplay: remarkActions,
          }),
        );
      }),
    );
  }
}
