import { Component, Input, OnInit } from '@angular/core';
import {
  CommentReportStatus,
  ReportedWorkflowComment,
} from '../../../models/workflow-comment.model';
import { WorkflowCommentFacade } from '../../../facade/workflow-comment.facade';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { ConfirmDialogWithOneOptionComponent } from 'src/app/shared/shared-components/confirm-dialog-with-one-option/confirm-dialog-with-one-option.component';

interface ReportedWorkflowCommentCardState {
  selectedReportedWorkflowComment: ReportedWorkflowComment | undefined;
}

const initReportedWorkflowCommentCardState: ReportedWorkflowCommentCardState = {
  selectedReportedWorkflowComment: undefined,
};

@Component({
  selector: 'app-reported-workflow-comment-card',
  templateUrl: './reported-workflow-comment-card.component.html',
  styleUrls: ['./reported-workflow-comment-card.component.scss'],
})
export class ReportedWorkflowCommentCardComponent implements OnInit {
   collapsed : boolean = true;
   commentReportStatus = CommentReportStatus;
  @Input() reportedWorkflowComment!: ReportedWorkflowComment;
  selectedReportedWorkflowComment: ReportedWorkflowComment | undefined;
  selectedReportedWorkflowComment$ = this.state.select(
    'selectedReportedWorkflowComment',
  );

  constructor(
    private state: RxState<ReportedWorkflowCommentCardState>,
    private workflowCommentFacade: WorkflowCommentFacade,
    private dialog: MatDialog,
  ) {
    this.state.set(initReportedWorkflowCommentCardState);
    this.state.connect(
      'selectedReportedWorkflowComment',
      this.workflowCommentFacade.selectedReportedWorkflowComment$,
    );
  }

  ngOnInit() {}
  toggleComment(){
    this.collapsed = !this.collapsed;
  }

  resolveComment() {
    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        message: 'Accept Report',
        regularTextOne: $localize`:@@documents.reported-workflow-comment-card.approved-report:Are you sure you want to approve this report?`,
        placeholder: $localize`:@@documents.reported-workflow-comment-card.approved-report-placehodler:wirte your acceptance reason here.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        this.workflowCommentFacade.dispatchResloveReportedWorkflowComment({
          reportedWorkflowCommentId: this.reportedWorkflowComment?.id as string,
          resolutionDescription: result.comment,
          status: CommentReportStatus.RESOLVED,
        });
      }
    });
  }

  rejectReport() {
    const dialogRef = this.dialog.open(ConfirmWithCommentDialogComponent, {
      data: {
        message: 'Reject Report',
        regularTextOne: $localize`:@@documents.reported-workflow-comment-card.rejected-report:Are you sure you want to reject this report?`,
        placeholder: $localize`:@@documents.reported-workflow-comment-card.rejected-report-placeholder:wirte your rejection reason here.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.comment && result.confirmText === 'confirm') {
        this.workflowCommentFacade.dispatchResloveReportedWorkflowComment({
          reportedWorkflowCommentId: this.reportedWorkflowComment?.id as string,
          resolutionDescription: result.comment,
          status: CommentReportStatus.REJECTED,
        });
      }
    });
  }

  showReportedWorkflowCommentRespondingRemark() {
    const dialogRef = this.dialog.open(ConfirmDialogWithOneOptionComponent, {
      data: {
        name: '',
        title: $localize `:@@documents.reported-workflow-comment-card.remark-text-while-responding-for-reported-comment:Response remark`,
        regularTextOne: this.reportedWorkflowComment?.resolutionDescription,
      },
    });
  }
}
