<mat-toolbar color="primary" class="toolbar-menu">
  <span i18n="@@home.home.imis">IMIS</span>
  <div class="nav-links">
    <ng-container *ngFor="let link of mainMenu$ | async">
      <a
        *ngIf="!link.hidden && isAuthenticated && !isFirstTimeLogin"
        class="menu-button"
        mat-button
        [routerLink]="link.link"
        [routerLinkActive]="['is-active']"
      >
        <mat-icon color="accent" *ngIf="link.icon">{{ link.icon }}</mat-icon>
        <span>{{ link.label }}</span>
      </a>
    </ng-container>
    <ng-container *ngFor="let item of contactMenu$ | async">
      <a
        *ngIf="!item.hidden && isAuthenticated && !isFirstTimeLogin"
        class="menu-button"
        mat-button
        [routerLink]="item.link"
        [routerLinkActive]="['is-active']"
      >
      <mat-icon color="accent" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <span>{{ item.label }}</span>
      </a>
    </ng-container>
  </div>
  <div class="right-menu">
    <app-notification-button
      *ngIf="isAuthenticated && !isFirstTimeLogin"
      (openNotificationMenu)="drawer.open()"
    ></app-notification-button>
    <button mat-icon-button [matMenuTriggerFor]="accountMenu">
      <mat-icon>{{ accountManagement.icon }}</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu">
      <a
        *ngIf="!(isAuthenticated$ | async)"
        mat-menu-item
        [routerLink]="loginRoute.link"
        [routerLinkActive]="['is-active']"
        i18n="@@home.home.login"
        >Login</a
      >
      <a
        *ngIf="(isAuthenticated$ | async) && !isFirstTimeLogin"
        mat-menu-item
        [routerLink]="profile.link"
        [routerLinkActive]="['is-active']"
        i18n="@@home.home.profile"
        >Profile</a
      >
      <a *ngIf="isAuthenticated$ | async" mat-menu-item (click)="logout()"
      i18n="@@home.home.logout"
        >Logout</a
      >
      <a
        *ngIf="(isAuthenticated$ | async) && !isFirstTimeLogin"
        mat-menu-item
        [routerLink]="loginHistory.link"
        [routerLinkActive]="['is-active']"
        i18n="@@home.home.login-history"
        >Login History</a
      >
      <a
        *ngIf="(isAuthenticated$ | async) && !isFirstTimeLogin"
        mat-menu-item
        [routerLink]="changePasswordRoute.link"
        [routerLinkActive]="['is-active']"
        i18n="@@home.home.change-password"
        >Change Password</a
      >
      <a
        *ngIf="
          (isAuthenticated$ | async) &&
          !(accountManagement$ | async)?.hidden &&
          !isFirstTimeLogin
        "
        [routerLink]="accountManagement.link"
        [routerLinkActive]="['is-active']"
        mat-menu-item
        i18n="@@home.home.manage-accounts"
      >
        Manage Accounts
      </a>
      <a
        *ngIf="
          (isAuthenticated$ | async) &&
          !(delegationManagement$ | async)?.hidden &&
          !isFirstTimeLogin
        "
        [routerLink]="delegationManagement.link"
        [routerLinkActive]="['is-active']"
        mat-menu-item
        i18n="@@home.home.manage-delegation"
      >
        Manage Delegation
      </a>
    </mat-menu>
    <app-language-selector />
  </div>
  <div class="progress-status">
    <app-progress-status />
  </div>
</mat-toolbar>
<mat-drawer-container>
  <mat-drawer #drawer position="end" class="notification-drawer">
    <app-notification-menu />
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
