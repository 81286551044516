import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { Role } from 'src/app/users/models/role.model';
import { User } from 'src/app/users/models/user.model';
import { ResearchVsTime } from '../../models/research-vs-time.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import {
  EvaluatedProcessInstanceCountByDepartment,
  EvaluatedProcessInstanceStatusCount,
} from '../../models/research-dashboard.model';
import { BudgetPerCommunication } from '../../models/communication-vs-time.mode';
import { CommunicationsWithStatus } from '../../models/communications';
import { combineLatest, startWith } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';

interface ResearchDashboardHomeComponentState {
  roles: Role[];
  users: User[];
  selectedFlatOfficeNode: FlatOfficeNode[];
  researchVsTime: ResearchVsTime[];
  budgetPerCommunication: BudgetPerCommunication[];
  researchesCount: number;
  communicationsWithStatus: CommunicationsWithStatus[];
  totalCost: number;
  totalCostResearch: number;
  totalCostCommunication: number;
  totalCommunicationsCount: number;
  totalPublicationCategoriesCount: number;
}

const initResearchDashboardHomeComponentState: Partial<ResearchDashboardHomeComponentState> =
  {
    roles: [],
    users: [],
    selectedFlatOfficeNode: [],
    researchVsTime: [],
    budgetPerCommunication: [],
    researchesCount: 0,
    communicationsWithStatus: [],
    totalCommunicationsCount: 0,
    totalCost: 0,
    totalCostResearch: 0,
    totalCostCommunication: 0,
    totalPublicationCategoriesCount: 0,
  };

@Component({
  selector: 'app-research-dashboard-home',
  templateUrl: './research-dashboard-home.component.html',
  styleUrls: ['./research-dashboard-home.component.scss'],
})
export class ResearchDashboardHomeComponent implements OnInit {
  roles: Role[] = [];
  roles$ = this.state.select('roles');
  users: User[] = [];
  users$ = this.state.select('users');
  researchVsTime: ResearchVsTime[] = [];
  researchVsTime$ = this.state.select('researchVsTime');
  communicationsWithStatus: CommunicationsWithStatus[] = [];
  communicationsWithStatus$ = this.state.select('communicationsWithStatus');
  budgetPerCommunication: BudgetPerCommunication[] = [];
  budgetPerCommunication$ = this.state.select('budgetPerCommunication');
  filterForm: FormGroup;
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode [] = [];
  totalCost$ = this.state.select('totalCost');
  totalCost: number = 0;
  totalCostResearch$ = this.state.select('totalCostResearch');
  totalCostResearch: number = 0;
  totalCostCommunication$ = this.state.select('totalCostCommunication');
  totalCostCommunication: number = 0;
  value = this.totalCostResearch / 100;
  researchesCount$ = this.state.select('researchesCount');
  researchesCount: number = 0;
  totalCommunicationsCount$ = this.state.select('totalCommunicationsCount');
  totalCommunicationsCount: number = 0;
  totalPublicationCategoriesCount$ = this.state.select(
    'totalPublicationCategoriesCount',
  );
  totalPublicationCategoriesCount: number = 0;
  constructor(
    private matDialog: MatDialog,
    private roleFacade: RoleFacade,
    private userFacade: UserFacade,
    private fb: FormBuilder,
    private officeFacade: OfficeFacade,
    private researchDashboardFacade: ResearchDashboardFacade,
    private state: RxState<ResearchDashboardHomeComponentState>,
  ) {
    this.state.set(initResearchDashboardHomeComponentState);
    this.state.connect(
      'budgetPerCommunication',
      this.researchDashboardFacade.budgetPerCommunication$,
    );
    this.state.connect('totalCost', this.researchDashboardFacade.totalCost$);
    this.state.connect(
      'totalCostResearch',
      this.researchDashboardFacade.totalResearchCost$,
    );
    this.state.connect(
      'totalCostCommunication',
      this.researchDashboardFacade.totalCommunicationCost$,
    );
    this.state.connect('roles', this.roleFacade.roles$);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNodes$,
    );
    this.state.connect(
      'researchVsTime',
      this.researchDashboardFacade.researchVsTime$,
    );
    this.state.connect(
      'budgetPerCommunication',
      this.researchDashboardFacade.budgetPerCommunication$,
    );

    this.state.connect(
      'researchesCount',
      researchDashboardFacade.researchesCount$,
    );

    this.state.connect('totalCost', researchDashboardFacade.totalCost$);
    this.state.connect(
      'totalCostResearch',
      researchDashboardFacade.totalResearchCost$,
    );
    this.state.connect(
      'totalCostCommunication',
      researchDashboardFacade.totalCommunicationCost$,
    );
    this.state.connect(
      'communicationsWithStatus',
      researchDashboardFacade.communicationsWithStatus$,
    );
    this.state.connect(
      'totalCommunicationsCount',
      researchDashboardFacade.totalCommunicationsCount$,
    );
    this.state.connect(
      'totalPublicationCategoriesCount',
      researchDashboardFacade.totalPublicationcategoriesCount$,
    );
    this.filterForm = this.fb.group({
      roles: [[]],
      user: [[]],
      startDate: [],
      endDate: [],
    });
    this.selectedFlatOfficeNode$.subscribe((offices) => this.selectedFlatOfficeNode = offices);
    combineLatest([
      this.filterForm.get('user')?.valueChanges.pipe(startWith(undefined)),
      this.filterForm.get('startDate')?.valueChanges.pipe(startWith(undefined)),
      this.filterForm.get('endDate')?.valueChanges.pipe(startWith(undefined)),
      this.state.select('selectedFlatOfficeNode').pipe(startWith(undefined)),
    ]).subscribe(() => {
      this.dispatchResearchDashboardActions()
    });
  }

  ngOnInit(): void {
    this.roleFacade.dispatchGetRoles();
    this.officeFacade.dispatchResetSelectedOffices()
    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });
    this.users$.subscribe((users) => {
      this.users = users;
    });
    this.selectedFlatOfficeNode$.subscribe((office) => {
      this.selectedFlatOfficeNode = office;
    });

    this.researchVsTime$.subscribe((data) => {
      this.researchVsTime = data;

      this.researchesCount$.subscribe((count) => {
        this.researchesCount = count;
      });

    this.totalCommunicationsCount$.subscribe((count) => {
      this.totalCommunicationsCount = count;
    });
    });
    this.budgetPerCommunication$.subscribe((data) => {
      this.budgetPerCommunication = data;
    });
    this.budgetPerCommunication$.subscribe((data) => {
      this.budgetPerCommunication = data;
    });

    this.communicationsWithStatus$.subscribe((data) => {
      this.communicationsWithStatus = data;
    });

    this.researchDashboardFacade.dispatchGetResearchesCount();
    this.researchDashboardFacade.dispatchGetPublicationCategoriesCount();
    this.researchDashboardFacade.dispatchGetTotalCommunicationsCount();

    this.researchDashboardFacade.researchesCount$.subscribe((researchCount) => {
      if (researchCount) {
        this.researchesCount = researchCount;
      }
    });
    this.researchDashboardFacade.totalCommunicationsCount$.subscribe(
      (communicationsCount) => {
        if (communicationsCount) {
          this.totalCommunicationsCount = communicationsCount;
        }
      },
    );

    this.totalPublicationCategoriesCount$.subscribe((data) => {
      this.totalPublicationCategoriesCount = data;
    });

    this.researchDashboardFacade.dispatchGetTotalCount();

    this.totalCost$.subscribe((data) => {
      if (data) {
        this.totalCost = data;
      }
    });

    this.researchDashboardFacade.dispatchGetTotalResearchCount();

    this.totalCostResearch$.subscribe((data) => {
      if (data) {
        this.totalCostResearch = data;
      }
    });

    this.researchDashboardFacade.dispatchGetTotalCommunicationCount();

    this.totalCostCommunication$.subscribe((data) => {
      if (data) {
        this.totalCostCommunication = data;
      }
    });

    this.researchDashboardFacade.totalPublicationcategoriesCount$.subscribe(
      (count) => {
        if (count) {
          this.totalPublicationCategoriesCount = count;
        }
      },
    );
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
  }
  
  resetFilter() {
    this.filterForm.reset();
    this.officeFacade.dispatchSelectFlatOfficeNodes([]);
    this.dispatchResearchDashboardActions();
  }

  onRoleSelect() {
    const selectedRoles = this.filterForm.get('roles') as FormControl;
    if (selectedRoles && selectedRoles.value) {
      const roleIds = selectedRoles.value.map((p: Role) => p.id);
      if (!this.selectedFlatOfficeNode) {
        this.userFacade.dispatchGetUsersByMultipleRoleIds(roleIds);
      } else {
        if (roleIds && roleIds.length > 0) {
          this.userFacade.dispachGetUsersByRolesAndOffices(roleIds, this.selectedFlatOfficeNode.map((office) => office.id));
        }
      }
    }
  }
  removeSelectedOffice(office: FlatOfficeNode) {
    if (this.selectedFlatOfficeNode === undefined) return;
      const index = this.selectedFlatOfficeNode.indexOf(office);
    if (index >= 0) {
      this.selectedFlatOfficeNode.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(this.selectedFlatOfficeNode);
    }
    this.dispatchResearchDashboardActions();
  }

  dispatchResearchDashboardActions() {
    const {startDate, endDate, offices, users} = this.filterParams()
    this.researchDashboardFacade.dispatchGetResearchVsTime(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceStatusCount(offices,users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetEvaluatedProcessInstanceCountByDepartment(1, 5, offices, users,startDate, endDate);
    this.researchDashboardFacade.dispatchGetBudgetPerCommunication(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetCommunicationsWithStatus(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchgetResearchCommunications(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchgetFullLengthResearches(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetTotalCount(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetResearchesCount(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetTotalResearchCount(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetTotalCommunicationCount(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetTotalCommunicationsCount(startDate, endDate, offices, users);
    this.researchDashboardFacade.dispatchGetDepartmentVsPublication(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetResearchPublicationData(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetResearchEvaluations(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetCommunicationTypeInstances(startDate, endDate, offices,users);
    this.researchDashboardFacade.dispatchGetCommunicationVsTime(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchGetResearchCount(offices, users, startDate, endDate);
    this.researchDashboardFacade.dispatchSetDashboardFilter({users,offices, startDate, endDate})
  }

  filterParams(){
    const offices = this.selectedFlatOfficeNode?.map((office) => office.id) || [];
    const users = this.filterForm.get('user')?.value?.map((user: User) => user.id) || [];
    const startDate = this.filterForm.get('startDate')?.value || undefined;
    const endDate = this.filterForm.get('endDate')?.value || undefined;
    return {startDate, endDate, offices,users}
  }

  hasGetBudgetTimeGraphPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetBudgetTimeGraph,
    );
  }

  hasGetCommunicationTypesWithInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCommunicationTypesWithInstances,
    );
  }

  hasGetCommunicationsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCommunications,
    );
  }

  hasGetCommunicationsCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCommunicationsCount,
    );
  }

  hasGetCommunicationsTimeGraphPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCommunicationsTimeGraph,
    );
  }

  hasGetCommunicationsWithStatusPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCommunicationsWithStatus,
    );
  }

  hasGetCountOfEvaluatedProcessByDepartmentPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCountOfEvaluatedProcessByDepartment,
    );
  }

  hasGetCountOfEvaluatedProcessWithTheirStatusPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetCountOfEvaluatedProcessWithTheirStatus,
    );
  }

  hasGetFullLengthResearchesPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetFullLengthResearches,
    );
  }

  hasGetPublicationCategoriesVsResearchCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetPublicationCategoriesVsResearchCount,
    );
  }

  hasGetPublicationsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetResearchPublications,
    );
  }

  hasGetResearchCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetResearchCount,
    );
  }

  hasGetResearchEvaluationsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetResearchEvaluations,
    );
  }

  hasGetTaskAccomplishmentCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetTaskAccomplishmentCount,
    );
  }

  hasGetTotalCommunicationsCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetTotalCommunicationsCount,
    );
  }

  hasGetTotalPublicateioncategoriesCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetTotalPublicateioncategoriesCount,
    );
  }

  hasGetResearchesPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetResearches,
    );
  }

  hasGetDepartmentVsPublicationsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsResearch.GetDepartmentVsPublications,
    );
  }
}
