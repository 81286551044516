import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import {
  DELEGATIONS_ROUTE,
  DOCUMENTS_ROUTE,
  FILES_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  OFFICES_ROUTE,
  REPORTED_ISSUES_ROUTE,
  RESEARCH_ROUTE,
  UNAUTHORIZED_ROUTE,
  USERS_ROUTE,
} from './core/constants/routes';
import { HomeComponent } from './home/home.component';
import {
  HasFeaturePermissionGuard,
  HasModulePermissionGuard,
} from './auth/services/has.permission.guard';
import { MODULES } from './core/constants/permissions';
import { UnauthorizedComponent } from './shared/shared-components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', redirectTo: LOGIN_ROUTE, pathMatch: 'full' },
  { path: LOGIN_ROUTE, component: LoginComponent },
  { path: UNAUTHORIZED_ROUTE, component: UnauthorizedComponent},

  {
    path: USERS_ROUTE,
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    data: { module: 'Identities' },
    canActivate: [HasModulePermissionGuard],
  },

  { path: HOME_ROUTE, component: HomeComponent },

  {
    path: FILES_ROUTE,
    loadChildren: () =>
      import('./files/files.module').then((m) => m.FilesModule),
    data: { module: 'Files' },
    canActivate: [HasModulePermissionGuard],
  },

  {
    path: OFFICES_ROUTE,
    loadChildren: () =>
      import('./offices/offices.module').then((m) => m.OfficesModule),
    data: { module: 'Offices' },
    canActivate: [HasModulePermissionGuard],
  },
  {
    path: RESEARCH_ROUTE,
    loadChildren: () =>
      import('./researches/researches.module').then((m) => m.ResearchesModule),
    data: { module: MODULES.RESEARCHES },
    canActivate: [HasModulePermissionGuard],
  },
  {
    path: DOCUMENTS_ROUTE,
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
    data: { module: MODULES.DOCUMENTS },
    canActivate: [HasModulePermissionGuard],
  },
  {
    path: DELEGATIONS_ROUTE,
    loadChildren: () =>
      import('./delegations/delegations.module').then(
        (m) => m.DelegationsModule,
      ),
    data: { module: 'Offices' },
    canActivate: [HasModulePermissionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
