<h1
  *ngIf="!currentStage?.id"
  mat-dialog-title
  i18n="@@researches.stage-form.create-stage"
>
  Create Stage
</h1>
<h1
  *ngIf="currentStage?.id"
  mat-dialog-title
  i18n="@@researches.stage-form.update-stage"
>
  Update Stage
</h1>
<mat-dialog-content mat-dialog-content class="form-container">
  <form [formGroup]="stageForm" class="stage-form">
    <section class="check-group">
      <mat-checkbox
        class="check-box"
        formControlName="hasEvaluation"
        i18n="@@researches.stage-form.has-evaluation"
        >Has Evaluation
      </mat-checkbox>
      <mat-checkbox
        class="check-box"
        formControlName="hasBudget"
        i18n="@@researches.stage-form.has-budget"
        >Has Budget</mat-checkbox
      >
    </section>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.stage-form.name">Name</mat-label>
      <input
        matInput
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            stageForm.get('name')?.value ?? ''
          )
        "
        [placeholder]="placeholderToggleLabel.stageName"
        formControlName="name"
        [attr.data-test-id]="'researches.create-stage.stage-form.stage-name-input'"
      />
      <mat-error
        *ngIf="stageForm.controls.name.errors?.['required']"
        i18n="@@researches.stage-form.please-provide-a-valid-name-for-the-stage"
      >
        Please provide a valid name for the stage
      </mat-error>
      <mat-error
        *ngIf="stageForm.controls.name.errors?.['stageNameDuplicated']"
        i18n="
          @@researches.stage-form.please-provide-a-unique-name-for-the-stage"
      >
        Please provide a unique name for the stage
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.stage-form.description"
        >Description</mat-label
      >
      <textarea
        matInput
        formControlName="description"
        [placeholder]="placeholderToggleLabel.stageProposalDescription"
        [attr.data-test-id]="'researches.create-stage.stage-form.stage-description-input'"
      ></textarea>
      <mat-error
        *ngIf="stageForm.controls.description.errors?.['required']"
        i18n="
          @@researches.stage-form.please-provide-a-valid-description-for-the-stage"
      >
        Please provide a valid description for the stage
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n="@@researches.stage-form.approximate-duration-in-weeks"
        >Approximate Duration (in weeks)</mat-label
      >
      <mat-select matInput formControlName="approximateDurationInWeeks">
        <mat-option *ngFor="let week of numberOfWeeks" [value]="week">
          {{ week }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="stageForm.controls.hasEvaluation.value" >
      <mat-label i18n="@@researches.stage-form.passing-score-">Minimum Passing Score</mat-label>
      <input
        matInput
        [placeholder]="placeholderToggleLabel.exScore"
        formControlName="score"
        type="number"
        [attr.data-test-id]="'researches.minimum-passing-score.stage-form.minimum-passing-score-input'"
      />
      <mat-error *ngIf="stageForm.controls['score'].errors?.['max']"
      i18n="@@researches.stage-form.passing-score-can-not-be-greater-than-1">
        Passing Score can not be greater than 1
      </mat-error>
      <mat-error *ngIf="stageForm.controls['score'].errors?.['min']"
      i18n="@@researches.stage-form.passing-score-must-be-greater-than-0">
     Passing Score must be greater than 0
      </mat-error>
    </mat-form-field>

    <div *ngIf="stageForm.controls.hasEvaluation.value">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label i18n="@@researches.stage-form.quorum-in-percentage"
          >Quorum (in %)</mat-label
        >
        <input
          matInput
          [placeholder]="placeholderToggleLabel.ex10"
          formControlName="quorum"
          type="number"
           [attr.data-test-id]="'researches.quorum.stage-form.quorum-input'"
        />
        <mat-error
          *ngIf="stageForm.controls['quorum'].errors?.['pattern']"
          i18n="
            @@researches.stage-form.quorum-can-not-contain-negative-or-decimal-numbers"
        >
          Quorum can not contain negative or decimal numbers
        </mat-error>
        <mat-error
          *ngIf="stageForm.controls['quorum'].errors?.['max']"
          i18n="
            @@researches.stage-form.quorum-percentage-can-not-be-greater-than-100"
        >
          Quorum % can not be greater than 100
        </mat-error>
        <mat-error
          *ngIf="stageForm.controls['quorum'].errors?.['min']"
          i18n="
            @@researches.stage-form.quorum-percentage-must-be-greater-than-0"
        >
          Quorum % must be greater than 0
        </mat-error>
      </mat-form-field>

      <mat-card *ngIf="!data.isUpdate">
        <mat-card-header i18n="@@researches.stage-form.criteria-sum"
          >Criteria sum
        </mat-card-header>
        <mat-card-content>{{ this.criteriaSum }}</mat-card-content>
      </mat-card>
      <mat-error
        *ngIf="stageForm.controls['criteriaSum'].errors?.['max']"
        i18n="@@researches.stage-form.criteria-sum-can-not-be-greater-than-100"
      >
        Criteria Sum can not be greater than 100
      </mat-error>
      <mat-error
        *ngIf="stageForm.controls['criteriaSum'].errors?.['min']"
        i18n="@@researches.stage-form.criteria-sum-can-not-be-less-than-0"
      >
        Criteria Sum can not be less than 0
      </mat-error>
      <ng-container *appHasPermission="hasCreateCriteriaPermission()">
        <app-criteria-form *ngIf="!data.isUpdate"></app-criteria-form>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close i18n="@@researches.stage-form.cancel">
    Cancel
  </button>
  <button
    *ngIf="!currentStage?.id"
    mat-flat-button
    color="primary"
    (click)="submit()"
    [disabled]="stageForm.invalid"
    i18n="@@researches.stage-form.create-stage"
  >
    Create Stage
  </button>

  <button
    *ngIf="currentStage?.id"
    mat-flat-button
    color="primary"
    (click)="submit()"
    [disabled]="stageForm.invalid"
    i18n="@@researches.stage-form.update-stage"
  >
    Update Stage
  </button>
</div>
