import { Select, Store } from '@ngxs/store';
import { WorkflowSelector } from '../store/workflow-analytics.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { InitiatedWorkflowData, WorkflowData } from '../models/workflow.model';
import { Injectable } from '@angular/core';
import {
  GetInvolvedWorkflows,
  GetMyWorkflows,
  GetWorkflows,
} from '../store/workflow-analytics.action';

@Injectable({
  providedIn: 'root',
})
export class WorkflowFacade {
  constructor(private store: Store) {}

  @Select(WorkflowSelector.initiatedWorkflows)
  initiatedWorkflows$!: Observable<PaginatedList<InitiatedWorkflowData>>;
  @Select(WorkflowSelector.myWorkflows)
  myWorkflows$!: Observable<PaginatedList<InitiatedWorkflowData>>;

  @Select(WorkflowSelector.involvedWorkflows)
  involvedWorkflows$!: Observable<PaginatedList<WorkflowData>>;

  dispatchGetWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    userId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
    userName?: string,
  ) {
    this.store.dispatch(
      new GetWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        userId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
        userName,
      ),
    );
  }

  dispatchGetMyWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
  ) {
    this.store.dispatch(
      new GetMyWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
      ),
    );
  }

  dispatchGetMyInvolvedWorkflows(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
    officeId?: string,
    status?: string,
    initiatedStart?: Date,
    initiatedEnd?: Date,
    completedStart?: Date,
    completedEnd?: Date,
  ) {
    this.store.dispatch(
      new GetInvolvedWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
      ),
    );
  }
}
