import { Component, OnInit } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowAverageTimeSpent } from '../../models/workflow-average-time-spent.model';
import { RxState } from '@rx-angular/state';
import { MatTableDataSource } from '@angular/material/table';
import { WorkflowAverageTimeSpentFacade } from '../../facades/workflow-average-time-spent.facades';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

interface WorkflowStepAveragerTimeComponentState {
  workflowAverageTimeSpents: PaginatedList<WorkflowAverageTimeSpent>;
}

const initialorkflowStepAveragerTimeComponentState: WorkflowStepAveragerTimeComponentState =
  {
    workflowAverageTimeSpents: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
  };

@Component({
  selector: 'app-workflow-average-time-spent',
  templateUrl: './workflow-average-time-spent.component.html',
  styleUrls: ['./workflow-average-time-spent.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowAverageTimeSpentComponent}, RxState],
})
export class WorkflowAverageTimeSpentComponent implements OnInit {
  workflowAverageTimeSpents: WorkflowAverageTimeSpent[] | undefined;
  workflowAverageTimeSpents$ = this.state.select('workflowAverageTimeSpents');
  displayedColumns: string[] = [
    'workflowName',
    'workflowTypeName',
    'averageTimeInDays',
  ];

  dataSource: MatTableDataSource<WorkflowAverageTimeSpent> =
    new MatTableDataSource<WorkflowAverageTimeSpent>();
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;

  firstPageLabel = $localize`:@@documents.workflow-average-time-spent.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.workflow-average-time-spent.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.workflow-average-time-spent.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.workflow-average-time-spent.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.workflow-average-time-spent.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.workflow-average-time-spent.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@documents.workflow-average-time-spent.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow-average-time-spent.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<WorkflowStepAveragerTimeComponentState>,
    private workflowAverageTimeSpentFacade: WorkflowAverageTimeSpentFacade,
  ) {
    this.state.set(initialorkflowStepAveragerTimeComponentState);
    this.state.connect(
      'workflowAverageTimeSpents',
      this.workflowAverageTimeSpentFacade.workflowAverageTimeSpents$,
    );
  }
  changes = new RxState();
  ngOnInit(): void {
    this.workflowAverageTimeSpentFacade.dispatchGetWorkflowAverageTimeSpent(
      this.pageIndex + 1,
      this.pageSize,
    );

    this.workflowAverageTimeSpents$.subscribe((workflowAverageTimeSpents) => {
      this.workflowAverageTimeSpents = workflowAverageTimeSpents.items;
      this.length = workflowAverageTimeSpents.totalCount;
      this.dataSource.data = this.workflowAverageTimeSpents;
    });
  }

  loadPaginatedWorkflowAverageTimeSpents(event: PageEvent) {
    this.workflowAverageTimeSpentFacade.dispatchGetWorkflowAverageTimeSpent(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  convertDaysToDaysAndHours(days: number): string {
    const wholeDays = Math.floor(days);
    const hours = Math.round((days - wholeDays) * 24);
    return `${wholeDays}` +  $localize`:@@documents.workflow-average-time-spent.time-spent-in-days-and-hours-part-1: day` + `${wholeDays === 1 ? '' : 's'}` + $localize`:@@documents.workflow-average-time-spent.time-spent-in-days-and-hours-part-2: and` + `${hours}` +  $localize`:@@documents.workflow-average-time-spent.time-spent-in-days-and-hours-part-3: hour` + `${
      hours === 1 ? '' : 's'
    }`;
  }
}
