<mat-tab-group>
  <mat-tab
    [label]="reportedIssues"
  >
    <div class="main-container" layout="column">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 20, 100]"
        (page)="loadPaginatedIssues($event)"
      ></mat-paginator>

      <mat-table [dataSource]="dataset" class="table">
        <ng-container matColumnDef="reporterName">
          <th mat-header-cell *matHeaderCellDef style="width: 10%"
          i18n="@@users.reported-issues.reporter-name">
            Reporter Name
          </th>
          <td
            mat-cell
            *matCellDef="let issue"
            style="width: 10%; vertical-align: middle"
          >
            {{ issue.sentByUserName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef style="width: 30%" i18n="@@users.reported-issues.subject">Subject</th>
          <td
            mat-cell
            *matCellDef="let issue"
            style="width: 30%; vertical-align: middle"
          >
            {{ issue.subject }}
          </td>
        </ng-container>

        <ng-container matColumnDef="priority" *appHasPermission="hasGetAllContactUsPermission()">
          <th mat-header-cell *matHeaderCellDef style="width: 15%" i18n="@@users.reported-issues.priority">Priority</th>
          <td
            mat-cell
            *matCellDef="let issue"
            style="width: 15%; vertical-align: middle"
          >
            {{ checkIssuePriority(issue.priority) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="width: 15%" i18n="@@users.reported-issues.status">Status</th>
          <td
            mat-cell
            *matCellDef="let issue"
            style="width: 15%; vertical-align: middle"
          >
            {{ checkIssueStatus(issue.status) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reportedDate">
          <th mat-header-cell *matHeaderCellDef style="width: 10%"
          i18n="@@users.reported-issues.reported-date">
            Reported Date
          </th>

          <td
            mat-cell
            *matCellDef="let issue"
            style="width: 10%; vertical-align: middle"
          >
            {{ issue.createdDate | date: "shortDate" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let issue; columns: displayedColumns" class="clickable-row" (click)="onRowClick($event, issue)"
          style="min-width: 100%"
        ></tr>
      </mat-table>
    </div>
    </mat-tab>

    <mat-tab
    [label]="reportedIssuesBoard"
    >
      <div class="centered-container">
        <app-reported-issue-board></app-reported-issue-board>
      </div>
    </mat-tab>
</mat-tab-group>