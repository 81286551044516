<mat-checkbox
  [checked]="showResponse"
  (change)="getExternalDocumentResponses($event)"
  *appHasPermission="hasGetDocumentResponsesPermission()"
  i18n="@@documents.external-document-list.view-external-document-responses"
  [attr.data-test-id]="'documents.external-document-response.external-document-list.mat-checkbox'"
>
  View External Letter Responses
</mat-checkbox>

<div class="flex justify-between">
  <div class="gap-x gap-y flex">
    <h1 class="mat-h1" *ngIf="!showResponse" i18n="@@documents.external-document-list.external-documents">External Letters</h1>
    <h1 class="mat-h1" *ngIf="showResponse" i18n="@@documents.external-document-list.responded-external-document">External Letter Responses</h1>
    <mat-paginator
      [length]="totalCount"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedExternalDocuments($event)"
    >
    </mat-paginator>
  </div>
  <div *appHasPermission="hasCreateExternalDocumentPermission()">
    <button
      *ngIf="!showResponse"
      class="add-btn gap-y"
      mat-flat-button
      color="primary"
      (click)="uploadExternalDocument()"
    >
      <span i18n="@@documents.external-document-list.add-external-document"> Add External Letters </span> <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="!showResponse">
  <ng-container *ngIf="externalDocuments?.items; else emptyExternalDocumentList">
    <div
      *ngFor="let externalDocument of externalDocuments?.items"
    >
      <mat-card class="stage-card">
        <mat-card-header class="card-header" [attr.data-test-id]="'documents.get-document.external-document-list.mat-card-header'">
          <div class="stage-header">
            <mat-card-title>
              {{ externalDocument?.source }} :
              {{ externalDocument?.subject }}
            </mat-card-title>

            <div class="maticon" *ngIf="!showResponse">
              <button
                mat-icon-button
                (click)="openDeleteConfirmationDialog(externalDocument, $event)"
                *appHasPermission="hasDeleteExternalDocumentPermission()"
                [attr.data-test-id]="'documents.delete-document.external-document-list.delete-button'"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example delete icon"
                  fontIcon="delete"
                ></mat-icon>
              </button>

              <button
                mat-icon-button
                (click)="updateExternalDocument($event, externalDocument)"
                *appHasPermission="hasUpdateExternalDocumentPermission()"
                 [attr.data-test-id]="'documents.update-document.external-document-list.edit-button'"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example edit icon"
                  fontIcon="edit"
                ></mat-icon>
              </button>
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <div class="chip">
                <span i18n="@@documents.external-document-list.ref-no">Ref.No</span>
              </div>
              <mat-card-subtitle>{{
                externalDocument?.referenceNumber || "xxxxx"
              }}</mat-card-subtitle>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content>
          <p class="mat-body">
            {{ externalDocument?.description }}
          </p>
        </mat-card-content>

        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            *appHasPermission="hasGetExternalDocumentDetailPermission()"
            i18n="@@documents.external-document-list.external-document-detail"
            (click)="navigateToExternalDocumentDetail(externalDocument)"
            [attr.data-test-id]="'documents.get-document.external-document-list.' + externalDocument?.source  +'-detail-button'"
            >
            External Letters Detail
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
</div>
<div *ngIf="showResponse">
  <ng-container *ngIf="externalDocumentResponses?.items; else emptyExternalDocumentList">
    <ng-container *ngFor="let externalDocumentResponse of externalDocumentResponses?.items">
      <div *ngIf="externalDocumentResponse?.status === responseStatus.Complete">
      <mat-card class="stage-card">
        <mat-card-header class="card-header">
          <div class="stage-header" [attr.data-test-id]="'documents.external-document-response.external-document-list.' + externalDocumentResponse?.subject +'-div'">
            <mat-card-title>
              {{ externalDocumentResponse?.subject }}
            </mat-card-title>

            <div class="maticon" *ngIf="showResponse">
              <button
                mat-icon-button
                (click)="openDeleteResponseConfirmationDialog(externalDocumentResponse, $event)"
                *appHasPermission="hasDeleteExternalDocumentResponsePermission()"
              >
                <mat-icon 
                  aria-hidden="false"
                  aria-label="Example delete icon"
                  fontIcon="delete"
                ></mat-icon>
              </button>

              <button
                
                mat-icon-button
                (click)="updateExternalDocumentResponse($event, externalDocumentResponse)"
                *appHasPermission="hasUpdateExternalDocumentResponsePermission()"
              >
                <mat-icon
                *ngIf="externalDocumentResponse?.status !== responseStatus.Complete"
                  aria-hidden="false"
                  aria-label="Example edit icon"
                  fontIcon="edit"
                ></mat-icon>
              </button>
            </div>
          </div>
          
        </mat-card-header>
        <mat-card-content>
          <p class="mat-body">
            {{ parseAndConvertBodyToString(externalDocumentResponse?.body) }}
          </p>

          <button
            mat-stroked-button
            color="warn"
            >
            {{ externalDocumentResponse?.status }}
          </button>
        </mat-card-content>

        <mat-card-actions>
          <button
            mat-stroked-button
            color="primary"
            *appHasPermission="hasGetExternalDocumentResponseDetailPermission()"
            (click)="navigateToExternalDocumentResponseDetail(externalDocumentResponse)"
            i18n="@@documents.external-document-list.external-document-response-detail"
          >
            External Letter Response Detail
          </button>
          </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
  </ng-container>
</div>
<ng-template #emptyExternalDocumentList>
  <div class="empty-process-instance-list">
    <span class="text-2xl" i18n="@@documents.external-document-list.no-documents-found">No Letters found</span>
  </div>
</ng-template>
