import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoginRequest } from '../models/login-request.model';
import { Login, Logout, SetDisabled } from '../store/auth.actions';
import { AuthSelector } from '../store/auth.selector';
import { StateResetAll } from 'ngxs-reset-plugin';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  isAuthenticated$: Observable<boolean> = this.store.select(
    AuthSelector.isAuthenticated,
  );

  @Select(AuthSelector.accessToken)
  accessToken$!: Observable<string>;

  @Select(AuthSelector.lockoutDuration)
  lockoutDuration$!: Observable<number>;

  @Select(AuthSelector.isDisabled)
  isDisabled$!: Observable<boolean>;

  constructor(private store: Store) {}

  dispatchLogin(request: LoginRequest) {
    this.store.dispatch(new Login(request));
  }

  dispatchLogout() {
    this.store.dispatch(new Logout());
  }

  dispatchStateResetAll() {
    this.store.dispatch(new StateResetAll());
  }

  dispatchSetDisabled(isDisabled: boolean) {
    this.store.dispatch(new SetDisabled(isDisabled));
  }
}
