<mat-sidenav-container class="container">
  <mat-sidenav mode="side" opened class="left-menu mat-elevation-z2">
    <mat-nav-list>
      <div *ngFor="let link of navLinks$ | async">
        <a
          mat-list-item
          [routerLink]="link.link"
          [routerLinkActive]="['is-active', 'active-link']"
          *ngIf="!link.hidden"
        >
          <mat-icon color="accent" *ngIf="link.icon" matListItemIcon>{{ link.icon }}</mat-icon>
          <span
            [matBadge]="getBadge$(link.label) | async"
            [matBadgeHidden]="(getBadge$(link.label) | async) == 0"
            matBadgeColor="warn"
            matBadgeSize="small"
            *ngIf="
              link.label == 'Memos' || link.label == 'Notices';
              else elseBlock
            "
            matListItemTitle
          >
            {{ link.label }}
          </span>
          <ng-template #elseBlock>
            <span matListItemTitle>{{ link.label }}</span>
          </ng-template>
        </a>
      </div>

      <a
        mat-list-item
        (click)="navigateToSettings()"
        *appHasPermission="hasGetWorkflowCommentPolicyPermission()"
      >
        <mat-icon color="accent" matListItemIcon>settings</mat-icon>
        <span matListItemTitle i18n="@@documents.document-home.settings">
          Settings
        </span>
      </a>
      <a
        mat-list-item
        href="assets/manuals/document/document-module.html"
        target="_blank"
      >
        <mat-icon color="accent" matListItemIcon>help_outline</mat-icon>
        <span matListItemTitle i18n="@@documents.document-home.user-manual">User Manual</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
