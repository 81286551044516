import { Component, OnInit } from '@angular/core';
import { PublicationCategoriesVsProcessCount } from '../../models/research-vs-time.model';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { RxState } from '@rx-angular/state';
import { EChartsOption } from 'echarts';
import { Filter } from '../../models/filter.model';
import { combineLatest } from 'rxjs';

interface PublishedProcessVsCategoryComponentState {
  publicationCategoriesVsProcessCount: PublicationCategoriesVsProcessCount[];
  filter?: Filter;
}

const initPublishedProcessVsCategoryComponentState: PublishedProcessVsCategoryComponentState =
  {
    publicationCategoriesVsProcessCount: [],
  };

@Component({
  selector: 'app-published-process-vs-category',
  templateUrl: './published-process-vs-category.component.html',
  styleUrls: ['./published-process-vs-category.component.scss'],
})
export class PublishedProcessVsCategoryComponent implements OnInit {
  publicationCategoriesVsProcessCount$ = this.state.select(
    'publicationCategoriesVsProcessCount',
  );
  publicationCategoriesVsProcessCount: PublicationCategoriesVsProcessCount[] =
    [];
  chartOption: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '5%',
      left: 'center',
    },
  };

  constructor(
    private researchDashboardFacade: ResearchDashboardFacade,
    public state: RxState<PublishedProcessVsCategoryComponentState>,
  ) {
    this.state.set(initPublishedProcessVsCategoryComponentState);
    this.state.connect(
      'publicationCategoriesVsProcessCount',
      this.researchDashboardFacade.publicationCategoriesVsProcessCount$,
    );

    this.state.connect('filter', this.researchDashboardFacade.filter$);
    this.publicationCategoriesVsProcessCount$.subscribe((data) => {
      this.setChartOption();
    });
  }

  ngOnInit(): void {
    this.researchDashboardFacade.dispatchGetPublicationCategoriesVsProcessCount();

    this.publicationCategoriesVsProcessCount$.subscribe((data) => {
      if (data) {
        this.publicationCategoriesVsProcessCount = data;
        this.setChartOption();
      }
    });

    combineLatest([this.state.select('filter')]).subscribe(([filters]) => {
      this.researchDashboardFacade.dispatchGetPublicationCategoriesVsProcessCount(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
      );
    });
  }

  onChartInit(e: any) {
    this.chartOption = e;
    this.setChartOption();
  }

  setChartOption() {
    this.chartOption.series = [
      {
        name: $localize`:@@researches.published-process-vs-category.publication-category-vs-research-count:Publication Category Vs Research Count`,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: this.publicationCategoriesVsProcessCount.map(
          (item: PublicationCategoriesVsProcessCount) => {
            return {
              name: item.categoryName,
              value: item.processCount,
            };
          },
        ),
      },
    ];
  }
}
