import { Component } from '@angular/core';
import { ActivityType } from '../../models/activityType.model';
import { TorConfigurationFacade } from '../../facade/tor-setttings.facade';
import { RxState } from '@rx-angular/state';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { MatDialog } from '@angular/material/dialog';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { CreateActivityTypeComponent } from '../create-activity-type/create-activity-type.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface ActivityTypeListState {
  activityTypes: ActivityType[];
}

const initActivityTypeListState: ActivityTypeListState = {
  activityTypes: [],
};

@Component({
  selector: 'app-activity-types-list',
  templateUrl: './activity-types-list.component.html',
  styleUrls: ['./activity-types-list.component.scss'],
})
export class ActivityTypesListComponent {
  activityTypes$ = this.activityTypeListState.select('activityTypes');
  activityTypes: ActivityType[] = [];

  constructor(
    private activityTypeListState: RxState<ActivityTypeListState>,
    private torConfigurationFacade: TorConfigurationFacade,
    private sanitizerService: SanitizerService,
    private dialog: MatDialog,
  ) {
    this.activityTypeListState.connect;
    this.activityTypeListState.set(initActivityTypeListState);
    this.activityTypeListState.connect(
      'activityTypes',
      this.torConfigurationFacade.activityTypes$,
    );
  }

  ngOnInit(): void {
    this.torConfigurationFacade.dispatchGetActivityTypes();
    this.activityTypes$.subscribe((types) => {
      this.activityTypes = types;
    });
  }

  editActivityType(item: any) {
    this.torConfigurationFacade.dispatchSetActivityUpdate(item);
    this.dialog.open(CreateActivityTypeComponent,{
      disableClose: true,
    });
  }
  deleteActivityType(item: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.tor-settings.delete-activity-typeconfirmation-part-1:Are you sure you want to delete the` ,
        boldText: ` ${item.name} ` ,
        regularTextTwo: $localize`:@@researches.tor-settings.delete-activity-typeconfirmation-part-2:activity type?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.torConfigurationFacade.dispatchRemoveActivityType(item.id);
      }
    });
  }

  addActivityType() {
    this.torConfigurationFacade.dispatchRemoveActivityUpdate();
    this.dialog.open(CreateActivityTypeComponent,{
      disableClose: true,
    });
  }

  hasCreateActivityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ActivityType.Feature,
      PERMISSION_NAMES.Researches.ActivityType.CreateActivityType,
    );
  }
  hasUpdateActivityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ActivityType.Feature,
      PERMISSION_NAMES.Researches.ActivityType.UpdateActivityType,
    );
  }
  hasRemoveActivityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ActivityType.Feature,
      PERMISSION_NAMES.Researches.ActivityType.RemoveActivityType,
    );
  }

  hasCrudPermission() {
    return [
      this.hasUpdateActivityTypePermission(),
      this.hasRemoveActivityTypePermission()
    ]
  }
}
