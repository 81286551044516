<div
  class="flex justify-between mbg-white"
  *appHasPermission="hasGetArchivedWorkspacesPermission()"
>
  <h1
    class="mat-h1"
    i18n="@@offices.archived-workspaces-list.archived-workspaces"
  >
    Archived Workspaces
  </h1>
  <mat-paginator
    [length]="archivedWorkspaces?.totalCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedWorkspaces($event)"
  >
  </mat-paginator>
</div>

<div *appHasPermission="hasGetArchivedWorkspacesPermission()">
  <ng-container
    *ngIf="
      archivedWorkspaces?.items?.length ?? 0 > 0;
      else emptyArchivedWorkspaceList
    "
  >
    <app-archived-workspace-card
      *ngFor="let archivedWorkspace of archivedWorkspaces?.items"
      [archivedWorkspace]="archivedWorkspace"
    ></app-archived-workspace-card>
  </ng-container>
  <ng-template #emptyArchivedWorkspaceList>
    <div class="empty-archived-workspace-list">
      <span
        class="text-2xl"
        i18n="@@offices.archived-workspaces-list.no-archived-workspaces-found"
        >No archived workspaces found</span
      >
    </div>
  </ng-template>
</div>
