import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { RxState } from '@rx-angular/state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TorFacade } from 'src/app/researches/tor/facades/tor.facades';
import { TorComment, TorCommentReply, TorDetail } from '../../models/tor.model';
import { MatDrawer } from '@angular/material/sidenav';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { ActivatedRoute } from '@angular/router';

interface ToRCommentsComponentState {
  torComments: PaginatedList<TorComment> | undefined;
  selectedTorDetail?: TorDetail;
  selectedTorComment?: TorComment;
  selectedReply?: TorComment;
  isUpdatingComment?: boolean;
  isReplyingToComment?: boolean;
  isUpdatingReply?: boolean;
}

const initTorCommentComponentState: ToRCommentsComponentState = {
  torComments: undefined,
};

@Component({
  selector: 'app-tor-comments',
  templateUrl: './tor-comments.component.html',
  styleUrls: ['./tor-comments.component.scss'],
})
export class TorCommentsComponent implements OnInit {
  torComments: PaginatedList<TorComment> | undefined;
  torComments$ = this.state.select('torComments');

  selectedTorDetail?: TorDetail;
  selectedTorDetail$ = this.state.select('selectedTorDetail');

  selectedTorComment?: TorComment;
  selectedTorComment$ = this.state.select('selectedTorComment');

  selectedReply?: TorComment;
  selectedReply$ = this.state.select('selectedReply');

  isUpdatingComment?: boolean = false;
  isUpdatingComment$ = this.state.select('isUpdatingComment');

  isReplyingToComment?: boolean = false;
  isReplyingToComment$ = this.state.select('isReplyingToComment');

  placeholderToggleLabel = {
      addComment: $localize`:@@researches.tor-comments.add-comment: Add Comment` ,
  }
  isUpdatingReply?: boolean = false;
  isUpdatingReply$ = this.state.select('isUpdatingReply');

  commentForm: FormGroup;
  selectedTorId: any;
  @ViewChild('drawer') drawer?: MatDrawer | undefined;

  @Output() closeDrawer = new EventEmitter<void>();

  onCloseIconClick() {
    this.closeDrawer.emit();
  }

  constructor(
    private fb: FormBuilder,
    private state: RxState<ToRCommentsComponentState>,
    private torFacade: TorFacade,
    private elementRef: ElementRef,
    private route: ActivatedRoute
  ) {
    this.state.set(initTorCommentComponentState);
    this.state.connect('torComments', this.torFacade.torComments$);

    this.state.connect('selectedTorDetail', this.torFacade.selectedTorDetail$);

    this.state.connect(
      'selectedTorComment',
      this.torFacade.selectedTorComment$,
    );

    this.state.connect(
      'selectedReply',
      this.torFacade.selectedReply$);


    this.state.connect('isUpdatingComment', this.torFacade.isUpdatingComment$);

    this.state.connect(
      'isReplyingToComment',
      this.torFacade.isReplyingToComment$,
    );

    this.state.connect('isUpdatingReply', this.torFacade.isUpdatingReply$);

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.torFacade.dispatchGetTorComments(params['id']);
      }})
      
    this.selectedTorDetail$.subscribe((selectedTorDetail) => {
      this.selectedTorDetail = selectedTorDetail;
    });

    this.selectedTorComment$.subscribe((selectedTorComment) => {
      this.selectedTorComment = selectedTorComment;
    });

    this.selectedReply$.subscribe((selectedReply) => {
      this.selectedReply = selectedReply;
    });

    this.torComments$.subscribe((torComments) => {
      this.torComments = torComments;
    });

    this.isUpdatingComment$.subscribe((isUpdatingComment) => {
      this.isUpdatingComment = isUpdatingComment;
    });

    this.isReplyingToComment$.subscribe((isReplyingToComment) => {
      this.isReplyingToComment = isReplyingToComment;
    });

    this.isUpdatingReply$.subscribe((isUpdatingReply) => {
      this.isUpdatingReply = isUpdatingReply;
    });
  }

  addComment() {
    const { valid, touched, dirty } = this.commentForm;
    if (valid && (touched || dirty)) {
      if (!this.isUpdatingComment && !this.isReplyingToComment && !this.isUpdatingReply) {
        this.torFacade.dispatchAddTorComment(
          this.selectedTorDetail?.id!,
          this.commentForm.value.comment,
        );
      }
      if (this.isUpdatingComment) {
        this.torFacade.dispatchEditTorComment(
          this.selectedTorComment?.id as string,
          this.commentForm.value.comment,
        );
      }

       if (this.isUpdatingReply) {
         this.torFacade.dispatchUpdateTorCommentReply(
           this.selectedTorComment?.id as string,
           this.selectedReply?.id as string,
           this.commentForm.value.comment,
         );
       }
      if (this.isReplyingToComment) {
        this.torFacade.dispatchReplyToTorComment(
          this.commentForm.value.comment,
          this.selectedTorComment?.id as string,
        );
      }
      this.commentForm.reset();
    }
  }

  updateForm() {
    if (this.selectedTorComment && this.isUpdatingComment) {
      this.commentForm.setValue({
        comment
        : this.selectedTorComment.content,
      });
    }
    else if (this.selectedReply) {
      this.commentForm.setValue({
        comment
        : this.selectedReply?.content,
      })
    }
    this.scrollToForm();
  }


  replyCommentForm() {
    if (this.selectedTorComment && this.isReplyingToComment) {
      this.commentForm.setValue({
        comment: '',
      });
    }
    this.scrollToForm();
  }

  cancelComment() {
    if (this.isUpdatingComment) {
      this.torFacade.dispatchSetTorCommentUpdatingMode(false);
    }
    if (this.isReplyingToComment) {
      this.torFacade.dispatchSetTorCommentReplyingMode(false);
    }
    if(this.isUpdatingReply) {
      this.torFacade.dispatchSetTorReplyUpdatingMode(false);
    }
    this.commentForm.reset();
  }

  scrollToForm() {
    const formElement =
      this.elementRef.nativeElement.querySelector('#comment-form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  hasCreateTorCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TorComment.Feature,
      PERMISSION_NAMES.Researches.TorComment.CreateTorComment,
    );
  }

  hasGetTorCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TorComment.Feature,
      PERMISSION_NAMES.Researches.TorComment.GetTorComments,
    );
  }
}
