export class GetWorkflows {
  static readonly type = `[template] ${GetWorkflows.name}`;

  constructor(
    public pageNumber: number,
    public pageSize: number,
    public workflowTypeId?: string,
    public officeId?: string,
    public userId?: string,
    public status?: string,
    public initiatedStart?: Date,
    public initiatedEnd?: Date,
    public completedStart?: Date,
    public completedEnd?: Date,
    public userName?: string,
  ) {}
}

export class GetMyWorkflows {
  static readonly type = `[template] ${GetMyWorkflows.name}`;

  constructor(
    public pageNumber: number,
    public pageSize: number,
    public workflowTypeId?: string,
    public officeId?: string,
    public status?: string,
    public initiatedStart?: Date,
    public initiatedEnd?: Date,
    public completedStart?: Date,
    public completedEnd?: Date,
  ) {}
}

export class GetInvolvedWorkflows {
  static readonly type = `[template] ${GetInvolvedWorkflows.name}`;

  constructor(
    public pageNumber: number,
    public pageSize: number,
    public workflowTypeId?: string,
    public officeId?: string,
    public status?: string,
    public initiatedStart?: Date,
    public initiatedEnd?: Date,
    public completedStart?: Date,
    public completedEnd?: Date,
  ) {}
}
