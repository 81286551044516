import { FormStatusOption } from '../../shared/models/document-form-status';
import { FormFields } from '../../template-fields/models/form-fields.model';

export class GetDocumentFormsByStatus {
  static readonly type = `[Files] ${GetDocumentFormsByStatus.name}`;
  constructor(
    public status: FormStatusOption,
    public pageIndex: number,
    public pageSize: number,
  ) {}
}
import {
  DocumentForm,
  UpdateFormStatusDto,
} from '../models/document-forms.model';
import { CreateDocumentForm } from '../models/document-forms.model';

export class CreateForm {
  static readonly type = `[template] ${CreateForm.name}`;
  constructor(public template: CreateDocumentForm) {}
}

export class GetDocumentForms {
  static readonly type = `[DocumentForm] ${GetDocumentForms.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class SetSelectedDocumentForm {
  static readonly type = `[SetSelectedDocumentForm] ${SetSelectedDocumentForm.name}`;
  constructor(public selectedDocumentForm: DocumentForm | undefined) {}
}
export class ResetSelectedDocumentForm {
  static readonly type = `[ResetSelectedDocumentForm] ${ResetSelectedDocumentForm.name}`;
  constructor() {}
}
import { UpdateDocumentFormDto } from '../models/document-forms.model';

export class UpdateDocumentForm {
  static readonly type = `[Document] ${UpdateDocumentForm.name}`;
  constructor(public template: UpdateDocumentFormDto) {}
}

export class SetUpdateStatus {
  static readonly type = `[Document] ${SetUpdateStatus}`;
  constructor(public status: boolean) {}
}
export class GetDraftForms {
  static readonly type = `[Document] ${GetDraftForms.name}`;
  constructor(
    public pageNumber: number | null,
    public pageSize: number | null,
  ) {}
}

export class GetFormDetailByStatus {
  static readonly type = `[Document] ${GetFormDetailByStatus.name}`;

  constructor(
    public id: string,
    public status: FormStatusOption,
  ) {}
}

export class DeleteDocumentForm {
  static readonly type = `[DocumentForm] ${DeleteDocumentForm.name}`;
  constructor(public id: string) {}
}

export class GetDocumentForm {
  static readonly type = `[DocumentForm] ${GetDocumentForm.name}`;
  constructor(public id: string) {}
}
export class DeleteDraftDocumentForm {
  static readonly type = `[DocumentForm] ${DeleteDraftDocumentForm.name}`;
  constructor(public id: string) {}
}
export class SetFormCreatingMode {
  static readonly type = `[DocumentForm] ${SetFormCreatingMode.name}`;
  constructor(public isCreatingForm: boolean) {}
}

export class GetSelectedDocumentForm {
  static readonly type = `[DocumentForm] ${GetDocumentForm.name}`;
  constructor(public id: string) {}
}
export class ChangeFormStatus {
  static readonly type = `[DocumentForm] ${ChangeFormStatus.name}`;
  constructor(public updatedForm: UpdateFormStatusDto) {}
}
export class SaveForm {
  static readonly type = `[] ${SaveForm.name}`;
  constructor(public template: CreateDocumentForm) {}
}

export class AddFieldOnFormDetail {
  static readonly type = `[DocumentForm] ${AddFieldOnFormDetail.name}`;
  constructor(public newFormField: FormFields) {}
}

export class UpdateFieldOnFormDetail {
  static readonly type = `[DocumentForm] ${UpdateFieldOnFormDetail.name}`;
  constructor(public updatedFormField: FormFields | undefined) {}
}

export class DeleteFieldOnFormDetail {
  static readonly type = `[DocumentForm] ${DeleteFieldOnFormDetail.name}`;
  constructor(public fieldId: string) {}
}

export class SearchDocumentForms {
  static readonly type = `[DocumentForm] ${SearchDocumentForms.name}`;
  constructor(
    public status: string,
    public templateName: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class SetFormSearchingMode {
  static readonly type = `[DocumentForm] ${SetFormSearchingMode.name}`;
  constructor(public isSearchingForm: boolean) {}
}

export class OrderFormFields {
  static readonly type = `[DocumentForm] ${OrderFormFields.name}`;
  constructor(public orderedFields: FormFields[]) {}
}

export class GetApprovedForms {
  static readonly type = `[Document] ${GetApprovedForms.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetArchivedDocumentForms {
  static readonly type = `[Document] ${GetArchivedDocumentForms.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
    public templateName?: string,
  ) {}
}

export class SelectedFormType{
  static readonly type = `[Document] ${SelectedFormType.name}`;
  constructor(public templateType: string) {}
}

export class ToggleAddFieldStep{
  static readonly type = `[Document] ${ToggleAddFieldStep.name}`;
  constructor(public isAddfieldStepSelected: boolean ) {}
}