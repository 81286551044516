import { DeleteProcessInstanceDto, ProcessInstanceFilter, ProcessInstanceMultipleFilter } from './../models/process-instance.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ProcessInstance,
  ProcessInstanceDetail,
} from '../models/process-instance.model';
import { StageInstanceTask, StageInstanceStatus } from '../models/stage-instance-detail.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProcessInstanceService } from '../services/process-instance.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  CreateProcessInstance,
  DeleteProcessInstance,
  GetProcessInstanceDetail,
  GetProcessInstances,
  ResetSelectedProcessInstance,
  SetSelectedProcessInstance,
  SetUpdateStatus,
  UpdateProcessInstance,
  SearchProcessInstances,
  AssignOwnerToResearchInstance,
  SubmitProcessInstanceSchedule,
  UpdateStageInstanceList,
  ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData,
  UpdateProcessInstanceSchedule,
  GetArchivedProcessInstances,
  DeleteArchivedProcessInstance,
  ArchiveProcessInstance,
  RestoreArchivedProcessInstance,
  AddTeamMembers,
  DeleteTeamMember,
  GetOwnedProcessInstances,
  GetProcessInstancesByAssignedTasks,
  GetProcessInstanceTeamMembers,
  CancelDeleteProcess,
  DownloadProcess,
  GetProcessInstanceFilters,
  GetProcessInstancesByStatus,
  GetProcessInstancesByDate,
  GetProcessInstancesByFilter,
  SetProcessInstanceMultipleFilter,
  ResetProcessInstanceFilter,
} from './process-instance.actions';
import { filter, take, tap } from 'rxjs';
import {
  append,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { Deadline } from '../models/deadline.model';
import { ChangeDateFormatService } from 'src/app/core/services/change-date-format/change-date-format.service';

export interface ProcessInstanceStateModel {
  processInstances: PaginatedList<ProcessInstance>;
  ownedProcessInstances: PaginatedList<ProcessInstanceDetail>;
  selectedProcessInstance: ProcessInstance | undefined;
  update: boolean;
  processInstanceDetail: ProcessInstanceDetail | undefined;
  showSearchTermAndFiltersData: boolean;
  archivedProcessInstances: PaginatedList<ProcessInstance> | undefined;
  totalCount: number;
  processInstancesByAssignedTasks: PaginatedList<ProcessInstance>;
  processInstanceFilters:ProcessInstanceFilter[];
  multipleFilterData:ProcessInstanceMultipleFilter | undefined;
  isProcessInstanceFilterReset:boolean
}

const PROCESS_INSTANCE_TOKEN = new StateToken<ProcessInstanceStateModel>(
  'processInstanceState',
);

const defaults: ProcessInstanceStateModel = {
  update: false,
  processInstances: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  ownedProcessInstances: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedProcessInstance: undefined,
  processInstanceDetail: undefined,
  showSearchTermAndFiltersData: false,
  archivedProcessInstances: undefined,
  totalCount: 0,
  processInstancesByAssignedTasks: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  processInstanceFilters:[],
  multipleFilterData:undefined,
  isProcessInstanceFilterReset:false
};

@State<ProcessInstanceStateModel>({
  name: PROCESS_INSTANCE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ProcessInstanceState {
  constructor(
    public processInstanceService: ProcessInstanceService,
    public operationStatus: OperationStatusService,
    private store: Store,
    private changeDateFormatService: ChangeDateFormatService,
  ) {}

  @Action(GetProcessInstances)
  getProcessInstances(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { pageNumber, pageSize }: GetProcessInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstances(pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstances: paginatedProcessInstances,
            totalCount: paginatedProcessInstances.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetOwnedProcessInstances)
  getOwnedProcessInstances(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { pageNumber, pageSize }: GetOwnedProcessInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getOwnedProcessInstances(pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            ownedProcessInstances: paginatedProcessInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateProcessInstance)
  createProcessInstance(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { processInstance }: CreateProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().processInstances.totalCount;
    let pageSize =
      getState().processInstances.totalCount /
      getState().processInstances.totalPages;
    return this.processInstanceService
      .createProcessInstance(processInstance)
      .pipe(
        tap((processInstance: ProcessInstance) => {
          setState(
            patch({
              processInstances: patch({
                items: insertItem(processInstance),
                totalCount: (state) => prevLength + 1,
                totalPages: (state) => Math.ceil((prevLength + 1) / pageSize),
              }),
              totalCount: getState().totalCount + 1,
            }),
          );

          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-created-successfully: Process created successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetSelectedProcessInstance)
  setSelectedProcessInstance(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { processInstance }: SetSelectedProcessInstance,
  ) {
    patchState({
      selectedProcessInstance: processInstance,
    });
  }

  @Action(DownloadProcess)
  downloadProcess(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: DownloadProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService.downloadProcess(processInstanceId).pipe(
      tap((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `process.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { updateStatus }: SetUpdateStatus,
  ) {
    patchState({ update: updateStatus });
  }

  @Action(UpdateProcessInstance)
  updateProcessInstance(
    { patchState, getState }: StateContext<ProcessInstanceStateModel>,
    { processInstance }: UpdateProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .updateProcessInstance(processInstance)
      .pipe(
        filter((updatedProcessInstance) => updatedProcessInstance !== null),
        take(1),
        tap((updatedProcessInstance) => {
          let oldProcessInstances = getState().processInstances;
          let selectedProcessInstance = getState().selectedProcessInstance;
          if (selectedProcessInstance) {
            updatedProcessInstance = {
              ...selectedProcessInstance,
              title: updatedProcessInstance.title,
              description: updatedProcessInstance.description,
            };
          }
          let filteredProcessInstances = [
            updatedProcessInstance,
            ...oldProcessInstances.items.filter(
              (processInstance) =>
                processInstance.id !== updatedProcessInstance.id,
            ),
          ];
          let newPaginatedProcessInstances = getState().processInstances;
          newPaginatedProcessInstances.items = filteredProcessInstances;
          patchState({
            update: false,
            processInstances: newPaginatedProcessInstances,
            selectedProcessInstance: updatedProcessInstance,
          });
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-updated-successfully: Process updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AssignOwnerToResearchInstance)
  assignOwnerToResearchInstance(
    { patchState, getState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId, ownerId }: AssignOwnerToResearchInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .assignOwnerToResearchInstance(processInstanceId, ownerId)
      .pipe(
        filter((updatedProcessInstance) => updatedProcessInstance !== null),
        take(1),
        tap((updatedProcessInstance) => {
          let oldProcessInstances = getState().processInstances;
          let filteredProcessInstances = [
            updatedProcessInstance,
            ...oldProcessInstances.items.filter(
              (processInstance) =>
                processInstance.id !== updatedProcessInstance.id,
            ),
          ];
          let newPaginatedProcessInstances = getState().processInstances;
          newPaginatedProcessInstances.items = filteredProcessInstances;
          let oldProcessInstanceDetail = getState().processInstanceDetail;
          if (oldProcessInstanceDetail) {
            oldProcessInstanceDetail.ownerName =
              updatedProcessInstance.ownerName;
          }
          patchState({
            processInstances: newPaginatedProcessInstances,
            selectedProcessInstance: updatedProcessInstance,
            processInstanceDetail: oldProcessInstanceDetail,
          });
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.owner-assigned-successfully: Owner Assigned successfully`,
            successStyle,
          );
          this.store.dispatch(new GetProcessInstanceDetail(processInstanceId));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SearchProcessInstances)
  searchProcessInstances(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { searchTerm, processesIds }: SearchProcessInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .searchProcessInstances(searchTerm, processesIds)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstances: paginatedProcessInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResetSelectedProcessInstance)
  resetSelectedProcessInstance(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    {}: ResetSelectedProcessInstance,
  ) {
    patchState({
      selectedProcessInstance: undefined,
      processInstanceDetail: undefined,
      showSearchTermAndFiltersData: false,
    });
  }

  @Action(DeleteProcessInstance)
  deleteProcess(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { deleteProcessInstanceDto }: DeleteProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    const archivedProcess = getState().archivedProcessInstances;

    return this.processInstanceService
      .deleteProcessInstance(deleteProcessInstanceDto)
      .pipe(
        tap(() => {
          setState(
            patch({
              archivedProcessInstances: archivedProcess
                ? patch({
                    items: updateItem<ProcessInstance>(
                      (processInstance) =>
                        processInstance.id ===
                        deleteProcessInstanceDto.processInstanceId,
                      (processInstance) => ({
                        ...processInstance,
                        deleteScheduled: true,
                      }),
                    ),
                  })
                : undefined,
              processInstances: patch({
                items: removeItem(
                  (process) =>
                    process.id == deleteProcessInstanceDto.processInstanceId,
                ),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-deletion-scheduled-successfully: Process deletion scheduled successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedProcessInstance());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CancelDeleteProcess)
  cancelDelete(
    { setState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: CancelDeleteProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .cancelProcessDeletion(processInstanceId)
      .pipe(
        tap(() => {
          setState(
            patch({
              archivedProcessInstances: patch({
                items: updateItem<ProcessInstance>(
                  (processInstance) => processInstance.id === processInstanceId,
                  (processInstance) => ({
                    ...processInstance,
                    deleteScheduled: false,
                  }),
                ),
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-deletion-cancelled-successfully: Process deletion cancelled successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstanceDetail)
  getProcessInstanceDetail(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: GetProcessInstanceDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstanceDetail(processInstanceId)
      .pipe(
        tap((processInstanceDetail) => {
          patchState({
            processInstanceDetail: processInstanceDetail,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SubmitProcessInstanceSchedule)
  submitProcessInstanceSchedule(
    { patchState, setState, getState }: StateContext<ProcessInstanceStateModel>,
    { deadline }: SubmitProcessInstanceSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .submitProcessInstanceSchedule(deadline)
      .pipe(
        tap((updatedProcessInstance) => {
          let oldProcessInstance = getState().processInstanceDetail;

          setState(
            patch({
              processInstanceDetail: patch({
                ...oldProcessInstance,
                startDate: updatedProcessInstance?.startDate,
                endDate: updatedProcessInstance?.endDate,
                stageInstances: oldProcessInstance?.stageInstances.map(
                  (stageInstance) => {
                    const newStageInstance =
                      updatedProcessInstance?.stageInstances.find(
                        (s) => stageInstance.id === s.id,
                      );
                    const updatedStageInstance = {
                      ...stageInstance,
                      startDate: newStageInstance?.startDate,
                      endDate: newStageInstance?.endDate,
                      tasks: stageInstance.tasks?.map((task: any) => {

                        return {
                          ...task,
                          startDate: newStageInstance?.startDate,
                          endDate: newStageInstance?.endDate
                        };
                      })
                    };
  
                    return updatedStageInstance;
                }),
              }),
            })
          );

          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-duration-submitted-successfully: Process duration submitted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateProcessInstanceSchedule)
  updateProcessInstanceSchedule(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { deadline }: UpdateProcessInstanceSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .updateProcessInstanceSchedule(deadline)
      .pipe(
        tap((updatedProcessInstance) => {
          let oldProcessInstance = getState().processInstanceDetail;

          setState(
            patch({
              processInstanceDetail: patch({
                ...oldProcessInstance,
                startDate: updatedProcessInstance?.startDate,
                endDate: updatedProcessInstance?.endDate,
                stageInstances: oldProcessInstance?.stageInstances.map(
                  (stageInstance) => {
                    const newStageInstance =
                      updatedProcessInstance?.stageInstances.find(
                        (s) => stageInstance.id === s.id,
                      );
                    return {
                      ...stageInstance,
                      startDate: newStageInstance?.startDate,
                      endDate: newStageInstance?.endDate,
                    };
                  },
                ),
              }),
            }),
          );

          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-duration-submitted-successfully: Process duration submitted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateStageInstanceList)
  updateStageInstanceList(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { stageInstances }: UpdateStageInstanceList,
  ) {
    const { processInstanceDetail } = getState();
    if (processInstanceDetail) {
      processInstanceDetail.stageInstances = stageInstances;
      setState(
        patch({
          processInstanceDetail: processInstanceDetail,
        }),
      );
    }
  }
  @Action(ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData)
  toggleVisibilityOfProcessInstanceSearchTermAndFiltersData(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { visible }: ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData,
  ) {
    patchState({
      showSearchTermAndFiltersData: visible,
    });
  }

  @Action(GetArchivedProcessInstances)
  getArchivedProcessInstances(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { pageNumber, pageSize }: GetArchivedProcessInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getArchivedProcessInstances(pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            archivedProcessInstances: paginatedProcessInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteArchivedProcessInstance)
  deleteArchivedProcess(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { deleteProcessInstanceDto }: DeleteArchivedProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .deleteProcessInstance(deleteProcessInstanceDto)
      .pipe(
        tap(() => {
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-deleted-successfully: process deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedProcessInstance());
          this.store.dispatch(new GetProcessInstances(1, 10));
          this.store.dispatch(new GetArchivedProcessInstances(1, 10));
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(ArchiveProcessInstance)
  archiveProcessInstance(
    { setState, getState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: ArchiveProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .archiveProcessInstance(processInstanceId)
      .pipe(
        tap(() => {
          setState(
            patch({
              processInstances: patch({
                items: removeItem((processInstance) => {
                  return processInstance.id === processInstanceId;
                }),
              }),
              totalCount: getState().totalCount - 1,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-archived-successfully: Process archived successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedProcessInstance());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(RestoreArchivedProcessInstance)
  restoreArchivedprocessInstance(
    { setState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: RestoreArchivedProcessInstance,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .restoreArchivedProcessInstance(processInstanceId)
      .pipe(
        tap(() => {
          setState(
            patch({
              archivedProcessInstances: patch({
                items: removeItem(
                  (archivedProcessInstance) =>
                    archivedProcessInstance.id === processInstanceId,
                ),
              }),
            }),
          );
          this.store.dispatch(new GetProcessInstances());
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.process-archived-successfully: Process archived successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedProcessInstance());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(AddTeamMembers)
  addTeamMembers(
    { setState }: StateContext<ProcessInstanceStateModel>,
    { addTeamMembersDto }: AddTeamMembers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService.addTeamMembers(addTeamMembersDto).pipe(
      tap((processInstance) => {
        setState(
          patch({
            processInstanceDetail: patch({
              teamMembersIds: processInstance.teamMembersIds,
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.process-instance.team-member-added-to-process-instance-successfully: Team members added to process instance successfully`,
          successStyle,
        );
        this.store.dispatch(
          new GetProcessInstanceTeamMembers(
            addTeamMembersDto.processInstanceId,
          ),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteTeamMember)
  deleteTeamMembers(
    { setState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId, teamMemberId }: DeleteTeamMember,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .deleteTeamMember(processInstanceId, teamMemberId)
      .pipe(
        tap((processInstance) => {
          setState(
            patch({
              processInstanceDetail: patch({
                teamMembersIds: processInstance.teamMembersIds,
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.process-instance.team-member-removed-from-process-instance-successfully: Team member removed from process instance successfully`,
            successStyle,
          );
          if (processInstanceId)
            this.store.dispatch(
              new GetProcessInstanceTeamMembers(processInstanceId),
            );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstanceTeamMembers)
  getProcessInstanceTeamMembers(
    { setState }: StateContext<ProcessInstanceStateModel>,
    { processInstanceId }: GetProcessInstanceTeamMembers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstanceTeamMembers(processInstanceId)
      .pipe(
        tap((users) => {
          setState(
            patch({
              processInstanceDetail: patch({
                teamMembers: users,
              }),
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstancesByAssignedTasks)
  getProcessInstancesByAssignedTasks(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { pageNumber, pageSize }: GetProcessInstancesByAssignedTasks,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstancesByAssignedTasks(pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstancesByAssignedTasks: paginatedProcessInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstanceFilters)
  getProcessInstanceFilters(
    { patchState }: StateContext<ProcessInstanceStateModel>,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService.getProcessInstanceFilters().pipe(
      tap((filters) => {
        patchState({ processInstanceFilters: filters });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetProcessInstancesByStatus)
  getProcessInstancesByStatus(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { status, pageNumber, pageSize }: GetProcessInstancesByStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstancesByStatus(status, pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstances: paginatedProcessInstances,
            totalCount: paginatedProcessInstances.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstancesByDate)
  getProcessInstancesByDate(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { startDate, endDate, pageNumber, pageSize }: GetProcessInstancesByDate,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstancesByDate(startDate, endDate, pageNumber, pageSize)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstances: paginatedProcessInstances,
            totalCount: paginatedProcessInstances.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetProcessInstancesByFilter)
  getProcessInstancesByFilter(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { filterData }: GetProcessInstancesByFilter,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processInstanceService
      .getProcessInstancesByFilter(filterData)
      .pipe(
        tap((paginatedProcessInstances) => {
          patchState({
            processInstances: paginatedProcessInstances,
            totalCount: paginatedProcessInstances.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetProcessInstanceMultipleFilter)
  setProcessInstanceMultipleFilter(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    { filterData }: SetProcessInstanceMultipleFilter,
  ) {
    patchState({
      multipleFilterData: filterData,
    });
  }

  @Action(ResetProcessInstanceFilter)
  resetProcessInstanceFilter(
    { patchState }: StateContext<ProcessInstanceStateModel>,
    {  isReset }: ResetProcessInstanceFilter,
  ) {
    patchState({
      isProcessInstanceFilterReset: isReset,
    });
  }
}
