<mat-sidenav-container class="container">
  <mat-sidenav mode="side" opened class="left-menu mat-elevation-z1">
    <mat-nav-list>
      <ng-container *ngFor="let link of navLinks$ | async">
        <a
          mat-list-item
          [routerLink]="link.link"
          [routerLinkActive]="['is-active', 'active-link']"
          *ngIf="!link.hidden"
        >
          <mat-icon color="accent" *ngIf="link.icon" matListItemIcon>{{ link.icon }}</mat-icon>
          <span matListItemTitle>{{ link.label }}</span>
        </a>
      </ng-container>
      <a
      mat-list-item
      href="assets/manuals/office/office-module.html"
      target="_blank"
    >
      <mat-icon color="accent" matListItemIcon>help_outline</mat-icon>
      <span matListItemTitle i18n="@@offices.office-home.user-manual">User Manual</span>
    </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content mat-elevation-z1">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
