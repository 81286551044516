import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CurrencyValidator {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^[A-Za-z]+$/;

      if (!value || pattern.test(value)) return null;

      return { isValidCurrency: `Field must be a valid currency.` };
    };
  }
}
