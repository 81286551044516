<mat-dialog-content mat-dialog-content class="form-container">
  <form class="role-form" [formGroup]="roleForm">
    <h3>{{ formTitle }}</h3>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@users.role-form.name">Name</mat-label>
      <input
        id="name"
        formControlName="name"
        type="text"
        matInput
        class="full-width-input"
        [placeholder]=placeholderLabel.roleName
        [attr.data-test-id]="'users.create-role.role-form.input-for-role-name'"
      />
      <mat-error *ngIf="roleForm.get('name')?.hasError('required')" i18n="@@users.role-form.please-provide-a-valid-name">
        Please provide a valid name
      </mat-error>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@users.role-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!roleForm.valid"
    >
      {{ saveButtonText }}
    </button>
  </div>
</mat-dialog-content>
