import {
  FlatOfficeNode,
  OrgFlatOfficeNode,
} from './../../models/flat-office-node.model';
import { OfficeFacade } from './../../facades/office.facades';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable, tap } from 'rxjs';
import * as echarts from 'echarts';
import { OrgChart } from 'd3-org-chart';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface OfficeOrganogramComponentState {
  flatOfficeNodes: FlatOfficeNode[];
}

const initOfficeOrganogramComponentState = {};

@Component({
  selector: 'app-office-organogram',
  templateUrl: './office-organogram.component.html',
  styleUrls: ['./office-organogram.component.scss'],
})
export class OfficeOrganogramComponent implements OnInit, AfterViewInit {
  @ViewChild('chartContainer') chartContainer: any;
  myChart: echarts.ECharts | undefined;
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.state.select('flatOfficeNodes');
  treeNodes: OrgFlatOfficeNode[] = [];
  chart: any = null;

  constructor(
    private OfficeFacade: OfficeFacade,
    private state: RxState<OfficeOrganogramComponentState>,
  ) {
    this.state.set(initOfficeOrganogramComponentState);
    this.state.connect('flatOfficeNodes', this.OfficeFacade.flatOfficeNodes$);
  }
  ngAfterViewInit(): void {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.prepareChart();
  }

  ngOnInit(): void {
    this.OfficeFacade.dispatchGetFlatOfficeNodes();
    this.flatOfficeNodes$.subscribe((nodes: FlatOfficeNode[]) => {
      let pathToNode = this.mapPathToNode(nodes);
      let orgNodes = [];
      for (let node of nodes) {
        let parentId = this.findParentId(node, pathToNode);
        let treeNode: OrgFlatOfficeNode = { ...node, parentId };
        orgNodes.push(treeNode);
      }
      this.treeNodes = orgNodes;
      this.prepareChart();
    });
  }

  findParentId(
    node: FlatOfficeNode,
    pathToNode: Map<string, FlatOfficeNode>,
  ): string {
    let currPath = node.pathFromRoot;
    let dotLastIndex = currPath.lastIndexOf('.');
    if (dotLastIndex === -1) return '';

    let parentPath = currPath.slice(0, dotLastIndex);
    if (pathToNode.has(parentPath)) return pathToNode.get(parentPath)?.id!;
    else return '';
  }

  mapPathToNode(nodes: FlatOfficeNode[]): Map<string, FlatOfficeNode> {
    let pathToNode = new Map<string, FlatOfficeNode>();
    for (let node of nodes) pathToNode.set(node.pathFromRoot, node);

    return pathToNode;
  }

  prepareChart(): void {
    if (!this.treeNodes) {
      return;
    }
    if (!this.chart) {
      return;
    }

    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.treeNodes)
      .rootMargin(100)
      .nodeWidth((d: any) => 180)
      .nodeHeight((d: any) => 80)
      .childrenMargin((d: any) => 130)
      .compactMarginBetween((d: any) => 75)
      .compactMarginPair((d: any) => 80)
      .nodeContent(function (
        d: {
          depth: number;
          width: number;
          height: any;
          data: { imageUrl: any; name: any; shortName: any };
        },
        i: any,
        arr: any,
        state: any,
      ) {
        const colors = [
          '#6E6B6F',
          '#18A8B6',
          '#F45754',
          '#96C62C',
          '#BD7E16',
          '#802F74',
        ];
        const color = colors[d.depth % colors.length];

        return `
            <div style="background-color:white; position:absolute;width:${
              d.width
            }px;height:${d.height}px;white-space:nowrap;" >
                  <div style="background-color:${color};height:28px;text-align:center;padding-top:10px;color:#ffffff;font-weight:bold;font-size:16px;overflow:hidden;padding-left:8px;padding-right:8px;" title="${
                    d.data.name
                  }">
                    ${d.data.name.substring(0, 20)}${
                      d.data.name.length > 20 ? '...' : ''
                    }
                  </div>
                  <div style="background-color:#F0EDEF;height:28px;text-align:center;padding-top:10px;color:#424142;font-size:16px">
                      ${d.data.shortName}
                  </div>
               </div>
           </div>`;
      })
      .render();
  }

  hasGetFlatOfficeNodesPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.GetFlatOfficeNodes,
    );
  }
}
