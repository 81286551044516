import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { Role } from '../../models/role.model';
import { AngularGridInstance, Column, GridOption } from 'angular-slickgrid';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleFacade } from '../../facade/role.facade';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, Subject, debounceTime } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { RoleFormComponent } from '../role-form/role-form.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ASSIGN_REVOKE_PERMISSIONS } from 'src/app/core/constants/routes';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface RoleListComponentState {
  paginatedRoles: Role[];
  length: number;
  selectedRole: Role | undefined;
}

const initRoleListComponentState: Partial<RoleListComponentState> = {
  paginatedRoles: [],
  length: 0,
  selectedRole: undefined,
};

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: RoleListComponent}],
})
export class RoleListComponent implements OnInit, MatPaginatorIntl {
  angularGrid!: AngularGridInstance;
  dataset!: Role[];

  paginatedRoles$: Observable<Role[]> = this.state.select('paginatedRoles');
  paginatedRoles: Role[] = [];
  selectedRole$: Observable<Role | undefined> =
    this.state.select('selectedRole');
  selectedRole: Role | undefined = undefined;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length$ = this.state.select('length');
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;

  firstPageLabel = $localize`:@@users.role-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@users.role-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@users.role-list.last-page: Last page`;

  nextPageLabel = $localize`:@@users.role-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@users.role-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@users.role-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@users.role-list.page-part-one: Page` + `${page + 1}` + $localize`:@@users.role-list.page-part-two: of` + `${amountPages}`;
  }

  searchRoleForm!: FormGroup;
  destroyRef = inject(DestroyRef);

  constructor(
    private matDialog: MatDialog,
    private roleFacade: RoleFacade,
    private state: RxState<RoleListComponentState>,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.state.set(initRoleListComponentState);
    this.state.connect('paginatedRoles', this.roleFacade.roles$);
    this.state.connect('length', this.roleFacade.totalCount$);
    this.state.connect('selectedRole', this.roleFacade.selectedRole$);

    this.searchRoleForm = this.fb.group({
      searchTerm: [''],
    });
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.roleFacade.dispatchGetPaginatedRoles(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10,
    );

    this.paginatedRoles$.subscribe(
      (paginatedRoles) => (this.dataset = paginatedRoles),
    );
    this.length$.subscribe((length) => {
      this.length = length;
    });
    this.selectedRole$.subscribe((role) => {
      this.selectedRole = role;
    });
  }

  loadPaginatedRoles(event: PageEvent) {
    this.roleFacade.dispatchGetPaginatedRoles(
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  angularGridReady(event: Event) {
    const angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.angularGrid = angularGrid;
  }

  addRole() {
    this.roleFacade.dispatchSetUpdateStatus(false);
    this.matDialog.open(RoleFormComponent, SIDE_DIALOG_CONFIG);
  }

  updateRole() {
    this.roleFacade.dispatchSetUpdateStatus(true);
    this.matDialog.open(RoleFormComponent, SIDE_DIALOG_CONFIG);
  }

  onRowClick(event: MouseEvent, role: Role): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    if (isMenuButtonClick) return;
  }

  setSelectedRole(event: MouseEvent, role: Role) {
    event.stopPropagation();
    this.roleFacade.dispatchSetSelectedRole(role);
  }

  assignOrRevokePermissions() {
    this.router.navigate([ASSIGN_REVOKE_PERMISSIONS]);
  }

  deleteRole() {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@users.role-list.delete-role-confirmation-text: Are you sure you want to delete the role` ,
        boldText: `"${this.selectedRole?.name}"?` ,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm' && this.selectedRole) {
        this.roleFacade.dispatchDeleteRole(this.selectedRole.id);
      }
    });
  }

  hasGetRolesPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.GetRoles,
    );
  }

  hasCreateRolePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.CreateRole,
    );
  }

  hasAssignRevokePermissionsToRolePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.AssignRevokePermissionsToRole,
    );
  }

  hasUpdateRolePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.UpdateRole,
    );
  }

  hasDeleteRolePermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.DeleteRole,
    );
  }

  hasCrudPermission() {
    return [
      this.hasAssignRevokePermissionsToRolePermission(),
      this.hasUpdateRolePermission(),
      this.hasDeleteRolePermission()
    ]
  }

  hasGetUsersBySearchPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.Role.Feature,
      PERMISSION_NAMES.Identities.Role.SearchRoles,
    );
  }

  searchRole() {
    this.searchRoleForm.get('searchTerm')
        ?.valueChanges.pipe(
          debounceTime(500),
          takeUntilDestroyed(this.destroyRef),
          ).subscribe((value) => {
      if(value !== ''){
        this.roleFacade.dispatchSearchRole(
          value,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize || 10,
        );
      } else {
        this.roleFacade.dispatchGetPaginatedRoles(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize || 10,
        );
      }
    });
  }

  resetSearch() {
    this.searchRoleForm.reset();
    this.roleFacade.dispatchGetPaginatedRoles(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize || 10,
    );
  }
}
