import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PublicationService } from '../services/publication.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  CreatePublication,
  DeletePublicationProcessDocument,
  GetCompletedProcessInstances,
  GetPublications,
  SetSelectedPublicationTab,
  UploadPublicationFiles,
  SetSelectedPublication,
  SetSelectedPublicationOption,
  SubmitPublicationProcess,
  ChangePublicationStatus,
  GetPaginatedPublicationComments,
  AddPublicationComment,
  DeletePublicationComment,
  ReplyPublicationComment,
  UpdatePublicationComment,
  UpdatePublicationCommentReply,
  DeletePublicationCommentReply,
  DownloadPublicationDocument,
  PreviewPublicationDocument,
  TogglePublicationComments,
} from './publication.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { of, tap } from 'rxjs';
import {
  errorStyle,
  successStyle,
} from 'src/app/core/services/operation-status/status-style-names';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstance } from '../models/process-instance.model';
import {
  Publication,
  PublicationComment,
  PublicationStatus,
} from '../models/publication.model';
import { PublicationOption } from '../models/publication-option.model';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';

export interface PublicationStateModel {
  completedProcessInstances?: PaginatedList<ProcessInstance>;
  publications?: PaginatedList<Publication>;
  selectedTabIndex: number;
  selectedPublication?: Publication;
  selectedPublicationOption?: PublicationOption;
  publicationComments: PaginatedList<PublicationComment>;
  isPublicationCommentVisible: boolean;
}

const PUBLICATION_STATE_TOKEN = new StateToken<PublicationStateModel>(
  'PublicationState',
);

const defaults: PublicationStateModel = {
  selectedTabIndex: 0,
  publicationComments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  isPublicationCommentVisible: false,
};

@State<PublicationStateModel>({
  name: PUBLICATION_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class PublicationState {
  constructor(
    private readonly publicationService: PublicationService,
    private readonly store: Store,
    private readonly operationStatus: OperationStatusService,
  ) {}

  @Action(CreatePublication)
  createPublication(
    { patchState }: StateContext<PublicationStateModel>,
    { publication }: CreatePublication,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService.createPublication(publication).pipe(
      tap((createdPub) => {
        this.operationStatus.displayStatus(
          $localize`:@@researches.publication.publication-started-successfully: Publication started Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetCompletedProcessInstances)
  getCompletedProcessInstances(
    { patchState }: StateContext<PublicationStateModel>,
    { pageNumber, pageSize }: GetCompletedProcessInstances,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .getCompletedProcessInstances(pageNumber, pageSize)
      .pipe(
        tap((processInstances) => {
          patchState({
            completedProcessInstances: processInstances,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetPublications)
  getPublications(
    { patchState }: StateContext<PublicationStateModel>,
    { pageNumber, pageSize }: GetPublications,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService.getPublications(pageNumber, pageSize).pipe(
      tap((publications) => {
        patchState({
          publications: publications,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedPublicationTab)
  setSelectedPublicationTab(
    { patchState }: StateContext<PublicationStateModel>,
    { selectedTabIndex }: SetSelectedPublicationTab,
  ) {
    patchState({
      selectedTabIndex: selectedTabIndex,
    });
  }

  @Action(SetSelectedPublication)
  setSelectedPublication(
    { patchState }: StateContext<PublicationStateModel>,
    { publication }: SetSelectedPublication,
  ) {
    patchState({
      selectedPublication: publication,
    });
  }

  @Action(SetSelectedPublicationOption)
  setSelectedPublicationTemplates(
    { patchState }: StateContext<PublicationStateModel>,
    { publicationOption }: SetSelectedPublicationOption,
  ) {
    patchState({
      selectedPublicationOption: publicationOption,
    });
  }

  @Action(UploadPublicationFiles)
  uploadPublicationFiles(
    { getState, patchState }: StateContext<PublicationStateModel>,
    { files, id }: UploadPublicationFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService.postPublicationFiles(files, id).pipe(
      tap((response) => {
        this.store.dispatch(new GetPublications());
        const state = getState();
        const publication = state.selectedPublication;
        if (publication) {
          const updatedPublicationProcesses =
            publication.publicationProcesses.map((process) => {
              if (process.id === id) {
                return { ...process, publicationDocument: response };
              }
              return process;
            });

          const updatedPublication = {
            ...publication,
            publicationProcesses: updatedPublicationProcesses,
          };

          patchState({ selectedPublication: updatedPublication });
        }
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.publication.publication-documents-uploaded-successfully: Publication Documents uploaded successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(DeletePublicationProcessDocument)
  DeletePublicationProcessDocument(
    { getState, patchState }: StateContext<PublicationStateModel>,
    { processId, documentId }: DeletePublicationProcessDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .removePublicationProcessDocument(processId, documentId)
      .pipe(
        tap(() => {
          const state = getState();
          const publication = state.selectedPublication;
          if (publication) {
            const updatedPublicationProcesses =
              publication.publicationProcesses.map((process) => {
                if (process.id === processId) {
                  const updatedPublicationDocuments =
                    process.publicationDocument?.filter(
                      (doc) => doc.id !== documentId,
                    );
                  return {
                    ...process,
                    publicationDocument: updatedPublicationDocuments,
                  };
                }
                return process;
              });

            const updatedPublication = {
              ...publication,
              publicationProcesses: updatedPublicationProcesses,
            };

            patchState({ selectedPublication: updatedPublication });

            this.operationStatus.displayStatus(
              $localize`:@@researches.publication.document-deleted-successfully: Document deleted successfully`,
              successStyle,
            );
          }
          this.store.dispatch(new GetPublications());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SubmitPublicationProcess)
  submitPublicationProcess(
    { getState, patchState }: StateContext<PublicationStateModel>,
    { id }: SubmitPublicationProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService.submitPublicationDocuments(id).pipe(
      tap((response) => {
        const state = getState();
        const publication = state.selectedPublication;

        if (publication) {
          const updatedPublicationProcesses =
            publication.publicationProcesses.map((process) => {
              if (process.id === id) {
                return {
                  ...process,
                  publicationStatus: PublicationStatus.Submitted,
                  publicationStatusForDisplay: response
                };
              }
              return process;
            });

          const updatedPublication = {
            ...publication,
            publicationProcesses: updatedPublicationProcesses,
          };

          patchState({ selectedPublication: updatedPublication });

          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.publication-submitted-successfully: Publication submitted Successfully`,
            successStyle,
          );
        }
        this.store.dispatch(new GetPublications());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ChangePublicationStatus)
  updatePublicationStatus(
    { patchState, getState }: StateContext<PublicationStateModel>,
    { id, status ,statusForDisplay }: ChangePublicationStatus,
  ) {
    return this.publicationService.changePublicationStatus(id, status).pipe(
      tap((response) => {
        this.store.dispatch(new SetProgressOn());
        const selectedPublication = getState().selectedPublication;

        if (selectedPublication && selectedPublication.publicationProcesses) {
          const updatedProcesses = selectedPublication.publicationProcesses.map(
            (process) =>
              process.id === id
                ? { ...process, publicationStatus: status , publicationStatusForDisplay: response }
                : process,
          );

          const updatedSelectedPublication = {
            ...selectedPublication,
            publicationProcesses: updatedProcesses,
          };
          patchState({ selectedPublication: updatedSelectedPublication });
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.publication-process-started-successfully: Publication process updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(GetPaginatedPublicationComments)
  getPaginatedPublicationComments(
    { setState }: StateContext<PublicationStateModel>,
    {
      publicationProcessId,
      pageNumber,
      pageSize,
    }: GetPaginatedPublicationComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .getPaginatedPublicationComments(
        publicationProcessId,
        pageNumber,
        pageSize,
      )
      .pipe(
        tap((paginatedTaskComments) => {
          setState(
            patch({
              publicationComments: paginatedTaskComments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AddPublicationComment)
  addPublicationComment(
    { setState, getState }: StateContext<PublicationStateModel>,
    { publicationProcessId, comment }: AddPublicationComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    var state = getState();
    const totalCount = state.publicationComments.totalCount;
    return this.publicationService
      .addPublicationComment(publicationProcessId, comment)
      .pipe(
        tap((publicationComment) => {
          setState(
            patch({
              publicationComments: patch({
                items: insertItem(publicationComment, totalCount),
                totalCount: totalCount + 1,
              }),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.comment-added-successfully: Comment Added Successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeletePublicationComment)
  deleteTaskComment(
    { setState, getState }: StateContext<PublicationStateModel>,
    { commentId }: DeletePublicationComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    const totalCount = getState().publicationComments.totalCount;
    return this.publicationService.deletePublicationComment(commentId).pipe(
      tap(() => {
        setState(
          patch({
            publicationComments: patch({
              items: removeItem<PublicationComment>(
                (item) => item.id === commentId,
              ),
              totalCount: totalCount - 1,
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.publication.comment-deleted-successfully: Comment deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ReplyPublicationComment)
  replyTaskComment(
    { setState, getState }: StateContext<PublicationStateModel>,
    { commentId, content }: ReplyPublicationComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .replyPublicationComment(commentId, content)
      .pipe(
        tap((createdReply) => {
          const state = getState();
          const comments = state.publicationComments.items;
          const commentIndex = comments.findIndex((c) => c.id === commentId);

          if (commentIndex > -1) {
            const comment = comments[commentIndex];
            const updatedReplies = [...comment.replies, createdReply];

            const updatedComments = [
              ...comments.slice(0, commentIndex),
              { ...comment, replies: updatedReplies },
              ...comments.slice(commentIndex + 1),
            ];

            setState({
              ...state,
              publicationComments: {
                ...state.publicationComments,
                items: updatedComments,
              },
            });
          }

          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.reply-added-successfully:  Reply added successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdatePublicationComment)
  updateTaskComment(
    { getState, setState }: StateContext<PublicationStateModel>,
    { publicationCommentId, content }: UpdatePublicationComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .updatePublicationComment(publicationCommentId, content)
      .pipe(
        tap((updatedContent) => {
          const state = getState();
          const comments = state.publicationComments.items;
          const commentIndex = comments.findIndex(
            (c) => c.id === publicationCommentId,
          );

          if (commentIndex !== -1) {
            const updatedComments = [...comments];
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              content: updatedContent.content,
            };

            setState({
              ...state,
              publicationComments: {
                ...state.publicationComments,
                items: updatedComments,
              },
            });

            this.operationStatus.displayStatus(
              $localize`:@@researches.publication.comment-updated-successfully: Comment updated successfully`,
              successStyle,
            );
          }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdatePublicationCommentReply)
  updatePublicationCommentReply(
    { getState, setState }: StateContext<PublicationStateModel>,
    { replyId, content, parentCommentId }: UpdatePublicationCommentReply,
  ) {
    return this.publicationService
      .updateReplyPublicationCommentReply(parentCommentId, replyId, content)
      .pipe(
        tap((updatedReply) => {
          const state = getState();
          const updatedComments = state.publicationComments.items.map(
            (comment) => {
              if (comment.id === parentCommentId) {
                const updatedReplies = comment.replies.map((reply) => {
                  if (reply.id === replyId) {
                    return { ...reply, content: content };
                  }
                  return reply;
                });
                return { ...comment, replies: updatedReplies };
              }
              return comment;
            },
          );

          setState({
            ...state,
            publicationComments: {
              ...state.publicationComments,
              items: updatedComments,
            },
          });

          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.reply-updated-successfully: Reply updated successfully`,
            successStyle,
          );
        }),
      );
  }

  @Action(DeletePublicationCommentReply)
  removePublicationCommentReply(
    { getState, setState }: StateContext<PublicationStateModel>,
    { replyId, parentCommentId }: DeletePublicationCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .deletePublicationCommentReply(parentCommentId, replyId)
      .pipe(
        tap((success) => {
          const state = getState();
          const parentCommentIndex = state.publicationComments.items.findIndex(
            (comment) => comment.id === parentCommentId,
          );
          if (parentCommentIndex !== -1) {
            const updatedReplies = state.publicationComments.items[
              parentCommentIndex
            ].replies.filter((reply) => reply.id !== replyId);
            const updatedComments = [...state.publicationComments.items];
            updatedComments[parentCommentIndex] = {
              ...updatedComments[parentCommentIndex],
              replies: updatedReplies,
            };
            setState(
              patch({
                publicationComments: patch({
                  items: updatedComments,
                }),
              }),
            );
          }
          this.operationStatus.displayStatus(
            $localize`:@@researches.publication.reply-removed-successfully: Reply removed successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DownloadPublicationDocument)
  downloadPublicationDocument(
    { setState }: StateContext<PublicationStateModel>,
    { publicationDocument }: DownloadPublicationDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.publicationService
      .downloadPublicationDocument(publicationDocument.id)
      .pipe(
        tap((response: any) => {
          const blob = new Blob([response], {
            type: 'application/octate-stream',
          });

          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = fileUrl;
          link.download = publicationDocument.fileName;
          link.click();
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(PreviewPublicationDocument)
  previewPublicationDocument(
    { setState }: StateContext<PublicationStateModel>,
    { publicationProcessId, fileId, fileName }: PreviewPublicationDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    var fileType = 'pdf';
    var contentType = 'application/pdf';
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (fileExtension === 'jpg' ||fileExtension === 'jpeg' ||fileExtension === 'png' ||fileExtension === 'gif') {
      fileType = 'image';
    }
    else if(fileExtension !== 'pdf'){
      this.operationStatus.displayStatus(
        $localize`:@@researches.publication.file-type-not-supported: File type not supported`,
        errorStyle,
      );
      this.store.dispatch(new SetProgressOff());
      return of();
    }
    if (fileType === 'image') {
      contentType = `image/${fileExtension}`;
    }


    return this.publicationService.getPublicationFile(publicationProcessId, fileId).pipe(
      tap((response) => {
        const blob = new Blob([response], { type: contentType });
        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl, '_blank');
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(TogglePublicationComments)
  togglePublicationComments(
    { patchState }: StateContext<PublicationStateModel>,
    { isPublicationCommentVisible }: TogglePublicationComments,
  ) {
    patchState({
      isPublicationCommentVisible: isPublicationCommentVisible,
    });
  }
}
