<div class="w-100" *ngIf="currentLoggedInUser?.id === taskDetail?.assignee?.id">
  <h2 i18n="@@researches.task-file-upload.upload-files">Upload Files</h2>
  <div
    class="drop-zone w-100 flex-center"
    [class.dragover]="isDragOver"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <ng-container *ngIf="files.length === 0">
      <p i18n="@@researches.task-file-upload.drag-and-drop-files-here">Drag and drop files here</p>
    </ng-container>
    <div *ngFor="let file of files">
      <div class="flex-center">
        <p>{{ file.name }}</p>
        <mat-icon class="pointer" color="warn" (click)="removeFile(file)"
          >close</mat-icon
        >
      </div>
      <mat-error *ngIf="isFileInFailedReports(file)">{{
        errorForForTheFailedReport(file)
      }}</mat-error>
    </div>
    <button mat-flat-button color="primary" (click)="selectFile()"
    i18n="@@researches.task-file-upload.select-files"
    [attr.data-test-id]="'researches.upload-file.task-file-upload.select-files-button'">
      Select Files
    </button>
  </div>

  <button
    mat-flat-button
    color="primary"
    [disabled]="isUploadButtonDisabled()"
    (click)="uploadFiles()"
    class="mt-15"
    i18n="@@researches.task-file-upload.upload"
    [attr.data-test-id]="'researches.upload-file.task-file-upload.upload-button'"
  >
    Upload
  </button>
</div>
