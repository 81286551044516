<div>

  <form [formGroup]="searchForm" class="search-form-container">
    <mat-form-field appearance="outline" class="process-selector">
      <mat-label i18n="@@researches.process-instance-search.choose-process-type" [attr.data-test-id]="'researches.filter-process-instance.process-instance-search.input-for-choose-process-type'">Choose Process Type</mat-label>
      <mat-select
        formControlName="processTypes"
        multiple
        (selectionChange)="onProcessSelect()"
      >
        <mat-option *ngFor="let process of processes" [value]="process" [attr.data-test-id]="'researches.filter-process-instance.process-instance-search.process-type-option-input'">{{
          process.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="process-filter">
      <mat-label i18n="@@documents.process-instance-search.choose-filter">Choose Filter</mat-label>
      <mat-select
        formControlName="filter"
        (selectionChange)="onProcessFilterSelect($event)"
      >
        <mat-option *ngFor="let filter of processInstanceFilters" [value]="filter.processStatus">{{
          filter.processStatus
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="date-filter">
      <mat-label i18n="@@offices.offices-count.start-and-end-date">Start and End Date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="datePlaceholderToggleLabel.startDate"
          formControlName="startDate"
        />
        <input matEndDate placeholder="datePlaceholderToggleLabel.endDate" formControlName="endDate" />
      </mat-date-range-input>
      <mat-hint i18n="@@researches.process-instance-search.mm-dd-yyyy-mm-dd-yyyy">MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="search-term-input">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        [placeholder]="placeholderToggleLabel.Africa"
        formControlName="searchTerm"
        (input)="onSerchTermChange()"
        [attr.data-test-id]="
              'researches.update-process-instance.process-instance-search.process-instance-search-input'
            "
      />
    </mat-form-field>
  </form>
  <div class="w-full">
    <button
      mat-flat-button
      [disabled]="isResetFilterDisabled()"
      color="warn"
      (click)="resetFilter()"
      i18n="@@offices.offices-count.reset-filter">
      Reset Filter
    </button>
  </div>
</div>
