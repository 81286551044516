import { Component, OnInit } from '@angular/core';
import { ContactForm, UpdateContactUsStatusDto, UpdateContactUsPriorityDto } from '../../models/contact-form.model';
import { ContactUsFacade } from '../../facade/contact.facade';
import { RxState } from '@rx-angular/state';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Priority, Status } from 'src/app/core/constants/reported-issues-constants';

export interface ReportedIssueDetailComponentState {
  selectedReportedIssueDetail: ContactForm | undefined;
}

const initReportedIssueDetailComponentState: ReportedIssueDetailComponentState = {
  selectedReportedIssueDetail: undefined,
};

@Component({
  selector: 'app-reported-issue-detail',
  templateUrl: './reported-issue-detail.component.html',
  styleUrl: './reported-issue-detail.component.scss'
})


export class ReportedIssueDetailComponent implements OnInit {
  selectedReportedIssueDetail$ = this.state.select('selectedReportedIssueDetail');
  selectedReportedIssueDetail: ContactForm | undefined;
  isStatusDropdownOpen: boolean = false;
  isPriorityDropdownOpen: boolean = false;
  statuses: Status[] = [Status.Reported, Status.InProgress, Status.Fixed];
  resolvedStatuses: Status[] = [Status.Resolved, Status.Unresolved];
  priorities: Priority[] = [Priority.High, Priority.Medium, Priority.Low];

  statusContact = {
    open: Status.Reported,
    inprogress: Status.InProgress,
    closed: Status.Fixed
  };

  priorityContact = {
    high: Priority.High,
    medium: Priority.Medium,
    low: Priority.Low
  };

  statusForDisplay: { [key in Status]: string } = {
    [Status.Reported]: $localize`:@@users.reported-issue-detail.reported: Reported`,
    [Status.InProgress]: $localize`:@@users.reported-issue-detail.in-progress: InProgress`,
    [Status.Fixed]: $localize`:@@users.reported-issue-detail.fixed: Fixed`,
    [Status.Resolved]: $localize`:@@users.reported-issue-detail.resolved: Resolved`,
    [Status.Unresolved]: $localize`:@@users.reported-issue-detail.unresolved: Unresolved`,
  };  

  priorityForDisplay: { [key in Priority]: string } = {
    [Priority.High]: $localize`:@@users.reported-issue-detail.high: High`,
    [Priority.Medium]: $localize`:@@users.reported-issue-detail.medium: Medium`,
    [Priority.Low]: $localize`:@@users.reported-issue-detail.low: Low`,
  };  

  constructor(
    private contactUsFacade: ContactUsFacade,
    private state: RxState<ReportedIssueDetailComponentState>,
    private dialog: MatDialog,
  ) {
    this.state.set(initReportedIssueDetailComponentState);
    this.state.connect(
      'selectedReportedIssueDetail',
      this.contactUsFacade.selectedContactForm$,
    );
  }

  ngOnInit() {
    this.selectedReportedIssueDetail$.subscribe((selectedReportedIssueDetail) => {
      this.selectedReportedIssueDetail = selectedReportedIssueDetail;
    });
  }
  toggleStatusDropdown() {
    this.isStatusDropdownOpen = !this.isStatusDropdownOpen;
  }
  togglePriorityDropdown() {
    this.isPriorityDropdownOpen = !this.isPriorityDropdownOpen;
  }

  selectStatus(status: Status) {
    if (!this.selectedReportedIssueDetail) return;
  
    const statusIdMap: { [key in Status]: string } = {
      Reported: 'Reported',
      InProgress:  'InProgress',
      Fixed: 'Fixed',
      Resolved: 'Resolved',
      Unresolved: 'Unresolved',
    };
  
    const statusDto = {
      id: statusIdMap[status],
      statusName: status,
    };
  
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@users.reported-issue-detail.status-mark-part-one: Are you sure you want to mark` ,
        boldText:` "${this.selectedReportedIssueDetail!.subject}" ` ,
        regularTextTwo: $localize`:@@users.reported-issue-detail.status-mark-part-two: as` + `${this.statusForDisplay[status]}?`,
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedContactUs = { id: this.selectedReportedIssueDetail?.id, status: statusDto };
        this.contactUsFacade.dispatchChangeContactUsStatus(updatedContactUs);
      }
    });
  }
  selectPriority(priority: Priority) {
    const priorityIdMap: { [key in Priority]: string } = {
      High: 'High',
      Medium:  'Medium',
      Low: 'Low',
    };

    const priorityDto = {
      id: priorityIdMap[priority],
      priorityName: priority 
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@users.reported-issue-detail.priority-mark-part-one: Are you sure you want to mark` ,
        boldText:` "${this.selectedReportedIssueDetail!.subject}" ` ,
        regularTextTwo: $localize`:@@users.reported-issue-detail.priority-mark-part-two: as` + `${this.priorityForDisplay[priority]}?`,
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedContactUs = { id: this.selectedReportedIssueDetail?.id, priority: priorityDto };
        this.contactUsFacade.dispatchChangeContactUsPriority(updatedContactUs);
      }
    });
  }

  checkIssueStatus(status: any) {
    if (status && status.statusName) {
      return this.statusForDisplay[status.statusName as Status];
    } else {
      return $localize`:@@users.reported-issue-detail.reported: Reported`;
    }
  }
  checkIssuePriority(priority: any) {
    if (priority && priority.priorityName) {
      return this.priorityForDisplay[priority.priorityName as Priority] || $localize`:@@users.reported-issue-detail.not-set: Not Set`;
    } else {
      return $localize`:@@users.reported-issue-detail.not-set: Not Set`;
    }
  }

  downloadAttachment(attachment: any) {
    this.contactUsFacade.dispatchDownloadAttachment(
      this.selectedReportedIssueDetail?.id as string,
      attachment.id,
      attachment.name,
    );
  }

  previewAttachment(attachment: any) {
    this.contactUsFacade.dispatchPreviewAttachment(
      this.selectedReportedIssueDetail?.id as string,
      attachment.id,
      attachment.name,
    );
  }

  hasUpdateContactUsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.UpdateContactUs,
    );
  }
  hasUpdateContactUsStatusPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.UpdateContactUsStatus,
    );
  }
  hasUpdateContactUsPriorityPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.UpdateContactUsPrioriry,
    );
  }
  hasGetContactUsAttachmentPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetContactUsAttachment,
    );
  }

  hasGetMyContactsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetMyContact,
    );
  }
  hasGetAllContactsPermission(): string {
    return GetFullPermissionName(
      MODULES.IDENTITIES,
      PERMISSION_NAMES.Identities.ContactUs.Feature,
      PERMISSION_NAMES.Identities.ContactUs.GetAllContactUs,
    );
  }
}

