<h2 mat-dialog-title>{{ message }}</h2>
<div mat-dialog-content class="mat-dialog">
  <div class="my-1">
    <span>{{ regularTextOne }}</span>
    <span class="font-bold">{{ boldText }}</span>
    <span>{{ regularTextTwo }}</span>
  </div>
  <div class="my-1"></div>
  <span class="font-bold">{{ extra }}</span>
  <div class="my-1"></div>
  <div class="full-width-input" z>
    <textarea
      type="text"
      class="input-style"
      rows="3"
      (input)="onConfirmTextChange($event)"
      placeholder="{{ placeholder }}"
      [attr.data-test-id]="'shared.confirm-generic.confirm-generic-dialog.textarea-input'"
    ></textarea>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button
    [mat-dialog-close]="true"
    mat-flat-button
    color="accent"
    (click)="onConfirm()"
    [disabled]="!confirmText"
    i18n="@@shared.confirm-with-comment-dialog.yes"
    [attr.data-test-id]="'shared.confirm-generic.confirm-generic-dialog.yes-button'"
  >
    Yes
  </button>
  <button mat-flat-button mat-dialog-close color="primary" i18n="@@shared.confirm-with-comment-dialog.cancel">Cancel</button>
</div>
