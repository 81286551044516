import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import {
  AngularGridInstance,
  Column,
  GridOption,
  GridStateChange,
} from 'angular-slickgrid';
import { Subject, combineLatest } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { FilePropertyComponent } from 'src/app/files/components/file-property/file-property.component';
import { SIZE_UNITS } from 'src/app/files/components/workspace-quota/workspace-quota.component';
import { FileFacade } from 'src/app/files/facade/file.facade';
import { FolderFacade } from 'src/app/files/facade/folder.facade';
import { ImisFile } from 'src/app/files/models/imis-file';
import { SortColumn } from 'src/app/files/models/sort-column';
import { SizeUnits } from 'src/app/files/models/workspace';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { ArchivedFolderMoveComponent } from '../archived-folder-move/archived-folder-move.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface FolderFilesState {
  files?: PaginatedList<ImisFile>;
  sortColumns: SortColumn[];
  pagination: {
    pageSize: number;
    pageNumber: number;
  };
  selectedFilesIds: string[];
  selectedFile: ImisFile | undefined;
  selectedFiles: ImisFile[];
  audioBlob: Blob | null;
}

const initFolderFilesState: FolderFilesState = {
  sortColumns: [SortColumn.NAME_DECENDING],
  pagination: { pageSize: 10, pageNumber: 1 },
  selectedFilesIds: [],
  selectedFile: undefined,
  selectedFiles: [],
  audioBlob: null,
};

@Component({
  selector: 'app-archived-workspace-files',
  templateUrl: './archived-workspace-files.component.html',
  styleUrls: ['./archived-workspace-files.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ArchivedWorkspaceFilesComponent}, RxState],
})
export class ArchivedWorkspaceFilesComponent implements OnInit, MatPaginatorIntl {
  angularGrid!: AngularGridInstance;
  columnDefinitions: Column[] = [];
  gridOptions!: GridOption;
  files$ = this.state.select('files');
  selectedFilesIds$ = this.state.select('selectedFilesIds');
  selectedFile$ = this.state.select('selectedFile');
  pagination$ = this.state.select('pagination');
  sortColumns$ = this.state.select('sortColumns');
  audioBlob$ = this.state.select('audioBlob');

  files: ImisFile[] = [];
  selectedFilesIds: string[] = [];
  selectedFile: ImisFile | undefined;

  audio: boolean = true;
  isPlaying = false;
  audioVolume = 0.5;
  audioProgress = 0;

  pageSizes = [5, 10, 25, 100];

  @ViewChild('videoElement', { static: true })
  videoElementRef!: ElementRef<HTMLVideoElement>;

  @ViewChild('audioElement', { static: true })
  audioElementRef!: ElementRef<HTMLAudioElement>;

  firstPageLabel = $localize`:@@offices.archived-workspace-files.first-page: First page`;
  itemsPerPageLabel = $localize`:@@offices.archived-workspace-files.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@offices.archived-workspace-files.last-page: Last page`;

  nextPageLabel = $localize`:@@offices.archived-workspace-files.next-page:  Next page`;
  previousPageLabel = $localize`:@@offices.archived-workspace-files.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@offices.archived-workspace-files.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@offices.archived-workspace-files.page-part-one: Page` + `${page + 1}` + $localize`:@@offices.archived-workspace-files.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<FolderFilesState>,
    private route: ActivatedRoute,
    private fileFacade: FileFacade,

    private folderFacade: FolderFacade,
    private cd: ChangeDetectorRef,
    private matDialog: MatDialog,
    private officeFacade: OfficeFacade,
  ) {
    this.state.set(initFolderFilesState);
    this.state.connect('files', this.fileFacade.archivedFiles$);
    this.state.connect('selectedFilesIds', this.fileFacade.selectedFilesIds$);
    this.state.connect('selectedFile', this.fileFacade.selectedFile$);
    this.prepareGrid();
    this.state.connect('audioBlob', this.fileFacade.audioBlob$);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.files$.subscribe((files) => {
      if (files) {
        this.cd.detectChanges();

        this.files = files?.items.map((fs) => {
          return {
            ...fs,
            sizeString: `${fs.size.toFixed(2)} ${
              SIZE_UNITS[SizeUnits[fs.sizeUnit]]
            }`,
            lastAccessed: fs.lastAccessed ? this.transformNullableDate(fs.lastAccessed) : '',
            lastModified: fs.lastModified ? this.transformNullableDate(fs.lastModified) : '',
          };
        });
      }
    });

    this.audioBlob$.subscribe((blob) => {
      if (blob) {
        const audioElement: HTMLAudioElement =
          this.audioElementRef.nativeElement;
        this.audio = false;
        audioElement.src = URL.createObjectURL(blob);
        audioElement.controls = true;

        audioElement.addEventListener('ended', () => {
          this.removeAudioElement();
        });

        audioElement.play();
      }
    });

    combineLatest([
      this.pagination$,
      this.route.params,
      this.sortColumns$,
    ]).subscribe(([pagination, params, sortCols]) => {
      this.fileFacade.dispatchGetArchivedFiles(
        params['folderId'],
        sortCols,
        pagination.pageNumber,
        pagination.pageSize,
      );
    });

    this.selectedFile$.subscribe((selectedFile) => {
      this.selectedFile = selectedFile;
    });
  }

  prepareGrid() {
    this.columnDefinitions = [
      { id: 'name', name: 'Name', field: 'name', sortable: true },
      { id: 'size', name: 'Size', field: 'sizeString' },
      {
        id: 'lastAccessed',
        name: 'Last Accessed',
        field: 'lastAccessed',
        sortable: true,
      },
      {
        id: 'lastModified',
        name: 'Last Modified',
        field: 'lastModified',
        sortable: true,
      },
      { id: 'downloadCount', name: 'Download Count', field: 'downloadCount' },
    ];

    this.gridOptions = {
      gridWidth: '100%',
      autoHeight: true,
      enableAutoResize: true,
      enableSorting: true,
      enableFiltering: false,
      autoFitColumnsOnFirstLoad: false,
      enableContextMenu: true,
      enableCellMenu: false,
      enableGridMenu: false,
      enableHeaderMenu: false,
      enableRowSelection: true,
      multiSelect: true,
      enableCheckboxSelector: true,
      rowSelectionOptions: {
        selectActiveRow: false,
      },
      fullWidthRows: false,
      multiColumnSort: true,
      contextMenu: {
        hideCopyCellValueCommand: true,
        hideExportExcelCommand: true,
        width: 200,
        commandItems: [
          {
            command: 'Open',
            iconCssClass: 'fa fa-book',
            title: 'Open',
            action: (e, args) => {
              let fileId = args.dataContext.id;
              let fileName = args.dataContext.name;
              let fileData = fileName.split('.');
              if (
                fileData[1] == 'mp3' ||
                fileData[1] == 'ogg' ||
                fileData[1] == 'wav' ||
                fileData[1] == 'mp4' ||
                fileData[1] == 'avi' ||
                fileData[1] == 'm4a'
              ) {
                this.fileFacade.dispatchFetchAudio(fileId);
              } else {
                this.fileFacade.dispatchPreviewFile(fileId, fileName);
              }
            },
          },
          {
            command: 'Download',
            iconCssClass: 'fa fa-download',
            title: 'Download',
            action: (e, args) => {
              let fileId = args.dataContext.id;
              let fileName = args.dataContext.name;
              this.fileFacade.dispatchDownloadFile(fileId, fileName);
            },
          },
          {
            command: 'Move',
            iconCssClass: 'fa fa-arrow-right',
            title: 'Move',
            action: (e, args) => {
              this.fileFacade.dispatchSelectFilesIds([args.dataContext.id]);
              this.fileFacade.dispatchSetFileSharingMode(true);
              this.matDialog.open(ArchivedFolderMoveComponent, {
                disableClose: true,
                autoFocus: false,
              });
            },
          },
          {
            command: 'Properties',
            iconCssClass: 'fa fa-info',
            title: 'Properties',
            action: (e, args) =>
              this.matDialog.open(FilePropertyComponent, {
                data: {
                  selectedFileId: args.dataContext.id,
                },
              }),
          },
        ],
      },
    };
  }

  updatePaginationState(event: PageEvent) {
    this.state.set({
      pagination: { pageSize: event.pageSize, pageNumber: event.pageIndex + 1 },
    });
  }

  angularGridReady(event: Event) {
    const angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.angularGrid = angularGrid;
  }
  gridStateChanged(gridStateChange: Event) {
    const selectedRowState = (gridStateChange as CustomEvent)
      .detail as GridStateChange;

    if (selectedRowState.gridState?.rowSelection) {
      this.selectedFilesIds = (selectedRowState!.gridState!.rowSelection
        .filteredDataContextIds || []) as string[];
    }
    this.cd.detectChanges();
    this.fileFacade.dispatchSelectFilesIds(this.selectedFilesIds!);
    const selectedFiles = this.files.filter((file) =>
      this.selectedFilesIds.includes(file.id),
    );
    this.fileFacade.dispatchSelectFiles(selectedFiles);
  }

  private transformNullableDate(date?: string): string {
    return !date ? 'N/A' : new Date(date).toLocaleDateString();
  }

  selectFile(file: ImisFile) {
    this.fileFacade.dispatchSelectFile(file);
  }

  removeVideoElement() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  removeAudioElement() {
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.pause();
    audioElement.removeEventListener('ended', this.removeAudioElement);
    const audioContainer = audioElement.parentElement;
    if (audioContainer) {
      audioContainer.removeChild(audioElement);
    }
  }

  closePlayer() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  moveFiles() {
    this.fileFacade.dispatchSetFileSharingMode(true);
    this.matDialog.open(ArchivedFolderMoveComponent, {
      disableClose: true,
      autoFocus: false,
    });
  }

  hasGetFilesInFolderPermission(): string {
    return GetFullPermissionName(
      MODULES.FILES,
      PERMISSION_NAMES.Files.File.Feature,
      PERMISSION_NAMES.Files.File.GetFilesInFolder,
    );
  }
}
