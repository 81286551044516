import { DownloadProcess } from './../../../store/process-instance.actions';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  ProcessInstance,
  ProcessInstanceStatus,
} from 'src/app/researches/models/process-instance.model';
import { ProcessInstanceFormComponent } from '../process-instance-form/process-instance-form.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import {
  ARCHIVED_ROUTE,
  PROCESS_INSTANCE_LIST_ROUTE,
  PUBLICATION_ROUTE,
} from 'src/app/core/constants/routes';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { ChoosePublicationOptionFormComponent } from '../../publication-options/choose-publication-option-form/choose-publication-option-form.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { DeleteProcessInstanceFormComponent } from '../delete-process-instance-form/delete-process-instance-form.component';

interface ProcessInstanceCardComponentState {
  selectedProcessInstance: ProcessInstance | undefined;
  update: boolean;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initProcessInstanceCardComponentState: ProcessInstanceCardComponentState =
  {
    selectedProcessInstance: undefined,
    update: false,
    currentLoggedInUser: undefined,
  };

@Component({
  selector: 'app-process-instance-card',
  templateUrl: './process-instance-card.component.html',
  styleUrls: ['./process-instance-card.component.scss'],
})
export class ProcessInstanceCardComponent implements OnInit {
  @Input() processInstance: ProcessInstance | undefined;
  @Input() isArchived?: boolean = false;
  selectedProcessInstance: ProcessInstance | undefined = undefined;
  selectedProcessInstance$ = this.state.select('selectedProcessInstance');
  
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  constructor(
    private processInstanceFacade: ProcessInstanceFacade,
    private state: RxState<ProcessInstanceCardComponentState>,
    private dialog: MatDialog,
    private router: Router,
    private userFacade: UserFacade,
  ) {
    this.state.set(initProcessInstanceCardComponentState);
    this.state.connect(
      'selectedProcessInstance',
      this.processInstanceFacade.selectedProcessInstance$,
    );
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  ngOnInit(): void {
    this.selectedProcessInstance$.subscribe((selectedProcessInstance) => {
      this.selectedProcessInstance = selectedProcessInstance;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  openDeletionForm(event: MouseEvent, processInstance: ProcessInstance | undefined) {
    event.stopPropagation();
      if(processInstance?.id){
      this.processInstanceFacade.dispatchGetProcessInstanceDetail(processInstance!.id);
      this.dialog.open(
        DeleteProcessInstanceFormComponent,
        PROCESS_FORM_SIDE_DIALOG_CONFIG,
      );}
  }
    openRestoreConfirmationDialogue(event: MouseEvent, processInstance: ProcessInstance | undefined) {
      event.stopPropagation();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          regularTextOne: $localize`:@@researches.process-instance-card.restore-process-confimation-part-1: Are you sure you want to restore`,
          boldText:` "${
            processInstance!.title
          }" `,
          regularTextTwo:$localize`:@@researches.process-instance-card.restore-process-confimation-part-2:process?`,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.openDeliberateRestoreConfirmationDialog(processInstance);
        }
      });
    }

  openDeliberateRestoreConfirmationDialog(processInstance: ProcessInstance | undefined) {
      const deliberateDialogRef = this.dialog.open(
        ConfirmDeliberateDialogComponent,
        {
          data: {
            name: processInstance!.title,
            message: $localize`:@@researches.process-instance-card.restore-message: Restore` + 
            ` "${processInstance!.title}" `,
            regularTextOne: $localize`:@@researches.process-instance-card.restore-description-part-1: Are you sure you want to restore`,
            boldText:` "${processInstance!.title}" `,
            regularTextTwo: $localize`:@@researches.process-instance-card.restore-description-part-2:process?`,
            extraTextOne: $localize`:@@researches.process-instance-card.restore-extra-part-1: To confirm, type`, 
            extraTextBold:` "${processInstance!.title}" `,
            extraTextTwo:$localize`:@@researches.process-instance-card.restore-extra-part-2:below:`,
            confirmText: "restore",
          },
        },
      );
      deliberateDialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.processInstanceFacade.dispatchRestoreArchivedProcessInstance(
            processInstance?.id ?? ''
          );
        }
      });
    }
  openDeliberateArchivalConfirmationDialog(
    processInstance: ProcessInstance | undefined,
  ) {
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: processInstance!.title,
          message: $localize`:@@researches.process-instance-card.archive-message: Archive` + ` "${processInstance!.title}" `,
          regularTextOne: $localize`:@@researches.process-instance-card.archive-description-part-1: Are you sure you want to archive`, 
          BoldText: ` "${
            processInstance!.title
          }" `,
          RegularTextTwo:$localize`:@@researches.process-instance-card.archive-description-part-2:process?`,
          extraTextOne: $localize`:@@researches.process-instance-card.archive-extra-part-1: To confirm, type`,
          extraTextBold:` "${processInstance!.title}" `,
          extraTextTwo:$localize`:@@researches.process-instance-card.archive-extra-part-2:below:`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.processInstanceFacade.dispatchArchiveProcessInstance(
          processInstance?.id ?? '',
        )
      }
    });
  }

  get isCompletedProcess(): boolean {
    return (
      !!this.processInstance &&
      this.processInstance.processStatus === ProcessInstanceStatus.Completed
    );
  }

  openArchiveConfirmationDialog(
    event: MouseEvent,
    processInstance: ProcessInstance | undefined,
  ) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.process-instance-card.archive-description-part-1: Are you sure you want to archive` ,
         boldText:` "${
          processInstance!.title
        }" `,
        regularTextTwo: $localize`:@@researches.process-instance-card.archive-description-part-2:process?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.openDeliberateArchivalConfirmationDialog(processInstance);
      }
    });
  }



  checkProcessInstanceStatus(status: string) {
    if (status == ProcessInstanceStatus.ReadyToStart) {
      return $localize`:@@researches.process-instance-card.status-ready-to-start:Ready To Start`;
    } else if (status == ProcessInstanceStatus.Inprogress) {
      return $localize`:@@researches.process-instance-card.status-in-progress:In Progress`;
    } else if (status == ProcessInstanceStatus.Terminated) {
      return $localize`:@@researches.process-instance-card.status-terminated:Terminated`;
    } else if (status == ProcessInstanceStatus.Rejected) {
      return $localize`:@@researches.process-instance-card.status-rejected:Rejected`;
    } else if (status == ProcessInstanceStatus.WaitingForApproval) {
      return $localize`:@@researches.process-instance-card.status-waiting-for-approval:Waiting For Approval`;
    } else if (status == ProcessInstanceStatus.SubmittedToEvaluation) {
      return $localize`:@@researches.process-instance-card.status-submitted-to-evaluation:Submitted To Evaluation`;
    } else if (status == ProcessInstanceStatus.Completed) {
      return $localize`:@@researches.process-instance-card.status-completed:Completed`;
    } else {
      return status;
    }
  }

  getProcessInstanceStatusColor(status: string) {
    if (status == ProcessInstanceStatus.ReadyToStart) {
      return 'gray';
    } else if (status == ProcessInstanceStatus.Inprogress) {
      return 'yellow';
    } else if (
      status == ProcessInstanceStatus.Rejected ||
      status == ProcessInstanceStatus.Terminated
    ) {
      return 'red';
    } else if (
      status == ProcessInstanceStatus.SubmittedToEvaluation ||
      status == ProcessInstanceStatus.WaitingForApproval
    ) {
      return 'primary';
    } else if (status == ProcessInstanceStatus.Completed) {
      return 'green';
    } else {
      return 'gray';
    }
  }

  updateProcessInstance(
    event: MouseEvent,
    processInstance: ProcessInstance | undefined,
  ) {
    event.stopPropagation();
    this.processInstanceFacade.dispatchSetUpdateStatus(true);
    this.processInstanceFacade.dispatchSetSelectedProcessInstance(
      processInstance!,
    );
    this.dialog.open(
      ProcessInstanceFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  navigateToProcessInstanceDetail(
    processInstance: ProcessInstance | undefined,
  ) {
    this.processInstanceFacade.dispatchSetSelectedProcessInstance(
      processInstance!,
    );
    !this.isArchived
      ? this.router.navigate([PROCESS_INSTANCE_LIST_ROUTE, processInstance?.id])
      : this.router.navigate([ARCHIVED_ROUTE, processInstance?.id]);
  }

  startPublication() {
    if (this.processInstance) {
      this.dialog
        .open(ChoosePublicationOptionFormComponent, {
          data: {
            processInstanceId: this.processInstance.id,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm') {
            this.router.navigate([PUBLICATION_ROUTE]);
          }
        });
    }
  }
  downloadProcess(processInstance?: ProcessInstance|undefined){
    if(!processInstance) return
    if(!processInstance.id)return
    this.processInstanceFacade.dispatchDownloadProcess(processInstance.id);
  }
  cancelDeletion(){
    if(this.processInstance?.id){
      this.processInstanceFacade.dispatchCancelProcessInstanceDeletion(this.processInstance.id)
    }
  }

  hasArchiveProcessInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.ArchiveProcessInstance,
    );
  }
  hasUpdateProcessInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.UpdateProcessInstance,
    );
  }
  hasDeleteProcessInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.DeleteProcessInstance,
    );
  }

  hasCreatePublicationPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.CreatePublication,
    );
  }

  hasDownloadProcessInstancePermission(){
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.DownloadProcessInstance,
    );
  }

  hasCancelProcessInstanceDeletionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.CancelProcessInstanceDeletion,
    );
  }
}
