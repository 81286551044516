<div class="mat-elevation-z1">
  <h1 i18n="@@documents.initiated-workflows-report.initiated-workflows" class="mat-h1">Initiated Workflows</h1>
  <form class="action-btn" [formGroup]="filterForm">
    <!-- office filter -->
    <button mat-flat-button color="primary" (click)="openSingleOffice()"
    i18n="@@documents.initiated-workflows-report.select-office">
      Select office
    </button>
    <!-- user filter -->
    <mat-form-field appearance="outline" class="search-name-input">
      <mat-label i18n="@@documents.initiated-workflows-report.search-by-initiator">Search by Initiator</mat-label>
      <input
        matInput
        formControlName="searchName"
        placeholder="Search"
        (input)="applyFilter()"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.initiated-workflows-report.workflow-status">Workflow Status</mat-label>
      <mat-select formControlName="status">
        <mat-option *ngFor="let status of workflowStatuses" [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- filter by workflow type -->
    <mat-form-field appearance="outline" class="workflow-type-selector">
      <mat-label i18n="@@documents.initiated-workflows-report.workflow-type">Workflow Type</mat-label>
      <mat-select
        formControlName="workflowType"
        (selectionChange)="applyFilter()"
      >
        <mat-option [value]="null" i18n="@@documents.initiated-workflows-report.all">All</mat-option>
        <mat-option *ngFor="let type of workflowTypes" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Workflow Initiation Date Range Picker -->
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.initiated-workflows-report.initiation-data-range">Initiation Date Range</mat-label>
      <mat-date-range-input [rangePicker]="initiationPicker">
        <input
          matStartDate
          placeholder="Start date"
          formControlName="initiationStartDate"
          (dateChange)="applyFilter()"
        />
        <input
          matEndDate
          placeholder="End date"
          formControlName="initiationEndDate"
          (dateChange)="applyFilter()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="initiationPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #initiationPicker></mat-date-range-picker>
    </mat-form-field>

    <!-- Workflow Completion Date Range Picker -->
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.initiated-workflows-report.completion-date-range">Completion Date Range</mat-label>
      <mat-date-range-input [rangePicker]="completionPicker">
        <input
          matStartDate
          placeholder="Start date"
          formControlName="completionStartDate"
          (dateChange)="applyFilter()"
        />
        <input
          matEndDate
          placeholder="End date"
          formControlName="completionEndDate"
          (dateChange)="applyFilter()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="completionPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #completionPicker></mat-date-range-picker>
    </mat-form-field>

    <button mat-stroked-button color="warn" (click)="resetFilter()"
    i18n="@@documents.initiated-workflows-report.reset-filter">
      Reset Filter
    </button>
  </form>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="WorkflowTypeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.initiated-workflows-report.workflow-type"> Workflow Type </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.workflowTypeName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="OfficeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.initiated-workflows-report.office"> Office </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.officeName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="WorkflowStatus">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.initiated-workflows-report.status"> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.workflowStatus }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="InitiatedAt">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.initiated-workflows-report.initiated-at"> Initiated At </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.initiatedAt | date }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CompletedAt">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.initiated-workflows-report.completed-at"> Completed At </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.completedAt ? (element.completedAt | date) : "-" }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedWorkflows($event)"
  />
</div>
