<div class="flex justify-between" *appHasPermission="hasGetArchivedProcessInstancesPermission()">
    <h1 class="mat-h1" i18n="@@researches.archived-process-instance.archived-processes">Archived Processes</h1>
    <mat-paginator
      [length]="archivedProcessInstances?.totalCount"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedProcessInstances($event)"
    >
    </mat-paginator>
</div>

<div *appHasPermission="hasGetArchivedProcessInstancesPermission()">
  <ng-container
  *ngIf="
    archivedProcessInstances?.items?.length ?? 0 > 0;
    else emptyProcessInstanceList
  "
>
  <app-process-instance-card
    *ngFor="let archivedProcessInstance of archivedProcessInstances?.items"
    [processInstance]="archivedProcessInstance"
    [isArchived]="true"
  ></app-process-instance-card>
</ng-container>
<ng-template #emptyProcessInstanceList>
  <div class="empty-process-instance-list">
    <span class="text-2xl" i18n="@@researches.archived-process-instance.no-archived-process-found">No archived process found</span>
  </div>
</ng-template>

</div>
