<div class="header">
  <h2 class="mat-h2" i18n="@@researches.tor-list.tor">TOR</h2>
</div>
<div class="row" *appHasPermission="hasGetTorsPermission()">
  <mat-checkbox
    (change)="onGroupedChange($event.checked)"
    [checked]="groupChecked"
    i18n="@@researches.tor-list.group-by-process-instance"
  >
    Group by Process Instance
  </mat-checkbox>
</div>
<mat-paginator
  [length]="paginatedTor?.totalCount"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 20, 100]"
  [showFirstLastButtons]="true"
  (page)="loadPaginatedTor($event)"
>
</mat-paginator>
<ng-container *appHasPermission="hasGetTorsPermission()">



<div *ngIf="!groupChecked">
  <ng-container *ngIf="paginatedTor && paginatedTor.items && paginatedTor.items.length > 0"
  >
    <mat-card
      class="card"
      appearance="outlined"
      *ngFor="let t of paginatedTor?.items"
      (click)="onRowClick($event, t)"
    >
      <mat-card-header>
        <mat-card-title>{{ t.title }}</mat-card-title>
        <mat-chip mat-stroked-button [ngClass]="getStatusColor(t.status)">
          {{ t?.statusForDisplay?.status }}
        </mat-chip>
      </mat-card-header>
      <mat-card-content class="card-content">
        <div>
          <div class="approvers">
            <div>
              <span i18n="@@researches.tor-list.budget-requester"
                >Budget requester :</span
              >
              <button class="approverButton" mat-mini-fab color="primary">
                <mat-icon class="approverIcon"
                  >format_color_text</mat-icon
                ></button
              >{{t.initiatorName }}
            </div>
            <div>
              <span class="approver" i18n="@@researches.tor-list.first-approver"
                >First Approver :</span
              >
              <button class="approverButton" mat-mini-fab color="primary">
                <mat-icon class="approverIcon"
                  >format_color_text</mat-icon
                ></button
              >{{ t.firstApproverUserName }}
            </div>

            <div>
              <span class="approver" i18n="@@researches.tor-list.last-approver"
                >Last Approver :</span
              >
              <button class="approverButton" mat-mini-fab color="primary">
                <mat-icon class="approverIcon"
                  >format_color_text</mat-icon
                ></button
              >{{ t.lastApproverUserName }}</div
            >
          </div>
        </div>
        <mat-divider vertical class="approverDivider"></mat-divider>
        <div>
          <mat-card-header i18n="@@researches.tor-list.total-cost"
            >Total Cost</mat-card-header
          >
          <mat-card-title>{{
            t.totalCost.toString()
          }} <span> {{ t.currency }} </span></mat-card-title>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>

</div>

<div *ngIf="groupChecked">
  <mat-accordion *ngFor="let process of processes" class="m-vert">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span i18n="@@researches.tor-list.process-title">Process title:</span>
          {{ process }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-accordion
        *ngFor="let stage of processToStage.get(process)"
        class="m-vert"
      >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span i18n="@@researches.tor-list.stage-title">Stage title:</span>
              {{ stage.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card class="card" *ngFor="let t of stageToTor.get(stage.id)" (click)="onRowClick($event, t)">
            <mat-card-header>
              <mat-card-title>{{ t.title }}</mat-card-title>
              <mat-chip mat-stroked-button [ngClass]="getStatusColor(t.status)">
                {{ t?.statusForDisplay?.status }}
              </mat-chip>
            </mat-card-header>
            <mat-card-content class="card-content">
              <div>
                <div class="approvers">
                  <div>
              <span i18n="@@researches.tor-list.budget-requester"
                >Budget requester :</span
              >
              <button class="approverButton" mat-mini-fab color="primary">
                <mat-icon class="approverIcon"
                  >format_color_text</mat-icon
                ></button
              >{{ t.initiatorName }}
            </div>
                  <div>
                    <span class="approver" i18n="@@researches.tor-list.first-approver"
                      >First Approver :</span
                    >
                    <button class="approverButton" mat-mini-fab color="primary">
                      <mat-icon class="approverIcon"
                        >format_color_text</mat-icon
                      ></button
                    >{{ t.firstApproverUserName }}
                  </div>

                  <div>
                    <span class="approver" i18n="@@researches.tor-list.last-approver"
                      >Last Approver :</span
                    >
                    <button class="approverButton" mat-mini-fab color="primary">
                      <mat-icon class="approverIcon"
                        >format_color_text</mat-icon
                      ></button
                    >{{ t.lastApproverUserName }}</div
                  >
                </div>
              </div>
              <mat-divider vertical class="approverDivider"></mat-divider>
              <div>
                <mat-card-header i18n="@@researches.tor-list.total-cost"
                  >Total Cost</mat-card-header
                >
                <mat-card-title>{{
                  t.totalCost.toString()
                }} <span> {{ t.currency }} </span></mat-card-title>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
</div>
</ng-container>

<div *ngIf="paginatedTor?.items?.length == 0">
  <div class="empty-process-instance-list">
    <span class="text-2xl" i18n="@@researches.tor-list.no-tor-found"
      >No TOR found</span
    >
  </div>
</div>
