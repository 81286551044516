import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Process, ProcessCategoryForDisplay } from '../models/process.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { ProcessService } from '../services/process.service';
import {
  CreateProcess,
  DeleteProcess,
  GetPaginatedProcesses,
  GetProcessCategories,
  ResetSelectedProcess,
  SearchProcess,
  SetSelectedProcess,
  SetUpdateStatus,
  UpdateProcess,
  SetProcessListPageIndex,
  SetProcessListPageSize,
  SetProcessListSearchTerm,
} from './process.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { filter, take, tap } from 'rxjs';
import { GetProcessDetail } from './process-detail.actions';

export interface ProcessStateModel {
  processes: Process[];
  update: boolean;
  paginatedProcesses: PaginatedList<Process>;
  selectedProcess: Process | undefined;
  totalCount: number;
  processCategories: ProcessCategoryForDisplay[]
  processListPageIndex: number;
  processListPageSize: number;
  processListSearchTerm: string;
}

const PROCESS_STATE_TOKEN = new StateToken<ProcessStateModel>('processState');

const defaults: ProcessStateModel = {
  processes: [],
  update: false,
  paginatedProcesses: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedProcess: undefined,
  totalCount: 0,
  processCategories: [],
  processListPageIndex: 1,
  processListPageSize: 10,
  processListSearchTerm: '',
};

@State<ProcessStateModel>({
  name: PROCESS_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ProcessState {
  constructor(
    public processService: ProcessService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(UpdateProcess)
  updateProcess(
    { patchState, getState }: StateContext<ProcessStateModel>,
    { process }: UpdateProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    this.processService
      .updateProcess(process)
      .pipe(
        filter((process) => process != null),
        take(1),
      )
      .subscribe((updatedProcess) => {
        let oldProcesses = getState().processes;
        let filteredProcesses = [
          updatedProcess,
          ...oldProcesses.filter((p) => p.id != updatedProcess.id),
        ];
        let newPaginatedProcesses = getState().paginatedProcesses;
        newPaginatedProcesses.items = filteredProcesses;
        patchState({
          update: false,
          paginatedProcesses: newPaginatedProcesses,
          processes: filteredProcesses,
          selectedProcess: updatedProcess,
        });
        this.operationStatus.displayStatus(
          $localize`:@@researches.process.process-updated-successfully: Process updated successfully`,
          successStyle,
        );
        this.store.dispatch(new GetProcessDetail(updatedProcess.id!));
        this.store.dispatch(new SetProgressOff());
      });
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<ProcessStateModel>,
    { updateStatus }: SetUpdateStatus,
  ) {
    patchState({ update: updateStatus });
  }

  @Action(CreateProcess)
  createProcess(
    { setState, getState }: StateContext<ProcessStateModel>,
    { process }: CreateProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().totalCount;
    return this.processService.createProcess(process).pipe(
      tap((createdProcess: Process) => {
        setState(
          patch({
            processes: insertItem(createdProcess),
            totalCount: prevLength + 1,
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@researches.process.process-type-created-successfully: Process type created successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetPaginatedProcesses)
  getPaginatedProcesses(
    { patchState }: StateContext<ProcessStateModel>,
    { pageNumber, pageSize }: GetPaginatedProcesses,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processService.getPaginatedProcesses(pageNumber, pageSize).pipe(
      tap((paginatedProcesses) => {
        patchState({
          processes: paginatedProcesses.items,
          paginatedProcesses: paginatedProcesses,
          totalCount: paginatedProcesses.totalCount,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedProcess)
  setSelectedProcess(
    { patchState }: StateContext<ProcessStateModel>,
    { process }: SetSelectedProcess,
  ) {
    patchState({
      selectedProcess: process,
    });
  }

  @Action(ResetSelectedProcess)
  resetSelectedProcess({ patchState }: StateContext<ProcessStateModel>) {
    patchState({
      selectedProcess: undefined,
    });
  }
  @Action(DeleteProcess)
  deleteProcess(
    { setState, getState }: StateContext<ProcessStateModel>,
    { processId }: DeleteProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processService.deleteProcess(processId).pipe(
      tap(() => {
        setState(
          patch({
            processes: removeItem((p) => p.id === processId),
            totalCount: getState().totalCount - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.process.process-type-deleted-successfully: Process type deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new ResetSelectedProcess());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SearchProcess)
  searchProcess(
    { setState }: StateContext<ProcessStateModel>,
    { searchTerm, pageNumber, pageSize }: SearchProcess,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.processService
      .searchProcess(searchTerm, pageNumber, pageSize)
      .pipe(
        tap((processes) => {
          setState(
            patch({
              processes: processes.items,
              paginatedProcesses: processes,
              totalCount: processes.totalCount,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetProcessCategories)
  getProcessCategories(
    { setState }: StateContext<ProcessStateModel>,
    { }: GetProcessCategories,
  ) {
    return this.processService
      .getProcessCategories()
      .pipe(
        tap((processCategories) => {
          setState(
            patch({
              processCategories: processCategories
            }),
          );
        }),
      );
  }

  @Action(SetProcessListPageIndex)
  setProcessListPageIndex(
    { patchState }: StateContext<ProcessStateModel>,
    { pageIndex }: SetProcessListPageIndex,
  ) {
    patchState({
      processListPageIndex: pageIndex,
    });
  }

  @Action(SetProcessListPageSize)
  setProcessListPageSize(
    { patchState }: StateContext<ProcessStateModel>,
    { pageSize }: SetProcessListPageSize,
  ) {
    patchState({
      processListPageSize: pageSize,
    });
  }

  @Action(SetProcessListSearchTerm)
  setProcessListSearchTerm(
    { patchState }: StateContext<ProcessStateModel>,
    { searchTerm }: SetProcessListSearchTerm,
  ) {
    patchState({
      processListSearchTerm: searchTerm,
    });
  }
}
