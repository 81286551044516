<div class="grid-container">
  <div
    class="column-container"
    *ngFor="let task of gridHeaders"
    cdkDropList
    id="{{ task }}"
    #{{task}}="cdkDropList"
    [cdkDropListData]="filterTasks(task)"
    [cdkDropListConnectedTo]="gridHeaders"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListEnterPredicate]="canDrop(task)"
    [cdkDropListDisabled]="disabled(task) || processInstanceDetail?.isArchived"
  >
    <p class="tile-title bold-5">
      {{ task === "Rejected" ? "Needs Revision" : task }}
      <small class="mat-caption">
        ({{ filteredTasks.length }})
        <span i18n="@@researches.task-board.tasks">Tasks</span></small
      >
    </p>
    <div class="list-container">
      <mat-list class="card-list">
        <mat-card
          class="card"
          *ngFor="let item of filteredTasks"
          cdkDrag
          [cdkDragData]="item"
          [attr.id]="item.id"
        >
          <mat-card-header>
            <mat-card-title [attr.data-test-id]="'researches.view-task-board.task-board.' + item.name + 'mat-card-title'"> {{ item.name }} aaa</mat-card-title>

            <mat-icon
              *appHasPermission="hasCrudPermission()"
              [matMenuTriggerFor]="menu"
              class="mat-icon"
              (click)="setSelectedTask($event, item)"
              >more_vert</mat-icon
            >
          </mat-card-header>
          <mat-menu #menu="matMenu">
            <button
              class="button-height"
              mat-menu-item
              (click)="viewDetail(item)"
            >
              <span class="menu-text" i18n="@@researches.task-board.view-detail"
                >View Detail</span
              >
            </button>
            <button
              class="button-height"
              mat-menu-item
              (click)="viewDocument()"
              *appHasPermission="hasGetFileTasksByTaskPermission()"
            >
              <span
                class="menu-text"
                i18n="@@researches.task-board.view-documents"
                >View Documents</span
              >
            </button>
            <ng-container *appHasPermission="hasUpdateTaskPermission()">
              <button
                class="button-height"
                mat-menu-item
                (click)="updateTask()"
                *ngIf="canEditAndDelete() && !processInstanceDetail?.isArchived"
              >
                <span class="menu-text" i18n="@@researches.task-board.edit"
                  >Edit</span
                >
              </button>
            </ng-container>
            <ng-container *appHasPermission="hasDeleteTaskPermission()">
              <button
                class="button-height"
                mat-menu-item
                (click)="openConfirmationDialog()"
                *ngIf="canEditAndDelete() && !processInstanceDetail?.isArchived"
              >
                <span class="menu-text" i18n="@@researches.task-board.delete"
                  >Delete</span
                >
              </button>
            </ng-container>
          </mat-menu>
          <mat-card-content> {{ item.description }} </mat-card-content>
          <mat-card-footer>
            <mat-divider class="divider"> </mat-divider>
            <div class="date-container">
              <p
                class="date-style bold-5"
                i18n="@@researches.task-board.start-date"
              >
                Start Date:
              </p>
              <p class="date-style">{{ item.startDate | date }}</p>
            </div>
            <div class="date-container">
              <p
                class="date-style bold-5"
                i18n="@@researches.task-board.end-date"
              >
                End Date:
              </p>
              <p class="date-style">{{ item.endDate | date }}</p>
            </div>
          </mat-card-footer>
          <mat-card-actions align="end">
            <div matCardAvatar class="avatar">
              <span>{{
                item.assignee && item.assignee.firstName[0].toUpperCase()
              }}</span>
            </div>
          </mat-card-actions>
        </mat-card>
      </mat-list>
    </div>
  </div>
</div>
