import { Component, ViewChild } from '@angular/core';
import { ResearchEvaluation } from '../../models/research-evaluation.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { RxState } from '@rx-angular/state';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { Subject } from 'rxjs';

interface ResearchEvaluationState {
  researchEvaluations: PaginatedList<ResearchEvaluation> | undefined;
}

const initResearchEvaluationState: ResearchEvaluationState = {
  researchEvaluations: undefined,
};

@Component({
  selector: 'app-research-evaluations',
  templateUrl: './research-evaluations.component.html',
  styleUrls: ['./research-evaluations.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ResearchEvaluationsComponent}],
})
export class ResearchEvaluationsComponent{
  researchEvaluations$ = this.state.select('researchEvaluations');
  researchEvaluations: PaginatedList<ResearchEvaluation> | undefined;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'researchName',
    'officeName',
    'stageName',
    'result',
  ];

  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;

  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.research-evaluations.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.research-evaluations.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.research-evaluations.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.research-evaluations.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.research-evaluations.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.research-evaluations.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.research-evaluations.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.research-evaluations.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<ResearchEvaluationState>,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initResearchEvaluationState),
      this.state.connect(
        'researchEvaluations',
        this.researchDashboardFacade.researchEvaluations$,
      );
  }
  changes = new RxState();
  ngOnInit() {
    this.researchDashboardFacade.dispatchGetResearchEvaluations();
    this.researchEvaluations$.subscribe((researchEvaluations) => {
      this.dataSource.data = researchEvaluations?.items || [];
      this.dataSource.sort = this.sort;
      this.length = researchEvaluations?.items.length ?? 0;
    });
  }

  loadPaginatedCommunications(event: PageEvent) {
    this.researchDashboardFacade.dispatchgetResearchCommunications(
      undefined,
      undefined,
      undefined,
      undefined,
      event.pageIndex + 1,
      event.pageSize,
    );
  }
}
