<div>
  <div class="flex-row space-between">
    <h2
      i18n="@@users.assign-revoke-permissions.assign-revoke-permissions"
      class="mat-h1"
    >
      Assign/Revoke Permissions
    </h2>
    <button
      mat-flat-button
      color="primary"
      (click)="submit()"
      class=""
      i18n="@@users.assign-revoke-permissions.save"
      [attr.data-test-id]="'users.assign-revoke-permissions.save-changes-btn'"
    >
      Save
    </button>
  </div>

  <div class="flex-row space-between">
    <div class="flex-row">
      <h4
        class="padding-right"
        i18n="@@users.assign-revoke-permissions.role-name"
        class="mat-subtitle-1"
      >
        Role name:
      </h4>
      <h3 class="mat-subtitle-1">{{ selectedRole?.name }}</h3>
    </div>
    <div class="flex-row filter-container">
      <mat-form-field class="filter-item" appearance="outline">
        <mat-label i18n="@@users.assign-revoke-permissions.select-module"
          >Select Module</mat-label
        >
        <mat-select
          [(value)]="selectedModuleId"
          (valueChange)="filterByModule($event)"
        >
          <mat-option *ngFor="let m of modulesList" [value]="m.id">
            {{ m.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-item" appearance="outline">
        <mat-label i18n="@@users.assign-revoke-permissions.select-feature"
          >Select Feature</mat-label
        >
        <mat-select
          [(value)]="selectedFeatureId"
          (valueChange)="filterByFeature($event)"
        >
          <mat-option *ngFor="let f of featuresList" [value]="f.id">
            {{ f.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-icon-button
        matTooltip="Clear Filters"
        (click)="clearFilters()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <mat-expansion-panel
    *ngFor="let m of modules"
    [expanded]="true"
    class="mat-expansion-panel"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-checkbox
          [(ngModel)]="m.checked"
          (click)="$event.stopPropagation()"
          (change)="setModuleLevel($event.checked, m.id)"
        >
          <div class="module-name">
            {{ m.name }}
            <span i18n="@@users.assign-revoke-permissions.module">
              Module
            </span>
          </div>
        </mat-checkbox>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let f of m.features">
      <mat-checkbox
        [(ngModel)]="f.checked"
        (change)="setFeatureLevel($event.checked, f.id)"
      >
        <div class="feature-name">
          {{ f.name }}
        </div>
      </mat-checkbox>
      <ul class="permission-list">
        <li class="permission-list-item" *ngFor="let p of f.permissions">
          <mat-checkbox
            [checked]="isChecked(p.id)"
            (change)="updatePermissionState($event.checked, p.id)"
            class="permission-checkbox"
            [matBadge]="getDependencyCount(p.id).dependencies"
            matBadgeSize="small"
            [matBadgeHidden]="getDependencyCount(p.id).dependencies == 0"
            [matTooltip]="getDependencyCount(p.id).tooltipString"
            [disabled]="hasReason(p.id)"
          >
            {{ p.permissionName }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </mat-expansion-panel>
</div>
