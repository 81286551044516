import { Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { UploadExcelDialogComponent } from '../upload-excel-dialog/upload-excel-dialog.component';

@Component({
  selector: 'upload-excel',
  templateUrl: './upload-excel.component.html',
  styleUrls: ['./upload-excel.component.scss'],
  providers: [RxState],
})
export class UploadExcelComponent {
  rules = [
    $localize`:@@offices.upload-excel.office-rule:The parent of an office should be recorded in the previous entries of the excel or the office will not be saved.`,
    $localize`:@@offices.upload-excel.office-rule-one:The Parent Office Code and Reports To Offices Code of the first office in the excel file should be empty.`,
  ];
  constructor(
    public dialog: MatDialog,
  ) {}
  hasExportTemplateExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.ExportTemplateExcel,
    );
  }
  hasUploadExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UploadExcelFile,
    );
  }
  openUploadExcelDialog() {
    this.dialog.open(UploadExcelDialogComponent);
  }
}
