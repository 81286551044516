<mat-card class="margin-top" id="fullLengthResearchComponent">
  <mat-card-title class="margin-top-title" i18n="@@researches.full-length-researches.researches">Researches </mat-card-title>
  <mat-card-content>
    <div class="">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by name"
            i18n="@@researches.full-length-researches.name"
          >
            Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="ownerOfficeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by ownerOfficeName"
            i18n="@@researches.full-length-researches.office"
          >
            office
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.ownerOfficeName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="budget">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by budget"
            i18n="@@researches.full-length-researches.budget"
          >
            Budget
          </th>
          <td mat-cell *matCellDef="let element">
            {{element?.budget?.toString() | currency: 'ETB':'code':'2.2-2': locale}}
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by startDate"
            i18n="@@researches.full-length-researches.start-date"
          >
            startDate
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by endDate"
            i18n="@@researches.full-length-researches.end-date"
          >
            EndDate
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Sort by category"
            i18n="@@researches.full-length-researches.category"
          >
            Publication Category
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngFor="let item of element.publicationCategories">
              {{ item }}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="paginator">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons]="true"
          (page)="loadPaginatedProcessInstances($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
