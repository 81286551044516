import { PhoneNumber } from 'src/app/core/models/PhoneNumber.model';

export interface Office {
  id?: string | null;
  name: string;
  shortName: string;
  pathLabel: string;
  phoneNumber: PhoneNumber;
  reportsToOfficeIds: string[];
  description: string;
  pathFromRoot: string;
  parentPathFromRoot: string;
  parentOfficeName: string;
  hierarchyLevel: number;
  parentId: string;
  reportsToIds: string[];
}

export interface UpdateOfficeDto {
  id?: string;
  name: string;
  shortName: string;
  phoneNumber: PhoneNumber;
  description: string;
  reportsToOfficeIds: string[];
}

export enum PathUpdateType {
  OFFICEONLY = 0,
  WITHCHILDREN = 1,
  WITHDESCENDANTS = 2,
}

export interface CreateOfficeDto {
  parentPathFromRoot: string;
  name: string;
  shortName: string;
  description: string;
  phoneNumber: PhoneNumber;
  reportsToOfficeIds: string[];
  parentId: string;
}

export enum SizeUnit {
  KiloByte,
  MegaByte,
  GigaByte,
  TeraByte,
}

export interface ViewOfficeDto {
  id?: string;
  pathFromRoot: string;
  pathLabel: string;
  name: string;
  shortName: string;
  phoneNumber: PhoneNumber;
  description: string;
}

export interface IUpdateOfficeStructure {
  id: string;
  pathFromRoot: string;
  parentPathFromRoot: string;
  updateType: PathUpdateType;
}
