<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
<mat-tab [label]="currencySetting">
<div class="container">
  <mat-card class="currency-card" appearance="outlined"
    ><mat-card-header>
      <h3 i18n="@@researches.researches-settings.currency-excel-upload">Currency excel upload</h3>
      <button
        mat-flat-button
        color="primary"
        (click)="DownloadTemplate()"
        *appHasPermission="hasDownloadCurrencyExcelTemplatePermission()"
        class="download-temp"
      >
        <mat-icon>download</mat-icon>
        <p i18n="@@researches.researches-settings.download-template" [attr.data-test-id]="'researches-researches-settings-button-download-template'">
          Download Template
        </p>
      </button>
    </mat-card-header>

    <mat-card-content *appHasPermission="hasUploadCurrencyExcelPermission()">
      <div>
        <button
          type="button"
          mat-button
          color="accent"
          (click)="chooseFiles.click()"
          class="custom-btn-space"
        >
          <mat-icon>file_upload</mat-icon>
          <p
            class="nowrap-text"
            i18n="@@researches.researches-settings.choose-file"
            [attr.data-test-id]="'researches-researches-settings-button-choose-file'"
          >
            Choose File
          </p>
        </button>

        <mat-chip
          class="truncated-chip"
          (removed)="removeSelectedFile()"
          *ngIf="selectedFile"
        >
          <fa-icon
            matChipAvatar
            [icon]="getIconForFile()"
            class="file-icon"
          ></fa-icon>
          <span>
            {{
              extractFileNameAndExtension().name.length > 25
                ? extractFileNameAndExtension().name.slice(0, 25) + "..."
                : extractFileNameAndExtension().name
            }}.{{ extractFileNameAndExtension().extension }}
          </span>
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </div>
      <button
        [disabled]="!selectedFile"
        mat-flat-button
        color="primary"
        (click)="uploadExcel()"
        i18n="@@researches.researches-settings.upload-currency-excel"
        [attr.data-test-id]="'upload-currency-excel'"
      >
        Upload Currency Excel
      </button>
      <input
        accept=".xlsx,.csv"
        type="file"
        hidden="true"
        #chooseFiles
        class="input"
        (change)="selectFile($event)"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </mat-card-content>
  </mat-card>
</div>
<div>
<h1 class="currencies-title" i18n="@@researches.researches-settings.available-currencies">Available currencies</h1>
<div class="currency-header">
  <h3> <span i18n="@@researches.researches-settings.default-currency"> Default Currency: </span> {{defaultCurrency}}</h3>
  <button (click)="toggleSetDefaultForm()" mat-stroked-button color="primary" *appHasPermission="hasUploadSetDefaultCurrencyPermission()" i18n="@@researches.researches-settings.set-default-currency">Set Default Currency</button>
</div>
<mat-card class="set-default-form" *ngIf="showDefaultForm">
  <mat-card-title class="form-header" i18n="@@researches.researches-settings.set-default-currency">Set Default currency</mat-card-title>
  <mat-form-field>
    <mat-label i18n="@@researches.researches-settings.select-default-currency">Select Default Currency</mat-label>
    <mat-select [(ngModel)]="selectedDefaultCurrency">
      <mat-option *ngFor="let currency of currencies" [value]="currency.id">
        {{ currency.currencyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-card-actions class="action-btns">
  <button class="action-btn" mat-raised-button color="warn" (click)="toggleSetDefaultForm()"
  i18n="@@researches.researches-settings.cancel">
    Cancel
  </button>
  <button [disabled]="selectedDefaultCurrency == null" class="action-btn" mat-raised-button color="primary" (click)="setDefaultCurrency()"
  i18n="@@researches.researches-settings.submit" [attr.data-test-id]="'researches-researchessettings-submit-button'">
    Submit
  </button>
</mat-card-actions>
</mat-card>
<mat-grid-list cols="4" rowHeight="100px" gutterSize="16px">
  <mat-grid-tile *ngFor="let currency of currencies">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{ currency.currencyName }}</mat-card-title>
        <mat-card-subtitle> <span i18n="@@researches.researches-settings.exchange-rate"> Exchange Rate: </span> {{ currency.value }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
</div>
</mat-tab>
<mat-tab [label]="torSetting">
<app-tor-settings></app-tor-settings>
</mat-tab>
</mat-tab-group>
