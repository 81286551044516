export const LOGIN_ROUTE = 'login';

export const USERS_ROUTE = 'users';
export const USERS_LIST = `users-list`;

export const ROLES_ROUTE = 'roles';
export const ROLES_LIST = `roles-list`;
export const ASSIGN_REVOKE_PERMISSIONS = `assign-revoke-permissions`;
export const ASSIGN_REVOKE_PERMISSIONS_USER = `assign-revoke-permissions-user`;
export const ASSIGN_REVOKE_ROLES_USER = `assign-revoke-roles-user`;

export const HOME_ROUTE = 'home';
export const UNAUTHORIZED_ROUTE = 'unauthorized';
export const PROFILE_ROUTE = 'profile';
export const LOGIN_HISTORY_ROUTE = 'login-history';
export const CHANGE_PASSWORD_ROUTE = 'change-password';
export const FORGET_PASSWORD_ROUTE = 'forget-password';
export const RESET_PASSWORD_ROUTE = 'reset-password';
export const MANAGE_PASSWORDS_ROUTE = 'manage-passwords';

export const RESEARCH_ROUTE = 'research';
export const RESEARCH_COUNCIL_ROUTE = 'research-council';

export const OFFICES_ROUTE = 'offices';
export const CREATE_OFFICES_ROUTE = 'create-office';
export const OFFICES_LIST_ROUTE = 'offices-list';
export const OFFICE_ORGANOGRAM_ROUTE = 'office-organogram';
export const OFFICES_TREE_ROUTE = 'offices-tree';
export const OFFICES_MULTIPLE_SELECT_ROUTE = 'offices-multiple-select-tree';
export const ARCHIVED_WORKSPACES_ROUTE = 'archived-workspaces';
export const UPDATE_OFFICE_STRUCTURE_ROUTE = 'update-structure';

export const FILES_ROUTE = 'files';

export const TRASH_ROUTE = 'trash';
export const BOOKMARKS_ROUTE = 'bookmarks';

export const RESEARCHES_ROUTE = 'researches';
export const PROCESS_LIST_ROUTE = 'process-list';
export const TASK_LIST_ROUTE = 'task-list';
export const ASSIGNED_TASKS_ROUTE = 'assigned-tasks';
export const PROCESS_INSTANCE_LIST_ROUTE = 'process-instance-list';
export const MEETINGS_ROUTE = 'meetings';
export const ARCHIVED_ROUTE = 'archived';
export const RESEARCHES_SETTINGS_ROUTE = 'researches-settings';

export const RESEARCH_COUNCIL_DETAIL_ROUTE = 'research-council-detail';
export const SCHEDULED_EVALUATIONS_ROUTE = 'scheduled-evaluations';
export const EVALUATION_DETAIL_ROUTE = 'evaluation-detail';

export const TASK_ROUTE = 'tasks';
export const STAGE_INSTANCES_ROUTE = 'stage-instances';
export const TASK_DOCUMENTS_ROUTE = 'task-document-route';

export const DOCUMENTS_ROUTE = 'documents';
export const WORKFLOW_TYPES_ROUTE = `workflow-types`;
export const DOCUMENT_TEMPLATES_ROUTE = `document-templates`;
export const TEMPLATE_FIELDS_ROUTE = `template-fields`;
export const FIELD_VALIDATION_RULES_ROUTE = `field-validation-rules`;
export const WORKFLOW_TYPE_STEPS_ROUTE = `workflow-types/workflow-steps`;
export const APPROVE_DOCUMENT_TEMPLATES_ROUTE = `approve-document-templates`;
export const WORKFLOWS_ROUTE = `workflows`;
export const MY_WORKFLOWS_ROUTE = `my-workflows`;
export const Owned_WORKFLOWS_ROUTE = `owned-workflows`;
export const ARCHIVED_WORKFLOWS_ROUTE = `archived-workflows`;

export const EXTERNAL_DOCUMENT_ROUTE = `external-document`;
export const EXTERNAL_DOCUMENT_RESPONSE_ROUTE = `${EXTERNAL_DOCUMENT_ROUTE}/response`;

export const WORKFLOW_STEPS_ROUTE = `workflow-steps`;
export const DOCUMENT_ROUTE = `document`;

export const DELEGATIONS_ROUTE = `delegations`;
export const DELEGATION_LIST_ROUTE = `delegations-list`;
export const ASSIGN_DELEGATION = `assign-delegation`;
export const ON_BEHALF_DELEGATION_LIST_ROUTE = `onbehalf-delegations-list`;
export const ONBEHALF_ASSIGN_DELEGATION = `onbehalf-assign-delegation`;
export const ALL_DELEGATIONS_LIST = `${DELEGATIONS_ROUTE}/all`;

export const DOCUMENT_TEMPLATE_DETAIL_ROUTE = `template-detail`;
export const DOCUMENT_TEMPLATE_LAYOUT_ROUTE = 'document-template-layout';
export const WORKFLOW_STEP_DETAIL_ROUTE = `workflow-step-detail`;

export const WORKFLOW_TYPE_VALIDATIONS = `validations`;
export const DOCUMENT_SETTINGS_ROUTE = `documents/settings`;
export const NOTICES_ROUTE = `notices`;
export const NOTICES_FORM_ROUTE = `notices-form`;
export const REPORTED_COMMENT_ROUTE = `reported-comments`;

export const TOR_SETTINGS_ROUTE = `tor-settings`;
export const TOR_ROUTE = 'tors';
export const TOR_INITIATE_ROUTE = 'tor-initiate';
export const TOR_DETAIL_ROUTE = 'tor-detail';

export const MEMOS_ROUTE = `memos`;
export const MY_MEMOS_ROUTE = '/my-memos';
export const ASSIGNED_REVIEWS_ROUTE = `assigned-reviews`;
export const NOTICES_UPDATE_ROUTE = `notices-update-form`;
export const MEMO_FORM_ROUTE = 'memo-form';
export const MEMO_DETAIL_ROUTE = 'memo-detail';
export const ASSIGN_REVIEWERS = 'assign-reviewers';

export const PUBLICATION_ROUTE = 'publication';

export const DASHBOARD_ROUTE = 'dashboard';

export const USERS_DASHBOARD_ROUTE = 'users-dashboard';
export const USERS_REPORTS_ROUTE = 'users-reports';

export const OFFICES_DASHBOARD_ROUTE = 'offices-dashboard';
export const OFFICES_REPORTS_ROUTE = 'offices-reports';

export const FILES_DASHBOARD_ROUTE = 'files-dashboard';
export const FILES_REPORTS_ROUTE = 'files-reports';

export const RESEARCHES_DASHBOARD_ROUTE = 'researches-dashboard';
export const RESEARCHES_REPORTS_ROUTE = 'researches-reports';

export const DOCUMENTS_DASHBOARD_ROUTE = 'documents-dashboard';
export const DOCUMENTS_REPORTS_ROUTE = 'documents-reports';
export const DOCUMENT_REPORTED_COMMENTS_REPORT_ROUTE = `${DOCUMENTS_REPORTS_ROUTE}/documents-reported-comments`;
export const DOCUMENT_WORKFLOWSTEP_AVG_TIME = `${DOCUMENTS_REPORTS_ROUTE}/workflow-step-avg-time`;
export const INITIATED_WORKFLOWS = `${DOCUMENTS_REPORTS_ROUTE}/initiated-workflows`;
export const MY_WORKFLOWS = `${DOCUMENTS_REPORTS_ROUTE}/my-workflows`;
export const INVOLVED_WORKFLOWS = `${DOCUMENTS_REPORTS_ROUTE}/involved-workflows`;
export const PUBLICATION_DETAIL = 'publicatoin-detail';

export const FILES_DASHBOARD_ROUTE_URL = '/files/files-dashboard';
export const FILES_ROUTE_URL = '/files';

export const CONTACT_US_ROUTE = 'app-contact-us';
export const REPORTED_ISSUES_ROUTE = 'reported-issues';
export const REPORTED_ISSUES_DETAIL_ROUTE = 'reported-issue-detail';
