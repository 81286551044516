<upload-excel *appHasPermission="hasUploadExcelPermission()" class="upload-excel"></upload-excel>
<button
  class="add-btn"
  mat-flat-button
  color="primary"
  (click)="createOffice()"
  *appHasPermission="hasAddOfficePermission()"
  i18n="@@offices.office-list.add-office"
  [attr.data-test-id]="'offices.create-office.office-list.add-office-button'">
  Add Office
</button>
<mat-paginator
  [length]="length"
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 20, 100]"
  [showFirstLastButtons]="true"
  (page)="loadPaginatedOffices($event)"
  *appHasPermission="hasGetOfficesPermission()"
>
</mat-paginator>
<angular-slickgrid
  *appHasPermission="hasGetOfficesPermission()"
  gridId="offices-list-grid"
  [columnDefinitions]="columnDefinitions"
  (onAngularGridCreated)="angularGridReady($event)"
  [gridOptions]="gridOptions"
  [dataset]="offices"
>
</angular-slickgrid>
