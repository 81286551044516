<mat-dialog-content mat-dialog-content class="form-container">
  <form class="process-instance-form" [formGroup]="processInstanceForm">
    <h3>{{ formTitle }}</h3>

    <mat-form-field appearance="fill" *ngIf="!update; else null">
      <mat-label i18n="@@researches.process-instance-form.choose-process-type">Choose Process Type</mat-label>
      <mat-select
        formControlName="processId"
        (valueChange)="checkZeroStagesProcess($event)"
        [attr.data-test-id]="
          'researches.create-process-instance.process-instance-form.process-instance-category-selector'
        "
      >
        <mat-option
          *ngFor="let process of processes"
          [value]="process.id"
          [attr.data-test-id]="
            'researches.create-process-instance.process-instance-form.process-instance-category-option-input'
          "
        >
          {{ process.name }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="processInstanceForm.get('processId')!.hasError('required')"
        i18n="@@researches.process-instance-form.please-select-a-process-type" 
      >
        Please select a process type
      </mat-error>
      <mat-error class="bottom-margin" *ngIf="this.zeroStageProcessChosen"
      i18n="@@researches.process-instance-form.please-create-stage-for-the-chosen-process-type"
        >Please create stages for the chosen process type</mat-error
      >
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.process-instance-form.title">Title</mat-label>
      <input
        id="name"
        formControlName="title"
        type="text"
        matInput
        class="full-width-input"
        [attr.data-test-id]="
          'researches.create-process-instance.process-instance-form.process-instance-title-input'
        "
      />
      <mat-error *ngIf="processInstanceForm.get('title')!.hasError('required')"
      i18n="@@researches.process-instance-form.please-enter-a-title">
        Please enter a title
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@researches.process-instance-form.description">Description</mat-label>
      <textarea
        id="description"
        formControlName="description"
        matInput
        [placeholder]="placeholderToggleLabel.processDescription"
        class="full-width"
        [attr.data-test-id]="
          'researches.create-process-instance.process-instance-form.process-instance-description-input'
        "
      ></textarea>

      <mat-error *ngIf="processInstanceForm.get('description')!.hasError('required')"
      i18n="@@researches.process-instance-form.please-enter-a-description">
        Please enter a description
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline" *ngIf="!update">
      <mat-label i18n="@@researches.process-instance-form.team-members">Team Members</mat-label>
      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #teamMemberChipGrid aria-label="Team Members">
        <mat-chip-row
          *ngFor="let teamMember of processInstanceForm.value.teamMembers"
          (removed)="removeTeamMember(teamMember)"
        >
          {{ teamMember?.firstName }}
          {{ teamMember?.middleName }}
          {{ teamMember?.lastName }}
          <button
            matChipRemove
            [attr.aria-label]="
              'remove ' + teamMember?.firstName + ' ' + teamMember?.middleName + ' ' + teamMember?.lastName
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #teamMembersInput
        [placeholder]="placeholderToggleLabel.teamMembers"
        formControlName="teamMembersSearchQuery"
        [matChipInputFor]="teamMemberChipGrid"
        [matAutocomplete]="auto"
        [attr.data-test-id]="
        'researches.create-process-instance.process-instance-form.process-instance-team-member-input'
      "
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectTeamMember($event)"
      >
        <mat-option
          *ngFor="let teamMember of filteredTeamMembers"
          [value]="teamMember"
          [attr.data-test-id]="
            'researches.create-process-instance.process-instance-form.process-instance-team-member-option'
          "
        >
          {{ teamMember?.firstName }} 
          {{ teamMember?.middleName}}  
          {{ teamMember?.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.process-instance-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="
        (!processInstanceForm.valid && !update) || zeroStageProcessChosen
      "
    >
      {{ saveButtonText }}
    </button>
  </div>
</mat-dialog-content>
