import { Selector, createPropertySelectors } from '@ngxs/store';
import { FolderState, FolderStateModel } from './folder.state';

export class FolderSelector {
  static slices = createPropertySelectors<FolderStateModel>(FolderState);

  @Selector([FolderState])
  static flatFolderNodes(state: FolderStateModel) {
    return state.flatFolderNodes;
  }

  @Selector([FolderState])
  static selectFlatFolderNode(state: FolderStateModel) {
    return state.selectedFlatFolderNode;
  }

  @Selector([FolderState])
  static selectedFlatOfficeNodes(stateModel: FolderStateModel) {
    return stateModel.selectedFlatFolderNodes;
  }

  @Selector([FolderState])
  static archivedWorkspaceFolders(stateModel: FolderStateModel) {
    return stateModel.archivedWorkspaceFolders;
  }

  @Selector([FolderState])
  static selectedArchivedFlatFolderNode(state: FolderStateModel) {
    return state.selectedArchivedFlatFolderNode;
  }

  @Selector([FolderState])
  static workspaceFlatFolderNodes(state: FolderStateModel) {
    return state.workspaceFlatFolderNodes;
  }

  @Selector([FolderState])
  static selectedDestinationFlatFolderNode(state: FolderStateModel) {
    return state.selectedDestinationFlatFolderNode;
  }

  @Selector([FolderState])
  static searchResults(state: FolderStateModel) {
    return state.searchResults;
  }

  @Selector([FolderState])
  static trash(stateModel: FolderStateModel) {
    return stateModel.trash;
  }
}
