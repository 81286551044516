import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  DocumentsAnalyticsState,
  DocumentsAnalyticsStateModel,
} from './documents-analytics.state';

export class DocumentsAnalyticsSelector {
  static slices = createPropertySelectors<DocumentsAnalyticsStateModel>(
    DocumentsAnalyticsState,
  );

  @Selector([DocumentsAnalyticsState])
  static workflowTypesCount(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.workflowTypesCount;
  }

  @Selector([DocumentsAnalyticsState])
  static workflowsCount(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.workflowsCount;
  }

  @Selector([DocumentsAnalyticsState])
  static receivedExternalDocumentsCount(
    stateModel: DocumentsAnalyticsStateModel,
  ) {
    return stateModel.receivedExternalDocumentsCount;
  }

  @Selector([DocumentsAnalyticsState])
  static workflowsVsTime(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.workflowsVsTime;
  }

  @Selector([DocumentsAnalyticsState])
  static receivedExternalDocuments(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.receivedExternalDocuments;
  }

  @Selector([DocumentsAnalyticsState])
  static sentExternalDocuments(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.sentExternalDocuments;
  }

  @Selector([DocumentsAnalyticsState])
  static reportedCommentsPercentage(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.reportedCommentsPercentage;
  }

  @Selector([DocumentsAnalyticsState])
  static myWorkflowsCount(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.myWorkflowsCount;
  }

  @Selector([DocumentsAnalyticsState])
  static involvedWorkflowsCount(stateModel: DocumentsAnalyticsStateModel) {
    return stateModel.involvedWorkflowsCount;
  }
}
