import { Component, EventEmitter, DestroyRef, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Workflow, WorkflowStatus, WorkflowFilter } from '../../models/workflow.model';
import { RxState } from '@rx-angular/state';

import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { SelectionChange } from '@angular/cdk/collections';
import { MatSelectChange } from '@angular/material/select';
import { WorkflowFacade } from '../../facade/workflow.facade';
import { debounceTime } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


interface WorkflowSearchComponentState {
  workflows: PaginatedList<Workflow> | undefined;
  search: boolean;
  workflowFilters: WorkflowFilter[];
}

const initWorkflowSearchComponentState: WorkflowSearchComponentState = {
  workflows: undefined,
  search: false,
  workflowFilters: [],
};

interface FilterParameters {
  filters: string;
  onlyAssigned: boolean;
}

@Component({
  selector: 'app-workflow-search',
  templateUrl: './workflow-search.component.html',
  styleUrls: ['./workflow-search.component.scss'],
})
export class WorkflowSearchComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  searchForm: FormGroup;
  filters: string = '';
  workflows$ = this.state.select('workflows');
  workflows: PaginatedList<Workflow> | undefined;

  @Output() filterParamsEventEmitter = new EventEmitter<FilterParameters>();

  search$ = this.state.select('search');
  search: boolean = false;

  totalWorkflowStatus = Object.keys(WorkflowStatus).length;
  workflowFilters: WorkflowFilter[] = []
  workflowFilters$ = this.state.select('workflowFilters');

  onlyAssigned: boolean = false;

  placeholderToggleLabel = {
    searchByName: $localize`:@@documents.workflow-search.search-by-name: search by name`,
}

  constructor(
    private fb: FormBuilder,
    private workflowFacade: WorkflowFacade,
    private state: RxState<WorkflowSearchComponentState>,
  ) {
    this.state.set(initWorkflowSearchComponentState);
    this.state.connect('workflows', this.workflowFacade.workflows$);
    this.state.connect('search', this.workflowFacade.search$);
    this.state.connect('workflowFilters', this.workflowFacade.workflowFilters$);
    this.searchForm = this.fb.group({
      workflowName: [''],
      onlyAssigned: [false],
      filter: [''],
    });
  }
  ngOnInit(): void {
    this.workflowFacade.dispatchGetWorkflowFilter();
    this.workflows$.subscribe((workflows) => {
      if (workflows) {
        this.workflows = workflows;
      }
    });
    this.search$.subscribe((search) => {
      this.search = search;
    });
    if (this.filters.length === 0 && this.onlyAssigned === false)
      this.workflowFacade.dispatchSetSearch(false);

     this.workflowFilters$.subscribe((filters) => {
      this.workflowFilters = filters.filter((filter) => filter.workflowStatusForDisplay == WorkflowStatus.Inprogress || filter.workflowStatusForDisplay == WorkflowStatus.Rejected);
    });

  }

  getWorkflowStatus(id: string) {
    return this.workflowFilters.find((filter) => filter.id === id)?.translations.find((translation) => translation.cultureName === 'en-US')?.workflowStatusForDisplay;
  }

  onFilterSelect(event: MatSelectChange) {
    if (event.value === undefined) {
      this.workflowFacade.dispatchSetSearch(false);
      return;
    }
    if (
      Array.from(event?.value.toString()).length === 0 &&
      !this.onlyAssigned
    ) {
      this.workflowFacade.dispatchSetSearch(false);
    } else {
      this.workflowFacade.dispatchSetSearch(true);
      this.filters = this.getWorkflowStatus(event.value)??'';
      this.filter();
    }
  }
  onlyAssignedCheckbox(checkboxEvent: MatCheckboxChange) {
    this.onlyAssigned = checkboxEvent.checked;
    if (this.onlyAssigned) {
      this.workflowFacade.dispatchSetSearch(true);
      this.filter();
    }
    if (this.filters === '' && this.onlyAssigned === false)
      this.workflowFacade.dispatchSetSearch(false);
  }
  filter() {
    this.workflowFacade.dispatchFilterWorkflows({
      filterParams: this.filters,
      onlyAssigned: this.onlyAssigned,
      pageNumber: 1,
      pageSize: 10,
    });
    this.filterParamsEventEmitter.emit({
      filters: this.filters,
      onlyAssigned: this.onlyAssigned,
    });
  }

  resetFilter() {
    this.searchForm.get('filter')?.setValue('');
    this.searchForm.get('onlyAssigned')?.setValue(false);
  }

  onSearchTermChange(){
    this.searchForm.get('workflowName')
        ?.valueChanges.pipe(
          debounceTime(500),
          takeUntilDestroyed(this.destroyRef),
          ).subscribe((value) => {
      if(value === ''){
        this.workflowFacade.dispatchSetWorkflowSearchingMode(false);
        this.workflowFacade.dispatchGetWorkflows(1, 10);
      } else {
        this.workflowFacade.dispatchSetWorkflowSearchingMode(true);
        this.workflowFacade.dispatchSetWorkflowSearchTerm(value);
        this.workflowFacade.dispatchSearchWorkflows(value);
        this.resetFilter();
      }
    }
    );
  }
}
