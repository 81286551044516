import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export class DateValidator {
  static isValidDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isValid()
        ? null
        : { isValidDate: `Field must be a valid date.` };
    };
  }

  static isDate(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isSame(val)
        ? null
        : { isDate: `Field must be equal to ${val}.` };
    };
  }

  static isOnOrBefore(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isSameOrBefore(val)
        ? null
        : { isOnOrBefore: `Field must be on or before ${val}.` };
    };
  }

  static isBefore(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isBefore(val)
        ? null
        : { isBefore: `Field must be before ${val}.` };
    };
  }

  static isAfter(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isAfter(val)
        ? null
        : { isAfter: `Field must be after ${val}.` };
    };
  }

  static isOnOrAfter(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isSameOrAfter(val)
        ? null
        : { isOnOrAfter: `Field must be a on or after ${val}.` };
    };
  }

  static isBetween(start: string, end: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return moment(value).isBetween(start, end)
        ? null
        : { isBetween: `Field must be between ${start} and ${end}.` };
    };
  }

  static isNotBetween(start: string, end: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return !moment(value).isBetween(start, end)
        ? null
        : { isNotBetween: `Field must not be between ${start} and ${end}.` };
    };
  }

  static hasStartAndEndDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('startDate')?.value;
      const endDate = control.get('endDate')?.value;
      
      if (new Date(startDate) > new Date(endDate)) {
        return { hasStartAndEndDate: 'Start date must be before end date.' };
      }
      return null; 
    };
  }
}
