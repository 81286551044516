<h3 mat-dialog-title>{{ formTitle }}</h3>
<mat-dialog-content mat-dialog-content class="form-container">
  <form class="process-instance-form" [formGroup]="workflowTypeStepForm">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.workflow-type-step-form.name">Name</mat-label>
      <input
        id="name"
        formControlName="name"
        type="text"
        matInput
        class="full-width-input"
        data-test-id="documents.workflow-type-step.name-input"
      />
      <mat-error *ngIf="workflowTypeStepForm.get('name')!.hasError('required')" i18n="@@documents.workflow-type-step-form.please-enter-a-name">
        Please enter a name
      </mat-error>
      <mat-error *ngIf="workflowTypeStepForm.get('name')?.hasError('duplicatedStepName')">
        Workflow step name is already taken.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.workflow-type-step-form.description">Description</mat-label>
      <textarea
        id="description"
        formControlName="description"
        matInput
        [placeholder]="placeholderFieldLabel.processDescriptionField"
        class="full-width"
        data-test-id="documents.workflow-type-step.description-input"
      ></textarea>

      <mat-error
        *ngIf="workflowTypeStepForm.get('description')!.hasError('required')"
       i18n="@@documents.workflow-type-step-form.please-enter-a-description">
        Please enter a description
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@documents.workflow-type-step-form.owner-role">Owner Role</mat-label>
      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #ownerChipGrid aria-label="Owner">
        <mat-chip-row
          (removed)="removeOwner()"
          *ngIf="workflowTypeStepForm.value.ownerRole"
        >
          {{ workflowTypeStepForm.value.ownerRole.name }}
          <button
            matChipRemove
            [attr.aria-label]="
              'remove ' + workflowTypeStepForm.value.ownerRole.name
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #approverInput
        [placeholder]="placeholderFieldLabel.ownerRoleField"
        formControlName="ownerSearchQuery"
        [matChipInputFor]="ownerChipGrid"
        [matAutocomplete]="auto"
        data-test-id="documents.workflow-type-step.role-input"
      />
      <mat-autocomplete
        requireSelection
        #auto="matAutocomplete"
        (optionSelected)="selectOwner($event)"
      >
        <mat-option *ngFor="let owner of filteredOwner" [value]="owner">
          {{ owner.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="chip-alignment">
<!--  TODO: localization for select office -->
      <button mat-raised-button (click)="openOfficeSelector()">
        select office
      </button>
      <mat-chip-row
        class="chip"
        *ngIf="selectedFlatOfficeNode"
        (removed)="removeSelectedFlatOfficeNode(selectedFlatOfficeNode)"
        [editable]="false"
      >
        {{ selectedFlatOfficeNode.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    </div>
    <mat-checkbox
      style="margin-bottom: 1em"
      formControlName="includeTextComposer"
       i18n="@@documents.workflow-type-step-form.include-text-composer">Include text composer</mat-checkbox
    >
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.workflow-type-step-form.add-form">Add Form</mat-label>
      <mat-select formControlName="documentTemplateId" data-test-id="documents.workflow-type-step.forms-input">
        <mat-option
          *ngFor="let documentTemplate of documentTemplates"
          [value]="documentTemplate.id"
        >
          {{ documentTemplate.name }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          workflowTypeStepForm.get('documentTemplateId')!.hasError('required')
        "
       i18n="@@documents.workflow-type-step-form.please-select-a-template">
        Please select a template
      </mat-error>
    </mat-form-field>
    <button
      mat-stroked-button
      color="primary"
      [disabled]="workflowTypeStepForm.get('documentTemplateId')!.invalid"
      (click)="onAddFieldClicked()"
    >
      <mat-icon>add</mat-icon> <span i18n="@@documents.workflow-type-step-form.add-field"> Add Field </span>
    </button>
    <div *ngIf="selectedWorkflowTypeStepDocumentTemplateFields!.length > 0">
      <h4 i18n="@@documents.workflow-type-step-form.selected-template-fields">Selected Template Fields</h4>
      <mat-card
        class="fields-card"
        *ngFor="
          let selectedWorkflowTypeStepDocumentTemplateField of selectedWorkflowTypeStepDocumentTemplateFields
        "
      >
        <mat-card-content class="card-content">
          <mat-list>
            <div>
              <div class="list-item">
                <div class="content">
                  <p>
                    {{ selectedWorkflowTypeStepDocumentTemplateField.name }}
                  </p>
                  <p>
                    <span i18n="@@documents.workflow-type-step-form.data-type"> data type : </span>
                    {{ selectedWorkflowTypeStepDocumentTemplateField.dataType }}
                  </p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </form>


  <div mat-dialog-actions class="action-btn" align="end">
    <button mat-button mat-dialog-close i18n="@@documents.workflow-type-step-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!workflowTypeStepForm.valid"
    >
      {{ saveButtonText }}
    </button>
  </div>
</mat-dialog-content>
