import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { TorConfigurationFacade } from '../../facade/tor-setttings.facade';
import { MatDialog } from '@angular/material/dialog';
import { QuantityType } from '../../models/quantityType.model';
import { RxState } from '@rx-angular/state';

interface ConfigureQuantityTypeState {
  quantityUpdate: QuantityType | undefined;
}

const initConfigureActivityTypeState: ConfigureQuantityTypeState = {
  quantityUpdate: undefined,
};

@Component({
  selector: 'app-configure-quantity-type',
  templateUrl: './configure-quantity-type.component.html',
  styleUrls: ['./configure-quantity-type.component.scss'],
})
export class ConfigureQuantityTypeComponent {
  quantityForm: FormGroup;
  saveButtonText: string = $localize`:@@researches.configure-quantity-type.create: Create`;
  formTitle = $localize`:@@researches.configure-quantity-type.create-quantity-type: Create Quantity Type`;
  quantityUpdate$ = this.configureQuantityTypeState.select('quantityUpdate');
  quantityUpdate: undefined | QuantityType = undefined;

  constructor(
    private configureQuantityTypeState: RxState<ConfigureQuantityTypeState>,
    private formBuilder: FormBuilder,
    public sanitizerService: SanitizerService,
    private torConfigurationFacade: TorConfigurationFacade,
    private dialog: MatDialog,
  ) {
    this.configureQuantityTypeState.connect;
    this.configureQuantityTypeState.set(initConfigureActivityTypeState);
    this.configureQuantityTypeState.connect(
      'quantityUpdate',
      this.torConfigurationFacade.quantityUpdate$,
    );
    this.quantityForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.quantityUpdate$.subscribe((result) => {
      if (result != undefined) {
        this.formTitle = $localize`:@@researches.configure-quantity-type.update-quantity-type: Update Quantity Type`;
        this.saveButtonText = $localize`:@@researches.configure-quantity-type.update: Update `;
        this.quantityUpdate = result;
        const control = this.quantityForm.get('name');
        if (control) {
          control.patchValue(result.name);
        }
      }
    });
  }

  save() {
    if (!this.quantityForm.valid) return;
    const quantityData = this.quantityForm.value;
    if (this.quantityUpdate == undefined) {
      this.torConfigurationFacade.dispatchCreateQuantityType(quantityData);
      this.dialog.closeAll();
    } else {
      if (this.quantityUpdate.name != quantityData.name) {
        const data = {...this.quantityUpdate , name: quantityData.name}
        this.torConfigurationFacade.dispatchUpdateQuantityType(data);
      }
    }
    this.torConfigurationFacade.dispatchRemoveQuantityUpdate();
    this.dialog.closeAll();
  }
}
