@if (options && options.length > 0) {
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-select [formControl]="control" [placeholder]="placeholder || ''">
      @for (option of options; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
      }
    </mat-select>
    <mat-error>
      {{ error }}
    </mat-error>
  </mat-form-field>
} @else {
  <mat-form-field
    appearance="outline"
    style="width: 100%"
    *ngIf="dataType == 'Date'; else bool"
  >
    <ng-container *ngIf="hasStartAndEndDateValidator; else singleDatePicker">
      <mat-label>{{ label || '' }}</mat-label>
      <mat-date-range-input [rangePicker]="pickerRange">
        <input matStartDate placeholder="Start Date" [readonly]="isReadOnly" />
        <input matEndDate placeholder="End Date" [readonly]="isReadOnly" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
      <mat-date-range-picker #pickerRange></mat-date-range-picker>
        <mat-error>{{ error }}</mat-error>
    </ng-container>
    <ng-template #singleDatePicker> 
      <input
        matInput
        [disabled]="true"
        [matDatepicker]="picker"
        [placeholder]="placeholder || ''"
        [readonly]="isReadOnly"
        [formControl]="control"
      />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker />
      @if (control.hasError("matDatepickerParse")) {
        <mat-error>Field must be a valid date</mat-error>
      } @else {
        <mat-error>
          {{ error }}
        </mat-error>
      }  
    </ng-template>  
  </mat-form-field>

  <ng-template #bool>
    <mat-radio-group
      *ngIf="dataType == 'Boolean'; else currency"
      [formControl]="control"
    >
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </ng-template>

  <ng-template #currency>
    <mat-form-field *ngIf="dataType == 'Currency'; else other">
      <mat-select
        (selectionChange)="onCurrencySelect($event)"
        [formControl]="control"
      >
        <mat-option
          *ngFor="let currency of currencies"
          [value]="currency.currencyName"
        >
          {{ currency.currencyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>

  <!--  -->
  <ng-template #other>
    <mat-form-field appearance="outline" style="width: 100%">
      <input
        class="input"
        [type]="
          dataType == 'Integer' || dataType == 'Decimal' ? 'number' : 'text'
        "
        [formControl]="control"
        matInput
        [placeholder]="placeholder || ''"
        [readonly]="isReadOnly"
      />
      <mat-error>
        {{ error }}
      </mat-error>
    </mat-form-field>
  </ng-template>
}
