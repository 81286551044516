import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateDocumentForm,
  DocumentFormDetailDto,
  UpdateDocumentFormDto,
  UpdateFormStatusDto,
} from '../models/document-forms.model';
import { Observable } from 'rxjs';
import { DocumentForm } from '../models/document-forms.model';
import {
  ARCHIVED_DOCUMENT_TEMPLATE_URL,
  DOCUMENT_TEMPLATE_URL,
  TEMPLATE_APPROVED_URL,
  TEMPLATE_DRAFT_URL,
  TEMPLATE_FIELDS_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { FormFields } from '../../template-fields/models/form-fields.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentFormService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  updateDocumentForm(
    updateForm: UpdateDocumentFormDto,
  ): Observable<DocumentForm> {
    return this.http.put<DocumentForm>(
      `${DOCUMENT_TEMPLATE_URL}`,
      updateForm,
      this.httpOptions,
    );
  }
  getDocumentFormsByStatus(
    status: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<DocumentForm>> {
    const options = new HttpParams({
      fromObject: { status, pageSize, pageNumber },
    });
    return this.http.get<PaginatedList<DocumentForm>>(
      `${DOCUMENT_TEMPLATE_URL}/by-status`,
      {
        params: options,
      },
    );
  }

  createForm(
    template: CreateDocumentForm,
  ): Observable<DocumentForm> {
    return this.http.post<DocumentForm>(
      `${DOCUMENT_TEMPLATE_URL}`,
      template,
      this.httpOptions,
    );
  }
  getDocumentForms(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<DocumentForm>> {
    let options;
    let pageindex= pageNumber || 1
    let size= pageSize || 10
    options=new HttpParams({
      fromObject:{pageNumber:pageindex,pageSize:size}
    });
    return this.http.get<PaginatedList<DocumentForm>>(
      `${DOCUMENT_TEMPLATE_URL}`,
      {
        params: options,
      },
    );
  }

  deleteDocumentForm(id: string): Observable<any> {
    return this.http.delete<any>(
      `${DOCUMENT_TEMPLATE_URL}/${id}`,
      this.httpOptions,
    );
  }
  deleteDraftDocumentForm(id: string): Observable<any> {
    return this.http.delete<any>(
      `${TEMPLATE_DRAFT_URL}/${id}`,
      this.httpOptions,
    );
  }

  getDocumentForm(id: string): Observable<any> {
    return this.http.get<any>(
      `${DOCUMENT_TEMPLATE_URL}/${id}`,
      this.httpOptions,
    );
  }

  getFormDetailByStatus(
    id: string,
    status: FormStatusOption,
  ): Observable<DocumentFormDetailDto> {
    return this.http.get<DocumentFormDetailDto>(
      `${DOCUMENT_TEMPLATE_URL}/${id}?status=${status.toString}`,
      this.httpOptions,
    );
  }

  getDraftForms(
    pageNumber: number | null,
    pageSize: number | null,
  ): Observable<PaginatedList<DocumentForm>> {
    const url = `${TEMPLATE_DRAFT_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get<PaginatedList<DocumentForm>>(url);
  }

  changeFormStatus(
    updatedForm: UpdateFormStatusDto,
  ): Observable<any> {
    let url = `${DOCUMENT_TEMPLATE_URL}/`;
    if (updatedForm.id !== null && updatedForm.status.status !== null) {
      switch(updatedForm.status.status) {
        case FormStatusOption.DRAFT:
          url = url + 'change-status-to-draft'
          break;
        case FormStatusOption.SUBMITTED:
          url = url + 'change-status-to-submitted'
          break;
        case FormStatusOption.APPROVED:
          url = url + 'change-status-to-approved'
          break;
        case FormStatusOption.REJECTED:
          url = url + 'change-status-to-rejected'
          break;
        case FormStatusOption.CHANGE_REQUESTED:
          url = url + 'change-status-to-change-requested'
          break;
      }
    }
    const requestData = {
      id: updatedForm.id,
      comment: updatedForm.status.comment,
    };
    return this.http.put<any>(`${url}`,requestData,this.httpOptions);
  }

  updateFieldOnFormDetail(
    updatedField: FormFields | undefined,
  ): Observable<FormFields> {
    return this.http.put<FormFields>(
      `${TEMPLATE_FIELDS_URL}`,
      updatedField,
      this.httpOptions,
    );
  }

  deleteFieldOnFormDetail(fieldId: string): Observable<any> {
    return this.http.delete<any>(
      `${TEMPLATE_FIELDS_URL}/${fieldId}`,
      this.httpOptions,
    );
  }

  searchDocumentForms(
    status:string,
    templateName: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<DocumentForm>> {
    let url = `${DOCUMENT_TEMPLATE_URL}/search-by-name?templateName=${templateName}&status=${status}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<DocumentForm>>(url);
  }

  orderFormFields(
    orderedFormFields: FormFields[],
    templateId: string,
  ) {
    return this.http.put<any>(
      `${TEMPLATE_FIELDS_URL}/order/${templateId}`,
      orderedFormFields,
      this.httpOptions,
    );
  }

  getApprovedForms(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<DocumentForm>> {
    let url = TEMPLATE_APPROVED_URL;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<DocumentForm>>(url);
  }

  getArchivedDocumentForms(
    pageNumber: number = 1,
    pageSize: number = 10,
    templateName?: string,
  ): Observable<PaginatedList<DocumentForm>> {
    if (templateName) {
      return this.http.get<PaginatedList<DocumentForm>>(
        ARCHIVED_DOCUMENT_TEMPLATE_URL,
        {
          params: new HttpParams({
            fromObject: { pageNumber, pageSize, templateName },
          }),
        },
      );
    }
    return this.http.get<PaginatedList<DocumentForm>>(
      ARCHIVED_DOCUMENT_TEMPLATE_URL,
      { params: new HttpParams({ fromObject: { pageNumber, pageSize } }) },
    );
  }
}
