import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  IntitatedWorkflowState,
  WorkflowStateModel,
} from './workflow-analytics.state';

export class WorkflowSelector {
  static slices = createPropertySelectors<WorkflowStateModel>(
    IntitatedWorkflowState,
  );

  @Selector([IntitatedWorkflowState])
  static initiatedWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.initiatedWorkflows;
  }

  @Selector([IntitatedWorkflowState])
  static myWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.myWorkflows;
  }

  @Selector([IntitatedWorkflowState])
  static involvedWorkflows(stateModel: WorkflowStateModel) {
    return stateModel.involvedWorkflows;
  }
}
