import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { PROCESS_LIST_ROUTE } from 'src/app/core/constants/routes';
import { Process } from 'src/app/researches/models/process.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import { ProcessDetailFacade } from 'src/app/researches/facades/process.detail.facades';
import {
  PROCESS_FORM_SIDE_DIALOG_CONFIG,
  SIDE_DIALOG_CONFIG,
} from 'src/app/core/constants/dialog_configs';
import { StageFormComponent } from '../../stage/stage-form/stage-form.component';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import { ProcessFormComponent } from '../process-form/process-form.component';
import { PermissionFacade } from 'src/app/users/facade/permission.facade';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface ProcessListComponentState {
  processes: Process[];
  selectedProcess: Process | undefined;
  length: number;
  featuresNameState: any;
  permissionsNameState: any;
  processListSearchTerm: string;
}

const initialProcessListComponentState: ProcessListComponentState = {
  processes: [],
  selectedProcess: undefined,
  length: 0,
  featuresNameState: {},
  permissionsNameState: {},
  processListSearchTerm: '',
};

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ProcessListComponent}, RxState],
})
export class ProcessListComponent implements OnInit, MatPaginatorIntl {
  dataSource!: MatTableDataSource<Process>;
  processes$ = this.state.select('processes');

  processListSearchTerm$ = this.state.select('processListSearchTerm');
  processListSearchTerm: string = '';

  processes: Process[] = [];
  selectedProcess: Process | undefined = undefined;
  selectedProcess$ = this.state.select('selectedProcess');
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length$ = this.state.select('length');
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;

  firstPageLabel = $localize`:@@researches.process-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.process-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.process-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.process-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.process-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.process-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.process-list.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.process-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private processFacade: ProcessFacade,
    private processDetailFacade: ProcessDetailFacade,
    private router: Router,
    private state: RxState<ProcessListComponentState>,
    private dialog: MatDialog,
    private stageFacade: StageFacade,
    private permissionFacade: PermissionFacade,
  ) {
    this.state.set(initialProcessListComponentState);
    this.state.connect('processes', this.processFacade.processes$);
    this.state.connect('length', this.processFacade.totalCount$);
    this.state.connect('selectedProcess', this.processFacade.selectedProcess$);
    this.state.connect('processListSearchTerm', this.processFacade.processListSearchTerm$);
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.processFacade.dispatchGetPaginatedProcesses(
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.processFacade.dispatchSetProcessListPageIndex(
      this.paginator?.pageIndex + 1 || 1,
    );
    this.processFacade.dispatchSetProcessListPageSize(
      this.paginator?.pageSize || 10,
    );
    this.processes$.subscribe((processes) => {
      if (processes) {
        this.processes = processes;
        this.dataSource = new MatTableDataSource<Process>(processes);
      }
    });
    this.length$.subscribe((length) => {
      this.length = length;
    });
    this.selectedProcess$.subscribe((process) => {
      this.selectedProcess = process;
    });

    this.processListSearchTerm$.subscribe((processListSearchTerm) => {
      this.processListSearchTerm = processListSearchTerm;
    });
  }

  addProcess() {
    this.processFacade.dispatchSetUpdateStatus(false);
    this.dialog.open(ProcessFormComponent, PROCESS_FORM_SIDE_DIALOG_CONFIG);
  }

  updateProcess() {
    this.processFacade.dispatchSetUpdateStatus(true);
    this.dialog.open(ProcessFormComponent, PROCESS_FORM_SIDE_DIALOG_CONFIG);
  }

  loadPaginatedProcess(event: PageEvent) {
    this.processFacade.dispatchSetProcessListPageIndex(event.pageIndex + 1);
    this.processFacade.dispatchSetProcessListPageSize(event.pageSize);

    if(this.processListSearchTerm) return;
    
    this.processFacade.dispatchGetPaginatedProcesses(
      event.pageIndex + 1,
      event.pageSize,
    );
  }
  setSelectedProcess(event: MouseEvent, process: Process) {
    event.stopPropagation();
    this.processFacade.dispatchSetSelectedProcess(process);
    this.processDetailFacade.dispatchSetSelectedProcess(process);
  }

  openCreateStageForm() {
    this.stageFacade.dispatchEditableStage(null);
    this.dialog.open(StageFormComponent, {
      ...SIDE_DIALOG_CONFIG,
      data: {
        isUpdate: false,
      },
    });
  }

  onRowClick(event: MouseEvent, process: Process): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    this.processFacade.dispatchSetSelectedProcess(process);
    if (!isMenuButtonClick) {
      this.router.navigate([`${PROCESS_LIST_ROUTE}`, process.id]);
      this.processDetailFacade.dispatchSetSelectedProcess(process);
    }
  }

  openConfirmationDialog() {
    if (!this.selectedProcess) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize `:@@researches.process-list.delete-process-type-confirmation-part-1:Are you sure you want to delete the` ,
          boldText: ` "${this.selectedProcess!.name}" ` ,
          regularTextTwo: $localize `:@@researches.process-list.delete-process-type-confirmation-part-2: process type?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedProcess)
          this.processFacade.dispatchDeleteProcess(this.selectedProcess!.id!);
        this.router.navigate([PROCESS_LIST_ROUTE]);
      }
    });
  }

  hasAddProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.CreateProcess,
    );
  }

  hasUpdateProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.UpdateProcess,
    );
  }

  hasDeleteProcessPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.DeleteProcess,
    );
  }

  hasGetProcessesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.GetProcesses,
    );
  }

  hasSearchProcessesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.SearchProcesses,
    );
  }

  hasCreateStagePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Stage.Feature,
      PERMISSION_NAMES.Researches.Stage.CreateStage,
    );
  }

  hasCrudPermission(): string[] {
    return [
      this.hasUpdateProcessPermission(),
      this.hasDeleteProcessPermission(),
      this.hasCreateStagePermission()
    ]
  }
}
