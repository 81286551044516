import { Component } from '@angular/core';
import { WorkflowTypeStepDetail } from '../../models/workflow-type-steps.model';
import { RxState } from '@rx-angular/state';
import { ActivatedRoute } from '@angular/router';
import { WorkflowTypeStepsFacade } from '../../facade/workflow-type-steps.facades';

interface WorkflowTypeStepDetailState {
  workflowtypeStep: WorkflowTypeStepDetail | undefined
}

const InitWorkflowTypeStepDetailState: WorkflowTypeStepDetailState = {
  workflowtypeStep: undefined
}

@Component({
  selector: 'app-workflow-type-step-detail',
  templateUrl: './workflow-type-step-detail.component.html',
  styleUrl: './workflow-type-step-detail.component.scss'
})
export class WorkflowTypeStepDetailComponent {
  workflowtypeStep$ = this.state.select('workflowtypeStep') 
  workflowtypeStep: WorkflowTypeStepDetail | undefined

  constructor(
    private state: RxState<WorkflowTypeStepDetailState>,
    private route: ActivatedRoute,
    private workflowTypeStepFacade: WorkflowTypeStepsFacade
  ){
    this.state.set(InitWorkflowTypeStepDetailState)
    this.state.connect('workflowtypeStep', this.workflowTypeStepFacade.workflowTypeStepDetail$)
  }

  ngOnInit(){
    this.route.params.subscribe((param) => {
      this.workflowTypeStepFacade.dispatchGetWorkflowTypeStepDetail(param['id'])
    })
    this.workflowtypeStep$.subscribe((workflowtypeStep) => {
      this.workflowtypeStep = workflowtypeStep
    })
  }
}
