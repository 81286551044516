import { ActivityType } from '../models/activityType.model';
import { QuantityType } from '../models/quantityType.model';

export class GetQuantityTypes {
  static readonly type = `[QuantityType] ${GetQuantityTypes.name}`;
  constructor() {}
}
export class GetActivityTypes {
  static readonly type = `[ActivityType] ${GetActivityTypes.name}`;
  constructor() {}
}

export class RemoveQuantityType {
  static readonly type = `[ActivityType] ${RemoveQuantityType.name}`;
  constructor(public id: string) {}
}

export class RemoveActivityType {
  static readonly type = `[ActivityType] ${RemoveActivityType.name}`;
  constructor(public id: string) {}
}

export class CreateActivityType {
  static readonly type = `[ActivityType] ${CreateActivityType.name}`;
  constructor(public activityType: any) {}
}

export class CreateQuantityType {
  static readonly type = `[QuantityType] ${CreateQuantityType.name}`;
  constructor(public quantityType: any) {}
}

export class ActivityUpdate {
  static readonly type = `[ActivityType] ${ActivityUpdate.name}`;
  constructor(public activityType: ActivityType) {}
}

export class RemoveActivityUpdate {
  static readonly type = `[ActivityType] ${RemoveActivityUpdate.name}`;
  constructor() {}
}

export class UpdateActivityType {
  static readonly type = `[ActivityType] ${UpdateActivityType.name}`;
  constructor(public activityType: ActivityType) {}
}

export class QuantityUpdate {
  static readonly type = `[QuantityType] ${QuantityUpdate.name}`;
  constructor(public quantityType: QuantityType) {}
}

export class RemoveQuantityUpdate {
  static readonly type = `[QuantityType] ${RemoveQuantityUpdate.name}`;
  constructor() {}
}

export class UpdateQuantityType {
  static readonly type = `[QuantityType] ${UpdateQuantityType.name}`;
  constructor(public quantityType: QuantityType) {}
}
