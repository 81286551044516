import { Deadline } from '../models/deadline.model';
import {
  AddTeamMembersDto,
  CreateProcessInstanceModel,
  DeleteProcessInstanceDto,
  ProcessInstance,
  ProcessInstanceMultipleFilter,
  ProcessInstanceStatus,
  UpdateProcessInstanceModel,
} from '../models/process-instance.model';

export class GetProcessInstances {
  static readonly type = `[ProcessInstance] ${GetProcessInstances.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetOwnedProcessInstances {
  static readonly type = `[ProcessInstance] ${GetOwnedProcessInstances.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class CreateProcessInstance {
  static readonly type = `[ProcessInstance] ${CreateProcessInstance.name}`;
  constructor(public processInstance: CreateProcessInstanceModel) {}
}

export class AssignOwnerToResearchInstance {
  static readonly type = `[ProcessInstance] ${AssignOwnerToResearchInstance.name}`;
  constructor(
    public processInstanceId: string,
    public ownerId: string,
  ) {}
}

export class SetSelectedProcessInstance {
  static readonly type = `[ProcessInstance] ${SetSelectedProcessInstance.name}`;
  constructor(public processInstance: ProcessInstance) {}
}

export class SetUpdateStatus {
  static readonly type = `[ProcessInstance] ${SetUpdateStatus.name}`;
  constructor(public updateStatus: boolean) {}
}

export class UpdateProcessInstance {
  static readonly type = `[ProcessInstance] ${UpdateProcessInstance.name}`;
  constructor(public processInstance: UpdateProcessInstanceModel) {}
}
export class SearchProcessInstances {
  static readonly type = `[ProcessInstance] ${SearchProcessInstances.name}`;
  constructor(
    public readonly searchTerm: string,
    public readonly processesIds: string[],
  ) {}
}
export class DeleteProcessInstance {
  static readonly type = `[ProcessInstance] ${DeleteProcessInstance.name}`;
  constructor(
    public deleteProcessInstanceDto: DeleteProcessInstanceDto,
  ) {}
}

export class CancelDeleteProcess{
  static readonly type=`[ProcessInstance] ${CancelDeleteProcess.name}`
  constructor(
    public processInstanceId: string,
  ){}
}

export class ResetSelectedProcessInstance {
  static readonly type = `[ProcessInstance] ${ResetSelectedProcessInstance.name}`;
}

export class GetProcessInstanceDetail {
  static readonly type = `[ProcessInstance] ${GetProcessInstanceDetail.name}`;
  constructor(public processInstanceId: string) {}
}

export class SubmitProcessInstanceSchedule {
  static readonly type = `[ProcessInstance] ${SubmitProcessInstanceSchedule.name}`;
  constructor(public deadline: Deadline) {}
}
export class UpdateProcessInstanceSchedule {
  static readonly type = `[ProcessInstance] ${UpdateProcessInstanceSchedule.name}`;
  constructor(public deadline: Deadline) {}
}

export class UpdateStageInstanceList {
  static readonly type = `[Process] ${UpdateStageInstanceList.name}`;
  constructor(public readonly stageInstances: any) {}
}
export class ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData {
  static readonly type = `[ProcessInstance] ${ToggleVisibilityOfProcessInstanceSearchTermAndFiltersData.name}`;
  constructor(public visible: boolean) {}
}
export class GetArchivedProcessInstances {
  static readonly type = `[ProcessInstance] ${GetArchivedProcessInstances.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class DeleteArchivedProcessInstance {
  static readonly type = `[ProcessInstance] ${DeleteArchivedProcessInstance.name}`;
  constructor(public deleteProcessInstanceDto: DeleteProcessInstanceDto) {}
}

export class ArchiveProcessInstance {
  static readonly type = `[ProcessInstance] ${ArchiveProcessInstance.name}`;
  constructor(public processInstanceId: string) {}
}
export class RestoreArchivedProcessInstance {
  static readonly type = `[ProcessInstance] ${RestoreArchivedProcessInstance.name}`;
  constructor(public processInstanceId: string) {}
}
export class AddTeamMembers {
  static readonly type = `[ProcessInstance] ${AddTeamMembers.name}`;
  constructor(public addTeamMembersDto: AddTeamMembersDto) {}
}

export class DeleteTeamMember {
  static readonly type = `[ProcessInstance] ${DeleteTeamMember.name}`;
  constructor(
    public processInstanceId: string | undefined | null,
    public teamMemberId: string,
  ) {}
}
export class GetProcessInstancesByAssignedTasks {
  static readonly type = `[ProcessInstance] ${GetProcessInstancesByAssignedTasks.name}`;
  constructor(
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class DownloadProcess{
  static readonly type=`[ProcessInstance] ${DownloadProcess.name}`
  constructor(public processInstanceId: string){}

}
export class GetProcessInstanceTeamMembers{
  static readonly type = `[ProcessInstance] ${GetProcessInstanceTeamMembers.name}`;
  constructor(public processInstanceId: string) {}
}

export class GetProcessInstanceFilters {
  static readonly type = `[ProcessInstance] ${GetProcessInstanceFilters.name}`;
}

export class GetProcessInstancesByStatus {
  static readonly type = `[ProcessInstance] ${GetProcessInstancesByStatus.name}`;
  constructor(
    public readonly status: ProcessInstanceStatus,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}
export class GetProcessInstancesByDate {
  static readonly type = `[ProcessInstance] ${GetProcessInstancesByDate.name}`;
  constructor(
    public readonly startDate: string,
    public readonly endDate: string,
    public readonly pageNumber?: number,
    public readonly pageSize?: number,
  ) {}
}

export class GetProcessInstancesByFilter {
  static readonly type = `[ProcessInstance] ${GetProcessInstancesByFilter.name}`;
  constructor(
    public readonly filterData: ProcessInstanceMultipleFilter
  ) {}
}

export class SetProcessInstanceMultipleFilter {
  static readonly type = `[ProcessInstance] ${SetProcessInstanceMultipleFilter.name}`;
  constructor(
    public readonly filterData: ProcessInstanceMultipleFilter | undefined
  ) {}
}

export class ResetProcessInstanceFilter {
  static readonly type = `[ProcessInstance] ${ResetProcessInstanceFilter.name}`;
  constructor(
    public readonly isReset: boolean
  ) {}
}
