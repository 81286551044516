<mat-dialog-content mat-dialog-content class="form-container">
  <form class="cc-office-form" [formGroup]="attachUserForm">
    <h3>{{ formTitle }}</h3>

    <div class="flex">
      <div class="column">
        <button
          mat-raised-button
          color="primary"
          align
          (click)="openMultipleOffice()"
          i18n="@@documents.attach-user-form.office"
          [attr.data-test-id]="'documents.cc-users.attach-user-form.office-button'">
          office
        </button>
        <mat-chip-row
          class="chip"
          *ngFor="let item of attachUserForm.controls['selectedOffices'].value"
          (removed)="removeSelectedFlatOfficeNodeFromNodes(item)"
          [editable]="false"
        >
          {{ item.name }}
          <button matChipRemove [attr.aria-label]="'remove ' + item.name">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </div>
      <div class="fields">
        <div>
          <mat-form-field appearance="outline" class="process-selector">
            <mat-label i18n="@@documents.attach-user-form.choose-role">choose role</mat-label>
            <mat-select
              formControlName="roles"
              multiple
              [attr.data-test-id]="'documents.cc-users.attach-user-form.choose-role-mat-select'"
            >
              <mat-option *ngFor="let role of roles" [value]="role"  [attr.data-test-id]="'documents.cc-users.attach-user-form.mat-option'">{{
                role.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="full-width" appearance="outline">
          <mat-chip-grid #usersChipGrid aria-label="users">
            <mat-chip-row
              *ngFor="let user of attachUserForm.value.users"
              (removed)="removeUser(user)"
            >
              {{ user.firstName }}
              <button
                matChipRemove
                [attr.aria-label]="'remove ' + user.firstName"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            #userInput
            [placeholder]="placeholderToggleLabel.usersField"
            formControlName="usersQuery"
            [matChipInputFor]="usersChipGrid"
            [matAutocomplete]="userauto"
             [attr.data-test-id]="'documents.cc-users.attach-user-form.input'">
          <mat-autocomplete
            #userauto="matAutocomplete"
            (optionSelected)="selectUsers($event)"
          >
            <mat-option *ngFor="let user of filteredUsers" [value]="user"
            [attr.data-test-id]="'documents.cc-users.attach-user-form.mat-option'">
              {{ user.firstName }}
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="attachUserForm.controls['users'].errors?.['usersValidator']"
           i18n="@@documents.attach-user-form.please-select-atleast-one-user">
            Please select atleast one user.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@documents.attach-user-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!attachUserForm.valid"
     i18n="@@documents.attach-user-form.save"
     [attr.data-test-id]="'documents.cc-users.attach-user-form.save-button'">
      save
    </button>
  </div>
</mat-dialog-content>
