<mat-sidenav-container class="container">
  <div class="header">
    <h1 i18n="@@researches.task-list.my-tasks" class="mat-h1">My Tasks</h1>
    <app-search-form></app-search-form>
  </div>
  <mat-accordion *ngIf="processInstances.length > 0" [attr.data-test-id]="'researches.filter-task.task-list.mat-accordion'">
    <div class="expansion-container">
      <mat-expansion-panel
        *ngFor="let item of getProcessInstances(); let index = index"
        (opened)="
          panelOpenState = true; onToggleClick(panelOpenState, item.id!)
        "
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header [attr.data-test-id]="'researches.filter-task.task-list.'+ item.title +'-mat-expansion'">
          <mat-panel-title>{{ item.title }}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="taskList.length !== 0" class="table-container">
          <div class="table">
            <mat-table [dataSource]="getTaskList(item.id!)">
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef i18n="@@researches.task-list.name">Name</mat-header-cell>
                <mat-cell *matCellDef="let task">{{ task.name }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef i18n="@@researches.task-list.description">Description</mat-header-cell>
                <mat-cell *matCellDef="let task">{{
                  task.description
                }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef i18n="@@researches.task-list.status">Status</mat-header-cell>
                <mat-cell *matCellDef="let task">{{
                  task.taskStatusForDisplay?.status
                }}</mat-cell>
              </ng-container>
              <mat-row
                class="table-row"
                *matRowDef="
                  let task;
                  columns: ['name', 'description', 'status']
                "
                (click)="onRowClick($event, task, item.id!)"
              ></mat-row>
            </mat-table>
          </div>
        </ng-container>
        <div *ngIf="getTaskList(item.id!).length === 0">
          <p i18n="@@researches.task-list.no-tasks-found">no tasks found</p>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</mat-sidenav-container>
