import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class BooleanValidator {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return (value === 'true' || value === 'false') ? null
      :{ isValidCurrency: `Field must be a valid boolean.` };
    };
  }
}
