import { Injectable } from '@angular/core';
import {
  StateToken,
  State,
  Store,
  Action,
  StateContext,
  StateOperator,
} from '@ngxs/store';
import {
  patch,
  removeItem,
  updateItem,
  insertItem,
} from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ChangeDateFormatService } from 'src/app/core/services/change-date-format/change-date-format.service';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import Quill from 'quill';
import { pdfExporter } from "quill-to-pdf";
import {
  SetProgressOn,
  SetProgressOff,
} from 'src/app/core/store/progress-status.actions';
import { ProcessTasks } from '../models/process-tasks.model';
import { TaskDocument } from '../models/task-document.model';
import {
  Task,
  TaskDocumnet,
  TaskComment,
  TaskType,
  FileTaskUploadReport,
  TaskStatus,
} from '../models/task.model';
import { TaskDetail } from '../models/taskDetail.model';
import { TaskService } from '../services/task.service';
import {
  AddSageInstanceTask,
  GetStageInstanceTasks,
  UpdateTask,
  UpdateTaskFromStageInstanceDetail,
} from './stage-instance-detail.actions';
import {
  StageInstanceDetailStateModel,
  StageInstanceDetailState,
} from './stage-instance-detail.state';
import {
  GetTasksBySearchTerm,
  GetTaskByName,
  GetListOfTasksWithInProcessType,
  ToggleTaskDetailFrom,
  CreateTaskDocument,
  UpdateTaskDocument,
  DeleteDocumentTask,
  CreateTask,
  DeleteTaskFromMyTasks,
  SetSelectedTask,
  ResetSelectedTask,
  GetTaskDetail,
  Update,
  UploadTaskFile,
  DeleteFileTask,
  ShowSearchedTask,
  AssignTask,
  GetPaginatedTaskComments,
  AddTaskComment,
  UpdateTaskComment,
  SetSelectedTaskComment,
  ResetSelectedTaskComment,
  ResetSelectedReplyComment,
  ReplyTaskComment,
  ChangeTaskStatus,
  DeleteTaskComment,
  PreviewTaskDocument,
  GetPaginatedTaskDocument,
  SearchTaskTypes,
  SetFileTaskUploadReportsNull,
  SetSelectedReplyComment,
  UpdateReplyTaskComment,
  FetchVideo,
  FetchAudio,
  GetTaskTypesByStageInstanceId,
  GetTaskFiles,
  DownloadTaskDocument,
  GetAssignedTasks,
  GetAssignedDocumetTasks,
  ResetTaskDetail,
  DeleteTaskCommentReply,
  DownloadTaskDocumentCompose,
  PreviewTaskDocumentCompose,
  GetPaginatedTaskDocumentsByStageInstanceId,
} from './task.actions';
import {
  NO_END_DATE,
  NO_START_DATE,
} from 'src/app/core/constants/message-constants';

export enum TaskDetailFrom {
  NONE,
  MY_TASKS,
  STAGE_INSTANCE,
}

export interface TaskStateModel {
  update: boolean;
  taskDetailFrom?: TaskDetailFrom;
  documents: TaskDocument[];
  selectedTask: Task | undefined;
  myTasks: ProcessTasks[];
  taskDetail?: TaskDetail;
  taskDocument: TaskDocumnet | undefined;
  isSearching: boolean;
  taskComments: PaginatedList<TaskComment>;
  selectedTaskComment: TaskComment | null;
  isEditTaskComment: boolean;
  isReplyTaskComment: boolean;
  similarTasks: Task[];
  taskDocuments: PaginatedList<TaskDocument>;
  totalCount: number;
  taskTypes: TaskType[];
  fileTaskUploadReports: FileTaskUploadReport[] | null;
  selectedReplyComment: TaskComment | null;
  videoBlob: Blob | null;
  audioBlob: Blob | null;
  taskFiles: any | undefined;
  stageInstanceTaskDocuments: PaginatedList<TaskDocument>;
  assignedTasks: PaginatedList<Task>;
  assignedTaskDocuments: PaginatedList<TaskDocument>;
}

const TASK_STATE_TOKEN = new StateToken<TaskStateModel>('taskState');

const defaults: TaskStateModel = {
  taskDetail: undefined,
  update: false,
  similarTasks: [],
  documents: [],
  selectedTask: undefined,
  myTasks: [],
  taskDocument: undefined,
  isSearching: false,
  taskComments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  selectedTaskComment: null,
  isEditTaskComment: false,
  isReplyTaskComment: false,
  taskDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  totalCount: 0,
  taskTypes: [],
  fileTaskUploadReports: null,
  selectedReplyComment: null,
  videoBlob: null,
  audioBlob: null,
  taskFiles: undefined,
  assignedTasks: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  stageInstanceTaskDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  assignedTaskDocuments: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<TaskStateModel>({
  name: TASK_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class TaskState {
  constructor(
    private taskService: TaskService,
    private store: Store,
    private operationStatus: OperationStatusService,
    private changeDateFormatService: ChangeDateFormatService,
  ) {}

  @Action(GetTasksBySearchTerm)
  getTasksBySearchTerm(
    { setState }: StateContext<TaskStateModel>,
    { searchTerm, processIds }: GetTasksBySearchTerm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getTasksBySearchTerm(searchTerm, processIds).pipe(
      tap((processTasks) => {
        setState(
          patch({
            myTasks: processTasks,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetTaskByName)
  getTaskByName(
    { setState }: StateContext<TaskStateModel>,
    { name, stageInstanceId }: GetTaskByName,
  ) {
    return this.taskService.getTasksByName(name, stageInstanceId).pipe(
      tap((tasks) => {
        setState(patch({ similarTasks: tasks }));
      }),
    );
  }

  upsertProcessTasks(processTask: ProcessTasks): StateOperator<ProcessTasks[]> {
    return (state: Readonly<ProcessTasks[]>) => {
      const newPtasks = state.filter((pTasks) => pTasks.id !== processTask.id);
      newPtasks.push(processTask);
      return newPtasks;
    };
  }

  @Action(GetListOfTasksWithInProcessType)
  getProcessTasks(
    { setState }: StateContext<TaskStateModel>,
    { processId }: GetListOfTasksWithInProcessType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getProcessTasks(processId).pipe(
      tap((processTask) => {
        setState(
          patch({
            myTasks: this.upsertProcessTasks(processTask),
          }),
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(ToggleTaskDetailFrom)
  toggleTaskDetailFrom(
    { patchState }: StateContext<TaskStateModel>,
    { taskDetailFrom }: ToggleTaskDetailFrom,
  ) {
    patchState({ taskDetailFrom });
  }

  @Action(CreateTaskDocument)
  createTaskDocument(
    { setState }: StateContext<TaskStateModel>,
    { taskDoc }: CreateTaskDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.createTaskDocument(taskDoc).pipe(
      tap((val) => {
        setState(
          patch({
            taskDocument: val,
            taskDetail: patch({ taskDocument: val }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.document-composed-successfully: Document composed successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateTaskDocument)
  updateTaskDocument(
    { setState }: StateContext<TaskStateModel>,
    { document }: UpdateTaskDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.updateTaskDocuemnt(document).pipe(
      tap((val) => {
        setState(
          patch({
            taskDocument: val,
            taskDetail: patch({ taskDocument: val }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.document-updated-successfully: Document updated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(DeleteDocumentTask)
  DeleteDocumentTask(
    { setState, getState }: StateContext<TaskStateModel>,
    { id }: DeleteDocumentTask,
  ) {
    this.store.dispatch(new SetProgressOn());
    const totalCount = getState().taskDocuments.totalCount;

    return this.taskService.DeleteDocumentTask(id).pipe(
      tap(() => {
        setState(
          patch({
            taskDocuments: patch({
              items: removeItem<TaskDocument>((item) => item.id === id),

              totalCount: totalCount - 1,
            }),
            documents: removeItem<TaskDocument>((item) => item.id === id),
            totalCount: totalCount - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.document-task-deleted-successfully: Document Task  deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CreateTask)
  createTask({ setState }: StateContext<TaskStateModel>, { task }: CreateTask) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.createTask(task).pipe(
      tap((t) => {
        this.store.dispatch(
          new AddSageInstanceTask({
            ...t,
            assigneeName: t.assignee?.firstName || 'Unknown',
          } as any),
        );
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.new-task-created-successfully: New Task Created Successfully`,
        );
      }),
    );
  }

  @Action(DeleteTaskFromMyTasks)
  deleteTaskFromMyTasks(
    { setState }: StateContext<TaskStateModel>,
    { processInstanceId, selectedTaskId }: DeleteTaskFromMyTasks,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.deleteTask(selectedTaskId).pipe(
      tap((deletedTask: Task) => {
        setState(
          patch({
            myTasks: updateItem<ProcessTasks>(
              (pTask) => pTask.id === processInstanceId,
              patch({
                tasks: removeItem<Task>((task) => task.id === deletedTask.id),
              }),
            ),
            selectedTask: undefined,
          }),
        );
        const stageInstanceDetailState: StageInstanceDetailStateModel =
          this.store.selectSnapshot(StageInstanceDetailState);
        if (!stageInstanceDetailState.StageInstanceDetail) {
          this.store.dispatch(new SetProgressOff());
          return;
        }
        const stageInstanceDetailId =
          stageInstanceDetailState.StageInstanceDetail.id;
        this.store.dispatch(new GetStageInstanceTasks(stageInstanceDetailId));
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedTask)
  setSelectedTask(
    { patchState }: StateContext<TaskStateModel>,
    { task }: SetSelectedTask,
  ) {
    return patchState({
      selectedTask: task,
    });
  }

  @Action(ResetSelectedTask)
  resetSelectedTask({ patchState }: StateContext<TaskStateModel>) {
    return patchState({
      selectedTask: undefined,
    });
  }

  @Action(GetTaskDetail)
  getTaskDetail(
    { setState }: StateContext<TaskStateModel>,
    { taskId }: GetTaskDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getTaskDetail(taskId).pipe(
      tap((task) => {
        task.startDate =
          task.startDate === null
            ? $localize`:@@researches.task-detail.no-start-date: ${NO_START_DATE}`
            : this.changeDateFormatService.changeDateFormat(
                new Date(task.startDate),
              );
        task.endDate =
          task.endDate === null
            ? $localize`:@@researches.task-detail.no-end-date: ${NO_END_DATE}`
            : this.changeDateFormatService.changeDateFormat(
                new Date(task.endDate),
              );
        setState(
          patch({
            taskDetail: task,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(Update)
  isTaskupdateTrue(
    { patchState }: StateContext<TaskStateModel>,
    { update }: Update,
  ) {
    patchState({
      update,
    });
  }

  @Action(UpdateTask)
  updateTask({ setState }: StateContext<TaskStateModel>, { task }: UpdateTask) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.updateTask(task).pipe(
      tap((updatedTask) => {
        this.store.dispatch(new UpdateTaskFromStageInstanceDetail(updatedTask));
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.task-updated-successfully: Task updated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UploadTaskFile)
  createFiles(
    { setState, getState }: StateContext<TaskStateModel>,
    { file, taskId }: UploadTaskFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    let prevLength = getState().taskDocuments.totalCount;
    let pageSize =
      getState().taskDocuments.totalCount / getState().taskDocuments.totalPages;
    return this.taskService.uploadTaskFile(file, taskId).pipe(
      tap((fileTaskUploadReports: FileTaskUploadReport[]) => {
        const state = this.store.selectSnapshot(TaskState);
        const successReports = fileTaskUploadReports.filter(
          (report) => report.fileTask && report.success,
        );
        const fileTaskList: TaskDocumnet[] = successReports.map(
          (report) => report.fileTask!,
        );
        setState(
          patch({
            taskDocuments: patch({
              items: [...state.taskDocuments.items, ...fileTaskList],
              totalCount: () => prevLength + fileTaskList.length,
              totalPages: () =>
                Math.ceil((prevLength + fileTaskList.length) / pageSize),
            }),
            documents: [...state.documents, ...fileTaskList],
            fileTaskUploadReports: fileTaskUploadReports,
            taskDetail: patch({
              taskFile: fileTaskUploadReports,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(DeleteFileTask)
  DeleteFileTask(
    { setState, getState }: StateContext<TaskStateModel>,
    { id }: DeleteFileTask,
  ) {
    this.store.dispatch(new SetProgressOn());
    const totalCount = getState().taskDocuments.totalCount;
    return this.taskService.DeleteFileTask(id).pipe(
      tap(() => {
        setState(
          patch({
            taskDocuments: patch({
              items: removeItem<TaskDocument>((item) => item.id === id),
              totalCount: totalCount - 1,
            }),
            documents: removeItem<TaskDocument>((item) => item.id === id),
            totalCount: totalCount - 1,
            taskFiles: removeItem<any>((item) => item.id === id),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.file-task-deleted-successfully: File Task  deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ShowSearchedTask)
  showSearchedTask(
    { patchState }: StateContext<TaskStateModel>,
    { isSearching }: ShowSearchedTask,
  ) {
    patchState({ isSearching });
  }

  @Action(AssignTask)
  assignTask(
    { setState }: StateContext<TaskStateModel>,
    { assignTask }: AssignTask,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.assignTask(assignTask).pipe(
      tap((val) => {
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.assigned-successfully: Task Assigned Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetPaginatedTaskComments)
  getPaginatedTaskComments(
    { setState }: StateContext<TaskStateModel>,
    { taskId, pageNumber, pageSize }: GetPaginatedTaskComments,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getPaginatedTaskComments(taskId, pageNumber, pageSize)
      .pipe(
        tap((paginatedTaskComments) => {
          setState(
            patch({
              taskComments: paginatedTaskComments,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AddTaskComment)
  addTaskComment(
    { setState, getState }: StateContext<TaskStateModel>,
    { taskId, comment }: AddTaskComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    const totalCount = getState().taskComments.totalCount;
    return this.taskService.addTaskComment(taskId, comment).pipe(
      tap((taskComment) => {
        setState(
          patch({
            taskComments: patch({
              items: insertItem(taskComment),
              totalCount: totalCount + 1,
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.comment-added-successfully: Comment Added Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateTaskComment)
  updateTaskComment(
    { setState }: StateContext<TaskStateModel>,
    { taskCommentId, content }: UpdateTaskComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.updateTaskComment(taskCommentId, content).pipe(
      tap((taskComment) => {
        setState(
          patch({
            taskComments: patch({
              items: updateItem<TaskComment>(
                (item) => item.id === taskCommentId,
                taskComment,
              ),
            }),
            isEditTaskComment: false,
            isReplyTaskComment: false,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.comment-updated-successfully: Comment updated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedTaskComment)
  setSelectedTaskComment(
    { patchState }: StateContext<TaskStateModel>,
    { taskComment, typeOfAction }: SetSelectedTaskComment,
  ) {
    patchState({
      selectedTaskComment: taskComment,
      isEditTaskComment: typeOfAction === 'edit',
      isReplyTaskComment: typeOfAction === 'reply',
    });
  }

  @Action(ResetSelectedTaskComment)
  resetSelectedTaskComment(
    { setState }: StateContext<TaskStateModel>,
    {}: ResetSelectedTaskComment,
  ) {
    setState(
      patch({
        selectedTaskComment: null,
      }),
    );
  }

  @Action(ResetSelectedReplyComment)
  resetSelectedReplyComment(
    { setState }: StateContext<TaskStateModel>,
    {}: ResetSelectedReplyComment,
  ) {
    setState(
      patch({
        selectedReplyComment: null,
      }),
    );
  }

  @Action(ReplyTaskComment)
  replyTaskComment(
    { setState, getState }: StateContext<TaskStateModel>,
    { commentId, content }: ReplyTaskComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.replyTaskComment(commentId, content).pipe(
      tap((taskComment) => {
        const selectedTaskComment = getState().selectedTaskComment;
        selectedTaskComment!.replies.push(taskComment);
        setState(
          patch({
            selectedTaskComment: selectedTaskComment,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.reply-sent-successfully: Reply sent successfully`,
          successStyle,
        );
        this.store.dispatch(new ResetSelectedTaskComment());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ChangeTaskStatus)
  changeTaskStatus(
    { setState }: StateContext<TaskStateModel>,
    { updatedTask }: ChangeTaskStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.changeTaskStatus(updatedTask).pipe(
      tap(() => {
        setState(
          patch({ taskDetail: patch({ taskStatus: updatedTask.taskStatus }) }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteTaskComment)
  deleteTaskComment(
    { setState, getState }: StateContext<TaskStateModel>,
    { commentId }: DeleteTaskComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    const totalCount = getState().taskComments.totalCount;
    return this.taskService.deleteTaskComment(commentId).pipe(
      tap(() => {
        setState(
          patch({
            taskComments: patch({
              items: removeItem<TaskComment>((item) => item.id === commentId),
              totalCount: totalCount - 1,
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@researches.task.comment-deleted-successfully: Comment deleted successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(PreviewTaskDocument)
  previewTaskDocument(
    { setState }: StateContext<TaskStateModel>,
    {
      fileId,
      name,
      table,
      documentDownloadType,
      documentOperationType,
    }: PreviewTaskDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    var fileType = 'pdf';
    const fileExtension = name.split('.').pop()?.toLowerCase();
    if (
      fileExtension === 'jpg' ||
      fileExtension === 'jpeg' ||
      fileExtension === 'png' ||
      fileExtension === 'gif'
    ) {
      fileType = 'image';
    }
    var contentType = 'application/pdf';
    if (fileType === 'image') {
      contentType = `image/${fileExtension}`;
    }
    return this.taskService
      .getTaskDocument(
        fileId,
        table,
        documentDownloadType,
        documentOperationType,
      )
      .pipe(
        tap((response) => {
          if (response) {
            const blob = new Blob([response], { type: contentType });
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, '_blank');
            this.store.dispatch(new SetProgressOff());
          }
        }),
      );
  }
  @Action(GetPaginatedTaskDocument)
  getPaginatedTaskDocument(
    { patchState }: StateContext<TaskStateModel>,
    { taskId, pageNumber, pageSize }: GetPaginatedTaskDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getPaginatedTaskDocument(taskId, pageNumber, pageSize)
      .pipe(
        tap((paginatedTaskDocument) => {
          patchState({
            documents: paginatedTaskDocument.items,
            taskDocuments: paginatedTaskDocument,
            totalCount: paginatedTaskDocument.totalCount,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
  @Action(GetPaginatedTaskDocumentsByStageInstanceId)
  getPaginatedTaskDocumentsByStageInstanceId(
    { patchState }: StateContext<TaskStateModel>,
    { stageInstanceId, pageNumber, pageSize }: GetPaginatedTaskDocumentsByStageInstanceId,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getPaginatedTaskDocumentsByStageInstanceId(stageInstanceId, pageNumber, pageSize)
      .pipe(
        tap((paginatedTaskDocument) => {
          patchState({
            stageInstanceTaskDocuments: paginatedTaskDocument,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SearchTaskTypes)
  searchTaskTypes(
    { setState }: StateContext<TaskStateModel>,
    { name, pageNumber, pageSize }: SearchTaskTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.searchTaskTypes(name, pageNumber, pageSize).pipe(
      tap((types) => {
        setState(
          patch({
            taskTypes: types.items,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetFileTaskUploadReportsNull)
  setFileTaskUploadReportsNull(
    { patchState }: StateContext<TaskStateModel>,
    {}: SetFileTaskUploadReportsNull,
  ) {
    patchState({
      fileTaskUploadReports: null,
    });
  }

  @Action(SetSelectedReplyComment)
  setSelectedReplyComment(
    { patchState }: StateContext<TaskStateModel>,
    { comment }: SetSelectedReplyComment,
  ) {
    patchState({
      selectedReplyComment: comment,
    });
  }

  @Action(UpdateReplyTaskComment)
  updateReplyTaskComment(
    { setState, getState }: StateContext<TaskStateModel>,
    { taskCommentId, commentId, content }: UpdateReplyTaskComment,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .updateReplyTaskComment(taskCommentId, commentId, content)
      .pipe(
        tap((taskComment) => {
          const selectedTaskComment = getState().selectedTaskComment;
          const selectedReplyComment = getState().selectedReplyComment;
          selectedReplyComment!.content = taskComment.content;
          setState(
            patch({
              selectedTaskComment: selectedTaskComment,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.task.reply-updated-successfully: Reply updated successfully`,
            successStyle,
          );
          this.store.dispatch(new ResetSelectedReplyComment());
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteTaskCommentReply)
  deleteTaskCommentReply(
    { setState, getState }: StateContext<TaskStateModel>,
    { taskCommentId, commentId }: DeleteTaskCommentReply,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .deleteTaskCommentReply(taskCommentId, commentId)
      .pipe(
        tap(() => {
          const state = getState();
          const parentCommentIndex = state.taskComments.items.findIndex(
            (comment) => comment.id === taskCommentId,
          );
          if (parentCommentIndex !== -1) {
            const updatedReplies = state.taskComments.items[
              parentCommentIndex
            ].replies.filter((reply) => reply.id !== commentId);
            const updatedComments = [...state.taskComments.items];
            updatedComments[parentCommentIndex] = {
              ...updatedComments[parentCommentIndex],
              replies: updatedReplies,
            };
            setState(
              patch({
                taskComments: patch({
                  items: updatedComments,
                }),
              }),
            );
          }
          this.operationStatus.displayStatus(
            $localize`:@@researches.task.comment-deleted-successfully: Comment deleted successfully`,
            successStyle,
          );

          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(FetchVideo)
  fetchVideo(
    { patchState }: StateContext<TaskStateModel>,
    { fileId }: FetchVideo,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getFile(fileId).pipe(
      tap((response) => {
        if (response) {
          var blob = new Blob([response], {
            type: 'application/pdf',
          });

          const fileUrl = URL.createObjectURL(blob);
          patchState({
            videoBlob: blob,
          });
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(FetchAudio)
  fetchAudio(
    { patchState }: StateContext<TaskStateModel>,
    { fileId }: FetchAudio,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getFile(fileId).pipe(
      tap((response) => {
        if (response) {
          var blob = new Blob([response], {
            type: 'application/pdf',
          });

          const fileUrl = URL.createObjectURL(blob);
          patchState({
            audioBlob: blob,
          });
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(GetTaskTypesByStageInstanceId)
  getTaskTypesByStageInstanceId(
    { setState }: StateContext<TaskStateModel>,
    { stageInstanceId }: GetTaskTypesByStageInstanceId,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getTaskTypeByStageInstanceId(stageInstanceId).pipe(
      tap((types) => {
        setState(
          patch({
            taskTypes: types,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetTaskFiles)
  getTaskFiles(
    { patchState }: StateContext<TaskStateModel>,
    { taskId, pageNumber, pageSize }: GetTaskFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getTaskFiles(taskId, pageNumber, pageSize).pipe(
      tap((files) => {
        if (files) {
          var taskFiles = files.items.filter(
            (file) => file.table.toLowerCase() === 'filetask',
          );
          patchState({
            taskFiles: taskFiles,
          });
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(DownloadTaskDocument)
  downloadTaskDocument(
    { patchState }: StateContext<TaskStateModel>,
    {
      fileId,
      name,
      table,
      documentDownloadType,
      documentOperationType,
    }: DownloadTaskDocument,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getTaskDocument(
        fileId,
        table,
        documentDownloadType,
        documentOperationType,
      )
      .pipe(
        tap((response) => {
          if (response) {
            const blob = new Blob([response], {
              type: 'application/octate-stream',
            });
            const fileUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileUrl;
            let filename = name;
            if (table.toLowerCase() === 'document') {
              if (documentDownloadType.toLowerCase() === 'word') {
                filename = name + '.docx';
              } else {
                filename = name + '.pdf';
              }
            }
            link.download = filename;
            link.click();
            this.store.dispatch(new SetProgressOff());
          }
        }),
      );
  }

  @Action(GetAssignedTasks)
  getAssignedTasks(
    { setState }: StateContext<TaskStateModel>,
    { pageNumber, pageSize }: GetAssignedTasks,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService.getAssignedTasks(pageNumber, pageSize).pipe(
      tap((paginatedTasks) => {
        setState(
          patch({
            assignedTasks: paginatedTasks,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetAssignedDocumetTasks)
  getAssignedTaskDocuments(
    { patchState }: StateContext<TaskStateModel>,
    { stageInstanceId, pageNumber, pageSize }: GetAssignedDocumetTasks,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.taskService
      .getAssignedTaskDocuments(stageInstanceId, pageNumber, pageSize)
      .pipe(
        tap((paginatedDocuments) => {
          patchState({
            assignedTaskDocuments: paginatedDocuments,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(ResetTaskDetail)
  resetTaskDetail(
    { patchState }: StateContext<TaskStateModel>,
    {}: ResetTaskDetail,
  ) {
    patchState(defaults);
  }

  @Action(DownloadTaskDocumentCompose)
  downloadTaskDocumentCompose(
    { patchState }: StateContext<TaskStateModel>,
    {id,name}: DownloadTaskDocumentCompose,
  ){
    this.store.dispatch(new SetProgressOn());
    return this.taskService.downloadTaskDocumentCompose(id).pipe(
      tap(async (response) => {
        if (response) {
        let editorElement = document.createElement('div');
          editorElement.setAttribute('id', 'editor');
          document.body.appendChild(editorElement);
          let editor = new Quill(editorElement);
          editor.setContents(
            JSON.parse(response.documentContent),
            'api',
          );
          const delta = editor.getContents();
          const blob = await pdfExporter.generatePdf(delta);
          const fileUrl = URL.createObjectURL(blob as Blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = name + '.pdf';
          link.click();
          document.body.removeChild(editorElement);
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

  @Action(PreviewTaskDocumentCompose)
  previewTaskDocumentCompose(
    { patchState }: StateContext<TaskStateModel>,
    {id,name}: PreviewTaskDocumentCompose,
  ){
    this.store.dispatch(new SetProgressOn());
    return this.taskService.downloadTaskDocumentCompose(id).pipe(
      tap(async (response) => {
        if (response) {
        let editorElement = document.createElement('div');
          editorElement.setAttribute('id', 'editor');
          document.body.appendChild(editorElement);
          let editor = new Quill(editorElement);
          editor.setContents(
            JSON.parse(response.documentContent),
            'api',
          );
          const delta = editor.getContents();
          const blob = await pdfExporter.generatePdf(delta);
          const fileUrl = URL.createObjectURL(blob as Blob);
          window.open(fileUrl, '_blank');
          document.body.removeChild(editorElement);
          this.store.dispatch(new SetProgressOff());
        }
      }),
    );
  }

}
