<div class="w-500">
  <h2 mat-dialog-title>{{ message }}</h2>
  <div mat-dialog-content class="w-full">
    <div class="my-1">
      <span>{{ regularTextOne }}</span>
      <span class="font-bold">{{ boldText }}</span>
      <span>{{ regularTextTwo }}</span>
    </div>

    <div class="my-1"></div>
    <span>{{ extraTextOne }}</span>
    <span class="font-bold">{{ extraTextBold }}</span>
    <span>{{ extraTextTwo }}</span>
    <div class="my-1"></div>
    <div class="full-width-input">
      <input
        type="text"
        class="input-style"
        data-test-id="shared.deliberate-confirmation-input"
        (input)="onConfirmTextChange($event)"
        placeholder="Type {{ name }} to confirm"
        [attr.data-test-id]="'shared.deliberate-delete.confirm-deliberate-dialog.item-identifier-input-field'"
      />
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button
      [disabled]="confirmText !== name"
      [mat-dialog-close]="true"
      mat-flat-button
      color="warn"
      (click)="onConfirm()"
      i18n="@@shared.confirm-deliberate-dialog.confirm-delete"
      [attr.data-test-id]="'shared.deliberate-delete.confirm-deliberate-dialog.confirm-button'"
    >
      Confirm
    </button>
    <button mat-flat-button mat-dialog-close color="primary" i18n="@@shared.confirm-deliberate-dialog.cancel">Cancel</button>
  </div>
</div>
