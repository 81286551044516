<div class="notification--header">
  <h1 class="mat-h1" i18n="@@core.notification-card.notifications">Notifications</h1>
  <button
    mat-stroked-button
    color="warn"
    (click)="markAllNotificationAsRead()"
    [disabled]="(unreadNotifications$ | async) == 0"
    i18n="@@core.notification-card.mark-all-as-read"
  >
    Mark all as read
  </button>
</div>
<div class="notifications">
  <mat-paginator
    aria-label="Select page"
    class="paginator"
    (page)="paginateNotifications($event)"
    [pageSizeOptions]="[10, 15, 20]"
    [length]="(notifications$ | async)?.totalCount"
  >
  </mat-paginator>

  <ng-container *ngFor="let notfication of (notifications$ | async)?.items">
    <app-notification-card
      [notification]="notfication"
      (visible)="markNotificationAsRead(notfication)"
    ></app-notification-card>
  </ng-container>
</div>
