import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PublicationCategoriesVsProcessCount,
  PublicationData,
  ResearchVsTime,
} from '../models/research-vs-time.model';
import {
  BI_BUDGET,
  BI_COMMUNICATION_COUNT,
  BI_COMMUNICATION_TYPE_INSTANCES,
  BI_COMMUNICATION_VS_TIME_URL,
  BI_RESEARCH_COUNT,
  RESEARCHES_ANALYTICS_URL,
  TOR_ANALYTICS_URL,
} from 'src/app/core/constants/api-endpoints';
import {
  EvaluatedProcessInstanceCountByDepartment,
  EvaluatedProcessInstanceStatusCount,
  TaskAccomplishmentVsTimeCount,
} from '../models/research-dashboard.model';
import {
  BudgetPerCommunication,
  CommunicationVsTime,
} from '../models/communication-vs-time.mode';
import { CommunicationCount } from '../models/communication-count';
import { ResearchCount } from '../models/research-count';
import { BudgetVsTime } from '../models/budget-vs-time.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Research } from '../models/research.model';
import {
  Communications,
  CommunicationsWithStatus,
} from '../models/communications';
import { CommunicationTypeInstance } from '../models/communication-type-Instances.model';
import { ResearchEvaluation } from '../models/research-evaluation.model';
import { DepartmentVsPublication } from '../models/department-vs-publicaiton.model';
@Injectable({
  providedIn: 'root',
})
export class ResearchDashboardService {
  constructor(private http: HttpClient) {}

  getResearchData(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<ResearchVsTime[]> {
    let params = new HttpParams();
    if (startDate) {params = params.set('startDate', startDate.toISOString())}
    if (endDate) {params = params.set('endDate', endDate.toISOString())}
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    return this.http.get<ResearchVsTime[]>(
      `${RESEARCHES_ANALYTICS_URL}/by-time-line`,
      { params: params },
    );
  }
  getEvaluatedProcessInstanceStatusCount(
    offices: string[],
    users: string[],
    start?: Date,
    end?: Date,
  ): Observable<EvaluatedProcessInstanceStatusCount[]> {
    let params = new HttpParams({
      fromObject: { offices, users },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<EvaluatedProcessInstanceStatusCount[]>(
      `${RESEARCHES_ANALYTICS_URL}/evaluated-process-instance-status-count`,
      { params },
    );
  }

  getEvaluatedProcessInstanceCountByDepartment(
    officeIds: string[],
    usersIds: string[],
    start?: Date,
    end?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ): Observable<PaginatedList<EvaluatedProcessInstanceCountByDepartment>> {
    let url = `${RESEARCHES_ANALYTICS_URL}/evaluated-process-instance-count-by-department`;
    let params = new HttpParams({
      fromObject: { officeIds, usersIds },
    })
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (start) params = params.set('startDate', start.toISOString());
    if (end) params = params.append('endDate', end.toISOString());

    return this.http.get<
      PaginatedList<EvaluatedProcessInstanceCountByDepartment>
    >(url, {
      params,
    });
  }

  getCommunicationTimeGraph(
    users: string[],
    offices: string[],
    start?: Date,
    end?: Date,
  ) {
    let params = new HttpParams({
      fromObject: {
        offices,
        users,
      },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<CommunicationVsTime[]>(BI_COMMUNICATION_VS_TIME_URL, {
      params,
    });
  }

  getCommunicationCounts(
    officeIds: string[],
    userIds: string[],
    start?: Date,
    end?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ) {
    let params = new HttpParams({
      fromObject: { officeIds, userIds, pageNumber, pageSize },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<PaginatedList<any>>(BI_COMMUNICATION_COUNT, {
      params,
    });
  }

  getResearchCounts(
    Offices: string[],
    Users: string[],
    start?: Date,
    end?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ) {
    let params = new HttpParams({
      fromObject: { Offices, Users, pageNumber, pageSize },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<PaginatedList<ResearchCount>>(BI_RESEARCH_COUNT, {
      params,
    });
  }

  GetBudgetTimeGraph(
    OfficeIds: string[],
    userIds: string[],
    start?: Date,
    end?: Date,
  ) {
    let params = new HttpParams({
      fromObject: { OfficeIds, userIds },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<BudgetVsTime[]>(BI_BUDGET, {
      params,
    });
  }

  getPublicationData(
    start?: Date,
    end?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<PublicationData[]> {
    let url = `${RESEARCHES_ANALYTICS_URL}/publicationCount`;

    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (start) {
      params = params.set('StartDate', start.toISOString());
    }
    if (end) {
      params = params.set('EndDate', end.toISOString());
    }
    return this.http.get<PublicationData[]>(url, { params });
  }

  getFullLengthResearches(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ): Observable<PaginatedList<Research>> {
    let url = `${RESEARCHES_ANALYTICS_URL}/full-length-researches`;
    let params = new HttpParams({ fromObject: { pageNumber, pageSize } });
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('startDate', startDate.toISOString());
    if (endDate) params = params.append('endDate', endDate.toISOString());
    return this.http.get<PaginatedList<Research>>(url, { params });
  }

  getResearchCommications(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ): Observable<PaginatedList<Communications>> {
    let url = `${RESEARCHES_ANALYTICS_URL}/research-communications`;
    let params = new HttpParams({ fromObject: { pageNumber, pageSize } });
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('startDate', startDate.toISOString());
    if (endDate) params = params.append('endDate', endDate.toISOString());

    return this.http.get<PaginatedList<Communications>>(url, { params });
  }

  getBudgetPerCommunication(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<BudgetPerCommunication[]> {
    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());
    return this.http.get<BudgetPerCommunication[]>(
      `${TOR_ANALYTICS_URL}/budget-per-communication`,
      { params: params },
    );
  }

  getTotalCost(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<number> {
    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());
    return this.http.get<number>(`${TOR_ANALYTICS_URL}/total-cost`, {
      params: params,
    });
  }

  getTotalResearchCost(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<number> {
    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());
    return this.http.get<number>(
      `${TOR_ANALYTICS_URL}/total-cost-per-research`,
      { params: params },
    );
  }

  getTotalCommunincationCost(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<number> {
    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());
    return this.http.get<number>(
      `${TOR_ANALYTICS_URL}/total-cost-per-communication`,
      { params: params },
    );
  }

  getResearchesCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<number> {
    let url = `${RESEARCHES_ANALYTICS_URL}/full-length-research-count`;
    let params = new HttpParams();
    if (startDate) params = params.set('startDate', startDate.toISOString());
    if (endDate) params = params.append('endDate', endDate.toISOString());
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    return this.http.get<number>(url, { params });
  }

  getCommunicationsWithStatus(
    startDate?: Date,
    endDate?: Date,
    offices?: string[],
    users?: string[],
  ): Observable<CommunicationsWithStatus[]> {
    let params = new HttpParams();
    if (offices) {params = params.appendAll({offices})}
    if (users) {params = params.appendAll({users})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());

    return this.http.get<CommunicationsWithStatus[]>(
      `${RESEARCHES_ANALYTICS_URL}/communication-by-status`,
      { params: params },
    );
  }

  getTotalCommunicationsCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ): Observable<number> {
    let url = `${RESEARCHES_ANALYTICS_URL}/total-communications-count`;
    let params = new HttpParams();
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());
    return this.http.get<number>(url, { params });
  }
  getCommunicationTypeInstances(
    officeIds: string[],
    userIds: string[],
    startDate?: Date,
    endDate?: Date,
  ) {
    const params = new HttpParams({ fromObject: { officeIds, userIds } })
      .set('start', startDate?.toISOString() || '')
      .set('end', endDate?.toISOString() || '');
    return this.http.get<CommunicationTypeInstance[]>(
      BI_COMMUNICATION_TYPE_INSTANCES,
      { params },
    );
  }

  getResearchEvaluations(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ): Observable<PaginatedList<ResearchEvaluation>> {
    let url = `${RESEARCHES_ANALYTICS_URL}/research-evaluations`;

    let params = new HttpParams({ fromObject: { pageNumber, pageSize } });
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());

    return this.http.get<PaginatedList<ResearchEvaluation>>(url, { params });
  }

  getTaskAccomplishmentVsTimeData(
    officeIds: string[],
    userIds: string[],
    start?: Date,
    end?: Date,
    year?: number,
  ): Observable<TaskAccomplishmentVsTimeCount[]> {
    let url = `${RESEARCHES_ANALYTICS_URL}/task-accomplishment-count`;
    let params = new HttpParams({
      fromObject: { officeIds, userIds },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    if (year) params = params.append('year', year.toString());

    return this.http.get<TaskAccomplishmentVsTimeCount[]>(url, {
      params,
    });
  }

  getPublishedResearchesVsCategory(
    officeIds: string[],
    userIds: string[],
    start?: Date,
    end?: Date,
  ): Observable<any[]> {
    let params = new HttpParams({
      fromObject: { officeIds, userIds },
    });
    if (start) params = params.set('start', start.toISOString());
    if (end) params = params.append('end', end.toISOString());
    return this.http.get<PublicationCategoriesVsProcessCount[]>(
      `${RESEARCHES_ANALYTICS_URL}/publication-categories-vs-research-count`,
      { params },
    );
  }

  getPublicationCategoriesCount() {
    let url = `${RESEARCHES_ANALYTICS_URL}/total-publication-categories-count`;
    return this.http.get<number>(url);
  }

  getDepartmentVsPublication(
    officeIds?: string[] | null,
    userIds?: string[] | null,
    startDate?: Date | null,
    endDate?: Date | null,
    pageNumber: number = 1,
    pageSize: number = 12,
  ): Observable<PaginatedList<DepartmentVsPublication>> {
    let url = `${RESEARCHES_ANALYTICS_URL}/deparment-vs-publication`;
    let params = new HttpParams({ fromObject: { pageNumber, pageSize } });
    if (officeIds) {params = params.appendAll({officeIds})}
    if (userIds) {params = params.appendAll({userIds})}
    if (startDate) params = params.set('start', startDate.toISOString());
    if (endDate) params = params.append('end', endDate.toISOString());

    return this.http.get<PaginatedList<DepartmentVsPublication>>(url, {
      params,
    });
  }
}
