import { List } from 'echarts';
import { Component } from '@angular/core';
import { QuantityType } from '../../models/quantityType.model';
import { RxState } from '@rx-angular/state';
import { TorConfigurationFacade } from '../../facade/tor-setttings.facade';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigureQuantityTypeComponent } from '../configure-quantity-type/configure-quantity-type.component';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface QuantityTypeListState {
  quantityTypes: QuantityType[];
}

const initActivityTypeListState: QuantityTypeListState = {
  quantityTypes: [],
};

@Component({
  selector: 'app-quantity-types-list',
  templateUrl: './quantity-types-list.component.html',
  styleUrls: ['./quantity-types-list.component.scss'],
})
export class QuantityTypesListComponent {
  quantityTypes$ = this.quantityTypeListState.select('quantityTypes');
  quantityTypes: QuantityType[] = [];

  constructor(
    private quantityTypeListState: RxState<QuantityTypeListState>,
    private torConfigurationFacade: TorConfigurationFacade,
    private saitizerService: SanitizerService,
    private dialog: MatDialog,
  ) {
    this.quantityTypeListState.connect;
    this.quantityTypeListState.set(initActivityTypeListState);
    this.quantityTypeListState.connect(
      'quantityTypes',
      this.torConfigurationFacade.quantityTypes$,
    );
  }

  ngOnInit(): void {
    this.torConfigurationFacade.dispatchGetQuantityTypes();
    this.quantityTypes$.subscribe((types) => {
      this.quantityTypes = types;
    });
  }

  editQuantityType(item: any) {
    this.torConfigurationFacade.dispatchSetQuantityUpdate(item);
    this.dialog.open(ConfigureQuantityTypeComponent,{
      disableClose: true,
    });
  }

  deleteQuantityType(item: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.tor-settings.delete-quantity-type-confirmation-part-1:Are you sure you want to delete the` ,
        boldText: ` "${item.name}" ` ,
        regularTextTwo: $localize`:@@researches.tor-settings.delete-quantity-type-confirmation-part-2:quantity type?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.torConfigurationFacade.dispatchRemoveQuantityType(item.id);
      }
    });
  }

  addQuantityType() {
    this.dialog.open(ConfigureQuantityTypeComponent,{
      disableClose: true,
    });
  }


  hasCreateQuantityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.QuantityType.Feature,
      PERMISSION_NAMES.Researches.QuantityType.CreateQuantityType,
    );
  }
  hasUpdateQuantityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.QuantityType.Feature,
      PERMISSION_NAMES.Researches.QuantityType.UpdateQuantityType,
    );
  }
  hasRemoveQuantityTypePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.QuantityType.Feature,
      PERMISSION_NAMES.Researches.QuantityType.RemoveQuantityType,
    );
  }

  hasCrudPermission(){
    return [
      this.hasUpdateQuantityTypePermission(),
      this.hasRemoveQuantityTypePermission()
    ]
  }
}
