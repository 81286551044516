import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FORGET_PASSWORD_URL,
  USERS_URL,
  RESET_PASSWORD_URL,
  ROLES_URL,
  CHANGE_PASSWORD_URL, LOGIN_HISTORY_URL,
  REGISTRATION_URL
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { CurrentLoggedInUser, UserProfile, User, UserUpdate, UserWithPassword } from '../models/user.model';
import { ForgetPasswordRequest } from '../models/forget-password-request.model';
import { ResetPasswordRequest } from '../models/reset-password-request.model';
import { ChangePasswordRequest } from '../models/change-password-request.model';
import { LoginHistory } from '../models/login-history.model';
import { Module, Permission } from '../models/permission.model';
import { Role } from '../models/role.model';
import { PasswordPolicy } from '../models/password-poilcy.model';
import { EmailSettings } from '../models/email-settings';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  registerUser(user: User): Observable<any> {
    return this.http.post<any>(`${REGISTRATION_URL}`, user, this.httpOptions);
  }

  getUsers(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<User>> {
    let url = USERS_URL;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<User>>(url);
  }

  getUsersByRoleId(
    roleId: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<User>> {
    return this.http.get<PaginatedList<User>>(
      `${ROLES_URL}/${roleId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getUsersByMultipleRoleIds(
    roleIds: string[],
    pageNumber?: number,
    pageSize?: number,
  ): Observable<User[]> {
    const concatRoleIds = roleIds.join(',');

    if (pageNumber === undefined || pageSize === undefined) {

      return this.http.get<User[]>(
        `${USERS_URL}/multiple?roleIds=${concatRoleIds}`
      );
    }
    return this.http.get<User[]>(
      `${USERS_URL}/multiple?roleIds=${concatRoleIds}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getUsersBySearch(
    search: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<User>> {
    return this.http.get<PaginatedList<User>>(
      `${USERS_URL}/search?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  updateUser(user: UserUpdate): Observable<User> {
    return this.http.put<User>(
      `${USERS_URL}`,
      user,
      this.httpOptions,
    );
  }

  updateUserRole(id: string, roleId: string): Observable<any> {
    return this.http.put<any>(
      `${USERS_URL}/${id}/roles/${roleId}`,
      this.httpOptions,
    );
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${USERS_URL}/${id}`, this.httpOptions);
  }

  forgetPassword(email: string): Observable<any> {
    return this.http.post<any>(
      `${FORGET_PASSWORD_URL}?email=${email}`,
      this.httpOptions,
    );
  }

  resetPassword(resetPassword: ResetPasswordRequest): Observable<any> {
    return this.http.post<any>(
      `${RESET_PASSWORD_URL}`,
      resetPassword,
      this.httpOptions,
    );
  }

  getAdmins(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<User>> {
    return this.http.get<PaginatedList<User>>(
      `${USERS_URL}/admin-users?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  toggleStatus(id?: string): Observable<User> {
    return this.http.get<any>(`${USERS_URL}/${id}/activate`, this.httpOptions);
  }

  changePassword(changePassword: ChangePasswordRequest): Observable<any> {
    return this.http.put<any>(
      `${CHANGE_PASSWORD_URL}`,
      changePassword,
      this.httpOptions,
    );
  }

  getUsersByRolesAndOffices(
    roleIds: string[],
    officeIds: string[],
  ): Observable<User[]> {
    return this.http.post<User[]>(
      `${USERS_URL}/users-by-role-and-office`,
      { roleIds, officeIds },
      this.httpOptions,
    );
  }
  getUsersByOfficeId(
    officeId?: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<User[]> {
    let params = new HttpParams();
    if (officeId) {
      params = params.append('officeId', officeId);
    }
    if (pageNumber) {
      params = params.append('pageNumber', pageNumber.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    return this.http.get<User[]>(`${USERS_URL}/office-users`, { params });
  }

  getOfficeUsersByRoleIds(roleIds: string[]): Observable<User[]> {
    const concatRoleIds = roleIds.join(',');
    return this.http.get<User[]>(
      `${USERS_URL}/office-user-by-roles?roleIds=${concatRoleIds}`,
    );
  }

  getLoginHistory(pageNumber: number, pageSize: number) {
    return this.http.get<PaginatedList<LoginHistory>>(LOGIN_HISTORY_URL, {
      params: new HttpParams({ fromObject: { pageNumber, pageSize } }),
    });
  }

  getCurrentLoggedInUser(): Observable<CurrentLoggedInUser> {
    return this.http.get<CurrentLoggedInUser>(
      `${USERS_URL}/current-logged-in-user`,
    );
  }

  assignRevokePermissions(userId: string, permissionIds: string[]) {
    return this.http.put<User>(
      `${USERS_URL}/${userId}/permissions`,
      permissionIds,
      this.httpOptions,
    );
  }

  getPermissionsInheritedFromRoles(userId: string) {
    return this.http.get<Permission[]>(
      `${USERS_URL}/${userId}/permissions-from-roles`,
      this.httpOptions,
    );
  }

  getUserRoles(userId: string) {
    return this.http.get<Role[]>(
      `${USERS_URL}/${userId}/roles`,
      this.httpOptions,
    );
  }

  assignRevokeRoles(roleIds: string[], userId?: string, userIds?: string[]) {
    return this.http.put<Role[]>(
      `${USERS_URL}/assign-roles`,
      {
        userId,
        userIds,
        roleIds,
      },
      this.httpOptions,
    );
  }

  getPermissionsOnlyFromDependency(userId: string) {
    return this.http.get<Permission[]>(
      `${USERS_URL}/${userId}/permissions-only-from-dependency`,
    );
  }

  getUserPermissionModules(userId: string) {
    return this.http.get<Module[]>(
      `${USERS_URL}/${userId}/permissions?module=true`,
      this.httpOptions,
    );
  }

  getUsersDefaultPasswords(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<UserWithPassword>> {
    return this.http.get<PaginatedList<UserWithPassword>>(
      `${USERS_URL}/default-passwords?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getUsersDefaultPasswordsBySearch(
    search: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<UserWithPassword>> {
    return this.http.get<PaginatedList<UserWithPassword>>(
      `${USERS_URL}/default-passwords/search?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  resetDefaultPassword(
    userId: string,
  ): Observable<any> {
    return this.http.get<any>(
      `${USERS_URL}/${userId}/reset-default-password`,
    );
  }

  getPasswordPolicy(): Observable<PasswordPolicy> {
    return this.http.get<PasswordPolicy>(`${USERS_URL}/password-policy`);
  }

  GetEmailSettings() {
    return this.http.get<EmailSettings>(`${USERS_URL}/email-settings`);
  }

  UpdateEmailSettings(settings: EmailSettings) {
    return this.http.put<EmailSettings>(`${USERS_URL}/email-settings`, settings);
  }

  UpdateUserProfile(userProfile:UserProfile) {
    return this.http.put<User>(`${USERS_URL}/profile`, userProfile);
  }
}
