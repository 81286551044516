import {
  PublicationOption,
  PublicationTemplate,
} from '../models/publication-option.model';

export class GetPublicationOptions {
  static readonly type = `[PublicationOption] ${GetPublicationOptions.name}`;
  constructor() {}
}

export class GetEditorTypes {
  static readonly type = `[EditorType] ${GetEditorTypes.name}`;
  constructor() {}
}

export class CreatePublicationOption {
  static readonly type = `PublicationOptions ${CreatePublicationOption.name}`;
  constructor(public publicationOption: any) {}
}

export class UpdatePublicationOption {
  static readonly type = `PublicationOptions ${UpdatePublicationOption.name}`;
  constructor(public publicationOption: any) {}
}

export class DeletePublicationOptions {
  static readonly type = `[EditorType] ${DeletePublicationOptions.name}`;
  constructor(public publicationOption: PublicationOption) {}
}

export class DeletePublicationTemplates {
  static readonly type = `PublicationOptions ${DeletePublicationTemplates.name}`;
  constructor(
    public id: string,
    public templateIds: string[],
  ) {}
}

export class DownloadPublicationTemplate {
  static readonly type = `PublicationTemplates ${DownloadPublicationTemplate.name}`;
  constructor(public template: PublicationTemplate) {}
}

export class IsPublicationOptionBeingUsed{
  static readonly type = `PublicationTemplates ${IsPublicationOptionBeingUsed.name}`;
  constructor(){}
}

export class BanPublicationOptions {
  static readonly type = `[EditorType] ${BanPublicationOptions.name}`;
  constructor(public publicationOption: PublicationOption) {}
}

export class PermitPublicationOptions {
  static readonly type = `[EditorType] ${PermitPublicationOptions.name}`;
  constructor(public publicationOption: PublicationOption) {}
}
