<div class="main">
  <div class="overflow">
    <app-workflow-comment-card
      *ngFor="let workflowComment of workflowComments?.items"
      [workflowComment]="workflowComment"
      (editCommentClicked)="updateForm()"
      (replyCommentClicked)="replyCommentForm()"
      [isArchived]="isArchived"
    ></app-workflow-comment-card>
  </div>
  <div *ngIf="!workflowComments?.items?.length" class="no-comment" i18n="@@documents.workflow-comment.no-comment-yet">
    No comments yet
  </div>
  <div class="comment-form">
    <mat-card
      class="card-edit-color flex-row"
      *ngIf="selectedWorkflowComment && update"
    >
      <mat-card-content class="mat-card-content">
        <mat-icon class="mat-edit-icon">edit</mat-icon>
        <div class="flex-column text-sm">
          <span class="text-comment edit-color" i18n="@@documents.workflow-comment.edit-message">Edit message</span>
          <span class="text-comment ellipsis">{{
            selectedWorkflowComment.comment
          }}</span>
        </div>
        <button
          class="hover-effect close-btn"
          mat-icon-button
          color="primary"
          (click)="cancelComment()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
    <mat-card
      class="card-edit-color flex-row"
      *ngIf="selectedWorkflowComment && reply"
    >
      <mat-card-content class="mat-card-content">
        <mat-icon class="mat-edit-icon">reply</mat-icon>
        <div class="flex-column text-sm">
          <span class="text-comment edit-color"
            ><span i18n="@@documents.workflow-comment.replying-to"> Replying to </span>{{ selectedWorkflowComment.fullName }}
          </span>
          <span class="text-comment ellipsis">{{
            selectedWorkflowComment.comment
          }}</span>
        </div>
        <button
          class="hover-effect close-btn"
          mat-icon-button
          color="primary"
          (click)="cancelComment()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
    <div class="flex-row">
      <form [formGroup]="commentForm" id="comment-form" class="w-full">
        <mat-form-field appearance="outline" class="padding-vert w-full">
          <input
            id="name"
            formControlName="comment"
            type="text"
            #input
            maxlength="1000"
            matInput
            [placeholder]= "placeholderToggleLabel.addCommentField"
            [attr.data-test-id]="'documents.add-comment.workflow-comment.input'"
          />
          <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
        </mat-form-field>
      </form>
      <button
        class="hover-effect h-full ml-2"
        mat-flat-button
        color="primary"
        (click)="addComment()"
        type="submit"
        [disabled]="!commentForm.valid || isArchived"
        [attr.data-test-id]="'documents.add-comment.workflow-comment.send-mat-icon-button'"
      >
        <mat-icon class="text-center send-btn">send</mat-icon>
      </button>
    </div>
  </div>
</div>
