import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, filter } from 'rxjs';
import {
  CURRENCY,
  RESEARCH_OWNER_ROLES,
} from 'src/app/core/constants/requirement_constants';
import {
  ARCHIVED_ROUTE,
  PROCESS_INSTANCE_LIST_ROUTE,
  STAGE_INSTANCES_ROUTE,
} from 'src/app/core/constants/routes';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import {
  ProcessInstance,
  ProcessInstanceDetail,
  ProcessInstanceStatus,
  StageInstance,
} from 'src/app/researches/models/process-instance.model';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { Role } from 'src/app/users/models/role.model';
import { User, CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { ResearchDeadlineComponent } from '../../research-deadline/research-deadline.component';
import {
  PROCESS_FORM_SIDE_DIALOG_CONFIG,
  SIDE_DIALOG_CONFIG,
} from 'src/app/core/constants/dialog_configs';
import { StageInstanceFacade } from 'src/app/researches/facades/stage-instance.facade';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { faUsersLine, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { AddTeamMemberFormComponent } from '../add-team-member-form/add-team-member-form.component';
import { StageInstanceStatus } from 'src/app/researches/models/stage-instance-detail.model';
import { StageStatus } from 'src/app/researches/models/stage.model';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { Localization } from 'src/app/core/localization/localization.class';
import { ENGLISH_LANGUAGE } from 'src/app/core/constants/language';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';

interface RoleState {
  roles: Role[];
}
interface FilteredUsersState {
  filteredUsersByMultipleRoles: User[];
}
interface ProcessInstanceDetailState {
  processInstanceDetail: ProcessInstanceDetail | undefined;
  datetime: string;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initProcessInstanceDetailState: ProcessInstanceDetailState = {
  processInstanceDetail: undefined,
  datetime: '',
  currentLoggedInUser: undefined,
};

const initRoleState: Partial<RoleState> = {
  roles: [],
};

const initFilteredUsersState: Partial<FilteredUsersState> = {
  filteredUsersByMultipleRoles: [],
};

@Component({
  selector: 'app-process-instance-detail',
  templateUrl: './process-instance-detail.component.html',
  styleUrls: ['./process-instance-detail.component.scss'],
})
export class ProcessInstanceDetailComponent implements OnInit {
  processInstanceDetail$ = this.state.select('processInstanceDetail');
  processInstanceDetail: ProcessInstanceDetail | undefined;
  ProcessStatus = ProcessInstanceStatus;

  roles$ = this.roleState.select('roles');
  roles: Role[] = [];

  filteredUsersByMultipleRoles$ = this.userState.select(
    'filteredUsersByMultipleRoles',
  );
  filteredUsersByMultipleRoles: User[] = [];

  assignOwnerClicked: boolean = false;
  toggleButton: string = $localize`:@@researches.process-instance-detail.change-owner: Change Owner`;
  filteredResearchers: User[] = [];

  assignOwnerForm = this.fb.group({
    researchOwner: ['', Validators.required],
  });
  faUsersLine = faUsersLine;
  faPeopleGroup = faPeopleGroup;
  teamMembers?: User[];

  locale = localStorage.getItem('locale') ?? ENGLISH_LANGUAGE.locale;

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  datetime: string = '';
  datetime$ = this.state.select('datetime');

  placeholderToggleLabel = {
    selectResearchers: $localize`:@@researches.process-instance-detail.select-researchers: Select Researchers`,
  };

  @ViewChild('researcherInput') researcherInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private state: RxState<ProcessInstanceDetailState>,
    private userState: RxState<FilteredUsersState>,
    private roleState: RxState<RoleState>,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private stageFacade: StageFacade,
    private route: ActivatedRoute,
    private router: Router,
    private stageInstanceFacade: StageInstanceFacade,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private datetimeFacade: DateTimeFacade,
  ) {
    this.state.set(initProcessInstanceDetailState);
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.userState.set(initFilteredUsersState);
    this.userState.connect(
      'filteredUsersByMultipleRoles',
      this.userFacade.filteredUsersByMultipleRoles$,
    );
    this.roleState.set(initRoleState);
    this.roleState.connect('roles', this.roleFacade.roles$);

    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });

    this.state.connect('datetime', this.datetimeFacade.datetime$);
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  isCurrentUserTeamMemberOrOwner: boolean = false;

  ngOnInit(): void {
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
    });

    this.processInstanceFacade.dispatchResetSelectedProcessInstance();
    this.route.paramMap.subscribe((params) => {
      const processInstanceId = params.get('id');
      if (processInstanceId) {
        this.processInstanceFacade.dispatchGetProcessInstanceDetail(
          processInstanceId,
        );
        this.processInstanceDetail$.subscribe((processInstanceDetail) => {
          this.processInstanceDetail = processInstanceDetail;
        });
      }
    });
    this.filteredUsersByMultipleRoles$.subscribe(
      (filteredUsersByMultipleRoles) => {
        this.filteredUsersByMultipleRoles = filteredUsersByMultipleRoles.filter(
          (user) => user.email != this.processInstanceDetail?.ownerEmail,
        );
      },
    );
    const roleIds = this.roles
      .filter((role) => {
        return RESEARCH_OWNER_ROLES.includes(role.name);
      })
      .map(({ id }) => id);

    this.userFacade.dispatchGetUsersByMultipleRoleIds(roleIds);

    this.assignOwnerForm.controls.researchOwner.valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredResearchers = this.filterUser(query);
      });

    this.processInstanceDetail$.subscribe((processInstanceDetail) => {
      this.processInstanceDetail = processInstanceDetail;
      if (processInstanceDetail?.teamMembers) {
        this.teamMembers = processInstanceDetail?.teamMembers;
        this.filteredResearchers = this.teamMembers.filter(
          (user) => user.email != this.processInstanceDetail?.ownerEmail,
        );
        this.isCurrentUserTeamMemberOrOwner =
          this.teamMembers.some(
            (member) => member.email === this.currentLoggedInUser?.email
          ) || this.processInstanceDetail?.ownerEmail === this.currentLoggedInUser?.email;
      } else {
        this.processInstanceFacade.dispatchGetProcessInstanceTeamMembers(
          processInstanceDetail?.id!,
        );
      }
    });
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
      if (this.teamMembers) {
        this.isCurrentUserTeamMemberOrOwner =
          this.teamMembers.some(
            (member) => member.email === this.currentLoggedInUser?.email
          ) || this.processInstanceDetail?.ownerEmail === this.currentLoggedInUser?.email;
      }
    });
  }

  get scheduleButtonText() {
    if (
      this.processInstanceDetail?.startDate == null &&
      this.processInstanceDetail?.endDate == null
    ) {
      return $localize`:@@researches.process-instance-detail.set-schedule:Set Schedule`;
    }
    return $localize`:@@researches.process-instance-detail.view-schedule:View Schedule`;
  }

  get scheduleMessage() {
    if (this.processInstanceDetail?.startDate == null) {
      return $localize`:@@researches.process-instance-detail.no-schedule:Schedule not set`;
    }
    return `${this.getStartDate} - ${this.getEndDate}`;
  }

  get getStartDate() {
    return (
      this.processInstanceDetail?.startDate &&
      new Date(this.processInstanceDetail?.startDate).toDateString()
    );
  }

  get getEndDate() {
    return (
      this.processInstanceDetail?.endDate &&
      new Date(this.processInstanceDetail?.endDate).toDateString()
    );
  }

  handleCurrencyUnit(curr: string) {
    if (curr === CURRENCY.Etb) return 'ETB';
    return 'USD';
  }

  onBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  enforceSequence(stage: StageInstance) {
    const isSequenceInforced =
      this.processInstanceDetail?.process.enforceSequential;

    if (isSequenceInforced) {
      const previousStage = this.processInstanceDetail?.stageInstances.find(
        (s) => s.stage.order === stage.stage?.order - 1,
      );
      if (previousStage && previousStage.stageStatus !== 'Approved') {
        return true;
      }
    }
    return false;
  }

  selectedStage(stageIntanceId: string, stage: StageInstance) {
    this.processInstanceDetail?.isArchived
      ? this.router.navigate([
          `${ARCHIVED_ROUTE}/${STAGE_INSTANCES_ROUTE}`,
          stageIntanceId,
        ])
      : this.router.navigate([
          `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}`,
          stageIntanceId,
        ]);
    this.stageInstanceFacade.dispatchSetSelectedStageInstance(stage);
  }

  checkProcessInstanceStageStatus(stage: StageInstance) {
    const status = stage.stageStatus;
    if (status == StageInstanceStatus.ReadyToStart) {
      if (this.processInstanceDetail?.process.enforceSequential) {
        const previousStage = this.processInstanceDetail?.stageInstances.find(
          (s) => s.stage.order === stage.stage?.order - 1,
        );
        if (
          previousStage &&
          previousStage.stageStatus != StageInstanceStatus.Approved
        )
          return $localize`:@@researches.process-instance-detail.stage-approval-criteria:This stage will be active if the previous stage is approved.`;
        return $localize`:@@researches.process-instance-detail.status-ready-to-start:Ready To Start`;
      }
      return $localize`:@@researches.process-instance-detail.status-ready-to-start:Ready To Start`;
    } else if (status == StageInstanceStatus.Inprogress) {
      return $localize`:@@researches.process-instance-detail.status-in-progress:In Progress`;
    } else if (status == StageInstanceStatus.WaitingForApproval) {
      return $localize`:@@researches.process-instance-detail.status-waiting-for-approval:Waiting For Approval`;
    } else if (status == StageInstanceStatus.NeedsRevision) {
      return $localize`:@@researches.process-instance-detail.status-needs-revision:Needs Revision`;
    } else if (status == StageInstanceStatus.SubmittedToEvaluation) {
      return $localize`:@@researches.process-instance-detail.status-submitted-for-evaluation:Submitted For Evaluation`;
    } else if (status == StageInstanceStatus.Terminated) {
      return $localize`:@@researches.process-instance-detail.status-terminated:Terminated`;
    } else if (status == StageInstanceStatus.Rejected) {
      return $localize`:@@researches.process-instance-detail.status-rejected:Rejected`;
    } else if (status == StageInstanceStatus.Approved) {
      return $localize`:@@researches.process-instance-detail.status-approved:Approved`;
    }
    return $localize`:@@researches.process-instance-detail.status-ready-to-start: Ready To Start`;
  }

  getProcessInstanceStageStatusColor(status: any) {
    if (status == StageInstanceStatus.ReadyToStart) {
      return 'gray';
    } else if (status == StageInstanceStatus.Inprogress) {
      return 'yellow';
    } else if (status == StageInstanceStatus.WaitingForApproval) {
      return 'cyan';
    } else if (status == StageInstanceStatus.Approved) {
      return 'green';
    } else if (status == StageInstanceStatus.Rejected) {
      return 'red';
    } else if (status == StageInstanceStatus.SubmittedToEvaluation) {
      return 'purple';
    } else if (status == StageInstanceStatus.NeedsRevision) {
      return 'orange';
    } else {
      return 'gray';
    }
  }

  assignOwner() {
    this.assignOwnerClicked = !this.assignOwnerClicked;
    if (this.assignOwnerClicked)
      this.toggleButton = $localize`:@@researches.process-instance-detail.cancel: Cancel`;
    else
      this.toggleButton = $localize`:@@researches.process-instance-detail.change-owner:Change Owner`;
  }

  selectResearcher(event: MatAutocompleteSelectedEvent) {
    this.processInstanceFacade.dispatchAssignOwnerToResearchInstance(
      this.processInstanceDetail?.id!,
      event.option.value.id,
    );
    this.assignOwnerForm.controls.researchOwner.setValue('');
    this.assignOwnerClicked = false;
    this.toggleButton = $localize`:@@researches.process-instance-detail.change-owner:Change Owner`;
  }

  private filterUser(query: string | null) {
    const members = this.teamMembers;
    if (!members) return [];
    return members.filter((user) =>
      `${user.firstName} ${user.middleName} ${user.lastName}`
        .toLowerCase()
        .includes(query?.toLocaleLowerCase() || ''),
    );
  }

  submitProcessInstanceSchedule() {
    this.dialog.open(ResearchDeadlineComponent, {
      data: {
        update: false,
        view:
          this.processInstanceDetail?.startDate != null &&
          this.processInstanceDetail?.endDate != null,
      },
      disableClose: true,
    });
  }
  selectStageInstance(event: MouseEvent, stageInstance: StageInstance) {
    this.stageInstanceFacade.dispatchSetSelectedStageInstance(stageInstance);
    event.stopPropagation();
  }
  updateDeadline() {
    this.dialog.open(ResearchDeadlineComponent, {
      data: {
        update: true,
      },
      disableClose: true,
    });
  }

  hasBudget(stageInstance: StageInstance) {
    if (stageInstance.allocatedBudget) return true;
    return false;
  }

  onAddMemberClicked() {
    this.dialog.open(
      AddTeamMemberFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  startStageInstance(stageInstance: StageInstance) {
    this.stageInstanceDetailFacade.dispatchChangeStageStatus({
      id: stageInstance.id,
      stageStatus: StageInstanceStatus.Inprogress,
    });
    this.router.navigate([
      `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}`,
      stageInstance.id,
    ]);
  }

  isStartButtonVisible(stage: StageInstance) {
    if (stage.stageStatus != StageInstanceStatus.ReadyToStart) return false;
    if (this.enforceSequence(stage)) {
      return false;
    }

    return true;
  }

  getReadableActualDuration(stage?: StageInstance) {
    let duration = '';
    if (stage) {
      if (
        [StageInstanceStatus.Approved, StageStatus.Terminated].includes(
          stage.stageStatus,
        ) &&
        stage.actualStartDate &&
        stage.actualEndDate
      ) {
        let startDate = new Date(stage.actualStartDate);
        let endDate = new Date(stage.actualEndDate);
        duration = `Start Date: ${startDate.toDateString()} - End Date: ${endDate.toDateString()}`;
      }
      if (
        stage.actualStartDate &&
        [
          StageInstanceStatus.Inprogress,
          StageInstanceStatus.ReadyToStart,
          StageInstanceStatus.NeedsRevision,
          StageInstanceStatus.Rejected,
          StageInstanceStatus.WaitingForApproval,
          StageInstanceStatus.SubmittedToEvaluation,
        ].includes(stage.stageStatus)
      ) {
        let startDate = new Date(stage.actualStartDate);

        if (stage.stage.approximateDurationInWeeks) {
          let expectedEndDate = new Date(stage.actualStartDate);
          expectedEndDate = new Date(
            expectedEndDate.setDate(
              expectedEndDate.getDate() +
                stage.stage.approximateDurationInWeeks * 7,
            ),
          );

          if (new Date(this.datetime) < expectedEndDate) {
            duration = `Start Date: ${startDate.toDateString()} (Expected End Date: ${expectedEndDate.toDateString()})`;
          } else {
            duration = `Start Date: ${startDate.toDateString()} (Expected End Date: ${expectedEndDate.toDateString()} - ${(
              (new Date(this.datetime).getTime() - expectedEndDate.getTime()) /
              (1000 * 60 * 60 * 24)
            ).toFixed()} days already passed. )`;
          }
        }
      }

      if (stage.actualStartDate && stage.actualEndDate) {
        const startDate = new Date(stage.actualStartDate);
        const endDate = new Date(stage.actualEndDate);
        duration = `Start Date: ${startDate.toDateString()} - End Date: ${endDate.toDateString()}`;
      }
    }

    return duration;
  }

  getReadablePlannedDuration(stage?: StageInstance) {
    let duration = '';
    if (stage && stage.startDate) {
      duration = `Start Date: ${new Date(stage.startDate).toDateString()}`;
    }
    if (stage && stage.endDate) {
      duration += ` - End Date: ${new Date(stage.endDate).toDateString()}`;
    }

    return duration;
  }

  checkAllStagesAreReadyToStart() {
    return this.processInstanceDetail?.stageInstances.every(
      (stageInstance) =>
        stageInstance.stageStatus == StageInstanceStatus.ReadyToStart,
    );
  }

  DownloadStage(stageInstance?: StageInstance | undefined) {
    if (!stageInstance) return;
    if (!stageInstance.id) return;
    this.stageInstanceDetailFacade.dispatchDownloadStage(stageInstance.id);
  }

  checkProcessInstanceStatus(status: ProcessInstanceStatus) {
    if (
      status == ProcessInstanceStatus.ReadyToStart &&
      this.checkAllStagesAreReadyToStart()
    ) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-ready-to-start:Ready To Start`;
    } else if (status == ProcessInstanceStatus.ReadyToStart) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-ready-to-start-next-stage:Ready To Start Next Stage`;
    } else if (status == ProcessInstanceStatus.Inprogress) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-in-progress:In Progress`;
    } else if (status == ProcessInstanceStatus.Terminated) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-terminated:Terminated`;
    } else if (status == ProcessInstanceStatus.Rejected) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-rejected:Rejected`;
    } else if (status == ProcessInstanceStatus.WaitingForApproval) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-waiting-for-approval:Waiting For Approval`;
    } else if (status == ProcessInstanceStatus.SubmittedToEvaluation) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-submitted-to-evaluation:Submitted To Evaluation`;
    } else if (status == ProcessInstanceStatus.Completed) {
      return $localize`:@@researches.process-instance-detail.process-instance-status-completed:Completed`;
    } else {
      return status;
    }
  }

  getProcessInstanceStatusColor(status: ProcessInstanceStatus) {
    if (status == ProcessInstanceStatus.ReadyToStart) {
      return 'gray';
    } else if (status == ProcessInstanceStatus.Inprogress) {
      return 'yellow';
    } else if (
      status == ProcessInstanceStatus.Rejected ||
      status == ProcessInstanceStatus.Terminated
    ) {
      return 'red';
    } else if (
      status == ProcessInstanceStatus.SubmittedToEvaluation ||
      status == ProcessInstanceStatus.WaitingForApproval
    ) {
      return 'primary';
    } else if (status == ProcessInstanceStatus.Completed) {
      return 'green';
    } else {
      return 'gray';
    }
  }

  hasAddTeamMembersPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.AddTeamMembers,
    );
  }

  hasSubmitProcessInstanceSchedulePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.SubmitProcessInstanceSchedule,
    );
  }

  hasUpdateProcessInstanceSchedulePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.UpdateProcessInstanceSchedule,
    );
  }
  hasDownloadStagePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.DownloadStageInstance,
    );
  }

  hasAssignOwnerToProcessInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.AssignOwnerToProcessInstance,
    );
  }

  hasGetStageInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetStageInstances,
    );
  }

  hasUpdateStatusToInprogressPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.UpdateStatusToInprogress,
    );
  }
}
