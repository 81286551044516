<h1 mat-dialog-title>
  {{ data.status }}
  <span i18n="@@researches.criteria-form.criteria">Criteria</span>
</h1>
<mat-dialog-content
  mat-dialog-content
  class="form-container"
  *appHasPermission="hasUpdateCriteriaPermission()"
>
  <div style="padding: 2%">
    <form class="criteria-form" [formGroup]="criteriaUpdateForm">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label i18n="@@researches.criteria-form.criteria-name"
          >Criterion Name</mat-label
        >
        <input
          id="name"
          [placeholder]="placeholderToggleLabel.enterCriteronName"
          formControlName="name"
          matInput
          class="form-input"
        />
        <mat-error
          *ngIf="criteriaUpdateForm.controls['name'].errors?.['required']"
          i18n="
            @@researches.criteria-update-form.please-provide-name-for-the-weight"
        >
          Please provide a name for the weight.
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline" class="form-field">
        <mat-label i18n="@@researches.criteria-update-form.weight"
          >Weight</mat-label
        >
        <input
          id="weight"
          class="form-input"
          type="number"
          [placeholder]="placeholderToggleLabel.enterWeight"
          formControlName="weight"
          matInput
          min="0"
        />
        <mat-error
          *ngIf="
            criteriaUpdateForm.controls['weight'].errors?.[
              'exceededCriteriaSum'
            ]
          "
          i18n="
            @@researches.criteria-update-form.criteria-sum-cannot-be-greater-than-hundered"
        >
          Total Criteria sum cannot be greater than 100.
        </mat-error>
        <mat-error
          *ngIf="criteriaUpdateForm.controls['weight'].errors?.['required']"
          i18n="
            @@researches.criteria-update-form.please-provide-weight-for-the-criteria"
        >
          Please provide a weight for the criteria.
        </mat-error>
        <mat-error
          *ngIf="criteriaUpdateForm.controls['weight'].errors?.['zeroWeight']"
          i18n="@@researches.criteria-update-form.please-provide-a-non-zero-weight-value"
        >
          Criteria with zero weight is not accepted.
        </mat-error>
        <mat-error
          *ngIf="criteriaUpdateForm.controls['weight'].value < 0"
          i18n="
            @@researches.criteria-update-form.please-provide-a-positive-weight-value"
        >
          Please provide a positive weight value.
        </mat-error>
      </mat-form-field>
    </form>

    <div mat-dialog-actions class="action-btn">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!criteriaUpdateForm.valid"
        (click)="saveCriteria()"
      >
        {{ data.status }}
        <span i18n="@@researches.criteria-form.criteria"      
        [attr.data-test-id]="'researches.create-criteria.criteria-update-form.create-and-update-criteria-button'"
        >criteria</span>
      </button>

      <button
        mat-flat-button
        (click)="cancel()"
        i18n="@@researches.criteria-form.cancel"
      >
        Cancel
      </button>
    </div>
  </div>
</mat-dialog-content>
