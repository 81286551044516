<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="center"
  *appHasPermission="hasGetStageInstancesByReviewersPermission()"
>
  <mat-tab [label]="assignedreviews">
    <div class="container">
      <div class="flex justify-between mx-2">
        <h1
          class="mat-h1"
          i18n="@@researches.requested-reviews.review-requested"
        >
          Review Requested
        </h1>
        <mat-paginator
          [length]="assignedStageInstances?.totalCount"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 20, 100]"
          [showFirstLastButtons]="true"
          (page)="loadPaginatedStageInstances($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div
      *ngFor="let stageInstance of assignedStageInstances?.items"
      class="container"
    >
      <mat-card class="task-card">
        <mat-card-header>
          <div class="card-header">
            <mat-card-title >{{
              stageInstance.processInstance.title
            }}</mat-card-title>
            <mat-card-subtitle>: {{ stageInstance.name }}</mat-card-subtitle>
            <div
              class="chip mat-caption"
              [ngClass]="{
                'text-green': stageInstance.isReviewed,
                'text-red': !stageInstance.isReviewed
              }"
            >
              <span class="mat-caption">
                {{ stageInstance.isReviewed ? "Reviewed" : "Not Reviewed" }}
              </span>
            </div>
          </div>
          <button
            class="review-button"
            mat-stroked-button
            style="margin-left: auto; display: block;"
            color="primary"
            (click)="onRowClick($event, stageInstance)"
            i18n="@@researches.requested-reviews.review"
            [attr.data-test-id]="'researches.add-review.requested-reviews.'+ stageInstance.processInstance.title+'-review-button'"
          >
            Review
          </button>
        </mat-card-header>

        <mat-card-content />
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab [label]="recievedreviews">
    <app-recieved-reviews></app-recieved-reviews>
  </mat-tab>
</mat-tab-group>
