import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowTypeAverageTimeState,
  WorkflowTypeAverageTimeStateModel,
} from './workflow-step-average-time.state';

export class WorkflowTypeAverageTimeSelector {
  static slices = createPropertySelectors<WorkflowTypeAverageTimeStateModel>(
    WorkflowTypeAverageTimeState,
  );

  @Selector([WorkflowTypeAverageTimeState])
  static workflowTypeStepAverageTimeReport(
    stateModel: WorkflowTypeAverageTimeStateModel,
  ) {
    return stateModel.workflowTypeStepTimes;
  }
}
