import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';
import {
  AbstractControl,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { combineLatest, debounceTime, filter, of, startWith, tap } from 'rxjs';
import { ProcessDetailFacade } from 'src/app/researches/facades/process.detail.facades';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import { Criterion } from 'src/app/researches/models/criterion.model';
import { Process } from 'src/app/researches/models/process.model';
import { Stage } from 'src/app/researches/models/stage.model';
import { errorStyle } from 'src/app/core/services/operation-status/status-style-names';
import { ProcessDetail } from 'src/app/researches/models/process-detail.model';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';

interface StageFormState {
  process?: Process;
  selectedStage: Partial<Stage> | null;
  criteria: Criterion[];
  currentProcess?: ProcessDetail;
  criteriaSum: number;
}

const initialStageFormState: StageFormState = {
  selectedStage: {},
  criteria: [],
  criteriaSum: 0,
};

@Component({
  selector: 'app-stage-form',
  templateUrl: './stage-form.component.html',
  styleUrls: ['./stage-form.component.scss'],
})
export class StageFormComponent implements OnInit {
  hasEvaluation: boolean = false;
  hasBudget: boolean = false;
  numberOfWeeks = Array(52)
    .fill(1)
    .map((x, i) => i + 1);

  addedCriteria$ = this.state.select('criteria');
  addedCriteria: Criterion[] = [];

  totalCriteriaSum: number = 0;
  isValidCriteriaSum = true;
  criteriaSum: number = 0;
  criteriaSum$ = this.state.select('criteriaSum');
  currentStage: Partial<Stage> | null = {};
  selectedStage$ = this.state.select('selectedStage');

  stageForm = this.fb.group({
    hasEvaluation: [false],
    hasBudget: [false],
    name: ['', Validators.required],
    description: ['', Validators.required],
    quorum: [
      75,
      [Validators.min(1), Validators.max(100), Validators.pattern('^[0-9]*$')],
    ],

    score: [
      0.5,
      [Validators.min(0.0001), Validators.max(1)],
    ],  
    
    criteriaSum: [0, [Validators.min(0), Validators.max(100)]],

    approximateDurationInWeeks: [4, [Validators.min(1), Validators.max(52)]],
  });

  placeholderToggleLabel = {
      stageName: $localize`:@@researches.stage-form.stage-name: Stage name`,
      stageProposalDescription: $localize`:@@researches.stage-form.this-is-description-for-the-proposal-stage: This is description for the proposal stage.`,
      ex10: $localize`:@@researches.stage-form.ex-10: Ex. 10 %`,
      exScore:$localize`:@@researches.stage-form.ex-score: Ex. 0.5`,
  }
  constructor(
    private fb: NonNullableFormBuilder,
    private stageFacade: StageFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isUpdate: boolean;
    },
    private processFacade: ProcessFacade,
    private processDetailFacade: ProcessDetailFacade,
    private dialog: MatDialog,
    private operationStatusService: OperationStatusService,
    public state: RxState<StageFormState>,
    public sanitizerService: SanitizerService
  ) {
    this.state.set(initialStageFormState);
    this.state.connect('process', this.processFacade.selectedProcess$);
    this.state.connect('selectedStage', stageFacade.selectedStage$);
    this.state.connect('criteriaSum', stageFacade.criteriaSum$);

    this.criteriaSum$.subscribe((criteriaSum) => {
      this.criteriaSum = criteriaSum;
      this.stageForm.controls.criteriaSum.patchValue(criteriaSum);
    });
    this.selectedStage$.subscribe((selectedStage: any | null) => {
      this.currentStage = selectedStage;
      if (selectedStage !== null)
        this.totalCriteriaSum = selectedStage.criteriaSum;
    });
    if (this.currentStage?.id) {
      this.stageForm = this.fb.group({
        hasEvaluation: [this.currentStage?.hasEvaluation || false],
        hasBudget: [this.currentStage?.hasBudget || false],
        name: [this.currentStage?.name || '', Validators.required],
        description: [
          this.currentStage?.description || '',
          Validators.required,
        ],
        quorum: [
          this.currentStage?.quorum ?? 1,
          Validators.pattern('^[0-9]*$'),
        ],
        score: [
          this.currentStage?.score ?? 1,
          [Validators.min(0),Validators.max(1)]
        ],
        criteriaSum: [
          this.criteriaSum,
          [Validators.required, Validators.min(0)],
        ],
        approximateDurationInWeeks: [
          Number(this.currentStage?.approximateDurationInWeeks) ?? 1,
          [Validators.min(1), Validators.max(52)],
        ],
      });
    }

    this.state.connect('criteria', this.stageFacade.criteria$);
    this.state.connect(
      'currentProcess',
      this.processDetailFacade.selectedProcessDetail$,
    );
  }

  ngOnInit(): void {
    this.stageFacade.dispatchResetCriteria();
    this.stageFacade.dispatchResetSelectedCriterion();
    this.addedCriteria$.subscribe((criteria) => {
      this.addedCriteria = criteria;
      this.isValidCriteriaSum =
        this.stageForm.value.criteriaSum! >= this.totalCriteriaSum;
    });

    this.stageForm.controls.name.valueChanges
    .pipe(debounceTime(1000))
    .subscribe((_) => {
      const { process } = this.state.get();
      if (process?.id) {
        this.processDetailFacade.dispatchGetProcessDetail(process.id);
      }
    });


    combineLatest([
      this.state.select('currentProcess'),
      this.stageForm.controls.name.valueChanges.pipe(debounceTime(1000)),
    ]).subscribe(([process, stageName]) => {
      const duplicatedStage = process?.stages.find(
        (stage) => stage.name == stageName.trim(),
      );
      const errors = duplicatedStage ? { stageNameDuplicated: true } : null;
      this.stageForm.controls.name.setErrors(errors);
    });

    if (this.currentStage != null) {
      this.stageFacade.dispatchGetStageCriteria(
        this.currentStage?.id as string,
      );
    }
  }

  submit() {
    const { process } = this.state.get();
    if (this.stageForm.valid && process) {
      let {
        name,
        description,
        hasEvaluation,
        quorum,
        criteriaSum,
        hasBudget,
        approximateDurationInWeeks,
        score
      } = this.stageForm.value as Stage;
      if (this.totalCriteriaSum > criteriaSum) {
        this.operationStatusService.displayStatus(
          $localize`:@@researches.stage-form.criteria-saving-failed: Saving Failed! Provide Criteria Sum greater than Sum of Crtieria Weight`,
          errorStyle,
        );
        this.isValidCriteriaSum = false;
        return;
      }
      name = name.toString().trim()
      const formData = {
        name,
        description,
        quorum,
        criteriaSum,
        hasBudget,
        hasEvaluation,
        processId: process.id!,
        criteria: this.addedCriteria,
        approximateDurationInWeeks,
        score
      };

      if (this.currentStage != null) {
        this.stageFacade.dispatchUpdateStage({
          id: this.currentStage!.id!,
          ...formData,
        });
      } else this.stageFacade.dispatchCreateStage(formData);
      this.dialog.closeAll();
    }
  }

  hasCreateCriteriaPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.CreateCriteria,
    );
  }
}
