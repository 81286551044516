<div class="change-password-form">
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword($event)">
    <mat-card>
      <mat-card-header>
        <mat-card-title i18n="@@users.change-password.change-password-form"
          >Change Password Form</mat-card-title
        >
      </mat-card-header>
      <mat-card-content class="change-password-fields">
        <mat-form-field appearance="outline">
          <mat-label>Current password</mat-label>
          <input
            id="currentPassword"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            formControlName="currentPassword"
            placeholder="Enter current password"
            matInput
            [attr.data-test-id]="'users.update-password.change-password.input-for-current-password'"
          />
          <mat-icon matSuffix (click)="changeOldPasswordVisibility()">{{
            hideCurrentPassword ? "visibility" : "visibility_off"
          }}</mat-icon>

          <mat-error
            *ngIf="
              changePasswordForm.get('currentPassword')?.hasError('required')
            "
          >
            Current password is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>New password</mat-label>
          <input
            id="newPassword"
            [type]="hideNewPassword ? 'password' : 'text'"
            formControlName="newPassword"
            [placeholder]="
              changePasswordForm.get('currentPassword')?.invalid
                ? 'Please fill the field above'
                : 'Enter new password'
            "
            matInput
            [readonly]="changePasswordForm.get('currentPassword')?.invalid"
            [attr.data-test-id]="'users.update-password.change-password.input-for-new-password'"
          />
          <mat-icon matSuffix (click)="changeNewPasswordVisibility()">{{
            hideNewPassword ? "visibility" : "visibility_off"
          }}</mat-icon>

          <mat-error
            *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')"
          >
            Password must be at least 8 characters long.
          </mat-error>

          <mat-error
            *ngIf="
              changePasswordForm.get('newPassword')?.hasError('pattern') &&
              !changePasswordForm.get('newPassword')?.hasError('minlength')
            "
          >
            Password must contain at least one uppercase, one lowercase, one
            digit and one special character.
          </mat-error>

          <mat-error
            *ngIf="
              changePasswordForm.get('newPassword')?.hasError('required') &&
              !changePasswordForm.get('currentPassword')?.invalid
            "
          >
            New password is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm password</mat-label>
          <input
            id="confirmPassword"
            type="password"
            formControlName="confirmPassword"
            [placeholder]="
              changePasswordForm.get('currentPassword')?.invalid
                ? 'Please fill the field above'
                : 'Confirm new password'
            "
            matInput
            [readonly]="changePasswordForm.get('newPassword')?.invalid"
            [attr.data-test-id]="'users.update-password.change-password.input-for-confirm-new-password'"
          />

          <mat-error
            *ngIf="
              changePasswordForm
                .get('confirmPassword')
                ?.hasError('confirmPasswordDoNotMatch')
            "
          >
            Password Confirmation Mismatch
          </mat-error>

          <mat-error
            *ngIf="
              changePasswordForm.get('confirmPassword')?.hasError('required') &&
              !changePasswordForm.get('currentPassword')?.invalid &&
                !changePasswordForm.get('newPassword')?.invalid
            "
          >
            Confirm password is required
          </mat-error>
        </mat-form-field>
        <button
          class="submit"
          mat-flat-button
          color="primary"
          [disabled]="!changePasswordForm.valid"
          type="submit"
          i18n="@@users.change-password.change-password"
        >
          Change Password
        </button>
      </mat-card-content>
    </mat-card>
  </form>
</div>
