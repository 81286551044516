import { Component, OnInit } from '@angular/core';
import {
  AFileDto,
  ExternalDocumentDetailDto,
  RemarkActionStatusForDisplay,
  RemarkStatus,
  ResponseStatus,
} from '../../models/external-document.model';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { ExternalDocumentFacade } from '../../facades/external-document-workflow.facade';
import { MatDialog } from '@angular/material/dialog';
import { ExternalDocumentRemarkFormComponent } from '../external-document-remark-form/external-document-remark-form.component';
import { ExternalDocumentResponseFormComponent } from '../../external-document-response-form/external-document-response-form.component';
import {
  EXTERNAL_DOCUMENT_RESPONSE_ROUTE,
} from 'src/app/core/constants/routes';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from "../../../../users/models/user.model";
import { UserFacade } from "../../../../users/facade/user.facade";

interface ExternalDocumentDetailComponentState {
  selectedExternalDocument: ExternalDocumentDetailDto | undefined;
  remarkActionStatusForDisplay: RemarkActionStatusForDisplay[];
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initExternalDocumentDetailComponentState: ExternalDocumentDetailComponentState =
  {
    currentLoggedInUser: undefined,
    selectedExternalDocument: undefined,
    remarkActionStatusForDisplay: [],
  };

@Component({
  selector: 'app-external-document-detail',
  templateUrl: './external-document-detail.component.html',
  styleUrls: ['./external-document-detail.component.scss'],
})
export class ExternalDocumentDetailComponent implements OnInit {
  selectedExternalDocumentDetail: ExternalDocumentDetailDto | undefined;
  selectedExternalDocumentDetail$= this.state.select('selectedExternalDocument');

  currentLoggedInUser: CurrentLoggedInUser | undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  responseStatus = ResponseStatus;
  remarkStatusEnum = RemarkStatus;
  hasReceivedRemarkStatus: boolean = false;
  remarkActionStatusForDisplay: RemarkActionStatusForDisplay[] = [];
  remarkActionStatusForDisplay$ = this.state.select(
    'remarkActionStatusForDisplay',
  );

  constructor(
    private state: RxState<ExternalDocumentDetailComponentState>,
    private externalDocumentFacade: ExternalDocumentFacade,
    private userFacade: UserFacade,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.state.set(initExternalDocumentDetailComponentState);
    this.state.connect(
      'selectedExternalDocument',
      this.externalDocumentFacade.selectedExternalDocument$,
    );
    this.state.connect('currentLoggedInUser', this.userFacade.currentLoggedInUser$);
    this.state.connect(
      'remarkActionStatusForDisplay',
      this.externalDocumentFacade.remarkActionForDisplay$,
    );

    this.currentLoggedInUser$.subscribe((currUser)=>{
      this.currentLoggedInUser = currUser;
    })
    this.selectedExternalDocumentDetail$.subscribe((externalDocument)=>{
      if(externalDocument){
        this.selectedExternalDocumentDetail = externalDocument
        this.checkForReceivedRemark();
    }
    })
    this.externalDocumentFacade.dispatchGetRemarkActionStatus();
    this.remarkActionStatusForDisplay$.subscribe((remarkActions) => {
      this.remarkActionStatusForDisplay = remarkActions;
    });
  }

  checkForReceivedRemark(): void {
    if (this.selectedExternalDocumentDetail?.remarkActions) {
      this.hasReceivedRemarkStatus = this.selectedExternalDocumentDetail.remarkActions.some(
        (remark: any) => remark.remarkStatus === this.remarkStatusEnum.Received
      );
    }
  }

  handleStatus(status: string) {
    if (!this.selectedExternalDocumentDetail || !status) return;
    const st = RemarkStatus[status as keyof typeof RemarkStatus];
    this.dialog.open(ExternalDocumentRemarkFormComponent, {
      data: {
        status: st,
        externalDocumentId: this.selectedExternalDocumentDetail.id,
      },
    });
  }

  getDateFromString(strDate?: string) {
    if (!strDate) return;
    return new Date(strDate).toUTCString();
  }

  viewRemarkStatus(remarkStatus:string){
    switch (remarkStatus){
      case "Approved" :
        return "Approve";
      case "Rejected":
        return "Reject";
      case "RollBacked":
        return "RollBack";
      case "Received":
        return "Receive";
      default:
        return remarkStatus;
    }
  }

  showActionButton(){
    const lastRemarkAction = this.selectedExternalDocumentDetail?.remarkActions[this.selectedExternalDocumentDetail?.remarkActions.length - 1];
    return lastRemarkAction?.toOfficeId === this.currentLoggedInUser?.officeId
  }

  openResponseForm() {
    if (!this.selectedExternalDocumentDetail) return;

    this.externalDocumentFacade.dispatchResetExternalDocumentResponseDetail();
    this.router.navigate([
      `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}`, ""]);

  }

  downloadAttachment(file: AFileDto) {
    this.externalDocumentFacade.dispatchDownloadAttachment(
      this.selectedExternalDocumentDetail?.id as string,
      file.id,
      file.name,
    );
  }
  previewAttachment(file: AFileDto) {
    this.externalDocumentFacade.dispatchPreviewAttachment(
      this.selectedExternalDocumentDetail?.id as string,
      file.id,
      file.name,
    );
  }

  hasRespondToExternalDocumentPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.ExternalDocument.Feature,
      PERMISSION_NAMES.Documents.ExternalDocument.RespondToExternalDocument,
    );
  }
}
