import { Component, OnInit } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { UserFacade } from '../../facade/user.facade';
import { RxState } from '@rx-angular/state';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';

interface State {
  paginationState: { pageNumber: number; pageSize: number };
  loginHistories?: PaginatedList<any>;
}

const initialState: State = {
  paginationState: { pageNumber: 1, pageSize: 10 },
};

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrl: './login-history.component.scss',
  providers: [{provide: MatPaginatorIntl, useClass: LoginHistoryComponent}],
})
export class LoginHistoryComponent implements OnInit, MatPaginatorIntl {
  loginHistories$ = this.state.select('loginHistories');

  displayedColumns = ['loggedInAt', 'browser', 'platform']

  firstPageLabel = $localize`:@@users.login-history.first-page: First page`;
  itemsPerPageLabel = $localize`:@@users.login-history.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@users.login-history.last-page: Last page`;

  nextPageLabel = $localize`:@@users.login-history.next-page:  Next page`;
  previousPageLabel = $localize`:@@users.login-history.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@users.login-history.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@users.login-history.page-part-one: Page` + `${page + 1}` + $localize`:@@users.login-history.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    public state: RxState<State>,
    private userFacade: UserFacade,
  ) {
    this.state.set(initialState);
    this.state.connect('loginHistories', this.userFacade.loginHistories$);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.userFacade.dispatchLoginHistory(1, 10);
  }

  ngAfterViewInit(): void {
    this.state.select('paginationState').subscribe((val) => {
      this.userFacade.dispatchLoginHistory(val.pageNumber, val.pageSize);
    });
  }

  paginateLoginHistory(event: PageEvent) {
    this.state.set({
      paginationState: {
        pageSize: event.pageSize,
        pageNumber: event.pageIndex + 1,
      },
    });
  }
}
