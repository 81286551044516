import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { init } from 'echarts';
import Quill from 'quill';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Observable, Subject } from 'rxjs';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { StageInstanceFacade } from 'src/app/researches/facades/stage-instance.facade';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import {
  StageInstanceByReviewers,
  StageInstanceReview,
  ReviewStatus,
} from 'src/app/researches/models/stage-instance-detail.model';
import { StageInstanceReviewerType } from 'src/app/researches/models/stage.model';
import { TaskDocument } from 'src/app/researches/models/task-document.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';

interface ReviewDetailComponentState {
  assignedTaskDocuments: PaginatedList<TaskDocument> | undefined;
  selectedAssignedStageInstance: StageInstanceByReviewers | undefined;
  videoBlob: Blob | null;
  audioBlob: Blob | null;
  myStageInstancesReview: StageInstanceReview | undefined;
  stageInstanceReviewerType: StageInstanceReviewerType | undefined;
}
const initReviewDetailComponentState: ReviewDetailComponentState = {
  assignedTaskDocuments: undefined,
  selectedAssignedStageInstance: undefined,
  audioBlob: null,
  videoBlob: null,
  myStageInstancesReview: undefined,
  stageInstanceReviewerType: undefined,
};

@Component({
  selector: 'app-review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: ReviewDetailComponent}],
})
export class ReviewDetailComponent implements MatPaginatorIntl {
  dataSource!: MatTableDataSource<TaskDocument>;
  assignedTaskDocuments$ = this.state.select('assignedTaskDocuments');
  assignedTaskDocuments: PaginatedList<TaskDocument> | undefined;
  selectedAssignedStageInstance$ = this.state.select(
    'selectedAssignedStageInstance',
  );
  selectedAssignedStageInstance: StageInstanceByReviewers | undefined;
  pageSize: number = 5;
  pageIndex: number = 0;
  stageInstanceId: string | undefined = undefined;
  editor?: Quill;
  selected: TaskDocument | undefined = undefined;
  documents: TaskDocument[] = [];
  videoBlob$ = this.state.select('videoBlob');
  audioBlob$ = this.state.select('audioBlob');
  isDragOver = false;
  files: File[] = [];

  @ViewChild('videoElement', { static: true })
  videoElementRef!: ElementRef<HTMLVideoElement>;

  @ViewChild('audioElement', { static: true })
  audioElementRef!: ElementRef<HTMLAudioElement>;
  composeIsValid: boolean = false;
  initialEditorContent: any;
  myStageInstancesReview$: Observable<StageInstanceReview | undefined> = this.state.select('myStageInstancesReview');
  myStageInstancesReview: StageInstanceReview | undefined;
  stageInstanceReviewerType$ = this.state.select('stageInstanceReviewerType');
  stageInstanceReviewerType: StageInstanceReviewerType | undefined;
  hasChange = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.review-detail.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.review-detail.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.review-detail.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.review-detail.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.review-detail.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.review-detail.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.review-detail.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.review-detail.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<ReviewDetailComponentState>,
    private taskFacade: TaskFacade,
    private stageInstanceFacade: StageInstanceFacade,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.state.set(initReviewDetailComponentState);
    this.state.connect(
      'assignedTaskDocuments',
      this.taskFacade.assignedTaskDocuments$,
    );
    this.state.connect(
      'selectedAssignedStageInstance',
      this.stageInstanceFacade.selectedAssignedStageInstance$,
    );
    this.state.connect('videoBlob', this.taskFacade.videoBlob$);
    this.state.connect('audioBlob', this.taskFacade.audioBlob$);
    this.state.connect(
      'myStageInstancesReview',
      this.stageInstanceFacade.myStageInstancesReview$,
    );
    this.state.connect(
      'stageInstanceReviewerType',
      this.stageInstanceFacade.stageInstanceReviewerType$,
    );
  }
  changes = new Subject<void>();

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.stageInstanceId = params['id'];
      if (this.stageInstanceId) {
        this.taskFacade.dispatchGetAssignedTaskDocuments(
          this.stageInstanceId,
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 5,
        );
        this.stageInstanceFacade.dispatchGetStageInstanceReviewerType(
          this.stageInstanceId,
        );
        this.stageInstanceReviewerType$.subscribe((reviewerType) => {
          this.stageInstanceReviewerType = reviewerType;
        });
      }
    });
    this.assignedTaskDocuments$.subscribe((documents) => {
      if (documents) {
        this.assignedTaskDocuments = documents;
        this.documents = documents.items;
        this.dataSource = new MatTableDataSource<TaskDocument>(documents.items);
      }
    });
    this.selectedAssignedStageInstance$.subscribe((stageInstance) => {
      this.selectedAssignedStageInstance = stageInstance;
    });
    this.editor = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });
    this.initialEditorContent = { ops: [] };
    this.editor.setContents(this.initialEditorContent, 'api');
    this.hasChange = false;
    this.editor.on('text-change', this.handleTextChange);

    this.myStageInstancesReview$.subscribe((review) => {
      this.myStageInstancesReview = review;
      if (this.myStageInstancesReview) {
        this.files = []
        this.myStageInstancesReview.attachments
        this.fillEditorWithInitialContent();
      }
    });
  }

  loadPaginatedTaskDocument(event: PageEvent) {
    if (this.stageInstanceId)
      this.taskFacade.dispatchGetAssignedTaskDocuments(
        this.stageInstanceId,
        event.pageIndex + 1,
        event.pageSize,
      );
  }
  handleTextChange = () => {
    this.composeIsValid = !!this.editor?.getText().trim();
    this.hasChange = true;
  };

  fillEditorWithInitialContent() {
    if (this.myStageInstancesReview?.documentContent) {
      this.initialEditorContent = this.myStageInstancesReview.documentContent;
      if (this.editor && this.initialEditorContent) {
        this.editor.setContents(this.initialEditorContent, 'api');
        this.hasChange = false;
      }
      if (this.myStageInstancesReview.reviewStatus == ReviewStatus.Submitted) {
        this.editor?.disable();
      }
    }
  }
  isSaveButtonDisabled() {
    if(this.myStageInstancesReview){
      if(this.myStageInstancesReview.reviewStatus === ReviewStatus.Submitted){
        return true;
      }else{
        return this.myStageInstancesReview.attachments?.length === 0 && this.myStageInstancesReview.documentContent === this.initialEditorContent && !this.composeIsValid && !(this.files.length !== 0)
      }
    }
    return !(this.composeIsValid || this.files.length !== 0)
  }

  isSubmitButtonDisabled() {
   if(this.myStageInstancesReview){
      if(this.myStageInstancesReview.reviewStatus === ReviewStatus.Submitted){
        return true;
      }else{
        return this.myStageInstancesReview.attachments?.length === 0 && this.myStageInstancesReview.documentContent === this.initialEditorContent && !this.composeIsValid && !(this.files.length !== 0)
      }
    }
    return !(this.composeIsValid || this.files.length !== 0)
  }

  isStatusSubmitted(){
    return this.myStageInstancesReview?.reviewStatus == ReviewStatus.Submitted
  }
  submitReview() {
    if (this.stageInstanceId && (this.editor || this.files.length > 0)){
      if (this.myStageInstancesReview?.id) {
        const review  = new FormData()
        review .append('DocumentContent', JSON.stringify(this.editor?.getContents()))
        review .append('ReviewStatus',ReviewStatus.Submitted)

        for (const file of this.files ){
          review .append('files',file);          
        }
        this.stageInstanceFacade.dispatchUpdateStageInstanceReview(
          this.stageInstanceId,
          this.myStageInstancesReview.id,
          review ,
        );
      } else {
        
        const review  = new FormData()
        review .append('DocumentContent',JSON.stringify(this.editor?.getContents()))
        review .append('ReviewStatus',ReviewStatus.Submitted)

        for (const file of this.files ){
          review .append('files',file);
        }
        this.stageInstanceFacade.dispatchReviewStageInstance(
          this.stageInstanceId,
          review ,
        );
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

 async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      const selectedFiles = Array.from(files);

      const newFiles = selectedFiles.filter(file => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }   
        return  !this.files.some((existingFile) => existingFile.name === file.name);
      });
      this.files = this.files.concat(newFiles);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter(file => {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          if (fileExtension === 'exe') {
            this.snackBar.open('Invalid file type.', 'Close', {
              duration: 3000,
            }); 
            return false;
          }
          
          return  !this.files.some((existingFile) => existingFile.name === file.name);
        });
        this.files = this.files.concat(newFiles);
      }
    };
    fileInput.click();
  }
  

   onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files) as File[];
    
      const newFiles = selectedFiles.filter((file: File) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }
        return !this.files.some(existingFile => existingFile.name === file.name);
      });

      this.files = this.files.concat(newFiles);
    }
  }

    removeFile(fileToRemove: File) {
    this.files = this.files.filter(file => file !== fileToRemove);
  }


  createReview() {
    if (this.stageInstanceId && (this.editor || this.files.length > 0)) {
      if (this.myStageInstancesReview?.id) {
        const review  = new FormData()
        review .append('DocumentContent',JSON.stringify(this.editor?.getContents()))
        review .append('ReviewStatus',this.myStageInstancesReview.reviewStatus)

        for (const file of this.files ){
          review .append('files',file);
        }
        this.stageInstanceFacade.dispatchUpdateStageInstanceReview(
          this.stageInstanceId,
          this.myStageInstancesReview.id,
          review ,
        );
      } else if (this.composeIsValid || this.files.length > 0) {
         const review = new FormData()
        review .append('DocumentContent',JSON.stringify(this.editor?.getContents()))
        review .append('ReviewStatus',ReviewStatus.Draft)

        for (const file of this.files ){
          review .append('files',file);
        }
        this.stageInstanceFacade.dispatchReviewStageInstance(
          this.stageInstanceId,
          review ,
        );
      }
    }
  }

  deleteReviewAttachment(attachmentId: string | undefined) {
    if (!this.myStageInstancesReview?.id) return;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.review-detail.delete-attachment:Are you sure you want to delete the attachment?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.myStageInstancesReview?.id && attachmentId)
          this.stageInstanceFacade.dispatchDeleteReviewAttachment(
            this.myStageInstancesReview?.id,
            attachmentId,
          );
      }
    });
  }

  cancle(){
    this.files = [],
    this.editor?.setContents(this.initialEditorContent, 'api');
    this.hasChange = false;
  }

  onMenuClick($event: Event, task: TaskDocument) {
    this.selected = task;
  }

  previewTaskDocument(process: any): void {
    const document = this.documents.find((doc: any) => doc.id === process.id);
    if (document) {
      this.taskFacade.dispatchPreviewTaskDocument(
        document.id,
        document.name,
        document.table,
        'pdf',
        'Preview',
      );
    }
  }

  previewDocument() {
    if (!this.documents && !this.assignedTaskDocuments$) return;
    if (!this.selected) return;
    if (this.selected.table == 'Document') {
      this.taskFacade.dispatchPreviewTaskDocumentCompose(
        this.selected.id,
        this.selected.name
      );
    } else {
      let fileId = this.selected.id;
      let fileName = this.selected.name;
      let fileData = fileName.split('.');
      if (
        fileData[1] == 'mp4' ||
        fileData[1] == 'ogg' ||
        fileData[1] == 'webm'
      ) {
        this.taskFacade.dispatchFetchVideo(fileId);
        this.videoBlob$.subscribe((blob) => {
          if (blob) {
            const videoElement: HTMLVideoElement =
              this.videoElementRef.nativeElement;

            videoElement.src = URL.createObjectURL(blob);
            videoElement.controls = true;
            const videoContainer = document.getElementById('video-container');
            if (!videoContainer) return;
            videoContainer.style.display = 'block';

            videoElement.addEventListener('ended', () => {
              this.removeVideoElement();
            });

            videoElement.play();
          }
        });
      } else if (fileData[1] == 'mp3' || fileData[1] == 'wav') {
        this.taskFacade.dispatchFetchAudio(fileId);
        this.audioBlob$.subscribe((blob) => {
          if (blob) {
            const audioElement: HTMLAudioElement =
              this.audioElementRef.nativeElement;
            audioElement.src = URL.createObjectURL(blob);
            audioElement.controls = true;

            const audioContainer = document.getElementById('audio-container');
            if (!audioContainer) return;
            audioContainer.style.display = 'flex';
            audioContainer.style.flexDirection = 'row';

            audioElement.addEventListener('ended', () => {
              this.removeAudioElement();
            });

            audioElement.play();
          }
        });
      } else {
        this.taskFacade.dispatchPreviewTaskDocument(
          this.selected.id,
          this.selected.name,
          this.selected.table,
          'pdf',
          'Preview',
        );
      }
    }
  }

  removeVideoElement() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  removeAudioElement() {
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.pause();
    audioElement.removeEventListener('ended', this.removeAudioElement);
    const audioContainer = audioElement.parentElement;
    if (audioContainer) {
      audioContainer.removeChild(audioElement);
    }
  }

  closePlayer() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  closeAudioPlayer() {
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.pause();
    const container = document.getElementById('audio-container');
    if (!container) return;
    container.style.display = 'none';
  }
  isTableDocument() {
    if (!this.documents && !this.assignedTaskDocuments$) return false;
    if (!this.selected) return false;
    return this.selected.table == 'Document';
  }

  downloadDocumentAsPdf() {
    if (!this.documents) return;
    if (!this.selected) return;
    this.taskFacade.dispatchDownloadTaskDocumentCompose(this.selected.id, this.selected.name);
  }

  downloadDocument(type?: string) {
    if (!this.documents && !this.assignedTaskDocuments$) return;
    if (!this.selected) return;
    const documentDownloadType = type ? type : 'pdf';
    this.taskFacade.dispatchDownloadTaskDocument(
      this.selected.id,
      this.selected.name,
      this.selected.table,
      documentDownloadType,
      'Download',
    );
  }

  hasGetFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTask,
    );
  }

  hasReviewStageInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.ReviewStageInstance,
    );
  }
}
