import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AFAAN_OROMOO_LANGUAGE,
  AFAR_LANGUAGE,
  AMHARIC_LANGUAGE,
  ENGLISH_LANGUAGE,
  SOOMAALI_LANGUAGE,
  TIGRI_LANGUAGE,
} from '../constants/language';

@Injectable()
export class AcceptLanguageHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const locale = localStorage.getItem('locale') ?? ENGLISH_LANGUAGE.locale;
    switch (locale) {
      case ENGLISH_LANGUAGE.locale:
        request = request.clone({
          headers: request.headers.set(
            'Accept-Language',
            ENGLISH_LANGUAGE.code,
          ),
        });
        break;
      case AMHARIC_LANGUAGE.locale:
        request = request.clone({
          headers: request.headers.set(
            'Accept-Language',
            AMHARIC_LANGUAGE.code,
          ),
        });
        break;
      case AFAAN_OROMOO_LANGUAGE.locale:
        request = request.clone({
          headers: request.headers.set(
            'Accept-Language',
            AFAAN_OROMOO_LANGUAGE.code,
          ),
        });
        break;
      case TIGRI_LANGUAGE.locale:
        request = request.clone({
          headers: request.headers.set('Accept-Language', TIGRI_LANGUAGE.code),
        });
        break;
      case SOOMAALI_LANGUAGE.locale:
        request = request.clone({
          headers: request.headers.set(
            'Accept-Language',
            SOOMAALI_LANGUAGE.code,
          ),
        });
        break;
      default:
        request = request.clone({
          headers: request.headers.set(
            'Accept-Language',
            ENGLISH_LANGUAGE.code,
          ),
        });
    }

    return next.handle(request);
  }
}
