import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ExternalDocumentDetailDto,
  ExternalDocumentResponseDetailDto,
  RemarkStatus,
  SendRemarkDto,
} from '../../models/external-document.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { RxState } from '@rx-angular/state';
import { ExternalDocumentFacade } from '../../facades/external-document-workflow.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { timeHours } from 'd3';
import { OfficeSelectorcomponent } from 'src/app/offices/components/office-selector/office-selector.component';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User } from 'src/app/users/models/user.model';

interface ExternalDocumentRemarkState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  externalDocumentResponseDetail: ExternalDocumentResponseDetailDto | undefined;
  user: User | null;
}

const initState: ExternalDocumentRemarkState = {
  selectedFlatOfficeNode: undefined,
  externalDocumentResponseDetail: undefined,
  user: null,
};
@Component({
  selector: 'app-external-document-remark-form',
  templateUrl: './external-document-response-remark-form.component.html',
  styleUrls: ['./external-document-response-remark-form.component.scss'],
})
export class ExternalDocumentResponseRemarkFormComponent implements OnInit {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');

  viewOfficeSelector: boolean = true;

  remarkFormGroup: FormGroup = this.fb.group({
    remark: ['', Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      status: RemarkStatus;
      externalDocumentId: string;
    },
    public sanitizerService: SanitizerService,
    private fb: FormBuilder,
    private state: RxState<ExternalDocumentRemarkState>,
    private officeFacade: OfficeFacade,
    private externalDocumentFacade: ExternalDocumentFacade,
    private dialog: MatDialog,
  ) {
    this.handleRemarkForm(this.data.status);
  }
  ngOnInit(): void {
    this.state.set(initState);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.selectedFlatOfficeNode$.subscribe((selectedOffice) => {
      this.selectedFlatOfficeNode = selectedOffice;
    });
  }

  handleRemarkForm(status: RemarkStatus) {
    switch (status) {
      case RemarkStatus.Approved:
        this.viewOfficeSelector = true;
        break;
      case RemarkStatus.RollBacked:
        this.viewOfficeSelector = false;
        break;
      case RemarkStatus.Rejected:
        this.viewOfficeSelector = false;
        break;
      case RemarkStatus.Received:
        this.viewOfficeSelector = false;
        break;
      default:
        this.viewOfficeSelector = true;
    }
  }

  openSingleOffice() {
    this.dialog.open(OfficeSelectorcomponent, {
      data: {},
    });
  }
  sendRemark() {
    if (!this.remarkFormGroup) return;
    const { valid, touched, dirty } = this.remarkFormGroup;
    if (valid && (touched || dirty)) {
      const { remark } = this.remarkFormGroup.value;
      const sendRemark: SendRemarkDto = {
        remark,
        toOfficeId: this.selectedFlatOfficeNode?.id??"",
        status: this.data.status,
      };
      this.externalDocumentFacade.dispatchSendResponseRemark(
        sendRemark,
        this.data.externalDocumentId,
      );
    }
    this.dialog.closeAll();
  }
  removeSelectedFlatOfficeNode() {
    if (this.selectedFlatOfficeNode === undefined) return;
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(this.selectedFlatOfficeNode);
  }

  cancel() {
    this.dialog.closeAll();
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
  }
}
