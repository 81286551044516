import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENTS_ANALYTICS_URL } from 'src/app/core/constants/api-endpoints';
import { WorkflowsVsTime } from '../models/workflows-vs-time.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ReceivedExternalDocument } from '../models/received-external-document.model';
import { SentExternalDocument } from '../models/sent-external-document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAnalyticsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getWorkflowTypesCount(officeId?: string): Observable<number> {
    var url = `${DOCUMENTS_ANALYTICS_URL}/workflowTypes-count`;
    if (officeId != null && officeId != undefined) {
      url += `?officeId=${officeId}`;
    }
    return this.http.get<number>(url);
  }

  getWorkflowsCount(
    officeId?: string,
    userId?: string,
    startDate?: Date,
    endDate?: Date,
  ): Observable<any> {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('StartDateTime', startDate.toISOString());
    }
    if (endDate) {
      params = params.set('EndDateTime', endDate.toISOString());
    }
    if (officeId) {
      params = params.set('OfficeId', officeId);
    }
    if (userId) {
      params = params.set('UserId', userId);
    }
    return this.http.get<any>(`${DOCUMENTS_ANALYTICS_URL}/workflows-count`, {
      params: params,
    });
  }

  getWorkflowsVsTimeGraphData(
    startDate?: Date,
    endDate?: Date,
    officeId?: string,
    userId?: string,
    workflowTypeId?: string,
  ): Observable<WorkflowsVsTime[]> {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('StartDateTime', startDate.toISOString());
    }
    if (endDate) {
      params = params.set('EndDateTime', endDate.toISOString());
    }
    if (officeId) {
      params = params.set('OfficeId', officeId);
    }
    if (userId) {
      params = params.set('UserId', userId);
    }
    if (workflowTypeId) {
      params = params.set('WorkflowTypeId', workflowTypeId);
    }
    return this.http.get<WorkflowsVsTime[]>(
      `${DOCUMENTS_ANALYTICS_URL}/workflows-by-time-line`,
      { params: params },
    );
  }

  getReceivedExternalDocumentCount(officeId?: string): Observable<number> {
    var url =  `${DOCUMENTS_ANALYTICS_URL}/received-external-documents/count`;
    if (officeId != null && officeId != undefined) {
      url += `?officeId=${officeId}`;
    }
    return this.http.get<number>(url);
  }

  getReceivedExternalDocuments(
    officeId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<ReceivedExternalDocument>> {
    let url = `${DOCUMENTS_ANALYTICS_URL}/received-external-documents/?officeId=${officeId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<ReceivedExternalDocument>>(url);
  }

  getSentExternalDocuments(
    officeId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<SentExternalDocument>> {
    let url = `${DOCUMENTS_ANALYTICS_URL}/sent-external-documents/?officeId=${officeId}`;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<SentExternalDocument>>(url);
  }

  getReportedCommentsPercentage(
    officeId: string,
    workflowTypeId?: string,
  ): Observable<number> {
    let url = `${DOCUMENTS_ANALYTICS_URL}/reported-comments-percentage/?officeId=${officeId}`;
    if (workflowTypeId !== null && workflowTypeId !== undefined) {
      url += `&workflowTypeId=${workflowTypeId}`;
    }
    return this.http.get<number>(url);
  }

  getMyWorkflowsCount(
    userId: string,
    officeId?: string,
    startDate?: Date,
    endDate?: Date,
  ): Observable<number> {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('StartDateTime', startDate.toISOString());
    }
    if (endDate) {
      params = params.set('EndDateTime', endDate.toISOString());
    }
    if (officeId) {
      params = params.set('OfficeId', officeId);
    }
    if (userId) {
      params = params.set('UserId', userId);
    }
    return this.http.get<any>(`${DOCUMENTS_ANALYTICS_URL}/my-workflows-count`, {
      params: params,
    });
  }

  getInvolvedWorkflowsCount(
    userId: string,
    officeId?: string,
    startDate?: Date,
    endDate?: Date,
  ): Observable<number> {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('StartDateTime', startDate.toISOString());
    }
    if (endDate) {
      params = params.set('EndDateTime', endDate.toISOString());
    }
    if (officeId) {
      params = params.set('OfficeId', officeId);
    }
    if (userId) {
      params = params.set('UserId', userId);
    }
    return this.http.get<any>(`${DOCUMENTS_ANALYTICS_URL}/involved-workflows-count`, {
      params: params,
    });
  }
}
