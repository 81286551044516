import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { PublicationFacade } from 'src/app/researches/facades/publication.facade';
import { Publication } from 'src/app/researches/models/publication.model';

interface PublicationDetailComponentState {
  publication?: Publication;
}

const initialPublicationDetailComponentState: PublicationDetailComponentState =
  {
    publication: undefined,
  };

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss',
})
export class UploadFileComponent {
  publication$ = this.state.select('publication');
  publication?: Publication;
  @Output() uploaded = new EventEmitter<File[]>();

  fileUploadForm: FormGroup;
  files: File[] = [];
  isDragOver = false;

  constructor(
    private fb: FormBuilder,
    private publicationFacade: PublicationFacade,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private state: RxState<PublicationDetailComponentState>,
  ) {
    this.state.connect('publication', this.publicationFacade.selectedPublication$);
    this.fileUploadForm = this.fb.group({
      files: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.publication$.subscribe((publication) => {
      if (publication) {
        this.publication = publication;
      }
    });
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.files = this.files.concat(Array.from(event.target.files));
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = await event.dataTransfer?.files;
    if (files) {
      this.files = this.files.concat(Array.from(files));
    }
    this.isDragOver = false;
  }

  get fileControls(): FormArray {
    return this.fileUploadForm.get('files') as FormArray;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadFiles() {
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('files', file);
    }

    var id = this.publication?.id;
    if(id){
    this.publicationFacade.dispatchUploadPublicationFiles(
      formData,
      this.data.id
    );
    }
  }
}
