<div class="mat-elevation-z1">
  <h1 i18n="@@documents.workflow-step-averager-time.average-time-spent-on-workflow-step-type" class="mat-h1">Average time spent on a workflow step type</h1>
  <form [formGroup]="filterForm" class="filter-form-container">
    <button mat-flat-button color="primary" (click)="openSingleOffice()"
    i18n="@@documents.workflow-step-averager-time.select-office">
      Select office
    </button>
    <mat-form-field appearance="outline" class="search-name-input">
      <mat-label i18n="@@documents.workflow-step-averager-time.search-by-owner">Search by Owner</mat-label>
      <input
        matInput
        formControlName="searchName"
        placeholder="Search"
        (input)="applyFilter()"
      />
    </mat-form-field>
    <button mat-stroked-button color="warn" (click)="resetFilter()"
    i18n="@@documents.workflow-step-averager-time.reset-filter">
      Reset Filter
    </button>
  </form>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedReport($event)"
  ></mat-paginator>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="WorkflowTypeStepName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-step-averager-time.step-name"> Step Name </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.workflowTypeStepName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="WorkflowTypeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-step-averager-time.workflow-type"> Workflow Type </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.workflowTypeName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="AverageTimeInDays">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.workflow-step-averager-time.avg-time"> Avg Time </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ convertDaysToDaysAndHours(row.averageTimeInDays) }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
