import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ROLES_URL } from 'src/app/core/constants/api-endpoints';
import { Role } from '../models/role.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Permission } from '../models/permission.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${ROLES_URL}`);
  }

  getPaginatedRoles(
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Role>> {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    return this.http.get<PaginatedList<Role>>(`${ROLES_URL}`, {
      params,
    });
  }

  createRole(name: string): Observable<Role> {
    return this.http.post<Role>(
      `${ROLES_URL}`,
      { name, permissionIds: [] },
      this.httpOptions,
    );
  }

  updateRole(role: Role): Observable<Role> {
    return this.http.put<Role>(
      `${ROLES_URL}/${role.id}`,
      {
        name: role.name,
      },
      this.httpOptions,
    );
  }

  getPermissionsByRoleId(roleId: string): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${ROLES_URL}/${roleId}/permissions`);
  }

  assignRevokePermissions(roleId: string, permissionIds: string[]) {
    return this.http.put<Role>(
      `${ROLES_URL}/${roleId}/permissions`,
      permissionIds,
      this.httpOptions,
    );
  }

  deleteRole(roleId: string): Observable<Role> {
    return this.http.delete<any>(`${ROLES_URL}/${roleId}`);
  }

  searchRole(
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<PaginatedList<Role>> {
    return this.http.get<PaginatedList<Role>>(
      `${ROLES_URL}/search?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }
}
