import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Store } from '@ngxs/store';
import {
  append,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { iif, tap } from 'rxjs';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { FieldValidationRule } from '../models/field-validation-rule.model';
import { ValidationRule } from '../models/validation-rule.model';

import { FieldValidationRulesService } from '../services/field-validation-rules.service';
import {
  AddValidationRulesByDataType,
  ClearValidationRulesByDataType,
  CreateFieldValidationRules,
  GetTemplateFieldValidationRules,
  DeleteFieldValidationRules,
  GetValidationRulesByDataType,
  UpdateFieldValidationRule,
  AddValidationRules,
} from './field-validation-rules.actions';

export interface FieldValidationRuleStateModel {
  ValidationRules: Required<ValidationRule>[];
  currentFieldValidationRules: FieldValidationRule[];
}

const FIELD_VALIDATION_RULE_STATE_TOKEN =
  new StateToken<FieldValidationRuleStateModel>('fieldValidationRule');

const defaults: FieldValidationRuleStateModel = {
  ValidationRules: [],
  currentFieldValidationRules: [],
};

@State<FieldValidationRuleStateModel>({
  name: FIELD_VALIDATION_RULE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class FieldValidationRuleState {
  constructor(
    private fieldValidationRuleService: FieldValidationRulesService,
    private operationStatus: OperationStatusService,
    private store: Store,
  ) {}

  @Action(GetTemplateFieldValidationRules)
  getTemplateFieldValidationRules(
    { patchState }: StateContext<FieldValidationRuleStateModel>,
    { templateFieldId }: GetTemplateFieldValidationRules,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fieldValidationRuleService
      .getFieldValidationRules(templateFieldId)
      .pipe(
        tap((val) => {
          patchState({ currentFieldValidationRules: val.items });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetValidationRulesByDataType)
  getFieldValidationRulesByDataType(
    { patchState }: StateContext<FieldValidationRuleStateModel>,
    { dataType }: GetValidationRulesByDataType,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fieldValidationRuleService
      .getValidationRulesByDataType(dataType)
      .pipe(
        tap((fieldValidationRules) => {
          patchState({
            ValidationRules: fieldValidationRules,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AddValidationRulesByDataType)
  addValidationRulesByDataType(
    { setState, getState }: StateContext<FieldValidationRuleStateModel>,
    { fieldValidationRule }: AddValidationRulesByDataType,
  ) {
    const exists = getState().currentFieldValidationRules.some(
      (rule) => rule.validationRule.id == fieldValidationRule.validationRule.id,
    );

    if (!exists)
      return setState(
        patch({
          currentFieldValidationRules: append([fieldValidationRule]),
        }),
      );

    return setState(
      patch({
        currentFieldValidationRules: updateItem(
          (rule) =>
            rule.validationRule.id == fieldValidationRule.validationRule.id,
          patch(fieldValidationRule),
        ),
      }),
    );
  }

  @Action(AddValidationRules)
  addValidationRules(
    { setState }: StateContext<FieldValidationRuleStateModel>,
    { fieldValidationRules }: AddValidationRules,
  ) {
    return setState(
      patch({
        currentFieldValidationRules: fieldValidationRules,
      }),
    );
  }

  @Action(UpdateFieldValidationRule)
  updateFieldValidationRulesByDataType(
    { setState }: StateContext<FieldValidationRuleStateModel>,
    { index, fieldValidation }: UpdateFieldValidationRule,
  ) {
    if (fieldValidation.id) {
      this.store.dispatch(new SetProgressOn());
      return this.fieldValidationRuleService
        .updateFieldValidationRule(
          fieldValidation.id,
          fieldValidation.validationRuleValues,
        )
        .pipe(
          tap((val) => {
            setState(
              patch({
                currentFieldValidationRules: updateItem(
                  (item) => item.id === fieldValidation.id,
                  fieldValidation,
                ),
              }),
            );
            this.store.dispatch(new SetProgressOff());
            this.operationStatus.displayStatus(
              $localize`:@@documents.feild-validation-rules.template-field-validation:Template Field Validation Rules updated successfully.`,
            );
          }),
        );
    } else {
      this.operationStatus.displayStatus(
        $localize`:@@documents.field-validation-rules.update-field-validation:Template Field Validation Rules updated successfully.`,
      );
      return setState(
        patch({
          currentFieldValidationRules: updateItem(index, fieldValidation),
        }),
      );
    }
  }

  @Action(ClearValidationRulesByDataType)
  clearValidationRulesByDataType(
    { setState }: StateContext<FieldValidationRuleStateModel>,
    {}: ClearValidationRulesByDataType,
  ) {
    setState(
      patch({
        currentFieldValidationRules: [],
      }),
    );
  }

  @Action(CreateFieldValidationRules)
  createFieldValidationRules(
    { setState, getState }: StateContext<FieldValidationRuleStateModel>,
    { templateFieldId }: CreateFieldValidationRules,
  ) {
    const fieldValidationRules = getState()
      .currentFieldValidationRules.filter((rule) => !rule.id)
      .map((rule) => {
        return {
          validationRuleId: rule.validationRule.id,
          validationRuleValue: rule.validationRuleValues,
        };
      });

    this.store.dispatch(new SetProgressOn());
    return this.fieldValidationRuleService
      .createFieldValidationRules(templateFieldId, fieldValidationRules)
      .pipe(
        tap((val) => {
          setState(patch({ currentFieldValidationRules: val }));
          this.store.dispatch(new SetProgressOff());
          this.operationStatus.displayStatus(
            $localize`:@@documents.field-validation-rules.create-field-validation:Template Field Validation Rules Created successfully.`,
          );
        }),
      );
  }

  @Action(DeleteFieldValidationRules)
  DeleteFieldValidationRules(
    { setState }: StateContext<FieldValidationRuleStateModel>,
    { fieldValidationRule }: DeleteFieldValidationRules,
  ) {
    if (fieldValidationRule.id) {
      return this.fieldValidationRuleService
        .deleteFieldValidationRules(fieldValidationRule.id)
        .pipe(
          tap(() => {
            setState(
              patch({
                currentFieldValidationRules: removeItem(
                  (fr) => fr.id == fieldValidationRule.id,
                ),
              }),
            );
            this.operationStatus.displayStatus(
              $localize`:@@documents.field-validation-rules.remove-field-validation:Field validation rule removed.`,
            );
          }),
        );
    }
    const result = setState(
      patch({
        currentFieldValidationRules: removeItem(
          (fr) => fr.validationRule.id == fieldValidationRule.validationRule.id,
        ),
      }),
    );
    this.operationStatus.displayStatus(
      $localize`:@@documents.field-validation-rules.remove-field-validation:Field validation rule removed.`,
    );
    return result;
  }
}
