import { Component, OnInit } from '@angular/core';
import { UserProfile, User, CurrentLoggedInUser } from '../../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserFacade } from '../../facade/user.facade';
import { RxState } from '@rx-angular/state';
import { MatDialogRef } from '@angular/material/dialog';

interface UpdateUserProfileFormComponentState {
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initUpdateUserProfileFormComponentState: Partial<UpdateUserProfileFormComponentState> = {
  currentLoggedInUser: undefined,
};
@Component({
  selector: 'app-update-user-profile-form',
  templateUrl: './update-user-profile-form.component.html',
  styleUrl: './update-user-profile-form.component.scss',
  providers: [RxState],
})
export class UpdateUserProfileFormComponent implements OnInit{


  userForm: FormGroup;

  currentLoggedInUser = undefined;
  currentLoggedInUser$: Observable<CurrentLoggedInUser | undefined> =
    this.state.select('currentLoggedInUser');
    
  constructor(
    private fb: FormBuilder,
    private userFacade: UserFacade,
    private state: RxState<UpdateUserProfileFormComponentState>,
    private dialogRef: MatDialogRef<UpdateUserProfileFormComponent>,
  ) {
    this.state.set(initUpdateUserProfileFormComponentState);
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );

    this.userForm = this.fb.group({
      userName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9-]+$'),
          Validators.minLength(3),
        ],
      ],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(/^(9|7)[0-9]{8}$/)],
      ]
    });
  }

  ngOnInit(): void {
    this.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.userForm.patchValue({
          userName: user.userName,
          phoneNumber: user.phoneNumber,
        });
      }
    });
  }

  save() {
    const { valid, touched, dirty } = this.userForm;
    if (valid && (touched || dirty)) {
      const updatedUserProfile: UserProfile = {
        ...this.userForm.value,
      };
      this.currentLoggedInUser$.subscribe((user) => {
        if (user && user.id) {
          updatedUserProfile.id = user.id;
          this.userFacade.dispatchUpdateUserProfile(updatedUserProfile);
        }
      });

      this.dialogRef.close()
    }
  }
}
