import {
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  KeyValueDiffers,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { combineLatest, debounceTime, filter } from 'rxjs';
import { DateTimeFacade } from 'src/app/core/facades/datetime.facade';
import { DateTimeService } from 'src/app/core/services/datetime/datetime.service';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { StageInstanceFacade } from 'src/app/researches/facades/stage-instance.facade';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import {
  StageInstanceDetail,
  StageInstanceStatus,
} from 'src/app/researches/models/stage-instance-detail.model';
import { StageInstance } from 'src/app/researches/models/process-instance.model';
import { Task, TaskType } from 'src/app/researches/models/task.model';

import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { User } from 'src/app/users/models/user.model';

interface TaskFormState {
  users: User[];
  update: boolean;
  selectedStageInstanceDetail?: StageInstanceDetail;
  selectedStageInstance: StageInstance | undefined;
  selectedTask: Task | undefined;
  simiarTasks: Task[];
  filteredTaskType: TaskType[];
  datetime: string;
  dateOnly: string;
}

const initialTaskFormState: TaskFormState = {
  users: [],
  update: false,
  selectedTask: undefined,
  selectedStageInstance: undefined,
  simiarTasks: [],
  filteredTaskType: [],
  datetime: '',
  dateOnly: '',
};

export interface EditTaskDialogData {
  processInstanceId: string | undefined;
  stageInstanceDetailId: string | undefined;
}

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
})
export class TaskFormcomponent implements OnInit {
  hasEvaluation: boolean = false;
  users$ = this.state.select('users');
  users: User[] = [];
  filteredAssignee: User[] = [];
  selectedTask: Task | undefined;
  selectedTask$ = this.state.select('selectedTask');
  selectedStageInstance: StageInstance | undefined;
  selectedStageInstance$ = this.state.select('selectedStageInstance');
  update: boolean = false;
  update$ = this.state.select('update');

  datetime: string = '';
  datetime$ = this.state.select('datetime');

  dateOnly: string = '';

  createEditToggle = {
    value: {
      title: $localize`:@@researches.task-form.create-task-title: CREATE A task`,
      submitButton: $localize`:@@researches.task-form.create-task-button: Create`,
    },
    create: {
      title: $localize`:@@researches.task-form.create-task-title: CREATE A task`,
      submitButton: $localize`:@@researches.task-form.create-task-button: Create`,
    },
    update: {
      title: $localize`:@@researches.task-form.update-task-title-update: UPDATE A task`,
      submitButton: $localize`:@@researches.task-form.update-task-button-update: Update`,
    },
  };

  placeholderToggleLabel = {
    taskName: $localize`:@@researches.task-form.task-name: Task name`,
    taskDescription: $localize`:@@researches.task-form.this-is-description-for-the-task: This is description for the task.`,
    assigneeField: $localize`:@@researches.task-form.assignee: Assignee`,
    taskType: $localize`:@@researches.task-form.task-types: Task Types`,
    startDate: $localize`:@@researches.task-form.start-date: Start date`,
    endDate: $localize`:@@researches.task-form.end-date: End date`,
  };

  filteredTaskType$ = this.state.select('filteredTaskType');
  filteredTaskType: TaskType[] = [];

  taskForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    assigneeSearchQuery: [''],
    taskTypeSearchQuery: [''],
    assignee: [undefined as User | undefined],
    taskType: [undefined as TaskType | undefined],
    startDate: ['', [Validators.required]],
    endDate: [''],
  });

  isAddTypeButtonVisible: boolean = false;

  @ViewChild('assigneeInput') approverInput!: ElementRef<HTMLInputElement>;
  @ViewChild('taskTypeInput') taskTypeInput!: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditTaskDialogData,

    private fb: NonNullableFormBuilder,
    private roleFacade: RoleFacade,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TaskFormcomponent>,
    private userFacade: UserFacade,
    private taskFacade: TaskFacade,
    private stageInstanceFacade: StageInstanceFacade,
    public state: RxState<TaskFormState>,
    private destroyRef: DestroyRef,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private datetimeFacade: DateTimeFacade,
    private dateTimeService: DateTimeService,
  ) {
    this.state.set(initialTaskFormState);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect('filteredTaskType', this.taskFacade.taskTypes$);
    this.state.connect(
      'selectedStageInstanceDetail',
      this.stageInstanceDetailFacade.selectedStageInstanceDetail$,
    );
    this.state.connect(
      'selectedStageInstance',
      this.stageInstanceFacade.selectedStageInstance$,
    );
    this.state.connect('selectedTask', this.taskFacade.selectedTask$);
    this.state.connect('update', this.taskFacade.update$);
    this.state.connect('simiarTasks', this.taskFacade.similarTasks$);
    this.selectedTask$.subscribe((selectedTask) => {
      this.selectedTask = selectedTask;
    });
    this.state.connect('datetime', this.datetimeFacade.datetime$);

    this.update$.subscribe((update) => {
      this.update = update;
    });
    if (this.update && this.selectedTask) {
      this.createEditToggle.value = this.createEditToggle.update;
      this.taskForm = this.fb.group({
        name: [this.selectedTask.name, Validators.required],
        description: [this.selectedTask.description, Validators.required],
        assigneeSearchQuery: [''],
        taskTypeSearchQuery: [''],
        assignee: [this.selectedTask.assignee as User | undefined],
        taskType: [
          this.selectedTask.taskType as TaskType | undefined,
          Validators.required,
        ],
        startDate: [
          this.selectedTask.startDate.toString(),
          Validators.required,
        ],
        endDate: [
          this.selectedTask.endDate !== null
            ? this.selectedTask.endDate.toString()
            : '',
          Validators.required,
        ],
      });
    }
  }
  dateFilter = (date: Date) => {
    if (
      this.selectedStageInstance?.startDate &&
      this.selectedStageInstance?.endDate
    )
      return (
        date >= new Date(this.selectedStageInstance?.startDate) &&
        date <= new Date(this.selectedStageInstance?.endDate)
      );
    return true;
  };

  ngOnInit(): void {
    this.userFacade.dispatchGetUsers();
    this.datetimeFacade.dispatchGetCurrentDateTime();
    this.roleFacade.dispatchGetRoles();

    this.taskFacade.dispatchSearchTaskTypes();
    this.users$.subscribe((users) => {
      this.users = users;
      this.filteredAssignee = users;
    });
    this.taskForm.controls.assigneeSearchQuery.valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredAssignee = this.filterUser(query);
      });

    combineLatest([
      this.taskForm.controls.name.valueChanges,
      this.state.select('selectedStageInstanceDetail'),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe(([name, stageInstance]) => {
        if (stageInstance?.id && name !== '') {
          this.taskForm.controls.name.setErrors({ taskNameAlreadyTaken: true });

          this.taskFacade.dispatchGetTaskByName(name, stageInstance?.id);
        }
      });

    this.state.select('simiarTasks').subscribe((tasks) => {
      this.taskForm.controls.name.setErrors(
        tasks.length != 0 ? { taskNameAlreadyTaken: true } : null,
      );
    });

    this.taskForm.controls.taskTypeSearchQuery.valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((name) => {
        if (name !== '') {
          this.taskFacade.dispatchSearchTaskTypes(name);
          this.filteredTaskType.map((type) => {
            this.isAddTypeButtonVisible =
              name.toLowerCase() === type.name.toLowerCase() ? false : true;
          });
        } else {
          this.isAddTypeButtonVisible = false;
          this.taskFacade.dispatchSearchTaskTypes();
        }
      });

    this.state.select('datetime').subscribe((datetime) => {
      this.datetime = datetime;
      this.dateOnly = this.dateTimeService.getDateOnly(new Date(datetime));
    });
    this.filteredTaskType$.subscribe((types) => {
      this.filteredTaskType = types;
    });

    this.state.select('selectedStageInstance').subscribe((stage) => {
      this.selectedStageInstance = stage;
    });
  }

  private filterUser(query: string | null) {
    return this.users.filter((user) =>
      `${user.firstName} ${user.middleName} ${user.lastName}`
        .toLowerCase()
        .includes(query?.toLocaleLowerCase() || ''),
    );
  }

  selecteAssignee(event: MatAutocompleteSelectedEvent) {
    this.taskForm.controls.assignee.setValue(event.option.value);
    this.approverInput.nativeElement.value = '';
  }

  selectTaskType(event: MatAutocompleteSelectedEvent) {
    this.taskForm.controls.taskType.setValue(event.option.value);
    this.taskTypeInput.nativeElement.value = '';
  }

  removeAssignee() {
    this.taskForm.controls.assignee.setValue(undefined);
  }

  removetaskType() {
    this.taskForm.controls.taskType.setValue(undefined);
  }

  addNewTaskType() {
    const input = this.taskTypeInput.nativeElement as HTMLInputElement;
    const value = input.value.trim();
    if (value) {
      const newTaskType = { name: value };
      this.taskForm.patchValue({ taskType: newTaskType });

      input.value = '';
      this.taskForm.controls.taskTypeSearchQuery.setValue('');
      this.isAddTypeButtonVisible = false;
      this.taskFacade.dispatchSearchTaskTypes();
    }
  }

  submit() {
    const { selectedStageInstanceDetail } = this.state.get();
    const { valid, touched, dirty } = this.taskForm;
    if (valid && (touched || dirty)) {
      if (this.update && this.selectedTask) {
        const { name, description, assignee, startDate, endDate, taskType } =
          this.taskForm.value as unknown as Task;
        this.stageInstanceDetailFacade.dispatchUpdateTask({
          id: this.selectedTask.id,
          name,
          description,
          assignedTo: assignee!.id!,
          startDate,
          endDate,
          taskStatus: this.selectedTask.taskStatus,
          taskType,
          stageInstanceId: this.selectedTask.stageInstanceId,
          isMajorTask: false,
        });
        this.taskFacade.dispatchUpdate(false);
        this.taskFacade.dispatchResetSelectedTask();
        this.createEditToggle.value = this.createEditToggle.create;
        this.dialogRef.close('confirm');
        return;
      }
      if (selectedStageInstanceDetail) {
        const { name, description, assignee, startDate, endDate, taskType } =
          this.taskForm.value as any;
        this.taskFacade.dispatchCreateTask({
          name,
          description,
          assignedTo: assignee?.id,
          startDate,
          endDate,
          stageInstanceId: selectedStageInstanceDetail.id,
          taskType: taskType,
        });
        this.dialogRef.close('confirm');
      }
    }
    if (
      this.selectedStageInstance?.stageStatus ===
      StageInstanceStatus.ReadyToStart
    ) {
      this.stageInstanceDetailFacade.dispatchChangeStageStatus({
        id: this.selectedStageInstance.id,
        stageStatus: StageInstanceStatus.Inprogress,
      });
    }
  }

  validateSchedule(control: AbstractControl): ValidationErrors | null {
    const startDate = new Date(control.get('startDate')?.value ?? '');
    const endDate = new Date(control.get('endDate')?.value ?? '');

    let error = null;
    if (endDate < startDate) {
      error = { InvalidEndDate: true };
    }

    control.get('endDate')?.setErrors(error);
    return error;
  }
}
