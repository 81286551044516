import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  WORKFLOW_COMMENT_POLICY_URL,
  WORKFLOW_COMMENT_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ReportedWorkflowComment,
  RespondReportedWorkflowComment,
  WorkflowComment,
  WorkflowCommentPolicy,
  WorkflowCommentReply,
} from '../models/workflow-comment.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowCommentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  getWorkflowComments(
    workflowId: string,
    pageNumber: number | undefined,
    pageSize: number | undefined,
  ) {
    if (pageNumber !== undefined && pageSize !== undefined) {
      return this.httpClient.get<PaginatedList<WorkflowComment>>(
        `${WORKFLOW_COMMENT_URL}/workflows/${workflowId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    } else {
      return this.httpClient.get<PaginatedList<WorkflowComment>>(
        `${WORKFLOW_COMMENT_URL}/workflows/${workflowId}`,
      );
    }
  }

  getArchivedWorkflowComments(
    workflowId: string,
    pageNumber: number = 1,
    pageSize: number = 10,
  ) {
    return this.httpClient.get<PaginatedList<WorkflowComment>>(
      `${WORKFLOW_COMMENT_URL}/archived-workflows/${workflowId}`,
      { params: new HttpParams({ fromObject: { pageNumber, pageSize } }) },
    );
  }

  createWorkflowComment(workflowId: string, comment: string) {
    return this.httpClient.post<WorkflowComment>(`${WORKFLOW_COMMENT_URL}`, {
      workflowId,
      comment,
    });
  }

  deleteWorkflowComment(workflowId: string, workflowCommentId: string) {
    return this.httpClient.delete(`${WORKFLOW_COMMENT_URL}`, {
      body: {
        workflowId,
        workflowCommentId,
      },
    });
  }

  editWorkflowComment(
    workflowId: string,
    workflowCommentId: string,
    content: string,
  ) {
    return this.httpClient.put<WorkflowComment>(
      `${WORKFLOW_COMMENT_URL}`,
      {
        workflowId,
        workflowCommentId,
        content,
      },
      this.httpOptions,
    );
  }

  replyWorkflowComment(workflowCommentId: string, content: string) {
    return this.httpClient.post<WorkflowComment>(
      `${WORKFLOW_COMMENT_URL}/reply`,
      {
        workflowCommentId,
        content,
      },
      this.httpOptions,
    );
  }

  reportWorkflowComment(workflowCommentId: string, issueDescription: string) {
    return this.httpClient.put(`${WORKFLOW_COMMENT_URL}/reports`, {
      workflowCommentId,
      issueDescription,
    });
  }

  getWorkflowCommentPolicy() {
    return this.httpClient.get<WorkflowCommentPolicy>(
      `${WORKFLOW_COMMENT_POLICY_URL}`,
    );
  }

  createWorkflowCommentPolicy(description: string) {
    return this.httpClient.post<WorkflowCommentPolicy>(
      `${WORKFLOW_COMMENT_POLICY_URL}`,
      {
        description: description,
      },
      this.httpOptions,
    );
  }

  updateWorkflowCommentPolicy(id: string, description: string) {
    return this.httpClient.put<WorkflowCommentPolicy>(
      `${WORKFLOW_COMMENT_POLICY_URL}`,
      {
        id,
        description,
      },
      this.httpOptions,
    );
  }

  deleteWorkflowCommentPolicy(id: string) {
    return this.httpClient.delete<string>(
      `${WORKFLOW_COMMENT_POLICY_URL}/${id}`,
    );
  }

  getReportedWorkflowComments(pageNumber: number, pageSize: number) {
    return this.httpClient.get<PaginatedList<ReportedWorkflowComment>>(
      `${WORKFLOW_COMMENT_URL}/reports?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getWorkflowCommentsByReported(
    workflowCommentId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    if (pageNumber !== undefined && pageSize !== undefined) {
      return this.httpClient.get<PaginatedList<WorkflowComment>>(
        `${WORKFLOW_COMMENT_URL}/reports/${workflowCommentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    } else {
      return this.httpClient.get<PaginatedList<WorkflowComment>>(
        `${WORKFLOW_COMMENT_URL}/reports/${workflowCommentId}`,
      );
    }
  }

  resolveReportedWorkflowComment(
    respondReportedWorkflowComment: RespondReportedWorkflowComment,
  ) {
    return this.httpClient.put<ReportedWorkflowComment>(
      `${WORKFLOW_COMMENT_URL}/respond`,
      respondReportedWorkflowComment,
      this.httpOptions,
    );
  }

  updateWorkflowCommentReply(
    workflowCommentId: string,
    commentId: string,
    content: string,
  ) {
    return this.httpClient.put<WorkflowComment>(`${WORKFLOW_COMMENT_URL}/reply`, {
      workflowCommentId,
    commentId,
    content});
  }

  deleteWorkflowCommentReply(workflowCommentId: string, commentId: string): Observable<any> {
    return this.httpClient.delete<any>(`${WORKFLOW_COMMENT_URL}/reply`, {
      params: {
        workflowCommentId: workflowCommentId,
        commentId: commentId
      }
    });
  }
}
