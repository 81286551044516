import { Component } from '@angular/core';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Role } from 'src/app/users/models/role.model';
import { User } from 'src/app/users/models/user.model';
import { Memo } from '../../models/memo.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, filter } from 'rxjs';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { MemoFacade } from '../../facade/memo.facades';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { MatSelectChange } from '@angular/material/select';

interface MemoCcFormComponentState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  roles: Role[];
  filteredUsers: User[];
  selectedMemo: Memo | null;
}

const initMemoCcFormComponentState: MemoCcFormComponentState = {
  selectedFlatOfficeNode: undefined,
  roles: [],
  filteredUsers: [],
  selectedMemo: null,
};

@Component({
  selector: 'app-memo-cc-form',
  templateUrl: './memo-cc-form.component.html',
  styleUrls: ['./memo-cc-form.component.scss'],
})
export class MemoCcFormComponent {
  selectedUser: User | null = null;
  selectedRole: Role | null = null;

  selectedMemo$: Observable<Memo | null> = this.state.select('selectedMemo');
  selectedMemo: Memo | null = null;

  selectedFlatOfficeNode: FlatOfficeNode | undefined = undefined;
  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.state.select('selectedFlatOfficeNode');

  roles: Role[] = [];
  roles$: Observable<Role[]> = this.state.select('roles');

  filteredUsers: User[] = [];
  filterdUsers$: Observable<User[]> = this.state.select('filteredUsers');

  constructor(
    private memoFacade: MemoFacade,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private router: Router,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private officeFacade: OfficeFacade,
    private state: RxState<MemoCcFormComponentState>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.userFacade.dispachSetFilterdUsersEmpty();
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);

    this.state.set(initMemoCcFormComponentState);
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect('roles', roleFacade.roles$);
    this.state.connect('filteredUsers', userFacade.filterdUsers$);
    this.state.connect('selectedMemo', this.memoFacade.selectedMemo$);
  }

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });

    this.selectedMemo$.subscribe((memo) => {
      this.selectedMemo = memo;
    });
    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.filterdUsers$.subscribe(
      (filterdUsers) => (this.filteredUsers = filterdUsers),
    );
    this.filterdUsers$.subscribe((filterdUsers) => {
      this.filteredUsers = filterdUsers;
    });
  }

  onUserSelect(event: MatSelectChange) {
    this.selectedUser = event.value;
  }

  onRoleSelect(event: MatSelectChange) {
    this.selectedRole = event.value;
    const filterOfficeList = [];
    if (this.selectedFlatOfficeNode)
      filterOfficeList.push(this.selectedFlatOfficeNode.id);
    this.userFacade.dispachGetUsersByRolesAndOffices(
      [event.value.id],
      filterOfficeList,
    );
  }

  removeSelectedRole() {
    this.selectedRole = null;
  }
  removeSelectedUser() {
    this.selectedUser = null;
  }

  removeSelectedOffice() {
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
  }

  openSingleOfficeSelector() {
    this.dialog.open(OfficeTreeComponent, {
      data: {
        update: false,
      },
    });
  }

  save() {
    if (!this.selectedMemo) return;
    if (this.selectedUser?.id)
      this.memoFacade.dispatchAddCcUserToMemo(
        this.selectedMemo.id,
        this.selectedUser.id,
      );
    else return;
  }
}
