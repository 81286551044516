import { Component, OnInit } from '@angular/core';
import { WorkflowsVsTime } from '../../models/workflows-vs-time.model';
import { EChartsOption } from 'echarts';
import { DocumentsAnalyticsFacade } from '../../facades/documents-analytics.facade';
import { RxState } from '@rx-angular/state';
import { MONTHS } from 'src/app/core/constants/months';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { MatDialog } from '@angular/material/dialog';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { CurrentLoggedInUser, User } from 'src/app/users/models/user.model';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

interface WorkflowsTimeGraphComponentState {
  workflowsVsTime: WorkflowsVsTime[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  users: User[];
  workflowTypes: PaginatedList<WorkflowTypes> | undefined;
  currentLoggedInUser?: CurrentLoggedInUser;
}

const initWorkflowsTimeGraphComponentState: Partial<WorkflowsTimeGraphComponentState> =
  {
    workflowsVsTime: [],
    selectedFlatOfficeNode: undefined,
    users: [],
  };

@Component({
  selector: 'app-workflows-time-graph',
  templateUrl: './workflows-time-graph.component.html',
  styleUrls: ['./workflows-time-graph.component.scss'],
})
export class WorkflowsTimeGraphComponent implements OnInit {
  chartOption!: EChartsOption;
  workflowsVsTime$ = this.state.select('workflowsVsTime');
  workflowsVsTime: WorkflowsVsTime[] = [];

  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  users: User[] = [];
  users$ = this.state.select('users');

  workflowTypes$ = this.state.select('workflowTypes');
  workflowTypes: WorkflowTypes[] = [];

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser?:CurrentLoggedInUser;

  filterForm: FormGroup;

  constructor(
    private documentsAnalyticsFacade: DocumentsAnalyticsFacade,
    private state: RxState<WorkflowsTimeGraphComponentState>,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
    private userFacade: UserFacade,
    private workflowTypesFacade: WorkflowTypesFacade,
    private fb: FormBuilder,
  ) {
    this.state.set(initWorkflowsTimeGraphComponentState);
    this.state.connect(
      'workflowsVsTime',
      this.documentsAnalyticsFacade.workflowsVsTime$,
    );
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect('users', this.userFacade.users$);
    this.state.connect(
      'workflowTypes',
      this.workflowTypesFacade.workflowTypes$,
    );

    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );

    this.filterForm = this.fb.group({
      user: [null],
      workflowType: [null],
      startDate: [],
      endDate: [],
    });

    this.filterForm.get('user')?.valueChanges.subscribe((user) => {
      this.onUserChange();
    });

    this.filterForm
      .get('startDate')
      ?.valueChanges.subscribe((startDateValue) => {
        this.handleDateRangeChange();
      });

    this.filterForm.get('endDate')?.valueChanges.subscribe((endDateValue) => {
      this.handleDateRangeChange();
    });

    this.filterForm
      .get('workflowType')
      ?.valueChanges.subscribe((workflowType) => {
        this.onWorkflowTypeChange();
      });
  }

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser
    });

    this.userFacade.dispatchGetUsersByOfficeId(this.currentLoggedInUser?.officeId);
    this.users$.subscribe((users) => {
      this.users = users;
    });

    this.workflowTypesFacade.dispatchGetWorkflowTypes();

    this.workflowTypes$.subscribe((workflowTypes) => {
      if (workflowTypes) {
        this.workflowTypes = workflowTypes.items;
      }
    });

    this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData();
    this.workflowsVsTime$.subscribe((data) => {
      this.workflowsVsTime = data;
      const years = [...new Set(this.workflowsVsTime.map((item) => item.year))];
      const months = [
        MONTHS.Jan,
        MONTHS.Feb,
        MONTHS.Mar,
        MONTHS.Apr,
        MONTHS.May,
        MONTHS.Jun,
        MONTHS.Jul,
        MONTHS.Aug,
        MONTHS.Sep,
        MONTHS.Oct,
        MONTHS.Nov,
        MONTHS.Dec,
      ];
      this.chartOption = {
        xAxis: {
          type: 'category',
          data: months.map((monthValue) => {
            const monthKey = Object.keys(MONTHS).find(
              (key) => MONTHS[key as keyof typeof MONTHS] === monthValue,
            );
            return monthKey || '';
          }),
        },
        yAxis: {
          type: 'value',
          interval: 1,
        },
        series: years.map((year) => ({
          name: year.toString(),
          type: 'line',
          data: months.map((month) => {
            const dataForMonth = this.workflowsVsTime.find(
              (item) => item.year === year && item.month === month,
            );
            return dataForMonth ? dataForMonth.numberOfWorkflows : 0;
          }),
          itemStyle: {
            color: `rgb(0, 0, ${Math.floor(Math.random() * 256)})`,
          },
        })),
        legend: {
          data: years.map((year) => year.toString()),
        },
      };
    });
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.handleSelectedFlatOfficeNodeChange();
    });
  }

  handleSelectedFlatOfficeNodeChange() {
    if (this.selectedFlatOfficeNode?.id) {
      this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData(
        this.selectedFlatOfficeNode?.id,
        this.filterForm.get('user')?.value?.id,
        this.filterForm.get('startDate')?.value,
        this.filterForm.get('endDate')?.value,
        this.filterForm.get('workflowType')?.value?.id,
      );
      this.userFacade.dispatchGetUsersByOfficeId(
        this.selectedFlatOfficeNode?.id,
      );
      this.users$.subscribe((users) => {
        this.users = users;
      });
    }
  }

  resetFilter() {
    this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData();
    this.officeFacade.dispatchResetSelectedOffice();
    this.filterForm.get('startDate')?.setValue('');
    this.filterForm.get('endDate')?.setValue('');
    this.filterForm.get('user')?.setValue('');
    this.filterForm.controls['workflowType'].setValue('');
  }

  onUserChange() {
    this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData(
      this.selectedFlatOfficeNode?.id,
      this.filterForm.get('user')?.value?.id,
      this.filterForm.get('startDate')?.value,
      this.filterForm.get('endDate')?.value,
      this.filterForm.get('workflowType')?.value?.id,
    );
  }

  handleDateRangeChange() {
    const startDate = this.filterForm.get('startDate')?.value;
    const endDate = this.filterForm.get('endDate')?.value;

    if (startDate && endDate) {
      this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData(
        this.selectedFlatOfficeNode?.id,
        this.filterForm.get('user')?.value?.id,
        startDate,
        endDate,
        this.filterForm.get('workflowType')?.value?.id,
      );
    }
  }

  onWorkflowTypeChange() {
    this.documentsAnalyticsFacade.dispatchGetWorkflowsVsTimeData(
      this.selectedFlatOfficeNode?.id,
      this.filterForm.get('user')?.value?.id,
      this.filterForm.get('startDate')?.value,
      this.filterForm.get('endDate')?.value,
      this.filterForm.get('workflowType')?.value?.id,
    );
  }

  removeSelectedOffice() {
    this.selectedFlatOfficeNode = undefined;
  }
}
