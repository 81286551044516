import {
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export class EmailValidator {
  static isValidEmail() {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = Validators.email(control);
      if (error) {
        return { EmailIsValid: 'Field must be a valid email address' };
      }

      return null;
    };
  }
}
