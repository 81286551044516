import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { IntegerValidator } from './integer.validator';

export class DecimalValidator extends IntegerValidator {
  static hasPrecision(precision: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value || Number.isNaN(+value)) return null;

      if (value.indexOf('.') !== -1) {
        const decimalPart = value.split('.')[1];

        if (decimalPart.length == precision) {
          return null;
        }
      }
      return { hasPrecision: `Field must be a precision of ${precision}.` };
    };
  }

  static isValidDecimal(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value) return null;
      if (!Number.isNaN(+value)) return { isValidDecimal: `Field must be a valid decimal number.` };
      return null;
    };
  }
}
