import { ProcessInstance } from '../../models/stage-instance-detail.model';

export interface Tor {
  id?: string;
  title: string;
  initiatorName:string;
  firstApproverUserName: string;
  lastApproverUserName: string;
  status: TorStatus;
  statusForDisplay: TorStatusForDisplay;
  totalCost: number;
  stageInstance: TorStageInstance;
  currency?: string;
}

export interface TorStatusForDisplay{
  id: string;
  status: string
}

export enum TorStatus {
  Todo = 'Draft',
  Submitted1 = 'SubmittedToFirstApprover',
  Submitted2 = 'SubmittedToSecondApprover',
  Approved = 'Approved',
  Rejected1 = 'DeclinedByFirstApprover',
  Rejected2 = 'DeclinedBySecondApprover',
  Revision = 'NeedsRevision',
}

export interface TorDetail {
  id: string;
  title: string;
  status: TorStatus;
  statusForDisplay: TorStatusForDisplay;
  description: string;
  firstApproverUserName: string;
  lastApproverUserName: string;
  stageInstanceName: string;
  numberOfTorsInStage: number;
  numberOfApprovedTorsInStage: number;
  processInstanceName: string;
  processInstanceOwnerId:string;
  numberOfTorsInProcess: number;
  numberOfApprovedTorsInProcess: number;
  currency?:string
}

export interface TorPdfFile {
  fileName: string;
  fileType: string;
  fileContent: string;
}

export interface TorStageInstance {
  id: string;
  name: string;
  description: string;
  processInstance: TorProcessInstance;
}

export interface TorProcessInstance {
  id: string;
  description: string;
  title: string;
}

export interface TorStatusChange {
  id: string;
  status: TorStatus;
  comment?: string | null;
}

export interface TorComment {
  id?: string;
  content: string;
  created: string;
  createdBy:string;
  replies: TorComment[];
  fullName: string;
}

export interface TorCommentReply {
  id?: string;
  content: string;
  created: string;
  fullName: string;
}
