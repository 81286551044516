import { UpdateWorkflowStep } from '../models/workflow-step.model';

export class GetWorkflowStep {
  static readonly type = `[WorkflowStep] ${GetWorkflowStep.name}`;
  constructor(public readonly workflowId: string) {}
}
export class GetArchivedWorkflowStep {
  static readonly type = `[WorkflowStep] ${GetArchivedWorkflowStep.name}`;
  constructor(public readonly workflowId: string) {}
}
export class GetWorkflowSteps {
  static readonly type = `[WorkflowStep] ${GetWorkflowSteps.name}`;
  constructor(public readonly workflowId: string) {}
}

export class GetArchivedWorkflowSteps {
  static readonly type = `[WorkflowStep] ${GetArchivedWorkflowSteps.name}`;
  constructor(public readonly workflowId: string) {}
}

export class WorkflowStepUpdate {
  static readonly type = `[WorkflowStepAction] ${WorkflowStepUpdate.name}`;
  constructor(public readonly workflowStep: UpdateWorkflowStep) {}
}

export class WorkflowStepUpdateStatus {
  static readonly type = `[WorkflowStepAction] ${WorkflowStepUpdateStatus.name}`;
  constructor(
    public readonly workflowStepStatus: {
      stepId: string;
      action: string;
      comment?: string;
      rejectionRemark?: string;
    },
  ) {}
}

export class GetNotifiedUsers {
  static readonly type = `[Workflow] ${GetNotifiedUsers.name}`;
  constructor(
    public readonly workflowStepId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}
export class DownloadWorkflowStep {
  static readonly type = `[workflow] ${DownloadWorkflowStep.name}`;
  constructor(
    public readonly workflowStepId: string,
    public readonly workflowStepName: string,
  ) {}
}
export class RemoveNotifiedUser {
  static readonly type = `[Workflow] ${RemoveNotifiedUser.name}`;
  constructor(
    public readonly worflowStepId: string,
    public readonly userId: string,
  ) {}
}
export class AttachUserToStep {
  static readonly type = `[workflow] ${AttachUserToStep.name}`;
  constructor(
    public id: string,
    public notifiedUsers: string[],
  ) {}
}
export class AssignUserToWorkflowStep {
  static readonly type = `[Workflow] ${AssignUserToWorkflowStep.name}`;
  constructor(
    public readonly workflowStepId: string,
    public readonly userId: string,
    public readonly workflowId: string,
  ) {}
}

export class GetCcdWorkflowStepDetail {
  static readonly type = `[workflow] ${GetCcdWorkflowStepDetail.name}`;
  constructor(public readonly workflowStepId: string) {}
}

export class GetSelectedWorkflowStep {
  static readonly type = `[workflow] ${GetSelectedWorkflowStep.name}`;
  constructor(public readonly workflowStepId: string) {}
}

export class UploadWorkflowStepFile {
  static readonly type = `[workflow] ${UploadWorkflowStepFile.name}`;
  constructor(public file: File, public workflowStepId: string) {}
}

export class GetWorkflowStepFiles {
  static readonly type = `[workflow] ${GetWorkflowStepFiles.name}`;
  constructor(public workflowStepId: string) {}
}

export class DeleteWorkflowStepFile {
  static readonly type = `[workflow] ${DeleteWorkflowStepFile.name}`;
  constructor(public fileId: string) {}
}

export class PreviewWorkflowStepFile {
  static readonly type = `[workflow] ${PreviewWorkflowStepFile.name}`;
  constructor(
    public readonly fileId: string,
    public readonly fileName: string
  ) {}
}