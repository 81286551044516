<mat-dialog-content mat-dialog-content class="form-container" *appHasPermission="hasDeleteProcessPermission()">
  <form class="add-member-form" [formGroup]="deleteProcessForm">
    <h3 class="title" i18n="@@researches.delete-process-instance-form.delete-process">Delete Process.</h3>
    <mat-form-field class="add-member-form-field" appearance="outline">
      <mat-label i18n="@@researches.delete-process-instance-form.add-notified-users">Notified Users</mat-label>
      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #notifiedUserChipGrid aria-label="Team Members">
        <mat-chip-row
          *ngFor="let notifiedUser of deleteProcessForm.value.notifiedUsers"
          (removed)="removeNotifiedUser(notifiedUser)"
        >
          {{ notifiedUser?.firstName }}
          {{ notifiedUser?.middleName }}
          {{ notifiedUser?.lastName }}
          <button
            matChipRemove
            [attr.aria-label]="
              'remove ' + notifiedUser?.firstName + ' ' + notifiedUser?.middleName + ' ' + notifiedUser?.lastName
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #notifiedUsersInput
        [placeholder]="placeholderToggleLabel.searchNotifiedUsersAdd"
        [placeholder]="
          filteredNotifiedUsers.length > 0
            ? placeholderToggleLabel.searchNotifiedUsersAdd
            : placeholderToggleLabel.allAvailableUsersAdded
        "
        formControlName="notifiedUsersSearchQuery"
        [matChipInputFor]="notifiedUserChipGrid"
        [matAutocomplete]="auto"
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectNotifiedUser($event)"
      >
        <mat-option
          *ngFor="let notifiedUser of filteredNotifiedUsers"
          [value]="notifiedUser"
        >
          {{ notifiedUser?.firstName }} {{ notifiedUser?.middleName }}{{ notifiedUser?.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <mat-card class="members-list-card" appearance="outlined" *ngIf="notifiedUsers.length>0">
    <mat-card-content class="members-list-content">
      <mat-list>
        <div *ngFor="let item of notifiedUsers">
          <div class="members-list-item">
            <div>
              <p>{{ item.firstName + " " + item.middleName + " " + item.lastName }}</p>
              <mat-card-subtitle i18n="@@researches.add-team-member-form.role">Role : </mat-card-subtitle>
            </div>
            <div >
              <button mat-icon-button (click)="onDeleteNotifiedUserClicked(item)">
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.add-team-member-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="deleteProcess()"
      i18n="@@researches.delete-process-instance-form.delete"
      [attr.data-test-id]="
            'researches.delete-archive-process-instance.process-instance-notify-form.process-instance-delete-archive-button'
          "
    >
      Delete
    </button>
  </div>
</mat-dialog-content>
