import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ExternalDocument,
  ExternalDocumentDetailDto,
  ExternalDocumentDto,
  ExternalDocumentResponseDetailDto,
  RemarkActionStatusForDisplay,
  SendRemarkDto,
} from '../models/external-document.model';
import { EXTERNAL_DOCUMENT_URL } from 'src/app/core/constants/api-endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalDocumentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient) {}

  getExternalDocuments(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(
      `${EXTERNAL_DOCUMENT_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  getExternalDocumentResponses(
    pageNumber: number,
    pageSize: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${EXTERNAL_DOCUMENT_URL}/get-responded-documents?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  registerExternalDocument(externalDocument: FormData): Observable<any> {
    return this.http.post<any>(`${EXTERNAL_DOCUMENT_URL}`, externalDocument);
  }

  sendRemark(
    remark: SendRemarkDto,
    externalDocumentId: string,
  ): Observable<any> {
    return this.http.post<any>(
      `${EXTERNAL_DOCUMENT_URL}/remark/${externalDocumentId}`,
      remark,
    );
  }

  sendResponseRemark(
    remark: SendRemarkDto,
    responseId: string,
  ): Observable<any> {
    return this.http.post<any>(
      `${EXTERNAL_DOCUMENT_URL}/response/remark/${responseId}`,
      remark,
    );
  }

  getExternalDocumentDetail(
    externalDocumentId: string,
  ): Observable<ExternalDocumentDetailDto> {
    return this.http.get<ExternalDocumentDetailDto>(
      `${EXTERNAL_DOCUMENT_URL}/${externalDocumentId}`,
    );
  }

  getExternalDocumentResponseDetail(
    responseId: string,
  ): Observable<ExternalDocumentResponseDetailDto> {
    return this.http.get<ExternalDocumentResponseDetailDto>(
      `${EXTERNAL_DOCUMENT_URL}/response/${responseId}`,
    );
  }

  deleteExternalDocument(externalDocumentId: string): Observable<any> {
    return this.http.delete<any>(
      `${EXTERNAL_DOCUMENT_URL}/${externalDocumentId}`,
    );
  }

  deleteExternalDocumentResponse(externalDocumentId: string): Observable<any> {
    return this.http.delete<any>(
      `${EXTERNAL_DOCUMENT_URL}/response/${externalDocumentId}`,
    );
  }

  updateExternalDocument(externalDocument: FormData): Observable<any> {
    return this.http.put<any>(`${EXTERNAL_DOCUMENT_URL}`, externalDocument);
  }

  createResponse(response: FormData, send: boolean) {
    return this.http.post<any>(
      `${EXTERNAL_DOCUMENT_URL}/response?send=${send}`,
      response,
    );
  }

  updateResponse(response: FormData, send: boolean) {
    return this.http.post<any>(
      `${EXTERNAL_DOCUMENT_URL}/response/update?send=${send}`,
      response,
    );
  }

  getAttachment(documentId: string, fileId: string): Observable<any> {
    return this.http.get(
      `${EXTERNAL_DOCUMENT_URL}/${documentId}/attachment/${fileId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getResponseAttachment(documentId: string, fileId: string): Observable<any> {
    return this.http.get(
      `${EXTERNAL_DOCUMENT_URL}/response/${documentId}/attachment/${fileId}`,
      {
        responseType: 'blob',
      },
    );
  }

  getRemarkStatusEnum(): Observable<RemarkActionStatusForDisplay[]> {
    return this.http.get<RemarkActionStatusForDisplay[]>(
      `${EXTERNAL_DOCUMENT_URL}/remark-action-status`,
    );
  }
}
