import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { EChartsOption } from 'echarts';
import { User } from 'src/app/users/models/user.model';
import { Role } from 'src/app/users/models/role.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { MatDialog } from '@angular/material/dialog';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { MONTHS } from 'src/app/core/constants/months';
import { PublicationData } from '../../models/research-vs-time.model';

interface ReseachAnalyticsState {
  chartData: PublicationData[];
  roles: Role[];
  users: User[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
}

const initReseachAnalyticsState: ReseachAnalyticsState = {
  chartData: [],
  roles: [],
  users: [],
  selectedFlatOfficeNode: undefined,
};

@Component({
  selector: 'app-publication-vs-time',
  templateUrl: './publication-vs-time.component.html',
  styleUrls: ['./publication-vs-time.component.scss'],
})
export class PublicationVsTimeComponent implements OnInit {
  publicationData$ = this.state.select('chartData');
  chartOption!: EChartsOption;
  roles: Role[] = [];
  roles$ = this.state.select('roles');
  users: User[] = [];
  users$ = this.state.select('users');
  legend: { data: string[] } = { data: [] };

  filterForm: FormGroup;
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  constructor(
    private state: RxState<ReseachAnalyticsState>,
    private researchDashboardFacade: ResearchDashboardFacade,
    private matDialog: MatDialog,
    private roleFacade: RoleFacade,
    private userFacade: UserFacade,
    private fb: FormBuilder,
    private officeFacade: OfficeFacade,
  ) {
    this.state.set(initReseachAnalyticsState);
    this.state.connect(
      'chartData',
      this.researchDashboardFacade.publicationData$,
    );

    this.state.connect('roles', this.roleFacade.roles$);
    this.state.connect('users', this.userFacade.users$);
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.filterForm = this.fb.group({
      selectedOffice: [''],
      roles: [[]],
      user: [undefined],
      month: new Date().getUTCMonth() + 1,
      year: new Date().getUTCFullYear(),
    });
  }

  ngOnInit() {
    this.researchDashboardFacade.dispatchGetResearchPublicationData();
    this.publicationData$.subscribe((data) => {
      this.chartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: data.map((item) => item.publicationCategory),
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: Array(12)
              .fill(0)
              .map((_, index) => {
                const date = new Date();
                date.setMonth(index);

                return date.toLocaleString('en-US', {
                  month: 'short',
                });
              }),
          },
        ],
        yAxis: [
          {
            type: 'value',
            interval: 1,
          },
        ],
        series: this.mapPublicationDataToSeries(data),
      };
    });

    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });
    this.users$.subscribe((users) => {
      this.users = users;
    });
    this.selectedFlatOfficeNode$.subscribe((office) => {
      this.selectedFlatOfficeNode = office;
    });
  }

  mapPublicationDataToSeries(data: PublicationData[]): any[] {
    const counts: { [category: string]: number[] } = {};
    data.forEach((item) => {
      const category = item.publicationCategory;
      const month = item.publicationMonth - 1;
      counts[category] = counts[category] || Array(12).fill(0);
      counts[category][month] += item.publicationCount;
    });
    return Object.keys(counts).map((category) => {
      return {
        name: category,
        type: 'bar',
        data: counts[category],
      };
    });
  }
}
