import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { UserFacade } from '../../facade/user.facade';
import { User, UserUpdate } from '../../models/user.model';
import { RoleFacade } from '../../facade/role.facade';
import { Role } from '../../models/role.model';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';

export enum RegistrationType {
  EMPLOYEE = 'Employee',
  USER = 'User',
  ADMIN = 'Admin',
}

export interface ValidationResult {
  [key: string]: boolean;
}

interface SelectedUserComponentState {
  selectedUser: User | undefined;
  closeRegistrationModal: boolean;
}

interface RoleListComponentState {
  roles: Role[];
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  flatOfficeNodes: FlatOfficeNode[];
}

const initSelectedUserComponentState: Partial<SelectedUserComponentState> = {
  selectedUser: undefined,
  closeRegistrationModal: false,
};

const initRoleListComponentState: Partial<RoleListComponentState> = {
  roles: [],
  selectedFlatOfficeNode: undefined,
  flatOfficeNodes: [],
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [RxState],
})
export class UserFormComponent implements OnInit {
  update = false;
  registrationType?: RegistrationType = RegistrationType.USER;

  userForm: FormGroup;

  selectedUser$: Observable<User | undefined> =
    this.state.select('selectedUser');

  roles$: Observable<Role[]> = this.roleState.select('roles');

  roles: Role[] = [];

  maxBirthDate: Date = new Date();
  selectedFlatOfficeNode$: Observable<FlatOfficeNode | undefined> =
    this.roleState.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.roleState.select('flatOfficeNodes');
  flatOfficeNodes: FlatOfficeNode[] = [];
  closeRegistrationModal$: Observable<boolean> = this.state.select(
    'closeRegistrationModal',
  );
  closeRegistrationModal: boolean = false;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      update: boolean;
      registrationType?: RegistrationType;
    },
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private state: RxState<SelectedUserComponentState>,
    private roleState: RxState<RoleListComponentState>,
    private officeFacade: OfficeFacade,
    private matDialog: MatDialog,
  ) {
    this.state.set(initSelectedUserComponentState);
    this.roleState.set(initRoleListComponentState);
    this.state.connect('selectedUser', userFacade.selectedUser$);
    this.roleState.connect('roles', roleFacade.roles$);
    this.roleState.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    this.roleState.connect(
      'flatOfficeNodes',
      this.officeFacade.flatOfficeNodes$,
    );
    this.state.connect(
      'closeRegistrationModal',
      this.userFacade.closeRegistrationModal$,
    );
    this.update = this.data.update;
    this.registrationType = this.data.registrationType || RegistrationType.USER;

    const today = new Date();
    this.maxBirthDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
    );

    this.userForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[a-zA-Z ]*$'),
        ],
      ],
      middleName: [
        '',
        [Validators.required,Validators.minLength(3), Validators.pattern('^[a-zA-Z ]*$')],
      ],
      lastName: [
        '',
        [Validators.required,Validators.minLength(3), Validators.pattern('^[a-zA-Z ]*$')],
      ],
      userName: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9-@._]+$'),
          Validators.minLength(3),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(/^(9|7)[0-9]{8}$/)],
      ],
      roleId: ['', this.isAdminRegistration() && Validators.required],
      officeId: ['', Validators.required],
      dateOfBirth: null,
    });
  }

  ngOnInit(): void {
    this.roleFacade.dispatchGetRoles();
    this.officeFacade.dispatchGetFlatOfficeNodes();
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      selectedFlatOfficeNode &&
        this.userForm.controls['officeId'].setValue(selectedFlatOfficeNode.id);
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });
    if (this.update) {
      this.selectedUser$.subscribe((user) => {
        if (user) {
          this.userForm.patchValue({
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName,
            userName: user.userName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            roleId: user.roleId,
            dateOfBirth: user.dateOfBirth,
            officeId: user.officeId,
          });

          this.flatOfficeNodes$.subscribe((offices) => {
            this.selectedFlatOfficeNode = offices.find(
              (office) => office.id === user.officeId,
            );
          });
        }
      });
    }
    this.closeRegistrationModal$.subscribe((close) => {
      if (close) {
        this.matDialog.closeAll();
      }
    });
  }

  isUserRegistration(): boolean {
    return this.registrationType === RegistrationType.USER;
  }

  isAdminRegistration(): boolean {
    return this.registrationType === RegistrationType.ADMIN;
  }

  getRole(): String {
    switch (this.registrationType) {
      case RegistrationType.USER:
        return this.roles.find((role) => role.name === RegistrationType.USER)!
          .id!;

      case RegistrationType.EMPLOYEE:
        return this.roles.find(
          (role) => role.name === RegistrationType.EMPLOYEE,
        )!.id!;
      default:
        return '';
    }
  }

  save() {
    const { valid, touched, dirty } = this.userForm;
    if (valid && (touched || dirty)) {
      if (!this.update) {
        const user: User = {
          ...this.userForm.value,
          roleId: !this.isAdminRegistration()
            ? this.getRole()
            : this.userForm.value.roleId,
        };

        this.userFacade.dispatchRegisterUser(user);
      }
      if (this.update) {
        const userUpdate: UserUpdate = {
          ...this.userForm.value,
        };
        this.selectedUser$.subscribe((user) => {
          if (user && user.id) {
            userUpdate.id = user.id;
            this.userFacade.dispatchUpdateUser(userUpdate);
          }
        });
      }
    }
  }

  openSingleOffice() {
    this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { update: this.update },
    });
  }
  removeSelectedFlatOfficeNode() {
    this.officeFacade.dispatchResetSelectedOffice();
    this.userForm.controls['officeId'].setValue("")
  }

  trimEmail() {
    const emailControl = this.userForm.get('email');
    if (emailControl?.value && typeof emailControl.value === 'string') {
      emailControl.setValue(emailControl.value.trim());
    }
  }
}
