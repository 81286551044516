export const RESEARCH_OWNER_ROLES = ['User', 'SuperAdmin'];
export enum CURRENCY {
  Etb = 'ETB',
  Usd = 'USD',
}

export enum WorkflowStepAction {
  Submitted = 'Submitted',
  Draft = 'Draft',
  Rollbacked = 'Rollbacked',
  Rejected = 'Rejected',
}

export enum WorkflowStatus {
  InProgress = 'InProgress',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
}

export enum ErrorDetail {
  UnAuthorized = 'Unauthorized',
  LockedOut = 'LockedOut',
  InvalidRefreshToken = 'Invalid refresh token',
}
