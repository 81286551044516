import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { phone } from 'phone';

export class PhoneValidator {
  static isValidPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (value.startsWith('+251')) {
        const length = value.length;

        if (length === 13) {
          const localPrefix = value.slice(4, 5); 
          const validPrefixes = ['9', '7'];     

          if (validPrefixes.includes(localPrefix)) {
            const valueToValidate = localPrefix === '7'
              ? value.replace('+2517', '+2519') 
              : value;
            return phone(valueToValidate).isValid
              ? null 
              : { isValidPhone: `Field must be a valid phone number.` }; 
          } else {
            return { isValidPhone: `Field must be a valid phone number.` };
          }
        } else {
          return { isValidPhone: `Field must be a valid phone number.` };
        }
      }

      return phone(value).isValid
        ? null
        : { isValidPhone: `Field must be a valid phone number.` };
    };
  }

  static countryCodeEquals(countryCode: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      if (value.startsWith('+251')) {
        const length = value.length;

        if (length === 13) {
          const localPrefix = value.slice(4, 5); 
          const validPrefixes = ['9', '7'];     

          if (validPrefixes.includes(localPrefix)) {
            const valueToValidate = localPrefix === '7'
              ? value.replace('+2517', '+2519') 
              : value;
            return phone(valueToValidate).isValid
              ? null 
              : { isValidPhone: `Field must be a valid phone number.` }; 
          } else {
            return { isValidPhone: `Field must be a valid phone number.` };
          }
        } else {
          return { isValidPhone: `Field must be a valid phone number..` };
        }
      }

      return phone(value).countryCode == countryCode
        ? null
        : {
            countryCodeEquals: `Field must be a country code of ${countryCode}.`,
          };
    };
  }
}
