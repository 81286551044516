import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ResearchVsTimeGraphComponent } from './components/research-vs-time-graph/research-vs-time-graph.component';
import { NgxsModule } from '@ngxs/store';
import { ResearchDashboardState } from './store/research-dashboard.state';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResearchDashboardHomeComponent } from './components/research-dashboard-home/research-dashboard-home.component';
import { ProcessesVsEvaluationComponent } from './components/processes-vs-evaluation/processes-vs-evaluation.component';
import { OfficeVsEvaluationComponent } from './components/office-vs-evaluation/office-vs-evaluation.component';
import { CommunicationTimeGraphComponent } from './components/communication-time-graph/communication-time-graph.component';
import { DepartmentCommunicationsComponent } from './components/department-communications/department-communications.component';
import { DepartmentResearchComponent } from './components/department-research/department-research.component';
import { BudgetTimeGraphComponent } from './components/budget-time-graph/budget-time-graph.component';
import { PublicationVsTimeComponent } from './components/publication-vs-time/publication-vs-time.component';
import { FullLengthResearchesComponent } from './components/full-length-researches/full-length-researches.component';
import { ResearchCommuncationsComponent } from './components/research-communcations/research-communcations.component';
import { FullLengthResearchCountComponent } from './components/full-length-research-count/full-length-research-count/full-length-research-count.component';
import { BudgetPerCommunicationComponent } from './components/budget-per-communication/budget-per-communication.component';
import { CommunicationsWithStatusComponent } from './components/communications-with-status/communications-with-status.component';
import { CommunicationsCountComponent } from './components/communications-count/communications-count/communications-count.component';
import { CommunicationTypeInstancesComponent } from './components/communication-type-instances/communication-type-instances.component';
import { ResearchEvaluationsComponent } from './components/research-evaluations/research-evaluations.component';
import { TaskAccomplishmentVsTimeComponent } from './components/task-accomplishment-vs-time/task-accomplishment-vs-time.component';
import { PublishedProcessVsCategoryComponent } from './components/published-process-vs-category/published-process-vs-category.component';
import { PublicationCategoriesCountComponent } from './components/publication-categories-count/publication-categories-count/publication-categories-count.component';
import { DepartmentVsPublicationComponent } from './components/department-vs-publication/department-vs-publication.component';
@NgModule({
  declarations: [
    ResearchVsTimeGraphComponent,
    ResearchDashboardHomeComponent,
    ProcessesVsEvaluationComponent,
    OfficeVsEvaluationComponent,
    CommunicationTimeGraphComponent,
    DepartmentCommunicationsComponent,
    DepartmentResearchComponent,
    PublicationVsTimeComponent,
    FullLengthResearchesComponent,
    ResearchCommuncationsComponent,
    BudgetPerCommunicationComponent,
    BudgetTimeGraphComponent,
    PublicationVsTimeComponent,
    FullLengthResearchCountComponent,
    CommunicationsWithStatusComponent,
    CommunicationsCountComponent,
    CommunicationTypeInstancesComponent,
    ResearchEvaluationsComponent,
    TaskAccomplishmentVsTimeComponent,
    PublishedProcessVsCategoryComponent,
    PublicationCategoriesCountComponent,
    DepartmentVsPublicationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    NgxsModule.forFeature([ResearchDashboardState]),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
})
export class DashboardModule {}
