import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  StageInstanceState,
  StageInstanceStateModel,
} from './stage-instance.state';

export class StageInstanceSelector {
  static slices =
    createPropertySelectors<StageInstanceStateModel>(StageInstanceState);

  @Selector([StageInstanceState])
  static selectedStageInstance(state: StageInstanceStateModel) {
    return state.selectedStageInstance;
  }

  @Selector([StageInstanceState])
  static selectedAssignedStageInstance(state: StageInstanceStateModel) {
    return state.selectedAssignedStageInstance;
  }

  @Selector([StageInstanceState])
  static selectStageInstancesWithoutSchedule(state: StageInstanceStateModel) {
    return state.stageInstancesWithoutSchedule;
  }
  @Selector([StageInstanceState])
  static assignedStageInstances(state: StageInstanceStateModel) {
    return state.assignedStageInstances;
  }

  @Selector([StageInstanceState])
  static myStageInstancesReview(state: StageInstanceStateModel) {
    return state.myStageInstancesReview;
  }
  @Selector([StageInstanceState])
  static stageInstancesReviews(state: StageInstanceStateModel) {
    return state.stageInstancesReviews;
  }
  @Selector([StageInstanceState])
  static reviews(state: StageInstanceStateModel) {
    return state.reviews;
  }
  @Selector([StageInstanceState])
  static stageInstanceReviewerType(state: StageInstanceStateModel) {
    return state.stageInstanceReviewerType;
  }
}
