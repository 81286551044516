<mat-card class="example-card" appearance="outlined">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      {{
        workflowCommentReply!.fullName &&
          workflowCommentReply!.fullName.substring(0, 1)
      }}
    </div>
    <mat-card-title >
      <p class="mat-body-strong">
        {{ workflowCommentReply!.fullName }}
      </p>
    </mat-card-title>
    <mat-card-subtitle>
      <p class="mat-caption">
        {{ workflowCommentReply!.dateTime | date: "medium" }}
      </p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p class="mat-body-2">
      {{ workflowCommentReply!.comment }}
    </p>
  </mat-card-content>
  <mat-card-actions class="action-buttons-end">
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Edit comment"
      (click)="editReply(workflowComment!, workflowCommentReply!)"
      [disabled]="!isCommenter()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="warn"
      matTooltip="Delete comment"
      (click)="deleteComment($event, workflowComment!, workflowCommentReply!)"
      [disabled]="!isCommenter()"
    >
      <mat-icon>delete</mat-icon>
    </button>
    </mat-card-actions>
</mat-card>
