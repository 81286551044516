import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import {
  ARCHIVED_ROUTE,
  PROCESS_INSTANCE_LIST_ROUTE,
  STAGE_INSTANCES_ROUTE,
  TASK_DOCUMENTS_ROUTE,
} from 'src/app/core/constants/routes';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { StageInstanceDetailFacade } from 'src/app/researches/facades/stage-instance-detail.facades';
import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import {
  StageInstanceDetail,
  StageInstanceEvaluation,
  StageInstanceEvaluationStatus,
  StageInstanceStatus,
  StageInstanceTask,
} from 'src/app/researches/models/stage-instance-detail.model';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { TaskFormcomponent } from '../../task/task-form/task-form.component';
import { Task, TaskType } from 'src/app/researches/models/task.model';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { TaskStatus } from 'src/app/researches/models/task.model';
import { StageFacade } from 'src/app/researches/facades/stage.facades';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { Subject, combineLatest, take } from 'rxjs';
import { TaskDetailFrom } from 'src/app/researches/store/task.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { StageStatusChangeSuccess } from 'src/app/researches/store/stage-instance-detail.actions';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { StageStatus } from 'src/app/researches/models/stage.model';
import { ConfirmGenericDialogComponent } from 'src/app/shared/shared-components/confirm-generic-dialog/confirm-generic-dialog.component';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { TorInitiateFormComponent } from 'src/app/researches/tor/components/tor-initiate-form/tor-initiate-form.component';
import { ConfirmDialogWithOneOptionComponent } from 'src/app/shared/shared-components/confirm-dialog-with-one-option/confirm-dialog-with-one-option.component';

interface StageInstanceDetailState {
  selectedTask: Task | undefined;
  selectedStageInstanceDetail: StageInstanceDetail | undefined;
  processInstanceDetail: ProcessInstanceDetail | undefined;
  stageInstanceTasks: PaginatedList<StageInstanceTask> | undefined;
  evaluations?: StageInstanceEvaluation;
  isEveryTaskApproved: boolean | undefined;
  evaluationStatus: StageInstanceEvaluationStatus;
  taskTypes: TaskType[];
  currentLoggedInUser?: CurrentLoggedInUser;
  isMajorTaskFound:boolean;
}

interface TaskState {
  selectedTask: Task | undefined;
}

const initStageInstanceDetailState: StageInstanceDetailState = {
  selectedTask: undefined,
  selectedStageInstanceDetail: undefined,
  processInstanceDetail: undefined,
  stageInstanceTasks: undefined,
  isEveryTaskApproved: undefined,
  evaluationStatus: StageInstanceEvaluationStatus.Invalid,
  taskTypes: [],
  isMajorTaskFound:false,
};

const initTaskState: TaskState = {
  selectedTask: undefined,
};

@Component({
  selector: 'app-stage-instance-detail',
  templateUrl: './stage-instance-detail.component.html',
  styleUrls: ['./stage-instance-detail.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: StageInstanceDetailComponent}, RxState],
})
export class StageInstanceDetailComponent implements MatPaginatorIntl {
  stages: any[] = [];
  stageIds: any[] = [];
  stageInstanceId: any;
  displayedColumns: string[] = ['criteria', 'weight'];

  evaluationColumns: string[] = ['name', 'weight', 'value'];
  stageEvaluations: any[] = [];
  totalEvaluationResult = 0;

  currentStageId: any = '';
  currentStageIndex: number = 0;
  activeLink = 'tasks';
  statuses: StageInstanceStatus[] = [
    StageInstanceStatus.ReadyToStart,
    StageInstanceStatus.Inprogress,
  ];
  isStatusDropdownOpen: boolean = false;
  selectedStageInstanceDetail$ = this.state.select(
    'selectedStageInstanceDetail',
  );
  selectedTask$ = this.state.select('selectedTask');
  selectedTask: Task | undefined = undefined;

  selectedStageInstanceDetail: StageInstanceDetail | undefined;
  processInstanceDetail$ = this.state.select('processInstanceDetail');

  processInstanceDetail: ProcessInstanceDetail | undefined;
  stageInstanceTasks$ = this.state.select('stageInstanceTasks');
  stageInstanceTasks: PaginatedList<StageInstanceTask> | undefined = undefined;

  placeholderToggleLabel = {
    submitForEvaluation: $localize`:@@researches.stage-instance-detail.submit-for-evaluation: Submit for Evaluation`,
    approveStage: $localize`:@@researches.stage-instance-detail.approve-stage: Approve Stage`,
    infoStage: $localize`:@@researches.stage-instance-detail.info-stage: In order to submit your tasks for an approval,
    make at least one task that you want to submit a Major Task,
    this will only send documents of the Major Task. Use the menu
    icon on the right side of a task to make it a Major Task`,
}

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;

  tasks: any;
  taskBoard: any;
  criteria: any;
  evaluation: any;

  approvable = false;
  isRejectable = false;
  isEveryTaskApproved$ = this.state.select('isEveryTaskApproved');
  isEveryTaskApproved: boolean | undefined;

  showEvaluationResult: boolean = false;

  isStageEvaluatable = false;

  taskTypeForm: FormGroup;
  taskTypes$ = this.state.select('taskTypes');
  taskTypes: TaskType[] = [];
  $stageInstanceTasks = this.state.select('stageInstanceTasks');
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  canSubmitForApproval = false;
  StageStatus = StageStatus;
  StageInstanceStatus = StageInstanceStatus;
  isMajorTaskFound$ = this.state.select('isMajorTaskFound');
  isMajorTaskFound:boolean = false;

  firstPageLabel = $localize`:@@researches.stage-instance-detail.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.stage-instance-detail.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.stage-instance-detail.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.stage-instance-detail.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.stage-instance-detail.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.stage-instance-detail.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.stage-instance-detail.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.stage-instance-detail.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private taskState: RxState<TaskState>,
    private stageInstanceDetailFacade: StageInstanceDetailFacade,
    private processInstanceFacade: ProcessInstanceFacade,
    private route: ActivatedRoute,
    private router: Router,
    private taskFacade: TaskFacade,
    private dialog: MatDialog,
    private stageFacade: StageFacade,
    private fb: FormBuilder,
    public state: RxState<StageInstanceDetailState>,
    private actions$: Actions,
    private userFacade: UserFacade,
  ) {
    this.taskState.set(initTaskState);
    this.taskState.connect('selectedTask', this.taskFacade.selectedTask$);
    this.state.set(initStageInstanceDetailState);
    this.state.connect(
      'selectedStageInstanceDetail',
      this.stageInstanceDetailFacade.selectedStageInstanceDetail$,
    );
    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.state.connect(
      'stageInstanceTasks',
      this.stageInstanceDetailFacade.stageInstanceTasks$,
    );

    this.state.connect(
      'evaluations',
      this.stageInstanceDetailFacade.StageInstanceEvaluations$,
    );
    this.state.connect(
      'isEveryTaskApproved',
      this.stageInstanceDetailFacade.isEveryTaskApproved$,
    );
    this.state.connect(
      'evaluationStatus',
      this.stageInstanceDetailFacade.StageInstanceEvaluationStatus$,
    );
    this.state.connect(
      'stageInstanceTasks',
      this.stageInstanceDetailFacade.stageInstanceTasks$,
    );
    this.state.connect('taskTypes', this.taskFacade.taskTypes$);

    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect(
      'isMajorTaskFound',
      this.stageInstanceDetailFacade.isMajorTaskFound$,
    );
    this.taskTypeForm = this.fb.group({
      selectedTaskType: [],
    });
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.state.select('evaluations').subscribe((evals) => {
      this.stageEvaluations = evals?.evaluations ?? [];
      this.totalEvaluationResult = evals?.totalResult ?? 0;
    });

    this.route.paramMap.subscribe((params) => {
      const stageInstanceId = (this.currentStageId = params.get('id'));
      if (stageInstanceId) {
        this.stageInstanceDetailFacade.dispatchGetStageInstanceDetail(
          stageInstanceId,
        );
        this.selectedTask$.subscribe((selectedTask) => {
          this.selectedTask = selectedTask;
        });
        this.stageInstanceDetailFacade.dispatchCheckEveryTasksApproval(
          stageInstanceId,
        );
        this.selectedStageInstanceDetail$.subscribe(
          (selectedStageInstanceDetail) => {
            this.selectedStageInstanceDetail = selectedStageInstanceDetail;
          },
        );

        this.stageInstanceDetailFacade.dispatchGetStageEvaluation(
          stageInstanceId,
        );
        this.stageInstanceDetailFacade.dispatchGetStageInstanceEvaluationStatus(
          stageInstanceId,
        );
        this.stageInstanceDetailFacade.dispatchCheckIfMajorTaskFound(
          stageInstanceId
        )
      }
      this.state.connect('selectedTask', this.taskFacade.selectedTask$);
      this.selectedTask$.subscribe((task) => {
        this.selectedTask = task;
      });

      this.isEveryTaskApproved$.subscribe((isApproved) => {
        this.isEveryTaskApproved = isApproved;
      });

      this.stageInstanceTasks$.subscribe((tasks) => {
        this.approvable = this.isEveryTaskApproved ?? false;
        this.isRejectable =
          this.selectedStageInstanceDetail?.stageStatus !==
            StageInstanceStatus.Rejected ?? false;
      });
    });

    combineLatest([
      this.state.select('selectedStageInstanceDetail'),
      this.state.select('isEveryTaskApproved'),
      this.state.select('isMajorTaskFound'),
    ]).subscribe(([stageDetail, isAllTasksApproved, isMajorTaskFound]) => {
      if (stageDetail) {
        this.canSubmitForApproval =
          (stageDetail.stageStatus == StageInstanceStatus.Inprogress ||
            stageDetail.stageStatus == StageInstanceStatus.NeedsRevision) &&
          !!isAllTasksApproved && !!isMajorTaskFound;
        return;
      }
      this.canSubmitForApproval = false;
    });

    this.route.paramMap.subscribe((params) => {
      const stageInstanceId = (this.currentStageId = params.get('id'));
      if (stageInstanceId) {
        this.stageInstanceDetailFacade.dispatchGetStageInstanceTasks(
          stageInstanceId,
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 5,
        );
        this.stageInstanceTasks$.subscribe((stageInstanceTasks) => {
          if (stageInstanceTasks) {
            this.stageInstanceTasks = stageInstanceTasks;
            this.length = stageInstanceTasks.totalCount;
          }
        });
      }
    });

    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
      this.processInstanceDetail!.stageInstances.forEach((stage) => {
        this.stageIds.push(stage.id);
      });
      this.currentStageIndex = this.stageIds.indexOf(this.currentStageId);
    });
    this.state.select('evaluationStatus').subscribe((status) => {
      this.showEvaluationResult =
        status !== StageInstanceEvaluationStatus.Invalid;
    });

    this.taskFacade.dispatchGetTaskTypesByStageInstanceId(this.currentStageId);
    this.taskTypes$.subscribe((types) => {
      this.taskTypes = types;
    });
    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
    this.tasks = $localize`:@@researches.stage-instance-detail.tasks: Tasks`;
    this.taskBoard = $localize`:@@researches.stage-instance-detail.task-board: Task Board`;
    this.criteria = $localize`:@@researches.stage-instance-detail.criteria: Criteria`;
    this.evaluation = $localize`:@@researches.stage-instance-detail.evaluation: Evaluation`;
  }

  previousStage() {
    if (this.currentStageIndex > 0) {
      this.currentStageIndex -= 1;
      this.router.navigate([
        `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}`,
        this.stageIds[this.currentStageIndex],
      ]);
      this.currentStageId = this.stageIds[this.currentStageIndex];
    }
  }

  nextStage() {
    if (this.currentStageIndex < this.stageIds.length - 1) {
      this.currentStageIndex += 1;
      this.router.navigate([
        `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}`,
        this.stageIds[this.currentStageIndex],
      ]);
      this.currentStageId = this.stageIds[this.currentStageIndex];
    }
  }
  loadPaginatedTasks(event: PageEvent) {
    this.stageInstanceDetailFacade.dispatchGetStageInstanceTasks(
      this.currentStageId,
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  checkTaskStatus(status: any) {
    if (status === TaskStatus.Todo) {
      return $localize`:@@researches.stage-instance-detail.to-do: To Do`;
    } else if (status === TaskStatus.InProgress) {
      return $localize`:@@researches.stage-instance-detail.in-progress: In Progress`;
    } else if (status === TaskStatus.Submitted) {
      return $localize`:@@researches.stage-instance-detail.submitted-to: Submitted`;
    } else if (status === TaskStatus.Rejected) {
      return $localize`:@@researches.stage-instance-detail.needs-revision: Needs Revision`;
    } else if (status === TaskStatus.Done) {
      return $localize`:@@researches.stage-instance-detail.done: Done`;
    } else {
      return $localize`:@@researches.stage-instance-detail.approved: Approved`;
    }
  }

  getTaskStatusColor(status: any) {
    if (status === TaskStatus.Todo) {
      return 'gray';
    } else if (status === TaskStatus.InProgress) {
      return 'yellow';
    } else if (status === TaskStatus.Submitted) {
      return 'cyan';
    } else if (status === TaskStatus.Rejected) {
      return 'red';
    } else {
      return 'green';
    }
  }

  checkStageStatus(status: any) {
    if (status == StageInstanceStatus.ReadyToStart) {
      return $localize`:@@researches.stage-instance-detail.ready-to-start:Ready To Start`;
    } else if (status == StageInstanceStatus.Inprogress) {
      return $localize`:@@researches.stage-instance-detail.in-progress:In Progress`;
    } else if (status == StageInstanceStatus.WaitingForApproval) {
      return $localize`:@@researches.stage-instance-detail.waiting-for-approval:Waiting for Approval`;
    } else if (status == StageInstanceStatus.Rejected) {
      return $localize`:@@researches.stage-instance-detail.rejected:Rejected`;
    } else if (status == StageInstanceStatus.Approved) {
      return $localize`:@@researches.stage-instance-detail.approved:Approved`;
    } else if (status == StageInstanceStatus.NeedsRevision) {
      return $localize`:@@researches.stage-instance-detail.needs-revision:Needs Revision`;
    } else if (status == StageInstanceStatus.SubmittedToEvaluation) {
      return $localize`:@@researches.stage-instance-detail.waiting-for-evaluation:Waiting For Evaluation`;
    } else if (status == StageInstanceStatus.Terminated) {
      return $localize`:@@researches.stage-instance-detail.terminated:Terminated`;
    }
    return '-';
  }

  getStageStatusColor(status: any) {
    if (status == StageInstanceStatus.ReadyToStart) {
      return 'gray';
    } else if (status == StageInstanceStatus.Inprogress) {
      return 'yellow';
    } else if (status == StageInstanceStatus.WaitingForApproval) {
      return 'cyan';
    } else if (
      status == StageInstanceStatus.Rejected ||
      status == StageInstanceStatus.Terminated
    ) {
      return 'red';
    } else if (status == StageInstanceStatus.Approved) {
      return 'green';
    }
    return 'gray';
  }

  openTaskForm() {
    const dialogRef = this.dialog.open(TaskFormcomponent, SIDE_DIALOG_CONFIG);
    dialogRef.afterClosed().subscribe(() => {
      this.taskFacade.dispatchSearchTaskTypes();
      this.stageInstanceDetailFacade.dispatchSetIsEveryTaskApprovedStatus(
        false,
      );
    });
  }
  onRowClick(event: MouseEvent, task: StageInstanceTask): void {
    const isMenuButtonClick =
      (event.target as HTMLElement).closest('.mat-menu-trigger') !== null;
    if (!isMenuButtonClick) {
      this.taskFacade.dispatchToggleTaskDetailFrom(
        TaskDetailFrom.STAGE_INSTANCE,
      );
      this.processInstanceDetail?.isArchived
        ? this.router.navigate([
            `${ARCHIVED_ROUTE}/${STAGE_INSTANCES_ROUTE}/task-detail`,
            task.id,
            {
              stageId: this.selectedStageInstanceDetail?.id ?? '',
              processId: undefined,
            },
          ])
        : this.router.navigate([
            `${PROCESS_INSTANCE_LIST_ROUTE}/${STAGE_INSTANCES_ROUTE}/task-detail`,
            task.id,
            {
              stageId: this.selectedStageInstanceDetail?.id ?? '',
              processId: undefined,
            },
          ]);
    }
  }

  onMenuClick(event: MouseEvent, task: Task) {
    event.stopPropagation();
    this.taskFacade.dispatchSetSelectedTask(task);
    this.canEditAndDelete();
    this.canSetAsMajorAndSetAsNonMajor();
  }

  approveStage() {
    const { selectedStageInstanceDetail } = this.state.get();

    if (selectedStageInstanceDetail?.stage.hasEvaluation) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          regularTextOne: $localize`:@@researches.stage-instance-detail.submit-stage-part-1: Are you sure you want to submit` ,
          boldText: ` "${selectedStageInstanceDetail?.name}" ` ,
          regularTextTwo:  $localize`:@@researches.stage-instance-detail.submit-stage-part-2: stage for evaluation?`,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.stageInstanceDetailFacade.dispatchChangeStageStatusToSubmittedForEvaluation(
            selectedStageInstanceDetail!.id,
          );
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          regularTextOne: $localize`:@@researches.stage-instance-detail.approve-stage-part-1: Are you sure you want to approve` ,
          boldText:` "${selectedStageInstanceDetail?.name}" ` +
            $localize`:@@researches.stage-instance-detail.approve-stage-part-2: stage?`,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.stageInstanceDetailFacade.dispatchChangeStageStatusToApproved(
            selectedStageInstanceDetail!.id,
          );
        }
      });
    }
  }

  needsRevision() {
    const { selectedStageInstanceDetail } = this.state.get();
    const deliberateDialogRef = this.dialog.open(
      ConfirmWithCommentDialogComponent,
      {
        data: {
          name: '',
          message: $localize`:@@researches.stage-instance-detail.needs-revision-message: Needs revision`,
          regularTextOne: $localize`:@@researches.stage-instance-detail.needs-revision-description-part-1: Are you sure you want to give comment` ,
          boldText: ` ${selectedStageInstanceDetail?.name} ` ,
          regularTextTwo: $localize`:@@researches.stage-instance-detail.needs-revision-description-part-2: stage?`,
          extra: $localize`:@@researches.stage-instance-detail.needs-revision-extra: Write your reason for requesting revision`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result.confirmText === 'confirm') {
        this.stageInstanceDetailFacade
          .dispatchChangeStageStatusToNeedsRevision(
            selectedStageInstanceDetail!.id,
            result.comment,
          )
          .pipe(take(1))
          .subscribe(() =>
            this.stageInstanceDetailFacade.dispatchGetStageInstanceDetail(
              selectedStageInstanceDetail!.id,
            ),
          );
      }
    });
  }

  updateTask(stageInstanceDetail?: StageInstanceDetail) {
    if (!stageInstanceDetail) return;
    if (!this.selectedTask) return;
    this.taskFacade.dispatchUpdate(true);
    const dialogRef = this.dialog.open(TaskFormcomponent, SIDE_DIALOG_CONFIG);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedTask) this.router.navigate([STAGE_INSTANCES_ROUTE]);
      } else {
        this.taskFacade.dispatchUpdate(false);
        this.taskFacade.dispatchResetSelectedTask();
      }
    });
  }

  toggleStatusDropdown() {
    this.isStatusDropdownOpen = !this.isStatusDropdownOpen;
  }

  selectStatus(status: StageInstanceStatus) {
    var updatedStatus = {
      id: this.selectedStageInstanceDetail?.id,
      stageStatus: status,
    };
    this.stageInstanceDetailFacade.dispatchChangeStageStatus(updatedStatus);
  }

  rejectStage() {
    const { selectedStageInstanceDetail } = this.state.get();
    const deliberateDialogRef = this.dialog.open(
      ConfirmWithCommentDialogComponent,
      {
        data: {
          name: '',
          message: $localize`:@@researches.stage-instance-detail.reject-stage-message: Reject Stage`,
          regularTextOne: $localize`:@@researches.stage-instance-detail.reject-stage-description-part-1: Are you sure you want to reject` ,
          boldText: ` ${selectedStageInstanceDetail?.name} ` ,
          regularTextTwo: $localize`:@@researches.stage-instance-detail.reject-stage-description-part-2:stage? The whole process will be archived.`,
          extra: $localize`:@@researches.stage-instance-detail.reject-stage-extra: Write your reason for rejection`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result.confirmText === 'confirm') {
        this.stageInstanceDetailFacade.dispatchChangeStageStatusToRejected(
          selectedStageInstanceDetail!.id,
          result.comment,
        );
        this.isRejectable = false;
        this.actions$
          .pipe(ofActionSuccessful(StageStatusChangeSuccess))
          .subscribe(() =>
            this.router.navigate([
              PROCESS_INSTANCE_LIST_ROUTE,
              this.processInstanceDetail?.id,
            ]),
          );
      }
    });
  }

  submitForApproval() {
    const { selectedStageInstanceDetail } = this.state.get();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.stage-instance-detail.submit-for-approval-message-part-1:Are you sure you want to submit` ,
        boldText: ` "${selectedStageInstanceDetail?.name}" ` ,
        regularTextTwo:  $localize`:@@researches.stage-instance-detail.submit-for-approval-message-part-2: stage for approval?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.stageInstanceDetailFacade.dispatchChangeStageStatusToWaitingForApproval(
          selectedStageInstanceDetail!.id,
        );
      }
    });
  }

  setSelectedTask(event: MouseEvent, task: StageInstanceTask) {
    event.stopPropagation();
    this.taskFacade.dispatchSetSelectedTask(task as unknown as Task);
    this.canEditAndDelete();
    this.canSetAsMajorAndSetAsNonMajor();
  }
  openConfirmationDialog() {
    if (!this.selectedTask) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.stage-instance-detail.delete-task-message-part-1: Are you sure you want to delete` ,
        boldText:  ` "${this.selectedTask.name}"? ` ,
        regularTextTwo: $localize`:@@researches.stage-instance-detail.delete-task-message-part-2: All related documents and comments are also removed.`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        const taskId = this.selectedTask?.id;
        if (!taskId) return;
        this.stageInstanceDetailFacade.dispatchDeleteTaskFromStageInstanceDetail(
          taskId,
        );
        this.dialog.closeAll();
      }
    });
  }

  getTasksByType() {
    const selectedType = this.taskTypeForm.get('selectedTaskType')?.value;

    if (selectedType !== null && selectedType !== undefined) {
      this.stageInstanceDetailFacade.dispatchGetTasksByTaskType(
        selectedType?.id,
        this.selectedStageInstanceDetail?.id ?? '',
      );
      this.$stageInstanceTasks.subscribe((tasks) => {
        this.stageInstanceTasks = tasks;
      });
    } else {
      this.taskTypeForm.get('selectedTaskType')?.setValue(null);
      this.stageInstanceDetailFacade.dispatchGetStageInstanceTasks(
        this.currentStageId,
        this.paginator?.pageIndex || 1,
        this.paginator?.pageSize || 5,
      );
    }
  }

  viewDocument() {
    if (!this.selectedTask) return;
    this.processInstanceDetail?.isArchived
      ? this.router.navigate([
          `${ARCHIVED_ROUTE}/${TASK_DOCUMENTS_ROUTE}`,
          this.selectedTask.id,
          { taskId: this.selectedTask.id },
        ])
      : this.router.navigate([
          `${PROCESS_INSTANCE_LIST_ROUTE}/${TASK_DOCUMENTS_ROUTE}`,
          this.selectedTask.id,
          { taskId: this.selectedTask.id },
        ]);
  }

  canEditAndDelete() {
    const id = this.currentLoggedInUser?.id;
    const userName = this.currentLoggedInUser?.userName;
    const email = this.currentLoggedInUser?.email;

    if (
      (this.selectedTask?.assignedTo == id &&
        this.selectedTask?.createdBy == userName) ||
      this.processInstanceDetail?.ownerEmail === email ||
      this.selectedTask?.createdBy == userName
    ) {
      return true;
    }
    return false;
  }

  toggleIsMajor(id?: string) {
    if (!id) return;
    if(!this.selectedStageInstanceDetail?.id) return;
    this.stageInstanceDetailFacade.dispatchToggleIsMajorTask(id, this.selectedStageInstanceDetail?.id);
  }

  showNeedRevisionComments() {
    const { selectedStageInstanceDetail } = this.state.get();
    const dialogRef = this.dialog.open(ConfirmDialogWithOneOptionComponent, {
      data: {
        name: '',
        title: $localize `:@@researches.stage-instance-detail.comment-given-during-needs-revision-request:Comment to be considered`,
        regularTextOne: this.selectedStageInstanceDetail?.needsRevisionComment,
      },
    });
  }

  canSetAsMajorAndSetAsNonMajor() {
    const id = this.currentLoggedInUser?.id;
    const userName = this.currentLoggedInUser?.userName;
    const email = this.currentLoggedInUser?.email;

    if (
      (id !== undefined &&
        this.processInstanceDetail?.teamMembersIds?.includes(id)) ||
      this.processInstanceDetail?.ownerEmail === email ||
      this.selectedTask?.createdBy == userName ||
      this.selectedTask?.assignedTo == id
    ) {
      return true;
    }

    return false;
  }

  hasGetUserStageEvaluationPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Evaluation.Feature,
      PERMISSION_NAMES.Researches.Evaluation.GetUserStageEvaluation,
    );
  }

  hasCreateTorPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.CreateTor,
    );
  }

  hasUpdateStatusToNeedsRevisionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.UpdateStatusToNeedsRevision,
    );
  }

  hasUpdateStatusToApprovedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.UpdateStatusToApproved,
    );
  }

  hasUpdateStatusToWaitingForApprovalPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance
        .UpdateStatusToWaitingForApproval,
    );
  }

  hasGetStageInstanceTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetStageInstanceTask,
    );
  }

  hasCreateTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.CreateTask,
    );
  }

  hasGetFileTasksByTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTasksByTask,
    );
  }
  hasUpdateTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.UpdateTask,
    );
  }

  hasDeleteTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.DeleteTask,
    );
  }

  hasToggleIsMajorPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ToggleIsMajor,
    );
  }

  hasGetCriterionPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Criterion.Feature,
      PERMISSION_NAMES.Researches.Criterion.GetCriterion,
    );
  }

  hasUpdateStatusToRejectedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.UpdateStatusToRejected,
    );
  }

  initiateTorForm() {
    this.dialog.open(TorInitiateFormComponent, {
      data: {
        stageInstanceId: this.currentStageId,
      },
    });
  }

  checkStageInstanceStatus() {
    return (
      this.selectedStageInstanceDetail?.stageStatus === StageInstanceStatus.WaitingForApproval ||
      this.selectedStageInstanceDetail?.stageStatus === StageInstanceStatus.SubmittedToEvaluation
    );
  }

  hasCrudPermission(): string[] {
    return [
      this.hasUpdateTaskPermission(),
      this.hasDeleteTaskPermission(),
      this.hasGetFileTasksByTaskPermission(),
      this.hasToggleIsMajorPermission()
    ]
  }
}
