<h1 class="mat-h1" i18n="@@documents.archived-workflows.archived-workflows">Archived Workflows</h1>
<form [formGroup]="filterForm" class="form-container">
  <mat-form-field appearance="outline" class="workflow-selector">
    <mat-label i18n="@@documents.archived-workflows.choose-filter">Choose Filter</mat-label>
    <mat-select
      formControlName="filter"
      (selectionChange)="onFilterSelect($event)"
    >
      <mat-option *ngFor="let filter of workflowFilters" [value]="filter?.id">{{
        filter.workflowStatusForDisplay
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>

<div class="paginator-container">
  <mat-paginator
    [length]="archivedWorkflows?.totalCount ?? 0"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedWorkflows($event)"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>
</div>

<div class="col">
  <app-workflow-card
    [isArchived]="true"
    [workflow]="workflow"
    *ngFor="let workflow of archivedWorkflows?.items"
  />
</div>

<ng-container *ngIf="!archivedWorkflows || archivedWorkflows?.totalCount === 0">
  <div class="empty-workflow-list">
    <span class="" i18n="@@documents.archived-workflows.no-archived-workflows-found">No Archived Workflows found</span>
  </div>
</ng-container>
