import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EVALUATION_URL } from 'src/app/core/constants/api-endpoints';
import {
  CriterionEvaluation,
  UpdateCriterionEvaluation,
} from '../models/criterion.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  constructor(private http: HttpClient) {}

  evaluateStage(stageId: string, evaluations: CriterionEvaluation[]) {
    return this.http.post(`${EVALUATION_URL}/?stageInstanceId=${stageId}`, {
      evaluations,
    });
  }

  updateStageEvalution(
    stageInstanceId: string,
    evaluations: UpdateCriterionEvaluation[],
  ) {
    return this.http.put(`${EVALUATION_URL}`, {
      evaluations,
      stageInstanceId,
    } as any);
  }
}
