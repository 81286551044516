import { Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { DepartmentVsPublication } from '../../models/department-vs-publicaiton.model';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';

interface DeparmentvspublicationState {
  paginationState: { pageNumber: number; pageSize: number };
  departmentVsPublication: PaginatedList< DepartmentVsPublication> | undefined
}

const initDeparmentvspublicationState: DeparmentvspublicationState = {
  paginationState: { pageNumber: 0, pageSize: 5 },
  departmentVsPublication: undefined
}

@Component({
  selector: 'app-department-vs-publication',
  templateUrl: './department-vs-publication.component.html',
  styleUrls: ['./department-vs-publication.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: DepartmentVsPublicationComponent}],
})
export class DepartmentVsPublicationComponent {
  deparmentVsPublication$ = this.state.select("departmentVsPublication");
  deparmentVsPublication: PaginatedList<DepartmentVsPublication> | undefined;
  paginatedState$ =  this.state.select('paginationState');

  displayedColumns: string[] = ['office', 'total'];
  dataSource: any[] = [];

  firstPageLabel = $localize`:@@researches.department-vs-publication.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.department-vs-publication.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.department-vs-publication.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.department-vs-publication.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.department-vs-publication.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.department-vs-publication.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.department-vs-publication.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.department-vs-publication.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<DeparmentvspublicationState>,
    private researchDashboardFacade: ResearchDashboardFacade
  ){
    this.state.set(initDeparmentvspublicationState);
    this.state.connect('departmentVsPublication', researchDashboardFacade.departmentvspublication$)
  }
  changes = new RxState();
  ngOnInit() {
    this.researchDashboardFacade.dispatchGetDepartmentVsPublication()
    this.deparmentVsPublication$.subscribe((data) => {
      this.deparmentVsPublication = data;
    });
    this.paginatedState$ .subscribe((pagination) => {
      this.researchDashboardFacade.dispatchGetDepartmentVsPublication(
        null, null, null, null, pagination.pageNumber, pagination.pageSize
      )
    })
  }

  paginatedDepartmentVsPublication(event: PageEvent) {
    this.state.set({
      paginationState: {
        pageSize: event.pageSize,
        pageNumber: event.pageIndex + 1,
        },
    });

  }

}
