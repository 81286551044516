<div class="main">
  <h1 class="mat-h1" i18n="@@documents.reported-workflow-comment.comments">Comments</h1>
</div>
<div class="main flex-row">
  <div class="workflows">
    <mat-paginator
      [length]="reportedWorkflowComments.items.length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
      class="paginator"
      (page)="loadPaginatedWorkflowComments($event)"
    >
    </mat-paginator>
    <div
      *ngIf="
        reportedWorkflowComments.items.length > 0;
        else emptyReportedWorkflowComments
      "
      class="comments-container"
    >
      <app-reported-workflow-comment-card
        *ngFor="let reportedWorkflowComment of reportedWorkflowComments.items"
        [reportedWorkflowComment]="reportedWorkflowComment"
      ></app-reported-workflow-comment-card>
    </div>
    <ng-template #emptyReportedWorkflowComments>
      <div class="empty-process-instance-list">
        <span
          class="text-2xl"
          i18n="@@documents.reported-workflow-comment.no-process-found"
          >No reported comment found</span
        >
      </div>
    </ng-template>
  </div>
</div>
