import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { take } from 'rxjs';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { FieldValidationRuleFacade } from 'src/app/documents/field-validation-rules/facades/field-validation-rules.facade';
import { ValidationRule } from 'src/app/documents/field-validation-rules/models/validation-rule.model';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { WorkflowValidationFacade } from '../../facade/workflow-validation.facades';
import { WorkflowValidation } from '../../models/workflow-validation.model';
import { WorkflowStepValidationRule } from '../../models/workflow-step-validation-rule.model';

interface WorkflowValidationState {
  documentTemplates: DocumentForm[];
  operators: WorkflowStepValidationRule[];
  selectedValidation?: WorkflowValidation;
}

const initialState: WorkflowValidationState = {
  documentTemplates: [],
  operators: [],
};

@Component({
  selector: 'app-workflow-validation-form',
  templateUrl: './workflow-validation-form.component.html',
  styleUrls: ['./workflow-validation-form.component.scss'],
})
export class WorkflowValidationFormComponent implements OnInit, AfterViewInit {
  @Output() close = new EventEmitter<void>();

  documentTemplates$ = this.state.select('documentTemplates');
  operators$ = this.state.select('operators');

  workflowTypeId?: string;

  workflowValidationForm = this.fb.group(
    {
      id: [null as string | null | undefined],
      leftTemplateFieldId: [null as FormFields | null, Validators.required],
      rightTemplateFieldId: [null as FormFields | null, Validators.required],
      validationRule: [
        null as Required<ValidationRule> | null,
        Validators.required,
      ],
    },
    { validators: [this.dataTypeValidation(), this.distinctTemplateFieldValidator()], },
  );

  distinctTemplateFieldValidator(): ValidatorFn {
    return (fg: AbstractControl) => {
      const leftTemplateFieldId = fg.get('leftTemplateFieldId')?.value;
      const rightTemplateFieldId = fg.get('rightTemplateFieldId')?.value;

      if (leftTemplateFieldId && rightTemplateFieldId && leftTemplateFieldId === rightTemplateFieldId) {
        return { templateFieldsIdentical: true };
      }
      return null;
    }
  }
  constructor(
    private route: ActivatedRoute,
    private state: RxState<WorkflowValidationState>,
    private workflowValidationFacade: WorkflowValidationFacade,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private fb: NonNullableFormBuilder,
    private destoryRef: DestroyRef,
  ) {
    this.state.set(initialState);
  }

  ngAfterViewInit(): void {
    this.workflowValidationForm.controls.leftTemplateFieldId.valueChanges
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((val) => {
        if (val)
          this.workflowValidationFacade.dispatchGetWorkflowStepValidationRules(
            val.dataType,
          );
      });

    this.state.select('selectedValidation').subscribe((val) => {
      if (val)
        this.workflowValidationForm.setValue({
          id: val.id,
          leftTemplateFieldId: val.leftTemplateField,
          rightTemplateFieldId: val.rightTemplateField,
          validationRule: val.validationRule,
        });
    });
  }

  ngOnInit(): void {
    this.state.connect(
      'documentTemplates',
      this.workflowValidationFacade.documentTemplates$,
    );

    this.state.connect(
      'operators',
      this.workflowValidationFacade.workflowValidationRules$
    );

    this.state.connect(
      'selectedValidation',
      this.workflowValidationFacade.selectedValidation$,
    );

    this.route.params
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((params) => {
        this.workflowTypeId = params['id'];
        if (!this.workflowTypeId) return;
        this.workflowValidationFacade.dispatchGetWorkflowTemplateFields(
          this.workflowTypeId,
        );
      });
  }

  private dataTypeValidation(): ValidatorFn {
    return (fg: AbstractControl) => {
      const leftTemplateField = fg.get('leftTemplateFieldId')?.value;
      const rightTemplateField = fg.get('rightTemplateFieldId')?.value;

      if (
        leftTemplateField &&
        rightTemplateField &&
        leftTemplateField.dataType != rightTemplateField.dataType
      )
        return { templateField2: true };
      return null;
    };
  }
  onSubmit() {
    if (!this.workflowValidationForm.valid && !this.workflowTypeId) return;

    const { id, rightTemplateFieldId, leftTemplateFieldId, validationRule } =
      this.workflowValidationForm.getRawValue();

    let submission;

    if (id) {
      submission =
        this.workflowValidationFacade.dispatchUpdateWorkflowValidation(
          id!,
          leftTemplateFieldId?.id!,
          rightTemplateFieldId?.id!,
          validationRule?.id!,
        );
    } else {
      submission =
        this.workflowValidationFacade.dispatchCreateWorkflowValidation(
          this.workflowTypeId!,
          leftTemplateFieldId?.id!,
          rightTemplateFieldId?.id!,
          validationRule?.id!,
        );
    }
    submission.pipe(take(1)).subscribe(() => {
      this.close.emit();
    });
  }

  compareWith(obj1: any, obj2: any): boolean {
    return obj1?.id == obj2?.id;
  }
}
