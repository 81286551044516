import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalDocumentListComponent } from './components/external-document-list/external-document-list.component';
import {
  EXTERNAL_DOCUMENT_RESPONSE_ROUTE,
  EXTERNAL_DOCUMENT_ROUTE,
} from 'src/app/core/constants/routes';
import { ExternalDocumentDetailComponent } from './components/external-document-detail/external-document-detail.component';
import { ExternalDocumentResponseFormComponent } from './external-document-response-form/external-document-response-form.component';

const routes: Routes = [
  {
    path: '',
    component: ExternalDocumentListComponent,
    children: [
      // { path: EXTERNAL_DOCUMENT_ROUTE, component: ExternalDocumentListComponent },
      {
        path: `${EXTERNAL_DOCUMENT_ROUTE}/:id`,
        component: ExternalDocumentDetailComponent,
      },
      {
        path: `${EXTERNAL_DOCUMENT_RESPONSE_ROUTE}`,
        component: ExternalDocumentResponseFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExternalDocumentRoutingModule {}
