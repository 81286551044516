import { Injectable } from '@angular/core';
import { ResearchDashboardSelector } from '../store/research-dashboard.selector';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  GetBudgetPerCommunication,
  GetBudgetTimeGraph,
  GetCommunicationCount,
  GetCommunicationTimeGraph,
  GetCommunicationTypeInstances,
  GetCommunicationsWithStatus,
  GetDepartmentVsPublication,
  GetFullLengthResearches,
  GetResearchCommunications,
  GetResearchCount,
  GetResearchEvaluations,
  GetResearchPublicationData,
  GetResearchVsTime,
  GetTotalCost,
  GetTotalCostCommunication,
  GetTotalCostResearch,
} from '../store/research-dashboard.action';

import {
  PublicationCategoriesVsProcessCount,
  PublicationData,
  ResearchVsTime,
} from '../models/research-vs-time.model';
import {
  EvaluatedProcessInstanceCountByDepartment,
  EvaluatedProcessInstanceStatusCount,
  TaskAccomplishmentVsTimeCount,
} from '../models/research-dashboard.model';
import {
  GetEvaluatedProcessInstanceCountByDepartment,
  GetEvaluatedProcessInstanceStatusCount,
  GetResearchesCount,
  GetTotalCommunicationsCount,
  GetTaskAccomplishmentVsTimeCount,
  SetDashboardFilter,
  GetPublishedResearchesVsCategory,
  GetPublicationCategoriesCount,
} from '../store/research-dashboard.actions';
import {
  BudgetPerCommunication,
  CommunicationVsTime,
} from '../models/communication-vs-time.mode';
import { CommunicationCount } from '../models/communication-count';
import { ResearchCount } from '../models/research-count';
import { Research } from '../models/research.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Communications } from '../models/communications';
import { BudgetVsTime } from '../models/budget-vs-time.model';
import { CommunicationTypeInstance } from '../models/communication-type-Instances.model';
import { Filter } from '../models/filter.model';
import { ResearchEvaluation } from '../models/research-evaluation.model';
import { DepartmentVsPublication } from '../models/department-vs-publicaiton.model';

@Injectable({
  providedIn: 'root',
})
export class ResearchDashboardFacade {
  @Select(ResearchDashboardSelector.researchVsTime)
  researchVsTime$!: Observable<ResearchVsTime[]>;

  @Select(ResearchDashboardSelector.evaluatedProcessInstanceStatusCount)
  evaluatedProcessInstanceStatusCount$!: Observable<
    EvaluatedProcessInstanceStatusCount[]
  >;

  @Select(ResearchDashboardSelector.evaluatedProcessInstanceCountByDepartment)
  evaluatedProcessInstanceCountByDepartment$!: Observable<
    PaginatedList<EvaluatedProcessInstanceCountByDepartment>
  >;

  @Select(ResearchDashboardSelector.slices.communicationVsTime)
  communicationVsTime$!: Observable<CommunicationVsTime[]>;

  @Select(ResearchDashboardSelector.slices.communicationCount)
  communicationCount$!: Observable<PaginatedList<any>>;

  @Select(ResearchDashboardSelector.publicationData)
  publicationData$!: Observable<PublicationData[]>;

  @Select(ResearchDashboardSelector.slices.researchCount)
  researchCount$!: Observable<PaginatedList<ResearchCount>>;

  @Select(ResearchDashboardSelector.slices.budgetVsTimeData)
  budgetVsTimeData$!: Observable<BudgetVsTime[]>;

  @Select(ResearchDashboardSelector.researches)
  researches$!: Observable<PaginatedList<Research>>;

  @Select(ResearchDashboardSelector.communications)
  communications$!: Observable<PaginatedList<Communications>>;

  @Select(ResearchDashboardSelector.budgetPerCommunication)
  budgetPerCommunication$!: Observable<BudgetPerCommunication[]>;

  @Select(ResearchDashboardSelector.totalCost)
  totalCost$!: Observable<number>;

  @Select(ResearchDashboardSelector.totalResearchCost)
  totalResearchCost$!: Observable<number>;

  @Select(ResearchDashboardSelector.totalCommunicationCost)
  totalCommunicationCost$!: Observable<number>;

  @Select(ResearchDashboardSelector.researchCount)
  researchesCount$!: Observable<number>;

  @Select(ResearchDashboardSelector.communicationsWithStatus)
  communicationsWithStatus$!: Observable<any>;
  @Select(ResearchDashboardSelector.totalCommunicationsCount)
  totalCommunicationsCount$!: Observable<number>;

  @Select(ResearchDashboardSelector.taskAccomplishmentVsTimeCount)
  taskAccomplishmentVsTimeCount$!: Observable<TaskAccomplishmentVsTimeCount[]>;

  @Select(ResearchDashboardSelector.slices.communicationTypeInstances)
  CommunicationTypeInstances$!: Observable<CommunicationTypeInstance[]>;

  @Select(ResearchDashboardSelector.slices.filter)
  filter$!: Observable<Filter | undefined>;
  @Select(ResearchDashboardSelector.researchEvaluations)
  researchEvaluations$!: Observable<PaginatedList<ResearchEvaluation>>;

  @Select(ResearchDashboardSelector.publishedResearchesVsCategory)
  publicationCategoriesVsProcessCount$!: Observable<
    PublicationCategoriesVsProcessCount[]
  >;

  @Select(ResearchDashboardSelector.totalPublicationcategoriesCount)
  totalPublicationcategoriesCount$!: Observable<number>;

  @Select(ResearchDashboardSelector.researchLength)
  length$!: Observable<number>;

  @Select(ResearchDashboardSelector.departmentVspublication)
  departmentvspublication$!: Observable<PaginatedList<DepartmentVsPublication>>;
  constructor(private store: Store) {}

  dispatchGetResearchVsTime(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetResearchVsTime(startDate, endDate, officeIds, userIds),
    );
  }

  dispatchGetEvaluatedProcessInstanceStatusCount(
    officeIds: string[] = [],
    userIds: string[] = [],
    startDate?: Date,
    endDate?: Date,
  ) {
    this.store.dispatch(
      new GetEvaluatedProcessInstanceStatusCount(
        officeIds,
        userIds,
        startDate,
        endDate,
      ),
    );
  }

  dispatchGetEvaluatedProcessInstanceCountByDepartment(
    pageNumber: number = 1,
    pageSize: number = 12,
    officeIds: string[] = [],
    userIds: string[] = [],
    startDate?: Date,
    endDate?: Date,
  ) {
    this.store.dispatch(
      new GetEvaluatedProcessInstanceCountByDepartment(
        officeIds,
        userIds,
        pageNumber,
        pageSize,
        startDate,
        endDate,
      ),
    );
  }

  dispatchSetDashboardFilter(filter?: {
    users: string[];
    offices: string[];
    startDate?: Date;
    endDate?: Date;
  }) {
    this.store.dispatch(new SetDashboardFilter(filter));
  }

  dispatchGetCommunicationVsTime(
    officeIds: string[] = [],
    userIds: string[] = [],
    start?: Date,
    end?: Date,
  ) {
    this.store.dispatch(
      new GetCommunicationTimeGraph(officeIds, userIds, start, end),
    );
  }

  dispatchGetCommunicationCount(
    pageNumber: number = 1,
    pageSize: number = 12,
    officeIds: string[] = [],
    userIds: string[] = [],
    start?: Date,
    end?: Date,
  ) {
    this.store.dispatch(
      new GetCommunicationCount(
        officeIds,
        userIds,
        pageNumber,
        pageSize,
        start,
        end,
      ),
    );
  }

  dispatchGetResearchCount(
    officeIds: string[] = [],
    userIds: string[] = [],
    start?: Date,
    end?: Date,
    pageNumber: number = 1,
    pageSize: number = 12,
  ) {
    this.store.dispatch(
      new GetResearchCount(
        officeIds,
        userIds,
        pageNumber,
        pageSize,
        start,
        end,
      ),
    );
  }

  dispatchGetBudgetTimeGraph(
    officeIds: string[] = [],
    userIds: string[] = [],
    start?: Date,
    end?: Date,
  ) {
    this.store.dispatch(new GetBudgetTimeGraph(officeIds, userIds, start, end));
  }

  dispatchGetResearchPublicationData(
    officeIds?: string[],
    userIds?: string[],
    start?: Date,
    end?: Date,
  ) {
    this.store.dispatch(
      new GetResearchPublicationData(start, end, officeIds, userIds),
    );
  }

  dispatchgetFullLengthResearches(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetFullLengthResearches(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      ),
    );
  }

  dispatchgetResearchCommunications(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetResearchCommunications(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      ),
    );
  }
  dispatchGetResearchesCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetResearchesCount(startDate, endDate, officeIds, userIds),
    );
  }
  dispatchGetBudgetPerCommunication(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetBudgetPerCommunication(startDate, endDate, officeIds, userIds),
    );
  }
  dispatchGetTotalCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(new GetTotalCost(startDate, endDate, officeIds, userIds));
  }

  dispatchGetTotalResearchCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetTotalCostResearch(startDate, endDate, officeIds, userIds),
    );
  }

  dispatchGetTotalCommunicationCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetTotalCostCommunication(startDate, endDate, officeIds, userIds),
    );
  }

  dispatchGetCommunicationsWithStatus(
    startDate?: Date,
    endDate?: Date,
    offices?: string[],
    users?: string[],
  ) {
    this.store.dispatch(
      new GetCommunicationsWithStatus(startDate, endDate, offices, users),
    );
  }

  dispatchGetTotalCommunicationsCount(
    startDate?: Date,
    endDate?: Date,
    officeIds?: string[],
    userIds?: string[],
  ) {
    this.store.dispatch(
      new GetTotalCommunicationsCount(startDate, endDate, officeIds, userIds),
    );
  }

  dispatchGetTaskAccomplishmentVsTimeCount(
    officeIds: string[] = [],
    userIds: string[] = [],
    start?: Date,
    end?: Date,
    year?: number,
  ) {
    this.store.dispatch(
      new GetTaskAccomplishmentVsTimeCount(
        officeIds,
        userIds,
        start,
        end,
        year,
      ),
    );
  }

  dispatchGetCommunicationTypeInstances(
    startDate?: Date,
    endDate?: Date,
    officeIds: string[] = [],
    userIds: string[] = [],
  ) {
    this.store.dispatch(
      new GetCommunicationTypeInstances(officeIds, userIds, startDate, endDate),
    );
  }

  dispatchGetResearchEvaluations(
    officeIds?: string[],
    userIds?: string[],
    startDate?: Date,
    endDate?: Date,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetResearchEvaluations(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      ),
    );
  }

  dispatchGetPublicationCategoriesVsProcessCount(
    officeIds: string[] = [],
    userIds: string[] = [],
    startDate?: Date,
    endDate?: Date,
  ) {
    this.store.dispatch(
      new GetPublishedResearchesVsCategory(
        officeIds,
        userIds,
        startDate,
        endDate,
      ),
    );
  }

  dispatchGetPublicationCategoriesCount() {
    this.store.dispatch(new GetPublicationCategoriesCount());
  }

  dispatchGetDepartmentVsPublication(
    officeIds?: string[] | null,
    userIds?: string[] | null,
    startDate?: Date | null,
    endDate?: Date | null,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetDepartmentVsPublication(
        officeIds,
        userIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      ),
    );
  }
}
