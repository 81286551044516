<h2 mat-dialog-title>{{ title }}</h2>
<div mat-dialog-content class="mat-dialog">
  <span>{{ regularTextOne }}</span>
  <span class="font-bold">{{ boldText }}</span>
  <span>{{ regularTextTwo }}</span>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="onConfirm()" color="primary" i18n="@@shared.confirm-generic-dialog.yes">yes</button>
  <button mat-flat-button mat-dialog-close color="warn" (click)="onCancel()" i18n="@shared.confirm-with-comment-dialog.cancel">
    Cancel
  </button>
</div>
