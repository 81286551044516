import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { TaskComment } from 'src/app/researches/models/task.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';

interface TaskCommentComponentState {
  selectedTaskComment: TaskComment | undefined;
  selectedReplyComment: TaskComment | undefined;
  processInstanceDetail: ProcessInstanceDetail | undefined;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initTaskCommentComponentState: TaskCommentComponentState = {
  selectedTaskComment: undefined,
  selectedReplyComment: undefined,
  processInstanceDetail: undefined,
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-task-comment',
  templateUrl: './task-comment.component.html',
  styleUrls: ['./task-comment.component.scss'],
})
export class TaskCommentComponent implements OnInit {
  @Input() taskComment: TaskComment | undefined;
  @Input() toggleReplyBoxVisibility: boolean = false;
  @Input() toggleReplyBox: () => void = () => {};
  @Input() cancelReplyBox: () => void = () => {};

  selectedTaskComment: TaskComment | undefined;
  selectedTaskComment$ = this.state.select('selectedTaskComment');

  selectedReplyComment: TaskComment | undefined;
  selectedReplyComment$ = this.state.select('selectedReplyComment');

  commentForm: FormGroup;
  replyForm: FormGroup;

  isEditTaskComment: boolean = false;
  isEditReplyTaskComment: boolean = false;

  processInstanceDetail$ = this.state.select('processInstanceDetail');
  processInstanceDetail: ProcessInstanceDetail | undefined;

  placeholderToggleLabel = {
      replyingTo: $localize`:@@researches.task-comment.replying-to: Replying to` ,
      editComment: $localize`:@@researches.task-comment.edit-comment: Edit Comment`,
      replyComment: $localize`:@@researches.task-comment.reply: Reply`,
  }

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private state: RxState<TaskCommentComponentState>,
    private taskFacade: TaskFacade,
    private dialog: MatDialog,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initTaskCommentComponentState);
    this.state.connect(
      'selectedTaskComment',
      this.taskFacade.selectedTaskComment$,
    );
    this.state.connect(
      'selectedReplyComment',
      this.taskFacade.selectedReplyComment$,
    );

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });

    this.replyForm = this.fb.group({
      reply: ['', [Validators.required]],
    });

    this.state.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );

    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
  }

  ngOnInit(): void {
    this.selectedTaskComment$.subscribe((selectedTaskComment) => {
      this.selectedTaskComment = selectedTaskComment;
    });

    this.selectedReplyComment$.subscribe((selectedReplyComment) => {
      this.selectedReplyComment = selectedReplyComment;
    });

    this.processInstanceDetail$.subscribe((processIntance) => {
      this.processInstanceDetail = processIntance;
    });

    this.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user;
    });
  }

  handleEditReplyTaskComment(
    isEditReplyTaskCommentClicked: boolean,
    taskComment: TaskComment,
  ) {
    if (isEditReplyTaskCommentClicked) {
      this.taskFacade.dispatchSetSelectedTaskComment(taskComment, '');
    }
  }

  replyComment(comment: TaskComment) {
    this.taskFacade.dispatchSetSelectedTaskComment(comment, 'reply');
    this.isEditTaskComment = false;
    this.commentForm.reset();
    this.toggleReplyBox();
  }

  editComment(comment: TaskComment) {
    this.taskFacade.dispatchSetSelectedTaskComment(comment, 'edit');
    this.isEditTaskComment = true;
    this.updateForm();
    this.toggleReplyBox();
  }

  cancelComment() {
    this.commentForm.reset();
    this.cancelReplyBox();
    this.isEditTaskComment = false;
  }

  openDeleteConfirmationDialog(
    event: MouseEvent,
    taskComment: TaskComment | undefined,
  ) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.task-comment.delete-comment-part-one: Are you sure you want to delete` ,
        boldText: `${taskComment!.content}` ,
        regularTextTwo:  $localize`:@@researches.task-comment.delete-comment-part-two:comment?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.taskFacade.dispatchDeleteTaskComment(taskComment?.id!);
      }
    });
  }

  addComment() {
    const { valid, touched, dirty } = this.commentForm;
    if (!this.isEditTaskComment) {
      if (valid && (touched || dirty)) {
        this.taskFacade.dispatchReplyTaskComment(
          this.selectedTaskComment!.id!,
          this.commentForm.value.comment,
        );
        this.commentForm.reset();
        this.cancelReplyBox();
      }
    } else if (this.isEditTaskComment) {
      if (valid && (touched || dirty)) {
        this.taskFacade.dispatchUpdateTaskComment(
          this.selectedTaskComment!.id!,
          this.commentForm.value.comment,
        );
        this.commentForm.reset();
        this.resetSelectedTaskComment();
      }
    }
  }

  resetSelectedTaskComment() {
    this.taskFacade.dispatchResetSelectedTaskComment();
  }

  updateForm() {
    if (this.isEditTaskComment) {
      this.commentForm.patchValue({
        comment: this.selectedTaskComment?.content,
      });
    }
  }

  hasUpdateTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.UpdateTaskComment,
    );
  }

  hasDeleteTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.DeleteTaskComment,
    );
  }

  hasReplyTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.ReplyTaskComment,
    );
  }
  hasCreateTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.CreateTaskComment,
    );
  }
}
