import { AfterViewInit, Component, DestroyRef } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { Subject, combineLatest } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ResearchCount } from '../../models/research-count';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Filter } from '../../models/filter.model';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

interface State {
  paginationState: { pageNumber: number; pageSize: number };
  filter?: Filter;
  researchCount?: PaginatedList<ResearchCount>;
}

const initialState: State = {
  paginationState: { pageNumber: 1, pageSize: 5 },
};

@Component({
  selector: 'app-department-research',
  templateUrl: './department-research.component.html',
  styleUrls: ['./department-research.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: DepartmentResearchComponent}],
})
export class DepartmentResearchComponent implements AfterViewInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  displayedColumns: string[] = ['officeName', 'total'];
  dataSource: any[] = [];

  researchCount$ = this.state.select('researchCount');

  firstPageLabel = $localize`:@@researches.department-resesarch.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.department-resesarch.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.department-resesarch.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.department-resesarch.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.department-resesarch.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.department-resesarch.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.department-resesarch.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.department-resesarch.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private fb: NonNullableFormBuilder,
    private userFacade: UserFacade,
    private destroyRef: DestroyRef,
    public state: RxState<State>,
    private matDialog: MatDialog,
    private officeFacade: OfficeFacade,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'researchCount',
      this.researchDashboardFacade.researchCount$,
    );

    this.state.select('researchCount').subscribe((val) => {});
  }
  changes = new RxState();
  ngAfterViewInit(): void {
    this.researchDashboardFacade.dispatchGetResearchCount();
    combineLatest([
      this.state.select('filter'),
      this.state.select('paginationState'),
    ]).subscribe(([filters, pagination]) => {
      this.researchDashboardFacade.dispatchGetResearchCount(
        filters?.offices ?? [],
        filters?.users ?? [],
        filters?.startDate ?? undefined,
        filters?.endDate ?? undefined,
        pagination.pageNumber,
        pagination.pageSize
      );
    });
  }

  paginateCommunicationCounts(event: PageEvent) {
    this.state.set({
      paginationState: {
        pageSize: event.pageSize,
        pageNumber: event.pageIndex + 1,
      },
    });
  }
}
