import { Component, OnInit } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Observable, filter } from 'rxjs';
import { ProcessFacade } from '../../facades/process.facades';
import { ResearchesHomeFacade } from '../../facades/researches-home.facade';

export interface Breadcrumb {
  label: string;
  url: string;
}
@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
})
export class BreadcrumbNavigationComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]> | undefined;

  constructor(
    private router: Router,
    private researchHomeFacade: ResearchesHomeFacade,
  ) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.researchHomeFacade.breadcrumbs$;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.constructBreadcrumb(root, [], breadcrumbs);

        this.researchHomeFacade.dispatchUpdateBreadcrumbs(breadcrumbs);
      });
  }

  private constructBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[],
  ) {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

      if (route.data['breadcrumb']) {
        const breadcrumb = {
          label: this.getBreadcrumbLabel(route.data),
          url: routeUrl.length > 0 ? '/' + routeUrl.join('/') : 'research/',
        };
        breadcrumbs.push(breadcrumb);
      }

      this.constructBreadcrumb(
        route.firstChild as ActivatedRouteSnapshot,
        routeUrl,
        breadcrumbs,
      );
    }
  }

  private getBreadcrumbLabel(data: Data) {
    return typeof data['breadcrumb'] === 'function'
      ? data['breadcrumb'](data)
      : data['breadcrumb'];
  }
}
