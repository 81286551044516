import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { UserFacade } from '../../facade/user.facade';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { LOGIN_ROUTE } from 'src/app/core/constants/routes';
import { PasswordPolicy } from '../../models/password-poilcy.model';

interface ResetPasswordComponentState {
  resetPasswordSuccess: boolean;
  passwordPolicy: PasswordPolicy | null
}

const initResetPasswordComponentState: Partial<ResetPasswordComponentState> = {
  resetPasswordSuccess: false,
  passwordPolicy: null
};

@Component({
  selector: 'change-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [RxState],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  resetPasswordForm = this.fb.group({
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordPattern),
      ],
    ],
    confirmPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordPattern),
        this.confirmAndNewPasswordEqualityValidator,
      ],
    ],
  });

  resetPasswordSuccess$: Observable<boolean> = this.state.select(
    'resetPasswordSuccess',
  );
  passwordPolicy$ = this.state.select('passwordPolicy',);
  passwordPolicy: PasswordPolicy | null = null

  hideConfirmPassword = true;
  hideNewPassword = true;
  passwordStrength: string = '';

  constructor(
    private fb: NonNullableFormBuilder,
    private userFacade: UserFacade,
    private state: RxState<ResetPasswordComponentState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.state.set(initResetPasswordComponentState);
    this.state.connect(
      'resetPasswordSuccess',
      userFacade.isResetPasswordSuccessful$,
    );
    this.state.connect('passwordPolicy', this.userFacade.passwordPolicy$)
  }

  ngOnInit(): void {
    this.userFacade.dispatchGetPasswordPolicy();
    this.passwordPolicy$.subscribe((policy) =>{
      if(policy){
        this.passwordPolicy = policy
      }
    })
  }

  ngOnDestroy(): void {
    this.userFacade.dispatchResetResetPasswordStatus();
  }

  toggleVisibility(field: string) {
    if (field === 'confirmPassword') {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    } else if (field === 'newPassword') {
      this.hideNewPassword = !this.hideNewPassword;
    }
  }

  confirmAndNewPasswordEqualityValidator(control: AbstractControl) {
    if (!control.parent) {
      return null;
    }
    const newPassword = control.parent.get('newPassword')?.value;
    const confirmPassword = control.value;

    if (newPassword != confirmPassword) {
      return { notMatched: true };
    }

    return null;
  }

  resetPassword() {
    const { valid, touched, dirty } = this.resetPasswordForm;
    if (
      valid &&
      (touched || dirty) &&
      this.resetPasswordForm.value.newPassword
    ) {
      var email = ''; 
      var resetToken = '';
      this.route.queryParams
      .subscribe(params => {
        email = params['email']; 
        resetToken = params['token']
      }
    );
      this.userFacade
        .dispatchResetPassword({
          email: email,
          resetToken: resetToken,
          newPassword: this.resetPasswordForm.value.newPassword,
        })

        this.resetPasswordSuccess$.subscribe(isSuccess =>{
          if(isSuccess){
              this.router.navigate([LOGIN_ROUTE]);
          }
        })
    }
  }

  updatePasswordStrength() {
    const password = this.resetPasswordForm.value.newPassword;

    if (!password) {
      this.passwordStrength = '';
      this.setStrengthColor('black');
    } else if (this.passwordPolicy && (password.length < this.passwordPolicy.requiredLength)) {
      this.passwordStrength = 'Very Weak';
      this.setStrengthColor('red');
    } else if (
      this.passwordPolicy && (
      (this.passwordPolicy.requireLowercase && !/[a-z]/.test(password)) ||
      (this.passwordPolicy.requireUppercase && !/[A-Z]/.test(password)) ||
      (this.passwordPolicy.requireDigit && !/\d/.test(password)) ||
      (this.passwordPolicy.requireNonAlphanumeric && !/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)))
    ) {
      this.passwordStrength = 'Weak';
      this.setStrengthColor('orange');
    } else if (password.length < 12) {
      this.passwordStrength = 'Medium';
      this.setStrengthColor('#FFD700');
    } else if (password.length < 16) {
      this.passwordStrength = 'Strong';
      this.setStrengthColor('green');
    } else {
      this.passwordStrength = 'Very Strong';
      this.setStrengthColor('darkgreen');
    }
  }
  private setStrengthColor(color: string) {
    const element = document.getElementById('password-strength');
    if (element) {
      element.style.color = color;
    }
  }
}
