import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  AddFormField,
  CreateFormField,
  GetFormFields,
  RemoveFormField,
  SetSelectedFormField,
  SetFormFieldUpdatingMode,
  UpdateFormField,
  DeleteFormField,
  GetDataTypes,
  ResetSelectedFormFields,
  SelectFormFields,
  ResetSelectedFormField,
} from './form-fields.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { FormFieldsService } from '../services/form-fields.service';
import { tap } from 'rxjs';
import { FieldValidationRuleFacade } from '../../field-validation-rules/facades/field-validation-rules.facade';
import { FormFields } from '../models/form-fields.model';
import {
  StateOperator,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { DocumentFormFacade } from '../../document-templates/facade/document-forms.facades';
import { DataTypeForDisplay } from '../../shared/models/data-types.model';

export interface FormFieldsStateModel {
  selectedFormFields: FormFields[];
  isUpdatingFormField?: boolean;
  selectedFormField?: FormFields | null;
  dataTypes: DataTypeForDisplay[];
  templateFields: PaginatedList<FormFields>;
}

const TEMPLATE_FIELDS_TOKEN = new StateToken<FormFieldsStateModel>(
  'templateFieldsStateModel',
);

const defaults: FormFieldsStateModel = {
  dataTypes: [],
  selectedFormFields: [],
  templateFields: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<FormFieldsStateModel>({
  name: TEMPLATE_FIELDS_TOKEN,
  defaults: defaults,
})
@Injectable()
export class FormFieldsState {
  constructor(
    public operationStatus: OperationStatusService,
    private store: Store,
    private templateFieldService: FormFieldsService,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private documentFormFacade: DocumentFormFacade,
  ) {}

  @Action(GetFormFields)
  getFormFields(
    { patchState }: StateContext<FormFieldsStateModel>,
    { documentTemplateId, pageNumber, pageSize }: GetFormFields,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.templateFieldService
      .getFormFields(documentTemplateId, pageNumber, pageSize)
      .pipe(
        tap((templateFields) => {
          patchState({
            templateFields: templateFields,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateFormField)
  createFormField(
    { getState }: StateContext<FormFieldsStateModel>,
    { templateField }: CreateFormField,
  ) {
    let previousFields = getState().selectedFormFields || [];
    const maxOrder =
      previousFields.find(
        (field) =>
          field.order === Math.max(...previousFields.map((f) => f.order)),
      )?.order ?? 0;
    templateField.order = maxOrder + 1;
    this.store.dispatch(new SetProgressOn());
    return this.templateFieldService.createFormField(templateField).pipe(
      tap((field) => {
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@documents.template-fields.added-template-field:New Form Field Added Successfully`,
        );
        this.documentFormFacade.dispatchAddFieldOnFormDetail(field);
        this.fieldValidationRuleFacade.dispatchCreateFieldValidationRules(
          field.id!,
        );
      }),
    );
  }

  @Action(AddFormField)
  addFormField(
    { getState, patchState }: StateContext<FormFieldsStateModel>,
    { newFormField }: AddFormField,
  ) {
    let previousFields = getState().selectedFormFields || [];
    const existingField = previousFields.find(
      (field) => field.name === newFormField?.name,
    );
    const maxOrder =
      previousFields.find(
        (field) =>
          field.order === Math.max(...previousFields.map((f) => f.order)),
      )?.order ?? 0;
    if (existingField) {
      this.operationStatus.displayStatus(
        $localize`:@@documents.template-fields.template-field-exists:Form Field With The Same Name Already Exists`,
      );
    } else {
      newFormField.order = maxOrder + 1;
      patchState({
        selectedFormFields: [...previousFields, newFormField],
      });
      this.operationStatus.displayStatus(
        $localize`:@@documents.template-fields.added-template-field:New Form Field Added Successfully`,
      );
    }
  }

  @Action(ResetSelectedFormFields)
  resetSelectedFormFields({
    patchState,
  }: StateContext<FormFieldsStateModel>) {
    patchState({
      selectedFormFields: [],
    });
  }

  @Action(ResetSelectedFormField)
  resetSelectedFormField({
    patchState,
  }: StateContext<FormFieldsStateModel>) {
    patchState({
      selectedFormField: null,
    });
  }

  @Action(SetFormFieldUpdatingMode)
  setFormCreatingMode(
    { patchState }: StateContext<FormFieldsStateModel>,
    { isUpdatingFormField }: SetFormFieldUpdatingMode,
  ) {
    patchState({
      isUpdatingFormField: isUpdatingFormField,
    });
  }

  @Action(SetSelectedFormField)
  setSelectedFormField(
    { patchState }: StateContext<FormFieldsStateModel>,
    { selectedFormField }: SetSelectedFormField,
  ) {
    patchState({
      selectedFormField: selectedFormField,
    });
  }

  @Action(UpdateFormField)
  updatedFormField(
    { getState, setState }: StateContext<FormFieldsStateModel>,
    { updatedFormField }: UpdateFormField,
  ) {
    const state = getState();
    const existingField = state.selectedFormFields.find(
      (field) =>
        field.name === updatedFormField?.name &&
        field.name !== state.selectedFormField?.name,
    );
    if (existingField) {
      this.operationStatus.displayStatus(
        $localize`:@@documents.template-fields.template-field-exists:Form Field With The Same Name Already Exists`,
      );
    } else {
      setState(
        patch({
          selectedFormFields: updateItem<FormFields>(
            (field) => field.name === getState().selectedFormField?.name,
            updatedFormField as FormFields,
          ),
        }),
      );
      this.operationStatus.displayStatus($localize`:@@documents.template-fields.updated-template-field:Form Field Updated Successfully`);
    }
  }

  @Action(RemoveFormField)
  removeFormField(
    { setState }: StateContext<FormFieldsStateModel>,
    { templateField }: RemoveFormField,
  ) {
    setState(
      patch({
        selectedFormFields: removeItem<FormFields>(
          (field) => field.name === templateField?.name,
        ),
      }),
    );
    this.operationStatus.displayStatus($localize`:@@documents.template-fields.removed-template-field:Form Field Removed Successfully`);
  }

  @Action(SelectFormFields)
  selectFormFields(
    { patchState }: StateContext<FormFieldsStateModel>,
    { selectedFormFields }: SelectFormFields,
  ) {
    patchState({
      selectedFormFields: selectedFormFields,
    });
  }

  @Action(DeleteFormField)
  deleteFormField(
    {}: StateContext<FormFieldsStateModel>,
    { templateFieldId }: DeleteFormField,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.templateFieldService.deleteFormField(templateFieldId).pipe(
      tap(() => {
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@documents.template-fields.deleted-template-field:Form Field Deleted Successfully`,
        );
      }),
    );
  }

  @Action(GetDataTypes)
  getDataTypes(
    { patchState }: StateContext<FormFieldsStateModel>,
    {}: GetDataTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.templateFieldService.getDataTypes().pipe(
      tap((dataTypes) => {
        patchState({
          dataTypes: dataTypes,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
