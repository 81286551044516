<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      {{
        torCommentReply?.fullName && torCommentReply?.fullName?.substring(0, 1)
      }}
    </div>
    <mat-card-title>
      {{ torCommentReply?.fullName }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ torCommentReply?.created | date: "medium" }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p class="text-comment">
      {{ torCommentReply?.content }}
    </p>
  </mat-card-content>
  <mat-card-actions class="action-buttons-end">
      <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Edit comment"
      (click)="editReply(torComment!, torCommentReply!)"
      *ngIf="currentLoggedInUser?.id === torComment?.createdBy"
    >
      <mat-icon>edit</mat-icon>
    </button>
      <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="warn"
      matTooltip="Delete comment"
      (click)="deleteComment($event, torComment!, torCommentReply!)"
      *ngIf="currentLoggedInUser?.id === torComment?.createdBy"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
