import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  CreateFormFieldDto,
  FormFields,
  UpdateFormFieldDto,
} from '../models/form-fields.model';
import { Observable } from 'rxjs';
import {
  DOCUMENT_TEMPLATE_URL,
  TEMPLATE_FIELDS_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { DataTypeForDisplay } from '../../shared/models/data-types.model';

@Injectable({
  providedIn: 'root',
})
export class FormFieldsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  createFormField(
    templateField: CreateFormFieldDto,
  ): Observable<FormFields> {
    return this.http.post<FormFields>(TEMPLATE_FIELDS_URL, templateField);
  }

  getFormFields(
    documentTemplateId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<FormFields>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<FormFields>>(TEMPLATE_FIELDS_URL, {
        params: {
          documentTemplateId,
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
        },
      });
    } else {
      return this.http.get<PaginatedList<FormFields>>(TEMPLATE_FIELDS_URL, {
        params: {
          documentTemplateId,
        },
      });
    }
  }

  deleteFormField(templateFieldId: string): Observable<any> {
    return this.http.delete<any>(
      `${TEMPLATE_FIELDS_URL}/${templateFieldId}`,
      this.httpOptions,
    );
  }

  getDataTypes(): Observable<DataTypeForDisplay[]> {
    return this.http.get<DataTypeForDisplay[]>(
      `${TEMPLATE_FIELDS_URL}/data-types`,
    );
  }

  searchFormByName(
    name: string,
  ): Observable<FormFields> {
    return this.http.get<FormFields>(
      `${DOCUMENT_TEMPLATE_URL}/by-name`,
      { params: new HttpParams({ fromObject: {templateName: name } }) },
    );
  }
}
