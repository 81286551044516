<div class="workspace-wrapper mat-typography" *ngIf="!!currentWorkspace">
  <h3 class="mat-subheading-2" *appHasPermission="hasGetWorkspacePermission()">{{ currentWorkspace!.name }}</h3>
  <mat-progress-bar
    mode="determinate"
    [value]="usage"
    [color]="usage > 85 ? 'warn' : 'primary'"
    *appHasPermission="hasGetWorkspacePermission()"
  ></mat-progress-bar>
  <p class="mat-caption" *appHasPermission="hasGetWorkspacePermission()">
    {{ workspaceSize }} <span i18n="@@files.workspace-quota.of">of</span> {{ workspaceLimit }} <span i18n="@@files.workspace-quota.used">used</span>
  </p>
  <button
    mat-stroked-button
    color="primary"
    class="workspace-bttn"
    (click)="openDialog()"
    [disabled]="!isAdmin"
    i18n="@@files.workspace-quota.manage-quota" [attr.data-test-id]="'files.manage-quota.workspace-quota.manage-quota-button'"
    *appHasPermission="hasGetWorkspacesPermission()"
  >
    Manage Quota
  </button>
</div>
