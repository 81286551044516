<div class="letter">
  <mat-card class="card">
    <mat-card-content class="content">
      <div class="sender_info">
        <p class="mat-subtitle-2">{{ letter.sender.fullName }}</p>
        <p class="mat-subtitle-2">{{ letter.sender.address }}</p>
        <p class="mat-subtitle-2">{{ letter.sender.email }}</p>
        <p class="mat-subtitle-2">{{ letter.date | date }}</p>
      </div>

      <div>
        <p class="mat-subtitle-2">{{ letter.recipient.fullName }}</p>
        <p class="mat-subtitle-2">{{ letter.recipient.address }}</p>
      </div>

      <div>
        <p class="mat-body-2 body">
          {{ letter.content }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
