import { IUpdateOfficeStructure } from './../models/office.model';
import { Select } from '@ngxs/store';
import {
  CreateOfficeDto,
  Office,
  UpdateOfficeDto,
} from '../models/office.model';
import { FlatOfficeNode } from '../models/flat-office-node.model';

export class CreateOffice {
  static readonly type = `[Offices] ${CreateOffice.name}`;
  constructor(public office: CreateOfficeDto) {}
}
export class GetOffices {
  static readonly type = `[Office] ${GetOffices.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class ResetSelectedOffice {
  static readonly type = `[Office] ${ResetSelectedOffice.name}`;
}

export class ResetSelectedOffices {
  static readonly type = `[Office] ${ResetSelectedOffices.name}`;
}

export class GetFlatOfficeNodes {
  static readonly type = `[Offices] ${GetFlatOfficeNodes.name}`;
}

export class SetSelectedFlatOfficeNode {
  static readonly type = `[Offices] ${SetSelectedFlatOfficeNode.name}`;
  constructor(public id: string) {}
}

export class GetOfficeTree {
  static readonly type = `[Offices] ${GetOfficeTree.name}`;
}

export class UploadExcel {
  static readonly type = `[Office] ${UploadExcel.name}`;
  constructor(public excelFileUplad: FormData) {}
}

export class DownloadExcelTemplate {
  static readonly type = `[Office] ${DownloadExcelTemplate.name}`;
}

export class FlushExcelTemplate {
  static readonly type = `[Office] ${FlushExcelTemplate.name}`;
}

export class SelectOffice {
  static readonly type = `[Office] ${SelectOffice.name}`;
  constructor(public selectedOffice: Office) {}
}

export class SelectFlatOfficeNode {
  static readonly type = `[Office] ${SelectFlatOfficeNode.name}`;
  constructor(public selectedFlatOfficeNode: FlatOfficeNode | undefined) {}
}

export class GetReportsToOfficeNodes {
  static readonly type = `[Office] ${GetReportsToOfficeNodes.name}`;
  constructor(public officeId: string) {}
}

export class SelectFlatOfficeNodes {
  static readonly type = `[Office] ${SelectFlatOfficeNodes.name}`;
  constructor(public selectedFlatOfficeNodes: FlatOfficeNode[]) {}
}

export class UpdateOffice {
  static readonly type = `[Office] ${UpdateOffice.name}`;
  constructor(public office: UpdateOfficeDto) {}
}

export class UpdateOfficeTreeStructure {
  static readonly type = `[Office] ${UpdateOfficeTreeStructure.name}`;
  constructor(public updateStructure: IUpdateOfficeStructure) {}
}

export class DeleteOffice {
  static readonly type = `[Office] ${DeleteOffice.name}`;
  constructor(public id: string) {}
}

export class SetPageIndexAndSize {
  static readonly type = `[Office] ${SetPageIndexAndSize.name}`;
  constructor(
    public index: number,
    public size: number,
  ) {}
}

export class GetOffice {
  static readonly type = `[Office] ${GetOffice.name}`;
  constructor(public id: string) {}
}

export class GetUserOfficeTree {
  static readonly type = `[Office] ${GetUserOfficeTree.name}`;
  constructor() {}
}
