import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PERMISSIONS_URL } from 'src/app/core/constants/api-endpoints';
import {
  Module,
  Permission,
  PermissionWithDependenciesId,
} from '../models/permission.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getPermissions(): Observable<Module[]> {
    return this.http.get<Module[]>(`${PERMISSIONS_URL}`);
  }

  getPermissionsWithDependenciesId(): Observable<
    PermissionWithDependenciesId[]
  > {
    return this.http.get<PermissionWithDependenciesId[]>(
      `${PERMISSIONS_URL}/dependencies`,
    );
  }
}
