import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ProcessInstanceState,
  ProcessInstanceStateModel,
} from './process-instance.state';

export class ProcessInstanceSelector {
  static slices =
    createPropertySelectors<ProcessInstanceStateModel>(ProcessInstanceState);

  @Selector([ProcessInstanceState])
  static selectedProcessInstance(state: ProcessInstanceStateModel) {
    return state.selectedProcessInstance;
  }

  @Selector([ProcessInstanceState])
  static update(stateModel: ProcessInstanceStateModel) {
    return stateModel.update;
  }

  @Selector([ProcessInstanceState])
  static processInstances(state: ProcessInstanceStateModel) {
    return state.processInstances;
  }
  @Selector([ProcessInstanceState])
  static ownedProcessInstances(state: ProcessInstanceStateModel) {
    return state.ownedProcessInstances;
  }

  @Selector([ProcessInstanceState])
  static processInstanceDetail(state: ProcessInstanceStateModel) {
    return state.processInstanceDetail;
  }
  @Selector([ProcessInstanceState])
  static showSearchTermAndFiltersData(state: ProcessInstanceStateModel) {
    return state.showSearchTermAndFiltersData;
  }
  @Selector([ProcessInstanceState])
  static archivedProcessInstances(state: ProcessInstanceStateModel) {
    return state.archivedProcessInstances;
  }
  @Selector([ProcessInstanceState])
  static totalCount(stateModel: ProcessInstanceStateModel) {
    return stateModel.totalCount;
  }

  @Selector([ProcessInstanceState])
  static processInstancesByAssignedTasks(state: ProcessInstanceStateModel) {
    return state.processInstancesByAssignedTasks;
  }

  @Selector([ProcessInstanceState])
  static processInstanceFilters(stateModel: ProcessInstanceStateModel) {
    return stateModel.processInstanceFilters;
  }

  @Selector([ProcessInstanceState])
  static multipleFilterData(stateModel: ProcessInstanceStateModel) {
    return stateModel.multipleFilterData;
  }

  @Selector([ProcessInstanceState])
  static isProcessInstanceFilterReset(stateModel: ProcessInstanceStateModel) {
    return stateModel.isProcessInstanceFilterReset;
  }
}
