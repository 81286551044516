import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { WorkflowAverageTimeSpentSelector } from '../store/workflow-average-time-spent.selector';
import { WorkflowAverageTimeSpent } from '../models/workflow-average-time-spent.model';
import { GetWorkflowAverageTimeSpent } from '../store/workflow-average-time-spent.action';

@Injectable({
  providedIn: 'root',
})
export class WorkflowAverageTimeSpentFacade {
  constructor(private store: Store) {}

  @Select(WorkflowAverageTimeSpentSelector.workflowAverageTimeSpents)
  workflowAverageTimeSpents$!: Observable<
    PaginatedList<WorkflowAverageTimeSpent>
  >;

  dispatchGetWorkflowAverageTimeSpent(
    pageNumber: number,
    pageSize: number,
    workflowTypeId?: string,
  ) {
    this.store.dispatch(
      new GetWorkflowAverageTimeSpent(pageNumber, pageSize, workflowTypeId),
    );
  }
}
