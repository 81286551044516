<mat-sidenav-container class="container">
  <mat-sidenav-content class="taskDetail">
    <div class="close-btn row flex-end">
      <button mat-icon-button (click)="toggleTaskInfo()">
        <mat-icon>{{
          isTaskInfoHidden ? "keyboard_arrow_left" : "close"
        }}</mat-icon>
      </button>
    </div>
    <div class="detail-content row padding-hor">
      <div
        class="left-content column scrollable"
        [ngClass]="{ expanded: !isTaskInfoHidden }"
      >
        <h4>{{ taskDetail?.name ?? "" }}</h4>
        <mat-radio-group color="primary" [(ngModel)]="composingOption">
          <mat-radio-button value="compose" i18n="@@researches.assigned-task-detail.compose">Compose</mat-radio-button>
          <mat-radio-button value="upload" i18n="@@researches.assigned-task-detail.upload-file">Upload File</mat-radio-button>
        </mat-radio-group>
        <ng-container *appHasPermission="hasCreateDocumentTaskPermission()">
        <app-task-compose
          [taskId]="taskDetail?.id ?? ''"
          [taskDetail]="taskDetail"
          [assigneeId]="taskDetail?.assignee?.id ?? ''"
          *ngIf="composingOption === 'compose'"
        ></app-task-compose>
      </ng-container>
        <div
          class="upload-file padding-vert"
          *ngIf="composingOption === 'upload'"
        >
        <app-task-file-upload *appHasPermission="hasCreateFileTaskPermission()"></app-task-file-upload>
        </div>

        <h4 *ngIf="taskFiles?.length">
          <span i18n="@@researches.assigned-task-detail.files-uploaded">Files Uploaded</span> ({{ taskFiles?.length }})
        </h4>
        <mat-chip-listbox class="mat-mdc-chip-set-stacked" *appHasPermission="hasGetFileTasksByTaskPermission()">
          <ng-container *appHasPermission="hasDeleteFileTaskPermission()">
            <mat-chip
            class="truncated-chip"
            *ngFor="let file of taskFiles"
            (removed)="removeTaskFile(file)"
          >
            <fa-icon
              matChipAvatar
              [icon]="getIconForFile(file.name)"
              class="file-icon"
            ></fa-icon>
            <span>
              {{
                extractFileNameAndExtension(file.name).name.length > 25
                  ? extractFileNameAndExtension(file.name).name.slice(0, 25) +
                    "..."
                  : extractFileNameAndExtension(file.name).name
              }}.{{ extractFileNameAndExtension(file.name).extension }}
            </span>
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          </ng-container>
        </mat-chip-listbox>

        <div class="submit-btn">
          <button
            mat-flat-button
            color="primary"
            class="custom-button"
            (click)="submitTask()"
            *appHasPermission="hasChangeStatusToSubmittedPermission()"
            [disabled]="
              taskDetail === null ||
              taskDetail === undefined ||
              (!taskDetail.taskFile && !taskDetail.taskDocument) ||
              taskDetail.taskStatus.toString().toLowerCase() ===
                TaskStatus.Submitted ||
              taskDetail.taskStatus.toString().toLowerCase() ===
                TaskStatus.Approved
            "
            i18n="@@researches.assigned-task-detail.submit-task"
          >
            Submit Task
          </button>
        </div>
        <h4 *appHasPermission="hasGetTaskCommentsPermission()"> <span i18n="@@researches.assigned-task-detail.comments">Comments</span> ({{ taskComments?.totalCount }})</h4>
        <div *ngIf="taskComments?.items?.length! > 0; else emptyTaskComments">
          <ng-container *appHasPermission="hasGetTaskCommentsPermission()">
          <app-task-comment
            *ngFor="let taskComment of taskComments?.items"
            [taskComment]="taskComment"
            [toggleReplyBoxVisibility]="toggleReplyBoxVisibility"
            [toggleReplyBox]="toggleReplyBox"
            [cancelReplyBox]="cancelReplyBox"
          ></app-task-comment>
        </ng-container>
        </div>
        <ng-template #emptyTaskComments>
          <div class="empty-task-comments">
            <span class="text-2xl" i18n="@@researches.assigned-task-detail.no-comments">No comments</span>
          </div>
        </ng-template>

        <div *ngIf="taskComments?.totalCount! > 5">
          <button
            mat-stroked-button
            color="primary"
            class="see-comments-btn custom-button"
            (click)="getPaginatedComments()"
            *appHasPermission="hasGetTaskCommentsPermission()"
          >
            <span
              *ngIf="
                taskComments?.totalCount !== taskComments?.items?.length &&
                taskComments?.totalCount !== 0
              "
              i18n="@@researches.assigned-task-detail.view-more-comments"
              >View More Comments</span
            >
            <span
              *ngIf="
                taskComments?.totalCount === taskComments?.items?.length ||
                taskComments?.totalCount === 0
              "
              i18n="@@researches.assigned-task-detail.view-less-comments"
              >View Less Comments</span
            >
          </button>
        </div>

        <div class="comment-form" *appHasPermission="hasCreateTaskCommentPermission()">
          <form [formGroup]="commentForm">
            <mat-form-field appearance="outline" class="padding-vert">
              <input
                id="name"
                formControlName="comment"
                type="text"
                #input
                maxlength="1000"
                matInput
                [placeholder]="placeholderToggleLabel.addComment"
              />
              <mat-hint align="end">{{ input.value.length }}/1000</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div class="comment-btns row">
          <button
            mat-flat-button
            class="custom-button"
            (click)="commentForm.reset()"
            [disabled]="commentForm.controls['comment'].value === ''"
            i18n="@@researches.assigned-task-detail.cancel"
            *appHasPermission="hasCreateTaskCommentPermission()"
          >
            Cancel
          </button>
          <button
            [disabled]="!commentForm.valid"
            mat-flat-button
            color="primary"
            class="custom-button"
            (click)="addComment()"
            i18n="@@researches.assigned-task-detail.comment"
            *appHasPermission="hasCreateTaskCommentPermission()"
          >
            Comment
          </button>
        </div>
      </div>
      <div
        class="right-content column"
        [ngClass]="{ hidden: isTaskInfoHidden }"
      >
        <h4 i18n="@@researches.assigned-task-detail.task-overview">Task Overview</h4>
        <div class="task-info column">
          <div class="row">
            <p i18n="@@researches.assigned-task-detail.status">Status :</p>
            <button
              mat-flat-button
              color="primary"
              class="custom-button"
              [ngClass]="getTaskStatusColor(taskDetail?.taskStatus)"
              (click)="toggleStatusDropdown()"
              [matMenuTriggerFor]="menu"
            >
              {{ checkTaskStatus(taskDetail?.taskStatus) }}
              <mat-icon>{{
                isStatusDropdownOpen
                  ? "keyboard_arrow_up"
                  : "keyboard_arrow_down"
              }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu" (closed)="toggleStatusDropdown()">
              <ng-container *appHasPermission="hasChangeStatusToInProgressPermission() || hasChangeStatusToTodoPermission()">
                <button
                mat-menu-item
                *ngFor="let status of statuses"
                (click)="selectStatus(status)"
              >
                {{ checkTaskStatus(status) }}
              </button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="row">
            <p><span i18n="@@researches.assigned-task-detail.start-date">Start Date</span> : {{ taskDetail?.startDate ?? "" }}</p>
          </div>
          <div class="row">
            <p><span i18n="@@researches.assigned-task-detail.end-date">End Date</span> : {{ taskDetail?.endDate ?? "" }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="description padding-vert">
          <h4 i18n="@@researches.assigned-task-detail.task-description">Task Description</h4>
          <p>{{ taskDetail?.description ?? "" }}</p>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
