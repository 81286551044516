import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WorkflowCommentSelector } from '../store/workflow-comment.selectors';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ReportedWorkflowComment,
  RespondReportedWorkflowComment,
  WorkflowComment,
  WorkflowCommentPolicy,
} from '../models/workflow-comment.model';
import {
  CreateWorkflowComment,
  CreateWorkflowCommentPolicy,
  DeleteWorkflowComment,
  DeleteWorkflowCommentPolicy,
  DeleteWorkflowCommentReply,
  EditWorkflowComment,
  GetArchivedWorkflowComments,
  GetReportedWorkflowComments,
  GetWorkflowCommentPolicy,
  GetWorkflowComments,
  GetWorkflowCommentsByReported,
  ReplyWorkflowComment,
  ReportWorkflowComment,
  ResetSelectedReplyComment,
  ResetWorkflowComments,
  ResolveReportedWorkflowComment,
  SetReplyWorkflowComment,
  SetSelectedReplyComment,
  SetSelectedReportedWorkflowComment,
  SetSelectedWorkflowComment,
  SetUpdateWorkflowComment,
  SetWorkflowReplyUpdatingMode,
  UpdateWorkflowCommentPolicy,
  UpdateWorkflowCommentReply,
} from '../store/workflow-comment.actions';

@Injectable({
  providedIn: 'root',
})
export class WorkflowCommentFacade {
  constructor(private store: Store) {}

  @Select(WorkflowCommentSelector.workflowComments)
  workflowComments$!: Observable<PaginatedList<WorkflowComment>>;

  @Select(WorkflowCommentSelector.update)
  update$!: Observable<boolean>;

  @Select(WorkflowCommentSelector.selectedWorkflowComment)
  selectedWorkflowComment$!: Observable<WorkflowComment | undefined>;

  @Select(WorkflowCommentSelector.reply)
  reply$!: Observable<boolean>;

  @Select(WorkflowCommentSelector.workflowCommentPolicy)
  workflowCommentPolicy$!: Observable<WorkflowCommentPolicy>;

  @Select(WorkflowCommentSelector.reportedWorkflowComments)
  reportedWorkflowComments$!: Observable<
    PaginatedList<ReportedWorkflowComment>
  >;

  @Select(WorkflowCommentSelector.selectedReportedWorkflowComment)
  selectedReportedWorkflowComment$!: Observable<
    ReportedWorkflowComment | undefined
  >;

  @Select(WorkflowCommentSelector.selectedReply)
  selectedReply$!: Observable<WorkflowComment>

  @Select(WorkflowCommentSelector.isUpdatingReply)
  isUpdatingReply$!: Observable<boolean>;

  dispatchGetWorkflowComments(
    workflowId: string,
    isArchived: boolean,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      !isArchived
        ? new GetWorkflowComments(workflowId, pageNumber, pageSize)
        : new GetArchivedWorkflowComments(workflowId, pageNumber, pageSize),
    );
  }

  dispatchCreateWorkflowComment(workflowId: string, comment: string) {
    this.store.dispatch(new CreateWorkflowComment(workflowId, comment));
  }

  dispatchDeleteWorkflowComment(workflowId: string, workflowCommentId: string) {
    this.store.dispatch(
      new DeleteWorkflowComment(workflowId, workflowCommentId),
    );
  }

  dispatchSetUpdateWorkflowComment(status: boolean) {
    this.store.dispatch(new SetUpdateWorkflowComment(status));
  }

  dispatchSetSelectedWorkflowComment(workflowComment: WorkflowComment) {
    this.store.dispatch(new SetSelectedWorkflowComment(workflowComment));
  }

  dispatchEditWorkflowComment(
    workflowId: string,
    workflowCommentId: string,
    content: string,
  ) {
    this.store.dispatch(
      new EditWorkflowComment(workflowId, workflowCommentId, content),
    );
  }

  dispatchSetReplyWorkflowComment(status: boolean) {
    this.store.dispatch(new SetReplyWorkflowComment(status));
  }

  dispatchReplyWorkflowComment(workflowCommentId: string, content: string) {
    this.store.dispatch(new ReplyWorkflowComment(workflowCommentId, content));
  }

  dispatchReportWorkflowComment(
    workflowCommentId: string,
    issueDescription: string,
  ) {
    this.store.dispatch(
      new ReportWorkflowComment(workflowCommentId, issueDescription),
    );
  }

  dispatchGetWorkflowCommentPolicy() {
    this.store.dispatch(new GetWorkflowCommentPolicy());
  }

  dispatchCreateWorkflowCommentPolicy(description: string) {
    this.store.dispatch(new CreateWorkflowCommentPolicy(description));
  }

  dispatchUpdateWorkflowCommentPolicy(id: string, description: string) {
    this.store.dispatch(new UpdateWorkflowCommentPolicy(id, description));
  }

  dispatchDeleteWorkflowCommentPolicy(id: string) {
    this.store.dispatch(new DeleteWorkflowCommentPolicy(id));
  }

  dispatchGetReportedWorkflowComments(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetReportedWorkflowComments(pageNumber, pageSize));
  }

  dispatchSetSelectedReportedWorkflowComment(
    reportedWorkflowComment: ReportedWorkflowComment,
  ) {
    this.store.dispatch(
      new SetSelectedReportedWorkflowComment(reportedWorkflowComment),
    );
  }

  dispatchGetWorkflowCommentsByReported(
    workflowCommentId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    this.store.dispatch(
      new GetWorkflowCommentsByReported(
        workflowCommentId,
        pageNumber,
        pageSize,
      ),
    );
  }

  dispatchResloveReportedWorkflowComment(
    respondReportedWorkflowComment: RespondReportedWorkflowComment,
  ) {
    this.store.dispatch(
      new ResolveReportedWorkflowComment(respondReportedWorkflowComment),
    );
  }

  dispatchUpdateWorkflowCommentReply(
    workflowCommentid: string, 
    commentId: string, 
    content: string
    ) {
      return this.store.dispatch(new UpdateWorkflowCommentReply(workflowCommentid, commentId, content));

    }

    dispatchSetSelectedReplyComment(
      comment: WorkflowComment,
    ) {
      this.store.dispatch( new SetSelectedReplyComment(comment));
    }

    dispatchResetSelectedReplyComment(){
      this.store.dispatch( new ResetSelectedReplyComment);
    }

    dispatchSetWorkflowReplyUpdatingMode(isUpdatingReply: boolean) {
      this.store.dispatch(new SetWorkflowReplyUpdatingMode(isUpdatingReply));
    }

    dispatchDeleteWorkflowCommentReply(
      workflowCommentId: string,
      commentId: string
      ){
        this.store.dispatch( new DeleteWorkflowCommentReply(workflowCommentId, commentId));
      }

    dispatchResetWorkflowComments(
      ){
        this.store.dispatch( new ResetWorkflowComments());
      }
}
