import { Selector, createPropertySelectors } from '@ngxs/store';
import { TaskState, TaskStateModel } from './task.state';

export class TaskSelector {
  static slices = createPropertySelectors<TaskStateModel>(TaskState);

  @Selector([TaskState])
  static selectedTask(taskModel: TaskStateModel) {
    return taskModel.selectedTask;
  }
  @Selector([TaskState])
  static assingedTasks(taskModel: TaskStateModel) {
    return taskModel.assignedTasks;
  }

  @Selector([TaskState])
  static myTasks(taskModel: TaskStateModel) {
    return taskModel.myTasks;
  }

  @Selector([TaskState])
  static update(taskModel: TaskStateModel) {
    return taskModel.update;
  }

  @Selector([TaskState])
  static taskDetail(stateModel: TaskStateModel) {
    return stateModel.taskDetail;
  }

  @Selector([TaskState])
  static tasks(stateModel: TaskStateModel) {
    return stateModel.myTasks;
  }

  @Selector([TaskState])
  static tasksWithInprocess(state: TaskStateModel) {
    return state.myTasks;
  }

  @Selector([TaskState])
  static taskDetailFrom(state: TaskStateModel) {
    return state.taskDetailFrom;
  }

  @Selector([TaskState])
  static isSearching(state: TaskStateModel) {
    return state.isSearching;
  }

  @Selector([TaskState])
  static taskComments(state: TaskStateModel) {
    return state.taskComments;
  }

@Selector([TaskState])
  static stageInstanceTaskDocuments(state: TaskStateModel) {
    return state.stageInstanceTaskDocuments;
  }

  @Selector([TaskState])
  static selectedTaskComment(state: TaskStateModel) {
    return state.selectedTaskComment;
  }

  @Selector([TaskState])
  static Documents(state: TaskStateModel) {
    return state.documents;
  }

  @Selector([TaskState])
  static TotalCount(stateModel: TaskStateModel) {
    return stateModel.totalCount;
  }

  @Selector([TaskState])
  static taskTypes(stateModel: TaskStateModel) {
    return stateModel.taskTypes;
  }

  static isEditTaskComment(stateModel: TaskStateModel) {
    return stateModel.isEditTaskComment;
  }

  @Selector([TaskState])
  static isReplyTaskComment(stateModel: TaskStateModel) {
    return stateModel.isReplyTaskComment;
  }

  @Selector([TaskState])
  static fileTaskUploadReports(stateModel: TaskStateModel) {
    return stateModel.fileTaskUploadReports;
  }

  @Selector([TaskState])
  static selectedReplyComment(stateModel: TaskStateModel) {
    return stateModel.selectedReplyComment;
  }

  @Selector([TaskState])
  static videoBlob(stateModel: TaskStateModel) {
    return stateModel.videoBlob;
  }

  @Selector([TaskState])
  static audioBlob(stateModel: TaskStateModel) {
    return stateModel.audioBlob;
  }

  @Selector([TaskState])
  static taskFiles(stateModel: TaskStateModel) {
    return stateModel.taskFiles;
  }
  @Selector([TaskState])
  static assignedTaskDocuments(stateModel: TaskStateModel) {
    return stateModel.assignedTaskDocuments;
  }
}
