import { Selector, createPropertySelectors } from '@ngxs/store';
import { PermissionState, PermissionStateModel } from './permission.state';

export class PermissionSelector {
  static slices =
    createPropertySelectors<PermissionStateModel>(PermissionState);

  @Selector([PermissionState])
  static modules(stateModel: PermissionStateModel) {
    return stateModel.modules;
  }

  @Selector([PermissionState])
  static permissionsWithDependenciesId(stateModel: PermissionStateModel) {
    return stateModel.permissionsWithDependenciesId;
  }
}
