import { Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessTasks } from 'src/app/researches/models/process-tasks.model';

interface TaskSearchResultComponentState {
  processTasks: ProcessTasks[];
  showCardContent: boolean[];
}

const initialTaskSearchResultComponentState: TaskSearchResultComponentState = {
  processTasks: [],
  showCardContent: [],
};

@Component({
  selector: 'app-task-search-result',
  templateUrl: './task-search-result.component.html',
  styleUrls: ['./task-search-result.component.scss'],
})
export class TaskSearchResultComponent {
  processTasks: ProcessTasks[] = [];
  processTasks$ = this.state.select('processTasks');

  showCardContent: boolean[] = [];
  showCardContent$ = this.state.select('showCardContent');

  constructor(
    private taskFacade: TaskFacade,
    private state: RxState<TaskSearchResultComponentState>,
  ) {
    this.state.set(initialTaskSearchResultComponentState);
  }

  ngOnInit(): void {
    this.processTasks$.subscribe((processTasks) => {
      this.processTasks = processTasks;
    });

    this.showCardContent$.subscribe((showCardContent) => {
      this.showCardContent = showCardContent;
    });
  }

  toggleSearchCardContent(index: number) {
    let updatedShowCardContent: boolean[] = [];

    if (this.showCardContent.length === 0) {
      updatedShowCardContent = Array(this.processTasks.length).fill(false);
    } else {
      updatedShowCardContent = this.showCardContent.map((value, i) =>
        i === index ? !value : value,
      );
    }
    this.taskFacade.dispatchToggleCardContent(updatedShowCardContent);
  }
}
