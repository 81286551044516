import { Selector, createPropertySelectors } from '@ngxs/store';
import { OfficeState, OfficeStateModel } from './office.state';

export class OfficeSelector {
  static slices = createPropertySelectors<OfficeStateModel>(OfficeState);

  @Selector([OfficeState])
  static offices(stateModel: OfficeStateModel) {
    return stateModel.offices;
  }

  @Selector([OfficeState])
  static flatOfficeNodes(stateModel: OfficeStateModel) {
    return stateModel.flatOfficeNodes;
  }

  @Selector([OfficeState])
  static officeTreeNodes(stateModel: OfficeStateModel) {
    return stateModel.officeTreeNodes;
  }

  @Selector([OfficeState])
  static selectedFlatOfficeNode(stateModel: OfficeStateModel) {
    return stateModel.selectedFlatOfficeNode;
  }

  @Selector([OfficeState])
  static selectedFlatOfficeNodes(stateModel: OfficeStateModel) {
    return stateModel.selectedFlatOfficeNodes;
  }

  @Selector([OfficeState])
  static paginatedOffices(stateModel: OfficeStateModel) {
    return stateModel.paginatedOffices;
  }

  @Selector([OfficeState])
  static selectedOffice(stateModel: OfficeStateModel) {
    return stateModel.selectedOffice;
  }

  @Selector([OfficeState])
  static totalCount(stateModel: OfficeStateModel) {
    return stateModel.totalCount;
  }

  @Selector([OfficeState])
  static excelUploadReports(stateModel: OfficeStateModel) {
    return stateModel.excelUploadReports;
  }

  @Selector([OfficeState])
  static templateExcelFileUrl(stateModel: OfficeStateModel) {
    return stateModel.templateExcelFileUrl;
  }

  @Selector([OfficeState])
  static office(stateModel: OfficeStateModel) {
    return stateModel.office;
  }
}
