<h1 class="mat-h1" i18n="@@documents.documents-analytics-home.dashboard">Dashboard</h1>
<div class="container">
  <form class="action-btn" [formGroup]="filterForm" *appHasFeature="hasDocumentAnalyticsFeature()">
    <button mat-flat-button color="primary" (click)="openSingleOffice()"
    i18n="@@documents.documents-analytics-home.select-office">
      Select office
    </button>
    <mat-chip *ngIf="selectedFlatOfficeNode" (removed)="removeSelectedOffice()">
      {{ selectedFlatOfficeNode.name }}
      <mat-icon matChipRemove
        >cancel</mat-icon
      >
    </mat-chip>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.documents-analytics-home.user">User</mat-label>
      <mat-select
        placeholder="Select user"
        formControlName="user"
        (selectionChange)="onUserChange()"
        hideSingleSelectionIndicator="true"
      >
        <mat-select-trigger>
          {{ filterForm.controls["user"].value?.firstName || "" }}
        </mat-select-trigger>
        <mat-option *ngFor="let user of users; let i = index" [value]="user">
          <mat-radio-button
            [checked]="filterForm.controls['user'].value === user"
            (change)="onUserChange()"
          >
            {{ user.firstName + " " + user.middleName + " " + user.lastName }}
          </mat-radio-button>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@documents.documents-analytics-home.start-and-end-date">Start and End Date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="Start date"
          formControlName="startDate"
        />
        <input matEndDate placeholder="End date" formControlName="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-stroked-button color="warn" (click)="resetFilter()"
    i18n="@@documents.documents-analytics-home.reset-filter">
      Reset Filter
    </button>
  </form>
  <div class="cards-container">
    <div class="workflow-cards-container">
      <mat-card
      color="primary"
      class="count-card mat-elevation-z0"
      *appHasPermission="hasGetWorkflowTypesCountPermission()"
    >
      <mat-card-header>
        <mat-card-title i18n="@@documents.documents-analytics-home.workflow-types">Workflow Types</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <h1 class="mat-h1">{{ workflowTypesCount }}</h1>
      </mat-card-content>
    </mat-card>

    <mat-card
      color="primary"
      class="count-card workflow-count"
      (click)="initiatedWorkflows()"
      *appHasPermission="hasGetInitiatedWorkflowsCountPermission()"
    >
      <mat-card-header>
        <mat-card-title i18n="@@documents.documents-analytics-home.workflows-initiated">Workflows Initiated</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="workflow-statuses">
          <div *ngFor="let status of workflowStatuses">
            <h4>{{ status[0] | titlecase }}: {{ status[1] }}</h4>
          </div>
        </div>
      </mat-card-content>
      <mat-card-content>
        <h1 class="mat-h1">{{ calculateTotalWorkflows() }}</h1>
      </mat-card-content>
    </mat-card>
    <mat-card
    color="primary"
    class="count-card mat-elevation-z0"
    *appHasPermission="hasGetReceivedExternalDocumentCountPermission()"
  >
    <mat-card-header (click)="externalDocumentDetail()">
      <mat-card-title i18n="@@documents.documents-analytics-home.external-document">External Documents</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h1 class="mat-h1">{{ receivedExternalDocumentsCount }}</h1>
    </mat-card-content>
  </mat-card>

    <mat-card
      class="count-card workflow-time-card"
      (click)="workflowTypeStepTime()"
      *appHasPermission="hasGetAverageTimeSpentPerWorkflowTypeStepPermission()"
    >
      <mat-card-header class="">
        <mat-card-title class="" i18n="@@documents.documents-analytics-home.time-spent-on-a-workflow">Time spent on a workflow</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content"><h1 class="mat-h1">0</h1></mat-card-content>
    </mat-card>

    <mat-card
      class="count-card"
      (click)="myWorkflows()"
      *appHasPermission="hasGetMyInitiatedWorkflowsPermission()"
    >
      <mat-card-header class="">
        <mat-card-title class="" i18n="@@documents.documents-analytics-home.my-workflows">My Workflows</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content"><h1 class="mat-h1">{{myWorkflowsCount}}</h1></mat-card-content>
    </mat-card>

    <mat-card
      class="count-card workflow-time-card"
      (click)="involvedWorkflows()"
      *appHasPermission="hasGetWorkflowsIAmInvovedInPermission()"
    >
      <mat-card-header class="m-1">
        <mat-card-title class="mb-1"
        i18n="@@documents.documents-analytics-home.workflows-i-am-involved-in"
          >Workflows I am involved in</mat-card-title
        >
      </mat-card-header>
      <mat-card-content class="content"><h1 class="mat-h1">{{involvedWorkflowsCount}}</h1></mat-card-content>
    </mat-card>
    </div>
    <app-workflows-time-graph *appHasPermission="hasGetWorkflowsCountByTimelinePermission()"/>
    <app-workflow-average-time-spent *appHasPermission="hasGetWorkflowsAverageTimeSpentPermission()" />
    <div>
      <mat-card
        class="count-card reported-comment-card"
        (click)="reportedCommentsReport()"
        *appHasPermission="hasGetReportedCommentsReportPermission()"
      >
        <mat-card-header class="m-1">
          <mat-card-title class="mb-1" i18n="@@documents.documents-analytics-home.reported-comments">Reported Comments</mat-card-title>
        </mat-card-header>
        <mat-card-content
          ><h2 class="mat-h2">{{ reportedCommentsCount }}</h2></mat-card-content
        >
      </mat-card>
    </div>
  </div>
</div>
<app-reported-comments-percentage *appHasPermission="hasGetReportedCommentPercentagePermission()" />
