<div class="flex justify-between mx-5">
  <h1 class="mat-h1">{{ selectedNotice?.title }}</h1>
  <div class="flex" *ngIf="selectedNotice?.noticeStatus?.status !== 'Completed'" >
    <button mat-icon-button (click)="updateNotice()" *appHasPermission="hasUpdateNoticePermission()">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button (click)="deleteNotice()" *appHasPermission="hasDeleteNoticePermission()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>

<div class="editor--wrapper">
  <div id="quill-container"></div>
</div>

<ng-container *ngIf="selectedNotice?.attachments?.length; else emptyFilesList">
  <div class="mx-5" *appHasPermission="hasGetNoticesAttachmentPermission()">
    <h2 i18n="@@documents.notices-detail.files" class="mat-h2">Files</h2>
    <mat-card
      *ngFor="let attachment of selectedNotice?.attachments"
      class="custom-mat-card"
      appearance="outlined"
    >
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>{{ attachment.name }}</mat-card-title>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="previewAttachment(attachment)" i18n="@@documents.notices-detail.preview">
              Preview
            </button>
            <button mat-menu-item (click)="downloadAttachment(attachment)" i18n="@@documents.notices-detail.download">
              Download
            </button>
            <button
              mat-menu-item
              (click)="deleteNoticeAttachment(attachment.id)"
             i18n="@@documents.notices-detail.delete">
              Delete
            </button>
          </mat-menu>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content></mat-card-content>
    </mat-card>
  </div>
</ng-container>
<ng-template #emptyFilesList>
  <div class="mx-5">
    <h2 i18n="@@documents.notices-detail.files">Files</h2>
  </div>
  <div class="empty-notices-list">
    <span class="text-2xl" i18n="@@documents.notices-detail.no-files-attached">No files attached</span>
  </div>
</ng-template>
