import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Office } from 'src/app/offices/models/office.model';
import { Role } from 'src/app/users/models/role.model';
import { User } from 'src/app/users/models/user.model';

export interface Memo {
  id: string;
  title: string;
  parentMemoId: string | undefined;
  body: any;
  sentById: string;
  sentFrom: User;
  attachments: MemoFile[];
  status: string;
  memoStatusForDisplay: MemoStatusForDisplay;
  lastModified:string;
  ccedUsers?: CcUserWithOfficeDto[];
}

export interface MemoFile {
  id: string;
  name: string;
  uploadedById: string;
  fileSize: string;
  created: Date;
  lastModified: Date;
  lastModifiedId?: string;
}

export enum MemoStatus {
  Sent = 'Sent',
  Draft = 'Draft'
}

export interface MemoAccessDetail {
  senderUser: User,
  senderRole : Role[],
  senderOffice:FlatOfficeNode[] ,
  offices: FlatOfficeNode[];
  roles: Role[];
  users: User[];
}

export interface CcUserWithOfficeDto {
  user: User;
  usersOffice: Office;
}

export interface MemoFilter{
  id?:string
  memoFilterForDisplay:string
  translations:any[]
}

export interface MemoStatusForDisplay{
  id?:string;
  memoStatusForDisplay: string;
}