import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import {
  CreateWorkflowTypeStep,
  WorkflowTypeStepDto,
} from '../../models/workflow-type-steps.model';
import { WorkflowTypeStepsFacade } from '../../facade/workflow-type-steps.facades';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkflowTypeStepAddFieldsComponent } from '../workflow-type-step-add-fields/workflow-type-step-add-fields.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { Role } from 'src/app/users/models/role.model';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import {
  filter,
  combineLatest,
  take,
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  of,
} from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FlatOfficeNode } from '../../../../offices/models/flat-office-node.model';
import { OfficeFacade } from '../../../../offices/facades/office.facades';
import { OfficeTreeComponent } from '../../../../offices/components/office-tree/office-tree.component';

interface WorkflowTypeStepFormComponentState {
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  documentTemplates: DocumentForm[];
  update: boolean;
  selectedWorkflowType: WorkflowTypes | null;
  selectedWorkflowTypeStep: WorkflowTypeStepDto | undefined;
  selectedWorkflowTypeStepDocumentTemplateFields: FormFields[] | [];
  roles: Role[];
}

const initWorkflowTypeStepFormComponentState: WorkflowTypeStepFormComponentState =
  {
    selectedFlatOfficeNode: undefined,
    documentTemplates: [],
    update: false,
    selectedWorkflowType: null,
    selectedWorkflowTypeStep: undefined,
    selectedWorkflowTypeStepDocumentTemplateFields: [],
    roles: [],
  };

const ownerRequiredValidator: ValidatorFn = (control) => {
  const ownerRole: Role = control.value;
  if (ownerRole === null) {
    return { ownerRequired: true };
  }
  return null;
};

@Component({
  selector: 'app-workflow-type-step-form',
  templateUrl: './workflow-type-step-form.component.html',
  styleUrls: ['./workflow-type-step-form.component.scss'],
})
export class WorkflowTypeStepFormComponent implements OnInit {
  workflowTypeStepForm: FormGroup;

  formTitle: string = $localize`:@@documents.workflow-type-step-form.add-step:Add Step`;
  saveButtonText: string = $localize`:@@documents.workflow-type-step-form.add-step:Add Step`;

  documentTemplates: DocumentForm[] = [];
  documentTemplates$ = this.state.select('documentTemplates');

  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');

  selectedWorkflowType: WorkflowTypes | undefined;
  selectedWorkflowType$ = this.state.select('selectedWorkflowType');

  selectedWorkflowTypeStep$ = this.state.select('selectedWorkflowTypeStep');
  selectedWorkflowTypeStep: WorkflowTypeStepDto | undefined;

  selectedWorkflowTypeStepDocumentTemplateFields: FormFields[] | [] = [];
  selectedWorkflowTypeStepDocumentTemplateFields$ = this.state.select(
    'selectedWorkflowTypeStepDocumentTemplateFields',
  );

  update: boolean = false;
  update$ = this.state.select('update');

  roles: Role[] = [];
  roles$ = this.state.select('roles');
  filteredOwner: Role[] = [];

  placeholderFieldLabel = {
    processDescriptionField: $localize`:@@documents.workflow-type-step-form.this-is-description-for-the-process: This is description for the process.`,
    ownerRoleField: $localize`:@@documents.workflow-type-step-form.owner-role: Owner Role`,
  };

  @ViewChild('roleInput') roleInput!: ElementRef<HTMLInputElement>;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private officeFacade: OfficeFacade,
    private state: RxState<WorkflowTypeStepFormComponentState>,
    private documentFormFacade: DocumentFormFacade,
    private workflowTypesFacade: WorkflowTypesFacade,
    private workflowTypeStepFacade: WorkflowTypeStepsFacade,
    private WorkflowTypeStepsFacade: WorkflowTypeStepsFacade,
    public dialogRef: MatDialogRef<WorkflowTypeStepFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleFacade: RoleFacade,
  ) {
    this.state.set(initWorkflowTypeStepFormComponentState);
    this.state.connect(
      'documentTemplates',
      this.documentFormFacade.documentForms$,
    );
    this.state.connect(
      'selectedFlatOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect(
      'selectedWorkflowType',
      this.workflowTypesFacade.selectedWorkflowType$,
    );
    this.state.connect(
      'selectedWorkflowTypeStep',
      this.WorkflowTypeStepsFacade.selectedWorkflowTypeStep$,
    );
    this.state.connect('update', WorkflowTypeStepsFacade.update$);
    this.state.connect(
      'selectedWorkflowTypeStepDocumentTemplateFields',
      this.WorkflowTypeStepsFacade
        .selectedWorkflowTypeStepDocumentTemplateFields$,
    );
    this.state.connect('roles', this.roleFacade.roles$);
    this.workflowTypeStepForm = this.fb.group({
      name: ['', [Validators.required], [this.workflowStepAsyncValidator()]],
      description: ['', [Validators.required]],
      documentTemplateId: ['', [Validators.required]],
      ownerSearchQuery: [''],
      ownerOffice: [''],
      ownerRole: [null, ownerRequiredValidator],
      includeTextComposer: [false],
    });
  }

  ngOnInit(): void {
    this.roleFacade.dispatchGetRoles();
    this.officeFacade.resetSelectedFlatOfficeNode();
    this.documentFormFacade.dispatchGetApprovedForms();
    this.documentTemplates$.subscribe((documentTemplates) => {
      if (documentTemplates) {
        this.documentTemplates = documentTemplates;
      }
    });

    this.selectedFlatOfficeNode$.subscribe((selectedOffice) => {
      this.selectedFlatOfficeNode = selectedOffice;
    });

    this.workflowTypesFacade.selectedWorkflowType$.subscribe(
      (selectedWorkflowType) => {
        if (selectedWorkflowType) {
          this.selectedWorkflowType = selectedWorkflowType;
        }
      },
    );

    combineLatest([this.selectedWorkflowTypeStep$, this.update$]).subscribe(
      ([selectedWorkflowTypeStep, update]) => {
        if (selectedWorkflowTypeStep) {
          this.selectedWorkflowTypeStep = selectedWorkflowTypeStep;
        }
        this.update = update;
      },
    );
    this.selectedWorkflowTypeStepDocumentTemplateFields$.subscribe(
      (selectedWorkflowTypeStepDocumentTemplateFields) => {
        this.selectedWorkflowTypeStepDocumentTemplateFields =
          selectedWorkflowTypeStepDocumentTemplateFields;
      },
    );

    if (this.update && this.selectedWorkflowTypeStep) {
      if (this.selectedWorkflowTypeStep.ownerOfficeId) {
        this.officeFacade.dispatchGetOffice(
          this.selectedWorkflowTypeStep.ownerOfficeId,
        );
      }

      this.workflowTypeStepForm.patchValue({
        name: this.selectedWorkflowTypeStep.name,
        description: this.selectedWorkflowTypeStep.description,
        ownerRoleId: this.selectedWorkflowTypeStep.ownerRoleId,
        ownerRole: this.selectedWorkflowTypeStep.ownerRole,
        ownerOfficeId: this.selectedWorkflowTypeStep.ownerOfficeId,
        documentTemplateId: this.selectedWorkflowTypeStep.documentTemplateId,
        includeTextComposer: this.selectedWorkflowTypeStep.includeTextComposer,
      });
      this.selectedWorkflowTypeStep.ownerOffice &&
        this.officeFacade.dispatchSelectFlatOfficeNode(
          this.selectedWorkflowTypeStep.ownerOffice,
        );

      (this.formTitle = $localize`:@@documents.workflow-type-step-form.update-step:Update Step`),
        (this.saveButtonText = $localize`:@@documents.workflow-type-step-form.update-step:Update Step`);
    } else {
      this.workflowTypeStepForm.addControl(
        'documentTemplateId',
        new FormControl('', [Validators.required]),
      );
      this.workflowTypeStepForm.reset();
    }

    this.state.select('roles').subscribe((roles) => {
      this.roles = roles;
    });

    this.state.select('roles').subscribe((roles) => {
      this.filteredOwner = roles;
    });

    this.workflowTypeStepForm.controls['ownerSearchQuery'].valueChanges
      .pipe(filter((val) => typeof val == 'string'))
      .subscribe((query) => {
        this.filteredOwner = this.filterRoles(query);
      });
  }

  private filterRoles(query: string | null) {
    return this.roles.filter((role) =>
      role.name.toLowerCase().includes(query?.toLocaleLowerCase() || ''),
    );
  }

  selectOwner(event: MatAutocompleteSelectedEvent) {
    this.workflowTypeStepForm.controls['ownerRole'].setValue(
      event.option.value,
    );
    this.roleInput.nativeElement.value = '';
  }

  private workflowStepAsyncValidator(): AsyncValidatorFn {
    return (c: AbstractControl) => {
      return of(c.value).pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap((name) =>
          this.workflowTypeStepFacade
            .dispatchGetStepByName(this.selectedWorkflowType?.id!, name)
            .pipe(
              map((val) => {
                console.log(
                  'dagem',
                  val,
                  this.selectedWorkflowTypeStep,
                  this.update,
                );
                if (this.update && this.selectedWorkflowTypeStep?.id) {
                  return !!val && val.id != this.selectedWorkflowTypeStep?.id
                    ? { duplicatedStepName: true }
                    : null;
                }
                return !!val ? { duplicatedStepName: true } : null;
              }),
            ),
        ),
      );
    };
  }

  removeOwner() {
    this.workflowTypeStepForm.controls['ownerRole'].setValue(undefined);
    this.workflowTypeStepForm.controls['ownerRole'].setErrors({
      required: true,
    });
  }

  openOfficeSelector() {
    this.dialog.open(OfficeTreeComponent, {
      data: {
        update: false,
      },
    });
  }

  removeSelectedFlatOfficeNode(officeNode: FlatOfficeNode) {
    if (this.selectedFlatOfficeNode === undefined) return;
    this.selectedFlatOfficeNode = undefined;
    this.officeFacade.dispatchSelectFlatOfficeNode(this.selectedFlatOfficeNode);
    this.workflowTypeStepForm.controls['ownerOffice'].setValue(undefined);
  }

  save() {
    const { valid, touched, dirty } = this.workflowTypeStepForm;
    let dispatch;
  
    if (valid && (touched || dirty)) {
      if (
        this.update &&
        this.selectedWorkflowTypeStep &&
        this.selectedWorkflowTypeStep.id
      ) {
        dispatch = this.workflowTypeStepFacade.dispatchUpdateWorkflowTypeStep({
          id: this.selectedWorkflowTypeStep?.id,
          name: this.workflowTypeStepForm.value.name,
          description: this.workflowTypeStepForm.value.description,
          documentTemplateId:
            this.workflowTypeStepForm.value.documentTemplateId,
          ownerRoleId: this.workflowTypeStepForm.value.ownerRole.id,
          ownerOfficeId: this.selectedFlatOfficeNode
            ? this.selectedFlatOfficeNode.id
            : null,
          includeTextComposer:
            this.workflowTypeStepForm.value.includeTextComposer ?? false,
          visibleTemplateFieldIds:
            this.selectedWorkflowTypeStepDocumentTemplateFields
              .filter((field) => Boolean(field.id))
              .map((field) => field.id) as string[],
        });
      } else {
        const workflowTypeStep: CreateWorkflowTypeStep = {
          workflowTypeId: this.selectedWorkflowType?.id!,
          ...this.workflowTypeStepForm.value,
          visibleTemplateFieldIds:
            this.selectedWorkflowTypeStepDocumentTemplateFields.map(
              (templateField) => templateField.id!,
            ),
          ownerRoleId: this.workflowTypeStepForm.value.ownerRole.id,
          ownerOfficeId: this.selectedFlatOfficeNode
            ? this.selectedFlatOfficeNode.id
            : null,
          includeTextComposer:
            this.workflowTypeStepForm.value.includeTextComposer ?? false,
        };
        dispatch =
          this.workflowTypeStepFacade.dispatchCreateWorkflowTypeStep(
            workflowTypeStep,
          );
      }
  
      dispatch.pipe(take(1)).subscribe({
        complete: () => {
          this.workflowTypeStepFacade.dispatchGetWorkFlowTypeSteps(
            this.selectedWorkflowType?.id!,
            this.data.paginator?.pageIndex + 1 || 1,
            this.data.paginator?.pageSize || 10,
          );
          this.dialog.closeAll();
        },
      });
    }
  }
  

  onAddFieldClicked() {
    let documentTemplate = this.documentTemplates.find(
      (documentTemplate) =>
        documentTemplate.id ===
        this.workflowTypeStepForm.value.documentTemplateId,
    );
    this.workflowTypeStepFacade.dispatchSetSelectedWorkflowTypeStepDocumentTemplate(
      documentTemplate!,
    );
    this.dialog.open(
      WorkflowTypeStepAddFieldsComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }
}
