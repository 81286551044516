<div style="padding: 2%">
  <p i18n="@@researches.criteria-form.criteria">Criteria</p>
  <form class="criteria-form" [formGroup]="criteriaForm">
    <mat-form-field appearance="outline" class="form-field">
      <mat-label i18n="@@researches.criteria-form.criteria-name"
      [attr.data-test-id]="'researches.create-criteria.criteria-form.criteria-name-input'"
        >Criterion Name</mat-label
      >
      <input
        id="name"
        [placeholder]="placeholderToggleLabel.enterCriteronName"
        formControlName="name"
        matInput
        class="form-input"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            criteriaForm.get('name')?.value ?? ''
          )
        "
        [attr.data-test-id]="'researches.add-criteria.criteria-form.criterion-name-input'"
      />
      <mat-error
        *ngIf="
          criteriaForm.controls['name'].errors?.['required'] ||
          validName() !== null
        "
        i18n="
          @@researches.criteria-form.please-provide-unused-and-non-empty-name"
      >
        Please provide unused and non-empty name
      </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field appearance="outline" class="form-field">
      <mat-label i18n="@@researches.criteria-form.weight" [attr.data-test-id]="'researches.create-criteria.criteria-form.criteria-weight-input'">Weight</mat-label>
      <input
        id="weight"
        class="form-input"
        type="number"
        [placeholder]="placeholderToggleLabel.enterWeight"
        formControlName="weight"
        matInput
        min="0"
        max="100"
        [attr.data-test-id]="'researches.add-criteria.criteria-form.weight-input'"
      />
      <mat-error
        *ngIf="criteriaForm.controls['weight'].errors?.['required']"
        i18n="
          @@researches.criteria-form.please-provide-valid-weight-for-criterion"
      >
        Please provide a valid weight for Criterion
      </mat-error>
      <mat-error
        *ngIf="criteriaForm.controls['weight'].hasError('zeroWeight')"
        i18n="
          @@researches.criteria-form.please-provide-a-non-zero-weight-value"
      >
        Criteria with zero weight is not accepted
      </mat-error>
    </mat-form-field>
  </form>
  <button
    mat-stroked-button
    class="add-btn"
    color="primary"
    (click)="saveCriterion()"
    *ngIf="this.isUpdate"
    [disabled]="
      criteriaForm.controls['name'].hasError('required') ||
      criteriaForm.controls['weight'].hasError('required')
    "
    i18n="@@researches.criteria-form.save"
    [attr.data-test-id]="'researches.create-criteria.criteria-form.criteria-save-button'"
  >
    save
  </button>
  <button
    mat-stroked-button
    class="add-btn"
    color="primary"
    [disabled]="!criteriaForm.valid || getCriteriaSum() === 100"
    (click)="addCriterion()"
    *ngIf="!isUpdate"
    [attr.data-test-id]="'researches.add-criteria.criteria-form.add-criterion-button'"
  >
    + <span i18n="@@researches.criteria-form.add-criteria" [attr.data-test-id]="'researches.create-criteria.criteria-form.criteria-add-button'">Add Criterion</span>
  </button>
  <mat-card *ngIf="addedCriteria && addedCriteria.length > 0">
    <mat-card-content class="card-content">
      <mat-list>
        <div *ngFor="let item of addedCriteria">
          <div class="list-item">
            <div class="content">
              <p>{{ item.name }}</p>
              <p>
                <span i18n="@@researches.criteria-form.weight">Weight</span>:
                {{ item.weight }}
              </p>
            </div>
            <div class="actions">
              <button mat-icon-button (click)="editCriterion(item)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="removeCriterion(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
