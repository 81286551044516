import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  WorkflowComment,
  WorkflowCommentReply,
} from '../../models/workflow-comment.model';
import { WorkflowCommentFacade } from '../../facade/workflow-comment.facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { RxState } from '@rx-angular/state';

interface WorkflowCommentReplyCardComponentState {
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initWorkflowCommentReplyCardComponentState: WorkflowCommentReplyCardComponentState =
  {
    currentLoggedInUser: undefined
  };

@Component({
  selector: 'app-workflow-comment-reply-card',
  templateUrl: './workflow-comment-reply-card.component.html',
  styleUrls: ['./workflow-comment-reply-card.component.scss'],
})
export class WorkflowCommentReplyCardComponent implements OnInit{
  @Input() workflowComment: WorkflowComment | undefined;
  @Input() workflowCommentReply: WorkflowComment | undefined;
  @Output() editCommentReplyClicked: EventEmitter<void> = new EventEmitter<void>();

  currentLoggedInUser: CurrentLoggedInUser | undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  constructor(
    private workflowCommentFacade: WorkflowCommentFacade,
    private dialog: MatDialog,
    private userFacade: UserFacade,  
    private state: RxState<WorkflowCommentReplyCardComponentState>,
  ) {
    this.state.connect('currentLoggedInUser', this.userFacade.currentLoggedInUser$);
  }
  ngOnInit(): void {
    this.currentLoggedInUser$.subscribe((currUser)=>{
      this.currentLoggedInUser = currUser;
    })
  }

  editReply(workflowComment: WorkflowComment, reply: WorkflowComment) {
    this.workflowCommentFacade.dispatchSetSelectedWorkflowComment(workflowComment);
    this.workflowCommentFacade.dispatchSetSelectedReplyComment(reply);
    this.workflowCommentFacade.dispatchSetWorkflowReplyUpdatingMode(true);
    this.workflowCommentFacade.dispatchSetReplyWorkflowComment(false);
    this.editCommentReplyClicked.emit();
  }

  deleteComment(event: MouseEvent, workflowComment: WorkflowComment, workflowCommentReply: WorkflowComment) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-comment-card.deleted-workflow-comment-part-1:Are you sure you want to delete ` ,
        boldText:  ` "${workflowCommentReply?.comment}" `  ,
        regularTextTwo: $localize`:@@documents.workflow-comment-card.deleted-workflow-comment-part-2:workflow comment?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowCommentFacade.dispatchDeleteWorkflowCommentReply(workflowComment?.id as string, workflowCommentReply?.id as string);
      }
    });
  }

  isCommenter(){
    return this.currentLoggedInUser?.id == this.workflowCommentReply?.commentedUserId;
  }
}
