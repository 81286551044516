import { NgModule } from '@angular/core';

import { FieldValidationRulesRoutingModule } from './field-validation-rules-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FieldValidationRuleState } from './store/field-validation-rules.state';
import { NgxsModule } from '@ngxs/store';
import { FieldValidationRuleComponent } from './components/field-validation-rule/field-validation-rule.component';
import { AddFieldValidationRulesComponent } from './components/add-field-validation-rules/add-field-validation-rules.component';
import { FieldValidationListComponent } from './components/field-validation-list/field-validation-list.component';

@NgModule({
  declarations: [
    FieldValidationRuleComponent,
    AddFieldValidationRulesComponent,
    FieldValidationListComponent,
  ],
  imports: [
    SharedModule,
    FieldValidationRulesRoutingModule,
    NgxsModule.forFeature([FieldValidationRuleState]),
  ],
  exports: [AddFieldValidationRulesComponent, FieldValidationListComponent],
})
export class FieldValidationRulesModule {}
