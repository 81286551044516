<div class="comments-container" [class.show]="showComments">
  <div class="comment-header">
    <h2 i18n="@@researches.publication-comment.comments">Comments</h2>
    <button mat-icon-button (click)="closeComment()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="comments-list">
    <div *ngFor="let comment of publicationComments" class="comment-item">
      <div
        class="comment-bubble"
        [class.editing]="editingCommentId === comment.id"
      >
        <div class="comment-header">
          <span class="commentor-name">{{ comment.fullName }}</span>
          <span class="comment-time">{{
            comment.created | date: "short"
          }}</span>

        </div>
        <div class="comment-content" *ngIf="editingCommentId !== comment.id">
          {{ comment.content }}
        </div>
        <div class="action-buttons">
          <button mat-icon-button (click)="openEditForm(comment)" *ngIf="currentLoggedInUser?.id === comment?.createdBy"
          [attr.data-test-id]="'researches.edit-comment.publication-comment.edit-icon'">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteComment(comment.id)" *ngIf="currentLoggedInUser?.id === comment?.createdBy"
          [attr.data-test-id]="'researches.delete-comment.publication-comment.delete-icon'">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        <button mat-icon-button (click)="openReplyForm(comment.id)" *appHasPermission="hasReplyPublicationCommentPermission()"
        [attr.data-test-id]="'researches.reply-to-comment.publication-comment.reply-icon'">
          <mat-icon color="primary">reply</mat-icon>
        </button>
      </div>
        <input
          *ngIf="editingCommentId === comment.id"
          type="text"
          [(ngModel)]="editingContent"
          class="editing-textarea"
        />
        <div *ngIf="editingCommentId === comment.id" class="editing-actions">
          <button mat-flat-button color="warn" (click)="closeEditForm()" class="button-cancel" i18n="@@researches.publication-comment.cancel">
            Cancel
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="submitEdit(comment.id)"
            color="primary"
            class="button-edit"
            i18n="@@researches.publication-comment.update"
            [disabled]="!editingContent?.trim()"
            [attr.data-test-id]="'researches.update-comment.publication-comment.update-comment-button'"
          >
            Update
          </button>
        </div>
      </div>
      <div *ngIf="currentReplyId === comment.id" class="reply-form-container">
        <form
          (submit)="
            submitReply(replyText.value, comment.id); $event.preventDefault()
          "
          class="reply-form"
          class="reply-form"
        >
          <textarea
            #replyText
            matInput
            [placeholder]="placeholderToggleLabel.writeReply"
            [attr.data-test-id]="'researches.reply-comment.publication-comment.reply-comment-textarea'"
          ></textarea>
          <div class="reply-form-actions">
            <button
              mat-flat-button
              color="warn"
              class="cancel-button"
              (click)="closeReplyForm()"
              i18n="@@researches.publication-comment.cancel"
            >
              Cancel
            </button>
            <button
              mat-flat-button
              color="primary"
              [disabled]="!replyText.value"
              color="primary"
              type="submit"
              i18n="@@researches.publication-comment.reply"
              [attr.data-test-id]="'researches.comment-reply.publication-comment.reply-button'"
            >
              Reply
            </button>
          </div>
        </form>
      </div>
      <div *ngFor="let reply of comment.replies" class="reply-item">
        <div class="reply-bubble" [class.editing]="editingReplyId === reply.id">
          <div class="reply-header">
            <span class="replier-name">{{ reply.fullName }}</span>
            <span class="reply-time">{{ reply.created | date: "short" }}</span>
          </div>
          <div class="reply-content" *ngIf="editingReplyId !== reply.id">
            {{ reply.content }}
          </div>
          <div class="action-buttons">
              <button
              mat-icon-button
              color="primary"
              (click)="openEditReplyForm(reply, comment.id)"
              *ngIf="currentLoggedInUser?.id === reply?.createdBy"
            >
              <mat-icon>edit</mat-icon>
            </button>
              <button
              mat-icon-button
              color="warn"
              (click)="deleteReplyComment(comment.id, reply.id)"
              *ngIf="currentLoggedInUser?.id === reply?.createdBy"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <textarea
            *ngIf="editingReplyId === reply.id"
            [(ngModel)]="editingReplyContent"
            class="editing-textarea"
          ></textarea>
          <div *ngIf="editingReplyId === reply.id" class="editing-actions">
            <button mat-flat-button color="warn" (click)="closeEditReplyForm()" class="button-cancel" i18n="@@researches.publication-comment.cancel">
              Cancel
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="submitEditReply(reply.id, comment.id)"
              class="button-edit"
              i18n="@@researches.publication-comment.update"
              *appHasPermission="hasUpdatePublicationCommentReplyPermission()"
              [disabled]="!editingReplyContent?.trim()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="comment-form-container">
    <form
      [formGroup]="commentForm"
      (ngSubmit)="submitComment()"
      class="comment-form"
      *appHasPermission="hasCreatePublicationCommentPermission()"
    >
      <textarea
        matInput
        [placeholder]="placeholderToggleLabel.writeComment"
        formControlName="comment"
        class="comment-input"
      ></textarea>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="send-button"
        i18n="@@researches.publication-comment.send"
        *appHasPermission="hasCreatePublicationCommentPermission()"
        [disabled]="!commentForm.get('comment')?.valid"
        [attr.data-test-id]="'researches.send-comment.publication-comment.send-button'"
      >
        Send
      </button>
    </form>
  </div>
</div>
