import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NonNullableFormBuilder,
  Validators
} from '@angular/forms';
import { RxState } from '@rx-angular/state';
import {
  DataType,
  DataTypeForDisplay,
} from 'src/app/documents/shared/models/data-types.model';
import { FormFieldsFacade } from '../../facade/form-fields.facades';
import { FormFields } from '../../models/form-fields.model';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { DocumentFormFacade } from 'src/app/documents/document-templates/facade/document-forms.facades';
import { FieldValidationRuleFacade } from 'src/app/documents/field-validation-rules/facades/field-validation-rules.facade';
import { FieldValidationRule } from 'src/app/documents/field-validation-rules/models/field-validation-rule.model';
import { take } from 'rxjs';
import { AddFieldValidationRulesComponent } from 'src/app/documents/field-validation-rules/components/add-field-validation-rules/add-field-validation-rules.component';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';

interface FormFieldFormComponentState {
  selectedDocumentForm?: DocumentForm;
  isCreatingForm?: boolean;
  isUpdatingFormField?: boolean;
  selectedFormField?: FormFields;
  isUpdatingDocumentForm?: boolean;
  dataTypes?: DataTypeForDisplay[];
  currentFieldValidationRules: FieldValidationRule[];
  isAddFieldStepSelected: boolean;
}

const initialFormFieldFormComponentState: FormFieldFormComponentState = {
  currentFieldValidationRules: [],
  isAddFieldStepSelected: false,
};

@Component({
  selector: 'app-form-field-step-form',
  templateUrl: './form-field-step-form.component.html',
  styleUrls: ['./form-field-step-form.component.scss'],
})
export class FormFieldStepFormComponent implements OnInit {
  selectedDocumentForm$ = this.state.select('selectedDocumentForm');
  selectedDocumentForm?: DocumentForm;
  focusedInput: string = '';
  dataTypes?: DataTypeForDisplay[];
  dataTypes$ = this.state.select('dataTypes');

  addCreateFieldToggle = {
    addFieldButton: $localize`:@@documents.form-field-step-form.add-field:Add Field`,
    updateFieldButton: $localize`:@@documents.form-field-step-form.update-field:Update Field`,
  };

  placeholderFieldLabel = {
    templateFieldName: $localize`:@@documents.form-field-step-form.form-field-name: Form Field Name`,
    templateFieldDescription: $localize`:@@documents.form-field-step-form.this-is-description-for-the-form-feild: This is description for the form field`,
    templateFieldPlaceholder: $localize`:@@documents.form-field-step-form.this-is-placeholder-for-the-form-feild: This is placeholder for the Form field`,
  };

  selectedFormField?: FormFields;
  selectedFormField$ = this.state.select('selectedFormField');

  templateFieldForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    placeholder: [''],
    dataType: [undefined as DataType | undefined, Validators.required],
  });

  optionsForm = this.fb.group({
    enabled: [],
    items: this.fb.array([this.fb.control('', [Validators.required])])
  });

  @ViewChild(AddFieldValidationRulesComponent)
  addFieldValidationRulesComponent!: AddFieldValidationRulesComponent;

  currentFieldValidationRules: FieldValidationRule[] = [];

  isUpdatingFormField?: boolean;
  isUpdatingFormField$ = this.state.select('isUpdatingFormField');

  isAddFieldStepSelected?: boolean;
  isAddFieldStepSelected$ = this.state.select('isAddFieldStepSelected');
  constructor(
    private fb: NonNullableFormBuilder,
    public state: RxState<FormFieldFormComponentState>,
    private templateFieldFacade: FormFieldsFacade,
    private documentFormFacade: DocumentFormFacade,
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private sanitizerService: SanitizerService,
  ) {
    this.state.set(initialFormFieldFormComponentState);
    this.state.connect(
      'selectedDocumentForm',
      this.documentFormFacade.selectedDocumentForm$,
    );

    this.state.connect(
      'selectedFormField',
      this.templateFieldFacade.selectedFormField$,
    );

    this.state.connect('dataTypes', this.templateFieldFacade.dataTypes$);

    this.state.connect(
      'currentFieldValidationRules',
      this.fieldValidationRuleFacade.currentFieldValidationRules$,
    );

    this.state.connect(
      'isUpdatingFormField',
      this.templateFieldFacade.isUpdatingFormField$,
    );

    this.state.connect(
      'isAddFieldStepSelected',
      this.documentFormFacade.isAddFieldStepSelected$,
    );
  }
  ngAfterViewInit() {
    this.isAddFieldStepSelected$.subscribe((isAddFieldStepSelected) => {
      if (isAddFieldStepSelected) {
        this.templateFieldForm?.get('name')?.setErrors(null);
        this.templateFieldForm?.get('dataType')?.setErrors(null);
      }
    });
  }

  ngOnInit(): void {
    this.selectedDocumentForm$.subscribe(
      (selectedForm) => (this.selectedDocumentForm = selectedForm),
    );

    this.templateFieldFacade.dispatchGetDataTypes();
    this.dataTypes$.subscribe((dataTypes) => (this.dataTypes = dataTypes));
    this.state.select('currentFieldValidationRules').subscribe((val) => {
      this.currentFieldValidationRules = val;
    });

    this.isUpdatingFormField$.subscribe((isUpdatingFormField) => {
      this.isUpdatingFormField = isUpdatingFormField;
    });

    this.selectedFormField$.subscribe((selectedFormField) => {
      this.selectedFormField = selectedFormField;
      if (
        this.selectedFormField == null ||
        this.selectedFormField == undefined
      ) {
        this.resetForm();
      } else {
        this.templateFieldForm.setValue({
          name: selectedFormField?.name || '',
          description: selectedFormField?.description || '',
          placeholder: selectedFormField?.placeholder || '',
          dataType: selectedFormField?.dataType as DataType | undefined,
        });
        this.templateFieldForm.markAsPristine();
        this.templateFieldForm.markAsUntouched();
      }
    });
  }

  nextClicked() {
    this.focusedInput = '';
  }

  handleAnotherSpaceEvent(event: KeyboardEvent) {
    console.log(event);
    const val = this.templateFieldForm.get(this.focusedInput)?.value;
    this.sanitizerService.spaceSanitizer(event, val);
  }

  submit(event: Event) {
    const  {enabled, items} = this.optionsForm.value
    const fieldDto = {
      name: this.templateFieldForm.getRawValue().name,
      id: this.selectedFormField?.id || undefined,
      description: this.templateFieldForm.getRawValue().description,
      placeholder: this.templateFieldForm.getRawValue().placeholder,
      dataType: this.templateFieldForm.getRawValue().dataType as DataType,
      groupName: "",
      documentTemplateId: this.selectedDocumentForm?.id!,
      optionValues: enabled ? items : null,
      fieldValidationRules: this.currentFieldValidationRules.map((val) => {
        return {
          ValidationRuleValue: val.validationRuleValues,
          validationRuleId: val.validationRule.id,
          validationRule: val.validationRule,
          validationRuleValues: val.validationRuleValues,
        };
      }),
      order: this.selectedFormField?.order || 0,
    };

    let dispatch;
    if (this.isUpdatingFormField) {
      dispatch = this.templateFieldFacade.dispatchUpdateFormField(fieldDto);
    } else {
      dispatch = this.templateFieldFacade.dispatchAddFormField(fieldDto);
    }

    this.templateFieldFacade.dispatchSetSelectedFormField(undefined);

    dispatch.pipe(take(1)).subscribe(() => {
      this.resetForm();
      this.templateFieldForm.setErrors({ invalid: true });
      this.templateFieldFacade.dispatchSetFormFieldUpdatingMode(false);
    });

    event.preventDefault();
  }

  resetForm() {
    this.templateFieldForm.reset();
    this.addFieldValidationRulesComponent.resetForm();
    this.optionsForm.reset();
  }

  addOption(){
    this.optionsForm.controls.items.push(this.fb.control('', [Validators.required]))
  }

  removeOption(index: number) {
    this.optionsForm.controls.items.removeAt(index)
  }
}
