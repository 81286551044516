import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  ResearchDashboardState,
  ResearchDashboardStateModel,
} from './research-dashboard.state';

export class ResearchDashboardSelector {
  static slices = createPropertySelectors<ResearchDashboardStateModel>(
    ResearchDashboardState,
  );

  @Selector([ResearchDashboardState])
  static researchVsTime(stateModel: ResearchDashboardStateModel) {
    return stateModel.researchVsTime;
  }
  @Selector([ResearchDashboardState])
  static evaluatedProcessInstanceStatusCount(
    stateModel: ResearchDashboardStateModel,
  ) {
    return stateModel.evaluatedProcessInstanceStatusCount;
  }

  @Selector([ResearchDashboardState])
  static evaluatedProcessInstanceCountByDepartment(
    stateModel: ResearchDashboardStateModel,
  ) {
    return stateModel.evaluatedProcessInstanceCountByDepartment;
  }

  @Selector([ResearchDashboardState])
  static publicationData(state: ResearchDashboardStateModel) {
    return state.publicationData;
  }
  @Selector([ResearchDashboardState])
  static researches(state: ResearchDashboardStateModel) {
    return state.researches;
  }

  @Selector([ResearchDashboardState])
  static communications(state: ResearchDashboardStateModel) {
    return state.communications;
  }

  @Selector([ResearchDashboardState])
  static researchCount(stateModel: ResearchDashboardStateModel) {
    return stateModel.researchesCount;
  }

  @Selector([ResearchDashboardState])
  static budgetPerCommunication(state: ResearchDashboardStateModel) {
    return state.budgetPerCommunication;
  }
  @Selector([ResearchDashboardState])
  static totalCost(stateModel: ResearchDashboardStateModel) {
    return stateModel.totalCost;
  }

  @Selector([ResearchDashboardState])
  static totalResearchCost(stateModel: ResearchDashboardStateModel) {
    return stateModel.totalResearchCost;
  }

  @Selector([ResearchDashboardState])
  static totalCommunicationCost(stateModel: ResearchDashboardStateModel) {
    return stateModel.totalCommunicationCost;
  }

  @Selector([ResearchDashboardState])
  static communicationsWithStatus(stateModel: ResearchDashboardStateModel) {
    return stateModel.communicationsWithStatus;
  }

  @Selector([ResearchDashboardState])
  static totalCommunicationsCount(stateModel: ResearchDashboardStateModel) {
    return stateModel.totalCommunicationsCount;
  }
  @Selector([ResearchDashboardState])
  static researchEvaluations(stateModel: ResearchDashboardStateModel) {
    return stateModel.researchEvaluations;
  }
  @Selector([ResearchDashboardState])
  static researchLength(stateModel: ResearchDashboardStateModel) {
    return stateModel.length;
  }

  @Selector([ResearchDashboardState])
  static taskAccomplishmentVsTimeCount(state: ResearchDashboardStateModel) {
    return state.taskAccomplishmentVsTimeCount;
  }

  @Selector([ResearchDashboardState])
  static publishedResearchesVsCategory(state: ResearchDashboardStateModel) {
    return state.publishedResearchesVsCategory;
  }

  @Selector([ResearchDashboardState])
  static totalPublicationcategoriesCount(
    stateModel: ResearchDashboardStateModel,
  ) {
    return stateModel.totalPublicationCategoriesCount;
  }

  @Selector([ResearchDashboardState])
  static departmentVspublication(
    stateModel: ResearchDashboardStateModel,
  ) {
    return stateModel.departmentVspublication;
  }
}
