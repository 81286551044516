import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-task-file-upload-option-dialog',
  templateUrl: './task-file-upload-option-dialog.component.html',
  styleUrls: ['./task-file-upload-option-dialog.component.scss'],
})
export class TaskFileUploadOptionDialogComponent {
  message: string | undefined;
  replaceControl = new FormControl('replace');
  constructor(
    private dialogRef: MatDialogRef<TaskFileUploadOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
  ) {
    this.message = data.message;
  }

  onConfirm() {
    this.dialogRef.close(this.replaceControl.value);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
