<mat-dialog-content>
  <form class="office-form" [formGroup]="officeForm">
    <mat-form-field appearance="outline">
      <mat-label
        i18n="@@offices.office-form.office-name"
        i18n="@@offices.office-form.office-name"
        >Office Name</mat-label
      >
      <input
        id="name"
        formControlName="name"
        matInput
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            officeForm.get('name')?.value ?? ''
          )
        "
        [attr.data-test-id]="'offices.create-and-update-office.office-form.office-name-input-field'"
      />
      <mat-error
        *ngIf="officeForm.get('name')?.hasError('required')"
        i18n="@@offices.office-form.office-name-is-required"
      >
        Office name is required
      </mat-error>

      <mat-error
        *ngIf="officeForm.get('name')?.hasError('maxLengthExceeded')"
        i18n="@@offices.office-form.office-name-too-long"
      >
        Office name is too long
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@offices.office-form.short-name">Short name</mat-label>
      <input
        id="shortName"
        formControlName="shortName"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            officeForm.get('shortName')?.value ?? ''
          )
        "
        matInput
        [attr.data-test-id]="'offices.create-and-update-office.office-form.short-name-input-field'"
      />
      <mat-error
        *ngIf="officeForm.get('shortName')?.hasError('required')"
        i18n="@@offices.office-form.office-short-name-is-required"
      >
        Office short name is required
      </mat-error>

      <mat-error
        *ngIf="officeForm.get('shortName')?.hasError('maxLengthExceeded')"
        i18n="@@offices.office-form.office-short-name-too-long"
      >
        Office short name is too long
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label
        i18n="@@offices.office-form.country-code"
        [attr.data-test-id]="'offices.create-and-update-office.office-form.country-code-selection-field'"
        >Country Code</mat-label
      >
      <mat-select
        id="countryCode"
        [(value)]="selectedCountry"
        (selectionChange)="onCountryCodeSelect($event)"
      >
        <mat-select-trigger>
          {{ selectedCountry?.name }}({{ selectedCountry?.dialCode }})
        </mat-select-trigger>
        <mat-option *ngFor="let phone of phoneCode" [value]="phone">
          {{ phone.name }}({{ phone.dialCode }})
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="officeForm.get('countryCode')?.hasError('required')"
        i18n="@@offices.office-form.select-atleast-one-country"
      >
        select at least one country
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" #phoneNumber>
      <mat-label
        *ngIf="isLabelVisible"
        i18n="@@offices.office-form.phone-number"
        >Phone Number</mat-label
      >

      <input
        (focus)="onFocus()"
        (blur)="onBlur()"
        id="phoneNumber"
        (keydown)="sanitizerService.numberSanitizer($event)"
        formControlName="phoneNumber"
        matInput
        (onchange)="onPhoneNumberChange()"
        [attr.data-test-id]="'offices.create-and-update-office.office-form.phone-number-input-field'"
      />
      <mat-error
        class="phone-number-error"
        *ngIf="officeForm.get('phoneNumber')?.hasError('required')"
        i18n="@@offices.office-form.phone-number-is-required"
      >
        phone number is required
      </mat-error>

      <mat-error
        class="phone-number-error"
        *ngIf="
          officeForm.get('phoneNumber')?.errors &&
          officeForm.get('phoneNumber')?.value
        "
        i18n="@@offices.office-form.phone-number-format-is-invalid"
      >
        phone number format is invalid
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="description">
      <mat-label i18n="@@offices.office-form.full-description"
        >Full Description</mat-label
      >
      <input
        id="description"
        formControlName="description"
        (keydown)="
          sanitizerService.spaceSanitizer(
            $event,
            officeForm.get('description')?.value ?? ''
          )
        "
        matInput
        [attr.data-test-id]="'offices.create-and-update-office.office-form.full-description-input-field'"
      />
      <mat-error
        *ngIf="officeForm.get('description')?.hasError('required')"
        i18n="@@offices.office-form.office-description-is-required"
      >
        Office description is required
      </mat-error>
    </mat-form-field>
    <div class="chip-alignment" *ngIf="!update">
      <button
        mat-raised-button
        (click)="openSingleOffice()"
        i18n="@@offices.office-form.parent-office"
        [attr.data-test-id]="'offices.create-office.office-form.parent-office-button'"
      >
        Parent office
      </button>
      <mat-chip-row
        class="chip"
        *ngIf="selectedFlatOfficeNode"
        (removed)="removeSelectedFlatOfficeNode(selectedFlatOfficeNode)"
        [editable]="false"
        [matTooltip]="selectedFlatOfficeNode.name"
      >
        {{
          selectedFlatOfficeNode.name.length > 40
            ? selectedFlatOfficeNode.name.slice(0, 40) + " ..."
            : selectedFlatOfficeNode.name
        }}
        <button
          matChipRemove
          [attr.aria-label]="'remove ' + selectedFlatOfficeNode.name"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>
    <div class="chip-alignment">
      <button
        mat-raised-button
        *appHasPermission="hasGetReportsToNodesPermission()"
        (click)="openMultipleOffice()"
        i18n="@@offices.office-form.reports-to"
        [attr.data-test-id]="'offices.create-and-update-office.office-form.report-to-button'"
      >
        Reports to
      </button>
      <mat-chip-row
        class="chip"
        *ngFor="let item of selectedFlatOfficeNodes"
        (removed)="removeSelectedFlatOfficeNodeFromNodes(item)"
        [editable]="false"
        [matTooltip]="item.name"
      >
        {{
          item.name.length > 40 ? item.name.slice(0, 40) + " ..." : item.name
        }}
        <button matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action-buttons">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="
      !officeForm.valid ||
      this.selectedFlatOfficeNode === null ||
      this.selectedFlatOfficeNode === undefined
    "
    mat-dialog-close="save"
    i18n="@@offices.office-form.save"
    [attr.data-test-id]="'offices.create-and-update-office.office-form.save-button'"
  >
    Save
  </button>
  <button
    class="submit"
    mat-flat-button
    color="accent"
    mat-dialog-close
    i18n="@@offices.office-form.cancel"
  >
    Cancel
  </button>
</mat-dialog-actions>
