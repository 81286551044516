<button
  mat-flat-button
  [disabled]="false"
  color="primary"
  (click)="downloadTemplate()"
  class="custom-btn-space nowrap-text"
  style="margin-bottom: 0"
  i18n="@@offices.download-excel-template.download-office-excel-template"
  [attr.data-test-id]="'offices.download-excel.download-excel-template.download-button'"
>
  Download Office Excel Template
</button>
