import { Component, Input } from '@angular/core';
import { WorkflowCommentReply } from '../../../models/workflow-comment.model';

@Component({
  selector: 'app-show-workflow-comment-reply-card',
  templateUrl: './show-workflow-comment-reply-card.component.html',
  styleUrls: ['./show-workflow-comment-reply-card.component.scss'],
})
export class ShowWorkflowCommentReplyCardComponent {
  @Input() workflowCommentReply: WorkflowCommentReply | undefined;
}
