import { Injectable } from '@angular/core';
import {
  TorStatusChange,
  Tor,
  TorComment,
  TorDetail,
  TorStatus,
  TorCommentReply,
} from '../models/tor.model';
import { TorSelector } from '../store/tor.selector';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  DeleteTor,
  DetailTor,
  GetPaginatedTor,
  InitiateTor,
  SelectedTor,
  SetPageIndexAndSize,
  SetSelectedTorDescription,
  UpdateTor,
  AddCostBreakdownItem,
  GetCostBreakdownItems,
  DownloadTorPdf,
  SetSelectedCostBreakdownItem,
  UpdateCostBreakdownItem,
  SetUpdateCostBreakdownItemStatus,
  DeleteCostBreakdownItem,
  ChangeTorStatus,
  GetTorComments,
  SetSelectedTorComment,
  AddTorComment,
  SetTorCommentUpdatingMode,
  EditTorComment,
  DeleteTorComment,
  SetTorCommentReplyingMode,
  ReplyToTorComment,
  GetCurrencyList,
  UpdateTorCommentReply,
  SetSelectedReplyComment,
  SetTorReplyUpdatingMode,
  DeleteTorCommentReply,
} from '../store/tor.action';
import {
  CostBreakdownItem,
  CreateCostBreakdownItemRequest,
} from '../models/cost-breakdown';
import { ResetSelectedReplyComment } from '../../store/task.actions';

@Injectable({
  providedIn: 'root',
})
export class TorFacade {
  @Select(TorSelector.tors)
  tors$!: Observable<Tor[]>;

  @Select(TorSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(TorSelector.getPaginatedTor)
  paginatedTor$!: Observable<PaginatedList<Tor>>;

  @Select(TorSelector.initiatedTor)
  initiatedTor$!: Observable<Tor>;

  @Select(TorSelector.selectedTorDetail)
  selectedTorDetail$!: Observable<TorDetail>;

  @Select(TorSelector.costBreakdownItems)
  costBreakdownItems$!: Observable<PaginatedList<CostBreakdownItem>>;

  @Select(TorSelector.selectedCostBreakdownItem)
  selectedCostBreakdownItem$!: Observable<CostBreakdownItem>;

  @Select(TorSelector.updateCostBreakdownItem)
  updateCostBreakdownItemStatus$!: Observable<boolean>;

  @Select(TorSelector.torComments)
  torComments$!: Observable<PaginatedList<TorComment>>;

  @Select(TorSelector.selectedTorComment)
  selectedTorComment$!: Observable<TorComment>;

  @Select(TorSelector.isUpdatingComment)
  isUpdatingComment$!: Observable<boolean>;

  @Select(TorSelector.isReplyingToComment)
  isReplyingToComment$!: Observable<boolean>;

  @Select(TorSelector.currency)
  currency$!: Observable<any[]>;
  @Select(TorSelector.selectedTorprocessInstanceOwnerId)
  selectedTorprocessInstanceOwnerId$!: Observable<string>;
  @Select(TorSelector.isReplyTorComment)
  isReplyTorComment$!: Observable<boolean>;

  @Select(TorSelector.selectedReply)
  selectedReply$!: Observable<TorComment>

  @Select(TorSelector.isUpdatingReply)
  isUpdatingReply$!: Observable<boolean>;
  constructor(private store: Store) {}
  dispatchSetPageIndexAndSize(pageIndex: number, pageSize: number) {
    this.store.dispatch(new SetPageIndexAndSize(pageIndex, pageSize));
  }

  dispatchGetTor(
    pageIndex: number,
    pageSize: number,
    grouped?: boolean | null,
  ) {
    return this.store.dispatch(
      new GetPaginatedTor(pageIndex, pageSize, grouped),
    );
  }

  dispatchInitiateTor(stageInstanceId: string , currency:string) {
    return this.store.dispatch(new InitiateTor(stageInstanceId , currency));
  }

  dispatchDeleteTor(tor: string) {
    this.store.dispatch(new DeleteTor(tor));
  }

  dispatchGetTorDetail(torId: string) {
    this.store.dispatch(new DetailTor(torId));
  }

  dispatchSetSelectedTor(tor: Tor) {
    this.store.dispatch(new SelectedTor(tor));
  }

  dispatchUpdateTor(id: string, description: string) {
    return this.store.dispatch(new UpdateTor(id, description));
  }

  dispatchSetSelectedTorDescription(
    torDescription: TorDetail,
    typeOfAction: string,
  ) {
    return this.store.dispatch(
      new SetSelectedTorDescription(torDescription, typeOfAction),
    );
  }

  dispatchAddCostBreakdownItem(
    torId: string,
    costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {
    return this.store.dispatch(
      new AddCostBreakdownItem(torId, costBreakdownItem),
    );
  }

  dispatchGetCostBreakdownItems(
    torId: string,
    pageNumber: number,
    pageSize: number,
  ) {
    return this.store.dispatch(
      new GetCostBreakdownItems(torId, pageNumber, pageSize),
    );
  }

  dispatchDownloadTorPdf(torId: string) {
    return this.store.dispatch(new DownloadTorPdf(torId));
  }

  dispatchSetSelectedCostBreakdownItem(
    selectedCostBreakdownItem: CostBreakdownItem,
  ) {
    return this.store.dispatch(
      new SetSelectedCostBreakdownItem(selectedCostBreakdownItem),
    );
  }

  dispatchSetUpdateCostBreakdownItemStatus(updateStatus: boolean) {
    return this.store.dispatch(
      new SetUpdateCostBreakdownItemStatus(updateStatus),
    );
  }

  dispatchUpdateCostBreakdownItem(
    torId: string,
    costBreakdownItemId: string,
    costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {
    return this.store.dispatch(
      new UpdateCostBreakdownItem(
        torId,
        costBreakdownItemId,
        costBreakdownItem,
      ),
    );
  }
  dispatchDeleteCostBreakdownItem(torId: string, costBreakdownItemId: string) {
    return this.store.dispatch(
      new DeleteCostBreakdownItem(torId, costBreakdownItemId),
    );
  }

  dispatchChangeStatusToDraft(id: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Todo
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToApproved(id: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Approved
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToNeedsRevision(id: string, remark: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Revision,
      comment: remark
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToDeclinedByFirstApprover(id: string, remark: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Rejected1,
      comment: remark
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToDeclinedBySecondApprover(id: string, remark: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Rejected2,
      comment: remark
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToSubmittedToFirstApprover(id: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Submitted1
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchChangeStatusToSubmittedToSecondApprover(id: string) {
    const updateStatus = {
      id: id,
      status: TorStatus.Submitted2
    }
    this.store.dispatch( new ChangeTorStatus(updateStatus));
  }

  dispatchGetTorComments(
    torId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    return this.store.dispatch(new GetTorComments(torId, pageNumber, pageSize));
  }

  dispatchSetSelectedTorComment(selectedTorComment: TorComment) {
    return this.store.dispatch(new SetSelectedTorComment(selectedTorComment));
  }

  dispatchAddTorComment(torId: string, content: string) {
    return this.store.dispatch(new AddTorComment(torId, content));
  }

  dispatchSetTorCommentUpdatingMode(isUpdatingComment: boolean) {
    return this.store.dispatch(
      new SetTorCommentUpdatingMode(isUpdatingComment),
    );
  }

  dispatchEditTorComment(torCommentId: string, content: string) {
    return this.store.dispatch(new EditTorComment(torCommentId, content));
  }

  dispatchDeleteTorComment(torCommentId: string) {
    return this.store.dispatch(new DeleteTorComment(torCommentId));
  }

  dispatchSetTorCommentReplyingMode(isReplyingToComment: boolean) {
    return this.store.dispatch(
      new SetTorCommentReplyingMode(isReplyingToComment),
    );
  }

  dispatchReplyToTorComment(content: string, commentId: string) {
    return this.store.dispatch(new ReplyToTorComment(content, commentId));
  }

  dispatchGetCurrencyList() {
    return this.store.dispatch(
      new GetCurrencyList(),
    );
  }

  dispatchUpdateTorCommentReply(
    torCommentid: string,
    commentId: string,
    content: string
    ) {
      return this.store.dispatch(new UpdateTorCommentReply(torCommentid, commentId, content));

    }

    dispatchSetSelectedReplyComment(
      comment: TorComment,
    ) {
      this.store.dispatch( new SetSelectedReplyComment(comment));
    }

    dispatchResetSelectedReplyComment(){
      this.store.dispatch( new ResetSelectedReplyComment);
    }

    dispatchSetTorReplyUpdatingMode(isUpdatingReply: boolean) {
      this.store.dispatch(new SetTorReplyUpdatingMode(isUpdatingReply));
    }

    dispatchDeleteTorCommentReply(
      torCommentId: string,
      commentId: string
      ){
        this.store.dispatch( new DeleteTorCommentReply(torCommentId, commentId));
      }
}
