import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class TextValidator {
  static isNotEmpty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return { TextIsNotEmpty: 'Field must not be empty' };

      return null
    };
  }

  static isEmpty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;

      return { TextIsEmpty: 'Field must be empty' };
    };
  }

  static contains(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return value.includes(val) ? null : { TextContains: `Field must contain '${val}.'` };
    };
  }

  static notContains(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return !value.includes(val) ? null : { TextNotContains: `Field must not contain '${val}.'` };
    };
  }

  static startsWith(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return value.startsWith(val) ? null : { TextStartsWith: `Field must start with '${val}.'` };
    };
  }

  static endsWith(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return value.endsWith(val) ? null : { TextEndsWith: `Field must end with '${val}.'` };
    };
  }

  static isExactly(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) return null;
      return value == val ? null : { TextIsExactly: `Field must be '${val}.'` };
    };
  }

  static isURL(val: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      try {
        new URL(value);
        return null;
      } catch (e) {
        return { TextIsURL: `Field must be a valid URL` };
      }
    };
  }
}
