import { Component, OnInit } from '@angular/core';
import {
  CcUserWithOfficeDto,
  Memo,
  MemoAccessDetail,
} from '../../models/memo.model';
import { User } from 'src/app/users/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { MemoCcFormComponent } from '../memo-cc-form/memo-cc-form.component';
import { MemoFacade } from '../../facade/memo.facades';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface MemoCcListComponentState {
  selectedMemo: Memo | null;
}

const initMemoCcListComponentState: MemoCcListComponentState = {
  selectedMemo: null,
};

@Component({
  selector: 'app-memo-cc-list',
  templateUrl: './memo-cc-list.component.html',
  styleUrls: ['./memo-cc-list.component.scss'],
})
export class MemoCcListComponent implements OnInit {
  CCUsersList: CcUserWithOfficeDto[] = [];

  selectedMemo$: Observable<Memo | null> = this.state.select('selectedMemo');
  selectedMemo: Memo | null = null;

  constructor(
    private dialog: MatDialog,
    private memoFacade: MemoFacade,
    private state: RxState<MemoCcListComponentState>,
  ) {
    this.state.set(initMemoCcListComponentState);
    this.state.connect('selectedMemo', this.memoFacade.selectedMemo$);
  }
  ngOnInit(): void {
    this.selectedMemo$.subscribe((memo) => {
      this.selectedMemo = memo;
      if (memo?.ccedUsers) this.CCUsersList = memo?.ccedUsers;
    });
  }

  openCcForm() {
    this.dialog.open(MemoCcFormComponent, {
      minWidth: 350,
    });
  }

  removeUser(event: MouseEvent, ccedUser: CcUserWithOfficeDto) {
    event.preventDefault();
    if (!this.selectedMemo) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.memo-cc-list.remove-user-memo-part-1:Are you sure you want to remove` ,
        boldText: ` "${ccedUser.user.firstName}" ` ,
        regularTextTwo:  $localize`:@@documents.memo-cc-list.remove-user-memo-part-2:from this memo?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedMemo?.id && ccedUser.user.id)
          this.memoFacade.dispatchRemoveCcUserToMemo(
            this.selectedMemo.id,
            ccedUser.user.id,
          );
      }
    });
  }

  hasAddUsersCCedToMemoPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.AddUsersCCedToMemo,
    );
  }

  hasRemoveUsersCCedToMemoPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Memo.Feature,
      PERMISSION_NAMES.Documents.Memo.RemoveUsersCCedToMemo,
    );
  }
}
