import { Component, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { DocumentForm } from 'src/app/documents/document-templates/models/document-forms.model';
import { WorkflowTypeStepsFacade } from '../../facade/workflow-type-steps.facades';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { FormFieldsFacade } from 'src/app/documents/template-fields/facade/form-fields.facades';
import { MatDialogRef } from '@angular/material/dialog';

interface WorkflowTypeStepAddFieldsComponentState {
  selectedWorkflowTypeStepDocumentTemplate: DocumentForm | null;
  templateFields: PaginatedList<FormFields> | undefined;
  selectedWorkflowTypeStepDocumentTemplateFields: FormFields[] | [];
}

const initWorkflowTypeStepAddFieldsComponentState: WorkflowTypeStepAddFieldsComponentState =
  {
    selectedWorkflowTypeStepDocumentTemplate: null,
    templateFields: undefined,
    selectedWorkflowTypeStepDocumentTemplateFields: [],
  };

@Component({
  selector: 'app-workflow-type-step-add-fields',
  templateUrl: './workflow-type-step-add-fields.component.html',
  styleUrls: ['./workflow-type-step-add-fields.component.scss'],
})
export class WorkflowTypeStepAddFieldsComponent implements OnInit {
  selectedWorkflowTypeStepDocumentTemplate: DocumentForm | null = null;
  selectedWorkflowTypeStepDocumentTemplate$ = this.state.select(
    'selectedWorkflowTypeStepDocumentTemplate',
  );

  templateFields: PaginatedList<FormFields> | undefined;
  templateFields$ = this.state.select('templateFields');

  selectedTemplateFields: FormFields[] = [];
  selectedWorkflowTypeStepDocumentTemplateFields$ = this.state.select(
    'selectedWorkflowTypeStepDocumentTemplateFields',
  );

  constructor(
    private state: RxState<WorkflowTypeStepAddFieldsComponentState>,
    private workflowTypeStepsFacade: WorkflowTypeStepsFacade,
    private formFieldsFacade: FormFieldsFacade,
    private dialogRef: MatDialogRef<WorkflowTypeStepAddFieldsComponent>,
  ) {
    this.state.set(initWorkflowTypeStepAddFieldsComponentState);
    this.state.connect(
      'selectedWorkflowTypeStepDocumentTemplate',
      this.workflowTypeStepsFacade.selectedWorkflowTypeStepDocumentTemplate$,
    );
    this.state.connect(
      'templateFields',
      this.formFieldsFacade.templateFields$,
    );
    this.state.connect(
      'selectedWorkflowTypeStepDocumentTemplateFields',
      this.workflowTypeStepsFacade
        .selectedWorkflowTypeStepDocumentTemplateFields$,
    );
  }

  ngOnInit(): void {
    this.selectedWorkflowTypeStepDocumentTemplate$.subscribe(
      (selectedWorkflowTypeStepDocumentTemplate) => {
        this.selectedWorkflowTypeStepDocumentTemplate =
          selectedWorkflowTypeStepDocumentTemplate;
      },
    );

    this.formFieldsFacade.dispatchGetFormFields(
      this.selectedWorkflowTypeStepDocumentTemplate?.id!,
    );

    this.templateFields$.subscribe((templateFields) => {
      this.templateFields = templateFields;
    });

    this.selectedWorkflowTypeStepDocumentTemplateFields$.subscribe(
      (selectedWorkflowTypeStepDocumentTemplateFields) => {
        this.selectedTemplateFields =
          selectedWorkflowTypeStepDocumentTemplateFields;
      },
    );
  }

  save() {
    this.workflowTypeStepsFacade.dispatchSetSelectedWorkflowTypeStepDocumentTemplateFields(
      this.selectedTemplateFields!,
    );
    this.dialogRef.close(this.selectedTemplateFields);
  }

  onFieldChange(templateField: FormFields) {
    // when one field is selected it adds or removes all fields if it is a table column
    if (this.selectedTemplateFields.includes(templateField)) {
      this.selectedTemplateFields = this.selectedTemplateFields.filter(
        (field) => field.id !== templateField.id,
      );
      return;
    }
    this.selectedTemplateFields.push(templateField);
  }

  handleChecked(templateField: FormFields) {
    if (
      this.selectedTemplateFields
        .map((field) => field.id)
        .includes(templateField.id)
    ) {
      return true;
    }

    return false;
  }
}
