import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Subject } from 'rxjs';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import {
  TASK_DOCUMENTS_ROUTE,
  TASK_ROUTE,
} from 'src/app/core/constants/routes';
import { ProcessInstanceFacade } from 'src/app/researches/facades/process-instance.facades';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessInstanceDetail } from 'src/app/researches/models/process-instance.model';
import { TaskDocument } from 'src/app/researches/models/task-document.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';

interface TaskDocumentListComponentState {
  documents: TaskDocument[];
  length: number;
  processInstanceDetail: ProcessInstanceDetail | undefined;
  videoBlob: Blob | null;
  audioBlob: Blob | null;
  currentLoggedInUser: CurrentLoggedInUser | undefined;
}

const initialTaskDocumentListComponentState: TaskDocumentListComponentState = {
  documents: [],
  length: 0,
  audioBlob: null,
  processInstanceDetail: undefined,
  videoBlob: null,
  currentLoggedInUser: undefined,
};

@Component({
  selector: 'app-task-document-list',
  templateUrl: './task-document-list.component.html',
  styleUrls: ['./task-document-list.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: TaskDocumentListComponent}, RxState],
})
export class TaskDocumentListComponent implements MatPaginatorIntl {
  dataSource!: MatTableDataSource<TaskDocument>;
  documents$ = this.state.select('documents');
  selected: TaskDocument | undefined = undefined;

  documents: TaskDocument[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  length$ = this.state.select('length');
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  taskId: string | undefined = undefined;
  videoBlob$ = this.state.select('videoBlob');
  audioBlob$ = this.state.select('audioBlob');

  processInstanceDetail$ = this.taskDocumentListState.select(
    'processInstanceDetail',
  );
  processInstanceDetail: ProcessInstanceDetail | undefined;

  @ViewChild('videoElement', { static: true })
  videoElementRef!: ElementRef<HTMLVideoElement>;

  @ViewChild('audioElement', { static: true })
  audioElementRef!: ElementRef<HTMLAudioElement>;

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;

  firstPageLabel = $localize`:@@researches.task-document-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.task-document-list.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.task-document-list.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.task-document-list.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.task-document-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.task-document-list.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.task-document-list.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.task-document-list.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private taskFacade: TaskFacade,
    private dialog: MatDialog,
    private state: RxState<TaskDocumentListComponentState>,
    private route: ActivatedRoute,
    private router: Router,
    private taskDocumentListState: RxState<TaskDocumentListComponent>,
    private processInstanceFacade: ProcessInstanceFacade,
    private userFacade: UserFacade,
  ) {
    this.state.set(initialTaskDocumentListComponentState);
    this.state.connect('documents', this.taskFacade.documents$);
    this.state.connect('length', this.taskFacade.length$);
    this.taskDocumentListState.connect(
      'processInstanceDetail',
      this.processInstanceFacade.processInstanceDetail$,
    );
    this.state.connect('videoBlob', this.taskFacade.videoBlob$);
    this.state.connect('audioBlob', this.taskFacade.audioBlob$);
    this.state.connect('currentLoggedInUser',this.userFacade.currentLoggedInUser$,);
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.taskId = params.get('taskId') as string;
      if (this.taskId) {
        this.taskFacade.dispatchGetPaginatedTaskDocument(
          this.taskId,
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 10,
        );
      }
    });

    this.documents$.subscribe((documents) => {
      if (documents) {
        this.documents = documents;
        this.dataSource = new MatTableDataSource<TaskDocument>(documents);
      }
    });

    this.length$.subscribe((length) => {
      this.length = length;
    });

    this.processInstanceDetail$.subscribe((processInstance) => {
      this.processInstanceDetail = processInstance;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
  }

  onMenuClick($event: Event, task: TaskDocument) {
    this.selected = task;
  }

  isDeleteDocumentPossible(): boolean {
    if (!this.documents && !this.documents$) return false;
    if (!this.selected) return false;
    return this.selected.creator?.id === this.currentLoggedInUser?.id;
  }

  loadPaginatedTaskDocument(event: PageEvent) {
    if (this.taskId)
      this.taskFacade.dispatchGetPaginatedTaskDocument(
        this.taskId,
        event.pageIndex + 1,
        event.pageSize,
      );
  }

  previewTaskDocument(process: any): void {
    const document = this.documents.find((doc: any) => doc.id === process.id);
    if (document) {
      this.taskFacade.dispatchPreviewTaskDocument(
        document.id,
        document.name,
        document.table,
        'pdf',
        'Preview',
      );
    }
  }

  openConfirmationDialog() {
    if (!this.documents && !this.documents$) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.task-document-list.delete-document-message: Are you sure you want to delete` ,
        boldText: ` "${this.selected?.name}"? `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selected)
          if (this.selected?.table == 'Document') {
            this.taskFacade.dispatchDeleteDocumentTask(this.selected!.id!);
            this.router.navigate([TASK_DOCUMENTS_ROUTE]);
          } else {
            this.taskFacade.dispatchDeleteFileTask(this.selected!.id!);
            this.router.navigate([TASK_ROUTE]);
          }
      }
    });
  }

  previewDocument() {
    if (!this.documents && !this.documents$) return;
    if (!this.selected) return;
    if (this.selected.table == 'Document') {
      this.taskFacade.dispatchPreviewTaskDocumentCompose(
        this.selected.id,
        this.selected.name
      );
    } else {
      let fileId = this.selected.id;
      let fileName = this.selected.name;
      let fileData = fileName.split('.');
      if (
        fileData[1] == 'mp4' ||
        fileData[1] == 'ogg' ||
        fileData[1] == 'webm'
      ) {
        this.taskFacade.dispatchFetchVideo(fileId);
        this.videoBlob$.subscribe((blob) => {
          if (blob) {
            const videoElement: HTMLVideoElement =
              this.videoElementRef.nativeElement;

            videoElement.src = URL.createObjectURL(blob);
            videoElement.controls = true;
            const videoContainer = document.getElementById('video-container');
            if (!videoContainer) return;
            videoContainer.style.display = 'block';

            videoElement.addEventListener('ended', () => {
              this.removeVideoElement();
            });

            videoElement.play();
          }
        });
      } else if (fileData[1] == 'mp3' || fileData[1] == 'wav') {
        this.taskFacade.dispatchFetchAudio(fileId);
        this.audioBlob$.subscribe((blob) => {
          if (blob) {
            const audioElement: HTMLAudioElement =
              this.audioElementRef.nativeElement;
            audioElement.src = URL.createObjectURL(blob);
            audioElement.controls = true;

            const audioContainer = document.getElementById('audio-container');
            if (!audioContainer) return;
            audioContainer.style.display = 'flex';
            audioContainer.style.flexDirection = 'row';

            audioElement.addEventListener('ended', () => {
              this.removeAudioElement();
            });

            audioElement.play();
          }
        });
      } else {
        this.taskFacade.dispatchPreviewTaskDocument(
          this.selected.id,
          this.selected.name,
          this.selected.table,
          'pdf',
          'Preview',
        );
      }
    }
  }

  removeVideoElement() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  removeAudioElement() {
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.pause();
    audioElement.removeEventListener('ended', this.removeAudioElement);
    const audioContainer = audioElement.parentElement;
    if (audioContainer) {
      audioContainer.removeChild(audioElement);
    }
  }

  closePlayer() {
    const videoElement: HTMLVideoElement = this.videoElementRef.nativeElement;
    videoElement.pause();

    const container = document.getElementById('video-container');
    if (!container) return;
    container.style.display = 'none';
  }

  closeAudioPlayer() {
    const audioElement: HTMLAudioElement = this.audioElementRef.nativeElement;
    audioElement.pause();
    const container = document.getElementById('audio-container');
    if (!container) return;
    container.style.display = 'none';
  }
  isTableDocument() {
    if (!this.documents && !this.documents$) return false;
    if (!this.selected) return false;
    return this.selected.table == 'Document';
  }
  downloadDocument(type?: string) {
    if (!this.documents && !this.documents$) return;
    if (!this.selected) return;
    const documentDownloadType = type ? type : 'pdf';
    this.taskFacade.dispatchDownloadTaskDocument(
      this.selected.id,
      this.selected.name,
      this.selected.table,
      documentDownloadType,
      'Download',
    );
  }

  downloadDocumentAsPdf() {
    if (!this.documents && !this.documents$) return;
    if (!this.selected) return;
    this.taskFacade.dispatchDownloadTaskDocumentCompose(this.selected.id, this.selected.name);
  }



  hasGetFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTask,
    );
  }

  hasDeleteFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.DeleteFileTask,
    );
  }

  hasCrudPermission(): string[] {
    return [
      this.hasGetFileTaskPermission(),
      this.hasDeleteFileTaskPermission()
    ]
  }
}
