import { Component, ViewChild } from '@angular/core';
import { Research } from '../../models/research.model';
import { RxState } from '@rx-angular/state';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ENGLISH_LANGUAGE } from 'src/app/core/constants/language';
import { Subject } from 'rxjs';

interface FullLengthResearchesState {
  researches: PaginatedList<Research> | undefined;
  length: number;
}

const initFullLengthResearchesState: FullLengthResearchesState = {
  researches: undefined,
  length: 0,
};

@Component({
  selector: 'app-full-length-researches',
  templateUrl: './full-length-researches.component.html',
  styleUrls: ['./full-length-researches.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: FullLengthResearchesComponent}],
})
export class FullLengthResearchesComponent {
  researches$ = this.state.select('researches');
  length$ = this.state.select('length');
  researches: PaginatedList<Research> | undefined;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'name',
    'ownerOfficeName',
    'budget',
    'startDate',
    'endDate',
    'category',
  ];
  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  locale = localStorage.getItem('locale')?? ENGLISH_LANGUAGE.locale;

  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@researches.full-length-researches.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.full-length-researches.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.full-length-researches.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.full-length-researches.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.full-length-researches.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.full-length-researches.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.full-length-researches.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.full-length-researches.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private state: RxState<FullLengthResearchesState>,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initFullLengthResearchesState),
      this.state.connect(
        'researches',
        this.researchDashboardFacade.researches$,
      );
    this.state.connect('length', this.researchDashboardFacade.length$);
  }
  changes = new RxState();
  ngOnInit() {
    this.researchDashboardFacade.dispatchgetFullLengthResearches();
    this.researches$.subscribe((researches) => {
      this.dataSource.data = researches?.items || [];
      this.dataSource.sort = this.sort;
      this.length = researches?.totalCount as number;
    });
  }

  loadPaginatedProcessInstances(event: PageEvent) {
    this.researchDashboardFacade.dispatchgetFullLengthResearches(
      undefined,
      undefined,
      undefined,
      undefined,
      event.pageIndex + 1,
      event.pageSize,
    );
  }
}
