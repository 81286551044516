import {
  CostBreakdownItem,
  CreateCostBreakdownItemRequest,
} from '../models/cost-breakdown';
import {
  TorStatusChange,
  Tor,
  TorComment,
  TorDetail,
  TorCommentReply,
} from '../models/tor.model';

export class GetPaginatedTor {
  static readonly type = `[Tors] ${GetPaginatedTor.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
    public readonly grouped?: boolean | null,
  ) {}
}

export class SetPageIndexAndSize {
  static readonly type = `[Tor] ${SetPageIndexAndSize.name}`;
  constructor(
    public index: number,
    public size: number,
  ) {}
}

export class InitiateTor {
  static readonly type = `[Tor] ${InitiateTor.name}`;
  constructor(public stageInstanceId: string , public currency:string) {}
}

export class DeleteTor {
  static readonly type = `[Tor] ${DeleteTor.name}`;
  constructor(public torId: string) {}
}

export class DetailTor {
  static readonly type = `[TorDetail] ${DetailTor.name}`;
  constructor(public readonly torDetailId: string) {}
}

export class SelectedTor {
  static readonly type = `[Tor] ${SelectedTor.name}`;
  constructor(public readonly selectedTor: Tor) {}
}

export class UpdateTor {
  static readonly type = `[TorDetail] ${UpdateTor.name}`;
  constructor(
    public id: string,
    public description: string,
  ) {}
}

export class SetSelectedTorDescription {
  static readonly type = `[TorDetail] ${SetSelectedTorDescription.name}`;
  constructor(
    public selectedTorDescription: TorDetail,
    public typeOfAction: string,
  ) {}
}

export class AddCostBreakdownItem {
  static readonly type = `[TorDetail] ${AddCostBreakdownItem.name}`;
  constructor(
    public torId: string,
    public costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {}
}

export class GetCostBreakdownItems {
  static readonly type = `[TorDetail] ${GetCostBreakdownItems.name}`;
  constructor(
    public torId: string,
    public pageNumber: number,
    public pageSize: number,
  ) {}
}

export class SetSelectedCostBreakdownItem {
  static readonly type = `[TorDetail] ${SetSelectedCostBreakdownItem.name}`;
  constructor(public selectedCostBreakdownItem: CostBreakdownItem) {}
}

export class UpdateCostBreakdownItem {
  static readonly type = `[TorDetail] ${UpdateCostBreakdownItem.name}`;
  constructor(
    public torId: string,
    public costBreakdownItemId: string,
    public costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {}
}

export class SetUpdateCostBreakdownItemStatus {
  static readonly type = `[TorDetail] ${SetUpdateCostBreakdownItemStatus.name}`;
  constructor(public updateCostBreakdownItemStatus: boolean) {}
}

export class DownloadTorPdf {
  static readonly type = `[Tor] ${DownloadTorPdf.name}`;
  constructor(public torId: string) {}
}
export class DeleteCostBreakdownItem {
  static readonly type = `[TorDetail] ${DeleteCostBreakdownItem.name}`;
  constructor(
    public torId: string,
    public costBreakdownItemId: string,
  ) {}
}

export class ChangeTorStatus {
  static readonly type = `[Tor] ${ChangeTorStatus.name}`;
  constructor(public readonly statusChange: TorStatusChange) {}
}

export class GetTorComments {
  static readonly type = `[TorDetail] ${GetTorComments.name}`;
  constructor(
    public torId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}

export class SetSelectedTorComment {
  static readonly type = `[Tor] ${SetSelectedTorComment.name}`;
  constructor(public readonly selectedTorComment: TorComment) {}
}

export class AddTorComment {
  static readonly type = `[Tor] ${AddTorComment.name}`;
  constructor(
    public readonly torId: string,
    public readonly content: string,
  ) {}
}

export class SetTorCommentUpdatingMode {
  static readonly type = `[Tor] ${SetTorCommentUpdatingMode.name}`;
  constructor(public readonly isUpdatingComment: boolean) {}
}

export class EditTorComment {
  static readonly type = `[Tor] ${EditTorComment.name}`;
  constructor(
    public readonly torCommentId: string,
    public readonly content: string,
  ) {}
}

export class DeleteTorComment {
  static readonly type = `[Tor] ${DeleteTorComment.name}`;
  constructor(public readonly torCommentId: string) {}
}

export class SetTorCommentReplyingMode {
  static readonly type = `[Tor] ${SetTorCommentReplyingMode.name}`;
  constructor(public readonly isReplyingToComment: boolean) {}
}

export class SetTorReplyUpdatingMode {
  static readonly type = `[Tor] ${SetTorReplyUpdatingMode.name}`;
  constructor(public readonly isUpdatingReply: boolean) {}
}

export class ReplyToTorComment {
  static readonly type = `[Tor] ${ReplyToTorComment.name}`;
  constructor(
    public readonly content: string,
    public readonly commentId: string,
  ) {}
}

export class GetCurrencyList {
  static readonly type = `[Tor] ${GetCurrencyList.name}`;
  constructor(
  ) {}
}

export class UpdateTorCommentReply {
  static readonly type = `[Tor] ${UpdateTorCommentReply.name}`;
  constructor(
    public torCommentId: string,
    public commentId: string,
    public content: string,
  ){}
}

export class SetSelectedReplyComment {
  static readonly type = `[Tor] ${SetSelectedReplyComment.name}`;
  constructor(
    public comment: TorComment,
  ) {}
}

export class ResetSelectedReplyComment {
  static readonly type = `[Tor] ${ResetSelectedReplyComment.name}`;
  constructor() {}
}

export class DeleteTorCommentReply {
  static readonly type = `[Tor] ${DeleteTorCommentReply.name}`;
  constructor(
    public readonly torCommentId: string,
    public commentId: string,
    ) {}
}

