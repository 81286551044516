import { UpdateWorkflowStep, WorkflowStepFile, WorkflowStepFileUploadReport } from './../models/workflow-step.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { WORKFLOW_STEP_URL } from 'src/app/core/constants/api-endpoints';
import {
  WorkflowStep,
  WorkflowStepSimple,
} from '../models/workflow-step.model';
import { NotifiedUser } from '../models/notified-user.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  GetWorkflowStep(workflowId: string) {
    return this.http.get<WorkflowStep>(`${WORKFLOW_STEP_URL}/${workflowId}`);
  }

  GetWorkflowSteps(workflowId: string) {
    return this.http.get<WorkflowStepSimple[]>(
      `${WORKFLOW_STEP_URL}/${workflowId}/steps`,
    );
  }

  getArchivedWorkflowStep(workflowId: string) {
    return this.http.get<WorkflowStep>(
      `${WORKFLOW_STEP_URL}/archived/${workflowId}`,
    );
  }

  getArchivedWorkflowSteps(workflowId: string) {
    return this.http.get<WorkflowStepSimple[]>(
      `${WORKFLOW_STEP_URL}/archived/${workflowId}/steps`,
    );
  }

  GetNotifiedUsers(
    workflowStepId: string,
    pageNumber?: number,
    pageSize?: number,
  ) {
    return this.http.get<PaginatedList<NotifiedUser>>(
      `${WORKFLOW_STEP_URL}/notified-users?workflowStepId=${workflowStepId}`,
    );
  }
  downloadWorkflowStep(workflowStepId: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<any>(
      `${WORKFLOW_STEP_URL}/download-workflow-step-document?workflowStepId=${workflowStepId}`,
      options,
    );
  }
  removeNotifiedUser(WorkflowStepId: string, userId: string) {
    return this.http.put<any>(`${WORKFLOW_STEP_URL}/remove-notify-user`, {
      WorkflowStepId,
      userId,
    });
  }

  updateWorkflowStep(workflowStep: UpdateWorkflowStep) {
    return this.http.put<WorkflowStep>(`${WORKFLOW_STEP_URL}`, workflowStep);
  }

  updateWorkflowStepStatus({stepId: workflowStep, action, comment, rejectionRemark}: {
    stepId: string;
    action: string;
    comment?: string;
    rejectionRemark?: string;
  }) {
    return this.http.put<WorkflowStep>(`${WORKFLOW_STEP_URL}/status`, {workflowStep, action, comment, rejectionRemark});
  }

  attachUserToStep(id: string, notifiedUsers: string[]): Observable<any> {
    return this.http.put<any>(`${WORKFLOW_STEP_URL}/add-notify-users`, {
      id,
      notifiedUsers,
    });
  }

  assignUserToWorkflowStep(
    workflowStepId: string,
    userId: string,
  ): Observable<WorkflowStep> {
    return this.http.put<WorkflowStep>(
      `${WORKFLOW_STEP_URL}/assign-user`,
      { workflowStepId, userId },
      this.httpOptions,
    );
  }
  getCcdWorkflowStepDetail(workflowStepId: string): Observable<WorkflowStep> {
    return this.http.get<WorkflowStep>(
      `${WORKFLOW_STEP_URL}/notified/${workflowStepId}`,
    );
  }

  getWorkflowStepDetail(workflowStepId: string): Observable<WorkflowStep> {
    return this.http.get<WorkflowStep>(
      `${WORKFLOW_STEP_URL}/${workflowStepId}/detail`,
    );
  }

  uploadWorkflowStepFile(
    file: File,
    workflowStepId:string
  ): Observable<HttpEvent<WorkflowStepFileUploadReport[]>> {
    const formData = new FormData();
    formData.append('files', file);

    return this.http.post<WorkflowStepFileUploadReport[]>(
      `${WORKFLOW_STEP_URL}/upload-file?workflowStepId=${workflowStepId}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  getWorkflowStepFiles(
    workflowStepId:string
  ): Observable<PaginatedList<WorkflowStepFile>>{
    return this.http.get<PaginatedList<WorkflowStepFile>>(
      `${WORKFLOW_STEP_URL}/files?workflowStepId=${workflowStepId}`
    );
  }

  DeleteWorkflowStepFile(fileId: string): Observable<any> {
    return this.http.delete<any>(`${WORKFLOW_STEP_URL}/files/${fileId}`, this.httpOptions);
  }

  getWorkflowStepFile(fileId: string): Observable<any> {
    return this.http.get(`${WORKFLOW_STEP_URL}/file/${fileId}`,{
      responseType: 'blob',
    });
  }
  
}
