<mat-dialog-content class="form-container">
  <h3>{{ title }}</h3>
  <form [formGroup]="publicationForm" class="publication-form">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@researches.configure-publication-template.title"
        >Title</mat-label
      >
      <input matInput formControlName="title" required 
      [attr.data-test-id]="'researches.create-publication-option.configure-publication-template.title-input-field'"
      />
    </mat-form-field>

    <ng-container *ngFor="let item of editorTypes; let i = index">
      <mat-toolbar class="row-container" [attr.data-test-id]="'researches.create-publication-option.configure-publication-template.mat-toolbar'">
        <span class="title">
          {{ item.name }}
        </span>

        <span class="spacer"></span>

        <input
          type="file"
          style="display: none"
          (change)="onFileSelected($event, item.id)"
          #fileInput
          [attr.data-test-id]="'researches.create-publication-option.configure-publication-template.upload-template-button'"
        />

        <ng-container *ngIf="isFileUploaded(item.id); else uploadTemplate">
          <button
            mat-button
            class="editory-type-button"
            (click)="removeFile(item.id)"
            aria-label="Remove uploaded file"
          >
            <span class="file-name" matTooltip="{{ getFileName(item.id) }}">
              {{ getFileName(item.id) }}
            </span>
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
        <ng-template #uploadTemplate>
          <button
            mat-button
            class="editory-type-button"
            (click)="fileInput.click()"
            aria-label="Upload"
            >
            <mat-icon>file_upload</mat-icon>
            <span
              i18n="@@researches.configure-publication-template.upload-template"
              >Upload Template</span
            >
          </button>
        </ng-template>
      </mat-toolbar>
    </ng-container>

    <div mat-dialog-actions class="action-btn">
      <button
        mat-button
        mat-dialog-close
        i18n="@@researches.configure-publication-template.cancel"
      >
        Cancel
      </button>
      <span class="spacer"></span>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!isFormValid()"
        (click)="onSubmit()"
        [attr.data-test-id]="'researches.create-publication-option.configure-publication-template.create-button'"
      >
        {{ button }}
      </button>
    </div>
  </form>
</mat-dialog-content>
