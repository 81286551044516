<mat-sidenav-container class="container" *ngIf="processInstanceDetail">
  <mat-sidenav-content class="processdetail">
    <div>
      <h1 class="mat-h1">
        {{ processInstanceDetail.title }} :
        {{ processInstanceDetail.process.name }}
        <span
          class="chip text-bold"
          [ngClass]="
            getProcessInstanceStatusColor(processInstanceDetail.processStatus)
          "
        >
          <span>
            {{
              checkProcessInstanceStatus(processInstanceDetail.processStatus)
            }}
          </span>
        </span>
      </h1>
      <p>{{ processInstanceDetail.description }}</p>
    </div>
    <div class="header">
      <div class="team-members-container">
        <mat-card class="team-member-card">
          <mat-card-header class="team-card-header">
            <div mat-card-avatar class="team-member-header-icon">
              <fa-icon [icon]="faPeopleGroup" class="team-icon"></fa-icon>
            </div>
            <mat-card-title
              i18n="@@researches.process-instance-detail.team-members"
              >Team Members</mat-card-title
            >
          </mat-card-header>
          <mat-card-content class="team-content">
            <div
              *ngFor="let teamMember of teamMembers"
              class="member"
              matTooltip="{{
                teamMember.firstName +
                  ' ' +
                  teamMember.middleName +
                  ' ' +
                  teamMember.lastName
              }}"
            >
              <div class="rounded p-10">
                <span>{{ teamMember?.firstName?.substring(0, 1) }}</span>
              </div>
              <mat-card-subtitle class="text ellipsis">
                {{ teamMember?.firstName }}
                {{ teamMember?.middleName }}
                {{ teamMember?.lastName }}
              </mat-card-subtitle>
            </div>
          </mat-card-content>
          <mat-card-subtitle
            class="no-member-text"
            *ngIf="!teamMembers?.length ?? 0 > 0"
            i18n="@@researches.process-instance-detail.no-team-members-added"
          >
            no team members added
          </mat-card-subtitle>
          <mat-card-actions>
           <ng-container *appHasPermission="hasAddTeamMembersPermission()">
            <button
            *ngIf="!processInstanceDetail?.isArchived && processInstanceDetail.ownerId===currentLoggedInUser?.id"
            (click)="onAddMemberClicked()"
            mat-button
            i18n="@@researches.process-instance-detail.add-member"
          >
            Manage Team Member
          </button>
           </ng-container>
          </mat-card-actions>
        </mat-card>
      </div>
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ scheduleMessage }}</mat-card-title>
        </mat-card-header>
        <mat-card-actions style="margin-top: auto">
          <ng-container *appHasPermission="hasSubmitProcessInstanceSchedulePermission()">
          <button
            *ngIf="!processInstanceDetail?.isArchived && processInstanceDetail?.ownerEmail === currentLoggedInUser?.email"
            mat-flat-button
            color="primary"
            (click)="submitProcessInstanceSchedule()"
          >
            {{ scheduleButtonText }}
          </button>
        </ng-container>
          <button
            mat-icon-button
            class="icons"
            *ngIf="
              !processInstanceDetail?.isArchived &&
              processInstanceDetail.startDate &&
              processInstanceDetail?.ownerEmail === currentLoggedInUser?.email
            "
            (click)="updateDeadline()"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="edit icon"
              fontIcon="edit"
              *appHasPermission="hasUpdateProcessInstanceSchedulePermission()"
            ></mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="flex-end mr-4 flex-center">
      <div class="flex gap-x-2">
        <div *ngIf="!processInstanceDetail.isArchived">
          <button
          mat-flat-button
          [color]="toggleButton === 'Cancel' ? 'warn' : 'primary'"
          (click)="!processInstanceDetail.isArchived ? assignOwner() : null"
          *appHasPermission="hasAssignOwnerToProcessInstancePermission()"
        >
          {{ !processInstanceDetail.isArchived ? toggleButton : "Owner" }}
        </button>
        </div>
        <div
          class="flex"
          [ngClass]="!assignOwnerClicked ? 'visible' : 'hidden'"
          *ngIf="!assignOwnerClicked"
        >
          <div class="rounded p-10 mr-4">
            <span>{{ processInstanceDetail.ownerName!.substring(0, 1) }}</span>
          </div>
          <mat-card-title class="text">
            {{ processInstanceDetail.ownerName }}
          </mat-card-title>
        </div>
        <form class="researcher-form" [formGroup]="assignOwnerForm" *ngIf="assignOwnerClicked">
          <mat-form-field appearance="outline">
            <mat-label i18n="@@researches.process-instance-detail.researchers"
              >Researchers</mat-label
            >
            <input
              #approverInput
              matInput
              [value]="processInstanceDetail.ownerName"
              [placeholder]= "placeholderToggleLabel.selectResearchers"
              formControlName="researchOwner"
              [matAutocomplete]="auto"
              [attr.data-test-id]="'researches.change-owner.process-instance-detail.input-for-select-researchers'"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectResearcher($event)"
            >
              <mat-option
                *ngFor="let researcher of filteredResearchers"
                [value]="researcher"
              >
                <div class="flex">
                  <div class="rounded p-10 mr-4">
                    <span>{{ researcher.firstName.substring(0, 1) }}</span>
                  </div>
                  <mat-card-title class="text">
                    {{ researcher.firstName }} {{ researcher.middleName }}
                    {{ researcher.lastName }}
                  </mat-card-title>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="stages">
      <h2 class="mat-h2" i18n="@@researches.process-instance-detail.stage-instances" *appHasPermission="hasGetStageInstancesPermission()">
        Stage Instances
      </h2>
    </div>
    <div *appHasPermission="hasGetStageInstancesPermission()">
      <ng-container *ngIf="processInstanceDetail">
        <div
          *ngIf="
            processInstanceDetail!.stageInstances &&
            processInstanceDetail!.stageInstances.length > 0
          "
        >
          <mat-card
            [ngClass]="
              enforceSequence(stage) ? 'disabled-stage-card' : 'stage-card'
            "
            *ngFor="let stage of processInstanceDetail?.stageInstances"
            [attr.data-test-id]="'researches.get-stage-instance.process-instance-detail.mat-card'"
          >
            <div>
              <mat-card-header class="card-header">
                <div class="stage-header">
                  <div class="flex">
                    <mat-card-title class="card-title">
                      {{ stage.name }}
                    </mat-card-title>
                    <div
                      class="chip text-bold"
                      [ngClass]="
                        getProcessInstanceStageStatusColor(
                          stage?.stageStatus!.toString()
                        )
                      "
                    >
                      <span>
                        {{ checkProcessInstanceStageStatus(stage) }}
                      </span>
                    </div>

                    <div class="budget" *ngIf="hasBudget(stage)">
                      <h4
                        style="margin: 19px 5px"
                        i18n="
                          @@researches.process-instance-detail.allocated-budget"
                      >
                        Allocated Budget:
                      </h4>
                      <p>
                        {{
                          stage.allocatedBudget.toString()
                            | currency
                              : stage.allocatedBudgetCurrency
                              : "code"
                              : "2.2-2"
                              : locale
                        }}
                      </p>
                      <h4
                        style="margin: 19px 5px"
                        i18n="
                          @@researches.process-instance-detail.consumed-budget"
                      >
                        Consumed Budget:
                      </h4>
                      <p>
                        {{ stage.consumedBudget }}
                        {{ stage.consumedBudgetCurrency }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="maticon"
                    *ngIf="!processInstanceDetail?.isArchived"
                  ></div>
                </div>
              </mat-card-header>
              <mat-card-content class="mat-body-2">
                <div *ngIf="!(stage.stageStatus.toString() === 'Approved' || stage.stageStatus.toString() === 'Rejected')">
                  <p
                    [ngClass]="{ duration: stage?.actualStartDate }"
                    *ngIf="stage?.actualStartDate"
                  >
                    <span
                      i18n="
                        @@researches.process-instance-detail.actual-duration"
                      >Actual Duration:</span
                    >
                    {{ getReadableActualDuration(stage) }}
                  </p>
                  <p
                    [ngClass]="{ duration: stage?.startDate }"
                    *ngIf="stage?.startDate"
                  >
                    <span
                      i18n="
                        @@researches.process-instance-detail.planned-duration"
                      >Planned Duration:</span
                    >
                    {{ getReadablePlannedDuration(stage) }}
                  </p>
                </div>
                <p class="text-gray">
                  {{ stage.description }}
                </p>
              </mat-card-content>
              <div class="budget" *ngIf="hasBudget(stage)">
                <h4
                  style="margin: 19px 5px"
                  i18n="@@researches.process-instance-detail.allocated-budget"
                >
                  Allocated Budget:
                </h4>
                <p>
                  {{
                    stage.allocatedBudget.toString()
                      | currency
                        : stage.allocatedBudgetCurrency
                        : "code"
                        : "2.2-2"
                        : locale
                  }}
                </p>
                <h4
                  style="margin: 19px 5px"
                  i18n="@@researches.process-instance-detail.consumed-budget"
                >
                  Consumed Budget:
                </h4>
                <p>
                  {{
                    stage.consumedBudget.toString()
                      | currency
                        : stage.consumedBudgetCurrency
                        : "code"
                        : "2.2-2"
                        : locale
                  }}
                </p>
              </div>
            </div>

            <div class="flex" >
              <div class="flex">
                <div class="flex">
                  <mat-card-actions>
                    <button
                    (click)="
                    enforceSequence(stage) ? null : selectedStage(stage.id!, stage)
                  "
                      *ngIf="!processInstanceDetail?.isArchived"
                      mat-stroked-button
                      color="primary"
                      i18n="
                        @@researches.process-instance-detail.stage-instance-detail"
                    >
                      Stage Instance Detail
                    </button>
                    <ng-container *appHasPermission="hasUpdateStatusToInprogressPermission()">
                    <button
                      *ngIf="isStartButtonVisible(stage) && !processInstanceDetail?.isArchived && isCurrentUserTeamMemberOrOwner"
                      (click)="startStageInstance(stage)"
                      mat-stroked-button
                      class="start-btn"
                      color="primary"
                      i18n="@@researches.process-instance-detail.start"
                    >
                      Start Working
                    </button>
                    <button
                    mat-stroked-button
                    class="start-btn"
                    color="primary"
                    *ngIf="processInstanceDetail?.isArchived"
                    i18n="@@researches.tor-detail.download"
                    (click)="DownloadStage(stage)"
                    >
                    Download
                    </button>
                  </ng-container>
                  </mat-card-actions>
                </div>
              </div>
            </div>

          </mat-card>
        </div>

        <div
          *ngIf="processInstanceDetail!.stageInstances.length === 0"
          i18n="@@researches.process-instance-detail.no-stage-instance-found"
        >
          No stage instance found.
        </div>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
