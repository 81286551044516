<div class="m15 mat-dialog">
  <div mat-dialog-content>
    <h2 i18n="@@researches.task-file-upload-option-dialog.upload-options">Upload Options</h2>
    <p>{{ message }}</p>
    <mat-radio-group
      class="flex align-items"
      aria-label="Select an option"
      [formControl]="replaceControl"
    >
      <mat-radio-button value="replace" i18n="@@researches.task-file-upload-option-dialog.replace-existing-file">Replace existing file</mat-radio-button>
      <mat-radio-button value="keep" i18n="@@researches.task-file-upload-option-dialog.keep-both-files">Keep both files</mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary"
    i18n="@@researches.task-file-upload-option-dialog.cancel">Cancel</button>
    <button
      [mat-dialog-close]="true"
      mat-flat-button
      color="primary"
      (click)="onConfirm()"
      i18n="@@researches.task-file-upload-option-dialog.upload"
    >
      Upload
    </button>
  </div>
</div>
