import { removeItem } from '@ngxs/store/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicationOption } from './../../../models/publication-option.model';
import { Component, Inject, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable, take } from 'rxjs';
import { PublicationOptionFacade } from 'src/app/researches/facades/publication-option.facade';
import { PublicationFacade } from 'src/app/researches/facades/publications.facades';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CreatePublicationInfo } from 'src/app/researches/models/publication.model';

interface ChoosePublicationOptionFormComponentState {
  publicationOptions: PublicationOption[];
}

const initState: ChoosePublicationOptionFormComponentState = {
  publicationOptions: [],
};

@Component({
  selector: 'app-choose-publication-option-form',
  templateUrl: './choose-publication-option-form.component.html',
  styleUrls: ['./choose-publication-option-form.component.scss'],
})
export class ChoosePublicationOptionFormComponent implements OnInit {
  publicationOptions$: Observable<PublicationOption[]> =
    this.state.select('publicationOptions');
  publicationOptions: PublicationOption[] = [];
  selectedOptions: Set<string> = new Set();
  title: string = '';
  constructor(
    private readonly publicationOptionFacade: PublicationOptionFacade,
    private readonly publicationFacade: PublicationFacade,
    private readonly state: RxState<ChoosePublicationOptionFormComponentState>,
    private dialogRef: MatDialogRef<ChoosePublicationOptionFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { processInstanceId: string },
  ) {
    this.state.set(initState);
    this.state.connect(
      'publicationOptions',
      this.publicationOptionFacade.publicationOptions$,
    );
  }

  placeholderToggleLabel = {
    titleOption: $localize`:@@researches.choose-publication-option-form.title: Title`,
  };

  ngOnInit(): void {
    this.publicationOptionFacade.dispatchGetPublicationOptions();
    this.publicationOptions$.subscribe((opts) => {
      this.publicationOptions = opts;
    });
  }

  onSave(): void {
    let publication: CreatePublicationInfo = {
      processInstanceId: this.data.processInstanceId,
      publicationOptionIds: Array.from(this.selectedOptions),
      title: this.title,
    };
    this.publicationFacade.dispatchCreatePublication(publication).pipe(take(1)).subscribe(() => {
      this.dialogRef.close('confirm');
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onChange(event: MatCheckboxChange, option: PublicationOption) {
    let selected = event.checked;
    if (selected) this.selectedOptions.add(option.id);
    else this.selectedOptions.delete(option.id);
  }
}
