import { Component, OnInit } from '@angular/core';
import { OfficeFacade } from '../../facades/office.facades';
import { FlatOfficeNode } from '../../models/flat-office-node.model';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Office } from '../../models/office.model';
import { UpdateOfficeStructureFormComponent } from '../update-office-structure-form/update-office-structure-form.component';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatRadioChange } from '@angular/material/radio';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';

interface UpdateStructureComponentState {
  flatOfficeNodes: FlatOfficeNode[];
  selectedOfficeNode: FlatOfficeNode;
}

const initUpdateStructureComponentState = {
  flatOfficeNodes: [],
};

@Component({
  selector: 'app-update-structure',
  templateUrl: './update-structure.component.html',
  styleUrls: ['./update-structure.component.scss'],
})
export class UpdateStructureComponent implements OnInit {
  flatOfficeNodes$: Observable<FlatOfficeNode[]> =
    this.state.select('flatOfficeNodes');
  officeNodes: FlatOfficeNode[] = [];
  selectedFlatOfficeNode$ = this.state.select('selectedOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  previousSelectedOfficeNode: FlatOfficeNode | null | undefined;
  dataSource: FlatOfficeNode[] = [];
  toBeEdited: FlatOfficeNode | undefined;

  constructor(
    private officeFacade: OfficeFacade,
    private state: RxState<UpdateStructureComponentState>,
    private dialog: MatDialog,
  ) {
    this.state.set(initUpdateStructureComponentState);
    this.state.connect('flatOfficeNodes', this.officeFacade.flatOfficeNodes$);
    this.state.connect(
      'selectedOfficeNode',
      this.officeFacade.selectedFlatOfficeNode$,
    );
  }

  ngOnInit(): void {
    this.officeFacade.dispatchSelectFlatOfficeNode(undefined);
    this.flatOfficeNodes$.subscribe((nodes) => {
      if (nodes) {
        this.dataSource = nodes;
      }
    });
    this.selectedFlatOfficeNode$.subscribe((officeNode) => {
      this.selectedFlatOfficeNode = officeNode;
    });
  }

  updateDataSource() {
    if (this.officeNodes) this.dataSource = this.officeNodes;
  }

  onNodeClick(officeNode: FlatOfficeNode) {
    this.officeFacade.dispatchSelectFlatOfficeNode(officeNode);
    this.dialog
      .open(UpdateOfficeStructureFormComponent)
      .afterClosed()
      .subscribe(() => {
        this.officeFacade.dispatchGetFlatOfficeNodes();
      });
  }

  treeControl = new FlatTreeControl<FlatOfficeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  hasChild = (_: number, node: FlatOfficeNode) => node.expandable;

  getParentNode(node: FlatOfficeNode) {
    const nodeIndex = this.dataSource.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      const parts = node.pathFromRoot.split('.');
      if (
        this.dataSource[i].pathFromRoot ===
        parts.slice(0, parts.length - 1).join('.')
      ) {
        return this.dataSource[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatOfficeNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  selectFlatOfficeNode(event: MatRadioChange, flatOfficeNode: FlatOfficeNode) {
    this.toBeEdited = flatOfficeNode;
  }
  cancel() {
    if (this.previousSelectedOfficeNode)
      this.officeFacade.dispatchSelectFlatOfficeNode(
        this.previousSelectedOfficeNode,
      );
    else this.officeFacade.dispatchResetSelectedOffice();
  }

  openChangeParentForm() {
    this.officeFacade.dispatchSelectFlatOfficeNode(this.toBeEdited);
    this.dialog
      .open(UpdateOfficeStructureFormComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result === 'save') {
        }
      });
  }

  hasUpdateOfficeTreePermission(): string {
    return GetFullPermissionName(
      MODULES.OFFICES,
      PERMISSION_NAMES.Offices.Office.Feature,
      PERMISSION_NAMES.Offices.Office.UpdateOfficeTree,
    );
  }
}
