<div class="main-table mat-elevation-z4">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedTaskDocument($event)"
  >
  </mat-paginator>
  <mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.task-document-list.name">Name</mat-header-cell>
      <mat-cell *matCellDef="let task">{{ task.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.task-document-list.type">Type</mat-header-cell>
      <mat-cell *matCellDef="let task">{{ task?.taskFileTypeForDisplay?.fileType }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.task-document-list.created-at">Created At</mat-header-cell>
      <mat-cell *matCellDef="let task">{{ task.createdAt }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.task-document-list.created-by">Created By</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <p *ngIf="task.creator?.fullName" class="mat-h2 avatar" mat-card-avatar>{{task.creator?.fullName?.[0]}}</p>
        {{ task.creator?.fullName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.task-document-list.actions">Actions</mat-header-cell>
      <mat-cell *matCellDef="let task" class="icon-cell">
        <button
          *appHasPermission="hasCrudPermission()"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="onMenuClick($event, task)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="['name', 'type', 'createdAt', 'createdBy', 'actions']"
    ></mat-header-row>

    <mat-row
      class="table-row"
      *matRowDef="
        let process;
        columns: ['name', 'type', 'createdAt', 'createdBy', 'actions']
      "
    ></mat-row>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="previewDocument()"
      *appHasPermission="hasGetFileTaskPermission()"
      i18n="@@researches.task-document-list.previous-document"
      [attr.data-test-id]="'researches.preview-task-document.task-document-list.preview-document-button'">
        Preview Document
      </button>
      <ng-container *appHasPermission="hasGetFileTaskPermission()">
      <button
        *ngIf="isTableDocument()"
        mat-menu-item
        (click)="downloadDocumentAsPdf()"
        i18n="@@researches.task-document-list.download-as-pdf"
        [attr.data-test-id]="'researches.download-document-as-pdf.task-document-list.download-as-pdf-button'"
      >
        Download As PDF
      </button>
      <button
        *ngIf="isTableDocument() == false"
        mat-menu-item
        (click)="downloadDocument()"
        i18n="@@researches.task-document-list.download-as-document"
        [attr.data-test-id]="'researches.download-document.task-document-list.download-document-button'"
      >
        Download Document
      </button>
    </ng-container>
    <div *ngIf="isDeleteDocumentPossible() && !processInstanceDetail?.isArchived">
      <button mat-menu-item (click)="openConfirmationDialog()"
      *appHasPermission="hasDeleteFileTaskPermission()"
      i18n="@@researches.task-document-list.delete-document"
      [attr.data-test-id]="'researches.delete-document.task-document-list.delete-document-button'">
        Delete Document
      </button>
    </div>
    </mat-menu>
  </mat-table>
</div>

<div class="media-container">
  <div class="video-container" id="video-container">
    <button class="close-button" id="close-button" (click)="closePlayer()">
      <mat-icon class="close-icon">close-button</mat-icon>
    </button>
    <video #videoElement controlsList="nodownload"
    i18n="@@researches.task-document-list.your-browser-does-not-support-the-video-tag">
      Your browser does not support the video tag
    </video>
  </div>
  <div class="audio-container" id="audio-container">
    <button
      class="close-audio-button"
      id="close-audio-button"
      (click)="closeAudioPlayer()"
    >
      <mat-icon class="close-audio-icon">close-button</mat-icon>
    </button>
    <audio #audioElement controlsList="nodownload"></audio>
  </div>
</div>
