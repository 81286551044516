import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { tap } from 'rxjs';

import { Role } from '../models/role.model';
import { RolesService } from '../services/roles.service';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import {
  AssignRevokePermissions,
  CreateRole,
  DeleteRole,
  GetPaginatedRoles,
  GetPermissionsByRoleId,
  GetRoles,
  SearchRole,
  SetSelectedRole,
  SetUpdateStatus,
  UpdateRole,
} from './role.actions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';

export interface RoleStateModel {
  roles: Role[];
  paginatedRoles: PaginatedList<Role>;
  totalCount: number;
  selectedRole: Role | undefined;
  update: boolean;
}

const ROLE_STATE_TOKEN = new StateToken<RoleStateModel>('rolesState');

const defaults = {
  roles: [],
  paginatedRoles: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  totalCount: 0,
  update: false,
  selectedRole: undefined,
};

@State<RoleStateModel>({
  name: ROLE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class RoleState {
  constructor(
    private rolesService: RolesService,
    private store: Store,
    private operationStatus: OperationStatusService,
  ) {}

  @Action(GetRoles)
  getRoles({ setState }: StateContext<RoleStateModel>) {
    this.store.dispatch(new SetProgressOn());
    return this.rolesService.getRoles().pipe(
      tap((roles) => {
        setState(
          patch({
            roles: roles,
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetPaginatedRoles)
  getPaginatedRoles(
    { patchState }: StateContext<RoleStateModel>,
    { pageNumber, pageSize }: GetPaginatedRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.rolesService.getPaginatedRoles(pageNumber, pageSize).pipe(
      tap((roles) => {
        patchState({
          roles: roles.items,
          paginatedRoles: roles,
          totalCount: roles.totalCount,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetUpdateStatus)
  setUpdateStatus(
    { patchState }: StateContext<RoleStateModel>,
    { updateStatus }: SetUpdateStatus,
  ) {
    patchState({ update: updateStatus });
  }

  @Action(CreateRole)
  createRole(
    { setState, getState }: StateContext<RoleStateModel>,
    { name }: CreateRole,
  ) {
    this.store.dispatch(new SetProgressOn());
    const prevLength = getState().roles.length;
    return this.rolesService.createRole(name).pipe(
      tap((role: Role) => {
        setState(
          patch({
            roles: insertItem(role),
            totalCount: prevLength + 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.role.create-role: Role has been created successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateRole)
  updateRole(
    { setState, getState }: StateContext<RoleStateModel>,
    { role }: UpdateRole,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.rolesService.updateRole(role).pipe(
      tap((updatedRole) => {
        setState(
          patch({
            roles: updateItem<Role>((r) => r.id === role.id, updatedRole),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.role.edit-role:Role updated successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetSelectedRole)
  setSelectedRole(
    { patchState }: StateContext<RoleStateModel>,
    { role }: SetSelectedRole,
  ) {
    patchState({ selectedRole: role });
  }

  @Action(GetPermissionsByRoleId)
  getPermissionsByRoleId(
    { setState, getState }: StateContext<RoleStateModel>,
    { roleId }: GetPermissionsByRoleId,
  ) {
    this.store.dispatch(new SetProgressOn());
    const selectedRole = getState().selectedRole;
    return this.rolesService.getPermissionsByRoleId(roleId).pipe(
      tap((permissions) => {
        setState(
          patch({
            selectedRole: patch({
              ...selectedRole,
              permissions: permissions,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AssignRevokePermissions)
  assignRevokePermissions(
    { setState, getState }: StateContext<RoleStateModel>,
    { roleId, permissionsId }: AssignRevokePermissions,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.rolesService
      .assignRevokePermissions(roleId, permissionsId)
      .pipe(
        tap((role) => {
          setState(
            patch({
              selectedRole: role,
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@users.role.update-role: Role permissions updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteRole)
  deleteRole(
    { setState, getState }: StateContext<RoleStateModel>,
    { roleId }: DeleteRole,
  ) {
    this.store.dispatch(new SetProgressOn());
    const prevLength = getState().totalCount;
    return this.rolesService.deleteRole(roleId).pipe(
      tap(() => {
        setState(
          patch({
            roles: removeItem<Role>((r) => r.id === roleId),
            totalCount: prevLength - 1,
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@users.role.delete-role: Role deleted successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SearchRole)
  searchRole(
    { setState }: StateContext<RoleStateModel>,
    { searchTerm, pageNumber, pageSize }: SearchRole,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.rolesService
      .searchRole(searchTerm, pageNumber, pageSize)
      .pipe(
        tap((roles) => {
          setState(
            patch({
              roles: roles.items,
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
