<div class="flex mx-2 mt-4">
  <app-workflow-search
    (filterParamsEventEmitter)="saveWorkflowParameters($event)"
    *appHasPermission="hasSearchWorkflowsPermission()"
  ></app-workflow-search>
</div>

<div class="flex justify-between">
  <div class="gap-x flex">
    <h1 class="mat-h1" i18n="@@documents.workflow.workflows">Workflows</h1>
    <mat-paginator
      [pageSize]="pageSize"
      [pageIndex]="handlePageIndex()"
      [length]="search ? filteredWorkflows?.totalCount : workflows?.totalCount"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="loadPaginatedWorkflows($event)"
    >
    </mat-paginator>
  </div>
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="initiateWorkflow()"
    *appHasPermission="hasInitiateWorkflowPermission()"
  >
    <span i18n="@@documents.workflow.initiate-workflow"> Initate Workflow </span> <mat-icon>add</mat-icon>
  </button>
</div>
<ng-container *ngIf="workflows?.items !== []; else emptyWorkflowList">
  <app-workflow-card
    *ngFor="
      let workflow of search ? filteredWorkflows?.items : workflows?.items
    "
    [workflow]="workflow"
  ></app-workflow-card>
</ng-container>
<ng-template #emptyWorkflowList>
  <div class="empty-workflow-list">
    <span class="text-2xl" i18n="@@documents.workflow.no-workflows-found">No workflows found</span>
  </div>
</ng-template>
