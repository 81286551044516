import { LoginRequest, RefreshTokenRequest } from '../models/login-request.model';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public request: LoginRequest) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class RefreshToken{
  static readonly type = '[Auth] RefreshToken';
  constructor(public request: RefreshTokenRequest) {}
}
export class SetLockedOut{
  static readonly type = '[Auth] SetLockedOut';
  constructor(public lockoutDuration: number) {}
}
export class SetDisabled{
  static readonly type = '[Auth] SetDisabled';
  constructor(public isDisabled: boolean) {}
}
