import { createPropertySelectors } from '@ngxs/store';
import {
  WorkflowValidationsStateModel,
  WorkflowValidationState,
} from './workflow-validations.state';

export class WorkflowValidationSelector {
  static slices = createPropertySelectors<WorkflowValidationsStateModel>(
    WorkflowValidationState,
  );
}
