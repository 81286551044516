export enum Priority {
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
  }

  export enum Status {
    Reported = 'Reported',
    InProgress = 'InProgress',
    Fixed = 'Fixed',
    Resolved = 'Resolved',
    Unresolved = 'Unresolved',
  }