<mat-card class="container-card">
  <mat-card-header>
    <div mat-card-avatar class="avatar">
      {{ torComment?.fullName && torComment?.fullName?.substring(0, 1) }}
    </div>
    <mat-card-title>
      {{ torComment?.fullName }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ torComment?.created | date: "medium" }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p class="text-comment">
      {{ torComment?.content }}
    </p>
  </mat-card-content>

  <mat-card-actions class="action-buttons-end">
      <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Edit comment"
      (click)="editComment(torComment!)"
      *ngIf="currentLoggedInUser?.id === torComment?.createdBy"
    >
      <mat-icon>edit</mat-icon>
    </button>
      <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="warn"
      matTooltip="Delete comment"
      (click)="deleteComment($event, torComment!)"
      *ngIf="currentLoggedInUser?.id === torComment?.createdBy"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <button
      class="hover-effect mat-icon-button-small"
      mat-icon-button
      color="primary"
      matTooltip="Reply comment"
      (click)="replyComment(torComment!)"
      *appHasPermission="hasReplyTorCommentPermission()"
    >
      <mat-icon>reply</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
<div *ngIf="torComment?.replies?.length! > 0">
  <app-tor-comment-reply-card
    *ngFor="let reply of torComment?.replies"
    [torCommentReply]="reply"
    [torComment]="torComment"
    (editCommentReplyClicked)="editReply()"
  ></app-tor-comment-reply-card>
</div>