import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowTypesState,
  WorkflowTypesStateModel,
} from './workflow-types.state';

export class WorkflowTypesSelector {
  static slices =
    createPropertySelectors<WorkflowTypesStateModel>(WorkflowTypesState);

  @Selector([WorkflowTypesState])
  static workflowTypes(state: WorkflowTypesStateModel) {
    return state.workflowTypes;
  }

  @Selector([WorkflowTypesState])
  static workflowTypesWithSteps(state: WorkflowTypesStateModel){
    return state.workflowTypesWithSteps;
  }

  @Selector([WorkflowTypesState])
  static ownedWorkflowTypes(state: WorkflowTypesStateModel) {
    return state.ownedWorkflowTypes;
  }

  @Selector([WorkflowTypesState])
  static totalCount(state: WorkflowTypesStateModel) {
    return state.totalCount;
  }

  @Selector([WorkflowTypesState])
  static selecteWorkflowType(state: WorkflowTypesStateModel) {
    return state.selectedWorkflowType;
  }

  @Selector([WorkflowTypesState])
  static update(stateModel: WorkflowTypesStateModel) {
    return stateModel.update;
  }

  @Selector([WorkflowTypesState])
  static isSearchingWorkflowType(stateModel: WorkflowTypesStateModel) {
    return stateModel.isSearchingWorkflowType;
  }
}
