import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FIELD_VALIDATION_RULES_URL,
  VALIDATION_RULES_URL,
} from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { FieldValidationRule } from '../models/field-validation-rule.model';
import { ValidationRuleValue } from '../models/validation-rule-value.model';

import { ValidationRule } from '../models/validation-rule.model';

@Injectable({
  providedIn: 'root',
})
export class FieldValidationRulesService {
  constructor(private http: HttpClient) {}

  getValidationRulesByDataType(dataType: string) {
    return this.http.get<Required<ValidationRule>[]>(VALIDATION_RULES_URL, {
      params: new HttpParams().set('dataType', dataType),
    });
  }

  getFieldValidationRules(templateFieldId: string) {
    return this.http.get<PaginatedList<Required<FieldValidationRule>>>(
      FIELD_VALIDATION_RULES_URL,
      {
        params: new HttpParams().set('templateFieldId', templateFieldId),
      },
    );
  }

  updateFieldValidationRule(
    id: string,
    validationRuleValues: ValidationRuleValue[],
  ) {
    return this.http.put<Required<FieldValidationRule>>(
      FIELD_VALIDATION_RULES_URL,
      { id, validationRuleValues },
    );
  }

  createFieldValidationRules(
    templateFieldId: string,
    fieldValidationRules: Array<{
      validationRuleId: string;
      validationRuleValue: { value: string; order: number }[];
    }>,
  ) {
    return this.http.post<Required<FieldValidationRule>[]>(
      FIELD_VALIDATION_RULES_URL,
      fieldValidationRules,
      {
        params: new HttpParams().set('templateFieldId', templateFieldId),
      },
    );
  }

  deleteFieldValidationRules(id: string) {
    return this.http.delete(`${FIELD_VALIDATION_RULES_URL}/${id}`);
  }
}
