import { DocumentForm } from '../../models/document-forms.model';
import { Component, ViewChild } from '@angular/core';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { RxState } from '@rx-angular/state';
import { DocumentFormFacade } from '../../facade/document-forms.facades';
import { FormStatusOption } from 'src/app/documents/shared/models/document-form-status';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { DOCUMENT_TEMPLATE_DETAIL_ROUTE } from 'src/app/core/constants/routes';
import { Router } from '@angular/router';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { map } from 'rxjs';

interface SubmittedDocumentFormsComponentState {
  documentForms: PaginatedList<DocumentForm>;
}

const initialSubmittedDocumentFormsComponentState: SubmittedDocumentFormsComponentState =
  {
    documentForms: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
  };

@Component({
  selector: 'app-submitted-document-forms',
  templateUrl: './submitted-document-forms.component.html',
  styleUrls: ['./submitted-document-forms.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: SubmittedDocumentFormsComponent}, RxState],
})
export class SubmittedDocumentFormsComponent {
  dataSource!: MatTableDataSource<DocumentForm>;
  documentForms$ = this.state.select('documentForms');
  documentForms: DocumentForm[] = [];
  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  selected: DocumentForm | undefined = undefined;
  displayedColumns: string[] = ['name','status','actions'];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    firstPageLabel = $localize`:@@documents.submitted-document-forms.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.submitted-document-forms.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.submitted-document-forms.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.submitted-document-forms.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.submitted-document-forms.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.submitted-document-forms.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.submitted-document-forms.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.submitted-document-forms.page-part-two: of` + `${amountPages}`;
    }

  constructor(
    private documentFormFacade: DocumentFormFacade,
    private state: RxState<SubmittedDocumentFormsComponentState>,
    private router: Router,
    private userFacade: UserFacade
  ) {
    this.state.set(initialSubmittedDocumentFormsComponentState);
    this.state.connect(
      'documentForms',
      this.documentFormFacade.templates$,
    );
  }
  changes = new RxState();
  ngOnInit(): void {
    this.documentFormFacade.dispatchGetDocumentFormsByStatus(
      FormStatusOption.SUBMITTED,
      1,
      10,
    );
    this.documentFormFacade.templates$.subscribe((documentForms) => {
      this.dataSource = new MatTableDataSource<DocumentForm>(
        documentForms.items,
      );
      this.length = documentForms.totalCount;
      this.documentForms = documentForms.items;
    });
  }

  onMenuClick($event: Event, template: DocumentForm) {
    this.selected = template;
  }

  loadPaginatedDocumentForms(event: PageEvent) {
    this.documentFormFacade.dispatchGetDocumentFormsByStatus(
      FormStatusOption.SUBMITTED,
      event.pageIndex + 1,
      event.pageSize,
    );
  }
  
  onRowClick(event: any, template: DocumentForm) {
    this.documentFormFacade.dispatchSetSelectedDocumentForm(template);
    if (template.id) {
      this.documentFormFacade.dispatchGetDocumentForm(
        template.id as string,
      );
      this.router.navigate([DOCUMENT_TEMPLATE_DETAIL_ROUTE]);
    }
  }

  hasGetDocumentFormPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.DocumentForm.Feature,
      PERMISSION_NAMES.Documents.DocumentForm.GetDocumentForm,
    );
  }

  isAuthorized(permission: string): boolean {
    let isAuthorized: boolean = false;
    this.userFacade.hasPermission(permission).pipe(map((permission) => !!permission)
      )
      .subscribe(hasPermission => {
        isAuthorized = hasPermission;
      });

    return isAuthorized;
  }
}
