<form class="reset-password-form" [formGroup]="resetPasswordForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n="@@users.reset-password.reset-password-form">Reset Password Form</mat-card-title>
    </mat-card-header>
    <mat-card-content class="reset-password-fields">
      <mat-form-field appearance="outline" class="password-field">
        <mat-label i18n="@@users.reset-password.new-password">New Password</mat-label>
        <input
          id="newPassword"
          type="{{ hideNewPassword ? 'password' : 'text' }}"
          formControlName="newPassword"
          matInput
          (input)="updatePasswordStrength()"
        />
        <mat-error *ngIf="resetPasswordForm.get('newPassword')?.hasError('required')">
          New Password is required
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.get('newPassword')?.hasError('minlength')">
          New Password must be at least 8 characters long
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.get('newPassword')?.hasError('pattern')">
          New Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character
        </mat-error>
        <button
          mat-icon-button
          matSuffix
          (click)="hideNewPassword = !hideNewPassword"
        >
          <mat-icon>
            {{ hideNewPassword ? "visibility_off" : "visibility" }}
          </mat-icon>
        </button>
      </mat-form-field>

      <div class="spacer"></div>

      <mat-form-field appearance="outline">
        <mat-label i18n="@@users.reset-password.confirm-password">Confirm Password</mat-label>
        <input
          id="confirmPassword"
          type="password"
          formControlName="confirmPassword"
          matInput
        />
        <mat-error *ngIf="resetPasswordForm.hasError('notMatched', 'confirmPassword')">
          Passwords do not match
        </mat-error>
      </mat-form-field>
      <div *ngIf="passwordStrength" class="password-strength" id="password-strength">
        Password Strength: {{ passwordStrength }}
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button
        class="submit"
        mat-flat-button
        color="primary"
        (click)="resetPassword()"
       i18n="@@users.reset-password.reset-password">
        Reset Password
      </button>
    </mat-card-actions>
  </mat-card>
</form>
