<mat-card class="graph-car" id="reportedCommentCard">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title
        class="bold"
        i18n="
          @@documents.reported-comments-percentage.reported-comments-percentage"
        >Reported Comments Percentage</mat-card-title
      >
      <div class="filter-btn">
        <button
          mat-button
          (click)="drawer.toggle()"
        >
          <mat-icon>tune</mat-icon>
          <span i18n="@@documents.reported-comments-percentage.filters"> Filters </span>
        </button>
        <button
          mat-stroked-button
          color="warn"
          (click)="resetFilter()"
          i18n="@@documents.reported-comments-percentage.reset-filters"
        >
          Reset filters
        </button>
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <mat-drawer-container id="drawer">
      <mat-drawer #drawer>
        <form [formGroup]="filterForm" id="formGroup">
          <button
            mat-flat-button
            color="primary"
            [style.margin-top.px]="20"
            (click)="openSingleOffice()"
            i18n="@@documents.reported-comments-percentage.select-office"
          >
            Select office
          </button>
          <mat-chip-grid aria-label="Office selection" #officeChipGrid>
            <mat-chip-row
              *ngIf="selectedFlatOfficeNode"
              (removed)="removeSelectedOffice()"
            >
              {{ selectedFlatOfficeNode.name }}
              <button
                matChipRemove
                [attr.aria-label]="'remove ' + selectedFlatOfficeNode.id"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>

          <mat-form-field appearance="outline" [style.margin-top.px]="20">
            <mat-label
              i18n="
                @@documents.reported-comments-percentage.select-workflow-type"
              >Select Workflow Type</mat-label
            >
            <mat-select
              placeholder="Select workflow type"
              formControlName="workflowType"
              (selectionChange)="onWorkflowTypeChange()"
              hideSingleSelectionIndicator="true"
              [disabled]="!selectedFlatOfficeNode"
            >
              <mat-select-trigger>
                {{ filterForm.controls["workflowType"].value?.name || "" }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let type of workflowTypes; let i = index"
                [value]="type"
              >
                <mat-radio-button
                  [checked]="filterForm.controls['workflowType'].value === type"
                  (change)="onWorkflowTypeChange()"
                >
                  {{ type?.name }}
                </mat-radio-button>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-drawer>
      <mat-drawer-content id="reportedComment">
        <h1 class="mat-h1">{{ reportedCommentPercentage }} %</h1>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-card-content>
</mat-card>
