<div
  class="archived-workspace-card"
  (click)="navigateToWorkspaceDetail(archivedWorkspace)"
>
  <ng-container>
    <div>
      <mat-card class="workspace-card">
        <mat-card-header class="card-header">
          <div class="workspace-header">
            <div class="flex-column">
              <mat-card-title class="card-title">
                {{ archivedWorkspaceName }}
              </mat-card-title>
              <mat-card-subtitle class="card-title archived-date">
                <span i18n="@@offices.archived-workspace-card.date-archived"> Date Archived : </span> {{ archivedWorkspaceDate }}
              </mat-card-subtitle>
            </div>
            <div class="maticon">
              <button mat-icon-button (click)="deleteWorkspace($event,archivedWorkspace?.id)">
                <mat-icon
                  aria-hidden="false"
                  aria-label="delete workspace icon"
                  fontIcon="delete"
                ></mat-icon>
              </button>
            </div>
          </div>
        </mat-card-header>

          <mat-card-actions>
            <button
              (click)="navigateToWorkspaceDetail(archivedWorkspace)"
              mat-stroked-button
              color="primary"
              i18n="@@offices.archived-workspace-card.workspace-detail">
              Workspace Detail
            </button>
          </mat-card-actions>

      </mat-card>
    </div>
  </ng-container>
</div>
