<div class="main">
  <h1 class="mat-h1" i18n="@@documents.workflow-comment-policy.settings">Settings</h1>
  <div>
    <mat-accordion>
      <mat-expansion-panel *appHasPermission="hasCreateWorkflowCommentPolicyPermission()">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@documents.workflow-comment-policy.set-comment-policy">Set Comment Policy</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <button mat-button (click)="openCommentPolicyDialog()">
            <mat-icon color="primary">add</mat-icon>
            <span i18n="@@documents.workflow-comment-policy.set-comment-policy"> Set Comment Policy </span>
          </button>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@documents.workflow-comment-policy.view-comment-policy">View Comment Policy</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <p>
            {{ workflowCommentPolicy?.description }}
          </p>
        <div *ngIf="workflowCommentPolicy">
          <button 
           mat-button 
           (click)="openDeleteCommentPolicyDialog()" 
           *appHasPermission="hasDeleteWorkflowCommentPolicyPermission()"
           [disabled]="!workflowCommentPolicy?.id"
           >
            <mat-icon color="accent">delete</mat-icon>
            <span i18n="@@documents.workflow-comment-policy.delete-comment-policy"> Delete Comment Policy </span>
          </button>
        </div> 

        <div *ngIf="!workflowCommentPolicy">
          <p i18n="@@documents.workflow-comment-policy.no-workflow-comment-policy-set">No Workflow comment policy set</p>
        </div>

        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
