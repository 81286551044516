<div class="files-container" *appHasPermission="hasGetFilesInFolderPermission()">
  <div class="flex" *ngIf="selectedFilesIds.length != 0; else header">
   <span i18n="@@offices.archived-workspace-files.selected"> Selected </span> {{ selectedFilesIds.length }}
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <ng-template #header>
    <div class="title"></div>
  </ng-template>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="moveFiles()">
      <mat-icon color="primary">drive_file_move</mat-icon>
      <span i18n="@@offices.archived-workspace-files.move"> Move </span>
    </button>
  </mat-menu>

  <mat-paginator
    [length]="(files$ | async)?.totalCount"
    [pageSize]="(pagination$ | async)?.pageSize"
    [pageSizeOptions]="pageSizes"
    [pageIndex]="((pagination$ | async)?.pageNumber ?? 1) - 1"
    aria-label="Select page"
    (page)="updatePaginationState($event)"
  >
  </mat-paginator>
  <div class="grid-wrapper">
    <angular-slickgrid
      [ariaMultiSelectable]=""
      gridId="shared-from-files"
      [columnDefinitions]="columnDefinitions"
      [gridOptions]="gridOptions"
      [dataset]="files"
      (onAngularGridCreated)="angularGridReady($event)"
      (onGridStateChanged)="gridStateChanged($event)"
    ></angular-slickgrid>
  </div>

  <div class="media-container">
    <div class="video-container" id="video-container">
      <button class="close-button" id="close-button" (click)="closePlayer()">
        <mat-icon class="close-icon">close-button</mat-icon>
      </button>
      <video #videoElement controlsList="nodownload" i18n="@@offices.archived-workspace-files.your-browser-does-not-support-the-video-tag">
        Your browser does not support the video tag
      </video>
    </div>
    <div class="audio-container">
      <audio #audioElement controlsList="nodownload"></audio>
    </div>
  </div>
</div>
