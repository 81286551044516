import { ImisFile } from './../models/imis-file';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { filter, take, tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { FileService } from '../services/file.service';
import { removeItem } from '@ngxs/store/operators';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import {
  errorStyle,
  successStyle,
} from 'src/app/core/services/operation-status/status-style-names';
import {
  GetFilesBySearchTerm,
  GetSortedFiles,
  MoveFiles,
  RenameFile,
  SelectFilesIds,
  CopyFile,
  DeleteFiles,
  SelectFile,
  SelectFiles,
  ShareFilesToOffices,
  ShareFilesToRoles,
  ShareFilesToUsers,
  GetSharedFromFiles,
  GetSharedToFiles,
  DownloadFile,
  CreateFiles,
  PreviewFile,
  FetchAudio,
  TrashFiles,
  GetTrashedFiles,
  RestoreFile,
  GetFileProperty,
  GetArchivedFiles,
  SetArchivedFiles,
  MoveArchivedFiles,
  SetFileSharingMode,
  UnshareFile,
  GetFileDetial,
  SetFilesNull,
  FilesAddedToBookmark,
  FilesRemovedFromBookmark,
} from './file.actions';
import { ImisFileUploadReport } from '../models/file-upload-report.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileProperty } from '../models/file-property.model';
import { UnsharedFile } from '../models/file.model';

export interface FileStateModel {
  files: PaginatedList<ImisFile>;
  trash: PaginatedList<ImisFile>;
  selectedFilesIds: string[];
  selectedFile: ImisFile | null;
  selectedFileId: string | null;
  selectedFiles: ImisFile[];
  audioBlob: Blob | null;
  fileProperty?: FileProperty;
  archivedFiles?: PaginatedList<ImisFile>;
  isSharingFile?: boolean;
}

const FILE_STATE_TOKEN = new StateToken<FileStateModel>('fileState');
const defaults = {
  files: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  trash: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  selectedFilesIds: [],
  selectedFile: null,
  selectedFileId: null,
  selectedFiles: [],
  audioBlob: null,
};

@State<FileStateModel>({
  name: FILE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class FileState {
  constructor(
    private fileService: FileService,
    private operationStatus: OperationStatusService,
    private store: Store,
    private snackBar: MatSnackBar,
  ) {}

  @Action(GetSortedFiles)
  getStorageInfo(
    { patchState }: StateContext<FileStateModel>,
    getSortedFiles: GetSortedFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getFiles(getSortedFiles).pipe(
      tap((files) => {
        patchState({ files });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SelectFilesIds)
  selectFilesIds(
    { patchState }: StateContext<FileStateModel>,
    { selectedFilesIds }: SelectFilesIds,
  ) {
    patchState({ selectedFilesIds: selectedFilesIds });
  }

  @Action(SelectFile)
  selectFile(
    { patchState }: StateContext<FileStateModel>,
    { selectedFile }: SelectFile,
  ) {
    this.operationStatus.displayStatus(
      $localize`:@@files.file.file-copied-part-one: File` + `${selectedFile.name}` +  $localize`:@@files.file.file-copied-part-two: is copied.`,
      successStyle,
    );
    patchState({ selectedFile: selectedFile });
  }

  @Action(MoveFiles)
  moveFiles(
    { setState }: StateContext<FileStateModel>,
    { destinationFolderId, selectedFilesIds }: MoveFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService
      .moveFiles(destinationFolderId, selectedFilesIds)
      .pipe(
        tap((movedFiles) => {
          const filteredItems = movedFiles.filter(
            (f: { folderId: string }) => f.folderId === destinationFolderId,
          );
          if (filteredItems.length > 0) {
            setState(
              patch({
                files: patch({
                  items: (currentItems) =>
                    currentItems.filter(
                      (f) => !selectedFilesIds.includes(f.id),
                    ),
                }),
              }),
            );
            this.operationStatus.displayStatus(
              $localize`:@@files.file.file-moved: Files moved successfully`,
              successStyle,
            );
          } else {
            this.operationStatus.displayStatus(
              $localize`:@@files.file.no-file-moved: No files were moved`,
              errorStyle,
            );
          }
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetFilesBySearchTerm)
  GetFilesBySearchTerm(
    { setState }: StateContext<FileStateModel>,
    { searchTerm }: GetFilesBySearchTerm,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getFilesBySearchTerm(searchTerm).pipe(
      tap((files) => {
        setState(
          patch({
            files: patch({
              items: files,
              totalCount: files.length,
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(CopyFile)
  copyFile(
    { patchState, setState }: StateContext<FileStateModel>,
    { fileId, folderId }: CopyFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.copyFile({ fileId, folderId }).pipe(
      tap((file) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-copied-successfully: File copied successfully`,
          successStyle,
        );
        patchState({
          selectedFile: null,
        });

        setState(
          patch({
            files: patch({
              items: insertItem(file),
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(RenameFile)
  RenameFile(
    { setState }: StateContext<FileStateModel>,
    { fileId, fileName }: RenameFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.renameFile(fileId, fileName).pipe(
      tap((file: ImisFile) => {
        setState(
          patch({
            files: patch({
              items: updateItem((fs) => fs.id == file.id, file),
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DeleteFiles)
  deleteFiles(
    { setState, patchState }: StateContext<FileStateModel>,
    { fileIds }: DeleteFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.deleteFiles(fileIds).pipe(
      tap(() => {
        if (!Array.isArray(fileIds)) {
          setState(
            patch({
              files: patch({
                items: removeItem((f) => f.id == fileIds),
              }),
            }),
          );
        } else {
          setState(
            patch({
              files: patch({
                items: this.store
                  .selectSnapshot(FileState)
                  .files.items.filter(
                    (f: any) => !fileIds.some((id) => id == f.id),
                  ),
              }),
              trash: patch({
                items: removeItem((f) => f.id == fileIds[0]),
              }),
            }),
          );
        }
        this.store.dispatch(new SetProgressOff());
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-deleted-successfully: Files deleted successfully`,
          successStyle,
        );
      }),
    );
  }

  @Action(SelectFiles)
  selectFiles(
    { patchState }: StateContext<FileStateModel>,
    { selectedFiles }: SelectFiles,
  ) {
    patchState({ selectedFiles: selectedFiles });
  }

  @Action(ShareFilesToOffices)
  shareFilesToOffices(
    { setState }: StateContext<FileStateModel>,
    { shareFileToOfficeRequests }: ShareFilesToOffices,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.shareFileToOffice(shareFileToOfficeRequests).pipe(
      tap((_) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-has-been-shared-to-offices: files has been shared to the selcted offices`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ShareFilesToRoles)
  shareFilesToRoles(
    { setState }: StateContext<FileStateModel>,
    { shareFileToRolesRequests }: ShareFilesToRoles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.shareFileToRole(shareFileToRolesRequests).pipe(
      tap((_) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-has-been-shared-to-roles: files has been shared to the selcted roles`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(ShareFilesToUsers)
  shareFilesToUsers(
    { setState }: StateContext<FileStateModel>,
    { shareFileToUsersRequests }: ShareFilesToUsers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.shareFileToUser(shareFileToUsersRequests).pipe(
      tap((_) => {
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-has-been-shared-to-users: files has been shared to the selcted users`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UnshareFile)
  UnshareFile(
    { setState }: StateContext<FileStateModel>,
    { unsharedFile }: UnshareFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.unshareFile(unsharedFile).pipe(
      tap((_) => {
        setState(
          patch({
            files: patch({
              items: removeItem((fs) => fs.id == unsharedFile.fileId),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-unshared-successfully: File Unshared Successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetSharedFromFiles)
  getSharedFromFiles(
    { patchState }: StateContext<FileStateModel>,
    getSharedFromFiles: GetSharedFromFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getSharedFromFiles(getSharedFromFiles).pipe(
      tap((files) => {
        patchState({ files });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetSharedToFiles)
  getSharedToFiles(
    { patchState }: StateContext<FileStateModel>,
    getSharedToFiles: GetSharedToFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getSharedToFiles(getSharedToFiles).pipe(
      tap((files) => {
        patchState({ files });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(DownloadFile)
  downloadFile(
    { setState }: StateContext<FileStateModel>,
    { fileId, fileName }: DownloadFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService
      .downloadFile(fileId)
      .pipe(
        filter((file) => file !== null),
        take(1),
      )
      .subscribe(async (response: any) => {
        if (response) {
          const blob = new Blob([response], {
            type: 'application/octate-stream',
          });
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          link.click();
          this.store.dispatch(new SetProgressOff());
          this.store.dispatch(new GetFileDetial(fileId))
        }
      });
  }

  @Action(CreateFiles)
  createFiles(
    { setState, getState }: StateContext<FileStateModel>,
    { newFiles, folderId }: CreateFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    const state = getState();
    const oldFiles = state.files.items;

    this.fileService
      .addFile(newFiles, folderId)
      .pipe(
        tap((filesReport: ImisFileUploadReport[]) => {
          const errorMessage = filesReport
            .filter((element) => !element.success && element.error)
            .map((element) => element.error)
            .join(',  ');

          let filesList = filesReport
            .filter((element) => element.success && element.file != null)
            .map((element) => element.file);

          let nonNullFilesList: ImisFile[] = filesList
            .filter((file) => file !== null)
            .map((file) => file as ImisFile);

          if (errorMessage != '') {
            this.snackBar.open(errorMessage, 'OK', {
              duration: 10000,
              panelClass: ['warning-snackbar'],
            });
          }

          setState(
            patch({
              files: patch({
                items: [...nonNullFilesList, ...oldFiles],
              }),
            }),
          );
          this.store.dispatch(new SetProgressOff());
        }),
      )
      .subscribe(() => {});
  }

  @Action(PreviewFile)
  previewFile(
    { setState }: StateContext<FileStateModel>,
    { fileId, fileName }: PreviewFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    var fileType = 'pdf';
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (
      fileExtension === 'jpg' ||
      fileExtension === 'jpeg' ||
      fileExtension === 'png' ||
      fileExtension === 'gif'
    ) {
      fileType = 'image';
    }
    var contentType = 'application/pdf';
    if (fileType === 'image') {
      contentType = `image/${fileExtension}`;
    }
    return this.fileService.previewFile(fileId).pipe(
      tap((response) => {
        if (response) {
          const blob = new Blob([response], { type: contentType });
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, '_blank');
          this.store.dispatch(new SetProgressOff());
          this.store.dispatch(new GetFileDetial(fileId))
        }
      }),
    );
  }

  @Action(FetchAudio)
  fetchAudio(
    { patchState }: StateContext<FileStateModel>,
    { fileId }: FetchAudio,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.downloadFile(fileId).pipe(
      tap((response) => {
        if (response) {
          var blob = new Blob([response], {
            type: 'application/pdf',
          });

          const fileUrl = URL.createObjectURL(blob);
          patchState({
            audioBlob: blob,
          });
          this.store.dispatch(new SetProgressOff());
          this.store.dispatch(new GetFileDetial(fileId))
        }
      }),
    );
  }

  @Action(GetTrashedFiles)
  getTrashFiles(
    { setState, patchState }: StateContext<FileStateModel>,
    { pageIndex, pageSize }: GetTrashedFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getTrashedFiles(pageIndex, pageSize).pipe(
      tap((trash: PaginatedList<ImisFile>) => {
        patchState({ trash });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(TrashFiles)
  trashFiles(
    { setState, patchState }: StateContext<FileStateModel>,
    { fileIds }: TrashFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.trashFiles(fileIds).pipe(
      tap(() => {
        if (!Array.isArray(fileIds)) {
          setState(
            patch({
              files: patch({
                items: removeItem((f) => f.id == fileIds),
              }),
            }),
          );
        } else {
          setState(
            patch({
              files: patch({
                items: this.store
                  .selectSnapshot(FileState)
                  .files.items.filter(
                    (f: any) => !fileIds.some((id: any) => id == f.id),
                  ),
              }),
            }),
          );
        }

        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-trashed-successfully: Files trashed successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(RestoreFile)
  restoreFile(
    { setState, patchState }: StateContext<FileStateModel>,
    { fileId }: RestoreFile,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.restoreFile(fileId).pipe(
      tap(() => {
        setState(
          patch({
            trash: patch({
              items: removeItem((f) => f.id == fileId),
            }),
          }),
        );

        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-restored-successfully: Files restored successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetFileProperty)
  getFileProperty(
    { setState }: StateContext<FileStateModel>,
    { fileId }: GetFileProperty,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getFileProperty(fileId).pipe(
      tap((fileProperty: FileProperty) => {
        setState(
          patch({
            fileProperty: fileProperty,
          }),
        );
        this.store.dispatch(new SetProgressOff());
        this.store.dispatch(new GetFileDetial(fileId))
      }),
    );
  }

  @Action(GetArchivedFiles)
  getArchivedFiles(
    { patchState }: StateContext<FileStateModel>,
    getArchivedFiles: GetArchivedFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.getFiles(getArchivedFiles).pipe(
      tap((files) => {
        patchState({ archivedFiles: files });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetArchivedFiles)
  setArchivedFiles(
    { setState, patchState }: StateContext<FileStateModel>,
    { archivedFiles }: SetArchivedFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    setState(patchState({ archivedFiles: archivedFiles }));
    this.store.dispatch(new SetProgressOff());
  }

  @Action(MoveArchivedFiles)
  moveArchivedFiles(
    { setState, getState }: StateContext<FileStateModel>,
    { filesToMove }: MoveArchivedFiles,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.fileService.moveArchivedFiles(filesToMove).pipe(
      tap(() => {
        setState(
          patch({
            archivedFiles: patch({
              items: getState().archivedFiles?.items.filter(
                (file) => !filesToMove.some((dto) => dto.fileId === file.id),
              ),
            }),
          }),
        );
        this.operationStatus.displayStatus(
          $localize`:@@files.file.file-moved-successfully: Files moved successfully`,
          successStyle,
        );

        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetFileSharingMode)
  setFileSharingMode(
    { setState }: StateContext<FileStateModel>,
    { isSharingFile }: SetFileSharingMode,
  ) {
    this.store.dispatch(new SetProgressOn());
    setState(
      patch({
        isSharingFile: isSharingFile,
      }),
    );
    this.store.dispatch(new SetProgressOff());
  }

  @Action(GetFileDetial)
  increamentFileDownloadCount(
    { setState,getState }: StateContext<FileStateModel>,
    { fileId }: GetFileDetial,
  ) {

    this.store.dispatch(new SetProgressOn());
    return this.fileService.getFileDetial(fileId).pipe(
      tap((ImisFile: ImisFile) => {
        setState(
          patch({
            files: patch({
              items: updateItem((fs) => fs.id == ImisFile.id, ImisFile),
            }),
          }),
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(SetFilesNull)
  SetFilesNull({patchState}:StateContext<FileStateModel>,){
    patchState({ files: defaults.files });
  }

  @Action(FilesAddedToBookmark)
  filesAddedToBookmark(
    { setState }: StateContext<FileStateModel>,
    { addedBookmarks }: FilesAddedToBookmark,
  ) {
    const oldFiles = this.store.selectSnapshot(FileState).files.items;
    const newFiles = oldFiles.map((file:ImisFile) => {
      let imisFileBookmarkId = addedBookmarks.find((b) => b.imisFileId == file.id)?.id;
      if(imisFileBookmarkId){
        file.imisFileBookmarkId = imisFileBookmarkId;
      }
      return file;
    });
    setState(
      patch({
        files: patch({
          items: [...newFiles],
        }),
      }),
    );
  }

  @Action(FilesRemovedFromBookmark)
  filesRemovedFromBookmark(
    { setState }: StateContext<FileStateModel>,
    { removedBookmarks }: FilesRemovedFromBookmark,
  ) {
    const oldFiles = this.store.selectSnapshot(FileState).files.items;
    const newFiles = oldFiles.map((file:ImisFile) => {
      let imisFileBookmarkId = removedBookmarks.find((b) => b.imisFileId == file.id)?.id;
      if(imisFileBookmarkId){
        file.imisFileBookmarkId = null;
      }
      return file;
    });
    setState(
      patch({
        files: patch({
          items: [...newFiles],
        }),
      }),
    );
  }

}
