<mat-dialog-content
  mat-dialog-content
  *ngIf="stageInstanceSchedules.controls.length > 0"
  class="form-container"
>
  <div>
    <h3>
      {{ title }} for {{ processInstanceDetail?.title?.toUpperCase() }} (<span
        class="process-schedule"
        >{{ getStartDate }} - {{ getEndDate }}</span
      >)
    </h3>
  </div>

  <form [formGroup]="deadlineForm" *ngIf="!isView">
    <div formArrayName="stageInstanceSchedules">
      <div
        class="date-picker"
        *ngFor="
          let stageInstanceSchedule of stageInstanceSchedules.controls;
          let i = index
        "
        [formGroupName]="i"
      >
        <div class="stage-instance-name">
          {{ i + 1 }}. {{ stageInstanceSchedule.value.stageInstanceName }}
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label i18n="@@researches.research-deadline.enter-a-date-range"
            >Enter a date range</mat-label
          >
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="startDate"
              [placeholder]="placeholderToggleLabel.startDate"
            />
            <input
              matEndDate
              formControlName="endDate"
              [placeholder]="placeholderToggleLabel.endDate"
            />
          </mat-date-range-input>
          <mat-hint i18n="@@researches.research-deadline.mm-dd-yyyy-mm-dd-yyyy"
            >MM/DD/YYYY – MM/DD/YYYY</mat-hint
          >
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-error
          *ngIf="deadlineForm.errors?.['invalidDateRange']"
          i18n="
            @@researches.research-deadline.please-make-sure-the-schedule-is-not-overlapped"
        >
          Please make sure the schedule is not overlapped.
        </mat-error>
      </div>
    </div>

    <div mat-dialog-actions align="end">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!deadlineForm.valid"
        (click)="save()"
        i18n="@@researches.research-deadline.submit"
      >
        Submit
      </button>
      <button
        mat-flat-button
        color="warn"
        mat-dialog-close
        i18n="@@researches.research-deadline.cancel"
      >
        Cancel
      </button>
    </div>
  </form>

  <div *ngIf="isView">
    <div
      class="date-picker"
      *ngFor="
        let stageInstanceSchedule of stageInstanceSchedules.controls;
        let i = index
      "
    >
      <div class="stage-instance-name">
        {{ i + 1 }}. {{ stageInstanceSchedule.value.stageInstanceName }}
      </div>
      <p>
        {{ getReadableDate(stageInstanceSchedule.value.startDate) }} -
        {{ getReadableDate(stageInstanceSchedule.value.endDate) }}
      </p>
    </div>
    <button
      mat-flat-button
      color="accent"
      mat-dialog-close
      i18n="@@researches.research-deadline.close"
    >
      Close
    </button>
  </div>
</mat-dialog-content>
