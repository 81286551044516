<div class="main-containier">
  <form class="response-form" [formGroup]="responseForm">
    <h1 *ngIf="!selectedResponse">Create Response</h1>
    <h1 *ngIf="selectedResponse">Update response</h1>

    <div class="response-form">
      <mat-form-field class="form-fields" appearance="outline">
        <mat-label>Subject</mat-label>
        <input
          id="subject"
          formControlName="subject"
          type="text"
          matInput
          class="full-width-input"
          [placeholder]="placeholderFieldLabel.responseSubjectField"
          [attr.data-test-id]="'documents.external-document-response.external-document-response-form.subject-input'"
        />
        <mat-error *ngIf="responseForm.get('subject')?.hasError('required')">
          Please provide a subject for the response
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-fields" appearance="outline">
        <mat-label>Destination </mat-label>
        <input
          id="destinationOffice"
          formControlName="destinationOffice"
          type="text"
          matInput
          class="full-width-input"
          [placeholder]="placeholderFieldLabel.destinationField"
          [attr.data-test-id]="'documents.external-document-response.external-document-response-form.destination-input'"
        />
        <mat-error
          *ngIf="responseForm.get('destinationOffice')?.hasError('required')"
        >
          Please provide a intended party for the response
        </mat-error>
      </mat-form-field>
    </div>
    <div class="editor--wrapper">
      <div id="editor"></div>
    </div>

    <div class="w-100">
      <div
        class="drop-zone flex-center"
        [class.dragover]="isDragOver"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <input
          id="file-upload"
          type="file"
          (change)="onFileSelect($event)"
          multiple
        />
        <ng-container *ngIf="!selectedResponse?.files">
          <p>
            <label for="file-upload" class="custom-file-upload">
            <span class="file-upload-label">
              <mat-icon class="upload-icon">cloud_upload</mat-icon>
              <span> Upload Response Document </span>
            </span>
            </label>
          </p>
        </ng-container>
        <div>
          <ng-container
            class="overflow height-m"
            *ngFor="let file of files; let i = index"
          >
            <div class="file-preview">
              <mat-icon>insert_drive_file</mat-icon>
              <p>{{ file.name }}</p>
              <button class="delete-button" (click)="removeFile(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
        <button mat-flat-button color="primary" (click)="selectFile()">
          Select Files
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        [disabled]="buttonDisabled()"
        (click)="handleResponseAction(false)"
        class="mt-15 ml-15"
      >
        Save
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="buttonDisabled()"
        (click)="openOfficeSelector()"
        class="mt-15 ml-15"
        [attr.data-test-id]="'documents.external-document-response.external-document-response-form.send-button'"
      >
        Send
      </button>
    </div>
  </form>
</div>
