<div>
  <mat-form-field class="template-select" appearance="outline">
    <mat-label i18n="@@documents.memo-list.filter-memo" >Filter Memos</mat-label>
    <mat-select
    [formControl]="memoFilterControl"
      [placeholder]= "dropDownButtonToogleLabel.filterMemoStatus"
      (selectionChange)="onFilterChange($event)"
      hideSingleSelectionIndicator="true"
    >
      <mat-select-trigger>
        {{ MemoFilter[selectedFilter] || "" }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let type of memoFilters; let i = index"
        [value]="type"
      >
        <mat-checkbox
          [checked]="isFilterChecked(type)"
          (change)="onCheckboxChange($event,type)"
        ></mat-checkbox>
        {{ MemoFilter[type.toString()] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<form [formGroup]="memoSearchForm">
  <div class="flex margin-top margin-x">
    <div class="column">
      <button
        mat-raised-button
        color="primary"
        align
        (click)="openMultipleOffice()"
       i18n="@@documents.memo-list.select-office">
        Select office
      </button>
      <mat-chip-row
        class="chip"
        *ngFor="let item of selectedOffices"
        (removed)="removeSelectedFlatOfficeNodeFromNodes(item)"
        [editable]="false"
      >
        {{ item.name }}
        <button matChipRemove [attr.aria-label]="'remove ' + item.name">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>

    <div>
      <mat-form-field appearance="outline" class="process-selector">
        <mat-label i18n="@@documents.memo-list.choose-role">choose role</mat-label>
        <mat-select
          formControlName="roles"
          multiple
          (selectionChange)="onRoleSelect()"
        >
          <mat-option *ngFor="let role of roles" [value]="role">{{
            role.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="template-select" appearance="outline">
      <mat-label i18n="@@documents.memo-list.filter-sender">Filter Sender</mat-label>
      <mat-select
        [placeholder]="dropDownButtonToogleLabel.searchMemoSender"
        formControlName="user"
        (selectionChange)="onSenderChange()"
        hideSingleSelectionIndicator="true"
      >
        <mat-select-trigger>
          {{ memoSearchForm.controls["user"].value?.firstName || "" }}
        </mat-select-trigger>
        <mat-option *ngFor="let user of users; let i = index" [value]="user">
          <mat-checkbox
            [checked]="memoSearchForm.controls['user'].value === user"
          ></mat-checkbox>
          {{ user.firstName + " " + user.middleName + " " + user.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
<div class="flex justify-between margin-top margin-x">
  <div *appHasPermission="hasSearchMemosByNameAndSentFromPermission()">
    <form [formGroup]="memoSearchForm">
      <mat-form-field appearance="outline" class="search-term-input">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [placeholder]="placeholderToggleLabel.searchMemo"
          formControlName="searchTerm"
          (input)="onSearchTermChange()"
        />
      </mat-form-field>
    </form>
  </div>
  <form [formGroup]="memoSearchForm">
    <mat-form-field appearance="outline" class="date-filter">
      <mat-label i18n="@@documents.memo-list.start-and-end-date">Start and End Date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="datePlaceholderToggleLabel.startDate"
          formControlName="startDate"
        />
        <input
          matEndDate
          placeholder="datePlaceholderToggleLabel.endDate"
          formControlName="endDate"
        />
      </mat-date-range-input>
      <mat-hint i18n="@@documents.memo-list.mm-dd-yyyy-mm-dd-yyyy">MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
  
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="createMemo()"
    *appHasPermission="hasCreateMemosPermission()"
  >
    <mat-icon>add</mat-icon> <span i18n="@@documents.memo-list.create-a-memo"> Create a Memo </span>
  </button>
</div>

<div class="main-table mat-elevation-z2">
  <mat-paginator
    [length]="handlePaginationLength()"
    [pageSize]="pageSize"
    [pageIndex]="pageNumber - 1"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedMemos($event)"
  >
  </mat-paginator>
  <mat-table [dataSource]="memoDataSource" class="table">
    <ng-container matColumnDef="Title">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.memo-list.title">Title</mat-header-cell>
      <mat-cell *matCellDef="let memo">
        {{ memo.parentMemoId ? this.replyPrefix + memo.title : memo.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Status" *ngIf="selectedFilter !== 'CC'">
      <mat-header-cell *matHeaderCellDef
         i18n="@@documents.memo-list.memo-status">Memo Status</mat-header-cell>
      <mat-cell *matCellDef="let memo">{{ memo?.memoStatusForDisplay?.memoStatusForDisplay }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Sent From" *ngIf="selectedFilter !== 'Sent'">
      <mat-header-cell *matHeaderCellDef
         i18n="@@documents.memo-list.sent-from">Sent From</mat-header-cell>
      <mat-cell *matCellDef="let memo">{{ memo.sentFrom }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Created At">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.memo-list.created-at">Created At</mat-header-cell>
      <mat-cell *matCellDef="let memo">
        {{ memo.lastModified | date:'short'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Actions" 
    *ngIf="selectedFilter === 'Sent' || selectedFilter === 'Draft' || selectedFilter === 'Sent'">
      <mat-header-cell *matHeaderCellDef
         i18n="@@documents.memo-list.actions">Actions</mat-header-cell
      >
      <mat-cell *matCellDef="let template">
        <button
         *appHasPermission="hasCrudPermission()"
         [disabled]="selectedFilter !== 'Sent' && selectedFilter !== 'Draft'"
          mat-icon-button
          [matMenuTriggerFor]="template.status === 'Draft' ? menu1: null"
          (click)="setSelectedMemo($event, template)"
        >
          <mat-icon *ngIf="template.status === 'Draft'">more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableHeader()"></mat-header-row>
    <mat-row
      class="table-row"
      *matRowDef="let template; columns: tableHeader()"
      (click)="onRowClick($event, template)"
    ></mat-row>
  </mat-table>

  <mat-menu #menu1="matMenu">
    <button mat-menu-item (click)="editMemo()" *appHasPermission="hasUpdateMemosPermission()" i18n="@@documents.memo-list.edit-memo">Edit Memo</button>
    <button mat-menu-item (click)="sendMemo()" i18n="@@documents.memo-list.send-memo">Send Memo</button>
    <button mat-menu-item (click)="deleteMemo()" *appHasPermission="hasDeleteMemosPermission()" i18n="@@documents.memo-list.delete-memo">Delete Memo</button>
  </mat-menu>
</div>
