<mat-sidenav-container class="container">
  <mat-sidenav-content>
    <div class="template-detail">
      <div class="row">
        <h1>{{ selectedFormDetail?.name }}</h1>
      </div>
      <p>
        {{ selectedFormDetail?.description }}
      </p>

      <div class="template-head">
        <h2 *ngIf="(selectedFormDetail?.templateFields)!.length > 0"
          i18n="@@documents.document-form-layout.fields">
          Fields
        </h2>
        <div *ngIf="isDraftForm" class="save">
          <button
            class="template-btn"
            mat-raised-button
            color="primary"
            (click)="save()"
            i18n="@@documents.document-form-layout.save"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="hasFields">
      <div
        class="drag-drop-container"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <mat-card
          *ngFor="let field of selectedFormDetail?.templateFields"
          class="field-card draggable-item"
          cdkDrag
        >
          <div class="flex">
            <mat-card-header class="card-header">
              <div class="field-header">
                <div class="grid-left">
                  <mat-card-title class="card-title">
                    {{ field.name }}
                  </mat-card-title>
                  <div>
                    <span i18n="@@documents.document-form-layout.fields-type">Field Type:</span> <span class="chip">{{ field.dataType }}</span>
                  </div>
                </div>
              </div>
            </mat-card-header>
            <div class="content-container">
              <mat-card-content>
                <span i18n="@@documents.document-form-layout.place-holder">Place holder:</span> <span> {{ field.placeholder }}</span>

                <p class="text-gray">
                  <span class="description" i18n="@@documents.document-form-layout.description">Description:</span>
                  {{ field.description }}
                </p>
              </mat-card-content>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
