import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Process, ProcessCategoryForDisplay } from '../models/process.model';
import { Observable } from 'rxjs';
import { PROCESSES_URL } from 'src/app/core/constants/api-endpoints';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';

@Injectable({
  providedIn: 'root',
})
export class ProcessService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  createProcess(process: Process): Observable<Process> {
    return this.http.post<Process>(
      `${PROCESSES_URL}`,
      process,
      this.httpOptions,
    );
  }
  getPaginatedProcesses(
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Process>> {
    let url = PROCESSES_URL;
    if (
      pageNumber !== null &&
      pageNumber !== undefined &&
      pageSize !== null &&
      pageSize !== undefined
    ) {
      url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this.http.get<PaginatedList<Process>>(url);
  }
  deleteProcess(id: string): Observable<any> {
    return this.http.delete<any>(`${PROCESSES_URL}/${id}`, this.httpOptions);
  }

  updateProcess(process: Process): Observable<Process> {
    return this.http.put<Process>(
      `${PROCESSES_URL}`,
      process,
      this.httpOptions,
    );
  }

  searchProcess(
    searchTerm: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<Process>> {
    return this.http.get<PaginatedList<Process>>(
      `${PROCESSES_URL}/search?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${searchTerm}`,
    );
  }
  getProcessCategories(
  ): Observable<ProcessCategoryForDisplay[]> {
    return this.http.get<ProcessCategoryForDisplay[]>(
      `${PROCESSES_URL}/process-category`,
    );
  }
}
