import { AfterViewInit, Component, DestroyRef, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { EChartsOption } from 'echarts';
import { CommunicationTypeInstance } from '../../models/communication-type-Instances.model';
import { takeUntil } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResearchDashboardFacade } from '../../facades/research-dashboard.facade';

interface State {
  communicationTypeInstances: CommunicationTypeInstance[];
}

const initialState: State = {
  communicationTypeInstances: [],
};

@Component({
  selector: 'app-communication-type-instances',
  templateUrl: './communication-type-instances.component.html',
  styleUrls: ['./communication-type-instances.component.scss'],
})
export class CommunicationTypeInstancesComponent
  implements AfterViewInit, OnInit
{
  eChartInstance: any;

  data = Array(10)
    .fill(0)
    .map(() => Math.round(Math.random() * 200));

  chartOption: EChartsOption = {
    xAxis: {
      max: 'dataMax',
    },
    yAxis: {
      type: 'category',
      data: [],
    },
    series: [
      {
        realtimeSort: true,
        name: 'X',
        type: 'bar',
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
        },
      },
    ],
    legend: {
      show: true,
    },
  };

  constructor(
    public state: RxState<State>,
    private destroyRef: DestroyRef,
    private researchDashboardFacade: ResearchDashboardFacade,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'communicationTypeInstances',
      this.researchDashboardFacade.CommunicationTypeInstances$,
    );
  }

  ngOnInit(): void {
    this.researchDashboardFacade.dispatchGetCommunicationTypeInstances();
  }

  ngAfterViewInit(): void {
    this.state
      .select('communicationTypeInstances')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        const total = val
          .map((item) => +item.count)
          .reduce((prev, cur) => prev + cur, 0);

        this.chartOption = {
          ...this.chartOption,
          series: val.map((item) => {
            return {
              realtimeSort: true,
              name: `${item.name} (${((+item.count / total) * 100).toFixed(
                2,
              )}%)`,
              type: 'bar',
              label: {
                show: true,
                position: 'right',
                valueAnimation: true,
              },
              data: [item.count],
            };
          }),
        };
      });
  }

  onChartInit(ec: any) {
    this.eChartInstance = ec;
  }
}
