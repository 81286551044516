import { RxState } from '@rx-angular/state';
import { OfficeFacade } from './../../facades/office.facades';
import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs';

interface DownloadExcelTemplateComponentState {
  templateExcelFileUrl: string | null;
}

const initDownloadExcelTemplateComponentState = {};

@Component({
  selector: 'download-excel-template',
  templateUrl: './download-excel-template.component.html',
  styleUrls: ['./download-excel-template.component.scss'],
  providers: [RxState],
})
export class DownloadExcelTemplateComponent implements OnInit {
  templateExcelFileUrl$ = this.state.select('templateExcelFileUrl');

  constructor(
    private officeFacade: OfficeFacade,
    private state: RxState<DownloadExcelTemplateComponentState>,
  ) {
    this.state.set(initDownloadExcelTemplateComponentState);
    this.state.connect(
      'templateExcelFileUrl',
      officeFacade.tempalateExcelFileUrl$,
    );
  }

  ngOnInit(): void {
    this.officeFacade.dispatchFlushExcelTemplate();
  }

  downloadTemplate() {
    this.officeFacade.dispatchDownloadExcelTemplate();
    this.templateExcelFileUrl$
      .pipe(
        filter((fileUrl) => fileUrl !== null),
        take(1),
      )
      .subscribe((fileUrl) => {
        if (fileUrl) {
          const downloadLink = document.createElement('a');
          downloadLink.href = fileUrl;
          downloadLink.download = 'officeTemplate.xlsx';
          downloadLink.click();
        }
      });
    this.officeFacade.dispatchFlushExcelTemplate();
  }
}
