<mat-card class="graph-card">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title class="bold" i18n="@@documents.workflows-time-graph.workflows-vs-time">Workflows Vs Time</mat-card-title>
      <div class="filter-btn">
        <button mat-button (click)="drawer.toggle()">
          <mat-icon>tune</mat-icon>
          <span i18n="@@documents.workflows-time-graph.filters">Filters</span>
        </button>
        <button mat-stroked-button color="warn" (click)="resetFilter()"
        i18n="@@documents.workflows-time-graph.reset-filters">
          Reset filters
        </button>
      </div>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <mat-drawer-container>
      <mat-drawer #drawer>
        <form [formGroup]="filterForm">
          <button mat-flat-button color="primary" (click)="openSingleOffice()"
          i18n="@@documents.workflows-time-graph.select-office">
            Select office
          </button>
          <mat-chip-grid aria-label="Office selection" #officeChipGrid>
            <mat-chip-row
              *ngIf="selectedFlatOfficeNode"
              (removed)="removeSelectedOffice()"
            >
              {{ selectedFlatOfficeNode.name }}
              <button
                matChipRemove
                [attr.aria-label]="'remove ' + selectedFlatOfficeNode.id"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <mat-form-field appearance="outline">
            <mat-label i18n="@@documents.workflows-time-graph.select-user">Select User</mat-label>
            <mat-select
              placeholder="Select user"
              formControlName="user"
              (selectionChange)="onUserChange()"
              hideSingleSelectionIndicator="true"
            >
              <mat-select-trigger>
                {{ filterForm.controls["user"].value?.firstName || "" }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let user of users; let i = index"
                [value]="user"
              >
                <mat-radio-button
                  [checked]="filterForm.controls['user'].value === user"
                  (change)="onUserChange()"
                >
                  {{
                    user.firstName + " " + user.middleName + " " + user.lastName
                  }}
                </mat-radio-button>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label i18n="@@documents.workflows-time-graph.select-workflow-type">Select Workflow Type</mat-label>
            <mat-select
              placeholder="Select workflow type"
              formControlName="workflowType"
              (selectionChange)="onWorkflowTypeChange()"
              hideSingleSelectionIndicator="true"
            >
              <mat-select-trigger>
                {{ filterForm.controls["workflowType"].value?.name || "" }}
              </mat-select-trigger>
              <mat-option
                *ngFor="let type of workflowTypes; let i = index"
                [value]="type"
              >
                <mat-radio-button
                  [checked]="filterForm.controls['workflowType'].value === type"
                  (change)="onWorkflowTypeChange()"
                >
                  {{ type?.name }}
                </mat-radio-button>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label i18n="@@documents.workflows-time-graph.start-and-end-date">Start and End Date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="startDate"
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="endDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </mat-drawer>
      <mat-drawer-content>
        <div class="chart-container">
          <div echarts [options]="chartOption" class="chart"></div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-card-content>
</mat-card>
