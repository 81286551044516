<h1 class="mat-h1">{{ archivedWorkspaceName }}</h1>
<div class="container">
  <mat-card class="folders-list grid-item">
    <mat-card-header>
      <mat-card-title i18n="@@offices.archived-workspace-detail.folders" class="mat-h2">Folders</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodePadding
          matTreeNodePaddingIndent="20"
          [style.display]="shouldRender(node) ? 'flex' : 'none'"
          class="tree-node"
        >
          <div
            (contextmenu)="onRightClick($event, node)"
            (click)="handleClick(node)"
            [ngClass]="node.id === selectedFlatFolderNode?.id ? 'color-blue' : ''"
            class="none cursor-pointer flex justify-center items-center gap-x-4"
          >
            <div class="flex justify-center items-center">
              <mat-icon class="mat-icon-rtl-mirror hidden">
                {{
                  treeControl.isExpanded(node) ? "arrow_drop_down" : "arrow_right"
                }}
              </mat-icon>
              <mat-icon
                class="folder-icon"
                [ngClass]="
                  node.id === selectedFlatFolderNode?.id
                    ? 'color-blue'
                    : 'color-black'
                "
                >folder</mat-icon
              >
            </div>
            <span class="font-base ellipsis" matTooltip="{{ node.name }}">{{
              node.name
            }}</span>
          </div>
        </mat-tree-node>

        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          matTreeNodePaddingIndent="20"
          [style.display]="shouldRender(node) ? 'flex' : 'none'"
          class="tree-node"
        >
          <div
            (contextmenu)="onRightClick($event, node)"
            (click)="handleClick(node)"
            [ngClass]="
              node.id === selectedFlatFolderNode?.id
                ? 'color-blue'
                : 'color-black'
            "
            class="none cursor-pointer flex justify-center items-center gap-x-4"
          >
            <div class="flex justify-center items-center">
              <mat-icon
                class="mat-icon-rtl-mirror"
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="node.isExpanded = !node.isExpanded"
                [style.visibility]="node.expandable ? 'visible' : 'hidden'"
              >
                {{
                  treeControl.isExpanded(node) ? "arrow_drop_down" : "arrow_right"
                }}
              </mat-icon>
              <mat-icon
                class="folder-icon"
                [ngClass]="
                  node.id === selectedFlatFolderNode?.id
                    ? 'color-blue'
                    : 'color-black'
                "
                >folder</mat-icon
              >
            </div>
            <span class="font-base ellipsis-child" matTooltip="{{ node.name }}">{{
              node.name
            }}</span>
          </div>
        </mat-tree-node>
      </mat-tree>
      <div *ngIf="flatFolderNodes?.length == 0">
        <span i18n="@@offices.archived-workspace-detail.no-archived-folders-found">No archived folders found</span>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="files-list grid-item">
    <h2 i18n="@@offices.archived-workspace-detail.files" class="mat-h2">Files</h2>
    <router-outlet></router-outlet>
  </div>
</div>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="menu"
></div>
<mat-menu #menu="matMenu" class="custom-menu">
  <button (click)="showFolderProperty()" mat-menu-item>
    <mat-icon color="primary">info</mat-icon>
    <span i18n="@@offices.archived-workspace-detail.properties"> Properties </span>
  </button>
  <button (click)="moveFolder()" class="custom-menu" mat-menu-item>
    <mat-icon color="primary">drive_file_move</mat-icon>
    <span i18n="@@offices.archived-workspace-detail.move"> Move </span>
  </button>
</mat-menu>
