<div
  class="flex gap-x flex-end align-center mt-4 space-between"
  *appHasPermission="hasSearchNoticePermission()"
>
  <form [formGroup]="searchForm" class="search-form-container">
    <mat-form-field
      appearance="outline"
      class="notice-filter-choice selector"
      *appHasPermission="hasNoticeFilterPermission()"
    >
      <mat-label i18n="@@documents.notices-list.filter-notices"
        >Filter Notices</mat-label
      >
      <mat-select
        formControlName="noticeFilter"
        multiple
        (selectionChange)="onNoticeFilterSelect()"
      >
        <mat-option
          *ngFor="let noticeFilter of noticeFilters"
          [value]="noticeFilter"
          >{{ noticeFilter }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="search-term-input">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        [placeholder]="placeholderFieldLabel.searchTitleField"
        formControlName="searchTerm"
        (input)="onSerchTermChange()"
        [attr.data-test-id]="
          'documents.update-notice.notice-list.notice-search-input'
        "
      />
    </mat-form-field>
  </form>

  <form [formGroup]="searchForm" class="search-form-container">
    <mat-form-field appearance="outline" class="date-filter">
      <mat-label i18n="@@documents.notice-list.start-and-end-date">Start and End Date</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          placeholder="datePlaceholderToggleLabel.startDate"
          formControlName="startDate"
        />
        <input
          matEndDate
          placeholder="datePlaceholderToggleLabel.endDate"
          formControlName="endDate"
        />
      </mat-date-range-input>
      <mat-hint i18n="@@documents.notice-list.mm-dd-yyyy-mm-dd-yyyy">MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>

  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="addNotices()"
    *appHasPermission="hasCreateNoticeRecievedPermission()"
  >
    <span i18n="@@documents.notices-list.add-notice"> Add Notice </span>
    <mat-icon>add</mat-icon>
  </button>
</div>
<div class="main-table mat-elevation-z2">
  <mat-paginator
    [length]="notices?.totalCount"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedProcessInstances($event)"
  >
  </mat-paginator>

  <ng-container *ngIf="notices!.items.length > 0; else emptyNoticesList">
    <mat-table [dataSource]="handleDataSource()" class="table">
      <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef i18n="@@documents.notices-list.title"
          >Title</mat-header-cell
        >
        <mat-cell *matCellDef="let notice">{{ notice.title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell
          *matHeaderCellDef
          i18n="@@documents.notices-list.status"
          >Status</mat-header-cell
        >
        <mat-cell *matCellDef="let notice">{{
          notice.noticeStatusForDisplay.noticeStatusForDisplay
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell
          *matHeaderCellDef
          i18n="@@document.notice-list.created-at"
          >Created At</mat-header-cell
        >
        <mat-cell *matCellDef="let notice">{{
          notice.created | date: "short"
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <mat-header-cell
          *matHeaderCellDef
          class="icon-cell"
          i18n="@@documents.notices-list.actions"
          >Actions</mat-header-cell
        >
        <mat-cell *matCellDef="let notice" class="icon-cell">
          <div *ngIf="notice.noticeStatus === 'Draft'">
            <button
              mat-icon-button
              (click)="updateNotice($event, notice)"
              *appHasPermission="hasUpdateNoticePermission()"
              [attr.data-test-id]="
                'documents.update-notice.notice-list.edit-notice-button'
              "
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="deleteNotice($event, notice)"
              *appHasPermission="hasDeleteNoticePermission()"
              [attr.data-test-id]="
                'documents.update-notice.notice-list.delete-notice-button'
              "
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['Title', 'Status', 'createdAt', 'Actions']"
      ></mat-header-row>
      <mat-row
        class="table-row"
        *matRowDef="
          let notices;
          columns: ['Title', 'Status', 'createdAt', 'Actions']
        "
        (click)="onRowClick(notices)"
      ></mat-row>
    </mat-table>
  </ng-container>

  <ng-template #emptyNoticesList>
    <div class="empty-notices-list">
      <span class="text-2xl" i18n="@@documents.notices-list.no-notices-found"
        >No notices found</span
      >
    </div>
  </ng-template>
</div>
