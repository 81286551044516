import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  PublicationOptionState,
  PublicationOptionStateModel,
} from './publication-option.state';

export class PublicationOptionSelector {
  static slices = createPropertySelectors<PublicationOptionStateModel>(
    PublicationOptionState,
  );

  @Selector([PublicationOptionState])
  static publicationOptions(stateModel: PublicationOptionStateModel) {
    return stateModel.publicationOptions;
  }

  @Selector([PublicationOptionState])
  static editorTypes(stateModel: PublicationOptionStateModel) {
    return stateModel.editorTypes;
  }

  @Selector([PublicationOptionState])
  static isBeingUsed(stateModel: PublicationOptionStateModel){
    return stateModel.used;
  }
}
