<div class="container" *appHasPermission="hasGetPublicationsPermission()">
  <div class="space-between">
    <h2 class="mat-h2 font-bold" i18n="@@researches.publication-list.publications">Publications</h2>
    <mat-paginator
      [length]="
        selectedTabIndex
          ? completedProcessInstances?.totalCount
          : publications?.totalCount
      "
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
      (page)="
        selectedTabIndex
          ? loadPaginatedProcessInstances($event)
          : loadPaginatedPublications($event)
      "
    >
    </mat-paginator>
  </div>
  <div class="header">
    <button
      mat-flat-button
      class="custom-mat-button"
      color="primary"
      (click)="viewPublicationOptions()"
      *appHasPermission="hasGetPublicationOptionsPermission()"
      [attr.data-test-id]="'researches.create-publication-option.publication-list.publication-options-button'"
    >
      <span i18n="@@researches.publication-list.publication-options">Publication Options</span>
    </button>
  </div>

  <mat-tab-group
    animationDuration="100ms"
    mat-stretch-tabs="false"
    mat-align-tabs="center"
    [selectedIndex]="selectedTabIndex"
    (selectedTabChange)="onTabChange($event)"
    
  >
    <mat-tab [label]="onGoingPublication">
      <div class="process-list">
        <mat-card
          class="publication-card"
          *ngFor="let publication of publications?.items"
          appearance="outlined"
          (click)="publicationClicked(publication)"
          
        >
          <mat-card-header
            class="ongoing-header"
            (click)="setSelectedPublication(publication)"
            [attr.data-test-id]="'researches.submit-publication.publication-list.mat-card-header-for-ongoing'"
          >
            <div class="ongoing-title">
              <h3 class="mat-h3">{{ publication.processInstance.title }}</h3>
            </div>
            <div class="publication-title"><span i18n="@@researches.publication-list.publication-title"> Publication title: </span> {{publication.title}}</div>
            <div class="ongoing-options">
              <div
                class="option"
                *ngFor="let process of publication?.publicationProcesses"
              >
                <p>{{ process.publicationOption.title }} :</p>
                <div
                  class="status-chip"
                  [ngClass]="
                    getPublicationStatusColor(
                      process?.publicationStatus?.toString()
                    )
                  "
                >
                  <p>{{ process?.publicationStatusForDisplay?.publicationStatus }}</p>
                </div>
              </div>
            </div>
          </mat-card-header>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab [label]="notOnPublication" [attr.data-test-id]="'researches.submit-publication.publication-list.not-no-publication-tab'">
      <div class="process-list">
        <mat-card
          class="process-card"
          *ngFor="let process of completedProcessInstances?.items"
          appearance="outlined"
        >
          <mat-card-header  [attr.data-test-id]="'researches.submit-publication.publication-list.mat-card-header'">
            <h3 class="mat-h3">{{ process?.title }}</h3>
            <button
              mat-raised-button
              color="primary"
              (click)="startPublication(process)"
              *appHasPermission="hasCreatePublicationPermission()"
              i18n="@@researches.publication-list.choose-publication"
              [attr.data-test-id]="'researches.submit-publication.publication-list.choose-publication-button'"
            >
              Choose Publication
            </button>
          </mat-card-header>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
