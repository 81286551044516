import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  TorPdfFile,
  TorStatusChange,
  Tor,
  TorComment,
  TorCommentReply,
  TorDetail,
  TorStatus,
} from '../models/tor.model';
import {
  TOR_COMMENTS_URL,
  TOR_URL,
} from 'src/app/core/constants/api-endpoints';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CostBreakdownItem,
  CreateCostBreakdownItemRequest,
} from '../models/cost-breakdown';

@Injectable({
  providedIn: 'root',
})
export class TorService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getPaginatedTor(
    pageNumber: number,
    pageSize: number,
    grouped?: boolean | null,
  ): Observable<PaginatedList<Tor>> {
    const url = `${TOR_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}&grouped=${
      grouped ?? null
    }`;

    return this.http.get<PaginatedList<Tor>>(url);
  }

  intiateTor(stageInstanceId: string , currency:string) {
    return this.http.post<Tor>(
      `${TOR_URL}`,
      { stageInstanceId  , currency },
      this.httpOptions,
    );
  }

  deleteTor(id: string): Observable<any> {
    return this.http.delete<any>(`${TOR_URL}/${id}`, this.httpOptions);
  }

  detailTor(id: string): Observable<TorDetail> {
    return this.http.get<TorDetail>(`${TOR_URL}/${id}`);
  }

  updateTorDescription(id: string, description: string) {
    return this.http.put<TorDetail>(`${TOR_URL}`, {
      id,
      description,
    });
  }

  getCurrencyList() {
     return this.http.get<any>(`${TOR_URL}/currency`);
  }

  addCostBreakdownItem(
    torId: string,
    costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {
    return this.http.post<any>(
      `${TOR_URL}/${torId}/cost-breakdown-items`,
      costBreakdownItem,
    );
  }
  getCostBreakdownItems(torId: string, pageNumber: number, pageSize: number) {
    return this.http.get<PaginatedList<CostBreakdownItem>>(
      `${TOR_URL}/${torId}/cost-breakdown-items?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    );
  }

  updateCostBreakdownItem(
    torId: string,
    costBreakdownItemId: string,
    costBreakdownItem: CreateCostBreakdownItemRequest,
  ) {
    return this.http.put<CostBreakdownItem>(
      `${TOR_URL}/${torId}/cost-breakdown-items/${costBreakdownItemId}`,
      costBreakdownItem,
    );
  }
  deleteCostBreakdownItem(torId: string, costBreakdownItemId: string) {
    return this.http.delete<any>(
      `${TOR_URL}/${torId}/cost-breakdown-items/${costBreakdownItemId}`,
    );
  }

  downloadTorPdf(torId: string): Observable<TorPdfFile> {
    return this.http.get<TorPdfFile>(`${TOR_URL}/download-pdf/${torId}`);
  }

  changeStatus(statusChange: TorStatusChange): Observable<any> {
    if(statusChange.status == TorStatus.Todo) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-draft`, statusChange);
    }
    else if(statusChange.status == TorStatus.Approved) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-approved`, statusChange);
    }
    else if(statusChange.status == TorStatus.Rejected1) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-declined-by-first-approver`, statusChange);
    }
    else if(statusChange.status == TorStatus.Rejected2) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-declined-by-second-approver`, statusChange);
    }
    else if(statusChange.status == TorStatus.Revision) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-needs-revision`, statusChange);
    }
    else if(statusChange.status == TorStatus.Submitted1) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-submitted-to-first-approver`, statusChange);
    }
    else if(statusChange.status == TorStatus.Submitted2) {
      return this.http.put<any>(`${TOR_URL}/change-status-to-submitted-to-second-approver`, statusChange);
    }
    else {
      return new Observable<any>;
    }
  }

  getTorComments(
    torId: string,
    pageNumber?: number,
    pageSize?: number,
  ): Observable<PaginatedList<TorComment>> {
    if (pageNumber != null && pageSize != null) {
      return this.http.get<PaginatedList<TorComment>>(
        `${TOR_COMMENTS_URL}/tor/${torId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      );
    } else {
      return this.http.get<PaginatedList<TorComment>>(
        `${TOR_COMMENTS_URL}/tor/${torId}`,
      );
    }
  }

  addTorComment(toRId: string, content: string): Observable<TorComment> {
    return this.http.post<TorComment>(`${TOR_COMMENTS_URL}`, {
      toRId,
      content,
    });
  }

  editTorComment(
    toRCommentId: string,
    content: string,
  ): Observable<TorComment> {
    return this.http.put<TorComment>(`${TOR_COMMENTS_URL}`, {
      toRCommentId,
      content,
    });
  }

  deleteTorComment(torCommentId: string): Observable<any> {
    return this.http.delete<any>(`${TOR_COMMENTS_URL}/${torCommentId}`);
  }

  deleteTorCommentReply(torCommentId: string, commentId: string): Observable<any> {
    return this.http.delete<any>(`${TOR_COMMENTS_URL}/reply`, {
      params: {
        torCommentId: torCommentId,
        commentId: commentId
      }
    });
  }

  replyToTorComment(
    content: string,
    commentId: string,
  ): Observable<TorComment> {
    return this.http.post<TorComment>(`${TOR_COMMENTS_URL}/reply`, {
      content,
      commentId,
    });
  }

  updateTorCommentReply(
    torCommentId: string,
    commentId: string,
    content: string,
  ) {
    return this.http.put<TorComment>(`${TOR_COMMENTS_URL}/reply`, {
    torCommentId,
    commentId,
    content});
  }


}
