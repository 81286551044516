import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  WorkflowTypeStepState,
  WorkflowTypeStepStateModel,
} from './workflow-type-steps.state';

export class WorkflowTypeStepSelector {
  static slices = createPropertySelectors<WorkflowTypeStepState>(
    WorkflowTypeStepState,
  );

  @Selector([WorkflowTypeStepState])
  static workFlowSteps(stateModel: WorkflowTypeStepStateModel) {
    return stateModel.WorkFlowTypeSteps;
  }

  @Selector([WorkflowTypeStepState])
  static update(stateModel: WorkflowTypeStepStateModel) {
    return stateModel.update;
  }

  @Selector([WorkflowTypeStepState])
  static selectedWorkflowTypeStep(stateModel: WorkflowTypeStepStateModel) {
    return stateModel.selectedWorkflowTypeStep;
  }

  @Selector([WorkflowTypeStepState])
  static totalCount(stateModel: WorkflowTypeStepStateModel) {
    return stateModel.totalCount;
  }

  @Selector([WorkflowTypeStepState])
  static selectedWorkflowTypeStepDocumentTemplate(
    stateModel: WorkflowTypeStepStateModel,
  ) {
    return stateModel.selectedWorkflowTypeStepDocumentTemplate;
  }

  @Selector([WorkflowTypeStepState])
  static selectedWorkflowTypeStepDocumentTemplateFields(
    stateModel: WorkflowTypeStepStateModel,
  ) {
    return stateModel.selectedWorkflowTypeStepDocumentTemplateFields;
  }

  @Selector([WorkflowTypeStepState])
  static workflowTypeStepDetail(
    stateModel: WorkflowTypeStepStateModel,
  ) {
    return stateModel.workflowTypeStepDetail;
  }
}
