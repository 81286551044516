<mat-card class="card">
  <mat-card-header>
    <mat-card-title i18n="@@researches.communications-with-status.briefing-types-and-their-status">Briefing types and their status </mat-card-title>
  </mat-card-header>
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="Communication Type">
      <th mat-header-cell *matHeaderCellDef i18n="@@researches.communications-with-status.briefing-type">Briefing</th>
      <td mat-cell *matCellDef="let element">
        {{ element["Communication Type"] }}
      </td>
    </ng-container>

    <ng-container
      *ngFor="let column of displayedColumns.slice(1)"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
