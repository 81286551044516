import { Selector, createPropertySelectors } from '@ngxs/store';
import { RoleState, RoleStateModel } from './role.state';

export class RoleSelector {
  static slices = createPropertySelectors<RoleStateModel>(RoleState);

  @Selector([RoleState])
  static roles(stateModel: RoleStateModel) {
    return stateModel.roles;
  }

  @Selector([RoleState])
  static paginatedRoles(stateModel: RoleStateModel) {
    return stateModel.paginatedRoles;
  }

  @Selector([RoleState])
  static totalCount(stateModel: RoleStateModel) {
    return stateModel.totalCount;
  }

  @Selector([RoleState])
  static update(stateModel: RoleStateModel) {
    return stateModel.update;
  }

  @Selector([RoleState])
  static selectedRole(stateModel: RoleStateModel) {
    return stateModel.selectedRole;
  }
}
