import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { ReportedCommentService } from '../services/reported-comment.service';
import { A } from '@angular/cdk/keycodes';
import {
  GetReportedCommentsReport,
  GetWorkflowTypes,
} from './reported-comments.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { ReportedComment } from '../models/reported-comment.model';
import { WorkflowType } from '../models/workfflow-type.model';

export interface ReportedCommentStateModel {
  reportedComments: PaginatedList<ReportedComment>;
  workflowTypes: WorkflowType[];
}

const ReportedComment_STATE_TOKEN = new StateToken<ReportedCommentStateModel>(
  'reportedCommentState',
);

const defaults: ReportedCommentStateModel = {
  reportedComments: { items: [], pageNumber: 0, totalPages: 0, totalCount: 0 },
  workflowTypes: [],
};

@State<ReportedCommentStateModel>({
  name: ReportedComment_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class ReportedCommentState {
  constructor(
    private store: Store,
    private operationStatus: OperationStatusService,
    private reportedComentsService: ReportedCommentService,
  ) {}

  @Action(GetReportedCommentsReport)
  getReportedComments(
    { patchState }: StateContext<ReportedCommentStateModel>,
    { pageIdx, pageSize, workflowType, searchTerm }: GetReportedCommentsReport,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.reportedComentsService
      .getReportedCommentsReport(pageIdx, pageSize, workflowType, searchTerm)
      .pipe(
        tap((report: any) => {
          patchState({ reportedComments: report });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetWorkflowTypes)
  getWorkflowTypes(
    { patchState }: StateContext<ReportedCommentStateModel>,
    {}: GetWorkflowTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.reportedComentsService.getWorkflowTypes().pipe(
      tap((types) => {
        patchState({ workflowTypes: types });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
}
