<nav>
  <ng-container
    *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last"
  >
    <ng-container *ngIf="!last; else lastBreadcrumb">
      <ng-container *ngIf="!last && breadcrumb.url !== ''">
        <a mat-button [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        &#47;
      </ng-container>
      <ng-container *ngIf="!last && breadcrumb.url === ''">
        <button mat-button>{{ breadcrumb.label }}</button> &#47;
      </ng-container>
    </ng-container>
    <ng-template #lastBreadcrumb>
      <button mat-button disabled>{{ breadcrumb.label }}</button>
    </ng-template>
  </ng-container>
</nav>
