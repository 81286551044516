<div class="container">
  <h1 class="title" i18n="@@researches.research-dashboard-home.dashboard">
    Dashboard
  </h1>
  <div class="content">
    <div class="flex justify-between">
      <form [formGroup]="filterForm" class="flex align-center">
        <div class="button">
          <button
            mat-flat-button
            color="primary"
            (click)="openSingleOffice()"
            i18n="@@researches.research-dashboard-home.select-office"
          >
            Select Office
          </button>
          <mat-chip
            *ngFor="let office of selectedFlatOfficeNode"
            (removed)="removeSelectedOffice(office)"
          >
            {{ office.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </div>
        <div>
          <mat-form-field appearance="outline" class="process-selector">
            <mat-label i18n="@@researches.research-dashboard-home.choose-role"
              >Choose Role</mat-label
            >
            <mat-select
              formControlName="roles"
              multiple
              (selectionChange)="onRoleSelect()"
            >
              <mat-option *ngFor="let role of roles" [value]="role">{{
                role.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="template-select" appearance="outline">
          <mat-label i18n="@@researches.research-dashboard-home.choose-user"
            >Choose User</mat-label
          >
          <mat-select
            placeholder="Sender"
            multiple
            formControlName="user"
          >
            <mat-option
              *ngFor="let user of users; let i = index"
              [value]="user"
            >
              {{ user.firstName + " " + user.middleName + " " + user.lastName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label
            i18n="@@researches.research-dashboard-home.start-and-end-date"
            >Start and End Date</mat-label
          >
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              formControlName="startDate"
            />
            <input
              matEndDate
              placeholder="End date"
              formControlName="endDate"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <button
          class="button"
          mat-flat-button
          color="warn"
          (click)="resetFilter()"
          i18n="@@researches.research-dashboard-home.reset-filter"
        >
          Reset Filter
        </button>
      </form>
    </div>
    <div class="space-left space-right">
      <div class="flex-row">
        <app-full-length-research-count
          class="flex-1"
          [researchesCount]="researchesCount"
          *appHasPermission="hasGetResearchCountPermission()"
        />
        <app-total-communications-count
          class="flex-1"
          [totalCommunicationsCount]="totalCommunicationsCount"
          *appHasPermission="hasGetCommunicationsCountPermission()"
        />
        <app-publication-categories-count
          class="flex-1"
          [totalPublicationCategoriesCount]="totalPublicationCategoriesCount"
          *appHasPermission="hasGetTotalPublicateioncategoriesCountPermission()"
        />
      </div>
      <div class="flex-row">
        <app-processes-vs-evaluation
          class="flex-1"
          *appHasPermission="
            hasGetCountOfEvaluatedProcessWithTheirStatusPermission()
          "
        />
        <app-office-vs-evaluation
          class="flex-1"
          *appHasPermission="
            hasGetCountOfEvaluatedProcessByDepartmentPermission()
          "
        />
      </div>
    </div>
    <div style="display: flex; flex-direction: column; gap: 1.5em">
      <app-communications-with-status
        *appHasPermission="hasGetCommunicationsWithStatusPermission()"
      ></app-communications-with-status>
      <app-research-vs-time-graph
        *appHasPermission="hasGetResearchesPermission()"
      ></app-research-vs-time-graph>
      <app-communication-type-instances
        *appHasPermission="hasGetCommunicationTypesWithInstancesPermission()"
      />

      <app-communication-time-graph
        *appHasPermission="hasGetCommunicationsTimeGraphPermission()"
      />
      <app-full-length-researches
        *appHasPermission="hasGetFullLengthResearchesPermission()"
      ></app-full-length-researches>
      <app-research-communcations
        *appHasPermission="hasGetCommunicationsPermission()"
      ></app-research-communcations>
      <app-research-evaluations
        *appHasPermission="hasGetResearchEvaluationsPermission()"
      />

      <app-department-communications
        *appHasPermission="hasGetCommunicationsPermission()"
      />
      <app-department-research
        *appHasPermission="hasGetResearchesPermission()"
      />
      <app-task-accomplishment-vs-time
        *appHasPermission="hasGetTaskAccomplishmentCountPermission()"
      />
      <app-budget-per-communication
        *appHasPermission="hasGetBudgetTimeGraphPermission()"
      ></app-budget-per-communication>
      <app-budget-time-graph
        *appHasPermission="hasGetBudgetTimeGraphPermission()"
      />

      <app-publication-vs-time
        *appHasPermission="hasGetPublicationsPermission()"
      ></app-publication-vs-time>
      <app-published-process-vs-category
        *appHasPermission="
          hasGetPublicationCategoriesVsResearchCountPermission()
        "
      />
      <app-department-vs-publication
      *appHasPermission="hasGetDepartmentVsPublicationsPermission()"
      ></app-department-vs-publication>
    </div>
  </div>
</div>
