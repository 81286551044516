import { Component } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsFacade } from '../../facade/contact.facade';
import { ContactForm } from '../../models/contact-form.model';
import { Router } from '@angular/router';
import { CONTACT_US_ROUTE } from 'src/app/core/constants/routes';
import { Priority, Status } from 'src/app/core/constants/reported-issues-constants';

/** @title Simple form field */
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  providers: [RxState],

})
export class ContactUsComponent {

  contactUsForm:FormGroup;
  attachments: File[] = [];
  isDragOver = false;

  placeHolderLabel={
    enterSubject : $localize`:@@users.contact-us.enter-subject: enter subject`,
    enterDescription : $localize`:@@users.contact-us.enter-description: enter description`,
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contactUsFacade:ContactUsFacade
  ){
    this.contactUsForm  = this.fb.group({
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      const selectedFiles = Array.from(files);

      const newFiles = selectedFiles.filter(
        (file) =>
          !this.attachments.some((existingFile) => existingFile.name === file.name),
      );
      this.attachments = this.attachments.concat(newFiles);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter(
          (file) =>
            !this.attachments.some((existingFile) => existingFile.name === file.name),
        );
        this.attachments = this.attachments.concat(newFiles);
      }
    };
    fileInput.click();
  }

  removeFile(file: File) {
    this.attachments = this.attachments.filter((f) => f !== file);
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.attachments = this.attachments.concat(Array.from(event.target.files));
    }
  }
  createContactUs() {
    const { valid, touched, dirty } = this.contactUsForm;
    if (    
      valid &&  touched && dirty)
     {
      const { subject, description} = this.contactUsForm.value;
      
      const contactForm: ContactForm = {
        subject:subject,
        description:description,
        attachments:this.attachments,
        status: Status.Reported,
        createdDate: new Date(),
      }
        this.contactUsFacade.dispatchCreateContactUS(
          contactForm
        );
        this.contactUsForm.reset()
        this.attachments=[]
        this.router.navigate([CONTACT_US_ROUTE]);
      }
  }

  isSaveButtonDisabled(){
    return  !this.contactUsForm.valid 
  }

}
