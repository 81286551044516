<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      {{
        workflowCommentReply!.fullName &&
          workflowCommentReply!.fullName.substring(0, 1)
      }}
    </div>
    <mat-card-title>
      {{ workflowCommentReply!.fullName }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ workflowCommentReply!.dateTime | date: "medium" }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p class="text-comment">
      {{ workflowCommentReply!.comment }}
    </p>
  </mat-card-content>
</mat-card>
