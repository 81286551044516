import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicationOptionFacade } from '../../facades/publication-option.facade';
import { EditorType } from '../../models/editor-type.model';
import { RxState } from '@rx-angular/state';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  PublicationOption,
  TemplateWithFile,
  UpdatedTemplateWithFile,
} from '../../models/publication-option.model';

interface PublicationConfigurationState {
  editorTypes: EditorType[];
}

interface FormControls {
  [key: string]: any;
}

const initPublicationConfigurationState: PublicationConfigurationState = {
  editorTypes: [],
};

@Component({
  selector: 'app-configure-publication-template',
  templateUrl: './configure-publication-template.component.html',
  styleUrls: ['./configure-publication-template.component.scss'],
})
export class ConfigurePublicationTemplateComponent {
  publicationOption: PublicationOption | undefined = undefined;
  publicationForm: FormGroup;
  editorTypes$ = this.publicationConfigurationState.select('editorTypes');
  editorTypes: EditorType[] = [];
  formInitialized: boolean = false;
  validFileType: boolean = false;
  title = $localize`:@@researches.configure-publication-template.create-publication-option: Create Publication Option`;
  button = $localize`:@@researches.configure-publication-template.create: Create`;
  private uploadedFiles: Map<
    string,
    {
      name: string | null;
      state: 'new' | 'existing' | 'deleted';
      publicationTemplateId: string | undefined;
    }
  > = new Map();

  constructor(
    private publicationConfigurationState: RxState<PublicationConfigurationState>,
    private publicationOptionFacade: PublicationOptionFacade,
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.publicationConfigurationState.connect;
    this.publicationConfigurationState.set(initPublicationConfigurationState);
    this.publicationConfigurationState.connect(
      'editorTypes',
      this.publicationOptionFacade.editorTypes$,
    );
    this.publicationForm = this.fb.group({});
  }

  ngOnInit() {
    this.publicationOptionFacade.dispatchGetEditorTypes();
    this.editorTypes$.subscribe((editorTypes) => {
      this.editorTypes = editorTypes;

      const formControls: FormControls = {
        title: ['', Validators.required],
      };

      editorTypes.forEach((editorType) => {
        formControls[editorType.id] = [null];
      });

      this.publicationForm = this.fb.group(formControls);
      this.formInitialized = true;

      if (this.data && this.data.publicationOption) {
        this.title = $localize`:@@researches.configure-publication-template.update-publication-option: Update Publication Option`;
        this.button = $localize`:@@researches.configure-publication-template.update: Update`;
        this.publicationOption = this.data.publicationOption;
        this.populateFormForUpdate();
      }
    });
  }

  populateFormForUpdate() {
    if (this.publicationOption == undefined) return;
    const control = this.publicationForm.get('title');
    if (control) {
      control.patchValue(this.publicationOption.title);
    }

    this.publicationOption.publicationTemplate.forEach((template) => {
      this.uploadedFiles.set(template.editorType.id, {
        name: template.fileName,
        state: 'existing',
        publicationTemplateId: template.id,
      });
      this.publicationForm
        .get(template.editorType.id)
        ?.setValue({ ...template, name: template.fileName });
    });
  }

  onSubmit() {
    if (this.publicationOption) {
      this.updatePublicationOptionAsFormData(
        this.publicationForm,
        this.publicationOption,
      );
    } else {
      const titleControl = this.publicationForm.get('title');
      if (!titleControl) return;

      if (this.publicationForm.valid) {
        const data = {
          title: titleControl.value,
          createPublicationTemplate: this.editorTypes
            .map((editorType) => {
              const fileControl = this.publicationForm.get(editorType.id);
              return {
                editorID: editorType.id,
                file: fileControl?.value || null,
              };
            })
            .filter((template) => template.file != null),
        };

        const formData = new FormData();
        formData.append('Title', data.title);

        data.createPublicationTemplate.forEach(
          (template: any, index: number) => {
            formData.append(
              `CreatePublicationTemplate[${index}].EditorId`,
              template.editorID,
            );
            if (template.file) {
              formData.append(
                `CreatePublicationTemplate[${index}].File`,
                template.file,
                template.file.name,
              );
            }
          },
        );
        this.publicationOptionFacade.dispatchCreatePublicationOption(formData);
      }
    }
    this.dialog.closeAll();
  }

  onFileSelected(event: Event, editorTypeID: string) {
    const element = event.currentTarget as HTMLInputElement;
    if (element && element.files && element.files.length > 0) {
      const file = element.files[0];
      this.uploadedFiles.set(editorTypeID, {
        name: file.name,
        state: 'new',
        publicationTemplateId: undefined,
      });
      this.publicationForm.get(editorTypeID)?.setValue(file);
    }
  }

  isFormValid(): boolean {
    const isTitleFilled =
      this.publicationForm.get('title')?.value?.trim() !== '';

    const isOneFileUploaded = Array.from(this.uploadedFiles.values()).some(
      (file) => !!file,
    );

    return isTitleFilled && isOneFileUploaded && !this.validFileType;
  }

  isFileUploaded(editorTypeID: string): boolean {
    return this.uploadedFiles.get(editorTypeID)?.name ? true : false;
  }

  removeFile(editorTypeID: string): void {
    const fileInfo = this.uploadedFiles.get(editorTypeID);
    if (fileInfo) {
      if (fileInfo.state === 'new') {
        this.uploadedFiles.delete(editorTypeID);
      } else {
        if (!this.uploadedFiles.has(editorTypeID)) {
          this.uploadedFiles.set(editorTypeID, {
            name: null,
            state: 'deleted',
            publicationTemplateId: undefined,
          });
        } else {
          this.uploadedFiles.set(editorTypeID, {
            name: null,
            state: 'deleted',
            publicationTemplateId:
              this.uploadedFiles.get(editorTypeID)?.publicationTemplateId,
          });
        }
      }
    }
    this.publicationForm.get(editorTypeID)?.reset();
  }

  getFileName(editorTypeID: string): string {
    if (
      this.uploadedFiles.has(editorTypeID) &&
      this.uploadedFiles.get(editorTypeID)
    ) {
      const fileName = this.uploadedFiles.get(editorTypeID);
      return fileName?.name ?? 'Unknown File';
    }
    return $localize`:@@researches.configure-publication-template.unknown-file: Unknown File`;
  }

  updatePublicationOptionAsFormData(
    form: FormGroup,
    originalPublicationOption: PublicationOption,
  ) {
    const formData = new FormData();

    const title = form.get('title')?.value;
    formData.append('Id', originalPublicationOption.id);
    formData.append('Title', title);

    let createIndex = 0;
    let updateIndex = 0;

    this.editorTypes.forEach((editorType) => {
      const fileControl = form.get(editorType.id);
      if (fileControl && fileControl.value) {
        const file = fileControl.value;
        const existingTemplate =
          originalPublicationOption.publicationTemplate.find(
            (template) => template.editorType.id === editorType.id,
          );

        if (file instanceof File) {
          if (existingTemplate) {
            if (existingTemplate.fileName !== file.name) {
              formData.append(
                `UpdatePublicationTemplates[${updateIndex}].TemplateId`,
                existingTemplate.id,
              );
              formData.append(
                `UpdatePublicationTemplates[${updateIndex}].File`,
                file,
                file.name,
              );
              updateIndex++;
            }
          } else {
            formData.append(
              `CreatePublicationTemplates[${createIndex}].EditorId`,
              editorType.id,
            );
            formData.append(
              `CreatePublicationTemplates[${createIndex}].File`,
              file,
              file.name,
            );
            createIndex++;
          }
        }
      }
    });

    let deletedTemplates: string[] = [];
    originalPublicationOption.publicationTemplate.forEach((template) => {
      if (!form.get(template.editorType.id)?.value) {
        deletedTemplates = [...deletedTemplates, template.id];
      }
    });
    if (deletedTemplates.length > 0) {
      this.publicationOptionFacade.dispatchDeletePublicationOptionTemplates(
        originalPublicationOption.id,
        deletedTemplates,
      );
    }
    this.publicationOptionFacade.dispatchUpdatePublicationOption(formData);
  }

  showValidationError(item: EditorType): boolean {
    const formValue = this.publicationForm.get(item.id)?.value;

    if (!formValue) {
      return false;
    }

    const formNameParts = (formValue?.name || '').split('.');
    const lastPart = formNameParts.at(formNameParts.length - 1)?.toLowerCase();
    const itemName = item.fileType.toLowerCase();
    return this.validFileType = (lastPart !== itemName)
  }
}
