<mat-dialog-content mat-dialog-content class="container">

  <div>
    <h3 class="add-fields-title" i18n="@@documents.workflow-initiate-form.select-the-workflow-type">select the workflow type</h3>
    <mat-paginator
      aria-label="Select page"
      class="paginator"
      (page)="loadPaginatedWorkflowTypesWithSteps($event)"
      [pageSizeOptions]="[10, 15, 20]"
      [length]="workflowTypesWithSteps ? workflowTypesWithSteps.totalCount : 0"
    >
    </mat-paginator>

    <div *ngIf="workflowTypesWithSteps?.totalCount === 0">
      <p i18n="@@documents.workflow-initiate-form.first-create-a-workflow-type-before-initiating-a-workflow">
        First create a Workflow Type before initiating a workflow
      </p>
    </div>

    <ng-container>
      <ul *ngFor="let workflowType of workflowTypesWithSteps?.items; let i = index">
        <mat-checkbox
          (change)="setSelectedWorkflowType($event, workflowType)"
          [checked]="this.selectedWorkflowType?.id === workflowType.id"
        >
          {{ workflowType.name }}
        </mat-checkbox>
      </ul>
    </ng-container>
  </div>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@documents.workflow-initiate-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="!selectedWorkflowType"
      i18n="@@documents.workflow-initiate-form.initiate">
      Initiate
    </button>
  </div>
</mat-dialog-content>
