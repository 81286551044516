<mat-dialog-content class="wide-dialog-content">
  <form class="user-form" [formGroup]="userForm">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.first-name">First Name</mat-label>
      <input id="firstName" formControlName="firstName" matInput [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-first-name'" />
      <mat-error
        *ngIf="userForm.get('firstName')?.hasError('required')"
        i18n="@@users.user-form.first-name-is-required"
        >First name is required</mat-error
      >
      <mat-error
        *ngIf="userForm.get('firstName')?.hasError('minlength')"
        i18n="@@users.user-form.must-contain-at-least-3-characters"
        >Must contain at least 3 characters</mat-error
      >
      <mat-error
        *ngIf="userForm.get('firstName')?.hasError('pattern')"
        i18n="@@users.user-form.must-contain-only-alphabets"
        >Must contain only alphabets</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.middle-name">Middle Name</mat-label>
      <input id="middleName" formControlName="middleName" matInput [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-middle-name'"/>
      <mat-error
        *ngIf="userForm.get('middleName')?.hasError('required')"
        i18n="@@users.user-form.middle-name-is-required"
        >Middle name is required</mat-error
      >
      <mat-error
        *ngIf="userForm.get('middleName')?.hasError('minlength')"
        i18n="@@users.user-form.must-contain-at-least-3-characters"
        >Must contain at least 3 characters
      </mat-error>
      <mat-error
        *ngIf="userForm.get('middleName')?.hasError('pattern')"
        i18n="@@users.user-form.must-contain-only-alphabets"
        >Must contain only alphabets</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.last-name">Last Name</mat-label>
      <input id="lastName" formControlName="lastName" matInput [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-last-name'"/>
      <mat-error
        *ngIf="userForm.get('lastName')?.hasError('required')"
        i18n="@@users.user-form.last-name-is-required"
        >Last name is required</mat-error
      >
      <mat-error
        *ngIf="userForm.get('lastName')?.hasError('minlength')"
        i18n="@@users.user-form.must-contain-at-least-3-characters"
        >Must contain at least 3 characters
      </mat-error>
      <mat-error
        *ngIf="userForm.get('lastName')?.hasError('pattern')"
        i18n="@@users.user-form.must-contain-only-alphabets"
        >Must contain only alphabets</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.user-name">User Name</mat-label>
      <input id="userName" formControlName="userName" matInput [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-user-name'"/>
      <mat-error
        *ngIf="userForm.get('userName')?.hasError('required')"
        i18n="@@users.user-form.user-name-is-required"
        >Username is required</mat-error
      >
      <mat-error
        *ngIf="userForm.get('userName')?.hasError('pattern')"
        i18n="@@users.user-form.allowed-character-sets-for-a-user-name"
        >Only English letters,numbers,dash(-), underscore(_), at sign(&#64;) and dot(.) are allowed for user
        name</mat-error
      >

      <mat-error
        *ngIf="userForm.get('userName')?.hasError('minlength')"
        i18n="
          @@users.user-form.user-name-must-contain-at-least-three-characters"
        >At least three characters for a user name</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.date-of-birth"
        >Date of birth</mat-label
      >
      <input
        matInput
        [matDatepicker]="picker"
        id="dateOfBirth"
        formControlName="dateOfBirth"
        [max]="maxBirthDate"
      />
      <mat-hint i18n="@@users.user-form.mm-dd-yy">MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.email">Email</mat-label>
      <input
        id="email"
        formControlName="email"
        matInput
        (input)="trimEmail()"
        [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-email'"
      />
      <mat-error
        *ngIf="userForm.get('email')?.hasError('required')"
        i18n="@@users.user-form.please-enter-email"
      >
        Please enter email
      </mat-error>
      <mat-error
        *ngIf="userForm.get('email')?.hasError('email')"
        i18n="@@users.user-form.email-is-not-valid"
        >Email is not valid</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@users.user-form.phone-Number">Phone Number</mat-label>
      <span matPrefix>&nbsp;+251 &nbsp;</span>
      <input
        id="phoneNumber"
        formControlName="phoneNumber"
        matInput
        type="tel"
        [attr.data-test-id]="'users.create-and-update-user.user-form.input-for-phone-number'"
      />
      <mat-error
        *ngIf="userForm.get('phoneNumber')?.hasError('required')"
        i18n="@@users.user-form.phone-number-is-required"
      >
        Phone Number is required
      </mat-error>
      <mat-error
        *ngIf="userForm.get('phoneNumber')?.hasError('pattern')"
        i18n="@@users.user-form.phone-number-is-not-valid"
        >Phone Number is not valid</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isAdminRegistration()">
      <mat-label i18n="@@users.user-form.select-role">Select Role</mat-label>
      <mat-select id="roleId" formControlName="roleId" >
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="chip-alignment">
    <button
      mat-raised-button
      (click)="openSingleOffice()"
      i18n="@@users.user-form.select-office"
    >
      Select office
    </button>

    <mat-error *ngIf="userForm.controls['officeId'].getError('required')">
      Office is required.
    </mat-error>

    <mat-chip-row
      class="chip"
      *ngIf="userForm.controls['officeId'].value"
      (removed)="removeSelectedFlatOfficeNode()"
      [editable]="false"
    >
      {{ selectedFlatOfficeNode?.name }}
      <button
        matChipRemove
        [attr.aria-label]="'remove ' + selectedFlatOfficeNode?.name"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="submit"
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="!userForm.valid"
    i18n="@@users.user-form.save"
  >
    Save
  </button>
  <button
    class="submit"
    mat-flat-button
    color="accent"
    mat-dialog-close
    i18n="@@users.user-form.cancel"
  >
    Cancel
  </button>
</mat-dialog-actions>
