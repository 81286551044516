<mat-dialog-content mat-dialog-content class="form-container" *appHasPermission="hasAddTeamMembersPermission()">
  <form class="add-member-form" [formGroup]="addTeamMemberForm">
    <h3 class="title" i18n="@@researches.add-team-member-form.add-team-members">Manage Team Members</h3>
    <mat-form-field class="add-member-form-field" appearance="outline">
      <mat-label i18n="@@researches.add-team-member-form.team-members">Team Members</mat-label>
      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #teamMemberChipGrid aria-label="Team Members">
        <mat-chip-row
          *ngFor="let teamMember of addTeamMemberForm.value.teamMembers"
          (removed)="removeTeamMember(teamMember)"
        >
          {{ teamMember?.firstName }}
          {{ teamMember?.middleName }}
          {{ teamMember?.lastName }}
          <button
            matChipRemove
            [attr.aria-label]="
              'remove ' + teamMember?.firstName + ' ' + teamMember?.middleName + ' ' + teamMember?.lastName
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #teamMembersInput
        [placeholder]="placeholderToggleLabel.searchTeamMembersAdd"
        [placeholder]="
          filteredTeamMembers.length > 0
            ? placeholderToggleLabel.searchTeamMembersAdd
            : placeholderToggleLabel.allAvailableUsersAdded
        "
        formControlName="teamMembersSearchQuery"
        [matChipInputFor]="teamMemberChipGrid"
        [matAutocomplete]="auto"
        [attr.data-test-id]="'researches.add-team-members.add-team-member-form.input-for-search-team-members-add'"
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectTeamMember($event)"
      >
        <mat-option
          *ngFor="let teamMember of filteredTeamMembers"
          [value]="teamMember"
        >
          {{ teamMember?.firstName }} {{ teamMember?.middleName }}{{ teamMember?.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <mat-card class="members-list-card" appearance="outlined">
    <mat-card-content class="members-list-content">
      <mat-list>
        <div *ngFor="let item of teamMembers">
          <div class="members-list-item">
            <div>
              <p>{{ item.firstName + " " + item.middleName + " " + item.lastName }}</p>
              <mat-card-subtitle i18n="@@researches.add-team-member-form.role">Role : </mat-card-subtitle>
            </div>
            <div *ngIf="processInstanceDetail?.ownerId === currentLoggedInUser?.id">
              <button mat-icon-button (click)="onDeleteTeamMemberClicked(item)">
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <div mat-dialog-actions class="action-btn">
    <button mat-button mat-dialog-close i18n="@@researches.add-team-member-form.cancel">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="addTeamMembers()"
      [disabled]="!(addTeamMemberForm.controls['teamMembers'].value.size > 0)"
      i18n="@@researches.add-team-member-form.add-members"
    >
      Add Members
    </button>
  </div>
</mat-dialog-content>
