import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { FieldValidationRuleFacade } from '../../facades/field-validation-rules.facade';
import { FieldValidationRule } from '../../models/field-validation-rule.model';
import { ValidationRuleValue } from '../../models/validation-rule-value.model';

interface FieldValidationListComponentState {
  currentFieldValidationRules: FieldValidationRule[];
}

const initalState: FieldValidationListComponentState = {
  currentFieldValidationRules: [],
};

@Component({
  selector: 'app-field-validation-list',
  templateUrl: './field-validation-list.component.html',
  styleUrls: ['./field-validation-list.component.scss'],
})
export class FieldValidationListComponent implements OnChanges {
  @Input() templateFieldId?: string;

  fieldValidationRules$ = this.state.select('currentFieldValidationRules');

  constructor(
    private fieldValidationRuleFacade: FieldValidationRuleFacade,
    private state: RxState<FieldValidationListComponentState>,
  ) {
    this.state.connect(
      'currentFieldValidationRules',
      this.fieldValidationRuleFacade.currentFieldValidationRules$,
    );
    this.state.set(initalState);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.templateFieldId)
      this.fieldValidationRuleFacade.dispatchGetTemplateFieldValidationRules(
        this.templateFieldId,
      );
  }

  formatRuleValues(values: ValidationRuleValue[]) {
    return values.map((val) => val.value).join(',');
  }
}
