<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title i18n="@@researches.task-accomplishment-vs-time.task-accomplishment-vs-time">Task Accomplishment Vs Time</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
    <div echarts [options]="chartOption" class="chart"></div>
  </mat-card-content>
</mat-card>
