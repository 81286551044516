<div class="workflow-card" (click)="navigateToWorkflowSteps(workflow)">
  <mat-card class="stage-card">
    <mat-card-header class="card-header"  [attr.data-test-id]="'documents.get-workflow.workflow-card.mat-card-header'">
      <div class="stage-header">
        <div class="flex">
          <mat-card-title class="card-title">
            {{ workflow?.name }}
          </mat-card-title>
          <div class="chip mat-caption">
            <span>
              {{ workflow?.status }}
            </span>
          </div>
        </div>
        <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [attr.data-test-id]="
              'documents.workflow.' + workflow?.name + 'more-icon-button'
            "
            (click)="handleMenuClick($event)"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
      <div class="workflow-subtitle">
        <mat-card-subtitle class="initiator"
          >Initiator :
          <span class="avatar">{{ (workflow?.initiator?.firstName)![0] }}</span>
          {{ workflow?.initiator?.fullName }}</mat-card-subtitle
        >
        <mat-card-subtitle
          >Intiation Date : {{ workflow?.created | date:'short' }}</mat-card-subtitle
        >
        <mat-card-subtitle
          >Current step : {{ workflow?.currentStep?.name }}</mat-card-subtitle
        >
      </div>
    </mat-card-header>
    <mat-card-content>
      <p class="mat-body-2">
        {{ workflow?.description }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-stroked-button
        class="button"
        (click)="detailPage()"
        color="primary"
        [attr.data-test-id]="'documents.workflow-card.'+workflow?.name+'-view-details-button'"
        i18n="@@documents.workflow-card.view-details"
      >
        View Details
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="downloadWorkflow($event)"
      *appHasPermission="hasDownloadWorkflowPermission()"
      i18n="@@workflows.workflow-card.download"
    >
      Download
    </button>
    <button
      mat-menu-item
      *ngIf="!isArchived"
      (click)="OpenCancelConfirmationDialog($event)"
      i18n="@@workflows.workflow-card.cancel"
    >
      Cancel
    </button>
  </mat-menu>
</div>
