import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EDITOR_TYPE_URL } from 'src/app/core/constants/api-endpoints';
import { EditorType } from '../models/editor-type.model';

@Injectable({
  providedIn: 'root',
})
export class EditorTypeService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}
  getEditorTypes() {
    return this.http.get<EditorType[]>(`${EDITOR_TYPE_URL}`);
  }
}
