<div class="flex justify-between">
  <div class="gap-x">
    <app-search-process *appHasPermission="hasSearchProcessesPermission()"></app-search-process>
  </div>
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="addProcess()"
    *appHasPermission="hasAddProcessPermission()"
    [attr.data-test-id]="'researches.create-process.process-list.create-process-type-button'"
  >
    <mat-icon>add</mat-icon>
    <span i18n="@@researches.process-list.create-process-type"
      >Create Process Type</span
    >
  </button>
</div>

<div class="main-table mat-elevation-z2">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedProcess($event)"
    *appHasPermission="hasGetProcessesPermission()"
  >
  </mat-paginator>
  <mat-table *appHasPermission="hasGetProcessesPermission()" [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="category">
      <mat-header-cell
        *matHeaderCellDef
        i18n="@@researches.process-list.category"
        >Category</mat-header-cell
      >
      <mat-cell *matCellDef="let process">
        <span class="chip mat-body-2">{{ process?.processCategoryForDisplay?.processCategory }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.process-list.code"
        >Code</mat-header-cell
      >
      <mat-cell *matCellDef="let process">{{ process.code }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="@@researches.process-list.name"
        >Name</mat-header-cell
      >
      <mat-cell *matCellDef="let process"
        >{{ process.name
        }}{{
          process.enforceSequential ? " (Sequence Enforced)" : ""
        }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        class="icon-cell"
        i18n="@@researches.process-list.actions"
        >Actions</mat-header-cell
      >
      <mat-cell *matCellDef="let process" class="icon-cell">
        <button
         *appHasPermission="hasCrudPermission()"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="setSelectedProcess($event, process)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['category', 'code', 'name', 'actions']"
    ></mat-header-row>
    <mat-row
      class="table-row"
      *matRowDef="let process; columns: ['category', 'code', 'name', 'actions']"
      (click)="onRowClick($event, process)"
    ></mat-row>
  </mat-table>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="openCreateStageForm()"
      *appHasPermission="hasCreateStagePermission()"
      i18n="@@researches.process-list.create-stage"
    >
      Create Stage
    </button>
    <button
      mat-menu-item
      (click)="updateProcess()"
      *appHasPermission="hasUpdateProcessPermission()"
      i18n="@@researches.process-list.update-process-type"
    >
      Update Process Type
    </button>
    <button
      mat-menu-item
      (click)="openConfirmationDialog()"
      *appHasPermission="hasDeleteProcessPermission()"
      i18n="@@researches.process-list.delete-process-type"
    >
      Delete Process Type
    </button>
  </mat-menu>
</div>
