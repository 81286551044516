<form [formGroup]="workflowValidationForm" (ngSubmit)="onSubmit()">
  <mat-card class="form-card mat-elevation-z0" appearance="outlined">
    <mat-card-content class="validation-form">
      <mat-radio-group
        formControlName="leftTemplateFieldId"
        style="flex-grow: 1"
      >
        <mat-accordion appearance="outlined">
          <mat-expansion-panel
            *ngFor="let document of documentTemplates$ | async"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> {{ document.name }} </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-list role="list">
              <mat-list-item
                role="listitem"
                *ngFor="let field of document.templateFields"
              >
                <mat-radio-button
                  color="primary"
                  [value]="field"
                  [checked]="
                    field.id ==
                    workflowValidationForm.controls.leftTemplateFieldId.value
                      ?.id
                  "
                >
                  {{ field.name }}
                </mat-radio-button>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
        
        <mat-error
        *ngIf="workflowValidationForm.hasError('templateFieldsIdentical')"
        class="mat-caption"
       i18n="@@documents.workflow-validation-form.the-two-field-must-not-be-exactly-same">
        The two field must not be exactly same
      </mat-error>

      </mat-radio-group>

      <mat-form-field appearance="outline" class="operator">
        <mat-label i18n="@@documents.workflow-validation-form.operators">Operators</mat-label>
        <mat-select
          formControlName="validationRule"
          [compareWith]="compareWith"
        >
          <mat-option
            *ngFor="let operator of operators$ | async"
            [value]="operator"
          >
            {{ operator.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-radio-group
        style="flex-grow: 1"
        formControlName="rightTemplateFieldId"
      >
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let document of documentTemplates$ | async"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> {{ document.name }} </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list role="list">
              <mat-list-item
                role="listitem"
                *ngFor="let field of document.templateFields"
              >
                <mat-radio-button
                  color="primary"
                  [value]="field"
                  [checked]="
                    field.id ==
                    workflowValidationForm.controls.rightTemplateFieldId.value
                      ?.id
                  "
                >
                  {{ field.name }}
                </mat-radio-button>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-error
          *ngIf="workflowValidationForm.hasError('templateField2')"
          class="mat-caption"
         i18n="@@documents.workflow-validation-form.the-two-field-must-have-the-same-data-type">
          The two field must have the same data type
        </mat-error>
        <mat-error
          *ngIf="workflowValidationForm.hasError('templateFieldsIdentical')"
          class="mat-caption"
         i18n="@@documents.workflow-validation-form.the-two-field-must-not-be-exactly-same">
          The two field must not be exactly same
        </mat-error>
      </mat-radio-group>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-flat-button (click)="close.emit()" i18n="@@documents.workflow-validation-form.cancel">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        style="margin-left: 1em"
        type="submit"
        [disabled]="workflowValidationForm.invalid"
       i18n="@@documents.workflow-validation-form.save">
        Save
      </button>
    </mat-card-actions>
  </mat-card>
</form>
