import {
  CreateStageDto,
  Stage,
  UpdateStageOrderDto,
} from '../models/stage.model';
import {
  CreateCriterionDto,
  Criterion,
  CriterionEvaluation,
  UpdateCriterionDto,
} from '../models/criterion.model';

export class DeleteStage {
  static readonly type = `[stage] ${DeleteStage.name}`;
  constructor(public selectedStageId: string) {}
}

export class GetStageCriteria {
  static readonly type = `[stage] ${GetStageCriteria.name}`;
  constructor(public seletedStageId: string) {}
}

export class RemoveCriterion {
  static readonly type = `[stage] ${RemoveCriterion.name}`;
  constructor(public criterion: Criterion) {}
}

export class UpdateProcessStageOnDelete {
  static readonly type = `[stage] ${UpdateProcessStageOnDelete.name}`;
  constructor(public selectedStageId: string) {}
}

export class CreateStage {
  static readonly type = `[Stage] ${CreateStage.name}`;
  constructor(public stage: CreateStageDto) {}
}

export class AddCriterion {
  static readonly type = `[Criteria] ${AddCriterion}`;
  constructor(public criteria: Criterion) {}
}

export class LoadCriteria {
  static readonly type = `[Criteria] ${LoadCriteria}`;
  constructor(public criteria: Criterion[]) {}
}

export class CreateCriterion {
  static readonly type = `[Criteria] ${CreateCriterion}`;
  constructor(public criterion: CreateCriterionDto) {}
}

export class SaveCriteria {
  static readonly type = `[Criteria] ${SaveCriteria}`;
}

export class UpdateStage {
  static readonly type = `[Stage] ${UpdateStage.name}`;
  constructor(public stage: CreateStageDto) {}
}

export class SelectEditableStage {
  static readonly type = `[Stage] ${SelectEditableStage.name}`;
  constructor(public stage: Stage | null) {}
}
export class ResetCriteria {
  static readonly type = `[Criteria] ${ResetCriteria}`;
}

export class UpdateCriterion {
  static readonly type = `[Criteria] ${UpdateCriterion.name}`;
  constructor(public criterion: UpdateCriterionDto) {}
}

export class DeleteCriterion {
  static readonly type = `[Criteria] ${DeleteCriterion.name}`;
  constructor(public id: string) {}
}
export class SetSelectedCriterion {
  static readonly type = `[Criteria] ${SetSelectedCriterion.name}`;
  constructor(public criterion: Criterion) {}
}
export class ResetSelectedCriterion {
  static readonly type = `[Criteria] ${ResetSelectedCriterion.name}`;
  constructor() {}
}

export class OrderStage {
  static readonly type = `[Stage] ${OrderStage.name}`;
  constructor(public updateStageOrder: UpdateStageOrderDto) {}
}
export class GetStageInstanceEvaluationSchedule {
  static readonly type = `[Stage] ${GetStageInstanceEvaluationSchedule.name}`;
}
export class EvaluateStage {
  static readonly type = `[Stage] ${EvaluateStage.name}`;
  constructor(
    public stageId: string,
    public evaluations: CriterionEvaluation[],
  ) {}
}
export class GetPaginatedStageCriteria {
  static readonly type = `[Stage] ${GetPaginatedStageCriteria.name}`;
  constructor(
    public stageId: string,
    public pageNumber?: number,
    public pageSize?: number,
  ) {}
}
