import { OrderWorkflowTypeStep } from './../../store/workflow-type-steps.actions';
import { OrderWorkflowTypeSteps } from './../../models/workflow-type-steps.model';
import { append } from '@ngxs/store/operators';
import { Component, ViewChild } from '@angular/core';
import { WorkflowTypeStepDto } from '../../models/workflow-type-steps.model';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { RxState } from '@rx-angular/state';
import { WorkflowTypeStepsFacade } from '../../facade/workflow-type-steps.facades';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { MatDialog } from '@angular/material/dialog';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { WorkflowTypeStepFormComponent } from '../workflow-type-step-form/workflow-type-step-form.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { AddCcOfficesFormComponent } from '../add-cc-offices-form/add-cc-offices-form.component';
import { WorkflowStepValidationComponent } from 'src/app/documents/workflow-validation/components/workflow-step-validation/workflow-step-validation.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';

interface WorkflowTypeStepListComponentState {
  workFlowTypeSteps: PaginatedList<WorkflowTypeStepDto> | undefined;
  length: number;
}
const iniWorkflowTypeStepListComponentState: WorkflowTypeStepListComponentState =
  {
    workFlowTypeSteps: undefined,
    length: 0,
  };

@Component({
  selector: 'app-workflow-type-step-list',
  templateUrl: './workflow-type-step-list.component.html',
  styleUrls: ['./workflow-type-step-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: WorkflowTypeStepListComponent },
  ],
})
export class WorkflowTypeStepListComponent implements MatPaginatorIntl {
  workFlowTypeSteps$ = this.state.select('workFlowTypeSteps');
  workFlowTypeSteps: PaginatedList<WorkflowTypeStepDto> | undefined;
  selectedWorkFlowType$ = this.workflowTypesFacade.selectedWorkflowType$;
  selectedWorkflowType: WorkflowTypes | undefined;
  pageSize: number = 10;
  pageIndex: number = 0;
  workFlowTypeId: string | undefined;
  length$ = this.state.select('length');
  length: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  firstPageLabel = $localize`:@@documents.workflow-type-step-list.first-page: First page`;
  itemsPerPageLabel = $localize`:@@documents.workflow-type-step-list-comment.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@documents.workflow-type-step-list-comment.last-page: Last page`;

  nextPageLabel = $localize`:@@documents.workflow-type-step-list-comment.next-page:  Next page`;
  previousPageLabel = $localize`:@@documents.workflow-type-step-list.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@documents.workflow-type-step-list-comment.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return (
      $localize`:@@documents.workflow-type-step-list-comment.page-part-one: Page` +
      `${page + 1}` +
      $localize`:@@documents.workflow-type-step-list-comment.page-part-two: of` +
      `${amountPages}`
    );
  }

  constructor(
    private dialog: MatDialog,
    private state: RxState<WorkflowTypeStepListComponentState>,
    private workflowTypeStepsFacade: WorkflowTypeStepsFacade,
    private workflowTypesFacade: WorkflowTypesFacade,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.state.set(iniWorkflowTypeStepListComponentState);
    this.state.connect(
      'workFlowTypeSteps',
      this.workflowTypeStepsFacade.workFlowSteps$,
    );
    this.state.connect('length', this.workflowTypeStepsFacade.totalCount$);
  }
  changes = new Subject<void>();
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.workFlowTypeId = params['id'];
    });
    this.selectedWorkFlowType$.subscribe((selectedWorkFlowType) => {
      this.selectedWorkflowType = selectedWorkFlowType;
    });

    this.workflowTypeStepsFacade.dispatchGetWorkFlowTypeSteps(
      this.workFlowTypeId!,
      this.paginator?.pageIndex + 1 || 1,
      this.paginator?.pageSize || 10,
    );

    this.length$.subscribe((length) => {
      this.length = length;
    });

    this.workFlowTypeSteps$.subscribe((workFlowTypeSteps) => {
      if (workFlowTypeSteps) {
        this.workFlowTypeSteps = workFlowTypeSteps;
        this.pageIndex = workFlowTypeSteps.pageNumber - 1;
      }
    });
  }

  addWorkflowTypeStep() {
    this.workflowTypeStepsFacade.dispatchSetUpdateStatus(false);
    this.workflowTypeStepsFacade.dispatchSetSelectedWorkflowTypeStepDocumentTemplateFields(
      [],
    );
    const dialogConfig = {
      ...PROCESS_FORM_SIDE_DIALOG_CONFIG,
      data: { paginator: this.paginator }
    };
    this.dialog.open(
      WorkflowTypeStepFormComponent,dialogConfig
    );
  }

  moreActions(event: MouseEvent) {
    event.stopPropagation();
  }

  updateWorkflowTypeStep(WorkFlowTypeStep: WorkflowTypeStepDto) {
    this.workflowTypeStepsFacade.dispatchSetUpdateStatus(true);
    this.workflowTypeStepsFacade.dispatchSetSelectedWorkflowTypeStep(
      WorkFlowTypeStep,
    );
    this.workflowTypeStepsFacade;
    this.dialog.open(
      WorkflowTypeStepFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  openDeleteConfirmationDialog(
    event: MouseEvent,
    workflowTypeStep: WorkflowTypeStepDto,
  ) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`:@@documents.workflow-type-step-list.delete-workflow-step-title:Delete Workflow Type Step`,
        regularTextOne:  $localize`:@@documents.workflow-type-step-list.delete-workflow-step-part-1:Are you sure you want to delete` ,
        boldText: ` "${workflowTypeStep.name}" ` ,
        regularTextTwo: $localize`:@@documents.workflow-type-step-list.delete-workflow-step-part-2:workflow type step?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.openDeliberateConfirmationDialog(workflowTypeStep);
      }
    });
  }

  openDeliberateConfirmationDialog(workflowTypeStep: WorkflowTypeStepDto) {
    const dialogRef = this.dialog.open(ConfirmDeliberateDialogComponent, {
      data: {
        name: workflowTypeStep.name,
        message:
          $localize`:@@documents.workflow-type-step-list.permanently-delete-workflow-step-part-1:Deleting` +
          ` "[${workflowTypeStep.name}]" ` +
          $localize`:@@documents.workflow-type-step-list.permanently-delete-workflow-step-part-2:workflow type step is irreversible, do you want to proceed?`,
        regularTextOne: $localize`:@@documents.workflow-type-step-list.permanently-delete-workflow-step-description-part-1:This action is irreversible. Are you sure you want to delete` ,
        boldText: ` "${workflowTypeStep.name}" ` ,
        regularTextTwo: $localize`:@@documents.workflow-type-step-list.permanently-delete-workflow-step-description-part-2:workflow type step?`,
        extraTextOne: $localize `:@@documents.workflow-type-step-list.permanent-delete-workflow-step-confirmation-part-1:To confirm, type`,
        extraTextBold: ` "${workflowTypeStep.name} " ` ,
        extraTextTwo: $localize `:@@documents.workflow-type-step-list.permanent-delete-workflow-step-confirmation-part-1:below:`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowTypeStepsFacade.dispatchDeleteWorkflowTypeStep(
          workflowTypeStep.id!,
        );
      }
    });
  }

  loadPaginatedWorkflowTypeSteps(event: PageEvent) {
    this.workflowTypeStepsFacade.dispatchGetWorkFlowTypeSteps(
      this.workFlowTypeId!,
      event.pageIndex + 1,
      event.pageSize,
    );
  }

  AddNotifyOfficesToWorkflowTypeStep(workFlowTypeStep: WorkflowTypeStepDto) {
    this.workflowTypeStepsFacade.dispatchSetSelectedWorkflowTypeStep(
      workFlowTypeStep,
    );
    this.dialog.open(
      AddCcOfficesFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }

  openWorkflowStepValidationForm(
    event: any,
    workFlowTypeStep: WorkflowTypeStepDto,
  ) {
    this.dialog.open(WorkflowStepValidationComponent, {
      ...PROCESS_FORM_SIDE_DIALOG_CONFIG,
      data: { workflowStep: workFlowTypeStep },
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.workFlowTypeSteps) return;
    var previousOrder = this.workFlowTypeSteps.items[event.previousIndex].order;
    var currOrder = this.workFlowTypeSteps.items[event.currentIndex].order;

    this.workFlowTypeSteps.items[event.previousIndex].order = currOrder;
    this.workFlowTypeSteps.items[event.currentIndex].order = previousOrder;
    this.workFlowTypeSteps.items.sort((f1, f2) => f1.order - f2.order);
    let stepOrders: number[] = [];
    let stepIds: string[] = [];
    for (let step of this.workFlowTypeSteps.items) {
      stepOrders.push(step.order);
      stepIds.push(step.id!);
    }
    let orderSteps: OrderWorkflowTypeSteps = {
      workflowTypeId: this.selectedWorkflowType?.id!,
      workflowTypeStepIds: stepIds,
      stepOrders: stepOrders,
    };
    this.workflowTypeStepsFacade.dispatchOrderWorkflowTypeSteps(orderSteps);
  }

  getWorkflowTypeStepDetail(step: WorkflowTypeStepDto) {
    this.router.navigate([`./${step.id}`], { relativeTo: this.route });
  }

  hasCreateWorkflowTypeStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.CreateWorkflowTypeStep,
    );
  }
  hasUpdateWorkflowTypeStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.UpdateWorkflowTypeStep,
    );
  }
  hasAttachNotifyOfficesToWorkflowTypeStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStep
        .AttachNotifyOfficesToWorkflowTypeStep,
    );
  }
  hasDeleteWorkflowTypeStepPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStep.DeleteWorkflowTypeStep,
    );
  }
  hasCreateWorkflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation
        .CreateWorkflowStepValidation,
    );
  }
  hasGetWorkflowStepValidationRulesPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation
        .GetWorkflowStepValidationRules,
    );
  }

  hasCrudPermission() {
    return [
      this.hasCreateWorkflowStepValidationPermission(),
      this.hasAttachNotifyOfficesToWorkflowTypeStepPermission(),
      this.hasUpdateWorkflowTypeStepPermission(),
      this.hasDeleteWorkflowTypeStepPermission(),
    ];
  }

  hasUpdateWorkflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.UpdateWorkflowStepValidation
    )
  } 

  hasDeleteWorkflowStepValidationPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.Feature,
      PERMISSION_NAMES.Documents.WorkflowTypeStepValidation.DeleteWorkflowStepValidation
    )
  }
}
