import { Component, OnInit } from '@angular/core';
import { DocumentsAnalyticsFacade } from '../../facades/documents-analytics.facade';
import { RxState } from '@rx-angular/state';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { MatDialog } from '@angular/material/dialog';
import { OfficeTreeComponent } from 'src/app/offices/components/office-tree/office-tree.component';
import { CurrentLoggedInUser, User } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportedCommentFacade } from '../../facades/reported-comment.facade';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ReportedComment } from '../../models/reported-comment.model';
import { Router } from '@angular/router';
import {
  DOCUMENT_REPORTED_COMMENTS_REPORT_ROUTE,
  DOCUMENT_WORKFLOWSTEP_AVG_TIME,
  INITIATED_WORKFLOWS,
  INVOLVED_WORKFLOWS,
  MY_WORKFLOWS,
} from 'src/app/core/constants/routes';
import { DOCUMENTS_DASHBOARD_ROUTE } from 'src/app/core/constants/routes';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { WorkflowFacade } from '../../facades/workflow-analytics.facade';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { map } from 'rxjs';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';

interface DocumentsAnalyticsHomeComponentState {
  workflowTypesCount: number;
  selectedFlatOfficeNode: FlatOfficeNode | undefined;
  workflowsCount: any;
  users: User[];
  receivedExternalDocumentsCount: number;
  reportedComments: PaginatedList<ReportedComment>;
  myWorkflowsCount:number;
  involvedWorkflowsCount:number;
  currentLoggedInUser?: CurrentLoggedInUser;
}

const initDocumentsAnalyticsHomeComponentState: Partial<DocumentsAnalyticsHomeComponentState> =
  {
    workflowTypesCount: 0,
    selectedFlatOfficeNode: undefined,
    workflowsCount: undefined,
    users: [],
    receivedExternalDocumentsCount: 0,
    reportedComments: {
      items: [],
      pageNumber: 0,
      totalPages: 0,
      totalCount: 0,
    },
    myWorkflowsCount:0,
    involvedWorkflowsCount:0
  };

@Component({
  selector: 'app-documents-analytics-home',
  templateUrl: './documents-analytics-home.component.html',
  styleUrls: ['./documents-analytics-home.component.scss'],
})
export class DocumentsAnalyticsHomeComponent implements OnInit {
  workflowTypesCount$ = this.state.select('workflowTypesCount');
  workflowTypesCount: number = 0;

  receivedExternalDocumentsCount$ = this.state.select(
    'receivedExternalDocumentsCount',
  );
  receivedExternalDocumentsCount: number = 0;

  workflowsCount$ = this.state.select('workflowsCount');
  workflowsCount: any;
  reportedComments$ = this.state.select('reportedComments');
  reportedCommentsCount = 0;

  selectedFlatOfficeNode$ = this.state.select('selectedFlatOfficeNode');
  selectedFlatOfficeNode: FlatOfficeNode | undefined;

  users: User[] = [];
  users$ = this.state.select('users');

  filterForm: FormGroup;

  myWorkflowsCount$ = this.state.select('myWorkflowsCount');
  myWorkflowsCount: number = 0;
  
  involvedWorkflowsCount$ = this.state.select('involvedWorkflowsCount');
  involvedWorkflowsCount: number = 0;

  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  currentLoggedInUser$ = this.state.select('currentLoggedInUser');

  constructor(
    private documentsAnalyticsFacade: DocumentsAnalyticsFacade,
    private state: RxState<DocumentsAnalyticsHomeComponentState>,
    private officeFacade: OfficeFacade,
    private reportedCommentsFacade: ReportedCommentFacade,
    private matDialog: MatDialog,
    private userFacade: UserFacade,
    private fb: FormBuilder,
    private router: Router,
    private workflowFacade: WorkflowFacade,
    private operationStatus: OperationStatusService,
  ) {
    this.state.set(initDocumentsAnalyticsHomeComponentState);
    this.state.connect(
      'workflowTypesCount',
      documentsAnalyticsFacade.workflowTypesCount$,
    );

    this.state.connect(
      'receivedExternalDocumentsCount',
      documentsAnalyticsFacade.receivedExternalDocumentsCount$,
    );
    this.state.connect(
      'selectedFlatOfficeNode',
      officeFacade.selectedFlatOfficeNode$,
    );
    this.state.connect(
      'workflowsCount',
      documentsAnalyticsFacade.workflowsCount$,
    );
    this.state.connect('users', this.userFacade.users$);

    this.filterForm = this.fb.group({
      user: [null],
      startDate: [],
      endDate: [],
    });

    this.filterForm.get('user')?.valueChanges.subscribe((user) => {
      this.onUserChange();
    });

    this.filterForm
      .get('startDate')
      ?.valueChanges.subscribe((startDateValue) => {
        this.handleDateRangeChange();
      });

    this.filterForm.get('endDate')?.valueChanges.subscribe((endDateValue) => {
      this.handleDateRangeChange();
    });

    this.state.connect(
      'reportedComments',
      this.reportedCommentsFacade.reportedComments$,
    );

    this.state.connect(
      'myWorkflowsCount',
      documentsAnalyticsFacade.myWorkflowsCount$,
    );
    
    this.state.connect(
      'involvedWorkflowsCount',
      documentsAnalyticsFacade.involvedWorkflowsCount$,
    );
    this.state.connect('currentLoggedInUser', userFacade.currentLoggedInUser$);
  }

  ngOnInit(): void {
    this.selectedFlatOfficeNode$.subscribe((selectedFlatOfficeNode) => {
      this.selectedFlatOfficeNode = selectedFlatOfficeNode;
    });
    this.officeFacade.dispatchResetSelectedOffice()
    this.userFacade.dispatchGetUsers()
    this.users$.subscribe((users) => {
      this.users = users;
    });

    this.documentsAnalyticsFacade.dispatchGetWorkflowTypesCount();
      this.documentsAnalyticsFacade.dispatchGetReceivedExternalDocumentsCount(this.selectedFlatOfficeNode?.id);
    this.workflowTypesCount$.subscribe((count) => {
      this.workflowTypesCount = count;
    });

    this.documentsAnalyticsFacade.dispatchGetWorkflowsCount();

    this.workflowsCount$.subscribe((count) => {
      this.workflowsCount = count;
    });

    this.receivedExternalDocumentsCount$.subscribe((count) => {
      this.receivedExternalDocumentsCount = count;
    });
    this.reportedCommentsFacade.dispatchGetReportedCommentsReport(1, 10);
    this.reportedComments$.subscribe((items) => {
      this.reportedCommentsCount = items.totalCount;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });

    this.documentsAnalyticsFacade.dispatchGetMyWorkflowsCount(this.currentLoggedInUser?.id as string)
    this.myWorkflowsCount$.subscribe((count) => {
      this.myWorkflowsCount = count;
    });
    
    this.documentsAnalyticsFacade.dispatchGetInvolvedWorkflowsCount(this.currentLoggedInUser?.id as string)
    this.involvedWorkflowsCount$.subscribe((count) => {
      this.involvedWorkflowsCount = count;
    });
  }

  openSingleOffice() {
    const dialogRef = this.matDialog.open(OfficeTreeComponent, {
      disableClose: true,
      data: { userOfficeTree: true },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.handleSelectedFlatOfficeNodeChange();
    });
    dialogRef.afterClosed().subscribe(() => {
      this.handleSelectedFlatOfficeNodeChange();
    });
  }

  handleSelectedFlatOfficeNodeChange() {
    if (this.selectedFlatOfficeNode?.id) {
      this.documentsAnalyticsFacade.dispatchGetWorkflowTypesCount(
        this.selectedFlatOfficeNode.id,
      );
      this.documentsAnalyticsFacade.dispatchGetWorkflowsCount(
        this.selectedFlatOfficeNode.id,
      );
      this.userFacade.dispatchGetUsersByOfficeId(
        this.selectedFlatOfficeNode?.id,
      );
      this.documentsAnalyticsFacade.dispatchGetReceivedExternalDocumentsCount(
        this.selectedFlatOfficeNode?.id,
      );
        
      this.users$.subscribe((users) => {
        this.users = users.filter(user => user.officeId === this.selectedFlatOfficeNode?.id);
      });
    }
  }

  resetFilter() {
    this.documentsAnalyticsFacade.dispatchGetWorkflowTypesCount();
    this.officeFacade.dispatchResetSelectedOffice();
    this.filterForm.get('startDate')?.setValue('');
    this.filterForm.get('endDate')?.setValue('');
    this.filterForm.get('user')?.setValue('');
  }

  get workflowStatuses(): [string, any][] {
    return Object.entries(this.workflowsCount).map(([key, value]) => [
      key.charAt(0).toUpperCase() + key.slice(1),
      value,
    ]);
  }

  calculateTotalWorkflows(): any {
    return Object.values(this.workflowsCount).reduce(
      (sum, value: any) => sum + value,
      0,
    );
  }

  onUserChange() {
    this.documentsAnalyticsFacade.dispatchGetWorkflowsCount(
      this.selectedFlatOfficeNode?.id,
      this.filterForm.get('user')?.value.id,
      this.filterForm.get('startDate')?.value,
      this.filterForm.get('endDate')?.value,
    );
  }

  handleDateRangeChange() {
    const startDate = this.filterForm.get('startDate')?.value;
    const endDate = this.filterForm.get('endDate')?.value;

    if (startDate && endDate) {
      this.documentsAnalyticsFacade.dispatchGetWorkflowsCount(
        this.selectedFlatOfficeNode?.id,
        this.filterForm.get('user')?.value,
        startDate,
        endDate,
      );
    }
  }
  reportedCommentsReport() {
    this.router.navigate([DOCUMENT_REPORTED_COMMENTS_REPORT_ROUTE]);
  }

  workflowTypeStepTime() {
    this.router.navigate([DOCUMENT_WORKFLOWSTEP_AVG_TIME]);
  }

  externalDocumentDetail() {
    if (this.selectedFlatOfficeNode != undefined) {
      this.router.navigate([
        `${DOCUMENTS_DASHBOARD_ROUTE}/external-document/${this.selectedFlatOfficeNode?.id}`,
      ]);
    } else {
      this.operationStatus.displayStatus($localize`:@@documents.documents-analytics-home.please-choose-your-office: Please choose your office`);
    }
  }

  initiatedWorkflows() {
    this.router.navigate([INITIATED_WORKFLOWS]);
  }

  myWorkflows() {
    this.workflowFacade.dispatchGetMyWorkflows(1, 10);
    this.router.navigate([MY_WORKFLOWS]);
  }
  involvedWorkflows() {
    this.workflowFacade.dispatchGetMyInvolvedWorkflows(1, 10);
    this.router.navigate([INVOLVED_WORKFLOWS]);
  }

  hasGetWorkflowTypesPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetAllWorkflowTypes
    )
  }
  hasGetWorkflowTypesCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetWorkflowTypesCount
    )
  }
  hasGetInitiatedWorkflowsCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetInitiatedWorkflows
    )
  }
  hasGetMyInitiatedWorkflowsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetMyInitiatedWorkflows
    )
  }

  hasGetWorkflowsAverageTimeSpentPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetWorkflowsAverageTimeSpent
    )
  }

  hasGetWorkflowsIAmInvovedInPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetWorkflowsIAmInvovedIn
    )
  }
  hasGetWorkflowsCountByTimelinePermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetWorkflowsByTimeline
    )
  }
  hasGetWorkflowsCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetWorkflowsCount
    )
  }

  hasGetAverageTimeSpentPerWorkflowTypeStepPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetAverageTimeSpentPerWorkflowTypeStep
    )
  }

  hasGetReceivedExternalDocumentsPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetReceivedExternalDocuments
    )
  }

  hasGetReceivedExternalDocumentCountPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetReceivedExternalDocumentCount
    )
  }

  hasGetReportedCommentPercentagePermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetReportedCommentPercentage
    )
  }

  hasGetReportedCommentsReportPermission(): string {
    return GetFullPermissionName(
      MODULES.ANALYTICS,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature,
      PERMISSION_NAMES.Analytics.AnalyticsDocument.GetReportedCommentsReport
    )
  }

  removeSelectedOffice() {
    this.officeFacade.dispatchResetSelectedOffice();
    this.users$.subscribe((users) => {
      this.users = users
    });
  }

  hasDocumentAnalyticsFeature(): string {
    return PERMISSION_NAMES.Analytics.AnalyticsDocument.Feature;
  }
}
