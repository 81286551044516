import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { AuthFacade } from '../../facade/auth.facade';
import { RxState } from '@rx-angular/state';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
  HOME_ROUTE,
  USERS_ROUTE,
  FORGET_PASSWORD_ROUTE,
  OFFICES_ROUTE,
  FILES_ROUTE,
  DOCUMENTS_ROUTE,
  RESEARCHES_ROUTE,
  RESEARCH_ROUTE,
  PROFILE_ROUTE,
  CHANGE_PASSWORD_ROUTE,
} from 'src/app/core/constants/routes';
import { Store } from '@ngxs/store';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { MODULES } from 'src/app/core/constants/permissions';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import { SanitizerService } from 'src/app/core/services/sanitizers-and-validators/sanitizer.service';

interface LoginComponentState {
  isAuthenticated: boolean;
  isPasswordVisible: boolean;
  isDisabled: boolean;
  lockoutDuration: number;
}

const initLoginComponentState: Partial<LoginComponentState> = {
  isAuthenticated: true,
  isPasswordVisible: true,
  isDisabled: false,
  lockoutDuration: 0,
};

const modulePriority = [
  { module: MODULES.RESEARCHES, path: RESEARCH_ROUTE },
  {
    module: MODULES.DOCUMENTS,
    path: DOCUMENTS_ROUTE,
  },
  {
    module: MODULES.FILES,
    path: FILES_ROUTE,
  },
];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [RxState],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    identifier: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  isAuthenticated$: Observable<boolean> = this.state.select('isAuthenticated');
  isDisabled$: Observable<boolean> = this.state.select('isDisabled');
  lockoutDuration$: Observable<number> = this.state.select('lockoutDuration');

  constructor(
    private fb: NonNullableFormBuilder,
    private authFacade: AuthFacade,
    private state: RxState<LoginComponentState>,
    private router: Router,
    private userFacade: UserFacade,
    public sanitizerService: SanitizerService,
  ) {
    this.state.set(initLoginComponentState);
    this.state.connect('isAuthenticated', authFacade.isAuthenticated$);
    this.state.connect('lockoutDuration', authFacade.lockoutDuration$);
    this.state.connect('isDisabled', authFacade.isDisabled$);
  }

  ngOnInit(): void {
    this.lockoutDuration$.subscribe((lockoutDuration) => {
      this.authFacade.dispatchSetDisabled(true);
      setTimeout(
        () => {
          this.authFacade.dispatchSetDisabled(false);
        },
        lockoutDuration * 60 * 1000,
      );
    });

    this.isAuthenticated$.subscribe((canNavigate) => {
      if (canNavigate) {
        this.userFacade.currentLoggedInUser$.pipe(
          filter((user) => !!user),
          map((user) => {
            return user?.isFirstTimeLogin!;
          })
        ).subscribe((isFirstTime) => {
         if(isFirstTime){
           this.router.navigate([CHANGE_PASSWORD_ROUTE])
         }else{
           this.redirectToAuthorizedModule();
         }
        })
        
      } 
    });
  }

  redirectToAuthorizedModule() {
    for (const module of modulePriority) {
      if (this.isAuthorized(module.module)) {
        this.router.navigate([module.path]);
      }
    }
    this.router.navigate([PROFILE_ROUTE]);
  }

  isAuthorized(module: any): Observable<boolean> {
    return this.userFacade
      .hasModulePermission(module)
      .pipe(map((module) => !!module));
  }

  get identifierValidationError() {
    return this.loginForm.controls.identifier;
  }
  get passwordValidationError() {
    return this.loginForm.controls.password;
  }

  get isPasswordVisible() {
    const { isPasswordVisible } = this.state.get();
    return isPasswordVisible;
  }
  forgetPasswoerd() {
    this.router.navigate([FORGET_PASSWORD_ROUTE]);
  }

  togglePasswordVisibility() {
    const { isPasswordVisible } = this.state.get();
    this.state.set({ isPasswordVisible: !isPasswordVisible });
  }

  login() {
    const { valid, touched, dirty } = this.loginForm;

    if (
      valid &&
      (touched || dirty) &&
      this.loginForm.value.identifier &&
      this.loginForm.value.password
    ) {
      this.authFacade.dispatchLogin({
        identifier: this.loginForm.value.identifier,
        password: this.loginForm.value.password,
      });
    }
  }
  trimEmail() {
    const emailControl = this.loginForm.get('identifier');
    if (emailControl?.value && typeof emailControl.value === 'string') {
      emailControl.setValue(emailControl.value.trim());
    }
  }
}
