<form [formGroup]="templateFieldForm" class="template-field-form">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label i18n="@@documents.form-field-step-form.name">Name</mat-label>
    <input
      id="name"
      (focus)="focusedInput = 'name'"
      (keydown)="handleAnotherSpaceEvent($event)"
      matInput
      [placeholder]="placeholderFieldLabel.templateFieldName"
      formControlName="name"
    />
    <mat-error
      *ngIf="templateFieldForm.controls.name.errors?.['required']"
      i18n="
        @@documents.form-field-step-form.please-provide-a-valid-name-for-the-form-field"
    >
      Please provide a valid name for the form field.
    </mat-error>
    <mat-error
      *ngIf="
        templateFieldForm.controls.name.errors?.[
          'templateFieldNameAlreadyTaken'
        ]
      "
      i18n="@@documents.form-field-step-form.form-field-name-already-taken"
    >
      Form Field name already taken.
    </mat-error>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="outline">
    <mat-label i18n="@@documents.form-field-step-form.description"
      >Description</mat-label
    >
    <textarea
      id="description"
      (focus)="focusedInput = 'description'"
      (keydown)="handleAnotherSpaceEvent($event)"
      matInput
      formControlName="description"
      [placeholder]="placeholderFieldLabel.templateFieldDescription"
    ></textarea>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="outline">
    <mat-label i18n="@@documents.form-field-step-form.placeholder"
      >Placeholder</mat-label
    >
    <textarea
      id="placeholder"
      (focus)="focusedInput = 'placeholder'"
      (keydown)="handleAnotherSpaceEvent($event)"
      matInput
      formControlName="placeholder"
      [placeholder]="placeholderFieldLabel.templateFieldPlaceholder"
    ></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label i18n="@@documents.form-field-step-form.choose-a-data-type"
      >Choose Data Type</mat-label
    >
    <mat-select formControlName="dataType">
      <mat-option *ngFor="let type of dataTypes" [value]="type.dataTypeEnum">
        {{ type.dataTypeForDisplay }}
      </mat-option>
    </mat-select>
    <mat-error
      *ngIf="templateFieldForm.controls.dataType.errors?.['required']"
      i18n="@@documents.form-field-step-form.please-select-a-data-type"
    >
      Please select a data type
    </mat-error>
  </mat-form-field>
</form>

<mat-checkbox class="is-option" [formControl]="optionsForm.controls.enabled">
  Is list
</mat-checkbox>

@if (!!optionsForm.controls.enabled.value) {
  <mat-card appearance="outlined">
    <mat-card-header></mat-card-header>
    <mat-card-content>
      <div class="card-header">
        <h3 class="mat-h3">Option values</h3>
        <button
          mat-flat-button
          (click)="addOption()"
          color="primary"
        >
          <mat-icon>add</mat-icon>
          Add
        </button>
      </div>

      @for (
        optionValue of optionsForm.controls.items.controls;
        track i;
        let i = $index
      ) {
        <div class="input-wrapper">
          <app-custom-input
            style="flex-grow: 1"
            [control]="optionValue"
            label="Option"
            [dataType]="templateFieldForm.controls.dataType.value"
          />
          <button mat-icon-button (click)="removeOption(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }
    </mat-card-content>
  </mat-card>
}

<app-add-field-validation-rules
  [templateFieldId]="selectedFormField?.id"
  [dataType]="templateFieldForm.controls.dataType.value"
  [previousRules]="selectedFormField?.fieldValidationRules"
/>

<div style="margin: 1em 0">
  <button
    mat-flat-button
    color="primary"
    (click)="submit($event)"
    [disabled]="templateFieldForm.invalid"
  >
    {{
      isUpdatingFormField
        ? addCreateFieldToggle.updateFieldButton
        : addCreateFieldToggle.addFieldButton
    }}
  </button>
</div>

<div>
  <button
    mat-button
    matStepperPrevious
    i18n="@@documents.form-field-step-form.back"
  >
    Back
  </button>
  <button
    (click)="nextClicked()"
    mat-button
    matStepperNext
    i18n="@@documents.form-field-step-form.next"
    data-test-id="document.form-field-step-form.next-button"
  >
    Next
  </button>
</div>
