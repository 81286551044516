import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { WorkflowValidationFacade } from '../../facade/workflow-validation.facades';
import { WorkflowValidation } from '../../models/workflow-validation.model';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { Subject } from 'rxjs';
import { WorkflowTypes } from 'src/app/documents/workflow-types/models/workflow-types.model';
import { WorkflowTypesFacade } from 'src/app/documents/workflow-types/facade/workflow-types.facades';

interface WorkflowValidationState {
  workflowValidations: PaginatedList<WorkflowValidation>;
  selectedWorkflowType: WorkflowTypes | null;
}

const initialState: WorkflowValidationState = {
  workflowValidations: {
    items: [],
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
  },
  selectedWorkflowType: null,
};

@Component({
  selector: 'app-workflow-validations',
  templateUrl: './workflow-validations.component.html',
  styleUrls: ['./workflow-validations.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: WorkflowValidationsComponent}],
})
export class WorkflowValidationsComponent implements OnInit, MatPaginatorIntl {
  workflowTypeId?: string;
  isValidationFormOpend = false;

  firstPageLabel = $localize`:@@documents.workflow-validations.first-page: First page`;
    itemsPerPageLabel = $localize`:@@documents.workflow-validations.items-per-page: Items per page:`;
    lastPageLabel = $localize`:@@documents.workflow-validations.last-page: Last page`;

    nextPageLabel = $localize`:@@documents.workflow-validations.next-page:  Next page`;
    previousPageLabel = $localize`:@@documents.workflow-validations.previous-page:  Previous page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0) {
        return $localize`:@@documents.workflow-validations.page-1-of-1: Page 1 of 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return $localize`:@@documents.workflow-validations.page-part-one: Page` + `${page + 1}` + $localize`:@@documents.workflow-validations.page-part-two: of` + `${amountPages}`;
    }

  workflowValidations$ = this.state.select('workflowValidations');

  selectedWorkflowType: WorkflowTypes | null = null;
  selectedWorkflowType$ = this.state.select('selectedWorkflowType');

  constructor(
    private workflowValidationFacade: WorkflowValidationFacade,
    private route: ActivatedRoute,
    private destoryRef: DestroyRef,
    private state: RxState<WorkflowValidationState>,
    private dialog: MatDialog,
    private workflowTypesFacade: WorkflowTypesFacade,
  ) {
    this.state.set(initialState);
    this.state.connect(
      'workflowValidations',
      this.workflowValidationFacade.workflowValidations$,
    );
    this.state.connect(
      'selectedWorkflowType',
      this.workflowTypesFacade.selectedWorkflowType$,
    );
  }
  changes = new Subject<void>();
  ngOnInit(): void {
    this.route.params
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((params) => {
        this.workflowTypeId = params['id'];

        if (this.workflowTypeId) {
          this.workflowValidationFacade.dispatchGetWorkflowValidations(
            this.workflowTypeId,
          );
        }
      });

    this.selectedWorkflowType$.subscribe((selectedWorkflowType) => {
      this.selectedWorkflowType = selectedWorkflowType;
    });
  }

  paginateWorkflowValidations(event: PageEvent) {
    this.workflowValidationFacade.dispatchGetWorkflowValidations(
      this.workflowTypeId!,
      event.pageSize,
      event.pageIndex + 1,
    );
  }

  deleteWorkflowValidation(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.workflow-validations.deleted-workflow-validation-rule:Are you sure you want to delete workflow validation rule?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.workflowValidationFacade.dispatchDeleteWorkflowValidation(id);
      }
    });
  }

  selecteWorkflowValidation(workflowValidtion: WorkflowValidation) {
    this.workflowValidationFacade.dispatchSelectValidation(workflowValidtion);
    this.isValidationFormOpend = true;
  }

  close() {
    this.isValidationFormOpend = false;
    this.workflowValidationFacade.dispatchUnSelectValidation();
  }
  hasCreateWorkflowValidationsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.CreateWorkflowValidations,
    );
  }
  hasEditWorkflowValidationsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.EditWorkflowValidations,
    );
  }
  hasDeleteWorkflowValidationsPermission(): string {
    return GetFullPermissionName(
      MODULES.DOCUMENTS,
      PERMISSION_NAMES.Documents.Workflows.Feature,
      PERMISSION_NAMES.Documents.Workflows.DeleteWorkflowValidations,
    );
  }
}
