import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { LoginResponse } from '../models/login-response.model';
import { AuthenticationService } from '../services/authentication.service';
import { Login, Logout, RefreshToken, SetDisabled, SetLockedOut } from './auth.actions';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { GetCurrentLoggedInUser } from 'src/app/users/store/user.actions';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { LOGIN_ROUTE } from 'src/app/core/constants/routes';
export interface AuthStateModel {
  accessToken: string | null;
  refreshToken: string | null;
  email: string | null;
  username: string | null;
  isDisabled: boolean,
  lockoutDuration: number | null
}

const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('authState');

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    accessToken: null,
    refreshToken: null,
    username: null,
    email: null,
    isDisabled: false,
    lockoutDuration: null
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthenticationService,
    private store: Store,
  ) {}

  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, { request }: Login) {
    this.store.dispatch(new SetProgressOn());
    return this.authService.login(request).pipe(
      tap((result: LoginResponse) => {
        patchState({
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          isDisabled: false,
          lockoutDuration: null
        });
        this.store.dispatch(new GetCurrentLoggedInUser());
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(Logout)
  logout({ setState }: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(
      tap(() => {
        setState({
          accessToken: null,
          refreshToken: null,
          username: null,
          email: null,
          isDisabled: false,
          lockoutDuration: 0
        });
        this.store.dispatch(new StateResetAll());
      }),
    );
  }

  @Action(RefreshToken)
  refreshToken(
    { patchState }: StateContext<AuthStateModel>,
    { request }: RefreshToken,
  ) {
    return this.authService.refreshToken(request).pipe(
      tap((result: LoginResponse) => {
        patchState({
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          isDisabled: false,
          lockoutDuration: null
        });
      }),
    );
  }

  @Action(SetLockedOut)
  setLockedOut(
    { patchState }: StateContext<AuthStateModel>,
    { lockoutDuration }: SetLockedOut,
  ) {
      patchState({
        isDisabled: true,
        lockoutDuration: lockoutDuration
      });
  }

  @Action(SetDisabled)
  setDisabledOut(
    { patchState }: StateContext<AuthStateModel>,
    { isDisabled }: SetDisabled,
  ) {
      patchState({
        isDisabled: isDisabled
      });
  }
}
