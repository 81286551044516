<div>
  <mat-form-field class="template-select" appearance="outline">
    <mat-label i18n="@@documents.document-form-list.form-type">Form type</mat-label>
    <mat-select
      [placeholder]="placeholderFieldLabel.selectFormType"
      [formControl]="templateTypeControl"
      data-test-id="document.document-form.filter-by-state-select"
      hideSingleSelectionIndicator="true"
    >
      <mat-select-trigger>
        {{ templateTypeControl.value || "" }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let type of templateTypesForDisplay; let i = index"
        [value]="type"
      >
        <mat-checkbox
          [checked]="templateTypeControl.value === type"
        ></mat-checkbox>
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="flex justify-between margin-top">
  <div *appHasPermission="hasSearchDocumentFormPermission()">
    <form [formGroup]="templateSearchForm">
      <mat-form-field appearance="outline" class="search-term-input">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          [placeholder]="placeholderFieldLabel.searchForms"
          formControlName="templateName"
          (input)="onSearchTermChange()"
        />
      </mat-form-field>
    </form>
  </div>
  <button
    class="add-btn"
    mat-flat-button
    color="primary"
    (click)="addForm()"
    *appHasPermission="hasCreateDocumentFormPermission()"
  >
    <mat-icon>add</mat-icon> <span i18n="@@documents.document-form-list.create-new-form"> Create New Form </span>
  </button>
</div>

<div class="main-table mat-elevation-z2">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
    (page)="loadPaginatedFormEvent($event)"
  >
  </mat-paginator>
  <mat-table
    [dataSource]="dataSource"
    class="table"
  >
    <ng-container matColumnDef="officeName">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.document-form-list.office-name">Office Name</mat-header-cell>
      <mat-cell *matCellDef="let template">{{ template.Office }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.document-form-list.name">Name</mat-header-cell>
      <mat-cell *matCellDef="let template">{{ template.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef i18n="@@documents.document-form-list.status">Status</mat-header-cell>
      <mat-cell *matCellDef="let template">{{
        template.isArchived ? "Archived" : template.status.status
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="icon-cell"
      i18n="@@documents.document-form-list.actions"
        >Actions</mat-header-cell
      >
      <mat-cell *matCellDef="let template" class="icon-cell">
        <button
        *ngIf = "template.status.status === 'Draft' || template.status.status === 'ChangeRequested'"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="setSelectedForm($event, template)"
        >
          <mat-icon *appHasPermission="hasCrudPermission()">more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="['name', 'status', 'actions']"
    ></mat-header-row>
    <mat-row
      class="table-row"
      *matRowDef="let template; columns: ['name', 'status', 'actions']"
      (click)="onRowClick($event, template)"
    ></mat-row>
  </mat-table>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editForm()" *appHasPermission="hasUpdateDocumentFormPermission()" i18n="@@documents.document-form-list.edit-form">Edit Form</button>
    <button mat-menu-item (click)="openConfirmationDialog()" *appHasPermission="hasDeleteDocumentFormPermission()"
    i18n="@@documents.document-form-list.delete-template">
      Delete Form
    </button>
    <button mat-menu-item  *appHasPermission="hasOrderFormFieldPermission()" (click)="layout()" i18n="@@documents.document-form-list.edit-layout">Edit Layout</button>
  </mat-menu>
</div>
