import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NoticesSelector } from '../store/notices.selector';
import { Observable } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { Notice, NoticeFilter, NoticeStatus } from '../models/notices.model';
import {
  CreateNotice,
  GetNotices,
  SetSelectedNotice,
  GetNoticeDetial,
  DeleteNotice,
  DeleteNoticeAttachment,
  SetUpdateStatus,
  UpdateNotice,
  GetNoticeAccessData,
  SearchNotice,
  DownloadAttachment,
  PreviewAttachment,
  GetNoticeFilters,
  GetNoticeByStatus,
  SearchNoticeWithDate,
} from '../store/notices.actions';
@Injectable({
  providedIn: 'root',
})
export class NoticesFacade {
  constructor(private store: Store) {}
  @Select(NoticesSelector.notices)
  notices$!: Observable<PaginatedList<Notice>>;
  @Select(NoticesSelector.selectedNotice)
  selectedNotice$!: Observable<Notice | undefined>;
  @Select(NoticesSelector.update)
  update$!: Observable<boolean>;
  @Select(NoticesSelector.noticeAccessData)
  noticeAccessData$!: Observable<any>;
  @Select(NoticesSelector.noticeFilters)
  noticeFilters$!: Observable<NoticeFilter[]>

  dispatchGetNotices(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetNotices(pageNumber, pageSize));
  }
  dispatchCreateNotice(notice: FormData, send: boolean) {
    this.store.dispatch(new CreateNotice(notice, send));
  }
  dispatchSetSelectedNotice(notice: Notice) {
    this.store.dispatch(new SetSelectedNotice(notice));
  }
  dispatchGetNoticeDetial(noticeId: string) {
    this.store.dispatch(new GetNoticeDetial(noticeId));
  }
  dispatchDeleteNotice(noticeId: string) {
    this.store.dispatch(new DeleteNotice(noticeId));
  }
  dispatchDeleteNoticeAttachment(noticeId: string, attachmentId: string) {
    this.store.dispatch(new DeleteNoticeAttachment(noticeId, attachmentId));
  }
  dispatchUpdateNotice(noticeId: string, notice: FormData, send: boolean) {
    this.store.dispatch(new UpdateNotice(noticeId, notice, send));
  }
  dispatchSetUpdateStatus(updateStatus: boolean) {
    this.store.dispatch(new SetUpdateStatus(updateStatus));
  }
  dispatchGetNoticeAccessData(noticeId: string) {
    this.store.dispatch(new GetNoticeAccessData(noticeId));
  }
  dispatchSearchNotice(
    isDraft: boolean,
    isSent: boolean,
    isReceived: boolean,
    searchTerm?: string,
  ) {
    this.store.dispatch(
      new SearchNotice(isDraft, isSent, isReceived, searchTerm),
    );
  }

  dispatchDownloadAttachment(
    noticeId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new DownloadAttachment(noticeId, attachmentId, name));
  }

  dispatchPreviewAttachment(
    noticeId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new PreviewAttachment(noticeId, attachmentId, name));
  }

  dispatchGetNoticeFilters() {
    this.store.dispatch(new GetNoticeFilters());
  }

  dispatchNoticeByStatus(
    status: NoticeStatus,
    pageNumber: number,
    pageSize: number,
  ) {
    this.store.dispatch(
      new GetNoticeByStatus(status, pageNumber, pageSize),
    );
  }

  dispatchSearchNoticeWithDate(
    email: string,
    startDate: string,
    endDate: string,
    isDraft: boolean,
    isSent: boolean,
    isReceived: boolean,
    pageNumber: number,
    pageSize: number
  ) {
    this.store.dispatch(
      new SearchNoticeWithDate(
        email,
        startDate,
        endDate,
        isDraft,
        isSent,
        isReceived,
        pageNumber,
        pageSize
      )
    );
  }
}
