import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  TorStatusChange,
  Tor,
  TorDetail,
  TorStatus,
} from '../../models/tor.model';
import { RxState } from '@rx-angular/state';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { TorFacade } from '../../facades/tor.facades';
import { ActivatedRoute, Router } from '@angular/router';
import { TOR_ROUTE } from 'src/app/core/constants/routes';
import { CostBreakdownFormComponent } from '../cost-breakdown-form/cost-breakdown-form.component';
import { PROCESS_FORM_SIDE_DIALOG_CONFIG } from 'src/app/core/constants/dialog_configs';
import { CostBreakdownItem } from '../../models/cost-breakdown';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { ConfirmWithCommentDialogComponent } from 'src/app/shared/shared-components/confirm-with-comment-dialog/confirm-with-comment-dialog.component';
import { ENGLISH_LANGUAGE } from 'src/app/core/constants/language';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import { MatDrawer } from '@angular/material/sidenav';
import { CurrentLoggedInUser } from 'src/app/users/models/user.model';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { ConfirmDeliberateDialogComponent } from 'src/app/shared/shared-components/confirm-deliberate-dialog/confirm-deliberate-dialog.component';
import { Subject } from 'rxjs';

interface TorDetailComponentState {
  initiatedTor?: Tor | undefined;
  selectedTorDetail?: TorDetail;
  costBreakdownItems?: PaginatedList<CostBreakdownItem> | undefined;
  selectedCostBreakdownItem?: CostBreakdownItem | undefined;
  currentLoggedInUser?: CurrentLoggedInUser | undefined;
  selectedTorprocessInstanceOwnerId?: string;
}

const initTorDetailComponentState: TorDetailComponentState = {};

@Component({
  selector: 'app-tor-detail',
  templateUrl: './tor-detail.component.html',
  styleUrls: ['./tor-detail.component.scss'],
  providers: [{provide: MatPaginatorIntl, useClass: TorDetailComponent}],
})
export class TorDetailComponent implements OnInit, MatPaginatorIntl {
  show = true;
  @Input() torDescription: TorDetail | undefined;
  initiatedTor$ = this.state.select('initiatedTor');
  initiatedTor: Tor | undefined = undefined;
  selectedTorDetail$ = this.state.select('selectedTorDetail');
  selectedTorDetail: TorDetail | undefined;
  locale = localStorage.getItem('locale') ?? ENGLISH_LANGUAGE.locale;
  torForm = new FormGroup({
    description: new FormControl('', [
      Validators.nullValidator,
      Validators.required,
    ]),
  });
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  isLinear = false;
  isEditTorDescription: boolean = false;
  costBreakdownItems$ = this.state.select('costBreakdownItems');
  costBreakdownItems: PaginatedList<CostBreakdownItem> | undefined = undefined;
  selectedCostBreakdownItem$ = this.state.select('selectedCostBreakdownItem');
  selectedCostBreakdownItem: CostBreakdownItem | undefined = undefined;
  dataSource!: MatTableDataSource<CostBreakdownItem>;
  pageSize: number = 10;
  pageIndex: number = 0;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatDrawer) drawer!: MatDrawer | undefined;

  currentLoggedInUser$ = this.state.select('currentLoggedInUser');
  currentLoggedInUser: CurrentLoggedInUser | undefined = undefined;
  selectedTorprocessInstanceOwnerId$ = this.state.select(
    'selectedTorprocessInstanceOwnerId',
  );
  selectedTorprocessInstanceOwnerId: string | undefined = undefined;

  firstPageLabel = $localize`:@@researches.tor-detail.first-page: First page`;
  itemsPerPageLabel = $localize`:@@researches.tor-detail.items-per-page: Items per page:`;
  lastPageLabel = $localize`:@@researches.tor-detail.last-page: Last page`;

  nextPageLabel = $localize`:@@researches.tor-detail.next-page:  Next page`;
  previousPageLabel = $localize`:@@researches.tor-detail.previous-page:  Previous page`;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return $localize`:@@researches.tor-detail.page-1-of-1: Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return $localize`:@@researches.tor-detail.page-part-one: Page` + `${page + 1}` + $localize`:@@researches.tor-detail.page-part-two: of` + `${amountPages}`;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private state: RxState<TorDetailComponentState>,
    private dialog: MatDialog,
    private torFacade: TorFacade,
    private router: Router,
    private route: ActivatedRoute,
    private userFacade: UserFacade,
  ) {
    this.state.set(initTorDetailComponentState);
    this.state.connect('initiatedTor', this.torFacade.initiatedTor$);
    this.state.connect('selectedTorDetail', this.torFacade.selectedTorDetail$);
    this.state.connect(
      'costBreakdownItems',
      this.torFacade.costBreakdownItems$,
    );
    this.state.connect(
      'selectedCostBreakdownItem',
      this.torFacade.selectedCostBreakdownItem$,
    );
    this.state.connect(
      'currentLoggedInUser',
      this.userFacade.currentLoggedInUser$,
    );
    this.state.connect(
      'selectedTorprocessInstanceOwnerId',
      this.torFacade.selectedTorprocessInstanceOwnerId$,
    );
  }
  changes = new Subject<void>();
  displayedColumns: string[] = [
    'No',
    'activity',
    'activity name',
    'quantity',
    'quantity type',
    'unit cost',
    'total cost',
    'remark',
    'actions',
  ];
  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.torFacade.dispatchGetTorDetail(params['id']);
        this.torFacade.dispatchGetCostBreakdownItems(
          params['id'],
          this.paginator?.pageIndex + 1 || 1,
          this.paginator?.pageSize || 10,
        );
      }
    });

    this.selectedTorDetail$.subscribe((selectedTorDetail) => {
      this.selectedTorDetail = selectedTorDetail;
      this.torForm.setValue({
        description: this.selectedTorDetail?.description as string,
      });
    });

    this.costBreakdownItems$.subscribe((costBreakdownItems) => {
      if (costBreakdownItems) {
        this.costBreakdownItems = costBreakdownItems;
        this.dataSource = new MatTableDataSource<CostBreakdownItem>(
          costBreakdownItems?.items,
        );
      }
    });

    this.selectedCostBreakdownItem$.subscribe((selectedCostBreakdownItem) => {
      this.selectedCostBreakdownItem = selectedCostBreakdownItem;
    });

    this.currentLoggedInUser$.subscribe((currentLoggedInUser) => {
      this.currentLoggedInUser = currentLoggedInUser;
    });
    this.selectedTorprocessInstanceOwnerId$.subscribe(
      (selectedTorprocessInstanceOwnerId) => {
        this.selectedTorprocessInstanceOwnerId =
          selectedTorprocessInstanceOwnerId;
      },
    );
  }

  closeDrawer() {
    this.drawer!.close();
  }

  openConfirmationDialog() {
    if (!this.selectedTorDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.tor-detail.delete-tor-confirmation-part-1:Are you sure you want to delete` ,
        boldText:` "${this.selectedTorDetail!.title || ''}" ` ,
        regularTextTwo: $localize`:@@researches.tor-detail.delete-tor-confirmation-part-2: tor ?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm' && this.selectedTorDetail) {
        this.openDeliberateConfirmationDialog(this.selectedTorDetail);
      }
    });
  }

  openDeliberateConfirmationDialog(tor: TorDetail) {
    const deliberateDialogRef = this.dialog.open(
      ConfirmDeliberateDialogComponent,
      {
        data: {
          name: tor.title,
          message: $localize`:@@researches.tor-detail.delete: Delete` + ` "[${tor.title}]" `,
          regularTextOne: $localize`:@@researches.tor-detail.irreversible-delete-confirmation: This action is irreversible. Are you sure you want to delete` ,
          boldText: ` "${tor.title}"? `,
          extraTextOne: $localize`:@@researches.tor-detail.type-for-delete-confirmation-part-1: To confirm deletion, type` ,
          extraTextBold: ` "${tor.title}" ` ,
          extraTextTwo: $localize`:@@researches.tor-detail.type-for-delete-confirmation-part-2: below.`,
        },
      },
    );
    deliberateDialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.torFacade.dispatchDeleteTor(this.selectedTorDetail!.id!);
        this.router.navigate([TOR_ROUTE]);
      }
    });
  }

  editDescription() {
    const { valid, touched, dirty } = this.torForm;
    if (valid && (touched || dirty)) {
      this.torFacade.dispatchUpdateTor(
        this.selectedTorDetail?.id as string,
        this.torForm?.get('description')?.value as string,
      );
      this.updateForm();
      this.toggleShowButton();
    }
  }

  updateForm() {
    if (
      this.isEditTorDescription &&
      this.selectedTorDetail?.status === 'Draft'
    ) {
      this.torForm.patchValue({
        description: this.selectedTorDetail?.description,
      });
    }
  }

  cancelDescription() {
    this.toggleShowButton();
  }

  toggleShowButton() {
    this.show = !this.show;
  }

  addRow() {
    this.dialog.open(
      CostBreakdownFormComponent,
      PROCESS_FORM_SIDE_DIALOG_CONFIG,
    );
  }
  isAddNewButtonDisabled(): boolean {
    if (!this.selectedTorDetail) return true;
    return (
      this.selectedTorDetail.status !== 'Draft' &&
      this.selectedTorDetail.status !== 'NeedsRevision'
    );
  }

  addNewCostBreakdownItem() {
    this.torFacade.dispatchSetUpdateCostBreakdownItemStatus(false);
    this.dialog.open(CostBreakdownFormComponent, {
      ...PROCESS_FORM_SIDE_DIALOG_CONFIG,
      data: {
        currency: this.selectedTorDetail?.currency,
      },
    });
  }

  isEditAndDeleteButtonDisabled(): boolean {
    if (!this.selectedCostBreakdownItem || !this.selectedTorDetail) return true;
    if (this.currentLoggedInUser?.id !== this.selectedTorprocessInstanceOwnerId)
      return true;
    return (
      this.selectedTorDetail?.status !== 'Draft' &&
      this.selectedTorDetail?.status !== 'NeedsRevision'
    );
  }

  loadPaginatedCostBreakdownItems(event: PageEvent) {
    if (this.selectedTorDetail)
      this.torFacade.dispatchGetCostBreakdownItems(
        this.selectedTorDetail.id,
        event.pageIndex + 1,
        event.pageSize,
      );
  }

  setSelectedCostBreakdownItem(
    event: MouseEvent,
    costBreakdownItem: CostBreakdownItem,
  ) {
    event.stopPropagation();
    this.torFacade.dispatchSetSelectedCostBreakdownItem(costBreakdownItem);
  }
  updateCostBreakdownItem() {
    this.torFacade.dispatchSetUpdateCostBreakdownItemStatus(true);
    const dialogRef = this.dialog.open(CostBreakdownFormComponent, {
      ...PROCESS_FORM_SIDE_DIALOG_CONFIG,
      data: {
        currency: this.selectedTorDetail?.currency,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.data = this.costBreakdownItems
        ?.items as CostBreakdownItem[];
    });
  }
  deleteCostBreakdownItem() {
    if (!this.selectedCostBreakdownItem || !this.selectedTorDetail) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.tor-detail.delete-cost-breakdown-item-confirmation-part-1:Are you sure you want to delete the` ,
        boldText: ` ${this.selectedCostBreakdownItem!.activity || ''} ` ,
        regularTextTwo: $localize`:@@researches.tor-detail.delete-cost-breakdown-item-confirmation-part-2:cost breakdown item ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedCostBreakdownItem && this.selectedTorDetail)
          this.torFacade.dispatchDeleteCostBreakdownItem(
            this.selectedTorDetail.id,
            this.selectedCostBreakdownItem.id,
          );
      }
    });
  }

  downloadPdf() {
    if (this.selectedTorDetail) {
      this.torFacade.dispatchDownloadTorPdf(this.selectedTorDetail.id);
    }
  }

  submitTor() {
    if (this.selectedTorDetail) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.tor-detail.submit-tor-confirmation:Are you sure you want to submit this TOR?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm' && this.selectedTorDetail) {
            this.torFacade.dispatchChangeStatusToSubmittedToFirstApprover(
              this.selectedTorDetail!.id,
            );
          }
        });
    }
  }

  get isOnFirstStep(): boolean {
    return (
      !!this.selectedTorDetail &&
      (this.selectedTorDetail.status === TorStatus.Todo ||
        this.selectedTorDetail.status === TorStatus.Revision)
    );
  }

  get isOnSecondStep(): boolean {
    return (
      !!this.selectedTorDetail &&
      this.selectedTorDetail.status === TorStatus.Submitted1
    );
  }

  get isOnThirdStep(): boolean {
    return (
      !!this.selectedTorDetail &&
      this.selectedTorDetail.status === TorStatus.Submitted2
    );
  }

  approveStepTwo(): void {
    if (this.selectedTorDetail) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.tor-detail.approve-tor-confirmation:Are you sure you want to approve this TOR?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm' && this.selectedTorDetail) {
            this.torFacade.dispatchChangeStatusToSubmittedToSecondApprover(
              this.selectedTorDetail!.id,
            );
          }
        });
    }
  }

  rejectStepTwo(): void {
    if (this.selectedTorDetail) {
      this.dialog
        .open(ConfirmWithCommentDialogComponent, {
          data: {
            message: $localize`:@@researches.tor-detail.reject-tor-confirmation:Are you sure you want to reject this TOR?`,
            regularTextOne: $localize`:@@researches.tor-detail.description-tor-confirmation:Provide your reason to reject the TOR`,
            placeholder: $localize`:@@researches.tor-detail.placeholder-tor-confirmation:comment`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result.confirmText === 'confirm' && this.selectedTorDetail) {
            this.torFacade.dispatchChangeStatusToDeclinedByFirstApprover(
              this.selectedTorDetail!.id,
              result.comment,
            );
          }
        });
    }
  }

  approveStepThree(): void {
    if (this.selectedTorDetail) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            regularTextOne: $localize`:@@researches.tor-detail.approve-tor-confirmation:Are you sure you want to approve this TOR?`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'confirm' && this.selectedTorDetail) {
            this.torFacade.dispatchChangeStatusToApproved(
              this.selectedTorDetail!.id,
            );
          }
        });
    }
  }

  rejectStepThree(): void {
    if (this.selectedTorDetail) {
      this.dialog
        .open(ConfirmWithCommentDialogComponent, {
          data: {
            message: $localize`:@@researches.tor-detail.reject-tor-confirmation:Are you sure you want to reject this TOR?`,
            regularTextOne: $localize`:@@researches.tor-detail.description-tor-confirmation:Provide your reason to reject the TOR`,
            placeholder: $localize`:@@researches.tor-detail.placeholder-tor-confirmation:comment`,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result.confirmText === 'confirm' && this.selectedTorDetail) {
            this.torFacade.dispatchChangeStatusToDeclinedBySecondApprover(
              this.selectedTorDetail!.id,
              result.comment,
            );
          }
        });
    }
  }

  hasDownloadTorPdfPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.DownloadTorPdf,
    );
  }

  hasSubmitTorPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.ChangeTorStatusToSubmittedToFirstApprover,
    );
  }

  hasUpdateTorPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.UpdateTor,
    );
  }

  hasGetTorCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TorComment.Feature,
      PERMISSION_NAMES.Researches.TorComment.GetTorComments,
    );
  }

  hasCreateCostBreakdownItemPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.CreateCostBreakdownItem,
    );
  }

  hasGetCostBreakdownItemsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.GetCostBreakdownItems,
    );
  }

  hasUpdateCostBreakdownItemPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.UpdateCostBreakdownItem,
    );
  }

  hasDeleteCostBreakdownItemPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.DeleteCostBreakdownItem,
    );
  }

  hasDeleteTorPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.DeleteTor,
    );
  }

  hasChangeTorStatusToSubmittedToSecondApproverPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor
        .ChangeTorStatusToSubmittedToSecondApprover,
    );
  }

  hasChangeTorStatusToDeclinedByFirstApproverPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.ChangeTorStatusToDeclinedByFirstApprover,
    );
  }

  hasChangeTorStatusToApprovedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.ChangeTorStatusToApproved,
    );
  }

  hasChangeTorStatusToDeclinedBySecondApproverPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.ChangeTorStatusToDeclinedBySecondApprover,
    );
  }

  hasGetTorDetailPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.GetTorDetail,
    );
  }

  hasCrudPermission(){
    return [
      this.hasUpdateCostBreakdownItemPermission(),
      this.hasDeleteCostBreakdownItemPermission()
    ]
  }
}
