import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faFile,
  faFileAudio,
  faFileExcel,
  faFileLines,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import { RxState } from '@rx-angular/state';
import { GetFullPermissionName, MODULES, PERMISSION_NAMES } from 'src/app/core/constants/permissions';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { TaskFacade } from 'src/app/researches/facades/task.facades';
import { ProcessTasks } from 'src/app/researches/models/process-tasks.model';
import { StageInstanceTask } from 'src/app/researches/models/stage-instance-detail.model';
import {
  Task,
  TaskComment,
  TaskStatus,
} from 'src/app/researches/models/task.model';
import { TaskDetail } from 'src/app/researches/models/taskDetail.model';
import { TaskDetailFrom } from 'src/app/researches/store/task.state';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';
import { ConfirmGenericDialogComponent } from 'src/app/shared/shared-components/confirm-generic-dialog/confirm-generic-dialog.component';

interface AssignedTaskDetailComponentState {
  taskDetailFrom: TaskDetailFrom;
  taskDetail?: TaskDetail;
  fileIcons: any | undefined;
  taskFiles: any | undefined;
}

interface TaskCommentComponentState {
  taskComments?: PaginatedList<TaskComment> | undefined;
  selectedTaskComment?: TaskComment | null;
  isEditTaskComment: boolean;
  isReplyTaskComment: boolean;
}

const initTaskDetailComponentState: Partial<AssignedTaskDetailComponentState> =
  {
    taskDetailFrom: TaskDetailFrom.NONE,
    fileIcons: undefined,
    taskFiles: undefined,
  };

const initTaskCommentComponentState: TaskCommentComponentState = {
  taskComments: undefined,
  selectedTaskComment: null,
  isEditTaskComment: false,
  isReplyTaskComment: false,
};

@Component({
  selector: 'app-assigned-task-detail',
  templateUrl: './assigned-task-detail.component.html',
  styleUrls: ['./assigned-task-detail.component.scss'],
})
export class AssignedTaskDetailComponent {
  TaskStatus = TaskStatus;
  composingOption: string = 'compose';
  isTaskInfoHidden: boolean = false;
  statuses: TaskStatus[] = [TaskStatus.Todo, TaskStatus.InProgress];
  isStatusDropdownOpen: boolean = false;

  taskDetail$ = this.state.select('taskDetail');
  taskDetail?: TaskDetail;

  taskDetailFrom$ = this.state.select('taskDetailFrom');
  taskDetailFrom: TaskDetailFrom = TaskDetailFrom.NONE;

  taskComments$ = this.taskCommentState.select('taskComments');
  taskComments: PaginatedList<TaskComment> | undefined = undefined;

  stageInstanceTasks?: PaginatedList<StageInstanceTask>;

  selectedTaskComment?: TaskComment | null;
  selectedTaskComment$ = this.taskCommentState.select('selectedTaskComment');

  isEditTaskComment$ = this.taskCommentState.select('isEditTaskComment');
  isEditTaskComment = false;

  isReplyTaskComment$ = this.taskCommentState.select('isReplyTaskComment');
  isReplyTaskComment = false;
  taskList: Task[] = [];

  commentForm: FormGroup;
  toggleReplyBoxVisibility: boolean = false;

  fileIcons: any = {
    doc: faFileWord,
    docx: faFileWord,
    pdf: faFilePdf,
    png: faImage,
    jpg: faImage,
    file: faFile,
    xlsx: faFileExcel,
    xls: faFileExcel,
    txt: faFileLines,
    mp4: faFileVideo,
    ogg: faFileVideo,
    webm: faFileVideo,
    mp3: faFileAudio,
    m4a: faFileAudio,
    wav: faFileAudio,
  };

  taskFiles$ = this.state.select('taskFiles');
  taskFiles: any[] | undefined;

  placeholderToggleLabel = {
      addComment: $localize`:@@researches.assigned-task-detail.add-comment: Add Comment`,
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private taskFacade: TaskFacade,
    private state: RxState<AssignedTaskDetailComponentState>,
    private matDialog: MatDialog,
    private taskCommentState: RxState<TaskCommentComponentState>,
    private dialog: MatDialog,
  ) {
    this.state.set(initTaskDetailComponentState);
    this.taskCommentState.set(initTaskCommentComponentState);
    this.state.connect('taskDetail', taskFacade.taskDetail$);
    this.state.connect('taskDetailFrom', this.taskFacade.taskDetailFrom$);

    this.taskCommentState.connect('taskComments', taskFacade.taskComments$);
    this.taskCommentState.connect(
      'selectedTaskComment',
      taskFacade.selectedTaskComment$,
    );

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });

    this.state.connect('taskFiles', taskFacade.taskFiles$);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.taskFacade.dispatchGetTaskDetail(params['id']);
      this.taskFacade.dispatchGetPaginatedTaskComments(params['id'], 1, 5);
      this.taskFacade.dispatchGetTaskFiles(params['id']);
    });

    this.taskDetail$.subscribe((taskDetail) => {
      this.taskDetail = taskDetail;
    });

    this.taskFiles$.subscribe((files) => {
      this.taskFiles = files;
    });

    this.taskDetailFrom$.subscribe((taskDetailFrom) => {
      this.taskDetailFrom = taskDetailFrom;
    });

    this.taskComments$.subscribe((taskComments) => {
      this.taskComments = taskComments;
    });
    this.selectedTaskComment$.subscribe((selectedTaskComment) => {
      this.selectedTaskComment = selectedTaskComment;
    });
  }

  toggleTaskInfo() {
    this.isTaskInfoHidden = !this.isTaskInfoHidden;
  }
  toggleStatusDropdown() {
    this.isStatusDropdownOpen = !this.isStatusDropdownOpen;
  }
  onDropdownClosed() {
    this.isStatusDropdownOpen = false;
  }

  selectStatus(status: TaskStatus) {
    var updatedTask = { id: this.taskDetail?.id, taskStatus: status };
    this.taskFacade.dispatchChangeTaskStatus(updatedTask);
  }

  submitTask() {
    const dialogRef = this.matDialog.open(ConfirmGenericDialogComponent, {
      data: {
        title: $localize`:@@researches.assigned-task-detail.submit-task-title: Confirm Task Submit`,
        regularTextOne: $localize`:@@researches.assigned-task-detail.submit-task-message: Are you sure you want to submit your task ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'confirm') {
        var updatedTask = {
          id: this.taskDetail?.id,
          taskStatus: TaskStatus.Submitted,
        };
        this.taskFacade.dispatchChangeTaskStatus(updatedTask);
      }
    });
  }

  addComment() {
    const { valid, touched, dirty } = this.commentForm;
    if (valid && (touched || dirty)) {
      this.taskFacade.dispatchAddTaskComment(
        this.taskDetail?.id!,
        this.commentForm.value.comment,
      );
      this.commentForm.reset();
    }
  }

  getPaginatedComments() {
    if (
      this.taskComments?.pageNumber === this.taskComments?.totalPages ||
      this.taskComments?.totalPages === 0
    ) {
      this.taskFacade.dispatchGetPaginatedTaskComments(
        this.taskDetail!.id!,
        1,
        5,
      );
    } else {
      this.taskFacade.dispatchGetPaginatedTaskComments(
        this.taskDetail!.id!,
        1,
        this.taskComments!.items.length + 5,
      );
    }
  }

  toggleReplyBox() {
    this.toggleReplyBoxVisibility = true;
  }
  cancelReplyBox() {
    this.toggleReplyBoxVisibility = false;
  }

  checkTaskStatus(status: any) {
    if (status === TaskStatus.Todo) {
      return $localize`:@@researches.assigned-task-detail.task-status-to-do: To Do`;
    } else if (status === TaskStatus.InProgress) {
      return $localize`:@@researches.assigned-task-detail.task-status-in-progress: In Progress`;
    } else if (status === TaskStatus.Submitted) {
      return $localize`:@@researches.assigned-task-detail.task-status-submitted: Submitted`;
    } else if (status === TaskStatus.Rejected) {
      return $localize`:@@researches.assigned-task-detail.task-status-rejected: Rejected`;
    } else if (status === TaskStatus.Done) {
      return $localize`:@@researches.assigned-task-detail.task-status-done: Done`;
    } else {
      return $localize`:@@researches.assigned-task-detail.task-status-approved: Approved`;
    }
  }

  getTaskStatusColor(status: any) {
    if (status === TaskStatus.Todo) {
      return 'gray';
    } else if (status === TaskStatus.InProgress) {
      return 'yellow';
    } else if (status === TaskStatus.Submitted) {
      return 'cyan';
    } else if (status === TaskStatus.Rejected) {
      return 'red';
    } else {
      return 'green';
    }
  }

  getIconForFile(fileName: string): any {
    const extension = fileName.split('.').slice(-1)[0].toLowerCase();
    const iconInfo = this.fileIcons[extension];
    return iconInfo ? iconInfo : this.fileIcons.file;
  }

  removeTaskFile(file: any) {
    this.openConfirmationDialog(file);
    this.taskFacade.dispatchGetTaskFiles(this.taskDetail?.id as string);
  }

  openConfirmationDialog(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@researches.assigned-task-detail.delete-task: Are you sure you want to delete` ,
        boldText: ` "${file?.name}"? `,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.taskFacade.dispatchDeleteFileTask(file.id);
      }
    });
  }

  extractFileNameAndExtension(fileName: string): any {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex !== -1) {
      const name = fileName.substring(0, lastIndex);
      const extension = fileName.substring(lastIndex + 1);
      return { name, extension };
    } else {
      return { name: fileName, extension: '' };
    }
  }

  hasCreateDocumentTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.DocumentTask.Feature,
      PERMISSION_NAMES.Researches.DocumentTask.CreateDocumentTask,
    );
  }

  hasCreateFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.CreateFileTask,
    );
  }

  hasDeleteFileTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.DeleteFileTask,
    );
  }

  hasGetFileTasksByTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.FileTask.Feature,
      PERMISSION_NAMES.Researches.FileTask.GetFileTasksByTask,
    );
  }

  hasGetTaskCommentsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.GetTaskComments,
    );
  }

  hasCreateTaskCommentPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.TaskComment.Feature,
      PERMISSION_NAMES.Researches.TaskComment.CreateTaskComment,
    );
  }

  hasChangeStatusToSubmittedPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToSubmitted,
    );
  }
  hasChangeStatusToTodoPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToTodo,
    );
  }

  hasChangeStatusToInProgressPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.ChangeStatusToInProgress,
    );
  }
}
