import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FOLDERS_URL,
  WORKSPACES_URL,
} from 'src/app/core/constants/api-endpoints';
import { FolderTree } from '../models/folder-tree.model';
import { FlatFolderNode } from '../models/flat-folder-node.model';
import { Folder } from '../models/folder.model';
import { ShareFolderRequest } from '../models/share-folder-request';
import { FolderProperty } from '../models/folder-property.model';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  getFlatFolderNodes(): Observable<FlatFolderNode[]> {
    return this.http.get<FlatFolderNode[]>(`${FOLDERS_URL}/flat-nodes`);
  }

  getFoldersByWorkspaceIdTree(workspaceId: string): Observable<FolderTree[]> {
    return this.http.get<FolderTree[]>(
      `${WORKSPACES_URL}/${workspaceId}/folders/tree`,
    );
  }

  moveFolder(pathFromRoot: string, folderId: string): Observable<any> {
    return this.http.put<any>(
      `${FOLDERS_URL}/move`,
      {
        pathFromRoot,
        folderId,
      },
      this.httpOptions,
    );
  }
  renameFolder(folderId: string, folderName: string): Observable<Folder> {
    return this.http.put<Folder>(
      `${FOLDERS_URL}/rename`,
      { folderId, name: folderName },
      this.httpOptions,
    );
  }

  deleteFolder(id: string): Observable<any> {
    return this.http.delete<any>(`${FOLDERS_URL}/${id}`, this.httpOptions);
  }

  createFolder(folder: Folder): Observable<any> {
    return this.http.post<any>(`${FOLDERS_URL}`, folder, this.httpOptions);
  }

  getFoldersByName(name: string): Observable<Folder[]> {
    return this.http.get<Folder[]>(`${FOLDERS_URL}/search?name=${name}`);
  }

  shareFoldersToUsers(
    shareFolderToUsersRequests: ShareFolderRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FOLDERS_URL}/share-to-users`,
      shareFolderToUsersRequests,
      this.httpOptions,
    );
  }

  shareFoldersToRoles(
    shareFolderToRolesRequests: ShareFolderRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FOLDERS_URL}/share-to-roles`,
      shareFolderToRolesRequests,
      this.httpOptions,
    );
  }

  shareFoldersToOffices(
    shareFolderToOfficeRequests: ShareFolderRequest[],
  ): Observable<any> {
    return this.http.post<any>(
      `${FOLDERS_URL}/share-to-offices`,
      shareFolderToOfficeRequests,
      this.httpOptions,
    );
  }

  getSharedToFolders(): Observable<FlatFolderNode[]> {
    return this.http.get<FlatFolderNode[]>(`${FOLDERS_URL}/shared-to`);
  }

  getSharedFromFolders(): Observable<FlatFolderNode[]> {
    return this.http.get<FlatFolderNode[]>(`${FOLDERS_URL}/shared-from`);
  }

  getFolderProperty(folderId: string): Observable<FolderProperty> {
    return this.http.get<FolderProperty>(`${FOLDERS_URL}/${folderId}`);
  }

  getArchivedFoldersByWorkspaceId(
    workspaceId: string,
  ): Observable<FlatFolderNode[]> {
    return this.http.get<FlatFolderNode[]>(
      `${FOLDERS_URL}/workspace-folders?workspaceId=${workspaceId}`,
    );
  }

  getArchivedFolderProperty(folderId: string): Observable<FolderProperty> {
    return this.http.get<FolderProperty>(
      `${FOLDERS_URL}/archived-folder/${folderId}`,
    );
  }

  moveFolderFromArchivedWorkspace(
    folderId: string,
    destinationWorkspaceId: string,
  ): Observable<Folder> {
    return this.http.post<Folder>(
      `${FOLDERS_URL}/move-from-archived-workspace?folderId=${folderId}&destinationWorkspaceId=${destinationWorkspaceId}`,
      this.httpOptions,
    );
  }

  trashFolder(folderId: string): Observable<any> {
    return this.http.put<any>(
      `${FOLDERS_URL}/trash?folderId=${folderId}`,
      this.httpOptions,
    );
  }

  getTrashedFolders(pageNumber: number, pageSize: number): Observable<any> {
    const options = new HttpParams({
      fromObject: { pageNumber, pageSize },
    });
    return this.http.get<any>(`${FOLDERS_URL}/trash`, {
      params: options,
    });
  }

  restoreFile(folderId: string): Observable<any> {
    return this.http.put<any>(
      `${FOLDERS_URL}/restore?folderId=${folderId}`,
      this.httpOptions,
    );
  }
}
