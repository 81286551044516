<div class="overlay">
  <div class="dialog">
    <h2 i18n="@@researches.upload-file.upload-file">Upload File</h2>
    <div
      class="drop-zone"
      [class.dragover]="isDragOver"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
      <label for="file-upload" class="custom-file-upload" i18n="@@researches.upload-file.upload-files"> Upload files </label>
      <input
        id="file-upload"
        type="file"
        (change)="onFileSelect($event)"
        multiple
        [attr.data-test-id]="'researches.add-document.upload-file.upload-file-input'"
      />
      <ng-container *ngIf="!files.length">
        <p i18n="@@researches.upload-file.drag-and-drop-files">Drag and drop files here</p>
      </ng-container>
      <ng-container *ngFor="let file of files; let i = index">
        <div class="file-preview">
          <mat-icon>insert_drive_file</mat-icon>
          <p>{{ file.name }}</p>
          <button class="delete-button" (click)="removeFile(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="buttons">
      <button
        (click)="uploadFiles()"
        [disabled]="files.length == 0"
        mat-flat-button
        color="primary"
        mat-dialog-close
        i18n="@@researches.upload-file.upload"
        [attr.data-test-id]="'researches.add-document.upload-file.upload-button'"
      >
        Upload
      </button>
      <button class="cancel" mat-flat-button mat-dialog-close i18n="@@researches.upload-file.cancel">Cancel</button>
    </div>
  </div>
</div>
