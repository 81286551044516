import { Role } from 'src/app/users/models/role.model';
import { Criterion } from './criterion.model';
import { StageReviewerType } from '../research-council/models/reviewer.model';

export interface Stage {
  id?: string | null;
  name: string;
  description: string;
  order: number;
  quorum: number;
  criteriaSum: number;
  hasEvaluation: boolean;
  hasBudget: boolean;
  criteria: Criterion[];
  approximateDurationInWeeks: number;
  score:number;
}

export interface CreateStageDto {
  id?: string;
  processId: string;
  name: string;
  description: string;
  hasBudget: boolean;
  hasEvaluation: boolean;
  quorum: number;
  criteriaSum: number;
  criteria: { name: string; weight: number }[];
  approximateDurationInWeeks: number;
}

export interface UpdateStageOrderDto {
  stageId: string;
  order: number;
}

export enum StageStatus {
  ReadyToStart = 'ReadyToStart',
  InProgress = 'Inprogress',
  Rejected = 'Rejected',
  Approved = 'Approved',
  WaitingForApproval = 'WaitingForApproval',
  NeedsRevision = 'NeedsRevision',
  SubmittedToEvaluation = 'SubmittedToEvaluation',
  Terminated = 'Terminated',
}

export interface StageInstanceReviewerType{
  id: string,
  reviewerType: StageReviewerType
}