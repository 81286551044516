import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import {
  ExternalDocumentDto,
  ExternalDocumentDetailDto,
  SendRemarkDto,
  ExternalDocumentResponseDetailDto,
  RemarkActionStatusForDisplay,
  ExternalDocumentResponseDto,
} from '../models/external-document.model';
import {
  CreateExternalDocument,
  UpdateExternalDocument,
  GetExternalDocument,
  GetExternalDocumentDetail,
  SetSelectedExternalDocument,
  ResetSelectedExternalDocument,
  DeleteExternalDocument,
  SendRemark,
  CreateResponse,
  UpdateResponse,
  GetExternalDocumentResponseDetail,
  DeleteExternalDocumentResponse,
  SendResponseRemark,
  GetExternalDocumentResponses,
  DownloadAttachment,
  PreviewAttachment,
  GetRemarkActionStatus,
  SetFormSuccessful,
  SetFormNotSuccessful,
  DownloadResponseAttachment,
  PreviewResponseAttachment,
  ResetExternalDocumentDetail,
  ResetExternalDocumentResponseDetail,
  SetSelectedExternalDocumentResponse
} from "../store/external-document.actions";
import { ExternalDocumentSelector } from '../store/external-document.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalDocumentFacade {
  @Select(ExternalDocumentSelector.externalDocuments)
  externalDocumentsList$!: Observable<PaginatedList<ExternalDocumentDto>>;

  @Select(ExternalDocumentSelector.externalDocumentResponses)
  externalDocumentResponses$!: Observable<PaginatedList<ExternalDocumentResponseDto>>;

  @Select(ExternalDocumentSelector.selectedExternalDocumentResponse)

  @Select(ExternalDocumentSelector.externalDocumentDetail)
  externalDocumentDetail$!: Observable<ExternalDocumentDetailDto>;

  @Select(ExternalDocumentSelector.externalDocumentResponseDetail)
  externalDocumentResponseDetail$!: Observable<ExternalDocumentResponseDetailDto>;

  @Select(ExternalDocumentSelector.totalCount)
  totalCount$!: Observable<number>;

  @Select(ExternalDocumentSelector.isFormSuccessFul)
  isFormSuccessFul$!: Observable<boolean>;


  @Select(ExternalDocumentSelector.selectedExternalDocument)
  selectedExternalDocument$!: Observable<ExternalDocumentDetailDto>;

  @Select(ExternalDocumentSelector.remarkActionsForDisplay)
  remarkActionForDisplay$!: Observable<RemarkActionStatusForDisplay[]>;

  constructor(private store: Store) {}

  dispatchCreateExternalDocument(externalDocument: FormData) {
    this.store.dispatch(new CreateExternalDocument(externalDocument));
  }

  dispatchUpdateExternalDocument(externalDocument: FormData) {
    this.store.dispatch(new UpdateExternalDocument(externalDocument));
  }

  dispatchCreateResponse(response: FormData, send: boolean) {
    return this.store.dispatch(new CreateResponse(response, send));
  }

  dispatchUpdateResponse(response: FormData, send: boolean) {
    return this.store.dispatch(new UpdateResponse(response, send));
  }

  dispatchGetExternalDocuments(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetExternalDocument(pageNumber, pageSize));
  }

  dispatchGetExternalDocumentResponses(pageNumber: number, pageSize: number) {
    this.store.dispatch(new GetExternalDocumentResponses(pageNumber, pageSize));
  }

  dispatchGetExternalDocumentDetail(externalDocumentId: string) {
    this.store.dispatch(new GetExternalDocumentDetail(externalDocumentId));
  }

  dispatchGetExternalDocumentResponseDetail(responseId: string) {
    this.store.dispatch(new GetExternalDocumentResponseDetail(responseId));
  }

  dispatchSetSelectedExternalDocument(externalDocument: ExternalDocumentDto) {
    this.store.dispatch(new SetSelectedExternalDocument(externalDocument));
  }

  dispatchSetSelectedExternalDocumentResponse(response: ExternalDocumentResponseDto) {
    this.store.dispatch(new SetSelectedExternalDocumentResponse(response));
  }

  dispatchResetSelectedExternalDocuments() {
    this.store.dispatch(new ResetSelectedExternalDocument());
  }

  dispatchResetExternalDocumentDetail() {
    this.store.dispatch(new ResetExternalDocumentDetail());
  }
  dispatchResetExternalDocumentResponseDetail() {
    this.store.dispatch(new ResetExternalDocumentResponseDetail());
  }

  dispatchDeleteExternalDocument(externalDocumentId: string) {
    this.store.dispatch(new DeleteExternalDocument(externalDocumentId));
  }

  dispatchDeleteExternalDocumentResponse(externalDocumentId: string) {
    this.store.dispatch(new DeleteExternalDocumentResponse(externalDocumentId));
  }

  dispatchSendRemark(remark: SendRemarkDto, externalDocumentId: string) {
    this.store.dispatch(new SendRemark(remark, externalDocumentId));
  }
  dispatchSendResponseRemark(remark: SendRemarkDto, responseId: string) {
    this.store.dispatch(new SendResponseRemark(remark, responseId));
  }
  dispatchDownloadAttachment(
    documentId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new DownloadAttachment(documentId, attachmentId, name));
  }

  dispatchPreviewAttachment(
    documentId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new PreviewAttachment(documentId, attachmentId, name));
  }

  dispatchDownloadResponseAttachment(
    documentId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new DownloadResponseAttachment(documentId, attachmentId, name));
  }

  dispatchPreviewResponseAttachment(
    documentId: string,
    attachmentId: string,
    name: string,
  ) {
    this.store.dispatch(new PreviewResponseAttachment(documentId, attachmentId, name));
  }

  dispatchGetRemarkActionStatus() {
    this.store.dispatch(new GetRemarkActionStatus());
  }
  dispatchSetFormSuccessFul() {
    this.store.dispatch(new SetFormSuccessful());
  }

  dispatchSetFormNotSuccessFul() {
    this.store.dispatch(new SetFormNotSuccessful());
  }
}
