import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';
import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { InitiatedWorkflowData, WorkflowData } from '../models/workflow.model';
import { WorkflowsService } from '../services/workflows.service';
import {
  GetInvolvedWorkflows,
  GetMyWorkflows,
  GetWorkflows,
} from './workflow-analytics.action';

export interface WorkflowStateModel {
  initiatedWorkflows: PaginatedList<InitiatedWorkflowData>;
  myWorkflows: PaginatedList<InitiatedWorkflowData>;
  involvedWorkflows: PaginatedList<WorkflowData>;
}

const WORKFLOWANALYTICS_STATE_TOKEN = new StateToken<WorkflowStateModel>(
  'initiatedWorkflowState',
);

const defaults: WorkflowStateModel = {
  initiatedWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  myWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  involvedWorkflows: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
};

@State<WorkflowStateModel>({
  name: WORKFLOWANALYTICS_STATE_TOKEN,
  defaults,
})
@Injectable()
export class IntitatedWorkflowState {
  constructor(private workflowService: WorkflowsService) {}

  @Action(GetWorkflows)
  getInitiatedWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    {
      pageNumber,
      pageSize,
      workflowTypeId,
      officeId,
      userId,
      status,
      initiatedStart,
      initiatedEnd,
      completedStart,
      completedEnd,
      userName,
    }: GetWorkflows,
  ) {
    return this.workflowService
      .getInitiatedWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        userId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
        userName,
      )
      .pipe(
        tap((result: PaginatedList<InitiatedWorkflowData>) => {
          patchState({ initiatedWorkflows: result });
        }),
      );
  }

  @Action(GetMyWorkflows)
  getMyInitiatedWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    {
      pageNumber,
      pageSize,
      workflowTypeId,
      officeId,
      status,
      initiatedStart,
      initiatedEnd,
      completedStart,
      completedEnd,
    }: GetMyWorkflows,
  ) {
    return this.workflowService
      .getMyInitiatedWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
      )
      .pipe(
        tap((result: PaginatedList<InitiatedWorkflowData>) => {
          patchState({ myWorkflows: result });
        }),
      );
  }

  @Action(GetInvolvedWorkflows)
  getMyInvolvedWorkflows(
    { patchState }: StateContext<WorkflowStateModel>,
    {
      pageNumber,
      pageSize,
      workflowTypeId,
      officeId,
      status,
      initiatedStart,
      initiatedEnd,
      completedStart,
      completedEnd,
    }: GetInvolvedWorkflows,
  ) {
    return this.workflowService
      .getMyWorkflows(
        pageNumber,
        pageSize,
        workflowTypeId,
        officeId,
        status,
        initiatedStart,
        initiatedEnd,
        completedStart,
        completedEnd,
      )
      .pipe(
        tap((result: PaginatedList<WorkflowData>) => {
          patchState({ involvedWorkflows: result });
        }),
      );
  }
}
