import { Component, HostListener, OnInit, Pipe } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { Observable, filter, of, take } from 'rxjs';
import { ProcessFacade } from 'src/app/researches/facades/process.facades';

import {
  Process,
  ProcessCategory,
  ProcessCategoryForDisplay,
} from 'src/app/researches/models/process.model';

interface ProcessFormComponentState {
  selectedProcess: Process | null;
  update: boolean;
  processCategory : ProcessCategoryForDisplay[]
}

const initProcessFormComponentState: ProcessFormComponentState = {
  selectedProcess: null,
  update: false,
  processCategory: []
};

@Component({
  selector: 'app-process-form',
  templateUrl: './process-form.component.html',
  styleUrls: ['./process-form.component.scss'],
  providers: [RxState],
})
export class ProcessFormComponent implements OnInit {
  processForm: FormGroup;
  update$: Observable<boolean> = this.state.select('update');
  update: boolean = false;
  focusedInput: string = '';
  selectedProcess$: Observable<Process | null> =
    this.state.select('selectedProcess');
  selectedProcess: Process | undefined;
  saveButtonText: string = 'Create Process';
  formTitle: string = 'Create Process Type';
  processCategories: ProcessCategory[] = [
    ProcessCategory.RESEARCH,
    ProcessCategory.BRIEFING,
  ];
  processCategory$ = this.state.select('processCategory');
  processCategory: ProcessCategoryForDisplay[] = []

  placeholderToggleLabel = {
      processTypeName: $localize`:@@researches.process-form.process-type-name: Process Type Name`,
      processTypeCode: $localize`:@@researches.process-form.process-type-code: Process Type Code`,
      processTypeDescription: $localize`:@@researches.process-form.this-is-description-for-the-process: This is description for the process.`,
  }

  @HostListener('window:keydown.space', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const val = this.processForm.get(this.focusedInput)?.value;
    if (val === '' || val.trimEnd() !== val) event.preventDefault();
  }
  constructor(
    private fb: FormBuilder,
    private processFacade: ProcessFacade,
    private dialog: MatDialog,
    private state: RxState<ProcessFormComponentState>,
  ) {
    this.state.set(initProcessFormComponentState);
    this.state.connect('selectedProcess', this.processFacade.selectedProcess$);
    this.state.connect('update', this.processFacade.update$);
    this.state.connect('processCategory', this.processFacade.processCategories$);
    this.processForm = this.fb.group({
      enforceSequential: [false],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(1500)]],
      processCategory: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.selectedProcess$.subscribe((process) => {
      if (process) this.selectedProcess = process;
      this.updateForm();
    });
    this.update$.subscribe((updateStatus) => {
      this.update = updateStatus;
      this.updateForm();
    });
    this.processFacade.dispatchGetProcessCategories()
    this.processCategory$.subscribe((category)=>{
      this.processCategory = category
    })
  }

  save() {
    const { valid, touched, dirty } = this.processForm;
    if (valid && (touched || dirty)) {
      if (this.update) {
        const updatedProcess: Process = {
          id: this.selectedProcess?.id,
          enforceSequential: this.processForm.value.enforceSequential,
          name: this.processForm.value.name,
          code: this.processForm.value.code,
          description: this.processForm.value.description,
          processCategory: this.processForm.value.processCategory,
        };
        this.processFacade.dispatchUpdateProcess(updatedProcess);
      } else {
        const process: Process = {
          enforceSequential: this.processForm.value.enforceSequential,
          name: this.processForm.value.name,
          code: this.processForm.value.code,
          description: this.processForm.value.description,
          processCategory: this.processForm.value.processCategory,
        };
        this.processFacade.dispatchCreateProcess(process);
      }
      this.dialog.closeAll();
    }
  }

  updateForm() {
    if (this.update && this.selectedProcess) {
      this.processForm.setValue({
        enforceSequential: this.selectedProcess.enforceSequential,
        name: this.selectedProcess.name,
        code: this.selectedProcess.code,
        description: this.selectedProcess.description,
        processCategory: this.selectedProcess.processCategory,
      });
      this.formTitle = $localize`:@@researches.process-form.update-process-type:Update Process Type`;
      this.saveButtonText = $localize`:@@researches.process-form.update-process-type:Update Process Type`;
    } else {
      this.processForm.setValue({
        enforceSequential: false,
        name: '',
        code: '',
        description: '',
        processCategory: this.processCategories[0],
      });
      this.formTitle = $localize`:@@researches.process-form.create-process-type:Create Process Type`;
      this.saveButtonText = $localize`:@@researches.process-form.create-process-type:Create Process Type`;
    }
  }
  onFocus(value: string) {
    this.focusedInput = value;
  }
}
