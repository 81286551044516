import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RxState } from '@rx-angular/state';
import { Observable, combineLatest, map } from 'rxjs';
import { IS_DEVELOPMENT } from 'src/app/core/constants/api-endpoints';
import {
  GetFullPermissionName,
  MODULES,
  PERMISSION_NAMES,
} from 'src/app/core/constants/permissions';
import {
  PROCESS_INSTANCE_LIST_ROUTE,
  TASK_LIST_ROUTE,
  PROCESS_LIST_ROUTE,
  MEETINGS_ROUTE,
  ARCHIVED_ROUTE,
  RESEARCH_COUNCIL_ROUTE,
  ASSIGNED_TASKS_ROUTE,
  TOR_ROUTE,
  ASSIGNED_REVIEWS_ROUTE,
  PUBLICATION_ROUTE,
  RESEARCHES_SETTINGS_ROUTE,
} from 'src/app/core/constants/routes';
import { UserFacade } from 'src/app/users/facade/user.facade';

@Component({
  selector: 'app-researches-home',
  templateUrl: './researches-home.component.html',
  styleUrls: ['./researches-home.component.scss'],
  providers: [RxState],
})
export class ResearchesHomeComponent implements OnInit {
  RESEARCHES_SETTINGS_ROUTE: string = RESEARCHES_SETTINGS_ROUTE;

  navLinks: Array<{
    link: string;
    label: string;
    icon: string;
    hidden: boolean;
    feature: string;
  }> = [
    {
      link: PROCESS_LIST_ROUTE,
      label: $localize`:@@nav-links.process-types:Process Types`,
      icon: `view_list`,
      hidden: true,
      feature: this.hasGetProcessesPermission(),
    },
    {
      link: PROCESS_INSTANCE_LIST_ROUTE,
      label: $localize`:@@nav-links.processes:Processes`,
      icon: `view_week`,
      hidden: true,
      feature: this.hasGetProcessInstancesPermission(),
    },
    {
      link: TOR_ROUTE,
      label: $localize`:@@nav-links.tor:TOR`,
      icon: `insert_drive_file`,
      hidden: true,
      feature: this.hasGetTorsPermission(),
    },
    {
      link: TASK_LIST_ROUTE,
      label: $localize`:@@nav-links.tasks:Tasks`,
      icon: `assignment`,
      hidden: true,
      feature: this.hasGetProcessTaskPermission(),
    },
    {
      link: ASSIGNED_TASKS_ROUTE,
      label: $localize`:@@nav-links.collaborators-tasks:Collaborators Tasks`,
      icon: `assignment_ind`,
      hidden: true,
      feature: this.hasGetAssignedTasksPermission(),
    },
    {
      link: ASSIGNED_REVIEWS_ROUTE,
      label: $localize`:@@nav-links.my-review:My Review`,
      icon: `forum`,
      hidden: true,
      feature: this.hasGetMyReviewPermission(),
    },
    {
      link: RESEARCH_COUNCIL_ROUTE,
      label: $localize`:@@nav-links.research-council:Research Council`,
      icon: `supervised_user_circle`,
      hidden: true,
      feature: this.hasGetResearchCouncilUsersPermission(),
    },
    {
      link: PUBLICATION_ROUTE,
      label: $localize`:@@nav-links.publications:Publications`,
      icon: `library_books`,
      hidden: true,
      feature: this.hasGetPublicationsPermission(),
    },
    {
      link: ARCHIVED_ROUTE,
      label: $localize`:@@nav-links.archived:Archived`,
      icon: `archive`,
      hidden: true,
      feature: this.hasGetArchivedProcessInstancesPermission(),
    },
    {
      link: RESEARCHES_SETTINGS_ROUTE,
      label: $localize`:@@nav-links.settings:Settings`,
      icon: `settings`,
      hidden: true,
      feature: this.hasUploadCurrencyExcelPermission(),
    },
  ];
  navLinks$ = new Observable<any>();

  constructor(
    private userFacade: UserFacade,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userFacade.currentLoggedInUser$.subscribe((user) => {
      if (user) {
        this.updateMenuAuthorization();
      }
    });
  }

  updateMenuAuthorization() {
    this.navLinks$ = combineLatest([
      ...this.navLinks.map((item) => this.isAuthorized(item.feature)),
    ]).pipe(
      map((authorized: boolean[]) =>
        this.navLinks.map((item, index) => ({
          ...item,
          hidden: !authorized[index],
        })),
      ),
    );
  }

  isAuthorized(permission: string): Observable<boolean> {
    return this.userFacade
      .hasPermission(permission)
      .pipe(map((permission) => !!permission));
  }

  hasGetProcessesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Process.Feature,
      PERMISSION_NAMES.Researches.Process.GetProcesses,
    );
  }

  hasGetProcessInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.GetProcessInstances,
    );
  }

  hasGetProcessTaskPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.GetProcessTask,
    );
  }

  hasGetAssignedTasksPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Task.Feature,
      PERMISSION_NAMES.Researches.Task.GetCollaboratorsTasks,
    );
  }

  hasGetReviewsByStageInstancePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetReviewsByStageInstance,
    );
  }

  hasGetMyReviewPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.StageInstance.Feature,
      PERMISSION_NAMES.Researches.StageInstance.GetMyReview,
    );
  }

  hasGetArchivedProcessInstancesPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ProcessInstance.Feature,
      PERMISSION_NAMES.Researches.ProcessInstance.GetArchivedProcessInstances,
    );
  }

  hasGetPublicationsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Publication.Feature,
      PERMISSION_NAMES.Researches.Publication.GetPublications,
    );
  }

  hasGetPublicationOptionsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.PublicationOption.Feature,
      PERMISSION_NAMES.Researches.PublicationOption.GetPublicationOptions,
    );
  }

  hasGetTorsPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Tor.Feature,
      PERMISSION_NAMES.Researches.Tor.GetTors,
    );
  }

  hasGetEvaluationSchedulePermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.EvaluationSchedule.Feature,
      PERMISSION_NAMES.Researches.EvaluationSchedule.GetEvaluationSchedule,
    );
  }

  hasGetResearchCouncilUsersPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.ResearchCouncil.Feature,
      PERMISSION_NAMES.Researches.ResearchCouncil.GetResearchCouncilUsers,
    );
  }

  hasUploadCurrencyExcelPermission(): string {
    return GetFullPermissionName(
      MODULES.RESEARCHES,
      PERMISSION_NAMES.Researches.Currency.Feature,
      PERMISSION_NAMES.Researches.Currency.UploadCurrencyExcel,
    );
  }

  navigateToSettings() {
    this.router.navigate([RESEARCHES_SETTINGS_ROUTE]);
  }
}
