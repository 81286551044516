import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { TextValidator } from '../../validators/text.validator';
import { FieldValidationRule } from 'src/app/documents/field-validation-rules/models/field-validation-rule.model';
import { IntegerValidator } from '../../validators/integer.validator';
import { DecimalValidator } from '../../validators/decimal.validator';
import { BooleanValidator } from '../../validators/boolean.validator';
import { EmailValidator } from '../../validators/email.validator';
import { DateValidator } from '../../validators/date.validator';
import { PhoneValidator } from '../../validators/phone.validator';
import { WorkflowStepValidation } from 'src/app/documents/workflow-validation/models/worlfow-step-validation.model';
import { DocumentFieldForm } from 'src/app/documents/workflow-steps/components/workflow-step/workflow-step.component';
import { FormFields } from 'src/app/documents/template-fields/models/form-fields.model';
import { CurrencyValidator } from '../../validators/currency.validator';

export const ValidationRules = {
  TextContains: '8f0ed0d4-bde6-444d-875b-d9d2634dce0',
  TextNotContains: 'bbf8b8dc-2e74-45cb-ada6-f7d1d47df365',
  TextEndsWith: '12cfa684-1b98-4f9f-81a4-c1da5bbc1f13',
  TextStartsWith: 'cbd1a5aa-56fe-4732-8397-7b72b3c3a8ba',
  TextIsURL: '0c90e396-f03d-418f-94e8-d8995a3fe9b9',
  TextIsExactly: '8a824002-d36c-4c81-8ce0-376c4a9e3619',
  StepTextIsExactly: 'd01d9a28-283d-47bd-b953-08985a625008',
  TextIsEmpty: 'dea1687b-d3d0-44de-9936-0de42d857502',
  TextIsNotEmpty: '25051cc6-d81a-403b-85ca-205096d2a91b',
  IntegerIsEqual: '89742fa3-2f27-44cc-a50d-cd9507ca906c',
  IntegerIsGreaterThan: '8bdc74e2-8650-4afa-a311-681de1192c89',
  IntegerIsGreaterThanOrEqualTo: 'e8e47db9-f12a-4e9d-a4da-2c258501c3ab',
  IntegerIsLessThan: 'eb92af27-e734-4307-86ae-54807a0017e9',
  IntegerIsLessThanEqualTo: '86e766e8-ec51-4708-8526-8794a415ef01',
  IntegerIsNotEqualTo: '08cbbb16-c447-40d4-8c63-47acad9a7109',
  PhoneIsValid: 'f69d5831-b0eb-4a3a-973d-72ef3819d1d5',
  PhoneCountryCodeEquals: '35f5444b-f980-489d-9dad-d0a89203fc80',
  DecimalIsValid: '1fbef407-339b-4912-ae52-96d40bbbf809',
  DecimalHasPrecision: 'f224909b-268a-437d-846a-5f068e680390',
  EmailIsValid: '8d5df3d2-a4d9-4585-8959-f4df7b4eed13',
  DateIs: '4a372c4e-3506-488a-b716-294ca31af289',
  DateIsAfter: 'a9ef3f29-8a65-48e9-a917-670a8e7892da',
  DateIsOnOrAfter: '37675a39-6a05-4325-9a8e-c48351d03438',
  DateIsBefore: '66fe25c0-2e16-43f3-95ea-cebc9de7964e',
  DateIsOnOrBefore: 'ba3f084b-4080-4c3b-8bc3-9468e15994f8',
  DateIsBetween: '4145065d-a98f-48e5-b6c6-f0259d9cadcd',
  DateIsNotBetween: 'a14e1ea9-57f7-4290-bed5-22b102287c26',
  DateIsValid: 'bf04aa9f-fbfb-4489-aa96-0532b04ef8a0',
  DateHasStartAndEndDate: 'e6b3b510-4d2a-4b5d-a5d6-341e4a1e6de4',
  BooleanIsValid: '8e996115-5e09-42eb-ac94-7657f1f45b08',
  CurrencyIsValid: '61470d1-c5e8-4cae-919f-15a64349a320',
} as const;

@Injectable()
export class DocumentFieldControlService {
  static validators: Record<string, any> = {
    [ValidationRules.TextContains]: TextValidator.contains,
    [ValidationRules.TextNotContains]: TextValidator.notContains,
    [ValidationRules.TextStartsWith]: TextValidator.startsWith,
    [ValidationRules.TextEndsWith]: TextValidator.endsWith,
    [ValidationRules.TextIsExactly]: TextValidator.isExactly,
    [ValidationRules.StepTextIsExactly]: TextValidator.isExactly,
    [ValidationRules.TextIsEmpty]: TextValidator.isEmpty,
    [ValidationRules.TextIsNotEmpty]: TextValidator.isNotEmpty,

    [ValidationRules.IntegerIsEqual]: IntegerValidator.equalTo,
    [ValidationRules.IntegerIsGreaterThan]: IntegerValidator.greaterThan,
    [ValidationRules.IntegerIsGreaterThanOrEqualTo]:
      IntegerValidator.greaterThanOrEqualTo,
    [ValidationRules.IntegerIsLessThan]: IntegerValidator.lessThan,
    [ValidationRules.IntegerIsLessThanEqualTo]:
      IntegerValidator.lessThanOrEqualTo,
    [ValidationRules.IntegerIsNotEqualTo]: IntegerValidator.notEqualTo,

    [ValidationRules.PhoneIsValid]: PhoneValidator.isValidPhone,
    [ValidationRules.PhoneCountryCodeEquals]: PhoneValidator.countryCodeEquals,

    [ValidationRules.DateIs]: DateValidator.isDate,
    [ValidationRules.DateIsAfter]: DateValidator.isAfter,
    [ValidationRules.DateIsBefore]: DateValidator.isBefore,
    [ValidationRules.DateIsBetween]: DateValidator.isBetween,
    [ValidationRules.DateIsNotBetween]: DateValidator.isNotBetween,
    [ValidationRules.DateIsOnOrAfter]: DateValidator.isOnOrAfter,
    [ValidationRules.DateIsOnOrBefore]: DateValidator.isOnOrBefore,
    [ValidationRules.DateIsValid]: DateValidator.isValidDate,
    [ValidationRules.DateHasStartAndEndDate]: DateValidator.hasStartAndEndDate,

    [ValidationRules.EmailIsValid]: EmailValidator.isValidEmail,

    [ValidationRules.BooleanIsValid]: BooleanValidator.isValid,

    [ValidationRules.DecimalHasPrecision]: DecimalValidator.hasPrecision,
    [ValidationRules.DecimalIsValid]: DecimalValidator.isValidDecimal,
    [ValidationRules.CurrencyIsValid]: CurrencyValidator.isValid,
  };

  getValidators(rules: FieldValidationRule[]): ValidatorFn[] {
    return rules
      .map((rule) => {
        const fn =
          DocumentFieldControlService.validators[rule.validationRule.id];
        if (fn) {
          return fn.apply(
            null,
            rule.validationRuleValues
              .sort((a, b) => a.order - b.order)
              .map((r) => r.value),
          );
        }
        return null;
      })
      .filter((item) => !!item);
  }

  getStepValidators(validations: WorkflowStepValidation[]): ValidatorFn[] {
    return validations.map(
      (validation) =>
        (group: AbstractControl): ValidationErrors | null => {
          const documentFields = group.get(
            'documentFields',
          ) as FormArray<DocumentFieldForm>;
          const tableDocumentFields = group.get(
            'tableDocumentFields',
          ) as FormArray<FormArray<DocumentFieldForm>>;

          const leftFields = this.getControllers(
            validation.leftField,
            documentFields,
            tableDocumentFields,
          );

          const rightFields = this.getControllers(
            validation.rightField,
            documentFields,
            tableDocumentFields,
          );

          if (!leftFields || !rightFields) return null;

          const fn =
            DocumentFieldControlService.validators[
              validation.workflowTypeStepValidationRule.id!
            ];
          if (!fn) return null;

          if (!Array.isArray(leftFields) && !Array.isArray(rightFields)) {
            const result = fn(rightFields.value.fieldValue)(
              leftFields.controls.fieldValue,
            );
            leftFields.controls.fieldValue.setErrors(result);
          }

          if (Array.isArray(leftFields) && Array.isArray(rightFields)) {
            leftFields.forEach((left, index) => {
              const result = fn(rightFields[index].value.fieldValue)(
                left.controls.fieldValue,
              );
              left.controls.fieldValue.setErrors(result);
            });
          }

          if (!Array.isArray(leftFields) && Array.isArray(rightFields)) {
            rightFields.forEach((right) => {
              const result = fn(right.value.fieldValue)(
                leftFields.controls.fieldValue,
              );
              leftFields.controls.fieldValue.setErrors(result);
            });
          }

          if (Array.isArray(leftFields) && !Array.isArray(rightFields)) {
            leftFields.forEach((left) => {
              const result = fn(rightFields.value.fieldValue)(
                left.controls.fieldValue,
              );
              left.controls.fieldValue.setErrors(result);
            });
          }

          return null;
        },
    );
  }

  getControllers(
    field: FormFields,
    documentFields: FormArray<DocumentFieldForm>,
    tableDocumentFields: FormArray<FormArray<DocumentFieldForm>>,
  ) {
    if (!field.groupName)
      return documentFields.controls.find(
        (control) => control.controls.templateField.value.id == field.id,
      );

    return tableDocumentFields.controls.flatMap((row) =>
      row.controls.filter(
        (control) => control.controls.templateField.value.id == field.id,
      ),
    );
  }
}
