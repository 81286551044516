import { PaginatedList } from 'src/app/core/models/paginated-list.interface';
import { EvaluationScheduleStatus, Schedule } from '../models/schedule.model';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
  SetProgressOff,
  SetProgressOn,
} from 'src/app/core/store/progress-status.actions';
import { tap } from 'rxjs';
import { EvaluationScheduleService } from '../services/evaluation.schedule.service';
import {
  AssignStageInstanceReviewers,
  CreateEvaluationSchedule,
  CreateMeetingSchedule,
  DeleteEvaluationSchedule,
  GetEvaluationDetail,
  DeleteMeetingSchedule,
  GetEvaluationScheduleDetail,
  GetEvaluationSchedules,
  GetStageInstanceReviewers,
  SetEditMode,
  SetSelectedEvaluationSchedule,
  UpdateEvalutionSchedule,
  UpdateMeetingSchedule,
  SetUpateStatus,
  DeleteStageInstanceReviewer,
  FinishEvaluationSchedule,
  GetEvaluationScheduleStatus,
  AssignStageInstanceReviewerTypes,
  FinishStageInstanceEvaluation,
  CreateScheduleSuccess,
  CreateMeetingSuccess,
} from './evaluation-schedule.actions';

import { successStyle } from 'src/app/core/services/operation-status/status-style-names';
import { OperationStatusService } from 'src/app/core/services/operation-status/operation-status.service';
import { Injectable } from '@angular/core';
import { Reviewer } from '../models/reviewer.model';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { EvaluationDetail } from '../models/evaluation.model';
import { SetUpdateStatus } from '../../store/process.actions';

export interface EvaluationScheduleStateModel {
  schedules: PaginatedList<Schedule>;
  selectedSchedule: Schedule | undefined;
  stageInstanceReviewers: Reviewer[];
  editMode: boolean | undefined;
  evaluationDetail?: EvaluationDetail;
  update: boolean;
  evaluationScheduleStatus?: EvaluationScheduleStatus;
  length: number,
  isCreateScheduleSuccess: boolean;
}

const EVALUATION_SCHEDULE_STATE_TOKEN =
  new StateToken<EvaluationScheduleStateModel>('evaluationScheduleState');

const defaults: EvaluationScheduleStateModel = {
  selectedSchedule: undefined,
  editMode: undefined,
  schedules: {
    items: [],
    pageNumber: 0,
    totalPages: 0,
    totalCount: 0,
  },
  stageInstanceReviewers: [],
  update: false,
  length: 0,
  isCreateScheduleSuccess: false
};

@State<EvaluationScheduleStateModel>({
  name: EVALUATION_SCHEDULE_STATE_TOKEN,
  defaults: defaults,
})
@Injectable()
export class EvaluationScheduleState {
  constructor(
    private store: Store,
    private evaluationScheduleService: EvaluationScheduleService,
    private operationStatus: OperationStatusService,
  ) {}

  @Action(GetEvaluationSchedules)
  getEvaluationSchedules(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { pageNumber, pageSize }: GetEvaluationSchedules,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .getEvaluationSchedules(pageNumber, pageSize)
      .pipe(
        tap((schedules) => {
          patchState({
            schedules: schedules,
            length: schedules.totalCount
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(CreateEvaluationSchedule)
  createEvuationSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { schedule }: CreateEvaluationSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService.createEvuationSchedule(schedule).pipe(
      tap(() => {
        this.store.dispatch(new GetEvaluationSchedules(1, 10));
        this.store.dispatch(new CreateScheduleSuccess())
        this.operationStatus.displayStatus(
          $localize`:@@researches.evaluation-schedule.schedule-is-created-successfully: schedule is created successfully`,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action(GetEvaluationScheduleDetail)
  getEvaluationScheduleDetail(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { id }: GetEvaluationScheduleDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService.getEvaluationScheduleDetail(id).pipe(
      tap((schedule) => {
        patchState({
          selectedSchedule: schedule,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(UpdateEvalutionSchedule)
  updateEvaluationSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { schedule }: UpdateEvalutionSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .updateEvaluationSchedule(schedule)
      .pipe(
        tap(() => {
          this.store.dispatch(new GetEvaluationSchedules(1, 10));
          this.store.dispatch(new CreateScheduleSuccess())
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.schedule-is-updated-successfully: schedule is updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetEditMode)
  setEditMode(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { update }: SetEditMode,
  ) {
    setState(
      patch({
        editMode: update,
      }),
    );
  }

  @Action(SetSelectedEvaluationSchedule)
  setSelectedEvaluationSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { schedule }: SetSelectedEvaluationSchedule,
  ) {
    setState(
      patch({
        selectedSchedule: schedule,
      }),
    );
  }

  @Action(DeleteEvaluationSchedule)
  deleteEvaluationSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { id }: DeleteEvaluationSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService.deleteEvaluationSchedule(id).pipe(
      tap(() => {
        setState(
          patch({
            schedules: patch({
              items: removeItem((schedule) => {
                return schedule.id === id;
              }),
            }),
          }),
        ),
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.schedule-is-deleted-successfully: schedule is deleted successfully`,
            successStyle,
          );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(GetStageInstanceReviewers)
  getStageInstanceReviewers(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { id }: GetStageInstanceReviewers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService.getStageInstanceReviewers(id).pipe(
      tap((stageInstanceReviewers) => {
        patchState({
          stageInstanceReviewers: stageInstanceReviewers,
        });
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }

  @Action(AssignStageInstanceReviewers)
  assignStageInstanceReviewers(
    { patchState, getState }: StateContext<EvaluationScheduleStateModel>,
    { id, reviewerIds }: AssignStageInstanceReviewers,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService.assignReviewers(reviewerIds, id).pipe(
      tap((newStageReviewers) => {
        patchState({
          stageInstanceReviewers: [
            ...getState().stageInstanceReviewers,
            ...newStageReviewers,
          ],
        });
        this.operationStatus.displayStatus(
          $localize`:@@researches.evaluation-schedule.documents-shared-successfully: documents shared successfully `,
          successStyle,
        );
        this.store.dispatch(new SetProgressOff());
      }),
    );
  }
  @Action([CreateMeetingSchedule])
  createMeetingSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { meetingSchedule }: CreateMeetingSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .createMeetingSchedule(meetingSchedule)
      .pipe(
        tap((createdMeetingSchedule) => {
          setState(
            patch({
              selectedSchedule: patch({
                meetingSchedule: createdMeetingSchedule,
              }),
            }),
          );
          this.store.dispatch(new CreateMeetingSuccess())
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.meeting-schedule-created-successfully: meeting schedule created successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetEvaluationDetail)
  getEvaluationDetail(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { stageInstanceId }: GetEvaluationDetail,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .getEvaluationDetail(stageInstanceId)
      .pipe(
        tap((detail) => {
          patchState({
            evaluationDetail: detail,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(UpdateMeetingSchedule)
  updateMeetingSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { meetingSchedule }: UpdateMeetingSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .updateMeetingSchedule(meetingSchedule)
      .pipe(
        tap((updatedMeeting) => {
          setState(
            patch({
              selectedSchedule: patch({
                meetingSchedule: updatedMeeting,
              }),
            }),
          );
          this.store.dispatch(new CreateMeetingSuccess())
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.meeting-schedule-updated-successfully: meeting schedule updated successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(DeleteMeetingSchedule)
  deleteMeetingSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { meetingScheduleId }: DeleteMeetingSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .deleteMeetingSchedule(meetingScheduleId)
      .pipe(
        tap(() => {
          setState(
            patch({
              selectedSchedule: patch({
                meetingSchedule: undefined,
              }),
            }),
          ),
            this.operationStatus.displayStatus(
              $localize`:@@researches.evaluation-schedule.meeting-schedule-deleted-successfully: meeting schedule deleted successfully`,
              successStyle,
            );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(SetUpateStatus)
  setUpdateStatus(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { update }: SetUpateStatus,
  ) {
    patchState({
      update: update,
    });
  }

  @Action(DeleteStageInstanceReviewer)
  deleteStageInstanceReviewer(
    { setState, getState }: StateContext<EvaluationScheduleStateModel>,
    { stageInstanceId, reviewerId }: DeleteStageInstanceReviewer,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .deleteStageInstanceReviewer(stageInstanceId, reviewerId)
      .pipe(
        tap(() => {
          setState(
            patch({
              stageInstanceReviewers: removeItem(
                (reviewer) => reviewer.reviewerDetail.id === reviewerId,
              ),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.reviewer-deleted-successfully: reviewer deleted successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(FinishEvaluationSchedule)
  finishEvaluationSchedule(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { evaluationScheduleId }: FinishEvaluationSchedule,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .finishEvaluationSchedule(evaluationScheduleId)
      .pipe(
        tap(() => {
          setState(
            patch({
              evaluationScheduleStatus: patch({
                evaluationStatus: 'Finished',
                isFinishable: false,
              }),
            }),
          ),
            this.operationStatus.displayStatus(
              $localize`:@@researches.evaluation-schedule.evaluation-schedule-finished-successfully: Evaluation schedule finished successfully`,
              successStyle,
            );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(GetEvaluationScheduleStatus)
  getEvaluationScheduleStatus(
    { patchState }: StateContext<EvaluationScheduleStateModel>,
    { evaluationScheduleId }: GetEvaluationScheduleStatus,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .getEvaluationScheduleStatus(evaluationScheduleId)
      .pipe(
        tap((status) => {
          patchState({
            evaluationScheduleStatus: status,
          });
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(AssignStageInstanceReviewerTypes)
  assignStageInstanceReviewerTypes(
    {
      patchState,
      getState,
      setState,
    }: StateContext<EvaluationScheduleStateModel>,
    { stageInstanceId, assignReviewerType }: AssignStageInstanceReviewerTypes,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .assignReviewerType(stageInstanceId, assignReviewerType)
      .pipe(
        tap((newStageReviewers) => {
          setState(
            patch({
              stageInstanceReviewers: updateItem(
                (reviewer) =>
                  reviewer.reviewerDetail.id === assignReviewerType.reviewerId,
                newStageReviewers,
              ),
            }),
          );
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.reviewer-type-set-successfully: Reviewer type set successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }

  @Action(FinishStageInstanceEvaluation)
  finishStageInstanceEvaluation(
    { setState }: StateContext<EvaluationScheduleStateModel>,
    { evaluationScheduleId, stageInstanceId }: FinishStageInstanceEvaluation,
  ) {
    this.store.dispatch(new SetProgressOn());
    return this.evaluationScheduleService
      .FinishStageInstanceEvaluation(evaluationScheduleId, stageInstanceId)
      .pipe(
        tap(() => {
          this.operationStatus.displayStatus(
            $localize`:@@researches.evaluation-schedule.stage-instance-evaluation-finished-successfully: stage instance evaluation finished successfully`,
            successStyle,
          );
          this.store.dispatch(new SetProgressOff());
        }),
      );
  }
}
