import { Component, Inject } from '@angular/core';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  regularTextOne: string | undefined;
  regularTextTwo: string | undefined;
  boldText: string | undefined;
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      regularTextOne: string,
      regularTextTwo: string,
      boldText: string
     },
  ) {
    this.regularTextOne = this.data.regularTextOne;
    this.regularTextTwo = this.data.regularTextTwo;
    this.boldText = this.data.boldText;
    this.dialogRef.disableClose = true;
  }

  onConfirm() {
    this.dialogRef.close('confirm');
  }

  onCancel() {
    this.dialogRef.close();
  }
}
