import { Selector, createPropertySelectors } from '@ngxs/store';
import {
  EvaluationScheduleState,
  EvaluationScheduleStateModel,
} from './evaluation-schedule.state';

export class EvaluationScheduleSelector {
  static slices = createPropertySelectors<EvaluationScheduleStateModel>(
    EvaluationScheduleState,
  );
  @Selector([EvaluationScheduleState])
  static evaluationSchedules(stateModel: EvaluationScheduleStateModel) {
    return stateModel.schedules;
  }

  @Selector([EvaluationScheduleState])
  static evaluationScheduledetail(state: EvaluationScheduleStateModel) {
    return state.selectedSchedule;
  }
  @Selector([EvaluationScheduleState])
  static stageInstanceReviewers(state: EvaluationScheduleStateModel) {
    return state.stageInstanceReviewers;
  }

  @Selector([EvaluationScheduleState])
  static editMode(state: EvaluationScheduleStateModel) {
    return state.editMode;
  }

  @Selector([EvaluationScheduleState])
  static evaluationDetail(state: EvaluationScheduleStateModel) {
    return state.evaluationDetail;
  }

  @Selector([EvaluationScheduleState])
  static update(state: EvaluationScheduleStateModel) {
    return state.update;
  }

  @Selector([EvaluationScheduleState])
  static evaluationScheduleStatus(state: EvaluationScheduleStateModel) {
    return state.evaluationScheduleStatus;
  }
  @Selector([EvaluationScheduleState])
  static EvaluationScheduleLength(state: EvaluationScheduleStateModel) {
    return state.length;
  }
}
