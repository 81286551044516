import { Component, OnDestroy, OnInit } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { NoticesFacade } from '../../facade/notices.facade';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Role } from 'src/app/users/models/role.model';
import { User } from 'src/app/users/models/user.model';
import { Office } from 'src/app/offices/models/office.model';
import { FlatOfficeNode } from 'src/app/offices/models/flat-office-node.model';
import { Observable, filter } from 'rxjs';
import { UserFacade } from 'src/app/users/facade/user.facade';
import { RoleFacade } from 'src/app/users/facade/role.facade';
import { OfficeFacade } from 'src/app/offices/facades/office.facades';
import { FlatFolderNode } from 'src/app/files/models/flat-folder-node.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfficeMultipleSelectComponent } from 'src/app/offices/components/office-multiple-select/office-multiple-select.component';
import Quill from 'quill';
import { NOTICES_ROUTE } from 'src/app/core/constants/routes';
import { QuillEditorToolbarOptions } from 'src/app/core/constants/quill-editor-constants';
import { Notice, NoticeAccessData } from '../../models/notices.model';
import { ConfirmDialogComponent } from 'src/app/shared/shared-components/confirm-dialog/confirm-dialog.component';

interface selectedOfficeComponentState {
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined;
}

const initOfficeListComponentState: selectedOfficeComponentState = {
  selectedFlatOfficeNodes: [],
};

interface RoleListComponentState {
  roles: Role[];
}

const initRoleListComponentState: Partial<RoleListComponentState> = {
  roles: [],
};

interface UserListComponentState {
  filterdUsers: User[];
  filterdUsersByMultipleRoles: User[];

}

const initUserListComponentState: Partial<UserListComponentState> = {
  filterdUsers: [],
};

interface NoticesFormComponentState {
  selectedNotice: Notice | undefined;
  update: boolean;
  noticeAccessData: NoticeAccessData | undefined;
}

const initNoticesFormComponentState: NoticesFormComponentState = {
  selectedNotice: undefined,
  update: false,
  noticeAccessData: undefined,
};

@Component({
  selector: 'app-notices-list',
  templateUrl: './notices-form.component.html',
  styleUrls: ['./notices-form.component.scss'],
  providers: [RxState],
})
export class NoticesFormComponent implements OnInit, OnDestroy {
  noticeForm: FormGroup;
  update$: Observable<boolean> = this.state.select('update');
  update: boolean = false;
  files: File[] = [];
  isDragOver = false;
  roles: Role[] = [];
  users: User[] = [];
  offices: Office[] = [];
  selectedFlatOfficeNodes$: Observable<FlatOfficeNode[] | undefined> =
    this.officeState.select('selectedFlatOfficeNodes');
  selectedFlatOfficeNodes: FlatOfficeNode[] | undefined = [];
  selctedFlatFolderNode: FlatFolderNode | null = null;
  roles$: Observable<Role[]> = this.roleState.select('roles');
  filterdUsers$: Observable<User[]> = this.userState.select('filterdUsers');
  filterdUsersByMultipleRoles$: Observable<User[]>=this.userState.select('filterdUsersByMultipleRoles');
  editor?: Quill;
  composeIsValid: boolean = false;
  initialEditorContent: any;
  selectedNotice$: Observable<Notice | undefined> =
    this.state.select('selectedNotice');
  selectedNotice: Notice | undefined;
  noticeAccessData$: Observable<NoticeAccessData | undefined> =
    this.state.select('noticeAccessData');
  noticeAccessData: NoticeAccessData | undefined = undefined;

  placeholderFieldLabel = {
    noticeTitleField: $localize`:@@documents.notices-form.notice-title: Notice title`,
  };

  constructor(
    private noticesFacade: NoticesFacade,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private userFacade: UserFacade,
    private roleFacade: RoleFacade,
    private officeFacade: OfficeFacade,
    private state: RxState<NoticesFormComponentState>,
    private officeState: RxState<selectedOfficeComponentState>,
    private roleState: RxState<RoleListComponentState>,
    private userState: RxState<UserListComponentState>,
  ) {
    this.noticeForm = this.fb.group({
      title: ['', [Validators.required]],
      selectedRoles: [[]],
      selectedUsers: [[]],
    });
    this.officeState.set(initOfficeListComponentState);
    this.officeState.connect(
      'selectedFlatOfficeNodes',
      this.officeFacade.selectedFlatOfficeNodes$,
    );
    this.roleState.set(initRoleListComponentState);
    this.roleState.connect('roles', this.roleFacade.roles$);
    this.userState.set(initUserListComponentState);
    this.userState.connect('filterdUsers', this.userFacade.filterdUsers$);
    this.userState.connect('filterdUsersByMultipleRoles', this.userFacade.filteredUsersByMultipleRoles$);
    this.state.set(initNoticesFormComponentState);
    this.state.connect('update', this.noticesFacade.update$);
    this.state.connect('selectedNotice', this.noticesFacade.selectedNotice$);
    this.state.connect(
      'noticeAccessData',
      this.noticesFacade.noticeAccessData$,
    );
  }

  ngOnInit(): void {
    this.update$.subscribe((updateStatus) => {
      this.update = updateStatus;
      this.updateForm();
      this.fillEditorWithInitialContent();
    });

    this.selectedFlatOfficeNodes$.subscribe((selectedFlatOfficeNodes) => {
      this.selectedFlatOfficeNodes = selectedFlatOfficeNodes;
      this.onRoleSelect();
    });
    this.roleFacade.dispatchGetRoles();
    this.roles$.subscribe((roles) => (this.roles = roles));
    this.filterdUsers$.subscribe((filterdUsers) => (this.users = filterdUsers));

    this.editor = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: QuillEditorToolbarOptions,
      },
    });

    this.selectedNotice$.subscribe((notice) => {
      if (notice) {
        this.selectedNotice = notice;
        this.updateForm();
        this.fillEditorWithInitialContent();
      }
    });
    this.editor.on('text-change', this.handleTextChange);
    this.noticeAccessData$.subscribe((noticeAccessData) => {
      if (noticeAccessData) {
        this.noticeAccessData = noticeAccessData;
        this.updateForm();
        this.fillEditorWithInitialContent();
      }
    });
    this.officeFacade.dispatchResetSelectedOffices();
  }

  ngOnDestroy(): void {
    this.editor?.off('text-change', this.handleTextChange);
  }

  handleTextChange = () => {
    this.composeIsValid = !!this.editor?.getText().trim();
  };

  openMultipleOfficeChoice() {
    this.matDialog.open(OfficeMultipleSelectComponent, {
      disableClose: true,
    });
  }
  onRoleSelect() {
    const selectedRoles = this.noticeForm.get('selectedRoles');
    const selectedUsers = this.noticeForm.get('selectedUsers');
    const roleIds = selectedRoles?.value.map((r: Role) => r.id);
    const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);

    if (roleIds && officeIds && roleIds.length > 0 && officeIds.length > 0) {
      this.userFacade.dispachGetUsersByRolesAndOffices(roleIds, officeIds);
      this.filterdUsers$.subscribe((filterdUsers) => {
        this.users = filterdUsers;
        const tempselectedUsers = selectedUsers?.value as User[];
        const tempselectedUsersCopy = selectedUsers?.value as User[];
        tempselectedUsersCopy.forEach((user, index) => {
          const position = this.users.findIndex((u) => u.id === user.id);
          if (position < 0) {
            tempselectedUsers.splice(index, 1);
            selectedUsers?.setValue(tempselectedUsers);
          }
        });
      });
    }
    if (
      roleIds &&
      officeIds &&
      (roleIds.length === 0 || officeIds.length === 0)
    ) {
      this.userFacade.dispachSetFilterdUsersEmpty();
      selectedUsers?.setValue([]);
    }
  }
  removeSelectedUser(user: User) {
    const selectedUsers = this.noticeForm.get('selectedUsers');
    const users = selectedUsers?.value as User[];
    const filteredUsers = users.filter((u) => u.id !== user.id);
    selectedUsers?.setValue(filteredUsers);
  }

  removeSelectedRole(role: Role) {
    const selectedRoles = this.noticeForm.get('selectedRoles');
    const roles = selectedRoles?.value as Role[];
    const filteredRoles = roles.filter((r) => r.id !== role.id);
    selectedRoles?.setValue(filteredRoles);
    this.onRoleSelect()
  }

  removeSelectedOffice(office: FlatOfficeNode) {
    this.onRoleSelect();
    const selectedUsers = this.noticeForm.get('selectedUsers')?.value as User[];
    selectedUsers.map(user => {
      if(user.officeId == office.id){
        this.removeSelectedUser(user)
      }
    })
    if (this.selectedFlatOfficeNodes === undefined) return;
    const index = this.selectedFlatOfficeNodes.indexOf(office);

    if (index >= 0) {
      this.selectedFlatOfficeNodes.splice(index, 1);
      this.officeFacade.dispatchSelectFlatOfficeNodes(
        this.selectedFlatOfficeNodes,
      );
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = await event.dataTransfer?.files;
    if (files) {
      const selectedFiles = Array.from(files);

      const newFiles = selectedFiles.filter(file => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }   
        return  !this.files.some((existingFile) => existingFile.name === file.name);
      });
      this.files = this.files.concat(newFiles);
    }
    this.isDragOver = false;
  }

  selectFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const selectedFiles = Array.from(target.files);

        const newFiles = selectedFiles.filter(file => {
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          if (fileExtension === 'exe') {
            this.snackBar.open('Invalid file type.', 'Close', {
              duration: 3000,
            }); 
            return false;
          }
          
          return  !this.files.some((existingFile) => existingFile.name === file.name);
        });
        this.files = this.files.concat(newFiles);
      }
    };
    fileInput.click();
    this.noticeForm.markAsTouched();
  }

  removeFile(file: File) {
    this.files = this.files.filter(f => f !== file);
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files) as File[];
    
      const newFiles = selectedFiles.filter((file: File) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (fileExtension === 'exe') {
          this.snackBar.open('Invalid file type.', 'Close', {
            duration: 3000,
          });
          return false;
        }
        return !this.files.some(existingFile => existingFile.name === file.name);
      });

      this.files = this.files.concat(newFiles);
    }
  }

  isSaveButtonDisabled() {
    if (this.update) {
      if (
        this.noticeForm.valid &&
        (this.composeIsValid || this.files.length > 0) &&
        this.selectedNotice?.noticeStatus?.status == 'Draft'
      )
        return true;
      return false;
    }
    if (
      this.noticeForm.valid &&
      (this.composeIsValid || this.files.length > 0)
    )
      return false;
    return true;
  }
  isSendButtonDisabled() {
    if (this.noticeForm.valid && (this.composeIsValid || this.files.length > 0)) return false;
    return true;
  }

  createNotices() {
    const { valid, touched, dirty } = this.noticeForm;
    if (
      this.editor &&
      (this.composeIsValid || this.files.length > 0) &&
      valid &&
      (this.update || touched || dirty)
    ) {
      const { title, selectedRoles, selectedUsers } = this.noticeForm.value;
      const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
      const body = JSON.stringify(this.editor.getContents());
      if (!officeIds) return;
      const formData = this.organaizeNotice(
        title,
        body,
        officeIds,
        selectedRoles,
        selectedUsers,
      );
      if (this.update && this.selectedNotice?.id) {
        this.noticesFacade.dispatchUpdateNotice(
          this.selectedNotice?.id,
          formData,
          false,
        );
        this.router.navigate([NOTICES_ROUTE]);
        return;
      }
      this.noticesFacade.dispatchCreateNotice(formData, false);
      this.router.navigate([NOTICES_ROUTE]);
    }
  }

  sendNotices() {
    const { valid, touched, dirty } = this.noticeForm;
    if (
      this.editor &&
      (this.composeIsValid || this.files.length > 0) &&
      valid &&
      (this.update || touched || dirty)
    ) {
      const { title, selectedRoles, selectedUsers } = this.noticeForm.value;
      const officeIds = this.selectedFlatOfficeNodes?.map((node) => node.id);
      const body = JSON.stringify(this.editor.getContents());
      if (!officeIds) return;
      const formData = this.organaizeNotice(
        title,
        body,
        officeIds || [],
        selectedRoles,
        selectedUsers,
      );

      if (this.update && this.selectedNotice?.id) {
        this.noticesFacade.dispatchUpdateNotice(
          this.selectedNotice?.id,
          formData,
          true,
        );
        this.router.navigate([NOTICES_ROUTE]);
        return;
      }
      this.noticesFacade.dispatchCreateNotice(formData, true);
      this.router.navigate([NOTICES_ROUTE]);
    }
  }

  organaizeNotice(
    title: string,
    body: string,
    officeIds: string[],
    selectedRoles: Role[],
    selectedUsers: User[],
  ): FormData {
    const formData = new FormData();
    formData.append('Title', title);
    formData.append('Body', body);
    officeIds?.forEach((id) => {
      formData.append('SentToOfficeIds', id);
    });
    selectedRoles.forEach((role: Role) => {
      formData.append('SentToRoleIds', role.id);
    });
    selectedUsers.forEach((user: User) => {
      if (user.id != (null || undefined)) {
        formData.append('SentToUserIds', user.id);
      }
    });
    this.files.forEach((file) => {
      formData.append('Attachments', file);
    });
    return formData;
  }
  updateForm() {
    if (this.update && this.selectedNotice && this.noticeAccessData) {
      this.noticeForm.setValue({
        title: this.selectedNotice.title,
        selectedRoles: this.noticeAccessData.sentToRoles,
        selectedUsers: this.noticeAccessData.sentToUsers,
      });
      this.selectedFlatOfficeNodes = this.noticeAccessData.sentToOffices;
      this.onRoleSelect();
    }
  }
  fillEditorWithInitialContent() {
    if (this.selectedNotice?.body && this.update) {
      this.initialEditorContent = this.selectedNotice?.body;
      if (this.editor && this.initialEditorContent) {
        this.editor.setContents(JSON.parse(this.initialEditorContent), 'api');
      }
    }
  }

  deleteNoticeAttachment(attachmentId: string | undefined) {
    if (!this.selectedNotice?.id) return;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        regularTextOne: $localize`:@@documents.notices-form.delete-attachment:Are you sure you want to delete the attachment?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        if (this.selectedNotice?.id && attachmentId)
          this.noticesFacade.dispatchDeleteNoticeAttachment(
            this.selectedNotice?.id,
            attachmentId,
          );
      }
    });
  }
}
