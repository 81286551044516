<h1 mat-dialog-title class="dialog-title">
  {{ createEditToggle.value.title }}
</h1>

<mat-dialog-content mat-dialog-content class="form-container">
  <form [formGroup]="taskForm" class="stage-form">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.task-form.name">Name</mat-label>
      <input
        matInput
        [placeholder]="placeholderToggleLabel.taskName"
        formControlName="name"
        [attr.data-test-id]="'researches.create-and-update-task.task-form.name-input'"
      />
      <mat-error
        *ngIf="taskForm.controls.name.errors?.['required']"
        i18n="@@researches.task-form.please-provide-valid-name-for-the-task"
      >
        Please provide a valid name for the Task.
      </mat-error>
      <mat-error
        *ngIf="taskForm.controls.name.errors?.['taskNameAlreadyTaken']"
        i18n="@@researches.task-form.task-name-already-taken"
      >
        Task name already taken.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.task-form.description"
        >Description</mat-label
      >
      <textarea
        matInput
        formControlName="description"
        [placeholder]="placeholderToggleLabel.taskDescription"
        [attr.data-test-id]="'researches.create-and-update-task.task-form.description-input'"
      ></textarea>
      <mat-error
        *ngIf="taskForm.controls.description.errors?.['required']"
        i18n="
          @@researches.task-form.please-provide-valid-description-for-the-task"
      >
        Please provide a valid description for the task
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="outline">
      <mat-label i18n="@@researches.task-form.assignee">Assignee</mat-label>

      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #assigneeChipGrid aria-label="Approvers">
        <mat-chip-row
          (removed)="removeAssignee()"
          *ngIf="taskForm.value.assignee"
        >
          {{ taskForm.value.assignee.firstName }}
          {{ taskForm.value.assignee.middleName }}
          {{ taskForm.value.assignee.lastName }}
          <button
            matChipRemove
            [attr.aria-label]="
              'remove ' +
              taskForm.value.assignee.firstName +
              ' ' +
              taskForm.value.assignee.middleName +
              ' ' +
              taskForm.value.assignee.lastName
            "
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #approverInput
        [placeholder]="placeholderToggleLabel.assigneeField"
        formControlName="assigneeSearchQuery"
        [matChipInputFor]="assigneeChipGrid"
        [matAutocomplete]="auto"
        [attr.data-test-id]="'researches.assignee-task.task-form.assignee-input'"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selecteAssignee($event)"
      >
        <mat-option
          *ngFor="let assignee of filteredAssignee"
          [value]="assignee"
          [attr.data-test-id]="'researches.assignee-task.task-form.assignee-mat-option'"
        >
          {{
            assignee.firstName +
              " " +
              assignee.middleName +
              " " +
              assignee.lastName
          }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button
      mat-stroked-button
      class="add-type-btn"
      *ngIf="isAddTypeButtonVisible"
      (click)="addNewTaskType()"
      [attr.data-test-id]="'researches.create-task-type.task-form.create-new-type-button'"
    >
      <mat-icon>add</mat-icon>
      <span i18n="@@researches.task-form.create-new-type">Create new type</span>
    </button>

    <mat-form-field class="full-width" appearance="outline" [attr.data-test-id]="'researches.create-task-type.task-form.task-type-form-field'">
      <mat-label i18n="@@researches.task-form.task-type" >Task Type</mat-label>

      <mat-icon matPrefix>{{ "search" }}</mat-icon>
      <mat-chip-grid #taskTypeChipGrid aria-label="TaskTypes">
        <mat-chip-row
          (removed)="removetaskType()"
          *ngIf="taskForm.value.taskType"
        >
          {{ taskForm.value.taskType.name }}
          <button
            matChipRemove
            [attr.aria-label]="'remove ' + taskForm.value.taskType.name"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        #taskTypeInput
        [placeholder]="placeholderToggleLabel.taskType"
        formControlName="taskTypeSearchQuery"
        [matChipInputFor]="taskTypeChipGrid"
        [matAutocomplete]="taskAuto"
        [attr.data-test-id]="'researches.create-task-type.task-form.task-type-input'"
      />
      <mat-autocomplete
        #taskAuto="matAutocomplete"
        (optionSelected)="selectTaskType($event)"
      >
        <mat-option
          *ngFor="let taskType of filteredTaskType$ | async"
          [value]="taskType"
        >
          {{ taskType.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <section i18n="@@researches.task-form.deadline">Deadline</section>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label i18n="@@researches.task-form.start-and-end-date"
        >Start and End Date</mat-label
      >
      <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
        <input
          matStartDate
          [placeholder]="placeholderToggleLabel.startDate"
          formControlName="startDate"
          [attr.data-test-id]="'researches.create-task.task-form.start-date-input'"
        />
        <input
          matEndDate
          [placeholder]="placeholderToggleLabel.endDate"
          formControlName="endDate"
          [attr.data-test-id]="'researches.create-task.task-form.end-date-input'"
        />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close i18n="@@researches.task-form.cancel">
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="submit()"
    [disabled]="taskForm.invalid"
    [attr.data-test-id]="'researches.create-and-update-task.task-form.create-or-update-button'"
  >
    {{ createEditToggle.value.submitButton }}
  </button>
</div>
