import {
  StageInstance,
  UpdateStageInstanceStatusDto,
} from '../models/process-instance.model';
import {
  StageInstanceReview,
  StageInstanceByReviewers,
} from '../models/stage-instance-detail.model';

export class SetSelectedStageInstance {
  static readonly type = `[StageInstance] ${SetSelectedStageInstance.name}`;
  constructor(public stageInstance: StageInstance) {}
}

export class UpdateStageInstanceStatus {
  static readonly type = `[StageInstance] ${UpdateStageInstanceStatus.name}`;
  constructor(public updateStageInstance: UpdateStageInstanceStatusDto) {}
}

export class GetStageInstanceWithoutSchedule {
  static readonly type = `[StageInstance] ${GetStageInstanceWithoutSchedule.name}`;
  constructor() {}
}
export class GetAssignedStageInstances {
  static readonly type = `[StageInstance] ${GetAssignedStageInstances.name}`;
  constructor(
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}

export class SetSelectedAssignedStageInstance {
  static readonly type = `[StageInstance] ${SetSelectedAssignedStageInstance.name}`;
  constructor(public assignedStageInstance: StageInstanceByReviewers) {}
}

export class ReivewStageInstance {
  static readonly type = `[StageInstance] ${ReivewStageInstance.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly review: FormData,
  ) {}
}

export class UpdateStageInstanceReview {
  static readonly type = `[StageInstance] ${UpdateStageInstanceReview.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly reviewId: string,
    public readonly review: FormData,
  ) {}
}

export class GetMyStageInstanceReview {
  static readonly type = `[StageInstance] ${GetMyStageInstanceReview.name}`;
  constructor(public readonly stageInstanceId: string) {}
}

export class SetMyStageInstanceReviewNull {
  static readonly type = `[StageInstance] ${SetMyStageInstanceReviewNull.name}`;
  constructor() {}
}

export class GetStageInstanceReview {
  static readonly type = `[StageInstance] ${GetStageInstanceReview.name}`;
  constructor(
    public readonly stageInstanceId: string,
    public readonly pageNumber: number,
    public readonly pageSize: number,
  ) {}
}
export class GetStageInstanceReviews {
  static readonly type = `[StageInstance] ${GetStageInstanceReviews.name}`;
  constructor(
    public readonly stageInstanceId: string,
  ) {}
}
export class GetStageInstanceReviewerType {
  static readonly type = `[StageInstance] ${GetStageInstanceReviewerType.name}`;
  constructor(
    public readonly stageInstanceId: string,
  ) {}
}
export class DeleteReviewAttachment {
  static readonly type = `[StageInstance] ${DeleteReviewAttachment.name}`;
  constructor(
    public reviewId: string,
    public attachmentId: string,
  ) {}
}
