import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormFields } from "../../../template-fields/models/form-fields.model";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Observable } from "rxjs";
import { RxState } from "@rx-angular/state";
import { FormFieldsFacade } from "src/app/documents/template-fields/facade/form-fields.facades";

interface ListTableBuilderComponentState {
  selectedFormFields: FormFields[];
}

const initialListTableBuilderComponentState: ListTableBuilderComponentState =
  {
    selectedFormFields: [],
  };

@Component({
  selector: 'app-list-table-builder',
  templateUrl: './list-table-builder.component.html',
  styleUrl: './list-table-builder.component.scss'
})
export class ListTableBuilderComponent implements OnInit {

  createdColumnsTitle: Set<string> = new Set<string>();

  selectedFormFields: FormFields[] = [];
  selectedFormFields$: Observable<FormFields[]> = this.state.select(
    'selectedFormFields',
  );
  groupName="";
  tableForm!: FormGroup;
  focusedInput: string = '';

  constructor(private fb: FormBuilder,public state: RxState<ListTableBuilderComponentState>,private templateFieldsFacade: FormFieldsFacade,) {
    this.tableForm = this.fb.group({
      tableName: ['',Validators.required],
      columns: [[], this.atLeastOneColumnValidator]
    })

    this.state.set(initialListTableBuilderComponentState);
    this.state.connect(
      'selectedFormFields',
      this.templateFieldsFacade.selectedFormFields$,
    );

  }

  @ViewChild('select') select!: MatSelect;

  createTableCluster() {
    if(!this.select || this.select.empty) {
      this.tableForm.markAllAsTouched();
      this.createdColumnsTitle = new Set<string>();
      return;
    }
    const tableNameControl=this.tableForm.get('tableName');
    const columnsControl=this.tableForm.get('columns');
    if (tableNameControl?.valid && columnsControl?.valid) {
      this.selectedFormFields.forEach((selectedField : FormFields)=>{
        const column = this.select.value.find((item : FormFields)=>item.order ===selectedField.order)
        if(column) {
          if(this.tableForm.get('tableName')?.value){
            this.groupName = this.tableForm.get('tableName')?.value;
            selectedField.groupName=this.groupName
            this.createdColumnsTitle.add(selectedField.name);
          }
        }
        else{
          selectedField.groupName = "";
          this.createdColumnsTitle.delete(selectedField.name);
        }
      });
    } else {
      this.tableForm.markAllAsTouched();
    }

  }
  atLeastOneColumnValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const columns = control.value;
    if (columns && columns.length > 0) {
      return null;
    } else {
      return { atLeastOneRequired: true };
    }
  };
  @HostListener('window:keydown.space', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const val = this.tableForm.get(this.focusedInput)?.value;
    if(!this.focusedInput) return
    if (val === '' || val.trimEnd() !== val) event.preventDefault();
  }

  previousClicked(){
    this.focusedInput   = '';
  }

  
  ngOnInit(): void {
    this.selectedFormFields$.subscribe((selectedFormFields) => {
      this.selectedFormFields = selectedFormFields;
      this.createdColumnsTitle = new Set([])
      selectedFormFields.forEach((selectedField : FormFields)=>{
            this.groupName=selectedField.groupName
            if( selectedField.groupName){
              this.createdColumnsTitle.add(selectedField.name)
            }

      })
    });
  }
}
