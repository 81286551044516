<mat-dialog-content *appHasPermission="hasCreateEvaluationPermission()">
  <h1 class="mat-h1">{{ createEditToggle.value.title }}</h1>
  <form
    [formGroup]="evaluationsForm"
    class="evaluation"
    (ngSubmit)="submitEvaluation()"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      class="table"
      formArrayName="rows"
    >
      <ng-container matColumnDef="criterion">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.evaluation-form.criterion">Criterion</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.evaluation-form.weight">Weight</th>
        <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef i18n="@@researches.evaluation-form.value">Value</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field
            appearance="outline"
            class="table-input"
          >
            <input matInput type="number" [formControlName]="i" />
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let index = index"
      ></tr>
    </table>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="evaulation--actions">
  <button mat-button mat-dialog-close i18n="@@researches.evaluation-form.cancel">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="submitEvaluation()"
    [disabled]="evaluationsForm.invalid"
  >
    {{ createEditToggle.value.submitButton }}
  </button>
</mat-dialog-actions>
