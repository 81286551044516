<form class="login-form" [formGroup]="loginForm">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n="@@auth.login.please-login">Please Login</mat-card-title>
    </mat-card-header>
    <mat-card-content class="login-fields">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@auth.login.email-user-name-phone-number">Email/User name/Phone number</mat-label>
        <input id="email" formControlName="identifier" matInput (input)="trimEmail()" />
        <mat-error *ngIf="identifierValidationError.errors?.['required']"
        i18n="@@auth.login.please-provide-a-valid-email-or-user-name-or-phone-number">
          Please provide a valid email or user name or phone number
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n="@@auth.login.password">Password</mat-label>
        <input
          id="password"
          [type]="isPasswordVisible ? 'password' : 'text'"
          formControlName="password"
          matInput
          autocomplete="off"
        />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">{{
          isPasswordVisible ? "visibility" : "visibility_off"
        }}</mat-icon>
         <mat-error *ngIf="passwordValidationError.errors?.['required']"
         i18n="@@auth.login.please-provide-a-valid-password">
          Please provide a valid password
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions align="end">
      <button [disabled]="isDisabled$ | async" class="submit" mat-flat-button color="primary" (click)="login()"
      i18n="@@auth.login.log-in">
        Login
      </button>
      <button
        class="forget"
        mat-flat-button
        color="primary"
        (click)="forgetPasswoerd()"
        i18n="@@auth.login.forgot-password"
      >
        Forgot password
      </button>
    </mat-card-actions>
  </mat-card>
</form>
